import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core';

import {
  getErrorMessage,
  getResponseErrorMessage,
} from '../../../utils/requests';

import styles from './style';
import { ActionButtons } from '../../Buttons';

const BaseForm = ({
  error,
  classes,
  children,
  fetching,
  onSubmit,
  onCancel,
  cancelButtonText,
  submitButtonText,
  requestInProgress,
  preventDefault = true,
  showCancelButton = true,
  showLargeSubmitButton,
  centeredActions,
  actionButtonsClassName = '',
  isBodyOverflow = false,
  formBodyClassName = '',
  formClassName = '',
  leftActions = false,
  ...props
}) => {
  const errorContentRef = useRef(null);
  useEffect(() => {
    if (errorContentRef.current) {
      errorContentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [error]);

  const onFormSubmit = (event) => {
    if (preventDefault) {
      event.preventDefault();
    }

    if (onSubmit) {
      onSubmit(event);
    }
  };

  return (
    <form onSubmit={onFormSubmit} {...props} className={formClassName}>
      {error && (
        <div
          className={clsx(classes.error, 'word-break')}
          ref={errorContentRef}
        >
          {getResponseErrorMessage(error) || getErrorMessage(error)}
        </div>
      )}
      {isBodyOverflow ? (
        <div className={formBodyClassName}>{children}</div>
      ) : (
        children
      )}
      <div className={actionButtonsClassName}>
        <ActionButtons
          fetching={fetching}
          onCancel={onCancel}
          rootClass={classes.actionButtons}
          cancelButtonText={cancelButtonText}
          submitButtonText={submitButtonText}
          requestInProgress={requestInProgress}
          showCancelButton={showCancelButton}
          showLargeSubmitButton={showLargeSubmitButton}
          centeredActions={centeredActions}
          leftActions={leftActions}
          actionButtonsClassName={actionButtonsClassName}
        />
      </div>
    </form>
  );
};

export default withStyles(styles)(BaseForm);
