import React from 'react';
import { useParams } from 'react-router';
import { withStyles } from '@material-ui/core';
import Box from '../../../../components/atomic/Box';
import { openLink, openLinkNoEncode } from '../../../../utils/routes';
import Divider from '../../../../components/Divider';
import Copybox from '../../../../components/Copybox';
import useDesktop from '../../../../hooks/useDesktop';
import Grid from '../../../../components/atomic/Grid';
import EmailIcon from '../../../../assets/icons/Email';
import useSnackbar from '../../../../hooks/useSnackbar';
import Typography from '../../../../components/atomic/Typography';
import Image from '../../../../components/Image';
import ReferralImage from '../../../../assets/images/SalesLawImage.png';
import { referralLink, referralTitle } from '../../../../utils/referral';
import ProgressButton from '../../../../components/ProgressButton';
import styles from './style';
import PageLayout from '../../../../components/layouts/WithHeader';
import matomo from '../../../../utils/matomo';

const Referral = ({ classes }) => {
  const isDesktop = useDesktop();
  const { showSuccessMessage } = useSnackbar();
  const { boligmappaNumber } = useParams();
  const HomeSectionUrl = `/dashboard/${boligmappaNumber}/home`;

  const onLinkCopied = () => {
    matomo.clickEvent({
      category: 'Dashboard CTAs',
      name: 'Copy Referral Link',
      action: 'Refer Boligmappa to A Friend',
    });
    showSuccessMessage('Lenken er kopiert.');
  };
  const backButtonAction = () => {
    window.location = HomeSectionUrl;
  };
  const email = {
    subject: 'Kanskje Boligmappa kan være noe for deg?',
    body: `Hei!\n\nJeg har tatt i bruk Boligmappa for å holde oversikt over verdiøkende dokumentasjon om min bolig.\nJeg tenkte at det var noe som kunne passe for deg også.\n\nSjekk det ut på: ${referralLink}`,
  };

  const onEmailClick = () => {
    matomo.clickEvent({
      category: 'Dashboard CTAs',
      name: 'E-mail Referral',
      action: 'Refer Boligmappa to A Friend',
    });

    openLinkNoEncode(
      `mailto:?subject=${email.subject}&body=${encodeURIComponent(email.body)}`,
      '_blank'
    );
  };
  if (isDesktop) {
    return (
      <div className={classes.containerreferral}>
        <Box p={isDesktop ? 0 : 1}>
          <Grid container={isDesktop}>
            <Grid item md={isDesktop && 12}>
              <Typography variant="h2" className={classes.referralTitle}>
                {referralTitle}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box>
                    <Typography
                      className={
                        isDesktop
                          ? classes.containersubtitle
                          : classes.containermobiel
                      }
                    >
                      Kopier lenken og del den med de du ønsker
                    </Typography>
                  </Box>
                  <div
                    className={
                      isDesktop
                        ? classes.referralLinkContainer
                        : classes.copyboxContainer
                    }
                  >
                    <Copybox
                      color="primary"
                      str={referralLink}
                      onCopySuccess={onLinkCopied}
                      onClickUrl={() => openLink(referralLink, '_blank')}
                    />
                  </div>
                  <div className={classes.dividerlink}>
                    <Divider>ELLER</Divider>
                  </div>
                  <div
                    className={
                      isDesktop
                        ? classes.emailButtonContainerDesktop
                        : classes.emailButtonContainer
                    }
                  >
                    <ProgressButton
                      onClick={onEmailClick}
                      requestInProgress={false}
                      type="submit"
                      color="primary"
                      variant="contained"
                      className={classes.EmailButton}
                    >
                      <EmailIcon className={classes.buttonIcon} /> Send på
                      e-post
                    </ProgressButton>
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className={classes.referalimagecontainer}>
                    <Image
                      alt="ReferralImage"
                      src={ReferralImage}
                      fallbackSrc={ReferralImage}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
  return (
    <PageLayout
      minFullHeight
      hideHeader={isDesktop}
      lessMarginLeft={isDesktop}
      maxWidth="lg"
      headerTitle="Anbefale Boligmappa til en venn"
      backButtonAction={backButtonAction}
      className={classes.referralTitleMobile}
    >
      <div>
        <Grid
          container={!isDesktop}
          className={classes.referralcontainermobile}
        >
          <Grid item md={!isDesktop && 12}>
            <Grid container rowSpacing={1} direction="column">
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image
                    alt="publicshare"
                    src={ReferralImage}
                    className={classes.publicshareimageMobile}
                    fallbackSrc={ReferralImage}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.publicShareContentMobile}>
                <Box>
                  <p>Kopier lenken og del den med de du ønsker</p>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div className={classes.copyContainer}>
          <div>
            <Copybox
              color="primary"
              str={referralLink}
              onCopySuccess={onLinkCopied}
              onClickUrl={() => openLink(referralLink, '_blank')}
            />
          </div>
          <div className={classes.dividerlinkMobile}>
            <Divider>ELLER</Divider>
          </div>
          <Grid item xs={12} className={classes.EmailButtonMobile}>
            <ProgressButton
              onClick={onEmailClick}
              requestInProgress={false}
              type="submit"
              color="primary"
              variant="contained"
              className={classes.sendepostbuttonmobile}
            >
              <EmailIcon className={classes.buttonIconmonile} /> Send på e-post
            </ProgressButton>
          </Grid>
        </div>
      </div>
    </PageLayout>
  );
};

export default withStyles(styles)(Referral);
