import { mapVariables } from './helpers';

export const borderVariables = {
  radius: {
    small: '--hj-border-radius-small',
    medium: '--hj-border-radius-medium',
    large: '--hj-border-radius-large',
    full: '--hj-border-radius-full',
  },
  default: '--hj-border-default',
  defaultFocus: '--hj-border-default--focus',
  defaultActive: '--hj-border-default--active',
  inputActive: '--hj-border-input--active',
  outlineDefault: '--hj-outline-default',
  outlineLight: '--hj-outline-light',
  radio: {
    border: '--hj-radio-border',
    borderActive: '--hj-radio-border--active',
  },
  link: {
    outline: '--hj-link-outline',
  },
};

export const borders = mapVariables<typeof borderVariables>(borderVariables);
