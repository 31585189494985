import React from 'react';
import { withStyles } from '@material-ui/core';
import Box from '../../../../../../components/atomic/Box';
import ContentLoader from '../../../../../../components/loaders/ContentLoader';
import styles from './style';

function Loader({ classes }) {
  return (
    <Box className={classes.container}>
      <ContentLoader display="inline-flex" height={30} width="70%" />

      <ContentLoader display="inline-flex" height={20} width="30%" />

      <ContentLoader display="inline-flex" height={30} width="50%" />

      <ContentLoader display="inline-flex" height={20} width="30%" />

      <ContentLoader display="inline-flex" height={30} width="70%" />
    </Box>
  );
}

export default withStyles(styles)(Loader);
