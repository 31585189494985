const styles = (theme) => ({
  badge: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.green,
  },
  label: {
    fontWeight: 400,
    fontSize: '10px',
  },
  icon: {
    width: '30px',
    height: '25px',
    marginBottom: '6px',
  },
  navItem: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minWidth: '64px',
    maxWidth: '64px',
    padding: 0,
    color: '#717171',
    textAlign: 'center',
    lineHeight: '10px',
  },
  navItemActive: {
    color: theme.palette.primary.blackGreen,
    fontWeight: 500,
  },
  notificationNumber: {
    position: 'absolute',
    width: '14px',
    height: '14px',
    borderRadius: '50%',
    padding: 0,
    justifyContent: 'center',
    right: '-10px',
    top: '-5px',
    fontSize: '10px',
  },
});

export default styles;
