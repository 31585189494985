import React from 'react';

const Event = ({ width = '1.2rem', height = '1.2rem', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 27.526 24.912"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      transform="translate(-419.035 -1696.822)"
      d="M430.2,1707.322a.586.586,0,0,0-.813,0l-9.5,9.5a2.86,2.86,0,0,0-.843,2.036,2.96,2.96,0,0,0,2.876,2.876,2.861,2.861,0,0,0,2.03-.84l9.5-9.5a.58.58,0,0,0,0-.817Z"
    />
    <path
      transform="translate(-418.085 -1698.172)"
      d="M445.105,1707.452l-1.219-1.219a1.151,1.151,0,0,0-1.627,0,1.2,1.2,0,0,1-1.627,0,1.161,1.161,0,0,1-.258-1.242,1.727,1.727,0,0,0-.385-1.84c-1.647-1.656-2.309-2.324-2.638-2.588h.008a8.911,8.911,0,0,0-11.538-.517,1.147,1.147,0,0,0,.735,2.07,5.8,5.8,0,0,1,4.316,1.668c.595.6.9,1.058.9,1.369a.484.484,0,0,1-.174.356l-1.13,1.127a.58.58,0,0,0,0,.817l3.253,3.244a.566.566,0,0,0,.813,0l1.22-1.219a1.194,1.194,0,0,1,1.625,0,1.159,1.159,0,0,1,0,1.633,1.145,1.145,0,0,0,0,1.622l1.22,1.231a1.716,1.716,0,0,0,2.44,0l4.067-4.072a1.723,1.723,0,0,0,0-2.438Z"
    />
  </svg>
);

export default Event;
