import React from 'react';

const EmailIcon = ({
  width = '1.2rem',
  height = '1.2rem',
  color,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 22.093 14.729"
    {...props}
  >
    <g
      data-name="Emails / Email-Actions / email-action-unread"
      transform="translate(-288 -868)"
    >
      <g transform="translate(288 868)">
        <g>
          <path
            d="M309.99,869.831a.167.167,0,0,0-.184.036l-8.972,8.971a2.532,2.532,0,0,1-3.579,0l-8.969-8.971a.166.166,0,0,0-.184-.036.168.168,0,0,0-.1.155v11.046a1.84,1.84,0,0,0,1.841,1.841h18.411a1.841,1.841,0,0,0,1.841-1.841V869.985A.167.167,0,0,0,309.99,869.831Z"
            transform="translate(-288 -868.144)"
            fill={color || '#fff'}
          />
          <path
            d="M298.334,877.717a1.153,1.153,0,0,0,1.627,0l8.916-8.916a.459.459,0,0,0,.111-.47c-.1-.3-.383-.33-.635-.33H289.943c-.253,0-.537.028-.636.33a.459.459,0,0,0,.111.47Z"
            transform="translate(-288.102 -868)"
            fill={color || '#fff'}
          />
        </g>
      </g>
    </g>
  </svg>
);

export default EmailIcon;
