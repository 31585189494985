import React from 'react';

import FeatureBox from '@componentsV2/FeatureBox';
import CallToAction from '@componentsV2/BoligmappaPlus/CallToAction';
import NewsletterSignup from '@componentsV2/NewsletterSignup';

import skjermbilde from '../../assets/images/Skjermbilde.jpg';
import { StyledLanding } from './styles';

const BoligmappaPlusLanding = ({
  isDesktop = false,
  dashboardLinkPrefix = '',
  isBoligmappaPlusSub = false,
  onActionClick,
}) => {
  return (
    <StyledLanding>
      {!isBoligmappaPlusSub && (
        <>
          <CallToAction isDesktop={isDesktop} onActionClick={onActionClick} />
          <div className="section">
            <h2>Boligmappa +</h2>
            <p>
              I over 10 år har Boligmappa tatt vare på historikken til norske
              bygg og boliger. Hele tiden med fokus på sikkerhet, med BankID
              innlogging og kobling mot eiendomsregisteret. Det skal vi
              fortsette med. Nå tilbyr vi noe nytt – i tillegg.
              <br />
              <br />
              Boligmappa+ er for deg som er opptatt av hjemmet ditt, ønsker å ta
              smarte valg – og spare litt penger på veien!
            </p>
          </div>
          <img
            className="landing_img"
            src={skjermbilde}
            alt="boligmappa plus"
          />
          <div className="cta_advantages">
            <p>Boligmappa+ består av to områder:</p>
            <ul>
              <li>
                <b>Ny og kul funksjonalitet</b>
                <br />
                Det første vi lanserer er et personlig område! Her kan du ta
                vare på alt av kvitteringer, garantier, bilder og annet som er
                viktig for deg. Mye mer lanseres snart!
              </li>
              <li>
                <b>Et skikkelig bra fordelsprogram</b>
                <br />
                Over 900 000 boligeiere bruker Boligmappa. Når vi er så mange
                får vi skikkelig gode avtaler på alt fra boliglån, forsikring og
                alarm, til byggevare, håndverkstjenester, varmepumpe, peis og
                mye mer.
              </li>
            </ul>
            <p>
              Boligmappa+ er et tillegg til alt det som allerede finnes i
              Boligmappa. Dine boligdokumenter, oversikt over håndverkere, rom
              og hendelser er fortsatt tilgjengelig, helt gratis.
            </p>
          </div>
        </>
      )}
      <div className="features_section">
        <h3>
          {!isBoligmappaPlusSub && 'Hva får jeg i '} Boligmappa+{' '}
          {!isBoligmappaPlusSub && '?'}
        </h3>
        <div className="features_block">
          <FeatureBox
            headline="Personlig område"
            linkText="Lær mer om Personlig område →"
            hideLinkText={!isDesktop}
            to={`${dashboardLinkPrefix}/documents?personal=true`}
            navigationArrowIcon
          >
            Dokumenter i personlig område følger deg for alltid. Trygg og sikker
            lagring av viktige dokumenter med BankID.
          </FeatureBox>
          <FeatureBox
            headline="Mine fordeler"
            linkText="Lær mer om Mine fordeler →"
            hideLinkText={!isDesktop}
            to={`${dashboardLinkPrefix}/boligmappa-plus/loyalty`}
            navigationArrowIcon
          >
            Rabatter, fordeler og gode avtaler for oss som er opptatt av bolig.
          </FeatureBox>
        </div>
        <div className="soon_features">
          <h3>Kommer snart!</h3>
        </div>
        <div className="features_block">
          <FeatureBox
            headline="Tidligere boliger"
            backgroundColor="rgba(255, 255, 255, 0.4)"
          >
            Trenger du dokumentasjon i en bolig du har eid tidligere?
            <br />
            <br />
            Med Boligmappa+ får du tilgang til tidligere boliger.
          </FeatureBox>
          <FeatureBox
            headline="Veileder for oppussing"
            backgroundColor="rgba(255, 255, 255, 0.4)"
          >
            Vi hjelper deg med å sørge for at ditt neste oppussingsprosjekt blir
            ordentlig dokumentert.
          </FeatureBox>
          <FeatureBox
            headline="Mitt nabolag"
            backgroundColor="rgba(255, 255, 255, 0.4)"
          >
            Se populære håndverkere og solgtpriser i nabolaget ditt.
          </FeatureBox>
        </div>
      </div>
      {!isBoligmappaPlusSub && (
        <div className="unsubscribe">
          <h3>Er det bindingstid?</h3>
          <p>
            Du kan si opp Boligmappa+ når du selv ønsker.{' '}
            <a
              href="https://support.boligmappa.no/hc/no/articles/15231152112146"
              target="_blank"
              rel="noreferrer"
            >
              Les mer her
            </a>
            .
          </p>
        </div>
      )}
      <NewsletterSignup />
    </StyledLanding>
  );
};

export default BoligmappaPlusLanding;
