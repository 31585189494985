import React from 'react';

import clsx from 'clsx';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Link from '../atomic/Link';
import Button from '../atomic/Button';
import Typography from '../atomic/Typography';

const EmptyMessage = ({
  to,
  classes,
  children,
  className,
  onLinkClick,
  shiftMarginTopBy,
  marginTop = true,
  additionalContent,
  publicSection = false,
  wrapInTypography = true,
  buttonText = 'Kom i gang',
  isPersonal,
  ...props
}) => {
  const button = (
    <Button
      color="primary"
      variant="contained"
      onClick={onLinkClick}
      padding={2}
    >
      {buttonText}
    </Button>
  );

  const personalButton = (
    <Button className={classes.personalBtn} onClick={onLinkClick}>
      {buttonText}
    </Button>
  );

  return (
    <div
      className={clsx(
        className,
        classes.container,
        marginTop && classes.marginTop
      )}
      {...props}
    >
      {wrapInTypography ? (
        <Typography variant="h2" color="textSecondary" className={classes.text}>
          {children}
        </Typography>
      ) : (
        children
      )}

      {!publicSection && to && (
        <div className={classes.buttonContainer}>
          <Link to={to}>{isPersonal ? personalButton : button}</Link>
        </div>
      )}

      {!publicSection && onLinkClick && (
        <div className={classes.buttonContainer}>
          {isPersonal ? personalButton : button}
          {additionalContent}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(EmptyMessage);
