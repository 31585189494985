import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import useDesktop from '../../../../hooks/useDesktop';
import Menu from '../../../atomic/Menu';
import Button from '../../../atomic/Button';
import Close from '../../../../assets/icons/Close';
import Typography from '../../../atomic/Typography';
import BottomContextMenu from '../../../BottomContextMenu';
import Box from '../../../atomic/Box';
import BackArrow from '../../../../assets/icons/DocumentBulkEditIcons/BackArrow';

const ListHeader = ({
  classes,
  title,
  onMenuClose,
  onClose,
  children,
  selectedDocuments,
  isBulk = true,
  subTitle = '',
  ...props
}) => {
  const isDesktop = useDesktop();

  const titleContent = isDesktop ? (
    <Box display="flex" alignItems="right">
      <Box flexGrow={1} marginRight="-64px" className={classes.title}>
        <Typography>{title}</Typography>
      </Box>

      <Box width="64px">
        <Button
          varinat="text"
          color="primary"
          onClick={onMenuClose}
          style={{ float: 'right' }}
        >
          <Close />
        </Button>
      </Box>
    </Box>
  ) : (
    <Box
      display="flex"
      sx={{ justifyContent: 'space-between', alignItems: 'center' }}
    >
      <Box display="flex" sx={{ alignItems: 'center' }}>
        <Box onClick={onMenuClose}>
          <BackArrow />
        </Box>
        <Box sx={{ ml: 2, textAlign: 'start' }}>
          {isBulk && (
            <Typography fontSize={20} fontWeight={600}>
              {selectedDocuments?.length} valgt
            </Typography>
          )}
          {subTitle && (
            <Typography fontSize={20} fontWeight={600}>
              {subTitle}
            </Typography>
          )}
          <Typography fontSize={17} fontWeight={500}>
            {title}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  const mobileMenuContent = (
    <BottomContextMenu title={titleContent} {...props}>
      {children}
    </BottomContextMenu>
  );

  const desktopMenuContent = (
    <Menu
      onClose={onClose}
      PopoverClasses={{ root: classes.popover }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      {...props}
    >
      {children}
    </Menu>
  );

  if (!isDesktop) {
    return mobileMenuContent;
  }

  return desktopMenuContent;
};

export default withStyles(styles)(ListHeader);
