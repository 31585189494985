import React from 'react';
import BaseFileIcon from '../BaseFileIcon';

const DocxIcon = ({
  color = '#527fcf',
  text = 'DOCX',
  x = -2,
  fontSize = '6',
}) => <BaseFileIcon color={color} text={text} x={x} fontSize={fontSize} />;

export default DocxIcon;
