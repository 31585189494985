export const padding = 13;

const styles = (theme) => ({
  paper: {
    padding,

    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  title: {
    margin: '15px 0 0',
  },
});

export default styles;
