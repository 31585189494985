import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core';

import useDesktop from '@hooksV2/useDesktop';
import Header from '../../Header';
import Container from '../../Container';
import { sGetActiveProperty } from '../../../store/reducers/properties';
import styles from './style';
import MenuHorizontal from '../../../assets/icons/MenuHorizontal';

const Page = ({
  classes,
  children,
  propertySelectLayout,
  homeLayout,
  topLevelLayout,
  activeProperty,
  headerChild,
  headerSearch,
  isSearching,
  setHeaderHeight,
  fetchingProperties,
  fetchingPropertiesError,
  headerTitle,
  className,
  contentClass,
  publicSection,
  onMenuIconClick,
  subPageHeader = false,
  hideHeader = false,
  root = false,
  maxWidth = 'lg',
  minFullHeight = false,
}) => {
  const isDesktop = useDesktop();
  return (
    <div
      className={clsx(
        className,
        classes.container,
        root && classes.root,
        minFullHeight && classes.minFullHeight
      )}
    >
      {isDesktop && subPageHeader && (
        <div className={classes.subPageHeader}>
          <h2 className={classes.pageHeader}>{headerTitle}</h2>
          <div className={classes.editButtonsBlock}>
            {onMenuIconClick && (
              <button
                className={classes.editItemButton}
                type="button"
                onClick={onMenuIconClick}
                aria-label="Menu Horizontal"
              >
                <MenuHorizontal />
              </button>
            )}
          </div>
        </div>
      )}
      {!hideHeader && (
        <Header
          isDesktop={isDesktop}
          headerTitle={headerTitle}
          propertySelectLayout={propertySelectLayout}
          homeLayout={homeLayout}
          topLevelLayout={topLevelLayout}
          setHeaderHeight={setHeaderHeight}
          headerChild={headerChild}
          headerSearch={headerSearch}
          isSearching={isSearching}
          onMenuIconClick={onMenuIconClick}
          activeProperty={activeProperty}
          publicSection={publicSection}
          fetching={fetchingProperties}
          error={fetchingPropertiesError}
        />
      )}
      <Container
        maxWidth={maxWidth}
        className={clsx(contentClass, classes.withHeaderContent)}
      >
        {children}
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeProperty: sGetActiveProperty(state),
});

export default connect(mapStateToProps)(withStyles(styles)(Page));
