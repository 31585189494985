import { css } from '@linaria/core';
import { borders, colors } from '@style/vars';

export const linkButtonStyle = css`
  border: none;
  background: none;

  &:focus {
    outline-offset: 6px;
    outline: ${borders.link.outline};
  }

  &.variant-light {
    svg {
      stroke: ${colors.lightColor};
    }
  }
`;
