const styles = (theme) => ({
  title: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#000',
    margin: '0',
  },
  label: {
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.colors.greyBlack,
    fontWeight: 500,
  },
  value: {
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.colors.greyBlack,
    fontWeight: 500,
  },
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '16px 0',
    [theme.breakpoints.up('md')]: {
      border: 'none',
    },
  },
  tagList: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: '4px',
  },
  roomEventTag: {
    fontSize: '12px',
    lineHeight: '18px',
    textDecoration: 'unset',
    color: theme.colors.darkBlack,
    margin: 0,
    padding: '3px 8px',
    backgroundColor: '#F2F8F8',
    borderRadius: '4px',
  },
  emptyTag: {
    backgroundColor: theme.colors.redTransparent,
  },
  titleLoader: {
    margin: '10px 0 50px',
  },
  listItemLoader: {
    margin: '43px 0',
    '&:last-child': {
      margin: '43px 0 30px',
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.colors.greyBlack,
  },
  listLink: {
    marginBottom: '8px',
    '&:last-child': {
      marginBottom: '0',
    },
  },
});

export default styles;
