export const sidebarWidth = 220;
export const containerMaxWidth = 1480;

const styles = (theme) => ({
  addButtonPaddingEnabled: {
    paddingBottom: '160px',
  },
  addPublicButtomPaddingEnabled: {
    paddingBottom: '90px',
  },
  mobileNavbarPaddingEnabled: {
    paddingBottom: '80px',
  },
  desktopSidebar: {
    position: 'fixed',
    overflowY: 'auto',
    width: `${sidebarWidth}px`,
    height: 'calc(100vh - 65px)',
    borderRight: `1px solid ${theme.palette.primary.whiteDark}`,
  },
  desktopHeader: {
    top: '0',
    left: 'auto',
    right: '0',
    position: 'sticky',
    zIndex: 99,
    background: '#fff',
    borderBottom: '1px solid #E6E6E6',
  },
  backgroundContentContainer: ({ backgroundContentContainer }) => ({
    backgroundColor: backgroundContentContainer,
  }),
});

export default styles;
