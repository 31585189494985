import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { isNativeEnv } from 'utils/nativeBridge';
// import { useFlag } from '@unleash/proxy-client-react';
import {
  sGetOwnedProperties,
  sGetPropertiesLoaded,
  sGetFetchingProperties,
  sGetFetchingPropertiesError,
  sGetSharedPrivatelyProperties,
} from '../../../store/reducers/properties';

import {
  acSetProperties,
  acSetFetchingProperties,
  acSetFetchingPropertiesError,
  acSetActiveProperty,
} from '../../../store/actions/properties';

import {
  getHttpErrorStatus,
  getResponseErrorMessage,
  isHttpBadRequestError,
  isPropertyOwnerNotFoundError,
} from '../../../utils/requests';

import {
  removePrivateShareToken,
  retrievePrivateShareToken,
} from '../../../utils/auth';

import {
  refreshProperties as apiRefreshProperties,
  updateLastActivityTime as apiUpdateLastActivityTime,
} from '../../../api/propertyowners';

import styles from './style';
import PropertyList from './PropertyList';
import Box from '../../../components/atomic/Box';
import ListItemCard from '../../../components/ListItemCard';
import useDesktop from '../../../hooks/useDesktop';
import {
  getDashboardLinkPrefix,
  parseQuery,
  redirectPath,
} from '../../../utils/routes';
import BoligmappaHeaderLogo from '../../../assets/images/BM-header-logo.svg';
import NoPropertiesScreen from './NoPropertiesScreen';
import { logError, logInfo } from '../../../utils/loggers';
import useActionDialog from '../../../hooks/useActionDialog';
import Typography from '../../../components/atomic/Typography';
import ErrorContainer from '../../../components/ErrorContainer';
import PageLayout from '../../../components/layouts/WithHeader';
import PropertyRefreshContext from '../../../contexts/propertyRefresh';
import CardItemLoading from '../../../components/loaders/CardItemLoading';
import { fetchProperties as apiFetchProperties } from '../../../api/properties';
import { patchPrivateShareToken as apiPatchPrivateShareToken } from '../../../api/privateaccess';
import sessionStorage from '../../../utils/sessionStorage';
import { getPropertyBoligmappaNumber } from '../../../utils/properties';
import { PROPERTY_MISSING } from '../../../constants';

export const Properties = ({
  classes,
  fetching,
  setFetching,
  fetchingError,
  setProperties,
  ownedProperties,
  propertiesLoaded,
  setFetchingError,
  setActiveProperty,
  sharedPrivatelyProperties,
}) => {
  const history = useHistory();
  const isDesktop = useDesktop();
  const { search } = useLocation();
  const { showActionDialog } = useActionDialog();
  // const enableDocumentsTab = useFlag('boligeier.document.tab');

  useEffect(() => {
    setActiveProperty(null);
  }, []);

  useEffect(() => {
    if ((ownedProperties || []).length === 1) {
      const redirectPathValue = sessionStorage.get(redirectPath);
      if (redirectPathValue) {
        const redirectUrl = `${getDashboardLinkPrefix(
          getPropertyBoligmappaNumber(ownedProperties[0])
        )}/home`;
        history.push(redirectUrl);
      }
    }
  }, [ownedProperties]);

  useEffect(() => {
    return () => {
      localStorage.setItem('scrollPosition', '0');
      localStorage.removeItem('sortType');
    };
  }, []);

  const {
    propertyRefreshRequestInProgress,
    setPropertyRefreshRequestInProgress,
  } = useContext(PropertyRefreshContext);

  const { refreshEnabled, shouldRefetchProperties } = parseQuery(search);

  const shareToken = retrievePrivateShareToken();

  const hasOwnedProperties =
    Array.isArray(ownedProperties) && ownedProperties.length > 0;

  const hasSharedPrivatelyProperties =
    Array.isArray(sharedPrivatelyProperties) &&
    sharedPrivatelyProperties.length > 0;

  const patchCallFailedAlert = (submitHandler, errorResponse) => {
    const PatchFailedMessage = () => (
      <>
        <p className={classes.errorResponse}>
          Feilkode : {getResponseErrorMessage(errorResponse)}
        </p>
        <p>
          Det ser ut til at du har forsøkt å benytte en ugyldig token for privat
          deling. Dette kan skje dersom:
        </p>
        <ul className={classes.errorlist}>
          <li>- det er mer enn 30 dager siden tilgangen ble delt med deg</li>
          <li>- tilgangen allerede er tilknyttet en annen brukerkonto.</li>
        </ul>

        <p>
          Vennligst ta kontakt med personen som delte tilgangen med deg, slik at
          de kan dele tilgangen på nytt.
        </p>
      </>
    );

    return showActionDialog({
      closeable: true,
      showCancelButton: false,
      title: 'Privat deling feilet',
      submitButtonText: 'OK',
      submitButtonVariant: 'contained',
      onSubmit: submitHandler,
      message: <PatchFailedMessage />,
    });
  };

  const fetchProperties = async () => {
    setFetching(true);

    const [error, properties] = await apiFetchProperties();

    if (properties && !error) {
      /* if (
        enableDocumentsTab &&
        properties.owned?.length === 0 &&
        properties.sharedPrivately?.length === 0
      ) {
        history.push('/personal-documents');
      } */
      setProperties(properties);
      setFetching(false);
    }

    if (error && !isPropertyOwnerNotFoundError(error)) {
      setFetchingError(error);
      setFetching(false);
    }

    if (error && isPropertyOwnerNotFoundError(error)) {
      history.push('/signup');
    }
  };

  const patchPrivateShareToken = async (token) => {
    const [error, response] = await apiPatchPrivateShareToken(token);

    if (response) {
      logInfo('Successfully patched shared property');
      fetchProperties();
    }

    if (error) {
      patchCallFailedAlert(fetchProperties, error);
      logError(`Failed to patch the private share token${error}`);
    }

    removePrivateShareToken();
  };

  const handlePrivateShareToken = () => {
    if (shareToken) {
      patchPrivateShareToken(shareToken);
    } else {
      fetchProperties();
    }
  };

  const sendPropertyRefreshRequest = async (updateActivityTime = true) => {
    setPropertyRefreshRequestInProgress(true);

    const [error, response] = await apiRefreshProperties();

    if (updateActivityTime) {
      apiUpdateLastActivityTime();
    }

    if (
      (!error && response) ||
      isHttpBadRequestError(getHttpErrorStatus(error))
    ) {
      handlePrivateShareToken();
    } else {
      showActionDialog({
        closeable: true,
        cancelButtonText: 'Fortsett',
        submitButtonText: 'Prøv på nytt',
        submitButtonVariant: 'contained',
        onCancel: handlePrivateShareToken,
        onSubmit: () => sendPropertyRefreshRequest(false),
        message: (
          <Typography>
            Vær oppmerksom på at eiendommer registrert på deg kan være udatert
            og eventuelle endringer du gjør for disse kan gå tapt.
          </Typography>
        ),
        title: 'Oppdatering av nyeste eiendomsinformasjon mislyktes',
      });
    }

    setPropertyRefreshRequestInProgress(false);
  };

  const renderContent = () => {
    if (fetching || propertyRefreshRequestInProgress) {
      return (
        <Box mt="70px">
          <CardItemLoading />
          <CardItemLoading />
          <CardItemLoading />
        </Box>
      );
    }

    if (fetchingError) {
      return (
        <ErrorContainer
          paddingTop={isDesktop}
          errorResponse={fetchingError}
          style={{ paddingTop: isDesktop ? undefined : '15%' }}
        />
      );
    }

    if (hasOwnedProperties || hasSharedPrivatelyProperties) {
      return (
        <>
          <Box py={2} pb={6}>
            {hasOwnedProperties && (
              <>
                {' '}
                <Box className={classes.headerBox}>
                  <img
                    src={BoligmappaHeaderLogo}
                    className={classes.headerImage}
                    alt=""
                  />
                </Box>
                {!isNativeEnv() && <ListItemCard />}
                <br />
                <Box>
                  <PropertyList
                    fetching={fetching}
                    properties={ownedProperties}
                  />
                </Box>
              </>
            )}
            {!hasOwnedProperties && <NoPropertiesScreen />}
            {hasSharedPrivatelyProperties && (
              <Box mt={8}>
                <Box my={4} className={classes.caption}>
                  Delt med deg
                </Box>

                <PropertyList
                  fetching={fetching}
                  properties={sharedPrivatelyProperties}
                />
              </Box>
            )}
          </Box>
          <br />
          {hasOwnedProperties && (
            <a
              href={PROPERTY_MISSING}
              target="_blank"
              rel="noreferrer"
              className={classes.terms_link}
            >
              Mangler du en eiendom
            </a>
          )}
        </>
      );
    }

    return <NoPropertiesScreen />;
  };

  useEffect(() => {
    if (!propertiesLoaded && refreshEnabled) {
      sendPropertyRefreshRequest();
    }

    if (!propertiesLoaded && !refreshEnabled) {
      fetchProperties();
    }
  }, [shouldRefetchProperties]);

  return (
    <>
      <PageLayout maxWidth="sm" headerTitle="" propertySelectLayout>
        {renderContent()}
      </PageLayout>
    </>
  );
};

Properties.defaultProps = {};

Properties.propTypes = {
  setProperties: PropTypes.func.isRequired,
  setFetching: PropTypes.func.isRequired,
  setFetchingError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  fetching: sGetFetchingProperties(state),
  ownedProperties: sGetOwnedProperties(state),
  propertiesLoaded: sGetPropertiesLoaded(state),
  fetchingError: sGetFetchingPropertiesError(state),
  sharedPrivatelyProperties: sGetSharedPrivatelyProperties(state),
});

const mapDispatchToProps = (dispatch) => ({
  setProperties: (properties) => dispatch(acSetProperties(properties)),
  setFetching: (fetching) => dispatch(acSetFetchingProperties(fetching)),
  setFetchingError: (error) => dispatch(acSetFetchingPropertiesError(error)),
  setActiveProperty: (property) => dispatch(acSetActiveProperty(property)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Properties));
