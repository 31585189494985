import React, { createElement, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import useFetchEffect from '../../../../../hooks/useFetchEffect';
import Page from '../../../../../components/layouts/WithHeader';
import styles from './style';
import Box from '../../../../../components/atomic/Box';
import Close from '../../../../../assets/icons/Close';
import BoligmappaPlusLogo from '../../../../../assets/images/Boligmappa-Plus-Logo.png';
import CallToAction from '../../../../../componentsV2/BoligmappaPlus/CallToAction';
import SearchIcon from '../../../../../assets/icons/Search';
import Collapse from '../../../../../components/atomic/Collapse';
import TextField from '../../../../../components/atomic/TextField';
import InputAdornment from '../../../../../components/atomic/InputAdornment';
// import SearchField from '../../../../../components/SearchField';
import UploadArrowIcon from '../../../../../assets/icons/UploadArrowIcon';
import { AddNewButton } from '../../../../../components/Buttons';
import ErrorContainer from '../../../../../components/ErrorContainer';
import { fetchPersonalDocuments as apiFetchPersonalDocuments } from '../../../../../api/propertyowners';
import {
  sGetFetchingPersonalDocuments,
  sGetFetchingPersonalDocumentsError,
  sGetPersonalDocuments,
} from '../../../../../store/reducers/personalDocuments';
import {
  acDeletePersonalDocument,
  acSetFetchingPersonalDocuments,
  acSetFetchingPersonalDocumentsError,
  acSetPersonalDocuments,
} from '../../../../../store/actions/personalDocuments';
import {
  acSetDocumentSearchText,
  acSetDocuments,
  acSetFetchingDocuments,
} from '../../../../../store/actions/documents';
import useDesktop from '../../../../../hooks/useDesktop';
import {
  getDocumentList,
  getDocumentTable,
} from '../../../../../utils/DocumentTable';
import { parseQuery } from '../../../../../utils/routes';
import ChapterTable from '../../Documents/ChapterTable';
import ChapterList from '../../Documents/ChapterList';
import MobileDocumentSearch from '../../Documents/MobileDocumentSearch';
import { FileUploadContext } from '../../../../../contexts/fileUpload';
import { sGetDocumentSearchText } from '../../../../../store/reducers/documents';
import Typography from '../../../../../components/atomic/Typography';
// import EmptyMessage from '../../../../../components/EmptyMessage';
// import ManWorkingImg from '../../../../../assets/images/mann-kontorpult.png';
import Hidden from '../../../../../components/atomic/Hidden';
import AddContextMenu from '../../AddContextMenu';
import { useBoligmappaPlusSubscriptionStatus } from '../../../../../store/hooks/useBoligmappaPlusSubscriptionStatus';
import { boligmappaPlusActionCallback } from '../../../../../utils/boligmappaPlus';
import matomo from '../../../../../utils/matomo';
import useSnackbar from '../../../../../hooks/useSnackbar';

const MyDocuments = ({
  setPersonalDocumentFetching,
  setPersonalDocuments,
  setPersonalDocumentFetchingError,
  personalDocuments,
  personalDocumentfetching,
  fetchingError,
  searchText,
  setSearchText,
  deletePersonalDocument,
  classes,
}) => {
  const isBoligmappaPlusUser = useBoligmappaPlusSubscriptionStatus();
  const [personalDocumentLoading, setPersonalDocumentLoading] = useState(false);
  const isDesktop = useDesktop();
  const isExpandable = true;
  const { search } = useLocation();
  const parsedQuery = parseQuery(search);
  const { onFileUploadClick } = useContext(FileUploadContext);
  const [searchQuery, setSearchQuery] = useState(searchText);
  const [searchFieldChecked, setSearchFieldChecked] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [stickyFiltersHeight, setStickyFiltersHeight] = useState(0);
  const [showEmptyListMessage, setShowEmptyListMessage] = useState(
    personalDocuments && personalDocuments?.length === 0
  );

  const { chaptersMode } = parsedQuery || {};
  const { showErrorMessage } = useSnackbar();

  const getPersonalDocumentLoading = (data) => {
    setPersonalDocumentFetching(data);
    setPersonalDocumentLoading(data);
  };

  const onBackgroundClick = () => {
    if (searchFieldChecked && !(searchQuery?.length > 0)) {
      setSearchFieldChecked(false);
    }
  };

  useFetchEffect({
    setFetchingFunction: (data) => getPersonalDocumentLoading(data),
    onSuccess: (data) => setPersonalDocuments(data.documents),
    onError: (error) => setPersonalDocumentFetchingError(error),
    apiFetchFunction: () => apiFetchPersonalDocuments(),
  });

  useEffect(() => {
    if (personalDocuments?.length < 1) {
      setShowEmptyListMessage(true);
    } else {
      setShowEmptyListMessage(false);
    }
  }, [personalDocuments]);

  const onDcumentUpload = () => {
    if (isDesktop) {
      onFileUploadClick({
        query: {
          bulkUploadMode: true,
          source: 'table',
          newUpload: true,
          isRoomOrEvent: true,
        },
      });
    } else {
      onFileUploadClick({
        query: {
          bulkUploadMode: true,
          source: 'listView',
          newUpload: true,
          isRoomOrEvent: true,
        },
      });
    }
  };
  const oncloseSearch = () => {
    setSearchQuery('');
  };

  const onDocumentDeleted = (document) => {
    deletePersonalDocument(document);
  };

  const onSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setSearchText(event.target.value);
  };

  const handleQueryChange = () => {
    if (!(searchQuery?.length > 0)) {
      setSearchFieldChecked(!searchFieldChecked);
    } else {
      setSearchFieldChecked(true);
    }
  };

  const handleActionClick = async (type) => {
    matomo.clickEvent({
      category: 'Boligmappa+',
      action: 'Pay with Vipps',
    });
    await boligmappaPlusActionCallback(type, showErrorMessage);
  };

  const mobileHeaderSearch = () => (
    <MobileDocumentSearch
      fullWidth
      value={searchQuery}
      onChange={onSearchChange}
      placeholder="Søk i dokumenter"
      isSearching={isSearching}
      setIsSearching={setIsSearching}
    />
  );

  const renderBoligmappaPlusCTA = () => {
    return (
      <div className={classes.boligmappa_plus_subscription_section}>
        <h1>Mine dokumenter</h1>
        <div style={{ display: 'flex' }}>
          <div>
            <p className={classes.boligmappa_plus_personal_text}>
              Dokumenter i personlig område følger deg for alltid. Trygg og
              sikker lagring av viktige dokumenter med BankID. .{' '}
            </p>
            <button type="button" className={classes.boligmappa_plus_logo_btn}>
              Tilgjengelig med
              <img alt="Boligmappa+Logo" src={BoligmappaPlusLogo} />
            </button>
          </div>
          <div>
            <CallToAction
              isDesktop={isDesktop}
              className={classes.personal_area_call_to_action}
              onActionClick={handleActionClick}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderTable = () => {
    if (chaptersMode) {
      return createElement(isDesktop ? ChapterTable : ChapterList, {
        documents: personalDocuments,
        fetchingDocuments: personalDocumentfetching,
        fetchingDocumentsError: fetchingError,
        searchQuery,
      });
    }
    return createElement(isDesktop ? getDocumentTable() : getDocumentList(), {
      fetching: personalDocumentLoading,
      documents: personalDocuments,
      searchQuery,
      showBulkSelectionOptions: true,
      onDocumentDeleted,
      isPersonalDocuments: true,
      fetchingError,
      isFromPersonal: true,
      headerHeight,
      setStickyFiltersHeight,
      isBoligmappaPlusUser: isBoligmappaPlusUser === true,
    });
  };

  return (
    <Page
      topLevelLayout
      document
      noPadding
      maxWidth={false}
      showBackButton={false}
      headerTitle="Mine dokumenter"
      minFullHeight={isDesktop}
      headerSearch={mobileHeaderSearch()}
      isSearching={isSearching}
      setHeaderHeight={setHeaderHeight}
    >
      {fetchingError ? (
        <ErrorContainer
          paddingTop={isDesktop}
          errorResponse={fetchingError}
          style={{ paddingTop: isDesktop ? undefined : '15%' }}
        />
      ) : (
        <Box
          className={isDesktop && classes.mobilePadding}
          sx={{ marginTop: stickyFiltersHeight }}
        >
          {!isBoligmappaPlusUser && isDesktop && renderBoligmappaPlusCTA()}
          <Box display="flex" alignItems="center" px={isDesktop ? 0 : 2}>
            {isDesktop && (
              <Typography
                className={
                  isDesktop ? classes.headerTitle : classes.headerTitleMobile
                }
              >
                Mine dokumenter
              </Typography>
            )}

            <Box flex="50%" maxWidth={isDesktop ? '50%' : undefined}>
              {!chaptersMode && isDesktop && (
                <Box display="flex" onClick={() => handleQueryChange()}>
                  <Box className={classes.verticalSeperator} />
                  <Collapse
                    orientation="horizontal"
                    in={searchFieldChecked}
                    collapsedSize={40}
                    sx={{ overflow: 'visible' }}
                  >
                    <TextField
                      isExpandable={isExpandable}
                      value={searchQuery}
                      className={classes.queryInput}
                      onChange={(e) => onSearchChange(e)}
                      onBlur={() => onBackgroundClick()}
                      placeholder="Søk"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            disablePointerEvents
                            className={classes.searchButton}
                          >
                            &nbsp;
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: searchFieldChecked && (
                          <InputAdornment position="end">
                            <Close
                              className={classes.closeButton}
                              onClick={oncloseSearch}
                            />
                          </InputAdornment>
                        ),
                        className:
                          searchQuery && searchQuery !== ''
                            ? classes.textInputOnValue
                            : '',
                      }}
                    />
                  </Collapse>
                </Box>
              )}
            </Box>

            {isDesktop && !chaptersMode && (
              <Box flex="30%" textAlign="right" ml={2}>
                <AddNewButton
                  text="Legg til nytt"
                  onClick={onDcumentUpload}
                  flip
                  disabled={!isBoligmappaPlusUser}
                />
              </Box>
            )}
          </Box>

          <Box mt={1}>{renderTable()}</Box>
          {showEmptyListMessage && !personalDocumentfetching && (
            <Box
              onClick={onDcumentUpload}
              className={
                isBoligmappaPlusUser
                  ? classes.documentUploadbox
                  : classes.DisableddocumentUploadbox
              }
            >
              <UploadArrowIcon height="32px" width="32px" />
              <Typography sx={{ textAlign: 'center' }}>
                {isBoligmappaPlusUser
                  ? 'Last opp dokumenter til mappen!'
                  : 'Kjøp et abonnement for å laste opp dine første dokumenter.'}
              </Typography>
            </Box>
          )}

          {/* Mobile only */}

          {!isDesktop && !isBoligmappaPlusUser && renderBoligmappaPlusCTA()}

          <Hidden mdUp>
            {!isDesktop && isBoligmappaPlusUser && (
              <AddContextMenu
                isFromPersonal
                isBoligmappaPlusUser={isBoligmappaPlusUser}
              />
            )}
          </Hidden>
        </Box>
      )}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  fetchingError: sGetFetchingPersonalDocumentsError(state),
  personalDocumentfetching: sGetFetchingPersonalDocuments(state),
  personalDocuments: sGetPersonalDocuments(state),
  searchText: sGetDocumentSearchText(state),
});

const mapDispatchToProps = (dispatch) => ({
  setPersonalDocumentFetchingError: (fetchingError) =>
    dispatch(acSetFetchingPersonalDocumentsError(fetchingError)),
  setDocuments: (documents) => dispatch(acSetDocuments(documents)),
  setPersonalDocuments: (documents) =>
    dispatch(acSetPersonalDocuments(documents)),
  setFetching: (fetching) => dispatch(acSetFetchingDocuments(fetching)),
  setSearchText: (searchText) => dispatch(acSetDocumentSearchText(searchText)),
  setPersonalDocumentFetching: (fetching) =>
    dispatch(acSetFetchingPersonalDocuments(fetching)),
  deletePersonalDocument: (document) =>
    dispatch(acDeletePersonalDocument(document)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MyDocuments));
