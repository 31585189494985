import axios from 'axios';
import pick from 'lodash-es/pick';

/**
 * Taken from API contract described in confluence:
 * Create: https://boligmappa.atlassian.net/wiki/spaces/BMP/pages/757235751/POST+rooms
 * Update: https://boligmappa.atlassian.net/wiki/spaces/BMP/pages/757006420/PATCH+rooms+id
 * @type {string[]}
 */
export const roomFields = [
  'boligmappaNumber',
  'title',
  'roomTypeId',
  'floorNo',
  'description',
  // array values
  'imageIds',
  'eventIds',
  'documentIds',
  'applicationName',
];

export const roomRequestDataTransformer = [
  (data) => pick(data, roomFields),
  ...axios.defaults.transformRequest,
];
