const styles = () => ({
  contextPaper: {
    margin: '0 8px',
    padding: 0,
  },
  contextContent: {
    borderBottom: '1px solid #E6E6E6',
    marginBottom: '32px',
  },
  contextMenuHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '19px 16px',
  },
  contextMenuTitle: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '28px',
    margin: 0,
    color: '#000',
  },
  contextItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '19px 16px',
    color: '#111',
    textDecoration: 'unset',
    columnGap: '8px',

    '& svg, & path': {
      fill: '#111',
    },

    '& p': {
      margin: 0,
    },

    '&.active,&:hover': {
      color: '#164144',

      '& svg, & path': {
        fill: '#164144',
      },
    },
  },
});

export default styles;
