import React from 'react';
import Box from '../../atomic/Box';
import FilterItem from '../FilterItem';
import FilterMenu from '../FilterMenu';
import TextField from '../../atomic/TextField';
import useMenuState from '../../../hooks/useMenuState';
import { getFilterValue, onFilterChange } from './util';

const UploadedDateFilter = ({
  name,
  description,
  filters = [],
  onFiltersChanged,
  isSortable = false,
  isFilterable = false,
  defaultSortUp,
  currentSortingType,
  onSortTypeChange,
  sortUpType,
  sortDownType,
}) => {
  const {
    onMenuOpen,
    onMenuClose,
    open: menuOpen,
    setOpen: setMenuOpen,
  } = useMenuState();

  return (
    <>
      <FilterItem
        name={name}
        onClick={onMenuOpen}
        onSortTypeChange={onSortTypeChange}
        isSortable={isSortable}
        isFilterable={isFilterable}
        sortUpType={sortUpType}
        sortDownType={sortDownType}
        currentSortingType={currentSortingType}
        defaultSortUp={defaultSortUp}
      />

      <FilterMenu
        open={menuOpen}
        title={description}
        setOpen={setMenuOpen}
        onMenuClose={onMenuClose}
      >
        <Box p={2} py={3}>
          <TextField
            type="date"
            margin="normal"
            label="Startdato"
            value={getFilterValue('start', filters)}
            onChange={(e) =>
              onFilterChange(e, 'start', filters, onFiltersChanged)
            }
          />

          <TextField
            type="date"
            margin="normal"
            label="Sluttdato"
            value={getFilterValue('end', filters)}
            onChange={(e) =>
              onFilterChange(e, 'end', filters, onFiltersChanged)
            }
          />
        </Box>
      </FilterMenu>
    </>
  );
};

export default UploadedDateFilter;
