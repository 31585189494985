import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import CompanyProfile from '@vb/components/build/components/features/CompanyProfile';
import useDesktop from '../../../../hooks/useDesktop';
import useFetchEffect from '../../../../hooks/useFetchEffect';
import { fetchCompanyDocuments } from '../../../../api/properties';
import { fetchCompany } from '../../../../api/companies';
import { areIdsEqual } from '../../../../utils/strings';
import { getCompanyId } from '../../../../utils/companies';
import Page from '../../../../components/layouts/WithHeader';
import CompanyDocuments from '../../../../modules/CompanyDocuments';
import Box from '../../../../components/atomic/Box';
import ContactProfessional from '../../../../components/ContactProfessional';
import { sGetCompanies } from '../../../../store/reducers/companies';
import { CompanyProfileLoading } from '../../../../components/CompanyProfileLoading';
import Link from '../../../../components/atomic/Link';
import ArrowBackward from '../../../../assets/icons/ArrowBackward';
import Button from '../../../../components/atomic/Button';
import matomo from '../../../../utils/matomo';

const learnMoreCertificateUrl =
  'https://support.boligmappa.no/hc/no/articles/10689348171154-Om-h%C3%A5ndverkernes-sertifiseringsordninger';

const CompanyProfileCmp = ({ companies }) => {
  const [company, setCompany] = useState(null);
  const [showContactProfDialog, setShowContactProfDialog] = useState(false);
  const { companyId, boligmappaNumber } = useParams();
  const isDesktop = useDesktop();
  const history = useHistory();

  const onBackButtonClick = () => {
    history.goBack();
  };

  const { data: companyDocuments, fetching: fetchingDocuments } =
    useFetchEffect({
      apiFetchFunction: () =>
        fetchCompanyDocuments(boligmappaNumber, companyId, {
          includeTaggedInfo: true,
        }),
    });

  useEffect(() => {
    if (showContactProfDialog) {
      matomo.clickEvent({
        category: 'Find Professionals',
        name: `Contact from Company Profile`,
        action: 'Contact Company',
      });
    }
  }, [showContactProfDialog]);

  const fetchCompanyDetails = async () => {
    const [errorResponse, data] = await fetchCompany(companyId);

    if (!errorResponse) {
      if (data?.features?.[0]) {
        setCompany({
          ...data,
          features: data.features.map((value) => {
            return {
              ...value,
              url:
                value.url.includes('http://') || value.url.includes('https://')
                  ? value.url
                  : `http://${value.url}`,
            };
          }),
        });
      } else {
        setCompany(data);
      }
    }
  };

  const selectedCompany =
    Array.isArray(companies) &&
    companies.find((storedCompany) =>
      areIdsEqual(getCompanyId(storedCompany), companyId)
    );

  useEffect(() => {
    if (selectedCompany) {
      setCompany(selectedCompany);
    } else {
      fetchCompanyDetails();
    }
  }, [companyId]);

  return (
    <Page minFullHeight lessMarginLeft={isDesktop} maxWidth="lg">
      {isDesktop && (
        <Button
          style={{
            backgroundColor: 'transparent',
            fontSize: '14px',
            marginBottom: '24px',
          }}
          startIcon={
            <ArrowBackward
              style={{ minWidth: '14px', maxHeight: '14px', maxWidth: '14px' }}
            />
          }
          onClick={() => onBackButtonClick()}
        >
          Gå tilbake
        </Button>
      )}

      {!company && <CompanyProfileLoading isLoading />}
      {company && (
        <CompanyProfile
          company={company}
          linkComponent={<Link />}
          contactCompany={() => setShowContactProfDialog(true)}
          learnMoreAboutCertificatesURLPath={learnMoreCertificateUrl}
        />
      )}
      <Box mt={8}>
        <CompanyDocuments
          showRoomsColumn
          showEventsColumn
          isDesktop={isDesktop}
          fetching={fetchingDocuments}
          companyDocuments={companyDocuments}
          actionsEnabled={isDesktop}
        />
      </Box>
      <ContactProfessional
        open={showContactProfDialog}
        onClose={() => setShowContactProfDialog(false)}
        company={company}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  companies: sGetCompanies(state),
});

export default connect(mapStateToProps, null)(CompanyProfileCmp);
