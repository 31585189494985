import { useRouteMatch } from 'react-router';
import { getDashboardLinkPrefix } from '../utils/routes';

const useDashboardLinkPrefix = () => {
  const routeMatch = useRouteMatch('/:prefix/:boligmappaNumber');

  const { params } = routeMatch || {};
  const { prefix, boligmappaNumber } = params || {};

  return boligmappaNumber
    ? getDashboardLinkPrefix(boligmappaNumber, prefix)
    : null;
};

export default useDashboardLinkPrefix;
