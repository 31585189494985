const styles = (theme) => ({
  table_col_sub_text: {
    color: theme.palette.primary.greyDark,
    textdecoration: 'none',
  },
  doc_icon_name_wrapper: {
    display: 'flex',
  },
  doc_list_read_only_icon: {
    width: '18px',
    height: '18px',
    margin: '0 10px 0 10px',
  },
});

export default styles;
