const styles = (theme) => ({
  paper: {
    padding: 0,
    overflow: 'auto',
    marginBottom: '60px',
  },
  filters: {
    borderBottom: `1px solid ${theme.palette.primary.greyLighter}`,
  },
  filtersEmpty: {
    borderBottom: 'none',
  },
  emptyList: {
    padding: '40px 0',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  checkbox: {
    padding: '13px',
    margin: 0,
  },
  checkboxChecked: {
    paddingLeft: '15px',
    marginRight: '0',
  },
  editButton: {
    width: '69px',
    height: '32px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    borderRadius: '50px',
    color: '#FFFFFF',
    padding: '8px 16px',
    background: '#164144',
    textDecoration: 'none',

    '&:hover': { backgroundColor: '#164144' },
  },
  docCountText: {
    flex: '1',
    fontWeight: '700',
    fontSize: '14px',
  },
  snackbar: {
    '& #client-snackbar': {
      display: 'flex',
      color: theme.palette.primary.black,
      closeIcon: {
        width: '1rem',
        height: '1rem',
        color: theme.palette.primary.black,
      },
      dismissButton: {
        fontWeignt: 300,
        color: '#111111',
      },
    },
  },
  success: { backgroundColor: '#5DC9A2' },
  filtersBlock: {
    background: '#fff',
    padding: '6px 0',
    transition: '0.5s',
  },
  fixedFilters: {
    position: 'fixed',
    top: 0,
    borderBottom: `1px solid ${theme.palette.primary.greyLighter}`,
    width: '100%',
    zIndex: 9,
  },
  uploadMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FBF3C1 !important',
    padding: '8px 18px',
  },
  uploadMessage: {
    fontSize: '14px',
    lineHeight: '22px',
    marginLeft: '12px',
    flexGrow: 1,
  },
  closeButton: {
    padding: '0 0 0 10px',
  },
  infoIcon: {},
  linkButton: {
    color: 'black',
  },
});

export default styles;
