import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import styles from './style';
import FileForm from './FileForm';
import FileChoice from './FileChoice';
import useSnackbar from '../../../../hooks/useSnackbar';
import { getDocumentId } from '../../../../utils/documents';
import { sGetRooms } from '../../../../store/reducers/rooms';
import Page from '../../../../components/layouts/WithHeader';
import { sGetEvents } from '../../../../store/reducers/events';
import { FileUploadContext } from '../../../../contexts/fileUpload';
import { acAddDocument } from '../../../../store/actions/documents';
import { sGetDocuments } from '../../../../store/reducers/documents';
import { acSyncRoomsDocuments } from '../../../../store/actions/rooms';
import { acSyncEventsDocuments } from '../../../../store/actions/events';
import { sGetActiveProperty } from '../../../../store/reducers/properties';
import useDashboardLinkPrefix from '../../../../hooks/useDashboardLinkPrefix';
import matomo from '../../../../utils/matomo';

const UPLOAD_FILE_MODE = 'UPLOAD_FILE_MODE';
const SELECT_FILES_MODE = 'SELECT_FILES_MODE';

export const AddDocument = ({
  rooms,
  events,
  classes,
  documents,
  addDocument,
  activeProperty,
  syncRoomsDocuments,
  syncEventsDocuments,
  location,
}) => {
  const history = useHistory();
  const { boligmappaNumber } = useParams();
  const { showSuccessMessage } = useSnackbar();
  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const { files, setFiles, onFileUploadCancel, onFileUploadComplete } =
    useContext(FileUploadContext);

  const [mode, setMode] = useState(SELECT_FILES_MODE);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [createdDocuments, setCreatedDocuments] = useState([]);

  const currentFile =
    files && files[currentFileIndex] ? files[currentFileIndex] : null;

  const trackMatomoEvent = (docCount) => {
    matomo.clickEvent({
      category: 'Document handling',
      name: '',
      action: 'Upload Document from Document List',
      value: docCount,
    });
  };

  const redirectToDocuments = (newDocuments) => {
    trackMatomoEvent(newDocuments?.length);
    if (newDocuments.length === 1) {
      const documentId = getDocumentId(newDocuments[0]);

      // Delay redirecting to single document page
      // cause API returns 404 if we fetch document
      // right after its creation
      showSuccessMessage('Dokument lastet opp.');
      setTimeout(() => {
        history.push({
          pathname: `${dashboardLinkPrefix}/documents/${documentId}`,
          state: { newUpload: true },
        });
      }, 500);
    } else if (newDocuments.length === 0) {
      history.goBack();
    } else {
      showSuccessMessage(
        `${newDocuments.length} av ${files.length} dokumenter lastet opp`
      );

      history.goBack();
    }
  };

  const onCancel = () => {
    history.goBack();

    // reset files asynchroniously to prevent
    // showing empty files text on cancel click
    setTimeout(onFileUploadCancel, 100);
  };

  const onUploaded = (document) => {
    // Only add document to store if there are documents loaded.
    // Otherwise created document will be fetched along with all other documents.
    if (documents) {
      addDocument(document);
    }

    if (rooms) {
      const { id: documentId, roomIds } = document;

      syncRoomsDocuments({
        roomIds,
        document,
        documentId,
      });
    }

    if (events) {
      const { id: documentId, eventIds } = document;

      syncEventsDocuments({
        eventIds,
        document,
        documentId,
      });
    }

    setCreatedDocuments([...createdDocuments, document]);

    if (currentFileIndex + 1 !== files.length) {
      setCurrentFileIndex(currentFileIndex + 1);
    } else {
      onFileUploadComplete();

      redirectToDocuments([...createdDocuments, document]);
    }
  };

  const onFileSelected = () => {
    if (location.search.includes('eventIds')) {
      matomo.clickEvent({
        category: 'Events',
        action: 'Add Document to Event',
        name: 'Add New Document from Event',
        value: files?.length,
      });
    }

    window.scrollTo(0, 0);
    setMode(UPLOAD_FILE_MODE);
  };

  const onFileSelect = (selectedFiles) => {
    setFiles(selectedFiles);

    if (selectedFiles.length === 0) {
      history.goBack();
    }
  };

  return (
    <Page minFullHeight headerTitle="Legg til dokument">
      {mode === UPLOAD_FILE_MODE && currentFile && (
        <>
          <small className={classes.currentIndex}>
            <strong>{`${currentFileIndex + 1} AV ${files.length}`}</strong>
          </small>

          <FileForm
            onSkip={onCancel}
            file={currentFile}
            onUploaded={onUploaded}
            fetchingProperty={!activeProperty}
            boligmappaNumber={boligmappaNumber}
          />
        </>
      )}

      {mode === SELECT_FILES_MODE && (
        <FileChoice
          files={files}
          onCancel={onCancel}
          setFiles={onFileSelect}
          onSubmit={onFileSelected}
        />
      )}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  rooms: sGetRooms(state),
  events: sGetEvents(state),
  documents: sGetDocuments(state),
  activeProperty: sGetActiveProperty(state),
});

const mapDispatchToProps = (dispatch) => ({
  addDocument: (document) => dispatch(acAddDocument(document)),
  syncRoomsDocuments: (payload) => dispatch(acSyncRoomsDocuments(payload)),
  syncEventsDocuments: (payload) => dispatch(acSyncEventsDocuments(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddDocument));
