import React from 'react';
import { withStyles } from '@material-ui/core';

import {
  getEventRooms,
  getEventSubType,
  getEventEndDate,
  getEventStartDate,
} from '../../utils/events';

import styles from './style';
import List from '../../components/atomic/List';
import Link from '../../components/atomic/Link';
import { formattedDate } from '../../utils/dates';
import { capitalizeFirst } from '../../utils/strings';
import { getRoomId, getRoomName } from '../../utils/rooms';
import Typography from '../../components/atomic/Typography';
import ListItem from '../../components/CardList/KeyValueListItem';
import ContentLoader from '../../components/loaders/ContentLoader';
import ConnectRoomButton from '../../components/ConnectRoomButton';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';
import matomo from '../../utils/matomo';

const EventDetails = ({
  event,
  classes,
  fetching,
  isHide = false,
  onConnectRoomClick,
}) => {
  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const rooms = getEventRooms(event);

  const dateFormat = 'MMMM, YYYY';

  const onSeeRoom = () => {
    matomo.clickEvent({
      category: 'Events',
      action: 'See Room from Event',
    });
  };

  if (fetching) {
    return (
      <div>
        <Typography variant="h3">
          <ContentLoader height={20} width={100} />
        </Typography>
        <ContentLoader
          width={90}
          height={20}
          className={classes.listItemLoader}
        />
        <ContentLoader
          width={100}
          height={20}
          className={classes.listItemLoader}
        />
        <ContentLoader
          width={50}
          height={20}
          className={classes.listItemLoader}
        />
      </div>
    );
  }

  return (
    <div>
      <Typography variant="h3">Detaljer</Typography>

      <List>
        {getEventSubType(event) !== 'undefined' && (
          <ListItem label="Kategori">{getEventSubType(event)}</ListItem>
        )}

        <ListItem label="Startdato">
          {capitalizeFirst(formattedDate(getEventStartDate(event), dateFormat))}
        </ListItem>

        {getEventEndDate(event) && (
          <ListItem label="Sluttdato">
            {capitalizeFirst(
              formattedDate(getEventEndDate(event), dateFormat)
            ) || 'N/A'}
          </ListItem>
        )}

        <ListItem label="Rom" isHide={isHide}>
          {(!rooms || rooms.length === 0) && onConnectRoomClick && (
            <ConnectRoomButton onClick={onConnectRoomClick} />
          )}

          {rooms &&
            rooms.length > 0 &&
            rooms.map((room) => (
              <Link
                className={classes.listLink}
                key={`rooms-link-${getRoomId(room)}`}
                to={`${dashboardLinkPrefix}/rooms/${getRoomId(room)}`}
                onClick={onSeeRoom}
              >
                {getRoomName(room)}
              </Link>
            ))}
        </ListItem>
      </List>
    </div>
  );
};

export default withStyles(styles)(EventDetails);
