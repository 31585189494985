import React from 'react';

import EventsPage from '../../../../components/EventsPage';

const Events = () => {

  return (
    <EventsPage publicSection displayByYear />
  );
};

export default Events;
