export const getFinancialsPrice = (financials) =>
  financials && financials.propertyOwnershipDetails
    ? financials.propertyOwnershipDetails.price
    : null;

export const getFinancialsTotalLoanAmount = (financials) =>
  financials && financials.loanDetails
    ? financials.loanDetails.totalLoanAmount
    : null;

export const getFinancialsMortgagors = (financials) =>
  financials &&
  financials.propertyOwnershipDetails &&
  financials.propertyOwnershipDetails.propertyOwnership
    ? (financials.propertyOwnershipDetails.propertyOwnership || []).map(
        (record) => record.ownerName
      )
    : null;

export const getPropertyOwnershipDetails = (financials) =>
  financials && financials.propertyOwnershipDetails
    ? financials.propertyOwnershipDetails
    : null;

export const getPropertyOwnerships = (financials) =>
  financials && financials.propertyOwnershipDetails
    ? financials.propertyOwnershipDetails.propertyOwnership
    : null;

export const getOwnershipName = (ownership) =>
  ownership && ownership.ownerName ? ownership.ownerName : null;

export const getOwnershipShare = (ownership) =>
  ownership && ownership.share ? ownership.share : null;

export const getOwnershipSalesPrice = (ownership) =>
  ownership && ownership.salesPrice ? ownership.salesPrice : null;

export const getOwnershipSalesType = (ownership) =>
  ownership && ownership.salesType ? ownership.salesType : null;

export const getOwnershipSalesDate = (ownership) =>
  ownership && ownership.salesDate ? ownership.salesDate : null;

export const getOwnershipUpdatedDate = (ownership) =>
  ownership && ownership.infolandDateUpdatedDate
    ? ownership.infolandDateUpdatedDate
    : null;

export const getOwnershipHistory = (financials) =>
  financials && financials.propertyOwnershipHistory
    ? financials.propertyOwnershipHistory
    : null;

export const getPropertySales = (financials) => {
  const ownershipHistory = getOwnershipHistory(financials);

  return ownershipHistory ? ownershipHistory.propertySales : null;
};

export const getSaleDate = (sale) =>
  sale && sale.salesDate ? sale.salesDate : null;

export const getSaleType = (sale) =>
  sale && sale.salesType ? sale.salesType : null;

export const getSaleBuyer = (sale) => (sale && sale.buyer ? sale.buyer : null);

export const getLoanDetails = (financials) =>
  financials && financials.loanDetails ? financials.loanDetails : null;

export const getPropertyLoans = (financials) => {
  const loanDetails = getLoanDetails(financials);

  return loanDetails && loanDetails.loans ? loanDetails.loans : null;
};

export const getLoanBank = (loan) => (loan && loan.bank ? loan.bank : null);

export const getLoanType = (loan) => (loan && loan.type ? loan.type : null);

export const getLoanAmount = (loan) =>
  loan && loan.amount ? loan.amount : null;
