export const defaultState = {
  wetroomTemplates: null,
  electricalTemplates: null,
  outdoorTemplates: null,
  otherTemplates: null,
  fetchingError: null,
};

export const SET_WETROOM_TEMPLATES = 'SET_WETROOM_TEMPLATES';
export const SET_ELECTRICAL_TEMPLATES = 'SET_ELECTRICAL_TEMPLATES';
export const SET_OUTDOOR_TEMPLATES = 'SET_OUTDOOR_TEMPLATES';
export const SET_OTHER_TEMPLATES = 'SET_OTHER_TEMPLATES';
export const SET_FETCHING_ERROR = 'SET_FETCHING_ERROR';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_WETROOM_TEMPLATES:
      return {
        ...state,
        wetroomTemplates: action.payload,
      };
    case SET_ELECTRICAL_TEMPLATES:
      return {
        ...state,
        electricalTemplates: action.payload,
      };
    case SET_OUTDOOR_TEMPLATES:
      return {
        ...state,
        outdoorTemplates: action.payload,
      };
    case SET_OTHER_TEMPLATES:
      return {
        ...state,
        otherTemplates: action.payload,
      };
    case SET_FETCHING_ERROR:
      return {
        ...state,
        fetchingError: action.payload,
      };
    default:
      return state;
  }
};

export const sGetBYBFEventsTemplteModule = (state) => state.bybfEventTemplates;
export const sGetWetroomTemplates = (state) =>
  sGetBYBFEventsTemplteModule(state).wetroomTemplates;
export const sGetElectricalTemplates = (state) =>
  sGetBYBFEventsTemplteModule(state).electricalTemplates;
export const sGetOutdoorTemplates = (state) =>
  sGetBYBFEventsTemplteModule(state).outdoorTemplates;
export const sGetOtherTemplates = (state) =>
  sGetBYBFEventsTemplteModule(state).otherTemplates;
