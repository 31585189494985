// Add your styles here, and reference them in your component className
import { css } from '@linaria/core';
import { mediaQueries } from '@style/mediaQueries';

export const contactPersonWrapper = css`
    .contact-person-infor{
        display:flex;
        margin-top: 30px;
    }
    .infor-section {
        flex-grow: 1;
        ${mediaQueries.xs} {
            margin-right: 70px;
            flex-grow: 0;
        }
    }
    .image-section{
        width:71px;
        height:71px;
        img {
            width: inherit;
            height: inherit;
            border-radius: 50%;
            border: 1px solid white;
        }
    }
    .contact-person-name {
        font-size:18px;
        font-weight: 700;
    }
    .contact-person-details {
        font-size: 14px;
    }
`;