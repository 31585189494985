import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Button from '../../atomic/Button';
import Typography from '../../atomic/Typography';
import AddIcon from '../../../assets/icons/Add';

const AddNewButton = ({
  classes,
  text,
  colored,
  flip,
  buttonRef,
  ...props
}) => (
  <Button
    ref={buttonRef}
    variant="contained"
    className={classes.root}
    {...props}
  >
    {flip ? (
      <>
        <Typography className={classes.text}>{text}</Typography>
        <AddIcon className={classes.icon} />
      </>
    ) : (
      <>
        <AddIcon className={classes.icon} />
        <Typography className={classes.text}>{text}</Typography>
      </>
    )}
  </Button>
);

export default withStyles(styles)(AddNewButton);
