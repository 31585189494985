import { detect as detectBrowser, detectOS } from 'detect-browser';
import { toLowerCase } from './strings';

export const copyToClipboard = (domElementId) => {
  const div = document.getElementById(domElementId);
  const text = div.innerText;
  const textArea = document.createElement('textarea');

  textArea.width = '1px';
  textArea.height = '1px';
  textArea.background = 'transparent';
  textArea.value = text;

  document.body.append(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
};

export const isBrowserEdge = (includeMobile = true) => {
  const { name: browserName } = detectBrowser();

  return (
    browserName === 'edge' ||
    browserName === 'edge-chromium' ||
    (browserName === 'edge-ios' && includeMobile)
  );
};

export const isIos = () => {
  const userAgentString = navigator.userAgent;
  const name = detectOS(userAgentString);
  return toLowerCase(name) === 'ios';
};

export const isIosPlatform = () => {
  const { name } = detectBrowser();

  return toLowerCase(name) === 'ios';
};

export const isClickEvent = (
  touchStartEvent,
  touchEndEvent,
  threshold = 10
) => {
  if (
    !touchEndEvent ||
    !touchStartEvent ||
    !touchEndEvent.clientX ||
    !touchEndEvent.clientY ||
    !touchStartEvent.clientX ||
    !touchStartEvent.clientY
  ) {
    return false;
  }

  const xDistance = Math.abs(touchStartEvent.clientX - touchEndEvent.clientX);
  const yDistance = Math.abs(touchStartEvent.clientY - touchEndEvent.clientY);

  const totalDistance = Math.sqrt(
    xDistance * xDistance + yDistance * yDistance
  );

  return totalDistance < threshold;
};

export const disableBodyScroll = () => document.body.classList.add('no-scroll');

export const enableBodyScroll = () =>
  document.body.classList.remove('no-scroll');
