import React from 'react';

const ConnectExistingEvent = ({
  width = '1.5rem',
  height = '1.5rem',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 24 29.764"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g data-name="Group 2178" transform="translate(-513.737 7820.959)">
      <g transform="translate(513.737 -7820.959)">
        <g>
          <path
            d="M428.467,1707.3a.5.5,0,0,0-.687,0l-8.032,8.029a2.417,2.417,0,0,0-.713,1.72,2.5,2.5,0,0,0,2.43,2.43,2.418,2.418,0,0,0,1.716-.71l8.032-8.029a.49.49,0,0,0,0-.69Z"
            transform="translate(-419.035 -1698.423)"
          />
          <path
            d="M442.045,1706.015l-1.03-1.03a.973.973,0,0,0-1.375,0,1.01,1.01,0,0,1-1.375,0,.981.981,0,0,1-.218-1.05,1.459,1.459,0,0,0-.326-1.555c-1.392-1.4-1.951-1.964-2.229-2.187h.007a7.531,7.531,0,0,0-9.751-.437.97.97,0,0,0,.621,1.75,4.9,4.9,0,0,1,3.647,1.41c.5.505.757.894.757,1.157a.41.41,0,0,1-.147.3l-.955.952a.49.49,0,0,0,0,.69l2.749,2.741a.478.478,0,0,0,.687,0l1.031-1.03a1.009,1.009,0,0,1,1.374,0,.98.98,0,0,1,0,1.38.968.968,0,0,0,0,1.371l1.031,1.04a1.45,1.45,0,0,0,2.062,0l3.437-3.441a1.456,1.456,0,0,0,0-2.061Z"
            transform="translate(-419.211 -1698.172)"
          />
        </g>
      </g>
      <path
        data-name="iconmonstr-link-1 (2)"
        d="M3.874,5.458a4.017,4.017,0,0,1,6.19.615L8.658,7.478a2.119,2.119,0,0,0-2.4-1.22,2.085,2.085,0,0,0-1.022.562l-2.694,2.7a2.1,2.1,0,0,0,2.967,2.967l.831-.831a5.341,5.341,0,0,0,2.366.36L6.871,13.845A4.025,4.025,0,1,1,1.18,8.153l2.694-2.7ZM8.153,1.179,6.319,3.012a5.342,5.342,0,0,1,2.366.359l.83-.83a2.1,2.1,0,0,1,2.967,2.967L9.787,8.2a2.1,2.1,0,0,1-2.967,0,2.233,2.233,0,0,1-.455-.658L4.959,8.951a3.78,3.78,0,0,0,.5.615,4.021,4.021,0,0,0,5.692,0l2.694-2.695A4.024,4.024,0,1,0,8.153,1.179Z"
        transform="translate(522.714 -7806.219)"
      />
    </g>
  </svg>
);

export default ConnectExistingEvent;
