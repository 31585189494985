import * as React from 'react';

const DocumentDownloadSuccessIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    style={{ marginRight: 10 }}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#111"
      d="M8 .25c1.375 0 2.688.375 3.875 1.063a7.59 7.59 0 0 1 2.813 2.812A7.685 7.685 0 0 1 15.75 8c0 1.406-.375 2.688-1.063 3.875-.687 1.188-1.624 2.156-2.812 2.844A7.656 7.656 0 0 1 8 15.75a7.573 7.573 0 0 1-3.875-1.031 7.758 7.758 0 0 1-2.844-2.844A7.572 7.572 0 0 1 .25 8c0-1.375.344-2.688 1.031-3.875C1.97 2.937 2.938 2 4.125 1.312 5.313.625 6.594.25 8 .25Zm0 1.5a6.01 6.01 0 0 0-3.125.844c-.969.562-1.719 1.344-2.281 2.281-.563.969-.844 2-.844 3.125a6.01 6.01 0 0 0 .844 3.125 6.145 6.145 0 0 0 2.281 2.281c.938.563 2 .844 3.125.844a6.091 6.091 0 0 0 3.125-.844c.938-.562 1.719-1.312 2.281-2.281.563-.938.844-2 .844-3.125a6.091 6.091 0 0 0-.844-3.125 6.64 6.64 0 0 0-2.281-2.281c-.969-.563-2-.844-3.125-.844Zm4.375 4.063c.063.093.125.187.125.28 0 .126-.063.188-.125.25L7 11.689a.382.382 0 0 1-.281.124c-.125 0-.188-.03-.25-.124L3.625 8.844c-.094-.063-.125-.156-.125-.281 0-.094.031-.188.125-.25l.719-.72c.062-.062.125-.093.25-.093.093 0 .187.031.281.094L6.719 9.5l4.437-4.406c.063-.063.125-.094.25-.094.094 0 .188.063.281.125l.688.688Z"
    />
  </svg>
);
export default DocumentDownloadSuccessIcon;
