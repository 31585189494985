const styles = (theme) => ({
  error: {
    color: theme.palette.primary.redDark,
  },
  formElement: {
    marginBottom: '20px',
  },
  formElementEvvents: {
    marginBottom: '51px',
  },
  floorHelperText: {
    lineHeight: '1.1rem',
  },
  roomTypeField: {
    '&:focus': {
      outline: 'none',
    },
  },
  dropdown_support_text: {
    fontSize: '13px',
    fontWeight: 400,
    margin: '5px 0 20px 13px',
  },
  eventImagesBox: {
    marginBottom: '20px',
  },
  roomForm: {
    padding: '20px 43px 50px 43px',
    width: '500px',
    height: 'auto',
    maxHeight: 'calc(100vh - 30vh)',
    overflow: 'auto',
  },
  roomFormMobile: {
    padding: '15px 15px 15px 15px',
    height: 'auto',
    maxHeight: 'calc(100vh - 23vh)',
    overflow: 'auto',
  },
});

export default styles;
