import {
  toLowerCase,
  compareAlphabetically,
  findSubString,
  isNumeric,
  getMaxNumber,
} from './strings';

import { sortImagesByPosition } from './images';

import floors from './floors';

export const getRoomTypeName = (roomType) => (roomType ? roomType.title : null);

export const getRoomName = (room) => (room ? room.name || room.title : null);

export const getRoomType = (room) => (room ? room.roomType : null);

export const getRoomTypeId = (room) => (room ? room.roomTypeId : null);

export const getRoomDescription = (room) => (room ? room.description : null);

export const getRoomFloor = (room) => (room ? room.floorNo : null);

export const getDefaultFloor = (isfloorEnabled) =>
  isfloorEnabled ? '1. etasje' : null;

export const getFloor = (isfloorEnabled) =>
  isfloorEnabled ? { label: '1. etasje', value: '1' } : null;

export const getFloorId = (room) => {
  if (!room) return null;

  const staticFloor = floors.find(({ name }) => name === room.floorNo);
  if (!staticFloor) return null;

  return staticFloor.id;
};

export const getFloorName = (room) => {
  if (!room) return null;

  const staticFloor = floors.find(({ id }) => id === room.floorNo);
  if (!staticFloor) return null;

  return staticFloor.name;
};

export const getRoomEvents = (room) =>
  room && room.events ? room.events : null;

export const getRoomDocuments = (room) =>
  room && room.documents ? room.documents : null;

export const getRoomImages = (room) =>
  room && room.gallery ? room.gallery : null;

export const getRoomImage = (room) => {
  const images = getRoomImages(room);

  if (!Array.isArray(images) || images.length === 0) {
    return null;
  }

  return images.slice().sort(sortImagesByPosition)[0];
};

export const getImageUrl = (image) => (image ? image.url : null);

export const getRoomId = (room) => (room ? room.id : null);

const mapRoomTypeOptions = (roomTypes) =>
  roomTypes.map((roomType) => ({
    value: roomType.id,
    label: getRoomTypeName(roomType),
  }));

export const getRoomTypeOptions = (roomTypes) => {
  if (!Array.isArray(roomTypes)) return [];

  const sortedRoomTypes = roomTypes
    // remove duplicates
    .reduce(
      (result, roomType) =>
        result.find(
          (addedRoomType) =>
            getRoomTypeName(addedRoomType) === getRoomTypeName(roomType)
        )
          ? result
          : [...result, roomType],
      []
    )
    .sort((a, b) =>
      compareAlphabetically(getRoomTypeName(a), getRoomTypeName(b))
    );

  const allRoomTypes = mapRoomTypeOptions(sortedRoomTypes);
  const commonRoomTypes = mapRoomTypeOptions(
    sortedRoomTypes.filter((roomType) => roomType.isCommonType)
  );

  const roomTypeOptions = [
    {
      label: 'Mest brukte',
      options: commonRoomTypes,
    },
    {
      label: 'Alle',
      options: allRoomTypes,
    },
  ];

  return roomTypeOptions;
};

export const getFloorOptions = (_floors) =>
  Array.isArray(_floors)
    ? _floors.map((floor) => ({
        value: floor.id,
        label: floor.name,
      }))
    : [];

// The order is Kjeller → Underetasje → Etasje (H) → Loft → Without floor (null)
export const floorGroups = [
  {
    firstLetter: 'k',
    label: 'kjeller',
    priority: 1,
  },
  {
    firstLetter: 'u',
    label: 'underetasje',
    priority: 2,
  },
  {
    firstLetter: '1',
    label: '1. etasje',
    priority: 3,
  },
  {
    firstLetter: '2',
    label: '2. etasje',
    priority: 4,
  },
  {
    firstLetter: '3',
    label: '3. etasje',
    priority: 5,
  },
  {
    firstLetter: '4',
    label: '4. etasje',
    priority: 6,
  },
  {
    firstLetter: '5',
    label: '5. etasje',
    priority: 7,
  },
  {
    firstLetter: '6',
    label: '6. etasje',
    priority: 8,
  },
  {
    firstLetter: 'o',
    label: 'over 6. etasje',
    priority: 9,
  },
  {
    firstLetter: 'l',
    label: 'loft',
    priority: 10,
  },
  {
    firstLetter: 'n',
    label: '',
    priority: 11,
  },
];

export const sortFloors = (floor1, floor2) => {
  const priorities = floorGroups.reduce(
    (result, group) => ({
      ...result,
      [group.firstLetter]: group.priority,
    }),
    {}
  );

  const floor1Name = toLowerCase(floor1);
  const floor2Name = toLowerCase(floor2);

  const floor1Letter = toLowerCase(String(floor1)).substr(0, 1);
  const floor2Letter = toLowerCase(String(floor2)).substr(0, 1);

  if (priorities[floor1Letter] === priorities[floor2Letter]) {
    const floor1Number = Number(floor1Name.substr(1, floor1Name.length));
    const floor2Number = Number(floor2Name.substr(1, floor1Name.length));

    return floor1Number > floor2Number ? 1 : -1;
  }

  return priorities[floor1Letter] > priorities[floor2Letter] ? 1 : -1;
};

export const getFloorData = (floor) => {
  if (floor === null) {
    return null;
  }
  return floor;
};

export const getRoomsByFloor = (rooms) => {
  if (!Array.isArray(rooms)) {
    return rooms;
  }

  // convert rooms array into hashmap
  // with floor numbers as keys and arrays of rooms as values
  const floorMap = rooms.reduce((result, room) => {
    const floorData = getFloorData(getRoomFloor(room));

    const floorNumberKey = floorData || '1. etasje';

    // If hashmap already contains such key,
    // then append room to an existing array of rooms
    if (result[floorNumberKey]) {
      return {
        ...result,
        [floorNumberKey]: [...result[floorNumberKey], room],
      };
    }

    // Otherwise create object property with floor as key
    // and array with single room as a value
    return { ...result, [floorNumberKey]: [room] };
  }, {});

  return Object.keys(floorMap)
    .sort(sortFloors)
    .map((floor) => ({
      floor,
      rooms: floorMap[floor],
    }));
};

export const getFloorLabel = (floor) => {
  if (floor === null) {
    return null;
  }
  return `${floor}`;
};

export const getFilteredRooms = (rooms, searchQuery) => {
  if (!Array.isArray(rooms) || rooms.length === 0 || !searchQuery) {
    return rooms;
  }

  return rooms.filter((room) =>
    toLowerCase(getRoomName(room)).includes(toLowerCase(searchQuery))
  );
};

export const generateRoomTitle = (rooms, roomType, order, isSingle) => {
  const sameTypeRooms = rooms.filter((room) =>
    findSubString(room.title, roomType)
  );
  if (sameTypeRooms?.length > 0) {
    const numberPartsOfTitles = sameTypeRooms.map((d) => {
      const numberPart = d.title.toString().replace(`${roomType} `, '');
      return isNumeric(numberPart) ? parseInt(numberPart, 10) : 0;
    });
    const nextTitleNumber = getMaxNumber(numberPartsOfTitles);
    const titleNumber =
      nextTitleNumber > 0 ? +nextTitleNumber + +order : order + 1;
    return isSingle && titleNumber === 1
      ? roomType
      : `${roomType} ${titleNumber}`;
  }
  return isSingle ? roomType : `${roomType} ${order}`;
};

export const roomModes = {
  room: 'roomMode',
  document: 'attachDocumentMode',
  event: 'attachEventMode',
};
