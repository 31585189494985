import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import React, { createElement } from 'react';

import styles from './style';
import Link from '../atomic/Link';
import ArrowIcon from '../../assets/icons/ArrowForward';
// import { getDashboardLinkPrefix } from '../../utils/routes';
import PersonalDocLinkImage from '../../assets/images/Personal-Doc-Link.png';

const ListItemCard = ({ classes, link = true }) => {
  const content = (
    <div className={classes.cardLayout}>
      <img
        src={PersonalDocLinkImage}
        alt=""
        style={{ maxWidth: '56px', marginRight: '10px' }}
      />
      <div>
        <p className={classes.itemText} style={{ display: 'inline' }}>
          Personlig område
        </p>
        &nbsp;
        <p className={classes.new_batch} style={{ display: 'inline' }}>
          Nytt!
        </p>
      </div>
      <div style={{ marginLeft: 'auto', verticalAlign: 'center' }}>
        {/*         <span style={{ fontSize: '30px', verticalAlign: 'center' }}>
          &rsaquo;
        </span> */}
        <ArrowIcon className={classes.arrowIcon} />
      </div>
    </div>
  );

  // eslint-disable-next-line react/no-children-prop
  return createElement(link ? Link : 'div', {
    className: clsx(classes.linkContainer),
    to: link ? `/personal-area` : undefined,
    children: content,
  });
};

export default withStyles(styles)(ListItemCard);
