import React from 'react';

const Person = ({
  width = '1.2rem',
  height = '1.2rem',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 14.595 18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      transform="translate(-436.703 -2762.75)"
      d="M444,2771.548a4.412,4.412,0,1,0-4.751-4.4A4.587,4.587,0,0,0,444,2771.548Z"
    />
    <path
      transform="translate(-436.5 -2762.659)"
      d="M443.8,2773.25a7.133,7.133,0,0,0-7.3,6.946.473.473,0,0,0,.486.463h13.622a.473.473,0,0,0,.487-.463,7.133,7.133,0,0,0-7.3-6.946Z"
    />
  </svg>
);

export default Person;
