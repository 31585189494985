import React from 'react';

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import {
  sGetActiveProperty,
  sGetFetchingProperties,
} from '../../../../store/reducers/properties';

import styles from './style';
import Box from '../../../atomic/Box';
import Link from '../../../atomic/Link';
import ContentLoader from '../../../loaders/ContentLoader';
import { getPropertyAddress } from '../../../../utils/properties';
import BoligmappaLogo from '../../../../assets/icons/BoligmappaLogo';
import { acSetActiveProperty } from '../../../../store/actions/properties';
import ArrowDownFilter from '../../../../assets/icons/ArrowDownFilter';

const PropertySelect = ({
  classes,
  fetching,
  fetchingError,
  activeProperty,
  publicMode = false,
  isPersonalArea = false,
}) => {
  const renderPropSelectContent = () => (
    <Box display="flex" alignItems="center">
      <>
        <div className={classes.logo}>
          {isPersonalArea ? (
            <BoligmappaLogo className={classes.logoImage} />
          ) : (
            <>
              {fetching ? (
                <ContentLoader width={30} height={30} variant="circle" />
              ) : (
                <BoligmappaLogo className={classes.logoImage} />
              )}
            </>
          )}
        </div>
        <div className={classes.selectContainer}>
          {isPersonalArea ? (
            <>
              <ArrowDownFilter width={9} height={6} />
              <small className={classes.selectedPropertyAddress}>
                Personlig område
              </small>
            </>
          ) : (
            <>
              {fetching ? (
                <ContentLoader height={20} width={160} />
              ) : (
                <>
                  {!publicMode && <ArrowDownFilter width={9} height={6} />}
                  <small className={classes.selectedPropertyAddress}>
                    {getPropertyAddress(activeProperty)}
                  </small>
                </>
              )}
            </>
          )}
        </div>
      </>
    </Box>
  );

  if (fetchingError) {
    return null;
  }

  if (publicMode) return renderPropSelectContent();

  return (
    <Link to="/properties" underline={false}>
      {renderPropSelectContent()}
    </Link>
  );
};

const mapStateToProps = (state) => ({
  fetching: sGetFetchingProperties(state),
  activeProperty: sGetActiveProperty(state),
});

const mapDispatchToProps = (dispatch) => ({
  setActiveProperty: (property) => dispatch(acSetActiveProperty(property)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PropertySelect));
