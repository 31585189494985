import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import useSnackbar from '../../../../../../hooks/useSnackbar';
import EventForm from '../../../../../../components/forms/EventForm';
import { updateEvent as apiUpdateEvent } from '../../../../../../api/events';
import {
  sGetEventMode,
  sGetShowEditEvent,
} from '../../../../../../store/reducers/events';
import { acSyncDocumentsEvents } from '../../../../../../store/actions/documents';
import { sGetDocuments } from '../../../../../../store/reducers/documents';
import {
  acSetEventMode,
  acSetShowEditEvent,
} from '../../../../../../store/actions/events';
import { eventModes } from '../../../../../../utils/events';
import useActionDialog from '../../../../../../hooks/useActionDialog';
import matomo from '../../../../../../utils/matomo';

const Edit = ({
  event,
  fetching,
  fetchingError,
  onEventUpdated,
  showEditEvent,
  setShowEditEvent,
  eventMode,
  setEventMode,
  documents,
  syncDocumentEvents,
  currentDocuments,
}) => {
  const { showSuccessMessage } = useSnackbar();
  const { eventId, boligmappaNumber } = useParams();

  const [error, setError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const { showActionDialog } = useActionDialog();

  const attachDocumentMode = eventMode === eventModes.document;

  const resetDialog = () => {
    setError(null);
    setShowEditEvent(false);
    setEventMode(eventModes.event);
  };

  const onClose = (closeCallback, showActionStatus = false) => {
    if (showActionStatus) {
      resetDialog();
      closeCallback();
      return;
    }
    showActionDialog({
      title: 'Hendelsen er ikke lagret',
      showCancelButton: false,
      submitButtonText: 'Avbryt',
      submitButtonVariant: 'contained',
      message: 'Er du sikker på at du vil avbryte?',
      onSubmit: () => {
        resetDialog();
        closeCallback();
      },
    });
  };

  const setTitle = () => {
    switch (eventMode) {
      case eventModes.document:
        return 'Koble til dokumenter';
      case eventModes.room:
        return 'Koble til rom';
      default:
        return 'Redigere hendelse';
    }
  };

  const onFormSubmit = async (data, submitCallback) => {
    setError(null);
    setRequestInProgress(true);

    if (attachDocumentMode) {
      matomo.clickEvent({
        category: 'Events',
        action: 'Add Document to Event',
        name: 'Add Existing Document from Event',
        value: Math.abs(data.documents.length - currentDocuments?.length),
      });
    }

    const [requestError, response] = await apiUpdateEvent(eventId, data);

    if (!response && requestError) {
      setError(requestError);
    } else {
      const { documentIds } = data;
      // eslint-disable-next-line no-shadow
      const { id: eventId } = data;
      onEventUpdated({
        ...event,
        ...data,
      });

      if (documents) {
        syncDocumentEvents({
          eventId,
          documentIds,
          event: {
            ...event,
            ...data,
          },
        });
      }

      onClose(submitCallback, true);
      showSuccessMessage();
    }

    setRequestInProgress(false);
  };

  return (
    <EventForm
      open={showEditEvent}
      onClose={onClose}
      onSubmit={onFormSubmit}
      requestInProgress={requestInProgress}
      error={error}
      header={setTitle}
      boligmappaNumber={boligmappaNumber}
      event={event}
      fetching={fetching}
      fetchingError={fetchingError}
    />
  );
};

const mapStateToProps = (state) => ({
  showEditEvent: sGetShowEditEvent(state),
  eventMode: sGetEventMode(state),
  documents: sGetDocuments(state),
});

const mapDispatchToProps = (dispatch) => ({
  setShowEditEvent: (payload) => dispatch(acSetShowEditEvent(payload)),
  setEventMode: (payload) => dispatch(acSetEventMode(payload)),
  syncDocumentEvents: (payload) => dispatch(acSyncDocumentsEvents(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
