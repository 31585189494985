import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import BaseListItem from '@material-ui/core/ListItem';

import styles from './style';

const ListItem = ({ label, classes, children, isHide }) => (
  <BaseListItem
    className={
      isHide && label === 'Rom' ? classes.listItemDisable : classes.listItem
    }
  >
    <div className={classes.label}>{label}</div>
    <div className={clsx(classes.value, 'word-break')}>{children}</div>
  </BaseListItem>
);

export default withStyles(styles)(ListItem);
