import matomo from './matomo';
import localStorage from './localStorage';
import { SNACK_BAR_CLOSED_SESSION_ID } from '../constants';

export const closeSnackBarWithUpdate = (
  updateMatomo,
  matomoName,
  matomoValue,
  sid,
  closeSnackbar
) => {
  if (updateMatomo) {
    matomo.clickEvent({
      category: 'Login',
      name: matomoName,
      action: 'click-event',
      value: matomoValue,
    });
  }
  localStorage.set(SNACK_BAR_CLOSED_SESSION_ID, sid);
  closeSnackbar();
};
