import { colorVariables } from '@style/vars/colors';
import { baseColors } from '../base/baseTheme';

export const colors = {
  black: '#13142A',
  darkGray: '#26273B',
  white: '#FFF',
  transparent: 'transparent',
  transparentGrey: '#EFEFEF',
  softPink: '#FFEADD',
  redSoft: '#FF787A',
  redWarning: '#D7394C',
  redAlert: '#FFEFEF',
  lightMint: '#D1EDE8',
  green: '#31777B',
  greenBright: '#5DC9A2',
  darkGreen: '#286367',
  darkestGreen: '#254E52',
  purple: {
    100: '#F0F0FF',
    200: '#D4D5FC',
    300: '#8D84F2',
    400: '#5B53B5',
    500: '#231C70',
  },
  gray: {
    100: '#F7F8FD',
    200: '#F0F1F8',
    300: '#DDE2F1',
    400: '#BBBCD0',
    500: '#73748F',
    700: '#313245',
    900: '#333448',
  },
  brandBackgroundColor: '#FD6764',
  yellowDark: '#D8E827',
};

export const boligmappaColors: typeof colorVariables = {
  ...baseColors,
  // background
  brandBackgroundColor: colors.brandBackgroundColor,
  transparent: colors.transparent,
  backgroundColor: colors.white,
  backgroundLightGrayColor: colors.gray[100],
  backgroundLightColor: colors.white,
  backgroundGrayColor: colors.gray[400],
  backgroundDarkColor: colors.black,
  // icon
  iconBaseColor: colors.black,
  // buttons
  button: {
    filled: {
      background_hover: colors.purple[500],
      background_active: colors.purple[400],
    },
    background_active: 'transparent',
  },
  radio: {
    square_background: colors.purple[100],
  },
  slider: {
    track: colors.gray[300],
    trackFilled: colors.purple[400],
    handleLines: colors.gray[700],
  },
  switch: {
    background: colors.gray[400],
    background_hover: colors.gray[500],
  },
  link: {
    hover: colors.purple[200],
    text: colors.gray[300],
  },
  // pagination
  paginationButton: {
    background: colors.white,
    backgroundActive: colors.purple[100],
  },
  fileUpload: {
    deleteBackground: colors.softPink,
    background: colors.gray[100],
    control: colors.lightMint,
    border: colors.gray[300],
    progress: colors.darkestGreen,
    done: colors.greenBright,
    preview: colors.gray[300],
    filesUploaded: colors.gray[500],
    removeButton: colors.black,
  },
  textColor: colors.black,
  textLight: colors.white,
  textDark: colors.darkGray,
  textColor_disabled: colors.gray[500],
  textSecondary: colors.gray[500],
  textGreenBright: colors.greenBright,
  textGrayColor: colors.gray[300],
  textError: colors.redWarning,
  badgeColor: colors.yellowDark,
  dotColor: colors.black,
  disabledBackground: colors.gray[300],
  disabledBackgroundDark: colors.gray[400],
  darkColor: colors.black,
  lightColor: colors.white,
  // select
  select: {
    background: colors.softPink,
    hoverBg: colors.darkGreen,
    selectedBg: colors.green,
  },
  // tags
  tags: {
    hover: colors.brandBackgroundColor,
    background: colors.softPink,
    temporary: colors.transparentGrey,
  },
  dotColorWarning: colors.redSoft,
  dotColorSuccess: colors.greenBright,
  dotColorInfo: colors.purple[400],
  dotColorNeutral: colors.gray[500],
  tagColorPrimary: colors.purple[100],
  tagColorSecondary: colors.gray[100],
  tagColorLight: colors.white,
  tagColorDark: colors.black,
  // dropdownMenu
  dropdownMenu: {
    background: colors.white,
    color: colors.black,
    backgroundHover: colors.gray[200],
    border: colors.gray[300],
  },
  map: {
    zoom: {
      dashColor: colors.gray[200],
    },
  },
  loader: {
    mainColor: colors.brandBackgroundColor,
  },
  // alert
  alert: {
    error: colors.redAlert,
    info: colors.purple[100],
    infoDark: colors.purple[500],
  },
  input: {
    default: colors.gray[900],
    error: colors.redWarning,
  },
};
