import { BodySize, FontSize, LinkSize, Size } from '@style/vars/typography';

export const bodySize: Record<BodySize, FontSize> = {
  large: {
    fontSize: '1.125rem',
    lineHeight: '28px',
    fontWeight: '400',
  },
  medium: {
    fontSize: '1rem',
    lineHeight: '28px',
    fontWeight: '400',
  },
  body: {
    fontSize: '0.875rem',
    lineHeight: '24px',
    fontWeight: '400',
  },
  small: {
    fontSize: '0.75rem',
    lineHeight: '22px',
    fontWeight: '400',
  },
  tiny: {
    fontSize: '0.625rem',
    lineHeight: '18px',
    fontWeight: '400',
  },
  kicker: {
    fontSize: '0.75rem',
    lineHeight: '20px',
    fontWeight: '400',
  },
  special_1: {
    fontSize: '1.75rem',
    lineHeight: '40px',
    fontSizeMobile: '1.5rem',
    lineHeightMobile: '32px',
    fontWeight: '500',
  },
  special_2: {
    fontSize: '4.1875rem',
    lineHeight: '88px',
    fontSizeMobile: '2.375rem',
    lineHeightMobile: '49px',
    fontWeight: '500',
  },
};

export const linkSize: Record<LinkSize, FontSize> = {
  big: {
    fontSize: '1rem',
    lineHeight: '28px',
    fontWeight: '600',
  },
  default: {
    fontSize: '0.875rem',
    lineHeight: '24px',
    fontWeight: '400',
  },
};

export const titleSize: Record<Size, FontSize> = {
  xxl: {
    fontSize: '4rem',
    lineHeight: '86px',
    fontSizeMobile: '2.375rem',
    lineHeightMobile: '49px',
  },
  xl: {
    fontSize: '3.1875rem',
    lineHeight: '66px',
    fontSizeMobile: '1.75rem',
    lineHeightMobile: '37px',
  },
  large: {
    fontSize: '2.5rem',
    lineHeight: '52px',
    fontSizeMobile: '1.5rem',
    lineHeightMobile: '36px',
  },
  medium: {
    fontSize: '1.75rem',
    lineHeight: '37px',
    fontSizeMobile: '1.25rem',
    lineHeightMobile: '28px',
  },
  small: {
    fontSize: '1.25rem',
    lineHeight: '28px',
    fontSizeMobile: '1.125rem',
    lineHeightMobile: '24px',
  },
  xs: {
    fontSize: '1rem',
    lineHeight: '28px',
  },
  xxs: {
    fontSize: '0.875rem',
    lineHeight: '19px',
  },
};

export const generalFonts = {
  family: 'Sofia Pro',
  size: {
    xxxxs: '.625rem',
    xxxs: '.75rem',
    xxs: '.875rem',
    xs: '1rem',
    s: '1.125rem',
    m: '1.25rem',
    l: '1.5rem',
    xl: '2rem',
    xxl: '3rem',
    xxxl: '3.5rem',
  },
  weight: {
    light: '400',
    normal: '500',
    bold: '600',
  },
  lineHeight: {
    xxxs:'18px',
    xxs:'20px',
    xs:'24px',
    s: '28px',
    m: '32px',
    l: '36px',
    xl: '52px',
    xxl: '66px',
    xxxl: '86px',
  },
};