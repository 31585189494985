const styles = (theme) => ({
  eventAndRoomCard: {
    padding: '3px 8px',
    whiteSpace: 'nowrap',
    background: '#FAFAFA',
    borderRadius: ' 4px',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  moreEventAndRoomCard: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    marginLeft: '8px',
    background: '#F2F8F8',
  },
  tableCell: {
    padding: '12px',
    paddingLeft: 'unset',
    color: theme.palette.primary.greyBlack,
  },
  avsenderCell: {
    textDecoration: 'underline',
    maxWidth: '300px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingLeft: '8.5px',
  },
  rowHover: {
    transition: '.3s',

    '&.draggedOver': {
      backgroundColor: '#EEEEEE',
    },

    '&:hover:not(.siblingDragged)': {
      backgroundColor: '#EEEEEE',
    },

    '&.dragged': {
      opacity: '.3',
    },
  },
  titleRow: {
    '& .hoverIn': {
      display: 'none',
    },

    '&:hover': {
      '& .hoverIn': {
        display: 'block',
      },
      '& .hoverOut': {
        display: 'none',
      },
    },
  },
  folderCheckbox: {
    pointerEvents: 'none',
    opacity: 0,
  },
  dragElement: {
    display: 'none',
    alignItems: 'center',
    columnGap: '5px',
    padding: '5px',
    position: 'absolute',
    top: '-9999px',
    left: '-9999px',
    backgroundColor: '#f4f4f4',
    borderRadius: '4px',

    '& > p': {
      margin: 0,
    },

    '& .hoverIn': {
      display: 'none',
    },

    '&.dragged': {
      display: 'flex',
    },
  },
  imageIconBox: {
    width: '32px',
    minWidth: '32px',
    height: '32px',
    background: '#F2F8F8',
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.docx,&.doc': {
      background: '#EAEEF5',
    },
    '&.xls,&.xlsx': {
      background: '#E8F1ED',
    },
    '&.pdf': {
      background: '#FDEDEB',
    },
    '&.mp4,&.avi,&.mpg,&.mov,&.flv': {
      background: '#E8F3F9',
    },
  },
  cellMinWidth100: {
    minWidth: '100px',
  },
  singleCheckBox: {
    marginRight: 0,
  },
  thumbnailImageIcon: {
    width: '32px',
    height: '32px',
    objectFit: 'cover',
    borderRadius: '3px',
  },
  displayFlex: {
    display: 'flex',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  marginLeft9: {
    marginLeft: '9px',
  },
  moreIcon: {
    color: theme.palette.primary.darkBlack,
    height: '22px',
    width: '22px',
  },
  tableCellDocName: {
    paddingLeft: 0,
    minWidth: '270px',
    maxWidth: '270px',
  },
  lockForDocView: {
    position: 'relative',
    top: 4,
    marginLeft: 5,
  },
  builidingDocViewContainer: {
    marginTop: '-5px',
  },
  lockForDocViewContainer: {
    marginTop: 4,
  },
  dateCell: {
    fontSize: '13px',
    fontWeight: 400,
    color: '#717171',
    paddingRight: 0,
  },
  typeCell: {
    paddingLeft: '8.5px',
  },
  combinedWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  combinedBodyCell: {
    maxWidth: '270px',
    color: theme.palette.primary.darkBlack,
  },
  optionsCell: {
    width: '48px',
    padding: 0,
    margin: 0,
  },
  docNameLink: {
    marginLeft: '10px',
  },
  docNameText: {
    fontSize: '14px',
    fontFamily: 'inherit',
    lineHeight: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textDecoration: 'unset',
    color: 'inherit',
    backgroundColor: 'unset',
    padding: 'unset',
    border: 'unset',
    cursor: 'pointer',
  },
  imageIcon: {
    width: '16px',
    height: '16px',
  },
});

export default styles;
