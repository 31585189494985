import React from 'react';
import { withStyles } from '@material-ui/core';
import Card from '../atomic/Card';
import Grid from '../atomic/Grid';
import Image from '../Image/index';
import Typography from '../atomic/Typography';
import ProgressButton from '../ProgressButton';
import VirdiMapImage from '../../assets/images/virdiMapCTA.png';
import Box from '../atomic/Box';
import styles from './styles';

const CTAcard = ({ classes, title, description, buttonText, onClick }) => {
  return (
    <Box className={classes.boxClass}>
      <Card className={classes.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.textArea}>
            <Grid item xs={12}>
              <Typography mt={0} variant="h2" className={classes.title}>
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={9} lg={9} className={classes.textArea}>
            <Grid item xs={12}>
              <Typography className={classes.textArea}>
                {description}
              </Typography>
            </Grid>
          </Grid>

          <Grid item sm={3} lg={3} className={classes.imageItem}>
            <Image className={classes.image} fallbackSrc={VirdiMapImage} />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ProgressButton
              requestInProgress={false}
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={onClick}
            >
              <span>{buttonText}</span>
            </ProgressButton>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default withStyles(styles)(CTAcard);
