const styles = () => ({
  dialogBox: {
    textAlign: 'center',
  },
  dialogDesktop: {
    minWidth: '500px !important',
    maxWidth: '500px !important',
    padding: '32px 16px !important',
  },
  dialogMobile: {
    padding: '32px 16px !important',
  },
  header: {
    fontWeight: '700',
    fontSize: '20px',
    marginBottom: '10px',
  },
  text: {
    fontWeight: '400',
    fontSize: '14px',
  },
  hintText: {
    fontWeight: '400',
    fontSize: '14px',
    color: '#717171',
  },
  saveButton: {
    margin: '10px 0px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    borderRadius: '50px',
    color: '#FFFFFF',
    padding: '8px 16px',
    background: '#164144',
    textDecoration: 'none',
    '&:hover': { backgroundColor: '#164144' },
  },
  dismissButton: {
    color: '#000000',
    fontSize: '16px',
    fontWeight: 400,
  },
});

export default styles;
