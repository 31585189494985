import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';

import {
  Magnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from 'react-image-magnifiers';
import useDesktop from '@hooksV2/useDesktop';
import PdfViewerBase from '@vb/components/build/components/molecules/PdfViewerBase/PdfViewerBase';
import styles from './style';

// required to configure zoom controls provided by file-viewer
import './styles.scss';
import ErrorBoundary from '../ErrorBoundary';
import { toLowerCase } from '../../utils/strings';
import { logLevelWarning } from '../../utils/loggers';
import matomo from '../../utils/matomo';
import ShowErrorMessage from '../ShowErrorMessage';
import { getOriginalDocumentPath } from '../../utils/documents';
import Plus from '../../assets/icons/raw/plus';
import Minus from '../../assets/icons/raw/minus';

const imageFileTypes = [
  'png',
  'jpg',
  'jpeg',
  'gif',
  'webp',
  'svg',
  'bmp',
  'ico',
];
const videoFileTypes = [
  'mp4',
  'webm',
  'ogg',
  'avi',
  'flv',
  'mkv',
  'mov',
  'wmv',
];

const FileViewer = ({
  documentData,
  classes,
  fileType,
  filePath,
  className,
  isConvertedFile,
}) => {
  const zoomLevels = [
    0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3,
    4, 5,
  ];
  const isDesktop = useDesktop();
  const [zoomLevelIndex, setZoomLevelIndex] = useState(isDesktop ? 7 : 2);
  const containerRef = useRef(null);
  const onZoomClick = () => {
    matomo.clickEvent({
      category: 'Document View',
      name: 'Zoom Document',
      action: 'Read Document',
    });
  };

  const onZoomIn = () => {
    if (zoomLevelIndex < zoomLevels.length - 1) {
      setZoomLevelIndex(zoomLevelIndex + 1);
    }
  };
  const onZoomOut = () => {
    if (zoomLevelIndex > 0) {
      setZoomLevelIndex(zoomLevelIndex - 1);
    }
  };

  if (imageFileTypes.includes(toLowerCase(fileType))) {
    return (
      <div className="image">
        <Magnifier
          className="input-position"
          imageSrc={getOriginalDocumentPath(documentData)}
          largeImageSrc={getOriginalDocumentPath(documentData)}
          mouseActivation={MOUSE_ACTIVATION.CLICK}
          touchActivation={TOUCH_ACTIVATION.TAP}
          dragToMove
          onClick={onZoomClick}
        />
      </div>
    );
  }
  if (videoFileTypes.includes(toLowerCase(fileType))) {
    return (
      <div className={classes.videoWrapper}>
        <video className={classes.videoPlayer} controls>
          <track default kind="captions" />
          <source src={filePath} type="video/mp4" />
        </video>
      </div>
    );
  }
  if (fileType === 'pdf' || isConvertedFile) {
    return (
      <ErrorBoundary onError={() => null} logLevel={logLevelWarning}>
        <div
          className={clsx(
            className,
            classes.container,
            !isDesktop && 'mobile',
            'document-preview'
          )}
        >
          <div className={classes.previewContainer} ref={containerRef}>
            <PdfViewerBase
              className={classes.pdfViewer}
              containerRef={containerRef}
              file={filePath}
              width={600}
              useInfiniteScroll
              scale={zoomLevels[zoomLevelIndex]}
            />
          </div>
          <div className={classes.previewZoomButtons}>
            <button
              type="button"
              aria-label="zoom in"
              onClick={() => onZoomIn()}
            >
              <Plus />
            </button>
            <button
              type="button"
              aria-label="zoom out"
              onClick={() => onZoomOut()}
            >
              <Minus />
            </button>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
  return <ShowErrorMessage documentOriginalPath={filePath} />;
};

export default withStyles(styles)(FileViewer);
