import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import React, { createElement, useState } from 'react';

import {
  getPropertyZip,
  isPropertyOwned,
  getPropertyAddress,
  getPropertyImagePath,
  getPropertyUnitNumber,
  getPropertyPostalPlace,
  getPropertyBoligmappaNumber,
  getPropertyGoogleStreetImage,
  getPropertyGoogleStreetViewPhotoEnabled,
} from '../../utils/properties';

import styles from './style';
import Image from '../Image';
import Link from '../../components/atomic/Link';
import Card from '../../components/atomic/Card';
import Typography from '../../components/atomic/Typography';
import BallIcon from '../../assets/icons/Ball';
import ArrowIcon from '../../assets/icons/ArrowForward';
import { getDashboardLinkPrefix } from '../../utils/routes';
import { capitalizeFirst, truncate } from '../../utils/strings';
import PropertyImagePlaceholder from '../../components/PropertyImagePlaceholder';
import BrokenPropertyImage from '../../assets/images/brokenPropertyImage.png';

export const PropertyCard = ({
  theme,
  classes,
  property,
  className,
  link = true,
  onCard = true,
  showArrow = true,
  ...props
}) => {
  const [imageLoadingFailed, setImageLoadingFailed] = useState(false);

  const zip = getPropertyZip(property);
  const owned = isPropertyOwned(property);
  const address = getPropertyAddress(property);
  const postalPlace = getPropertyPostalPlace(property);
  const boligmappaNumber = getPropertyBoligmappaNumber(property);
  const zipCode = zip && zip !== '0000' ? zip : null;

  const propertyImageSrc = getPropertyImagePath(property);
  const propertyGoogleStreetImageSrc = getPropertyGoogleStreetImage(property);

  const googleSteetImageEnabled =
    getPropertyGoogleStreetViewPhotoEnabled(boligmappaNumber);

  const getBackgroundColor = () => {
    if (imageLoadingFailed) {
      return owned ? theme.palette.primary.red : theme.palette.primary.blue;
    }

    return undefined;
  };

  const content = (
    <>
      <div className={classes.imageContainer}>
        {propertyImageSrc && (
          <Image
            src={propertyImageSrc}
            className={classes.image}
            fallbackSrc={BrokenPropertyImage}
            alt={getPropertyAddress(property)}
            onError={() => setImageLoadingFailed(true)}
            style={{
              backgroundColor: getBackgroundColor(),
              borderRadius: 4,
            }}
          />
        )}

        {!propertyImageSrc && googleSteetImageEnabled && (
          <img
            className={classes.image}
            src={propertyGoogleStreetImageSrc}
            alt={getPropertyAddress(property)}
          />
        )}

        {!propertyImageSrc && !googleSteetImageEnabled && (
          <PropertyImagePlaceholder
            borderRadius="4px"
            width="56px"
            height="56px"
            margin="20px 10px 16px 16px"
          />
        )}
      </div>

      <div className={clsx('word-break', classes.textContainer)}>
        <Typography
          variant="h4"
          component="h4"
          color="textPrimary"
          style={{ margin: '5px 40px' }}
        >
          {truncate(address, { length: 30 })}
        </Typography>

        <Typography
          variant="inherit"
          component="small"
          color="textSecondary"
          className={classes.details}
        >
          {zipCode}
          {zipCode && postalPlace && ', '}
          {postalPlace && capitalizeFirst(postalPlace)}

          {(zipCode || postalPlace) && (
            <>
              &nbsp;
              <BallIcon
                style={{
                  color: theme.palette.text.secondary,
                }}
                className={classes.ballIcon}
              />
              &nbsp;
            </>
          )}
          {getPropertyUnitNumber(property)}

          {(zipCode || postalPlace || getPropertyUnitNumber(property)) && (
            <>
              &nbsp;
              <BallIcon
                style={{
                  color: theme.palette.text.secondary,
                }}
                className={classes.ballIcon}
              />
              &nbsp;
            </>
          )}
          {getPropertyBoligmappaNumber(property)}
        </Typography>
      </div>

      <div className={classes.actionsContainer}>
        {showArrow && <ArrowIcon className={classes.arrowIcon} />}
      </div>
    </>
  );

  const wrappedContent = onCard ? (
    <Card elevation={2} className={clsx(classes.card, className)} {...props}>
      {content}
    </Card>
  ) : (
    <div className={clsx(classes.card, className)} {...props}>
      {content}
    </div>
  );

  // eslint-disable-next-line react/no-children-prop
  return createElement(link ? Link : 'div', {
    className: clsx(classes.linkContainer, classes.link),
    to: link ? `${getDashboardLinkPrefix(boligmappaNumber)}/home` : undefined,
    children: wrappedContent,
  });
};

export default withStyles(styles, { withTheme: true })(PropertyCard);
