import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from '../style';
import Box from '../../../../../../../components/atomic/Box';
import { ValueEstimateConsent } from './ValueEstimateConsent/ValueEstimateConsent';
import { EstimateNumber } from './EstimateNumber/EstimateNumber';
import { getBogusValue } from '../../../../../../../utils/numbers';

export const propertyEstimateKey = 'propertyEstimate';
export const propertyPriceWidgetId = 'property-price-widget';
export const propertyPriceLinkId = 'property-price-external-link';

const PropertyEstimate = ({ classes, data, hideValues }) => {
  return (
    <Box className={classes.container}>
      <EstimateNumber
        valueEstimate={
          hideValues ? getBogusValue(data.valueestimate) : data.valueestimate
        }
      />

      <ValueEstimateConsent />
    </Box>
  );
};

export default withStyles(styles)(PropertyEstimate);
