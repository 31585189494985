const styles = () => ({
  seeMoreLink: {
    color: '#164144',
    lineHeight: '24px',
    fontWeight: '400',
    fontSize: '12px',
    '@media only screen and (max-width: 340px)': {
      fontSize: '12px',
    },
    removeMarginOnNative: {
      marginLeft: '-50px',
    },
  },
  text: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  energyMarkText: {
    fontSize: 'smaller',
    fontWeight: 400,
    marginTop: '-10px',
  },
});

export default styles;
