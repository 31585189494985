export const defaultState = {
  companies: null,
  fetching: false,
  fetchingError: null,
};

export const SET_COMPANIES = 'SET_COMPANIES';
export const CLEAR_COMPANIES = 'CLEAR_COMPANIES';
export const SET_FETCHING_COMPANIES = 'SET_FETCHING_COMPANIES';
export const SET_FETCHING_COMPANIES_ERROR = 'SET_FETCHING_COMPANIES_ERROR';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };
    case CLEAR_COMPANIES:
      return defaultState;
    case SET_FETCHING_COMPANIES:
      return {
        ...state,
        fetching: action.payload,
      };
    case SET_FETCHING_COMPANIES_ERROR:
      return {
        ...state,
        fetchingError: action.payload,
      };
    default:
      return state;
  }
};

export const sGetCompaniesModule = (state) => state.companies;
export const sGetCompanies = (state) => sGetCompaniesModule(state).companies;

export const sGetFetchingCompanies = (state) =>
  sGetCompaniesModule(state).fetching;

export const sGetFetchingCompaniesError = (state) =>
  sGetCompaniesModule(state).fetchingError;

export const sGetCompaniesLoaded = (state) => !!sGetCompanies(state);
