import React from 'react';
import PageLayout from '../../../../../components/layouts/WithHeader';
import useDesktop from '../../../../../hooks/useDesktop';

const Addons = () => {
  const isDesktop = useDesktop();
  return (
    <>
      <PageLayout
        minFullHeight
        lessMarginLeft={isDesktop}
        maxWidth="lg"
        headerTitle="Ad-ons"
        noPaddingTop
      />
    </>
  );
};

export default Addons;
