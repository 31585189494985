// Add your styles here, and reference them in your component className
import { css } from '@linaria/core';
import { hjemlaColors } from '@style/themes/hjemla/colors';
import { fonts, colors, shadows } from '@style/vars';

export const Select = css`
  position: relative;

  .hide {
    display: none !important;
  }

  .input-r-content {
    pointer-events: none;
  }

  .selectList {
    position: absolute;
    max-height: 300px;
    width: 100%;
    top: 100%;
    padding: 0px;
    margin: auto;
    margin-top: 6px;
    background-color: ${colors.backgroundLightGrayColor};
    border-radius: 4px;
    overflow-x: hidden;
    font-size: ${fonts.size.xxs};
    box-shadow: ${shadows.select};

    > li {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 12px 16px;
      margin-right: 10px;
      background-color: ${hjemlaColors.white};
      cursor: pointer;

      &:focus,
      &:hover,
      &.focused {
        background-color: ${hjemlaColors.gray[200]};
        color: ${hjemlaColors.black};
      }
    }

    &-hidden {
      visibility: hidden;
    }
  }

  &.error {
    .selectList {
      top: calc(100% - 24px);
    }
  }

  .selectGroupLabel {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 12px 16px;
    margin-right: 10px;
    background-color: ${hjemlaColors.white};
    font-size: ${fonts.size.xxxs};
    font-weight: ${fonts.weight.light};
  }

  .selectSeparator {
    padding-left: 12px;
    border-bottom: 1.5px solid ${hjemlaColors.gray[200]};
  }

  .input-wrapper.default {
    padding: 0;

    > input {
      padding: 10.5px 16px;
      z-index: 1;
      cursor: pointer;
    }

    > svg {
      position: absolute;
      right: 16px;
      z-index: 0;
    }
  }
`;
