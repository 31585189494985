import React from 'react';

const RadonAreaLevelUnknown = (props) => (
  <svg
    width={33}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.22 5.147 2.927 24a2.667 2.667 0 0 0 2.28 4h22.586a2.667 2.667 0 0 0 2.28-4L18.78 5.147a2.666 2.666 0 0 0-4.56 0v0ZM16.5 12v5.333M16.5 21.333V22"
      stroke="#BE1E14"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RadonAreaLevelUnknown;
