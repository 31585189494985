export const getJobYearDoneOptions = (moreThanFiveYearsLabel) => {
  const currentYear = new Date().getFullYear();
  const yearDoneOptions = [];

  for (let i = 0; i < 5; i += 1) {
    yearDoneOptions.push({
      value: currentYear - i,
      label: currentYear - i,
    });
  }
  yearDoneOptions.push({
    value: '5+',
    label: moreThanFiveYearsLabel,
  });

  return yearDoneOptions;
};

export const transformJobDoneDate = (jobYearDone) => {
  if (jobYearDone === '5+') {
    return new Date(new Date().getFullYear() - 6, 0, 2);
  }
  return new Date(jobYearDone, 0, 2);
};

export const transformToMoreThanFiveYears = (jobYearDone, label) => {
  const moreThanFive = new Date();
  moreThanFive.setFullYear(moreThanFive.getFullYear() - 5);
  if (new Date(jobYearDone) < moreThanFive) {
    return label;
  }
  return new Date(jobYearDone).getFullYear();
};

export const JobStates = {
  Pending: 'Pending',
  // add more if needed in the future
};
