const styles = (theme) => ({
  buttonContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
  },
  ballIcon: {
    width: '12px',
    height: '12px',
    color: theme.palette.primary.red,
    marginRight: '5px',
  },
  greenBallIcon: {
    width: '12px',
    height: '12px',
    color: theme.palette.primary.green,
    marginRight: '5px',
  },
  addressText: {
    color: '#254E52',
    fontSize: '14px',
    textAlign: 'left',
    fontWeight: '700',
    FontFace: 'Sofia Pro, Semi Bold',
  },
  adState: {
    fontSize: '13px',
    fontWeight: '500',
  },
  textArea: {
    fontSize: '13px',
    fontWeight: '500',
  },
  valueDataArea: {
    fontSize: '13px',
    fontWeight: '500',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  button: {
    '@media only screen and (min-width: 768px) and (max-width: 1023px)': {
      width: '65%',
    },
    width: '90%',
    border: '1px solid',
    fontSize: '13px',
    fontWeight: '200',
  },
  buttonIcon: {
    marginLeft: '16px',
  },
  addressArea: {
    padding: '16px 16px 0 16px!important',
    minHeight: '60px',
  },
  adjustPadding: {
    '@media only screen and (min-width: 768px) and (max-width: 1023px)': {
      padding: '0 16px!important',
    },
    padding: '16px 16px 0 16px!important',
    height: '128px',
  },
  tagArea: {
    padding: '16px 16px 0 0!important',
    minHeight: '60px',
    textAlign: 'right',
  },
});

export default styles;
