const styles = () => ({
  roomList: {
    display: 'flex',
    flexFlow: 'wrap',
  },
  roomListMobile: {
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'space-between',
  },
});

export default styles;
