import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import useDesktop from '../../../../../../../hooks/useDesktop';
import ImageIcon from '../../../../../../../assets/icons/Image';
import Paper from '../../../../../../../components/atomic/Paper';
import ActionList from '../../../../../../../components/ActionList';
import DocumentIcon from '../../../../../../../assets/icons/Document';
import { getEventId, getEventImages } from '../../../../../../../utils/events';
import { uploadImage as apiUploadImage } from '../../../../../../../api/events';
import UploadImageButton from '../../../../../../../components/forms/ImageForm/UploadImageButton';
// import matomo from '../../../../../../../utils/matomo';

const EventActions = ({
  event,
  classes,
  onImagesUploaded,
  onDocumentIconClick,
}) => {
  const isDesktop = useDesktop();
  const [imageRef, setImageRef] = useState(null);
  const openImageUploadDialog = () => imageRef.click();

  const existingImages = getEventImages(event);
  const lastImagePosition = Array.isArray(existingImages)
    ? existingImages.length
    : 0;

  const uploadImageRequest = (image, position) =>
    apiUploadImage(getEventId(event), image, position);

  const actions = [
    {
      icon: DocumentIcon,
      text: 'Dokument',
      onClick: onDocumentIconClick,
    },
    {
      icon: ImageIcon,
      text: 'Bilde',
      onClick: openImageUploadDialog,
    },
  ];

  return (
    <Paper visible={!isDesktop}>
      <strong className={classes.title}>LEGG TIL I HENDELSEN</strong>

      <UploadImageButton
        showButton={false}
        imageRef={imageRef}
        setImageRef={setImageRef}
        onImagesUploaded={onImagesUploaded}
        lastImagePosition={lastImagePosition}
        uploadImageRequest={uploadImageRequest}
      />

      <ActionList actions={actions} />
    </Paper>
  );
};

export default withStyles(styles)(EventActions);
