import React from 'react';

const CloseCustomGray = ({ width = '1.2rem', height = '1.2rem', ...props }) => (
  <svg
    fill="#000"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    {...props}
  >
    <circle
      id="Ellipse_29"
      data-name="Ellipse 29"
      cx="9"
      cy="9"
      r="9"
      opacity="0.289"
    />
    <line
      id="Line_39"
      data-name="Line 39"
      x1="7.999"
      y2="7.999"
      transform="translate(5.124 5.125)"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <line
      id="Line_40"
      data-name="Line 40"
      x1="7.999"
      y1="7.999"
      transform="translate(5.124 5.125)"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export default CloseCustomGray;
