import React, { useState, useEffect } from 'react';
import Box from '../../../../atomic/Box';
import Typography from '../../../../atomic/Typography';
import { useFindProfessionalsStyles } from '../../../findProfessionalStyles';
import useDesktop from '../../../../../hooks/useDesktop';
import Autocomplete from '../../../../Autocomplete';
import Grid from '../../../../atomic/Grid';
import { getPropertyOptions } from '../../../../../utils/findProfessional';
import { lookupLocations as apiLookupLocations } from '../../../../../api/location';
import SearchIcon from '../../../../../assets/icons/Search';

const LocationFilter = ({
  propertyLocations = [],
  selectedOption,
  onSelect = () => {},
  backOnSelect = () => {},
  reset = false,
}) => {
  const onItemSelectStyles = {
    borderColor: 'black',
    borderWidth: '2px',
    background: '#D9E9E9',
    boxShadow: 'none',
    ':hover': {
      borderColor: 'black !important',
    },
  };

  const ItemSelectStyles = {
    borderColor: 'hsl(0,0%,80%)',
    borderWidth: '1px',
    boxShadow: 'none',
    ':active': {
      border: '2px solid black  !important',
    },
    ':hover': {
      border: '2px solid black  !important',
    },
    ':focus': {
      border: '2px solid black  !important',
    },
  };
  const classes = useFindProfessionalsStyles();
  const isDesktop = useDesktop();

  const [streetName, setStreetName] = useState('');
  const [locations, setLocations] = useState([]);
  const [fetchingLocations, setFetchingLocations] = useState(false);

  const [locationSelect, setLocationSelect] = useState(
    selectedOption
      ? {
          label: selectedOption.label,
          value: selectedOption.value,
        }
      : null
  );
  const autoSlectStyles = {
    control: (base, state) => ({
      ...base,
      ...(state.hasValue ? onItemSelectStyles : ItemSelectStyles),
    }),
  };

  const autoSlectStylesMobile = {
    control: (base, state) => ({
      ...base,
      ...(state.hasValue ? onItemSelectStyles : ItemSelectStyles),
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: '445px',
      border: 'none',
    }),
    menu: (base) => ({
      ...base,
      boxShadow: 'none',
    }),
  };

  useEffect(() => {
    if (reset) {
      setLocationSelect(null);
    }
  }, [reset]);

  const getLocationData = async (street) => {
    setFetchingLocations(true);
    const [errorResponse, data] = await apiLookupLocations({ keyword: street });
    if (!errorResponse) {
      const locationOptions = (Array.isArray(data) ? data : []).map(
        (locationData) => ({
          label: locationData.verbose,
          value: `${locationData.lat},${locationData.lng}`,
        })
      );
      setLocations(locationOptions);
      setFetchingLocations(false);
    }
  };

  const onStreetNameChange = (text, { action }) => {
    const approvedActions = ['set-value', 'input-change'];
    if (approvedActions.includes(action)) {
      setStreetName(text);
    }
  };

  const onLocationSelect = (selectedLocation) => {
    if (selectedLocation) {
      setLocationSelect({
        label: 'Sted: '.concat(selectedLocation.label),
        value: selectedLocation.value,
      });
      backOnSelect();
    } else {
      setLocationSelect(null);
    }
  };

  useEffect(() => {
    onSelect(locationSelect);
  }, [locationSelect]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (streetName.length > 0) {
        getLocationData(streetName);
      }
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [streetName]);

  return (
    <Box sx={{ flexGrow: 1 }} p={2}>
      {isDesktop && (
        <Typography
          className={
            isDesktop
              ? classes.distancefilterheading
              : classes.distancefilterheadingMobile
          }
        >
          Din posisjon
        </Typography>
      )}
      <Grid container spacing={1} columns={{ xs: 1, sm: 8 }}>
        <Grid item xs={12} sm={12} md={6}>
          <br />
          <Autocomplete
            inputValue={streetName}
            value={locationSelect}
            onChange={onLocationSelect}
            options={
              streetName.length > 0
                ? getPropertyOptions(locations, propertyLocations)
                : getPropertyOptions([], propertyLocations)
            }
            onInputChange={onStreetNameChange}
            isLoading={fetchingLocations}
            loadingMessage={() => 'Laster...'}
            noOptionsMessage={() => 'Ingen resultater'}
            placeholder={
              <Box display="flex">
                <SearchIcon fontSize={isDesktop ? 'medium' : 'small'} /> Søk på
                adresse
              </Box>
            }
            styles={isDesktop ? autoSlectStyles : autoSlectStylesMobile}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LocationFilter;
