import React from 'react';

const Login = ({
  width = '1.2rem',
  height = '1.2rem',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 24.995 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      transform="translate(-466.528 -513.801)"
      d="M489.98,524.284h-8.74V520.54a1.012,1.012,0,0,0-.583-.912,1.043,1.043,0,0,0-1.088.122l-6.684,5.262a1,1,0,0,0,0,1.58l6.684,5.262a1.064,1.064,0,0,0,1.088.122,1.011,1.011,0,0,0,.583-.913v-3.744h8.74a1.518,1.518,0,1,0,0-3.036Z"
    />
    <path
      transform="translate(-467.003 -513.959)"
      d="M490.4,529.546a1.06,1.06,0,0,0-1.378.5,10.538,10.538,0,0,1-11.677,5.691,9.921,9.921,0,0,1-.049-19.551A10.543,10.543,0,0,1,489,521.823a1.039,1.039,0,0,0,.849.59A1.06,1.06,0,0,0,490.8,522a.965.965,0,0,0,.094-1,12.65,12.65,0,0,0-14.048-6.76,11.9,11.9,0,0,0,.065,23.46,12.646,12.646,0,0,0,14.01-6.831A.983.983,0,0,0,490.4,529.546Z"
    />
  </svg>
);

export default Login;
