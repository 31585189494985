import { useEffect } from 'react';
import { fetchPriceStatsFromApi } from '../properties';
import { useApiFetch } from './useApiFetch';

export function useFetchPriceStats(property) {
  const companiesApi = useApiFetch(fetchPriceStatsFromApi);

  useEffect(() => {
    if (!property) return;
    if (!property.boligmappaNumber) return;

    const { boligmappaNumber } = property;
    companiesApi.executeFetch(boligmappaNumber);
  }, [property?.boligmappaNumber]);

  return {
    data: companiesApi.data,
    error: companiesApi.error,
    executeFetch: companiesApi.executeFetch,
    isLoading: companiesApi.isLoading,
  };
}
