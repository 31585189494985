import axios from 'axios';
import pick from 'lodash-es/pick';

/**
 * Taken from API contract described in confluence:
 * Create: https://boligmappa.atlassian.net/wiki/spaces/BMP/pages/753762363/POST+documents
 * Update: https://boligmappa.atlassian.net/wiki/spaces/BMP/pages/757301313/PATCH+documents+id
 * @type {string[]}
 */
export const documentFields = [
  'title',
  'boligmappaNumber',
  'jobDate',
  'fileName',
  'directoryId',
  'description',
  'professionid',
  'documentTypeId',
  'applicationName',
  // array values
  'roomIds',
  'eventIds',
  'chapterTagIds',
];

export const documentRequestDataTransformer = [
  (data) => pick(data, documentFields),
  ...axios.defaults.transformRequest,
];
