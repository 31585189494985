import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Box from '../../atomic/Box';
import Grid from '../../atomic/Grid';
import { useFindProfessionalsStyles } from '../findProfessionalStyles';
import ProfessionalListItem from './ProfessionalItem';
import ProfessionalListLoading from './Loading';

const ProfessionalList = ({
  professionals,
  setFetchStatus,
  metaData,
  setSelectedCompany,
}) => {
  const classes = useFindProfessionalsStyles();
  const [professionalsData, setProfessionalsData] = useState([]);

  useEffect(() => {
    setProfessionalsData(professionals);
  }, [professionals]);

  const fetchMore = () => setFetchStatus(true);

  return (
    <>
      <InfiniteScroll
        dataLength={(professionalsData || []).length}
        next={fetchMore}
        hasMore={metaData.totalCount > metaData.page * metaData.pageSize}
        endMessage={<ProfessionalListLoading metaData={metaData} />}
        loader={<ProfessionalListLoading metaData={metaData} />}
        className={classes.infiniteScrollWrapper}
      >
        <div className={classes.totalItemCount}>
          {metaData?.totalCount} håndverksbedrifter
        </div>
        <Box padding={3}>
          <Grid container spacing={3}>
            {(professionalsData || []).map((company) => (
              <Grid
                item
                xs={12}
                md={6}
                key={`professional-grid-item-${company?.id}`}
              >
                <ProfessionalListItem
                  key={`professional-item-${company?.id}`}
                  company={company}
                  setSelectedCompany={setSelectedCompany}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </InfiniteScroll>
    </>
  );
};

export default ProfessionalList;
