import {
  CLEAR_FINANCIALS,
  SET_FINANCIALS,
  SET_FETCHING_FINANCIALS,
  SET_FETCHING_FINANCIALS_ERROR,
} from '../reducers/financials';

export const acSetFinancials = (financials) => ({
  type: SET_FINANCIALS,
  payload: financials,
});

export const acClearFinancials = () => ({
  type: CLEAR_FINANCIALS,
});

export const acSetFetchingFinancials = (fetching) => ({
  type: SET_FETCHING_FINANCIALS,
  payload: fetching,
});

export const acSetFetchingFinancialsError = (fetchingError) => ({
  type: SET_FETCHING_FINANCIALS_ERROR,
  payload: fetchingError,
});
