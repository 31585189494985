import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocumentsFromApi } from '../properties';
import {
  getDefaultDocumentSortType,
  getProfessionalDocuments,
  getByDocumentsCreatedDate,
} from '../../utils/documents';
import { acSetDocuments } from '../../store/actions/documents';
import { useApiFetch } from './useApiFetch';
import { storeReferralSuggested } from '../../utils/referral';
import { referralSuggestedDays } from '../../config';

const { sortFunction } = getDefaultDocumentSortType();

export function useFetchDocuments(property) {
  const documentsApi = useApiFetch(fetchDocumentsFromApi);

  const dispatch = useDispatch();

  // Gets the documents from the global store. Im not sure if this
  // is the best way of doing it, since it seems like the global store
  // gets reassigned on the different pages.
  const documents = useSelector((state) => state.documents.documents);

  const triggerRefferal = (docsArray) => {
    if (docsArray) {
      const professionalDocuments = getProfessionalDocuments(docsArray);
      if (
        professionalDocuments !== 'undefined' &&
        professionalDocuments.length !== 0
      ) {
        const ninetyDaysBack = new Date();
        ninetyDaysBack.setDate(
          ninetyDaysBack.getDate() - referralSuggestedDays
        );
        const documentList = getByDocumentsCreatedDate(
          professionalDocuments,
          ninetyDaysBack
        );
        if (documentList !== 'undefined' && documentList.length !== 0) {
          storeReferralSuggested(false);
        }
      }
    }
  };

  useEffect(() => {
    if (!property) return;
    if (!property.boligmappaNumber) return;

    const { boligmappaNumber } = property;
    documentsApi.executeFetch(boligmappaNumber, {
      includeTaggedInfo: true,
    });
  }, [property?.boligmappaNumber]);

  useEffect(() => {
    // Sets the documents in the global store
    dispatch(acSetDocuments(documentsApi.data));
    triggerRefferal(documentsApi.data);
  }, [documentsApi.data]);

  /**
   * Gets the first five documents sorted on "UPDATED_DATE_DESC".
   * It is used in the overview page. It might be an idea
   * to implement a limit in the backend endpoint so we actually
   * just fetch five documents instead of fetching all at once.
   */
  const docsArray = documentsApi.data || [];
  const firstFiveDocuments = docsArray.sort(sortFunction).slice(0, 5);

  return {
    fetch: documentsApi.executeFetch,
    data: documents,
    isLoading: documentsApi.isLoading,
    error: documentsApi.error,
    firstFiveDocuments,
  };
}
