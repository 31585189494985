const style = () => ({
  rowBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogContent: {
    '&.mobile': {
      padding: '20px',
    },
  },
  dialogDescription: {
    '&.mobile': {
      fontSize: '14px',
    },
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
  },
  dialogButton: {
    minWidth: 'unset',
    padding: '12px 24px',
    fontSize: '14px',
    lineHeight: '16px',
    height: '40px',
    '&.mobile': {
      padding: '5px 10px',
      fontSize: '14px',
    },
  },
  permitCheckbox: {
    margin: '20px 0 10px',
    '&.mobile': {
      margin: 0,
    },
  },
  permitHeadline: {
    margin: '0 0 20px',
    '&.mobile': {
      fontSize: '20px',
      color: 'black',
    },
  },
  qwe: {
    backgroundColor: '#000!important',
    display: 'none!important',
  },
  rejectButton: {
    marginLeft: '24px',
    padding: 'unset',
    fontWeight: 500,
    textDecoration: 'unset',
    borderBottom: '1.5px solid #BBBCD0',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'unset',
      textDecoration: 'unset',
    },
    '&.mobile': {
      fontSize: '14px',
      marginLeft: '14px',
    },
  },
  checkboxLabel: {
    fontWeight: 500,
    fontSize: '16px',
    '&.mobile': {
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
});

export default style;
