import loadImage from 'blueimp-load-image';

/**
 * Transform file size into human readable format
 * @param fileSize in bytes
 */
export const getReadableSize = (fileSize) => {
  if (fileSize > 1000000000) {
    return `${(fileSize / 1000000000).toFixed(2).toLocaleString()} gb`;
  }

  if (fileSize > 1000000) {
    return `${Math.round(fileSize / 1000000).toLocaleString()} mb`;
  }

  if (fileSize > 1000) {
    return `${Math.round(fileSize / 1000).toLocaleString()} kb`;
  }

  return `${fileSize.toLocaleString()} bytes`;
};

export const blueImpSupportedFileTypes = [
  'jpg',
  'jpeg',
  'png',
  'bmp',
  'x-icon',
  'svg+xml',
  'gif',
  'webp',
];

export const inputImagesAccept = blueImpSupportedFileTypes.map(
  (fileType) => `image/${fileType}`
);

export const supportedFileTypeCheck = (file) =>
  file.type &&
  blueImpSupportedFileTypes.includes(file.type.replace('image/', ''));

export const imageToBase64 = (file) =>
  new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const { result } = reader;
        const fileType = file.name.toLowerCase().split('.').pop();
        const returnValue = result.replace(
          'data:application/octet-stream',
          `data:image/${fileType}`
        );
        resolve(returnValue);
      };
    } catch (error) {
      reject(error);
    }
  })
    .then((base64) => [null, base64])
    .catch((error) => [error, null]);

export const rotateImage = (file) =>
  new Promise((resolve, reject) => {
    loadImage(
      file,
      (canvas, error) => {
        if (canvas.type === 'error') {
          reject(error);
          return;
        }

        if (canvas && canvas.toBlob) {
          canvas.toBlob((blob) => {
            resolve(new File([blob], 'image.png'));
          });
          return;
        }

        reject(error);
      },
      {
        maxWidth: 1536,
        orientation: 1,
      }
    );
  });

export const fileNameToFileExtension = (fileName) =>
  fileName.split('.').pop().toLowerCase();

// Function to convert Base64 to a Blob
const base64ToBlob = (base64String) => {
  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: 'application/pdf' });
};

// Convert Base64 to File object
export const base64ToFile = (base64String) => {
  const blob = base64ToBlob(base64String);

  const currentDate = new Date();
  const dateValue = currentDate.toISOString().split('T')[0];
  const filename = `scan_${dateValue}.pdf`;
  return new File([blob], filename, { type: 'application/pdf' });
};
