import React, { useRef } from 'react';
import ListHeader from './ListHeader';
import useOutsideAlerter from '../../../hooks/useOutsideAlerter';
import ListBody from './ListBody';

const DocumentTypeOptions = ({
  menuOpen,
  description,
  setMenuOpen,
  onMenuClose,
  anchorEl,
  editOptions,
  onFiltersReset,
  onMenuSave,
  selectedDocuments,
  onSelectOption,
  selectedOptions,
  enableSearch,
  ...props
}) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onMenuClose);
  return (
    <>
      <ListHeader
        open={menuOpen}
        title={description}
        setOpen={setMenuOpen}
        onMenuClose={onMenuClose}
        anchorEl={anchorEl}
        selectedDocuments={selectedDocuments}
        {...props}
      >
        <ListBody
          onReset={onFiltersReset}
          editOptions={editOptions}
          onMenuClose={onMenuClose}
          onMenuSave={onMenuSave}
          onSelectOption={onSelectOption}
          selectedOptions={selectedOptions}
          enableSearch={enableSearch}
        />
      </ListHeader>
    </>
  );
};
export default DocumentTypeOptions;
