import React from 'react';

const VerdiEconomy = ({
  width = '16',
  height = '16',
  fill = '#717171',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    fill={fill}
    {...props}
  >
    <path d="M48 56c0-13.3-10.7-24-24-24S0 42.7 0 56V408c0 39.8 32.2 72 72 72H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H72c-13.3 0-24-10.7-24-24V56zm272 96c0 13.3 10.7 24 24 24h54.1L304 270.1l-79-79c-9.4-9.4-24.6-9.4-33.9 0l-80 80c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l63-63 79 79c9.4 9.4 24.6 9.4 33.9 0l111-111V264c0 13.3 10.7 24 24 24s24-10.7 24-24V152c0-13.3-10.7-24-24-24H344c-13.3 0-24 10.7-24 24z" />
  </svg>
);

export default VerdiEconomy;
