const styles = (theme) => ({
  error: {
    color: theme.palette.primary.redDark,
  },
  labelsContainer: {
    marginBottom: '10px',
  },
  labelContainer: {
    paddingLeft: '4px',
  },
  datesContainer: {
    justifyContent: 'space-between',
  },
  dateLabel: {
    marginBottom: '-10px',
  },
  toLabelContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(1),
  },
  datePickerContainer: {
    alignItems: 'center',
  },
  formElement: {
    marginBottom: '20px',

    '&:last-child': {
      marginBottom: 0,
    },
  },
});

export default styles;
