const styles = {
  button: {
    minWidth: '200px',
  },
  icon: {
    top: '4px',
    marginRight: '4px',
    position: 'relative',
  },
};

export default styles;
