import Hammer from '../../../assets/images/hammer.png';

const styles = (theme) => ({
  card: {
    height: '120px',
    boxShadow: '0 3px 4px rgba(0,0,0,0.1)',
    display: 'flex',
    marginBottom: '15px',
    '&:last-child': {
      marginBottom: '30px',
    },
    position: 'relative',
    borderRadius: '20px',
    border: '1px solid',
    borderColor: theme.colors.mint,
  },
  imageContainer: {
    display: 'flex',
    marginRight: '26px',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  image: {
    width: '115px',
    height: '100%',
    objectFit: 'cover',
    backgroundColor: theme.palette.primary.whiteDarker,
  },
  textContainer: {
    display: 'flex',
    padding: '20px 0',
    flexDirection: 'column',
    justifyContent: 'start',
    maxWidth: '776px',
    height: '100%',
  },
  cardContent: {
    display: 'flex',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  uncheckedIcon: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: theme.colors.mint,
  },
  checkIcon: {
    width: '30px',
    height: '30px',
    color: theme.palette.primary.main,
  },
  eventIcon: {
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    border: '2px solid',
    borderColor: theme.palette.primary.white,
    background: `${theme.colors.mint} url(${Hammer}) no-repeat center`,
    position: 'absolute',
    bottom: 0,
    left: '98px',
    zIndex: 1,
  },
  date: {
    bottom: '2px',
    position: 'relative',
    fontWeight: 400,
    fontSize: '11px',
    color: theme.colors.greyLighter,
    marginTop: '6px',
  },
});

export default styles;
