import React from 'react';
import { useMediaQuery, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import useDesktop from '../../../../hooks/useDesktop';
import { fetchDocuments as apiFetchDocuments } from '../../../../api/properties';
import styles from './style';
import Typography from '../../../../components/atomic/Typography';
import Divider from '../../../../components/atomic/Divider';
import Box from '../../../../components/atomic/Box';
import Grid from '../../../../components/atomic/Grid';
import Card from '../../../../components/Card';
import PropertySalesFeatures from '../../../../modules/PropertySalesFeatures';
import PropertySalesLawInfoCard from '../../../../components/PropertySalesLawInfoCard';
import PageLayout from '../../../../components/layouts/WithHeader';
import SalesInfoImage1 from '../../../../assets/images/mann-kontorpult.png';
import SalesInfoImage2 from '../../../../assets/images/hus_hvitt.png';
import SalesInfoImage3 from '../../../../assets/images/SalesLawImage.png';
import { useActiveProperty } from '../../../../store/hooks';
import useFetchEffect from '../../../../hooks/useFetchEffect';

import {
  sGetDocuments,
  sGetFetchingDocuments,
} from '../../../../store/reducers/documents';
import {
  acSetDocuments,
  acSetFetchingDocuments,
} from '../../../../store/actions/documents';

const PropertySalesLaw = ({
  classes,
  documents,
  setDocuments,
  setFetchingDocuments,
  fetchingDocuments,
}) => {
  const isDesktop = useDesktop();
  const isTabMode = useMediaQuery(`(min-width: 760px)`);
  const { property } = useActiveProperty();
  const { boligmappaNumber } = useParams();
  const HomeSectionUrl = `/dashboard/${boligmappaNumber}/home`;

  const backButtonAction = () => {
    window.location = HomeSectionUrl;
  };

  useFetchEffect({
    onFetchStart: () => setDocuments(null),
    onSuccess: (data) => setDocuments(data),
    setFetchingFunction: setFetchingDocuments,
    apiFetchFunction: () =>
      apiFetchDocuments(boligmappaNumber, { includeTaggedInfo: true }),
    conditionFunction: () => !documents && !fetchingDocuments,
  });

  return (
    <PageLayout
      minFullHeight
      hideHeader={isDesktop}
      lessMarginLeft={isDesktop}
      maxWidth="lg"
      headerTitle={!isDesktop ? 'Ny boligsalgslov' : ''}
      backButtonAction={backButtonAction}
    >
      <div>
        {isDesktop && (
          <Typography variant="h1">
            Ny boligsalgslov - hva betyr det for deg?
          </Typography>
        )}

        <Box>
          {/* eslint-disable no-nested-ternary */}
          <p
            className={
              isDesktop
                ? classes.introContent
                : isTabMode
                ? classes.introTabContent
                : classes.introMobileContent
            }
          >
            1. januar 2022 trådte ny lov om kjøp og salg av eiendom i kraft, og
            den vil påvirke alle boligeiere. Intensjonen er å skape en tryggere
            bolighandel med færre konflikter. Vi har samlet en rekke av våre
            tjenester på et sted, som vil hjelpe deg med å få orden på boligens
            verdipapirer.
          </p>
          {/* eslint-enable no-nested-ternary */}
        </Box>

        <Box
          className={
            isDesktop ? classes.salesFeatures : classes.salesFeaturesMobile
          }
        >
          <PropertySalesFeatures isPropertyOwned={property?.owned} />
        </Box>
        {!isDesktop && <Divider className={classes.divider} />}
        <Box
          className={isDesktop ? classes.salesInfo : classes.salesMobileInfo}
        >
          <Grid container spacing={4}>
            <Grid item xs={isDesktop ? 6 : 12} md={isDesktop ? 6 : 12}>
              <Card border={1} padding={3} height="100%">
                <PropertySalesLawInfoCard
                  cardTitle="Det blir lovpålagt å kontrollere dokumentasjon for utførte arbeider"
                  cardMobileTutle="Det blir lovpålagt å kontrollere dokumnetasjon"
                  infoText="På samme måte som el-tilsynet er pålagt å kontrollere dokumentasjon i Boligmappa, må den bygningssakkyndige kontrollere dokumentasjonen for arbeid utført av håndverkere."
                  cardImage={SalesInfoImage1}
                />
              </Card>
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12} md={isDesktop ? 6 : 12}>
              <Card border={1} padding={3} height="100%">
                <PropertySalesLawInfoCard
                  cardTitle="Boligmappa følger eiendommen over tid - automagisk!"
                  cardMobileTutle="Boligmappa følger eiendommen over tid - automagisk!"
                  infoText="Dette betyr at ved en eiendomsovertakelse vil tilgangen automatisk overtas av ny tinglyst eier. Det er alltid den til enhver tid rettmessige eieren som har tilgang til boligmappen. Dette skjer automatisk via synkronisering mot eiendomsregisteret i Norge."
                  cardImage={SalesInfoImage2}
                />
              </Card>
            </Grid>

            <Grid item xs={12} md={12}>
              <Card border={1} padding={3}>
                <PropertySalesLawInfoCard
                  cardTitle="Smart boligselgerforsikring i samarbeid med Boligmappa"
                  cardMobileTutle="Smart boligselgerforsikring"
                  infoText="Flere meglere, slik som Eiendomsmegler 1 og DNB Eiendom tilbyr digitalt egenerklæringsskjema. 
                        Her er Boligmappa integrert, slik at du kan knytte opp feks. dokumentasjon fra rørleggeren under punktet for bad/våtrom. Det gir tryggere interessenter og budgivere."
                  cardImage={SalesInfoImage3}
                />
              </Card>
            </Grid>
          </Grid>
        </Box>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  fetchingDocuments: sGetFetchingDocuments(state),
  documents: sGetDocuments(state),
});

const mapDispatchToProps = (dispatch) => ({
  setFetchingDocuments: (fetching) =>
    dispatch(acSetFetchingDocuments(fetching)),
  setDocuments: (documents) => dispatch(acSetDocuments(documents)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PropertySalesLaw));
