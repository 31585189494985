export const imageIconStyles = {
  width: '38px',
  height: '38px',
  objectFit: 'cover',
  borderRadius: '3px',
};

const styles = (theme) => ({
  link: {
    color: 'inherit',
    display: 'block',
    textDecoration: 'none',
  },
  listItem: ({ showRoomsColumn, showEventsColumn }) => ({
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    alignItems: showRoomsColumn || showEventsColumn ? 'flex-start' : 'center',
  }),
  borderedItem: {
    borderBottom: `1px solid ${theme.colors.whiteDarker}`,
  },
  menuIcon: {
    color: theme.palette.primary.blackLight,
  },
  imageIcon: {
    ...imageIconStyles,
  },
  iconContainer: ({ showRoomsColumn, showEventsColumn }) => ({
    minWidth: 38,
    marginRight: 10,
    textAlign: 'center',
    marginTop: showRoomsColumn || showEventsColumn ? theme.spacing(1) : 0,
  }),
  secondaryText: {
    display: 'flex',
    fontSize: '12px',
    alignItems: 'center',
  },
  secondaryAction: ({ showRoomsColumn, showEventsColumn }) => ({
    top: 0,
    right: 0,
    transform: 'none',
    position: 'inherit',
    marginTop: showRoomsColumn || showEventsColumn ? theme.spacing(1) : 0,
  }),
  lockIcon: {
    width: '1.2rem',
    height: '1.2rem',
    marginLeft: theme.spacing(0.5),
  },
});

export default styles;
