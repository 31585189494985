import {
  setAuthTokens,
  resetAuthTokens,
  setLastLoginTimestamp,
  clearTokenUpdatedTimestamp,
  unSyncRefreshTokenWithInterval,
} from '../../utils/auth';

import { setAuthHeaders, resetAuthHeaders } from '../../utils/http';

import { logInfo } from '../../utils/loggers';
import { SET_AUTH_INFO, LOGOUT } from '../reducers/auth';
import { clearActivePropertyUid } from '../../utils/properties';

export const acSetAuthInfo = ({
  user,
  idToken,
  accessToken,
  refreshToken,
  ...authInfo
}) => {
  if (idToken || accessToken || refreshToken) {
    setAuthTokens({ idToken, accessToken, refreshToken });
    setAuthHeaders({ idToken, accessToken, refreshToken });
  }

  if (idToken || accessToken || refreshToken) {
    setLastLoginTimestamp(Date.now());
  }

  return {
    type: SET_AUTH_INFO,
    payload: {
      user,
      ...authInfo,
    },
  };
};

export const acLogout = () => {
  logInfo('User logged out.');

  // reset id/access/refresh tokens
  resetAuthTokens();
  resetAuthHeaders();

  clearActivePropertyUid();
  clearTokenUpdatedTimestamp();
  unSyncRefreshTokenWithInterval();

  return {
    type: LOGOUT,
  };
};
