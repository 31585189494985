import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { Grid, withStyles } from '@material-ui/core';
import PageLayout from '../../../../components/layouts/WithHeader';
import MarketEventCard from '../../../../components/MarketEventCard';
import Box from '../../../../components/atomic/Box';
import Divider from '../../../../components/atomic/Divider';
import BallIcon from '../../../../assets/icons/Ball';
import useDesktop from '../../../../hooks/useDesktop';
import { isNativeEnv, openLinkRequest } from '../../../../utils/nativeBridge';
import style from './style';
import Typography from '../../../../components/atomic/Typography';
import {
  getVirdiUnitLink,
  eventLimit,
  redirectToVirdiMap,
} from '../../../../utils/marketEvents';
import { virdiUnitLinkBaseUrl } from '../../../../config';
import {
  acSetSoldMarketEvents,
  acSetForSaleMarketEvents,
} from '../../../../store/actions/marketEvents';
import {
  sGetSoldMarketEvents,
  sGetForSaleMarketEvents,
} from '../../../../store/reducers/marketEvents';
import useFetchEffect from '../../../../hooks/useFetchEffect';
import { getMarketEvents as apiFetchMarketEvents } from '../../../../api/properties';
import GridItemLoading from '../../../../components/GridItemLoading';
import CTAcard from '../../../../components/CTAcard';
import { useActiveProperty } from '../../../../store/hooks';
import Button from '../../../../components/atomic/Button';
import ArrowBackward from '../../../../assets/icons/ArrowBackward';

const PropertyMarketEvents = ({
  classes,
  marketEventsForSale,
  marketEventsSold,
  setMarketEventsForSale,
  setSoldMarketEvents,
}) => {
  const isDesktop = useDesktop();
  const { property } = useActiveProperty();

  const history = useHistory();
  const [fetchingMarketEventsForSale, setFetchingMarketEventsForSale] =
    useState(false);
  const [fetching, setFetching] = useState(false);
  const emptyStateText = 'Finner ingen boliger akkurat nå';
  const CTAcardText =
    'Utforsk hjemla.no sitt boligkart og se verdiestimat for alle boliger som er til salgs, eller nylig er blitt solgt i hele Norge.';

  useFetchEffect({
    dependencies: [property],
    setFetchingFunction: setFetching,
    onSuccess: (data) => setSoldMarketEvents(data),
    conditionFunction: () => !marketEventsSold && !fetching && property != null,
    apiFetchFunction: () =>
      apiFetchMarketEvents(property.boligmappaNumber, eventLimit, 'sold'),
  });

  useFetchEffect({
    dependencies: [property],
    setFetchingFunction: setFetchingMarketEventsForSale,
    onSuccess: (data) => setMarketEventsForSale(data),
    conditionFunction: () =>
      !marketEventsForSale && !fetchingMarketEventsForSale && property != null,
    apiFetchFunction: () =>
      apiFetchMarketEvents(property.boligmappaNumber, eventLimit, 'for_sale'),
  });

  const redirectToVirdiUnitPage = (unitId) => {
    if (isNativeEnv()) {
      openLinkRequest(getVirdiUnitLink(unitId, virdiUnitLinkBaseUrl));
      return;
    }
    window.open(getVirdiUnitLink(unitId, virdiUnitLinkBaseUrl), '_blank');
  };

  const onBackButtonClick = () => {
    history.goBack();
  };

  const Title = 'Siste hendelser i nabolaget';

  const renderLoding = () => {
    return (
      <Box py={2} mx={-2}>
        <Grid container spacing={2}>
          {[...Array(3).keys()].map((itemKey) => (
            <GridItemLoading
              width="200px"
              height="200px"
              key={`company-loading-${itemKey}`}
            />
          ))}
        </Grid>
      </Box>
    );
  };

  return (
    <>
      {isDesktop && (
        <Button
          style={{
            backgroundColor: 'transparent',
            fontSize: '14px',
            margin: '15px 0',
          }}
          startIcon={<ArrowBackward width={14} height={14} />}
          onClick={() => onBackButtonClick()}
        >
          Gå tilbake
        </Button>
      )}
      <PageLayout
        minFullHeight
        maxWidth="lg"
        headerTitle={Title}
        lessMarginLeft={isDesktop}
      >
        {(fetching || fetchingMarketEventsForSale) && renderLoding()}

        {marketEventsForSale && !fetchingMarketEventsForSale && !fetching && (
          <Box className={classes.sectionBox}>
            <Box
              className={
                isDesktop ? classes.titleArea : classes.titleAreaMobile
              }
            >
              <BallIcon className={classes.ballIcon} />
              <div className={classes.title}>
                <span>Til salgs</span>
                <Divider className={classes.divider} />
              </div>
            </Box>
            {marketEventsForSale && marketEventsForSale.length > 0 && (
              <Grid container spacing={2}>
                {(marketEventsForSale || []).slice(0, 3).map((forSaleEvent) => (
                  <MarketEventCard
                    key={forSaleEvent.id}
                    type="for_sale"
                    marketEvent={forSaleEvent}
                    onClick={() => redirectToVirdiUnitPage(forSaleEvent.id)}
                  />
                ))}
              </Grid>
            )}
            {marketEventsForSale.length === 0 && (
              <Box
                className={
                  isDesktop
                    ? classes.emptyStateText
                    : classes.emptyStateTextMobile
                }
              >
                <Typography>{emptyStateText}</Typography>
              </Box>
            )}
          </Box>
        )}

        {marketEventsSold && !fetching && !fetchingMarketEventsForSale && (
          <Box className={classes.sectionBox}>
            <Box
              className={
                isDesktop ? classes.titleArea : classes.titleAreaMobile
              }
            >
              <BallIcon className={classes.greenBallIcon} />
              <div className={classes.title}>
                <span>Nylig solgt</span>
                <Divider className={classes.divider} />
              </div>
            </Box>
            {marketEventsSold && marketEventsSold.length > 0 && (
              <Grid container spacing={2}>
                {(marketEventsSold || []).slice(0, 3).map((soldMarketEvent) => (
                  <MarketEventCard
                    key={soldMarketEvent.id}
                    type="sold"
                    marketEvent={soldMarketEvent}
                    onClick={() => redirectToVirdiUnitPage(soldMarketEvent.id)}
                  />
                ))}
              </Grid>
            )}
            {marketEventsSold.length === 0 && (
              <Box
                className={
                  isDesktop
                    ? classes.emptyStateText
                    : classes.emptyStateTextMobile
                }
              >
                <Typography>{emptyStateText}</Typography>
              </Box>
            )}
          </Box>
        )}

        <CTAcard
          title="Vil du se flere boliger?"
          description={CTAcardText}
          buttonText="Se flere boliger"
          onClick={redirectToVirdiMap}
        />
      </PageLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  marketEventsSold: sGetSoldMarketEvents(state),
  marketEventsForSale: sGetForSaleMarketEvents(state),
});
const mapDispatchToProps = (dispatch) => ({
  setSoldMarketEvents: (soldMarketEvents) =>
    dispatch(acSetSoldMarketEvents(soldMarketEvents)),
  setMarketEventsForSale: (marketEventsForSale) =>
    dispatch(acSetForSaleMarketEvents(marketEventsForSale)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(PropertyMarketEvents));
