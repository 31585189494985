import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState, useEffect, createElement } from 'react';

import {
  Switch,
  useParams,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router';

import { useFlag } from '@unleash/proxy-client-react';
import HeaderNav from '@componentsV2/HeaderNav';
import {
  sGetActiveProperty,
  sGetPropertiesLoaded,
  sGetFetchingProperties,
  sGetFetchingPropertiesError,
  sGetPropertyByBoligmappaNumber,
  sGetAllProperties,
} from '../../../store/reducers/properties';
import { sGetUser } from '../../../store/reducers/auth';

import { sGetAccessRequests } from '../../../store/reducers/accessRequests';

import {
  fetchProperties as apiFetchProperties,
  fetchNotifications as apiFetchNotifications,
  getAccessRequestsData,
  getPropertyFeatures,
  fetchRooms,
  fetchEvents,
} from '../../../api/properties';

import {
  acSetNotifications,
  acSetFetchingNotifications,
  acSetFetchingNotificationsError,
} from '../../../store/actions/notifications';

import {
  acSetProperties,
  acSetActiveProperty,
  acSetFetchingProperties,
  acSetFetchingPropertiesError,
} from '../../../store/actions/properties';

import { actionSetAccessRequests } from '../../../store/actions/accessRequests';
import Images from './Images';
import AddRoomDialog from './Rooms/Room/Add';
import AddEventDialog from './Events/Event/Add';
import Room from './Rooms/Room';
import Terms from './More/Terms';
import Share from './More/Share';
import Referral from './Referral';
import Event from './Events/Event';
import Account from './More/Account';
import Consents from './More/Consents';
import AddDocument from './AddDocument';
import ContactUs from './More/ContactUs';
import MobileNavbar from './MobileNavbar';
import Chapter from './Documents/Chapter';
import Document from './Documents/Document';
import ExperimentTemplate from './ExperimentTemplate';
import Notifications from './Notifications';
import Rights from './PropertyDetails/Rights';
import AddContextMenu from './AddContextMenu';
import routes from '../../../structure/routes';
import PrivateShare from './More/PrivateShare';
import PropertyDetails from './PropertyDetails';
import ChapterOne from './Documents/ChapterOne';
import PropertyEconomy from './PropertyEconomy';
import ProfileDelete from './More/ProfileDelete';
import CreateMDR from './CreateMDR';
import useDesktop from '../../../hooks/useDesktop';
import PropertyCompanies from './PropertyCompanies';
import Route from '../../../components/atomic/Route';
import Hidden from '../../../components/atomic/Hidden';
import Notification from './Notifications/Notification';
import Commitments from './PropertyDetails/Commitments';
import useFetchEffect from '../../../hooks/useFetchEffect';
import {
  acClearRooms,
  acSetFetchingRooms,
  acSetFetchingRoomsError,
  acSetRooms,
} from '../../../store/actions/rooms';
import PropertyLoans from './PropertyEconomy/PropertyLoans';
import PropertySales from './PropertyEconomy/PropertySales';
import {
  acClearEvents,
  acSetEvents,
  acSetFetchingEvents,
  acSetFetchingEventsError,
} from '../../../store/actions/events';
import { acClearRights } from '../../../store/actions/rights';
import { acClearMarketEvents } from '../../../store/actions/marketEvents';
import {
  isAddButtonEnabled,
  isBackgroundContentContainer,
  isFullscreenMode,
  isFullScreenPreviewMode,
} from './util';
import DesktopTopHeader from '../../../components/desktop/Header';
import DownloadPersonalData from './More/DownloadPersonalData';
import ErrorContainer from '../../../components/ErrorContainer';
import { sGetFetchingRooms, sGetRooms } from '../../../store/reducers/rooms';
import { acClearCompanies } from '../../../store/actions/companies';
import {
  acClearDocuments,
  acSetIsCreatingDocumentFolder,
} from '../../../store/actions/documents';
import DashboardLayout from '../../../components/layouts/Dashboard';
import { acClearFinancials } from '../../../store/actions/financials';
import PropertyOwnerships from './PropertyEconomy/PropertyOwnerships';
import { isPropertyOwnerNotFoundError } from '../../../utils/requests';
import { acClearCommitments } from '../../../store/actions/commitments';
import { acBYBFClearRooms } from '../../../store/actions/bybfRooms';
import sessionStorage from '../../../utils/sessionStorage';
import { redirectOptions, redirectPath } from '../../../utils/routes';
import useDashboardLinkPrefix from '../../../hooks/useDashboardLinkPrefix';
import { isPropertyOwned } from '../../../utils/properties';
import PropertySalesLaw from './PropertySalesLaw';
import PropertyFinancials from './PropertyFinancials';
import PropertyMarketEvents from './PropertyMarketEvents';
import FindProfessional from './More/FindProfessional';
import CompanyProfile from './CompanyProfile';
import Addons from './More/Addons';
import { acSetUnitFeatures } from '../../../store/actions/unitFeatures';
import AccessRequestDialog from './More/PrivateShare/Details/AccessRequestDialog';
import localStorage from '../../../utils/localStorage';
import { enableSurveyor } from '../../../config';
import PropertyFinancialsHjemla from './PropertyFinancialsHjemla';
import { sGetEvents, sGetFetchingEvents } from '../../../store/reducers/events';
import { sGetUnitFeatures } from '../../../store/reducers/unitFeatures';
import BoligmappaPlusPage from './BoligmappaPlusPage';
import BoligmappaPlusLoyaltyPage from './BoligmappaPlusLoyaltyPage';
import BoligmappaPlusLoyaltyCompanyPage from './BoligmappaPlusLoyaltyCompanyPage';
import { isNativeEnv } from '../../../utils/nativeBridge';
import ProtectedRoute from '../../../components/ProtectedRoute';
import InsurancePage from '../InsurancePage';
import PurchasesPage from './PurchasesPage';
import PersonalArea from './PersonalArea';
import { privateNavbarMenu } from '../../../structure/menu';
import MyDocuments from './PersonalArea/MyDocuments';

export const Dashboard = ({
  propertiesLoaded,
  fetchingProperties,
  fetchingPropertiesError,
  getPropertyByBoligmappaNumber,
  accessRequests,
  activeProperty,
  setUnitFeatures,
  setProperties,
  setNotifications,
  setActiveProperty,
  setFetchingProperties,
  setFetchingNotifications,
  setFetchingPropertiesError,
  setFetchingNotificationsError,
  setAccessRequests,
  clearRooms,
  clearRights,
  clearBYBFRooms,
  clearEvents,
  clearDocuments,
  clearCompanies,
  clearFinancials,
  clearCommtiments,
  clearMarketEvents,
  fetchingRooms,
  rooms,
  setFetchingRooms,
  setRooms,
  setFetchingRoomsError,
  events,
  setEvents,
  fetching,
  setFetching,
  setFetchingEventsError,
  setIsCreatingDocumentFolder,
  activeUser,
}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const isDesktop = useDesktop();
  const { pathname } = useLocation();
  const { boligmappaNumber } = useParams();
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const enableBoligmappaPlus = useFlag('boligeier.boligmappaPlus');
  const enableInsurancePage = useFlag('boligeier.insurance.page');
  const folderFeatureFlag = useFlag('boligeier.folder.feature');
  const backgroundContentContainer = isBackgroundContentContainer(
    pathname,
    match.url
  );
  const fullscreenPreviewMode = isFullScreenPreviewMode(pathname, match.url);
  const fullscreenMode = isFullscreenMode(pathname, match.url);
  const showAddButton = isAddButtonEnabled(pathname, match.url) && !isDesktop;
  const compensateBottomNavigationPadding =
    !showAddButton && !fullscreenMode && !isDesktop;
  const [
    isAccessRequestDialogSeenStorage,
    setIsAccessRequestDialogSeenStorage,
  ] = useState(localStorage.get('accessRequestsSeen'));
  const isApp = isNativeEnv();
  const onAccessRequestDialogClose = () => {
    localStorage.set('accessRequestsSeen', true);
    setIsAccessRequestDialogSeenStorage(true);
  };

  const desktopTopHeader = (
    <DesktopTopHeader
      ownedProperty={isPropertyOwned(
        getPropertyByBoligmappaNumber(boligmappaNumber)
      )}
    />
  );
  const desktopHeaderNav = (
    <HeaderNav
      activeProperty={activeProperty}
      navbarMenuItems={privateNavbarMenu}
    />
  );

  const [isBoligmappaPlusUser, setIsBoligmappaPlusUser] = useState(true); // // change this value to false to test

  useEffect(() => {
    if (enableBoligmappaPlus) {
      setIsBoligmappaPlusUser(activeUser?.isSubscribeToBoligmappaPlus);
    }
  }, [activeUser]);

  useFetchEffect({
    dependencies: [propertiesLoaded],
    apiFetchFunction: apiFetchProperties,
    conditionFunction: ([_propertiesLoaded]) => !_propertiesLoaded,

    onFetchStart: () => setFetchingProperties(true),
    onFetchComplete: () => setFetchingProperties(false),

    onSuccess: (data) => setProperties(data),
    onError: (error) =>
      isPropertyOwnerNotFoundError(error)
        ? history.push('/signup')
        : setFetchingPropertiesError(error),
  });

  useFetchEffect({
    dependencies: [boligmappaNumber],
    apiFetchFunction: () => apiFetchNotifications(boligmappaNumber),

    onFetchStart: () => setFetchingNotifications(true),
    onFetchComplete: () => setFetchingNotifications(false),

    onSuccess: (data) => setNotifications(data),
    onError: (error) => setFetchingNotificationsError(error),
  });

  useFetchEffect({
    dependencies: [boligmappaNumber],
    apiFetchFunction: () => getAccessRequestsData(boligmappaNumber),
    onSuccess: (data) => setAccessRequests(data),
  });

  useFetchEffect({
    dependencies: [boligmappaNumber],
    apiFetchFunction: () => getPropertyFeatures(boligmappaNumber),

    onFetchStart: () =>
      setUnitFeatures({ unitFeatures: { fetching: true, loaded: false } }),

    onSuccess: (data) => {
      setUnitFeatures({
        unitFeatures: { ...data, fetching: false, loaded: true },
      });
    },
    onError: () =>
      setUnitFeatures({ unitFeatures: { fetching: false, loaded: false } }),
  });

  useFetchEffect({
    setFetchingFunction: setFetchingRooms,
    onSuccess: (data) => setRooms(data),
    onError: (error) => setFetchingRoomsError(error),
    conditionFunction: () => !rooms && !fetchingRooms,
    apiFetchFunction: () => fetchRooms(boligmappaNumber),
  });

  useFetchEffect({
    setFetchingFunction: setFetching,
    onSuccess: (data) => setEvents(data),
    conditionFunction: () => !events && !fetching,
    onError: (error) => setFetchingEventsError(error),
    apiFetchFunction: () => fetchEvents(boligmappaNumber),
  });

  const redirectToSpecific = (currentProperty) => {
    const redirectPathValue = sessionStorage.get(redirectPath);
    if (redirectPathValue) {
      const isPropertyOwnedStaus = isPropertyOwned(currentProperty);
      let redirectUrl = null;
      let authorizedForRedirect = true;
      switch (redirectPathValue) {
        case redirectOptions.privateSharing:
          redirectUrl = `${dashboardLinkPrefix}/more/private-share`;
          authorizedForRedirect = isPropertyOwnedStaus;
          break;
        case redirectOptions.publicSharing:
          redirectUrl = `${dashboardLinkPrefix}/more/share`;
          authorizedForRedirect = isPropertyOwnedStaus;
          break;
        case redirectOptions.home:
          redirectUrl = `${dashboardLinkPrefix}/home`;
          break;
        case redirectOptions.companies:
          redirectUrl = `${dashboardLinkPrefix}/companies`;
          break;
        case redirectOptions.moreAccount:
          redirectUrl = `${dashboardLinkPrefix}/more/account`;
          break;
        case redirectOptions.financials:
          redirectUrl = `${dashboardLinkPrefix}/financials`;
          break;
        case redirectOptions.findProfessional:
          redirectUrl = `${dashboardLinkPrefix}/more/find-professional`;
          break;
        case redirectOptions.radon:
          redirectUrl = `${dashboardLinkPrefix}/addon/radon`;
          break;
        case redirectOptions.otovo:
          redirectUrl = `${dashboardLinkPrefix}/addon/otovo`;
          break;
        case redirectOptions.boligmappaPlus:
          redirectUrl = `${dashboardLinkPrefix}/boligmappa-plus`;
          break;
        case redirectOptions.myPurchases:
          redirectUrl = `${dashboardLinkPrefix}/my-purchases`;
          break;
        case redirectOptions.insurance:
          redirectUrl = `${dashboardLinkPrefix}/insurance`;
          break;
        case redirectOptions.documents:
          redirectUrl = `${dashboardLinkPrefix}/documents`;
          break;
        case redirectOptions.sparebank1sorostnorge:
          redirectUrl = `${dashboardLinkPrefix}/boligmappa-plus/loyalty/company/sparebank-1-sorost-norge`;
          break;
        case redirectOptions.personaldocuments:
          redirectUrl = `/personal-area/dashboard/documents`;
          break;
        case redirectOptions.krogsveen:
          redirectUrl = `${dashboardLinkPrefix}/boligmappa-plus/loyalty/company/krogsveen`;
          break;
        case redirectOptions.flisekompaniet:
          redirectUrl = `${dashboardLinkPrefix}/boligmappa-plus/loyalty/company/flisekompaniet`;
          break;
        case redirectOptions.ifforsikring:
          redirectUrl = `${dashboardLinkPrefix}/boligmappa-plus/loyalty/company/if-forsikring`;
          break;
        case redirectOptions.verisure:
          redirectUrl = `${dashboardLinkPrefix}/boligmappa-plus/loyalty/company/verisure`;
          break;
        case redirectOptions.profileDelete:
          redirectUrl = `/personal-area/dashboard/more/profile-delete`;
          break;
        default:
          break;
      }
      if (redirectUrl && authorizedForRedirect) {
        sessionStorage.remove(redirectPath);
        history.push(redirectUrl);
      }
    }
  };

  useEffect(() => {
    const newActiveProperty = getPropertyByBoligmappaNumber(boligmappaNumber);
    if (propertiesLoaded && newActiveProperty) {
      setActiveProperty(newActiveProperty);
      redirectToSpecific(newActiveProperty);
      localStorage.set('accessRequestsSeen', false);
      setIsAccessRequestDialogSeenStorage(false);
    }
    clearRooms();
    clearEvents();
    clearRights();
    clearBYBFRooms();
    clearDocuments();
    clearCompanies();
    clearFinancials();
    clearCommtiments();
    clearMarketEvents();
  }, [boligmappaNumber]);

  useEffect(() => {
    const newActiveProperty = getPropertyByBoligmappaNumber(boligmappaNumber);
    if (propertiesLoaded && newActiveProperty) {
      setActiveProperty(newActiveProperty);
      redirectToSpecific(newActiveProperty);
    }
  }, [propertiesLoaded]);

  if (fetchingPropertiesError) {
    return <ErrorContainer errorResponse={fetchingPropertiesError} />;
  }

  return (
    <DashboardLayout
      desktopTopHeader={desktopTopHeader}
      desktopHeaderNav={desktopHeaderNav}
      addButtonPaddingEnabled={showAddButton}
      mobileNavbarPaddingEnabled={compensateBottomNavigationPadding}
      isFullscreenPreviewMode={fullscreenPreviewMode}
      backgroundContentContainer={backgroundContentContainer}
    >
      {activeProperty &&
        enableSurveyor === 'true' &&
        activeProperty.owned &&
        !isAccessRequestDialogSeenStorage &&
        accessRequests.find((el) => el.status.status === 'Pending') && (
          <AccessRequestDialog
            accessRequestDialog={!isAccessRequestDialogSeenStorage}
            setAccessRequestDialog={onAccessRequestDialogClose}
            accessRequests={accessRequests}
            dashboardLinkPrefix={dashboardLinkPrefix}
          />
        )}
      {routes.map(({ path, component, mobileOnly, hasSubRoutes }) => (
        <Route
          key={`route-${path}`}
          exact={!hasSubRoutes}
          mobileOnly={mobileOnly}
          path={`/dashboard/:boligmappaNumber/${path}`}
          render={(routeProps) =>
            createElement(component, {
              boligmappaNumber,
              fetchingProperties,
              fetchingPropertiesError,
              ...routeProps,
            })
          }
        />
      ))}

      <Switch>
        <Route
          component={ChapterOne}
          path="/dashboard/:boligmappaNumber/chapters/1"
        />
        <Route
          component={Chapter}
          path="/dashboard/:boligmappaNumber/chapters/:chapterId"
        />
      </Switch>

      <Route component={Images} path="/dashboard/:boligmappaNumber/images" />
      <Route
        mobileOnly
        component={Notification}
        path="/dashboard/:boligmappaNumber/notifications/:notificationIdHash"
      />
      <Route
        desktopOnly
        component={Notifications}
        path="/dashboard/:boligmappaNumber/notifications/:notificationIdHash"
      />
      <Route
        exact
        component={PropertyEconomy}
        path="/dashboard/:boligmappaNumber/economy"
      />
      <Route
        exact
        component={PropertyCompanies}
        path="/dashboard/:boligmappaNumber/companies"
      />
      <Route
        component={PropertyLoans}
        path="/dashboard/:boligmappaNumber/economy/loans"
      />
      <Route
        component={PropertyOwnerships}
        path="/dashboard/:boligmappaNumber/economy/ownerships"
      />
      <Route
        component={PropertySales}
        path="/dashboard/:boligmappaNumber/economy/sales"
      />
      <Route
        exact
        component={PropertyDetails}
        path="/dashboard/:boligmappaNumber/details"
      />
      <Route
        component={Commitments}
        path="/dashboard/:boligmappaNumber/details/commitments"
      />
      <Route
        component={Rights}
        path="/dashboard/:boligmappaNumber/details/rights"
      />
      <Route
        component={Event}
        path="/dashboard/:boligmappaNumber/events/:eventId"
      />
      <Route
        component={Document}
        path="/dashboard/:boligmappaNumber/documents/:documentId"
      />
      <Route
        component={ExperimentTemplate}
        path="/dashboard/:boligmappaNumber/addon/:experimentSlug"
      />
      <Route
        component={CompanyProfile}
        path="/dashboard/:boligmappaNumber/company/:companyId"
      />
      <Route
        component={Room}
        path="/dashboard/:boligmappaNumber/rooms/:roomId"
      />
      <Route
        exact
        component={AddDocument}
        path="/dashboard/:boligmappaNumber/add-document"
      />
      <Route
        exact
        component={Referral}
        path="/dashboard/:boligmappaNumber/referral"
      />
      <Route
        exact
        component={PropertySalesLaw}
        path="/dashboard/:boligmappaNumber/sales-law"
      />
      <Route
        exact
        component={PropertyFinancials}
        path="/dashboard/:boligmappaNumber/financials"
      />
      <Route
        exact
        component={PropertyFinancialsHjemla}
        path="/dashboard/:boligmappaNumber/financials/hjemla"
      />
      <Route
        component={PropertyMarketEvents}
        path="/dashboard/:boligmappaNumber/market-events"
      />

      {/* More section routes */}
      <Route
        component={ProfileDelete}
        path="/dashboard/:boligmappaNumber/more/profile-delete"
      />
      <Route component={Terms} path="/dashboard/:boligmappaNumber/more/terms" />
      <Route
        component={Consents}
        path="/dashboard/:boligmappaNumber/more/consents"
      />
      <Route
        component={DownloadPersonalData}
        path="/dashboard/:boligmappaNumber/more/download-personal-data"
      />
      <Route
        component={Account}
        path="/dashboard/:boligmappaNumber/more/account"
      />
      <Route
        component={ContactUs}
        path="/dashboard/:boligmappaNumber/more/contact"
      />
      <Route component={Share} path="/dashboard/:boligmappaNumber/more/share" />
      <Route
        exact
        component={PrivateShare}
        path="/dashboard/:boligmappaNumber/more/private-share"
      />
      <Route
        exact
        component={FindProfessional}
        path="/dashboard/:boligmappaNumber/more/find-professional"
      />
      <Route
        exact
        component={Addons}
        path="/dashboard/:boligmappaNumber/more/addons"
      />
      <Route
        component={CreateMDR}
        path="/dashboard/:boligmappaNumber/create-mdr"
      />
      {enableInsurancePage && (
        <>
          <ProtectedRoute
            exact
            path="/dashboard/:boligmappaNumber/insurance"
            component={InsurancePage}
          />
        </>
      )}
      <Route
        exact
        component={PurchasesPage}
        path="/dashboard/:boligmappaNumber/my-purchases"
      />
      {enableBoligmappaPlus && !isApp && (
        <>
          <Route
            exact
            component={BoligmappaPlusPage}
            path="/dashboard/:boligmappaNumber/boligmappa-plus"
          />
          <Route
            exact
            component={BoligmappaPlusLoyaltyPage}
            path="/dashboard/:boligmappaNumber/boligmappa-plus/loyalty"
          />
          <Route
            exact
            component={BoligmappaPlusLoyaltyCompanyPage}
            path="/dashboard/:boligmappaNumber/boligmappa-plus/loyalty/company/:companyCode"
          />
          <Route
            exact
            component={BoligmappaPlusLoyaltyCompanyPage}
            path="/dashboard/:boligmappaNumber/loyalty/company/:companyCode"
          />
        </>
      )}
      <Route
        exact
        component={PersonalArea}
        path="/dashboard/:boligmappaNumber/personal-area"
      />
      <Route
        exact
        component={MyDocuments}
        path="/dashboard/:boligmappaNumber/my-documents"
      />

      {/* Mobile only */}
      {!fullscreenPreviewMode && (
        <Hidden mdUp>
          <MobileNavbar hidden={fullscreenMode} />
          {showAddButton && (
            <AddContextMenu
              isBoligmappaPlusUser={isBoligmappaPlusUser}
              onMobileFolderCreate={() => setIsCreatingDocumentFolder(true)}
              folderFeatureFlag={folderFeatureFlag}
            />
          )}
        </Hidden>
      )}

      <AddRoomDialog />
      <AddEventDialog />
    </DashboardLayout>
  );
};

Dashboard.defaultProps = {};

Dashboard.propTypes = {
  getPropertyByBoligmappaNumber: PropTypes.func.isRequired,
  clearRooms: PropTypes.func.isRequired,
  clearEvents: PropTypes.func.isRequired,
  clearCompanies: PropTypes.func.isRequired,
  clearDocuments: PropTypes.func.isRequired,
  clearFinancials: PropTypes.func.isRequired,
  clearCommtiments: PropTypes.func.isRequired,
  clearRights: PropTypes.func.isRequired,
  clearMarketEvents: PropTypes.func.isRequired,
  clearBYBFRooms: PropTypes.func.isRequired,
  setProperties: PropTypes.func.isRequired,
  setActiveProperty: PropTypes.func.isRequired,
  setNotifications: PropTypes.func.isRequired,
  setAccessRequests: PropTypes.func.isRequired,
  setFetchingProperties: PropTypes.func.isRequired,
  setFetchingPropertiesError: PropTypes.func.isRequired,
  setFetchingNotifications: PropTypes.func.isRequired,
  setFetchingNotificationsError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  properties: sGetAllProperties(state),
  accessRequests: sGetAccessRequests(state),
  fetchingRooms: sGetFetchingRooms(state),
  activeProperty: sGetActiveProperty(state),
  propertiesLoaded: sGetPropertiesLoaded(state),
  fetchingProperties: sGetFetchingProperties(state),
  getPropertyByBoligmappaNumber: (boligmappaNumber) =>
    sGetPropertyByBoligmappaNumber(state, boligmappaNumber),
  fetchingPropertiesError: sGetFetchingPropertiesError(state),
  rooms: sGetRooms(state),
  unitFeatures: sGetUnitFeatures(state),
  events: sGetEvents(state),
  fetching: sGetFetchingEvents(state),
  activeUser: sGetUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearRooms: () => dispatch(acClearRooms()),
  clearEvents: () => dispatch(acClearEvents()),
  clearRights: () => dispatch(acClearRights()),
  clearBYBFRooms: () => dispatch(acBYBFClearRooms()),
  clearCompanies: () => dispatch(acClearCompanies()),
  clearDocuments: () => dispatch(acClearDocuments()),
  clearFinancials: () => dispatch(acClearFinancials()),
  clearCommtiments: () => dispatch(acClearCommitments()),
  clearMarketEvents: () => dispatch(acClearMarketEvents()),

  setProperties: (properties) => dispatch(acSetProperties(properties)),
  setActiveProperty: (property) => dispatch(acSetActiveProperty(property)),
  setNotifications: (notifications) =>
    dispatch(acSetNotifications(notifications)),
  setFetchingProperties: (fetching) =>
    dispatch(acSetFetchingProperties(fetching)),
  setFetchingPropertiesError: (error) =>
    dispatch(acSetFetchingPropertiesError(error)),
  setFetchingNotifications: (fetching) =>
    dispatch(acSetFetchingNotifications(fetching)),
  setFetchingNotificationsError: (error) =>
    dispatch(acSetFetchingNotificationsError(error)),
  setAccessRequests: (accessRequests) =>
    dispatch(actionSetAccessRequests(accessRequests)),
  setUnitFeatures: (unitFeatures) => dispatch(acSetUnitFeatures(unitFeatures)),
  setRooms: (rooms) => dispatch(acSetRooms(rooms)),
  setFetchingRooms: (fetchingRooms) =>
    dispatch(acSetFetchingRooms(fetchingRooms)),
  setFetchingRoomsError: (fetchingRoomsError) =>
    dispatch(acSetFetchingRoomsError(fetchingRoomsError)),
  setEvents: (events) => dispatch(acSetEvents(events)),
  setFetching: (fetching) => dispatch(acSetFetchingEvents(fetching)),
  setFetchingEventsError: (fetchingEventsError) =>
    dispatch(acSetFetchingEventsError(fetchingEventsError)),
  setIsCreatingDocumentFolder: (isCreatingDocumentFolder) =>
    dispatch(acSetIsCreatingDocumentFolder(isCreatingDocumentFolder)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
