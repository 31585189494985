const styles = () => ({
  previewHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1199,
    background: '#000',
    width: '100%',
    padding: '46px 0 20px 0',
  },
  editInputBlock: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    maxHeight: '32px',
    '&.desktop': {
      maxWidth: '60%',
    },
  },
  previewCloseButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '32px',
    maxHeight: '32px',
    padding: '11px',
    margin: '8px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '50%',
    '&:hover': {
      background: '#ccc',
    },
  },
  previewPage: {
    background: '#717171',
    paddingTop: '24px',
    marginTop: '98px',
  },
  contentClass: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    width: '100%',
  },
  filePreview: {
    width: '100%',
  },

  previewDetails: {
    width: '100%',
    maxWidth: '295px',
    margin: '0 16px',
    padding: '19px 16px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  mobileContext: {
    pointerEvents: 'none',
    visibility: 'unset!important',
    zIndex: '1200!important',
  },
  mobileContextShown: {
    pointerEvents: 'unset',
  },
  visibilityDrawerHidden: {
    visibility: 'unset!important',
    transform: 'translateY(calc(100% - 80px))!important',
    pointerEvents: 'unset!important',
    maxHeight: '95vh',
  },
  contentWrapper: {
    position: 'relative',
    padding: '19px 16px',
    overflow: 'scroll',
    maxHeight: '95vh',
    pointerEvents: 'auto',
  },
  contentHidden: {
    overflow: 'hidden',
  },
  mobileContextButton: {
    border: 'unset',
    background: 'unset',
    position: 'absolute',
    top: '20px',
    right: '20px',
  },
  mobileContextIcon: {
    transition: '0.2s',
  },
  mobileContextIconOpened: {
    transform: 'rotate(-180deg)',
  },
  documentName: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 700,
    color: '#fff',
    textWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
});

export default styles;
