import {
  sortPropertiesByAddress,
  getPropertyBoligmappaNumber,
  isPropertyOwned,
} from '../../utils/properties';

import { areIdsEqual } from '../../utils/strings';

const defaultState = {
  owned: null,
  fetching: true,
  fetchingError: null,
  activeProperty: null,
  sharedPrivately: null,
  sharedPublicly: null,
};

export const ADD_PROPERTY = 'ADD_PROPERTY';
export const SET_PROPERTIES = 'SET_PROPERTIES';
export const SET_PUBLIC_PROPERTY = 'SET_PUBLIC_PROPERTY';
export const UPDATE_PROPERTY = 'UPDATE_PROPERTY';
export const SET_ACTIVE_PROPERTY = 'SET_ACTIVE_PROPERTY';
export const SET_FETCHING_PROPERTIES = 'SET_FETCHING_PROPERTIES';
export const SET_FETCHING_PROPERTIES_ERROR = 'SET_FETCHING_PROPERTIES_ERROR';

export const getUpdatedProperty = (storedProperty, updatedProperty) =>
  areIdsEqual(
    getPropertyBoligmappaNumber(storedProperty),
    getPropertyBoligmappaNumber(updatedProperty)
  )
    ? updatedProperty
    : storedProperty;

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_PROPERTY:
      const { isOwned, property } = action.payload;

      return {
        ...state,
        owned: [...state.owned, isOwned ? property : null],
        sharedPrivately: [...state.sharedPrivately, isOwned ? null : property],
      };
    case SET_PROPERTIES:
      return {
        ...state,
        owned: (action.payload.owned || [])
          .map((data) => ({ ...data, owned: true }))
          .sort(sortPropertiesByAddress),
        sharedPrivately: (action.payload.sharedPrivately || [])
          .map((data) => ({ ...data, owned: false }))
          .sort(sortPropertiesByAddress),
      };
    case SET_PUBLIC_PROPERTY:
      return action.payload; // new
    case UPDATE_PROPERTY:
      const updatedProperty = action.payload;

      return {
        ...state,
        sharedPrivately: state.sharedPrivately.map((storedProperty) =>
          getUpdatedProperty(storedProperty, updatedProperty)
        ),
        owned: state.owned.map((storedProperty) =>
          getUpdatedProperty(storedProperty, updatedProperty)
        ),
        activeProperty: getUpdatedProperty(
          state.activeProperty,
          updatedProperty
        ),
      };
    case SET_ACTIVE_PROPERTY:
      return {
        ...state,
        activeProperty: {
          ...action.payload,
          owned: isPropertyOwned(action.payload),
        },
      };
    case SET_FETCHING_PROPERTIES:
      return {
        ...state,
        fetching: action.payload,
      };
    case SET_FETCHING_PROPERTIES_ERROR:
      return {
        ...state,
        fetchingError: action.payload,
      };
    default:
      return state;
  }
};

export const sGetProperties = (state) => state.properties;
export const sGetOwnedProperties = (state) => sGetProperties(state).owned;

export const sGetSharedPrivatelyProperties = (state) =>
  sGetProperties(state).sharedPrivately;

export const sGetPublicProperty = (state) => state.properties.activeProperty; // new

export const sGetFetchingProperties = (state) => sGetProperties(state).fetching;

export const sGetActiveProperty = (state) =>
  sGetProperties(state).activeProperty;

export const sGetFetchingPropertiesError = (state) =>
  sGetProperties(state).fetchingError;

export const sGetPropertiesLoaded = (state) =>
  !!(sGetOwnedProperties(state) || !!sGetSharedPrivatelyProperties(state));

export const sGetAllProperties = (state) => [
  ...(sGetOwnedProperties(state) || []),
  ...(sGetSharedPrivatelyProperties(state) || []),
];

export const sGetPropertyByBoligmappaNumber = (state, boligmappaNumber) =>
  sGetAllProperties(state).find(
    (property) => getPropertyBoligmappaNumber(property) === boligmappaNumber
  );
