// import SanityClient  from "@sanity/client/sanityClient";
import sanityClient from '@sanity/client';
// import { SanityToken } from '../config';

export default sanityClient({
  // Find your project ID and dataset in `sanity.json` in your studio project
  projectId: 'znl9re2x',
  dataset: process.env.REACT_APP_SANITY_DATABASE ?? 'production',
  useCdn: true,
  apiVersion: '2022-06-02',
  // token required to fetch translations
  token: process.env.REACT_APP_SANITY_TOKEN,
  // useCdn == true gives fast, cheap responses using a globally distributed cache.
  // Set this to false if your application require the freshest possible
  // data always (potentially slightly slower and a bit more expensive).
});
