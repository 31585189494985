import {
  getEventId,
  getEventRooms,
  getEventDocuments,
  eventModes,
} from '../../utils/events';

import { getRoomId } from '../../utils/rooms';
import { areIdsEqual } from '../../utils/strings';
import { getDocumentId } from '../../utils/documents';

export const defaultState = {
  events: null,
  fetching: false,
  fetchingError: null,
  showEventCreateSuggesion: false,
  showAddEvent: false,
  showEditEvent: false,
  eventMode: eventModes.event,
  eventAttachedIds: {
    roomIds: [],
    documentIds: [],
  },
  returnOnSuccess: false,
};

export const ADD_EVENT = 'ADD_EVENT';
export const SET_EVENTS = 'SET_EVENTS';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const DELETE_EVENT = 'DELETE_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const SYNC_EVENTS_ROOMS = 'SYNC_EVENTS_ROOMS';
export const SET_FETCHING_EVENTS = 'SET_FETCHING_EVENTS';
export const SYNC_EVENTS_DOCUMENTS = 'SYNC_EVENTS_DOCUMENTS';
export const SET_FETCHING_EVENTS_ERROR = 'SET_FETCHING_EVENTS_ERROR';
export const DETACH_DOCUMENT_FROM_EVENTS = 'DETACH_DOCUMENT_FROM_EVENTS';
export const SET_SHOW_EVENT_CREATION_SUGGESION =
  'SET_SHOW_EVENT_CREATION_SUGGESION';
export const SET_SHOW_ADD_EVENT = 'SET_SHOW_ADD_EVENT';
export const SET_SHOW_EDIT_EVENT = 'SET_SHOW_EDIT_EVENT';
export const SET_EVENT_MODE = 'SET_EVENT_MODE';
export const SET_ATTACHED_IDS = 'SET_ATTACHED_IDS';
export const SET_RETURN_ON_SUCCESS = 'SET_RETURN_ON_SUCCESS';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
    case ADD_EVENT:
      return {
        ...state,
        events: [...(state.events || []), action.payload],
      };
    case CLEAR_EVENTS:
      return defaultState;
    case DELETE_EVENT:
      return {
        ...state,
        events: state.events.filter(
          (event) => !areIdsEqual(getEventId(event), action.payload.eventId)
        ),
      };
    case UPDATE_EVENT:
      const updatedEvent = action.payload;

      return {
        ...state,
        events: state.events.map((event) =>
          areIdsEqual(getEventId(event), getEventId(updatedEvent))
            ? updatedEvent
            : event
        ),
      };
    case SYNC_EVENTS_ROOMS:
      return {
        ...state,
        events: (state.events || []).map((event) => {
          const { roomId, room, eventIds: numericEventIds } = action.payload;
          const eventIds = numericEventIds.map((id) => String(id));
          const roomIds = (getEventRooms(event) || []).map((_room) =>
            String(getRoomId(_room))
          );
          const eventId = getEventId(event);
          const eventHadRoom = roomIds.includes(String(roomId));
          const roomAttached = eventIds.includes(String(eventId));

          if (eventHadRoom && !roomAttached) {
            return {
              ...event,
              rooms: (getEventRooms(event) || []).filter(
                (_room) => !areIdsEqual(getRoomId(_room), roomId)
              ),
            };
          }

          if (!eventHadRoom && roomAttached) {
            return {
              ...event,
              rooms: [...(getEventRooms(event) || []), room],
            };
          }

          return {
            ...event,
            rooms: (getEventRooms(event) || []).map((eventRoom) =>
              areIdsEqual(getRoomId(eventRoom), roomId) ? room : eventRoom
            ),
          };
        }),
      };
    case SYNC_EVENTS_DOCUMENTS:
      return {
        ...state,
        events: (state.events || []).map((event) => {
          const {
            documentId,
            document,
            eventIds: numericEventIds,
          } = action.payload;
          const eventIds = numericEventIds?.map((id) => String(id));
          const documentIds = (getEventDocuments(event) || []).map(
            (_document) => String(getDocumentId(_document))
          );
          const eventId = getEventId(event);
          const eventHadDocument = documentIds.includes(String(documentId));
          const documentAttached = eventIds?.includes(String(eventId));

          if (eventHadDocument && !documentAttached) {
            return {
              ...event,
              documents: (getEventDocuments(event) || []).filter(
                (_document) =>
                  !areIdsEqual(getDocumentId(_document), documentId)
              ),
            };
          }

          if (!eventHadDocument && documentAttached) {
            return {
              ...event,
              documents: [...(getEventDocuments(event) || []), document],
            };
          }

          return {
            ...event,
            documents: (getEventDocuments(event) || []).map((eventDocument) =>
              areIdsEqual(getDocumentId(eventDocument), documentId)
                ? document
                : eventDocument
            ),
          };
        }),
      };
    case DETACH_DOCUMENT_FROM_EVENTS:
      return {
        ...state,
        events: Array.isArray(state.events)
          ? state.events.map((event) => ({
              ...event,
              documents: (getEventDocuments(event) || []).filter(
                (document) =>
                  !areIdsEqual(
                    getDocumentId(document),
                    getDocumentId(action.payload)
                  )
              ),
            }))
          : state.events,
      };
    case SET_FETCHING_EVENTS:
      return {
        ...state,
        fetching: action.payload,
      };
    case SET_FETCHING_EVENTS_ERROR:
      return {
        ...state,
        fetchingError: action.payload,
      };
    case SET_SHOW_EVENT_CREATION_SUGGESION:
      return {
        ...state,
        showEventCreateSuggesion: action.payload,
      };
    case SET_SHOW_ADD_EVENT:
      return {
        ...state,
        showAddEvent: action.payload,
      };
    case SET_SHOW_EDIT_EVENT:
      return {
        ...state,
        showEditEvent: action.payload,
      };
    case SET_EVENT_MODE:
      return {
        ...state,
        eventMode: action.payload,
      };
    case SET_ATTACHED_IDS:
      return {
        ...state,
        eventAttachedIds: action.payload,
      };
    case SET_RETURN_ON_SUCCESS:
      return {
        ...state,
        returnOnSuccess: action.payload,
      };
    default:
      return state;
  }
};

export const sGetEventsModule = (state) => state.events;
export const sGetEvents = (state) => sGetEventsModule(state).events;
export const sGetFetchingEvents = (state) => sGetEventsModule(state).fetching;
export const sGetFetchingEventsError = (state) =>
  sGetEventsModule(state).fetchingError;
export const sGetShowEventCreateSuggesion = (state) =>
  sGetEventsModule(state).showEventCreateSuggesion;
export const sGetShowAddEvent = (state) => sGetEventsModule(state).showAddEvent;
export const sGetShowEditEvent = (state) =>
  sGetEventsModule(state).showEditEvent;
export const sGetEventMode = (state) => sGetEventsModule(state).eventMode;
export const sGetEventAttachedIds = (state) =>
  sGetEventsModule(state).eventAttachedIds;
export const sGetReturnOnSuccess = (state) =>
  sGetEventsModule(state).returnOnSuccess;
