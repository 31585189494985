import React, { createElement } from 'react';

import {
  defaultFileIcon,
  DOCUMENT_EVENT_FILTER,
  DOCUMENT_PROFESSION_TYPE_FILTER,
  DOCUMENT_ROOM_FILTER,
  DOCUMENT_TYPE_FILTER,
  DOCUMENT_UPLOADED_DATE_FILTER,
  FILE_NAME_FILTER,
  FILE_TYPE_FILTER,
  FILE_UPLOADED_NAME_FILTER,
  fileIcons,
  getDocumentCompanyName,
  getDocumentEvents,
  getDocumentFileType,
  getDocumentName,
  getDocumentOwnedByName,
  getDocumentProfessionType,
  getDocumentRooms,
  getDocumentType,
  STATIC_COLUMN,
} from '../../utils/documents';

import {
  compareAlphabetically,
  toLowerCase,
  toUpperCase,
} from '../../utils/strings';
import { getRoomName } from '../../utils/rooms';
import { getEventName } from '../../utils/events';

import Company from '../../assets/icons/Company';
import OptionListFilter from './OptionListFilter';
import UploadedDateFilter from './UploadedDateFilter';
import Individual from '../../assets/icons/Individual';
import TableHeaderCell from '../desktop/DocumentTable/Table/TableHeaderCell';
import TableHeaderOptionsFilter from '../desktop/DocumentTable/Table/TableHeaderOptionsFilter';
import TableHeaderUploadedDateFilter from '../desktop/DocumentTable/Table/TableHeaderUploadedDateFilter';

export const onListItemClick = (
  filter,
  filters,
  onFiltersChanged,
  processFilter = (incomingFilter) => incomingFilter
) =>
  filters.map(processFilter).includes(processFilter(filter))
    ? onFiltersChanged(
        filters.filter(
          (_filter) => processFilter(_filter) !== processFilter(filter)
        )
      )
    : onFiltersChanged([...filters, processFilter(filter)]);

export const getFileTypeIcon = (fileType) =>
  (
    fileIcons.find(
      ({ fileType: extension }) =>
        toLowerCase(extension) === toLowerCase(fileType)
    ) || defaultFileIcon
  ).icon;

export const filterTypes = [
  {
    name: 'Filtype',
    type: FILE_TYPE_FILTER,
    filterComponent: OptionListFilter,
    description: 'Filterer etter filtype',
    desktopComponent: TableHeaderOptionsFilter,
    getFilterOptions: (documents, filters = [], onFiltersChanged) =>
      (documents || [])
        .reduce(
          (accumulator, document) =>
            accumulator.includes(getDocumentFileType(document))
              ? accumulator
              : [...accumulator, getDocumentFileType(document)],
          []
        )
        .sort(compareAlphabetically)
        .map((fileType) => ({
          name: fileType,
          label: toUpperCase(fileType),
          checked: filters.map(toLowerCase).includes(toLowerCase(fileType)),
          icon: createElement(getFileTypeIcon(fileType)),
          onClick: () =>
            onListItemClick(fileType, filters, onFiltersChanged, (filter) =>
              toUpperCase(filter)
            ),
        })),
    getProps: () => ({
      style: {
        paddingRight: 0,
      },
    }),
  },
  {
    name: '',
    desktopOnly: true,
    type: STATIC_COLUMN,
    desktopComponent: TableHeaderCell,
  },
  {
    name: 'Navn',
    type: FILE_NAME_FILTER,
    filterComponent: OptionListFilter,
    description: 'Filterer etter filnavn',
    desktopComponent: TableHeaderOptionsFilter,
    getProps: () => ({ style: { minWidth: 200, paddingLeft: 0 } }),
    getFilterOptions: (documents, filters = [], onFiltersChanged) =>
      (documents || [])
        .reduce(
          (options, document) =>
            options.includes(getDocumentName(document))
              ? options
              : [...options, getDocumentName(document)],
          []
        )
        .map((documentName) => ({
          name: documentName,
          label: documentName,
          checked: filters.includes(documentName),
          icon: createElement(defaultFileIcon.icon),
          onClick: () =>
            onListItemClick(documentName, filters, onFiltersChanged),
        }))
        .sort((a, b) => compareAlphabetically(a.name, b.name)),
  },
  {
    name: 'Lagt til',
    description: 'Filterer etter dato',
    type: DOCUMENT_UPLOADED_DATE_FILTER,
    filterComponent: UploadedDateFilter,
    desktopComponent: TableHeaderUploadedDateFilter,
    getProps: (filters, onFiltersChanged) => ({
      filters: filters[DOCUMENT_UPLOADED_DATE_FILTER],
      onFiltersChanged: (updatedFilters) =>
        onFiltersChanged({
          ...filters,
          [DOCUMENT_UPLOADED_DATE_FILTER]: updatedFilters,
        }),
    }),
  },
  {
    name: 'Avsender',
    type: FILE_UPLOADED_NAME_FILTER,
    filterComponent: OptionListFilter,
    description: 'Filterer etter avsender',
    desktopComponent: TableHeaderOptionsFilter,
    getFilterOptions: (documents, filters = [], onFiltersChanged) =>
      (documents || [])
        .reduce((accumulator, document) => {
          const uploaderName =
            getDocumentOwnedByName(document) ||
            getDocumentCompanyName(document);

          const icon = getDocumentOwnedByName(document) ? (
            <Individual width={17} style={{ maxHeight: '25px' }} />
          ) : (
            <Company width={25} style={{ maxHeight: '25px' }} />
          );

          return accumulator.find((item) => item.uploaderName === uploaderName)
            ? accumulator
            : [...accumulator, { uploaderName, icon }];
        }, [])
        .sort((a, b) => compareAlphabetically(a.uploaderName, b.uploaderName))
        .map(({ icon, uploaderName }) => ({
          icon,
          name: uploaderName,
          label: uploaderName,
          checked: filters.includes(uploaderName),
          onClick: () =>
            onListItemClick(uploaderName, filters, onFiltersChanged),
        })),
  },
  {
    name: 'Dokumenttype',
    type: DOCUMENT_TYPE_FILTER,
    filterComponent: OptionListFilter,
    description: 'Filterer etter dokumenttype',
    desktopComponent: TableHeaderOptionsFilter,
    getFilterOptions: (documents, filters = [], onFiltersChanged) =>
      (documents || [])
        .reduce(
          (accumulator, document) =>
            accumulator.includes(getDocumentType(document))
              ? accumulator
              : [...accumulator, getDocumentType(document)],
          []
        )
        .sort(compareAlphabetically)
        .map((documentType) => ({
          name: documentType,
          label: documentType,
          checked: filters.includes(documentType),
          onClick: () =>
            onListItemClick(documentType, filters, onFiltersChanged),
        })),
  },
  {
    name: 'Fag',
    filterComponent: OptionListFilter,
    description: 'Filterer etter fag',
    type: DOCUMENT_PROFESSION_TYPE_FILTER,
    desktopComponent: TableHeaderOptionsFilter,
    getFilterOptions: (documents, filters = [], onFiltersChanged) =>
      (documents || [])
        .reduce(
          (accumulator, document) =>
            accumulator.includes(getDocumentProfessionType(document))
              ? accumulator
              : [...accumulator, getDocumentProfessionType(document)],
          []
        )
        .sort(compareAlphabetically)
        .map((professionType) => ({
          name: professionType,
          label: professionType,
          checked: filters.includes(professionType),
          onClick: () =>
            onListItemClick(professionType, filters, onFiltersChanged),
        })),
  },
  {
    name: 'Hendelse',
    type: DOCUMENT_EVENT_FILTER,
    filterComponent: OptionListFilter,
    description: 'Filterer etter hendelse',
    desktopComponent: TableHeaderOptionsFilter,
    getFilterOptions: (documents, filters = [], onFiltersChange) =>
      (documents || [])
        .reduce(
          (accumulator, document) => [
            ...accumulator,
            ...(getDocumentEvents(document) || [])
              .filter((event) => !accumulator.includes(getEventName(event)))
              .map(getEventName),
          ],
          []
        )
        .sort(compareAlphabetically)
        .map((eventName) => ({
          name: eventName,
          label: eventName,
          checked: filters.includes(eventName),
          onClick: () => onListItemClick(eventName, filters, onFiltersChange),
        })),
  },
  {
    name: 'Rom',
    type: DOCUMENT_ROOM_FILTER,
    filterComponent: OptionListFilter,
    description: 'Filterer etter rom',
    desktopComponent: TableHeaderOptionsFilter,
    getFilterOptions: (documents, filters = [], onFiltersChange) =>
      (documents || [])
        .reduce(
          (accumulator, document) => [
            ...accumulator,
            ...(getDocumentRooms(document) || [])
              .filter((room) => !accumulator.includes(getRoomName(room)))
              .map(getRoomName),
          ],
          []
        )
        .sort(compareAlphabetically)
        .map((roomName) => ({
          name: roomName,
          label: roomName,
          checked: filters.includes(roomName),
          onClick: () => onListItemClick(roomName, filters, onFiltersChange),
        })),
  },
];

export const SummaryColumns = [
  {
    name: 'Navn',
    type: 'FILE_NAME',
    styles: { paddingLeft: 0 },
  },
  {
    name: 'Avsender',
    type: 'FILE_UPLOADED_NAME',
  },
  {
    name: 'Dokumenttype',
    type: 'DOCUMENT_TYPE',
  },
  {
    name: 'Hendelse',
    type: 'DOCUMENT_EVENT',
  },
  {
    name: 'Rom',
    type: 'DOCUMENT_ROOM',
  },
  {
    name: '',
    type: 'DOCUMENT_ACTION',
  },
];

export const filterColumns = ({
  filterType,
  showRoomsColumn,
  showEventsColumn,
  showProfessionColumn,
  showUploadedDateColumn,
  showUploaderNameColumn,
}) => {
  const typeMap = {
    DOCUMENT_PROFESSION_TYPE_FILTER: showProfessionColumn,
    DOCUMENT_UPLOADED_DATE_FILTER: showUploadedDateColumn,
    FILE_UPLOADED_NAME_FILTER: showUploaderNameColumn,
    DOCUMENT_EVENT_FILTER: showEventsColumn,
    DOCUMENT_ROOM_FILTER: showRoomsColumn,
  };

  return Object.keys(typeMap).includes(filterType.type)
    ? typeMap[filterType.type]
    : true;
};
