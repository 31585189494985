import React, {
  createElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { useFlag } from '@unleash/proxy-client-react';
import InputField from '@vb/components/build/components/forms/inputs/InputField/InputField';
import BreadCrumbs from '@componentsV2/BreadCrumbs';
import BreadCrumbsFullPath from '@componentsV2/BreadCrumbsFullPath';
import useFetchEffect from '../../../../hooks/useFetchEffect';
import Page from '../../../../components/layouts/WithHeader';
import styles from './style';
import Box from '../../../../components/atomic/Box';
import Close from '../../../../assets/icons/Close';
import BoligmappaPlusLogo from '../../../../assets/images/Boligmappa-Plus-Logo.png';
import CallToAction from '../../../../componentsV2/BoligmappaPlus/CallToAction';
import SearchIcon from '../../../../assets/icons/Search';
import Collapse from '../../../../components/atomic/Collapse';
import TextField from '../../../../components/atomic/TextField';
import InputAdornment from '../../../../components/atomic/InputAdornment';
// import SearchField from '../../../../../components/SearchField';
import UploadArrowIcon from '../../../../assets/icons/UploadArrowIcon';
import { AddNewButton } from '../../../../components/Buttons';
import ErrorContainer from '../../../../components/ErrorContainer';
import {
  fetchPersonalDocuments as apiFetchPersonalDocuments,
  fetchPersonalFolders as apiFetchPersonalFolders,
} from '../../../../api/propertyowners';
import { fetchProperties as apiFetchProperties } from '../../../../api/properties';
import {
  sGetFetchingPersonalDocuments,
  sGetFetchingPersonalDocumentsError,
  sGetPersonalDocumentFolders,
  sGetPersonalDocumentFoldersToRender,
  sGetPersonalDocuments,
  sGetPersonalFoldersBreadCrumbs,
} from '../../../../store/reducers/personalDocuments';
import {
  acDeletePersonalDocument,
  acSetFetchingPersonalDocuments,
  acSetFetchingPersonalDocumentsError,
  acSetPersonalDocumentFolders,
  acSetPersonalDocumentFoldersToRender,
  acSetPersonalDocuments,
  acDeletePersonalDocumentFolder,
  acSetSelectedPersonalFolderId,
  acSetPersonalFoldersBreadCrumbs,
} from '../../../../store/actions/personalDocuments';
import {
  acSetDocumentSearchText,
  acSetDocuments,
  acSetFetchingDocuments,
} from '../../../../store/actions/documents';
import useDesktop from '../../../../hooks/useDesktop';
import {
  getDocumentList,
  getDocumentTable,
} from '../../../../utils/DocumentTable';
import {
  acSetProperties,
  acSetFetchingProperties,
  acSetFetchingPropertiesError,
} from '../../../../store/actions/properties';
import {
  sGetFetchingProperties,
  sGetPropertiesLoaded,
  sGetFetchingPropertiesError,
} from '../../../../store/reducers/properties';
import { parseQuery } from '../../../../utils/routes';
import ChapterTable from '../../../PrivateRoutes/Dashboard/Documents/ChapterTable';
import ChapterList from '../../../PrivateRoutes/Dashboard/Documents/ChapterList';
import { FileUploadContext } from '../../../../contexts/fileUpload';
import { sGetDocumentSearchText } from '../../../../store/reducers/documents';
import Typography from '../../../../components/atomic/Typography';
// import EmptyMessage from '../../../../../components/EmptyMessage';
// import ManWorkingImg from '../../../../../assets/images/mann-kontorpult.png';
import Hidden from '../../../../components/atomic/Hidden';
import AddContextMenu from '../../../PrivateRoutes/Dashboard/AddContextMenu';
import { useBoligmappaPlusSubscriptionStatus } from '../../../../store/hooks/useBoligmappaPlusSubscriptionStatus';
import { boligmappaPlusActionCallback } from '../../../../utils/boligmappaPlus';
import MobileDocumentSearch from '../../../PrivateRoutes/Dashboard/Documents/MobileDocumentSearch';
import matomo from '../../../../utils/matomo';
import useSnackbar from '../../../../hooks/useSnackbar';
import AddNewDocumentMenu from '../../../../components/AddNewDocumentMenu';
import useAddNewDocumentMenu from '../../../../hooks/useAddNewDocumentMenu';
import BaseDialogForm from '../../../../components/forms/BaseDialogForm';

const PersonalDocumentsPage = ({
  setPersonalDocumentFetching,
  setPersonalDocuments,
  setPersonalFoldersBreadCrumbs,
  setPersonalDocumentFolders,
  setPersonalDocumentFoldersToRender,
  setPersonalDocumentFetchingError,
  personalDocuments,
  personalDocumentfetching,
  personalDocumentFolders,
  personalDocumentFoldersToRender,
  personalFoldersBreadCrumbs,
  fetchingError,
  searchText,
  setSearchText,
  deletePersonalDocument,
  deletePersonalDocumentFolder,
  classes,
  setProperties,
  setFetchingProperties,
  setFetchingPropertiesError,
  propertiesLoaded,
  setSelectedPersonalFolderId,
}) => {
  const isBoligmappaPlusUser = useBoligmappaPlusSubscriptionStatus();
  const [currentDocFolderId, setCurrentDocFolderId] = useState(null);
  const [documentsToRender, setDocumentsToRender] = useState([]);
  const [personalDocumentLoading, setPersonalDocumentLoading] = useState(false);
  const isDesktop = useDesktop();
  const isExpandable = true;
  const { search } = useLocation();
  const parsedQuery = parseQuery(search);

  const {
    onFileUploadClick,
    directoryId,
    setDirectoryId,
    directoryTree,
    setDirectoryTree,
  } = useContext(FileUploadContext);
  const addNewDocRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState(searchText);
  const [searchFieldChecked, setSearchFieldChecked] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [stickyFiltersHeight, setStickyFiltersHeight] = useState(0);

  const {
    isAddingNewDoc,
    setIsAddingNewDoc,
    isCreatingName,
    setIsCreatingName,
    newFolderName,
    setNewFolderName,
    folderNameErrorMessage,
    isFolderNameError,
    onAddNewDoc,
    onFolderCreate,
    onCancelCreatingFolder,
  } = useAddNewDocumentMenu({
    currentDocFolderId,
    breadCrumbs: personalFoldersBreadCrumbs,
  });
  const history = useHistory();
  const folderFeatureFlag = useFlag('boligeier.folder.feature');

  const [showEmptyListMessage, setShowEmptyListMessage] = useState(
    personalDocuments && personalDocuments?.length === 0
  );

  const { chaptersMode } = parsedQuery || {};
  const { showErrorMessage } = useSnackbar();

  const getPersonalDocumentLoading = (data) => {
    setPersonalDocumentFetching(data);
    setPersonalDocumentLoading(data);
  };

  const onBackgroundClick = () => {
    if (searchFieldChecked && !(searchQuery?.length > 0)) {
      setSearchFieldChecked(false);
    }
  };
  const updateDocsAndFoldersToRender = (folderId) => {
    setCurrentDocFolderId(folderId);
    let foldersToRender = personalDocumentFolders;
    personalFoldersBreadCrumbs.forEach((breadcrumb) => {
      foldersToRender = foldersToRender.find(
        (el) => el.id === breadcrumb.id
      ).directories;
    });
    setPersonalDocumentFoldersToRender(foldersToRender);
    setSelectedPersonalFolderId(folderId);
    setDocumentsToRender(
      personalDocuments.filter((doc) => doc.directoryId === folderId)
    );
  };
  const onFolderChange = (id) => {
    const currentFolder = personalDocumentFoldersToRender.find(
      (folder) => folder.id === id
    );
    setPersonalFoldersBreadCrumbs([
      ...personalFoldersBreadCrumbs,
      currentFolder,
    ]);
  };

  const launchDocsAndFoldersState = ({ folders, docs }) => {
    if (folders) {
      setPersonalDocumentFolders(folders);
      setPersonalDocumentFoldersToRender(folders);
      setSelectedPersonalFolderId(null);
    }
    if (docs) {
      const docsToRender = docs.filter((el) => el.directoryId === null);
      setPersonalDocuments(docs);
      setDocumentsToRender(docsToRender);
    }
  };

  useFetchEffect({
    conditionFunction: () => personalDocuments?.length === 0,
    setFetchingFunction: (data) => getPersonalDocumentLoading(data),
    onSuccess: (data) => launchDocsAndFoldersState({ docs: data.documents }),
    onError: (error) => setPersonalDocumentFetchingError(error),
    apiFetchFunction: () => apiFetchPersonalDocuments(),
  });

  useFetchEffect({
    conditionFunction: () => personalDocumentFolders?.length === 0,
    setFetchingFunction: (data) => getPersonalDocumentLoading(data),
    onSuccess: (data) =>
      launchDocsAndFoldersState({ folders: data.directories.reverse() }),
    onError: (error) => setPersonalDocumentFetchingError(error),
    apiFetchFunction: () => apiFetchPersonalFolders(),
  });

  useEffect(() => {
    if (documentsToRender?.length < 1) {
      setShowEmptyListMessage(true);
    } else {
      setShowEmptyListMessage(false);
    }
  }, [documentsToRender]);

  useEffect(() => {
    setDocumentsToRender(
      personalDocuments.filter((doc) => doc.directoryId === currentDocFolderId)
    );
  }, [personalDocuments]);

  useEffect(() => {
    updateDocsAndFoldersToRender(
      personalFoldersBreadCrumbs[personalFoldersBreadCrumbs.length - 1]?.id ||
        null
    );
  }, [personalDocumentFolders]);
  useEffect(() => {
    updateDocsAndFoldersToRender(
      personalFoldersBreadCrumbs[personalFoldersBreadCrumbs.length - 1]?.id ||
        null
    );
  }, [personalFoldersBreadCrumbs]);

  useEffect(() => {
    if (history.action !== 'POP') {
      setPersonalFoldersBreadCrumbs([]);
    }
  }, [history]);

  useEffect(() => {
    const fetchProperties = async () => {
      setFetchingProperties(true);
      const [error, properties] = await apiFetchProperties();
      if (properties && !error) {
        setProperties(properties);
        setFetchingProperties(false);
      }
      if (error) {
        setFetchingPropertiesError(error);
        setFetchingProperties(false);
      }
    };
    if (!propertiesLoaded) {
      fetchProperties();
    }
  }, [propertiesLoaded]);

  // Todo: Old button handler, can be removed along with feature flag if feature released
  const onDcumentUpload = () => {
    if (isDesktop) {
      onFileUploadClick({
        query: {
          bulkUploadMode: true,
          source: 'table',
          newUpload: true,
          isRoomOrEvent: true,
        },
      });
    } else {
      onFileUploadClick({
        query: {
          bulkUploadMode: true,
          source: 'listView',
          newUpload: true,
          isRoomOrEvent: true,
        },
      });
    }
  };

  const onFolderUpload = () => {
    onFileUploadClick({
      query: {
        bulkUploadMode: true,
        source: 'table',
        newUpload: true,
        isRoomOrEvent: true,
        webkitdirectory: true,
        parentDirectoryId: currentDocFolderId,
        breadCrumbs: personalFoldersBreadCrumbs,
      },
    });
    setIsAddingNewDoc(false);
  };
  const onDocumentUpload = () => {
    if (isDesktop) {
      onFileUploadClick({
        query: {
          bulkUploadMode: true,
          source: 'table',
          newUpload: true,
          isRoomOrEvent: true,
        },
      });
    } else {
      onFileUploadClick({
        query: {
          bulkUploadMode: true,
          source: 'listView',
          newUpload: true,
          isRoomOrEvent: true,
        },
      });
    }
    setIsAddingNewDoc(false);
  };

  const renderMobileBreadCrumbs = () =>
    personalFoldersBreadCrumbs.length > 0 ? (
      <BreadCrumbs
        breadCrumbs={personalFoldersBreadCrumbs}
        breadCrumbsNames={personalFoldersBreadCrumbs.map((el) => el.name)}
        setBreadCrumbs={setPersonalFoldersBreadCrumbs}
        onlyBack
        pathClassName={classes.breadcrumbs}
      />
    ) : (
      <Typography
        className={isDesktop ? classes.headerTitle : classes.headerTitleMobile}
      >
        Alle dokumenter
      </Typography>
    );

  const oncloseSearch = () => {
    setSearchQuery('');
  };

  const onDocumentDeleted = (document) => {
    deletePersonalDocument(document);
  };

  const onFolderDelete = (folders) => {
    const folderIds = folders.map((el) => el.id);
    const deletedDocs = personalDocuments.filter((doc) =>
      folderIds.includes(doc.directoryId)
    );
    if ((deletedDocs || []).length > 0) {
      deletedDocs?.forEach((doc) => {
        deletePersonalDocument(doc);
      });
    }

    if (folders.length > 0) {
      folders.forEach((folder) => {
        deletePersonalDocumentFolder(folder);
      });
    }
  };

  const onSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setSearchText(event.target.value);
  };

  const handleQueryChange = () => {
    if (!(searchQuery?.length > 0)) {
      setSearchFieldChecked(!searchFieldChecked);
    } else {
      setSearchFieldChecked(true);
    }
  };

  const handleActionClick = async (type) => {
    matomo.clickEvent({
      category: 'Boligmappa+',
      action: 'Pay with Vipps',
    });
    await boligmappaPlusActionCallback(type, showErrorMessage);
  };

  const mobileHeaderSearch = () => (
    <MobileDocumentSearch
      fullWidth
      value={searchQuery}
      onChange={onSearchChange}
      placeholder="Søk i dokumenter"
      isSearching={isSearching}
      setIsSearching={setIsSearching}
    />
  );

  const renderBoligmappaPlusCTA = () => {
    return (
      <div className={classes.boligmappa_plus_subscription_section}>
        <h1>Mine dokumenter</h1>
        <div style={{ display: 'flex' }}>
          <div>
            <p className={classes.boligmappa_plus_personal_text}>
              Dokumenter i personlig område følger deg for alltid. Trygg og
              sikker lagring av viktige dokumenter med BankID. .{' '}
            </p>
            <button type="button" className={classes.boligmappa_plus_logo_btn}>
              Tilgjengelig med
              <img alt="Boligmappa+Logo" src={BoligmappaPlusLogo} />
            </button>
          </div>
          <div>
            <CallToAction
              isDesktop={isDesktop}
              className={classes.personal_area_call_to_action}
              onActionClick={handleActionClick}
            />
          </div>
        </div>
      </div>
    );
  };

  const changeBreadCrumb = (index) => {
    setPersonalFoldersBreadCrumbs(
      personalFoldersBreadCrumbs.slice(0, index + 1)
    );
  };

  const rootBreadCrumb = () => {
    setPersonalFoldersBreadCrumbs([]);
  };

  const renderHeaderBreadCrumbs = () => {
    const breadCrumbsNames = personalFoldersBreadCrumbs
      .map((el) => el.name)
      .slice(0, -1);
    return (
      <Box display="flex" alignItems="center">
        <>
          <div>
            <button
              type="button"
              className={classes.breadCrumbButton}
              onClick={rootBreadCrumb}
            >
              <p style={{ color: '#717171' }}>Alle dokumenter</p>
            </button>
          </div>
          {breadCrumbsNames.length > 0 && (
            <div style={{ color: '#717171' }}>/</div>
          )}
        </>
        {breadCrumbsNames.map((el, index) => (
          <>
            {index + 1 !== breadCrumbsNames.lenght && (
              <div>
                <button
                  type="button"
                  className={classes.breadCrumbButton}
                  onClick={() => changeBreadCrumb(index)}
                >
                  <p style={{ color: '#717171' }}>{el}</p>
                </button>
              </div>
            )}
            {index + 1 !== breadCrumbsNames.length && (
              <div style={{ color: '#717171' }}>/</div>
            )}
          </>
        ))}
      </Box>
    );
  };

  const renderTable = () => {
    if (chaptersMode) {
      return createElement(isDesktop ? ChapterTable : ChapterList, {
        documents: personalDocuments,
        fetchingDocuments: personalDocumentfetching,
        fetchingDocumentsError: fetchingError,
        searchQuery,
      });
    }
    return createElement(isDesktop ? getDocumentTable() : getDocumentList(), {
      fetching: personalDocumentLoading,
      documents: documentsToRender,
      onFolderChange,
      documentFolders: personalDocumentFoldersToRender,
      directoryId: directoryId || currentDocFolderId,
      setDirectoryId,
      searchQuery,
      directoryTree,
      setDirectoryTree,
      showBulkSelectionOptions: true,
      onDocumentDeleted,
      isPersonalDocuments: true,
      fetchingError,
      isFromPersonal: true,
      headerHeight,
      setStickyFiltersHeight,
      isBoligmappaPlusUser: isBoligmappaPlusUser === true,
      onFolderDelete,
    });
  };

  return (
    <Page
      topLevelLayout
      document
      showBackButton={false}
      headerTitle={renderMobileBreadCrumbs()}
      minFullHeight={isDesktop}
      headerSearch={mobileHeaderSearch()}
      isSearching={isSearching}
      setHeaderHeight={setHeaderHeight}
      noPadding={!isDesktop}
    >
      {fetchingError ? (
        <ErrorContainer
          paddingTop={isDesktop}
          errorResponse={fetchingError}
          style={{ paddingTop: isDesktop ? undefined : '15%' }}
        />
      ) : (
        <Box
          className={isDesktop && classes.mobilePadding}
          style={{ marginTop: stickyFiltersHeight }}
        >
          {!isBoligmappaPlusUser && isDesktop && renderBoligmappaPlusCTA()}
          {personalFoldersBreadCrumbs.length > 0 && renderHeaderBreadCrumbs()}
          <Box display="flex" alignItems="center" px={isDesktop ? 0 : 2}>
            {isDesktop &&
              (personalFoldersBreadCrumbs.length > 0 ? (
                <Box flex="none">
                  <Typography
                    className={
                      isDesktop
                        ? classes.headerTitle
                        : classes.headerTitleMobile
                    }
                  >
                    {personalFoldersBreadCrumbs?.slice(-1)[0].name}
                  </Typography>
                </Box>
              ) : (
                <Typography
                  className={
                    isDesktop ? classes.headerTitle : classes.headerTitleMobile
                  }
                >
                  Alle dokumenter
                </Typography>
              ))}

            <Box flex="50%" maxWidth={isDesktop ? '50%' : undefined}>
              {!chaptersMode && isDesktop && (
                <Box display="flex" onClick={() => handleQueryChange()}>
                  <Box className={classes.verticalSeperator} />
                  <Collapse
                    orientation="horizontal"
                    in={searchFieldChecked}
                    collapsedSize={40}
                    sx={{ overflow: 'visible' }}
                  >
                    <TextField
                      isExpandable={isExpandable}
                      value={searchQuery}
                      className={classes.queryInput}
                      onChange={(e) => onSearchChange(e)}
                      onBlur={() => onBackgroundClick()}
                      placeholder="Søk"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            disablePointerEvents
                            className={classes.searchButton}
                          >
                            &nbsp;
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: searchFieldChecked && (
                          <InputAdornment position="end">
                            <Close
                              className={classes.closeButton}
                              onClick={oncloseSearch}
                            />
                          </InputAdornment>
                        ),
                        className:
                          searchQuery && searchQuery !== ''
                            ? classes.textInputOnValue
                            : '',
                      }}
                    />
                  </Collapse>
                </Box>
              )}
            </Box>

            {isDesktop && !chaptersMode && (
              <Box flex="30%" textAlign="right" ml={2}>
                <AddNewButton
                  buttonRef={addNewDocRef}
                  text="Legg til"
                  onClick={folderFeatureFlag ? onAddNewDoc : onDcumentUpload}
                  flip
                  disabled={!isBoligmappaPlusUser}
                />
              </Box>
            )}
          </Box>

          <Box mt={1}>{renderTable()}</Box>
          {showEmptyListMessage && !personalDocumentfetching && (
            <Box
              onClick={onDocumentUpload}
              className={
                isBoligmappaPlusUser
                  ? classes.documentUploadbox
                  : classes.DisableddocumentUploadbox
              }
            >
              <UploadArrowIcon height="32px" width="32px" />
              <Typography sx={{ textAlign: 'center' }}>
                {isBoligmappaPlusUser
                  ? 'Last opp dokumenter til mappen!'
                  : 'Kjøp et abonnement for å laste opp dine første dokumenter.'}
              </Typography>
            </Box>
          )}

          {isDesktop && !personalDocumentfetching && (
            <BreadCrumbsFullPath
              breadCrumbs={personalFoldersBreadCrumbs}
              breadCrumbsNames={personalFoldersBreadCrumbs.map((el) => el.name)}
              setBreadCrumbs={setPersonalFoldersBreadCrumbs}
            />
          )}

          {isDesktop && (
            <AddNewDocumentMenu
              isAddingNewDoc={isAddingNewDoc}
              setIsAddingNewDoc={setIsAddingNewDoc}
              addNewDocRef={addNewDocRef}
              isCreatingName={isCreatingName}
              setIsCreatingName={setIsCreatingName}
              newFolderName={newFolderName}
              setNewFolderName={setNewFolderName}
              isFolderNameError={isFolderNameError}
              folderNameErrorMessage={folderNameErrorMessage}
              onDocumentUpload={onDocumentUpload}
              onFolderUpload={onFolderUpload}
              onFolderCreate={onFolderCreate}
              onCancelCreatingFolder={onCancelCreatingFolder}
            />
          )}

          {!isDesktop && (
            <BaseDialogForm
              open={isCreatingName}
              header="Ny mappe"
              onSubmit={onFolderCreate}
              onClose={onCancelCreatingFolder}
            >
              <div className={classes.mobileCreateFolderContent}>
                <InputField
                  type="text"
                  label="Opprett mappe:"
                  value={newFolderName}
                  placeholder="Skriv inn mappenavn"
                  onChange={(event) => setNewFolderName(event.target.value)}
                />
                {isFolderNameError && (
                  <p className={classes.errorMessage}>
                    {folderNameErrorMessage}
                  </p>
                )}
              </div>
            </BaseDialogForm>
          )}

          {/* Mobile only */}

          {!isDesktop && !isBoligmappaPlusUser && renderBoligmappaPlusCTA()}

          <Hidden mdUp>
            {!isDesktop && isBoligmappaPlusUser && (
              <AddContextMenu
                isFromPersonal
                isBoligmappaPlusUser={isBoligmappaPlusUser}
                onMobileFolderCreate={() => setIsCreatingName(true)}
                folderFeatureFlag={folderFeatureFlag}
              />
            )}
          </Hidden>
        </Box>
      )}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  fetchingError: sGetFetchingPersonalDocumentsError(state),
  personalDocumentfetching: sGetFetchingPersonalDocuments(state),
  personalDocuments: sGetPersonalDocuments(state),
  personalDocumentFolders: sGetPersonalDocumentFolders(state),
  personalDocumentFoldersToRender: sGetPersonalDocumentFoldersToRender(state),
  personalFoldersBreadCrumbs: sGetPersonalFoldersBreadCrumbs(state),
  searchText: sGetDocumentSearchText(state),
  fetchingProperties: sGetFetchingProperties(state),
  propertiesLoaded: sGetPropertiesLoaded(state),
  fetchingPropertiesError: sGetFetchingPropertiesError(state),
});

const mapDispatchToProps = (dispatch) => ({
  setPersonalDocumentFetchingError: (fetchingError) =>
    dispatch(acSetFetchingPersonalDocumentsError(fetchingError)),
  setDocuments: (documents) => dispatch(acSetDocuments(documents)),
  setPersonalDocuments: (documents) =>
    dispatch(acSetPersonalDocuments(documents)),
  setPersonalDocumentFolders: (folders) =>
    dispatch(acSetPersonalDocumentFolders(folders)),
  setPersonalDocumentFoldersToRender: (folders) =>
    dispatch(acSetPersonalDocumentFoldersToRender(folders)),
  setPersonalFoldersBreadCrumbs: (breadCrumbs) =>
    dispatch(acSetPersonalFoldersBreadCrumbs(breadCrumbs)),
  setFetching: (fetching) => dispatch(acSetFetchingDocuments(fetching)),
  setSearchText: (searchText) => dispatch(acSetDocumentSearchText(searchText)),
  setPersonalDocumentFetching: (fetching) =>
    dispatch(acSetFetchingPersonalDocuments(fetching)),
  deletePersonalDocument: (document) =>
    dispatch(acDeletePersonalDocument(document)),
  deletePersonalDocumentFolder: (folder) =>
    dispatch(acDeletePersonalDocumentFolder(folder)),
  setProperties: (properties) => dispatch(acSetProperties(properties)),
  setFetchingProperties: (fetching) =>
    dispatch(acSetFetchingProperties(fetching)),
  setFetchingPropertiesError: (error) =>
    dispatch(acSetFetchingPropertiesError(error)),
  setSelectedPersonalFolderId: (id) =>
    dispatch(acSetSelectedPersonalFolderId(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PersonalDocumentsPage));
