import React from 'react';

import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import styles from './style';
import List from '../atomic/List';
import ListItem from '../atomic/ListItem';
import Box from '../atomic/Box';
import Typography from '../atomic/Typography';
import Close from '../../assets/icons/Close';
import Button from '../atomic/Button';

const BottomContextMenu = ({
  open,
  setOpen = () => {},
  classes,
  onClose,
  children,
  className,
  paperClass,
  title = null,
  showAnchor = true,
  closeOnContentClick = false,
  onFiltersReset = () => {},
  isBulk,
  ...props
}) => {
  const toggleDrawer = (mode) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(mode);
  };

  const setOnDrawerClose = () => {
    setOpen(false);
    onFiltersReset();
  };

  const onDrawerClose = (event) =>
    onClose ? onClose(event) : setOnDrawerClose();

  const onContentClick = (event) =>
    closeOnContentClick ? onDrawerClose(event) : null;

  return (
    <SwipeableDrawer
      open={open}
      classes={{
        paper: clsx(classes.paper, paperClass),
      }}
      anchor="bottom"
      disableSwipeToOpen
      onOpen={toggleDrawer(true)}
      className={clsx(className, classes.container)}
      onClose={onDrawerClose}
      {...props}
    >
      <div
        role="presentation"
        onClick={onContentClick}
        onKeyDown={onContentClick}
      >
        {showAnchor && <div className={classes.touch} />}

        {isBulk ? (
          <Box
            sx={{
              padding: 10,
              paddingLeft: 15,
              paddingRight: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography fontSize={20} fontWeight={600}>
                Rediger
              </Typography>
              <Typography fontSize={16} fontWeight={500}>
                {title} Valgt
              </Typography>
            </Box>
            <Button onClick={onDrawerClose}>
              <Close />
            </Button>
          </Box>
        ) : (
          title && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <List className={classes.titleContainer}>
                <ListItem className={classes.title}>{title}</ListItem>
              </List>
              <Button onClick={onDrawerClose}>
                <Close />
              </Button>
            </Box>
          )
        )}

        {children}
      </div>
    </SwipeableDrawer>
  );
};

export default withStyles(styles)(BottomContextMenu);
