import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import Collapse from '../../atomic/Collapse';
import IconButton from '../../atomic/IconButton';
import { companySearch } from '../../../api/companies';
import TextField from '../../atomic/TextField';
import {
  calculateTotalVisibleCount,
  getSortOptionsForFindProfessionals,
} from '../../../utils/findProfessional';

import {
  sGetOwnedProperties,
  sGetActiveProperty,
  sGetSharedPrivatelyProperties,
} from '../../../store/reducers/properties';

import Grid from '../../atomic/Grid';
import { useFindProfessionalsStyles } from '../findProfessionalStyles';
import InputAdornment from '../../atomic/InputAdornment';
import Box from '../../atomic/Box';
import SearchIcon from '../../../assets/icons/Search';
import useDesktop from '../../../hooks/useDesktop';
import Close from '../../../assets/icons/Close';
import Button from '../../atomic/Button';
import { isIOSNativeEnvironment } from '../../../utils/nativeBridge';
import MoreFilters from './MoreFilters';
import matomo from '../../../utils/matomo';
import Filters from '../../../assets/icons/Filters';

const defaultDistance = 32000000;

const ProfessionalFilters = ({
  filteredProfessionals,
  nameFilterChecked,
  setNameFilterChecked,
  fetchStatus,
  setFetchStatus,
  setLoading,
  setMetaData,
  ownedProperties,
  activeProperty,
  sharedProperties,
  setError,
}) => {
  const isDesktop = useDesktop();
  const isExpandable = true;
  const [searchTerm, setSearchTerm] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [professionals, setProfessionals] = useState([]);
  const [propetyLocations, setPropertyLocations] = useState([]);
  const [showMoreFilters, setshowMoreFilters] = useState(false);
  const [moreFilterData, setMoreFilterData] = useState({
    distance: defaultDistance,
    certificates: '',
    franchiser: '',
    location: {},
    sortAttribute: getSortOptionsForFindProfessionals().find(
      (option) => option.label === 'Nærmest deg'
    ),
    professionTypes: '',
  });

  const isMobileSearch = useMediaQuery(`(max-width: 960px)`);
  const classes = useFindProfessionalsStyles();
  const IOSEnv = isIOSNativeEnvironment();

  const handleNameFilterChange = () => {
    if (!(searchTerm.length > 0)) {
      setNameFilterChecked(!nameFilterChecked);
    } else {
      setNameFilterChecked(true);
    }
  };

  const oncloseSearch = () => {
    setSearchTerm('');
  };

  useEffect(() => {
    const url = new URL(window.location);
    url.searchParams.set('searchTerm', searchTerm);
    window.history.replaceState(null, '', url.toString());
    if (!searchTerm) {
      setNameFilterChecked(false);
    }
  }, [searchTerm]);

  useState(() => {
    const url = new URL(window.location);
    const paramData = new URLSearchParams(url.search);
    if (paramData.get('searchTerm')) {
      setSearchTerm(paramData.get('searchTerm'));
      setNameFilterChecked(true);
    }
  }, []);

  const getCompanyData = async (
    term,
    location,
    distance,
    page,
    sort,
    order,
    professions,
    franchisers,
    certificates
  ) => {
    if (!fetchStatus) setLoading(true);
    if (page === 1) {
      window.scrollTo(0, 0);
    }
    const distanceValue = !location ? '' : distance;
    const [errorResponse, data] = await companySearch(
      term,
      location,
      distanceValue,
      page,
      sort,
      professions,
      order,
      franchisers,
      certificates
    );
    if (!errorResponse) {
      setPageNumber(page);
      if (page !== 1) {
        const companyList = [...professionals, ...data.response];
        filteredProfessionals(companyList);
        setProfessionals(companyList);
        setFetchStatus(false);
      } else {
        filteredProfessionals(data.response);
        setProfessionals(data.response);
      }
      if (data.response.length > 0)
        setMetaData({
          ...data.meta,
          totalVisibleCount: calculateTotalVisibleCount(
            data.meta,
            data.response.length
          ),
        });
      setLoading(false);
      setError(false);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    const propData = (
      Array.isArray(ownedProperties) && Array.isArray(sharedProperties)
        ? ownedProperties.concat(sharedProperties)
        : []
    ).map((locationData) => ({
      label: locationData?.name,
      value: locationData.address?.geoCode,
    }));

    if (activeProperty) {
      setPropertyLocations(propData);
      setMoreFilterData({
        ...moreFilterData,
        location: activeProperty
          ? {
              label: 'Sted:'.concat(activeProperty.name),
              value: activeProperty.address?.geoCode,
            }
          : '',
      });
    }
  }, [activeProperty, ownedProperties, sharedProperties]);

  const updateCompanyDataDetails = (selectedFilters) => {
    const url = new URL(window.location);
    const paramData = new URLSearchParams(url.search);
    getCompanyData(
      paramData.get('searchTerm') ? paramData.get('searchTerm') : searchTerm,
      selectedFilters.location?.value ?? '',
      selectedFilters.distance,
      1,
      selectedFilters.sortAttribute?.sort,
      selectedFilters.sortAttribute?.sortOrder,
      selectedFilters.professionTypes,
      selectedFilters.franchiser,
      selectedFilters.certificates
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const url = new URL(window.location);
      if (activeProperty) {
        const paramData = new URLSearchParams(url.search);
        const selectedFilters = JSON.parse(paramData.get('filters'));
        if (selectedFilters) {
          updateCompanyDataDetails(selectedFilters);
        } else {
          url.searchParams.set('filters', JSON.stringify(moreFilterData));
          window.history.replaceState(null, '', url.toString());
          updateCompanyDataDetails(moreFilterData);
        }
      }
    }, 600);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, moreFilterData]);

  useEffect(() => {
    if (fetchStatus) {
      setTimeout(() => {
        getCompanyData(
          searchTerm,
          moreFilterData.location?.value ?? '',
          moreFilterData.distance,
          pageNumber + 1,
          moreFilterData.sortAttribute?.sort,
          moreFilterData.sortAttribute?.sortOrder,
          moreFilterData.professionTypes,
          moreFilterData.franchiser,
          moreFilterData.certificates
        );
      }, 300);
    }
  }, [fetchStatus]);

  const onBackgroundClick = () => {
    if (nameFilterChecked && !(searchTerm.length > 0)) {
      setNameFilterChecked(false);
    }
  };

  const onSearchTextChange = (e) => {
    setSearchTerm(e.target.value);
    matomo.clickEvent({
      category: 'Find Professionals',
      name: 'Search for Professional',
      action: 'Search for Professional',
    });
  };

  const getMorefilterData = (data) => {
    setMoreFilterData(data);
  };

  // adding a util method to avoid nested conditoin issue
  const iff = (condition, then, otherwise) => (condition ? then : otherwise);

  const getMobileSearchClass = nameFilterChecked
    ? iff(
        IOSEnv,
        classes.companyNameSearchFilterOnExpandIOS,
        classes.companyNameSearchFilterOnExpand
      )
    : iff(
        IOSEnv,
        classes.companyNameSearchFilterIOS,
        classes.companyNameSearchFilter
      );

  return (
    <Box className={classes.sortFilterWrapper}>
      <Box className={classes.filterOptionsSection}>
        <Grid alignItems="center" container spacing={2}>
          <>
            <Button
              underline={false}
              onClick={() => setshowMoreFilters(true)}
              className={clsx([
                isDesktop
                  ? classes.moreFilterButton
                  : classes.moreFilterButtonMobile,
                !isDesktop && nameFilterChecked && 'hide',
              ])}
            >
              <Filters
                className={clsx([
                  classes.moreFilterIcon,
                  isDesktop && 'desktopIcon',
                ])}
                fill="#333333"
              />
              {isDesktop && <p>Filter og sortering</p>}
            </Button>
            <MoreFilters
              showMoreFilters={showMoreFilters}
              onClose={() => setshowMoreFilters(false)}
              onSubmit={(data) => getMorefilterData(data)}
              propertyLocationData={propetyLocations}
              currentProperty={activeProperty}
            />
          </>

          {isDesktop && (
            <Grid
              item
              xs={4}
              sm={4}
              md={3}
              onClick={() => handleNameFilterChange()}
            >
              <Box display="flex">
                <Box className={classes.verticalSeperator} />
                <Collapse
                  orientation="horizontal"
                  in={nameFilterChecked}
                  collapsedSize={40}
                  sx={{ overflow: 'visible' }}
                >
                  <TextField
                    isExpandable={isExpandable}
                    value={searchTerm}
                    className={classes.formInput}
                    onChange={(e) => onSearchTextChange(e)}
                    onBlur={() => onBackgroundClick()}
                    placeholder="Søk på bedriftsnavn"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" disablePointerEvents>
                          &nbsp;
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: nameFilterChecked && (
                        <InputAdornment position="end">
                          <Close
                            className={classes.closeButton}
                            onClick={oncloseSearch}
                          />
                        </InputAdornment>
                      ),
                      className:
                        searchTerm && searchTerm !== ''
                          ? classes.textInputOnValue
                          : '',
                    }}
                  />
                </Collapse>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      {isMobileSearch && (
        <Box
          onClick={() => handleNameFilterChange()}
          className={getMobileSearchClass}
        >
          <Collapse
            orientation="horizontal"
            in={nameFilterChecked}
            collapsedSize={40}
            sx={{ overflow: 'visible' }}
            className={classes.collapsWrapper}
          >
            <TextField
              isExpandable={isExpandable}
              value={searchTerm}
              className={classes.formInput}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="| Søk på bedriftsnavn"
              onBlur={() => onBackgroundClick()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" disablePointerEvents>
                    &nbsp;
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: nameFilterChecked && (
                  <InputAdornment position="end">
                    <IconButton onClick={oncloseSearch}>
                      <Close className={classes.closeButton} />
                    </IconButton>
                  </InputAdornment>
                ),
                className:
                  searchTerm && searchTerm !== ''
                    ? classes.textInputOnValue
                    : '',
              }}
              classes={{ baseTextField: classes.testww }}
            />
          </Collapse>
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  ownedProperties: sGetOwnedProperties(state),
  sharedProperties: sGetSharedPrivatelyProperties(state),
  activeProperty: sGetActiveProperty(state),
});

export default connect(mapStateToProps, null)(ProfessionalFilters);
