import styled from 'styled-components';

export const StyledOverlay = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  z-index: 999999;

  &.show {
    display: flex;
    justify-content: center;
  }
`;

export const StyledDialog = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 10%;
  max-width: 500px;
  background-color: #fff;
  padding: 24px;
  gap: 32px;
  overflow-y: auto;
  min-height: fit-content;
  max-height: 610px;

  @media (max-width: 767px) {
    max-width: 343px;
  }

  .row {
    display: flex;
    align-items: flex-start;
    gap: 8px;

    label,
    input {
      cursor: pointer;
    }

    label {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  input[type='checkbox'] {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: unset;
    -webkit-appearance: none;

    width: 100%;
    max-width: 20px;
    height: 20px;

    border: 2px solid #717171;
    border-radius: 4px;

    background-color: #fff;
  }

  input[type='checkbox']:checked {
    background-color: #164144;
    border-color: #164144;

    &:before {
      content: '';
      position: absolute;
      top: 6.5px;
      left: 2.5px;
      width: 2px;
      height: 7px;
      background-color: #fff;
      transform: rotateZ(135deg);
    }
    &:after {
      content: '';
      position: absolute;
      top: 7px;
      left: 3px;
      width: 13px;
      height: 2px;
      background-color: #fff;
      transform: rotateZ(135deg);
    }
  }

  .button_row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  .filled_button {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    background-color: #164144;
    padding: 12px 24px;
    border: unset;
    border-radius: 50px;
    cursor: pointer;

    &:hover {
      background-color: #193639;
    }

    &:active {
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    }

    &:disabled {
      background-color: #e6e6e6;
      cursor: not-allowed;
      box-shadow: none;
    }
  }
`;
