import React from 'react';

const CreditCard = ({
  width = '18px',
  height = '14px',
  fill = '#111',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M16 1.5H2C1.71875 1.5 1.5 1.75 1.5 2V3H16.5V2C16.5 1.75 16.25 1.5 16 1.5ZM16.5 6H1.5V12C1.5 12.2812 1.71875 12.5 2 12.5H16C16.25 12.5 16.5 12.2812 16.5 12V6ZM2 0H16C17.0938 0 18 0.90625 18 2V12C18 13.125 17.0938 14 16 14H2C0.875 14 0 13.125 0 12V2C0 0.90625 0.875 0 2 0ZM3.75 9.5H5.25C5.65625 9.5 6 9.84375 6 10.25C6 10.6875 5.65625 11 5.25 11H3.75C3.3125 11 3 10.6875 3 10.25C3 9.84375 3.3125 9.5 3.75 9.5ZM7.75 9.5H11.25C11.6562 9.5 12 9.84375 12 10.25C12 10.6875 11.6562 11 11.25 11H7.75C7.3125 11 7 10.6875 7 10.25C7 9.84375 7.3125 9.5 7.75 9.5Z"
      fill={fill}
    />
  </svg>
);
export default CreditCard;
