const styles = (theme) => ({
  status_header: {
    padding: 13,
    width: '100%',
  },
  status_body: {
    padding: '130px 0 0 280px',
  },
  status_body_mobile: {
    padding: '50px',
  },
  status_body_container: {
    display: 'flex',
    flexDirection: 'row',
  },
  status_body_container_mobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  status_body_image: {
    marginLeft: 'auto',
    marginRight: 246,
  },
  status_body_image_mobile: {
    display: 'inline',
    justifyContent: 'center',
  },
  main_header: {
    fontSize: '100px',
    fontWeight: 'bold',
    color: theme.palette.primary.darkGreen,
  },
  main_header_mobile: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: theme.palette.primary.darkGreen,
  },
  sub_header: {
    fontSize: '35px',
    fontWeight: 'bold',
    color: theme.palette.primary.darkGreen,
  },
  sub_header_mobile: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.primary.darkGreen,
  },
  sub_sub_header: {
    fontSize: '25px',
    fontWeight: '500',
    color: theme.palette.primary.darkGreen,
  },
  sub_sub_header_mobile: {
    fontSize: '13px',
    marginTop: '20px',
  },
  email_form: {
    marginTop: 70,
  },
  email_form_header: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: theme.palette.primary.darkGreen,
  },
  email_form_header_mobile: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: theme.palette.primary.darkGreen,
    marginBottom: '10px',
  },
  email_form_sub_header_mobile: {
    fontSize: '12px',
  },
  email_section: {
    marginTop: 28,
    display: 'inline-flex',
  },
  email_address_name: {
    color: theme.palette.primary.darkGreen,
  },
  email_address_name_mobile: {
    marginTop: 30,
  },
  email_address_name_input: {
    color: theme.palette.primary.darkGreen,
    backgroundColor: theme.palette.primary.white,
    borderRadius: '25px',
    width: 282,
    height: 50,
  },
  send_button: {
    backgroundColor: theme.palette.primary.darkGreen,
    color: theme.palette.primary.white,
    width: 130,
    height: 50,
    marginTop: 15,
  },
  send_button_mobile: {
    backgroundColor: theme.palette.primary.darkGreen,
    color: theme.palette.primary.white,
    width: '100%',
    height: 50,
    marginTop: 24,
  },
  email_address_label: {
    color: theme.palette.primary.darkGreen,
    marginTop: '-5px',
  },
  logoImage: {
    display: 'inline',
    justifyContent: 'center',
  },
  email_form_sub_header: {
    color: theme.palette.primary.darkGreen,
    fontWeight: '500',
  },
});

export default styles;
