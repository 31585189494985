import React from 'react';

const FacebookIcon = ({ width = '30', height = '30', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 30 30"
    {...props}
  >
    <g id="Group_5">
      <g id="Bold_5">
        <path
          id="Shape_7"
          d="M264.438,18.2a.626.626,0,0,1-.625.55H261.25a.625.625,0,0,0-.625.625v10a.625.625,0,0,0,.625.625h7.5A1.25,1.25,0,0,0,270,28.75V1.25A1.25,1.25,0,0,0,268.75,0h-27.5A1.25,1.25,0,0,0,240,1.25v27.5A1.25,1.25,0,0,0,241.25,30H255a.625.625,0,0,0,.625-.625v-10A.625.625,0,0,0,255,18.75h-2.5a.625.625,0,0,1-.625-.625v-3.75a.625.625,0,0,1,.625-.625H255a.625.625,0,0,0,.625-.625V11.487a7.113,7.113,0,0,1,7.112-7.112h1.638A.624.624,0,0,1,265,5V8.75a.625.625,0,0,1-.625.625h-1.638a2.113,2.113,0,0,0-2.112,2.112v1.638a.625.625,0,0,0,.625.625h3.037a.624.624,0,0,1,.625.7Z"
          transform="translate(-240)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default FacebookIcon;
