export const defaultState = {
  unitFeatures: { fetching: true, loaded: false },
};

export const SET_UNIT_FEATURES = 'SET_UNIT_FEATURES';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_UNIT_FEATURES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const sGetUnitFeatures = (state) => state.unitFeatures;
