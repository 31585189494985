import React from 'react';

const UploadArrowIcon = ({
  width = '12',
  height = '13',
  fill = '#111',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 33 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.4297 4.6875L24.4297 12.125C24.9219 12.6719 24.9219 13.4922 24.375 13.9844C23.8281 14.4766 23.0078 14.4766 22.5156 13.9297L17.8125 8.89844V13.4375V20.4375C17.8125 21.2031 17.2109 21.75 16.5 21.75C15.7344 21.75 15.1875 21.2031 15.1875 20.4375V13.4375V8.89844L10.4297 13.9297C9.9375 14.4766 9.11719 14.4766 8.57031 13.9844C8.02344 13.4922 8.02344 12.6719 8.51562 12.125L15.5156 4.6875C15.7891 4.41406 16.1172 4.25 16.5 4.25C16.8281 4.25 17.1562 4.41406 17.4297 4.6875ZM7.3125 26.125H25.6875C26.3984 26.125 27 26.7266 27 27.4375C27 28.2031 26.3984 28.75 25.6875 28.75H7.3125C6.54688 28.75 6 28.2031 6 27.4375C6 26.7266 6.54688 26.125 7.3125 26.125Z"
      fill={fill}
    />
  </svg>
);

export default UploadArrowIcon;
