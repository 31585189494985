import { mapVariables } from './helpers';

export const sizeVariables = {
  borderDefault: '--hj-size-border-default',
  borderLarge: '--hj-size-border-large',
  iconDefaultSize: '--hj-size-icon-default',
  iconSmallSize: '--hj-size-icon-small',
  iconXsSize: '--hj-size-icon-xs',
};

export const sizes = mapVariables<typeof sizeVariables>(sizeVariables);
