import React from 'react';
import Select from 'react-select';
import { withStyles } from '@material-ui/core';

import defaultStyles from './style';
import InputLabel from '../atomic/InputLabel';
import CustomOption from './Option';

const Autocomplete = ({
  label,
  theme,
  options,
  disabled,
  onChange,
  className,
  toShrink = true,
  styles = {},
  isClearable = true,
  placeholder = 'Velg',
  maxMenuHeight,
  otherComponents,
  ...props
}) => (
  <div className={className}>
    {label && <InputLabel shrink={toShrink}>{label}</InputLabel>}

    <Select
      styles={{
        ...defaultStyles(theme),
        ...styles,
      }}
      options={options}
      onChange={onChange}
      isDisabled={disabled}
      placeholder={placeholder}
      isClearable={isClearable}
      maxMenuHeight={maxMenuHeight}
      components={{ Option: CustomOption, ...otherComponents }}
      {...props}
    />
  </div>
);

export default withStyles({}, { withTheme: true })(Autocomplete);
