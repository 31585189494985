import React from 'react';

const ArrowRight = ({ width = '1.2rem', height = '1.2rem', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    {...props}
  >
    <path
      d="M4.75223 4.27701L0.500689 0.450621C0.307633 0.276869 -4.93544e-09 0.413878 -8.03271e-09 0.673609L-9.92912e-08 8.32639C-1.02388e-07 8.58612 0.307633 8.72313 0.50069 8.54938L4.75224 4.72299C4.88465 4.60382 4.88465 4.39618 4.75223 4.27701Z"
      fill="#13142A"
    />
  </svg>
);
export default ArrowRight;
