import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles, Link as BaseLink } from '@material-ui/core';

import styles from './style';

import { openLinkRequest, isNativeEnv } from '../../../utils/nativeBridge';
import Button from '../Button';

export const systemPrefixes = ['tel', 'http', 'mailto'];

const Link = forwardRef(
  (
    {
      classes,
      children,
      disabled,
      className,
      to = null,
      href = null,
      underline = true,
      isNoPadding = false,
      isExternal = false,
      openInNewTab = false,
      inheritColor = false,
      preventEncode = false,
      onClick,
      ...props
    },
    ref
  ) => {
    const getPath = () => {
      const path = to || href;

      if (
        isExternal &&
        systemPrefixes.some((prefix) => String(path).startsWith(prefix))
      ) {
        return path;
      }

      if (isExternal && !String(path).startsWith('http')) {
        return `https://${path}`;
      }

      return path;
    };

    const nativeEnv = isNativeEnv();

    const baseConfig = {
      ref,
      className: clsx(className, classes.link, underline && classes.underline),
      style: {
        color: inheritColor ? 'inherit' : undefined,
      },
      ...props,
    };

    const linkConfig = {
      to: getPath(),
      href: getPath(),
      target: openInNewTab ? '_blank' : undefined,
      rel: openInNewTab ? 'noopener noreferrer' : undefined,
      ...baseConfig,
    };

    const onLinkClick = (e) => {
      if (!disabled && typeof onClick === 'function') {
        onClick();
      }
      if (!disabled && nativeEnv && (openInNewTab || isExternal)) {
        e.preventDefault();
        openLinkRequest(getPath(), preventEncode);
      }
    };

    if (isExternal && nativeEnv) {
      return (
        <Button noPadding={isNoPadding} onClick={onLinkClick} {...baseConfig}>
          {children}
        </Button>
      );
    }

    if (isExternal) {
      return (
        <BaseLink disabled={disabled} onClick={onLinkClick} {...linkConfig}>
          {children}
        </BaseLink>
      );
    }

    return (
      <RouterLink onClick={onLinkClick} disabled={disabled} {...linkConfig}>
        {children}
      </RouterLink>
    );
  }
);

export default withStyles(styles)(Link);
