import { formattedDate } from '../../../utils/dates';

export const dateLabels = {
  end: 'Lastet opp før',
  start: 'Lastet opp etter',
};

export const getFilterValue = (key, filters) =>
  (filters.find((filter) => filter.key === key) || { value: '' }).value;

export const onFilterChange = (e, key, filters, onFiltersChanged) => {
  if (!filters.find((filter) => filter.key === key)) {
    onFiltersChanged([
      ...filters,
      {
        key,
        value: e.target.value,
        label: `${dateLabels[key]}: ${formattedDate(e.target.value)}`,
      },
    ]);
  } else {
    onFiltersChanged(
      filters.map((filter) =>
        filter.key === key
          ? {
              ...filter,
              value: e.target.value,
              label: `${dateLabels[filter.key]}: ${formattedDate(
                e.target.value
              )}`,
            }
          : filter
      )
    );
  }
};
