const style = (theme) => ({
  title: {
    fontSize: '32px',
  },
  headline: {
    margin: '10px 0 40px',
  },
  tableWrapper: {
    border: '1px solid #254E52',
    borderRadius: '8px',
    padding: '24px',
    marginBottom: '40px',
    '&.mobile': {
      padding: '8px',
      marginBottom: '24px',
    },
  },
  blockDescription: {
    fontSize: '16px',
    '&.mobile': {
      fontSize: '14px',
    },
  },
  blockRow: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  baseRow: {
    display: 'flex',
    alignItems: 'center',
  },
  rowBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  copyBoxContainer: {
    display: 'flex',
    alignSelf: 'flex-start',
    marginTop: '32px',
    width: '100%',
    maxWidth: '412px',
  },
  morePublicInfoIcon: {
    display: 'flex',
    transition: '0.2s',
    '&.active': {
      transform: 'rotate(90deg)',
    },
  },
  morePublicInfo: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'unset',
    marginTop: '24px',
    '&:hover': {
      backgroundColor: 'unset',
    },
    '&.mobile': {
      marginTop: '15px',
      padding: 'unset',
    },
  },
  publicShareList: {
    maxHeight: '0px',
    transition: '0.3s',
    overflow: 'hidden',
    '&.active': {
      maxHeight: '100vh',
    },
  },
  activeStatus: {
    backgroundColor: '#d0ece7',
    padding: '3px 20px',
    fontSize: '12px',
    color: '#13A085',
    fontWeight: 'bold',
    borderRadius: '4px',
  },
  linkText: {
    fontWeight: 500,
    marginLeft: '16px',
    '&.mobile': {
      marginLeft: '8px',
    },
  },
  controlBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  moreButton: {
    padding: 0,
  },
  moreButtonIcon: {
    borderRadius: '50%',
    color: '#8D8D8D',
    backgroundColor: theme.palette.primary.greyLight,
    cursor: 'pointer',
    transition: '0.5s',
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
  },
  subHeadline: {
    fontSize: '14px',
    margin: '35px 0 8px',
    color: '#000',
    '&.mobile': {
      marginTop: '24px',
    },
  },
  placeholderText: {
    fontSize: '13px',
    fontWeight: 800,
    color: '#8D8D8D',
  },
  shareRow: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '&.mobile': {
      alignItems: 'center',
    },
  },
  requestRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 16px 16px 19px',
    backgroundColor: '#FFEDE0',
    borderRadius: '3px',
    marginBottom: '8px',
    '&.mobile': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  requestDescription: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '12px',
    '&.mobile': {
      alignItems: 'flex-start',
    },
  },
  requestIcon: {
    display: 'flex',
  },
  requestButton: {
    padding: 'unset',
    fontWeight: 400,
    textDecoration: 'unset',
    borderBottom: '2px solid #BBBCD0',
    borderRadius: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
    },
    '&.mobile': {
      marginLeft: '37px',
    },
  },
  pendingRequestTxt: {
    fontWeight: 400,
  },
  addShare: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '8px 24px',
    minWidth: '156px',
  },
  addShareMobile: {
    minWidth: 'unset',
    width: '35px',
    height: '35px',
  },
  progressButtonTxt: {
    fontStyle: 'normal',
    fontWeight: '800',
    marginLeft: '13px',
  },
  introButtonArea: {
    display: 'flex',
    justifyContent: 'center',
  },
  detailsButtonArea: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  detailsButtonAreaMobile: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '27px 0 27px 0 ',
  },
  shareIcon: {
    margin: '4px 10px 0 0 ',
    height: '19px',
  },
  iconButtonStyle: {
    padding: '7px',
    margin: '0 0 13px 17px',
  },
  iconArea: {
    margin: '2px',
  },
  tooltip: {
    backgroundColor: theme.palette.primary.white,
  },
  privateShareImage: {
    top: '94px',
    left: '848px',
    width: '344px',
    height: '250px',
    marginTop: '20px',
  },
  container: {
    display: 'flex',
  },
});

export default style;
