import React from 'react';

const FileVideoIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 16" {...props}>
    <path
      d="M2 0H7V4C7 4.5625 7.4375 5 8 5H12V14C12 15.125 11.0938 16 10 16H2C0.875 16 0 15.125 0 14V2C0 0.90625 0.875 0 2 0ZM8 0L12 4H8V0ZM2 9V12C2 12.5625 2.4375 13 3 13H6C6.53125 13 7 12.5625 7 12V9C7 8.46875 6.53125 8 6 8H3C2.4375 8 2 8.46875 2 9ZM9.375 12.4375C9.4375 12.5 9.53125 12.5 9.59375 12.5C9.8125 12.5 10 12.3438 10 12.125V8.90625C10 8.6875 9.8125 8.5 9.59375 8.5C9.53125 8.5 9.4375 8.53125 9.375 8.59375L8 9.5V11.5L9.375 12.4375Z"
      fill="#1B88C2"
    />
  </svg>
);

export default FileVideoIcon;
