const styles = (theme) => ({
  root: {
    display: 'flex',
    padding: '12px 0',
    overflowX: 'auto',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    paddingLeft: theme.spacing(0.3),
    background: theme.palette.primary.white,
  },
});

export default styles;
