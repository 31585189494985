import { css } from '@linaria/core';

export const baseButton = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 8px;
  &:hover {
    cursor: pointer;
  }
`;
