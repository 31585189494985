const styles = () => ({
  icon: {
    width: '100%',
    height: '100%',
    maxWidth: '1.2rem',
    maxHeight: '1.2rem',
  },
  label: {
    color: '#333333',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '25px',
    letterSpacing: '-0.01em',
    '@media only screen and (max-width: 360px)': {
      fontSize: '14px',
    },
    '@media only screen and (max-width: 320px)': {
      fontSize: '13px',
    },
  },
  childrenItems: {
    display: 'flex',
    flexDirection: 'column',
    width: '168px',
    fontSize: '16px',
    fontWeight: '800',
    lineHeight: '25px',
    letterSpacing: '-0.01em',
    '@media only screen and (max-width: 360px)': {
      fontSize: '14px',
    },
    '@media only screen and (max-width: 320px)': {
      fontSize: '13px',
    },
  },
});

export default styles;
