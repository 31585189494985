const styles = {
  image: ({ fullscreenMode }) => ({
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    objectFit: fullscreenMode ? 'scale-down' : 'cover',
  }),
};

export default styles;
