import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';

import {
  getHttpErrorStatus,
  isHttpUnauthenticatedError,
} from '../../utils/requests';

import {
  hasPassedIdleTimeThreshold,
  storeTokenUpdatedTimestamp,
  syncRefreshTokenWithInterval,
  retrieveTokensUpdatedTimestamp,
} from '../../utils/auth';

import Logout from './Logout';
import SignUp from './SignUp';
import Dashboard from './Dashboard';
import Properties from './Properties';
import LoadingScreen from '../../components/LoadingScreen';
import ProtectedRoute from '../../components/ProtectedRoute';
import CookieConsentDialog from '../../components/CookieConsent';
import { isNativeEnv } from '../../utils/nativeBridge';
import {
  eventEmitter,
  IOS_TOKENS_REFRESH_FINISHED,
  IOS_TOKENS_REFRESH_INPROGRESS,
  TOKENS_UPDATED,
} from '../../utils/eventEmitter';
import SessionExpiredPrompt from '../../components/SessionExpiredPrompt';
import EdgeImageUploadWarn from '../../components/Banners/EdgeImageUploadWarn';
import PropertyRefreshContextProvider from '../../components/PropertyRefreshContextProvider';
import {
  acApiHealthSetFetching,
  acApiHealthSetFetchingError,
  acSetApiHealth,
} from '../../store/actions/apiHealth';
import useFetchEffect from '../../hooks/useFetchEffect';
import { sGetApiHealth } from '../../store/reducers/apiHealth';
import { getApiHealthStatus } from '../../api/apiHealth';
import { fetchPropertyOwner as apiFetchPropertyOwner } from '../../api/propertyowners';
import sessionStorage from '../../utils/sessionStorage';
import { LOCAL_API_HEALTH_STATUS } from '../../constants';
import { enableHealthCheck } from '../../config';
import HjemlaLoginSnackBar from '../../components/HjemlaLoginSnackbar/HjemlaLoginSnackBar';
import PersonalDocuments from './PersonalDocuments';
import Document from './Dashboard/Documents/Document';
import OrderConfirmationPage from './InsurancePage/OrderConfirmationPage';
import { acSetAuthInfo } from '../../store/actions/auth';

// Milliseconds after app initialization during which reload should not happen
export const initializingThreshold = 10000;

export const PrivateRoutes = ({
  setApiHealthError,
  setApiHealthFetching,
  setApiHealthStatus,
  setAuthInfo,
}) => {
  const [loading, setLoading] = useState(true);
  const [initializedAt] = useState(Date.now());
  const history = useHistory();

  const shouldInitialize = (error) =>
    !isHttpUnauthenticatedError(getHttpErrorStatus(error));

  const callRefreshTokenEndpoint = async () => {
    // iOS shell handles tokens itself
    if (isNativeEnv()) {
      setLoading(false);
      return;
    }

    const [error] = await syncRefreshTokenWithInterval();

    if (shouldInitialize(error)) {
      setLoading(false);
    }
  };

  const onTokensUpdated = () => {
    if (
      isNativeEnv() &&
      Date.now() - initializedAt >= initializingThreshold &&
      hasPassedIdleTimeThreshold(retrieveTokensUpdatedTimestamp())
    ) {
      window.location = '/properties?refreshEnabled=true';
    }

    if (isNativeEnv()) {
      storeTokenUpdatedTimestamp();
    }
  };

  const onIosTokenRefresh = () => {
    setLoading(true);
  };

  const onIosTokenRecieved = () => {
    setLoading(false);
  };

  useFetchEffect({
    initialDataState: {},
    defaultDataState: {},
    initialFetchingState: true,
    apiFetchFunction: apiFetchPropertyOwner,
    onSuccess: (data) => setAuthInfo({ user: { ...data }, loaded: true }),
  });

  const emmiterFunctions = () => {
    eventEmitter.on(TOKENS_UPDATED, onTokensUpdated);
    eventEmitter.on(IOS_TOKENS_REFRESH_INPROGRESS, onIosTokenRefresh);
    eventEmitter.on(IOS_TOKENS_REFRESH_FINISHED, onIosTokenRecieved);
    return () => {
      eventEmitter.removeListener(
        IOS_TOKENS_REFRESH_INPROGRESS,
        onIosTokenRefresh
      );
      eventEmitter.removeListener(
        IOS_TOKENS_REFRESH_FINISHED,
        onIosTokenRecieved
      );
      eventEmitter.removeListener(TOKENS_UPDATED, onTokensUpdated);
    };
  };

  const setApiHealthStatusData = (data) => {
    setApiHealthStatus(data);
    sessionStorage.set(LOCAL_API_HEALTH_STATUS, data);
    if (data) {
      callRefreshTokenEndpoint();
      if (isNativeEnv() && !retrieveTokensUpdatedTimestamp()) {
        storeTokenUpdatedTimestamp();
      }
      return emmiterFunctions();
    }
    history.push('/status');
    return () => {};
  };

  const getApiStatus = async () => {
    setApiHealthFetching(true);
    setApiHealthError(null);
    if (enableHealthCheck === 'true') {
      if (!(sessionStorage.get(LOCAL_API_HEALTH_STATUS) === 'true')) {
        const [error, data] = await getApiHealthStatus();
        if (error) {
          setApiHealthError(error);
          setApiHealthStatusData(false);
        } else {
          setApiHealthStatusData(data);
        }
        setApiHealthFetching(false);
      } else {
        setApiHealthStatusData(true);
        setApiHealthFetching(false);
      }
    } else {
      setApiHealthStatusData(true);
    }
  };

  useEffect(() => {
    getApiStatus();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <PropertyRefreshContextProvider>
      <HjemlaLoginSnackBar />

      {isNativeEnv() ? null : <CookieConsentDialog />}

      <EdgeImageUploadWarn />

      <ProtectedRoute path="/properties" component={Properties} />
      <ProtectedRoute
        exact
        path="/personal-documents/:documentId"
        component={Document}
      />
      <ProtectedRoute
        exact
        path="/personal-documents"
        component={PersonalDocuments}
      />

      <ProtectedRoute
        path="/dashboard/:boligmappaNumber"
        component={Dashboard}
      />

      <ProtectedRoute
        exact
        path="/insurance/order-confirmation"
        component={OrderConfirmationPage}
      />

      <ProtectedRoute exact path="/logout" component={Logout} />

      <ProtectedRoute exact path="/signup" component={SignUp} />

      <SessionExpiredPrompt />
    </PropertyRefreshContextProvider>
  );
};

const mapStateToProps = (state) => ({
  isHealthy: sGetApiHealth(state),
});

const mapDispatchToProps = (dispatch) => ({
  setApiHealthStatus: (status) => dispatch(acSetApiHealth(status)),
  setApiHealthFetching: (fetching) =>
    dispatch(acApiHealthSetFetching(fetching)),
  setApiHealthError: (error) => dispatch(acApiHealthSetFetchingError(error)),
  setAuthInfo: (user) => dispatch(acSetAuthInfo(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoutes);
