const styles = (theme) => ({
  root: {
    backgroundColor: '#fff',
    overflowX: 'unset',
    '& .MuiTableCell-root': {
      fontSize: '13px',
    },
  },
  personalDocsTableContainer: {
    borderRadius: '8px 8px 0 0',
  },
  bulkCheckboxSelected: {
    marginLeft: '6px',
    marginTop: '0px',
    marginBottom: '0px',
  },
  snackbar: {
    top: '60px',
    '& #client-snackbar': {
      display: 'block',
      width: '660px',
      color: theme.palette.primary.black,
      closeIcon: {
        width: '1rem',
        height: '1rem',
        color: theme.palette.primary.black,
      },
      dismissButton: {
        fontWeight: 300,
        color: '#111111',
      },
    },
  },
  dismissButton: {
    fontWeight: 300,
    color: theme.palette.primary.black,
  },
  success: { backgroundColor: '#5DC9A2' },
  selectedDocCount: {
    margin: '0px 0px 4px 0px',
    fontWeight: 700,
    fontSize: '14px',
  },
  bulkCheckboxCell: {
    padding: '0px 0 5px 0',
    border: 0,
  },
  filtersHeaderRow: {
    top: '51px',
  },
  borderNone: {
    border: 0,
  },
  uploadMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '24px',
  },
  infoIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px',
  },
  closeIconButton: {
    padding: '8px',
    color: '#111111',
  },
  closeIcon: {
    width: '18px',
    height: '18px',
  },
  uploadMessageBlock: {
    paddingLeft: '16px',
  },
  uploadMessage: {
    marginLeft: '12px',
    flexGrow: 1,
  },
  uploadMessageRow: {
    backgroundColor: '#FBF3C1 !important',
  },
  uploadMessageRowError: {
    backgroundColor: '#F6D9D8 !important',
  },
  linkButton: {
    color: 'black',
  },
});

export default styles;
