const styles = {
  card: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '17px',
    '&:last-child': {
      marginBottom: '10px',
    },
  },
  imageLoading: {
    borderRadius: '6px',
  },
  textContainer: {
    display: 'flex',
    marginLeft: '26px',
    flexDirection: 'column',
    justifyContent: 'center',
  },
};

export default styles;
