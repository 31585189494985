import { SizeVariables, ColorVariables, BorderVariables } from '../themeTypes';

export const basePalette = {
  gray: {
    100: '#F7F8FD',
    200: '#F0F1F8',
    300: '#DDE2F1',
    400: '#BBBCD0',
    500: '#73748F',
    700: '#313245',
    900: '#333448',
  },
};

export const baseColors: Pick<
  ColorVariables,
  'primaryBoxShadow' | 'documentViewer'
> = {
  primaryBoxShadow: 'rgba(38, 39, 59, 0.16)',
  documentViewer: {
    background: basePalette.gray[900],
  },
};
export const baseSizes: Pick<
  SizeVariables,
  | 'iconDefaultSize'
  | 'iconSmallSize'
  | 'iconXsSize'
  | 'borderDefault'
  | 'borderLarge'
> = {
  iconDefaultSize: '24px',
  iconSmallSize: '16px',
  iconXsSize: '12px', 
  borderDefault: '1.5px',
  borderLarge: '2px',
};

export const baseBorders: Pick<BorderVariables, 'radius'> = {
  radius: {
    small: '4px',
    medium: '8px',
    large: '16px',
    full: '50px',
  },
};
