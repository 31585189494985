import {
  SET_SOLD_MARKET_EVENTS,
  SET_FOR_SALE_MARKET_EVENTS,
  CLEAR_MARKET_EVENTS,
} from '../reducers/marketEvents';

export const acSetSoldMarketEvents = (soldMarketEvents) => ({
  type: SET_SOLD_MARKET_EVENTS,
  payload: soldMarketEvents,
});

export const acSetForSaleMarketEvents = (forSaleMarketEvents) => ({
  type: SET_FOR_SALE_MARKET_EVENTS,
  payload: forSaleMarketEvents,
});

export const acClearMarketEvents = () => ({
  type: CLEAR_MARKET_EVENTS,
});
