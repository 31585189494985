import React from 'react';

const RightArrow = ({ width = '0.8rem', height = '0.8rem', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 9.03125C0 9.3125 0.125 9.5 0.375 9.59375C0.625 9.71875 0.875 9.65625 1.0625 9.46875L5.09375 5.4375C5.21875 5.3125 5.28125 5.1875 5.28125 5C5.28125 4.84375 5.21875 4.6875 5.09375 4.5625L1.0625 0.53125C0.875 0.34375 0.625 0.3125 0.375 0.40625C0.125 0.53125 0 0.71875 0 0.96875V9.03125Z"
      fill="#111111"
    />
  </svg>
);
export default RightArrow;
