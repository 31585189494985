import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import styles from './style';

const SeparatorLine = ({ classes, className, ...props }) => (
  <span className={clsx(className, classes.line)} {...props} />
);

export default withStyles(styles)(SeparatorLine);
