const styles = (theme) => ({
  listItem: {
    display: 'flex',
    padding: '20px 0',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.colors.whiteDarker}`,

    '&:last-child': {
      borderBottom: 'none',
    },
  },
  leftContainer: {
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.text.secondary,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  fileIconContainer: {
    display: 'flex',
    marginRight: '15px',
    marginLeft: '5px',
    flexDirection: 'column',
    justifyContent: 'start',
    color: theme.palette.primary.greyLighter,
  },
  primaryText: {
    fontWeight: 500,
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    overflowWrap: 'break-word',
    color: theme.palette.text.primary,
  },
  secondaryText: {
    fontSize: '12px',
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  rightContainer: {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.text.primary,
  },
});

export default styles;
