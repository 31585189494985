const styles = () => ({
  priceestimatecard: {
    marginBottom: '2px',
  },
  sectionBlur: {
    filter: 'blur(7px)',
    pointerEvents: 'none',
  },
  veridiCallToAction: {
    backgroundColor: '#fff!important',
    border: '2px solid #D9D9D9',
    borderRadius: '24px!important',
    maxWidth: '436px!important',
    top: '0 !important',
    left: 0,
    margin: '0 auto',

    '@media (max-width: 959px)': {
      position: 'absolute!important',
      bottom: 'unset!important',
      padding: '0!important',
      top: '100px !important',

      '& .collapsed_wrapper': {
        padding: '16px 24px',
      },
    },

    '@media (max-width: 480px)': {
      maxWidth: 'calc(100% - 30px)!important',

      '& .learn_more': {
        fontSize: '14px!important',
      },
    },

    '& .content': {
      maxWidth: '341px',
      margin: '0 auto',
      padding: '32px 20px!important',

      '@media (max-width: 640px)': {
        rowGap: '14px!important',
        padding: '20px!important',
      },

      '& h2': {
        fontSize: '26px',
        lineHeight: '40px',

        '@media (max-width: 640px)': {
          fontSize: '22px',
          lineHeight: '30px',
        },
      },

      '& .cta_button': {
        marginTop: '-15px',
      },

      '& .inputsWrapper': {
        flexDirection: 'column-reverse',
      },
    },
  },
  hjemlaLink: {
    color: '#000',
  },
  extraCtaContent: {
    color: '#000',
    textAlign: 'center',
    padding: '0 24px',
    backgroundColor: '#FBF3C1',
    borderRadius: '22px 22px 0 0',

    '& h2': {
      fontSize: '26px',
      lineHeight: '40px',
      margin: '22px 0 4px',

      '@media (max-width: 640px)': {
        fontSize: '22px',
        lineHeight: '30px',
        margin: '10px 0 4px',
      },
    },
    '& p': {
      lineHeight: '22px',
      margin: '0 0 10px',

      '@media (max-width: 640px)': {
        fontSize: '13px',
        lineHeight: '19px',
        margin: '0 0 5px',
      },
    },
  },
  veridiBoligmappaPlusSection: {
    position: 'relative',
  },
  veridiBoligmappaPlusTextSection: {
    width: '65%',
    minHeight: '340px',
    fontSize: '18px',
    '@media (max-width: 959px)': {
      width: '100%',
    },
  },
  greenText: {
    color: '#356E3C',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
});

export default styles;
