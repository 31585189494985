import React from 'react';
import { Bar } from 'react-chartjs-2';

const BarChart = ({ options, data, plugins, redraw }) => {
  return (
    <Bar options={options} data={data} plugins={plugins} redraw={redraw} />
  );
};

export default BarChart;
