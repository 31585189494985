import {
  SET_ACTIVE_CATEGORY,
  SET_CATEGORIES,
  SET_CATEGORY_ACTIVE_STEP,
  SET_CATEGORY_EVENT,
  SET_CATEGORY_COMPLETED,
  CLEAR_BYBF_EVENTS,
} from '../reducers/bybfEvents';

export const acBYBFEventsSetActiveCategory = (category) => ({
  type: SET_ACTIVE_CATEGORY,
  payload: category,
});

export const acBYBFEventsSetCategories = (categories) => ({
  type: SET_CATEGORIES,
  payload: categories,
});

export const acBYBFEventsSetCategoryActiveStep = (
  activeCategory,
  activeStep
) => ({
  type: SET_CATEGORY_ACTIVE_STEP,
  payload: {
    activeCategory,
    activeStep,
  },
});

export const acBYBFEventsSetCategoryEvent = (activeCategory, event) => ({
  type: SET_CATEGORY_EVENT,
  payload: {
    activeCategory,
    event,
  },
});

export const acBYBFEventsSetCategoryCompleted = (
  activeCategory,
  completed
) => ({
  type: SET_CATEGORY_COMPLETED,
  payload: {
    activeCategory,
    completed,
  },
});

export const acBYBFEventsClear = () => ({
  type: CLEAR_BYBF_EVENTS,
});
