const styles = (theme) => ({
  form: {
    marginTop: '30px',
  },
  error: {
    color: theme.palette.primary.redDark,
  },
  bottomMargin: '25px',
});

export default styles;
