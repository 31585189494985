export const zIndex = 1399;

const styles = (theme) => ({
  container: {
    height: '100%',
    position: 'relative',
    paddingBottom: '56.25%',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '75%',
    },
  },
  containerFullscreenMode: {
    height: '100%',
    overflow: 'auto',
    paddingBottom: '0%',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0%',
    },
  },
  fullscreenMode: {
    zIndex,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'fixed',
    flexDirection: 'column',
    justifyContent: 'center',
    background: 'rgba(0,0,0,0.9)',

    '@supports (padding-top: env(safe-area-inset-top))': {
      paddingTop: 'env(safe-area-inset-top)',
    },
  },
  currentIndex: {
    zIndex: 1,
    top: '15px',
    right: '15px',
    fontSize: '10px',
    borderRadius: '3px',
    position: 'absolute',
    padding: '5px 10px 3px',
    display: 'inline-block',
    background: 'rgba(0,0,0,0.6)',
    color: theme.palette.primary.white,
  },
  currentIndexFullscreenMode: {
    left: 0,
    right: 0,
    width: '100%',
    fontSize: '18px',
    background: 'none',
    textAlign: 'center',
  },
  closeFullscreenModeIcon: {
    color: theme.palette.primary.white,
  },
  carousel: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute !important',
    background: theme.palette.primary.white,

    '&.slick-container': {
      height: '60%',
    },
    '& .slick-slider': {
      height: '100%',
    },
    '& .slick-list': {
      height: '100%',
    },
    '& .slick-track': {
      height: '100%',
    },
    '& .slick-slide > div': {
      height: '100%',
    },
    '& .slick-prev': {
      left: '5px',
      zIndex: 100,
    },
    '& .slick-next': {
      right: '5px',
    },
  },
  carouselFullscreenMode: {
    background: 'none',
  },
  actionContainer: {
    right: 0,
    bottom: 0,
    position: 'absolute',
    padding: theme.spacing(2),
    '@supports (bottom: env(safe-area-inset-bottom))': {
      bottom: 'calc(env(safe-area-inset-bottom, 100px) * 0.6)',
    },
  },
  actionIcon: {
    fill: theme.palette.primary.white,
    color: theme.palette.primary.white,
  },
  mainPictureLabel: {
    borderRadius: '100px',
    paddingRight: theme.spacing(2),
    color: theme.palette.primary.white,
    border: `1px solid ${theme.palette.primary.white}`,
  },
  desktop: {
    '& .slick-dots': {
      bottom: 0,
      '& button::before': {
        opacity: 1,
        color: theme.palette.primary.grey,
      },
      '& .slick-active': {
        '& button::before': {
          color: theme.palette.primary.white,
        },
      },
    },
    '& .slick-arrow::before': {
      color: theme.palette.primary.black,
    },
  },
  fullscreenHeader: {
    top: 0,
    zIndex,
    left: 0,
    width: '100%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    padding: theme.spacing(2),
    color: theme.palette.primary.white,
  },
});

export default styles;
