const styles = (theme) => ({
  title: {
    margin: '0',
  },
  header: {
    marginBottom: '20px',
  },
  emptyMessage: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '10%',
    },
  },
  buttonHubspotContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '25px 13px 0',
  },
  buttonHubspot: {
    width: '100%',
    height: '35px',
  },
  buttonHubspotContainerDesktop: {
    display: 'flex',
    padding: '25px 13px 0',
  },
  buttonHubspotDesktop: {
    width: '50%',
    height: '35px',
  },
  chaptersToggleButtonMobile: {
    maxWidth: '1rem',
    maxHeight: '1rem',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '1.3rem',
      maxHeight: '1.3rem',
    },
  },
  dividerContent: {
    fontSize: '13px',
  },
  mobilePadding: {
    '@supports (padding-top: env(safe-area-inset-top))': {
      paddingTop: 'env(safe-area-inset-top)',
    },
  },
  tabContent: {
    '& .MuiTabPanel-root': {
      padding: '0px',
    },
    width: '100%',
    marginTop: '25px',
  },
  tabs: {
    width: 'fit-content',
    border: '1px solid #e6e6e6',
    borderRadius: 25,
    minHeight: '20px !important',
    height: '31px',
    '& .MuiTab-root.Mui-selected': {
      backgroundColor: '#e6e6e6',
      borderRadius: '30px',
    },
    '& .MuiTab-root': {
      opacity: '100%',
    },
    '& .MuiTabPanel-root': {
      padding: '0px',
    },
  },
  tabHeaderBox: {
    display: 'flex',
    alignItems: 'center',
  },
  tabHeaderText: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'none',
  },
  tabHeaderNewText: {
    fontSize: '10px',
    fontWeight: '800',
    textTransform: 'none',
    marginLeft: '5px',
    backgroundColor: '#5dc9a2',
    height: '20px',
    width: '31px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabItem: {
    minHeight: '20px !important',
    height: '30px',
  },
  personalErrorHeader: {
    fontWeight: '600',
    color: '#000',
  },
  personalErrorText: {
    marginTop: '15px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#000',
  },
  personalEmptyContetnt: {
    maxWidth: '50%',
    margin: 'auto',
    marginTop: '40px !important',
  },
  headerTitle: {
    fontSize: '24px',
    fontWeight: '600',
  },
  headerTitleMobile: {
    fontSize: '18px',
    fontWeight: '600',
  },
  contentBox: {
    padding: '0px 150px',
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  headerBoxMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
    marginTop: '20px',
    padding: '0px 16px',
  },
});

export default styles;
