import { backendBaseUrl } from '../config';
import { request } from '../utils/http';

export const sendMail = (email, type) =>
  request({
    method: 'post',
    url: 'subscribemails',
    baseURL: backendBaseUrl,
    data: { email, type },
  })
    .then((response) => [null, response.data])
    .catch((error) => [error, null]);
