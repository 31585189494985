export const addStatus = (objectValue, status) => {
  const { Id, email, sharedBy, sharedDate, tenant, sharedWith } = objectValue;

  const newObject = {
    Id,
    name: tenant || null,
    email: sharedWith || email || null,
    sharedDate: sharedDate || null,
    sharedBy: sharedBy || null,
    status,
  };
  return newObject;
};

export const getName = (shareDetail) =>
  shareDetail && shareDetail.name ? shareDetail.name : null;

export const getEmail = (shareDetail) =>
  shareDetail && shareDetail.email ? shareDetail.email : '-';

export const getSharedBy = (shareDetail) =>
  shareDetail && shareDetail.sharedBy ? shareDetail.sharedBy : '-';

export const getSharedDate = (shareDetail) =>
  shareDetail && shareDetail.sharedDate ? shareDetail.sharedDate : null;

export const getStatus = (shareDetail) =>
  shareDetail && shareDetail.status ? shareDetail.status : null;

export const getId = (shareDetail) =>
  shareDetail && shareDetail.Id ? shareDetail.Id : null;
export const getDisplayName = (shareDetail) =>
  shareDetail && shareDetail.name ? shareDetail.name : getEmail(shareDetail);
