import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ReactDOM from 'react-dom';
import nb from 'date-fns/locale/nb';
import { Provider } from 'react-redux';
import momentLocale from 'moment/locale/nb';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

/* import OneSignal from 'react-onesignal'; */

import {
  apiBaseUrl,
  /*   onesignalappid, */
} from './config';

import {
  errorResponseInterceptor,
  successResponseInterceptor,
} from './utils/requests';

import {
  setAuthTokens,
  onTokensUpdated,
  retrieveIdToken,
  retrieveAccessToken,
  retrieveRefreshToken,
  retrieveIdTokenFromUrl,
  retrieveAccessTokenFromUrl,
} from './utils/auth';

import './index.css';
import store from './store';
import App from './components/App';
import { setAuthHeaders } from './utils/http';
import * as serviceWorker from './serviceWorker';
import { chartJsInit } from './utils/charts';
import { acSetAppVersionData } from './store/actions/appVersionData';

// One signal
/* if (onesignalappid !== 'false') {
  console.log('enabled one signal', onesignalappid);
  OneSignal.init({ appId: onesignalappid });
} */

// Chart.js init config
chartJsInit();

// Set global locale
moment.updateLocale('nb', momentLocale);

// for DatePickers
registerLocale('nb', nb);
setDefaultLocale('nb');

// global requests config
axios.defaults.baseURL = `${apiBaseUrl}`;
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.response.use(
  successResponseInterceptor,
  errorResponseInterceptor
);

global.React = React;

const refreshToken = retrieveRefreshToken();
const idToken = retrieveIdTokenFromUrl() || retrieveIdToken();
const accessToken = retrieveAccessTokenFromUrl() || retrieveAccessToken();

if (idToken || accessToken) {
  setAuthTokens({ idToken, accessToken });
}

if (idToken || accessToken || refreshToken) {
  setAuthHeaders({ idToken, accessToken, refreshToken });
}
const nativeInitHandler = (nativeAppData) => {
  if (nativeAppData) {
    store.dispatch(acSetAppVersionData(nativeAppData));
  }
};

window.onNativeInit = nativeInitHandler;

// Bind global function to allow iOS to communicate back to PWA
window.onTokensUpdated = onTokensUpdated;

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
