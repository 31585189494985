import React from 'react';

const Download = ({ width = '1.2rem', height = '1.2rem', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14.018 17.108"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      data-name="atom / icon / download / grey"
      d="M14.017,6.007h-4V0H4V6.007H0l7.009,7.009ZM0,15.105v2H14.017v-2Z"
    />
  </svg>
);

export default Download;
