const Styles = {
  activeLabel: {
    color: '#13A085',
    backgroundColor: '#b6e0db',
    borderRadius: '3px',
    height: '20px',
    width: '78px',
    textAlign: 'center',
    fontSize: '13px',
  },
  pendingLabel: {
    color: '#FBB03B',
    backgroundColor: '#ffe9c7',
    borderRadius: '3px',
    height: '20px',
    width: '78px',
    textAlign: 'center',
    fontSize: '13px',
  },
};
export default Styles;
