import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './style';
import Box from '../../atomic/Box';
import { filterTypes } from '../util';
import SelectedFilter from './SelectedFilterForDocumentView';
import { areFiltersEmpty } from '../../../utils/documents';

const SelectedFiltersForDocumentView = ({
  classes,
  filters,
  onFiltersChanged,
}) =>
  areFiltersEmpty(filters) ? null : (
    <Box className={classes.filters}>
      {filterTypes.map(({ type }) =>
        (filters[type] || []).map((filter) => (
          <Box
            key={`chosen-filter-${
              filter && filter.value ? filter.value : filter
            }`}
          >
            <SelectedFilter
              filter={filter}
              onCloseClick={() =>
                onFiltersChanged({
                  ...filters,
                  [type]: filters[type].filter((_filter) => _filter !== filter),
                })
              }
            />
          </Box>
        ))
      )}
    </Box>
  );

export default withStyles(styles)(SelectedFiltersForDocumentView);
