import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import React, { createElement, useState } from 'react';

import {
  getEventDescription,
  getEventDocuments,
  getEventId,
  getEventImages,
  getEventName,
} from '../../../../../utils/events';

import Box from '../../../../../components/atomic/Box';
import useDesktop from '../../../../../hooks/useDesktop';
import Paper from '../../../../../components/atomic/Paper';
import { areIdsEqual } from '../../../../../utils/strings';
import EventDetails from '../../../../../modules/EventDetails';
import useFetchEffect from '../../../../../hooks/useFetchEffect';
import { sGetEvents } from '../../../../../store/reducers/events';
import ImageGallery from '../../../../../components/ImageGallery';
import Typography from '../../../../../components/atomic/Typography';
import PageLayout from '../../../../../components/layouts/PublicPage';
import ErrorContainer from '../../../../../components/ErrorContainer';
import { padding } from '../../../../../components/atomic/Paper/style';
import EventDescription from '../../../../../modules/EventDescription';
import ContentLoader from '../../../../../components/loaders/ContentLoader';
import { fetchPublicEvent as apiFetchEvent } from '../../../../../api/events';
import {
  getDocumentList,
  getDocumentTable,
} from '../../../../../utils/DocumentTable';

const Event = ({ events }) => {
  const isDesktop = useDesktop();
  const { eventId } = useParams();

  const [uploadedImageIndex] = useState(null);

  const storedEvent = (events || []).find((event) =>
    areIdsEqual(getEventId(event), eventId)
  );

  const {
    fetching,
    fetchingError,
    data: fetchedEvent,
  } = useFetchEffect({
    initialFetchingState: !storedEvent,
    dependencies: [storedEvent, eventId],
    apiFetchFunction: () => apiFetchEvent(eventId),
    conditionFunction: ([_storedEvent, _eventId]) => _eventId && !_storedEvent,
  });

  const event = storedEvent || fetchedEvent;

  const headerTitle = fetching ? 'Hendelse' : getEventName(event);

  const images = getEventImages(event);

  const showImageGallery =
    fetching || (!fetching && Array.isArray(images) && images.length > 0);

  const documents = getEventDocuments(event);

  const showDocumentList =
    fetching || (!fetching && documents && documents.length > 0);

  const renderDocumentList = () =>
    createElement(isDesktop ? getDocumentTable() : getDocumentList(), {
      fetching,
      documents,
      isFiltersSticky: false,
      showOnPaper: false,
      error: fetchingError,
      publicSection: true,
      actionsEnabled: false,
    });

  if (fetchingError) {
    return <ErrorContainer errorResponse={fetchingError} />;
  }

  return (
    <PageLayout
      noPaddingTop
      minFullHeight
      showBackButton
      noPadding={!isDesktop}
      subPageHeader
      headerTitle={headerTitle}
      hidePublicHeader={isDesktop}
      lessMarginLeft={isDesktop}
      publicSection
      maxWidth="lg"
    >
      {showImageGallery && (
        <ImageGallery
          images={images}
          fetching={fetching}
          activeIndex={uploadedImageIndex}
        />
      )}

      {(fetching || getEventDescription(event)) && (
        <>
          <br />
          <Paper visible={!isDesktop}>
            <EventDescription event={event} fetching={fetching} />
          </Paper>
        </>
      )}

      {showDocumentList && (
        <>
          <br />
          <Paper visible={!isDesktop}>
            <Typography variant="h3">
              {fetching ? (
                <ContentLoader height={20} width={110} />
              ) : (
                'Dokumenter'
              )}
            </Typography>

            <Box mt={2} mx={`-${padding}px`}>
              {renderDocumentList()}
            </Box>
          </Paper>
        </>
      )}
      <br />
      <div>
        <Paper visible={!isDesktop}>
          <EventDetails fetching={fetching} event={event} isHide />
        </Paper>
      </div>
    </PageLayout>
  );
};

Event.defaultProps = {};

Event.propTypes = {
  events: PropTypes.array, //eslint-disable-line
};

const mapStateToProps = (state) => ({
  events: sGetEvents(state),
});

export default connect(mapStateToProps)(Event);
