const styles = () => ({
  paper: {
    width: '100%',
    maxWidth: '390px',
    borderRadius: '8px',
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    padding: 0,
  },
  menuItemButton: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    padding: '14px 16px',
    width: '100%',
    backgroundColor: 'unset',
    border: 'unset',
    cursor: 'pointer',

    '& p': {
      fontSize: '14px',
      fontFamily: 'Sofia Pro',
      lineHeight: '24px',
      color: '#111',
      margin: 0,
    },
  },
  menuSvg: {
    width: '20px',
  },
  menuDivider: {
    margin: '3px 16px',
  },
  createFolderPopover: {
    backgroundColor: '#0000003b',
  },
  createNameMenuList: {
    display: 'flex',
    flexDirection: 'column',
    padding: '28px',
  },
  createFolderTitle: {
    fontSize: '14px',
    margin: 0,
  },
  createFolderInput: {
    marginBottom: 0,
    '& input.default': {
      padding: '7.5px 14px',
    },
  },
  errorMessage: {
    fontSize: '12px',
    margin: '5px 0 0',
    color: '#FD6765',
  },
  createFolderLabel: {
    fontSize: '16px',
    marginBottom: '20px',
  },
  createFolderButtons: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '14px',
    marginTop: '22px',

    '& button': {
      fontFamily: 'Sofia Pro',
      fontSize: '14px',
      lineHeight: '16px',
      backgroundColor: 'unset',
      border: '1px solid rgb(37, 78, 82)',
      borderRadius: '16px',
      padding: '8px 26px',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: 'rgba(51, 51, 51, 0.04)',
      },

      '&.cancel_button': {
        borderColor: '#FD6765',
      },
    },
  },
});

export default styles;
