const styles = (theme) => ({
  logoImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '12px',
  },

  companyIndustry: {
    color: theme.palette.primary.grey,
  },

  companyItemGrid: {
    flexGrow: '0',
    maxWidth: '233.33px',
    flexBasis: '50%',
    '@media only screen and (max-width: 1024px)': {
      maxWidth: '125.33px',
    },
    '@media only screen and (max-width: 860px)': {
      maxWidth: '185.33px',
    },
  },
  label: {
    '@media only screen and (max-width: 1024px)': {
      fontSize: '14px',
    },
  },
});

export default styles;
