import React from 'react';

const ArrowBackward = ({
  width = '100%',
  height = '100%',
  strokeWidth = 2,
  fill = 'currentColor',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 9.444 14.646"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="none"
      stroke={fill}
      strokeLinecap="round"
      strokeWidth={strokeWidth}
      d="M125,312.932l5.2,5.2-5.2,5.2"
      transform="translate(132.323 325.456) rotate(180)"
    />
  </svg>
);

export default ArrowBackward;
