import { useEffect } from 'react';
import {
  fetchCompaniesFromApi,
  fetchPublicCompaniesFromApi,
} from '../properties';
import { useApiFetch } from './useApiFetch';

export function useFetchCompanies(property, isPublic = false) {
  const companiesApi = useApiFetch(
    !isPublic ? fetchCompaniesFromApi : fetchPublicCompaniesFromApi
  );

  useEffect(() => {
    if (!property) return;
    if (!property.boligmappaNumber) return;

    const { boligmappaNumber } = property;
    companiesApi.executeFetch(boligmappaNumber);
  }, [property?.boligmappaNumber]);

  return {
    data: companiesApi.data,
    error: companiesApi.error,
    executeFetch: companiesApi.executeFetch,
    isLoading: companiesApi.isLoading,
  };
}
