const styles = (theme) => ({
  errorContainer: {
    [theme.breakpoints.up('md')]: {
      marginTop: '30%',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '10%',
    },
  },
});

export default styles;
