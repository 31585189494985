import React from 'react';

const EditPencil = ({
  width = '21',
  height = '21',
  fill = 'none',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 21"
    fill={fill}
    {...props}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M17.2266 2.80469H17.1875C16.8359 2.45312 16.25 2.45312 15.8984 2.80469L14.7266 3.9375L16.5625 5.77344L17.6953 4.60156C18.0469 4.25 18.0469 3.66406 17.6953 3.27344L17.2266 2.80469ZM8.16406 10.5391C8.04688 10.6172 7.96875 10.7734 7.92969 10.9297L7.26562 13.1953L9.57031 12.5703C9.72656 12.5312 9.84375 12.4531 9.96094 12.3359L15.2344 7.0625L13.4375 5.26562L8.16406 10.5391ZM14.5703 1.47656C15.6641 0.382812 17.4219 0.382812 18.5156 1.47656L19.0234 1.98438C20.1172 3.07812 20.1172 4.83594 19.0234 5.92969L11.2891 13.6641C10.9766 13.9766 10.5469 14.2109 10.0781 14.3672L6.17188 15.4609C5.85938 15.5781 5.50781 15.5 5.27344 15.2266C5 14.9922 4.92188 14.6406 5 14.3281L6.13281 10.4219C6.25 9.95312 6.52344 9.52344 6.83594 9.21094L14.5703 1.47656ZM3.4375 3H7.8125C8.32031 3 8.75 3.42969 8.75 3.9375C8.75 4.48438 8.32031 4.875 7.8125 4.875H3.4375C2.53906 4.875 1.875 5.57812 1.875 6.4375V17.0625C1.875 17.9609 2.53906 18.625 3.4375 18.625H14.0625C14.9219 18.625 15.625 17.9609 15.625 17.0625V12.6875C15.625 12.1797 16.0156 11.75 16.5625 11.75C17.0703 11.75 17.5 12.1797 17.5 12.6875V17.0625C17.5 18.9766 15.9375 20.5 14.0625 20.5H3.4375C1.52344 20.5 0 18.9766 0 17.0625V6.4375C0 4.5625 1.52344 3 3.4375 3Z"
      fill="#111111"
    />
  </svg>
);

export default EditPencil;
