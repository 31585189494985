import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Grid, withStyles } from '@material-ui/core';
import matomo from '../../utils/matomo';
import Box from '../../components/atomic/Box';
import useDesktop from '../../hooks/useDesktop';
import ProgressButton from '../../components/ProgressButton';
import { FileUploadContext } from '../../contexts/fileUpload';
import { acSetShowAddEvent } from '../../store/actions/events';
import { acSetOpenGlobleAddMenu } from '../../store/actions/action';
import style from './style';

const EventExperimentCTA = ({ classes, setShowAddEvent, setShowAddMenu }) => {
  const { onFileUploadClick } = useContext(FileUploadContext);
  const isDesktop = useDesktop();

  const MatomoTrackEvent = (name) => {
    matomo.clickEvent({
      category: 'Dashboard CTAs',
      name,
      action: 'Have You Renovated Lately',
    });
  };

  const createNewEvent = () => {
    setShowAddEvent(true);
    MatomoTrackEvent('Create Event from Have You Renovated Lately');
  };

  const uploadNewDocument = () => {
    if (!isDesktop) {
      setShowAddMenu(true);
    } else {
      MatomoTrackEvent('Upload Document from Have You Renovated Lately');
      onFileUploadClick({
        query: { bulkUploadMode: true, source: 'table', newUpload: true },
      });
    }
  };

  return (
    <>
      <Grid container className={classes.container}>
        <Box>
          <p className={classes.text}>
            Har du pusset opp eller oppgradert noe i det siste?
          </p>
          <p className={classes.subText}>
            Opprett en hendelse og last opp dokumentasjon!
          </p>
        </Box>
        <Box style={{ display: 'flex' }}>
          <ProgressButton
            color="primary"
            variant="contained"
            onClick={() => createNewEvent()}
            className={classes.eventButton}
          >
            + Opprett hendelse
          </ProgressButton>
          <ProgressButton
            className={classes.button}
            variant="contained"
            onClick={() => uploadNewDocument()}
          >
            + Last opp fil{' '}
          </ProgressButton>
        </Box>
      </Grid>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setShowAddEvent: (payload) => dispatch(acSetShowAddEvent(payload)),
  setShowAddMenu: (payload) => dispatch(acSetOpenGlobleAddMenu(payload)),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(style)(EventExperimentCTA));
