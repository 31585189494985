import React, {
  createElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { MoreVert } from '@material-ui/icons';
import { TableCell, TableRow, withStyles } from '@material-ui/core';
import clsx from 'clsx';

import EditableTitle from '@componentsV2/EditableTitle';

import Tooltip from '../../../../Tooltip';
import Image from '../../../../Image';
import Box from '../../../../atomic/Box';
import IconButton from '../../../../atomic/IconButton';
import TextHighlighted from '../../../../TextHighlighted';
import LockForDocView from '../../../../../assets/icons/LockForDocView';

import CheckboxCell from '../CheckboxCell';
import FileImageIcon from '../../../../../assets/icons/DocumentViewIcons/FileImageIcon';
import {
  getDocumentCompanyName,
  getDocumentCreatedDate,
  getDocumentEvents,
  getDocumentFileType,
  getDocumentId,
  getDocumentIsBuilding,
  getDocumentName,
  getDocumentOwnedByName,
  getDocumentProfessionTypeForDocumentView,
  getDocumentRooms,
  getDocumentType,
  getFileThumbNailForDocumentView,
  getOriginalDocumentPath,
  isDocumentImage,
  isDocumentReadOnly,
  isDocumentsFolder,
} from '../../../../../utils/documents';
import { formattedDate } from '../../../../../utils/dates';
import useDashboardLinkPrefix from '../../../../../hooks/useDashboardLinkPrefix';
import styles from './style';
import BuildingDocument from '../../../../../assets/icons/BuildingDocument';
import HouseIcon from '../../../../../assets/icons/HouseIcon';
import EventBar from '../../../../../assets/icons/EventBar';
import FolderClosed from '../../../../../assets/icons/FolderClosed';
import FolderOpened from '../../../../../assets/icons/FolderOpened';
import useSnackbar from '../../../../../hooks/useSnackbar';
import { updateDocument as apiUpdateDocument } from '../../../../../api/documents';
import {
  updatePersonalDocument as apiUpdatePersonalDocument,
  updatePersonalDocumentDirectory,
} from '../../../../../api/propertyowners';
import { acUpdateDocument } from '../../../../../store/actions/documents';
import {
  acSetSelectedPersonalFolderId,
  acUpdatePersonalDocument,
  acUpdatePersonalDocumentFolderToRender,
} from '../../../../../store/actions/personalDocuments';

const TableRowItem = ({
  classes,
  document,
  onFolderChange,
  newUpload,
  searchQuery,
  publicSection,
  actionsEnabled,
  onDocumentClick,
  onSelect = () => {},
  draggableListItem = false,
  handleDragStart,
  handleDragEnd,
  handleDragOver,
  handleDrop,
  isParentDragged,
  reset = false,
  selectedDocuments = [],
  showBulkSelectionOptions,
  isPersonalDocuments,
  isFromPersonal,
  updateDocument,
  updatePersonalDocument,
  updatePersonalDocumentFolderToRender,
  setSelectedPersonalFolderId,
}) => {
  const isFolder = isDocumentsFolder(document);
  const [options, setOptions] = useState(selectedDocuments);
  const [checkDocumentItem, setCheckDocumentItem] = useState(null);
  const [isDragged, setIsDragged] = useState(false);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  const tableRowRef = useRef(null);
  const dragRef = useRef(null);
  const dragCounter = useRef(0);

  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const professionType = getDocumentProfessionTypeForDocumentView(document);
  const documentPath = `${dashboardLinkPrefix}/documents/${getDocumentId(
    document
  )}`;
  const { showSuccessMessage, showErrorMessage } = useSnackbar();

  const docTitle = getDocumentName(document);
  const avsenderTitle =
    getDocumentOwnedByName(document) || getDocumentCompanyName(document);

  const personalDocumentPath = `/personal-documents/${getDocumentId(document)}`;

  const handleChange = (o) => {
    if (checkDocumentItem) {
      setCheckDocumentItem(null);
      onSelect(o);
    } else {
      setCheckDocumentItem(o);
      onSelect(o);
    }
  };
  const onChangeDocumentName = async (value) => {
    const data = { ...document, title: value };
    const patchData = {
      title: value,
      description: document?.description,
      documentTypeId: document?.documentType?.id,
      directoryId: document.directoryId,
    };
    const updateRequest = () =>
      isPersonalDocuments
        ? apiUpdatePersonalDocument(patchData, document.id)
        : apiUpdateDocument(document.id, {
            ...document,
            title: value,
          });

    const [requestError, response] = await updateRequest();
    if (response && !requestError) {
      if (isPersonalDocuments) {
        updatePersonalDocument(data);
      } else {
        updateDocument(data);
      }
      showSuccessMessage();
    }
  };

  const onChangeFolderName = async (value) => {
    const data = { ...document, name: value };
    const patchData = {
      name: value,
      parentId: document.parentId,
    };

    const [requestError, response] = await updatePersonalDocumentDirectory(
      patchData,
      document.id
    );
    if (response && !requestError) {
      updatePersonalDocumentFolderToRender(data);
      setSelectedPersonalFolderId(null);
      showSuccessMessage();
    } else {
      showErrorMessage('Mappenavnet finnes allerede');
    }
  };

  const renderedIcon = useMemo(() => {
    if (isDocumentImage(document)) {
      return (
        <>
          <Image
            fallbackComponent={<FileImageIcon className={classes.imageIcon} />}
            alt={docTitle}
            src={getOriginalDocumentPath(document)}
            className={classes.thumbnailImageIcon}
          />
        </>
      );
    }
    if (isFolder) {
      return (
        <>
          <FolderClosed className="hoverOut" />
          <FolderOpened className="hoverIn" />
        </>
      );
    }
    return createElement(
      getFileThumbNailForDocumentView(getDocumentFileType(document)) ||
        getFileThumbNailForDocumentView('default'),
      { className: classes.imageIcon }
    );
  }, [document]);

  const onDragStart = (e) => {
    setIsDragged(true);
    if (dragRef.current) {
      e.dataTransfer.setDragImage(dragRef.current, 0, 0);
    }
    handleDragStart(document);
  };
  const onDragEnd = () => {
    setIsDragged(false);
    handleDragEnd();
  };

  const onDragOver = (e) => {
    handleDragOver(e);
  };

  const onDrop = () => {
    setIsDragged(false);
    setIsDraggedOver(false);
    handleDrop(document);
  };

  const onDragEnter = (e) => {
    e.preventDefault();
    if (e.currentTarget === tableRowRef.current) {
      dragCounter.current += 1;
    }
    setIsDraggedOver(true);
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    if (e.currentTarget === tableRowRef.current) {
      dragCounter.current -= 1;
      if (dragCounter.current <= 0) {
        dragCounter.current = 0; // Prevent counter from going negative
        setIsDraggedOver(false);
      }
    }
  };

  useEffect(() => {
    onSelect(options);
  }, [options.length]);

  useEffect(() => {
    if (reset) {
      setOptions([]);
    }
  }, [reset]);

  const getEventAndRoomCard = (values, type) =>
    values?.length > 0 && (
      <Tooltip
        interactive
        placement="bottom"
        arrow
        popperArrow
        title={values?.map(
          (tooltipValue, index) =>
            ` ${tooltipValue?.title}${index !== values?.length - 1 ? ',' : ''}`
        )}
      >
        <div
          className={clsx(
            classes.eventAndRoomCard,
            classes.moreEventAndRoomCard
          )}
        >
          {type === 'room' && <HouseIcon />}
          {type === 'event' && <EventBar />}
          <span>{values?.length}</span>
        </div>
      </Tooltip>
    );

  const TooltipForDocNameAndAsvender = ({ title, children }) =>
    title?.length > 50 ? (
      <Tooltip title={title} interactive placement="bottom" arrow popperArrow>
        {children}
      </Tooltip>
    ) : (
      children
    );

  const handleDocumentSelect = () => {
    handleChange(document);
  };

  return (
    <>
      <div
        className={clsx([classes.dragElement, isDragged && 'dragged'])}
        ref={dragRef}
      >
        <span
          className={clsx([
            classes.imageIconBox,
            getDocumentFileType(document),
          ])}
        >
          {renderedIcon}
        </span>
        <p>{docTitle}</p>
      </div>
      <TableRow
        ref={tableRowRef}
        className={clsx([
          classes.rowHover,
          isDraggedOver && 'draggedOver',
          isDragged && 'dragged',
          isParentDragged && 'siblingDragged',
        ])}
        draggable={
          draggableListItem && (!isFolder || !document?.isSystemGenerated)
        }
        onDragStart={(e) => draggableListItem && onDragStart(e)}
        onDragEnd={(e) => draggableListItem && onDragEnd(e)}
        onDragOver={(e) => draggableListItem && onDragOver(e)}
        onDrop={(e) => draggableListItem && onDrop(e)}
        onDragEnter={(e) => draggableListItem && onDragEnter(e)}
        onDragLeave={(e) => draggableListItem && onDragLeave(e)}
      >
        <CheckboxCell
          classes={isFolder && { checkbox: classes.folderCheckbox }}
          onChange={handleDocumentSelect}
          disabled={
            isDocumentReadOnly(document) || getDocumentIsBuilding(document)
          }
          isVisible={showBulkSelectionOptions}
          isChecked={
            selectedDocuments.filter((value) => value.id === document?.id)
              .length > 0
          }
          style={{ padding: 0 }}
        />

        <TableCell
          className={clsx(classes.tableCell, classes.tableCellDocName)}
        >
          <Box
            className={clsx(
              classes.titleRow,
              classes.displayFlex,
              classes.alignItemsCenter,
              !showBulkSelectionOptions ? classes.marginLeft9 : null
            )}
          >
            <span
              className={clsx([
                classes.imageIconBox,
                getDocumentFileType(document),
              ])}
            >
              {renderedIcon}
            </span>
            {isDocumentReadOnly(document) && (
              <Tooltip
                title="Filen inneholder historisk data, og kan ikke endres"
                interactive
                placement="bottom"
                arrow
                popperArrow
              >
                <div className={classes.lockForDocViewContainer}>
                  <LockForDocView
                    width={18}
                    height={18}
                    className={classes.lockForDocView}
                  />
                </div>
              </Tooltip>
            )}

            {getDocumentIsBuilding(document) && (
              <Tooltip
                title="Filen er knyttet til bygningen og ikke boenheten og kan derfor ikke redigeresFilen er knyttet til bygningen og ikke boenheten, og den kan derfor kun endres av avsender/bedriften"
                interactive
                placement="bottom"
                arrow
                popperArrow
              >
                <div className={classes.builidingDocViewContainer}>
                  <BuildingDocument
                    width={14}
                    height={14}
                    className={classes.lockForDocView}
                  />
                </div>
              </Tooltip>
            )}
            <EditableTitle
              title={docTitle}
              onChangeTitle={
                isFolder ? onChangeFolderName : onChangeDocumentName
              }
              overlay={!isPersonalDocuments}
              disabled={
                publicSection ||
                isDocumentReadOnly(document) ||
                getDocumentIsBuilding(document) ||
                document?.isSystemGenerated
              }
            >
              <TooltipForDocNameAndAsvender title={docTitle}>
                {isFolder ? (
                  <button
                    type="button"
                    onClick={() => onFolderChange(document.id)}
                    className={clsx([classes.docNameText, classes.docNameLink])}
                  >
                    <TextHighlighted searchQuery={searchQuery}>
                      {docTitle}
                    </TextHighlighted>
                  </button>
                ) : (
                  <Link
                    draggable={false}
                    to={{
                      pathname: isFromPersonal
                        ? personalDocumentPath
                        : documentPath,
                      state: { newUpload, isPersonalDocuments, isFromPersonal },
                    }}
                    className={clsx(
                      !isDocumentReadOnly(document)
                        ? classes.docNameLink
                        : null,
                      classes.docNameText
                    )}
                  >
                    <TextHighlighted searchQuery={searchQuery}>
                      {docTitle}
                    </TextHighlighted>
                  </Link>
                )}
              </TooltipForDocNameAndAsvender>
            </EditableTitle>
          </Box>
        </TableCell>

        <TableCell className={clsx([classes.dateCell, classes.tableCell])}>
          <TextHighlighted searchQuery={searchQuery}>
            {formattedDate(getDocumentCreatedDate(document), 'D.MM.YYYY')}
          </TextHighlighted>
        </TableCell>

        {!isPersonalDocuments && (
          <>
            <TableCell className={clsx(classes.tableCell, classes.typeCell)}>
              <TextHighlighted searchQuery={searchQuery}>
                {getDocumentType(document)}
              </TextHighlighted>
            </TableCell>

            <TableCell
              className={clsx([classes.tableCell, classes.avsenderCell])}
            >
              <TooltipForDocNameAndAsvender title={avsenderTitle}>
                <TextHighlighted searchQuery={searchQuery}>
                  <span>{avsenderTitle}</span>
                </TextHighlighted>
              </TooltipForDocNameAndAsvender>
            </TableCell>

            <TableCell
              className={clsx([classes.combinedBodyCell, classes.tableCell])}
            >
              <div className={classes.combinedWrapper}>
                <TextHighlighted searchQuery={searchQuery}>
                  <span
                    className={professionType ? classes.eventAndRoomCard : null}
                  >
                    {professionType}
                  </span>
                  {getEventAndRoomCard(getDocumentRooms(document), 'room')}
                  {getEventAndRoomCard(getDocumentEvents(document), 'event')}
                </TextHighlighted>
              </div>
            </TableCell>
          </>
        )}
        <TableCell className={clsx([classes.tableCell, classes.optionsCell])}>
          {actionsEnabled && (
            <IconButton
              size="small"
              onClick={(event) => onDocumentClick(event, document)}
            >
              <MoreVert className={classes.moreIcon} />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateDocument: (document) => dispatch(acUpdateDocument(document)),
  updatePersonalDocument: (document) =>
    dispatch(acUpdatePersonalDocument(document)),
  updatePersonalDocumentFolderToRender: (document) =>
    dispatch(acUpdatePersonalDocumentFolderToRender(document)),
  setSelectedPersonalFolderId: (id) =>
    dispatch(acSetSelectedPersonalFolderId(id)),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(TableRowItem));
