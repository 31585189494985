const styles = () => ({
  container: {
    width: 'calc(100% - 332px)',
    height: '90vh',
    padding: '10px',
    position: 'relative',

    '&.mobile': {
      width: '100%',
    },
  },
  previewContainer: {
    overflow: 'scroll',
  },
  videoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  videoPlayer: {
    maxHeight: '80vh',
    maxWidth: '100%',
  },
  previewZoomButtons: {
    position: 'fixed',
    top: '10px',
    right: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',

    '& button': {
      background: '#8d8d8d',
      border: 'unset',
      outline: 'unset',
      margin: '10px 0 0 0',
      padding: '10px',
      borderRadius: '100%',
      cursor: 'pointer',
    },

    '& svg': {
      display: 'block',
      width: '15px',
      height: '15px',
      color: '#FC6865',
    },
  },
  pdfViewer: {
    display: 'block',
    height: '90vh',
    '& canvas': {
      margin: '0 auto',
    },
  },
});

export default styles;
