const styles = {
  checkbox: {
    margin: '0 16px',
  },
  checkboxCell: {
    padding: '0!important',
    minWidth: '59px',
    width: '59px',
  },
};

export default styles;
