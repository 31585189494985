import React from 'react';

const FolderClosed = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    {...props}
  >
    <path
      d="M2 14C0.875 14 0 13.125 0 12V2C0 0.90625 0.875 0 2 0H6C6.625 0 7.21875 0.3125 7.59375 0.8125L8.1875 1.625C8.375 1.875 8.65625 2 9 2H14C15.0938 2 16 2.90625 16 4V12C16 13.125 15.0938 14 14 14H2Z"
      fill="#1B88C2"
    />
  </svg>
);

export default FolderClosed;
