const style = {
  name: {
    width: '160px',
    fontSize: '13px',
    fontWeight: '800',
    color: 'black',
  },
  dateField: {
    minWidth: '99px',
    fontSize: '13px',
    color: '#666666',
  },
  emailField: {
    fontSize: '13px',
    color: '#666666',
  },
  statusField: {
    fontSize: '13px',
  },
  activeLabel: {
    color: '#13A085',
    backgroundColor: '#b6e0db',
    borderRadius: '3px',
    height: '16px',
    width: '58px',
    textAlign: 'center',
    fontSize: '10px',
  },
  pendingLabel: {
    color: '#FBB03B',
    backgroundColor: '#ffe9c7',
    borderRadius: '3px',
    height: '16px',
    width: '58px',
    textAlign: 'center',
    fontSize: '10px',
  },
  tooltipContainer: {
    backgroundColor: '#fff',
  },
  iconButton: {
    backgroundColor: '#fff',
  },
};

export default style;
