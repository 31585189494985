import clsx from 'clsx';
import React, { createElement } from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Checkbox from '../../../atomic/Checkbox';
import ListItem from '../../../atomic/ListItem';
import Typography from '../../../atomic/Typography';
import ListItemIcon from '../../../atomic/ListItemIcon';
import ListItemText from '../../../atomic/ListItemText';
import ListItemSecondaryAction from '../../../atomic/ListItemSecondaryAction';

const FilterListItem = ({
  icon,
  name,
  label,
  classes,
  checked,
  onClick,
  component = ListItem,
  ...props
}) =>
  // eslint-disable-next-line react/no-children-prop
  createElement(component, {
    button: true,
    divider: true,
    classes: {
      root: clsx(classes.listItem, 'word-break'),
    },
    onClick: () => onClick(name),
    children: (
      <>
        {icon && (
          <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
        )}

        <ListItemText className={classes.listItemText}>
          <Typography color="textPrimary">
            <strong>{label}</strong>
          </Typography>
        </ListItemText>

        <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
          <Checkbox checked={checked} className={classes.checkbox} />
        </ListItemSecondaryAction>
      </>
    ),
    ...props,
  });

export default withStyles(styles)(FilterListItem);
