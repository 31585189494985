import React from 'react';

const PrintIcon = ({
  width = '14',
  height = '15',
  fill = '#111',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 15"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M3.0625 5.125H1.75V2.5C1.75 1.54297 2.51562 0.75 3.5 0.75H9.76172C10.2266 0.75 10.6641 0.941406 10.9922 1.26953L11.7305 2.00781C12.0586 2.33594 12.25 2.77344 12.25 3.23828V5.125H10.9375V3.23828C10.9375 3.12891 10.8828 3.01953 10.8008 2.9375L10.0625 2.19922C9.98047 2.11719 9.87109 2.0625 9.76172 2.0625H3.5C3.25391 2.0625 3.0625 2.28125 3.0625 2.5V5.125ZM3.5 10.8125V13.4375H10.5V10.8125H3.5ZM3.0625 9.5H10.9375C11.4023 9.5 11.8125 9.91016 11.8125 10.375H12.6875V7.75C12.6875 7.53125 12.4688 7.3125 12.25 7.3125H1.75C1.50391 7.3125 1.3125 7.53125 1.3125 7.75V10.375H2.1875C2.1875 9.91016 2.57031 9.5 3.0625 9.5ZM11.8125 11.6875V13.875C11.8125 14.3672 11.4023 14.75 10.9375 14.75H3.0625C2.57031 14.75 2.1875 14.3672 2.1875 13.875V11.6875H0.875C0.382812 11.6875 0 11.3047 0 10.8125V7.75C0 6.79297 0.765625 6 1.75 6H12.25C13.207 6 14 6.79297 14 7.75V10.8125C14 11.3047 13.5898 11.6875 13.125 11.6875H11.8125Z"
      fill={fill}
    />
  </svg>
);

export default PrintIcon;
