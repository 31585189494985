// Add your styles here, and reference them in your component className
import { BodyElement, BodySize, bodySizeMap } from '@style/vars/typography';
import { css } from '@linaria/core';
import { mediaQueries } from '@style/mediaQueries';
import { colors } from '@style/vars';

export interface StyledTextProps {
  /**
   * Sets the text's font-size from a set of defined sizes.
   */
  size?: BodySize;
  /**
   * Use this if you need something else than the defined sizes you get from using the ´size´ prop.
   */
  fontSize?: string;
  /**
   * The `tag` of the text.
   */
  tag?: BodyElement;
}

export const getBodyTextStyle = () => {
  return Object.keys(bodySizeMap)
    .map((size) => {
      return `
        &.${size} {
          color: ${colors.textColor};
          font-size: ${bodySizeMap[size].fontSizeMobile || bodySizeMap[size].fontSize};
          line-height: ${bodySizeMap[size].lineHeightMobile || bodySizeMap[size].lineHeight};
          font-weight: ${bodySizeMap[size].fontWeight};
          
          ${mediaQueries.sm} {
            font-size: ${bodySizeMap[size].fontSize};
            line-height: ${bodySizeMap[size].lineHeight};
          }
        }
    `;
    })
    .join('');
};

export const bodyTextStyle = css`
  ${getBodyTextStyle()}
`;
