const styles = (theme) => ({
  room_type_heading: {
    textAlign: 'Center',
    margin: '20px 80px 20px 80px',
  },
  room_type_heading_mobile: {
    textAlign: 'Center',
    margin: '20px 30px 20px 30px',
  },
  room_select_typo_top: {
    fontWeight: '400',
  },
  room_select_Typo: {
    marginTop: 20,
    fontWeight: '400',
  },
  room_type_list: {
    listStyleType: 'none',
    padding: '0 85px 20px 74px',
  },
  room_type_list_mobile: {
    listStyleType: 'none',
    padding: '0 30px 20px 25px',
  },
  room_type_list_item: {
    display: 'flex',
    border: `2px solid ${theme.palette.primary.greyLight}`,
    borderRadius: '5px',
    padding: 15,
    marginBottom: 7,
  },
  room_type_list_item_name: {},
  room_type_list_item_pad: {
    marginLeft: 'auto',
    display: 'flex',
  },
  title: {
    margin: '10px 0 10px 0',
    color: theme.palette.primary.darkGreen,
    fontWeight: 600,
    fontSize: '16px',
    textAlign: 'left',
  },
  progressSummary: {
    fontWeight: 400,
    textAlign: 'left',
  },
  progressSummaryLast: {
    fontWeight: 400,
    textAlign: 'left',
    marginTop: '20px',
  },
  room_type_list_add_circle: {
    width: 26,
    color: theme.palette.primary.darkGreen,
    display: 'inline-block',
    margin: '0 15px 0 15px',
    cursor: 'pointer',
    position: 'relative',
  },
  room_type_list_remove_circle: {
    width: 26,
    height: 26,
    color: theme.palette.primary.darkGreen,
    display: 'inline-block',
    margin: '0 15px 0 15px',
    cursor: 'pointer',
    position: 'relative',
  },
  room_type_list_item_pad_pm_disabled: {
    color: theme.palette.primary.greyLight,
    pointerEvents: 'none',
  },
});

export default styles;
