const styles = (theme) => ({
  root: {
    padding: '20px 0',
    borderColor: theme.palette.primary.whiteDarker,
  },
  icon: {
    width: '2rem',
  },
  arrowIcon: {
    width: '10px',
    height: '10px',
    marginLeft: '5px',
  },
});

export default styles;
