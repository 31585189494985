import { connect } from 'react-redux';
import { useParams } from 'react-router';
import React, { useContext } from 'react';

import {
  getPropertySales,
  getPropertyLoans,
  getPropertyOwnerships,
} from '../../../../utils/financials';

import useDesktop from '../../../../hooks/useDesktop';
import Paper from '../../../../components/atomic/Paper';
import Page from '../../../../components/layouts/WithHeader';
import useFetchEffect from '../../../../hooks/useFetchEffect';
import PropertySales from '../../../../modules/PropertySales';
import PropertyLoans from '../../../../modules/PropertyLoans';
import ErrorContainer from '../../../../components/ErrorContainer';
import PropertyOwnership from '../../../../modules/PropertyOwnership';
import { sGetFinancials } from '../../../../store/reducers/financials';
import { acSetFinancials } from '../../../../store/actions/financials';
import PropertyRefreshContext from '../../../../contexts/propertyRefresh';
import { fetchFinancials as apiFetchFinancials } from '../../../../api/properties';
import Typography from '../../../../components/atomic/Typography';
import Box from '../../../../components/atomic/Box';
import CTAcard from '../../../../components/CTAcard';
import { zendeskHelpPage } from '../../../../config';
import { isNativeEnv, openLinkRequest } from '../../../../utils/nativeBridge';

const PropertyEconomy = ({ financials, setFinancials }) => {
  const isDesktop = useDesktop();
  const { boligmappaNumber } = useParams();

  const { propertyRefreshRequestInProgress } = useContext(
    PropertyRefreshContext
  );

  const { fetching, fetchingError } = useFetchEffect({
    conditionFunction: () => !financials,
    onSuccess: (data) => setFinancials(data),
    apiFetchFunction: () => apiFetchFinancials(boligmappaNumber),
  });

  const callToAction = () => {
    if (isNativeEnv()) {
      openLinkRequest(zendeskHelpPage);
      return;
    }
    window.open(zendeskHelpPage, '_blank');
  };

  const ownerships = getPropertyOwnerships(financials);
  const hasOwnerships = Array.isArray(ownerships) && ownerships.length > 0;
  const ownershipCount = Array.isArray(ownerships) ? ownerships.length : null;
  const showOwnerships = fetching || (!fetching && hasOwnerships);
  const ownership =
    Array.isArray(ownerships) && ownerships.length > 0 ? ownerships[0] : null;

  const sales = getPropertySales(financials);
  const hasSales = Array.isArray(sales) && sales.length > 0;
  const saleCount = hasSales ? sales.length : null;
  const showSales = fetching || (!fetching && hasSales);
  const sale = hasSales ? sales[0] : null;

  const loans = getPropertyLoans(financials);
  const hasLoans = Array.isArray(loans) && loans.length > 0;
  const loanCount = hasLoans ? loans.length : null;
  const showLoans = fetching || (!fetching && hasLoans);
  const loan = hasLoans ? loans[0] : null;
  const helpText =
    '*Pant i boligen er ikke det samme som hva som er nåværende lånebeløp. Det betyr kun hva en panthaver har som tinglyst sikkerhet i boligen. Registrert pantebeløp reduseres vanligvis ikke i takt med tilbakebetaling av lånet.  Har du spørsmål, kontakt banken din.';

  const CTAcardText =
    'I vår FAQ kan du lese om hvor vi henter data fra, hva informasjonen ovenfor betyr og hvorfor det noen ganger ikke samsvarer med hvordan du kjenner din egen bolig.';
  return (
    <Page
      minFullHeight
      headerTitle="Økonomi"
      hideHeader={isDesktop}
      noPadding={!isDesktop}
      lessMarginLeft={isDesktop}
      maxWidth="lg"
      showProgressBar={propertyRefreshRequestInProgress}
    >
      {fetchingError ? (
        <ErrorContainer
          paddingTop={isDesktop}
          errorResponse={fetchingError}
          style={{ paddingTop: !isDesktop ? '15%' : undefined }}
        />
      ) : (
        <>
          {!isDesktop && <br />}

          {showOwnerships && (
            <Paper visible={!isDesktop}>
              <PropertyOwnership
                fetching={fetching}
                ownership={ownership}
                ownershipCount={ownershipCount}
              />
            </Paper>
          )}

          {showSales && (
            <>
              <br />
              <Paper visible={!isDesktop}>
                <PropertySales
                  sale={sale}
                  fetching={fetching}
                  saleCount={saleCount}
                />
              </Paper>
            </>
          )}

          {showLoans && (
            <>
              <br />
              <Paper visible={!isDesktop}>
                <PropertyLoans
                  loan={loan}
                  title="Heftelser"
                  fetching={fetching}
                  loanCount={loanCount}
                />
              </Paper>
            </>
          )}

          <Box
            padding={!isDesktop ? 1.5 : 0}
            width={isDesktop ? '95%' : '100%'}
          >
            <Typography fontWeight="400">{helpText}</Typography>
          </Box>
          <Paper visible={!isDesktop}>
            <CTAcard
              title="Har du spørsmål til opplysningene ovenfor?"
              description={CTAcardText}
              buttonText="Gå til FAQ"
              onClick={callToAction}
            />
          </Paper>
        </>
      )}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  financials: sGetFinancials(state),
});

const mapDispatchToProps = (dispatch) => ({
  setFinancials: (financials) => dispatch(acSetFinancials(financials)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyEconomy);
