import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';

import {
  sortDocumentOptions,
  mapDocumentIds,
  mapDocumentOptions,
} from '../../../../../../utils/documents';
import {
  sortEventOptions,
  mapEventIds,
  mapEventOptions,
} from '../../../../../../utils/events';

import {
  fetchEvents as apiFetchEvents,
  fetchDocuments as apiFetchDocuments,
} from '../../../../../../api/properties';

import {
  acSetDocuments,
  acSetFetchingDocuments,
} from '../../../../../../store/actions/documents';

import {
  acSetEvents,
  acSetFetchingEvents,
} from '../../../../../../store/actions/events';

import styles from './styles';
import Box from '../../../../../../components/atomic/Box';
import DialogScrollBox from '../../../../../../components/DialogScrollBox';
import Typography from '../../../../../../components/atomic/Typography';
import Autocomplete from '../../../../../../components/Autocomplete';
import DocumentOptionList from '../../../../../../components/DocumentOptionList';
import TextArea from '../../../../../../components/TextArea';
import TextField from '../../../../../../components/atomic/TextField';
import CircularSectionedProgressBar from '../../../../../../components/atomic/CircularSectionedProgressBar';
import useDesktop from '../../../../../../hooks/useDesktop';
import RoomImages from '../../../../../../modules/RoomImages';
import { acBYBFUpdateRoom } from '../../../../../../store/actions/bybfRooms';
import floors from '../../../../../../utils/floors';
import { getFloorOptions } from '../../../../../../utils/rooms';
import useFetchEffect from '../../../../../../hooks/useFetchEffect';
import { sGetEvents } from '../../../../../../store/reducers/events';
import { sGetDocuments } from '../../../../../../store/reducers/documents';
import { getPropertyBoligmappaNumber } from '../../../../../../utils/properties';
import Button from '../../../../../../components/atomic/Button';
import DeleteIcon from '../../../../../../assets/icons/Bin';
import LinearProgress from '../../../../../../components/atomic/LinearProgress';

import {
  getErrorMessage,
  getResponseErrorMessage,
} from '../../../../../../utils/requests';

const descriptionMaxLength = 1500;

const RoomDetail = ({
  property,
  classes,
  room,
  floorsEnable,
  totalRoomCount,
  currentRoomIndex,
  onRoomImageUpload,
  onRoomImageDelete,
  setBYBFUpdateRoom,
  events,
  documents,
  setEvents,
  setDocuments,
  setFetchingEvents,
  setFetchingDocuments,
  onRoomDelete,
  reqError,
  nextRoomIndex,
  nextRoom,
  showHeader = true,
}) => {
  const isDesktop = useDesktop();
  const floorOptions = getFloorOptions(floors);

  const boligmappaNumber = getPropertyBoligmappaNumber(property);
  const [currentRoom, setCurrentRoom] = useState(room);
  const [title, setTitle] = useState(room?.title);
  const [floorNo, setFloorNo] = useState(room?.floorNo);
  const [description, setDescription] = useState(room?.description);
  const [formUpdateStatus, setFormUpdateStatus] = useState(false);

  const [eventOptions, setEventOptions] = useState([]);
  const [documentOptions, setDocumentOptions] = useState([]);

  const { fetching: fetchingDocuments } = useFetchEffect({
    initialFetchingState: !documents,
    conditionFunction: () => !documents,
    onSuccess: (data) => setDocuments(data),
    setFetchingFunction: setFetchingDocuments,
    apiFetchFunction: () =>
      apiFetchDocuments(boligmappaNumber, { includeTaggedInfo: true }),
  });

  const { fetching: fetchingEvents } = useFetchEffect({
    initialFetchingState: !events,
    conditionFunction: () => !events,
    onSuccess: (data) => setEvents(data),
    setFetchingFunction: setFetchingEvents,
    apiFetchFunction: () => apiFetchEvents(boligmappaNumber),
  });

  const errorContentRef = useRef(null);
  useEffect(() => {
    if (errorContentRef.current) {
      errorContentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [reqError]);

  useEffect(() => {
    if (documents) {
      setDocumentOptions(sortDocumentOptions(documents));
    }
  }, [documents]);

  useEffect(() => {
    if (events) {
      setEventOptions(sortEventOptions(events));
    }
  }, [events]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (formUpdateStatus) {
        setBYBFUpdateRoom(currentRoom);
        setFormUpdateStatus(false);
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [formUpdateStatus]);

  useEffect(() => {
    setCurrentRoom(room);
    setTitle(room?.title);
    setDescription(room?.description);
    setFormUpdateStatus(false);
    setFloorNo(
      room?.floorNo
        ? (floorOptions || []).find((floor) => floor.label === room.floorNo)
        : null
    );
  }, [room]);

  const updateStoreRoom = (field, value) => {
    const roomData = currentRoom;
    switch (field) {
      case 'TITLE':
        setFormUpdateStatus(true);
        roomData.title = value;
        break;
      case 'DESCRIPTION':
        setFormUpdateStatus(true);
        roomData.description = value;
        break;
      case 'FLOOR':
        roomData.floorNo = value;
        setBYBFUpdateRoom(roomData);
        setFormUpdateStatus(false);
        break;
      case 'DOCUMENTS':
        roomData.documents = value;
        setBYBFUpdateRoom(roomData);
        setFormUpdateStatus(false);
        break;
      case 'EVENTS':
        roomData.events = value;
        setBYBFUpdateRoom(roomData);
        setFormUpdateStatus(false);
        break;
      default:
        break;
    }
    setCurrentRoom(roomData);
  };

  const onFloorChange = (option) => {
    if (option) {
      setFloorNo(option.label);
      updateStoreRoom('FLOOR', option.label);
    } else {
      setFloorNo('1. etasje');
      updateStoreRoom('FLOOR', '1. etasje');
    }
  };

  return (
    <>
      {!room && (
        <DialogScrollBox>
          <LinearProgress />
          <Box pr={1} textAlign="right">
            <Typography component="small" color="textSecondary">
              Laster inn rom
            </Typography>
          </Box>
        </DialogScrollBox>
      )}
      {room && (
        <>
          {showHeader && (
            <Box
              display="flex"
              height={80}
              alignItems="center"
              className={classes.header}
            >
              <Box ml={isDesktop ? '50px' : '15px'}>
                <Typography variant="h2" className={classes.room_title}>
                  {title}
                </Typography>
                <Typography className={classes.room_sub_title}>
                  {nextRoomIndex <= totalRoomCount
                    ? `Neste: ${nextRoom}`
                    : null}
                </Typography>
              </Box>
              <Box
                className={
                  isDesktop
                    ? classes.circular_progress_bar
                    : classes.circular_progress_bar_mobile
                }
              >
                <CircularSectionedProgressBar
                  value={(currentRoomIndex / totalRoomCount) * 100}
                  text={`${currentRoomIndex}/${totalRoomCount}`}
                  seperatorCount={totalRoomCount}
                  strokeWidth={7}
                />
              </Box>
            </Box>
          )}

          <DialogScrollBox>
            <Box
              className={
                isDesktop
                  ? classes.image_galary_view
                  : classes.image_galary_view_mobile
              }
            >
              {room && (
                <RoomImages
                  editAccess
                  fetching={false}
                  room={room}
                  onRoomImageUpload={onRoomImageUpload}
                  onRoomImageDelete={onRoomImageDelete}
                  isGalleryModeActive={false}
                />
              )}
            </Box>
            <Box
              className={
                isDesktop
                  ? classes.room_edit_form
                  : classes.room_edit_form_mobile
              }
            >
              {reqError && (
                <div
                  className={clsx(classes.error, 'word-break')}
                  ref={errorContentRef}
                >
                  {getResponseErrorMessage(reqError) ||
                    getErrorMessage(reqError)}
                </div>
              )}
              <TextField
                value={title}
                label="Gi rommet et navn"
                className={classes.formElementTitle}
                onChange={(e) => {
                  setTitle(e.target.value);
                  updateStoreRoom('TITLE', e.target.value);
                }}
                placeholder="F eks. Gjestebad, Hovedsoverom, etc."
              />
              {floorsEnable && (
                <>
                  <Autocomplete
                    label="Etasje"
                    value={floorNo}
                    options={floorOptions}
                    placeholder="Velg etasje"
                    onChange={onFloorChange}
                    className={classes.formElement}
                    loadingMessage={() => 'Laster...'}
                    noOptionsMessage={() => 'Ingen resultater'}
                    isSearchable={false}
                    isClearable={false}
                  />
                  <Typography className={classes.dorpdown_support_text}>
                    Hvilken etasje i boligen dette rommet hører til.
                  </Typography>
                </>
              )}
              <TextArea
                mt={30}
                label="Nyttig informasjon"
                value={description}
                className={classes.formElement}
                maxLength={descriptionMaxLength}
                onChange={(e) => {
                  setDescription(e.target.value);
                  updateStoreRoom('DESCRIPTION', e.target.value);
                }}
                placeholder="Skriv inn nyttig informasjon om rommet som f eks. fargekoder, bruttoareal, med mer..."
              />

              {Array.isArray(documents) && documents.length > 0 && (
                <DocumentOptionList
                  value={documentOptions.filter(({ document }) =>
                    mapDocumentIds(room.documents).includes(document.id)
                  )}
                  options={documentOptions}
                  isLoading={fetchingDocuments}
                  className={classes.formElement}
                  maxMenuHeight={isDesktop ? 150 : null}
                  onChange={(options) =>
                    updateStoreRoom('DOCUMENTS', mapDocumentOptions(options))
                  }
                />
              )}

              {Array.isArray(events) && events.length > 0 && (
                <Autocomplete
                  isMulti
                  value={eventOptions.filter(({ value }) =>
                    mapEventIds(room.events).includes(value)
                  )}
                  options={eventOptions}
                  closeMenuOnSelect={false}
                  isLoading={fetchingEvents}
                  label="Koble til hendelse"
                  className={classes.formElement}
                  loadingMessage={() => 'Laster...'}
                  noOptionsMessage={() => 'Ingen hendelser'}
                  placeholder="Velg en eller flere hendelser"
                  maxMenuHeight={isDesktop ? 70 : null}
                  onChange={(options) =>
                    updateStoreRoom('EVENTS', mapEventOptions(options))
                  }
                />
              )}
            </Box>

            <Box className={classes.room_detail_footer}>
              <Button
                className={classes.delete_room_button}
                type="submit"
                variant="contained"
                align="center"
                onClick={onRoomDelete}
              >
                <DeleteIcon width="12px" />
                <Box component="span" marginLeft="10px">
                  Slett rom
                </Box>
              </Button>
            </Box>
          </DialogScrollBox>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  events: sGetEvents(state),
  documents: sGetDocuments(state),
});

const mapDispatchToProps = (dispatch) => ({
  setBYBFUpdateRoom: (room) => dispatch(acBYBFUpdateRoom(room)),
  setEvents: (events) => dispatch(acSetEvents(events)),
  setFetchingEvents: (fetching) => dispatch(acSetFetchingEvents(fetching)),
  setDocuments: (documents) => dispatch(acSetDocuments(documents)),
  setFetchingDocuments: (fetching) =>
    dispatch(acSetFetchingDocuments(fetching)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RoomDetail));
