import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  estimateNumber: {
    margin: 0,
    fontSize: 44,
    color: theme.palette.primary.grayGreen,
    fontWeight: 400,
    textAlign: 'center',
  },
  pricePerMeter: {
    margin: 0,
    color: theme.palette.text.primary,
    textAlign: 'center',
  },
}));
