import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useSanityContent } from '@hooksV2/useSanityContent';
import { BmPortableText } from '@componentsV2/BmPortableText/BmPortableText';
import ContactMeBox from '@componentsV2/ContactMeBox';
import CustomDialog from '@componentsV2/CustomDialog';
import CallToAction from '@componentsV2/BoligmappaPlus/CallToAction';
import BackArrow from '../../assets/icons/DocumentBulkEditIcons/BackArrow';
import {
  getLoyaltyCompanyByCode,
  getLoyaltyCompanyEmail,
} from '../../utils/LoyaltyCompanies';
import useIsDesktop from '../../hooks/v2/useDesktop';
import CompanyInfo from './CompanyInfo';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';
import { sGetUser } from '../../store/reducers/auth';
import { getImageKitImageUrlForSanity } from '../../content/contentHelpers';
import {
  getMobileNumber,
  getUserEmail,
  getUserFullname,
} from '../../utils/auth';
import { createInquiry } from '../../api/propertyowners';
import CheckCircleRounded from '../../assets/icons/CheckCircleRounded';
import matomo from '../../utils/matomo';
import { StyledLoyaltyCompany } from './styles';

const LoyaltyCompany = ({
  isBackButtonVisible = true,
  onActionClick,
  authUser,
}) => {
  const [formData, setFormData] = useState({});
  const [isDialog, setIsDialog] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [commentQty, setCommentQty] = useState(0);
  const [companyData, setCompanyData] = useState(null);
  const { companyCode, boligmappaNumber } = useParams();
  const isDesktop = useIsDesktop();
  const [showContactInfoAlways, setShowContactInfoAlways] = useState(false);

  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const sanityData = useSanityContent();

  const onContactMeClick = () => {
    matomo.clickEvent({
      category: 'loyalty page clicks',
      action: `${companyCode.toLowerCase()}-contact-form-clicks`,
      name: `${companyCode.toLowerCase()}-contact-form-clicks`,
    });
    setIsDialog(true);
  };

  const onFieldValueChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    setFormData((prevState) => ({ ...prevState, [fieldName]: fieldValue }));
  };

  const onContactMeSubmit = async () => {
    const requestData = {
      boligmappaNumber,
      requestEmail:
        companyData.informationBoxContactForm?.contactForm?.contactFormEmail ||
        getLoyaltyCompanyEmail(
          getLoyaltyCompanyByCode(
            companyCode.toLowerCase(),
            sanityData?.LoyaltyCompanies
          )
        ) ||
        formData.email,
      description:
        `${formData.comment}. Telefonnummer: ${formData.phone} ` ||
        `Ingen kommentar vedlagt forespørselen. Telefonnummer: ${formData.phone} `,
    };
    matomo.clickEvent({
      category: 'loyalty page clicks',
      action: `${companyCode.toLowerCase()}-contact-form-send-clicks`,
      name: `${companyCode.toLowerCase()}-contact-form-send-clicks`,
    });
    const [error, response] = await createInquiry(requestData);
    if (!error && response) {
      setIsDialog(false);
      setIsSuccessMessage(true);
      setFormData((prevState) => ({
        ...prevState,
        name: getUserFullname(authUser),
        email: getUserEmail(authUser),
        phone: getMobileNumber(authUser),
        comment: '',
      }));
    }
  };

  const handleMatomoClickEvent = (category, Companyname) => {
    if (matomo) {
      const clickedCompanyCode = `${Companyname}-click`;
      matomo.clickEvent({
        category,
        action: `${Companyname}-click`,
        name: clickedCompanyCode,
      });
    }
  };

  useEffect(() => {
    setCompanyData(
      getLoyaltyCompanyByCode(
        companyCode.toLowerCase(),
        sanityData?.LoyaltyCompanies
      )
    );
    setShowContactInfoAlways(companyCode === 'sparebank-1-sorost-norge');
  }, [companyCode, showContactInfoAlways]);

  useEffect(() => {
    if (getUserEmail(authUser)) {
      setFormData((prevState) => ({
        ...prevState,
        name: getUserFullname(authUser),
        email: getUserEmail(authUser),
        phone: getMobileNumber(authUser),
      }));
    }
  }, [authUser]);

  return (
    <StyledLoyaltyCompany
      $backgroundColor="#FAF7F1"
      $isSub={authUser?.isSubscribeToBoligmappaPlus || showContactInfoAlways}
    >
      {!showContactInfoAlways && !authUser?.isSubscribeToBoligmappaPlus && (
        <CallToAction isDesktop={isDesktop} onActionClick={onActionClick} />
      )}

      {companyData && (
        <>
          {isDesktop && isBackButtonVisible && (
            <div className="back_section">
              <Link to={`${dashboardLinkPrefix}/boligmappa-plus/loyalty`}>
                <BackArrow className="back_arrow_icon" /> Tilbake
              </Link>
            </div>
          )}
          <div className="section">
            <h2>{companyData?.title}</h2>
            <p className="main_description">{companyData?.shortDescription}</p>
          </div>
          <div className="section">
            <div className="img_container">
              <img
                alt="Cover"
                src={getImageKitImageUrlForSanity(
                  companyData?.coverPhoto?.asset
                )}
                className="landscape-horizonal"
              />
            </div>
          </div>
          <div className="section">
            <div className="infor_section">
              <div className="sub_heading_section">
                {isDesktop && companyData?.secondaryPhoto && (
                  <div className="description_img_container">
                    <img
                      alt=""
                      src={getImageKitImageUrlForSanity(
                        companyData?.secondaryPhoto?.asset
                      )}
                      className="landscape-horizonal"
                    />
                  </div>
                )}
              </div>
              <div className="long_description_section">
                {/* {getLoyaltyCompanyLongDescriptions(loyaltyCompanyData).map(
                  (des) => (
                    <p>{des}</p>
                  )
                )} */}
                <BmPortableText value={companyData?.longDescription} />
                {!isDesktop && companyData?.secondaryPhoto && (
                  <div className="description_img_container">
                    <img
                      alt=""
                      src={getImageKitImageUrlForSanity(
                        companyData?.secondaryPhoto?.asset
                      )}
                      className="landscape-horizonal"
                    />
                  </div>
                )}
              </div>
              <div className="offer_section">
                {companyData?.informationBoxTxt && (
                  <div className="offer_wrapper">
                    <h3>{companyData?.informationBoxTxt?.infoTitleTxt}</h3>
                    <BmPortableText
                      value={companyData?.informationBoxTxt?.infoDescriptionTxt}
                    />
                  </div>
                )}
                {companyData?.informationBoxPromo && (
                  <div className="offer_wrapper">
                    <h3>{companyData?.informationBoxPromo?.infoTitlePromo}</h3>
                    {/* <p>{companyData?.infoDescriptionTxt}</p>
                  <ul>
                    {companyBenefits?.list.map((item) => (
                      <li>{item}</li>
                    ))}
                  </ul> */}
                    <BmPortableText
                      value={
                        companyData?.informationBoxPromo?.infoDescriptionPromo
                      }
                    />
                    {authUser?.isSubscribeToBoligmappaPlus ||
                      (showContactInfoAlways && (
                        <div className="benefit_btn">
                          <span>Bruk rabattkode</span>
                          <span className="benefit_btn_text">
                            {companyData?.informationBoxPromo?.infoPromoCode}
                          </span>
                        </div>
                      ))}
                  </div>
                )}
                {companyData?.alwaysOnCta?.infoTitleCta && (
                  <ContactMeBox title={companyData?.alwaysOnCta.infoTitleCta}>
                    <BmPortableText
                      value={companyData?.alwaysOnCta.alwaysOnDescription}
                    />
                    <a
                      className="information_box_cta_link"
                      href={companyData?.alwaysOnCta.infoCtaLink.href}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {companyData?.alwaysOnCta.infoCtaLink.title}
                    </a>
                  </ContactMeBox>
                )}
                {(authUser?.isSubscribeToBoligmappaPlus ||
                  showContactInfoAlways) &&
                  companyData?.informationBoxCta?.infoTitleCta && (
                    <ContactMeBox
                      title={companyData?.informationBoxCta.infoTitleCta}
                    >
                      <BmPortableText
                        value={
                          companyData?.informationBoxCta.infoDescriptionCta
                        }
                      />
                      <a
                        className="information_box_cta_link"
                        href={companyData?.informationBoxCta.infoCtaLink.href}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() =>
                          handleMatomoClickEvent(
                            'loyalty page clicks',
                            companyCode
                          )
                        }
                      >
                        {companyData?.informationBoxCta.infoCtaLink.title}
                      </a>
                    </ContactMeBox>
                  )}
                {(authUser?.isSubscribeToBoligmappaPlus ||
                  showContactInfoAlways) &&
                  companyData?.enableContactMe && (
                    <>
                      <ContactMeBox
                        title={
                          companyData.informationBoxContactForm?.contactFormText
                            ?.title || 'Gratis byggteknisk rådgivning'
                        }
                        onButtonClick={onContactMeClick}
                      >
                        <BmPortableText
                          value={
                            companyData.informationBoxContactForm
                              ?.contactFormText?.description ||
                            'Vi tar kontakt for å fortelle mer så snart vi har mottatt en forespørsel.'
                          }
                        />
                      </ContactMeBox>
                      <CustomDialog
                        isShown={isDialog}
                        onClose={() => setIsDialog(false)}
                      >
                        <div className="dialog_content">
                          <h2>
                            {companyData.informationBoxContactForm?.contactForm
                              ?.contactFormHeading || 'Kontakt meg'}
                          </h2>
                          <p>Opplysninger som deles:</p>
                          <div className="form_wrapper">
                            <div className="row">
                              <p className="label">Navn</p>
                              <p className="label">{formData.name}</p>
                            </div>
                            <div className="row">
                              <p className="label">E-post</p>
                              <input
                                type="text"
                                className="editable-field"
                                value={formData.email}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    email: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="row">
                              <p className="label">Telefon</p>
                              <input
                                type="text"
                                className="editable-field"
                                value={formData.phone}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    phone: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="comment_wrapper">
                            <label htmlFor="commentInput">
                              Kommentar (valgfritt):
                            </label>
                            <textarea
                              id="commentInput"
                              name="comment"
                              placeholder="Relevante opplysninger om din forespørsel."
                              value={formData.comment}
                              onChange={(e) => {
                                if (e.target.value.length <= 1500) {
                                  onFieldValueChange(e);
                                  setCommentQty(e.target.value.length);
                                }
                              }}
                            />
                            <p>{commentQty}/1500</p>
                            <button
                              className="submit_comment"
                              type="button"
                              onClick={onContactMeSubmit}
                            >
                              Send inn
                            </button>
                          </div>
                        </div>
                      </CustomDialog>
                      <CustomDialog isShown={isSuccessMessage} hideClose>
                        <div className="success_message">
                          <div className="content">
                            <CheckCircleRounded width={30} height={30} />
                            <h2>Takk for din henvendelse!</h2>
                            <p>
                              Vi tar kontakt for å fortelle mer så snart vi har
                              mottatt en forespørsel
                            </p>
                          </div>
                          <button
                            className="close_success_message"
                            type="button"
                            onClick={() => setIsSuccessMessage(false)}
                          >
                            Lukk
                          </button>
                        </div>
                      </CustomDialog>
                    </>
                  )}
                {isDesktop && (
                  <CompanyInfo
                    loyaltyCompany={companyData}
                    showEmail={
                      authUser?.isSubscribeToBoligmappaPlus ||
                      showContactInfoAlways
                    }
                  />
                )}
              </div>
              {!isDesktop && (
                <div className="company_info">
                  <CompanyInfo
                    loyaltyCompany={companyData}
                    showEmail={
                      authUser?.isSubscribeToBoligmappaPlus ||
                      showContactInfoAlways
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </StyledLoyaltyCompany>
  );
};

const mapStateToProps = (state) => ({
  authUser: sGetUser(state),
});

export default connect(mapStateToProps)(LoyaltyCompany);
