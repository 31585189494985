import { makeStyles } from '@material-ui/core';

export const FAQStyles = makeStyles((theme) => ({
  faqSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    flex: '1',
    '& .MuiAccordionSummary-root': {
      padding: '0px',
    },
  },
  faqTitle: {
    fontSize: '24px',
    fontWeight: '700',
    color: theme.palette.primary.darkGreen,
    '@media only screen and (max-width: 768px)': {
      fontSize: '20px',
      fontWeight: '600',
    },
    '& .Mui-expanded': {
      margin: '12px 0',
    },
  },
  accordion: {
    boxShadow: 'none',
    position: 'unset',
    margin: '0px 0 !important',
  },
  muiAccordionSummary: {
    '& .MuiAccordionSummary-content': {
      margin: '12px 0',
    },
  },
  accordionSummary: {
    height: '41px',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    padding: '0px 16px 0px 4px',
    '@media only screen and (max-width: 768px)': {
      padding: '0px 16px 0px 4px',
    },
    '& .accordionIconSection': {
      width: '41px',
      height: '41px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&.open': {
        borderRadius: '50px',
        color: theme.colors.white,
        backgroundColor: theme.colors.darkGreen,
      },
    },
    '&:hover': {
      '& .accordionIconSection': {
        borderRadius: '50px',
        backgroundColor: theme.colors.mint,
      },
    },
  },
  openedAccordionSummary: {
    borderRadius: '50px',
    backgroundColor: theme.colors.mint,
  },
  accordionTitle: {
    fontWeight: 'bold',
  },
  accordionIcon: {
    width: '24px',
    height: '24px',
  },

  accordionContent: {
    fontWeight: '400',
  },
}));
