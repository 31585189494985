import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core';

import {
  getErrorMessage,
  getResponseErrorMessage,
} from '../../../utils/requests';

import styles from './style';
import useDesktop from '../../../hooks/useDesktop';
import BaseDialog from '../../atomic/Dialog';
import DialogTitle from '../../DialogTitle';
import DialogContent from '../../atomic/DialogContent';
import DialogActions from '../../atomic/DialogActions';
import { ActionButtons } from '../../Buttons';
import ErrorContainer from '../../ErrorContainer';

const BaseDialogForm = ({
  classes,
  header,
  open,
  onClose,
  children,
  error,
  fetching,
  fetchingError,
  onSubmit,
  onCancel,
  cancelButtonText,
  submitButtonText,
  requestInProgress,
  showCancelButton = true,
  showLargeSubmitButton = false,
  preventDefault = true,
  submitButtonDisabled = false,
  ...props
}) => {
  const isDesktop = useDesktop();
  const errorContentRef = useRef(null);

  useEffect(() => {
    if (errorContentRef.current) {
      errorContentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [error]);

  const onFormSubmit = (event) => {
    if (preventDefault) {
      event.preventDefault();
    }

    if (onSubmit) {
      onSubmit(event);
    }
  };

  return (
    <form onSubmit={onFormSubmit} {...props}>
      <BaseDialog
        open={open}
        scroll="paper"
        onClose={onClose}
        fullScreen={!isDesktop}
        className={isDesktop && classes.dialogDesktop}
      >
        <DialogTitle title={header} onClose={onClose} />

        {fetchingError ? (
          <ErrorContainer
            paddingTop={isDesktop}
            errorResponse={fetchingError}
            style={{ paddingTop: !isDesktop ? '15%' : undefined }}
          />
        ) : (
          <>
            <DialogContent
              className={
                isDesktop ? classes.dialogPadding : classes.dialogPaddingMobile
              }
            >
              {error && (
                <div
                  className={clsx(classes.error, 'word-break')}
                  ref={errorContentRef}
                >
                  {getResponseErrorMessage(error) || getErrorMessage(error)}
                </div>
              )}

              {children}
            </DialogContent>

            <DialogActions
              className={clsx(
                showLargeSubmitButton && classes.actionButtonsCenter,
                isDesktop ? classes.actionButtons : classes.actionButtonsMobile
              )}
            >
              <ActionButtons
                fetching={fetching}
                onCancel={onCancel || onClose}
                onSubmit={onFormSubmit}
                cancelButtonText={cancelButtonText}
                submitButtonText={submitButtonText}
                requestInProgress={requestInProgress}
                showCancelButton={showCancelButton}
                showLargeSubmitButton={showLargeSubmitButton}
                submitButtonDisabled={submitButtonDisabled}
              />
            </DialogActions>
          </>
        )}
      </BaseDialog>
    </form>
  );
};

export default withStyles(styles)(BaseDialogForm);
