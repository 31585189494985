import React from 'react';
import Box from '../../atomic/Box';
import Grid from '../../atomic/Grid';
import ProfessionalItemLoading from './ProfessionalItemLoading';

const ProfessionalListLoading = () => {
  return (
    <Box py={2}>
      <Grid container spacing={4}>
        {[...Array(12).keys()].map((itemKey) => (
          <Grid
            item
            xs={12}
            md={6}
            key={`professional-grid-loading-item-${itemKey}`}
          >
            <ProfessionalItemLoading
              key={`professional-item-loading-${itemKey}`}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProfessionalListLoading;
