import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import {
  sGetCommitments,
  sGetFetchingCommitments,
  sGetFetchingCommitmentsError,
} from '../../../../../store/reducers/commitments';

import {
  acSetCommitments,
  acSetFetchingCommitments,
  acSetFetchingCommitmentsError,
} from '../../../../../store/actions/commitments';

import useDesktop from '../../../../../hooks/useDesktop';
import Paper from '../../../../../components/atomic/Paper';
import Page from '../../../../../components/layouts/WithHeader';
import useFetchEffect from '../../../../../hooks/useFetchEffect';
import ErrorContainer from '../../../../../components/ErrorContainer';
import PropertyCommitment from '../../../../../modules/PropertyCommitment';
import { fetchCommitments as apiFetchCommitments } from '../../../../../api/properties';

const Commitments = ({
  fetching,
  commitments,
  setFetching,
  fetchingError,
  setCommitments,
  setFetchingError,
}) => {
  const isDesktop = useDesktop();
  const { boligmappaNumber } = useParams();

  useFetchEffect({
    conditionFunction: () => !commitments,
    apiFetchFunction: () => apiFetchCommitments(boligmappaNumber),

    onFetchComplete: () => setFetching(false),
    onFetchStart: () => setFetchingError(null),

    onSuccess: (data) => setCommitments(data),
    onError: (error) => setFetchingError(error),
  });

  return (
    <Page
      minFullHeight
      noPadding={!isDesktop}
      hideHeader={isDesktop}
      headerTitle="Servitutter"
      lessMarginLeft={isDesktop}
      maxWidth="lg"
    >
      {!isDesktop && <br />}
      {fetching ? (
        <Paper visible={!isDesktop}>
          <PropertyCommitment fetching />
        </Paper>
      ) : (
        <>
          {fetchingError ? (
            <ErrorContainer
              marginTop={isDesktop}
              errorResponse={fetchingError}
            />
          ) : (
            commitments.map((commitment) => (
              <>
                <Paper visible={!isDesktop}>
                  <PropertyCommitment commitment={commitment} />
                </Paper>
                <br />
              </>
            ))
          )}
        </>
      )}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  commitments: sGetCommitments(state),
  fetching: sGetFetchingCommitments(state),
  fetchingError: sGetFetchingCommitmentsError(state),
});

const mapDispatchToProps = (dispatch) => ({
  setFetching: (fetching) => dispatch(acSetFetchingCommitments(fetching)),
  setCommitments: (commitments) => dispatch(acSetCommitments(commitments)),
  setFetchingError: (error) => dispatch(acSetFetchingCommitmentsError(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Commitments);
