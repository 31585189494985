import React from 'react';

const HjemlaLogo = ({ width = '100%', height = '100%', invert, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="9.57133"
      y="11.5493"
      width="6.20659"
      height="6.60419"
      fill="#FFC600"
    />
    <path
      d="M10.2053 22.5362L16.1243 27.1685C16.493 27.457 17.0125 27.4514 17.3748 27.1549L23.0044 22.5489C23.8176 21.8836 24.2892 20.8884 24.2892 19.8378V13.8552C24.2892 12.4734 23.1689 11.3531 21.7871 11.3531H11.3633C9.98147 11.3531 8.86125 12.4734 8.86125 13.8552V19.7777C8.86125 20.855 9.35693 21.8723 10.2053 22.5362Z"
      stroke={invert ? '#ffffff' : '#383838'}
      strokeWidth="2.00165"
    />
    <path
      d="M2.34487 7.46375L8.26384 2.83151C8.63256 2.54296 9.15205 2.54859 9.51442 2.84507L15.144 7.4511C15.9572 8.1164 16.4287 9.11156 16.4287 10.1622V16.1448C16.4287 17.5266 15.3085 18.6469 13.9267 18.6469H3.50291C2.12105 18.6469 1.00084 17.5266 1.00084 16.1448V10.2223C1.00084 9.14503 1.49652 8.12768 2.34487 7.46375Z"
      stroke={invert ? '#ffffff' : '#383838'}
      strokeWidth="2.00165"
    />
    <path
      d="M46.86 20.875H44.46V14.475H37.28V20.875H34.88V5.99498H37.28V12.255H44.46V5.99498H46.86V20.875Z"
      fill="#383838"
    />
    <path
      d="M51.4222 7.83498C50.6422 7.83498 50.0422 7.25498 50.0422 6.49498C50.0422 5.71498 50.6422 5.13498 51.4222 5.13498C52.2022 5.13498 52.8022 5.71498 52.8022 6.49498C52.8022 7.25498 52.2022 7.83498 51.4222 7.83498ZM50.8822 25.075H48.2022C49.5622 23.595 50.2422 22.015 50.2422 19.395V9.95498H52.6022V19.235C52.6022 21.635 52.1422 23.455 50.8822 25.075Z"
      fill="#383838"
    />
    <path
      d="M65.1495 15.215C65.1495 15.555 65.1295 15.855 65.0895 16.135H57.2295C57.5695 18.055 59.0295 19.175 60.9495 19.175C62.1895 19.175 63.4095 18.735 64.5095 17.875V19.995C63.3895 20.755 62.1095 21.135 60.7095 21.135C57.1295 21.135 54.9695 18.815 54.9695 15.455C54.9695 11.955 57.4095 9.69498 60.3295 9.69498C62.9695 9.69498 65.1495 11.595 65.1495 15.215ZM57.2295 14.555H62.9095C62.8495 12.615 61.6695 11.655 60.3095 11.655C58.8295 11.655 57.5095 12.755 57.2295 14.555Z"
      fill="#383838"
    />
    <path
      d="M78.7627 9.69498C81.1427 9.69498 82.2427 11.295 82.2427 13.615V20.875H79.8827V14.035C79.8827 12.635 79.3427 11.895 78.0427 11.895C76.7627 11.895 76.0227 12.895 76.0227 13.995V20.875H73.6627V14.035C73.6627 12.635 73.1227 11.895 71.8227 11.895C70.5027 11.895 69.8227 12.955 69.8227 14.115V20.875H67.4627V9.95498H69.2827L69.5427 11.535H69.6027C70.1627 10.515 71.1427 9.69498 72.5627 9.69498C74.1227 9.69498 75.0827 10.415 75.6227 11.595C76.2827 10.455 77.3227 9.69498 78.7627 9.69498Z"
      fill="#383838"
    />
    <path d="M87.5166 20.875H85.1566V4.85498H87.5166V20.875Z" fill="#383838" />
    <path
      d="M94.8039 21.135C92.3039 21.135 89.9039 18.875 89.9039 15.435C89.9039 11.955 92.3039 9.69498 94.8039 9.69498C96.4039 9.69498 97.8039 10.495 98.5839 11.835L98.8439 9.95498H100.804V20.875H98.8439L98.5839 18.995C97.8039 20.335 96.4039 21.135 94.8039 21.135ZM95.4039 18.975C97.1439 18.975 98.5039 17.515 98.5039 15.415C98.5039 13.335 97.1439 11.855 95.4039 11.855C93.6239 11.855 92.2639 13.335 92.2639 15.415C92.2639 17.515 93.6239 18.975 95.4039 18.975Z"
      fill="#383838"
    />
  </svg>
);

export default HjemlaLogo;
