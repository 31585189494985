import React, { useState } from 'react';
import { useFindProfessionalsStyles } from '../../findProfessionalStyles';
import useDashboardLinkPrefix from '../../../../hooks/useDashboardLinkPrefix';
import Card from '../../../Card';
import Image from '../../../Image';
import Tooltip from '../../../Tooltip';
import Box from '../../../atomic/Box';
import Grid from '../../../atomic/Grid';
import Link from '../../../atomic/Link';
import Button from '../../../atomic/Button';
import Typography from '../../../atomic/Typography';
import IconButton from '../../../atomic/IconButton';
import Badge from '../../../../assets/icons/Badge';
import CertifcateIcon from '../../../../assets/icons/Certificate';
import CompanyLogoPlaceholder from '../../../../assets/images/findProfesionalCompanyLogo.png';
import {
  getCompanyLogo,
  getProfessionTypes,
  getCertificates,
  getPlantCount,
  getProfessionTypeName,
  getCompanyName,
  getTruncatedTitle,
  getDistance,
  getCompanyId,
  // uncomment when testing getCompanyOrgNumber
} from '../../../../utils/findProfessional';
import matomo from '../../../../utils/matomo';

import { getCompanyContactPersonEmail } from '../../../../utils/companies';

const ProfessionalListItem = ({ company, setSelectedCompany }) => {
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const classes = useFindProfessionalsStyles();
  const [isOpen, setIsOpen] = useState(false);
  const certificates = getCertificates(company) || [];
  const companyProfessions = getProfessionTypes(company) || [];

  const documentLink = `${dashboardLinkPrefix}/company/${getCompanyId(
    company
  )}`;

  const toggleTooltip = (e) => {
    setIsOpen((prev) => !prev);
    e.preventDefault();
    e.stopPropagation();
  };

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedCompany(company);
    matomo.clickEvent({
      category: 'Find Professionals',
      name: `Contact from Find Professional`,
      action: 'Contact Company',
    });
  };

  const TooltipContent = (
    <>
      {(companyProfessions || [])
        .slice(2, companyProfessions.length)
        .map((professionType) => (
          <li key={getProfessionTypeName(professionType)}>
            {' '}
            {getProfessionTypeName(professionType)}
          </li>
        ))}
    </>
  );

  const onCardClick = () => {
    matomo.clickEvent({
      category: 'Find Professionals',
      name: `Visit Profile from Find Professional`,
      action: 'Visit Company Profile',
    });
  };

  const ProfessionTypeTag = (
    <>
      {(companyProfessions || []).slice(0, 2).map((professionType) => (
        <div
          className={classes.professionTypeTag}
          key={`tag-${getProfessionTypeName(professionType)}`}
        >
          <span> {getProfessionTypeName(professionType)}</span>
        </div>
      ))}

      {companyProfessions.length > 2 && (
        <Tooltip
          placement="right"
          title={TooltipContent}
          disableHoverListener={false}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
        >
          <IconButton
            className={classes.professionTypeTag}
            onClick={toggleTooltip}
          >
            <span className={classes.profetionTypeText}>
              {' '}
              +{companyProfessions.length - 2}
            </span>
          </IconButton>
        </Tooltip>
      )}
    </>
  );

  const ProfessionTypeNotAvailableTag = (
    <div className={classes.emptyProfessionTypeTag}>
      <span> Fagområde mangler </span>
    </div>
  );

  const DistanceTag = (
    <div className={classes.distanceTag}>
      <span> {getDistance(company)}km fra deg </span>
    </div>
  );

  const Certificates = (
    <div className={classes.text}>Sertifisert av : {certificates.length}</div>
  );

  return (
    <Link to={documentLink} underline={false}>
      <Card
        borderRadius={8}
        borderColor="#e5e5e5"
        className={classes.companyCard}
        onClick={onCardClick}
      >
        <Grid container spacing={1}>
          <Grid item xs={5} className={classes.companyCardImageArea}>
            <Box className={classes.companyImageSection}>
              <Image
                className={classes.logoImage}
                fallbackSrc={CompanyLogoPlaceholder}
                src={getCompanyLogo(company)}
              />
            </Box>
          </Grid>

          <Grid item xs={7}>
            <Box mb={2} className={classes.companyCardMobilleDistanceArea}>
              {getDistance(company) != null && DistanceTag}
            </Box>

            <Box mb={2} className={classes.companyCardTitleArea}>
              <Typography className={classes.title}>
                {getTruncatedTitle(getCompanyName(company))}
              </Typography>
              <div className={classes.distanceTagArea}>
                {' '}
                {getDistance(company) && DistanceTag}
              </div>
            </Box>

            <Box className={classes.companyProfessionDataArea}>
              {companyProfessions.length > 0
                ? ProfessionTypeTag
                : ProfessionTypeNotAvailableTag}
            </Box>

            <Box className={classes.companyProfessionDataArea}>
              <div>
                <Badge />
              </div>
              <Typography className={classes.text}>
                {getPlantCount(company)} jobber i Boligmappa
              </Typography>
            </Box>

            {certificates.length > 0 && (
              <Box className={classes.companyCertificateArea}>
                <div>
                  <CertifcateIcon />
                </div>
                {Certificates}
              </Box>
            )}
          </Grid>

          <Grid item xs={12} className={classes.companyButtonArea}>
            {getCompanyContactPersonEmail(company) != null && (
              <Button
                className={classes.companyCardButton}
                onClick={onClick}
                underline={false}
              >
                <span>Kontakt bedriften</span>
              </Button>
            )}
            {getCompanyContactPersonEmail(company) == null && (
              <Typography className={classes.companyCardContactMissingText}>
                Kontaktinfo er ikke tilgjengelig
              </Typography>
            )}
          </Grid>
        </Grid>
      </Card>
    </Link>
  );
};

export default ProfessionalListItem;
