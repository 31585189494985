import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import {
  createEvent as apiCreateEvent,
  uploadImage as apiUploadImage,
} from '../../../../../../api/events';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import { acSyncRoomsEvents } from '../../../../../../store/actions/rooms';
import { sGetRooms } from '../../../../../../store/reducers/rooms';
import {
  acSyncDocumentsEvents,
  acSetMultipleDocumentsSelected,
} from '../../../../../../store/actions/documents';
import {
  sGetDocuments,
  sGetMultipeDocumentsSelected,
} from '../../../../../../store/reducers/documents';
import {
  sGetEvents,
  sGetReturnOnSuccess,
  sGetShowAddEvent,
} from '../../../../../../store/reducers/events';
import {
  acAddEvent,
  acSetShowAddEvent,
  acSetEventReturnOnSuccess,
} from '../../../../../../store/actions/events';
import { sGetActiveProperty } from '../../../../../../store/reducers/properties';
import { getPropertyBoligmappaNumber } from '../../../../../../utils/properties';
import useDashboardLinkPrefix from '../../../../../../hooks/useDashboardLinkPrefix';
import useActionDialog from '../../../../../../hooks/useActionDialog';
import EventForm from '../../../../../../components/forms/EventForm';
import { uploadImages } from '../../../../../../utils/images';
import matomo from '../../../../../../utils/matomo';

export const AddEvent = ({
  rooms,
  events,
  documents,
  activeProperty,
  showAddEvent,
  addEvent,
  syncRoomsEvents,
  setShowAddEvent,
  returnOnSuccess,
  syncDocumentEvents,
  setReturnonSuccess,
  mulitipleDocumentsSelected,
  setMulitipleDocumentsSelected,
}) => {
  const history = useHistory();
  const { boligmappaNumber } = useParams();
  const { showSuccessMessage } = useSnackbar();

  const [error, setError] = useState(null);
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [disableDocumentControl, setDisableDocumentControl] = useState(false);

  const { showActionDialog } = useActionDialog();

  const resetDialog = () => {
    setError(null);
    setShowAddEvent(false);
  };
  useEffect(() => {
    if (mulitipleDocumentsSelected) {
      setDisableDocumentControl(true);
    } else setDisableDocumentControl(false);
  }, [mulitipleDocumentsSelected]);

  const onClose = (closeCallback, showActionStatus = false) => {
    if (showActionStatus) {
      resetDialog();
      closeCallback();
      setMulitipleDocumentsSelected(false);
      return;
    }
    showActionDialog({
      title: 'Hendelsen er ikke lagret',
      showCancelButton: false,
      submitButtonText: 'Avbryt',
      submitButtonVariant: 'contained',
      message: 'Er du sikker på at du vil avbryte?',
      onSubmit: () => {
        resetDialog();
        closeCallback();
        setMulitipleDocumentsSelected(false);
      },
    });
  };

  const onSubmitEventComplete = (eventId, submitCallback) => {
    setRequestInProgress(false);
    onClose(submitCallback, true);
    showSuccessMessage('Hendelse opprettet.');

    if (!returnOnSuccess) {
      history.push(`${dashboardLinkPrefix}/events/${eventId}`);
    }

    setReturnonSuccess(false);
    setMulitipleDocumentsSelected(false);
  };

  const uploadEventImages = (imageFiles, eventId, submitCallback) => {
    const uploadImageRequest = (image, position) =>
      apiUploadImage(eventId, image, position);
    uploadImages({
      imageFiles,
      positionStart: 0,
      uploadRequest: uploadImageRequest,
      onComplete: () => {
        onSubmitEventComplete(eventId, submitCallback);
      },
      onError: () => {},
      onImageUploaded: () => {},
    });
  };

  const onFormSubmit = async (event, submitCallback, imageFiles) => {
    setError(null);
    setRequestInProgress(true);

    const [errorResponse, data] = await apiCreateEvent({
      ...event,
      boligmappaNumber: getPropertyBoligmappaNumber(activeProperty),
      // TODO fetch from api
      type: 1,
    });

    matomo.clickEvent({
      category: 'Events',
      action: 'Create New Event',
    });

    if (errorResponse) {
      setError(errorResponse);
      setRequestInProgress(false);
    } else {
      const { roomIds, documentIds } = event;
      const { id: eventId } = data;

      if (events) {
        addEvent({
          ...event,
          ...data,
        });
      }

      if (rooms) {
        syncRoomsEvents({
          eventId,
          roomIds,
          event: {
            ...event,
            ...data,
          },
        });
      }

      if (documents) {
        syncDocumentEvents({
          eventId,
          documentIds,
          event: {
            ...event,
            ...data,
          },
        });
      }

      if (imageFiles?.length > 0) {
        uploadEventImages(imageFiles, eventId, submitCallback);
      } else {
        onSubmitEventComplete(eventId, submitCallback);
      }
    }
  };

  return (
    <EventForm
      isCreate
      open={showAddEvent}
      onClose={onClose}
      onSubmit={onFormSubmit}
      requestInProgress={requestInProgress}
      error={error}
      header="Legg til hendelse"
      boligmappaNumber={boligmappaNumber}
      disableDocumentControl={disableDocumentControl}
    />
  );
};

const mapStateToProps = (state) => ({
  rooms: sGetRooms(state),
  events: sGetEvents(state),
  documents: sGetDocuments(state),
  activeProperty: sGetActiveProperty(state),
  showAddEvent: sGetShowAddEvent(state),
  returnOnSuccess: sGetReturnOnSuccess(state),
  mulitipleDocumentsSelected: sGetMultipeDocumentsSelected(state),
});

const mapDispatchToProps = (dispatch) => ({
  addEvent: (event) => dispatch(acAddEvent(event)),
  syncRoomsEvents: (payload) => dispatch(acSyncRoomsEvents(payload)),
  setShowAddEvent: (payload) => dispatch(acSetShowAddEvent(payload)),
  syncDocumentEvents: (payload) => dispatch(acSyncDocumentsEvents(payload)),
  setReturnonSuccess: (payload) => dispatch(acSetEventReturnOnSuccess(payload)),
  setMulitipleDocumentsSelected: (payload) =>
    dispatch(acSetMultipleDocumentsSelected(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEvent);
