import sortBy from 'lodash-es/sortBy';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';

import {
  updateRoom as apiUpdateRoom,
  uploadImage as apiUploadImage,
} from '../../../../../../api/rooms';

import { getEventId } from '../../../../../../utils/events';
import useDesktop from '../../../../../../hooks/useDesktop';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import ImageForm from '../../../../../../components/forms/ImageForm';
import ErrorContainer from '../../../../../../components/ErrorContainer';
import PageLayout from '../../../../../../components/layouts/WithHeader';
import { getRoomEvents, getRoomImages } from '../../../../../../utils/rooms';
import matomo from '../../../../../../utils/matomo';

const Images = ({ room, fetching, onRoomUpdated, fetchingError }) => {
  const history = useHistory();
  const { roomId } = useParams();
  const isDesktop = useDesktop();
  const { showSuccessMessage } = useSnackbar();
  const [requestError, setRequestError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const byPosition = ({ position }) => position;
  const sortedImages = sortBy(getRoomImages(room) || [], byPosition);

  const onSubmit = async (images) => {
    setRequestInProgress(true);

    const imageIds = images.map(({ id }) => id);
    const [error, response] = await apiUpdateRoom(roomId, { imageIds });

    if (error && !response) {
      setRequestError(error);
    } else {
      onRoomUpdated({
        ...room,
        gallery: images,
        eventIds: (getRoomEvents(room) || []).map(getEventId),
      });

      matomo.clickEvent({
        category: 'Rooms',
        action: 'Add Image to Room',
        name: 'Add Image from Room',
        value: imageIds?.length,
      });

      showSuccessMessage();
      history.goBack();
    }

    setRequestInProgress(false);
  };

  const onImageUploadRequest = (image, position) =>
    apiUploadImage(roomId, image, position);

  const onImagesUploaded = (uploadedImages) =>
    onRoomUpdated({
      ...room,
      gallery: [...(getRoomImages(room) || []), ...uploadedImages],
    });

  return (
    <PageLayout minFullHeight headerTitle="Bilder">
      {fetchingError ? (
        <ErrorContainer
          paddingTop={isDesktop}
          errorResponse={fetchingError}
          style={{ paddingTop: !isDesktop ? '15%' : undefined }}
        />
      ) : (
        <ImageForm
          onSubmit={onSubmit}
          fetching={fetching}
          images={sortedImages}
          formError={requestError}
          onImagesUploaded={onImagesUploaded}
          requestInProgress={requestInProgress}
          uploadImageRequest={onImageUploadRequest}
        />
      )}
    </PageLayout>
  );
};

export default Images;
