import React from 'react';

const MoveDocumentIcon = ({ width = '1rem', height = '1rem', ...props }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.5 11.375C10.6875 11.375 10.875 11.2109 10.875 11V4.25H9C8.57812 4.25 8.25 3.92188 8.25 3.5V1.625H4.5C4.28906 1.625 4.125 1.8125 4.125 2V6.5H3V2C3 1.17969 3.65625 0.5 4.5 0.5H8.36719C8.76562 0.5 9.14062 0.664062 9.42188 0.945312L11.5547 3.07812C11.8359 3.35938 12 3.73438 12 4.13281V11C12 11.8438 11.3203 12.5 10.5 12.5H4.5C3.65625 12.5 3 11.8438 3 11V9.125H4.125V11C4.125 11.2109 4.28906 11.375 4.5 11.375H10.5ZM6.96094 5.53906L8.83594 7.41406C9.04688 7.64844 9.04688 8 8.83594 8.21094L6.96094 10.0859C6.72656 10.3203 6.375 10.3203 6.16406 10.0859C5.92969 9.875 5.92969 9.52344 6.16406 9.28906L7.07812 8.375H0.5625C0.234375 8.375 0 8.14062 0 7.8125C0 7.50781 0.234375 7.25 0.5625 7.25H7.07812L6.16406 6.33594C5.92969 6.125 5.92969 5.77344 6.16406 5.53906C6.375 5.32812 6.72656 5.32812 6.96094 5.53906Z"
      fill="#111111"
    />
  </svg>
);
export default MoveDocumentIcon;
