import React from 'react';

import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import BaseInputLabel from '@material-ui/core/InputLabel';

import styles from './style';

const InputLabel = ({ classes, className, ...props }) => (
  <BaseInputLabel className={clsx(className, classes.label)} {...props} />
);

export default withStyles(styles)(InputLabel);
