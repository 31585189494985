import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import EventCard from './EventCard';
import { compareAlphabetically } from '../../utils/strings';
import { getEventsByYear, getEventStartDate } from '../../utils/events';

const ChooseEventList = ({
  classes,
  events,
  searchQuery,
  selectedEvents,
  addSelectedEvent,
  removeSelectedEvent,
}) => {
  const eventsByYear = getEventsByYear(events);

  return Object.keys(eventsByYear)
    .sort((a, b) => b - a)
    .map((year) => (
      <div key={`events-year-${year}`}>
        <div className={classes.eventYear}>{year}</div>
        {eventsByYear[year]
          .sort((a, b) =>
            compareAlphabetically(
              getEventStartDate(a),
              getEventStartDate(b),
              'desc'
            )
          )
          .map((event) => (
            <EventCard
              event={event}
              key={`event-item-${event.id}`}
              searchQuery={searchQuery}
              selectedEvents={selectedEvents}
              addSelectedEvent={addSelectedEvent}
              removeSelectedEvent={removeSelectedEvent}
            />
          ))}
      </div>
    ));
};

export default withStyles(styles)(ChooseEventList);
