import React, { useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import { connect } from 'react-redux';

import useSnackbar from '../../../../../../hooks/useSnackbar';
import Page from '../../../../../../components/layouts/WithHeader';
import ErrorContainer from '../../../../../../components/ErrorContainer';
import DocumentForm from '../../../../../../components/forms/DocumentForm';
import { parseQuery } from '../../../../../../utils/routes';
import { updateDocument as apiUpdateDocument } from '../../../../../../api/documents';
import useDesktop from '../../../../../../hooks/useDesktop';
import {
  acSetShowAddRoom,
  acSetRoomAttachedIds,
} from '../../../../../../store/actions/rooms';
import {
  acSetEventAttachedIds,
  acSetEventReturnOnSuccess,
  acSetShowAddEvent,
} from '../../../../../../store/actions/events';

const Edit = ({
  document,
  fetching,
  fetchingError,
  onDocumentUpdated,
  setShowAddRoom,
  setShowAddEvent,
  setEventReturnOnSuccess,
  setEventAttachedIds,
  setRoomAttachedIds,
}) => {
  const history = useHistory();
  const isDesktop = useDesktop();
  const { showSuccessMessage } = useSnackbar();
  const { boligmappaNumber, documentId } = useParams();

  const { search } = useLocation();
  const parsedQuery = parseQuery(search);
  const { focusOnRoom, focusOnEvent } = parsedQuery || {};

  const attachRoomMode = focusOnRoom || false;
  const attachEventMode = focusOnEvent || false;

  const [error, setError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const onCancel = () => history.goBack();

  const onConnectNewRoomClick = () => {
    setRoomAttachedIds({
      roomIds: [],
      documentIds: [documentId],
    });

    setShowAddRoom(true);
  };

  const onConnectNewEventClick = () => {
    setEventReturnOnSuccess(true);
    setEventAttachedIds({
      roomIds: [],
      documentIds: [documentId],
    });
    setShowAddEvent(true);
  };

  const onFormSubmit = async (data) => {
    setError(null);
    setRequestInProgress(true);

    const [requestError, response] = await apiUpdateDocument(documentId, data);

    if (!response && requestError) {
      setError(requestError);
    } else {
      onDocumentUpdated({
        ...document,
        ...data,
      });

      showSuccessMessage();
      history.goBack();
    }

    setRequestInProgress(false);
  };

  const renderTitle = (
    <strong>
      {attachRoomMode && 'Koble til rom'}
      {attachEventMode && 'Koble til hendelse'}
      {!attachEventMode && !attachRoomMode && 'Redigere dokument'}
    </strong>
  );

  return (
    <Page
      minFullHeight
      headerTitle={renderTitle}
      lessMarginLeft={isDesktop}
      maxWidth="lg"
    >
      {fetchingError && <ErrorContainer errorResponse={fetchingError} />}

      {!fetchingError && (
        <DocumentForm
          editMode
          document={document}
          fetching={fetching}
          onCancel={onCancel}
          onSubmit={onFormSubmit}
          formRequestError={error}
          submitButtonText="Lagre"
          focusOnRoom={focusOnRoom}
          focusOnEvent={focusOnEvent}
          attachRoomMode={attachRoomMode}
          attachEventMode={attachEventMode}
          boligmappaNumber={boligmappaNumber}
          requestInProgress={requestInProgress}
          onConnectNewRoomClick={onConnectNewRoomClick}
          onConnectNewEventClick={onConnectNewEventClick}
        />
      )}
    </Page>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setShowAddRoom: (payload) => dispatch(acSetShowAddRoom(payload)),
  setShowAddEvent: (payload) => dispatch(acSetShowAddEvent(payload)),
  setEventAttachedIds: (payload) => dispatch(acSetEventAttachedIds(payload)),
  setRoomAttachedIds: (payload) => dispatch(acSetRoomAttachedIds(payload)),
  setEventReturnOnSuccess: (payload) =>
    dispatch(acSetEventReturnOnSuccess(payload)),
});

export default connect(null, mapDispatchToProps)(Edit);
