import React from 'react';

const EmailAlternate = ({
  width = '25',
  height = '25',
  strokeColor,
  fillColor,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 25 25"
    {...props}
  >
    <path
      d="M4.04358 4.04419H20.2204C21.3325 4.04419 22.2425 4.95413 22.2425 6.06629V18.1989C22.2425 19.311 21.3325 20.221 20.2204 20.221H4.04358C2.93143 20.221 2.02148 19.311 2.02148 18.1989V6.06629C2.02148 4.95413 2.93143 4.04419 4.04358 4.04419Z"
      stroke={strokeColor || '#13142A'}
      fill={fillColor || 'transparent'}
      strokeWidth="2.0221"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.2425 6.06628L12.132 13.1436L2.02148 6.06628"
      stroke={strokeColor || '#13142A'}
      fill={fillColor || 'transparent'}
      strokeWidth="2.0221"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EmailAlternate;
