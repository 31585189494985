import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';

const BaseFileIcon = ({
  text,
  x = 0,
  color,
  classes,
  width = '24',
  height = '26',
  fontSize = '7',
}) => (
  <svg
    id="PDF_icon"
    width={width}
    height={height}
    viewBox="0 0 20 24"
    data-name="PDF icon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Shape_42"
      d="M406,54.328V70a2,2,0,0,1-2,2H388a2,2,0,0,1-2-2V50a2,2,0,0,1,2-2h11.672a2,2,0,0,1,1.414.586l4.328,4.328A2,2,0,0,1,406,54.328Z"
      transform="translate(-386 -48)"
      fill={color}
    />
    <text
      transform="translate(3 18.932)"
      fill="#fff"
      fontSize={fontSize}
      fontWeight="100"
      letterSpacing="0.04em"
      className={classes.text}
    >
      <tspan x={x} y="0">
        {text}
      </tspan>
    </text>
  </svg>
);

export default withStyles(styles)(BaseFileIcon);
