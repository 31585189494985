import React from 'react';
import useGrid from '../../hooks/useGrid';
import Box from '../../components/atomic/Box';
import useDesktop from '../../hooks/useDesktop';
import Headline from '../../components/Headline';
import Hidden from '../../components/atomic/Hidden';
import SeeMoreLink from '../../components/SeeMoreLink';
import CompanyList from '../../components/CompanyList';
import Typography from '../../components/atomic/Typography';
import ContentLoader from '../../components/loaders/ContentLoader';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';
import matomo from '../../utils/matomo';

const PropertyCompanies = ({ error, fetching, companies }) => {
  const isDesktop = useDesktop();
  const { itemsPerLine } = useGrid();
  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const title = 'Boligens fagfolk';

  return (
    <>
      <Hidden lgUp>
        <Headline mb={0} align="center">
          {title}
        </Headline>
      </Hidden>

      <Hidden mdDown>
        <Typography
          noMargins
          variant="h2"
          align={isDesktop ? 'left' : 'center'}
        >
          {fetching && <ContentLoader height={26} width={160} />}

          {Array.isArray(companies) && title}
        </Typography>
      </Hidden>

      <CompanyList
        wrap={isDesktop ? 'nowrap' : 'wrap'}
        error={error}
        fetching={fetching}
        itemsPerLine={!isDesktop && 2}
        companies={(companies || []).slice(0, itemsPerLine)}
        showEmptyStatePlaceholder
        requestFrom="Property"
      />

      {!fetching &&
        Array.isArray(companies) &&
        companies.length > itemsPerLine && (
          <Box
            py={2}
            textAlign="right"
            onClick={() =>
              matomo.clickEvent({
                category: 'Dashboard CTAs',
                action: "See The Property's Professional",
              })
            }
          >
            <SeeMoreLink to={`${dashboardLinkPrefix}/companies`}>
              Se mer
            </SeeMoreLink>
          </Box>
        )}
    </>
  );
};

export default PropertyCompanies;
