const styles = (theme) => ({
  root: {
    display: 'flex',
    overflowX: 'auto',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    paddingLeft: theme.spacing(0.5),
    background: theme.palette.primary.white,
    borderTop: `1px solid ${theme.palette.primary.greyLighter}`,
    borderBottom: `1px solid ${theme.palette.primary.greyLighter}`,
  },
});

export default styles;
