import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

import styles from './style';
import CopyIcon from '../../assets/icons/Copy';
import EmailIcon from '../../assets/icons/Email';

const Copybox = ({
  str,
  classes,
  onClickUrl,
  onCopySuccess,
  emailButton,
  onEmailSend,
  isDesktop = true,
}) => {
  let textInputRef = null;

  const copyCodeToClipboard = () => {
    textInputRef.select();
    document.execCommand('copy');

    if (onCopySuccess) {
      onCopySuccess();
    }
  };

  return (
    <div className={classes.container}>
      <div
        className={clsx(classes.buttonContainer, !isDesktop && 'mobile')}
        onClick={copyCodeToClipboard}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
        aria-label="Copy"
      >
        <CopyIcon />
      </div>
      {emailButton && (
        <div
          className={clsx(classes.buttonContainer, !isDesktop && 'mobile')}
          onClick={onEmailSend && onEmailSend}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
          aria-label="Email"
        >
          <EmailIcon color="#707070" />
        </div>
      )}

      <input
        readOnly
        type="text"
        value={str}
        onClick={onClickUrl}
        className={clsx(classes.textInput, !isDesktop && 'mobile')}
        ref={(inputRef) => {
          textInputRef = inputRef;
        }}
      />
    </div>
  );
};

export default withStyles(styles)(Copybox);
