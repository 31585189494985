const inquiryTypes = [
  {
    value: 'Generell henvendelse',
    label: 'Generell henvendelse',
    title: 'Kontakt oss',
    intro:
      'Vennligst gi oss beskjed om eventuelle problemer du opplever og vi vil besvare henvendelsen din så fort vi kan.',
    desc: 'Beskrivelse',
  },
];

export default inquiryTypes;
