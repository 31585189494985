import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  hjemlaEstimateTitle: {
    fontWeight: 700,
    padding: 0,
    textAlign: 'center',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  hjemlaEstimateLogo: {
    marginLeft: '10px',
  },
  orange: {
    color: theme.palette.primary.grapeFruit,
  },
  tooltipLink: {
    color: theme.palette.primary.white,
  },
  hjemlaPopover: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex - start',
    padding: '12px 16px',
    position: 'static',
    width: '302px',
    height: '254px',
    left: '0px',
    top: '0px',
    background: '#3B3B3B',
    borderRadius: '5px',
    flex: 'none',
    order: '1',
    flexgrow: '0',
    margin: '0px 0px',
    color: '#ffffff',
    fontSize: '9pt',
  },
  hjemlaEstimatelink: { color: '#ffffff' },
}));
