import React from 'react';
import { withStyles } from '@material-ui/core';

import {
  getEmail,
  getStatus,
  getSharedBy,
  getSharedDate,
} from '../../../../../../../utils/privateshare';

import Styles from './style';
import List from '../../../../../../../components/atomic/List';
import { formattedDate } from '../../../../../../../utils/dates';
import Typography from '../../../../../../../components/atomic/Typography';
import ListItem from '../../../../../../../components/CardList/KeyValueListItem';

const SingleRecord = ({ record, classes }) => {
  const statusDiv = (status) => (
    <div
      className={
        status === 'active' ? classes.activeLabel : classes.pendingLabel
      }
    >
      <strong>{status === 'active' ? 'AKTIV' : 'VENTER'}</strong>
    </div>
  );
  return (
    <>
      <div>
        <Typography variant="h2">Detaljer</Typography>
      </div>
      <List>
        <ListItem label="E-post">{getEmail(record)}</ListItem>
        <ListItem label="Dato delt">
          {formattedDate(getSharedDate(record))}
        </ListItem>
        <ListItem label="Delt av">{getSharedBy(record)}</ListItem>
        <ListItem label="Status">{statusDiv(getStatus(record))}</ListItem>
      </List>
    </>
  );
};

export default withStyles(Styles)(SingleRecord);
