import React from 'react';

const MissingDocs = ({ width = '60px', height = '60px', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30 60.5996C46.5685 60.5996 60 47.1682 60 30.5996C60 14.0311 46.5685 0.599609 30 0.599609C13.4315 0.599609 0 14.0311 0 30.5996C0 47.1682 13.4315 60.5996 30 60.5996Z"
      fill="#D9E9E9"
    />
    <path
      d="M29.3333 16.5996V22.5996C29.3333 23.2816 29.6283 23.9356 30.1534 24.4179C30.6785 24.9001 31.3907 25.171 32.1333 25.171H38.6667V38.0282C38.6667 38.7102 38.3717 39.3642 37.8466 39.8465C37.3215 40.3287 36.6093 40.5996 35.8667 40.5996H22.8C22.0574 40.5996 21.3452 40.3287 20.8201 39.8465C20.295 39.3642 20 38.7102 20 38.0282V19.171C20 18.4891 20.295 17.835 20.8201 17.3528C21.3452 16.8705 22.0574 16.5996 22.8 16.5996H29.3333ZM31.2 17.0282V22.5996C31.2 22.8269 31.2983 23.045 31.4734 23.2057C31.6484 23.3664 31.8858 23.4568 32.1333 23.4568H38.2L31.2 17.0282Z"
      fill="#254E52"
    />
    <path
      d="M29.9997 59.2659C45.8318 59.2659 58.6663 46.4314 58.6663 30.5993C58.6663 14.7671 45.8318 1.93262 29.9997 1.93262C14.1675 1.93262 1.33301 14.7671 1.33301 30.5993C1.33301 46.4314 14.1675 59.2659 29.9997 59.2659Z"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);

export default MissingDocs;
