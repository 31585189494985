import styled from 'styled-components';

export const StyledPersonalAreaCard = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 8px;
  width: 100%;

  @media (max-width: 959px) {
    flex-direction: column;
  }

  h3,
  p {
    margin: 0;
    color: #164144;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 20px;
    line-height: 40px;
    font-weight: 700;
  }
  .card_text_section {
    flex-grow: 1;
    row-gap: 20px;
  }
  .card_img_section {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 150px;
    }
  }
  .learn_more {
    margin-bottom: 0;
    text-decoration-color: #164144;
  }
`;
