import clsx from 'clsx';
import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Box from '../../atomic/Box';
import Grid from '../../atomic/Grid';

const Container = ({ classes, className, children, ...props }) => (
  <Grid className={clsx(classes.root, className)} container {...props}>
    <Grid item lg={12} md={12}>
      <Box className={classes.content}>{children}</Box>
    </Grid>
  </Grid>
);

export default withStyles(styles)(Container);
