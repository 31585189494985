const styles = (theme) => ({
  listItem: {
    [theme.breakpoints.up('md')]: {
      width: '325px !important',
    },
    padding: '8px 5px',
  },
  listItemIcon: {
    minWidth: theme.spacing(5),
  },
  listItemText: {
    overflow: 'hidden',
  },
  checkbox: {
    marginRight: 0,
  },
  listItemSecondaryAction: {
    right: 0,
    transform: 'none',
    position: 'inherit',
    marginLeft: theme.spacing(1),
  },
  iconBox: {
    marginRight: '8px',
    marginTop: '3px',
  },
  deleteText: {
    color: '#D7394C',
    fontWeight: 400,
  },
});

export default styles;
