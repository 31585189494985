import axios from 'axios';

export const setHeader = (key, value) => {
  axios.defaults.headers[key] = value;
};

export const setAuthHeaders = ({ idToken, accessToken, refreshToken }) => {
  if (idToken) setHeader('id-token', idToken);
  if (accessToken) setHeader('authorization', `bearer ${accessToken}`);
  if (refreshToken) setHeader('refresh-token', refreshToken);
};

export const resetAuthHeaders = () => {
  delete axios.defaults.headers.id_token;
  delete axios.defaults.headers.Authorization;
  delete axios.defaults.headers.refresh_token;
};

export const request = (options) => axios(options);

export const all = (iterable) =>
  axios.all(iterable).then(axios.spread((...args) => args));

export const get = (...args) => axios.get(...args);

export const getblob = (...args) =>
  axios.get(...args, { responseType: 'blob' });

export const post = (url, data, options = null) =>
  axios.post(url, data, options);

export const put = (url, data, options = null) => axios.put(url, data, options);

export const patch = (url, data, options = null) =>
  axios.patch(url, data, options);

export const del = (url) => axios.delete(url);

export const delPayLoad = (url, data = null) => axios.delete(url, { data });
