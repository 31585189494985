import React from 'react';
import { useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core';

import {
  getPropertyZip,
  getPropertyImages,
  getPropertyAddress,
  getPropertyPostalPlace,
  getPropertyGoogleStreetImage,
  getPropertyBoligmappaNumber,
} from '../../utils/properties';

import { updateProperty as apiUpdateProperty } from '../../api/properties';
import { propertyUpdateAction } from '../../store/actions/properties';

import styles from './style';
import Box from '../../components/atomic/Box';
import Camera from '../../assets/icons/Camera';
import Link from '../../components/atomic/Link';
import Button from '../../components/atomic/Button';
import ImageGallery from '../../components/ImageGallery';
import { sortImagesByPosition } from '../../utils/images';
import Typography from '../../components/atomic/Typography';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';

const PropertyImages = ({
  classes,
  fetching,
  property,
  editAccess = true,
  googleStreetImageEnabled,
}) => {
  const dispatch = useDispatch();

  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const zip = getPropertyZip(property);
  const address = getPropertyAddress(property);
  const zipCode = zip && zip !== '0000' ? zip : null;
  const postalPlace = getPropertyPostalPlace(property);

  const editImagesLink = `${dashboardLinkPrefix}/images`;

  const googleMapsImage =
    !fetching && googleStreetImageEnabled
      ? { isGoogleImage: true, url: getPropertyGoogleStreetImage(property) }
      : undefined;

  const propertyImages = (getPropertyImages(property) || []).sort(
    sortImagesByPosition
  );

  const images = googleMapsImage
    ? [...propertyImages, googleMapsImage]
    : propertyImages;

  const showIndex = Array.isArray(images) && images.length > 0;

  const filterByIsGoogleImage = ({ isGoogleImage }) => !isGoogleImage;

  const handleImagesUpdate = (imagesToUpdate) => {
    const updatedImages = imagesToUpdate.map((image, index) => ({
      ...image,
      position: index + 1,
    }));

    dispatch(
      propertyUpdateAction({
        ...property,
        gallery: updatedImages,
      })
    );
  };

  const onImagesUpdated = async (updatedImages, onSuccess, onError) => {
    const currentImages = images.slice().filter(filterByIsGoogleImage);
    const imagesToUpdate = updatedImages.filter(filterByIsGoogleImage);

    handleImagesUpdate(imagesToUpdate);

    const [error, response] = await apiUpdateProperty(
      getPropertyBoligmappaNumber(property),
      {
        imageIds: imagesToUpdate.map((image) => image && image.id),
      }
    );

    if (onSuccess && !error && response) {
      onSuccess();
    }

    if (onError && error && !response) {
      onError(error);
    }

    if (error && !response) {
      handleImagesUpdate(currentImages);
    }
  };

  return (
    <div className={classes.container}>
      <ImageGallery
        dots={false}
        showPlaceholder
        images={images}
        editable={editAccess}
        showIndex={showIndex}
        fetching={!property || fetching}
        onImagesUpdated={onImagesUpdated}
      />

      {!fetching && editAccess && (
        <div className={classes.actions}>
          <Link to={editImagesLink} underline={false}>
            <Button size="small" className={classes.editButton}>
              <Camera className={classes.cameraIcon} />
              <small>Rediger</small>
            </Button>
          </Link>
        </div>
      )}

      {address && (
        <Box
          className={classes.gradientOverlay}
          position="absolute"
          left="0px"
          bottom="0px"
          right="0px"
        >
          <Typography
            noMargins
            variant="h2"
            paletteColor="white"
            className="word-break"
          >
            {address}
          </Typography>
          <Typography paletteColor="white">
            {zipCode}&nbsp;
            {postalPlace}
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default withStyles(styles)(PropertyImages);
