import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { withStyles } from '@material-ui/core';
import React, { createElement, useState } from 'react';

import Box from '../../../../components/atomic/Box';
import useDesktop from '../../../../hooks/useDesktop';
import SearchField from '../../../../components/SearchField';
import useFetchEffect from '../../../../hooks/useFetchEffect';
// import DocumentList from '../../../../components/DocumentList';
import EmptyMessage from '../../../../components/EmptyMessage';
import ErrorContainer from '../../../../components/ErrorContainer';
import { PUBLICSECTIONFORBIDDENERROR } from '../../../../constants';
import {
  acSetDocuments,
  acSetDocumentSearchText,
} from '../../../../store/actions/documents';
import {
  sGetDocuments,
  sGetDocumentSearchText,
} from '../../../../store/reducers/documents';
import styles from '../../../PrivateRoutes/Dashboard/Documents/style';
import { getPublicDocuments as apiFetchDocuments } from '../../../../api/documents';
import {
  getDocumentList,
  getDocumentTable,
} from '../../../../utils/DocumentTable';
import WithHeader from '../../../../components/layouts/WithHeader';
import MobileDocumentSearch from '../../../PrivateRoutes/Dashboard/Documents/MobileDocumentSearch';

const Documents = ({
  classes,
  documents,
  setDocuments,
  searchText,
  setSearchText,
}) => {
  const isDesktop = useDesktop();
  const { boligmappaNumber } = useParams();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [stickyFiltersHeight, setStickyFiltersHeight] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState(searchText);

  const { fetching, fetchingError } = useFetchEffect({
    dependencies: [boligmappaNumber],
    onSuccess: (_documents) => setDocuments(_documents),
    apiFetchFunction: () =>
      apiFetchDocuments(boligmappaNumber, { includeTaggedInfo: true }),
    conditionFunction: ([_boligmappaNumber]) =>
      !documents && !!_boligmappaNumber,
  });

  const showEmptyListMessage =
    !fetching && Array.isArray(documents) && documents.length === 0;

  const showDocumentList =
    fetching || (!fetching && Array.isArray(documents) && documents.length > 0);

  const onSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setSearchText(event.target.value);
  };

  const renderTable = () =>
    createElement(isDesktop ? getDocumentTable() : getDocumentList(), {
      fetching,
      documents,
      searchQuery,
      headerHeight,
      setStickyFiltersHeight,
      publicSection: true,
      searchEnabled: true,
      actionsEnabled: false,
      showRoomsColumn: true,
      showEventsColumn: true,
      isPersonalDocuments: false,
    });

  const mobileHeaderSearch = () => (
    <MobileDocumentSearch
      fullWidth
      value={searchQuery}
      onChange={onSearchChange}
      placeholder="Søk i dokumenter"
      isSearching={isSearching}
      setIsSearching={setIsSearching}
    />
  );

  return (
    <WithHeader
      topLevelLayout
      minFullHeight
      noPadding={!isDesktop}
      setHeaderHeight={setHeaderHeight}
      hideHeader={isDesktop}
      isSearching={isSearching}
      headerTitle="Dokumenter"
      headerSearch={mobileHeaderSearch()}
      publicSection
      lessMarginLeft={isDesktop}
      maxWidth="lg"
    >
      {fetchingError && (
        <ErrorContainer
          customErrorObject={{
            title: PUBLICSECTIONFORBIDDENERROR,
            message: '',
          }}
          paddingTop={isDesktop}
          errorResponse={fetchingError}
          style={{ paddingTop: isDesktop ? undefined : '15%' }}
        />
      )}

      {showDocumentList && (
        <div style={{ marginTop: stickyFiltersHeight }}>
          <Box px={!isDesktop ? 2 : 0} width={isDesktop ? '50%' : '100%'}>
            {isDesktop && (
              <SearchField
                value={searchQuery}
                placeholder="Søk i dokumenter"
                onChange={onSearchChange}
                className={clsx(isDesktop && classes.desktopSearchField)}
              />
            )}
          </Box>

          {isDesktop && <br />}

          <Box mt={2}>{renderTable()}</Box>
        </div>
      )}

      {showEmptyListMessage && (
        <EmptyMessage publicSection>Det finnes ingen dokumenter.</EmptyMessage>
      )}
    </WithHeader>
  );
};

Documents.defaultProps = {
  classes: {},
  documents: null,
};

Documents.propTypes = {
  classes: PropTypes.object, // eslint-disable-line
  setDocuments: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      chapterTags: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          tagName: PropTypes.string,
        })
      ),
      companyId: PropTypes.number,
      companyName: PropTypes.string,
      createdDate: PropTypes.string,
      description: PropTypes.string,
      documentFileAvailable: PropTypes.bool,
      documentType: PropTypes.string,
      documentTypeId: PropTypes.number,
      events: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          link: PropTypes.string,
          title: PropTypes.string,
          image: PropTypes.string,
        })
      ),
      fileName: PropTypes.string,
      id: PropTypes.number,
      industry: PropTypes.string,
      jobDate: PropTypes.string,
      ownedById: PropTypes.number,
      ownedByName: PropTypes.string,
      rooms: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          link: PropTypes.string,
          title: PropTypes.string,
          image: PropTypes.string,
        })
      ),
      title: PropTypes.string,
      updatedById: PropTypes.number,
      updatedDate: PropTypes.string,
      url: PropTypes.shape({
        fileType: PropTypes.string,
        link: PropTypes.string,
        rel: PropTypes.string,
      }),
    })
  ),
};

const mapStateToProps = (state) => ({
  documents: sGetDocuments(state),
  searchText: sGetDocumentSearchText(state),
});

const mapDispatchToProps = (dispatch) => ({
  setDocuments: (documents) => dispatch(acSetDocuments(documents)),
  setSearchText: (searchText) => dispatch(acSetDocumentSearchText(searchText)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Documents));
