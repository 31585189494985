import React from 'react';

const ChapterThree = ({
  width = '100%',
  height = '100%',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 29.523 29.523"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      transform="translate(-144 -1832)"
      d="M172.293,1844.3a1.232,1.232,0,0,1,1.23,1.23v2.46a1.232,1.232,0,0,1-1.23,1.23h-2.426a.613.613,0,0,0-.594.455,16.587,16.587,0,0,1-.67,2.042.611.611,0,0,0,.137.664l1.765,1.771a1.225,1.225,0,0,1,0,1.734L167.9,1858.5a1.231,1.231,0,0,1-1.739,0l-1.766-1.759a.6.6,0,0,0-.674-.135,16.9,16.9,0,0,1-2.038.677.62.62,0,0,0-.455.591v2.423a1.232,1.232,0,0,1-1.23,1.23h-2.46a1.232,1.232,0,0,1-1.23-1.23v-2.423a.6.6,0,0,0-.455-.591,16.914,16.914,0,0,1-2.04-.677.6.6,0,0,0-.675.135l-1.764,1.771a1.278,1.278,0,0,1-1.739,0l-2.61-2.608a1.24,1.24,0,0,1,0-1.747l1.77-1.771a.6.6,0,0,0,.133-.664,16.825,16.825,0,0,1-.675-2.042.612.612,0,0,0-.593-.455H145.23a1.232,1.232,0,0,1-1.23-1.23v-2.46a1.232,1.232,0,0,1,1.23-1.23h2.421a.613.613,0,0,0,.594-.455,16.6,16.6,0,0,1,.675-2.042.6.6,0,0,0-.138-.664l-1.765-1.771a1.231,1.231,0,0,1-.36-.873,1.253,1.253,0,0,1,.36-.874l2.61-2.608a1.231,1.231,0,0,1,1.739,0l1.765,1.771a.6.6,0,0,0,.675.135,16.806,16.806,0,0,1,2.037-.677.619.619,0,0,0,.456-.59v-2.423a1.232,1.232,0,0,1,1.23-1.23h2.46a1.232,1.232,0,0,1,1.23,1.23v2.423a.617.617,0,0,0,.456.6,14.906,14.906,0,0,1,2.036.664.624.624,0,0,0,.675-.135l1.765-1.771a1.231,1.231,0,0,1,1.739,0l2.61,2.608a1.254,1.254,0,0,1,.36.874,1.231,1.231,0,0,1-.36.873l-1.769,1.759a.619.619,0,0,0-.133.677,16.187,16.187,0,0,1,.674,2.042.613.613,0,0,0,.594.455Zm-8,3.075h1.23a.615.615,0,0,0,.413-1.07l-6.351-5.769a1.222,1.222,0,0,0-1.656,0l-6.351,5.769a.615.615,0,0,0,.413,1.07h1.23a.612.612,0,0,1,.615.615v3.69a.612.612,0,0,0,.615.615h2.46a.612.612,0,0,0,.615-.615v-1.845a1.23,1.23,0,0,1,2.46,0v1.845a.612.612,0,0,0,.615.615h2.46a.612.612,0,0,0,.615-.615v-3.69A.612.612,0,0,1,164.3,1847.377Z"
    />
  </svg>
);

export default ChapterThree;
