import React from 'react';
import { withStyles } from '@material-ui/core';

import {
  getDocumentCompanyName,
  getDocumentCreatedDate,
  getDocumentOwnedByName,
  getDocumentUpdatedDate,
} from '../../../../../../utils/documents';

import styles from './style';
import Link from '../../../../../../components/atomic/Link';
import { formattedDate } from '../../../../../../utils/dates';
import ArrowForward from '../../../../../../assets/icons/ArrowForward';
import ChapterMobileItem from '../../../../../../components/ChapterMobileItem';
import useDashboardLinkPrefix from '../../../../../../hooks/useDashboardLinkPrefix';

const ChapterItem = ({ classes, chapter, documents, ...props }) => {
  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const lastUpdatedDocument =
    Array.isArray(documents) &&
    documents.length > 0 &&
    documents.reduce((result, document) =>
      getDocumentCreatedDate(document) > getDocumentCreatedDate(result)
        ? document
        : result
    );

  const secondaryText =
    lastUpdatedDocument &&
    `${
      getDocumentOwnedByName(lastUpdatedDocument) ||
      getDocumentCompanyName(lastUpdatedDocument)
    } • ${formattedDate(getDocumentUpdatedDate(lastUpdatedDocument))}`;

  const rightText = Array.isArray(documents) && (
    <>
      {chapter.documentCount || documents.length} dok.
      <ArrowForward className={classes.arrowIcon} />
    </>
  );

  return (
    <Link
      inheritColor
      underline={false}
      to={`${dashboardLinkPrefix}/chapters/${chapter.id}`}
    >
      <ChapterMobileItem
        chapter={chapter}
        rightText={rightText}
        secondaryText={secondaryText}
        {...props}
      />
    </Link>
  );
};

export default withStyles(styles)(ChapterItem);
