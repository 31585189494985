import React from 'react';

const ArrowRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M15.0368 7.11913C16.9751 9.01455 18.0618 10.0772 20 11.9727L14.9807 16.881"
      stroke="#13142A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="5"
      y1="11.9761"
      x2="18"
      y2="11.9761"
      stroke="#13142A"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default ArrowRight;
