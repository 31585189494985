const styles = {
  placeholderIcon: {
    fill: '#fff',
    width: '100%',
    height: '100%',
    padding: '10%',
  },
};

export default styles;
