const styles = (theme) => ({
  listItem: ({ disabled }) => ({
    padding: '10px 16px',
    columnGap: '10px',
    cursor: disabled ? 'default' : 'pointer',

    '&:hover': {
      background: disabled ? undefined : theme.palette.primary.whiteDarker,
    },
  }),
  disabled: {
    cursor: 'default',
  },
  text: {
    fontWeight: 500,
  },
  disabledDelete: {
    color: '#CD4646',
  },
  delete: {
    color: '#D7394C',
    fontSize: '14px',
    fontWeight: 400,
    paddingLeft: '5px',
  },
});

export default styles;
