import clsx from 'clsx';
import React from 'react';
import { withStyles } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';

import styles from './style';
import Account from './Account';
import Link from '../../atomic/Link';
import AppBar from '../../atomic/AppBar';
import PropertySelect from './PropertySelect';
import useDashboardLinkPrefix from '../../../hooks/useDashboardLinkPrefix';

const Header = ({
  classes,
  className,
  isFromPersonal,
  ownedProperty,
  publicMode = false,
  isPersonalArea = false,
}) => {
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const privateShareLink = `${dashboardLinkPrefix}/more/private-share`;

  return (
    <AppBar
      elevation={1}
      color="inherit"
      position="sticky"
      className={clsx(className, classes.root)}
      style={{ zIndex: 1101 }}
    >
      <Toolbar disableGutters className={classes.headerWrapper}>
        {!isFromPersonal && (
          <PropertySelect
            publicMode={publicMode}
            isPersonalArea={isPersonalArea}
          />
        )}

        <div className={classes.grow} />
        {!isFromPersonal && ownedProperty && (
          <Link
            inheritColor
            to={privateShareLink}
            className={classes.titleNavItem}
          >
            <p>Del din boligmappe</p>
          </Link>
        )}

        {!publicMode && (
          <div>
            <Account
              isFromPersonal={isFromPersonal}
              ownedProperty={ownedProperty}
            />
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(Header);
