import React from 'react';

const DocumentMode = ({
  width = '100%',
  height = '100%',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 22.431"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill={fill}
      transform="translate(-227.449 -5154.5)"
      d="M235.527,5158h14.895a1,1,0,1,0,0-2H235.527a1,1,0,1,0,0,2Z"
    />
    <path
      fill={fill}
      transform="translate(-227.449 -5153.812)"
      d="M250.422,5164H235.527a1.027,1.027,0,0,0,0,2.055h14.895a1.027,1.027,0,0,0,0-2.055Z"
    />
    <path
      fill={fill}
      transform="translate(-227.449 -5153.069)"
      d="M250.422,5172H235.527a1,1,0,1,0,0,2h14.895a1,1,0,1,0,0-2Z"
    />
    <path
      fill={fill}
      transform="translate(-227 -5154.5)"
      d="M228,5156a.5.5,0,0,1,.5-.5h2a.5.5,0,0,1,.5.5v2a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1-.5-.5Z"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      transform="translate(-227 -5154.5)"
      d="M228.5,5154.5h2a1.5,1.5,0,0,1,1.5,1.5v2a1.5,1.5,0,0,1-1.5,1.5h-2a1.5,1.5,0,0,1-1.5-1.5v-2A1.5,1.5,0,0,1,228.5,5154.5Zm2,4a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5Z"
    />
    <path
      fill={fill}
      transform="translate(-227 -5153.785)"
      d="M228,5164a.5.5,0,0,1,.5-.5h2a.5.5,0,0,1,.5.5v2a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1-.5-.5Z"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      transform="translate(-227 -5153.785)"
      d="M228.5,5162.5h2a1.5,1.5,0,0,1,1.5,1.5v2a1.5,1.5,0,0,1-1.5,1.5h-2a1.5,1.5,0,0,1-1.5-1.5v-2A1.5,1.5,0,0,1,228.5,5162.5Zm2,4a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5Z"
    />
    <path
      fill={fill}
      transform="translate(-227 -5153.069)"
      d="M228,5172a.5.5,0,0,1,.5-.5h2a.5.5,0,0,1,.5.5v2a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1-.5-.5Z"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      transform="translate(-227 -5153.069)"
      d="M228.5,5170.5h2a1.5,1.5,0,0,1,1.5,1.5v2a1.5,1.5,0,0,1-1.5,1.5h-2a1.5,1.5,0,0,1-1.5-1.5v-2A1.5,1.5,0,0,1,228.5,5170.5Zm2,4a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5Z"
    />
  </svg>
);

export default DocumentMode;
