import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';

import styles from './style';
import BaseListItem from '../../BottomContextMenu/ListItem';

const ListItem = forwardRef(({ className, classes, ...props }, ref) => (
  <BaseListItem
    ref={ref}
    className={clsx(className, classes.listItem)}
    {...props}
  />
));

export default withStyles(styles)(ListItem);
