import React from 'react';

import NotificationItem from '../NotificationItem';
import List from '../../../../../components/atomic/List';
import { areIdsEqual } from '../../../../../utils/strings';
import EmptyMessage from '../../../../../components/EmptyMessage';
import NotificationItemLoading from '../NotificationItemLoading';
import { getNotificationIdHash } from '../../../../../utils/notifications';

const NotificationList = ({
  fetching,
  notifications,
  selectedNotificationHash,
}) => (
  <>
    {fetching ? (
      <>
        <NotificationItemLoading />
        <NotificationItemLoading />
        <NotificationItemLoading />
      </>
    ) : (
      <List disablePadding>
        {Array.isArray(notifications) &&
          notifications.length > 0 &&
          notifications.map((notification) => (
            <NotificationItem
              notification={notification}
              selected={areIdsEqual(
                selectedNotificationHash,
                getNotificationIdHash(notification)
              )}
              key={`notification-item-${getNotificationIdHash(notification)}`}
            />
          ))}

        {Array.isArray(notifications) && notifications.length === 0 && (
          <EmptyMessage>Du har ingen varsler</EmptyMessage>
        )}
      </List>
    )}
  </>
);

export default NotificationList;
