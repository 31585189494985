import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { withStyles, Grid, CardMedia, CardContent } from '@material-ui/core';

import styles from './styles';
import Card from '../../../../../../components/atomic/Card';
import Headline from '../../../../../../components/Headline';
import useDesktop from '../../../../../../hooks/useDesktop';

import { fetchEventTemplates as apiFetchTemplates } from '../../../../../../api/events';
import ContentLoader from '../../../../../../components/loaders/ContentLoader';
import Button from '../../../../../../components/atomic/Button';
import DialogOverlay from '../../../../../../components/DialogOverlay';
import ChooseEvent from './ChooseEvent';
import DialogContent from '../../../../../../components/atomic/DialogContent';
import {
  acSetWetroomTemplates,
  acSetElectricalTemplates,
  acSetOutdoorTemplates,
  acSetOtherTemplates,
  acSetFetchingError,
} from '../../../../../../store/actions/bybfEventTemplates';

import {
  sGetWetroomTemplates,
  sGetElectricalTemplates,
  sGetOutdoorTemplates,
  sGetOtherTemplates,
} from '../../../../../../store/reducers/bybfEventTemplates';

const Templates = ({
  classes,
  nextStep,
  setEmptyEvent,
  setEventFromTemplate,
  events,
  boligmappaNumber,
  activeCategory,
  onExistingEventChosen,
  wetroomTemplates,
  electricalTemplates,
  outdoorTemplates,
  otherTemplates,
  setWetroomTemplates,
  setElectricalTemplates,
  setOutdoorTemplates,
  setOtherTemplates,
  setFetchingError,
}) => {
  const isDesktop = useDesktop();

  const [templates, setTemplates] = useState();
  const [inProgress, setInProgress] = useState(false);
  const [chooseEventOverlay, setChooseEventOverlay] = useState(false);

  const fetchTemplateData = async () => {
    setInProgress(true);
    const [error, data] = await apiFetchTemplates({
      category: activeCategory.category,
    });
    if (data) {
      setTemplates(data);

      if (activeCategory.category === 'WETROOMS') {
        setWetroomTemplates(data);
      } else if (activeCategory.category === 'ELECTRICAL') {
        setElectricalTemplates(data);
      } else if (activeCategory.category === 'OUTDOORS') {
        setOutdoorTemplates(data);
      } else {
        setOtherTemplates(data);
      }
    } else {
      setFetchingError(error);
    }
    setInProgress(false);
  };

  useEffect(() => {
    if (activeCategory.category) {
      switch (activeCategory.category) {
        case 'WETROOMS':
          if (wetroomTemplates != null) {
            setTemplates(wetroomTemplates);
          } else {
            fetchTemplateData();
          }
          break;
        case 'ELECTRICAL':
          if (electricalTemplates != null) {
            setTemplates(electricalTemplates);
          } else {
            fetchTemplateData();
          }
          break;
        case 'OUTDOORS':
          if (outdoorTemplates != null) {
            setTemplates(outdoorTemplates);
          } else {
            fetchTemplateData();
          }
          break;
        case 'OTHER':
          if (otherTemplates != null) {
            setTemplates(otherTemplates);
          } else {
            fetchTemplateData();
          }
          break;
        default:
          break;
      }
    }
  }, []);

  const onCloseChooseEvent = () => setChooseEventOverlay(false);

  return (
    <>
      <DialogContent
        className={clsx(
          classes.container,
          isDesktop && classes.containerDesktop
        )}
        px={isDesktop ? 3 : 1}
      >
        <Headline mt={3} mb={3} h="h2" align="center">
          Hva har blitt gjort?
        </Headline>

        {inProgress && (
          <Grid container>
            {[...Array(6)].map((item) => (
              <Grid item key={item} xs={4} className={classes.gridItem}>
                <Card
                  className={clsx(
                    classes.card,
                    classes.cardLoading,
                    isDesktop ? classes.cardDesktop : classes.cardMobile
                  )}
                >
                  <ContentLoader width={140} height={85} />
                  <ContentLoader width={80} height={25} />
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        {templates && (
          <Grid container>
            {templates.map((template) => (
              <Grid item key={template.id} xs={4} className={classes.gridItem}>
                <Card
                  className={clsx(
                    classes.card,
                    isDesktop ? classes.cardDesktop : classes.cardMobile
                  )}
                  onClick={() => {
                    setEventFromTemplate(template);
                    nextStep();
                  }}
                >
                  <CardMedia
                    image={template.image}
                    className={classes.cardImage}
                  />
                  <CardContent
                    className={clsx(
                      classes.cardText,
                      isDesktop
                        ? classes.cardTextDesktop
                        : classes.cardTextMobile
                    )}
                  >
                    {template.previewTitle}
                  </CardContent>
                </Card>
              </Grid>
            ))}
            <Grid item xs={4} className={classes.gridItem}>
              <Card
                className={clsx(
                  classes.card,
                  isDesktop ? classes.cardDesktop : classes.cardMobile
                )}
                onClick={() => {
                  setEmptyEvent();
                  nextStep();
                }}
              >
                <CardMedia className={classes.emptyCardImage} />
                <CardContent
                  className={clsx(
                    classes.cardText,
                    isDesktop ? classes.cardTextDesktop : classes.cardTextMobile
                  )}
                >
                  Opprett egen hendelse
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
        {events && events.length > 0 && (
          <Button
            className={classes.button}
            color="primary"
            variant="outlined"
            onClick={() => setChooseEventOverlay(true)}
          >
            Velg blant eksisterende hendelser
          </Button>
        )}
      </DialogContent>

      {chooseEventOverlay && (
        <DialogOverlay headerTxt="Velg hendelser" onClose={onCloseChooseEvent}>
          <ChooseEvent
            events={events}
            activeCategory={activeCategory}
            boligmappaNumber={boligmappaNumber}
            onClose={onCloseChooseEvent}
            onExistingEventChosen={onExistingEventChosen}
          />
        </DialogOverlay>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  wetroomTemplates: sGetWetroomTemplates(state),
  electricalTemplates: sGetElectricalTemplates(state),
  outdoorTemplates: sGetOutdoorTemplates(state),
  otherTemplates: sGetOtherTemplates(state),
});

const mapDispatchToProps = (dispatch) => ({
  setWetroomTemplates: (wetroomTemplates) =>
    dispatch(acSetWetroomTemplates(wetroomTemplates)),
  setElectricalTemplates: (electricalTemplates) =>
    dispatch(acSetElectricalTemplates(electricalTemplates)),
  setOutdoorTemplates: (outdoorTemplates) =>
    dispatch(acSetOutdoorTemplates(outdoorTemplates)),
  setOtherTemplates: (otherTemplates) =>
    dispatch(acSetOtherTemplates(otherTemplates)),
  setFetchingError: (fetchingError) =>
    dispatch(acSetFetchingError(fetchingError)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Templates));
