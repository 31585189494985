const bordered = (theme) => ({
  border: 'none',
  padding: '8px',
  borderRadius: '1.5rem',
  backgroundColor: theme.palette.primary.whiteDark,
});

const styles = (theme) => ({
  inputField: ({ bordered: isBordered }) => ({
    marginTop: 0,
    ...(isBordered ? bordered(theme) : {}),
    [theme.breakpoints.up('md')]: bordered(theme),
    height: '50px',
  }),
  searchIcon: {
    color: theme.palette.primary.blackLight,
  },
});

export default styles;
