import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core';

import styles, { logoSize } from './style';
import Box from '../atomic/Box';
import useHeaderSnackbar from '../../hooks/useHeaderSnackbar';
import ContentLoader from '../loaders/ContentLoader';
import Logo from '../../assets/icons/BoligmappaLogo';
import ArrowDownFilter from '../../assets/icons/ArrowDownFilter';
import { truncate } from '../../utils/strings';
import { getPropertyAddress } from '../../utils/properties';
import Account from '../desktop/Header/Account';
import BackArrow from '../../assets/icons/DocumentBulkEditIcons/BackArrow';
import { isIOSNativeEnvironment } from '../../utils/nativeBridge';
import MenuHorizontal from '../../assets/icons/MenuHorizontal';

const Header = ({
  classes,
  isDesktop,
  headerTitle,
  propertySelectLayout,
  homeLayout,
  topLevelLayout,
  setHeaderHeight,
  headerChild,
  headerSearch,
  isSearching,
  onMenuIconClick,
  fetching,
  error,
  activeProperty,
  publicSection,
}) => {
  const history = useHistory();
  const mobHeaderRef = useRef(null);
  const onBackButtonAction = history.goBack;
  const { snackbarHeight } = useHeaderSnackbar();
  const isIOSNative = isIOSNativeEnvironment();

  const renderPropertySelectContent = () => (
    <>
      <Logo className={classes.logo} />
      {!publicSection && (
        <ArrowDownFilter width={13} height={6} fill="#717171" />
      )}
      <Box paddingRight={`${logoSize}px`} width="100%">
        <h3 className={classes.pageTitle}>
          {error
            ? 'Boligmappa'
            : truncate(getPropertyAddress(activeProperty), {
                length: 20,
              })}
        </h3>
      </Box>
    </>
  );

  const layoutContent = () => {
    if (propertySelectLayout) return null;
    if (homeLayout)
      return (
        <div className={classes.headerLayout}>
          {!publicSection && (
            <Link to="/properties" className={classes.propertiesLink}>
              {renderPropertySelectContent()}
            </Link>
          )}
          {publicSection && renderPropertySelectContent()}
        </div>
      );
    if (topLevelLayout)
      return (
        <div className={classes.headerLayout}>
          <p className={classes.pageTitle}>{headerTitle}</p>
        </div>
      );
    return (
      <div className={classes.headerLayout}>
        <button
          type="button"
          className={classes.backButton}
          onClick={onBackButtonAction}
          aria-label="Back"
        >
          <BackArrow width={18} height={18} />
        </button>
        <p className={classes.pageTitle}>{headerTitle}</p>
      </div>
    );
  };

  useEffect(() => {
    if (setHeaderHeight) {
      setHeaderHeight(mobHeaderRef.current.offsetHeight + snackbarHeight);
    }
  }, [mobHeaderRef.current?.offsetHeight, snackbarHeight]);

  return (
    <div
      ref={mobHeaderRef}
      className={clsx([
        classes.header,
        propertySelectLayout && classes.propertyAlign,
        isDesktop && !propertySelectLayout && 'hide',
      ])}
      style={{ top: snackbarHeight }}
    >
      {isIOSNative && snackbarHeight === 0 && (
        <div className={classes.iosNativeNotch} />
      )}
      <div className={classes.mainHeaderContent}>
        {!isDesktop &&
          !isSearching &&
          (fetching ? (
            <div className={classes.content}>
              <ContentLoader variant="circle" className={classes.logoLoader} />
              <Box width="100%" paddingRight={`${logoSize}px`}>
                <ContentLoader width={150} height={20} />
              </Box>
            </div>
          ) : (
            layoutContent()
          ))}
        {headerSearch && headerSearch}
        {onMenuIconClick && (
          <button
            className={classes.editItemButton}
            type="button"
            onClick={onMenuIconClick}
            aria-label="Menu Horizontal"
          >
            <MenuHorizontal />
          </button>
        )}
        {!publicSection && (
          <div className={isSearching ? classes.hideAccount : ''}>
            <Account
              isFromPersonal={propertySelectLayout}
              isNameHidden
              activeProperty={activeProperty}
            />
          </div>
        )}
      </div>
      {headerChild && headerChild}
    </div>
  );
};

export default withStyles(styles)(Header);
