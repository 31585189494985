import { backendBaseUrl } from '../config';
import { request } from '../utils/http';

export const getApiHealthStatus = () =>
  request({
    method: 'get',
    url: 'healthcheck',
    baseURL: backendBaseUrl,
  })
    .then((response) => [null, response.data])
    .catch((error) => [error, null]);
