export const ACTIVE_PROPERTY_UID = 'ACTIVE_PROPERTY_UID';
export const SNACK_BAR_CLOSED_SESSION_ID = 'SNACK_BAR_CLOSED_SESSION_ID';

export const BEDRIFTNOTIFICATION = 'BEDRIFTNOTIFICATION';
export const MARKETINGEMAIL = 'MARKETINGEMAIL';
export const PROPERTYVALUEESTIMATEUPDATES = 'PROPERTYVALUEESTIMATEUPDATES';
export const GENERIC_CLIENT_ERROR_CODE = 'CLIENT_ERROR';

export const PUBLICSECTIONFORBIDDENERROR =
  'Beklager, men denne boligmappen er ikke lenger offentlig delt.';

export const PREVIEWENABLEDFOR = 'previewBarEnabledFor';

export const LOCAL_PROPERTIES_CONFIGS = 'LOCAL_PROPERTIES_CONFIGS';

export const LOCAL_API_HEALTH_STATUS = 'LOCAL_API_HEALTH_STATUS';

export const GOOGLE_STREET_VIEW_IMAGE_ENABLED =
  'GOOGLE_STREET_VIEW_IMAGE_ENABLED';

export const EVENT_SUGGESTION_ENABLED = 'EVENT_SUGGESTION_ENABLED';

export const TERMS_OF_USE_LINK = 'https://www.boligmappa.no/vilkar';

export const TERMS_OF_PURCHASE = 'https://www.boligmappa.no/vilkar-for-kjop';

export const PRIVACY_POLICY_LINK =
  'https://www.boligmappa.no/personvernerklaering-privat';

export const TOKEN_UPDATE_ERROR = 'TOKEN_UPDATE_ERROR';

export const INTERNET_CONNECTION_ERROR = 'INTERNET_CONNECTION_ERROR';

export const PRIVACY_POLICY_LINK_PUBLIC_SHARING =
  'https://www.boligmappa.no/vilkar-for-offentlig-deling';

export const PROPERTY_MISSING =
  'https://support.boligmappa.no/hc/no/articles/10740020701586-Mangler-eiendom';

export const NO_OWNED_PROPERTIES =
  'https://support.boligmappa.no/hc/no/sections/10722304119314-Matrikkel-og-eiendomsinformasjon';
