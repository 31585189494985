import clsx from 'clsx';
import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import TextField from '../atomic/TextField';
import SearchIcon from '../../assets/icons/Search';
import InputAdornment from '../atomic/InputAdornment';

const SearchField = ({
  classes,
  bordered,
  className,
  placeholder,
  value,
  ...props
}) => (
  <TextField
    placeholder={placeholder}
    className={clsx(classes.textField, className)}
    InputProps={{
      className: classes.inputField,
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon className={classes.searchIcon} />
        </InputAdornment>
      ),
    }}
    {...props}
  />
);

export default withStyles(styles)(SearchField);
