export const defaultState = {
  userTransactions: { fetching: true, loaded: false },
};

export const SET_USER_TRANSACTIONS = 'SET_USER_TRANSACTIONS';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER_TRANSACTIONS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const sGetUserTransactions = (state) => state.userTransactions;
