import moment from 'moment';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import React, { createElement, useEffect, useState } from 'react';
import { isEqual, sortBy } from 'lodash';

import {
  getDocumentId,
  getDocumentName,
  getDocumentRooms,
  getDocumentEvents,
  isDocumentReadOnly,
  getDocumentCompanyId,
  getOriginalDocumentPath,
  getCurrentDocumentType,
  getDocumentIsBuilding,
  ATTACH_ROOM_EDIT_OPTION,
  ATTACH_EVENT_EDIT_OPTION,
  isDocumentsFolder,
} from '../../../../utils/documents';

import {
  fetchDocument as apiFetchDocument,
  deleteDocument as apiDeleteDocument,
  updateDocument as apiUpdateDocument,
} from '../../../../api/documents';
import {
  deletePersonalDocument as apiDeletePersonalDocument,
  copySinglePersonalDocumentToProperty as apiCopySinglePersonalDocumentToProperty,
  moveSingleDocumentToProperty as apiMoveSingleDocumentToProperty,
  updatePersonalDocument as apiUpdatePersonalDocument,
  deletePersonalDocumentFolder as apiDeletePersonalDocumentFolder,
} from '../../../../api/propertyowners';
import styles from './style';
import List from '../../../atomic/List';
import Menu from '../../../atomic/Menu';
import { getRoomId } from '../../../../utils/rooms';
import EditIcon from '../../../../assets/icons/DocumentBulkEditIcons/PencilIcon';
import useDesktop from '../../../../hooks/useDesktop';
import { getEventId } from '../../../../utils/events';
import useSnackbar from '../../../../hooks/useSnackbar';
import ViewIcon from '../../../../assets/icons/DocumentBulkEditIcons/ViewIcon';
import RoomIcon from '../../../../assets/icons/DocumentBulkEditIcons/RoomIcon';
import EventIcon from '../../../../assets/icons/DocumentBulkEditIcons/EventIcon';
import DeleteIcon from '../../../../assets/icons/DocumentBulkEditIcons/DocumentsDeleteIcon';
import DownloadIcon from '../../../../assets/icons/DocumentBulkEditIcons/DownloadIcon';
import DocumentTypeIcon from '../../../../assets/icons/DocumentBulkEditIcons/EditDocumentIcon';
import BottomContextMenu from '../../../BottomContextMenu';
import DesktopListItem from '../../../desktop/MenuListItem';
import { sGetRooms } from '../../../../store/reducers/rooms';
import { sGetEvents } from '../../../../store/reducers/events';
import useActionDialog from '../../../../hooks/useActionDialog';
import MobileListItem from '../../../BottomContextMenu/ListItem';
import { sGetDocuments } from '../../../../store/reducers/documents';
import {
  acUpdateDocument,
  acAddDocument,
} from '../../../../store/actions/documents';
import { acSyncRoomsDocuments } from '../../../../store/actions/rooms';
import { acSyncEventsDocuments } from '../../../../store/actions/events';
import useDashboardLinkPrefix from '../../../../hooks/useDashboardLinkPrefix';
import MultiSelectComboBox from '../../../MultiSelectComboBox';
import useMenuState from '../../../../hooks/useMenuState';
import matomo from '../../../../utils/matomo';
import documentTypes from '../../../forms/DocumentForm/documentTypes';
import { prepareDocumentData } from '../../../forms/DocumentForm/util';
import DocumentTypeOptions from '../../../DocumentEditOptions/DocumentTypeOptions';
import { areIdsEqual } from '../../../../utils/strings';
import MoveDocumentIcon from '../../../../assets/icons/MoveDocumentIcon';
import CopyDocumentIcon from '../../../../assets/icons/CopyDocumentIcon';
import {
  sGetAllProperties,
  sGetActiveProperty,
} from '../../../../store/reducers/properties';
import {
  acAddPersonalDocument,
  acUpdatePersonalDocument,
} from '../../../../store/actions/personalDocuments';

import { copySinglePropertyDocumentToPrivate as apiCopySinglePropertyDocumentToPrivate } from '../../../../api/properties';
import {
  sGetPersonalDocumentFolders,
  sGetPersonalDocumentFoldersToRender,
  sGetSelectedFolderId,
} from '../../../../store/reducers/personalDocuments';
import MoveToFolderIcon from '../../../../assets/icons/MoveToFolder';
import { findDirectoriesInTheSameLevel } from '../../../../utils/directories';

const DocumentActionMenu = ({
  openParentPop,
  classes,
  rooms,
  events,
  setOpenParentPop,
  document,
  setIsMobileContext,
  onDocumentUpdated,
  parentAnchorEl,
  documents,
  updateDocument,
  onDocumentDeleted,
  syncRoomsDocuments,
  syncEventsDocuments,
  documentId: passedDocumentId,
  isPersonalDocuments = false,
  properties,
  addDocument,
  activeProperty,
  isFromPersonal,
  addPersonalDocument,
  isBoligmappaPlusUser,
  personalDocumentFoldersToRender,
  personalDocumentFolders,
  updatePersonalDocument,
  onFolderDelete,
  onFolderChange,
  selectedFolderId,
}) => {
  const history = useHistory();
  const isDesktop = useDesktop();
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const { showSuccessMessage, showErrorMessage } = useSnackbar();
  const { showActionDialog, showLoadingDialog } = useActionDialog();
  const { onOptionMenuClose, open, anchorEl, onMenuOpen } = useMenuState();
  const [subMenuOptions, setSubMenuOptions] = useState([]);
  const [subMenuTitle, setSubMenuTitle] = useState('');
  const [openDocumentType, setOpenDocumentType] = useState(false);
  const [onSelectDocumentOption, setOnSelectDocumentOption] = useState([]);
  const [roomIds, setRoomIds] = useState([]);
  const [eventIds, setEventIds] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [attachType, setAttachType] = useState(null);
  const [documentType, setDocumentType] = useState([]);
  const [type, setType] = useState('');
  const [openPersonalDocSubMenu, setOpenPersonalDocSubMenu] = useState(false);
  const [propertyOption, setPropertyOption] = useState(null);

  const documentCompanyId = getDocumentCompanyId(document);

  const documentId = passedDocumentId || getDocumentId(document);

  const COPY_DOCUMENT = 'copyDocument';
  const MOVE_DOCUMENT = 'moveDocument';
  const MOVE_DOCUMENT_TO_FOLDER = 'moveDocumentToFolder';

  const currentDocument =
    (documents || []).find((doc) =>
      areIdsEqual(getDocumentId(doc), documentId)
    ) || document;

  const fetchDocument = async () => {
    if (!isDocumentsFolder(document)) {
      const [reqError, data] = await apiFetchDocument(documentId);
      if (onDocumentUpdated) {
        if (!isPersonalDocuments) {
          onDocumentUpdated({
            ...document,
            ...data,
            eventIds: getDocumentEvents(data).map((event) => getEventId(event)),
            roomIds: getDocumentRooms(data).map((room) => getRoomId(room)),
          });
        } else {
          onDocumentUpdated({
            ...document,
            ...data,
          });
        }
      }

      if (reqError && !data) return null;

      if (documents) {
        updateDocument(data);
      }

      if (rooms && Array.isArray(getDocumentRooms(data))) {
        syncRoomsDocuments({
          documentId,
          document: data,
          roomIds: getDocumentRooms(data).map((room) => getRoomId(room)),
        });
      }

      if (events && Array.isArray(getDocumentEvents(data))) {
        syncEventsDocuments({
          documentId,
          document: data,
          eventIds: getDocumentEvents(data).map((event) => getEventId(event)),
        });
      }

      return null;
    }
    return null;
  };

  const getTrackingName = () => {
    let name = '';
    if (attachType && attachType === 'attachEvents') {
      name = 'Edit Event ';
    } else if (attachType && attachType === 'attachRooms') {
      name = 'Edit Room';
    } else if (openDocumentType) {
      name = 'EditDocumentType';
    }
    return name;
  };

  const trackMatomoEvent = (action) => {
    matomo.clickEvent({
      category: 'Document handling',
      name: getTrackingName(),
      action: `${action}`,
    });
  };

  const trackPersonalMatomoEvents = (action) => {
    matomo.clickEvent({
      category: 'Document handling',
      action,
    });
  };

  useEffect(() => {
    setRoomIds(
      (getDocumentRooms(currentDocument) || []).map((room) => ({
        label: room.title,
        value: getRoomId(room),
      }))
    );
    setEventIds(
      (getDocumentEvents(currentDocument) || []).map((event) => ({
        label: event.title,
        value: getEventId(event),
      }))
    );
    setDocumentType([getCurrentDocumentType(document)]);
  }, [document, events, rooms]);

  const onDownloadClick = () => {
    trackMatomoEvent('Download Document');
  };

  const onDeleteClick = async () =>
    showActionDialog({
      submitButtonText: 'Slett',
      message: 'Vil du slette dette dokumentet?',
      onSubmit: async () => {
        const closeDialog = showLoadingDialog('Sletter...');

        let reqError;
        let response;

        if (isPersonalDocuments) {
          [reqError, response] = await apiDeletePersonalDocument(documentId);
        } else {
          [reqError, response] = await apiDeleteDocument(documentId);
        }

        closeDialog();

        if (reqError && !response) {
          showErrorMessage(reqError);
        } else {
          showSuccessMessage('Dokument slettet.');
          onDocumentDeleted(document);
        }

        if (isPersonalDocuments) {
          trackMatomoEvent('Delete a Personal Document from Document List');
        } else {
          trackMatomoEvent('Delete Document from Document List');
        }
      },
    });

  const onDeleteFolder = async () => {
    showActionDialog({
      title: 'Viktig informasjon!',
      submitButtonText: 'Slett mappe',
      cancelButtonText: 'Ikke nå',
      buttonClassName: classes.deleteFolderActionButton,
      centeredRowActions: true,
      actionClassName: classes.deleteFolderActionButtonCluster,
      customTitleClassName: classes.dialogTitle,
      centeredContent: true,
      showCloseButton: false,
      titleFontSize: '20px',
      message:
        'Advarsel: Når du sletter en mappe, vil alt innhold i mappen, inkludert alle dokumenter og undermapper, bli permanent slettet. Denne handlingen kan ikke angres. Vennligst forsikre deg om at det ikke finnes noe innhold i mappen du ønsker å beholde før du fortsetter.',
      onSubmit: async () => {
        const closeDialog = showLoadingDialog('Sletter...');
        const [reqError, response] = await apiDeletePersonalDocumentFolder(
          isDocumentsFolder(document) && document?.id
        );

        closeDialog();
        if (reqError && !response) {
          showErrorMessage(reqError);
        } else {
          showSuccessMessage('Mappe slettet.');
          onFolderDelete(response?.map((dir) => dir));
        }
      },
    });
  };

  const openFolder = () => onFolderChange(document?.id);

  const onDocumentUpdate = async (data) => {
    const [requestError, response] = await apiUpdateDocument(documentId, data);

    if (!response && requestError) {
      showErrorMessage(requestError);
    } else {
      fetchDocument();
      showSuccessMessage();
    }
  };

  const onMoveDocumentToProperty = async (data, id) => {
    const [error, respose] = await apiMoveSingleDocumentToProperty(data, id);
    if (!respose && error) {
      showErrorMessage(error);
    } else {
      if (data?.boligmappaNumber === activeProperty?.boligmappaNumber) {
        addDocument(prepareDocumentData(document));
      }
      onDocumentDeleted(document);
      showSuccessMessage('Dokument flyttet.');
      trackPersonalMatomoEvents(
        'Move Single Personal Document to Property Documents'
      );
    }
  };

  const onMoveDocumentToFolder = async (data, id) => {
    const [error, respose] = await apiUpdatePersonalDocument(data, id);
    if (!respose && error) {
      showErrorMessage(error);
    } else {
      const { directoryId } = data;
      updatePersonalDocument({ ...document, directoryId });
      showSuccessMessage('Dokumentet flyttet til mappe.');
      trackPersonalMatomoEvents('Move Single Personal Document to a Directory');
    }
  };

  const onCopyDocumentToProperty = async (data, id) => {
    const [error, respose] = await apiCopySinglePersonalDocumentToProperty(
      data,
      id
    );
    if (!respose && error) {
      showErrorMessage(error);
    } else {
      const doc = respose?.bulkCopylDocumentResponse[0];
      if (data?.boligmappaNumber === activeProperty?.boligmappaNumber) {
        const copyid = doc?.id;
        const url = {
          rel: document?.url?.rel,
          link: doc.downloadUrl,
          fileType: document?.url?.fileType,
        };
        const d = { ...document, id: copyid, url };
        addDocument(prepareDocumentData(d));
      }
      showSuccessMessage('Dokumentet er kopiert.');
      trackPersonalMatomoEvents(
        'Copy Single Personal Document to Property Documents'
      );
    }
  };

  const roomOptions = (rooms || []).map((room) => ({
    name: room.title,
    label: room.title,
    id: getRoomId(room),
    checked: selectedOptions.includes((so) => so.value === getRoomId(room)),
  }));

  const eventOptions = (events || []).map((event) => ({
    name: event.title,
    label: event.title,
    id: getEventId(event),
    checked: selectedOptions.includes((so) => so.value === getEventId(event)),
  }));

  const documentTypeOptions = (documentTypes || []).map((opt) => ({
    name: opt.name,
    label: opt.name,
    value: opt.id,
  }));

  const propertyOptions = (properties || []).map((prop) => ({
    name: prop.name,
    label: prop.boligmappaNumber,
    value: prop.id,
  }));

  const getMovableFoldersToRender = () => {
    return [
      ...findDirectoriesInTheSameLevel(
        personalDocumentFolders,
        selectedFolderId
      ),
      ...personalDocumentFoldersToRender,
    ];
  };

  const foldersOptions = (getMovableFoldersToRender() || []).map((prop) => ({
    name: prop.name,
    label: prop.id,
    value: prop.id,
  }));

  useEffect(() => {
    if (type === ATTACH_EVENT_EDIT_OPTION) {
      const newEvent = events?.sort((a, b) => b.id - a.id)[0];
      const existingEvent = eventOptions.find(
        (item) => item.id === newEvent?.id
      );
      if (existingEvent) {
        setSubMenuOptions(eventOptions);
        const index = eventOptions.findIndex(
          (item) => item.id === newEvent?.id
        );
        setSelectedOptions([
          ...selectedOptions,
          { label: newEvent.title, value: getEventId(newEvent) },
        ]);
        eventOptions[index].checked = true;
        eventOptions?.sort((a, b) => b.id - a.id);
      }
    }
  }, [events, document]);

  useEffect(() => {
    if (type === ATTACH_ROOM_EDIT_OPTION) {
      const newRoom = rooms?.sort((a, b) => b.id - a.id)[0];
      const existingRoom = roomOptions.find((item) => item.id === newRoom?.id);
      if (existingRoom) {
        setSubMenuOptions(roomOptions);
        const index = roomOptions.findIndex((item) => item.id === newRoom?.id);
        setSelectedOptions([
          ...selectedOptions,
          { label: newRoom.title, value: getRoomId(newRoom) },
        ]);
        roomOptions[index].checked = true;
        roomOptions?.sort((a, b) => b.id - a.id);
      }
    }
  }, [rooms, document]);

  const onAttachRoomClick = (e, text) => {
    setType(ATTACH_ROOM_EDIT_OPTION);
    setOpenDocumentType(false);
    setSubMenuOptions(roomOptions);
    setAttachType('attachRooms');
    trackMatomoEvent('Connect Document to Room');
    setSelectedOptions(roomIds);
    setSubMenuTitle(text);
    onMenuOpen(e);
  };

  const onAttachEventClick = (e, text) => {
    setType(ATTACH_EVENT_EDIT_OPTION);
    setOpenDocumentType(false);
    setSubMenuOptions(eventOptions);
    setAttachType('attachEvents');
    trackMatomoEvent('Connect Document to Event');
    setSelectedOptions(eventIds);
    setSubMenuTitle(text);
    onMenuOpen(e);
  };

  const onAttachDocumentTypeClick = (e, text) => {
    setSubMenuOptions(documentTypeOptions);
    setSubMenuTitle(text);
    setOnSelectDocumentOption(documentType);
    trackMatomoEvent('Connect DocumentType');
    setOpenDocumentType(true);
    onMenuOpen(e);
  };

  const onDocumentTypeClose = () => {
    setOpenDocumentType(false);
    setOnSelectDocumentOption([]);
    onOptionMenuClose();
  };

  const onMovePrivateDocumentClick = (e) => {
    if (propertyOptions.length >= 1) {
      setAttachType(MOVE_DOCUMENT);
      setSubMenuOptions(propertyOptions);
      setOpenPersonalDocSubMenu(true);
      onMenuOpen(e);
    }
  };

  const onMovePersonalDocumentToFolderClick = (e) => {
    if (propertyOptions.length >= 1) {
      setAttachType(MOVE_DOCUMENT_TO_FOLDER);
      setSubMenuOptions(foldersOptions);
      setOpenPersonalDocSubMenu(true);
      onMenuOpen(e);
    }
  };

  const onCopyPrivateDocumentClick = (e) => {
    if (propertyOptions.length >= 1) {
      setAttachType(COPY_DOCUMENT);
      setSubMenuOptions(propertyOptions);
      setOpenPersonalDocSubMenu(true);
      onMenuOpen(e);
    }
  };

  const copyPropertyDocumentToPrivate = async () => {
    const [error, respose] = await apiCopySinglePropertyDocumentToPrivate(
      activeProperty?.boligmappaNumber,
      documentId
    );
    if (!respose && error) {
      showErrorMessage(error);
    } else {
      const doc = respose?.[0];
      const copyid = doc?.id;
      const url = {
        rel: document?.url?.rel,
        link: doc.downloadUrl,
        fileType: document?.url?.fileType,
      };
      const d = { ...document, id: copyid, url };
      addPersonalDocument(d);
      showSuccessMessage('Dokumentet er kopiert.');
      trackPersonalMatomoEvents(
        'Copy Single Property Document to Personal Documents'
      );
    }
  };

  const onPersonalDocSubMenuClose = () => {
    setOpenPersonalDocSubMenu(false);
    setAttachType(null);
    setPropertyOption([]);
    onOptionMenuClose();
  };

  const isUpdate = () => {
    if (
      attachType === 'attachRooms' &&
      !isEqual(sortBy(roomIds, 'value'), sortBy(selectedOptions, 'value'))
    ) {
      return true;
    }
    if (
      attachType === 'attachEvents' &&
      !isEqual(sortBy(eventIds, 'value'), sortBy(selectedOptions, 'value'))
    ) {
      return true;
    }
    if (
      openDocumentType &&
      documentType[0]?.value !== onSelectDocumentOption[0]?.value
    ) {
      return true;
    }
    return false;
  };

  const onMenuSave = () => {
    if (isUpdate()) {
      onDocumentUpdate(
        prepareDocumentData({
          rooms,
          events,
          title: getDocumentName(document),
          roomIds: attachType === 'attachRooms' ? selectedOptions : roomIds,
          eventIds: attachType === 'attachEvents' ? selectedOptions : eventIds,
          documentType:
            openDocumentType && onSelectDocumentOption.length > 0
              ? onSelectDocumentOption[0]
              : documentType[0],
          updatedDate: moment().format(),
        })
      );
      trackMatomoEvent('Edit Document from Document List');
    }

    if (
      openPersonalDocSubMenu &&
      attachType === MOVE_DOCUMENT &&
      propertyOption?.length > 0
    ) {
      const data = {
        boligmappaNumber: propertyOption[0]?.label,
      };
      onMoveDocumentToProperty(data, documentId);
    }

    if (
      openPersonalDocSubMenu &&
      attachType === COPY_DOCUMENT &&
      propertyOption?.length > 0
    ) {
      const data = {
        boligmappaNumber: propertyOption[0]?.label,
      };

      onCopyDocumentToProperty(data, documentId);
    }

    if (
      openPersonalDocSubMenu &&
      attachType === MOVE_DOCUMENT_TO_FOLDER &&
      propertyOption?.length > 0
    ) {
      const data = {
        directoryId: propertyOption[0]?.label,
      };
      onMoveDocumentToFolder(data, documentId);
    }
    if (openDocumentType) onDocumentTypeClose();

    setSelectedOptions([]);
    setAttachType(null);
    setOnSelectDocumentOption([]);
    onOptionMenuClose();
    setOpenParentPop(false);
  };

  const onOptionsMenuClose = () => {
    setSelectedOptions([]);
    setAttachType(null);
    onOptionMenuClose();
  };

  const onAmbitaDocumentClick = () =>
    isDesktop
      ? null
      : showActionDialog({
          message:
            'Dette dokumentet har en rettighetsbegrensning som gjør at det ikke kan lastes ned lokalt til din enhet.',
        });

  const personalDocMenuItem = [
    {
      icon: ViewIcon,
      onClick: () => {
        if (setIsMobileContext) {
          setIsMobileContext(true);
        } else {
          history.push({
            pathname: isFromPersonal
              ? `/personal-documents/${documentId}`
              : `${dashboardLinkPrefix}/documents/${documentId}`,
            state: { isPersonalDocuments, isFromPersonal },
          });
        }
      },
      text: 'Se detaljer',
      border: false,
      hidden: setIsMobileContext && isDesktop,
    },
    ...(!isBoligmappaPlusUser
      ? [
          {
            text: 'Enkelte handlinger krever Boligmappa+',
            border: false,
            startSeperation: isDesktop,
            isDelete: true,
          },
        ]
      : []),
    {
      icon: MoveDocumentIcon,
      onClick: (e) => onMovePrivateDocumentClick(e),
      text: 'Flytt til bolig',
      border: false,
      startSeperation: isBoligmappaPlusUser && isDesktop,
      isDropDown: true,
      disabled: properties?.length < 1 || !isBoligmappaPlusUser,
    },
    {
      icon: MoveToFolderIcon,
      onClick: (e) => onMovePersonalDocumentToFolderClick(e),
      text: 'Flytt til mappe',
      isDropDown: true,
      border: false,
      disabled: properties?.length < 1 || !isBoligmappaPlusUser,
    },
    {
      icon: CopyDocumentIcon,
      onClick: (e) => onCopyPrivateDocumentClick(e),
      text: 'Kopier til bolig',
      isDropDown: true,
      endSeperation: isDesktop,
      border: false,
      disabled: properties?.length < 1 || !isBoligmappaPlusUser,
    },
    {
      text: 'Last ned',
      icon: DownloadIcon,
      openInNewTab: true,
      isExternalLink: true,
      onClick: onDownloadClick,
      to: getOriginalDocumentPath(document),
      disabled:
        !getOriginalDocumentPath(document) || isDocumentReadOnly(document),
      border: false,
    },
    {
      icon: DeleteIcon,
      disabled:
        !getOriginalDocumentPath(document) ||
        isDocumentReadOnly(document) ||
        getDocumentIsBuilding(document) ||
        !!documentCompanyId,
      onClick: onDeleteClick,
      text: 'Slett',
      startSeperation: true,
      isDelete: true,
    },
  ];

  const menuItems = [
    {
      icon: ViewIcon,
      onClick: () => {
        trackMatomoEvent('View Document from Document List');
        if (setIsMobileContext) {
          setIsMobileContext(true);
        } else {
          history.push({
            pathname: `${dashboardLinkPrefix}/documents/${documentId}`,
            state: { isPersonalDocuments },
          });
        }
      },
      text: 'Se detaljer',
      border: false,
      hidden: setIsMobileContext && isDesktop,
    },
    {
      icon: EditIcon,
      disabled: isDocumentReadOnly(document) || getDocumentIsBuilding(document),
      onClick: () => {
        trackMatomoEvent('Edit Document Details');
        history.push(`${dashboardLinkPrefix}/documents/${documentId}/edit`);
      },
      text: 'Rediger',
      hidden: true,
    },
    {
      text: 'Dokumentet er låst for enkelte handlinger.',
      hidden: !isDocumentReadOnly(document) && !getDocumentIsBuilding(document),
      showError: true,
    },
    {
      icon: RoomIcon,
      text: 'Knytt til rom',
      disabled:
        !getOriginalDocumentPath(document) ||
        isDocumentReadOnly(document) ||
        getDocumentIsBuilding(document),
      onClick: (e) => onAttachRoomClick(e, 'Knytt til rom'),
      isDropDown: true,
      startSeperation: !isDesktop,
      border: false,
    },
    {
      icon: EventIcon,
      text: 'Knytt til hendelse',
      disabled:
        !getOriginalDocumentPath(document) ||
        isDocumentReadOnly(document) ||
        getDocumentIsBuilding(document),
      onClick: (e) => onAttachEventClick(e, 'Knytt til hendelse'),
      isDropDown: true,
      border: false,
    },
    {
      icon: DocumentTypeIcon,
      text: 'Endre dokumenttype',
      disabled:
        !getOriginalDocumentPath(document) ||
        isDocumentReadOnly(document) ||
        getDocumentIsBuilding(document),
      onClick: (e) => onAttachDocumentTypeClick(e, 'Legg til dokumenttype'),
      isDropDown: true,
      endSeperation: true,
      border: false,
    },
    ...(!isBoligmappaPlusUser
      ? [
          {
            text: 'Enkelte handlinger krever Boligmappa+',
            border: false,
            startSeperation: isDesktop,
            isDelete: true,
          },
        ]
      : []),
    {
      icon: CopyDocumentIcon,
      text: 'Kopier til personlig område',
      disabled: !isBoligmappaPlusUser,
      onClick: copyPropertyDocumentToPrivate,
      endSeperation: true,
      border: false,
    },
    {
      text: 'Last ned',
      icon: DownloadIcon,
      openInNewTab: true,
      isExternalLink: true,
      onClick: onDownloadClick,
      to: getOriginalDocumentPath(document),
      disabled:
        !getOriginalDocumentPath(document) || isDocumentReadOnly(document),
      border: false,
    },
    {
      icon: DeleteIcon,
      disabled:
        !getOriginalDocumentPath(document) ||
        isDocumentReadOnly(document) ||
        getDocumentIsBuilding(document) ||
        !!documentCompanyId,
      onClick: onDeleteClick,
      text: 'Slett',
      startSeperation: true,
      isDelete: true,
    },
  ];

  const personalFolderMenuItems = [
    {
      icon: ViewIcon,
      onClick: openFolder,
      text: 'Gå til mappe',
      border: false,
      hidden: setIsMobileContext && isDesktop,
    },
    {
      icon: DeleteIcon,
      onClick: onDeleteFolder,
      text: 'Slett',
      isDelete: true,
      startSeperation: true,
      disabled: document?.isSystemGenerated,
    },
  ];

  const renderMenuItem = ({
    text,
    onClick,
    disabled,
    tooltipLabel,
    isDropDown,
    showError,
    isDelete,
    startSeperation,
    endSeperation,
    ...props
  }) =>
    // eslint-disable-next-line react/no-children-prop
    createElement(isDesktop ? DesktopListItem : MobileListItem, {
      disabled,
      key: text,
      isDropDown,
      showError,
      startSeperation,
      endSeperation,
      textClass: !isDesktop && (isDelete || showError) ? classes.delete : null,
      isDelete,
      children: text,
      border: !isDesktop,
      label: tooltipLabel,
      onClick: (event) => {
        if (disabled) {
          onAmbitaDocumentClick();
          event.preventDefault();
          return;
        }

        if (onClick) {
          onClick(event);
        }

        if (!isDropDown) setOpenParentPop(false);
      },
      ...props,
    });

  const renderMenuItems = () =>
    isPersonalDocuments
      ? personalDocMenuItem.filter(({ hidden }) => !hidden).map(renderMenuItem)
      : menuItems.filter(({ hidden }) => !hidden).map(renderMenuItem);

  const renderPersonalFolderMenuItems = () =>
    personalFolderMenuItems.filter(({ hidden }) => !hidden).map(renderMenuItem);

  useEffect(() => {
    if (openParentPop && !getOriginalDocumentPath(document)) {
      fetchDocument();
    }
  }, [openParentPop]);

  if (isDesktop) {
    return (
      <>
        <Menu
          open={openParentPop}
          anchorEl={parentAnchorEl}
          disableAutoFocusItem
          onClose={() => setOpenParentPop(false)}
        >
          {isDocumentsFolder(document)
            ? renderPersonalFolderMenuItems()
            : renderMenuItems()}
        </Menu>

        {openDocumentType ? (
          <DocumentTypeOptions
            anchorEl={anchorEl}
            menuOpen={openDocumentType && open && !openPersonalDocSubMenu}
            onMenuClose={onDocumentTypeClose}
            onMenuSave={onMenuSave}
            editOptions={subMenuOptions}
            onSelectOption={setOnSelectDocumentOption}
            radioGroupName="Document Type"
            onFiltersReset={() => setOnSelectDocumentOption([])}
            selectedOptions={onSelectDocumentOption}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
        ) : (
          <MultiSelectComboBox
            type={type}
            anchorEl={anchorEl}
            menuOpen={!openDocumentType && open && !openPersonalDocSubMenu}
            onMenuClose={onOptionsMenuClose}
            onFiltersReset={() => setSelectedOptions([])}
            filterOptions={subMenuOptions}
            onMenuSave={onMenuSave}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
        )}

        {isPersonalDocuments && (
          <DocumentTypeOptions
            anchorEl={anchorEl}
            menuOpen={openPersonalDocSubMenu && open}
            onMenuClose={onPersonalDocSubMenuClose}
            onMenuSave={onMenuSave}
            editOptions={subMenuOptions}
            onSelectOption={setPropertyOption}
            radioGroupName="Property options"
            onFiltersReset={() => setPropertyOption([])}
            selectedOptions={onSelectDocumentOption}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            enableSearch={false}
          />
        )}
      </>
    );
  }

  return (
    <>
      <BottomContextMenu
        open={openParentPop}
        setOpen={setOpenParentPop}
        title={getDocumentName(document)}
        ModalProps={{
          style: {
            zIndex: 1202,
          },
        }}
      >
        <List>
          {isDocumentsFolder(document)
            ? renderPersonalFolderMenuItems()
            : renderMenuItems()}
        </List>
      </BottomContextMenu>

      {openDocumentType ? (
        <DocumentTypeOptions
          anchorEl={anchorEl}
          menuOpen={openDocumentType && open && !openPersonalDocSubMenu}
          onMenuClose={onDocumentTypeClose}
          onMenuSave={onMenuSave}
          editOptions={subMenuOptions}
          onSelectOption={setOnSelectDocumentOption}
          radioGroupName="Document Type"
          onFiltersReset={() => setOnSelectDocumentOption([])}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          subTitle={subMenuTitle}
          description={getDocumentName(document)}
          isBulk={false}
          selectedOptions={onSelectDocumentOption}
        />
      ) : (
        <MultiSelectComboBox
          type={type}
          subTitle={subMenuTitle}
          description={getDocumentName(document)}
          anchorEl={anchorEl}
          menuOpen={!openDocumentType && open && !openPersonalDocSubMenu}
          onMenuClose={onOptionsMenuClose}
          onFiltersReset={() => setSelectedOptions([])}
          filterOptions={subMenuOptions}
          onMenuSave={onMenuSave}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          isBulk={false}
        />
      )}

      {isPersonalDocuments && (
        <DocumentTypeOptions
          anchorEl={anchorEl}
          menuOpen={openPersonalDocSubMenu && open}
          onMenuClose={onPersonalDocSubMenuClose}
          onMenuSave={onMenuSave}
          editOptions={subMenuOptions}
          onSelectOption={setPropertyOption}
          radioGroupName="Property options"
          onFiltersReset={() => setPropertyOption([])}
          selectedOptions={onSelectDocumentOption}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          enableSearch={false}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  rooms: sGetRooms(state),
  events: sGetEvents(state),
  documents: sGetDocuments(state),
  properties: sGetAllProperties(state),
  activeProperty: sGetActiveProperty(state),
  personalDocumentFoldersToRender: sGetPersonalDocumentFoldersToRender(state),
  personalDocumentFolders: sGetPersonalDocumentFolders(state),
  selectedFolderId: sGetSelectedFolderId(state),
});

const mapDispatchToProps = (dispatch) => ({
  addDocument: (document) => dispatch(acAddDocument(document)),
  updateDocument: (document) => dispatch(acUpdateDocument(document)),
  syncRoomsDocuments: (payload) => dispatch(acSyncRoomsDocuments(payload)),
  syncEventsDocuments: (payload) => dispatch(acSyncEventsDocuments(payload)),
  addPersonalDocument: (payload) => dispatch(acAddPersonalDocument(payload)),
  updatePersonalDocument: (payload) =>
    dispatch(acUpdatePersonalDocument(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DocumentActionMenu));
