import { makeStyles } from '@material-ui/core';

export const useCompanyListStyles = makeStyles(() => ({
  contentContainer: {
    height: '100%',
    display: 'grid',
    alignItems: 'center',
    gap: '4px',
    gridTemplateRows: '2',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  textWrapper: {
    alignSelf: 'center',
  },
  buttonWrapper: {
    alignSelf: 'flex-end',
  },
  imageContainer: {
    height: '100%',
    maxHeight: '200px',
    gridColumn: '2 / 3',
    gridRow: '1 / 3',
    alignSelf: 'flex-start',
    justifySelf: 'flex-end',
  },
  mobileImageContainer: {
    height: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '200px',
    marginTop: '20px',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  findProfessionalButton: {
    padding: '5px 40px',
  },
  contentContainerMobile: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gridTemplateRows: 'none',
    gridTemplateColumns: 'none',
    gap: '4px',
    textAlign: 'center',
  },
  textWrapperMobile: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  buttonWrapperMobile: {
    width: '100%',
  },
}));
