import React from 'react';

import { StyledPurchaseCard } from '@componentsV2/PurchaseCard/styles';
import { Link } from 'react-router-dom';

const PurchaseCard = ({
  classes,
  dashboardLinkPrefix,
  type = 'userSinglePaymentId',
  date,
  nextBillingDate,
  orderNumber,
  amount,
  cancelled,
  onSubCancel,
}) => {
  const cardTitles = {
    userSinglePaymentId: 'Trygt utført',
    userSubscriptionId: 'Boligmappa+',
  };
  const cardDescriptions = {
    userSinglePaymentId: (
      <p className="description">
        Les mer om Trygt utført på{' '}
        <a
          href="https://support.boligmappa.no/hc/no/sections/15094668733330-Trygt-utf%C3%B8rt"
          target="_blank"
          rel="noreferrer"
        >
          våre hjelpesider.
        </a>
      </p>
    ),
    userSubscriptionId: (
      <p className="description">
        Les mer om Boligmappa+ på{' '}
        <a
          href="https://support.boligmappa.no/hc/no/sections/15094668733330-Trygt-utf%C3%B8rt"
          target="_blank"
          rel="noreferrer"
        >
          våre hjelpesider.
        </a>
      </p>
    ),
  };
  return (
    <StyledPurchaseCard className={classes}>
      <h3>{cardTitles[type]}</h3>
      {cardDescriptions[type]}
      <div className="data_block">
        {type === 'userSubscriptionId' && (
          <div className="row">
            <p className="row_title">Status</p>
            <p>{cancelled ? 'Kansellert' : 'Aktiv'}</p>
          </div>
        )}
        <div className="row">
          <p className="row_title">Dato for kjøp</p>
          <p>{date}</p>
        </div>
        {type === 'userSinglePaymentId' && (
          <>
            <div className="row">
              <p className="row_title">Ordrenummer</p>
              <p>{orderNumber}</p>
            </div>
            <div className="row">
              <p className="row_title">Beløp</p>
              <p>{amount} kr</p>
            </div>
            <p className="insurance_hint">
              Forsikringsdokumenter finner du i{' '}
              <Link to={`${dashboardLinkPrefix}/documents?personal=true`}>
                Personlig område
              </Link>
              . Informasjon om{' '}
              <a
                href="https://support.boligmappa.no/hc/no/articles/15474983466386-Hvordan-kansellere-Trygt-utf%C3%B8rt-"
                target="_blank"
                rel="noreferrer"
              >
                angrerett finner du her
              </a>
              .
            </p>
          </>
        )}
        {type === 'userSubscriptionId' && (
          <div className="row">
            <p className="row_title">
              {cancelled ? 'Utløpsdato' : 'Neste trekk'}
            </p>
            <p>{nextBillingDate}</p>
          </div>
        )}
      </div>
      {type === 'userSubscriptionId' && !cancelled && (
        <button
          type="button"
          className="outlined_button"
          onClick={() => onSubCancel()}
        >
          Avslutt abonnement
        </button>
      )}
      {type === 'userSubscriptionId' && cancelled && (
        <p className="description">
          Du kan fortsette å bruke Boligmappa+ frem til utløpsdatoen.
        </p>
      )}
      {type === 'userSinglePaymentId' && (
        <a
          href="https://help.no/kontakt-oss/meld-inn-sak"
          className="filled_button"
          target="_blank"
          rel="noreferrer"
        >
          Meld skade
        </a>
      )}
    </StyledPurchaseCard>
  );
};

export default PurchaseCard;
