import PlantsImage from '../../../assets/images/plants.png';

export const logoSize = 32;

const styles = (theme) => ({
  logo: {
    width: logoSize,
    height: logoSize,
  },
  logoDesktop: {
    width: logoSize,
    height: logoSize,
    paddingLeft: '32px',
  },
  logoMobile: {
    marginTop: '-6px',
    width: logoSize,
    height: logoSize,
    paddingLeft: '16px',
  },
  root: {
    [theme.breakpoints.up('md')]: {
      background: `url(${PlantsImage}) left 30px bottom fixed no-repeat`,
      backgroundSize: '100px',
    },
  },
  loaderContainer: {
    marginTop: '70px',
  },
  refreshPropertiesText: {
    marginTop: '-30px',
    marginBottom: '20px',
  },
  caption: {
    fontWeight: 600,
    fontSize: '16px',
    textAlign: 'center',
    color: '#111111',
  },
  errorResponse: {
    fontSize: '13px',
  },
  errorlist: {
    listStyleType: 'none',
  },
  headerImage: {
    width: '152.006px',
    height: '27.001px',
    flexShrink: '0',
  },
  headerBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '32px',
  },
  terms_link: {
    display: 'flex',
    color: '#111111',
    justifyContent: 'center',
  },
});

export default styles;
