import React from 'react';

const PencilIcon = ({ width = '1rem', height = '1rem', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    {...props}
  >
    <path
      d="M16.4062 1.75C16.7812 2.15625 17 2.625 17 3.1875C17 3.75 16.7812 4.21875 16.4062 4.59375L5.40625 15.5938L1.75 16C1.5 16 1.3125 15.9375 1.1875 15.75C1.03125 15.5938 0.96875 15.4062 1 15.1562L1.40625 11.5938L12.4062 0.59375C12.7812 0.21875 13.25 0 13.8125 0C14.375 0 14.8438 0.21875 15.25 0.59375L16.4062 1.75ZM4.71875 14.1562L12.9062 5.96875L11.0312 4.09375L2.84375 12.2812L2.59375 14.4062L4.71875 14.1562ZM15.3438 3.53125C15.4375 3.4375 15.5 3.3125 15.5 3.1875C15.5 3.0625 15.4375 2.9375 15.3438 2.8125L14.1875 1.65625C14.0625 1.5625 13.9375 1.5 13.8125 1.5C13.6875 1.5 13.5625 1.5625 13.4688 1.65625L12.0938 3.03125L13.9688 4.90625L15.3438 3.53125Z"
      fill="#111111"
    />
  </svg>
);
export default PencilIcon;
