import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Box from '../../../../atomic/Box';
import Menu from '../../../../atomic/Menu';
import Button from '../../../../atomic/Button';
import Typography from '../../../../atomic/Typography';

const FilterMenu = ({ classes, title, onClose, children, ...props }) => (
  <Menu
    onClose={onClose}
    PopoverClasses={{ root: classes.popover }}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    {...props}
  >
    <Box
      px={1}
      py={2}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      {title && (
        <Box p={1}>
          <Typography>
            <strong>{title}</strong>
          </Typography>
        </Box>
      )}

      <Button
        varinat="text"
        color="primary"
        onClick={onClose}
        style={{ textDecoration: 'none' }}
      >
        Ferdig
      </Button>
    </Box>

    {children}
  </Menu>
);

export default withStyles(styles)(FilterMenu);
