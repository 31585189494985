import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { connect } from 'react-redux';

import Images from './Images';
import ShowRoom from './Show';

import { sGetRooms } from '../../../../../store/reducers/rooms';
import { acUpdateRoom } from '../../../../../store/actions/rooms';
import { sGetEvents } from '../../../../../store/reducers/events';
import { acSyncEventsRooms } from '../../../../../store/actions/events';

import Route from '../../../../../components/atomic/Route';

import { getEventId } from '../../../../../utils/events';
import { areIdsEqual } from '../../../../../utils/strings';
import { fetchPublicRoom as apiFetchRoom } from '../../../../../api/rooms';
import { getRoomEvents, getRoomId } from '../../../../../utils/rooms';

/**
 * Parent component for edit/show single room pages.
 * It allows to store room data and prevent double
 * fetching upon switching from "show room" view
 * to "edit room" view and vice versa
 */
const Room = ({ rooms, events, updateRoom, syncEventsRooms }) => {
  const { roomId } = useParams();
  const storedRoom = (rooms || []).find((room) =>
    areIdsEqual(getRoomId(room), roomId)
  );

  const [room, setRoom] = useState(storedRoom);
  const [fetching, setFetching] = useState(!storedRoom);
  const [fetchingError, setFetchingError] = useState(null);

  const fetchRoomData = async () => {
    setFetching(true);

    const [error, data] = await apiFetchRoom(roomId);

    if (error) {
      setFetchingError(error);
    } else {
      setRoom(data);
    }

    setFetching(false);
  };

  const onRoomUpdated = (updatedRoom) => {
    setRoom(updatedRoom);

    // Only update room in store if there are rooms loaded.
    // Otherwise updated room will be fetched along with all other rooms.
    if (rooms) {
      updateRoom(updatedRoom);
    }

    if (events) {
      const { eventIds } = updatedRoom;

      syncEventsRooms({
        roomId,
        room: updatedRoom,
        eventIds:
          eventIds || (getRoomEvents(updatedRoom) || []).map(getEventId),
      });
    }
  };

  useEffect(() => {
    if (storedRoom) {
      setRoom(storedRoom);
    }

    if (!room && !storedRoom) {
      fetchRoomData();
    }
  }, [storedRoom]);

  return (
    <>
      <Route exact path="/public/:boligmappaNumber/rooms/:roomId">
        <ShowRoom
          room={room}
          fetching={fetching}
          onRoomUpdated={onRoomUpdated}
          fetchingError={fetchingError}
        />
      </Route>

      <Route exact path="/public/:boligmappaNumber/rooms/:roomId/images">
        <Images
          room={room}
          fetching={fetching}
          onRoomUpdated={onRoomUpdated}
          fetchingError={fetchingError}
        />
      </Route>
    </>
  );
};

const mapStateToProps = (state) => ({
  rooms: sGetRooms(state),
  events: sGetEvents(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateRoom: (room) => dispatch(acUpdateRoom(room)),
  syncEventsRooms: (payload) => dispatch(acSyncEventsRooms(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Room);
