export const defaultState = null;

export const SET_APP_VERSION_DATA = 'SET_APP_VERSION_DATA';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_APP_VERSION_DATA:
      return action.payload;
    default:
      return state;
  }
};

export const sGetAppVersionData = (state) => state.appVersionData;
