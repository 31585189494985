import React, { useState } from 'react';

const Image = ({
  alt,
  src,
  event,
  fallbackSrc,
  onImageSelect,
  fallbackComponent,
  onError: passedOnError,
  openImageUploadDialog,
  ...props
}) => {
  const [error, setError] = useState(null);

  const onError = (domEvent) => {
    domEvent.persist();

    setError(domEvent);

    return passedOnError ? passedOnError(domEvent) : null;
  };

  if ((src === null && fallbackComponent) || (error && fallbackComponent)) {
    return fallbackComponent;
  }

  if (error && !fallbackSrc) {
    return null;
  }

  if (error && fallbackSrc) {
    return <img alt={alt} src={fallbackSrc} {...props} />;
  }

  return (
    <img alt={alt} onError={onError} src={src || fallbackSrc} {...props} />
  );
};

export default Image;
