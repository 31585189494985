import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import styles from './style';
import insuranceWorkers from '../../assets/images/insurance_workers.jpg';
import useDesktop from '../../hooks/useDesktop';
import CheckCircleRounded from '../../assets/icons/CheckCircleRounded';

const Content = ({ classes }) => {
  const isDesktop = useDesktop();
  return (
    <div
      className={clsx([
        classes.insuranceContent,
        classes.column,
        !isDesktop && 'mobile',
      ])}
    >
      <div
        className={clsx([
          classes.row,
          classes.consultationBlock,
          !isDesktop && 'mobile',
        ])}
      >
        <div className={clsx([classes.text, !isDesktop && 'mobile'])}>
          <h2>Trygt utført</h2>
          <p>Boligmappas advokatforsikring for håndverkertjenester.</p>
        </div>
        <img src={insuranceWorkers} alt="boligmappa insurance" />
      </div>
      <div className={classes.consultationDesc}>
        <h2>1 av 6 boligeiere opplever uenighet med håndverkeren.</h2>
        <p>
          Derfor anbefaler vi <b>Trygt utført</b> - advokatforsikring for
          håndverkertjenester av Boligmappa.
        </p>
        <p>
          Få umiddelbar rådgivning fra advokat ved mangel og reklamasjon eller
          tvist. Forsikringen leveres av HELP forsikring.
        </p>
      </div>
      <div
        className={clsx([
          classes.featuresBlock,
          classes.column,
          !isDesktop && 'mobile',
        ])}
      >
        <h2>Hva dekker forsikringen?</h2>
        <div
          className={clsx([
            classes.row,
            classes.features,
            !isDesktop && 'mobile',
          ])}
        >
          <div className={clsx([classes.feature, !isDesktop && 'mobile'])}>
            <CheckCircleRounded className={classes.checkedLabel} />
            <div
              className={clsx([classes.featureText, !isDesktop && 'mobile'])}
            >
              <h2>Juridisk bistand</h2>
              <p>inntil 5 timer per år</p>
            </div>
          </div>
          <div className={clsx([classes.feature, !isDesktop && 'mobile'])}>
            <CheckCircleRounded className={classes.checkedLabel} />
            <div
              className={clsx([classes.featureText, !isDesktop && 'mobile'])}
            >
              <h2>Dekning av kostnader</h2>
              <p>opptil 300.000 kroner i dekning ved tvist</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Content);
