import clsx from 'clsx';
import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Button from '../atomic/Button';
import Typography from '../atomic/Typography';
import ConnectNewEvent from '../../assets/icons/ConnectNewEvent';
import ConnectExistingEvent from '../../assets/icons/ConnectExistingEvent';

const ConnectEventButton = ({
  classes,
  className,
  newEventIcon = false,
  textColor = 'textSecondary',
  children = 'Koble til hendelse',
  ...props
}) => (
  <Button
    variant="outlined"
    className={clsx(className, classes.button)}
    {...props}
  >
    <Typography component="span" color={textColor}>
      {newEventIcon ? (
        <ConnectNewEvent
          width="1.2rem"
          height="1.2rem"
          className={classes.icon}
        />
      ) : (
        <ConnectExistingEvent
          width="1.2rem"
          height="1.2rem"
          className={classes.icon}
        />
      )}
      {children}
    </Typography>
  </Button>
);

export default withStyles(styles)(ConnectEventButton);
