import clsx from 'clsx';
import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Box from '../../atomic/Box';
import Dialog from '../../atomic/Dialog';
import Close from '../../../assets/icons/Close';
import IconButton from '../../atomic/IconButton';
import DialogContent from '../../atomic/DialogContent';

const EntitiesDialog = ({
  onClose,
  classes,
  children,
  className,
  ...props
}) => {
  const onRootClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Dialog
      onClose={onClose}
      onClick={onRootClick}
      className={clsx(className, classes.root)}
      {...props}
    >
      <IconButton className={classes.closeButton} onClick={onClose}>
        <Close />
      </IconButton>
      <DialogContent>
        <Box py={2}>{children}</Box>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(EntitiesDialog);
