const styles = {
  title: {
    margin: '10px 0',
  },
  list: {
    padding: 0,
  },
};

export default styles;
