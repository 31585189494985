import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import styles from './style';
import useDesktop from '../../hooks/useDesktop';
import Typography from '../atomic/Typography';
import Button from '../atomic/Button';
import NewPage from '../../assets/icons/NewPage';
import ProgressButton from '../ProgressButton';
import SalesLawImage from '../../assets/images/NearbyUnitsImage.png';
import Image from '../Image/index';
import Headline from '../Headline';
import Link from '../atomic/Link';
import ContentLoader from '../loaders/ContentLoader';
import Box from '../atomic/Box';
import {
  acSetSoldMarketEvents,
  acSetForSaleMarketEvents,
} from '../../store/actions/marketEvents';
import {
  sGetSoldMarketEvents,
  sGetForSaleMarketEvents,
} from '../../store/reducers/marketEvents';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';
import {
  getPropertyCount,
  eventLimit,
  getMaxValueofArray,
  getMinValueofArray,
  truncateArray,
  redirectToVirdiMap,
} from '../../utils/marketEvents';
import useFetchEffect from '../../hooks/useFetchEffect';
import { getMarketEvents as apiFetchMarketEvents } from '../../api/properties';
import { formatNumber } from '../../utils/strings';
import Arrow from '../../assets/icons/ArrowRightAlt';
import { useActiveProperty } from '../../store/hooks';
import { getBogusValue } from '../../utils/numbers';

const PropertyMarketEventsCard = ({
  classes,
  hideValues,
  setSoldMarketEvents,
  soldMarketEvents,
  marketEventsForSale,
  setMarketEventsForSale,
}) => {
  const isDesktop = useDesktop();
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const { property } = useActiveProperty();
  const [fetchingMarketEventsForSale, setFetchingMarketEventsForSale] =
    useState(false);
  const [fetching, setFetching] = useState(false);

  useFetchEffect({
    dependencies: [property],
    setFetchingFunction: setFetching,
    onSuccess: (data) => setSoldMarketEvents(data),
    conditionFunction: () => !soldMarketEvents && !fetching && property != null,
    apiFetchFunction: () =>
      apiFetchMarketEvents(property.boligmappaNumber, eventLimit, 'sold'),
  });

  useFetchEffect({
    dependencies: [property],
    setFetchingFunction: setFetchingMarketEventsForSale,
    onSuccess: (data) => setMarketEventsForSale(data),
    conditionFunction: () =>
      !marketEventsForSale && !fetchingMarketEventsForSale && property != null,
    apiFetchFunction: () =>
      apiFetchMarketEvents(property.boligmappaNumber, eventLimit, 'for_sale'),
  });

  const maxEstimateValue = getMaxValueofArray(
    truncateArray(soldMarketEvents) || [],
    truncateArray(marketEventsForSale) || []
  );

  const minEstimateValue = getMinValueofArray(
    truncateArray(soldMarketEvents) || [],
    truncateArray(marketEventsForSale) || []
  );

  const showEmptyStateInfomationCard =
    ((marketEventsForSale || []).length === 0 || marketEventsForSale == null) &&
    ((soldMarketEvents || []).length === 0 || soldMarketEvents == null);

  const marketEventsPageURL = `market-events`;
  const buttonLabel = 'Se boliger';
  const virdiLinkText = 'Gå til hjemla.no';
  const cardTitle = 'Hva er naboen sin bolig verdt?';
  const cardTitleMobile = (
    <>
      Sammenlignbare <br /> boliger i nabolaget
    </>
  );
  const minimumValueToShow =
    minEstimateValue > 0 && !hideValues ? (
      <>fra {formatNumber(minEstimateValue)} kr </>
    ) : (
      'opp'
    );

  const emptyStateLinkitem = (
    <>
      <Button className={classes.virdiMapButton} onClick={redirectToVirdiMap}>
        <span>{virdiLinkText}</span>{' '}
        <span>
          <Arrow className={classes.linkIcon} />
        </span>
      </Button>
    </>
  );

  const button = (
    <div
      className={
        isDesktop ? classes.detailsButtonArea : classes.detailsButtonsAreaMobile
      }
    >
      <Link
        to={`${dashboardLinkPrefix}/${marketEventsPageURL}`}
        underline={false}
        inheritColor
      >
        <ProgressButton
          requestInProgress={false}
          type="submit"
          color="primary"
          variant="contained"
          className={classes.button}
        >
          <span>{buttonLabel}</span>
        </ProgressButton>
      </Link>
    </div>
  );

  const emptyStateMobileButton = (
    <Button
      className={classes.emptyStateButton}
      onClick={redirectToVirdiMap}
      underline={false}
    >
      <span>{virdiLinkText} </span>
      <NewPage className={classes.buttonIcon} />
    </Button>
  );

  const emptyStateInformation = (
    <Typography
      className={isDesktop ? classes.introContent : classes.introContentMobile}
    >
      Vi finner ingen boliger som er til salgs eller nylig er blitt solgt
      akkurat nå. Du kan se verdiestimater for alle boliger i hele Norge hos
      hjemla.no
    </Typography>
  );

  const information = (
    <Typography
      className={isDesktop ? classes.introContent : classes.introContentMobile}
    >
      Sjekk ut boliger i ditt nabolag som er til salgs eller nylig er blitt
      solgt. Det finnes {getPropertyCount(soldMarketEvents, eventLimit)} boliger
      tilgjengelig, med verdiestimater{' '}
      {!fetchingMarketEventsForSale && !fetching && minimumValueToShow} til{' '}
      {!fetchingMarketEventsForSale &&
        !fetching &&
        (hideValues
          ? getBogusValue(maxEstimateValue)
          : formatNumber(maxEstimateValue))}{' '}
      kr.
    </Typography>
  );

  const MarketEventImageForCard = (
    <div
      className={
        isDesktop ? classes.imageContainer : classes.mobileImageContainer
      }
    >
      <Image
        width="100%"
        className={classes.image}
        fallbackSrc={SalesLawImage}
      />
    </div>
  );

  if (fetching || fetchingMarketEventsForSale) {
    return (
      <Box width="100%" height="300px">
        <Box mb={3}>
          <ContentLoader width={250} height={30} />
        </Box>
        <Box mb={3}>
          <ContentLoader width={70} height={20} />
        </Box>
        <Box mb={3}>
          <ContentLoader width={70} height={20} />
        </Box>
      </Box>
    );
  }

  return (
    <div>
      {isDesktop && (
        <div className={classes.title}>
          <Typography mt={0} variant="h2" className={classes.titleText}>
            {cardTitle}
          </Typography>
        </div>
      )}
      {!isDesktop && (
        <Headline mb={0} align="center">
          {cardTitleMobile}
        </Headline>
      )}

      {!isDesktop && MarketEventImageForCard}

      <div
        className={
          isDesktop ? classes.contentContainer : classes.contentContainerMobile
        }
      >
        <div
          className={
            isDesktop ? classes.textWrapper : classes.textWrapperMobile
          }
        >
          {showEmptyStateInfomationCard && emptyStateInformation}
          {!showEmptyStateInfomationCard && information}
        </div>

        {isDesktop && MarketEventImageForCard}

        {!showEmptyStateInfomationCard && (
          <div
            className={
              isDesktop
                ? classes.buttonWrapper
                : classes.buttonWrapperMarketEventMobile
            }
          >
            {button}
          </div>
        )}
        {showEmptyStateInfomationCard && isDesktop && emptyStateLinkitem}
        {showEmptyStateInfomationCard && !isDesktop && emptyStateMobileButton}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  soldMarketEvents: sGetSoldMarketEvents(state),
  marketEventsForSale: sGetForSaleMarketEvents(state),
});
const mapDispatchToProps = (dispatch) => ({
  setSoldMarketEvents: (soldMarketEvents) =>
    dispatch(acSetSoldMarketEvents(soldMarketEvents)),
  setMarketEventsForSale: (marketEventsForSale) =>
    dispatch(acSetForSaleMarketEvents(marketEventsForSale)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PropertyMarketEventsCard));
