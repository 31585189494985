import React, { useRef } from 'react';
import { withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchField from '../../../../../components/SearchField';
import Search from '../../../../../assets/icons/Search';
import styles from './style';

const MobileDocumentSearch = ({
  classes,
  isSearching,
  setIsSearching,
  value,
  onChange,
}) => {
  const searchRef = useRef(null);
  const onSearchClick = () => {
    setIsSearching(true);
    if (searchRef.current) {
      searchRef.current.focus();
    }
  };
  return (
    <div className={classes.searchBox}>
      <SearchField
        fullWidth
        className={!isSearching && classes.hide}
        inputProps={{ ref: searchRef }}
        classes={{ inputField: classes.search }}
        value={value}
        onChange={onChange}
        placeholder="Søk i dokumenter"
        onBlur={() => setIsSearching(false)}
      />
      {isSearching && (
        <button
          className={classes.closeSearchButton}
          type="button"
          onClick={() => setIsSearching(false)}
          aria-label="Close"
        >
          <CloseIcon htmlColor="#111111" />
        </button>
      )}
      {!isSearching && (
        <button
          className={classes.searchButton}
          type="button"
          onClick={onSearchClick}
          aria-label="Search"
        >
          <Search htmlColor="#111111" />
        </button>
      )}
    </div>
  );
};

export default withStyles(styles)(MobileDocumentSearch);
