import { connect } from 'react-redux';
import React, { createElement } from 'react';

import {
  getRoomDocuments,
  getRoomEvents,
  getRoomImages,
  getRoomName,
} from '../../../../../../utils/rooms';

import Box from '../../../../../../components/atomic/Box';
import { truncate } from '../../../../../../utils/strings';
import useDesktop from '../../../../../../hooks/useDesktop';
import Paper from '../../../../../../components/atomic/Paper';
import EventList from '../../../../../../components/EventList';
import { sGetRooms } from '../../../../../../store/reducers/rooms';
import ImageGallery from '../../../../../../components/ImageGallery';
import Typography from '../../../../../../components/atomic/Typography';
import RoomDescription from '../../../../../../modules/RoomDescription';
import PageLayout from '../../../../../../components/layouts/PublicPage';
import ErrorContainer from '../../../../../../components/ErrorContainer';
import { padding } from '../../../../../../components/atomic/Paper/style';
import ContentLoader from '../../../../../../components/loaders/ContentLoader';
import {
  getDocumentList,
  getDocumentTable,
} from '../../../../../../utils/DocumentTable';

const Room = ({ room, fetching, fetchingError }) => {
  const isDesktop = useDesktop();

  const images = getRoomImages(room);

  const shouldShowImageGallery =
    fetching || (Array.isArray(images) && images.length > 0);

  const events = getRoomEvents(room);
  const documents = getRoomDocuments(room);

  const showEventList = fetching || (!fetching && events && events.length > 0);
  const showDocumentList =
    fetching || (!fetching && documents && documents.length > 0);

  const headerTitle = isDesktop
    ? getRoomName(room)
    : truncate(getRoomName(room), { length: 30 });

  const renderDocumentList = () =>
    createElement(isDesktop ? getDocumentTable() : getDocumentList(), {
      fetching,
      documents,
      isFiltersSticky: false,
      showOnPaper: false,
      error: fetchingError,
      publicSection: true,
      actionsEnabled: false,
    });

  if (fetchingError) {
    return <ErrorContainer errorResponse={fetchingError} />;
  }

  return (
    <PageLayout
      noPaddingTop
      minFullHeight
      showBackButton
      noPadding={!isDesktop}
      hidePublicHeader={isDesktop}
      subPageHeader
      headerTitle={headerTitle || 'Rom'}
      lessMarginLeft={isDesktop}
      publicSection
      maxWidth="lg"
    >
      {shouldShowImageGallery && (
        <ImageGallery images={images} fetching={fetching} />
      )}

      <br />
      <Paper visible={!isDesktop}>
        <RoomDescription room={room} fetching={fetching} />
      </Paper>

      {showEventList && (
        <>
          <br />
          <Paper visible={!isDesktop}>
            <Typography variant="h3">Historikk</Typography>
            <EventList
              truncatable
              fetching={fetching}
              events={getRoomEvents(room)}
            />
          </Paper>
        </>
      )}

      {showDocumentList && (
        <>
          <br />
          <Paper visible={!isDesktop}>
            <Typography variant="h3">
              {fetching ? (
                <ContentLoader height={20} width={110} />
              ) : (
                'Dokumenter'
              )}
            </Typography>

            <Box mt={2} mx={`-${padding}px`}>
              {renderDocumentList()}
            </Box>
          </Paper>
        </>
      )}
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  rooms: sGetRooms(state),
});

export default connect(mapStateToProps)(Room);
