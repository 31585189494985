import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';

import {
  hasAttachment,
  isNotificationSeen,
  getNotificationDate,
  getNotificationTitle,
  getNotificationIssuer,
  getNotificationIdHash,
  getNotificationCompanyName,
} from '../../../../../utils/notifications';

import styles from './style';
import { fromNow } from '../../../../../utils/dates';
import BallIcon from '../../../../../assets/icons/Ball';
import Link from '../../../../../components/atomic/Link';
import ListItem from '../../../../../components/atomic/ListItem';
import AttachmentIcon from '../../../../../assets/icons/Attachment';
import Typography from '../../../../../components/atomic/Typography';
import ArrowForwardIcon from '../../../../../assets/icons/ArrowForward';
import useDashboardLinkPrefix from '../../../../../hooks/useDashboardLinkPrefix';

export const messageTextMaxLength = 120;

const NotificationItem = ({ classes, selected, notification }) => {
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const notificationLink = `${dashboardLinkPrefix}/notifications/${getNotificationIdHash(
    notification
  )}`;

  return (
    <Link underline={false} to={notificationLink}>
      <ListItem
        className={clsx(classes.container, selected && classes.selected)}
      >
        <div style={{ display: 'flex' }}>
          <div className={classes.iconsContainer}>
            <div>
              {!isNotificationSeen(notification) && (
                <BallIcon className={classes.ballIcon} />
              )}
            </div>
            <div>
              {hasAttachment(notification) && (
                <AttachmentIcon className={classes.attachmentIcon} />
              )}
            </div>
          </div>

          <div style={{ width: '100%' }}>
            <div className={classes.titleContainer}>
              <div>
                <Typography variant="h4" component="span" color="textPrimary">
                  {getNotificationCompanyName(notification) ||
                    getNotificationIssuer(notification)}
                </Typography>
              </div>
              <div className={classes.dateContainer}>
                <div>
                  <small className={classes.date}>
                    {fromNow(getNotificationDate(notification))}
                  </small>
                </div>
                <div style={{ display: 'contents' }}>
                  <ArrowForwardIcon className={classes.arrowIcon} />
                </div>
              </div>
            </div>
            <div className={classes.content}>
              <div style={{ marginRight: '15px' }}>
                <Typography
                  component="p"
                  variant="body2"
                  color="textPrimary"
                  className={clsx('word-break', classes.message)}
                >
                  {getNotificationTitle(notification)}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </ListItem>
    </Link>
  );
};

export default withStyles(styles)(NotificationItem);
