import React, { useState } from 'react';
import { StyledPurchases } from '@componentsV2/Purchases/styles';
import PurchaseCard from '@componentsV2/PurchaseCard';
import CancelSubDialog from '@componentsV2/CancelSubDialog';
import DotLoader from '../../components/atomic/DotLoader';

const Purchases = ({
  loading,
  dashboardLinkPrefix,
  transactions,
  onSubscriptionCancel,
}) => {
  const [isCancelDialog, setIsCancelDialog] = useState(false);
  const [activeCancelId, setActiveCancelId] = useState();

  const onCancel = (id) => {
    setActiveCancelId(id);
    setIsCancelDialog(true);
  };

  const onCancelConfirm = () => {
    onSubscriptionCancel(activeCancelId);
    setIsCancelDialog(false);
  };
  return (
    <StyledPurchases>
      <h2>Mine kjøp</h2>
      <div className="content">
        <div className="purchases_cards">
          {loading && <DotLoader />}
          {!loading && transactions.length < 1 && (
            <div className="no_transactions">
              <p>Du har ingen kjøp.</p>
            </div>
          )}
          {!loading &&
            transactions.length > 0 &&
            transactions.map((el) => (
              <PurchaseCard
                key={el.orderNumber}
                classes="purchase_card"
                type={el.type}
                dashboardLinkPrefix={dashboardLinkPrefix}
                date={el.createdDate}
                orderNumber={el.orderNumber}
                amount={el.amount}
                nextBillingDate={el.nextBillingDate}
                cancelled={el.cancelled}
                onSubCancel={() => onCancel(el.orderNumber)}
              />
            ))}
        </div>
        <aside className="help_aside">
          <div className="help_box">
            <h3>Trenger du hjelp?</h3>
            <p>
              På våre hjelpesider finner du spørsmål og svar på alt om
              Boligmappa.
            </p>
            <a
              href="https://support.boligmappa.no"
              className="filled_button"
              target="_blank"
              rel="noreferrer"
            >
              Besøk kundesenteret
            </a>
          </div>
          <h4>Vilkår for kjøp</h4>
          <ul className="link_list">
            <li>
              <a
                href="https://www.boligmappa.no/vilkar-for-kjop"
                target="_blank"
                rel="noreferrer"
              >
                Vilkår for kjøp (boligmappa.no)
              </a>
            </li>
          </ul>
        </aside>
      </div>
      <CancelSubDialog
        isShown={isCancelDialog}
        onHide={() => setIsCancelDialog(false)}
        onSubCancel={onCancelConfirm}
      >
        <h3 className="dialog_title">Avslutt Boligmappa+?</h3>
        <p className="dialog_description">
          Dette skjer når du avslutter ditt abonnement:
        </p>
        <ul className="dialog_list">
          <li>
            Din bruker mister tilgang til alt innhold fra Boligmappa+ når
            nåværende, betalte abonnementperiode utløper.
          </li>
          <li>
            Du kan når som helst reaktivere abonnementet til den gjeldende
            markedsprisen på reaktiveringstidspunktet.
          </li>
          <li>
            Avsluttet abonnement vil ikke slette din bruker, dine boliger etc.
            Kun tilgangen til innhold fra Boligmappa+.
          </li>
        </ul>
      </CancelSubDialog>
    </StyledPurchases>
  );
};

export default Purchases;
