import React from 'react';

const Certificate = () => (
  <svg
    width="12"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.33333 2.17057H2.66667C2.22464 2.17057 1.80072 2.34617 1.48816 2.65873C1.17559 2.97129 1 3.39521 1 3.83724V13.8372C1 14.2793 1.17559 14.7032 1.48816 15.0157C1.80072 15.3283 2.22464 15.5039 2.66667 15.5039H11C11.442 15.5039 11.866 15.3283 12.1785 15.0157C12.4911 14.7032 12.6667 14.2793 12.6667 13.8372V3.83724C12.6667 3.39521 12.4911 2.97129 12.1785 2.65873C11.866 2.34617 11.442 2.17057 11 2.17057H9.33333M4.33333 2.17057H9.33333M4.33333 2.17057C4.33333 2.6126 4.50893 3.03652 4.82149 3.34908C5.13405 3.66164 5.55797 3.83724 6 3.83724H7.66667C8.1087 3.83724 8.53262 3.66164 8.84518 3.34908C9.15774 3.03652 9.33333 2.6126 9.33333 2.17057M4.33333 2.17057C4.33333 1.72855 4.50893 1.30462 4.82149 0.992062C5.13405 0.679501 5.55797 0.503906 6 0.503906H7.66667C8.1087 0.503906 8.53262 0.679501 8.84518 0.992062C9.15774 1.30462 9.33333 1.72855 9.33333 2.17057M4.33333 9.67057L6 11.3372L9.33333 8.00391"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Certificate;
