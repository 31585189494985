import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Box from '../atomic/Box';
import Typography from '../atomic/Typography';
import ArrowBackward from '../../assets/icons/ArrowBackward';

const NavigationBackButton = ({ classes, title, ...props }) => (
  <Box
    display="flex"
    alignItems="center"
    className={classes.backButtonContainer}
    {...props}
  >
    <ArrowBackward strokeWidth={3} className={classes.backIcon} />
    <Typography color="primary" component="small" className={classes.backText}>
      <strong>{title}</strong>
    </Typography>
  </Box>
);

export default withStyles(styles)(NavigationBackButton);
