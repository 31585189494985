import React from 'react';

const ControlArrowRight = ({
  width = '1.2rem',
  height = '1.2rem',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="24" cy="24" r="24" opacity="0.6" />
    <path
      stroke="#fff"
      fill="#FC6865"
      strokeWidth="4"
      d="M0,0H19.216"
      strokeLinecap="round"
      transform="translate(13 24.424)"
    />
    <path
      fill="none"
      stroke="#fff"
      strokeWidth="4"
      strokeLinecap="round"
      d="M0,0,9.41,9.41,0,18.82"
      transform="translate(24.817 14.738)"
    />
  </svg>
);

export default ControlArrowRight;
