import React from 'react';
import { PortableText } from '@portabletext/react';

export const getComponents = () => {
  const components = {
    marks: {
      link: ({ children, value }) => {
        const { href } = value;
        return (
          <a href={href} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      },
    },
  };
  return components;
};

export const BmPortableText = (props) => {
  const components = getComponents();

  return <PortableText components={components} {...props} />;
};
