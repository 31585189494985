import React from 'react';

import Box from '../../../../../../../../components/atomic/Box';
import Typography from '../../../../../../../../components/atomic/Typography';

export const PriceHistoryPlace = ({ value }) => {
  return (
    <Box
      width="auto"
      bgcolor="#F1F1F9"
      borderRadius="4px"
      padding="6px 10px"
      display="inline-block"
      mb={3}
    >
      <Typography fontSize={13}>{value}</Typography>
    </Box>
  );
};
