import imageUrlBuilder from '@sanity/image-url';
import sanity from '../sanity/sanity.mjs';

export const getSanityImageUrl = (image, width, height, quality) => {
  const builder = imageUrlBuilder(sanity);

  let imageUrl = builder.image(image);

  if (height) {
    imageUrl = imageUrl.height(height);
  }

  if (width) {
    imageUrl = imageUrl.width(width);
  }

  if (quality) {
    imageUrl = imageUrl.quality(quality);
  }

  return imageUrl.url();
};

export const getImageKitImageUrlForSanity = (image, width, height, quality) => {
  if (image) {
    const sanityImageUrl = getSanityImageUrl(image, width, height, quality);
    return `https://ik.imagekit.io/boligmappa/${sanityImageUrl}`;
  }
  return null;
};
