// Add your styles here, and reference them in your component className
import { css } from '@linaria/core';
import { mediaQueries } from '@style/mediaQueries';

export const style = css``;

export const CompanyProfile = css`
display: grid;
margin-top: 60px;
grid-template-areas:
  'calert calert calert calert calert calert'
  'cdescription cdescription cdescription cdescription cdescription cdescription'
  'cdata cdata cdata cdata cdata cdata'
  'cfeatures cfeatures cfeatures cfeatures cfeatures cfeatures';

${mediaQueries.lg} {
  grid-template-areas:
    'calert calert calert calert calert cdata'
    'cdescription cdescription cdescription cdescription cdescription cdata'
    'cfeatures cfeatures cfeatures cfeatures cfeatures cdata'
    '. . . . . cdata';
}

.company-card-wrapper {
  margin: 16px 0 0 0;
  border: 1.5px solid #cccccc;
  border-radius: 7px;
  padding: 32px 10px 32px 32px;
  ${mediaQueries.lg} {
    margin: 40px 45px 0 0;
    padding: 32px 25px;
  }
}

.company-alert-section {
  margin: 16px 0 0 0;
  grid-area: calert;
  ${mediaQueries.md} {
    min-width: 603px;
  }
  ${mediaQueries.lg} {
    margin: 40px 45px 0 0;
  }
}

.company-alert-section:empty { padding : 0; margin : 0}

  .company-description-section {
    grid-area: cdescription;
    ${mediaQueries.md} {
      min-width: 603px;
    }
  }

  .company-data-section {
    grid-area: cdata;
  }

  .company-feature-section {
    grid-area: cfeatures;
  }

  .company-contact-person-section {
    margin-top: 16px;
    ${mediaQueries.lg} {
      margin-top: 40px;
    }
  }
`;

export const contactButtonWrapper = css`
    display: block;
    position:fixed;
    right: 28px;
    bottom: 100px;
    z-index: 10;
    .contact-button {
        background: #254E52 !important;
        width:62px;
        height: 48px;
        :hover {
            background: #193639 !important; 
        }

        &:focus {
            outline: none !important;
        }
    }
    ${mediaQueries.md} {
        display: none;
    }
`;
