const styles = {
  heading: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  image: {
    width: '100%',
    height: '150px',
    objectFit: 'cover',
    borderRadius: '12px',
  },
  photoDisabled: {
    filter: 'opacity(.5)',
  },
  icon: {
    right: '10px',
    bottom: '10px',
    color: 'white',
    boxShadow: 'none',
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.6)',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.6)',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
};

export default styles;
