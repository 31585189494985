export const defaultState = {
  financials: null,
  fetching: null,
  fetchingError: null,
};

export const SET_FINANCIALS = 'SET_FINANCIALS';
export const CLEAR_FINANCIALS = 'CLEAR_FINANCIALS';
export const SET_FETCHING_FINANCIALS = 'SET_FETCHING_FINANCIALS';
export const SET_FETCHING_FINANCIALS_ERROR = 'SET_FETCHING_FINANCIALS_ERROR';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_FINANCIALS:
      return {
        ...state,
        financials: action.payload,
      };
    case CLEAR_FINANCIALS:
      return defaultState;
    case SET_FETCHING_FINANCIALS:
      return {
        ...state,
        fetching: action.payload,
      };
    case SET_FETCHING_FINANCIALS_ERROR:
      return {
        ...state,
        fetchingError: action.payload,
      };
    default:
      return state;
  }
};

export const sGetFinancialssModule = (state) => state.financials;
export const sGetFinancials = (state) =>
  sGetFinancialssModule(state).financials;
export const sGetLoanDetails = (state) =>
  sGetFinancialssModule(state).loanDetails;
export const sGetFetchingFinancials = (state) =>
  sGetFinancialssModule(state).fetching;
export const sGetFetchingFinancialsError = (state) =>
  sGetFinancialssModule(state).fetchingError;

export const sGetPropertyOwnershipDetails = (state) =>
  sGetFinancialssModule(state).propertyOwnershipDetails;

export const sGetPropertyOwnershipHistory = (state) =>
  sGetFinancialssModule(state).propertyOwnershipHistory;
