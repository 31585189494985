export const imageIconStyles = {
  width: '38px',
  height: '38px',
  objectFit: 'cover',
  borderRadius: '3px',
};

const styles = (theme) => ({
  link: {
    color: 'inherit',
    display: 'block',
    textDecoration: 'none',
  },
  listItem: {
    padding: '10px 7px',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.colors.whiteDarker}`,
  },
  folderCheckbox: {
    pointerEvents: 'none',
    opacity: 0,
  },
  borderedItem: {
    borderBottom: `1px solid ${theme.colors.whiteDarker}`,
  },
  menuIcon: {
    color: theme.palette.primary.blackLight,
  },
  iconContainer: ({ showRoomsColumn, showEventsColumn }) => ({
    minWidth: 38,
    marginRight: 10,
    textAlign: 'center',
    marginTop: showRoomsColumn || showEventsColumn ? theme.spacing(1) : 0,
  }),
  secondaryText: {
    display: 'flex',
    fontSize: '12px',
    alignItems: 'center',
  },
  secondaryAction: ({ showRoomsColumn, showEventsColumn }) => ({
    top: 'calc(50% - 25px)',
    right: 0,
    transform: 'none',
    paddingRight: '16px',
    marginTop: showRoomsColumn || showEventsColumn ? theme.spacing(1) : 1,
  }),
  lockIcon: {
    width: '1.2rem',
    height: '1.2rem',
    marginLeft: theme.spacing(0.5),
  },
  checkbox: {
    marginRight: 0,
  },
  listItemSecondaryAction: {
    right: 0,
    transform: 'none',
    position: 'inherit',
    marginLeft: theme.spacing(1),
  },
  primaryText: {
    maxWidth: '85%',
    fontSize: '14px',
    color: '#13142A',
    textDecoration: 'none !important',
    fontWeight: '400',
    gap: '1px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  linkText: {
    textDecoration: 'none',
    backgroundColor: 'unset',
    padding: 'unset',
    border: 'unset',
    cursor: 'pointer',
    overflow: 'hidden',
  },
  imageIcon: {
    width: '16px',
    height: '16px',
  },
  imageIconBox: {
    minWidth: '32px',
    height: '32px',
    background: '#F2F8F8',
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  thumbnailImageIcon: {
    width: '32px',
    height: '32px',
    objectFit: 'cover',
    borderRadius: '3px',
  },
  builidingDocViewContainer: {
    marginTop: '1px',
  },
  lockForDocViewContainer: {
    marginTop: 4,
  },
});

export default styles;
