const styles = () => ({
  dialogTitle: {
    padding: 0,
    marginLeft: 20,
    '@supports (padding-top: env(safe-area-inset-top))': {
      paddingTop: 'env(safe-area-inset-top)',
    },
  },
  contactOptions: {
    margin: '42px 46px 42px 46px',
    height: '100%',
  },
  contactOption: {
    border: '1.5px solid #DEDFDF',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '112px',
    cursor: 'pointer',
    '&.active': {
      background: '#D9E9E9',
      border: '2px solid black',
    },
    '@media only screen and (max-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'left',
    },
  },
  moveBtn: {
    marginTop: 55,
    float: 'right',
  },
  backBtn: {
    border: '1px solid #DDE2F1',
    borderRadius: '50px',
    marginRight: '16px',
    textDecoration: 'none',
    height: '40px',
    color: 'black',
  },
  typeIcon: {
    '@media only screen and (max-width: 768px)': {
      margin: '0 20px 0 20px',
    },
  },
  propertyIcon: {
    '@media only screen and (max-width: 768px)': {
      margin: '0 10px 0 10px',
    },
  },
  propertySelectText: {
    fontSize: 14,
    width: '70%',
  },
  editIcon: {
    '@media only screen and (max-width: 768px)': {
      margin: '0 20px 0 10px',
      position: 'absolute',
      right: '40px',
    },
  },
  subContactTxt: {
    display: 'none',
    '@media only screen and (max-width: 768px)': {
      display: 'block',
      fontSize: '14px',
      margin: 0,
    },
    '@media only screen and (max-width: 355px)': {
      display: 'block',
      fontSize: '12px',
      margin: 0,
    },
  },
  contactTxt: {
    '@media only screen and (max-width: 768px)': {
      fontSize: '18px',
    },
    '@media only screen and (max-width: 355px)': {
      fontSize: '14px',
    },
  },
  propertySelectMobileContainer: {
    height: '400px',
    overflow: 'auto',
    marginTop: '-50px',
  },
  propertyOption: {
    border: '1.5px solid #DEDFDF',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70px',
    cursor: 'pointer',
    '&.active': {
      background: '#D9E9E9',
      border: '2px solid black',
    },
    '@media only screen and (max-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'left',
      marginBottom: '16px',
    },
  },
  formElement: {
    '& .css-26l3qy-menu': {
      position: 'relative',
    },
    '& .MuiInputLabel-root': {
      marginBottom: 10,
      color: 'black',
    },
  },
});

export default styles;
