const styles = (theme) => ({
  eventYear: {
    fontSize: '20px',
    fontWeight: 600,
    textAlign: 'center',
    padding: '20px 0',
    color: theme.palette.primary.main,
  },
});

export default styles;
