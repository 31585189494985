const styles = {
  content: {
    background: '#FAFAFA',
    borderRadius: '8px',
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'end',
    backgroundColor: '#FFF2F2',
  },
  image: {
    borderRadius: '8px',
    '@media only screen and (max-width: 1300px)': {
      width: '100%',
    },
    height: '100%',
    objectFit: 'cover',
  },
  textArea: {
    padding: '32px',
    color: '#000',
    backgroundColor: '#FFF2F2',
  },
  text: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: 'normal',
    '@media only screen and (max-width: 598px)': {
      fontSize: '14px',
    },
  },
  title: {
    color: '#000',
    lineHeight: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    '@media only screen and (max-width: 598px)': {
      fontSize: '18px',
    },
  },
  button: {
    marginRight: '10px',
    padding: '4px 20px',
    fontSize: '14px',
  },
  btnContent: {
    display: 'flex',
    marginTop: '30px',
  },
};
export default styles;
