/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Card from '../../../../../components/Card';
import { OverviewGridItem } from './components';
import BYBFSummary from '../../../../../modules/BYBFSummary';

function BuildYourBoligmappeSection({ maxWidh }) {
  const minActionButton = true;
  return (
    <OverviewGridItem showMaxwidth={maxWidh}>
      <Card height="100%">
        <BYBFSummary minActionButton={minActionButton} />
      </Card>
    </OverviewGridItem>
  );
}

export default BuildYourBoligmappeSection;
