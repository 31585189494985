import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import Show from './Show';
import EditEventDialog from './Edit';
import Images from './Images';
import { getRoomId } from '../../../../../utils/rooms';
import Route from '../../../../../components/atomic/Route';
import { areIdsEqual } from '../../../../../utils/strings';
import { sGetRooms } from '../../../../../store/reducers/rooms';
import useFetchEffect from '../../../../../hooks/useFetchEffect';
import { sGetEvents } from '../../../../../store/reducers/events';
import { acUpdateEvent } from '../../../../../store/actions/events';
import { acSyncRoomsEvents } from '../../../../../store/actions/rooms';
import { fetchEvent as apiFetchEvent } from '../../../../../api/events';
import { getEventId, getEventRooms } from '../../../../../utils/events';
import Button from '../../../../../components/atomic/Button';
import ArrowBackward from '../../../../../assets/icons/ArrowBackward';
import useDesktop from '../../../../../hooks/useDesktop';

/**
 * Parent component for edit/show single event pages.
 * It allows to store event data and prevent double
 * fetching upon switching from "show event" view
 * to "edit event" view and vice versa
 */
const Event = ({ rooms, events, updateEvent, syncRoomsEvents }) => {
  const { eventId } = useParams();
  const storedEvent = (events || []).find((event) =>
    areIdsEqual(getEventId(event), eventId)
  );

  const {
    fetching,
    fetchingError,
    setData: setEvent,
    data: fetchedEvent,
  } = useFetchEffect({
    dependencies: [eventId],
    apiFetchFunction: () => apiFetchEvent(eventId),
    conditionFunction: ([_eventId]) => !!_eventId,
  });
  const isDesktop = useDesktop();
  const history = useHistory();

  const event = fetchedEvent || storedEvent;

  const onBackButtonClick = () => {
    history.goBack();
  };

  const onEventUpdated = (updatedEvent) => {
    setEvent(updatedEvent);

    // Only update event in store if there are events loaded.
    // Otherwise updated event will be fetched along with all other events.
    if (events) {
      updateEvent(updatedEvent);
    }

    if (rooms) {
      const { roomIds } = updatedEvent;

      syncRoomsEvents({
        eventId,
        event: updatedEvent,
        roomIds: roomIds || (getEventRooms(updatedEvent) || []).map(getRoomId),
      });
    }
  };

  return (
    <>
      {isDesktop && (
        <Button
          style={{
            backgroundColor: 'transparent',
            fontSize: '14px',
            margin: '15px 0',
          }}
          startIcon={<ArrowBackward width={14} height={14} />}
          onClick={() => onBackButtonClick()}
        >
          Gå tilbake
        </Button>
      )}
      <Route exact path="/dashboard/:boligmappaNumber/events/:eventId">
        <Show
          event={event}
          fetching={fetching}
          fetchingError={fetchingError}
          onEventUpdated={onEventUpdated}
        />
      </Route>

      <Route exact path="/dashboard/:boligmappaNumber/events/:eventId/images">
        <Images
          event={event}
          fetching={fetching}
          fetchingError={fetchingError}
          onEventUpdated={onEventUpdated}
        />
      </Route>

      <EditEventDialog
        currentDocuments={event?.documents}
        event={event}
        fetching={fetching}
        fetchingError={fetchingError}
        onEventUpdated={onEventUpdated}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  rooms: sGetRooms(state),
  events: sGetEvents(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateEvent: (event) => dispatch(acUpdateEvent(event)),
  syncRoomsEvents: (payload) => dispatch(acSyncRoomsEvents(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Event);
