import {
  SET_COMMITMENTS,
  CLEAR_COMMITMENTS,
  SET_FETCHING_COMMITMENTS,
  SET_FETCHING_COMMITMENTS_ERROR,
} from '../reducers/commitments';

export const acSetCommitments = (commitments) => ({
  type: SET_COMMITMENTS,
  payload: commitments,
});

export const acClearCommitments = () => ({
  type: CLEAR_COMMITMENTS,
});

export const acSetFetchingCommitments = (fetching) => ({
  type: SET_FETCHING_COMMITMENTS,
  payload: fetching,
});

export const acSetFetchingCommitmentsError = (error) => ({
  type: SET_FETCHING_COMMITMENTS_ERROR,
  payload: error,
});
