const styles = (theme) => ({
  dialogTitle: {
    padding: 0,
    borderBottom: '1px solid #dedfdf',
    color: theme.palette.primary.black,
    background: theme.palette.primary.white,

    '@supports (padding-top: env(safe-area-inset-top))': {
      paddingTop: 'env(safe-area-inset-top)',
    },
  },
  dialogTitle_txt: {
    marginLeft: 26,
    marginRight: 'auto',
  },
  dialogTitle_txt_mobile: {
    width: '100%',
    textAlign: 'center',
    marginLeft: '33px',
    paddingBottom: 0,
  },
  dialog_actions: {
    margin: 'auto 0 15px 0',
    borderTop: '1px solid #dedfdf',
  },
  dialog_actions_mobile: {
    margin: 'auto 0 30px 0',
    borderTop: '1px solid #dedfdf',
  },
  next_btn: {
    marginRight: 30,
    marginTop: 10,
    width: '125px',
    height: '45px',
  },
  next_btn_mobile: {
    margin: '15px 15px 0 0',
    width: '125px',
    height: '45px',
  },
  back_btn: {
    marginLeft: 50,
    marginRight: 'auto',
  },
  back_btn_mobile: {
    marginLeft: 14,
    marginRight: 'auto',
  },
  delete_list_item: {
    listStyle: 'none',
    paddingLeft: '5px',
    marginTop: '10px',
  },
  delete_dialog_heading: {
    fontSize: '21px',
    marginBottom: '30px',
  },
  delete_popup_actions: {
    display: 'flex',
    justifyContent: 'center',
    padding: '30px 8px 20px 8px!important',
  },
  confirmCloseActions: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 60px !important',
  },
  confirmCloseButton: {
    width: '200px',
  },
});

export default styles;
