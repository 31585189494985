import clsx from 'clsx';
import React from 'react';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core';

import styles from './style';

const MuiLink = ({ classes, className, ...props }) => (
  <Link className={clsx(className, classes.link)} {...props} />
);

export default withStyles(styles)(MuiLink);
