import { request } from '../utils/http';
import { backendJobsAPIUrl } from '../config';

export const requestMissingDocumentsToJobs = (data) =>
  request({
    method: 'post',
    url: 'jobs',
    baseURL: backendJobsAPIUrl,
    data,
  })
    .then((response) => [null, response.data])
    .catch((error) => [error, null]);

export const fetchMDRList = (boligmappaNumbers) =>
  request({
    params: { boligmappaNumbers },
    method: 'get',
    url: 'jobs',
    baseURL: backendJobsAPIUrl,
  })
    .then((response) => [null, response.data])
    .catch((error) => [error, null]);
