const styles = (theme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    verticalAlign: 'top',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  arrow: {
    width: '10px',
    marginLeft: '8px',
    fill: theme.palette.text.primary,
    paddingTop: '12px',
  },
  sortingArrow: {
    height: '16px',
  },
  headerBox: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#F2F8F8',
      borderRadius: '10px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 10px 0 10px',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  nonHoverHeaderBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 10px 0 0',
    fontWeight: 'bold',
    cursor: 'default',
  },
  arrowBox: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#F2F8F8',
      borderRadius: '100%',
    },
  },
  sortingDefaultArrow: {
    color: '#808080c7',
  },
  arrowIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    padding: '2px',
    '&:hover': {
      backgroundColor: '#F2F8F8',
    },
  },
});

export default styles;
