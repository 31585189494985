import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { withStyles } from '@material-ui/core';

import Form from './Form';
import styles from './styles';
import Box from '../../../components/atomic/Box';
import useDesktop from '../../../hooks/useDesktop';
import Headline from '../../../components/Headline';
import {
  MARKETINGEMAIL,
  PROPERTYVALUEESTIMATEUPDATES,
} from '../../../constants';
import { sGetUser } from '../../../store/reducers/auth';
import Page from '../../../components/layouts/WithHeader';
import useFormRequest from '../../../hooks/useFormRequest';
import Container from '../../../components/atomic/Container';
import { getUserFirstname, getUserLastname } from '../../../utils/auth';
import BoligmappaLogoText from '../../../assets/icons/BoligmappaLogoText';
import { createPropertyOwner as apiCreateUser } from '../../../api/propertyowners';

const SignUp = ({ user, classes }) => {
  const history = useHistory();
  const isDesktop = useDesktop();

  const { requestInProgress, requestError, onFormSubmit } = useFormRequest({
    onSuccess: () => history.push('/properties?refreshEnabled=true'),
    apiRequest: ({ email, phone, marketingConsent, valueEstimateConsent }) =>
      apiCreateUser({
        email,
        mobile: phone,
        firstName: getUserFirstname(user),
        lastName: getUserLastname(user),
        optionalconsents: [
          {
            type: MARKETINGEMAIL,
            isGranted: marketingConsent,
          },
          {
            type: PROPERTYVALUEESTIMATEUPDATES,
            isGranted: valueEstimateConsent,
          },
        ],
      }),
  });

  return (
    <Page
      hideHeader
      minFullHeight
      maxWidth={false}
      safePaddingEnabled
      className={clsx({
        [classes.root]: true,
        [classes.plantBackground]: requestInProgress || isDesktop,
      })}
    >
      <BoligmappaLogoText className={classes.logo} />

      <Box mt={6}>
        <Container maxWidth="xs">
          <Headline>
            {requestInProgress ? 'Et øyeblikk' : 'Velkommen!'}
          </Headline>

          <Form
            user={user}
            onSubmit={onFormSubmit}
            requestError={requestError}
            requestInProgress={requestInProgress}
          />
        </Container>
      </Box>

      <Box className={classes.floor} />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  user: sGetUser(state),
});

export default connect(mapStateToProps)(withStyles(styles)(SignUp));
