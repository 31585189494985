import React, { createElement } from 'react';
import { useParams } from 'react-router';

import useActionDialog from '../../../../../../hooks/useActionDialog';

import DeleteIcon from '../../../../../../assets/icons/Bin';
import DetailsIcon from '../../../../../../assets/icons/Info';
import List from '../../../../../../components/atomic/List';
import BottomContextMenu from '../../../../../../components/BottomContextMenu';
import MobileListItem from '../../../../../../components/BottomContextMenu/ListItem';

import {
  getDisplayName,
  getId,
  getStatus,
} from '../../../../../../utils/privateshare';

import { deletePrivateShare as apiDeletePrivateAccessData } from '../../../../../../api/properties';

const ContextMenu = ({
  open,
  setOpen,
  onDetailsClick,
  onDelete,
  onDeleteFail,
  record,
}) => {
  const { boligmappaNumber } = useParams();
  const query = `${getStatus(record)}Id=${getId(record)}`;
  const { showActionDialog, showLoadingDialog } = useActionDialog();
  const deletePopupMessage = (
    <>
      Er du sikker på at du vil fjerne {getDisplayName(record)} som
      administrator? Personen vil miste alle tilganger til din boligmappe.
    </>
  );
  const onDeleteClick = async () =>
    onDeleteFail
      ? showActionDialog({
          submitButtonText: 'Stopp deling',
          title: 'Fjern administrator',
          message: deletePopupMessage,
          submitButtonRed: true,
          onSubmit: async () => {
            const closeDialog = showLoadingDialog('Sletter...');

            const [error, response] = await apiDeletePrivateAccessData(
              boligmappaNumber,
              query
            );

            closeDialog();

            if (error && !response) {
              onDeleteFail();
            } else {
              onDelete();
            }
          },
        })
      : onDelete(record);

  const menuItems = [
    {
      icon: DetailsIcon,
      onClick: () => onDetailsClick(record),
      text: 'Detaljer',
    },
    {
      icon: DeleteIcon,
      onClick: () => onDeleteClick(),
      text: 'Fjern',
    },
  ];

  const renderMenuItem = ({ text, onClick, disabled, ...props }) =>
    // eslint-disable-next-line react/no-children-prop
    createElement(MobileListItem, {
      disabled,
      key: text,
      children: text,
      onClick: (event) => {
        if (disabled) {
          return;
        }

        if (onClick) {
          onClick(event);
        }

        setOpen(false);
      },
      ...props,
    });

  const renderMenuItems = () =>
    menuItems.filter(({ disabled }) => !disabled).map(renderMenuItem);

  const accessRequesterName =
    record &&
    record.requester &&
    `${record.requester.firstName} ${record.requester.lastName}`;

  return (
    <BottomContextMenu
      open={open}
      setOpen={setOpen}
      title={accessRequesterName || getDisplayName(record)}
      ModalProps={{
        style: {
          zIndex: 1202,
        },
      }}
    >
      <List>{renderMenuItems()}</List>
    </BottomContextMenu>
  );
};
export default ContextMenu;
