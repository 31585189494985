import React, { createElement } from 'react';
import { connect } from 'react-redux';

import BinIcon from '../../../../../../../assets/icons/Bin';
import Menu from '../../../../../../../components/atomic/Menu';
import List from '../../../../../../../components/atomic/List';
import useDesktop from '../../../../../../../hooks/useDesktop';
import ImageIcon from '../../../../../../../assets/icons/Image';
import PencilIcon from '../../../../../../../assets/icons/Pencil';
import useSnackbar from '../../../../../../../hooks/useSnackbar';
import useActionDialog from '../../../../../../../hooks/useActionDialog';
import { deleteRoom as apiDeleteRoom } from '../../../../../../../api/rooms';
import BottomContextMenu from '../../../../../../../components/BottomContextMenu';
import DesktopMenuItem from '../../../../../../../components/desktop/MenuListItem';
import { sGetActiveProperty } from '../../../../../../../store/reducers/properties';
import useDashboardLinkPrefix from '../../../../../../../hooks/useDashboardLinkPrefix';
import MobileMenutItem from '../../../../../../../components/BottomContextMenu/ListItem';
import { acDetachRoomFromDocuments } from '../../../../../../../store/actions/documents';
import { sGetDocuments } from '../../../../../../../store/reducers/documents';
import matomo from '../../../../../../../utils/matomo';

const RoomContextMenu = ({
  open,
  roomId,
  setOpen,
  anchorEl,
  roomName,
  onRoomDeleted,
  onEditRoom,
  documents,
  detachRoomFromDocuments,
}) => {
  const isDesktop = useDesktop();
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const { showErrorMessage, showSuccessMessage } = useSnackbar();
  const { showActionDialog, showLoadingDialog } = useActionDialog();

  const onDeleteRoomClick = () =>
    showActionDialog({
      submitButtonText: 'Slett',
      title: 'Vil du slette dette rommet?',
      message: `Dokumenter og hendelser du har koblet til
        dette rommet vil fortsatt være tilgjengelige,
        mens bildene du har lastet  opp blir slettet.
      `,
      onSubmit: async () => {
        const closeDialog = showLoadingDialog('Sletter...');

        const [error, response] = await apiDeleteRoom(roomId);

        closeDialog();

        if (error) {
          showErrorMessage(error);
        } else {
          showSuccessMessage('Rom slettet.');
          onRoomDeleted(response);
          if (documents) {
            detachRoomFromDocuments(roomId);
          }
        }
      },
    });

  const menuItems = [
    {
      icon: PencilIcon,
      text: 'Rediger innhold',
      onClick: () => onEditRoom(),
    },
    {
      icon: ImageIcon,
      text: 'Rediger bilder',
      to: `${dashboardLinkPrefix}/rooms/${roomId}/images`,
      onClick: () =>
        matomo.clickEvent({
          category: 'Rooms',
          action: 'Edit Room',
          name: 'Edit Room Images',
        }),
    },
    {
      icon: BinIcon,
      onClick: onDeleteRoomClick,
      text: 'Fjern',
    },
  ];

  const renderMenuItem = ({ text, onClick, ...props }) =>
    // eslint-disable-next-line react/no-children-prop
    createElement(isDesktop ? DesktopMenuItem : MobileMenutItem, {
      key: text,
      children: text,
      border: !isDesktop,
      onClick: (event) => {
        if (onClick) {
          onClick(event);
        }

        setOpen(false);
      },
      ...props,
    });

  if (isDesktop) {
    return (
      <Menu
        open={open}
        anchorEl={anchorEl}
        disableAutoFocusItem
        onClose={() => setOpen(false)}
      >
        {menuItems.map(renderMenuItem)}
      </Menu>
    );
  }

  return (
    <BottomContextMenu
      open={open}
      setOpen={setOpen}
      title={`Innstillinger for ${roomName || 'rom'}`}
    >
      <List>{menuItems.map(renderMenuItem)}</List>
    </BottomContextMenu>
  );
};

const mapStateToProps = (state) => ({
  activeProperty: sGetActiveProperty(state),
  documents: sGetDocuments(state),
});

const mapDispatchToProps = (dispatch) => ({
  detachRoomFromDocuments: (payload) =>
    dispatch(acDetachRoomFromDocuments(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomContextMenu);
