const styles = (theme) => ({
  backButtonContainer: {
    cursor: 'pointer',
  },
  backText: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  backIcon: {
    maxWidth: '18px',
    maxHeight: '13px',
    marginRight: '3px',
    color: theme.palette.primary.red,
  },
});

export default styles;
