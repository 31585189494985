const styles = (theme) => ({
  root: ({
    fontSize,
    lineHeight,
    fontWeight,
    paletteColor,
    marginTop = true,
  }) => ({
    fontSize,
    lineHeight,
    fontWeight,
    marginTop: marginTop === false ? 0 : undefined,
    color: theme.palette.primary[paletteColor] || undefined,
  }),
  strong: {
    fontWeight: 700,
  },
  small: {
    fontSize: '0.82rem',
  },
  noMargins: {
    marginTop: 0,
    marginBottom: 0,
  },
});

export default styles;
