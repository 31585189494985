import React from 'react';
import { Route, Switch } from 'react-router';

import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import { acSetAuthInfo } from '../../store/actions/auth';
import { fetchPropertyOwner } from '../../api/propertyowners';
import useFetchEffect from '../../hooks/useFetchEffect';

function PersonalAreaRoutes({ setAuthInfo }) {
  useFetchEffect({
    initialDataState: {},
    defaultDataState: {},
    initialFetchingState: true,
    apiFetchFunction: fetchPropertyOwner,
    onSuccess: (data) => setAuthInfo({ user: { ...data }, loaded: true }),
  });

  return (
    <Switch>
      <Route path="/">
        <Dashboard />
      </Route>
    </Switch>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setAuthInfo: (user) => dispatch(acSetAuthInfo(user)),
});

export default connect(null, mapDispatchToProps)(PersonalAreaRoutes);
