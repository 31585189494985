import React from 'react';
import Grid from '../../../../../../components/atomic/Grid';
import useDesktop from '../../../../../../hooks/useDesktop';
import { useSectionStyles } from '../sectionStyles';

export function OverviewGridItem({ children, showMaxwidth }) {
  const isDesktop = useDesktop();
  const classes = useSectionStyles();

  // If desktop and classes is not passed to props, return no class
  const className = isDesktop && classes ? classes.removePaddingBottom : '';
  return showMaxwidth ? (
    <Grid item md={12} lg={12} sm={12} xs={12} className={className}>
      {children}
    </Grid>
  ) : (
    <Grid item md={12} lg={6} sm={12} xs={12} className={className}>
      {children}
    </Grid>
  );
}
