const styles = (theme) => ({
  imageIcon: {
    width: '16px',
    height: '16px',
  },

  imageIconBoxTable: {
    minWidth: '32px',
    height: '32px',
    background: '#F2F8F8',
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  ItemContainer: {
    display: 'flex',
    justifyContent: 'flexStart',
    alignItems: 'center',
  },

  checkboxCell: {
    padding: '0!important',
    minWidth: '59px',
    width: '59px',
    borderBottom: 'unset',
  },

  uploadRow: {
    position: 'relative',
  },

  linearProgress: {
    position: 'absolute',
    width: 'calc(100% - 28px)',
    left: 0,
    bottom: 0,
    marginLeft: '14px',
    backgroundColor: '#D1EDE8',
    borderRadius: '4px',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#5DC9A2',
      borderRadius: '4px',
    },
  },

  tableRow: {
    paddingLeft: 0,
    borderBottom: 'unset',
  },

  ProgressContainer: {
    width: '100%',
    marginLeft: '20px',
  },
  fileDetailsText: {
    fontWeight: 600,
    color: '#717171',
  },
  errorProgress: {
    color: '#CD4646',
  },

  ProgressContainerMobile: {
    marginLeft: '10px',
    marginRight: '10px',
  },

  listItem: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    borderBottom: `1px solid ${theme.colors.whiteDarker}`,
    flexDirection: 'column',
    alignItems: 'center',
    display: 'block',
    paddingBottom: 0,
  },

  primaryText: {
    fontSize: '14px',
    color: '#13142A',
    textDecoration: 'none !important',
    display: 'flex',
    fontWeight: 'bold',
    gap: '1px',
  },

  imageIconBox: {
    minWidth: '32px',
    height: '32px',
    background: '#F2F8F8',
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },

  listItemSecondaryAction: {
    right: 0,
    transform: 'none',
    position: 'inherit',
    marginLeft: theme.spacing(1),
  },

  checkbox: {
    marginRight: 0,
  },

  divmobile: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
});

export default styles;
