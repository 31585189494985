import React from 'react';
import useDesktop from '../../hooks/useDesktop';
import Box from '../atomic/Box';
import Grid from '../atomic/Grid';
import ContentLoader from '../loaders/ContentLoader';

export const CompanyProfileLoading = ({ isLoading }) => {
  const isDesktop = useDesktop();
  return (
    <>
      {isDesktop
        ? isLoading && (
            <Box width="100%" height="574px">
              <Box mb={3}>
                <ContentLoader width="100%" height={300} />
              </Box>
              <Box mb={3}>
                <ContentLoader width={200} height={20} />
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={10}>
                  <Box mb={1}>
                    <ContentLoader width="100%" height={100} />
                  </Box>
                  <Box>
                    <ContentLoader width="100%" height={100} />
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box mb={1}>
                    <ContentLoader width="100%" height={210} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )
        : isLoading && (
            <Box width="100%" height="574px">
              <Box mb={3}>
                <ContentLoader width="100%" height={300} />
              </Box>
              <Box mb={3}>
                <ContentLoader width="100%" height={150} />
              </Box>
              <Box mb={3}>
                <ContentLoader width="100%" height={150} />
              </Box>
              <Box mb={3}>
                <ContentLoader width="100%" height={150} />
              </Box>
            </Box>
          )}
    </>
  );
};
