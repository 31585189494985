import React, { createElement } from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import DocumentItem from './DocumentItem';
import BaseTable from '../../../atomic/Table';
import TableRow from '../../../atomic/TableRow';
import SelectedFilters from '../SelectedFilters';
import TableHead from '../../../atomic/TableHead';
import TableBody from '../../../atomic/TableBody';
import TableCell from '../../../atomic/TableCell';
import Typography from '../../../atomic/Typography';
import TableHeaderSortFilter from './TableHeaderSortFilter';
import { getDocumentId, STATIC_COLUMN } from '../../../../utils/documents';
import { filterTypes, filterColumns } from '../../../DocumentFilters/util';

const Table = ({
  filters,
  classes,
  sortType,
  documents,
  searchQuery,
  actionsEnabled,
  onDocumentClick,
  onSortTypeChange,
  onFiltersChanged,
  filteredDocuments,
  showCompanyNameLinks,
  showRoomsColumn = false,
  showEventsColumn = false,
  showProfessionColumn = true,
  showUploadedDateColumn = true,
  showUploaderNameColumn = true,
}) => (
  <BaseTable className={classes.root}>
    <TableHead>
      <TableRow>
        {filterTypes
          .filter((filterType) =>
            filterColumns({
              filterType,
              showRoomsColumn,
              showEventsColumn,
              showProfessionColumn,
              showUploadedDateColumn,
              showUploaderNameColumn,
            })
          )
          .map(
            ({
              type,
              name,
              getProps,
              description,
              desktopComponent,
              getFilterOptions,
            }) =>
              createElement(desktopComponent, {
                name,
                description,
                clickable: type !== STATIC_COLUMN,
                key: `filter-option-${type}`,
                onFiltersReset: () =>
                  onFiltersChanged({
                    ...filters,
                    [type]: [],
                  }),
                filterOptions:
                  getFilterOptions &&
                  getFilterOptions(documents, filters[type], (updatedFilters) =>
                    onFiltersChanged({
                      ...filters,
                      [type]: updatedFilters,
                    })
                  ),
                ...(getProps ? getProps(filters, onFiltersChanged) : undefined),
              })
          )}

        <TableHeaderSortFilter
          sortType={sortType}
          onSortTypeChange={onSortTypeChange}
        />
      </TableRow>

      <TableRow>
        <TableCell padding="none" colSpan={filterTypes.length + 1}>
          <SelectedFilters
            filters={filters}
            borderBottom={false}
            onFiltersChanged={onFiltersChanged}
          />
        </TableCell>
      </TableRow>
    </TableHead>

    <TableBody>
      {Array.isArray(filteredDocuments) && filteredDocuments.length === 0 && (
        <TableRow>
          <TableCell colSpan={filterTypes.length + 1}>
            <Typography align="center" color="textSecondary">
              Ingen dokument funnet
            </Typography>
          </TableCell>
        </TableRow>
      )}

      {Array.isArray(documents) &&
        filteredDocuments.length > 0 &&
        filteredDocuments.map((document) => (
          <DocumentItem
            document={document}
            searchQuery={searchQuery}
            actionsEnabled={actionsEnabled}
            onDocumentClick={onDocumentClick}
            showRoomsColumn={showRoomsColumn}
            showEventsColumn={showEventsColumn}
            showCompanyNameLink={showCompanyNameLinks}
            showProfessionColumn={showProfessionColumn}
            showUploadedDateColumn={showUploadedDateColumn}
            showUploaderNameColumn={showUploaderNameColumn}
            key={`document-item-${getDocumentId(document)}`}
          />
        ))}
    </TableBody>
  </BaseTable>
);

export default withStyles(styles)(Table);
