import clsx from 'clsx';
import { withStyles, Slide } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';

import styles from './style';
import Box from '../atomic/Box';
import useDesktop from '../../hooks/useDesktop';
import HeaderSnackbarContext from '../../contexts/headerSnackbar';

const HeaderSnackbarProvider = ({ classes, children }) => {
  const containerRef = useRef();
  const isDesktop = useDesktop();

  const [snackbar, setSnackbar] = useState(null);
  const [snackbarHeight, setSnackbarHeight] = useState(0);

  const closeSnackbar = () => {
    if (snackbar && snackbar.onClose) {
      snackbar.onClose();
    }

    setSnackbar(null);
  };

  const showSnackbar = ({ variant = 'regular', ...config }) =>
    setSnackbar((currentSnackbar) => currentSnackbar || { variant, ...config });

  const exposedApi = {
    showSnackbar,
    closeSnackbar,
    snackbarHeight,
  };

  useEffect(() => {
    setTimeout(() => {
      setSnackbarHeight(
        containerRef &&
          containerRef.current &&
          containerRef.current.clientHeight !== undefined
          ? containerRef.current.clientHeight
          : 0
      );
    }, 0);
  });

  return (
    <HeaderSnackbarContext.Provider value={exposedApi}>
      <Box className={classes.root} ref={containerRef}>
        {snackbar && (
          <Slide direction="down" in mountOnEnter unmountOnExit timeout={1500}>
            <Box
              py={1}
              px={2}
              display="flex"
              alignItems="center"
              className={clsx(classes[snackbar.variant], snackbar.className)}
            >
              <Box
                flexGrow={1}
                className={classes.content}
                textAlign={isDesktop ? 'center' : 'left'}
              >
                {snackbar.content}
              </Box>

              <Box pl={1} display="flex">
                {snackbar.closeButton}
              </Box>
            </Box>
          </Slide>
        )}
      </Box>

      {children}
    </HeaderSnackbarContext.Provider>
  );
};

export default withStyles(styles)(HeaderSnackbarProvider);
