import React from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';

import useDesktop from '@hooksV2/useDesktop';
import BoligmappaPlusLoyalty from '@componentsV2/BoligmappaPlusLoyalty';
import styles from './style';
import { getPropertyOwnerSubscriptionStatus } from '../../../../utils/properties';
import { boligmappaPlusActionCallback } from '../../../../utils/boligmappaPlus';
import matomo from '../../../../utils/matomo';
import { sGetUser } from '../../../../store/reducers/auth';
import WithHeader from '../../../../components/layouts/WithHeader';
import useSnackbar from '../../../../hooks/useSnackbar';

const BoligmappaPlusLoyaltyPage = ({ classes, authUser }) => {
  const isDesktop = useDesktop();
  const { showErrorMessage } = useSnackbar();

  const handleActionClick = async (type) => {
    matomo.clickEvent({
      category: 'Boligmappa+',
      action: 'Pay with Vipps',
    });
    await boligmappaPlusActionCallback(type, showErrorMessage);
  };

  const isBoligmappaPlusSub = getPropertyOwnerSubscriptionStatus(authUser);

  return (
    <WithHeader headerTitle="Mine fordeler" noPadding>
      <div className={classes.page}>
        <BoligmappaPlusLoyalty
          isDesktop={isDesktop}
          isBoligmappaPlusSub={isBoligmappaPlusSub}
          onActionClick={handleActionClick}
        />
      </div>
    </WithHeader>
  );
};

const mapStateToProps = (state) => ({
  authUser: sGetUser(state),
});

export default connect(mapStateToProps)(
  withStyles(styles)(BoligmappaPlusLoyaltyPage)
);
