const styles = (theme) => ({
  title: {
    display: 'block',
    fontSize: '12px',
    margin: '10px 0 0',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

export default styles;
