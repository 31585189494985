import { borderVariables } from '@style/vars/borders';
import { colorVariables } from '@style/vars/colors';
import { fontVariables } from '@style/vars/fonts';
import { sizeVariables } from '@style/vars/sizes';
import {
  bodySizeVariables,
  linkSizeVariables,
  titleSizeVariables,
} from '@style/vars/typography';

/**
 * This object defines CSS variable names that can be set in a new theme.
 * The `Theme` type is based on this object, so this is kinda like the
 * 'singe source of truth' for the creation of new themes themes.
 */
export const cssVariables = {
  borders: {
    ...borderVariables,
  },
  sizes: {
    ...sizeVariables,
  },
  colors: {
    ...colorVariables,
  },
  typography: {
    body: {
      ...bodySizeVariables,
    },
    link: {
      ...linkSizeVariables,
    },
    title: {
      ...titleSizeVariables,
    },
    general: {
      ...fontVariables,
    },
  },
};
