import {
  InputTransformType,
  getInputTransformer,
} from '@components/utils/input/inputHelpers';
import React, { useRef } from 'react';

/**
 * This implements the given input transform function, and preserves the cursor position in the field
 * "as best as we can"
 */
export const useInputTransform = (inputTransform: InputTransformType) => {
  const prevValue = useRef<string>('');
  const inputTransformFunction = getInputTransformer(inputTransform);

  const onInputTransform = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (inputTransformFunction) {
      let position = e.target.selectionStart;
      const preLength = e.target.value.length;
      inputTransformFunction(e);
      const transformedLength = e.target.value.length;
      if (transformedLength !== prevValue.current.length) {
        const diff = e.target.value.length - preLength;
        position += diff;
      }
      e.target.setSelectionRange(position, position);
      prevValue.current = e.target.value;
    }
  };

  return { onInputTransform };
};
