import {
  SET_RIGHTS,
  CLEAR_RIGHTS,
  SET_FETCHING_RIGHTS,
  SET_FETCHING_RIGHTS_ERROR,
} from '../reducers/rights';

export const acSetRights = (rights) => ({
  type: SET_RIGHTS,
  payload: rights,
});

export const acClearRights = () => ({
  type: CLEAR_RIGHTS,
});

export const acSetFetchingRights = (fetching) => ({
  type: SET_FETCHING_RIGHTS,
  payload: fetching,
});

export const acSetFetchingRightsError = (error) => ({
  type: SET_FETCHING_RIGHTS_ERROR,
  payload: error,
});
