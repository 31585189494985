const styles = {
  root: {
    position: 'relative',
  },
  closeButton: {
    right: 0,
    zIndex: 1,
    position: 'absolute',
  },
};

export default styles;
