import React from 'react';

import { getRightName } from '../../utils/rights';
import NewCardList from '../../components/NewCardList';
import { getCommitmentName } from '../../utils/commitments';
import Box from '../../components/atomic/Box';
import InfoIconTooltip from '../../components/InfoIconWithTooltip';

const PropertyRightsCommitments = ({
  link,
  rights,
  commitments,
  fetchingRights,
  fetchingCommitments,
  classes,
  ...props
}) => {
  const items = [
    {
      label: 'Rettigheter',
      value:
        Array.isArray(rights) && rights.length > 0
          ? getRightName(rights[0])
          : 'Ingen Rettigheter registrert',
    },
    {
      label: 'Servitutter',
      value:
        Array.isArray(commitments) && commitments.length > 0
          ? getCommitmentName(commitments[0])
          : 'Ingen servitutter registrert',
    },
  ];

  const title = (
    <>
      Rettigheter og servitutter
      <InfoIconTooltip placement="bottom" classes={classes}>
        Opplysning om tinglyste rettigheter og servitutter hentes fra
        Kartverket. Boligmappa kan ikke endre denne informasjonen, ved feil må
        du kontakte din kommune, som vil sørge for at informasjonen i Kartverket
        oppdateres.
      </InfoIconTooltip>
    </>
  );

  return (
    <Box style={{ height: '91%' }}>
      <NewCardList
        isFinance={false}
        link={
          (Array.isArray(rights) && rights.length > 0) ||
          (Array.isArray(commitments) && commitments.length > 0)
            ? link
            : null
        }
        items={items}
        title={title}
        fetching={fetchingRights || fetchingCommitments}
        {...props}
      />
    </Box>
  );
};

export default PropertyRightsCommitments;
