import moment from 'moment';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import {
  getDocumentType,
  getDocumentName,
  getDocumentRooms,
  getDocumentEvents,
  getDocumentOwnedByName,
  getDocumentCreatedDate,
  getDocumentId,
} from '../../../utils/documents';

import {
  fetchRooms as apiFetchRooms,
  fetchEvents as apiFetchEvents,
} from '../../../api/properties';

import {
  acSetEvents,
  acSetFetchingEvents,
} from '../../../store/actions/events';

import styles from './style';
import Form from '../BaseForm';
import documentTypes from './documentTypes';
import { prepareDocumentData } from './util';
import Autocomplete from '../../Autocomplete';
import TextField from '../../atomic/TextField';
import { parseQuery } from '../../../utils/routes';
import { getUserFullname } from '../../../utils/auth';
import ConnectRoomButton from '../../ConnectRoomButton';
import { sGetUser } from '../../../store/reducers/auth';
import ConnectEventButton from '../../ConnectEventButton';
import { sGetRooms, sGetShowAddRoom } from '../../../store/reducers/rooms';
import useFetchEffect from '../../../hooks/useFetchEffect';
import { sGetEvents, sGetShowAddEvent } from '../../../store/reducers/events';
import { getRoomId, getRoomName } from '../../../utils/rooms';
import { compareAlphabetically } from '../../../utils/strings';
import { getEventId, getEventName } from '../../../utils/events';
import { acSetFetchingRooms, acSetRooms } from '../../../store/actions/rooms';
import matomo from '../../../utils/matomo';

const DocumentForm = ({
  rooms,
  events,
  classes,
  editMode,
  setRooms,
  document,
  onCancel,
  fetching,
  onSubmit,
  authUser,
  setEvents,
  className,
  boligmappaNumber,
  formRequestError,
  setFetchingRooms,
  setFetchingEvents,
  requestInProgress,
  focusOnRoom = false,
  focusOnEvent = false,
  onConnectNewRoomClick,
  onConnectNewEventClick,
  attachRoomMode = false,
  attachEventMode = false,
  cancelButtonText = 'Avbryt',
  submitButtonText = 'Gå videre',
  showAddEvent,
  showAddRoom,
}) => {
  const { search } = useLocation();
  const parsedQuery = parseQuery(search);
  const { roomIds: queryRoomIds, eventIds: queryEventIds } = parsedQuery || {};

  const [title, setTitle] = useState('');
  const [roomIds, setRoomIds] = useState([]);
  const [eventIds, setEventIds] = useState([]);
  const [documentType, setDocumentType] = useState(null);

  // TODO handle fetching error
  const { fetching: fetchingRooms } = useFetchEffect({
    initialFetchingState: !rooms,
    onSuccess: (data) => setRooms(data),
    setFetchingFunction: setFetchingRooms,
    apiFetchFunction: () => apiFetchRooms(boligmappaNumber),
  });

  // TODO handle fetching error
  const { fetching: fetchingEvents } = useFetchEffect({
    initialFetchingState: !events,
    onSuccess: (data) => setEvents(data),
    setFetchingFunction: setFetchingEvents,
    apiFetchFunction: () => apiFetchEvents(boligmappaNumber),
  });

  const roomOptions = Array.isArray(rooms)
    ? rooms
        .sort((a, b) => compareAlphabetically(getRoomName(a), getRoomName(b)))
        .map((room) => ({
          value: getRoomId(room),
          label: getRoomName(room),
        }))
    : [];

  const eventOptions = Array.isArray(events)
    ? events
        .sort((a, b) => compareAlphabetically(getEventName(a), getEventName(b)))
        .map((event) => ({
          value: getEventId(event),
          label: getEventName(event),
        }))
    : [];

  const documentTypeOptions = documentTypes
    .sort((a, b) => compareAlphabetically(a.name, b.name))
    .map((staticDocumentType) => ({
      value: staticDocumentType.id,
      label: staticDocumentType.name,
    }));

  const onChangeRooms = (options) => {
    setRoomIds(options || []);
  };

  const onChangeEvents = (options) => {
    matomo.clickEvent({
      category: 'Document View',
      name: 'Connect Event from Edit',
      action: 'Connect Document to Event',
    });
    setEventIds(options || []);
  };

  const onCancelClick = (event) => (onCancel ? onCancel(event) : null);

  const onFormSubmit = () => {
    if (attachEventMode) {
      matomo.clickEvent({
        category: 'Document View',
        name: 'Connect Event from view',
        action: 'Connect Document to Event',
      });
    }

    if (attachRoomMode) {
      matomo.clickEvent({
        category: 'Document View',
        name: 'Connect Room from view',
        action: 'Connect Document to Room',
      });
    }
    onSubmit(
      prepareDocumentData({
        rooms,
        events,
        title,
        roomIds,
        eventIds,
        documentType,
        updatedDate: moment().format(),
        createdDate: getDocumentCreatedDate(document) || moment().format(),
        ownedByName: editMode
          ? getDocumentOwnedByName(document)
          : getUserFullname(authUser),
      })
    );
  };

  useEffect(() => {
    const attachedEvents = [];
    if (events?.length > 0) {
      events.forEach((event) => {
        event.documents.forEach((doc) => {
          if (doc.id === getDocumentId(document)) {
            attachedEvents.push(event);
          }
        });
      });
    }

    const attachedEventOptions = attachedEvents.map((item) => ({
      value: item.id,
      label: item.title,
    }));

    if (attachedEventOptions.length > 0) {
      onChangeEvents(attachedEventOptions.concat(eventIds));
    }
  }, [showAddEvent]);

  useEffect(() => {
    const attachedRooms = [];
    if (rooms?.length > 0) {
      rooms.forEach((room) => {
        room.documents.forEach((doc) => {
          if (doc.id === getDocumentId(document)) {
            attachedRooms.push(room);
          }
        });
      });
    }

    const attachedRoomOptions = attachedRooms.map((item) => ({
      value: item.id,
      label: item.title,
    }));

    onChangeRooms(attachedRoomOptions);
  }, [showAddRoom]);

  const renderFormContent = () => {
    const roomSelectField = (
      <Autocomplete
        isMulti
        value={roomIds}
        options={roomOptions}
        label="Koble til rom"
        autoFocus={focusOnRoom}
        isLoading={fetchingRooms}
        closeMenuOnSelect={false}
        disabled={requestInProgress}
        className={classes.formElement}
        loadingMessage={() => 'Laster...'}
        noOptionsMessage={() => 'Ingen rom'}
        placeholder="Velg ett eller flere rom"
        onChange={(options) => onChangeRooms(options)}
      />
    );

    const eventSelectField = (
      <Autocomplete
        isMulti
        value={eventIds}
        options={eventOptions}
        autoFocus={focusOnEvent}
        closeMenuOnSelect={false}
        isLoading={fetchingEvents}
        label="Koble til hendelse"
        disabled={requestInProgress}
        className={classes.formElement}
        loadingMessage={() => 'Laster...'}
        noOptionsMessage={() => 'Ingen hendelser'}
        placeholder="Velg en eller flere hendelser"
        onChange={(options) => onChangeEvents(options)}
      />
    );

    if (attachEventMode) {
      return (
        <>
          {eventSelectField}

          <ConnectEventButton
            newEventIcon
            color="primary"
            textColor="primary"
            onClick={onConnectNewEventClick}
          >
            Opprett ny hendelse
          </ConnectEventButton>
        </>
      );
    }

    if (attachRoomMode) {
      return (
        <>
          {roomSelectField}

          <ConnectRoomButton
            newRoomIcon
            color="primary"
            textColor="primary"
            onClick={onConnectNewRoomClick}
          >
            Opprett nytt rom
          </ConnectRoomButton>
        </>
      );
    }

    return (
      <>
        <TextField
          required
          value={title}
          label="Navn"
          className={classes.formElement}
          onChange={(e) => setTitle(e.target.value)}
          disabled={fetching || requestInProgress}
        />

        <Autocomplete
          value={documentType}
          label="Dokumenttype"
          options={documentTypeOptions}
          className={classes.formElement}
          placeholder="Velg dokumenttype"
          disabled={fetching || requestInProgress}
          onChange={(option) => setDocumentType(option)}
        />

        {Array.isArray(rooms) && rooms.length > 0 && roomSelectField}

        {Array.isArray(events) && events.length > 0 && eventSelectField}
      </>
    );
  };

  useEffect(() => {
    if (document) {
      // form controls should not have null values, thus fallback ''
      setTitle(getDocumentName(document) || '');

      const staticDocumentType = documentTypes.find(
        ({ name }) => name === getDocumentType(document)
      );

      if (staticDocumentType) {
        setDocumentType({
          value: staticDocumentType.id,
          label: staticDocumentType.name,
        });
      } else {
        setDocumentType(null);
      }

      setRoomIds([
        ...(rooms || [])
          .filter(
            (room) =>
              Array.isArray(queryRoomIds) &&
              queryRoomIds.includes(String(getRoomId(room)))
          )
          .map((room) => ({
            value: getRoomId(room),
            label: getRoomName(room),
          })),
        ...getDocumentRooms(document).map((room) => ({
          value: getRoomId(room),
          label: getRoomName(room),
        })),
      ]);

      setEventIds([
        ...(events || [])
          .filter(
            (event) =>
              Array.isArray(queryEventIds) &&
              queryEventIds.includes(String(getEventId(event)))
          )
          .map((event) => ({
            value: getEventId(event),
            label: getEventName(event),
          })),
        ...getDocumentEvents(document).map((event) => ({
          value: getEventId(event),
          label: getEventName(event),
        })),
      ]);
    }
  }, [document]);

  useEffect(() => {
    if (document && Array.isArray(rooms) && Array.isArray(queryRoomIds)) {
      const alreadyAssignedRoomIds = roomIds.map(({ value }) => String(value));

      setRoomIds([
        ...rooms
          .filter(
            (room) =>
              queryRoomIds.includes(String(getRoomId(room))) &&
              !alreadyAssignedRoomIds.includes(String(getRoomId(room)))
          )
          .map((room) => ({
            value: getRoomId(room),
            label: getRoomName(room),
          })),
        ...roomIds,
      ]);
    }
  }, [document, rooms]);

  useEffect(() => {
    if (document && Array.isArray(events) && Array.isArray(queryEventIds)) {
      const alreadyAssignedEventIds = eventIds.map(({ value }) =>
        String(value)
      );

      setEventIds([
        ...events
          .filter(
            (event) =>
              queryEventIds.includes(String(getEventId(event))) &&
              !alreadyAssignedEventIds.includes(String(getEventId(event)))
          )
          .map((event) => ({
            value: getEventId(event),
            label: getEventName(event),
          })),
        ...eventIds,
      ]);
    }
  }, [document, events]);

  return (
    <Form
      fetching={fetching}
      className={className}
      onSubmit={onFormSubmit}
      error={formRequestError}
      onCancel={onCancelClick}
      submitButtonText={submitButtonText}
      cancelButtonText={cancelButtonText}
      requestInProgress={requestInProgress}
    >
      {renderFormContent()}
    </Form>
  );
};

const mapStateToProps = (state) => ({
  rooms: sGetRooms(state),
  events: sGetEvents(state),
  authUser: sGetUser(state),
  showAddEvent: sGetShowAddEvent(state),
  showAddRoom: sGetShowAddRoom(state),
});

const mapDispatchToProps = (dispatch) => ({
  setRooms: (rooms) => dispatch(acSetRooms(rooms)),
  setEvents: (events) => dispatch(acSetEvents(events)),
  setFetchingRooms: (fetching) => dispatch(acSetFetchingRooms(fetching)),
  setFetchingEvents: (fetching) => dispatch(acSetFetchingEvents(fetching)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DocumentForm));
