const styles = (theme) => ({
  menuList: {
    padding: '15px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuListMobile: {
    padding: '15px 0px 15px 0px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  multimenuListMobile: {
    padding: '15px 0px 15px 0px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  multiIconMenuList: {
    padding: '15px 0px 15px 0px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  menuItem: {
    fontSize: '14px',
    color: 'inherit',
    cursor: 'pointer',
    textDecoration: 'none',

    '&:last-child': {
      marginRight: 0,
    },
  },
  menuItemforLargeText: {
    fontSize: '14px',
    color: 'inherit',
    cursor: 'pointer',
    textDecoration: 'none',
    boxSizing: 'border-box',
    flexBasis: '25%',

    '&:last-child': {
      marginRight: 0,
    },
  },
  menuItemText: {
    marginTop: '10px',
    textAlign: 'center',
  },
  menuItemTextMobile: {
    marginTop: '10px',
    textAlign: 'center',
    width: 'min-Content',
  },
  iconContainer: {
    width: '60px',
    height: '60px',
    margin: '0 auto',
    textAlign: 'center',
    borderRadius: '30px',
    background: theme.palette.primary.red,
  },
  icon: {
    top: '15px',
    width: '30px',
    height: '30px',
    position: 'relative',
    fill: theme.palette.primary.white,
    color: theme.palette.primary.white,
  },
});

export default styles;
