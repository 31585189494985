import React, { forwardRef } from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ControlArrow from './ControlArrow';

const Carousel = forwardRef((props, ref) => {
  const config = {
    speed: 50,
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: false,
    nextArrow: <ControlArrow />,
    prevArrow: <ControlArrow previous />,
    ref,
    ...props,
  };

  return <Slider {...config} />;
});

export default Carousel;
