const styles = (theme) => ({
  divider: ({ align = 'center' }) => ({
    height: '3px',
    width: '45px',
    marginTop: theme.spacing(2),
    background: theme.palette.primary.red,
    marginLeft: align === 'center' ? 'auto' : undefined,
    marginRight: align === 'center' ? 'auto' : undefined,
  }),
});

export default styles;
