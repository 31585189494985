const styles = (theme) => ({
  roomProgress: {
    border: `1px solid ${theme.palette.primary.greyLight}`,
    borderRadius: '5px',
    marginBottom: '0.7rem',
  },
  roomHeader: {
    display: 'flex',
    fontWeight: 400,
    fontSize: '16px',
  },
  roomType: {
    paddingLeft: '0.5rem',
  },
  checkCircle: {
    width: '20px',
    color: theme.palette.primary.green,
  },
  removeCircle: {
    width: '20px',
    color: theme.palette.primary.orange,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
  },
  listItem: {
    display: 'flex',
    paddingLeft: '0.5rem',
  },
  step: {
    fontSize: '13px',
    paddingTop: '0.3rem',
    paddingLeft: '0.5rem',
  },
  listCheckCircle: {
    width: '18px',
    color: theme.palette.primary.green,
  },
  listRemoveCircle: {
    width: '18px',
    color: theme.palette.primary.orange,
  },
});

export default styles;
