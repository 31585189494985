import includes from 'lodash-es/includes';
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';

import Box from '../atomic/Box';
import Link from '../atomic/Link';
import Button from '../atomic/Button';
import { logInfo } from '../../utils/loggers';
import useHeaderSnackbar from '../../hooks/useHeaderSnackbar';
import { retrieveCookieConsent, storeCookieConsent } from '../../utils/cookie';

// Set the pathname here,
// if needs exclude from showing CookieConsent Dialog
const excludePaths = ['/signup'];

const CookieConsentDialog = () => {
  const location = useLocation();
  const currentPathname = location.pathname;
  const { showSnackbar, closeSnackbar } = useHeaderSnackbar();

  const onCloseHandler = () => {
    logInfo('User click on close button in Cookie consent Dialog');
  };

  const onAcceptHandler = () => {
    logInfo('User click on Ok button in Cookie consent Dialog');
    closeSnackbar();
  };

  const Dialog = () => (
    <>
      <small>
        Vi benytter cookies for å gi deg den beste brukeropplevelsen.&nbsp;
        <Link
          isExternal
          openInNewTab
          style={{ textDecoration: 'underline' }}
          href="https://www.boligmappa.no/slik-bruker-vi-informasjonskapsler"
        >
          Les mer
        </Link>
      </small>

      <Box ml={1} display="inline">
        <Button
          size="small"
          color="primary"
          variant="contained"
          style={{ padding: 0 }}
          onClick={onAcceptHandler}
        >
          OK
        </Button>
      </Box>
    </>
  );

  useEffect(() => {
    const cookieConsent = retrieveCookieConsent();
    const cookieQuestionAnswered = cookieConsent !== undefined;
    const noShowCookieConsent = includes(excludePaths, currentPathname);

    if (!noShowCookieConsent && !cookieQuestionAnswered) {
      showSnackbar({
        content: <Dialog />,
        onClose: onCloseHandler,
      });

      storeCookieConsent(true);
    }
  }, []);

  return null;
};

export default CookieConsentDialog;
