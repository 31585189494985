import { backendBaseUrl } from '../config';
import { get, post, request } from '../utils/http';

export const fetchCompany = (id) =>
  get(`companies/${id}`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const lookupCompanies = (params) =>
  request({
    params,
    method: 'get',
    url: 'searchcompany',
    baseURL: backendBaseUrl,
  })
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const requestMissingDocuments = (boligmappaNumber, data) =>
  post(`properties/${boligmappaNumber}/missingdocuments`, data)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const companySearch = (
  name,
  location,
  searchDistance,
  page,
  sort,
  professions,
  order,
  franchisers,
  certificates
) =>
  get(
    `/companies?nameFilter=${name}&locationFilter=${location}&searchDistance=${searchDistance}&page=${page}&sort=${sort}&sortOrder=${order}&professionFilter=${professions}&franchiserNameFilter=${franchisers}&certificateFilter=${certificates}`
  )
    .then((response) => [null, response.data])
    .catch((error) => [error, null]);

export const professionTypes = () =>
  get(`/companies/professionTypes`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const companyCertificates = () =>
  get(`/companies/certificateTypes`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);
