export const mostCreatedRoomTypes = [
  'SOVEROM',
  'BAD',
  'STUE',
  'GANG/ENTRE',
  'KJOKKEN',
  'BOD',
];
export const RoomTypePropertyType = [
  {
    propertyType: 'Default',
    floorsEnable: true,
    roomTypes: [
      {
        displayName: 'Soverom',
        code: 'SOVEROM',
        currentCount: 0,
        typeId: 7,
      },
      {
        displayName: 'Bad',
        code: 'BAD',
        currentCount: 0,
        typeId: 2,
      },
      {
        displayName: 'Stue',
        code: 'STUE',
        currentCount: 0,
        typeId: 6,
      },
      {
        displayName: 'Gang/Entre',
        code: 'GANG/ENTRE',
        currentCount: 0,
        typeId: 8,
      },
      {
        displayName: 'Kjøkken',
        code: 'KJOKKEN',
        currentCount: 0,
        typeId: 5,
      },
      {
        displayName: 'Bod',
        code: 'BOD',
        currentCount: 0,
        typeId: 46,
      },
      {
        displayName: 'Balkong/Terrasse e.l.',
        code: 'BALKONG/TERRASSE',
        currentCount: 0,
        typeId: 30,
      },
      {
        displayName: 'Toalett-ikke-bad',
        code: 'TOALETTE_IKKE_BAD',
        currentCount: 0,
        typeId: 4,
      },
      {
        displayName: 'Kontor',
        code: 'KONTOR',
        currentCount: 0,
        typeId: 14,
      },
      {
        displayName: 'Teknisk anlegg',
        code: 'TEKNISK_ANLEGG',
        currentCount: 0,
        typeId: 40,
      },
      {
        displayName: 'Hele eiendommen',
        code: 'HELE_EIENDOMMEN',
        currentCount: 0,
        typeId: 0,
      },
    ],
  },
  {
    propertyType: 'APPARTEMENT',
    floorsEnable: false,
    roomTypes: [
      {
        displayName: 'Soverom',
        code: 'SOVEROM',
        currentCount: 0,
        typeId: 7,
      },
      {
        displayName: 'Bad',
        code: 'BAD',
        currentCount: 0,
        typeId: 2,
      },
      {
        displayName: 'Stue',
        code: 'STUE',
        currentCount: 0,
        typeId: 6,
      },
      {
        displayName: 'Gang/Entre',
        code: 'GANG/ENTRE',
        currentCount: 0,
        typeId: 8,
      },
      {
        displayName: 'Kjøkken',
        code: 'KJOKKEN',
        currentCount: 0,
        typeId: 5,
      },
      {
        displayName: 'Bod',
        code: 'BOD',
        currentCount: 0,
        typeId: 46,
      },
      {
        displayName: 'Balkong/Terrasse e.l.',
        code: 'BALKONG/TERRASSE',
        currentCount: 0,
        typeId: 30,
      },
      {
        displayName: 'Toalett-ikke-bad',
        code: 'TOALETTE_IKKE_BAD',
        currentCount: 0,
        typeId: 4,
      },
      {
        displayName: 'Kontor',
        code: 'KONTOR',
        currentCount: 0,
        typeId: 14,
      },
      {
        displayName: 'Teknisk anlegg',
        code: 'TEKNISK_ANLEGG',
        currentCount: 0,
        typeId: 40,
      },
      {
        displayName: 'Hele eiendommen',
        code: 'HELE_EIENDOMMEN',
        currentCount: 0,
        typeId: 0,
      },
    ],
  },
  {
    propertyType: 'STORT FRITTLIGGENDE BOLIGBYGG PÅ 5 ETG. EL. MER',
    floorsEnable: false,
    roomTypes: [
      {
        displayName: 'Soverom',
        code: 'SOVEROM',
        currentCount: 0,
        typeId: 7,
      },
      {
        displayName: 'Bad',
        code: 'BAD',
        currentCount: 0,
        typeId: 2,
      },
      {
        displayName: 'Stue',
        code: 'STUE',
        currentCount: 0,
        typeId: 6,
      },
      {
        displayName: 'Gang/Entre',
        code: 'GANG/ENTRE',
        currentCount: 0,
        typeId: 8,
      },
      {
        displayName: 'Kjøkken',
        code: 'KJOKKEN',
        currentCount: 0,
        typeId: 5,
      },
      {
        displayName: 'Bod',
        code: 'BOD',
        currentCount: 0,
        typeId: 46,
      },
      {
        displayName: 'Balkong/Terrasse e.l.',
        code: 'BALKONG/TERRASSE',
        currentCount: 0,
        typeId: 30,
      },
      {
        displayName: 'Toalett-ikke-bad',
        code: 'TOALETTE_IKKE_BAD',
        currentCount: 0,
        typeId: 4,
      },
      {
        displayName: 'Kontor',
        code: 'KONTOR',
        currentCount: 0,
        typeId: 14,
      },
      {
        displayName: 'Teknisk anlegg',
        code: 'TEKNISK_ANLEGG',
        currentCount: 0,
        typeId: 40,
      },
      {
        displayName: 'Hele eiendommen',
        code: 'HELE_EIENDOMMEN',
        currentCount: 0,
        typeId: 0,
      },
    ],
  },
  {
    propertyType: 'STORT FRITTLIGGENDE BOLIGBYGG PÅ 2 ETG.',
    floorsEnable: false,
    roomTypes: [
      {
        displayName: 'Soverom',
        code: 'SOVEROM',
        currentCount: 0,
        typeId: 7,
      },
      {
        displayName: 'Bad',
        code: 'BAD',
        currentCount: 0,
        typeId: 2,
      },
      {
        displayName: 'Stue',
        code: 'STUE',
        currentCount: 0,
        typeId: 6,
      },
      {
        displayName: 'Gang/Entre',
        code: 'GANG/ENTRE',
        currentCount: 0,
        typeId: 8,
      },
      {
        displayName: 'Kjøkken',
        code: 'KJOKKEN',
        currentCount: 0,
        typeId: 5,
      },
      {
        displayName: 'Bod',
        code: 'BOD',
        currentCount: 0,
        typeId: 46,
      },
      {
        displayName: 'Balkong/Terrasse e.l.',
        code: 'BALKONG/TERRASSE',
        currentCount: 0,
        typeId: 30,
      },
      {
        displayName: 'Toalett-ikke-bad',
        code: 'TOALETTE_IKKE_BAD',
        currentCount: 0,
        typeId: 4,
      },
      {
        displayName: 'Kontor',
        code: 'KONTOR',
        currentCount: 0,
        typeId: 14,
      },
      {
        displayName: 'Teknisk anlegg',
        code: 'TEKNISK_ANLEGG',
        currentCount: 0,
        typeId: 40,
      },
      {
        displayName: 'Hele eiendommen',
        code: 'HELE_EIENDOMMEN',
        currentCount: 0,
        typeId: 0,
      },
    ],
  },
  {
    propertyType: 'STORE SAMMENBYGDE BOLIGBYGG PÅ 2 ETG.',
    floorsEnable: false,
    roomTypes: [
      {
        displayName: 'Soverom',
        code: 'SOVEROM',
        currentCount: 0,
        typeId: 7,
      },
      {
        displayName: 'Bad',
        code: 'BAD',
        currentCount: 0,
        typeId: 2,
      },
      {
        displayName: 'Stue',
        code: 'STUE',
        currentCount: 0,
        typeId: 6,
      },
      {
        displayName: 'Gang/Entre',
        code: 'GANG/ENTRE',
        currentCount: 0,
        typeId: 8,
      },
      {
        displayName: 'Kjøkken',
        code: 'KJOKKEN',
        currentCount: 0,
        typeId: 5,
      },
      {
        displayName: 'Bod',
        code: 'BOD',
        currentCount: 0,
        typeId: 46,
      },
      {
        displayName: 'Balkong/Terrasse e.l.',
        code: 'BALKONG/TERRASSE',
        currentCount: 0,
        typeId: 30,
      },
      {
        displayName: 'Toalett-ikke-bad',
        code: 'TOALETTE_IKKE_BAD',
        currentCount: 0,
        typeId: 4,
      },
      {
        displayName: 'Kontor',
        code: 'KONTOR',
        currentCount: 0,
        typeId: 14,
      },
      {
        displayName: 'Teknisk anlegg',
        code: 'TEKNISK_ANLEGG',
        currentCount: 0,
        typeId: 40,
      },
      {
        displayName: 'Hele eiendommen',
        code: 'HELE_EIENDOMMEN',
        currentCount: 0,
        typeId: 0,
      },
    ],
  },
  {
    propertyType: 'STORE SAMMENB. BOLIGBYGG PÅ 5 ETG. EL. MER',
    floorsEnable: false,
    roomTypes: [
      {
        displayName: 'Soverom',
        code: 'SOVEROM',
        currentCount: 0,
        typeId: 7,
      },
      {
        displayName: 'Bad',
        code: 'BAD',
        currentCount: 0,
        typeId: 2,
      },
      {
        displayName: 'Stue',
        code: 'STUE',
        currentCount: 0,
        typeId: 6,
      },
      {
        displayName: 'Gang/Entre',
        code: 'GANG/ENTRE',
        currentCount: 0,
        typeId: 8,
      },
      {
        displayName: 'Kjøkken',
        code: 'KJOKKEN',
        currentCount: 0,
        typeId: 5,
      },
      {
        displayName: 'Bod',
        code: 'BOD',
        currentCount: 0,
        typeId: 46,
      },
      {
        displayName: 'Balkong/Terrasse e.l.',
        code: 'BALKONG/TERRASSE',
        currentCount: 0,
        typeId: 30,
      },
      {
        displayName: 'Toalett-ikke-bad',
        code: 'TOALETTE_IKKE_BAD',
        currentCount: 0,
        typeId: 4,
      },
      {
        displayName: 'Kontor',
        code: 'KONTOR',
        currentCount: 0,
        typeId: 14,
      },
      {
        displayName: 'Teknisk anlegg',
        code: 'TEKNISK_ANLEGG',
        currentCount: 0,
        typeId: 40,
      },
      {
        displayName: 'Hele eiendommen',
        code: 'HELE_EIENDOMMEN',
        currentCount: 0,
        typeId: 0,
      },
    ],
  },
  {
    propertyType: 'STORE SAMMENB. BOLIGBYGG PÅ 3 OG 4 ETG.',
    floorsEnable: false,
    roomTypes: [
      {
        displayName: 'Soverom',
        code: 'SOVEROM',
        currentCount: 0,
        typeId: 7,
      },
      {
        displayName: 'Bad',
        code: 'BAD',
        currentCount: 0,
        typeId: 2,
      },
      {
        displayName: 'Stue',
        code: 'STUE',
        currentCount: 0,
        typeId: 6,
      },
      {
        displayName: 'Gang/Entre',
        code: 'GANG/ENTRE',
        currentCount: 0,
        typeId: 8,
      },
      {
        displayName: 'Kjøkken',
        code: 'KJOKKEN',
        currentCount: 0,
        typeId: 5,
      },
      {
        displayName: 'Bod',
        code: 'BOD',
        currentCount: 0,
        typeId: 46,
      },
      {
        displayName: 'Balkong/Terrasse e.l.',
        code: 'BALKONG/TERRASSE',
        currentCount: 0,
        typeId: 30,
      },
      {
        displayName: 'Toalett-ikke-bad',
        code: 'TOALETTE_IKKE_BAD',
        currentCount: 0,
        typeId: 4,
      },
      {
        displayName: 'Kontor',
        code: 'KONTOR',
        currentCount: 0,
        typeId: 14,
      },
      {
        displayName: 'Teknisk anlegg',
        code: 'TEKNISK_ANLEGG',
        currentCount: 0,
        typeId: 40,
      },
      {
        displayName: 'Hele eiendommen',
        code: 'HELE_EIENDOMMEN',
        currentCount: 0,
        typeId: 0,
      },
    ],
  },
  {
    propertyType: 'STORT FRITTLIGGENDE BOLIGBYGG PÅ 3 OG 4 ETG.',
    floorsEnable: false,
    roomTypes: [
      {
        displayName: 'Soverom',
        code: 'SOVEROM',
        currentCount: 0,
        typeId: 7,
      },
      {
        displayName: 'Bad',
        code: 'BAD',
        currentCount: 0,
        typeId: 2,
      },
      {
        displayName: 'Stue',
        code: 'STUE',
        currentCount: 0,
        typeId: 6,
      },
      {
        displayName: 'Gang/Entre',
        code: 'GANG/ENTRE',
        currentCount: 0,
        typeId: 8,
      },
      {
        displayName: 'Kjøkken',
        code: 'KJOKKEN',
        currentCount: 0,
        typeId: 5,
      },
      {
        displayName: 'Bod',
        code: 'BOD',
        currentCount: 0,
        typeId: 46,
      },
      {
        displayName: 'Balkong/Terrasse e.l.',
        code: 'BALKONG/TERRASSE',
        currentCount: 0,
        typeId: 30,
      },
      {
        displayName: 'Toalett-ikke-bad',
        code: 'TOALETTE_IKKE_BAD',
        currentCount: 0,
        typeId: 4,
      },
      {
        displayName: 'Kontor',
        code: 'KONTOR',
        currentCount: 0,
        typeId: 14,
      },
      {
        displayName: 'Teknisk anlegg',
        code: 'TEKNISK_ANLEGG',
        currentCount: 0,
        typeId: 40,
      },
      {
        displayName: 'Hele eiendommen',
        code: 'HELE_EIENDOMMEN',
        currentCount: 0,
        typeId: 0,
      },
    ],
  },
];
