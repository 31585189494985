import * as EventEmitter from 'events';

export const eventEmitter = new EventEmitter.EventEmitter();

export const UNAUTHENTICATED_RESPONSE_RECEIVED =
  'UNAUTHENTICATED_RESPONSE_RECEIVED';

export const TOKENS_UPDATED = 'TOKENS_UPDATED';

export const IOS_TOKENS_REFRESH_INPROGRESS = 'IOS_TOKENS_REFRESH_INPROGRESS';
export const IOS_TOKENS_REFRESH_FINISHED = 'IOS_TOKENS_REFRESH_FINISHED';
