const styles = {
  card: {
    width: '652px',
    padding: '10px 10px 25px 58px ',
    boxShadow: '2px 5px 15px rgba(0, 0, 0, 0.15)',
    '@media only screen and (max-width: 768px)': {
      padding: '8px ',
      boxShadow: '0 3px 3px rgb(0 0 0 / 0.15)',
    },
  },
  imageItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifySelf: 'end',
    justifyContent: 'end',
    '@media only screen and (max-width: 768px)': {
      display: 'none',
    },
  },
  image: {
    width: '114px',
    height: '89px',
  },
  title: {
    fontWeight: '400',
    fontSize: '24px',
    marginBottom: '10px',
    '@media only screen and (max-width: 768px)': {
      fontSize: '16px',
    },
  },
  button: {
    borderRadius: '20px',
    padding: '6px 25px',
    '@media only screen and (max-width: 768px)': {
      fontSize: '13px',
      width: '195px',
      marginTop: '20px',
    },
  },
  textArea: {
    fontWeight: '400',
    '@media only screen and (max-width: 768px)': {
      fontSize: '13px',
      fontWeight: '400',
    },
  },
  boxClass: {
    display: 'flex',
    justifyContent: 'center',
    margin: '30px 0 20px 0',
    '@media only screen and (min-width: 1024px)': {
      margin: '124px 0 20px 0',
    },
  },
};
export default styles;
