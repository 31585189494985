import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

import styles from './styles';
import Box from '../atomic/Box';
import useDesktop from '../../hooks/useDesktop';

const DialogScrollBox = ({ classes, children, padding = false, props }) => {
  const isDesktop = useDesktop();

  return (
    <Box
      overflow="auto"
      height="100%"
      className={clsx(
        classes.overflowScroll,
        isDesktop && classes.dialogDesktop,
        isDesktop && padding && classes.dialogPadding,
        !isDesktop && padding && classes.dialogPaddingMobile
      )}
      {...props}
    >
      {children}
    </Box>
  );
};

export default withStyles(styles)(DialogScrollBox);
