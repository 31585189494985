import React from 'react';

const LockForDocView = ({ width = '1.2rem', height = '1.2rem', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 21.827"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#13142A"
      d="M9.375 5.75c.305 0 .563.117.797.328.21.234.328.492.328.797v4.5c0 .328-.117.586-.328.797a1.062 1.062 0 0 1-.797.328h-8.25c-.328 0-.586-.094-.797-.328C.094 11.96 0 11.703 0 11.375v-4.5c0-.305.094-.563.328-.797.211-.21.469-.328.797-.328h.563V4.062c0-.632.14-1.218.468-1.78.328-.54.75-.962 1.313-1.29A3.34 3.34 0 0 1 5.25.5a3.46 3.46 0 0 1 1.781.492c.54.328.961.75 1.29 1.29a3.46 3.46 0 0 1 .492 1.78V5.75h.562Zm-2.438 0V4.062c0-.468-.164-.867-.492-1.195a1.627 1.627 0 0 0-1.195-.492c-.469 0-.867.164-1.195.492a1.627 1.627 0 0 0-.492 1.196V5.75h3.374Z"
    />
  </svg>
);

export default LockForDocView;
