import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import CirclesLoader from '../loaders/CirclesLoader';

const LoadingScreen = ({ classes }) => (
  <div className={classes.container}>
    <CirclesLoader className={classes.loader} />
  </div>
);

export default withStyles(styles)(LoadingScreen);
