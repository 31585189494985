import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import Lottie from 'react-lottie';
import Box from '../atomic/Box';
import Typography from '../atomic/Typography';
import TextArea from '../TextArea';
import styles from './style';

import useDesktop from '../../hooks/useDesktop';
import Drawer from '../atomic/Drawer';
import Close from '../../assets/icons/Close';
import Dialog from '../atomic/Dialog';
import DialogTitle from '../DialogTitle';
import DialogContent from '../atomic/DialogContent';
import DialogActions from '../atomic/DialogActions';
import Button from '../atomic/Button';
import successAnimation from '../../assets/animations/successAnimation.json';
import { createInquiry } from '../../api/propertyowners';
import useSnackbar from '../../hooks/useSnackbar';

const GeneralInquiryForm = ({
  companyname,
  companyemail,
  boligmappaNumber,
  organizationnnmber,
  onClose = () => {},
  classes,
  open = false,
}) => {
  const [description, setDescription] = useState(null);
  const [title, setTitle] = useState(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const descriptionMaxLength = 1500;
  const isDesktop = useDesktop();
  const { showErrorMessage } = useSnackbar();

  const animationOptions = {
    loop: false,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    setTitle('Generell hendvendelse');
  });

  const handleClose = () => {
    onClose(!open);
    setShowAnimation(false);
    setDescription(null);
  };

  const handleSubmit = async () => {
    const inquiry = {
      boligmappaNumber,
      requestEmail: companyemail,
      description,
      organizationNo: organizationnnmber,
    };
    const [error, response] = await createInquiry(inquiry);
    if (error && !response) {
      showErrorMessage(
        'Det oppsto en feil under opprettelsen av forespørselen'
      );
    } else {
      setTitle(isDesktop ? 'Du har bedt om dokumentasjon' : '');
      setShowAnimation(true);
      setTimeout(() => {
        handleClose();
      }, 2000);
    }
  };

  const renderContent = () => (
    <>
      {showAnimation ? (
        <>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop={!isDesktop && '50%'}
            justifyContent="center"
          >
            <Lottie
              options={animationOptions}
              height={150}
              width={150}
              isStopped={!showAnimation}
            />
            <Typography
              className={isDesktop && classes.companyName}
              color={!isDesktop ? 'primary' : ''}
              variant={!isDesktop ? 'h2' : ''}
            >
              Henvendelse sendt
            </Typography>
          </Box>
          <br />
        </>
      ) : (
        <Box p={!isDesktop ? 2 : 0}>
          <Typography className={classes.textTop}>
            Du er i ferd med å kontakte{' '}
            <span className={classes.companyName}>{companyname}</span>
          </Typography>
          <br />
          <Typography
            className={isDesktop ? classes.companyName : classes.textTop}
          >
            hvorfor kontakter du?
          </Typography>
          <TextArea
            counterLeft
            value={description}
            maxLength={descriptionMaxLength}
            placeholder="Skriv henvendelsen..."
            onChange={(e) => setDescription(e.target.value)}
          />

          <Typography className={classes.textTop}>
            <span className={classes.companyName}>NB! </span>Vi kan ikke
            garantere at håndverkeren svarer, men vi tar kontakt og prøver.
          </Typography>

          <br />
        </Box>
      )}
    </>
  );

  return (
    <>
      {isDesktop || (!isDesktop && showAnimation) ? (
        <Dialog
          open={open}
          classes={{ paper: classes.dialogPaper }}
          fullScreen={!isDesktop}
        >
          {!showAnimation && (
            <DialogTitle
              title={title}
              onClose={handleClose}
              noBorder
              noGutters
              h2Varient
              className={classes.dialogTitle}
              showTitle={!showAnimation}
            />
          )}
          <DialogContent className={isDesktop && classes.dialogContainer}>
            {renderContent()}
          </DialogContent>
          {!showAnimation && (
            <DialogActions className={classes.dialogActins}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleSubmit()}
                disabled={!description}
              >
                Send henvendelse
              </Button>
            </DialogActions>
          )}
        </Dialog>
      ) : (
        <Drawer
          anchor="bottom"
          open={open}
          classes={{
            paper: classes.mobileDrawer,
          }}
        >
          <Box
            p={2}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <h3>{title}</h3>
            <Close onClick={() => handleClose()} />
          </Box>
          {renderContent()}
          <DialogActions className={classes.dialogActins}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleSubmit()}
              disabled={!description}
            >
              Gå videre
            </Button>
          </DialogActions>
        </Drawer>
      )}
    </>
  );
};

export default withStyles(styles)(GeneralInquiryForm);
