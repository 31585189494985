import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

import styles from './styles';
import Typography from '../atomic/Typography';
import BaseDialogTitle from '../atomic/DialogTitle';
import Toolbar from '../atomic/Toolbar';
import IconButton from '../atomic/IconButton';
import Close from '../../assets/icons/Close';
import useDesktop from '../../hooks/useDesktop';

const DialogTitle = ({
  classes,
  title,
  onClose,
  noBorder,
  h2Varient,
  showTitle = true,
}) => {
  const isDesktop = useDesktop();

  const closeButton = (
    <IconButton size="small" color="inherit" onClick={onClose}>
      <Close />
    </IconButton>
  );

  const header = (
    <Typography
      variant={h2Varient ? 'h2' : 'h3'}
      component={h2Varient ? 'h2' : 'h3'}
      color="inherit"
      className={clsx(
        isDesktop ? classes.dialogTitle_txt : classes.dialogTitle_txt_mobile
      )}
    >
      <b>{typeof title === 'string' ? title : title()}</b>
    </Typography>
  );

  return (
    <BaseDialogTitle
      className={noBorder ? classes.BorderlessDialogTitle : classes.dialogTitle}
    >
      <Toolbar>
        {isDesktop ? (
          <>
            {header}
            {closeButton}
          </>
        ) : (
          <>
            {closeButton}
            {showTitle && header}
          </>
        )}
      </Toolbar>
    </BaseDialogTitle>
  );
};

export default withStyles(styles)(DialogTitle);
