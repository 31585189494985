import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { logoutRequest, isNativeEnv } from '../../../utils/nativeBridge';

import { acLogout } from '../../../store/actions/auth';
import { kcLogout } from '../../../api/auth';
import { retrieveRefreshToken } from '../../../utils/auth';
import sessionStorage from '../../../utils/sessionStorage';
import { LOCAL_API_HEALTH_STATUS } from '../../../constants';

export const Logout = ({ logout }) => {
  const history = useHistory();

  const logoutAndRedirect = async () => {
    const refreshToken = retrieveRefreshToken();

    const callback = () => {
      logout();

      // TODO use redux middleware to chain async tasks
      setTimeout(() => {
        if (isNativeEnv()) {
          logoutRequest();
        } else {
          sessionStorage.remove(LOCAL_API_HEALTH_STATUS);
          history.push('/login', { from: 'ATOMATED_LOGOUT' });
        }
      }, 0);
    };

    if (refreshToken) {
      await kcLogout();
      callback();
    } else {
      callback();
    }
  };

  useEffect(() => {
    logoutAndRedirect();
  }, []);

  return <div />;
};

Logout.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(acLogout()),
});

export default connect(null, mapDispatchToProps)(Logout);
