import sortBy from 'lodash-es/sortBy';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';

import {
  updateEvent as apiUpdateEvent,
  uploadImage as apiUploadImage,
} from '../../../../../../api/events';

import { getRoomId } from '../../../../../../utils/rooms';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import ImageForm from '../../../../../../components/forms/ImageForm';
import PageLayout from '../../../../../../components/layouts/WithHeader';
import ErrorContainer from '../../../../../../components/ErrorContainer';
import { getEventImages, getEventRooms } from '../../../../../../utils/events';

const Images = ({ event, fetching, fetchingError, onEventUpdated }) => {
  const history = useHistory();
  const { eventId } = useParams();
  const { showSuccessMessage } = useSnackbar();
  const [requestError, setRequestError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const byPosition = ({ position }) => position;
  const sortedImages = sortBy(getEventImages(event) || [], byPosition);

  const onSubmit = async (images) => {
    setRequestInProgress(true);

    const imageIds = images.map(({ id }) => id);
    const [error, response] = await apiUpdateEvent(eventId, { imageIds });

    if (error && !response) {
      setRequestError(error);
    } else {
      onEventUpdated({
        ...event,
        gallery: images,
        roomIds: (getEventRooms(event) || []).map(getRoomId),
      });

      showSuccessMessage();
      history.goBack();
    }

    setRequestInProgress(false);
  };

  const onImageUploadRequest = (image, position) =>
    apiUploadImage(eventId, image, position);

  const onImagesUploaded = (uploadedImages) =>
    onEventUpdated({
      ...event,
      gallery: [...(getEventImages(event) || []), ...uploadedImages],
    });

  return (
    <PageLayout minFullHeight headerTitle="Bilder">
      {fetchingError ? (
        <ErrorContainer errorResponse={fetchingError} />
      ) : (
        <ImageForm
          onSubmit={onSubmit}
          fetching={fetching}
          images={sortedImages}
          formError={requestError}
          onImagesUploaded={onImagesUploaded}
          requestInProgress={requestInProgress}
          uploadImageRequest={onImageUploadRequest}
        />
      )}
    </PageLayout>
  );
};

export default Images;
