import React from 'react';
import { withStyles } from '@material-ui/core';
import InputField from '@vb/components/build/components/forms/inputs/InputField/InputField';
import MenuItem from '../atomic/MenuItem';
import AddFolder from '../../assets/icons/AddFolder';
import Divider from '../atomic/Divider';
import AddFile from '../../assets/icons/AddFile';
import UploadFolder from '../../assets/icons/UploadFolder';
import Menu from '../atomic/Menu';
import styles from './style';

const AddNewDocumentMenu = ({
  isAddingNewDoc,
  setIsAddingNewDoc,
  addNewDocRef,
  isCreatingName,
  setIsCreatingName,
  newFolderName,
  setNewFolderName,
  isFolderNameError,
  folderNameErrorMessage,
  onDocumentUpload,
  onFolderUpload,
  onFolderCreate,
  onCancelCreatingFolder,
  classes,
}) => {
  return (
    <>
      <Menu
        PopoverClasses={{ paper: classes.paper }}
        MenuListProps={{ className: classes.menuList }}
        open={isAddingNewDoc}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ horizontal: 'right' }}
        anchorEl={addNewDocRef.current}
        disableAutoFocusItem
        onClose={() => setIsAddingNewDoc(false)}
      >
        <MenuItem className={classes.menuItem}>
          <button
            type="button"
            className={classes.menuItemButton}
            onClick={() => setIsCreatingName(true)}
          >
            <AddFolder className={classes.menuSvg} />
            <p>Ny mappe</p>
          </button>
        </MenuItem>
        <Divider className={classes.menuDivider} />
        <MenuItem className={classes.menuItem}>
          <button
            type="button"
            className={classes.menuItemButton}
            onClick={onDocumentUpload}
          >
            <AddFile className={classes.menuSvg} />
            <p>Last opp fil</p>
          </button>
        </MenuItem>
        <MenuItem className={classes.menuItem}>
          <button
            type="button"
            className={classes.menuItemButton}
            onClick={onFolderUpload}
          >
            <UploadFolder className={classes.menuSvg} />
            <p>Last opp mappe</p>
          </button>
        </MenuItem>
      </Menu>

      <Menu
        open={isCreatingName}
        onClose={() => setIsCreatingName(false)}
        anchorEl={addNewDocRef.current}
        PopoverClasses={{ root: classes.createFolderPopover }}
        MenuListProps={{ className: classes.createNameMenuList }}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <InputField
          labelClassName={classes.createFolderLabel}
          inputClassName={classes.createFolderInput}
          type="text"
          label="Opprett mappe:"
          placeholder="Skriv inn mappenavn"
          onChange={(event) => setNewFolderName(event.target.value)}
          value={newFolderName}
        />
        {isFolderNameError && (
          <p className={classes.errorMessage}>{folderNameErrorMessage}</p>
        )}
        <div className={classes.createFolderButtons}>
          <button type="button" onClick={onFolderCreate}>
            Opprett
          </button>
          <button
            className="cancel_button"
            type="button"
            onClick={onCancelCreatingFolder}
          >
            Avbryt
          </button>
        </div>
      </Menu>
    </>
  );
};

export default withStyles(styles)(AddNewDocumentMenu);
