import React from 'react';

const ArrowDown = ({ width = '1.2rem', height = '1.2rem', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 10.74"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      transform="translate(-566.251 -8651.022)"
      d="M585.833,8651.417a1.49,1.49,0,0,0-2.021,0l-7.36,6.911a.292.292,0,0,1-.4,0l-7.359-6.911a1.487,1.487,0,0,0-2.018,0,1.284,1.284,0,0,0,0,1.9l8.572,8.063a1.511,1.511,0,0,0,2.021,0l8.572-8.062a1.283,1.283,0,0,0,0-1.9Z"
    />
  </svg>
);

export default ArrowDown;
