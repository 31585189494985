/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Card from '../../../../../components/Card';
import { OverviewGridItem, PriceHistoryLoading } from './components';
import { useActiveProperty } from '../../../../../store/hooks';
import { useFetchPriceStats } from '../../../../../api/hooks/useFetchPriceStats';
import { getValueEstimatePerSqm } from '../../../../../utils/properties';
import PricePerSquareMeterView from '../../../../../modules/PricePerSquareMeterView';
import { useSectionStyles } from './sectionStyles';
import useDesktop from '../../../../../hooks/useDesktop';

function PricePerSquareMeterSection({ hideValues }) {
  const { property } = useActiveProperty();
  const classes = useSectionStyles();
  const priceStatData = useFetchPriceStats(property);
  const valueEstimatePerSqm = getValueEstimatePerSqm(property);
  const bogusData = {
    data: {
      sqmPricePercentile75: 0,
      area: '-',
      sqmPricePercentile25: 0,
      unitType: '-',
      averageSQMPrice: 0,
      openMarketSalesForSixMonths: 0,
      medianPrice: 0,
      priceDistributionSQM: [
        {
          from: 0,
          to: 1,
          count: 1,
        },
        {
          from: 0,
          to: 1,
          count: 1,
        },
      ],
    },
    isLoading: false,
  };
  const isDesktop = useDesktop();

  return (
    <>
      {(priceStatData.isLoading || priceStatData.data) && (
        <OverviewGridItem>
          <Card
            border={isDesktop ? 1 : 0}
            height="100%"
            className={classes.chartBlock}
          >
            <PriceHistoryLoading isLoading={priceStatData.isLoading} />
            <PricePerSquareMeterView
              priceStatData={hideValues ? bogusData : priceStatData}
              valueEstimatePerSqm={!hideValues && valueEstimatePerSqm}
            />
          </Card>
        </OverviewGridItem>
      )}
    </>
  );
}

export default PricePerSquareMeterSection;
