import React from 'react';

const ArrowDownFilter = ({
  width = '1.2rem',
  height = '1.2rem',
  fill = '#111',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    {...props}
  >
    <path
      fill={fill}
      d="M.848 0C.602 0 .438.137.355.355c-.109.22-.054.41.11.575l3.527 3.527c.11.11.219.164.383.164a.54.54 0 0 0 .383-.164L8.285.93c.164-.164.192-.356.11-.575C8.285.137 8.12 0 7.902 0H.848Z"
    />
  </svg>
);

export default ArrowDownFilter;
