import React from 'react';

const BoligmappaLogo = (props) => (
  <svg viewBox="0 0 37 33" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M33 15.3252H13C11.6 15.3252 10.4 16.1252 9.80002 17.4252L3.70002 28.1252C2.80002 30.4252 4.40002 32.9252 6.90002 32.9252H26.9C28.3 32.9252 29.5 32.1252 30.1 30.8252L36.2 20.1252C37.1 17.8252 35.5 15.3252 33 15.3252Z"
      fill="#EF4923"
      fillRule="evenodd"
    />
    <path
      d="M6.9 14.425C7.6 12.925 9.1 11.925 10.8 11.925H29.6C30.3 11.925 30.6 11.025 30.2 10.525L19.4 1.125C17.7 -0.375 14.9 -0.375 13.1 1.125L1.3 11.525C0.5 12.225 0 13.125 0 13.925V23.825C0 24.625 1.1 24.925 1.5 24.125L6.9 14.325V14.425Z"
      fill="#EF4923"
      fillRule="evenodd"
    />
  </svg>
);

export default BoligmappaLogo;
