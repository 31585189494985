import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import useOutsideAlerter from '@hooksV2/useOutsideAlerter';

import { TERMS_OF_PURCHASE } from '../../../constants';
import {
  StyledCTA,
  StyledCTAButton,
  StyledLabelCTA,
  StyledOffer,
} from './styles';
import VippsIcon from '../../../assets/icons/VippsIcon';
import useDashboardLinkPrefix from '../../../hooks/useDashboardLinkPrefix';

const CallToAction = ({
  className,
  isDesktop = false,
  ctaHeadline = 'Prøv Boligmappa+',
  defaultAnnual,
  extraHeaderContent,
  collapsible = true,
  headDescription,
  learnMoreLink,
  onActionClick,
  annualOffer,
  isCollapsed,
  buttonColor,
  bottomDescription = 'Lær mer om Boligmappa+',
}) => {
  const [collapsed, setCollapsed] = useState(
    isCollapsed !== undefined ? isCollapsed : !isDesktop
  );
  const [selectedOption, setSelectedOption] = useState(
    defaultAnnual ? 'year' : 'month'
  );

  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const onCTAclose = () => {
    if (!isDesktop && collapsible) {
      setCollapsed(true);
    }
  };

  const ctaRef = useRef(null);
  useOutsideAlerter(ctaRef, onCTAclose);
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const isActive = (radioValue) => radioValue === selectedOption;
  const handleLabelKeyDown = (e, val) => {
    if (e.key === 'Enter') {
      setSelectedOption(val);
    }
  };
  useEffect(() => {
    if (isCollapsed === undefined) {
      setCollapsed(!isDesktop);
    }
  }, [isDesktop]);
  return (
    <StyledCTA
      className={clsx([className, collapsed && 'collapsed'])}
      ref={ctaRef}
    >
      {extraHeaderContent && extraHeaderContent}
      <div className={clsx(['collapsed_wrapper', !collapsed && 'hide'])}>
        <div className="text">
          <h2 className="cta_headline">Prøv Boligmappa+</h2>
          <p>Få mer ut av Boligmappa</p>
        </div>
        <button type="button" onClick={() => setCollapsed(false)}>
          Prøv i dag!
        </button>
      </div>
      <div className={clsx(['content', collapsed && 'hide'])}>
        <h2 className="cta_headline">{ctaHeadline}</h2>
        {headDescription && <p className="head_desc">{headDescription}</p>}
        <div className="offerContent">
          <div className="inputsWrapper">
            <StyledLabelCTA
              className={clsx([isActive('month') && 'active'])}
              onKeyDown={(e) => handleLabelKeyDown(e, 'month')}
              tabIndex="0"
            >
              <div className="input_content">
                <div className="inputBlock">
                  <input
                    id="radioMonth"
                    type="radio"
                    name="ctaRadioGroup"
                    value="month"
                    checked={selectedOption === 'month'}
                    onChange={handleRadioChange}
                    tabIndex="-1"
                  />
                  <p>Måned</p>
                </div>
                <div className="price">
                  <p>kr 49</p>
                  <span>/mnd</span>
                </div>
              </div>
            </StyledLabelCTA>
            <StyledLabelCTA
              className={clsx([isActive('year') && 'active'])}
              onKeyDown={(e) => handleLabelKeyDown(e, 'year')}
              tabIndex="0"
            >
              <div className="input_content">
                <div className="inputBlock">
                  <input
                    id="radioYear"
                    type="radio"
                    name="ctaRadioGroup"
                    value="year"
                    checked={selectedOption === 'year'}
                    onChange={handleRadioChange}
                    tabIndex="-1"
                  />
                  <p>År</p>
                </div>
                <div className="price">
                  <p>kr 199</p>
                  <span>/år</span>
                </div>
              </div>
              {annualOffer && <StyledOffer>Tilbud!</StyledOffer>}
            </StyledLabelCTA>
          </div>
          <p className="terms_text">
            Du kan si opp når du selv ønsker. <br />
            Ved å betale bekrefter du{' '}
            <a
              href={TERMS_OF_PURCHASE}
              target="_blank"
              rel="noreferrer"
              className="terms_link"
            >
              vilkår for kjøp
            </a>
            .
          </p>
        </div>
        <StyledCTAButton
          type="button"
          className="cta_button"
          tabIndex="0"
          $buttonColor={buttonColor}
          onClick={() => onActionClick(selectedOption)}
        >
          Betal med
          <VippsIcon />
        </StyledCTAButton>
        {learnMoreLink && (
          <Link
            to={`${dashboardLinkPrefix}/boligmappa-plus`}
            className="learn_more"
          >
            {bottomDescription}
          </Link>
        )}
      </div>
    </StyledCTA>
  );
};

export default CallToAction;
