import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

import Box from '../../../../atomic/Box';
import TableCell from '../../../../atomic/TableCell';
import ArrowDownFilter from '../../../../../assets/icons/ArrowDownFilter';
import ArrowSortUp from '../../../../../assets/icons/ArrowSortUp';
import ArrowSortDown from '../../../../../assets/icons/ArrowSortDown';
import MultiSelectComboBox from '../../../../MultiSelectComboBox';
import useMenuState from '../../../../../hooks/useMenuState';
import matomo from '../../../../../utils/matomo';
import { getSortType } from '../../../../../utils/documents';

import styles from './style';

const TableHeaderCell = ({
  name,
  classes,
  sortable,
  customAlign,
  sortType,
  filterable,
  sortUpType,
  sortDownType,
  filterOptions,
  onFiltersReset,
  onSortTypeChange,
  sortingDirection,
  childrenComponent,
  setSortingDirection,
  isVisible = true,
  ...props
}) => {
  const handleSort = (sType) => {
    setSortingDirection(sType);
    onSortTypeChange(sType);

    matomo.clickEvent({
      category: 'Document handling',
      name: `Sort by ${getSortType({ SortType: sType })}`,
      action: 'Sort Document List',
    });
  };
  const { onMenuOpen, onMenuClose, onOptionMenuClose, open, anchorEl } =
    useMenuState();
  const getTitleBox = (titleName, handleOnMenuOpen, isFilterable) => {
    return (
      <Box
        onClick={isFilterable ? handleOnMenuOpen : null}
        display="flex"
        alignItems="top"
        className={isFilterable ? classes.headerBox : classes.nonHoverHeaderBox}
        style={customAlign && customAlign}
      >
        <span>{titleName}</span>
        {isFilterable && (
          <Box>
            <ArrowDownFilter className={classes.arrow} />
          </Box>
        )}
      </Box>
    );
  };
  const getSortingBox = (sTypeId, sUpType, sDownType, sDirection) => {
    return (
      <Box
        pt="5px"
        onClick={() =>
          handleSort(sDirection === sDownType ? sUpType : sDownType)
        }
        className={classes.arrowIcon}
      >
        {(() => {
          if (sTypeId !== sUpType && sTypeId !== sDownType) {
            return (
              <ArrowSortDown
                className={clsx(
                  classes.sortingArrow,
                  classes.sortingDefaultArrow
                )}
              />
            );
          }
          if (sDirection === sUpType) {
            return <ArrowSortUp className={classes.sortingArrow} />;
          }
          return <ArrowSortDown className={classes.sortingArrow} />;
        })()}
      </Box>
    );
  };
  return isVisible ? (
    <>
      <TableCell className={classes.root} {...props}>
        <Box display="flex" alignItems="top">
          {getTitleBox(name, onMenuOpen, filterable)}
          {sortable &&
            getSortingBox(
              sortType.id,
              sortUpType,
              sortDownType,
              sortingDirection
            )}
          {childrenComponent &&
            getTitleBox(
              childrenComponent.name,
              onMenuOpen,
              childrenComponent.filterable
            )}
          {childrenComponent &&
            childrenComponent.sortable &&
            getSortingBox(
              sortType.id,
              childrenComponent.sortUpType,
              childrenComponent.sortDownType,
              sortingDirection
            )}
        </Box>
      </TableCell>
      <MultiSelectComboBox
        anchorEl={anchorEl}
        isFiltering
        menuOpen={open}
        onMenuClose={onOptionMenuClose}
        onFiltersReset={onFiltersReset}
        filterOptions={filterOptions}
        onMenuSave={onMenuClose}
      />
    </>
  ) : null;
};

export default withStyles(styles)(TableHeaderCell);
