import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import React, { createElement, useEffect } from 'react';

import {
  getDocumentId,
  getDocumentName,
  getDocumentRooms,
  getDocumentEvents,
  isDocumentReadOnly,
  getDocumentCompanyId,
  getOriginalDocumentPath,
} from '../../utils/documents';

import {
  fetchDocument as apiFetchDocument,
  deleteDocument as apiDeleteDocument,
} from '../../api/documents';

import styles from './style';
import List from '../atomic/List';
import Menu from '../atomic/Menu';
import { getRoomId } from '../../utils/rooms';
import EditIcon from '../../assets/icons/Edit';
import useDesktop from '../../hooks/useDesktop';
import DeleteIcon from '../../assets/icons/Bin';
import { getEventId } from '../../utils/events';
import useSnackbar from '../../hooks/useSnackbar';
import PersonIcon from '../../assets/icons/Person';
import { stringifyQuery } from '../../utils/routes';
import BottomContextMenu from '../BottomContextMenu';
import DesktopListItem from '../desktop/MenuListItem';
import DownloadIcon from '../../assets/icons/Download';
import { sGetRooms } from '../../store/reducers/rooms';
import { sGetEvents } from '../../store/reducers/events';
import useActionDialog from '../../hooks/useActionDialog';
import MobileListItem from '../BottomContextMenu/ListItem';
import { sGetDocuments } from '../../store/reducers/documents';
import { acUpdateDocument } from '../../store/actions/documents';
import { acSyncRoomsDocuments } from '../../store/actions/rooms';
import { acSyncEventsDocuments } from '../../store/actions/events';
import ConnectRoomIcon from '../../assets/icons/ConnectExistingRoom';
import ConnectEventIcon from '../../assets/icons/ConnectExistingEvent';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';
import matomo from '../../utils/matomo';

const DocumentContextMenu = ({
  open,
  rooms,
  events,
  setOpen,
  document,
  anchorEl,
  documents,
  updateDocument,
  onDocumentDeleted,
  syncRoomsDocuments,
  syncEventsDocuments,
  documentId: passedDocumentId,
}) => {
  const history = useHistory();
  const isDesktop = useDesktop();
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const { showSuccessMessage, showErrorMessage } = useSnackbar();
  const { showActionDialog, showLoadingDialog } = useActionDialog();

  const queryFocusOnRoom = stringifyQuery({ focusOnRoom: true });
  const queryFocusOnEvent = stringifyQuery({ focusOnEvent: true });

  const documentCompanyId = getDocumentCompanyId(document);

  const documentId = passedDocumentId || getDocumentId(document);

  const companyProfilePage = `${dashboardLinkPrefix}/company/${documentCompanyId}`;
  const attachRoomLink = `${dashboardLinkPrefix}/documents/${documentId}/edit?${queryFocusOnRoom}`;
  const attachEventLink = `${dashboardLinkPrefix}/documents/${documentId}/edit?${queryFocusOnEvent}`;

  const { companyId } = useParams();

  const fetchDocument = async () => {
    const [error, data] = await apiFetchDocument(documentId);

    if (error && !data) return null;

    if (documents) {
      updateDocument(data);
    }

    if (
      rooms &&
      Array.isArray(getDocumentRooms(data)) &&
      getDocumentRooms(data).length > 0
    ) {
      syncRoomsDocuments({
        documentId,
        document: data,
        roomIds: getDocumentRooms(data).map((room) => getRoomId(room)),
      });
    }

    if (
      events &&
      Array.isArray(getDocumentEvents(data)) &&
      getDocumentEvents(data).length > 0
    ) {
      syncEventsDocuments({
        documentId,
        document: data,
        eventIds: getDocumentEvents(data).map((event) => getEventId(event)),
      });
    }

    return null;
  };

  const trackMatomoEvent = (action, name) => {
    matomo.clickEvent({
      category: 'Document View',
      name: `${name}`,
      action: `${action}`,
    });
  };

  const onDeleteClick = async () =>
    showActionDialog({
      submitButtonText: 'Slett',
      message: 'Vil du slette dette dokumentet?',
      onSubmit: async () => {
        const closeDialog = showLoadingDialog('Sletter...');

        const [error, response] = await apiDeleteDocument(documentId);

        closeDialog();

        if (error && !response) {
          showErrorMessage(error);
        } else {
          showSuccessMessage('Dokument slettet.');
          onDocumentDeleted(document);
        }
        trackMatomoEvent('Delete Document', 'Document View');
      },
    });

  const onAmbitaDocumentClick = () =>
    isDesktop
      ? null
      : showActionDialog({
          message:
            'Dette dokumentet har en rettighetsbegrensning som gjør at det ikke kan lastes ned lokalt til din enhet.',
        });

  const menuItems = [
    {
      icon: EditIcon,
      onClick: () => {
        trackMatomoEvent('Edit Document Details', 'Document View');
        history.push(`${dashboardLinkPrefix}/documents/${documentId}/edit`);
      },
      text: 'Rediger',
    },
    {
      to: attachEventLink,
      icon: ConnectEventIcon,
      text: 'Koble til hendelse',
      onClick: () =>
        trackMatomoEvent(
          'Connect Document to Event',
          'Connect Event from View'
        ),
    },
    {
      to: attachRoomLink,
      icon: ConnectRoomIcon,
      text: 'Koble til rom',
      onClick: () =>
        trackMatomoEvent('Connect Document to Room', 'Connect room from View'),
    },
    {
      icon: PersonIcon,
      text: 'Om sender',
      hidden: !documentCompanyId || companyId,
      to: companyProfilePage,
    },
    {
      text: 'Last ned',
      icon: DownloadIcon,
      openInNewTab: true,
      isExternalLink: true,
      to: getOriginalDocumentPath(document),
      onClick: () => trackMatomoEvent('Download Document', 'Document View'),
      disabled:
        !getOriginalDocumentPath(document) || isDocumentReadOnly(document),
      tooltipLabel:
        'Dette dokumentet har en rettighetsbegrensning som gjør at det ikke kan lastes ned lokalt til din enhet.',
    },
    {
      icon: DeleteIcon,
      hidden: !!documentCompanyId || !getOriginalDocumentPath(document),
      onClick: onDeleteClick,
      text: 'Fjern',
    },
  ];

  const renderMenuItem = ({
    text,
    onClick,
    disabled,
    tooltipLabel,
    ...props
  }) =>
    // eslint-disable-next-line react/no-children-prop
    createElement(isDesktop ? DesktopListItem : MobileListItem, {
      disabled,
      key: text,
      children: text,
      border: !isDesktop,
      label: tooltipLabel,
      onClick: (event) => {
        if (disabled) {
          onAmbitaDocumentClick();
          event.preventDefault();
          return;
        }

        if (onClick) {
          onClick(event);
        }

        setOpen(false);
      },
      ...props,
    });

  const renderMenuItems = () =>
    menuItems.filter(({ hidden }) => !hidden).map(renderMenuItem);

  useEffect(() => {
    if (open && !getOriginalDocumentPath(document)) {
      fetchDocument();
    }
  }, [open]);

  if (isDesktop) {
    return (
      <Menu
        open={open}
        anchorEl={anchorEl}
        disableAutoFocusItem
        onClose={() => setOpen(false)}
      >
        {renderMenuItems()}
      </Menu>
    );
  }

  return (
    <BottomContextMenu
      open={open}
      setOpen={setOpen}
      title={getDocumentName(document)}
      ModalProps={{
        style: {
          zIndex: 1202,
        },
      }}
    >
      <List>{renderMenuItems()}</List>
    </BottomContextMenu>
  );
};

const mapStateToProps = (state) => ({
  rooms: sGetRooms(state),
  events: sGetEvents(state),
  documents: sGetDocuments(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateDocument: (document) => dispatch(acUpdateDocument(document)),
  syncRoomsDocuments: (payload) => dispatch(acSyncRoomsDocuments(payload)),
  syncEventsDocuments: (payload) => dispatch(acSyncEventsDocuments(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DocumentContextMenu));
