import { useHistory } from 'react-router';
import React, { createElement } from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import NavigationBackButton from '../../../../../../components/NavigationBackButton';
import Box from '../../../../../../components/atomic/Box';
import useDesktop from '../../../../../../hooks/useDesktop';
import Paper from '../../../../../../components/atomic/Paper';
import Typography from '../../../../../../components/atomic/Typography';
import ChapterMobileItem from '../../../../../../components/ChapterMobileItem';

const ChapterHeadline = ({ classes, chapter, documents }) => {
  const history = useHistory();
  const isDesktop = useDesktop();

  const secondaryText = Array.isArray(documents) && (
    <>Totalt {documents.length} dokumenter</>
  );

  const onBackClick = () => history.goBack();

  if (!isDesktop) {
    return (
      <Paper className={classes.root}>
        <ChapterMobileItem
          boldPrimaryText
          chapter={chapter}
          secondaryText={secondaryText}
          className={classes.mobileItem}
        />
      </Paper>
    );
  }

  return (
    <>
      <NavigationBackButton
        onClick={onBackClick}
        title="Tilbake til kapitteloversikten"
      />

      <Box mt={2} display="flex" alignItems="center">
        <Box width={30} mr={1}>
          {createElement(chapter.icon, { className: classes.icon })}
        </Box>
        <Box>
          <Typography variant="h2">
            {chapter.id}. {chapter.name}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default withStyles(styles)(ChapterHeadline);
