import React from 'react';
import {
  StyledCustomDialog,
  StyledCustomDialogOverlay,
} from '@componentsV2/CustomDialog/styles';
import Close from '../../assets/icons/Close';

const CustomDialog = ({ isShown, onClose, hideClose, children }) => {
  return (
    <StyledCustomDialogOverlay className={isShown && 'show'}>
      <StyledCustomDialog>
        {!hideClose && onClose && (
          <button
            className="close"
            type="button"
            aria-label="Close"
            onClick={onClose && onClose}
          >
            <Close />
          </button>
        )}
        {children}
      </StyledCustomDialog>
    </StyledCustomDialogOverlay>
  );
};

export default CustomDialog;
