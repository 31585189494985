import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import Close from '../../../../../assets/icons/Close';
import Box from '../../../../../components/atomic/Box';
import useDesktop from '../../../../../hooks/useDesktop';
import Dialog from '../../../../../components/atomic/Dialog';
import Toolbar from '../../../../../components/atomic/Toolbar';
import Typography from '../../../../../components/atomic/Typography';
import IconButton from '../../../../../components/atomic/IconButton';
import DialogTitle from '../../../../../components/atomic/DialogTitle';
import DialogActions from '../../../../../components/atomic/DialogActions';
import Button from '../../../../../components/atomic/Button';
import RoomTypes from './RoomTypes';
import { sGetActiveProperty } from '../../../../../store/reducers/properties';
import {
  acBYBFAddRoom,
  acBYBFDeleteRoom,
  acBYBFDeleteRoomByCodeAndOrder,
  acBYBFRoomsFlowCompleted,
  acBYBFRoomsRoomsCreated,
  acBYBFRoomsSetActiveStep,
  acBYBFSetRooms,
  acBYBFUpdateRoom,
} from '../../../../../store/actions/bybfRooms';
import {
  sGetBYBFRooms,
  sGetBYBFRoomsActiveStep,
  sGetBYBFRoomsCreated,
} from '../../../../../store/reducers/bybfRooms';
import {
  createRoom as apiCreateRoom,
  updateRoom as apiUpdateRoom,
  deleteRoom as apiDeleteRoom,
} from '../../../../../api/rooms';
import {
  allTheRoomInforUpdatedCheck,
  prepareBYBFRoomJsonData,
  prepareRoomData,
  roomInforUpdatedCheck,
} from '../../../../../utils/bybfRooms';
import {
  getPropertyBoligmappaNumber,
  getPropertyType,
  isFloorEnabledProperty,
} from '../../../../../utils/properties';
import {
  buildBoligmappa as apiFetchBuildBoligmappa,
  fetchRooms as apiFetchRooms,
} from '../../../../../api/properties';
import RoomDetail from './RoomDetail';
import { getRoomImages, generateRoomTitle } from '../../../../../utils/rooms';

import {
  acAddRoom,
  acDeleteRoom,
  acUpdateRoom,
  acSetFetchingRooms,
  acSetFetchingRoomsError,
  acSetRooms,
} from '../../../../../store/actions/rooms';
import { acSyncDocumentsRooms } from '../../../../../store/actions/documents';
import ProgressButton from '../../../../../components/ProgressButton';
import useFetchEffect from '../../../../../hooks/useFetchEffect';
import { sGetRooms } from '../../../../../store/reducers/rooms';
import { sGetDocuments } from '../../../../../store/reducers/documents';
import LinearProgress from '../../../../../components/atomic/LinearProgress';
import useActionDialog from '../../../../../hooks/useActionDialog';
import { mapDocumentIds } from '../../../../../utils/documents';
import { mapEventIds } from '../../../../../utils/events';
import DialogScrollBox from '../../../../../components/DialogScrollBox';
import DialogCloseConfirm from '../../../../../components/DialogCloseConfirm';

const BYBFRooms = ({
  open,
  onClose,
  classes,
  addRoom,
  rooms,
  setRooms,
  updateRoom,
  activeProperty,
  bybfRooms,
  deleteRoom,
  roomsCreatedStatus,
  setBYBFAddRoomsData,
  setBYBFUpdateRoom,
  setBYBFSetRoomsCreated,
  activeBYBFStep,
  setBYBFDeleteRoom,
  setBYBFSetActiveStep,
  setFetchingRooms,
  setFetchingRoomsError,
  setBYBFSetRoomsFlowCompleted,
  setBYBYDeleteRoomByCodeAndOrder,
  documents,
  syncDocumentRooms,
}) => {
  const isDesktop = useDesktop();
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [activeStep, setActiveStep] = useState(1);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const { showActionDialog, showLoadingDialog } = useActionDialog();
  const [roomsFetchInProgress, setRoomsFetchInProgress] = useState(true);
  const [nonCreatedRooms, setNonCreatedRooms] = useState([]);
  const [reqError, setReqError] = useState(null);

  const boligmappaNumber = getPropertyBoligmappaNumber(activeProperty);

  const checkExistingRooms = () => {
    if (rooms?.length > 0) {
      rooms.forEach((room) => {
        if (!roomInforUpdatedCheck(room)) {
          setBYBFDeleteRoom(room.id);
          setBYBFAddRoomsData(room);
        } else if (activeStep === 1) {
          setBYBFDeleteRoom(room.id);
        }
      });
    }
  };

  const getRoomTitle = (roomType, order, isSingleRoom) => {
    if (rooms?.length > 0) {
      return generateRoomTitle(rooms, roomType, order, isSingleRoom);
    }
    return isSingleRoom ? roomType : `${roomType} ${order}`;
  };

  const getDefaultFloorNo = () => {
    return isFloorEnabledProperty(getPropertyType(activeProperty))
      ? '1. etasje'
      : null;
  };

  useEffect(() => {
    setSelectedRooms(bybfRooms || []);
  }, []);

  useEffect(() => {
    if ((bybfRooms || []).length > 0) {
      setNonCreatedRooms(bybfRooms.filter((a) => a.id === null));
    }
    if (
      (bybfRooms || []).length === 0 ||
      (bybfRooms || []).length < activeBYBFStep - 1
    ) {
      setActiveStep(1);
    } else if (!(bybfRooms || []).some((a) => a.id === null)) {
      setActiveStep(activeBYBFStep);
    }
  }, [bybfRooms]);

  useEffect(() => {
    if (rooms) {
      setRoomsFetchInProgress(false);
      if (rooms.length > 0) checkExistingRooms();
      bybfRooms.forEach((room) => {
        if (
          rooms.length > 0 &&
          !rooms.some((a) => a.id === room.id || room.id === null)
        ) {
          setBYBFDeleteRoom(room.id);
        }
      });
    }
  }, [rooms]);

  useFetchEffect({
    dependencies: [rooms, boligmappaNumber],
    conditionFunction: ([_rooms, _boligmappaNumber]) =>
      !_rooms && !!_boligmappaNumber,
    apiFetchFunction: () => apiFetchRooms(boligmappaNumber),

    setFetchingFunction: (status) => {
      setFetchingRooms(status);
      setRoomsFetchInProgress(status);
    },
    onFetchComplete: () => setFetchingRooms(false),
    onFetchStart: () => setFetchingRoomsError(null),

    onSuccess: (data) => setRooms(data),
    onError: (error) => setFetchingRoomsError(error),
  });

  const buildBoligmappaJsonSubmit = async (
    jsonDetails,
    flowCompleted,
    roomsCreated,
    movetoNextOnSuccess = false,
    movetoBackOnSuccess = false
  ) => {
    setRequestInProgress(true);
    const [errorResponse, data] = await apiFetchBuildBoligmappa(
      getPropertyBoligmappaNumber(activeProperty),
      { flowCompleted, state: jsonDetails },
      'rooms'
    );
    if (!errorResponse) {
      if (!(activeStep === bybfRooms.length + 1) && movetoNextOnSuccess) {
        setBYBFSetActiveStep(activeStep + 1);
        setActiveStep(activeStep + 1);
      }
      if (movetoBackOnSuccess) {
        setBYBFSetActiveStep(activeStep - 1);
        setActiveStep(activeStep - 1);
      }
      if (!movetoBackOnSuccess && activeStep === bybfRooms.length + 1)
        onClose();
      setBYBFSetRoomsFlowCompleted(flowCompleted);
      setBYBFSetRoomsCreated(roomsCreated);
      setRequestInProgress(false);
      return data;
    }
    return null;
  };

  const onRoomSelectSubmit = (
    isEdit = false,
    roomsCreated = true,
    roomsDetailsUpdated = false
  ) => {
    setRequestInProgress(true);
    checkExistingRooms();
    const promisess = (bybfRooms || []).map(async (room) => {
      if (!room.id) {
        const sameTypeRooms = bybfRooms.filter((a) => a.code === room.code);
        const isSingleRoom = (sameTypeRooms || []).length === 1;
        const roomObj = prepareRoomData({
          type: room.displayName,
          typeId: room.typeId,
          description: '',
          floorNo: getDefaultFloorNo(),
          title: `${getRoomTitle(room.displayName, room.order, isSingleRoom)}`,
        });
        const [errorResponse, data] = await apiCreateRoom({
          ...roomObj,
          boligmappaNumber: getPropertyBoligmappaNumber(activeProperty),
          applicationName: 'BUILDBOLIGMAPPA',
        });
        if (!errorResponse) {
          return {
            ...room,
            ...data,
            ...roomObj,
          };
        }
      }
      return null;
    });
    Promise.all(promisess).then((value) => {
      setSelectedRooms([]);
      const roomData = value.filter((a) => a !== null);
      let bybfRoomData = Array.from(
        JSON.parse(JSON.stringify(bybfRooms)) || []
      );
      roomData.forEach((room) => {
        setBYBYDeleteRoomByCodeAndOrder(room.code, room.order);
        setBYBFAddRoomsData(room);
        addRoom(room);
        bybfRoomData = bybfRoomData.filter(
          (a) =>
            !(a.code === room.code && a.order === room.order && a.id === null)
        );
        bybfRoomData.push(room);
      });
      const jsonDetails = JSON.stringify(
        prepareBYBFRoomJsonData({
          rooms: bybfRoomData,
          flowCompleted: false,
          isEdit,
          stage: activeStep + 1,
          roomsCreated,
          roomsDetailsUpdated,
        })
      );
      buildBoligmappaJsonSubmit(jsonDetails, false, roomsCreated, true);
    });
    setReqError(null);
  };

  const onRoomAdd = (item) => {
    setSelectedRooms((roomsInfo) => {
      const roomsData = [...roomsInfo];
      const roomData = {
        ...item,
        order: item.currentCount,
        id: null,
      };
      if (
        !roomsData.some(
          (rd) => rd.code === roomData.code && rd.order === roomData.order
        )
      ) {
        roomsData.push(roomData);
      }
      setBYBYDeleteRoomByCodeAndOrder(roomData.code, roomData.order);
      setBYBFAddRoomsData(roomData);
      return roomsData;
    });
  };

  const onRoomRemove = (item) => {
    const removeIndex = selectedRooms.findIndex(
      (a) => a.code === item.code && a.order === item.currentCount + 1
    );
    selectedRooms.splice(removeIndex, 1);
    setBYBYDeleteRoomByCodeAndOrder(item.code, item.currentCount + 1);
    setSelectedRooms(selectedRooms);
  };

  const onRoomImageUpload = (uploadedImages) => {
    const room = bybfRooms[activeStep - 2];
    setBYBFUpdateRoom({
      ...room,
      gallery: [...(getRoomImages(room) || []), ...uploadedImages],
    });
    updateRoom({
      ...room,
      gallery: [...(getRoomImages(room) || []), ...uploadedImages],
    });
  };

  const onRoomImageDelete = (images) => {
    const room = bybfRooms[activeStep - 2];
    setBYBFUpdateRoom({
      ...room,
      gallery: [...images],
    });
    updateRoom({
      ...room,
      gallery: [...images],
    });
  };

  const onRoomDelete = () =>
    showActionDialog({
      title: ' ',
      showCancelButton: false,
      submitButtonText: 'Slett rommet',
      submitButtonVariant: 'contained',
      buttonType: 'Customized',
      actionClassName: classes.delete_popup_actions,
      message: (
        <Box>
          <Box
            className={classes.delete_dialog_heading}
            display="flex"
            justifyContent="center"
            component="div"
          >
            <b>Slett rommet?</b>
          </Box>
          <Box display="flex" justifyContent="center" component="div">
            Ved å slette rommet vil miste:
          </Box>
          <Box display="flex" justifyContent="center">
            <ul className={classes.delete_list_item}>
              <li key={1}>- Informasjon om rommet</li>
              <li key={2}>- Bilder</li>
            </ul>
          </Box>
          <Box display="flex" justifyContent="center" component="div">
            Er du sikker på at du vil slette rommet?
          </Box>
        </Box>
      ),
      onSubmit: async () => {
        const closeDialog = showLoadingDialog('Sletter...');
        const updatinfRoom = bybfRooms[activeStep - 2];

        const [error, response] = await apiDeleteRoom(updatinfRoom.id);
        if (!error) {
          const bybfdata = JSON.parse(JSON.stringify(bybfRooms));
          bybfdata.splice(
            bybfdata.findIndex((a) => a.id === updatinfRoom.id),
            1
          );
          setBYBFDeleteRoom(updatinfRoom.id);
          deleteRoom(updatinfRoom.id);
          const allTheRoomDetailUpdateStatus =
            allTheRoomInforUpdatedCheck(bybfdata);
          const flowCompleted =
            bybfRooms.length === 1 || !allTheRoomDetailUpdateStatus
              ? false
              : activeStep === bybfRooms.length + 1;
          const jsonDetails = JSON.stringify(
            prepareBYBFRoomJsonData({
              rooms: bybfdata,
              flowCompleted,
              isEdit: response === null,
              stage: activeStep - 1,
              roomsCreated: !(bybfRooms.length === 1),
              roomsDetailsUpdated: allTheRoomDetailUpdateStatus,
            })
          );
          buildBoligmappaJsonSubmit(
            jsonDetails,
            flowCompleted,
            !(bybfRooms.length === 1),
            false,
            true
          );
        }
        closeDialog();
      },
    });

  const renderStepContent = () => {
    const totalRoomCount = bybfRooms.length;
    if (activeStep === 1 && rooms) {
      return (
        <RoomTypes
          onRoomAdd={onRoomAdd}
          onRoomRemove={onRoomRemove}
          property={activeProperty}
          nonCreatedRooms={nonCreatedRooms}
          requestInProgress={requestInProgress}
          roomsCreatedStatus={roomsCreatedStatus}
          bybfRooms={bybfRooms}
          rooms={rooms}
        />
      );
    }
    if (activeStep <= totalRoomCount + 1) {
      const floorEnableStatus = isFloorEnabledProperty(
        getPropertyType(activeProperty)
      );
      const selectedRoom = { ...bybfRooms[activeStep - 2] };
      const nextRoom = { ...bybfRooms[activeStep - 1] };
      return (
        <RoomDetail
          property={activeProperty}
          totalRoomCount={bybfRooms?.length}
          currentRoomIndex={activeStep - 1}
          room={selectedRoom}
          floorsEnable={floorEnableStatus}
          onRoomDelete={onRoomDelete}
          onRoomImageUpload={onRoomImageUpload}
          onRoomImageDelete={onRoomImageDelete}
          reqError={reqError}
          nextRoomIndex={activeStep}
          nextRoom={nextRoom?.title}
        />
      );
    }
    return null;
  };

  const onRoomValueUpdate = async (updateJson = true, isBack = false) => {
    setRequestInProgress(true);
    setReqError(null);

    const updatinfRoom = bybfRooms[activeStep - 2];
    if (updatinfRoom?.title === '') {
      updatinfRoom.title = updatinfRoom.displayName;
      setBYBFUpdateRoom(updatinfRoom);
    }

    const roomData = {
      ...updatinfRoom,
      documentIds: mapDocumentIds(updatinfRoom.documents),
      eventIds: mapEventIds(updatinfRoom.events),
    };

    const [requestError, response] = await apiUpdateRoom(roomData.id, roomData);

    if (response && !requestError) {
      updateRoom(updatinfRoom);
      const { documentIds } = roomData;
      const { id: roomId } = roomData;
      const bybfdata = JSON.parse(JSON.stringify(bybfRooms));
      const allTheRoomDetailUpdateStatus =
        allTheRoomInforUpdatedCheck(bybfdata);
      const flowCompleted = !allTheRoomDetailUpdateStatus
        ? false
        : activeStep === bybfRooms.length + 1;
      if (updateJson) {
        const jsonDetails = JSON.stringify(
          prepareBYBFRoomJsonData({
            rooms: bybfdata,
            flowCompleted,
            isEdit: true,
            stage:
              activeStep === bybfRooms.length + 1 ? activeStep : activeStep + 1,
            roomsCreated: true,
            roomsDetailsUpdated: allTheRoomDetailUpdateStatus,
          })
        );

        if (documents) {
          syncDocumentRooms({
            roomId,
            documentIds,
            room: {
              ...roomData,
            },
          });
        }

        buildBoligmappaJsonSubmit(
          jsonDetails,
          flowCompleted,
          true,
          !isBack,
          isBack
        );
      }
    }

    if (requestError) {
      setReqError(requestError);
      setRequestInProgress(false);
    }
  };

  const nextStep = () => {
    if (activeStep === 1) {
      onRoomSelectSubmit();
    } else {
      onRoomValueUpdate();
    }
  };

  const previousStep = () => {
    onRoomValueUpdate(true, true);
  };

  const openCloseSubmitPopup = (updateBybf) =>
    showActionDialog({
      title: ' ',
      showCancelButton: false,
      submitButtonText: 'Avslutt flyten',
      submitButtonVariant: 'contained',
      buttonType: 'Customized',
      actionClassName: classes.confirmCloseActions,
      buttonClassName: classes.confirmCloseButton,
      message: <DialogCloseConfirm />,
      onSubmit: async () => {
        if (activeStep !== 1) {
          onRoomValueUpdate(false);
        }
        if (updateBybf && bybfRooms) {
          const bybfdata = JSON.parse(JSON.stringify(bybfRooms));
          const allTheRoomDetailUpdateStatus =
            allTheRoomInforUpdatedCheck(bybfdata);
          const flowCompleted = false;
          const jsonDetails = JSON.stringify(
            prepareBYBFRoomJsonData({
              rooms: bybfdata,
              flowCompleted,
              isEdit: true,
              stage: activeStep,
              roomsCreated: roomsCreatedStatus,
              roomsDetailsUpdated: allTheRoomDetailUpdateStatus,
            })
          );
          buildBoligmappaJsonSubmit(
            jsonDetails,
            flowCompleted,
            roomsCreatedStatus
          );
        }
        onClose();
      },
    });

  const onModalClose = (updateBybf = true) => {
    openCloseSubmitPopup(updateBybf);
  };

  const finishFlowWithoutCreatingRoomsStatus = () => {
    if (rooms && bybfRooms) {
      const allRoomDeatailsUpdatedStatus = allTheRoomInforUpdatedCheck(rooms);
      const nonCreatedRoomsExistStatus = bybfRooms.some((a) => a.id === null);
      return allRoomDeatailsUpdatedStatus && !nonCreatedRoomsExistStatus;
    }
    return false;
  };

  const onFinishFlowOnStart = () => {
    const jsonDetails = JSON.stringify(
      prepareBYBFRoomJsonData({
        rooms: bybfRooms,
        flowCompleted: true,
        isEdit: true,
        stage: activeStep,
        roomsCreated: false,
        roomsDetailsUpdated: true,
      })
    );
    buildBoligmappaJsonSubmit(jsonDetails, true, false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      scroll="paper"
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          onModalClose();
        }
      }}
      disableEscapeKeyDown
      fullScreen={!isDesktop}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Toolbar>
          <Box
            p={isDesktop ? 0 : 2}
            className={
              isDesktop
                ? classes.dialogTitle_txt
                : classes.dialogTitle_txt_mobile
            }
          >
            <Typography variant="h3" component="h3" color="inherit">
              <b>{activeStep === 1 ? `Opprett rom` : `Detaljer om rom`}</b>
            </Typography>
          </Box>
          <IconButton size="small" color="inherit" onClick={onModalClose}>
            <Close />
          </IconButton>
        </Toolbar>
      </DialogTitle>

      {roomsFetchInProgress && (
        <DialogScrollBox>
          <LinearProgress />
          <Box pr={1} textAlign="right">
            <Typography component="small" color="textSecondary">
              Laster inn eksisterende rom
            </Typography>
          </Box>
        </DialogScrollBox>
      )}
      {rooms && renderStepContent()}

      <DialogActions
        className={
          isDesktop ? classes.dialog_actions : classes.dialog_actions_mobile
        }
      >
        {activeStep !== 1 && (
          <Button
            className={isDesktop ? classes.back_btn : classes.back_btn_mobile}
            color="primary"
            border="node"
            textDecoration="underline"
            onClick={previousStep}
          >
            Forrige
          </Button>
        )}
        {finishFlowWithoutCreatingRoomsStatus() ? (
          <ProgressButton
            type="submit"
            color="primary"
            variant="contained"
            align="right"
            onClick={onFinishFlowOnStart}
            className={isDesktop ? classes.next_btn : classes.next_btn_mobile}
            requestInProgress={requestInProgress}
            disabled={activeStep === 1 && (rooms || []).length === 0}
          >
            {(rooms || []).length === 0 ? `Neste` : `Bli ferdig`}
          </ProgressButton>
        ) : (
          <ProgressButton
            type="submit"
            color="primary"
            variant="contained"
            align="right"
            onClick={nextStep}
            className={isDesktop ? classes.next_btn : classes.next_btn_mobile}
            requestInProgress={requestInProgress}
            disabled={activeStep === 1 && (bybfRooms || []).length === 0}
          >
            {activeStep !== 1 && activeStep === bybfRooms?.length + 1
              ? 'Bli ferdig'
              : 'Neste'}
          </ProgressButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  activeProperty: sGetActiveProperty(state),
  bybfRooms: sGetBYBFRooms(state),
  activeBYBFStep: sGetBYBFRoomsActiveStep(state),
  rooms: sGetRooms(state),
  roomsCreatedStatus: sGetBYBFRoomsCreated(state),
  documents: sGetDocuments(state),
});

const mapDispatchToProps = (dispatch) => ({
  setRooms: (rooms) => dispatch(acSetRooms(rooms)),
  setBYBFSetRooms: (rooms) => dispatch(acBYBFSetRooms(rooms)),
  setBYBFAddRoomsData: (room) => dispatch(acBYBFAddRoom(room)),
  setBYBFUpdateRoom: (room) => dispatch(acBYBFUpdateRoom(room)),
  setBYBYDeleteRoomByCodeAndOrder: (code, order) =>
    dispatch(acBYBFDeleteRoomByCodeAndOrder(code, order)),
  setBYBFDeleteRoom: (roomId) => dispatch(acBYBFDeleteRoom(roomId)),
  addRoom: (room) => dispatch(acAddRoom(room)),
  deleteRoom: (roomId) => dispatch(acDeleteRoom(roomId)),
  updateRoom: (room) => dispatch(acUpdateRoom(room)),
  setBYBFSetRoomsFlowCompleted: (status) =>
    dispatch(acBYBFRoomsFlowCompleted(status)),
  setBYBFSetRoomsCreated: (status) => dispatch(acBYBFRoomsRoomsCreated(status)),
  setBYBFSetActiveStep: (status) => dispatch(acBYBFRoomsSetActiveStep(status)),
  setFetchingRooms: (fetching) => dispatch(acSetFetchingRooms(fetching)),
  setFetchingRoomsError: (error) => dispatch(acSetFetchingRoomsError(error)),
  syncDocumentRooms: (payload) => dispatch(acSyncDocumentsRooms(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BYBFRooms));
