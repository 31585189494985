import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { useFlag } from '@unleash/proxy-client-react';
import { useSanityContent } from '../../../../hooks/v2/useSanityContent';
import useDesktop from '../../../../hooks/useDesktop';
import useSnackbar from '../../../../hooks/useSnackbar';
import PageLayout from '../../../../components/layouts/WithHeader';
import Grid from '../../../../components/atomic/Grid';
import Box from '../../../../components/atomic/Box';
import CallToAction from '../../../../componentsV2/BoligmappaPlus/CallToAction';
import { useBoligmappaPlusSubscriptionStatus } from '../../../../store/hooks/useBoligmappaPlusSubscriptionStatus';
import { isNativeEnv } from '../../../../utils/nativeBridge';
import ContractTemplate from './ContractTemplate';
import styles from './style';
import { getImageKitImageUrlForSanity } from '../../../../content/contentHelpers';
import BoligmappaPlusLogo from '../../../../assets/images/Boligmappa-Plus-Logo.png';
import { boligmappaPlusActionCallback } from '../../../../utils/boligmappaPlus';

const ContractTemplatePage = ({ classes }) => {
  const isDesktop = useDesktop();
  const [boligmappaPlusCTAShow, setBoligmappaPlusCTAShow] = useState(false);
  const veridiBoligmappaPlus = useFlag('boligeier.veridi.boligmappa.plus');
  const boligmappaPlusSubscriptionStatus =
    useBoligmappaPlusSubscriptionStatus();
  const sanityData = useSanityContent();
  const [contractTemplatesData, setContractTemplatesData] = useState(null);
  const { showErrorMessage } = useSnackbar();

  useEffect(() => {
    if (boligmappaPlusSubscriptionStatus) {
      setBoligmappaPlusCTAShow(false);
    } else if (!isNativeEnv()) {
      setBoligmappaPlusCTAShow(veridiBoligmappaPlus);
    } else {
      setBoligmappaPlusCTAShow(false);
    }
  }, [veridiBoligmappaPlus, boligmappaPlusSubscriptionStatus]);

  useEffect(() => {
    setContractTemplatesData(sanityData.contractTemplates);
  }, []);

  const handleActionClick = async (type) => {
    await boligmappaPlusActionCallback(type, showErrorMessage);
  };

  return (
    <PageLayout
      topLevelLayout
      minFullHeight
      hideHeader={isDesktop}
      headerTitle="Kontrakter og maler"
      maxWidth="lg"
      className={classes.pageLayout}
    >
      {boligmappaPlusCTAShow && (
        <Box className={classes.veridiBoligmappaPlusSection}>
          <h2>Kontrakter og maler</h2>
          <Box className={classes.veridiBoligmappaPlusTextSection}>
            <p>
              Kvalitetssikret malverk for alt du trenger innen renovasjon, kjøp
              og salg av bolig.
            </p>
            <button type="button" className={classes.boligmappa_plus_logo_btn}>
              Tilgjengelig med
              <img alt="Boligmappa+Logo" src={BoligmappaPlusLogo} />
            </button>
          </Box>

          <CallToAction
            isDesktop={isDesktop}
            className={classes.veridiCallToAction}
            onActionClick={handleActionClick}
          />
        </Box>
      )}
      <Grid container spacing={isDesktop ? 6 : 2}>
        <div className={classes.contractAdvantages}>
          {contractTemplatesData?.map((contractTemplate) => (
            <ContractTemplate
              title={contractTemplate.contractTitle}
              description={contractTemplate.description}
              buttonText="Last ned"
              logo={getImageKitImageUrlForSanity(
                contractTemplate.companyLogo?.asset
              )}
              contractUrl={contractTemplate.contractDocument}
              isBoligmappaPlusSubscribed={boligmappaPlusSubscriptionStatus}
            />
          ))}
        </div>
      </Grid>
      <br />
      <br />
    </PageLayout>
  );
};

export default withStyles(styles)(ContractTemplatePage);
