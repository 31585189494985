import React from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import RadialSeparators from './RadialSeparators';
import 'react-circular-progressbar/dist/styles.css';

const CircularSectionedProgressBar = ({
  value,
  text,
  strokeWidth = 10,
  seperatorCount,
  separatorWidth = 0,
  textFontSize = 30,
  textColor = '#000000',
  pathColor = '#13A085',
  ...props
}) => (
  <CircularProgressbarWithChildren
    value={value}
    text={text}
    strokeWidth={strokeWidth}
    styles={{
      text: {
        fontSize: textFontSize,
        fill: textColor,
      },
      path: {
        strokeLinecap: 'butt',
        stroke: pathColor,
      },
    }}
    {...props}
  >
    <RadialSeparators
      count={seperatorCount}
      style={{
        background: '#fff',
        width: `${separatorWidth}px`,
        height: `${10}%`,
      }}
    />
  </CircularProgressbarWithChildren>
);

export default CircularSectionedProgressBar;
