import React from 'react';

const ConnectNewRoom = ({
  width = '1.2rem',
  height = '1.2rem',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 20.067 17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g data-name="Group 2936" transform="translate(0)">
      <path
        fill={fill}
        transform="translate(-1)"
        d="M7.875,0,1,3.75v7.582L7.875,15l6.875-3.668V3.791L7.875,0Zm4.944,4.154L7.878,6.79,2.958,4.106,7.872,1.426ZM2.25,5.144l5,2.727V13.25l-5-2.668V5.144ZM8.5,13.25V7.875l5-2.668v5.375Zm4.174-3.322a.375.375,0,1,1-.382-.368A.375.375,0,0,1,12.674,9.928ZM10.98,8.88a.375.375,0,1,0,.382.367A.374.374,0,0,0,10.98,8.88ZM9.647,8.2a.375.375,0,1,0,.382.368A.375.375,0,0,0,9.647,8.2ZM3.459,9.56a.375.375,0,1,1-.382.367.375.375,0,0,1,.382-.367Zm.929-.313a.375.375,0,1,0,.382-.367A.375.375,0,0,0,4.388,9.247Zm1.332-.684A.375.375,0,1,0,6.1,8.2a.375.375,0,0,0-.382.368ZM7.875,4.971a.375.375,0,1,0,.375.375A.375.375,0,0,0,7.875,4.971Zm0-1.391a.375.375,0,1,0,.375.375A.375.375,0,0,0,7.875,3.58Zm0-1.269a.375.375,0,1,0,.375.375A.375.375,0,0,0,7.875,2.311Z"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        transform="translate(11.089 -2.979)"
        d="M0,15.489a4.489,4.489,0,1,1,4.489,4.489A4.489,4.489,0,0,1,0,15.489Zm5.18.518H6.216a.518.518,0,0,0,0-1.036H5.18a.173.173,0,0,1-.173-.173V13.763a.518.518,0,1,0-1.036,0V14.8a.173.173,0,0,1-.173.173H2.763a.518.518,0,0,0,0,1.036H3.8a.173.173,0,0,1,.173.173v1.036a.518.518,0,0,0,1.036,0V16.18A.173.173,0,0,1,5.18,16.007Z"
      />
    </g>
  </svg>
);

export default ConnectNewRoom;
