const styles = () => ({
  title: {
    margin: '0px 0 20px',
  },
  listItemLoading: {
    marginBottom: '45px',
    '&:last-child': {
      marginBottom: '30px',
    },
  },
});

export default styles;
