import {
  decodeToken,
  retrieveIdToken,
  retrieveIdTokenFromUrl,
} from '../../utils/auth';

export const LOGOUT = 'LOGOUT';
export const SET_AUTH_INFO = 'SET_AUTH_INFO';

export const defaultState = { user: null };

export const initializeState = ({ idToken }) => {
  const [error, user] = decodeToken(idToken);

  return user && !error ? { ...defaultState, user } : defaultState;
};

const idToken = retrieveIdTokenFromUrl() || retrieveIdToken();

const initializedState = initializeState({
  idToken,
  fetching: true,
  loaded: false,
});

export default (state = initializedState, action) => {
  switch (action.type) {
    case SET_AUTH_INFO:
      return { ...state, ...action.payload };
    case LOGOUT:
      return { ...state, ...defaultState };
    default:
      return state;
  }
};

export const sGetAuth = (state) => state.auth;
export const sGetAuthLoaded = (state) => sGetAuth(state).loaded;
export const sGetUser = (state) => sGetAuth(state).user;
export const sGetIsAuthenticated = (state) => !!sGetUser(state);
export const sGetIsPlusSubscribed = (state) => !!sGetUser(state).subscription;

export const sGetUserFullname = (state) =>
  sGetUser(state) && sGetUser(state).name ? sGetUser(state).name : null;

export const sGetUsername = (state) =>
  sGetUser(state) && sGetUser(state).username ? sGetUser(state).username : null;
