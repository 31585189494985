import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';

import clsx from 'clsx';
import styles from './style';
import Box from '../../atomic/Box';
import Typography from '../../atomic/Typography';
import ArrowDown from '../../../assets/icons/ArrowDownFilter';
import ArrowSortUp from '../../../assets/icons/ArrowSortUp';
import ArrowSortDown from '../../../assets/icons/ArrowSortDown';
import matomo from '../../../utils/matomo';
import { getSortType } from '../../../utils/documents';

const FilterItem = ({
  name,
  classes,
  isFilterable,
  isSortable,
  sortUpType,
  sortDownType,
  defaultSortUp = false,
  currentSortingType,
  onSortTypeChange,
  onClick,
  ...props
}) => {
  const [sortUp, setSortUp] = useState(
    currentSortingType === sortUpType || defaultSortUp
  );

  const handleSort = (type) => {
    setSortUp(!sortUp);
    onSortTypeChange(type);
    matomo.clickEvent({
      category: 'Document handling',
      name: `Sort by ${getSortType({ SortType: type })}`,
      action: 'Sort Document List',
    });
  };

  useEffect(() => {
    if (currentSortingType) {
      setSortUp(currentSortingType === sortUpType);
    }
  }, [currentSortingType]);

  return (
    <>
      <Box
        px={2}
        py={1}
        display="flex"
        alignItems="center"
        className={classes.filterItem}
        {...props}
        onClick={isFilterable ? onClick : null}
      >
        <Typography
          style={{ fontSize: '14px', fontWeight: '700', paddingLeft: '0' }}
        >
          {name}
        </Typography>
        {isFilterable && <ArrowDown className={classes.arrow} />}
      </Box>
      {isSortable && (
        <Box
          pt="5px"
          onClick={() => handleSort(sortUp ? sortDownType : sortUpType)}
          ml="10px"
          className={clsx([
            classes.sortingArrowBox,
            isFilterable && classes.sortWithFilter,
          ])}
        >
          {sortUp ? (
            <ArrowSortUp className={classes.sortingArrow} />
          ) : (
            <ArrowSortDown className={classes.sortingArrow} />
          )}
        </Box>
      )}
    </>
  );
};

export default withStyles(styles)(FilterItem);
