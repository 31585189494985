import { zIndex } from '../ImageGallery/styles';

const styles = {
  dialog: {
    zIndex: `${zIndex + 1} !important`,
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 24px',
    justifyContent: 'space-between',
  },
  dialogContent: {
    padding: '0 24px 15px',
    '&:first-child': {
      paddingTop: '15px',
    },
  },
  actions: {
    padding: '10px 8px',
  },
  closeButton: {
    marginRight: '-12px',
  },
  centeredDialogContent: {
    textAlign: 'center',
  },
};

export default styles;
