const styles = {
  list: {
    paddingLeft: '20px',
  },
  listItem: {
    marginBottom: '20px',
  },
  formSection: {
    marginBottom: '60px',
  },
};

export default styles;
