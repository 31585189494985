import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';

import './styles.css';
import TextField from '../atomic/TextField';
import CalendarIcon from '../../assets/icons/Calendar';

const DatePickerModified = ({
  isDesktop = false,
  isCustom = false,
  isSimple = false,
  error = false,
  label = '',
  dateSelected,
  placeholder,
  required = false,
  disabled,
  onChange,
  ...props
}) => {
  if (!isDesktop && !isSimple) {
    return (
      <TextField
        required={required}
        type="date"
        label={label}
        value={dateSelected}
        placeholder={placeholder}
        inputProps={{ maxLength: 10 }}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
      />
    );
  }

  const SimplePicker = forwardRef(({ value, onClick }, ref) => (
    <TextField
      ref={ref}
      required={required}
      label={label}
      value={value}
      placeholder={placeholder}
      inputProps={{ maxLength: 10 }}
      disabled={disabled}
      onClick={onClick}
      error={error}
    />
  ));

  const CustomPicker = forwardRef(({ value, onClick }, ref) => (
    <div ref={ref} style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <TextField
        required={required}
        label={label}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        inputProps={{ maxLength: 10 }}
        onClick={onClick}
      />

      <CalendarIcon
        onClick={onClick}
        style={{ position: 'absolute', margin: '37px 6px 10px -27px' }}
      />
    </div>
  ));
  if (!isDesktop && isCustom) {
    return (
      <DatePicker
        selected={dateSelected}
        onChange={onChange}
        customInput={<CustomPicker />}
        {...props}
      />
    );
  }
  if (isDesktop && isSimple) {
    return (
      <DatePicker
        selected={dateSelected}
        onChange={onChange}
        customInput={<CustomPicker />}
        {...props}
      />
    );
  }
  return (
    <DatePicker
      selected={dateSelected}
      onChange={onChange}
      customInput={isSimple ? <SimplePicker /> : <CustomPicker />}
      {...props}
    />
  );
};

export default DatePickerModified;
