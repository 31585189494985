import { useEffect } from 'react';
import { getPriceHistoryFromApi } from '../properties';
import { useApiFetch } from './useApiFetch';

export function useFetchPriceHistory(property) {
  const priceHistoryApi = useApiFetch(getPriceHistoryFromApi);

  useEffect(() => {
    if (!property) return;
    if (!property.boligmappaNumber) return;

    const { boligmappaNumber } = property;
    priceHistoryApi.executeFetch(boligmappaNumber);
  }, [property?.boligmappaNumber]);

  return {
    data: priceHistoryApi.data,
    error: priceHistoryApi.error,
    isLoading: priceHistoryApi.isLoading,
    executeFetch: priceHistoryApi.executeFetch,
  };
}
