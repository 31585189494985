import React from 'react';
import FilterItem from '../FilterItem';
import useMenuState from '../../../hooks/useMenuState';
import BottomMenu from '../../DocumentSortingMenuMobile/BottomMenu';

const menuItemName = 'Sort etter';

const SortingMenuItem = ({ sortType, onSortTypeChange }) => {
  const { open: menuOpen, setOpen: setMenuOpen, onMenuOpen } = useMenuState();

  const onSortTypeChangeClick = (selectedSortType) => {
    onSortTypeChange(selectedSortType);

    setMenuOpen(false);
  };

  return (
    <>
      <FilterItem name={menuItemName} onClick={onMenuOpen} />

      <BottomMenu
        open={menuOpen}
        setOpen={setMenuOpen}
        activeSortTypeId={sortType.id}
        onSortTypeChange={onSortTypeChangeClick}
      />
    </>
  );
};

export default SortingMenuItem;
