import React from 'react';
import {
  getFinancialsPrice,
  getFinancialsTotalLoanAmount,
} from '../../utils/financials';
import NewCardList from '../../components/NewCardList';
import Box from '../../components/atomic/Box';
import InfoIconTooltip from '../../components/InfoIconWithTooltip';

const PropertyEconomy = ({
  fetching,
  financials,
  link = null,
  propertyOwners,
  classes,
  hideValues,
  ...props
}) => {
  const items = [
    {
      label: 'Eiere',
      value:
        !hideValues &&
        Array.isArray(propertyOwners) &&
        propertyOwners.map(({ fullName }) => fullName).join(', '),
    },
    {
      label: 'Solgt for',
      value:
        !hideValues && getFinancialsPrice(financials)
          ? `${getFinancialsPrice(financials)} NOK`
          : 'N/A',
    },
    {
      label: 'Pantebeløp',
      value:
        !hideValues && getFinancialsTotalLoanAmount(financials)
          ? `${getFinancialsTotalLoanAmount(financials)} NOK`
          : 'N/A',
    },
  ];

  const title = (
    <>
      Eiere og økonomi
      <InfoIconTooltip placement="bottom">
        Opplysningene om eiere, omsetningshistorikk og heftelser hentes fra
        Kartverkets matrikkel, grunnbok og tinglysning. Boligmappa kan ikke
        endre denne informasjonen, ved feil må du kontakte din kommune, som vil
        sørge for at informasjonen i Kartverket oppdateres.
      </InfoIconTooltip>
    </>
  );

  return (
    <Box style={{ height: '91%' }}>
      <NewCardList
        isFinance
        link={link}
        items={items}
        title={title}
        fetching={fetching}
        {...props}
      />
    </Box>
  );
};

export default PropertyEconomy;
