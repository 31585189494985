import { areIdsEqual } from '../../utils/strings';
import {
  getDocumentId,
  getDocumentEvents,
  getDocumentRooms,
} from '../../utils/documents';
import { getEventId } from '../../utils/events';
import { getRoomId } from '../../utils/rooms';

export const defaultState = {
  fetching: false,
  documents: null,
  fetchingError: null,
  searchText: null,
  multipleDocumentsSelected: false,
  isCreatingDocumentFolder: false,
};

export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const CLEAR_DOCUMENTS = 'CLEAR_DOCUMENTS';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const SET_FETCHING_DOCUMENTS = 'SET_FETCHING_DOCUMENTS';
export const SET_FETCHING_DOCUMENTS_ERROR = 'SET_FETCHING_DOCUMENTS_ERROR';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SYNC_DOCUMENTS_EVENTS = 'SYNC_DOCUMENTS_EVENTS';
export const SYNC_DOCUMENTS_ROOMS = 'SYNC_DOCUMENTS_ROOMS';
export const DETACH_EVENT_FROM_DOCUMENTS = 'DETACH_EVENT_FROM_DOCUMENTS';
export const DETACH_ROOM_FROM_DOCUMENTS = 'DETACH_ROOM_FROM_DOCUMENTS';
export const SET_MULTIPLE_DOCUMENTS_SELECTED =
  'SET_MULTIPLE_DOCUMENTS_SELECTED';
export const SET_INSURANCE_REMAINING_DAYS = 'SET_INSURANCE_REMAINING_DAYS';
export const SET_IS_CREATING_DOCUMENT_FOLDER =
  'SET_IS_CREATING_DOCUMENT_FOLDER';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };
    case ADD_DOCUMENT:
      return {
        ...state,
        documents: [...(state.documents || []), action.payload],
      };
    case CLEAR_DOCUMENTS:
      return defaultState;
    case DELETE_DOCUMENT:
      return {
        ...state,
        documents: state.documents.filter(
          (document) =>
            !areIdsEqual(getDocumentId(document), getDocumentId(action.payload))
        ),
      };
    case UPDATE_DOCUMENT:
      const updatedDocument = action.payload;

      return {
        ...state,
        documents: state.documents.map((document) =>
          areIdsEqual(getDocumentId(document), getDocumentId(updatedDocument))
            ? updatedDocument
            : document
        ),
      };
    case SET_FETCHING_DOCUMENTS:
      return {
        ...state,
        fetching: action.payload,
      };
    case SET_FETCHING_DOCUMENTS_ERROR:
      return {
        ...state,
        fetchingError: action.payload,
      };
    case SYNC_DOCUMENTS_EVENTS:
      return {
        ...state,
        documents: (state.documents || []).map((document) => {
          const {
            eventId,
            event,
            documentIds: numericDocumentIds,
          } = action.payload;
          const documentIds = numericDocumentIds.map((id) => String(id));
          const eventIds = (getDocumentEvents(document) || []).map((_event) =>
            String(getEventId(_event))
          );
          const documentId = getDocumentId(document);
          const documentHadEvent = eventIds.includes(String(eventId));
          const eventAttached = documentIds.includes(String(documentId));

          if (documentHadEvent && !eventAttached) {
            return {
              ...document,
              events: (getDocumentEvents(document) || []).filter(
                (_event) => !areIdsEqual(getEventId(_event), eventId)
              ),
            };
          }

          if (!documentHadEvent && eventAttached) {
            return {
              ...document,
              events: [...(getDocumentEvents(document) || []), event],
            };
          }

          return {
            ...document,
            events: (getDocumentEvents(document) || []).map((documentEvent) =>
              areIdsEqual(getEventId(documentEvent), eventId)
                ? event
                : documentEvent
            ),
          };
        }),
      };
    case SYNC_DOCUMENTS_ROOMS:
      return {
        ...state,
        documents: (state.documents || []).map((document) => {
          const {
            roomId,
            room,
            documentIds: numericDocumentIds,
          } = action.payload;
          const documentIds = numericDocumentIds.map((id) => String(id));
          const roomIds = (getDocumentRooms(document) || []).map((_room) =>
            String(getRoomId(_room))
          );
          const documentId = getDocumentId(document);
          const documentHadRoom = roomIds.includes(String(roomId));
          const roomAttached = documentIds.includes(String(documentId));

          if (documentHadRoom && !roomAttached) {
            return {
              ...document,
              rooms: (getDocumentRooms(document) || []).filter(
                (_room) => !areIdsEqual(getRoomId(_room), roomId)
              ),
            };
          }

          if (!documentHadRoom && roomAttached) {
            return {
              ...document,
              rooms: [...(getDocumentRooms(document) || []), room],
            };
          }

          return {
            ...document,
            rooms: (getDocumentRooms(document) || []).map((documentRoom) =>
              areIdsEqual(getEventId(documentRoom), roomId)
                ? room
                : documentRoom
            ),
          };
        }),
      };
    case DETACH_EVENT_FROM_DOCUMENTS:
      return {
        ...state,
        documents: Array.isArray(state.documents)
          ? state.documents.map((document) => ({
              ...document,
              events: (getDocumentEvents(document) || []).filter(
                (event) => !areIdsEqual(getEventId(event), action.payload)
              ),
            }))
          : state.documents,
      };
    case DETACH_ROOM_FROM_DOCUMENTS:
      return {
        ...state,
        documents: Array.isArray(state.documents)
          ? state.documents.map((document) => ({
              ...document,
              rooms: (getDocumentRooms(document) || []).filter(
                (room) => !areIdsEqual(getRoomId(room), action.payload)
              ),
            }))
          : state.documents,
      };
    case SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      };
    case SET_MULTIPLE_DOCUMENTS_SELECTED:
      return {
        ...state,
        multipleDocumentsSelected: action.payload,
      };
    case SET_INSURANCE_REMAINING_DAYS:
      return {
        ...state,
        insuranceRemainingDays: action.payload,
      };
    case SET_IS_CREATING_DOCUMENT_FOLDER:
      return {
        ...state,
        isCreatingDocumentFolder: action.payload,
      };

    default:
      return state;
  }
};

export const sGetDocumentsModule = (state) => state.documents;
export const sGetDocuments = (state) => sGetDocumentsModule(state).documents;

export const sGetFetchingDocuments = (state) =>
  sGetDocumentsModule(state).fetching;

export const sGetFetchingDocumentsError = (state) =>
  sGetDocumentsModule(state).fetchingError;

export const sGetDocumentSearchText = (state) =>
  sGetDocumentsModule(state).searchText;

export const sGetMultipeDocumentsSelected = (state) =>
  sGetDocumentsModule(state).multipleDocumentsSelected;

export const sGetInsuranceRemainingDays = (state) =>
  sGetDocumentsModule(state).insuranceRemainingDays;

export const sGetIsCreatingDocumentFolder = (state) =>
  sGetDocumentsModule(state).isCreatingDocumentFolder;
