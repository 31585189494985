/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Card from '../../../../../components/Card';
import PropertyReferralCardInfo from '../../../../../components/PropertyReferralCardInfo';
import { OverviewGridItem } from './components';

export function PropertyReferralSection() {
  return (
    <OverviewGridItem>
      <Card height="100%">
        <PropertyReferralCardInfo />
      </Card>
    </OverviewGridItem>
  );
}
