import { matchPath } from 'react-router';
import routes from '../../../structure/routes';

/**
 * Locations that don't display bottom navigation
 * and add menu context button (mobile).
 * @type {string[]}
 */
export const fullscreenLocations = [
  'images',
  'add-room',
  'add-event',
  'add-document',
  'rooms/:roomId/edit',
  'rooms/:roomId/images',
  'events/:eventId/edit',
  'events/:eventId/images',
  'documents/:documentId/edit',
];

export const fullscreenPreviewModeLocations = ['documents/:documentId'];

/**
 * Locations where add button should be enabled
 * @type {string[]}
 */

export const locationsWithAddButtonEnabled = [
  'home/rooms',
  ...routes.map(({ path }) => path),
];
export const locationsBackgrounds = {
  'boligmappa-plus': '#FAF7F1',
  'boligmappa-plus/loyalty': '#FAF7F1',
  'loyalty/company/*': '#FAF7F1',
  'boligmappa-plus/loyalty/company/*': '#FAF7F1',
  'my-purchases': '#FAF7F1',
  insurance: '#f5f5f5',
};

export const isFullScreenPreviewMode = (url, prefix) =>
  fullscreenPreviewModeLocations
    .map((location) => `${prefix}/${location}`)
    .some((location) =>
      matchPath(url, {
        path: location,
        exact: true,
      })
    );

export const isBackgroundContentContainer = (url, prefix) =>
  Object.keys(locationsBackgrounds)
    .map((locationsBackground) => {
      return {
        location: `${prefix}/${locationsBackground}`,
        background: locationsBackgrounds[locationsBackground],
      };
    })
    .find(
      (locationBgObject) =>
        matchPath(url, {
          path: locationBgObject.location,
          exact: true,
        }) && locationBgObject.background
    )?.background;

export const isFullscreenMode = (url, prefix) =>
  fullscreenLocations
    .map((location) => `${prefix}/${location}`)
    .some((location) =>
      matchPath(url, {
        path: location,
        exact: true,
      })
    );
export const isAddButtonEnabled = (url, prefix) =>
  locationsWithAddButtonEnabled
    .map((location) => `${prefix}/${location}`)
    .some((location) =>
      matchPath(url, {
        path: location,
        exact: true,
      })
    );
