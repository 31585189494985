import React from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';

import { EstimateTitleWithPopup } from '../PropertyEstimate/EstimateTitleWithPopup/EstimateTitleWithPopup';
import Pencil from '../../../../../../../assets/icons/Pencil';
import ContentLoader from '../../../../../../../components/loaders/ContentLoader';
import useDesktop from '../../../../../../../hooks/useDesktop';
import Link from '../../../../../../../components/atomic/Link';

export function EstimateCardHeader({ displayIcon, editButtonLink, isLoading }) {
  const classes = useStyles();
  const isDesktop = useDesktop();

  return (
    <div
      className={clsx(
        isDesktop ? classes.cardHeader : classes.cardHeaderMobile
      )}
    >
      {isLoading ? (
        <ContentLoader display="inline-flex" height={30} width="45%" />
      ) : (
        <>
          <EstimateTitleWithPopup />
          {displayIcon && isDesktop && (
            <Link
              to={editButtonLink}
              isExternal
              openInNewTab
              className={classes.EditButton}
            >
              <Pencil
                style={{
                  width: 24,
                  height: 24,
                }}
              />
            </Link>
          )}
        </>
      )}
    </div>
  );
}
