export const defaultState = {
  isHealthy: false,
  fetching: false,
  fetchingError: null,
};

export const SET_API_HEALTH_STATUS = 'SET_API_HEALTH_STATUS';
export const SET_API_HEALTH_FETCHING = 'SET_API_HEALTH_FETCHING';
export const SET_API_HEALTH_FETCHING_ERROR = 'SET_API_HEALTH_FETCHING_ERROR';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_API_HEALTH_STATUS:
      return {
        ...state,
        isHealthy: action.payload,
      };
    case SET_API_HEALTH_FETCHING:
      return {
        ...state,
        fetching: action.payload,
      };
    case SET_API_HEALTH_FETCHING_ERROR:
      return {
        ...state,
        fetchingError: action.payload,
      };
    default:
      return state;
  }
};

export const sGetApiHealth = (state) => state.apiHealth.isHealthy;
