import React from 'react';

const Notification = ({ fill = 'currentColor', ...props }) => (
  <svg
    viewBox="0 0 16.769 20.908"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <style>{`.notificationIconClass{fill:${fill};stroke:currentColor;stroke-width:0.75px;}`}</style>
    </defs>
    <g transform="translate(-518.763 -2884.572)">
      <g transform="translate(519.148 2884.947)">
        <g transform="translate(0 0)">
          <path
            className="notificationIconClass"
            transform="translate(-519.076 -2884.933)"
            d="M528.63,2902.6h-3.111a.512.512,0,0,0-.518.5,2.076,2.076,0,0,0,4.148,0A.512.512,0,0,0,528.63,2902.6Z"
          />
          <path
            className="notificationIconClass"
            transform="translate(-519 -2884.84)"
            d="M533.5,2894.982v-.616a7,7,0,0,0-5-6.927v-1.171a1.5,1.5,0,0,0-3,0v1.171a7,7,0,0,0-5,6.927v.616a12.65,12.65,0,0,1-1.447,5.845.444.444,0,0,0,.022.457.511.511,0,0,0,.425.228h15a.511.511,0,0,0,.425-.228.442.442,0,0,0,.022-.457A12.653,12.653,0,0,1,533.5,2894.982Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Notification;
