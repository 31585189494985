import React from 'react';

const DashPencil = ({ width = '16px', height = '17px', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2776_13128)">
      <line
        x1="8.71226"
        y1="14.4782"
        x2="13.979"
        y2="14.4782"
        stroke="#13142A"
        strokeWidth="1.40695"
        strokeLinecap="round"
      />
      <mask id="path-2-inside-1_2776_13128" fill="white">
        <path d="M9.99204 2.66421C10.7731 1.88317 12.0394 1.88317 12.8205 2.66421L13.8007 3.64449C14.5818 4.42554 14.5818 5.69187 13.8007 6.47292L5.46407 14.8096L2.45066 15.108C1.82269 15.1701 1.29482 14.6423 1.357 14.0143L1.65536 11.0009L9.99204 2.66421Z" />
      </mask>
      <path
        d="M5.46407 14.8096L5.59559 16.1378L6.07046 16.0908L6.40788 15.7534L5.46407 14.8096ZM1.65536 11.0009L0.711555 10.0571L0.37413 10.3945L0.327112 10.8694L1.65536 11.0009ZM1.357 14.0143L0.0287492 13.8828L1.357 14.0143ZM11.8767 3.60802L12.8569 4.5883L14.7446 2.70069L13.7643 1.72041L11.8767 3.60802ZM12.8569 5.52912L4.52027 13.8658L6.40788 15.7534L14.7446 7.41673L12.8569 5.52912ZM2.59917 11.9447L10.9358 3.60802L9.04823 1.72041L0.711555 10.0571L2.59917 11.9447ZM5.33256 13.4813L2.31915 13.7797L2.58218 16.4362L5.59559 16.1378L5.33256 13.4813ZM2.68525 14.1458L2.98361 11.1324L0.327112 10.8694L0.0287492 13.8828L2.68525 14.1458ZM2.31915 13.7797C2.52936 13.7589 2.70606 13.9356 2.68525 14.1458L0.0287492 13.8828C-0.116417 15.3489 1.11603 16.5814 2.58218 16.4362L2.31915 13.7797ZM12.8569 4.5883C13.1167 4.8481 13.1167 5.26932 12.8569 5.52912L14.7446 7.41673C16.0469 6.11443 16.0469 4.00299 14.7446 2.70069L12.8569 4.5883ZM13.7643 1.72041C12.462 0.418106 10.3505 0.418109 9.04823 1.72041L10.9358 3.60802C11.1956 3.34822 11.6169 3.34822 11.8767 3.60802L13.7643 1.72041Z"
        fill="#13142A"
        mask="url(#path-2-inside-1_2776_13128)"
      />
    </g>
    <defs>
      <clipPath id="clip0_2776_13128">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default DashPencil;
