import React from 'react';

const Camera = (props) => (
  <svg
    viewBox="0 0 14.042 11.361"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <style>{`.cameraIconClassA{fill:#333;}.cameraIconClassB{fill:#fff;}`}</style>
    </defs>
    <g transform="translate(-744.145 -67.262)">
      <path
        className="cameraIconClassA"
        d="M756.734,69.262h-2.158a.684.684,0,0,1-.62-.4l-.554-1.206a.681.681,0,0,0-.619-.4h-3.234a.684.684,0,0,0-.62.4l-.554,1.206a.682.682,0,0,1-.62.4H745.6a1.451,1.451,0,0,0-1.452,1.452V77.17a1.452,1.452,0,0,0,1.452,1.454h11.137a1.454,1.454,0,0,0,1.453-1.454V70.714A1.452,1.452,0,0,0,756.734,69.262Z"
      />
      <circle
        r="3.317"
        cx="3.317"
        cy="3.317"
        className="cameraIconClassB"
        transform="translate(747.849 70.48)"
      />
      <circle
        r="2.345"
        cx="2.345"
        cy="2.345"
        className="cameraIconClassA"
        transform="translate(748.821 71.452)"
      />
    </g>
  </svg>
);

export default Camera;
