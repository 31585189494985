import React from 'react';

const ExternalLink = ({ fill = 'currentColor', ...props }) => (
  <svg
    fill={fill}
    width="12.26"
    height="12.042"
    viewBox="0 0 12.26 12.042"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-423.664 -8246.079)">
      <g transform="translate(422.75 8244.76)">
        <g transform="translate(0)">
          <path
            className="a"
            d="M429.318,8248.1a.869.869,0,0,0-.876-.86h-3.065a2.607,2.607,0,0,0-2.627,2.58v6.881a2.6,2.6,0,0,0,2.627,2.581h7.006a2.6,2.6,0,0,0,2.627-2.581v-2.149a.876.876,0,0,0-1.751,0v2.149a.87.87,0,0,1-.876.86h-7.006a.87.87,0,0,1-.876-.86v-6.881a.869.869,0,0,1,.876-.859h3.065a.874.874,0,0,0,.619-.25A.855.855,0,0,0,429.318,8248.1Z"
            transform="translate(-421.836 -8245.921)"
          />
          <path
            className="a"
            d="M428.129,8251.783a.88.88,0,0,0,.622-.259l3.792-3.782a.14.14,0,0,1,.207,0l1.037,1.034a.313.313,0,0,0,.208.086.363.363,0,0,0,.112-.022.3.3,0,0,0,.181-.271v-3.517a.292.292,0,0,0-.293-.293h-3.518a.292.292,0,0,0-.208.5l1.033,1.031a.157.157,0,0,1,.043.106.144.144,0,0,1-.043.1l-3.794,3.786a.886.886,0,0,0-.191.963A.877.877,0,0,0,428.129,8251.783Z"
            transform="translate(-421.114 -8243.439)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ExternalLink;
