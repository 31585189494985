const styles = () => ({
  page: {
    backgroundColor: '#F3F6F6',
  },
  propertiesLink: {
    display: 'flex',
    gap: '5px',
    fontSize: '18px',
    textDecoration: 'none',
    color: 'black',
  },
  logo: {
    minWidth: '20px',
    height: '16.25px',
    marginTop: '5px',
  },
  arrow: {
    marginTop: '12px',
  },
});

export default styles;
