const styles = (theme) => ({
  container: {
    minHeight: '680px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageDesktop: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    maxHeight: '245px',
  },
  imageMobile: {
    maxHeight: '245px',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  infoText: {
    width: '100%',
    textAlign: 'center',
    color: '#254E52',
    lineHeight: 1.5,
    marginBottom: theme.spacing(7),
  },
  getStartedBtn: {
    width: '142px',
    height: '50px',
    backgroundColor: '#254E52',
    marginBottom: theme.spacing(9),
    '&:hover': {
      backgroundColor: '#254E52',
    },
  },
});

export default styles;
