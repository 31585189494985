/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { OverviewBox } from './components';
import Card from '../../../../../components/Card';
import PropertyCompanies from '../../../../../modules/PropertyCompanies';
import useDesktop from '../../../../../hooks/useDesktop';
import { useFetchCompanies } from '../../../../../api/hooks';
import { useActiveProperty } from '../../../../../store/hooks';
import { getFilteredCompanies } from '../../../../../utils/companies';

export function PropertyCompaniesSection() {
  const isDesktop = useDesktop();
  const { property } = useActiveProperty();
  const companies = useFetchCompanies(property);

  const sortedCompanyList = (companyList) =>
    getFilteredCompanies({
      companies: companyList || [],
      searchQuery: undefined,
    });

  return (
    <OverviewBox>
      <Card border={isDesktop ? 1 : 0} padding={isDesktop ? 3 : 0}>
        <PropertyCompanies
          companies={sortedCompanyList(companies.data)}
          error={companies.error}
          fetching={companies.isLoading || !companies.data}
        />
      </Card>
    </OverviewBox>
  );
}
