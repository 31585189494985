import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
/* import 'react-step-progress-bar/styles.css'; */
/* import { ProgressBar, Step } from 'react-step-progress-bar'; */
import { withStyles } from '@material-ui/core';

import styles from './styles';
import Box from '../../components/atomic/Box';
import Grid from '../../components/atomic/Grid';
import useDesktop from '../../hooks/useDesktop';
import Typography from '../../components/atomic/Typography';
import ContentLoader from '../../components/loaders/ContentLoader';
/* import BYBFStep from '../../assets/icons/BYBFStep'; */
import Button from '../../components/atomic/Button';
import ArrowBackward from '../../assets/icons/ArrowBackward';
import ArrowForward from '../../assets/icons/ArrowForward';
import CheckCircle from '../../assets/icons/CheckCircle';
import { sGetActiveProperty } from '../../store/reducers/properties';
import {
  acBYBFRoomsFlowCompleted,
  acBYBFRoomsRoomsCreated,
  acBYBFRoomsSetActiveStep,
  acBYBFSetRooms,
} from '../../store/actions/bybfRooms';
import {
  acBYBFClear,
  acBYBFSetFetching,
  acBYBFSetFetchingError,
  acBYBFSetStepCompleted,
} from '../../store/actions/bybf';
import {
  sGetBYBFRoomsCreated,
  sGetBYBFRoomsFlowCompleted,
} from '../../store/reducers/bybfRooms';
import {
  acBYBFEventsClear,
  acBYBFEventsSetActiveCategory,
  acBYBFEventsSetCategories,
} from '../../store/actions/bybfEvents';
import { getBuildBoligmappaState } from '../../api/properties';
import { getPropertyBoligmappaNumber } from '../../utils/properties';
import BYBFRooms from '../../views/PrivateRoutes/Dashboard/BuildBoligmappa/BYBFRooms';
import BYBFEvents from '../../views/PrivateRoutes/Dashboard/BuildBoligmappa/BYBFEvents';
import { sGetEventsCategories } from '../../store/reducers/bybfEvents';
import {
  /* getCompletedSteps, */
  getFinalStep,
  getNextStep,
  getPrevStep,
  /* getStepsExceptFinalStep, */
  isFirstStep,
  isLastStep,
  getCategoryCompleted,
  getStepByCategory,
} from './utils';
import { sGetBYBFSteps } from '../../store/reducers/bybf';
import SuccessImage from '../../assets/images/static-success2.png';
import SuccessImageMobile from '../../assets/images/static-success.png';
import matomo from '../../utils/matomo';

const BYBFSummary = ({
  classes,
  activeProperty,
  clearBYBF,
  setFetching,
  setFetchingError,
  steps,
  setStepCompleted,
  setRooms,
  setRoomsActiveStep,
  setRoomsCreated,
  setRoomsFlowCompleted,
  roomsCreatedStatus,
  floorCompletedStatus,
  setEventsActiveCategory,
  setEventsCategories,
  eventCategories,
  clearBYBFEvents,
  minActionButton,
}) => {
  const isDesktop = useDesktop();

  const [boligmappaNumber, setBoligmappaNumber] = useState('');

  const [showRoomsDialog, setShowRoomsDialog] = useState(false);
  const [showEventsDialog, setShowEventsDialog] = useState(false);

  const [isRoomsStateSet, setIsRoomsStateSet] = useState(false);
  const [isEventsStateSet, setIsEventsStateSet] = useState(false);
  const [isInitialStepsSet, setIsInitialStepsSet] = useState(false);

  /*   const [progress, setProgress] = useState(0); */
  const [currentStep, setCurrentStep] = useState(null);

  /* const calculateProgress = () => {
    const actualSteps = getStepsExceptFinalStep(steps);
    const stepsCompleted = getCompletedSteps(actualSteps);

    const percentage = (100 / actualSteps.length) * stepsCompleted.length;

    setProgress(percentage);
  }; */

  const setInitialStepStatus = () => {
    setStepCompleted('ROOMS_CREATED', roomsCreatedStatus);
    setStepCompleted('ROOMS_DETAILS', floorCompletedStatus);
    setStepCompleted(
      'WETROOMS',
      getCategoryCompleted(eventCategories, 'WETROOMS')
    );
    setStepCompleted(
      'ELECTRICAL',
      getCategoryCompleted(eventCategories, 'ELECTRICAL')
    );
    setStepCompleted(
      'OUTDOORS',
      getCategoryCompleted(eventCategories, 'OUTDOORS')
    );
    setStepCompleted('OTHER', getCategoryCompleted(eventCategories, 'OTHER'));
    setIsInitialStepsSet(true);
  };

  const setBYBFRoomsState = ({ roomsCreated, flowCompleted, stage, rooms }) => {
    setRoomsCreated(roomsCreated);
    setRoomsFlowCompleted(flowCompleted);
    setRoomsActiveStep(stage);
    setRooms(rooms);
    setIsRoomsStateSet(true);
  };

  const setBYBFEventsState = ({ categories }) => {
    if (categories) setEventsCategories(categories);
    setIsEventsStateSet(true);
  };

  const getBYBFState = async (boligNumber) => {
    clearBYBF();
    clearBYBFEvents();
    setFetching(true);
    setFetchingError(null);

    const [errorResponse, data] = await getBuildBoligmappaState(boligNumber);

    if (!errorResponse) {
      const { rooms, events } = data || {};

      if (rooms) {
        setBYBFRoomsState(JSON.parse(rooms.state));
      } else {
        setIsRoomsStateSet(true);
      }

      if (events) {
        setBYBFEventsState(JSON.parse(events.state));
      } else {
        clearBYBFEvents();
        setIsEventsStateSet(true);
      }
    } else {
      setFetchingError(errorResponse);
    }

    setFetching(false);
  };

  useEffect(() => {
    if (activeProperty) {
      const actualBoligmappaNumber =
        getPropertyBoligmappaNumber(activeProperty);
      if (actualBoligmappaNumber !== boligmappaNumber) {
        setBoligmappaNumber(actualBoligmappaNumber);
        getBYBFState(actualBoligmappaNumber);
      }
    }
  }, [activeProperty]);

  useEffect(() => {
    if (isRoomsStateSet && isEventsStateSet) {
      setInitialStepStatus();
    }
  }, [isRoomsStateSet, isEventsStateSet]);

  useEffect(() => {
    if (steps && isInitialStepsSet) {
      if (currentStep) {
        setCurrentStep(steps.find(({ id }) => id === currentStep.id));
      } else {
        setCurrentStep(
          steps.find(({ completed }) => !completed) || getFinalStep(steps)
        );
      }
      /* calculateProgress(); */
    }
  }, [steps, isInitialStepsSet]);

  const trackMatomoEvent = (eventName) => {
    matomo.clickEvent({
      category: 'Dashboard CTAs',
      action: 'Build Your Boligmappe',
      name: eventName,
    });
  };

  const openBYBFDialog = (step) => {
    if (step.section === 'ROOMS') {
      setRoomsActiveStep(step.id);
      setShowRoomsDialog(true);
    } else if (step.section === 'EVENTS') {
      setEventsActiveCategory(step.id);
      setShowEventsDialog(true);
    }

    if (!currentStep.completed) {
      switch (currentStep.category) {
        case 'ROOMS_CREATED':
          trackMatomoEvent('Create New Room from Build Your Boligmappe');
          break;
        case 'ROOMS_DETAILS':
          trackMatomoEvent('Edit Room Details from Build Your Boligmappe');
          break;
        case 'WETROOMS':
          trackMatomoEvent('Add Toilet/restrooms from Build Your Boligmappe');
          break;
        case 'ELECTRICAL':
          trackMatomoEvent('Add Electrical from Build Your Boligmappe');
          break;
        case 'OUTDOORS':
          trackMatomoEvent('Add Outdoor/facade from Build Your Boligmappe');
          break;
        case 'OTHER':
          trackMatomoEvent('Add Other work from Build Your Boligmappe');
          break;
        default:
          break;
      }
    }
  };

  const prevStep = () =>
    setCurrentStep((prevState) => getPrevStep(steps, prevState));

  const nextStep = () =>
    setCurrentStep((prevState) => getNextStep(steps, prevState));

  useEffect(() => {
    setStepCompleted('ROOMS_CREATED', roomsCreatedStatus);
  }, [roomsCreatedStatus]);

  useEffect(() => {
    setStepCompleted('ROOMS_DETAILS', floorCompletedStatus);
  }, [floorCompletedStatus]);

  useEffect(() => {
    if (steps && eventCategories) {
      eventCategories.forEach(({ category, completed }) => {
        const step = getStepByCategory(steps, category);

        if (step.completed !== completed) {
          setStepCompleted(category, completed);
        }
      });
    }
  }, [steps, eventCategories]);

  /* const renderProgress = () => (
    <Box mt={4}>
      <ProgressBar
        percent={progress}
        stepPositions={[100]}
        filledBackground="linear-gradient(to right, #13A085, #0A5043)"
      >
        <Step transition="scale">
          {({ accomplished }) => (
            <BYBFStep
              style={{
                display: accomplished ? 'block' : 'none',
              }}
              width="20"
              height="20"
            />
          )}
        </Step>
      </ProgressBar>
    </Box>
  ); */

  const prevButton = (
    <Box
      display="flex"
      alignItems="center"
      className={classes.btnContainer}
      onClick={prevStep}
    >
      <ArrowBackward
        strokeWidth={1}
        height={13}
        width={10}
        className={classes.prevIcon}
      />
      <Typography className={classes.btnText}>Forrige</Typography>
    </Box>
  );

  const navigationButtons = (
    <Grid container className={classes.navButtons}>
      <Grid item xs={isDesktop ? 4 : 6}>
        {!isFirstStep(currentStep, steps) && prevButton}
      </Grid>
      {!isLastStep(currentStep, steps) && (
        <Grid item xs={isDesktop ? 4 : 6}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={!isDesktop ? 'flex-end' : ''}
            className={classes.btnContainer}
            onClick={nextStep}
          >
            <Typography className={classes.btnText}>Neste</Typography>
            <ArrowForward
              strokeWidth={1}
              height={13}
              width={10}
              className={classes.nextIcon}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );

  const actionButton = !isLastStep(currentStep, steps) && (
    <Box mb={isDesktop ? 0 : 2}>
      <Button
        color="primary"
        variant="contained"
        className={minActionButton ? classes.minActionBtn : classes.actionBtn}
        onClick={() => openBYBFDialog(currentStep)}
      >
        {currentStep?.completed ? currentStep?.actionMore : currentStep?.action}
      </Button>
    </Box>
  );

  return (
    <Box height="100%">
      {currentStep ? (
        <Box className={classes.container}>
          <Box>
            <Typography
              paragraph
              variant="h2"
              fontWeight={800}
              marginTop={false}
              align="left"
              fontSize={!isDesktop && 18}
            >
              Bygg din boligmappe
            </Typography>

            {currentStep.title && (
              <Box
                mt={isDesktop ? 3 : 1}
                mb={1}
                display="flex"
                alignItems="center"
                height="20px"
              >
                <Typography className={classes.stepTitle}>
                  {currentStep.title}
                </Typography>
                {currentStep.completed && (
                  <CheckCircle className={classes.checkIcon} />
                )}
              </Box>
            )}

            {isLastStep(currentStep, steps) ? (
              <Box mt={3}>
                <Grid container spacing={2}>
                  <Grid item xs={isDesktop ? 4 : 6}>
                    <img
                      alt="Success"
                      src={isDesktop ? SuccessImage : SuccessImageMobile}
                      className={isDesktop && classes.imageDesktop}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Box mt={1} mb={2} className={classes.stepContainer}>
                      <Typography className={classes.stepDesc}>
                        {currentStep.desc}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Typography className={classes.stepDesc}>
                {currentStep.desc}
              </Typography>
            )}
          </Box>

          <Box mt={2} mb={isDesktop ? 0 : -2}>
            <Grid container>
              <Grid item xs={12} md={6}>
                {isDesktop ? navigationButtons : actionButton}
              </Grid>
              <Grid item xs={12} md={6}>
                {isDesktop ? actionButton : navigationButtons}
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box>
          <ContentLoader display="inline-flex" height={30} width="50%" />
          <Box mt={2}>
            <ContentLoader display="inline-flex" height={20} width="30%" />
          </Box>
          <Box mt={2}>
            <ContentLoader display="inline-flex" height={30} width="100%" />
          </Box>
          <Box mt={2}>
            <ContentLoader display="inline-flex" height={30} width="100%" />
          </Box>
        </Box>
      )}

      <BYBFRooms
        open={showRoomsDialog}
        onClose={() => {
          setShowRoomsDialog(false);
        }}
      />

      <BYBFEvents
        open={showEventsDialog}
        onClose={() => {
          setShowEventsDialog(false);
        }}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  activeProperty: sGetActiveProperty(state),
  floorCompletedStatus: sGetBYBFRoomsFlowCompleted(state),
  roomsCreatedStatus: sGetBYBFRoomsCreated(state),
  steps: sGetBYBFSteps(state),
  eventCategories: sGetEventsCategories(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearBYBF: () => dispatch(acBYBFClear()),
  setFetching: (fetching) => dispatch(acBYBFSetFetching(fetching)),
  setFetchingError: (error) => dispatch(acBYBFSetFetchingError(error)),
  setStepCompleted: (category, completed) =>
    dispatch(acBYBFSetStepCompleted(category, completed)),
  setRooms: (rooms) => dispatch(acBYBFSetRooms(rooms)),
  setRoomsActiveStep: (step) => dispatch(acBYBFRoomsSetActiveStep(step)),
  setRoomsFlowCompleted: (status) => dispatch(acBYBFRoomsFlowCompleted(status)),
  setRoomsCreated: (status) => dispatch(acBYBFRoomsRoomsCreated(status)),
  setEventsActiveCategory: (status) =>
    dispatch(acBYBFEventsSetActiveCategory(status)),
  setEventsCategories: (categories) =>
    dispatch(acBYBFEventsSetCategories(categories)),
  clearBYBFEvents: () => dispatch(acBYBFEventsClear()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BYBFSummary));
