export const defaultState = {
  openGlobleAddMenu: false,
};

export const SET_OPEN_GLOBLE_ADD_MENU = 'SET_OPEN_GLOBLE_ADD_MENU';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_OPEN_GLOBLE_ADD_MENU:
      return { ...state, openGlobleAddMenu: action.payload };
    default:
      return state;
  }
};

export const sGetActionModule = (state) => state.action;
export const sGetGlobleAddMenuState = (state) =>
  sGetActionModule(state).openGlobleAddMenu;
