import { navbarHeight } from '../../../../components/MobileNavbar/style';

const styles = (theme) => ({
  actionButton: ({ isFromPersonal }) => ({
    right: '20px',
    zIndex: '1200',
    position: 'fixed',
    bottom: !isFromPersonal ? `${navbarHeight + 20}px` : '50px !important',
    background: theme.palette.primary.blackGreen,
    color: '#fff',

    '&:hover': {
      background: theme.palette.primary.lighterGreen,
    },

    '@supports (bottom: env(safe-area-inset-bottom))': {
      bottom: `calc(env(safe-area-inset-bottom) * 0.6 + ${
        navbarHeight + 20
      }px)`,
    },
  }),
  actionButtonOpen: {
    transform: 'translateY(200px)',
  },
  SwipeableDrawer: {
    padding: '0 0 32px 0',
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '19px 25.5px 17px 16px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    margin: 'unset',
    color: '#000',
  },
  closeButton: {
    padding: 0,
    border: 0,
    background: 'unset',
  },
  menuList: {
    padding: '15px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  menuItem: {
    fontSize: '14px',
    color: 'inherit',
    textDecoration: 'none',

    '&:last-child': {
      marginRight: 0,
    },
  },
  menuItemText: {
    marginTop: '5px',
    textAlign: 'center',
  },
  iconContainer: {
    width: '60px',
    height: '60px',
    margin: '0 auto',
    textAlign: 'center',
    borderRadius: '30px',
    background: theme.palette.primary.main,
  },
  icon: {
    top: '13px',
    width: '30px',
    height: '30px',
    position: 'relative',
    color: theme.palette.primary.white,
  },
});

export default styles;
