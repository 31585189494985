import { createElement } from 'react';
import { isArray } from 'lodash';
import DeleteIcon from '../../assets/icons/DocumentBulkEditIcons/DocumentsDeleteIcon';
import DownloadIcon from '../../assets/icons/DocumentBulkEditIcons/DownloadIcon';
import EditDocumentIcon from '../../assets/icons/DocumentBulkEditIcons/EditDocumentIcon';
import EventIcon from '../../assets/icons/DocumentBulkEditIcons/EventIcon';
import MoveDocumentIcon from '../../assets/icons/MoveDocumentIcon';
import CopyDocumentIcon from '../../assets/icons/CopyDocumentIcon';
import MoreOptionsIcon from '../../assets/icons/DocumentBulkEditIcons/MoreOptionsIcon';
import RoomIcon from '../../assets/icons/DocumentBulkEditIcons/RoomIcon';
import EditOptionItem from './EditOptionItem';
import EditOptionMobileItem from './DocumentBulkEditMobile/EditOptionMobileItem';
import CheckboxCell from '../DocumentView/DocumentViewTable/Table/CheckboxCell';
import TableHeaderCellForDocumentViewTable from '../DocumentView/DocumentViewTable/Table/TableHeaderCell';
import {
  ATTACH_EVENT_EDIT_OPTION,
  ATTACH_ROOM_EDIT_OPTION,
  COPY_DOCUMENT_TO_PROPERTY_OPTION,
  MOVE_DOCUMENT_TO_PROPERTY_OPTION,
  DOCUMENT_DELETE_OPTION,
  DOCUMENT_DOWNLOAD_OPTION,
  DOCUMENT_EVENT_FILTER,
  DOCUMENT_OWNER_ASC,
  DOCUMENT_OWNER_DESC,
  DOCUMENT_PROFESSION_TYPE_FILTER,
  DOCUMENT_ROOM_FILTER,
  DOCUMENT_TYPE_ASC,
  DOCUMENT_TYPE_DESC,
  DOCUMENT_TYPE_EDIT_OPTION,
  DOCUMENT_TYPE_FILTER,
  FILE_TYPE_FILTER,
  FILE_UPLOADED_NAME_FILTER,
  FILETYPE_ASC,
  FILETYPE_DESC,
  getDocumentCompanyName,
  getDocumentEvents,
  getDocumentFileType,
  getDocumentId,
  getDocumentName,
  getDocumentOwnedByName,
  getDocumentRooms,
  getDocumentType,
  getDocumentTypeId,
  NAME_ASC,
  NAME_DESC,
  STATIC_COLUMN,
  UPDATED_DATE_ASC,
  UPDATED_DATE_DESC,
  MOVE_BULK_DOCUMENT_TO_PERSONAL_OPTION,
  DOCUMENT_DELETE_OPTION_PERSONAL,
} from '../../utils/documents';
import {
  compareAlphabetically,
  toLowerCase,
  toUpperCase,
} from '../../utils/strings';

import { getRoomId } from '../../utils/rooms';
import { getEventId } from '../../utils/events';
import { getFileTypeIcon, onListItemClick } from '../DocumentFilters/util';
import matomo from '../../utils/matomo';
import CombinedHeaderCell from '../DocumentView/DocumentViewTable/Table/CombinedHeaderCell';

export const onOptionItemClick = (
  option,
  options,
  onOptionsChanged,
  processOption = (incomingOption) => incomingOption
) =>
  options.map(processOption).includes(processOption(option))
    ? onOptionsChanged(
        options.filter(
          (_option) => processOption(_option) !== processOption(option)
        )
      )
    : processOption([...options, processOption(option)]);

const trackMatomoEvent = (filter) => {
  matomo.clickEvent({
    category: 'Document handling',
    name: `Filter by ${filter}`,
    action: 'Filter Document List',
  });
};

export const getEditOptionTypes = (
  personaldocument,
  isFromPersonal,
  isBoligmappaPlusUser
) => {
  return [
    {
      name: 'Knytt til rom',
      icon: RoomIcon,
      type: ATTACH_ROOM_EDIT_OPTION,
      enabled: 'true',
      border: false,
      isVisible: !personaldocument,
      showOnMobile: true,
      directAction: false,
      desktopComponent: EditOptionItem,
      mobileComponent: EditOptionMobileItem,
      editOptions: (options, selectedOptions = [], onOptionsSelect) =>
        (options || []).map((opt) => ({
          name: opt.title,
          label: opt.title,
          id: opt.id,
          checked: selectedOptions.map((e) => e.id).includes(opt.id),
          onClick: () => {
            if (selectedOptions.map((e) => e.id).includes(opt.id)) {
              onOptionsSelect(
                selectedOptions.filter((_option) => _option.id !== opt.id)
              );
            } else {
              onOptionsSelect([...selectedOptions, opt]);
            }
          },
        })),
      getProps: () => ({
        style: { padding: '0 0px 0 14px', border: 'none' },
      }),
      onMenuSave: (onSaveOption) => {
        onSaveOption(true);
      },
    },
    {
      name: 'Knytt til hendelse',
      icon: EventIcon,
      type: ATTACH_EVENT_EDIT_OPTION,
      enabled: 'true',
      border: false,
      showOnMobile: true,
      isVisible: !personaldocument,
      directAction: false,
      desktopComponent: EditOptionItem,
      mobileComponent: EditOptionMobileItem,
      editOptions: (options, selectedOptions = [], onOptionsSelect) =>
        (options || []).map((opt) => ({
          name: opt.title,
          label: opt.title,
          id: opt.id,
          checked: selectedOptions.map((e) => e.id).includes(opt.id),
          onClick: () => {
            if (selectedOptions.map((e) => e.id).includes(opt.id)) {
              onOptionsSelect(
                selectedOptions.filter((_option) => _option.id !== opt.id)
              );
            } else {
              onOptionsSelect([...selectedOptions, opt]);
            }
          },
        })),
      onFiltersReset: () => {},
      getProps: () => ({
        style: { padding: '0 0px 0 14px', border: 'none' },
      }),
      onMenuSave: (onSaveOption) => {
        onSaveOption(true);
      },
    },
    {
      name: 'Flytt til boligens område',
      icon: MoveDocumentIcon,
      type: MOVE_DOCUMENT_TO_PROPERTY_OPTION,
      enabled: 'true',
      border: false,
      showOnMobile: true,
      directAction: true,
      isVisible: isBoligmappaPlusUser && personaldocument,
      desktopComponent: EditOptionItem,
      mobileComponent: EditOptionMobileItem,
      editOptions: (options, selectedOptions = [], onOptionsSelect) =>
        (options || []).map((opt) => ({
          name: opt.name,
          label: opt.boligmappaNumber,
          id: opt.id,
          checked: selectedOptions.map((e) => e.id).includes(opt.id),
          onClick: () => {
            if (selectedOptions.map((e) => e.id).includes(opt.id)) {
              onOptionsSelect(
                selectedOptions.filter((_option) => _option.id !== opt.id)
              );
            } else {
              onOptionsSelect([...selectedOptions, opt]);
            }
          },
        })),
      onFiltersReset: () => {},
      getProps: () => ({
        style: { padding: '0 0px 0 14px', border: 'none' },
      }),
      onMenuSave: (onSaveOption) => {
        onSaveOption(true);
      },
    },
    {
      name: 'Kopier til boligens område',
      icon: CopyDocumentIcon,
      type: COPY_DOCUMENT_TO_PROPERTY_OPTION,
      enabled: 'true',
      border: false,
      showOnMobile: true,
      isVisible: isBoligmappaPlusUser && personaldocument,
      directAction: true,
      desktopComponent: EditOptionItem,
      mobileComponent: EditOptionMobileItem,
      editOptions: (options, selectedOptions = [], onOptionsSelect) =>
        (options || []).map((opt) => ({
          name: opt.name,
          label: opt.boligmappaNumber,
          id: opt.id,
          checked: selectedOptions.map((e) => e.id).includes(opt.id),
          onClick: () => {
            if (selectedOptions.map((e) => e.id).includes(opt.id)) {
              onOptionsSelect(
                selectedOptions.filter((_option) => _option.id !== opt.id)
              );
            } else {
              onOptionsSelect([...selectedOptions, opt]);
            }
          },
        })),
      onFiltersReset: () => {},
      getProps: () => ({
        style: { padding: '0 0px 0 14px', border: 'none' },
      }),
      onMenuSave: (onSaveOption) => {
        onSaveOption(true);
      },
    },
    {
      name: 'Endre dokumenttype',
      icon: EditDocumentIcon,
      type: DOCUMENT_TYPE_EDIT_OPTION,
      enabled: 'true',
      border: false,
      showOnMobile: true,
      isVisible: !personaldocument,
      directAction: false,
      desktopComponent: EditOptionItem,
      mobileComponent: EditOptionMobileItem,
      editOptions: (options, selectedOptions = []) =>
        (options || []).map((opt) => ({
          ...opt,
          label: opt.name,
          checked: selectedOptions
            .map((e) => e.name)
            .map(toLowerCase)
            .includes(toLowerCase(opt.name)),
        })),
      getProps: () => ({
        style: { padding: '0 0px 0 14px', border: 'none' },
      }),
      onMenuSave: (onSaveOption) => {
        onSaveOption(true);
      },
    },
    {
      name: 'Last ned',
      icon: DownloadIcon,
      type: DOCUMENT_DOWNLOAD_OPTION,
      enabled: 'true',
      border: true,
      showOnMobile: true,
      directAction: true,
      isVisible: true,
      desktopComponent: EditOptionItem,
      mobileComponent: EditOptionMobileItem,
      getProps: () => ({
        style: { padding: '0 0px 0 14px', border: 'none' },
      }),
      menuOption: (onDownload) => {
        onDownload(true);
      },
    },
    {
      name: 'Flere valg',
      icon: MoreOptionsIcon,
      type: DOCUMENT_DELETE_OPTION,
      enabled: 'true',
      border: true,
      showOnMobile: true,
      directAction: false,
      isVisible: !personaldocument,
      desktopComponent: EditOptionItem,
      mobileComponent: EditOptionMobileItem,
      editOptions: (options) =>
        (options || []).map((opt) => ({
          name: opt.title,
          label: opt.title,
          icon: opt.icon,
          onClick: () => opt.onClick(),
        })),
      onFiltersReset: () => {},
      getProps: () => ({
        style: { padding: '0 0px 0 14px', border: 'none' },
      }),
    },
    {
      name: 'Slett',
      icon: DeleteIcon,
      type: DOCUMENT_DELETE_OPTION_PERSONAL,
      enabled: 'true',
      border: true,
      showOnMobile: true,
      directAction: true,
      desktopComponent: EditOptionItem,
      mobileComponent: EditOptionMobileItem,
      isVisible: personaldocument,
      editOptions: () => {},
      onFiltersReset: () => {},
      getProps: () => ({
        style: { padding: '0 0px 0 14px', border: 'none', color: 'red' },
      }),
    },
  ];
};

export const getMobileEditOptionTypes = (
  isPersonalDocuments,
  isFromPersonal,
  isBoligmappaPlusUser
) => {
  return [
    ...getEditOptionTypes(
      isPersonalDocuments,
      isFromPersonal,
      isBoligmappaPlusUser
    ).filter((a) => a.type !== DOCUMENT_DELETE_OPTION),
    ...[
      {
        name: 'Kopier til personlig område',
        icon: CopyDocumentIcon,
        type: MOVE_BULK_DOCUMENT_TO_PERSONAL_OPTION,
        enabled: 'true',
        border: true,
        showOnMobile: true,
        desktopComponent: EditOptionItem,
        mobileComponent: EditOptionMobileItem,
        isVisible: !isPersonalDocuments,
        editOptions: () => {},
        onFiltersReset: () => {},
        getProps: () => ({
          style: { padding: '0 0px 0 14px', border: 'none' },
        }),
      },
      {
        name: 'Slett',
        icon: DeleteIcon,
        type: DOCUMENT_DELETE_OPTION,
        enabled: 'true',
        border: true,
        showOnMobile: true,
        desktopComponent: EditOptionItem,
        mobileComponent: EditOptionMobileItem,
        isVisible: !isPersonalDocuments, // Keep this until we create the bulk delete api for personal documents,
        editOptions: () => {},
        onFiltersReset: () => {},
        getProps: () => ({
          style: { padding: '0 0px 0 14px', border: 'none', color: 'red' },
        }),
      },
    ],
  ];
};

export const getDocTableHeaderItems = (personaldocument) => {
  return [
    {
      name: 'Checkbox',
      type: STATIC_COLUMN,
      isVisibleForPersonalDoc: true,
      desktopComponent: CheckboxCell,
      getProps: () => ({
        style: {
          padding: 0,
        },
      }),
    },
    {
      name: 'Navn',
      type: FILE_TYPE_FILTER,
      desktopComponent: TableHeaderCellForDocumentViewTable,
      getProps: () => ({
        style: {
          padding: '12px 0 12px 12px',
          width: 'auto',
        },
      }),
      getFilterOptions: (documents, filters = [], onFiltersChanged) =>
        (documents || [])
          .reduce(
            (accumulator, document) =>
              accumulator.includes(getDocumentFileType(document))
                ? accumulator
                : [...accumulator, getDocumentFileType(document)],
            []
          )
          .sort(compareAlphabetically)
          .map((fileType) => ({
            name: fileType,
            label: toUpperCase(fileType),
            checked: filters.map(toLowerCase).includes(toLowerCase(fileType)),
            icon: createElement(getFileTypeIcon(fileType)),
            onClick: () => {
              trackMatomoEvent('Filtype');
              onListItemClick(fileType, filters, onFiltersChanged, (filter) =>
                toUpperCase(filter)
              );
            },
          })),
      sortable: true,
      sortUpType: NAME_DESC,
      sortDownType: NAME_ASC,
      isVisibleForPersonalDoc: true,
      childrenComponent: {
        name: 'Filtype',
        type: FILE_TYPE_FILTER,
        getProps: () => ({
          style: {
            padding: '12px 0',
          },
        }),
        filterable: true,
        sortable: true,
        sortUpType: FILETYPE_DESC,
        sortDownType: FILETYPE_ASC,
      },
    },
    {
      name: 'Dato',
      type: STATIC_COLUMN,
      code: `${STATIC_COLUMN}date`,
      desktopComponent: TableHeaderCellForDocumentViewTable,
      sortable: true,
      sortUpType: UPDATED_DATE_DESC,
      sortDownType: UPDATED_DATE_ASC,
      isVisibleForPersonalDoc: true,
      getProps: () => ({
        style: {
          minWidth: 80,
          width: 80,
          padding: '12px 0',
        },
      }),
    },
    {
      name: 'Type',
      type: DOCUMENT_TYPE_FILTER,
      desktopComponent: TableHeaderCellForDocumentViewTable,
      getFilterOptions: (documents, filters = [], onFiltersChanged) =>
        (documents || [])
          .reduce(
            (accumulator, document) =>
              accumulator.includes(getDocumentType(document))
                ? accumulator
                : [...accumulator, getDocumentType(document)],
            []
          )
          .sort(compareAlphabetically)
          .map((documentType) => ({
            name: documentType,
            label: documentType,
            checked: filters.includes(documentType),
            onClick: () => {
              trackMatomoEvent('Document Type');
              onListItemClick(documentType, filters, onFiltersChanged);
            },
          })),
      filterable: true,
      sortable: true,
      isVisibleForPersonalDoc: !personaldocument,
      customAlign: {
        marginLeft: '-10px',
      },
      sortUpType: DOCUMENT_TYPE_DESC,
      sortDownType: DOCUMENT_TYPE_ASC,
      getProps: () => ({
        style: {
          width: 190,
          minWidth: 190,
          padding: '12px 0',
        },
      }),
    },
    {
      name: 'Avsender',
      type: FILE_UPLOADED_NAME_FILTER,
      desktopComponent: TableHeaderCellForDocumentViewTable,
      getFilterOptions: (documents, filters = [], onFiltersChanged) =>
        (documents || [])
          .reduce((accumulator, document) => {
            const uploaderName =
              getDocumentOwnedByName(document) ||
              getDocumentCompanyName(document);
            return accumulator.find(
              (item) => item.uploaderName === uploaderName
            )
              ? accumulator
              : [...accumulator, { uploaderName }];
          }, [])
          .sort((a, b) => compareAlphabetically(a.uploaderName, b.uploaderName))
          .map(({ icon, uploaderName }) => ({
            icon,
            name: uploaderName,
            label: uploaderName,
            checked: filters.includes(uploaderName),
            onClick: () => {
              trackMatomoEvent('Sender');
              onListItemClick(uploaderName, filters, onFiltersChanged);
            },
          })),
      filterable: true,
      sortable: true,
      isVisibleForPersonalDoc: !personaldocument,
      customAlign: {
        marginLeft: '-10px',
      },
      sortUpType: DOCUMENT_OWNER_DESC,
      sortDownType: DOCUMENT_OWNER_ASC,
      getProps: () => ({
        style: {
          padding: '12px 0',
        },
      }),
    },
    {
      name: ['Fag', 'Rom', 'Hendelser'],
      type: [
        DOCUMENT_PROFESSION_TYPE_FILTER,
        DOCUMENT_ROOM_FILTER,
        DOCUMENT_EVENT_FILTER,
      ],
      isVisibleForPersonalDoc: !personaldocument,
      desktopComponent: CombinedHeaderCell,
      getProps: () => ({
        style: {
          width: 270,
          minWidth: 270,
          padding: '12px 0',
        },
      }),
      filterable: true,
    },
    {
      name: '',
      desktopOnly: true,
      type: STATIC_COLUMN,
      code: 'STATIC_COLUMN_2',
      desktopComponent: TableHeaderCellForDocumentViewTable,
      getProps: () => ({
        style: {},
      }),
      onMenuSave: (onSaveOption) => {
        onSaveOption(true);
      },
    },
  ];
};

export const transformDocumentData = ({
  documents,
  events,
  rooms,
  documentType,
}) => {
  const transformedPutData = [];

  documents.forEach((document) => {
    const documentId = getDocumentId(document);
    const title = getDocumentName(document);

    const transformedObject = {
      Id: documentId,
      documentTypeId:
        documentType != null ? documentType : getDocumentTypeId(document) ?? 0,
      eventIds:
        isArray(events) && events?.length > 0
          ? [...(getDocumentEvents(document) || []), ...events]
              .map((event) => getEventId(event))
              .filter((value, index, array) => array.indexOf(value) === index)
          : (getDocumentEvents(document) || [])
              .map((event) => getEventId(event))
              .filter((value, index, array) => array.indexOf(value) === index),
      roomIds:
        isArray(rooms) && rooms?.length > 0
          ? [...(getDocumentRooms(document) || []), ...rooms]
              .map((room) => getRoomId(room))
              .filter((value, index, array) => array.indexOf(value) === index)
          : (getDocumentRooms(document) || [])
              .map((room) => getRoomId(room))
              .filter((value, index, array) => array.indexOf(value) === index),
      title,
    };

    transformedPutData.push(transformedObject);
  });
  return transformedPutData;
};
