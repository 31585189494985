import clsx from 'clsx';
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Box from '../../atomic/Box';
import Container from '../Container';
import useDesktop from '../../../hooks/useDesktop';
import useSnackbar from '../../../hooks/useSnackbar';
import useHeaderSnackbar from '../../../hooks/useHeaderSnackbar';

const Dashboard = ({
  classes,
  children,
  className,
  desktopTopHeader,
  desktopHeaderNav,
  addButtonPaddingEnabled = false,
  addPublicButtomPaddingEnabled = false,
  mobileNavbarPaddingEnabled = false,
  isFullscreenPreviewMode = false,
  backgroundContentContainer = false,
}) => {
  const { snackbarHeight } = useHeaderSnackbar();
  const isDesktop = useDesktop();
  const { showSuccessMessage } = useSnackbar();
  useEffect(() => {
    const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(new URL(currentUrl).search);
    const isJustSubscribed = urlParams.get('subscribed');
    if (isJustSubscribed) {
      urlParams.delete('subscribed');
      const updatedQueryString = urlParams.toString();
      const updatedUrl =
        updatedQueryString === ''
          ? `${window.location.origin}${window.location.pathname}`
          : `${window.location.origin}${window.location.pathname}?${updatedQueryString}`;

      window.history.replaceState({}, document.title, updatedUrl);
      showSuccessMessage('Velkommen som medlem av Boligmappa+!');
    }
  }, []);
  return (
    <div
      className={clsx(
        className,
        addButtonPaddingEnabled && classes.addButtonPaddingEnabled,
        addPublicButtomPaddingEnabled && classes.addPublicButtomPaddingEnabled,
        mobileNavbarPaddingEnabled && classes.mobileNavbarPaddingEnabled
      )}
    >
      {isDesktop &&
        desktopTopHeader &&
        desktopHeaderNav &&
        !isFullscreenPreviewMode && (
          <Container
            className={classes.desktopHeader}
            style={{ top: snackbarHeight }}
          >
            {desktopTopHeader}
            {desktopHeaderNav}
          </Container>
        )}
      {!isFullscreenPreviewMode ? (
        <Container
          className={
            backgroundContentContainer && classes.backgroundContentContainer
          }
        >
          {isDesktop ? (
            <>
              <Box className={classes.desktopContent}>{children}</Box>
            </>
          ) : (
            children
          )}
        </Container>
      ) : (
        children
      )}
    </div>
  );
};

export default withStyles(styles)(Dashboard);
