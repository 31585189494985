import React from 'react';
import TabList from '../../../../../components/atomic/TabList';
import Tab from '../../../../../components/atomic/Tab';
import Typography from '../../../../../components/atomic/Typography';
import Box from '../../../../../components/atomic/Box';
import TabContext from '../../../../../components/atomic/TabContext';

const DocumentTabs = ({ classes, handleChange, handleTabChange, value }) => {
  return (
    <Box>
      <TabContext value={value}>
        <TabList
          onChange={handleChange}
          TabIndicatorProps={{ style: { background: 'none' } }}
          className={classes.tabs}
        >
          <Tab
            className={classes.tabItem}
            classes={{ root: 'rootClassname' }}
            label={
              <Typography className={classes.tabHeaderText}>
                Boligens område
              </Typography>
            }
            value="1"
            onClick={() => handleTabChange('false')}
          />
          <Tab
            className={classes.tabItem}
            classes={{ root: 'rootClassname' }}
            label={
              <Box className={classes.tabHeaderBox}>
                <Typography className={classes.tabHeaderText}>
                  Personlig område
                </Typography>
                <Typography className={classes.tabHeaderNewText}>
                  Ny!
                </Typography>
              </Box>
            }
            value="2"
            onClick={() => handleTabChange('true')}
          />
        </TabList>
      </TabContext>
    </Box>
  );
};

export default DocumentTabs;
