import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import Content from '../../../components/Insurance/Content';
import Prices from '../../../components/Insurance/Prices';
import FormSection from '../../../components/Insurance/FormSection';
import useDesktop from '../../../hooks/useDesktop';
import styles from './style';
import { sGetDocuments } from '../../../store/reducers/documents';
import useFetchEffect from '../../../hooks/useFetchEffect';
import { fetchDocuments as apiFetchDocuments } from '../../../api/properties';
import { acSetDocuments } from '../../../store/actions/documents';
import {
  getDocumentsCreatedAfterDate,
  getProfessionalDocuments,
  getRecentlylAddedUnInsuredProfessionalDocument,
} from '../../../utils/documents';
import WithHeader from '../../../components/layouts/WithHeader';

const InsurancePage = ({ classes, documents, setDocuments }) => {
  const [loading, setLoading] = useState(true);
  const [availableCompanies, setAvailableCompanies] = useState([]);
  const { boligmappaNumber } = useParams();
  const location = useLocation();

  useFetchEffect({
    dependencies: [boligmappaNumber],
    onSuccess: (data) => {
      setLoading(false);
      setDocuments(data);
    },
    apiFetchFunction: () =>
      apiFetchDocuments(boligmappaNumber, { includeTaggedInfo: true }),
  });

  const getUniqueCompanyNames = (docs) => {
    const uniqueCompanyNames = [];
    const uniqueCompanies = [];
    if (!docs) {
      return [];
    }
    docs.forEach((item) => {
      if (!uniqueCompanyNames.includes(item.companyName)) {
        uniqueCompanyNames.push(item.companyName);
        uniqueCompanies.push({
          companyName: item.companyName,
          companyId: item.companyId,
          documentId: item.id,
        });
      }
    });
    return uniqueCompanies;
  };

  useEffect(() => {
    if (documents) {
      const sevenDaysBack = new Date();
      sevenDaysBack.setDate(sevenDaysBack.getDate() - 7);
      const recentDocuments = getDocumentsCreatedAfterDate(
        documents,
        sevenDaysBack
      );
      const recentlyAddProfessionalDocs =
        getProfessionalDocuments(recentDocuments);
      const insurancePaidDocuments =
        location.search && location.search.includes(',')
          ? location.search
              .substring(1)
              .split(',')
              .map((item) => parseInt(item, 24))
          : [];

      const recentlyAddedUnInsuredDoc =
        getRecentlylAddedUnInsuredProfessionalDocument(
          recentlyAddProfessionalDocs,
          insurancePaidDocuments
        );
      const companyName = getUniqueCompanyNames(recentlyAddedUnInsuredDoc);
      if (companyName) {
        setAvailableCompanies(companyName);
      }
    }
  }, [documents]);
  const isDesktop = useDesktop();
  return (
    <WithHeader headerTitle="Trygt utført" noPadding>
      <div className={classes.page}>
        <div className={clsx([classes.wrapper, !isDesktop && 'mobile'])}>
          <Content />
          <Prices />
          {availableCompanies.length > 0 ? (
            <FormSection
              loading={loading}
              availableCompanies={availableCompanies}
            />
          ) : (
            <div>&nbsp;</div>
          )}
        </div>
      </div>
    </WithHeader>
  );
};
const mapStateToProps = (state) => ({
  documents: sGetDocuments(state),
});
const mapDispatchToProps = (dispatch) => ({
  setDocuments: (documents) => dispatch(acSetDocuments(documents)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InsurancePage));
