import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import clsx from 'clsx';
import Typography from '../../atomic/Typography';
import matomo from '../../../utils/matomo';
import { FAQStyles } from './styles';

export default function FAQ({ FAQList, classes = FAQStyles(), title }) {
  const [activeAccordion, setActiveAccordion] = useState([]);

  const onClickAccordion = (index) => {
    if (activeAccordion.includes(index)) {
      matomo.clickEvent({
        category: `FAQ_Question${index + 1}`,
        name: `FAQ_Question${index + 1}`,
        action: 'Close',
      });
      setActiveAccordion([]);
    } else {
      matomo.clickEvent({
        category: `FAQ_Question${index + 1}`,
        name: `FAQ_Question${index + 1}`,
        action: 'Open',
      });
      setActiveAccordion([index]);
    }
  };

  const isOpened = (index) => activeAccordion.includes(index);

  const boldifyText = (text) => {
    const parts = text.split('\b');
    if (parts.length > 1) return <b>{parts[1]}</b>;

    return text;
  };

  return (
    <div className={classes.faqSection}>
      <Typography className={classes.faqTitle}>{title}</Typography>
      {FAQList.map((item, index) => (
        <Accordion
          key={`item_${item.title}`}
          onClick={() => onClickAccordion(index)}
          className={classes.accordion}
          expanded={isOpened(index)}
        >
          <AccordionSummary className={classes.muiAccordionSummary}>
            <div
              className={clsx(
                classes,
                classes.accordionSummary,
                isOpened(index) && classes.openedAccordionSummary
              )}
            >
              <div
                className={clsx(
                  'accordionIconSection',
                  isOpened(index) && 'open'
                )}
              >
                {isOpened(index) ? (
                  <ArrowDownwardIcon className={classes.accordionIcon} />
                ) : (
                  <ArrowForwardIcon className={classes.accordionIcon} />
                )}
              </div>

              <Typography className={classes.accordionTitle}>
                {item.title}
              </Typography>
            </div>
          </AccordionSummary>

          <AccordionDetails>
            <Typography display="block" className={classes.accordionContent}>
              {item.content.split('\n').map((text, i) => {
                const uniqueKey = `${item.id || 'no-id'}-${i}`;
                return (
                  <span key={uniqueKey}>
                    {boldifyText(text)}
                    <br />
                  </span>
                );
              })}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
