import moment from 'moment';
import React, { useState } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import styles from './style';
import Link from '../../../../../components/atomic/Link';
import Form from '../../../../../components/forms/BaseForm';
import Checkbox from '../../../../../components/atomic/Checkbox';
import DatePicker from '../../../../../components/DateTimePicker';
import Typography from '../../../../../components/atomic/Typography';
import Box from '../../../../../components/atomic/Box';
import useDesktop from '../../../../../hooks/useDesktop';
import { PRIVACY_POLICY_LINK_PUBLIC_SHARING } from '../../../../../constants';

const ShareForm = ({
  onSubmit,
  onCancel,
  requestInProgress,
  error,
  requestError,
  classes,
}) => {
  const [endDate, setEndDate] = useState(null);
  const [errorLocal, setErrorLocal] = useState(null);
  const [isAgreedTnC, setIsAgreedTnC] = useState(false);
  const isDesktop = useDesktop();
  return (
    <>
      <Grid container={isDesktop}>
        <Grid item md={isDesktop && 12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.headerContainer}>
                <Typography variant="h2" className={classes.publicShareH2}>
                  Gi lesetilgang
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <p className={classes.publicShareContent}>
                  Når du deler din boligmappe offentlig opprettes det en unik
                  lenke som kan deles med feks. potensielle boligkjøpere slik at
                  de får et innblikk i eiendommens dokumentasjon og tilstand. De
                  får kun lesetilgang og kan ikke gjøre endringer.
                </p>
                <p className={classes.statuscontent4}>
                  <strong>Følgende vises for en offentlig delt mappe</strong>{' '}
                  <br />
                </p>
                <Box className={classes.publicsharelist}>
                  <ul>
                    <li>Historikk - alle hendelser med all informasjon</li>
                    <li>Rom - alle rom med all informasjon</li>
                    <li>
                      Boligens fagfolk - bedrifter som har jobbet på boligen.{' '}
                    </li>
                    <li>
                      Dokumenter - lastet opp av håndverkere og privatpersoner
                    </li>
                    <li>
                      Informasjon om boligen - adresse, matrikkel, bruksareal,
                      boligmappenummer, bygningstype, bygningsnummer og
                      bruksenhet.
                    </li>
                  </ul>
                </Box>
                <p className={classes.statuscontent3}>
                  Er det noe du mener ikke skal vises, så må du fjerne dette før
                  du deler.
                </p>

                <p className={classes.statuscontent2}>Delingsperiode</p>
                <p className={classes.publicShareContent1}>
                  Delingen blir aktiv fra og med dags dato frem til sluttdatoen
                  du velger nedenfor.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div
        className={
          isDesktop
            ? classes.datePickerContainerarea
            : classes.datePickerContainerarea
        }
      >
        <Form
          onSubmit={() => {
            if (!endDate) {
              setErrorLocal('Ugyldig dato!');
              return;
            }

            const endDateFormatted = moment(endDate).format('YYYY-MM-DD');
            onSubmit({ isAgreedTnC, endDate: endDateFormatted });
          }}
          onCancel={onCancel}
          submitButtonText="Neste"
          className={classes.readingShareForm}
          requestInProgress={requestInProgress}
          actionButtonsClassName={classes.ActionButtonsShare}
          error={requestError}
        >
          <Grid
            item
            xs={5}
            container
            spacing={1}
            className={classes.datepicker}
          >
            <Box>
              <DatePicker
                required
                isSimple
                isDesktop
                label="Sluttdato"
                value={endDate}
                dateSelected={endDate}
                placeholder="dd/mm/åååå"
                disabled={requestInProgress}
                onChange={setEndDate}
                minDate={new Date()}
                className={classes.publicsharedatepicker}
              />
            </Box>
          </Grid>

          {(error || errorLocal) && (
            <div className={classes.error}>{error || errorLocal}</div>
          )}

          <div className={classes.checkcontent}>
            <Checkbox
              checked={isAgreedTnC}
              onChange={(e) => setIsAgreedTnC(e.target.checked)}
              style={{ zIndex: 0 }}
            >
              <p className={classes.checkcontent}>
                Jeg godtar vilkårene for offentlig deling
              </p>
            </Checkbox>

            <p className={classes.helperUrl}>
              <Link
                isExternal
                openInNewTab
                to={PRIVACY_POLICY_LINK_PUBLIC_SHARING}
              >
                Les våre delingsvilkår
              </Link>
            </p>
          </div>
        </Form>
      </div>
    </>
  );
};

export default withStyles(styles)(ShareForm);
