import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Box from '../atomic/Box';
import RoomList from '../RoomList';
import Page from '../layouts/WithHeader';
import SearchField from '../SearchField';
import EmptyMessage from '../EmptyMessage';
import useDesktop from '../../hooks/useDesktop';
import ErrorContainer from '../ErrorContainer';
import { getFilteredRooms } from '../../utils/rooms';
import { acSetShowAddRoom } from '../../store/actions/rooms';
import { acSetDocumentSearchText } from '../../store/actions/documents';
import { AddNewButton } from '../Buttons';
import matomo from '../../utils/matomo';

const RoomsPage = ({
  rooms,
  fetching,
  fetchingError,
  publicSection = false,
  emptyMessage = 'Du har ikke opprettet noen rom',
  setShowAddRoom,
  setSearchText,
}) => {
  const isDesktop = useDesktop();

  const [searchQuery, setSearchQuery] = useState('');

  const filteredRooms = getFilteredRooms(rooms, searchQuery);

  const onAddRoom = () => {
    setShowAddRoom(true);
  };

  useEffect(() => {
    setSearchText('');
  }, []);

  const handleSearchQuery = (event) => {
    setSearchQuery(event.target.value);
    matomo.clickEvent({
      category: 'Rooms',
      action: 'Search for Room',
    });
  };

  const renderContent = () => {
    if (fetchingError) {
      return (
        <ErrorContainer
          paddingTop={isDesktop}
          errorResponse={fetchingError}
          style={{ paddingTop: isDesktop ? undefined : '15%' }}
        />
      );
    }

    if (!fetching && Array.isArray(rooms) && rooms.length === 0) {
      return (
        <EmptyMessage publicSection={publicSection} onLinkClick={onAddRoom}>
          {emptyMessage}
        </EmptyMessage>
      );
    }

    return (
      <>
        <Box display="flex" alignItems="end" px={isDesktop ? 0 : 2} pb={6}>
          <Box flex="50%" maxWidth={isDesktop ? '50%' : undefined}>
            <Box pt={!isDesktop ? 2 : 0}>
              <SearchField
                bordered
                value={searchQuery}
                placeholder="Søk etter rom"
                onChange={(e) => handleSearchQuery(e)}
              />
            </Box>
          </Box>

          {isDesktop && !publicSection && (
            <Box flex="30%" textAlign="right" ml={2}>
              <AddNewButton text="Opprett rom" onClick={onAddRoom} />
            </Box>
          )}
        </Box>

        <Box mt={4}>
          <RoomList
            fetching={fetching}
            rooms={filteredRooms}
            searchQuery={searchQuery}
            enableImageUpload={!publicSection}
          />
        </Box>
      </>
    );
  };

  return (
    <Page topLevelLayout headerTitle="Rom" maxWidth="lg" publicSection={publicSection}>
      {renderContent()}
    </Page>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setShowAddRoom: (payload) => dispatch(acSetShowAddRoom(payload)),
  setSearchText: (searchText) => dispatch(acSetDocumentSearchText(searchText)),
});

export default connect(null, mapDispatchToProps)(RoomsPage);
