import React from 'react';
import { withStyles } from '@material-ui/core';

import {
  getName,
  getEmail,
  getSharedDate,
  getStatus,
} from '../../../../../../../../utils/privateshare';
import { formattedDate } from '../../../../../../../../utils/dates';

import ListItem from '../ListItem';
import MenuIcon from '../../../../../../../../assets/icons/MenuHorizontal';
import Style from './style';

const DetailsListItem = ({
  shareRecord,
  classes,
  onMenuClick,
  actionsEnabled,
}) => {
  const name = getName(shareRecord);
  const email = getEmail(shareRecord);
  const sharedDate = formattedDate(getSharedDate(shareRecord));
  const status = getStatus(shareRecord);
  const actionComponent =
    actionsEnabled && shareRecord ? (
      <MenuIcon
        className={classes.menuIcon}
        onClick={() => onMenuClick(shareRecord)}
      />
    ) : null;

  return (
    <>
      {shareRecord && (
        <ListItem
          primaryText={name || email}
          secondaryText={sharedDate}
          status={status}
          actionComponent={actionComponent}
          key={`share-items-${shareRecord.id}`}
        />
      )}
    </>
  );
};
export default withStyles(Style)(DetailsListItem);
