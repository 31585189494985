const styles = (theme) => ({
  error: {
    color: theme.palette.primary.redDark,
  },
  formElement: {
    marginBottom: '20px',

    '&:last-child': {
      marginBottom: 0,
    },
  },
});

export default styles;
