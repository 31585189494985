import styled from 'styled-components';

export const StyledBreadCrumbsFullPath = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #e6e6e6;
  z-index: 1;

  .breadCrumb_container {
    display: flex;
    align-items: center;
    max-width: 1115px;
    margin: 0 auto;
  }

  button {
    border: unset;
    background-color: unset;
    cursor: pointer;
  }
`;
