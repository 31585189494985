const styles = (theme) => ({
  root: {
    padding: '0 10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  combinedFilters: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: '16px',
  },
  headerBox: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#F2F8F8',
      borderRadius: '10px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8px',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  arrow: {
    width: '10px',
    marginLeft: '8px',
    fill: theme.palette.text.primary,
    paddingTop: '12px',
  },
});

export default styles;
