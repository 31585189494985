export const borderRadius = 12;

const styles = (theme) => ({
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: `${borderRadius}px`,
  },
  roomLetter: {
    fontWeight: 700,
    fontSize: '78px',
  },
  roomLetterContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: `${borderRadius}px`,
    border: `1px solid ${theme.palette.primary.greyLighter}`,
  },
  imageUploadButton: {
    height: '35px',
    borderRadius: '0',
    position: 'absolute',
    top: theme.spacing(2),
    borderTopLeftRadius: `${borderRadius}px`,
    borderTopRightRadius: `${borderRadius}px`,
    background: theme.palette.primary.greyLight,
    width: `calc(100% - ${theme.spacing(2) * 2}px)`,

    '&:hover': {
      background: theme.palette.primary.greyLight,
    },
  },
  room_item_mobile: {
    maxWidth: '50%',
    flexBasis: '50%',
  },
  room_item_desktop: {
    flexGrow: '0',
    maxWidth: '233.33px',
    flexBasis: '50%',
    paddingBottom: '0 !important',
  },
  roomName: {
    marginTop: '-0.5rem',
  },
});

export default styles;
