import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import styles from './style';
import { acLogout } from '../../store/actions/auth';
import useActionDialog from '../../hooks/useActionDialog';
import { shouldShowSessionExpiredWarning } from '../../utils/auth';
import {
  eventEmitter,
  UNAUTHENTICATED_RESPONSE_RECEIVED,
} from '../../utils/eventEmitter';

const SessionExpiredPrompt = ({ classes, logout }) => {
  const { showActionDialog } = useActionDialog();
  const [showOverlay, setShowOverlay] = useState(false);

  const logoutAndRedirect = () => {
    logout();
    window.location = '/login';
  };

  const onUnauthenticatedResponseReceived = () => {
    if (shouldShowSessionExpiredWarning()) {
      setShowOverlay(true);

      showActionDialog({
        submitButtonText: 'OK',
        showCancelButton: false,
        title: 'Økten har utløpt',
        submitButtonVariant: 'contained',
        message: (
          <div>
            Du har vært inaktiv i mer enn 30 minutter og derfor blitt logget ut.
            <br />
            <br />
            Du blir sendt til innloggingssiden.
          </div>
        ),
        onCancel: () => logoutAndRedirect(),
        onSubmit: () => logoutAndRedirect(),
      });
    } else {
      logoutAndRedirect();
    }
  };

  useEffect(() => {
    eventEmitter.on(
      UNAUTHENTICATED_RESPONSE_RECEIVED,
      onUnauthenticatedResponseReceived
    );

    return () => {
      eventEmitter.removeListener(
        UNAUTHENTICATED_RESPONSE_RECEIVED,
        onUnauthenticatedResponseReceived
      );
    };
  }, []);

  return showOverlay && <div className={classes.overlay} />;
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(acLogout()),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(SessionExpiredPrompt));
