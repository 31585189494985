import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { withStyles } from '@material-ui/core';

import {
  getNotificationIds,
  isNotificationSeen,
  getNotificationDate,
  getNotificationTitle,
  getNotificationIssuer,
  getNotificationIdHash,
  getNotificationMessage,
  getNotificationCompanyId,
  getNotificationCompanyName,
  getNotificationCompanyEmail,
  getNotificationCompanyPhone,
  getNotificationCompanyWebsite,
  getNotificationActionButtonConfig,
  isInformationPushNotification,
} from '../../../../../utils/notifications';

import {
  sGetNotifications,
  sGetNotificationsFetching,
  sGetFetchingNotificationsError,
} from '../../../../../store/reducers/notifications';

import styles from './style';
import { fromNow } from '../../../../../utils/dates';
import Box from '../../../../../components/atomic/Box';
import Link from '../../../../../components/atomic/Link';
import useDesktop from '../../../../../hooks/useDesktop';
import { areIdsEqual } from '../../../../../utils/strings';
import Button from '../../../../../components/atomic/Button';
import EmptyMessage from '../../../../../components/EmptyMessage';
import Typography from '../../../../../components/atomic/Typography';
import CompanyContacts from '../../../../../modules/CompanyContacts';
import PageLayout from '../../../../../components/layouts/WithHeader';
import ErrorContainer from '../../../../../components/ErrorContainer';
import { updateNotificationGroup } from '../../../../../api/properties';
import NotificationIcon from '../../../../../assets/icons/Notification';
import ContentLoader from '../../../../../components/loaders/ContentLoader';
import useDashboardLinkPrefix from '../../../../../hooks/useDashboardLinkPrefix';
import { acUpdateNotification } from '../../../../../store/actions/notifications';
import { acSetShowAddEvent } from '../../../../../store/actions/events';

const Notification = ({
  classes,
  fetching,
  notifications,
  fetchingError,
  updateNotification,
  // full screen (view/page component) mode is enabled on mobile,
  // on desktop it is used as as section on notifications list page
  fullScreenMode = true,
  setShowAddEvent,
}) => {
  const isDesktop = useDesktop();
  const { notificationIdHash } = useParams();
  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const notification = (notifications || []).find((storedNotification) =>
    areIdsEqual(notificationIdHash, getNotificationIdHash(storedNotification))
  );
  const { label, type } = notification?.action || {};

  const companyId = getNotificationCompanyId(notification);
  const companyName = getNotificationCompanyName(notification);
  const companyEmail = getNotificationCompanyEmail(notification);
  const companyPhone = getNotificationCompanyPhone(notification);
  const companyWebsite = getNotificationCompanyWebsite(notification);
  const hasContacts = companyEmail || companyPhone || companyWebsite;
  const isInformationNotification = isInformationPushNotification(notification);

  const action = getNotificationActionButtonConfig(
    notification,
    dashboardLinkPrefix
  );

  const pageTitle =
    getNotificationCompanyName(notification) ||
    getNotificationIssuer(notification);

  const companyLink = companyId && (
    <Link to={`${dashboardLinkPrefix}/company/${companyId}`}>
      {companyName}
    </Link>
  );

  const markNotificationSeen = () => {
    const data = { seenByUser: true };

    updateNotification({
      ...notification,
      ...data,
    });

    const notificationIds = getNotificationIds(notification);

    if (Array.isArray(notificationIds) && notificationIds.length > 0) {
      updateNotificationGroup({ ids: notificationIds });
    }
  };

  const getMessage = () => getNotificationMessage(notification);

  useEffect(() => {
    if (notification && !isNotificationSeen(notification)) {
      markNotificationSeen();
    }
  }, [notification]);

  return (
    <PageLayout
      minFullHeight
      hideHeader={!fullScreenMode}
      headerTitle={companyLink || pageTitle}
    >
      {!fetching && !notification && fullScreenMode && (
        <ErrorContainer
          errorResponse={fetchingError}
          style={{ paddingTop: isDesktop ? undefined : '10%' }}
        />
      )}

      {!fetching && !notification && !fullScreenMode && (
        <EmptyMessage wrapInTypography={false}>
          <Typography variant="h2" component="div" color="textSecondary">
            <Box textAlign="center">
              <NotificationIcon className={classes.selectIcon} />
              <br />
              <span>Velg et varsel du vil lese</span>
            </Box>
          </Typography>
        </EmptyMessage>
      )}

      {fetching && fullScreenMode && (
        <>
          <br />
          <ContentLoader width={160} height={20} />
          <br />
          <br />
          <ContentLoader width="100%" height={20} />
          <br />
          <ContentLoader width="90%" height={20} />
        </>
      )}

      {!fetching && notification && (
        <>
          <div>
            {!fullScreenMode && (
              <Typography variant="h2">{companyLink || pageTitle}</Typography>
            )}
            <Typography variant="h3">
              {getNotificationTitle(notification)}
            </Typography>
            <Box mt={2}>
              <Typography
                variant="body2"
                component="span"
                color="textSecondary"
              >
                {fromNow(getNotificationDate(notification))}
              </Typography>
            </Box>
            <br />
            {typeof getMessage() === 'string' &&
              getMessage()
                .split('\n')
                .map((str) => (
                  <div key={str}>
                    <p>{str}</p>
                  </div>
                ))}

            {isInformationNotification && notification.action && (
              <Link
                to={notification.action.infomationLink}
                isExternal
                openInNewTab
                bold
              >
                <Typography variant="h4">
                  {notification.action.infomationLinkLabel}
                </Typography>
              </Link>
            )}

            <br />

            {
              /* this is to tackle welcome notification */
              typeof getMessage() === 'object' && <p>{getMessage()}</p>
            }
            {hasContacts && (
              <div>
                <br />
                <Typography
                  variant="inherit"
                  component="small"
                  color="textSecondary"
                >
                  <strong>HAR DU SPØRSMÅL?</strong>
                </Typography>
                <CompanyContacts
                  showTitle={false}
                  url={companyWebsite}
                  companyName={pageTitle}
                  phone={companyPhone}
                  email={companyEmail}
                />
              </div>
            )}
          </div>
          <br />
          <div
            className={
              isDesktop ? undefined : classes.actionBtnNotificationMoble
            }
          >
            {!action && type === 'NEW_EVENT' && (
              <Button
                size="large"
                color="primary"
                variant="contained"
                fullWidth={fullScreenMode}
                onClick={() => setShowAddEvent(true)}
              >
                {label}
              </Button>
            )}
            {action && (
              <Link
                to={action.link}
                isExternal={isInformationPushNotification(notification)}
                openInNewTab={isInformationNotification}
              >
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  fullWidth={fullScreenMode}
                  onClick={() => {}}
                >
                  {action.label}
                </Button>
              </Link>
            )}
          </div>
        </>
      )}
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  notifications: sGetNotifications(state),
  fetching: sGetNotificationsFetching(state),
  fetchingError: sGetFetchingNotificationsError(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateNotification: (notification) =>
    dispatch(acUpdateNotification(notification)),
  setShowAddEvent: (payload) => dispatch(acSetShowAddEvent(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Notification));
