import {
  SET_COMPANIES,
  CLEAR_COMPANIES,
  SET_FETCHING_COMPANIES,
  SET_FETCHING_COMPANIES_ERROR,
} from '../reducers/companies';

export const acSetCompanies = (companies) => ({
  type: SET_COMPANIES,
  payload: companies,
});

export const acClearCompanies = () => ({
  type: CLEAR_COMPANIES,
});

export const acSetFetchingCompanies = (fetching) => ({
  payload: fetching,
  type: SET_FETCHING_COMPANIES,
});

export const acSetFetchingCompaniesError = (fetchingError) => ({
  payload: fetchingError,
  type: SET_FETCHING_COMPANIES_ERROR,
});
