import localStorage from './localStorage';
import { getMonth, getYear } from './dates';
import { sortImagesByPosition } from './images';
import { EVENT_SUGGESTION_ENABLED } from '../constants';
import { compareAlphabetically, toLowerCase } from './strings';

export const getEventId = (event) => (event ? event.id : null);

export const getEventName = (event) => (event ? event.title : null);

export const getEventStartDate = (event) =>
  event ? event.dateStart || event.startDate : null;

export const getEventEndDate = (event) =>
  event ? event.dateEnd || event.endDate : null;

export const getEventStartYear = (event) => getYear(getEventStartDate(event));

export const getEventEndYear = (event) => getYear(getEventEndDate(event));

export const getEventStartMonth = (event) => getMonth(getEventStartDate(event));

export const getEventEndMonth = (event) => getMonth(getEventEndDate(event));

export const getEventYear = (event) => getEventStartYear(event);

/**
 * Transforms events array into object with event.year as keys
 * and event as values
 * @param events
 * @returns {null|*}
 */
export const getEventsByYear = (events) => {
  if (events === null) {
    return null;
  }

  return events.reduce((eventsByYear, event) => {
    if (eventsByYear[getEventYear(event)]) {
      return {
        ...eventsByYear,
        [getEventYear(event)]: [...eventsByYear[getEventYear(event)], event],
      };
    }

    return {
      ...eventsByYear,
      [getEventYear(event)]: [event],
    };
  }, {});
};

export const getEventRoom = (event) =>
  event.rooms && event.rooms.length > 0 ? event.rooms[0] : null;

export const getEventImages = (event) =>
  event && event.gallery ? event.gallery : null;

export const getEventImage = (event) => {
  const images = getEventImages(event);

  if (!Array.isArray(images) || images.length === 0) {
    return null;
  }

  return images.slice().sort(sortImagesByPosition)[0];
};

export const getEventDescription = (event) =>
  event ? event.description : null;

export const getEventDocuments = (event) =>
  event && event.documents ? event.documents : null;

export const getEventRooms = (event) => (event ? event.rooms : null);

export const getEventType = (event) => (event ? event.type : null);

export const getEventSubType = (event) => (event ? event.subType : null);

export const getEventCategory = (event) => (event ? event.category : null);

export const retrieveEventSuggestionPreference = () =>
  localStorage.get(EVENT_SUGGESTION_ENABLED)
    ? JSON.parse(localStorage.get(EVENT_SUGGESTION_ENABLED))
    : true;

export const storeEventSuggestionPreference = (preference) =>
  localStorage.set(EVENT_SUGGESTION_ENABLED, JSON.stringify(preference));

export const sortEventOptions = (events) =>
  Array.isArray(events)
    ? events
        .sort((a, b) => compareAlphabetically(getEventName(a), getEventName(b)))
        .map((event) => ({
          value: getEventId(event),
          label: getEventName(event),
        }))
    : [];

export const mapEventIds = (events) =>
  Array.isArray(events) ? events.map(({ id }) => id) : [];

export const mapEventOptions = (events) =>
  Array.isArray(events)
    ? events.map(({ value, label }) => {
        return { id: value, title: label };
      })
    : [];

export const eventModes = {
  event: 'eventMode',
  document: 'attachDocumentMode',
  room: 'attachRoomMode',
};

export const getFilteredEvents = (events, searchQuery) => {
  if (!Array.isArray(events) || events.length === 0 || !searchQuery) {
    return events;
  }

  return events.filter((event) =>
    toLowerCase(getEventName(event)).includes(toLowerCase(searchQuery))
  );
};

export const getUnattachedEvents = (events, question) =>
  events.filter(
    ({ associations }) =>
      associations == null || !associations.includes(question)
  );
