import React from 'react';

import Box from '../../../../../../../../components/atomic/Box';
import Grid from '../../../../../../../../components/atomic/Grid';
import ContentLoader from '../../../../../../../../components/loaders/ContentLoader';

export const PriceHistoryLoading = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <Box width="100%" height="574px">
          <Box mb={3}>
            <ContentLoader width={250} height={30} />
          </Box>
          <Box mb={3}>
            <ContentLoader width={70} height={20} />
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box mb={1}>
                <ContentLoader width={100} height={20} />
              </Box>
              <Box mb={4} xs={6}>
                <ContentLoader width={100} height={20} />
              </Box>
            </Grid>
          </Grid>
          <Box mb={4} mr={1}>
            <ContentLoader width="100%" height={300} />
          </Box>
          <Box mb={1}>
            <ContentLoader width="80%" height={20} />
          </Box>
          <Box>
            <ContentLoader width="80%" height={20} />
          </Box>
        </Box>
      )}
    </>
  );
};
