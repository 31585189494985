import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

import useDesktop from '../../../hooks/useDesktop';

import styles from './style';
import OrderConfirmation from '../../../components/Insurance/OrderConfirmation';

const OrderConfirmationPage = ({ classes }) => {
  const isDesktop = useDesktop();
  return (
    <div className={classes.page}>
      <div className={clsx([classes.wrapper, !isDesktop && 'mobile'])}>
        <OrderConfirmation />
      </div>
    </div>
  );
};

export default withStyles(styles)(OrderConfirmationPage);
