import { RefObject, useEffect } from 'react';

export function useClickOutside(
  ref: RefObject<HTMLElement>,
  func: (arg) => void,
  value?: any,
) {
  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (func && ref.current && !ref.current.contains(event.target)) {
        func(value);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
