const localStorage = {
  set: (key, val) => {
    try {
      window.localStorage.setItem(key, val);
    } catch (err) {
      // ignore
    }
  },
  get: (key) => {
    try {
      return window.localStorage.getItem(key);
    } catch (err) {
      return undefined;
    }
  },
  remove: (key) => {
    try {
      window.localStorage.removeItem(key);
    } catch (err) {
      // ignore
    }
  },
  clear: () => {
    try {
      window.localStorage.clear();
    } catch (err) {
      // ignore
    }
  },
};

export default localStorage;
