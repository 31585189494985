const styles = () => ({
  container: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  loader: {
    width: '70px',
  },
});

export default styles;
