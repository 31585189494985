import clsx from 'clsx';
import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Box from '../atomic/Box';
import TextField from '../atomic/TextField';
import Typography from '../atomic/Typography';

const TextArea = ({
  value,
  classes,
  rows = 4,
  className,
  maxLength,
  mb = '20px',
  rowsMax = 100,
  inputProps = {},
  multiline = true,
  counterLeft,
  ...props
}) => (
  <Box mb={mb !== undefined ? mb : maxLength && '20px'}>
    <TextField
      minRows={rows}
      value={value}
      maxRows={rowsMax}
      multiline={multiline}
      inputProps={{ ...inputProps, maxLength }}
      className={clsx(className, maxLength && classes.noMarginBottom)}
      {...props}
    />
    {maxLength && (
      <Box textAlign={counterLeft ? 'left' : 'right'}>
        <Typography component="small" color="textSecondary">
          {(typeof value === 'string' && value.length) || 0}/{maxLength}
        </Typography>
      </Box>
    )}
  </Box>
);

export default withStyles(styles)(TextArea);
