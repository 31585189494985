const styles = (theme) => ({
  container: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  paddingDesktop: {
    flex: '1 1 auto',
    overflow: 'auto',
    padding: '20px 40px 0px 40px',
  },
  paddingMobile: {
    flex: '1 1 auto',
    overflow: 'auto',
    padding: '10px 0px 0px 0px',
  },
  actionButtons: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px 0 15px 0',
    borderTop: '1px solid #dedfdf',
    backgroundColor: theme.palette.primary.white,
  },
  actionButtonsMobile: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 0 12px 0',
    borderTop: '1px solid #dedfdf',
    backgroundColor: theme.palette.primary.white,
  },
  nextBtn: {
    width: '250px',
    height: '50px',
  },
  nextBtnMobile: {
    width: '239px',
    height: '50px',
  },
});

export default styles;
