const styles = (theme) => ({
  divider: {
    borderTop: `1px solid ${theme.palette.primary.whiteDarker}`,
  },
  list: {
    listStyle: 'none',
    paddingLeft: 0,
  },
  listItem: {
    display: 'flex',
    marginBottom: '20px',
    '::before': {
      content: '• ',
      color: 'red',
    },
  },
  checkbox: {
    marginBottom: '20px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  consentBody: {
    maxWidth: '700px',
  },
  header: {
    fontSize: '33px',
  },
  mainBody: {
    color: theme.palette.primary.darkGreen,
  },
  bulletPoint: {
    color: theme.palette.primary.darkGreen,
    width: '8px',
    marginRight: '10px',
    paddingBottom: '5px',
  },
  checkBoxText: {
    fontWeight: 500,
  },
  bodyTextPara: {
    fontWeight: 500,
  },
  formSection: {
    marginBottom: '60px',
  },
});

export default styles;
