import { connect } from 'react-redux';
import { useParams } from 'react-router';
import React, { Fragment, useContext } from 'react';

import {
  getOwnershipName,
  getPropertyOwnerships,
} from '../../../../../utils/financials';

import useDesktop from '../../../../../hooks/useDesktop';
import Paper from '../../../../../components/atomic/Paper';
import Page from '../../../../../components/layouts/WithHeader';
import useFetchEffect from '../../../../../hooks/useFetchEffect';
import { splitAndCapitalize } from '../../../../../utils/strings';
import ErrorContainer from '../../../../../components/ErrorContainer';
import PropertyOwnership from '../../../../../modules/PropertyOwnership';
import { sGetFinancials } from '../../../../../store/reducers/financials';
import { acSetFinancials } from '../../../../../store/actions/financials';
import PropertyRefreshContext from '../../../../../contexts/propertyRefresh';
import { fetchFinancials as apiFetchFinancials } from '../../../../../api/properties';
import CTAcard from '../../../../../components/CTAcard';
import { zendeskHelpPage } from '../../../../../config';
import {
  isNativeEnv,
  openLinkRequest,
} from '../../../../../utils/nativeBridge';

const PropertyOwnerships = ({ financials, setFinancials }) => {
  const isDesktop = useDesktop();
  const { boligmappaNumber } = useParams();
  const ownerships = getPropertyOwnerships(financials);
  const CTAcardDescription =
    'I vår FAQ kan du lese om hvor vi henter data fra, hva informasjonen ovenfor betyr og hvorfor det noen ganger ikke samsvarer med hvordan du kjenner din egen bolig.';

  const { propertyRefreshRequestInProgress } = useContext(
    PropertyRefreshContext
  );
  const callToAction = () => {
    if (isNativeEnv()) {
      openLinkRequest(zendeskHelpPage);
      return;
    }
    window.open(zendeskHelpPage, '_blank');
  };

  const { fetching, fetchingError } = useFetchEffect({
    initialFetchingState: !financials,
    conditionFunction: () => !financials,
    onSuccess: (data) => setFinancials(data),
    apiFetchFunction: () => apiFetchFinancials(boligmappaNumber),
  });

  const renderOwnership = (ownership, index) => (
    <Fragment key={`ownership-item-${index}`}>
      <Paper visible={!isDesktop}>
        <PropertyOwnership
          fetching={fetching}
          ownership={ownership}
          title={splitAndCapitalize(getOwnershipName(ownership))}
        />
      </Paper>
      <br />
    </Fragment>
  );

  return (
    <Page
      minFullHeight
      noPadding={!isDesktop}
      lessMarginLeft={isDesktop}
      maxWidth="lg"
      hideHeader={isDesktop}
      headerTitle="Hjemmelsinformasjon"
      showProgressBar={propertyRefreshRequestInProgress}
    >
      {!isDesktop && <br />}

      {fetching && (
        <Paper visible={!isDesktop}>
          <PropertyOwnership fetching />
        </Paper>
      )}

      {fetching && fetchingError && (
        <ErrorContainer paddingTop={isDesktop} errorResponse={fetchingError} />
      )}

      {!fetching &&
        !fetchingError &&
        Array.isArray(ownerships) &&
        ownerships.map(renderOwnership)}

      <Paper visible={!isDesktop}>
        <CTAcard
          title="Stemmer ikke opplysningene ovenfor?"
          description={CTAcardDescription}
          buttonText="Gå til FAQ"
          onClick={callToAction}
        />
      </Paper>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  financials: sGetFinancials(state),
});

const mapDispatchToProps = (dispatch) => ({
  setFinancials: (financials) => dispatch(acSetFinancials(financials)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyOwnerships);
