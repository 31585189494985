import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import Box from '../../../../../../components/atomic/Box';
import Typography from '../../../../../../components/atomic/Typography';
import Button from '../../../../../../components/atomic/Button';
import useDesktop from '../../../../../../hooks/useDesktop';

const RegisterWork = ({
  classes,
  isAddMore = false,
  category,
  onAnswerNo,
  onAnswerYes,
}) => {
  const isDesktop = useDesktop();

  return (
    <Box className={classes.container}>
      <img
        alt="Register work"
        src={category.imageUrl}
        className={isDesktop ? classes.imageDesktop : classes.imageMobile}
      />

      <Typography
        className={isDesktop ? classes.question : classes.questionMobile}
        variant="h2"
        align="center"
      >
        {isAddMore ? category.addMoreQuestion : category.question}
      </Typography>

      <p className={classes.infoText}>{category.desc}</p>

      <Box className={classes.buttonGroup}>
        <Button
          className={classes.button}
          color="primary"
          variant="outlined"
          onClick={() => onAnswerNo()}
        >
          Nei
        </Button>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={() => onAnswerYes()}
        >
          Ja
        </Button>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(RegisterWork);
