import React, { useRef, useState } from 'react';
import {
  InputAdornment,
  TextField,
  withStyles,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import styles from './style';
import Box from '../../../atomic/Box';
import Button from '../../../atomic/Button';
import useOutsideAlerter from '../../../../hooks/useOutsideAlerter';
import SearchIcon from '../../../../assets/icons/Search';
import List from '../../../atomic/List';
import ListItem from '../../../atomic/ListItem';
import { areIdsEqual } from '../../../../utils/strings';

const ListBody = ({
  classes,
  onReset,
  onMenuSave,
  onMenuClose,
  editOptions,
  radioGroupName,
  onSelectOption,
  selectedOptions = null,
  enableSearch = true,
}) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onMenuClose);

  const [searchTerm, setSearchTerm] = useState('');
  const [radioValue, setRadioValue] = useState(null);

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    const fileType = JSON.parse(event.target.value);
    delete fileType.checked;
    onSelectOption([fileType]);
  };

  const onSubmit = () => {
    onMenuSave(radioValue);
  };

  const onRemove = () => {
    setRadioValue(null);
    onReset({ value: null, reset: true });
  };

  return (
    <Box ref={wrapperRef}>
      <Box className={classes.content}>
        {enableSearch && (
          <Box ref={wrapperRef} sx={{ mx: 2, mt: 1 }}>
            <TextField
              className={classes.textField}
              value={searchTerm}
              sx={{ '& .MuiInput-underline:before': { content: 'none' } }}
              onChange={(e) => handleSearchChange(e.target.value)}
              placeholder="Søk etter dokumenttype"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" disablePointerEvents>
                    &nbsp;
                    <SearchIcon style={{ width: 18 }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}

        <Box sx={{ maxHeight: '400px' }}>
          <List>
            <RadioGroup
              value={radioValue}
              name={radioGroupName}
              aria-label={radioGroupName}
              onChange={handleRadioChange}
            >
              {editOptions
                .filter((option) =>
                  option.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map((props) => (
                  <ListItem key={`filter-item-${props.name}`} button>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={
                            selectedOptions?.length > 0
                              ? areIdsEqual(
                                  props.value,
                                  selectedOptions[0].value
                                )
                              : props.checked
                          }
                        />
                      }
                      label={props.name}
                      value={JSON.stringify(props)}
                    />
                  </ListItem>
                ))}
            </RadioGroup>
          </List>
        </Box>
      </Box>
      <Box p={1} textAlign="right" className={classes.buttonPane}>
        <Button
          varinat="text"
          color="primary"
          onClick={onRemove}
          className={classes.textButton}
        >
          <small>Nullstill</small>
        </Button>
        <Button
          varinat="text"
          color="primary"
          onClick={onSubmit}
          className={classes.saveButton}
        >
          Lagre
        </Button>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(ListBody);
