const styles = (theme) => ({
  list: {
    padding: 0,
  },
  listItemActive: {
    background: theme.palette.primary.redLighter,
  },
});

export default styles;
