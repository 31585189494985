import React from 'react';
import Box from '../../atomic/Box';
import Button from '../../atomic/Button';
import Typography from '../../atomic/Typography';
import BottomContextMenu from '../../BottomContextMenu';

const FilterMenu = ({ title, onMenuClose, ...props }) => {
  const titleContent = (
    <Box display="flex" alignItems="center">
      <Box flexGrow={1} marginRight="-64px">
        <Typography>{title}</Typography>
      </Box>

      <Box width="64px">
        <Button
          varinat="text"
          color="primary"
          onClick={onMenuClose}
          style={{ textDecoration: 'none' }}
        >
          Ferdig
        </Button>
      </Box>
    </Box>
  );

  return <BottomContextMenu title={titleContent} {...props} />;
};

export default FilterMenu;
