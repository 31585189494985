import React from 'react';

import { StyledSectionCallToAction } from '@componentsV2/BoligmappaPlus/SectionCallToAction/styles';
import CallToAction from '@componentsV2/BoligmappaPlus/CallToAction';
import loyaltyIllustration from '../../../assets/images/loyalty_Illustration.jpg';
import loyaltyIllustrationMob from '../../../assets/images/loyalty_IllustrationMob.jpg';

const SectionCallToAction = ({
  title,
  linkText,
  desktopImage = loyaltyIllustration,
  mobileImage = loyaltyIllustrationMob,
  isDesktop,
  className,
  bottomText,
  onActionClick,
  children,
}) => {
  return (
    <StyledSectionCallToAction className={className}>
      <div className="loyalty_content">
        <div className="loyalty_text">
          <h2>{title}</h2>
          <p>{children}</p>
          <p className="advantages_text">{linkText}</p>
        </div>
        <img
          className="loyalty_img"
          src={isDesktop ? desktopImage : mobileImage}
          alt="boligmappa plus"
        />
        {bottomText && bottomText}
      </div>
      <CallToAction
        className="loyalty_cta"
        isDesktop={isDesktop}
        headDescription="Med Boligmappa+ får du tilgang til Personlig område og Mine fordeler."
        learnMoreLink
        onActionClick={onActionClick}
      />
    </StyledSectionCallToAction>
  );
};

export default SectionCallToAction;
