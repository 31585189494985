import React, { useEffect, useState } from 'react';
import Box from '../../../../atomic/Box';
import Grid from '../../../../atomic/Grid';
import Checkbox from '../../../../atomic/Checkbox';
import Typography from '../../../../atomic/Typography';
import useDesktop from '../../../../../hooks/useDesktop';
import Divider from '../../../../atomic/Divider';
import { useFindProfessionalsStyles } from '../../../findProfessionalStyles';

const FranchiserFilter = ({
  franchisers = [],
  onSelect = () => {},
  selectedOptions,
  showHeading = true,
  reset = false,
}) => {
  const isDesktop = useDesktop();
  const [options, setOptions] = useState(
    selectedOptions !== ''
      ? selectedOptions.split(',').map((f) => decodeURIComponent(f))
      : []
  );

  const classes = useFindProfessionalsStyles();

  const handleOptionChange = (o) => {
    const index = options.indexOf(o);
    if (index === -1) {
      setOptions([...options, o]);
    } else {
      setOptions(options.filter((s) => s !== o));
    }
  };

  useEffect(() => {
    if (reset) {
      setOptions([]);
    }
  }, [reset]);

  useEffect(() => {
    onSelect(options);
  }, [options.length]);

  return (
    <Box sx={{ flexGrow: 1 }} p={!isDesktop && 2}>
      {showHeading && (
        <Typography
          className={
            isDesktop
              ? classes.moreFiltersSubTitles
              : classes.moreFiltersSubTitlesMobile
          }
        >
          Kjedeavhengighet
        </Typography>
      )}
      <Grid container spacing={0} columns={{ xs: 1, sm: 8 }}>
        {franchisers.map((o) =>
          isDesktop ? (
            <Grid item xs={6} sm={6} md={6} key={`franchiser-key-${o.name}`}>
              <Box pl={2} pr={2}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleOptionChange(o.name)}
                >
                  <Typography className={classes.moreFilterContent}>
                    {o.displayName}
                  </Typography>
                  <Checkbox
                    value={o.name}
                    checked={options.includes(o.name)}
                    onChange={(e) => handleOptionChange(e.target.value)}
                  />
                </Box>
                <Divider />
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12} key={`franchiser-key-${o.name}`}>
              <Checkbox
                label={o.displayName}
                value={o.name}
                checked={options.includes(o.name)}
                onChange={(e) => handleOptionChange(e.target.value)}
                classes={{ label: classes.moreFilterContent }}
              />
            </Grid>
          )
        )}
      </Grid>
    </Box>
  );
};

export default FranchiserFilter;
