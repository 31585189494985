import clsx from 'clsx';
import React, { useState } from 'react';
import { Alert } from '@material-ui/lab';
import { withStyles } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';

import {
  emailPattern,
  phonePattern,
  getErrorMessage,
} from '../../../../validation';

import styles from './style';
import Box from '../../../../components/atomic/Box';
import Link from '../../../../components/atomic/Link';
import Button from '../../../../components/atomic/Button';
import Checkbox from '../../../../components/atomic/Checkbox';
import TextField from '../../../../components/atomic/TextField';
import Typography from '../../../../components/atomic/Typography';
import { getUserEmail, getUserPhone } from '../../../../utils/auth';
import { getResponseErrorMessage } from '../../../../utils/requests';
import ProgressAnimation from '../../../../components/ProgressAnimation';
import { TERMS_OF_USE_LINK, PRIVACY_POLICY_LINK } from '../../../../constants';
import { hideSignupPolicyAcceptance } from '../../../../config';

const Form = ({
  user,
  classes,
  requestError,
  requestInProgress,
  onSubmit: passedOnSubmit,
}) => {
  const { control, handleSubmit, errors } = useForm({ mode: 'onTouched' });

  const [termsConsent, setTermsConsent] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [valueEstimateConsent, setvalueEstimateConsent] = useState(false);

  const onSubmit = (formData, e) =>
    passedOnSubmit(e, {
      ...formData,
      marketingConsent,
      valueEstimateConsent,
    });

  return (
    <Box>
      <form
        className={clsx({
          [classes.form]: true,
          [classes.hidden]: requestInProgress,
        })}
        onSubmit={handleSubmit(onSubmit)}
      >
        {requestError && (
          <Box mb={2}>
            <Alert className="word-break" severity="error">
              {getResponseErrorMessage(requestError)}
            </Alert>
          </Box>
        )}

        <Controller
          name="email"
          control={control}
          defaultValue={getUserEmail(user)}
          rules={{ required: true, pattern: emailPattern }}
          render={({ onChange, value, ...props }) => (
            <TextField
              value={value || ''}
              label="E-postadresse"
              error={!!errors.email}
              placeholder="Skriv inn din e-postadresse"
              onChange={(e) => onChange(e.target.value)}
              helperText={getErrorMessage(errors, 'email', 'E-post')}
              {...props}
            />
          )}
        />

        <Box mt={4}>
          <Controller
            name="phone"
            control={control}
            defaultValue={getUserPhone(user)}
            rules={{ required: true, pattern: phonePattern }}
            render={({ onChange, value, ...props }) => (
              <TextField
                value={value || ''}
                label="Mobilnummer"
                error={!!errors.phone}
                placeholder="Skriv inn ditt mobilnummer"
                onChange={(e) => onChange(e.target.value)}
                helperText={getErrorMessage(errors, 'phone', 'Mobilnummer')}
                {...props}
              />
            )}
          />
        </Box>

        {hideSignupPolicyAcceptance === 'false' && (
          <Box mt={3}>
            <Checkbox
              required
              checked={termsConsent}
              onChange={(e) => setTermsConsent(e.target.checked)}
              label={
                <span>
                  <b>Jeg har lest og godtar Boligmappa sine</b>&nbsp;
                  <Link isExternal openInNewTab to={TERMS_OF_USE_LINK}>
                    <strong>vilkår for bruk</strong>
                  </Link>
                  &nbsp;og&nbsp;
                  <Link isExternal openInNewTab to={PRIVACY_POLICY_LINK}>
                    <strong>personverneklæring</strong>
                  </Link>
                </span>
              }
            />
          </Box>
        )}
        <Box mt={4}>
          <Checkbox
            checked={marketingConsent}
            onChange={(e) => setMarketingConsent(e.target.checked)}
            label={
              <span>
                <b>Jeg vil motta nyheter, tips og triks på e-post.</b>
                <br />
                <small>Du kan når som helst melde deg av.</small>
              </span>
            }
          />
        </Box>

        <Box mt={4}>
          <Checkbox
            checked={valueEstimateConsent}
            onChange={(e) => setvalueEstimateConsent(e.target.checked)}
            label={
              <span>
                <b>
                  Motta månedlig varsel på e-post med oppdatert prisestimat for
                  eiendommene dine.
                </b>
                <br />
                <small>Du kan når som helst melde deg av.</small>
              </span>
            }
          />
        </Box>

        <Box mt={4}>
          <Button
            fullWidth
            type="submit"
            color="secondary"
            variant="contained"
            className={classes.button}
          >
            Se min boligmappe
          </Button>
        </Box>
      </form>

      <Box pt={3} className={clsx({ [classes.hidden]: !requestInProgress })}>
        <Typography color="textSecondary">
          Vi gjør klar din boligmappe
        </Typography>

        <Box mt={4}>
          <ProgressAnimation loading />
        </Box>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(Form);
