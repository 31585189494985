import React from 'react';

const Plus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <path
      id="Shape_1914"
      d="M275,6484.5a.938.938,0,0,0,.938.94h5.469a.154.154,0,0,1,.156.155v5.47a.938.938,0,0,0,1.875,0v-5.47a.154.154,0,0,1,.156-.155h5.469a.938.938,0,0,0,0-1.877h-5.469a.155.155,0,0,1-.156-.155v-5.47a.938.938,0,0,0-1.875,0v5.47a.155.155,0,0,1-.156.155h-5.469A.938.938,0,0,0,275,6484.5Z"
      transform="translate(-275 -6477.001)"
      fill="#fff"
    />
  </svg>
);

export default Plus;
