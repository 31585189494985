import React from 'react';

const RoomIcon = ({ width = '0.9rem', height = '0.9rem', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 9C8.78125 9 8.59375 8.90625 8.46875 8.71875C8.3125 8.53125 8.25 8.28125 8.25 8C8.25 7.75 8.3125 7.5 8.46875 7.3125C8.59375 7.125 8.78125 7 9 7C9.1875 7 9.375 7.125 9.53125 7.3125C9.65625 7.5 9.75 7.75 9.75 8C9.75 8.28125 9.65625 8.53125 9.53125 8.71875C9.375 8.90625 9.1875 9 9 9ZM19.5 14.5C19.625 14.5 19.75 14.5625 19.8438 14.6562C19.9375 14.75 20 14.875 20 15V15.5C20 15.6562 19.9375 15.7812 19.8438 15.875C19.75 15.9688 19.625 16 19.5 16H0.5C0.34375 16 0.21875 15.9688 0.125 15.875C0.03125 15.7812 0 15.6562 0 15.5V15C0 14.875 0.03125 14.75 0.125 14.6562C0.21875 14.5625 0.34375 14.5 0.5 14.5H4V2.59375C4 2.375 4.0625 2.15625 4.21875 1.96875C4.34375 1.78125 4.53125 1.65625 4.75 1.59375L10.75 0.03125C10.8125 0.03125 10.9062 0 11 0C11.25 0 11.5 0.125 11.6875 0.3125C11.875 0.5 12 0.75 12 1.03125V2H14.5C14.9062 2 15.25 2.15625 15.5625 2.46875C15.8438 2.78125 16 3.125 16 3.53125V14.5H19.5ZM10.5 14.5V1.65625L5.5 2.9375V14.5H10.5ZM14.5 14.5V3.5H12V14.5H14.5Z"
      fill="#111111"
    />
  </svg>
);
export default RoomIcon;
