import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';

import styles from './style';
import List from '../../atomic/List';
import { areIdsEqual } from '../../../utils/strings';
import BottomContextMenu from '../../BottomContextMenu';
import { arrayFromObject } from '../../../utils/arrays';
import ListItem from '../../BottomContextMenu/ListItem';
import { documentSortTypes } from '../../../utils/documents';

const BottomMenu = ({
  open,
  setOpen,
  classes,
  onSortTypeChange,
  activeSortTypeId,
  title = 'Sortere etter',
}) => {
  const isActive = (id) => areIdsEqual(id, activeSortTypeId);

  return (
    <BottomContextMenu open={open} title={title} setOpen={setOpen}>
      <List className={classes.list}>
        {arrayFromObject(documentSortTypes)
          .filter(({ desktopOnly }) => !desktopOnly)
          .map((sortType) => (
            <ListItem
              icon={sortType.icon}
              key={`sort-type-item-${sortType.id}`}
              onClick={() => onSortTypeChange(sortType)}
              className={clsx(isActive(sortType.id) && classes.listItemActive)}
            >
              {sortType.label}
            </ListItem>
          ))}
      </List>
    </BottomContextMenu>
  );
};

export default withStyles(styles)(BottomMenu);
