const styles = (theme) => ({
  root: {
    paddingTop: '7px',
    justifyContent: 'center',
    background: '#FFEDE0 !important',
  },
  button: {
    color: theme.palette.primary.white,
    marginLeft: '40px',
  },
  typography: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
});

export default styles;
