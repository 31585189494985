import React, { useState } from 'react';

import List from '../../../../../../../components/atomic/List';
import ListLoading from '../../../../../../../components/loaders/DocumentListItemLoading';

import { areIdsEqual } from '../../../../../../../utils/strings';
import { getId } from '../../../../../../../utils/privateshare';
import DetailsListItem from './DetailsListItem';
import ContextMenu from '../ContextMenu';

const DetailsList = ({
  shareRecords,
  fetching,
  onDelete,
  onDeleteFail,
  onDetailsClick,
  actionsEnabled = true,
}) => {
  const [openContextMenu, setOpenContextMenu] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(null);

  const selectedRecord = (shareRecords || []).find((shareRecord) =>
    areIdsEqual(getId(shareRecord), selectedRecordId)
  );
  const onMenuClick = (shareRecord) => {
    setSelectedRecordId(getId(shareRecord));
    setOpenContextMenu(true);
  };

  if (fetching) {
    return (
      <List>
        <ListLoading />
        <ListLoading />
        <ListLoading />
        <ListLoading />
      </List>
    );
  }

  return (
    <div>
      <ContextMenu
        open={openContextMenu}
        setOpen={setOpenContextMenu}
        record={selectedRecord}
        onDelete={onDelete}
        onDeleteFail={onDeleteFail}
        onDetailsClick={onDetailsClick}
      />
      {shareRecords && (
        <List>
          {shareRecords.map((shareRecord) => (
            <DetailsListItem
              shareRecord={shareRecord}
              actionsEnabled={actionsEnabled}
              onMenuClick={onMenuClick}
              key={`share-record${shareRecord.id}`}
            />
          ))}
        </List>
      )}
    </div>
  );
};
export default DetailsList;
