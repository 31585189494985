import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import Cookies from 'js-cookie';
import Link from '../atomic/Link';
import styles from './style';
import useDesktop from '../../hooks/useDesktop';
import CheckCircleRounded from '../../assets/icons/CheckCircleRounded';
import Button from '../atomic/Button';
import PrintIcon from '../../assets/icons/PrintIcon';
import { getUserPaymentInfoById as apiGetUserPaymentInfoById } from '../../api/insurance';

const OrderConfirmation = ({ classes }) => {
  const isDesktop = useDesktop();
  const currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, '0');
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-indexed
  const year = currentDate.getFullYear();
  const formattedDate = `${day}.${month}.${year}`;
  const [userPaymentInfo, setUserPaymentInfo] = useState(null);
  const [insuranceFormData, setInsuranceFormData] = useState(null);

  const getUserPaymentInfo = async (orderId) => {
    const [error, response] = await apiGetUserPaymentInfoById(orderId);
    if (!error && response) {
      setUserPaymentInfo(response);
    } else {
      setUserPaymentInfo(null);
    }
  };

  useEffect(() => {
    const data =
      Cookies.get('insuranceFormData') &&
      JSON.parse(Cookies.get('insuranceFormData'));
    setInsuranceFormData(data);
    if (data) {
      getUserPaymentInfo(data?.orderId);
    }
  }, []);

  return (
    <div
      className={clsx([
        classes.insuranceContent,
        classes.confirmationContent,
        !isDesktop && 'mobile',
      ])}
    >
      <div className={classes.thankyouBlock}>
        <div className={classes.row}>
          <CheckCircleRounded className={classes.confirmationChecked} />
          <h2>Du er forsikret!</h2>
        </div>
        <p>
          Takk for ditt kjøp av Trygt utført – Boligmappas advokatforsikring for
          håndverkertjenester.
        </p>
        <p>
          Du finner ditt forsikringsbevis og forsikringsvilkår i personlig
          område her i Boligmappa.
        </p>
        <Link
          to="/properties"
          className={clsx([classes.backButton])}
          color="primary"
          variant="contained"
          type="button"
        >
          Tilbake til Boligmappa
        </Link>
      </div>
      <div className={classes.summary}>
        <h2>Sammendrag</h2>
        <p className={classes.summaryText}>
          En kopi av sammendraget er sendt til {insuranceFormData?.email}.
        </p>
        <div className={classes.summaryBlocks}>
          <div className={classes.summaryInfo}>
            <p className={classes.summaryLabel}>Ordredetaljer</p>
            <div className="row">
              <p>Forsikring Trygt Utført</p>
              <p>500 kr / stk</p>
            </div>
            <div className="row">
              <p>MVA</p>
              <p>0 kr</p>
            </div>
            <div className="row">
              <p>
                <b>Total sum (inkl MVA)</b>
              </p>
              <p>
                <b>500 kr</b>
              </p>
            </div>
          </div>
          <div className={classes.summaryInfo}>
            <p className={classes.summaryLabel}>Bestillingsinformasjon</p>
            <div className="row">
              <p>Ordrenummer</p>
              <p>{insuranceFormData?.orderId}</p>
            </div>
            <div className="row">
              <p>Kundenummer</p>
              <p>{userPaymentInfo?.externalCustomerId}</p>
            </div>
            <div className="row">
              <p>Dato for kjøp</p>
              <p>{formattedDate}</p>
            </div>
            <div className="row">
              <p>Betalingstype</p>
              <p>Vipps</p>
            </div>
          </div>
          <div className={classes.summaryInfo}>
            <p className={classes.summaryLabel}>Personopplysninger</p>
            <div className="row">
              <p>Navn</p>
              <p>
                {insuranceFormData?.firstName} {insuranceFormData?.lastName}
              </p>
            </div>
            <div className="row">
              <p>Adresse</p>
              <p>{insuranceFormData?.address}</p>
            </div>
            <div className="row">
              <p>E-post</p>
              <p>{insuranceFormData?.email}</p>
            </div>
            <div className="row">
              <p>Telefon</p>
              <p>{insuranceFormData?.phone}</p>
            </div>
          </div>
        </div>
        <div className={classes.buttonsRow}>
          <Button
            className={classes.summaryButton}
            type="button"
            onClick={() => window.print()}
          >
            <PrintIcon className={classes.buttonIcon} />
            Skriv ut
          </Button>
        </div>
      </div>
      <h2>Har du behov for å melde skade?</h2>
      <p className={classes.damageReport}>
        Forsikringen er gyldig fra kjøpstidspunktet. Vi gjør oppmerksom på at
        det kan ta 2–5 dager før kundeforholdet blir oppdatert i Help sine
        systemer.
        <br />
        <br />
        Dersom du har behov for å melde skade allerede nå, ber vi deg{' '}
        <Link
          className={classes.textLink}
          to="https://support.boligmappa.no/hc/no/articles/15094686400658"
          isExternal
          openInNewTab
        >
          lese denne hjelpeartikkelen.
        </Link>
      </p>
    </div>
  );
};

export default withStyles(styles)(OrderConfirmation);
