import useSnackbar from './useSnackbar';
import useActionDialog from './useActionDialog';
import { getRedirectToken } from '../api/mixins';
import { legacyAppRedirectUrl, privateSectionUrlInLegacyApp } from '../config';
import { isNativeEnv, openLinkRequest } from '../utils/nativeBridge';

const useLegacyAppRedirect = () => {
  const { showErrorMessage } = useSnackbar();
  const { showLoadingDialog } = useActionDialog();

  const redirectToLegacyApp = async (url = legacyAppRedirectUrl) => {
    const closeDialog = showLoadingDialog('Sender deg til gammel løsning...');

    // use this workaround with window reference
    // to make it work on ios/safari: https://stackoverflow.com/a/39387533
    const windowReference = isNativeEnv() ? null : window.open();

    const [error, code] = await getRedirectToken();

    if (!error && code) {
      const link = `${url}?code=${code}`;

      if (isNativeEnv()) {
        openLinkRequest(link);
      } else {
        windowReference.location = link;
      }
    } else {
      showErrorMessage(error);
    }

    closeDialog();
  };

  const redirectToPersonalDocuments = () =>
    redirectToLegacyApp(privateSectionUrlInLegacyApp);

  return {
    redirectToLegacyApp,
    redirectToPersonalDocuments,
  };
};

export default useLegacyAppRedirect;
