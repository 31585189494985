import React, { useState, useEffect } from 'react';
import Dialog from '../../../atomic/Dialog';
import Drawer from '../../../atomic/Drawer';
import Box from '../../../atomic/Box';
import Button from '../../../atomic/Button';
import Close from '../../../../assets/icons/Close';
import ArrowRightAltIcon from '../../../../assets/icons/ArrowRightAlt';
import useDesktop from '../../../../hooks/useDesktop';
import DialogTitle from '../../../atomic/DialogTitle';
import Toolbar from '../../../atomic/Toolbar';
import Typography from '../../../atomic/Typography';
import IconButton from '../../../atomic/IconButton';
import CertificateFilter from './CertificateFilter';
import { useFindProfessionalsStyles } from '../../findProfessionalStyles';
import DistanceFilter from './DistanceFilter';
import FranchiserFilter from './FranchiserFilter';
import DialogScrollBox from '../../../DialogScrollBox';
import ArrowForward from '../../../../assets/icons/ArrowForward';
import { areIdsEqual } from '../../../../utils/strings';
import {
  FranchiserData,
  getSortOptionsForFindProfessionals,
  getSortOptionsForFindProfessionalsWithoutDistance,
} from '../../../../utils/findProfessional';
import ProgressButton from '../../../ProgressButton';
import {
  companyCertificates as fetchCompanyCertificates,
  professionTypes as fetchProfessionTypes,
} from '../../../../api/companies';
import useFetchEffect from '../../../../hooks/useFetchEffect';
import LocationFilter from './LocationFilter';
import ProfessionTypesFilter from './ProfessionTypesFilter';
import matomo from '../../../../utils/matomo';

const MoreFilters = ({
  showMoreFilters = false,
  onClose = () => {},
  onSubmit = () => {},
  propertyLocationData = [],
  currentProperty = {},
}) => {
  const isDesktop = useDesktop();
  const classes = useFindProfessionalsStyles();
  const [resetData, setResetData] = useState(false);
  const [resetCertificate, setResetCertificate] = useState(false);
  const [resetFranchise, setResetFranchise] = useState(false);
  const [resetProfessionType, setResetProfessionType] = useState(false);
  const [resetLocation, setResetLocation] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [drawerHeadingText, setDrawerHeadingText] = useState(
    'Filter og sortering'
  );
  const [sortOptions, setSortOptions] = useState(
    getSortOptionsForFindProfessionals()
  );
  const [moreFilters, setMoreFilter] = useState({
    distance: 32000000,
    certificates: '',
    franchiser: '',
    location: null,
    sortAttribute: getSortOptionsForFindProfessionals().find(
      (option) => option.label === 'Nærmest deg'
    ),
    professionTypes: '',
  });
  const [distance, setDistance] = useState(251);
  const [certificateType, setCertificateType] = useState([]);
  const [isLocationAvailable, setIsLocationAvailable] = useState(false);

  const url = new URL(window.location);
  const paramData = new URLSearchParams(url.search);

  const moreFilterOptions = [
    {
      code: 'PROFESSIONTYPES',
      displayName: 'Fagområde',
      visibility: true,
      isDisable: false,
    },
    {
      code: 'LOCATION',
      displayName: 'Sted',
      visibility: !isDesktop,
      isDisable: false,
    },
    {
      code: 'DISTANCE',
      displayName: isDesktop ? 'Sted' : 'Avstand fra deg',
      visibility: true,
      isDisable: !isDesktop && !moreFilters.location,
    },
    {
      code: 'CERTIFICATE',
      displayName: 'Sertifiseringer',
      visibility: true,
      isDisable: false,
    },
    {
      code: 'FRANCHISERS',
      displayName: 'Kjedetilhørighet',
      visibility: true,
      isDisable: false,
    },
  ];
  const [professions, setProfessions] = useState([]);
  useFetchEffect({
    apiFetchFunction: fetchCompanyCertificates,
    onSuccess: (data) => {
      setCertificateType(data);
    },
  });

  useFetchEffect({
    apiFetchFunction: fetchProfessionTypes,
    onSuccess: (data) => {
      setProfessions(data);
    },
  });

  const matomoFilterTrackEvent = (type, data) => {
    if (data) {
      matomo.clickEvent({
        category: 'Find Professionals',
        name: `Filter by ${type}`,
        action: 'Filter Professionals',
        value: data,
      });
    }
  };

  const matomoFilterTracking = () => {
    Object.entries(moreFilters).forEach(([key, value]) => {
      switch (key) {
        case 'distance':
          matomoFilterTrackEvent(key, value / 1000);
          break;
        case 'certificates':
          matomoFilterTrackEvent(key, value ? value.split(',').length : null);
          break;
        case 'franchiser':
          matomoFilterTrackEvent(key, value ? value.split(',').length : null);
          break;
        case 'professionTypes':
          matomoFilterTrackEvent(key, value ? value.split(',').length : null);
          break;
        default:
          // matomoFilterTrackEvent(key, '');
          break;
      }
    });
  };

  const updateFilter = (type, data) => {
    setMoreFilter({ ...moreFilters, [type]: data });
    if (type === 'sortAttribute') {
      matomo.clickEvent({
        category: 'Find Professionals',
        name: `Sort by ${data.sort}`,
        action: 'Sort Professionals',
      });
    }
  };

  useEffect(() => {
    if (!paramData.get('filters')) {
      if (currentProperty) {
        updateFilter('location', {
          label: 'Sted: '.concat(currentProperty.name),
          value: currentProperty.address?.geoCode,
        });
        setIsLocationAvailable(true);
      }
    }
  }, [currentProperty]);

  const onFranchiserSelect = (franchisers) => {
    const franchiserNames = franchisers
      .map((f) => encodeURIComponent(f))
      .join(',');
    updateFilter('franchiser', franchiserNames);
    setResetData(false);
  };

  const handleClose = () => {
    onClose(!showMoreFilters);
    setSelectedOption('');
    setDrawerHeadingText('Filter og sortering');
  };

  const onDistanceSelect = (distanceValue) => {
    if (distanceValue > 250) {
      updateFilter('distance', 3200 * 1000);
    } else {
      updateFilter('distance', distanceValue * 1000);
    }
    setDistance(distanceValue);
    setResetData(false);
  };

  const onCertificateSelect = (certificates) => {
    const certNames = (certificates || []).join(',');
    updateFilter('certificates', certNames);
    setResetData(false);
  };

  const onLocationSelect = (location) => {
    let sortAttr = {};
    if (location) {
      setIsLocationAvailable(true);
      setSortOptions(getSortOptionsForFindProfessionals());
      sortAttr = getSortOptionsForFindProfessionals().find(
        (option) => option.label === 'Nærmest deg'
      );
    } else {
      setIsLocationAvailable(false);
      setSortOptions(getSortOptionsForFindProfessionalsWithoutDistance());
      sortAttr = getSortOptionsForFindProfessionals().find(
        (option) => option.label === 'A-Å'
      );
    }
    setMoreFilter({
      ...moreFilters,
      location,
      sortAttribute: sortAttr,
    });
  };
  const onProfessionTypeSelect = (professionTypes) => {
    const profNames = (professionTypes || []).join(',');
    updateFilter('professionTypes', profNames);
    setResetData(false);
  };

  const onSelect = () => {
    matomoFilterTracking();
    onSubmit(JSON.parse(paramData.get('filters')));
    handleClose();
  };

  const clearOptions = () => {
    matomo.clickEvent({
      category: 'Find Professionals',
      name: 'Reset Filter',
      action: 'Filter Professionals',
    });
    if (selectedOption === '') {
      setDistance(251);
      setSortOptions(getSortOptionsForFindProfessionals());
      setMoreFilter({
        distance: 32000000,
        certificates: '',
        franchiser: '',
        location: {
          label: 'Sted: '.concat(currentProperty.name),
          value: currentProperty.address?.geoCode,
        },
        sortAttribute: getSortOptionsForFindProfessionals().find(
          (option) => option.label === 'Nærmest deg'
        ),
        professionTypes: '',
      });
      setResetData(true);
      setSelectedOption('');
    } else {
      switch (selectedOption) {
        case 'CERTIFICATE':
          setResetCertificate(true);
          break;
        case 'DISTANCE':
          setDistance(251);
          setMoreFilter({ ...moreFilters, distance: 32000000 });
          break;
        case 'FRANCHISERS':
          setResetFranchise(true);
          break;
        case 'PROFESSIONTYPES':
          setResetProfessionType(true);
          break;
        case 'LOCATION':
          setResetLocation(true);
          break;
        default:
          break;
      }
      setTimeout(() => {
        setResetCertificate(false);
        setResetFranchise(false);
        setResetProfessionType(false);
        setResetLocation(false);
      }, 2000);
    }
  };

  const handleBack = () => {
    setSelectedOption('');
    setDrawerHeadingText('Filter og sortering');
  };

  const isActive = (id) => areIdsEqual(id, moreFilters.sortAttribute?.value);

  const renderSelectedOption = () => (
    <>
      {selectedOption === 'CERTIFICATE' && (
        <CertificateFilter
          onSelect={(values) => onCertificateSelect(values)}
          selectedOptions={moreFilters.certificates}
          reset={resetData || resetCertificate}
          certificates={certificateType}
          showHeading={false}
        />
      )}
      {selectedOption === 'FRANCHISERS' && (
        <FranchiserFilter
          franchisers={FranchiserData}
          onSelect={(values) => onFranchiserSelect(values)}
          selectedOptions={moreFilters.franchiser}
          reset={resetData || resetFranchise}
          showHeading={false}
        />
      )}
      {selectedOption === 'DISTANCE' && (
        <DistanceFilter
          onSelect={(value) => onDistanceSelect(value)}
          value={distance}
          isLocationAvailable={isLocationAvailable}
          onLocationSelect={onLocationSelect}
          selectedLocation={moreFilters.location}
          propertyLocationData={propertyLocationData}
        />
      )}
      {selectedOption === 'LOCATION' && (
        <LocationFilter
          onSelect={(locationValue) => onLocationSelect(locationValue)}
          selectedOption={moreFilters.location}
          propertyLocations={propertyLocationData}
          reset={resetData || resetLocation}
          backOnSelect={() => {
            setTimeout(() => {
              setSelectedOption('');
            }, 600);
          }}
        />
      )}
      {selectedOption === 'PROFESSIONTYPES' && (
        <ProfessionTypesFilter
          professionTypes={professions}
          onSelect={(values) => onProfessionTypeSelect(values)}
          selectedOptions={moreFilters.professionTypes}
          reset={resetData || resetProfessionType}
          showHeading={false}
        />
      )}
    </>
  );

  const onChangeSort = (option) => {
    updateFilter('sortAttribute', option);
  };

  const getOptionDisplayValue = (code) => {
    switch (code) {
      case 'CERTIFICATE':
        const cert = moreFilters.certificates.split(',');
        return cert[0] !== '' ? `(${cert.length})` : '';
      case 'DISTANCE':
        return `(${
          moreFilters.distance > 250000 ? `250+` : moreFilters.distance / 1000
        } Km)`;
      case 'FRANCHISERS':
        const fran = moreFilters.franchiser.split(',');
        return fran[0] !== '' ? `(${fran.length})` : '';
      case 'LOCATION':
        const location = moreFilters.location?.value;
        return location ? `(1)` : '';
      case 'PROFESSIONTYPES':
        const prof = moreFilters.professionTypes?.split(',');
        return prof[0] !== '' ? `(${prof.length})` : '';
      default:
        break;
    }
    return 0;
  };

  const renderFilterBody = () => (
    <Box height="100%">
      {selectedOption === '' ? (
        <>
          <Box className={classes.moreFilterSortingSection}>
            <Typography
              component="span"
              color="inherit"
              style={{ fontWeight: 700 }}
            >
              Sorter etter
            </Typography>
            <Box className={classes.sortOptionsContainer}>
              {sortOptions.map((sortOption) => (
                <Box className={classes.sortOption} key={sortOption.value}>
                  <Button
                    key={`sort-type-item-${sortOption.value}`}
                    className={classes.generalButton}
                    onClick={() => onChangeSort(sortOption)}
                  >
                    <div
                      className={
                        isActive(sortOption.value)
                          ? classes.moreSelectedSortButton
                          : classes.moreNonSelectedSortButton
                      }
                    >
                      {sortOption.label}
                    </div>
                  </Button>
                </Box>
              ))}
            </Box>
          </Box>
          {moreFilterOptions.map((option) => (
            <React.Fragment key={option.code}>
              {option.visibility && (
                <Box
                  key={option.code}
                  onClick={() => {
                    setSelectedOption(option.code);
                    setDrawerHeadingText(option.displayName);
                  }}
                  className={`${classes.filterOptionContainer} ${
                    option.isDisable && classes.moreOptionDisable
                  }`}
                >
                  <Box
                    className={classes.filterOption}
                    key={`${option.code}-box`}
                  >
                    <Typography component="span" color="inherit">
                      {option.displayName}
                      {` ${getOptionDisplayValue(option.code)}`}
                    </Typography>
                  </Box>
                  <IconButton
                    size="small"
                    color="inherit"
                    key={`${option.code}-button`}
                  >
                    <ArrowForward
                      width="12px"
                      height="12px"
                      className={classes.accordianArrow}
                    />
                  </IconButton>
                </Box>
              )}
            </React.Fragment>
          ))}
        </>
      ) : (
        <>{renderSelectedOption()}</>
      )}
    </Box>
  );

  useEffect(() => {
    url.searchParams.set('filters', JSON.stringify(moreFilters));
    window.history.replaceState(null, '', url.toString());
  }, [moreFilters]);

  useState(() => {
    if (paramData.get('filters')) {
      setMoreFilter(JSON.parse(paramData.get('filters')));
      onSubmit(JSON.parse(paramData.get('filters')));
      setDistance(
        Number(JSON.parse(paramData.get('filters'))?.distance || 250000) / 1000
      );
    }
  }, []);

  return (
    <>
      {isDesktop ? (
        <Dialog
          open={showMoreFilters}
          scroll="paper"
          onClose={(_, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
              onSelect();
            }
          }}
          fullWidth
          maxWidth="md"
          disableEscapeKeyDown
          fullScreen={!isDesktop}
        >
          <DialogTitle
            className={
              selectedOption === ''
                ? classes.moreFiltersDialogTitle
                : classes.moreFiltersDialogFilterTitle
            }
          >
            <Toolbar>
              {selectedOption !== '' && (
                <ArrowRightAltIcon
                  className={classes.backIcon}
                  onClick={() => handleBack()}
                />
              )}
              <Box
                p={isDesktop ? 0 : 2}
                display="flex"
                flexGrow={1}
                flexDirection="column"
              >
                <Typography className={classes.drawerHeaderText}>
                  {selectedOption === '' ? (
                    <b>{drawerHeadingText}</b>
                  ) : (
                    <b>&nbsp;&nbsp;&nbsp;{drawerHeadingText}</b>
                  )}
                </Typography>
                {isDesktop && selectedOption === '' && (
                  <Typography className={classes.dialogSmallText}>
                    Finn håndverker
                  </Typography>
                )}
              </Box>
              <IconButton
                size="small"
                color="inherit"
                onClick={() => handleClose()}
              >
                <Close />
              </IconButton>
            </Toolbar>
          </DialogTitle>

          <DialogScrollBox>
            <Box className={classes.moreFilterBody}>{renderFilterBody()}</Box>
          </DialogScrollBox>
          <Box display="flex" marginBottom="auto" padding="16px 32px 16px 32px">
            <Box marginRight="30px">
              <Button
                underline={false}
                onClick={clearOptions}
                className={classes.cancelButton}
              >
                {selectedOption === '' ? 'Nullstill alle filter' : 'Nullstill'}
              </Button>
            </Box>

            <Box>
              <ProgressButton
                underline={false}
                className={classes.actionButton}
                color="primary"
                variant="contained"
                onClick={() => onSelect()}
              >
                Vis resultater{' '}
              </ProgressButton>
            </Box>
          </Box>
        </Dialog>
      ) : (
        <Drawer
          anchor="bottom"
          open={showMoreFilters}
          PaperProps={{ style: { height: '80vh' } }}
          classes={{
            paper: classes.mobileDrawer,
            root: classes.mobileDrawerModel,
          }}
        >
          <Box
            p={2}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {selectedOption !== '' && (
              <ArrowRightAltIcon
                className={classes.backIcon}
                onClick={() => handleBack()}
              />
            )}
            <Typography variant="h3" component="h3" color="inherit">
              <b>{drawerHeadingText}</b>
            </Typography>
            <Box display="flex">
              {selectedOption !== '' && (
                <Button underline onClick={clearOptions}>
                  Nullstill
                </Button>
              )}
              <Close
                className={classes.drawerClose}
                onClick={() => {
                  handleClose();
                  onSelect();
                }}
              />
            </Box>
          </Box>
          <DialogScrollBox>{renderFilterBody()}</DialogScrollBox>
          {selectedOption === '' ? (
            <Box
              display="flex"
              marginBottom="25px"
              padding="8px 8px 8px 8px"
              borderTop="1px solid #DDE2F1"
            >
              <Box flexGrow="1">
                <Button
                  underline={false}
                  onClick={clearOptions}
                  className={classes.cancelButton}
                >
                  Nullstill
                </Button>
              </Box>

              <Box>
                <ProgressButton
                  underline={false}
                  color="primary"
                  variant="contained"
                  className={classes.actionButton}
                  onClick={() => onSelect()}
                >
                  Vis resultater
                </ProgressButton>
              </Box>
            </Box>
          ) : (
            <>
              {selectedOption !== 'LOCATION' && (
                <Box
                  display="flex"
                  marginBottom="15px"
                  padding="8px"
                  height="50px"
                >
                  <ProgressButton
                    underline={false}
                    className={classes.mobileLagreButton}
                    onClick={() => setSelectedOption('')}
                  >
                    Lagre valg
                  </ProgressButton>
                </Box>
              )}
            </>
          )}
        </Drawer>
      )}
    </>
  );
};

export default MoreFilters;
