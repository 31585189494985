import clsx from 'clsx';
import React, { createElement } from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Link from '../atomic/Link';
import Badge from '../atomic/Badge';
import NavItemBase from '../atomic/NavbarItem';
import NotificationsTag from '../atomic/NotificationsTag';

const MobileNavItem = ({
  icon,
  title,
  classes,
  isActive,
  className,
  iconProps,
  badgeContent = null,
  notificationCount = null,
  moreAction = false,
  ...props
}) => {
  const renderLabel = () => <span className={classes.label}>{title}</span>;

  const renderIcon = () => (
    <Badge
      overlap="rectangular"
      color="primary"
      badgeContent={badgeContent}
      classes={{ badge: classes.badge }}
    >
      {createElement(icon, { className: classes.icon, ...iconProps })}
      {notificationCount > 0 && (
        <NotificationsTag
          className={classes.notificationNumber}
          qty={notificationCount}
        />
      )}
    </Badge>
  );

  return (
    <NavItemBase
      showLabel
      component={moreAction ? undefined : Link}
      underline={false}
      icon={renderIcon()}
      label={renderLabel()}
      className={clsx(
        className,
        classes.navItem,
        isActive && classes.navItemActive
      )}
      {...props}
    />
  );
};

export default withStyles(styles)(MobileNavItem);
