import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';

import styles from './style';
import inquiryTypes from './util';
import { parseQuery } from '../../../../../utils/routes';
import useDesktop from '../../../../../hooks/useDesktop';
import TextArea from '../../../../../components/TextArea';
import useSnackbar from '../../../../../hooks/useSnackbar';
import Form from '../../../../../components/forms/BaseForm';
import { sGetUser } from '../../../../../store/reducers/auth';
import useFetchEffect from '../../../../../hooks/useFetchEffect';
import Autocomplete from '../../../../../components/Autocomplete';
import TextField from '../../../../../components/atomic/TextField';
import Typography from '../../../../../components/atomic/Typography';
import ErrorContainer from '../../../../../components/ErrorContainer';
import PageLayout from '../../../../../components/layouts/WithHeader';
import { postFeedback as apiPostFeedback } from '../../../../../api/zendesk';
import { sGetPropertyByBoligmappaNumber } from '../../../../../store/reducers/properties';
import { fetchPropertyOwner as apiFetchPropertyOwner } from '../../../../../api/propertyowners';

const ContactUs = ({ user, classes, getPropertyByBoligmappaNumber }) => {
  const history = useHistory();
  const isDesktop = useDesktop();
  const { search } = useLocation();
  const parsedQuery = parseQuery(search);

  const { boligmappaNumber } = useParams();
  const { showSuccessMessage } = useSnackbar();

  const [requestInProgress, setRequestInProgress] = useState(false);

  const [inquiryType, setInquiryType] = useState([]);
  const [description, setDescription] = useState('');
  const [requestError, setRequestError] = useState(null);
  const [propertyOwnerName, setPropertyOwnerName] = useState('');

  const getInquiryType = () => {
    const { type: queryType } = parsedQuery || {};

    return queryType
      ? inquiryTypes.find(({ value }) => value === queryType)
      : inquiryTypes[0];
  };

  const {
    fetching,
    fetchingError,

    data: email,
    setData: setEmail,
  } = useFetchEffect({
    initialDataState: '',
    apiFetchFunction: apiFetchPropertyOwner,
    transformResponse: (response) => response.email,
  });

  const onFormSubmit = async () => {
    setRequestError(null);
    setRequestInProgress(true);

    const { id, name, address } =
      getPropertyByBoligmappaNumber(boligmappaNumber);

    const { streetName, houseNumber, houseSubNumber, postalCode, postalPlace } =
      address;

    const feedbackObj = {
      message: description,
      type: inquiryType.value,
      propertyOwnerId: id,
      propertyOwnerName,
      propertyOwnerEmail: email,
      boligmappaNumber,
      deviceInformation: window.navigator.userAgent,
      matrikkel: name,
      address: `${streetName} ${houseNumber} ${houseSubNumber} ${postalCode} ${postalPlace}`,
    };

    const [error, response] = await apiPostFeedback(feedbackObj);

    if (error && !response) {
      setRequestError(error);
    } else {
      showSuccessMessage('Henvendelsen din har blitt sendt.');

      if (!isDesktop) {
        history.goBack();
      } else {
        setDescription('');
      }
    }
    setRequestInProgress(false);
  };

  const onChangeInquiryType = (options) => {
    setInquiryType(options);
  };

  useEffect(() => {
    setPropertyOwnerName(user.name);
  }, [user]);

  useEffect(() => setInquiryType(getInquiryType()), []);

  return (
    <PageLayout minFullHeight headerTitle={inquiryType.title}>
      {fetchingError ? (
        <ErrorContainer paddingTop={false} errorResponse={fetchingError} />
      ) : (
        <>
          <Typography component="p" variant="body2" color="textPrimary">
            {inquiryType.intro}
          </Typography>

          <Form
            fetching={fetching}
            error={requestError}
            onSubmit={onFormSubmit}
            submitButtonText="Send"
            className={classes.form}
            requestInProgress={requestInProgress}
          >
            <TextField
              required
              type="email"
              value={email || ''}
              label="E-postadresse"
              disabled={fetching || requestInProgress}
              onChange={(e) => setEmail(e.target.value)}
            />
            <br />
            <br />

            <Autocomplete
              autoFocus
              isLoading={false}
              isClearable={false}
              value={inquiryType}
              isSearchable={false}
              options={inquiryTypes}
              className={classes.formElement}
              loadingMessage={() => 'Laster...'}
              label="Hva gjelder henvendelsen?"
              disabled={fetching || requestInProgress}
              noOptionsMessage={() => 'Ingen hendelser'}
              onChange={(options) => onChangeInquiryType(options)}
            />
            <br />

            <TextArea
              required
              maxLength={500}
              value={description}
              label={inquiryType.desc}
              className={classes.formElement}
              disabled={fetching || requestInProgress}
              placeholder={inquiryType.descriptionPlaceholder}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form>
        </>
      )}
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: sGetUser(state),
  getPropertyByBoligmappaNumber: (boligmappaNumber) =>
    sGetPropertyByBoligmappaNumber(state, boligmappaNumber),
});

export default connect(mapStateToProps, null)(withStyles(styles)(ContactUs));
