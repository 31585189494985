/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import Card from '../../../../../components/Card';
import {
  OverviewGridItem,
  PropertyEstimate,
  Loader,
  EstimateCardHeader,
} from './components';

import { useValueEstimate } from '../../../../../api/hooks';
import { useActiveProperty } from '../../../../../store/hooks';
import { useSectionStyles } from './sectionStyles';
import Pencil from '../../../../../assets/icons/Pencil';
import useDesktop from '../../../../../hooks/useDesktop';
import Link from '../../../../../components/atomic/Link';

function PropertyEstimateSection({
  showMaxwidth,
  hasValueEstimateError,
  hideValues,
}) {
  const classes = useSectionStyles();

  const { property } = useActiveProperty();

  const valueEstimate = useValueEstimate();
  const isDesktop = useDesktop();

  useEffect(() => {
    if (!property?.boligmappaNumber) return;

    valueEstimate.executeFetch();

    if (valueEstimate.error) {
      hasValueEstimateError(true);
    } else {
      hasValueEstimateError(false);
    }
  }, [property?.boligmappaNumber]);

  const displayValueEstimate =
    !valueEstimate.isLoading && property.valueEstimate;
  if (valueEstimate.isLoading) {
    return (
      <>
        <OverviewGridItem showMaxwidth={showMaxwidth}>
          <Card className={classes.estimateCard}>
            <EstimateCardHeader
              classes={classes}
              displayIcon
              isLoading={valueEstimate.isLoading}
              editButtonLink={valueEstimate.data?.linktovirdi}
            />

            {valueEstimate.isLoading && <Loader />}
          </Card>
        </OverviewGridItem>
      </>
    );
  }

  return (
    <>
      {!valueEstimate.error &&
        valueEstimate.data &&
        !valueEstimate.isLoading && (
          <OverviewGridItem showMaxwidth={showMaxwidth}>
            <Card className={classes.estimateCard}>
              <EstimateCardHeader
                classes={classes}
                displayIcon
                isLoading={valueEstimate.isLoading}
                editButtonLink={valueEstimate.data?.linktovirdi}
              />

              {displayValueEstimate && (
                <>
                  <PropertyEstimate
                    data={property.valueEstimate}
                    hideValues={hideValues}
                  />
                  {!isDesktop && (
                    <Link
                      to={valueEstimate.data?.linktovirdi}
                      isExternal
                      openInNewTab
                      className={classes.EditButton}
                    >
                      <Pencil
                        style={{
                          width: 24,
                          height: 24,
                        }}
                      />
                    </Link>
                  )}
                </>
              )}
            </Card>
          </OverviewGridItem>
        )}
    </>
  );
}

export default PropertyEstimateSection;
