const styles = (theme) => ({
  overlay: {
    top: 0,
    zIndex: 1300,
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: theme.palette.primary.whiteDark,
  },
});

export default styles;
