import { cssVariables } from './cssVariables';
import { ThemeObject } from './themeTypes';

/**
 * Loops through a theme, and assigns the theme values to
 * the matching css variable. Returns a string with all the
 * css varables of the provided theme.
 */
export function createVariablesFromTheme(
  themeObject: ThemeObject,
  variableObject = cssVariables,
): string {
  let vars = '';
  Object.keys(themeObject).forEach((key) => {
    const value = themeObject[key];
    if (typeof value === 'string') {
      const variableName = variableObject[key];
      const variableValue = value;
      vars += `${variableName}: ${variableValue};`;
    } else if (typeof value === 'object') {
      const nestedVars = createVariablesFromTheme(value, variableObject[key]);
      vars += nestedVars;
    }
  });
  return vars;
}

export type BorderStyle =
  | 'dotted'
  | 'dashed'
  | 'solid'
  | 'double'
  | 'groove'
  | 'ridge'
  | 'inset'
  | 'outset'
  | 'none'
  | 'hidden';

export const makeBorder = (
  color: string,
  width: string = '1.5px',
  style: BorderStyle = 'solid',
) => `${width} ${style} ${color}`;

export const makeOutline = (
  color: string,
  width: string = '2.5px',
  style: BorderStyle = 'solid',
) => `${width} ${style} ${color}`;
