import React from 'react';
import FilterItem from '../FilterItem';
import FilterMenu from '../FilterMenu';
import FilterList from '../FilterList';
import useMenuState from '../../../hooks/useMenuState';

const OptionListFilter = ({
  name,
  description,
  filterOptions,
  onFiltersReset,
}) => {
  const {
    onMenuOpen,
    onMenuClose,
    open: menuOpen,
    setOpen: setMenuOpen,
  } = useMenuState();

  return (
    <>
      <FilterItem name={name} onClick={onMenuOpen} />

      <FilterMenu
        open={menuOpen}
        title={description}
        setOpen={setMenuOpen}
        onMenuClose={onMenuClose}
      >
        <FilterList onReset={onFiltersReset} filters={filterOptions} />
      </FilterMenu>
    </>
  );
};

export default OptionListFilter;
