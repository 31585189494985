export const defaultState = {
  soldMaketEvents: null,
  forSaleMaketEvents: null,
};

export const SET_SOLD_MARKET_EVENTS = 'SET_SOLD_MARKET_EVENTS';
export const SET_FOR_SALE_MARKET_EVENTS = 'SET_FOR_SALE_MARKET_EVENTS';
export const CLEAR_MARKET_EVENTS = 'CLEAR_MARKET_EVENTS';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_SOLD_MARKET_EVENTS:
      return {
        ...state,
        soldMaketEvents: action.payload,
      };
    case SET_FOR_SALE_MARKET_EVENTS:
      return {
        ...state,
        forSaleMaketEvents: action.payload,
      };
    case CLEAR_MARKET_EVENTS:
      return defaultState;
    default:
      return state;
  }
};

export const sGetMarketEventsModule = (state) => state.marketEvents;
export const sGetSoldMarketEvents = (state) =>
  sGetMarketEventsModule(state).soldMaketEvents;
export const sGetForSaleMarketEvents = (state) =>
  sGetMarketEventsModule(state).forSaleMaketEvents;

export const sGetFetchingMarketEvents = (state) =>
  sGetMarketEventsModule(state).fetching;

export const sGetFetchingMarketEventsError = (state) =>
  sGetMarketEventsModule(state).fetchingError;
