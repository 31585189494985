import React, { forwardRef } from 'react';
import MenuBase from '@material-ui/core/Menu';

const Menu = forwardRef(
  (
    {
      anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'right',
      },
      getContentAnchorEl = null,
      ...props
    },
    ref
  ) => (
    <MenuBase
      ref={ref}
      anchorOrigin={anchorOrigin}
      getContentAnchorEl={getContentAnchorEl}
      {...props}
    />
  )
);

export default Menu;
