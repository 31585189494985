const styles = (theme) => ({
  paper: {
    padding: '5px',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    '@supports (padding-bottom: env(safe-area-inset-bottom))': {
      paddingBottom: 'calc(env(safe-area-inset-bottom, 100px) * 0.6)',
    },
  },
  touch: {
    height: '4px',
    width: '30px',
    margin: '0 auto',
    borderRadius: '10px',
    background: theme.palette.primary.greyLight,
  },
  titleContainer: {
    padding: '8px 0 0',
  },
  title: {
    display: 'block',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    padding: '10px 15px 20px',
    overflowWrap: 'break-word',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  titleDivider: {
    background: theme.palette.primary.whiteDarker,
  },
});

export default styles;
