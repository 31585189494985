import React from 'react';

const ChapterNine = ({
  width = '100%',
  height = '100%',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28.902 30.104"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(0 0)">
      <path
        fill={fill}
        transform="translate(0 -440.948)"
        d="M8.82,456.148a.585.585,0,0,0-.779,0l-7.827,6.9a.644.644,0,0,0-.177.695.6.6,0,0,0,.566.411h1.2a.615.615,0,0,1,.6.627v5.645a.615.615,0,0,0,.6.627h3.01a.615.615,0,0,0,.6-.627V467.6a1.808,1.808,0,1,1,3.613,0v2.822a.615.615,0,0,0,.6.627h3.01a.615.615,0,0,0,.6-.627v-5.645a.615.615,0,0,1,.6-.627h1.2a.6.6,0,0,0,.567-.411.644.644,0,0,0-.177-.695Z"
      />
      <path
        fill={fill}
        transform="translate(1.283 -441.901)"
        d="M6.245,454.759h6.623a1.255,1.255,0,0,0,0-2.509H6.245a1.255,1.255,0,0,0,0,2.509Z"
      />
      <path
        fill={fill}
        transform="translate(4.029 -444)"
        d="M24.52,445.989l-1.557-1.622a1.171,1.171,0,0,0-1.7,0l-5.242,5.46a.646.646,0,0,0,0,.887l2.408,2.509a.586.586,0,0,0,.851,0l5.243-5.46A1.292,1.292,0,0,0,24.52,445.989Z"
      />
      <path
        fill={fill}
        transform="translate(3.42 -442.475)"
        d="M15.672,450.184a.576.576,0,0,0-.565-.167.613.613,0,0,0-.432.413l-1.2,3.763a.647.647,0,0,0,.146.642.587.587,0,0,0,.616.151l3.613-1.254a.618.618,0,0,0,.4-.449.644.644,0,0,0-.158-.59Z"
      />
      <path
        fill={fill}
        transform="translate(0.184 -441.901)"
        d="M1.925,454.759H3.731a1.255,1.255,0,0,0,0-2.509H1.925a1.255,1.255,0,0,0,0,2.509Z"
      />
    </g>
  </svg>
);

export default ChapterNine;
