import { get, post, put, patch, del } from '../utils/http';
import { uploadFile } from './files';
import { eventRequestDataTransformer } from './transformers/events';
import { publicRequest } from '../utils/api';
import { storeReferralSuggested } from '../utils/referral';

export const fetchEvent = (eventId) =>
  get(`events/${eventId}`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const deleteEvent = (eventId) =>
  del(`events/${eventId}`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const updateEvent = (eventId, data) =>
  patch(`events/${eventId}`, data, {
    transformRequest: eventRequestDataTransformer,
  })
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const createEvent = (data) =>
  post('events', data, { transformRequest: eventRequestDataTransformer })
    .then((response) =>{
      storeReferralSuggested(false);
      return [null, response.data.response]} )
    .catch((error) => [error, null]);

export const requestUploadLink = (eventId, data) =>
  put(`events/${eventId}/images`, data)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const uploadImage = async (eventId, file, position) => {
  const data = {
    position,
    fileName: file.name,
  };

  const [error, response] = await requestUploadLink(eventId, data);

  if (error) return [error, null];

  const [uploadError] = await uploadFile(response.uploadLink, file);

  return uploadError ? [uploadError, null] : [null, response];
};

export const fetchEventTemplates = (params) =>
  get(`events/templates`, { params })
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

// Public APIs
export const fetchPublicEvents = (boligmappaNumber) =>
  publicRequest
    .get(`/properties/${boligmappaNumber}/events`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const fetchPublicEvent = (eventId) =>
  publicRequest
    .get(`/events/${eventId}`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);
