export const defaultState = null;

export const SET_UX_SIGNAL_STATUS = 'SET_UX_SIGNAL_STATUS';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_UX_SIGNAL_STATUS:
      return action.payload;
    default:
      return state;
  }
};

export const sGetUxSignalStatus = (state) => state.uxSignal;
