import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from '../../style';

const IFrameBlock = ({ iFrame, classes }) => {
  return (
    <>
      <span className={classes.iframeTitleWrapper}>{iFrame.title}</span>
      <div className={classes.iframeWrapper}>
        <iframe
          data-src={iFrame.url}
          height="75.5px"
          data-qa="iframe"
          title="otovo-address-search"
          className={`${classes.iframe} otovo-address-search`}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(IFrameBlock);
