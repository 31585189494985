import { mapVariables } from './helpers';

export const fontVariables = {
  family: '--hj-fonts-family',
  size: {
    xxxxs: '--hj-fonts-size-xxxxs',
    xxxs: '--hj-fonts-size-xxxs',
    xxs: '--hj-fonts-size-xxs',
    xs: '--hj-fonts-size-xs',
    s: '--hj-fonts-size-s',
    m: '--hj-fonts-size-m',
    l: '--hj-fonts-size-l',
    xl: '--hj-fonts-size-xl',
    xxl: '--hj-fonts-size-xxl',
    xxxl: '--hj-fonts-size-xxxl',
  },
  weight: {
    light: '--hj-fonts-weight-light',
    normal: '--hj-fonts-weight-normal',
    bold: '--hj-fonts-weight-bold',
  },
  lineHeight: {
    xxxs: '--hj-fonts-line-height-xxxs',
    xxs: '--hj-fonts-line-height-xxs',
    xs: '--hj-fonts-line-height-xs',
    s: '--hj-fonts-line-height-s',
    m: '--hj-fonts-line-height-m',
    l: '--hj-fonts-line-height-l',
    xl: '--hj-fonts-line-height-xl',
    xxl: '--hj-fonts-line-height-xxl',
    xxxl: '--hj-fonts-line-height-xxxl',
  },
};

export const fonts = mapVariables<typeof fontVariables>(fontVariables);
