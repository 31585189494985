import React from 'react';

const MoreOptionsIcon = ({ width = '0.9rem', height = '0.9rem', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 2 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 3.3125C0.726562 3.3125 0.511719 3.41016 0.335938 3.58594C0.140625 3.78125 0.0625 3.99609 0.0625 4.25C0.0625 4.52344 0.140625 4.73828 0.335938 4.91406C0.511719 5.10938 0.726562 5.1875 1 5.1875C1.25391 5.1875 1.46875 5.10938 1.66406 4.91406C1.83984 4.73828 1.9375 4.52344 1.9375 4.25C1.9375 3.99609 1.83984 3.78125 1.66406 3.58594C1.46875 3.41016 1.25391 3.3125 1 3.3125ZM0.0625 1.28125C0.0625 1.02734 0.140625 0.8125 0.335938 0.617188C0.511719 0.441406 0.726562 0.34375 1 0.34375C1.25391 0.34375 1.46875 0.441406 1.66406 0.617188C1.83984 0.8125 1.9375 1.02734 1.9375 1.28125C1.9375 1.55469 1.83984 1.76953 1.66406 1.94531C1.46875 2.14062 1.25391 2.21875 1 2.21875C0.726562 2.21875 0.511719 2.14062 0.335938 1.94531C0.140625 1.76953 0.0625 1.55469 0.0625 1.28125ZM0.0625 7.21875C0.0625 6.96484 0.140625 6.75 0.335938 6.55469C0.511719 6.37891 0.726562 6.28125 1 6.28125C1.25391 6.28125 1.46875 6.37891 1.66406 6.55469C1.83984 6.75 1.9375 6.96484 1.9375 7.21875C1.9375 7.49219 1.83984 7.70703 1.66406 7.88281C1.46875 8.07812 1.25391 8.15625 1 8.15625C0.726562 8.15625 0.511719 8.07812 0.335938 7.88281C0.140625 7.70703 0.0625 7.49219 0.0625 7.21875Z"
      fill="#111111"
    />
  </svg>
);
export default MoreOptionsIcon;
