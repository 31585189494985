const style = (theme) => ({
  dialogContent: {
    padding: '0px !important',
    radius: '8px',
    '&.mobile': {
      padding: '0px',
    },
  },
  dialog: {
    width: '500px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0px',
    },
  },
  title: {
    marginTop: '0px',
  },
  imageContainer: {
    width: '100%',
    height: '128px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  textContainer: {
    width: 'auto',
    padding: '32px',
    background: '#FDF3DA',
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      padding: '32px',
    },
  },
  buttonContainer: {
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
  },
  button: {
    width: 'auto',
    height: '32px',
    fontSize: '14px',
    marginRight: '8px',
    '&:disabled': {
      color: theme.colors.grey,
      background: theme.colors.whiteDarker,
    },
  },
  btn: {
    width: 'auto',
    height: '32px',
    fontSize: '14px',
    background: '#fff',
    border: '1px solid #D1EDE8',
    '&:hover': {
      background: theme.colors.whiteDarker,
      borderColor: theme.colors.whiteDarker,
    },
  },
  emailInput: {
    marginBottom: '4px',
    borderColor: theme.colors.greyLightBlack,
    color: theme.palette.background,
  },
  DialogContent: {
    padding: 0,
  },
});

export default style;
