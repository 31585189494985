const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.mint,

    '&:hover': {
      backgroundColor: theme.palette.primary.mint,
    },
  },
  content: {
    display: 'block',
  },
  imageIcon: {
    width: '50px',
    height: '50px',
    fill: theme.palette.primary.main,
  },
});

export default styles;
