import { withStyles } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import Box from '../../components/atomic/Box';
import ProgressButton from '../../components/ProgressButton';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';
import styles from './styles';

const OtovoCardInfor = ({ classes }) => {
  const history = useHistory();
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  return (
    <Box className={classes.otovoWrapper}>
      <Box className={classes.otowoInforWrapper}>
        <Box className={classes.otovoHeader}>Spar penger på taket ditt</Box>
        <p className={classes.otovoDescription}>
          Økende strømpriser gjør solstrøm til et mer lønnsomt alternativ. Nå
          kan vi beregne den årlige besparelsen du gjør ved å kjøpe solceller.
        </p>
      </Box>
      <Box className={classes.buttonContainer}>
        <ProgressButton
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={() => {
            history.push(`${dashboardLinkPrefix}/addon/otovo`);
          }}
        >
          Sjekk ditt tak
        </ProgressButton>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(OtovoCardInfor);
