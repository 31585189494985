const styles = (theme) => ({
  titleDivider: {
    border: '1px solid #FD6764',
    width: '40px',
  },
  contentText: {
    fontWeight: '500',
  },
  imageMobile: {
    height: '135px',
  },
  imageDesktop: {
    height: '177px',
  },
  messageBox: {
    display: 'flex',
    width: '544px',
    padding: '24px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '24px',
    borderRadius: '8px',
    border: '1px solid var(--BM-Grey, #E6E6E6)',
    background: 'var(--Vipps-white-white, #FFF)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  messageText: {
    color: '#000',
    textAlign: 'center',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '19px' /* 105.556% */,
  },
  messageImage: {
    display: 'flex',
    width: '30px',
    height: '30px',
  },
  messageBtn: {
    color: 'var(--BM-Light-Black, #717171)',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '19px' /* 135.714% */,
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
  headerImage: {
    width: '152.006px',
    height: '27.001px',
    flexShrink: '0',
  },
  headerBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '32px',
  },
  messageMoreText: {
    color: '#000',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '19px' /* 105.556% */,
  },
  messageMoreTextItems: {
    paddingLeft: '24px',
  },
  button: {
    width: '165px',
    height: '40px',
  },
});

export default styles;
