const styles = (theme) => ({
  listItem: ({ disabled }) => ({
    padding: '16px',
    paddingLeft: '16px',
    paddingRight: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    color: disabled
      ? theme.palette.primary.greyLight
      : theme.palette.text.primary,
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  }),
  borderClass: {
    borderTop: `1px solid ${theme.palette.primary.whiteDarker}`,
  },
});

export default styles;
