import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useFlag } from '@unleash/proxy-client-react';

import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import moment from 'moment';
import {
  getDocumentPath,
  isDocumentReadOnly,
  getDocumentFileType,
  getDocumentEvents,
  getDocumentRooms,
  getDocumentType,
  getDocumentCreatedDate,
  getDocumentOwnedByName,
  getDocumentName,
  getDocumentId,
  getOriginalDocumentPath,
} from '../../../../../../utils/documents';
import styles from './style';
import useDesktop from '../../../../../../hooks/useDesktop';
import Paper from '../../../../../../components/atomic/Paper';
import FileViewer from '../../../../../../components/FileViewer';
import Page from '../../../../../../components/layouts/WithHeader';
import DocumentDetails from '../../../../../../modules/DocumentDetails';
import ErrorContainer from '../../../../../../components/ErrorContainer';
import { sGetDocuments } from '../../../../../../store/reducers/documents';
import { acDeleteDocument } from '../../../../../../store/actions/documents';
import CirclesLoader from '../../../../../../components/loaders/CirclesLoader';
import { acDetachDocumentFromRooms } from '../../../../../../store/actions/rooms';
import { acDetachDocumentFromEvents } from '../../../../../../store/actions/events';
import {
  fetchDocument as apiFetchDocument,
  updateDocument as apiUpdateDocument,
} from '../../../../../../api/documents';
import {
  fetchPersonalDocument as apiFetchPersonalDocument,
  updatePersonalDocument as apiUpdatePersonalDocument,
} from '../../../../../../api/propertyowners';
import CloseBold from '../../../../../../assets/icons/DocumentIcons/CloseBold';
import PrimaryButton from '../../../../../../components/Buttons/PrimaryButton/PrimaryButton';
import PencilOutlined from '../../../../../../assets/icons/PencilOutlined';
import BottomContextMenu from '../../../../../../components/BottomContextMenu';
import ArrowUp from '../../../../../../assets/icons/ArrowUp';
import Check from '../../../../../../assets/icons/Check';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import { sGetEvents } from '../../../../../../store/reducers/events';
import { getEventId, getEventName } from '../../../../../../utils/events';
import { parseQuery } from '../../../../../../utils/routes';
import { getRoomId, getRoomName } from '../../../../../../utils/rooms';
import { sGetRooms } from '../../../../../../store/reducers/rooms';
import documentTypes from '../../../../../../components/forms/DocumentForm/documentTypes';
import { sGetUser } from '../../../../../../store/reducers/auth';
import { prepareDocumentData } from '../../../../../../components/forms/DocumentForm/util';
import { getUserFullname } from '../../../../../../utils/auth';
import DocumentActionMenu from '../../../../../../components/DocumentView/DocumentViewTable/DocumentActionMenu';
import useHeaderSnackbar from '../../../../../../hooks/useHeaderSnackbar';
import ShowErrorMessage from '../../../../../../components/ShowErrorMessage';
import { sGetPersonalDocuments } from '../../../../../../store/reducers/personalDocuments';
import { acDeletePersonalDocument } from '../../../../../../store/actions/personalDocuments';

const Show = ({
  classes,
  document,
  fetching,
  documents,
  personalDocuments,
  rooms,
  events,
  authUser,
  fetchingError,
  onDocumentUpdated,
  deleteDocument,
  deletePersonalDocument,
  detachDocumentFromRooms,
  detachDocumentFromEvents,
  isPersonalDocument,
}) => {
  const { search } = useLocation();
  const parsedQuery = parseQuery(search);
  const { roomIds: queryRoomIds, eventIds: queryEventIds } = parsedQuery || {};
  const history = useHistory();
  const isDesktop = useDesktop();
  const location = useLocation();
  const { documentId, boligmappaNumber } = useParams();
  const { showSuccessMessage } = useSnackbar();
  const { snackbarHeight } = useHeaderSnackbar();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openContextMenu, setOpenContextMenu] = useState(false);
  const [documentPath, setDocumentPath] = useState(null);
  const [isConvertedFile, setIsConvertedFile] = useState(false);
  const [fileType, setfileType] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isMobileContext, setIsMobileContext] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState('');
  const [eventIds, setEventIds] = useState([]);
  const [roomIds, setRoomIds] = useState([]);
  const [documentType, setDocumentType] = useState(null);
  const [typeOfFile, setTypeOfFile] = useState();
  const [inputWidth, setInputWidth] = useState(0);

  const swipeableRef = useRef(null);
  const editInputRef = useRef(null);
  const hiddenInputRef = useRef(null);
  const enableBoligmappaPlus = useFlag('boligeier.boligmappaPlus');

  const resetFileName = () => {
    setEditedName(getDocumentName(document).replace(`.${typeOfFile}`, ''));
  };

  const onCloseContext = () => {
    swipeableRef.current.scrollTop = 0;
    setIsMobileContext(false);
  };

  const onEditName = () => {
    setIsEditingName(true);
    editInputRef.current.focus();
  };

  const onCloseEdit = () => {
    resetFileName();
    setIsEditingName(false);
  };
  const onNameSaved = async () => {
    setIsEditingName(false);
    const data = prepareDocumentData({
      rooms,
      events,
      title: `${editedName}.${typeOfFile}`,
      roomIds,
      eventIds,
      documentType,
      updatedDate: moment().format(),
      createdDate: getDocumentCreatedDate(document) || moment().format(),
      ownedByName:
        getDocumentOwnedByName(document) || getUserFullname(authUser),
    });
    const personalData = prepareDocumentData({
      title: `${editedName}.${typeOfFile}`,
      documentType,
      updatedDate: moment().format(),
      createdDate: getDocumentCreatedDate(document) || moment().format(),
      ownedByName:
        getDocumentOwnedByName(document) || getUserFullname(authUser),
    });

    if (isPersonalDocument) {
      const patchData = {
        title: `${editedName}.${typeOfFile}`,
        description: document?.description,
        documentTypeId: document?.documentType?.id,
      };

      const [error, response] = await apiUpdatePersonalDocument(
        patchData,
        documentId
      );
      if (response && !error) {
        showSuccessMessage();
        onDocumentUpdated({ ...document, ...personalData });
      } else {
        ShowErrorMessage();
      }
    } else {
      const [requestError, response] = await apiUpdateDocument(
        documentId,
        data
      );

      if (response && !requestError) {
        onDocumentUpdated({ ...document, ...data });
        showSuccessMessage();
      }
    }
  };

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      onNameSaved();
    }
  };

  const onMenuOpenClick = (event) => {
    setOpenContextMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const supportedFileTypes = [
    'txt',
    'docx',
    'doc',
    'xlsx',
    'xls',
    'pptx',
    'ppt',
    'rtf',
  ];

  const onDocumentClose = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(`/dashboard/${boligmappaNumber}/documents`);
    }
  };

  const onDocumentDeleted = () => {
    if (isPersonalDocument) {
      if (personalDocuments) {
        deletePersonalDocument(document);
      }
      history.goBack();
    } else {
      // Only delete document from store if there are documents loaded.
      // Otherwise further when we fetch all documents it won't be there.
      if (documents) {
        deleteDocument(document);
      }

      detachDocumentFromRooms(document);
      detachDocumentFromEvents(document);

      history.goBack();
    }
  };

  const toggleContextMenu = (open) => {
    setOpenContextMenu(open);
  };

  const fetchFullDocument = async () => {
    let error;
    let data;

    if (isPersonalDocument) {
      [error, data] = await apiFetchPersonalDocument(documentId);
    } else {
      [error, data] = await apiFetchDocument(documentId);
    }
    const response = data || error;
    return response;
  };

  const startTimer = (type) => {
    let count = 0;
    const timer = setInterval(() => {
      if (count === 3 || (count > 3 && count < 8 && !documentPath)) {
        fetchFullDocument().then(async (response) => {
          if (response && (response.pdfconversion || response?.pdfFilePath)) {
            clearInterval(timer);
            const path = await getDocumentPath(response);
            setIsConvertedFile(true);
            setDocumentPath(path);
          }
        });
      }

      if (count > 8) {
        if (!documentPath && supportedFileTypes.includes(type)) {
          setIsError(true);
        }

        clearInterval(timer);
      }
      count += 1;
    }, 5000);
  };

  const LoadingUI = () => {
    if (
      !documentPath &&
      supportedFileTypes.includes(fileType) &&
      location.state &&
      location.state.newUpload
    ) {
      return (
        <div
          style={{
            maxWidth: '500px',
            margin: '5% auto 0',
            height: '200px',
            padding: 20,
            textAlign: 'center',
            color: '#fff',
          }}
        >
          <CirclesLoader style={{ width: 50, height: 20, marginTop: '16px' }} />
          <p style={{ marginTop: '36px', marginBottom: '36px' }}>
            Dokumentet konverteres til PDF og vil snart vises her. Du kan
            navigere rundt i appen så lenge.
          </p>
        </div>
      );
    }
    return null;
  };

  const getFileTypeDoc = (file) =>
    new Promise((resolve) => {
      if (file && file.url) {
        resolve(file.url.fileType);
      }
    });

  useEffect(() => {
    if (document) {
      setDocumentPath(getDocumentPath(document));
      if (document.pdfconversion || document?.pdfFilePath) {
        setIsConvertedFile(true);
      }
      setfileType(getDocumentFileType(document));
    }

    if (document && location.state) {
      if (documentPath) {
        setDocumentPath(documentPath);
      }
    }
  }, [document, documentPath, fileType, location]);

  useEffect(() => {
    if (location.state && location.state.newUpload) {
      if (documentPath) {
        setDocumentPath(getDocumentPath(documentPath));
        if (document.pdfconversion || document?.pdfFilePath) {
          setIsConvertedFile(true);
        }
      } else {
        getFileTypeDoc(document).then((res) => {
          if (supportedFileTypes.includes(res)) {
            startTimer(res);
          }
        });
      }
    }
    if (document) {
      setTypeOfFile(getDocumentFileType(document));

      const staticDocumentType = documentTypes.find(
        ({ name }) => name === getDocumentType(document)
      );

      if (staticDocumentType) {
        setDocumentType({
          value: staticDocumentType.id,
          label: staticDocumentType.name,
        });
      } else {
        setDocumentType(null);
      }
      if (!isPersonalDocument) {
        setRoomIds([
          ...(rooms || [])
            .filter(
              (room) =>
                Array.isArray(queryRoomIds) &&
                queryRoomIds.includes(String(getRoomId(room)))
            )
            .map((room) => ({
              value: getRoomId(room),
              label: getRoomName(room),
            })),
          ...getDocumentRooms(document).map((room) => ({
            value: getRoomId(room),
            label: getRoomName(room),
          })),
        ]);

        setEventIds([
          ...(events || [])
            .filter(
              (event) =>
                Array.isArray(queryEventIds) &&
                queryEventIds.includes(String(getEventId(event)))
            )
            .map((event) => ({
              value: getEventId(event),
              label: getEventName(event),
            })),
          ...getDocumentEvents(document).map((event) => ({
            value: getEventId(event),
            label: getEventName(event),
          })),
        ]);
      }
    }
  }, [document]);

  useEffect(() => {
    if (typeOfFile) {
      resetFileName();
    }
  }, [typeOfFile]);

  useEffect(() => {
    setInputWidth(hiddenInputRef.current?.offsetWidth);
  }, [editedName]);

  if (fetchingError) {
    return <ErrorContainer errorResponse={fetchingError} />;
  }
  return (
    <>
      <div
        style={{ marginTop: snackbarHeight }}
        className={classes.previewHeader}
      >
        <div
          className={clsx(
            classes.previewHeaderNameBlock,
            !isDesktop && isEditingName && classes.previewHeaderMobileEditing
          )}
        >
          <div className={classes.editInputBlock}>
            <button
              className={classes.previewCloseButton}
              type="button"
              onClick={() => onDocumentClose()}
              aria-label="Close"
            >
              <CloseBold />
            </button>
            <div
              className={clsx(
                classes.hideBlock,
                isEditingName && classes.showBlock
              )}
            >
              <input
                className={clsx([
                  classes.editNameInput,
                  isDesktop && 'desktop',
                ])}
                style={{ width: inputWidth }}
                ref={editInputRef}
                onChange={(e) => setEditedName(e.target.value)}
                onKeyDown={(e) => handleInputKeyPress(e)}
                value={editedName}
              />
              <div
                ref={hiddenInputRef}
                className={clsx([classes.hiddenInput, isDesktop && 'desktop'])}
              >
                {editedName}
              </div>
              <p className={classes.fileTypeLabel}>.{typeOfFile}</p>
            </div>
          </div>
          <div
            className={clsx(
              classes.hideBlock,
              isEditingName && classes.editNameButtonsPadding,
              isEditingName && classes.showBlock
            )}
          >
            <button
              type="button"
              className={clsx(classes.editButtons, classes.closeEditButton)}
              onClick={() => onCloseEdit()}
              aria-label="Close Bold"
            >
              <CloseBold
                className={classes.closeEditIcon}
                fill="#000"
                width={10}
                height={10}
              />
            </button>
            <button
              type="button"
              className={clsx(classes.editButtons, classes.saveEditButton)}
              onClick={() => onNameSaved()}
              aria-label="Check"
            >
              <Check className={classes.saveNameIcon} />
            </button>
          </div>
          <div
            className={clsx(
              classes.hideBlock,
              isEditingName && !isDesktop && classes.displayNone,
              !isEditingName && classes.showBlock
            )}
          >
            <p
              className={clsx([classes.documentName, isDesktop && 'desktop'])}
            >{`${editedName}.${typeOfFile}`}</p>
            <button
              type="button"
              className={classes.editNameButton}
              onClick={() => onEditName()}
              aria-label="Edit"
            >
              <PencilOutlined />
            </button>
          </div>
        </div>
        {(isDesktop || !isEditingName) && (
          <PrimaryButton
            className={classes.previewEditButton}
            onClick={onMenuOpenClick}
          >
            Rediger
          </PrimaryButton>
        )}
        <DocumentActionMenu
          full={false}
          parentAnchorEl={anchorEl}
          document={document}
          setIsMobileContext={setIsMobileContext}
          setOpenParentPop={toggleContextMenu}
          onDocumentUpdated={onDocumentUpdated}
          onDocumentDeleted={onDocumentDeleted}
          openParentPop={openContextMenu}
          documentId={getDocumentId(document)}
          isPersonalDocuments={isPersonalDocument}
          isBoligmappaPlusUser={
            enableBoligmappaPlus ? authUser?.isSubscribeToBoligmappaPlus : true
          }
        />
      </div>
      <Page
        noPaddingTop
        minFullHeight
        hideHeader
        noPadding
        maxWidth={false}
        className={classes.previewPage}
        contentClass={classes.contentClass}
      >
        {!documentPath && !isError && LoadingUI()}
        {document?.documentFileAvailable && isError && (
          <ShowErrorMessage
            documentOriginalPath={getOriginalDocumentPath(document)}
          />
        )}
        {document && !document?.documentFileAvailable && (
          <ShowErrorMessage noFile />
        )}
        {documentPath && document?.documentFileAvailable && !isError && (
          <>
            <FileViewer
              document={documentId}
              documentData={document}
              fetching={fetching}
              filePath={documentPath}
              fileType={getDocumentFileType(document)}
              className={classes.filePreview}
              isConvertedFile={isConvertedFile}
            />
          </>
        )}
        {isDesktop ? (
          <div className={classes.previewDetails}>
            <Paper visible={!isDesktop}>
              <DocumentDetails
                fetching={fetching}
                document={document}
                showDownloadLink={
                  !isDocumentReadOnly(document) &&
                  document?.documentFileAvailable
                }
                showHelperText={!isDesktop}
                personalDocument={isPersonalDocument}
              />
            </Paper>
          </div>
        ) : (
          <BottomContextMenu
            open={isMobileContext}
            showAnchor={false}
            className={clsx([
              classes.mobileContext,
              isMobileContext && classes.mobileContextShown,
            ])}
            paperClass={!isMobileContext && classes.visibilityDrawerHidden}
            closeOnContentClick
            onClose={onCloseContext}
            onClick={() => !isMobileContext && setIsMobileContext(true)}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              style: {
                overflow: !isMobileContext && 'hidden',
              },
            }}
          >
            <div
              className={clsx(
                classes.contentWrapper,
                !isMobileContext && classes.contentHidden
              )}
              ref={swipeableRef}
            >
              <button
                type="button"
                className={classes.mobileContextButton}
                aria-label="Arrow Up"
              >
                <ArrowUp
                  className={clsx(
                    classes.mobileContextIcon,
                    isMobileContext && classes.mobileContextIconOpened
                  )}
                />
              </button>
              <DocumentDetails
                fetching={fetching}
                document={document}
                showDownloadLink={
                  !isDocumentReadOnly(document) &&
                  document?.documentFileAvailable
                }
                showHelperText={!isDesktop}
                personalDocument={isPersonalDocument}
              />
            </div>
          </BottomContextMenu>
        )}
      </Page>
    </>
  );
};

const mapStateToProps = (state) => ({
  documents: sGetDocuments(state),
  personalDocuments: sGetPersonalDocuments(state),
  rooms: sGetRooms(state),
  events: sGetEvents(state),
  authUser: sGetUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  deleteDocument: (document) => dispatch(acDeleteDocument(document)),
  deletePersonalDocument: (document) =>
    dispatch(acDeletePersonalDocument(document)),
  detachDocumentFromRooms: (document) =>
    dispatch(acDetachDocumentFromRooms(document)),
  detachDocumentFromEvents: (document) =>
    dispatch(acDetachDocumentFromEvents(document)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Show));
