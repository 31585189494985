import { getBYBFRoomId } from '../../utils/bybfRooms';

import { areIdsEqual } from '../../utils/strings';

export const defaultState = {
  rooms: [],
  activeStep: 1,
  flowCompleted: false,
  roomsCreated: false,
};

export const ADD_BYBF_ROOM = 'ADD_BYBF_ROOM';
export const SET_BYBF_ROOMS = 'SET_BYBF_ROOMS';
export const DELETE_BYBF_ROOM = 'DELETE_BYBF_ROOM';
export const DELETE_BYBF_ROOM_ORDER_AND_TYPE =
  'DELETE_BYBF_ROOM_ORDER_AND_TYPE';
export const CLEAR_BYBF_ROOMS = 'CLEAR_BYBF_ROOMS';
export const UPDATE_BYBF_ROOM = 'UPDATE_BYBF_ROOM';
export const SET_BYBF_ROOMS_ACTIVE_STEP = 'SET_BYBF_ROOMS_ACTIVE_STEP';
export const SET_BYBF_ROOMS_FLOW_COMPLETED = 'SET_BYBF_ROOMS_FLOW_COMPLETED';
export const SET_BYBF_ROOMS_CREATED = 'SET_BYBF_ROOMS_CREATED';

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_BYBF_ROOM:
      return {
        ...state,
        rooms: [...(state.rooms || []), action.payload],
      };
    case DELETE_BYBF_ROOM:
      return {
        ...state,
        rooms: state.rooms.filter(
          (room) => !areIdsEqual(getBYBFRoomId(room), action.payload.roomId)
        ),
      };
    case DELETE_BYBF_ROOM_ORDER_AND_TYPE:
      return {
        ...state,
        rooms: state.rooms.filter(
          (room) =>
            !(
              room.code === action.payload.code &&
              room.order === action.payload.order &&
              room.id === null
            )
        ),
      };
    case SET_BYBF_ROOMS:
      return {
        ...state,
        rooms: action.payload,
      };
    case CLEAR_BYBF_ROOMS:
      return defaultState;
    case UPDATE_BYBF_ROOM:
      const updatedRoom = action.payload;
      return {
        ...state,
        rooms: state.rooms.map((room) =>
          areIdsEqual(getBYBFRoomId(room), getBYBFRoomId(updatedRoom))
            ? updatedRoom
            : room
        ),
      };
    case SET_BYBF_ROOMS_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };
    case SET_BYBF_ROOMS_FLOW_COMPLETED:
      return {
        ...state,
        flowCompleted: action.payload,
      };
    case SET_BYBF_ROOMS_CREATED:
      return {
        ...state,
        roomsCreated: action.payload,
      };
    default:
      return state;
  }
};

export const sGetBYBFRoomsModule = (state) => state.bybfRooms;
export const sGetBYBFRooms = (state) => sGetBYBFRoomsModule(state).rooms;
export const sGetBYBFRoomsActiveStep = (state) =>
  sGetBYBFRoomsModule(state).activeStep;
export const sGetBYBFRoomsFlowCompleted = (state) =>
  sGetBYBFRoomsModule(state).flowCompleted;
export const sGetBYBFRoomsCreated = (state) =>
  sGetBYBFRoomsModule(state).roomsCreated;
