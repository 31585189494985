import { useSelector } from 'react-redux';
import { getPropertyOwners } from '../../utils/properties';

export function useActiveProperty() {
  const property = useSelector((state) => state.properties.activeProperty);
  const isFetchingProperties = useSelector(
    (state) => state.properties.fetching
  );

  const { unitFeatures } = useSelector((state) => state.unitFeatures);

  const propertyOwners =
    !isFetchingProperties && property && getPropertyOwners(property);

  return {
    property,
    isFetchingProperties,
    propertyOwners,
    unitFeatures,
  };
}
