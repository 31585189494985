import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Box from '../../atomic/Box';
import { getFilterTypes } from '../util';
import SelectedFilter from './SelectedFilter';
import { areFiltersEmpty } from '../../../utils/documents';
import Filters from '../../../assets/icons/Filters';

const SelectedFilters = ({ classes, filters, onFiltersChanged }) =>
  areFiltersEmpty(filters) ? null : (
    <Box className={classes.filters}>
      <Box className={classes.title}>
        <Filters />
      </Box>
      {getFilterTypes().map(({ type }) =>
        (filters[type] || []).map((filter) => (
          <Box
            p={0.5}
            key={`chosen-filter-${
              filter && filter.value ? filter.value : filter
            }`}
          >
            <SelectedFilter
              filter={filter}
              onCloseClick={() =>
                onFiltersChanged({
                  ...filters,
                  [type]: filters[type].filter((_filter) => _filter !== filter),
                })
              }
            />
          </Box>
        ))
      )}
    </Box>
  );

export default withStyles(styles)(SelectedFilters);
