import styled from 'styled-components';

export const StyledEditableTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 25px;
  position: relative;
  overflow: ${(props) => (props.$isEditing ? 'inherit' : 'hidden')};

  &:hover {
    .edit_button {
      display: none;

      &.show {
        display: flex;
      }
    }
  }

  .edit_name_wrapper {
    display: flex;
    align-items: center;
    column-gap: 5px;
    position: ${(props) =>
      props.$isEditing && props.$overlay ? 'absolute' : 'inherit'};
    width: ${(props) =>
      props.$isEditing && props.$overlay ? '390px' : 'unset'};
    background-color: ${(props) =>
      props.$isEditing && props.$overlay && props.$overlayBackground};
    padding: 3px;
    border-radius: 5px;
  }

  .doc_title {
    display: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;

    &.show {
      display: flex;
    }
  }

  .edit_name_input {
    width: 100%;
    height: 100%;
    padding-right: 85px;
    border: 1px solid #e6e6e6;
  }

  .edit_block {
    position: absolute;
    display: flex;
    margin-left: 10px;
    opacity: 0;
    width: 0;
    height: 0;
    z-index: -1;

    &.show {
      position: relative;
      width: 100%;
      height: 100%;
      opacity: 1;
      z-index: unset;
    }

    .edit_hint_block {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      column-gap: 3px;
      padding-right: 5px;
      height: 100%;

      p {
        margin: 0;
        font-size: 13px;
        line-height: 15px;
        font-style: italic;
      }
    }

    .hint_icon_block {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 10px;
      width: 10px;
      background-color: #ccc;
      border-radius: 50%;

      &.save {
        background-color: #d1ede8;
      }
      &.close {
        background-color: #f6d9d8;
      }
    }
  }

  .edit_button {
    display: none;
    border: unset;
    background-color: unset;
    cursor: pointer;
  }

  .edit_control_buttons {
    display: none;
    column-gap: 5px;

    &.show {
      display: flex;
    }
  }

  .edit_action {
    display: flex;
    align-items: center;
    justify-content: center;
    border: unset;
    width: 25px;
    height: 25px;
    padding: 9px;
    border-radius: 50%;
    cursor: pointer;

    &.save {
      background-color: #d1ede8;
    }
    &.close {
      background-color: #f6d9d8;
    }

    .save_edit_icon {
      display: flex;
      transform: scale(2.5);
      align-self: center;
    }

    .close_edit_icon {
      display: flex;
      align-items: center;
    }
  }
`;
