import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import ControlArrowRight from '../../../assets/icons/ControlArrowRight';

const ControlArrow = ({
  classes,
  previous,
  className,
  slideCount,
  currentSlide,
  ...props
}) => (
  <ControlArrowRight
    className={clsx(className, classes.arrow, previous && classes.previous)}
    {...props}
  />
);

export default withStyles(styles)(ControlArrow);
