import React from 'react';

const CheckCircleRounded = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 38 37"
    fill={fill}
    {...props}
  >
    <path
      d="M19 36.5C12.5312 36.5 6.625 33.125 3.39062 27.5C0.15625 21.9453 0.15625 15.125 3.39062 9.5C6.625 3.94531 12.5312 0.5 19 0.5C25.3984 0.5 31.3047 3.94531 34.5391 9.5C37.7734 15.125 37.7734 21.9453 34.5391 27.5C31.3047 33.125 25.3984 36.5 19 36.5ZM26.9453 15.1953H26.875C27.5781 14.5625 27.5781 13.5078 26.875 12.8047C26.2422 12.1719 25.1875 12.1719 24.5547 12.8047L16.75 20.6797L13.4453 17.375C12.7422 16.6719 11.6875 16.6719 11.0547 17.375C10.3516 18.0078 10.3516 19.0625 11.0547 19.6953L15.5547 24.1953C16.1875 24.8984 17.2422 24.8984 17.9453 24.1953L26.9453 15.1953Z"
      fill="#5DC9A2"
    />
  </svg>
);

export default CheckCircleRounded;
