import React from 'react';

const LockCircled = ({ width = '1.5rem', height = '1.5rem', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <style>
        {`
        .lockIconClassA {
          fill:#254e52;
          stroke:#fff;
          stroke-width:2px;
        }
        .lockIconClassB {
          fill:#ffede0;
          fill-rule:evenodd;
        }
        .lockIconClassC {
          stroke:none;
        }
        .lockIconClassD {
          fill:none;
        }`}
      </style>
    </defs>
    <g transform="translate(-270 -315)">
      <g className="lockIconClassA" transform="translate(272 317)">
        <circle className="lockIconClassC" cx="12" cy="12" r="12" />
        <circle className="lockIconClassD" cx="12" cy="12" r="13" />
      </g>
      <g transform="translate(-334.754 -1082.638)">
        <g transform="translate(613.5 1405)">
          <path
            className="lockIconClassB"
            transform="translate(-613.5 -1405)"
            d="M622.9,1410.255h-.415v-1.521a3.734,3.734,0,1,0-7.467,0v1.521h-.415a1.108,1.108,0,0,0-1.106,1.106v5.808a1.108,1.108,0,0,0,1.106,1.106h8.3a1.108,1.108,0,0,0,1.106-1.106v-5.808A1.108,1.108,0,0,0,622.9,1410.255Zm-5.255,3.319a1.106,1.106,0,1,1,1.659.951v1.261a.553.553,0,1,1-1.106,0v-1.261A1.1,1.1,0,0,1,617.649,1413.574Zm1.106-7.191a2.35,2.35,0,0,0-2.351,2.351v1.245a.275.275,0,0,0,.277.276h4.149a.275.275,0,0,0,.277-.276v-1.245A2.35,2.35,0,0,0,618.755,1406.383Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default LockCircled;
