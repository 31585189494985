import styled from 'styled-components';

export const StyledLoyaltyCompany = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  max-width: 1160px;
  padding: 8px 0;
  margin: 0 auto;
  background-color: ${(props) => props.$backgroundColor || '#fff'};
  position: relative;

  @media (max-width: 959px) {
    padding: 32px 16px 110px;
  }

  h2,
  h3,
  p {
    margin: 0;
    color: #000;
  }

  h2 {
    font-size: 32px;
    line-height: 50px;
    font-weight: 700;
  }

  h3 {
    font-size: 20px;
    line-height: 40px;
    font-weight: 700;
  }

  .section {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    p {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 24px;
    }
  }
  .main_description {
    max-width: ${(props) => (props.$isSub ? '100%' : 'calc(100% - 362px)')};

    @media (max-width: 959px) {
      max-width: 100%;
    }
  }

  .back_section {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    a {
      color: #000;
    }
  }

  .back_arrow_icon {
    font-size: 14px;
    width: 14px;
    height: 14px;
  }
  .img_container {
    padding: 0;
    width: 100%;
    max-width: ${(props) => (props.$isSub ? '100%' : '928px')};
    height: 425px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    @media (max-width: 767px) {
      width: 100%;
      position: unset;
      overflow: unset;
      height: unset;
      img {
        position: unset;
        object-fit: contain;
        width: inherit;
      }
    }
  }
  .infor_section {
    display: grid;
    grid-template-areas:
      'longdes longdes longdes longdes longdes offers'
      'subheading subheading subheading subheading subheading offers'
      '. . . . . offers';
    grid-gap: 24px 32px;
    @media (max-width: 959px) {
      grid-gap: 8px;
      grid-template-areas:
        'offers offers offers offers offers offers'
        'subheading subheading subheading subheading subheading subheading'
        'longdes longdes longdes longdes longdes longdes'
        'companyinfo companyinfo companyinfo companyinfo companyinfo companyinfo';
    }
  }
  .company_info {
    grid-area: companyinfo;
  }

  .sub_heading_section {
    grid-area: subheading;
    .description_img_container {
      width: 100%;
      margin-bottom: 24px;
      img {
        width: inherit;
      }
    }
    .option_img_container {
      width: 100%;
      margin-bottom: 24px;
      img {
        width: inherit;
      }
    }
    @media (max-width: 767px) {
      h3 {
        margin-bottom: 24px;
        line-height: unset;
      }
    }
  }
  .long_description_section {
    grid-area: longdes;
    .description_img_container {
      width: 100%;
      margin-bottom: 24px;
      img {
        width: inherit;
      }
    }
  }
  .offer_section {
    grid-area: offers;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
  }
  .offer_wrapper {
    background-color: #fff;
    border-radius: 4px;
    padding: 32px;
    h3 {
      margin-bottom: 24px;
    }
    .logo_wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 24px;
      .logo_img_container {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 225px;
        max-height: 80px;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    p {
      margin-bottom: 16px;
    }
    .benefit_btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      border-radius: 8px;
      background: #fbf3c1;
      padding: 12px;
    }
    .benefit_btn_text {
      color: #164144;
      font-size: 16px;
      font-weight: 800;
    }
    .info_paragraph {
      display: flex;
      align-items: center;
    }
    .info_icons {
      margin-right: 10px;
      font-size: 18px;
    }
    .info_link {
      color: #111;
    }
  }

  .information_box_cta_link {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-align: center;
    text-decoration: unset;
    color: #fff;
    padding: 12px 24px;
    width: 100%;
    border: none;
    border-radius: 50px;
    background-color: #164144;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 5px 1px #848484;
    }
  }

  .dialog_content {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 24px;
    width: 374px;
    max-width: 100%;

    h2 {
      font-size: 20px;
      line-height: 31px;
      color: #111;
      font-weight: 700;
      margin: 0;
    }

    p {
      font-size: 16px;
      line-height: 25px;
      color: #111;
      font-weight: 400;
      margin: 0;
    }

    .form_wrapper {
      p {
        color: #000;
        font-weight: 500;
      }

      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;
      }
    }
    .editable-field {
      color: #73748f;
      border: 1.5px solid #dde2f1;
      height: 25px;
      border-radius: 5px;
    }

    .comment_wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      label {
        font-size: 14px;
        color: #292823;
      }

      textarea {
        font-size: 14px;
        line-height: 24px;
        color: #73748f;
        border: 1.5px solid #dde2f1;
        border-radius: 4px;
        padding: 12px 16px;
        width: 100%;
      }

      p {
        font-size: 12px;
        line-height: 20px;
        color: #73748f;
        align-self: flex-end;
      }
    }

    .submit_comment {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: #fff;
      padding: 12px 24px;
      width: 100%;
      border: none;
      border-radius: 50px;
      background-color: #164144;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 5px 1px #848484;
      }
    }
  }

  .success_message {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;
    padding: 48px 24px;
    width: 374px;
    max-width: 100%;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 16px;
      text-align: center;

      h2 {
        font-size: 18px;
        line-height: 19px;
        font-weight: 700;
        margin: 0;
      }

      p {
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
        margin: 0;
      }
    }

    .close_success_message {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: #fff;
      padding: 12px 24px;
      width: calc(100% - 38px);
      border: none;
      border-radius: 50px;
      background-color: #164144;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 5px 1px #848484;
      }
    }
  }
`;
