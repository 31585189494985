/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Features from '../../../../../modules/Features';
import Box from '../../../../../components/atomic/Box';
import useDesktop from '../../../../../hooks/useDesktop';
import { useActiveProperty } from '../../../../../store/hooks';
import { useSectionStyles } from './sectionStyles';

export function DocumentationActionsSection() {
  const { property } = useActiveProperty();
  const isDesktop = useDesktop();
  const classes = useSectionStyles();
  return (
    <Box
      px={isDesktop ? 3 : 1}
      mt={4}
      width="100%"
      className={
        isDesktop ? classes.section_seperator : classes.section_seperator_mobile
      }
    >
      {property && <Features isPropertyOwned={property.owned} />}
    </Box>
  );
}
