import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, withStyles } from '@material-ui/core';
import Link from '../../atomic/Link';

import styles from './style';
import useDesktop from '../../../hooks/useDesktop';
import useHeaderSnackbar from '../../../hooks/useHeaderSnackbar';
import IconButton from '../../atomic/IconButton';
import Close from '../../../assets/icons/Close';
import HjemlaLogo from '../../../assets/icons/HjemlaLogo';
import { hjemlaBaseUrl, enableHjemla } from '../../../config';
import { useActiveProperty } from '../../../store/hooks';
import { sGetUser } from '../../../store/reducers/auth';
import { SNACK_BAR_CLOSED_SESSION_ID } from '../../../constants';
import localStorage from '../../../utils/localStorage';
import { PrimaryButton } from '../../Buttons';
import { closeSnackBarWithUpdate } from '../../../utils/hjemlaLoginSnackBarUtil';

const HjemlaLoginSnackBar = ({ user, classes }) => {
  const { property } = useActiveProperty();
  const isDesktop = useDesktop();
  const { showSnackbar, closeSnackbar } = useHeaderSnackbar();

  const closeButton = (
    <IconButton
      size="small"
      color="inherit"
      onClick={() =>
        closeSnackBarWithUpdate(
          false,
          'Boligmappa-to-Hjemla-Login-using-snackbar',
          'Loggin from boligmappa snackbar banner',
          user?.sid,
          closeSnackbar
        )
      }
      className={classes.closeIconBtn}
    >
      <Close className={classes.closeIcon} />
    </IconButton>
  );

  const getHjemlaUrl = () =>
    `${hjemlaBaseUrl}min-bolig/${property?.boligmappaNumber}?shouldLogin=true`;

  useEffect(() => {
    if (
      property?.boligmappaNumber &&
      enableHjemla === 'true' &&
      user?.sid &&
      user?.sid !== localStorage.get(SNACK_BAR_CLOSED_SESSION_ID)
    ) {
      closeSnackbar();
      showSnackbar({
        onClose: () => {},
        variant: 'hjemlaLoginSnackBarColor',
        content: (
          <>
            <div className={classes.snackbarheader}>
              <Grid container direction="rows" spacing={1}>
                <Grid item xs="11">
                  <Grid
                    container
                    direction="rows"
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                    margin="0"
                  >
                    <Grid item>
                      <p className={classes.hjemlaLoginSnackBarTitle}>
                        Sjekk ut vår nye tjeneste
                      </p>
                    </Grid>
                    <Grid item>
                      <Link
                        className={`${classes.buttonWrapper}`}
                        to={getHjemlaUrl()}
                        isExternal
                        openInNewTab
                      >
                        <PrimaryButton
                          onClick={() =>
                            closeSnackBarWithUpdate(
                              false,
                              'Boligmappa-to-Hjemla-Login-using-snackbar',
                              'Loggin from boligmappa snackbar banner',
                              user?.sid,
                              closeSnackbar
                            )
                          }
                          variant="contained"
                          noPadding={isDesktop}
                          className={classes.button}
                        >
                          <HjemlaLogo height="16px" width="16px" invert />
                          <span className={classes.buttonText}>hjemla.no</span>
                        </PrimaryButton>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={1} padding={0}>
                  {closeButton}
                </Grid>
              </Grid>
            </div>
          </>
        ),
      });
    } else {
      closeSnackbar();
    }
  }, [property, user]);

  return null;
};
const mapStateToProps = (state) => ({
  user: sGetUser(state),
});

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(HjemlaLoginSnackBar));
