import React from 'react';

import Button from '../../atomic/Button';
import AppsIcon from '../../../assets/icons/Apps';
import ImageIcon from '../../../assets/icons/Image';

export const SELECTION_MODE = 'SELECTION_MODE';
export const SINGLE_IMAGE_MODE = 'SINGLE_IMAGE_MODE';

export const buttons = {
  SINGLE_IMAGE_MODE: {
    icon: <AppsIcon />,
    text: 'Bildeoversikt',
  },
  SELECTION_MODE: {
    icon: <ImageIcon />,
    text: 'Store bilder',
  },
};

const SelectionListToggleButton = ({
  onModeChange,
  mode = SINGLE_IMAGE_MODE,
}) => {
  const onClick = () =>
    onModeChange(
      mode === SINGLE_IMAGE_MODE ? SELECTION_MODE : SINGLE_IMAGE_MODE
    );

  return (
    <Button
      color="inherit"
      onClick={onClick}
      underline={false}
      startIcon={buttons[mode].icon}
    >
      {buttons[mode].text}
    </Button>
  );
};

export default SelectionListToggleButton;
