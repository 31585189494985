import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import Typography from '../atomic/Typography';
import BallIcon from '../../assets/icons/Ball';
import Button from '../atomic/Button';
import Card from '../Card';
import NewPage from '../../assets/icons/NewPage';
import Box from '../atomic/Box';
import style from './style';
import {
  getUnitType,
  getProm,
  getRoomCount,
  getEstimatePrice,
  getPriceForOtherUnit,
  getAddress,
  formatDate,
  getAnnounceDate,
  getPriceForSoldUnit,
} from '../../utils/marketEvents';
import { formatNumber, capitalizeFirst } from '../../utils/strings';

const MarketEventCard = ({ classes, onClick, marketEvent, type }) => {
  const unitType = getUnitType(marketEvent);
  const prom = getProm(marketEvent);
  const roomCount = getRoomCount(marketEvent);
  const estimate = getEstimatePrice(marketEvent);
  const anouncePrice =
    type === 'sold'
      ? getPriceForSoldUnit(marketEvent)
      : getPriceForOtherUnit(marketEvent);
  const priceText = type === 'sold' ? 'Solgt' : 'Annonsert';

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card width="100%" height="100%" border={1}>
        <Grid container spacing={4}>
          <Grid item xs={7} md={8} lg={8} className={classes.addressArea}>
            <Typography className={classes.addressText}>
              {getAddress(marketEvent)}
            </Typography>
          </Grid>

          <Grid item className={classes.tagArea} xs={5} md={4} lg={4}>
            <Typography className={classes.adState}>
              <BallIcon
                className={
                  type === 'sold' ? classes.greenBallIcon : classes.ballIcon
                }
              />
              {type === 'sold' ? 'Nylig solgt' : 'Til salgs'}
            </Typography>
          </Grid>

          <Grid item padding={0} xs={12} className={classes.adjustPadding}>
            <Typography className={classes.textArea}>
              {unitType && <span>{capitalizeFirst(unitType)} </span>}
              {unitType && (prom || roomCount) && <span>| </span>}
              {prom && (
                <span>
                  {prom} m<sup>2</sup>{' '}
                </span>
              )}
              {prom && roomCount && <span>| </span>}
              {roomCount && <span>{roomCount} sov</span>}
            </Typography>

            <Box marginTop={2}>
              <Typography className={classes.valueDataArea}>
                <span>
                  {' '}
                  {priceText} {formatDate(getAnnounceDate(type, marketEvent))}{' '}
                  for:{' '}
                </span>
                {anouncePrice != null && anouncePrice > 0 && (
                  <span> {formatNumber(anouncePrice)} kr</span>
                )}
                {!(anouncePrice > 0) && <span> Venter på pris</span>}
              </Typography>
              <Typography className={classes.valueDataArea}>
                <span> Verdiestimat: </span>
                {estimate > 0 && (
                  <>
                    <span> {formatNumber(estimate)} kr</span>
                  </>
                )}
                {estimate == null && <span> ikke tilgjengelig </span>}
              </Typography>
            </Box>
          </Grid>

          <Grid item className={classes.buttonContainer} xs={12}>
            <Button
              className={classes.button}
              onClick={onClick}
              underline={false}
            >
              <span>Se mer hos hjemla.no </span>
              <NewPage className={classes.buttonIcon} />
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default withStyles(style)(MarketEventCard);
