const styles = (theme) => ({
  title: {
    margin: '10px 0 15px',
  },
  form: {
    marginTop: '30px',
  },
  error: {
    color: theme.palette.primary.redDark,
    margin: '25px 0 0 0',
  },
  bottomMargin: '25px',
  termsWrap: { margin: '15px 0 10px 0' },
  helperText: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  helperUrl: { margin: '0 0 0 35px ' },
  titleLoader: {
    margin: '10px 0 55px',
  },
  listItemLoader: {
    margin: '35px 0',
    '&:last-child': {
      margin: '35px 0 30px',
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  listLink: {
    marginBottom: '8px',
    '&:last-child': {
      marginBottom: '0',
    },
  },
  EmailButtonMobile: {
    marginTop: '15px',
  },
  ActionButtonsShare: {},
  readingShareForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  ActionButtonsShareMobile: {
    marginRight: '63px',
  },
  pGray: { color: theme.colors.greyDark },
  proceedButton: { marginTop: '30px', textAlign: 'right' },
  proceedButtonDesktop: { marginTop: '30px', textAlign: 'left' },
  headerContainer: { display: 'flex', alignItems: 'flex-start' },
  detailsH2: { flex: 3, marginTop: 0 },
  publicShareH2: {
    top: '94px',
    left: '292px',
    width: '278px',
    height: '42px',
    textAlign: 'left',
    font: 'normal normal bold 33px/51px Sofia Pro',
    letterSpacing: '0px',
    color: '#254E52',
    opacity: '1',
  },
  publicShareContent: {
    top: '166px',
    left: '292px',
  },
  publicsharedatepicker: {
    top: '592px',
    left: '292px',
    height: '42px',
    font: 'normal normal bold 20px/24px Sofia Pro',
    borderRadius: '3px',
    opacity: '1',
    color: '#333333',
    textAlign: 'left',
  },
  publicsharedatepickerMobile: {
    top: '592px',
    left: '292px',
    height: '42px',
    font: 'normal normal bold 20px/24px Sofia Pro',
    borderRadius: '3px',
    opacity: '1',
    color: '#333333',
    textAlign: 'left',
    marginLeft: '22px',
  },
  publicShareContent1: {
    top: '507px',
    left: '292px',
  },
  publicShareContent1Mobile: {
    top: '507px',
    left: '292px',
  },
  datePickerContainerarea: {
    marginLeft: '3px',
  },
  datePickerContainerareaMobile: {
    width: '100%',
    marginLeft: '6px',
  },
  checkcontent: {
    textAlign: 'left',
    font: 'normal normal normal 16px/20px Sofia Pro',
    letterSpacing: '0px',
    color: '#000000D9',
    opacity: '1',
    marginTop: '25px',
  },
  checkcontentMobile: {
    top: '683px',
    textAlign: 'left',
    font: 'normal normal normal 16px/20px Sofia Pro',
    letterSpacing: '0px',
    color: '#000000D9',
    opacity: '1',
    marginTop: '40px',
  },
  publicshareimage: {
    top: '94px',
    opacity: '1',
  },
  statuscontent: {
    top: '507px',
    left: '750px',
    textAlign: 'left',
    font: 'normal normal bold 18px/28px Sofia Pro',
    letterSpacing: '0px',
    color: '#254E52',
    opacity: '1',
    marginTop: '27px',
  },
  publicshareimagestatussmall: {
    top: '94px',
    opacity: '1',
    marginTop: '13px',
    width: '83%',
  },
  statuscontentMobile: {
    font: 'normal normal bold 18px/28px Sofia Pro',
    letterSpacing: '0px',
    color: '#254E52',
    opacity: '1',
  },
  statuscontents: {
    marginTop: '26px',
  },
  statuscontentsMobile: {
    marginTop: '27px',
  },
  statuscontent2: {
    left: '292px',
    width: '302px',
    height: '20px',
    textAlign: 'left',
    font: 'normal normal bold 16px/23px Sofia Pro',
    letterSpacing: '0px',
    color: '#254E52',
    opacity: '1',
    marginTop: '49px',
  },
  statuscontent2Mobile: {
    textAlign: 'left',
    font: 'normal normal bold 16px/23px Sofia Pro',
    letterSpacing: '0px',
    color: '#254E52',
    opacity: '1',
    marginTop: '49px',
  },
  statuscontent4: {
    left: '292px',
    height: '20px',
    textAlign: 'left',
    font: 'normal normal bold 16px/23px Sofia Pro',
    letterSpacing: '0px',
    color: '#254E52',
    opacity: '1',
  },
  statuscontent4Mobile: {
    textAlign: 'left',
    font: 'normal normal bold 16px/23px Sofia Pro',
    letterSpacing: '0px',
    color: '#254E52',
    opacity: '1',
  },
  publicShareProcessButton: {
    top: '81px',
    left: '2px',
    width: '212px',
    height: '40px',
    background: '#254E52 0% 0% no-repeat padding-box',
    borderRadius: '25px',
    opacity: '1',
  },
  publicShareProcessButtonMobile: {
    width: '212px',
    height: '40px',
    background: '#254E52 0% 0% no-repeat padding-box',
    borderRadius: '25px',
    opacity: '1',
    marginTop: '30px',
  },
  publicShareProcessButtonsmall: {
    top: '41px',
    left: '2px',
    background: '#254E52 0% 0% no-repeat padding-box',
    borderRadius: '25px',
    opacity: '1',
  },
  proceedButtonpublicshare: {
    color: '#254E52',
    border: '1px solid',
    height: '50px',
    padding: '10px 25px',
    background: '#fff',
    boxShadow: '0 2px 5px 1px rgb(0 0 0 / 20%)',
    borderColor: '#254E52',
    borderRadius: '50px',
  },
  proceedButtonpublicshareMobile: {
    color: '#254E52',
    border: '1px solid',
    height: '50px',
    padding: '10px 25px',
    background: '#fff',
    boxShadow: '0 2px 5px 1px rgb(0 0 0 / 20%)',
    borderColor: '#254E52',
    borderRadius: '50px',
    marginTop: '40px',
  },
  publicsharecontainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  publicshareimagestatus: {
    top: '94px',
    opacity: '1',
    marginTop: '13px',
    width: '100%',
  },
  publicshareimagestatusMobile: {
    width: '250px',
    height: '186px',
    opacity: '1',
    marginTop: '47px',
  },
  publicsharelist: {
    top: '299px',
    textAlign: 'left',
    font: 'Sofia Pro, sans-serif',
    letterSpacing: '0px',
    color: '#333333',
    opacity: '1',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '2.43',
  },
  publicshareListMobile: {
    top: '299px',
    font: 'Sofia Pro, sans-serif',
    color: '#333333',
    opacity: '1',
    textAlign: 'left',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '2.43',
  },
  statuscontent3: {
    top: '425px',
    left: '292px',
    width: '440px',
    height: '17px',
    textAlign: 'left',
    font: 'normal normal normal 13px/20px Sofia Pro',
    letterSpacing: '0px',
    color: '#000000D9',
    opacity: '1',
  },
  statuscontent3Mobile: {
    top: '425px',
    textAlign: 'left',
    font: 'normal normal normal 13px/20px Sofia Pro',
    letterSpacing: '0px',
    color: '#000000D9',
    opacity: '1',
    marginTop: '32px',
    fontSize: '11pt',
  },
  publicShareH2Mobile: {
    width: 'max-content',
  },
  publicshareimageMobile: {
    width: '228px',
    height: '186px',
    opacity: '1',
  },
  publicShareContentMobile: {
    marginTop: '37px',
    left: '289pt',
  },
  publicShareContentMobiletext: {
    marginTop: '37px',
    left: '289pt',
    width: '335px',
    height: '167px',
    marginLeft: '2px',
  },
  infoIconContainer: { flex: 1, textAlign: 'right', paddingTop: '5px' },
  infoIconContainerpublicshare: {
    flex: '0 0 auto',
    backgroundColor: '#DEDFDF',
    padding: '9px 35px 35px 11px',
    overflow: 'visible',
    fontSize: '1.7142857142857142 rem',
    textAlign: 'center',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '50%',
    color: theme.palette.primary.blackLight,
  },
  infoIconContainerpublicshareMobile: {
    flex: '0 0 auto',
    backgroundColor: '#DEDFDF',
    padding: '0px 0px 0px 0px',
    overflow: 'visible',
    fontSize: '1.7142857142857142 rem',
    textAlign: 'center',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '50%',
    color: theme.palette.primary.blackLight,
  },
  headerContainerDesktop: { marginBottom: '10px' },
  detailsH1: {
    float: 'left',
    marginTop: 0,
    fontSize: '32px',
    marginRight: '10px',
  },
  activeStatus: {
    backgroundColor: '#d0ece7',
    padding: '3px 9px',
    fontSize: '12px',
    color: '#13A085',
    fontWeight: 'bold',
  },
  menuIcon: {
    color: theme.palette.primary.blackLight,
  },
  list: {
    padding: 0,
  },
  h2: {
    margin: 0,
  },
  copyContainer: {},
  copyContainerDesktop: { display: 'flex', alignItems: 'flex-start' },
  copyboxContainer: {},
  copyboxContainerDesktop: { flex: '1', margin: '20px 25px 20px auto' },
  emailButtonContainer: {
    textAlign: 'center',
    marginTop: '10px',
    marginBottom: '20px',
  },
  emailButtonContainerDesktop: {
    marginTop: '10px',
    marginBottom: '20px',
    flex: '1',
    textAlign: 'left',
  },
  buttonIcon: {
    width: '250px',
    height: '100%',
    maxWidth: '1.5rem',
    maxHeight: '1.5rem',
    marginRight: '10px',
  },
  close_dialog_heading: {
    fontSize: '21px',
    marginBottom: '12px',
    color: '#254E52',
  },
  close_dialog_text: {
    margin: 0,
    textAlign: 'center',
    fontSize: '14px',
    padding: '7px !important',
  },
  confirmCloseActions: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 60px !important',
  },
  confirmCloseButton: {
    width: '200px',
  },
  dialogsize: {
    width: '270px',
  },
});

export default styles;
