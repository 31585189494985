import React from 'react';
import { withStyles } from '@material-ui/core';
import ProgressButton from '../../../../../components/ProgressButton';

import DownloadWhiteIcon from '../../../../../assets/icons/DocumentBulkEditIcons/DownLoadWhiteIcon';
import DownloadGrayIcon from '../../../../../assets/icons/DocumentBulkEditIcons/DownloadGrayIcon';

import styles from './style';

const ContractTemplate = ({
  classes,
  title,
  logo,
  description,
  buttonText,
  contractUrl,
  isBoligmappaPlusSubscribed = false,
}) => {
  const onClick = (fileUrl) => () => {
    if (fileUrl) window.open(fileUrl, '_blank');
  };

  return (
    <div className={classes.content}>
      <div className={classes.header}>
        <h3 className={classes.title}>{title}</h3>
        {logo && <img src={logo} alt="Logo" className={classes.logo} />}
      </div>

      <div className={classes.description}>
        <p>{description}</p>
      </div>

      <div className={classes.buttonRow}>
        <ProgressButton
          size="small"
          color="primary"
          variant="contained"
          disabled={!isBoligmappaPlusSubscribed}
          className={classes.button}
          onClick={onClick(contractUrl)}
        >
          {isBoligmappaPlusSubscribed ? (
            <DownloadWhiteIcon className={classes.buttonIcon} />
          ) : (
            <DownloadGrayIcon className={classes.buttonIcon} />
          )}
          &nbsp; {buttonText}
        </ProgressButton>
      </div>
    </div>
  );
};

export default withStyles(styles)(ContractTemplate);
