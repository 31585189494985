import react from 'react';

export type InputTransformType =
  | 'currency'
  | ((e: react.ChangeEvent<HTMLInputElement>) => void);

export const transformCurrencyInput = (
  e: react.ChangeEvent<HTMLInputElement>,
) => {
  if (e.target.value) {
    /**
     * We limit the input to 15 digits, to not run into the Max integer allowed in JS
     * Once we're at this limit things are a bit harder to handle, so we have decided to
     * just substring the input value, this will look weird if the user goes back to earlier
     * in the string and starts typing, but it's an acceptable edge case
     */
    const textValue = e.target.value.replace(/\D/g, '').substring(0, 15);
    const newValue = parseInt(textValue, 10).toLocaleString('no');
    e.target.value = newValue;
  }
};

export const getInputTransformer = (inputTransform: InputTransformType) => {
  if (typeof inputTransform === 'function') {
    return inputTransform;
  }
  switch (inputTransform) {
    case 'currency':
      return transformCurrencyInput;
    default:
      return undefined;
  }
};
