import React from 'react';
import { withStyles } from '@material-ui/core';

import {
  getLoanType,
  getLoanBank,
  getLoanAmount,
} from '../../utils/financials';

import styles from './style';
import Box from '../../components/atomic/Box';
import List from '../../components/atomic/List';
import SeeMoreLink from '../../components/SeeMoreLink';
import Typography from '../../components/atomic/Typography';
import ListItem from '../../components/CardList/KeyValueListItem';
import ContentLoader from '../../components/loaders/ContentLoader';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';

const PropertyLoans = ({
  loan,
  title,
  classes,
  fetching,
  subtitle,
  loanCount,
}) => {
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const link = `${dashboardLinkPrefix}/economy/loans`;
  const showLink = loanCount > 1;

  if (fetching) {
    return (
      <List>
        <ContentLoader
          height={20}
          width={100}
          className={classes.titleLoader}
        />
        <ContentLoader
          width={90}
          height={20}
          className={classes.listItemLoading}
        />
        <ContentLoader
          width={85}
          height={20}
          className={classes.listItemLoading}
        />
        <ContentLoader
          width={50}
          height={20}
          className={classes.listItemLoading}
        />
      </List>
    );
  }

  return (
    <>
      <List>
        {title && (
          <Typography variant="h2" className={classes.title}>
            {title}
            {loanCount > 1 && ` (1/${loanCount})`}
          </Typography>
        )}
        {subtitle && (
          <Typography variant="small" component="small" color="textSecondary">
            {subtitle}
          </Typography>
        )}
        {getLoanBank(loan) && (
          <ListItem label="Panthaver">{getLoanBank(loan)}</ListItem>
        )}
        <ListItem label="Type pant">{getLoanType(loan)}</ListItem>
        <ListItem label="Beløp">{`${getLoanAmount(loan)} NOK`}</ListItem>
      </List>
      {showLink && (
        <Box py={2} textAlign="right">
          <SeeMoreLink to={link} />
        </Box>
      )}
    </>
  );
};

export default withStyles(styles)(PropertyLoans);
