import { publicApiBaseUrl } from '../config';
import { request } from '../utils/http';

export const lookupLocations = (params) =>
  request({
    params,
    method: 'get',
    url: 'locations/search',
    baseURL: publicApiBaseUrl,
  })
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);
