export const defaultState = {
  fetching: true,
  rights: null,
  fetchingError: null,
};

export const SET_RIGHTS = 'SET_RIGHTS';
export const CLEAR_RIGHTS = 'CLEAR_RIGHTS';
export const SET_FETCHING_RIGHTS = 'SET_FETCHING_RIGHTS';
export const SET_FETCHING_RIGHTS_ERROR = 'SET_FETCHING_RIGHTS_ERROR';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_RIGHTS:
      return {
        ...state,
        rights: action.payload,
      };
    case CLEAR_RIGHTS:
      return {
        ...state,
        rights: null,
      };
    case SET_FETCHING_RIGHTS:
      return {
        ...state,
        fetching: action.payload,
      };
    case SET_FETCHING_RIGHTS_ERROR:
      return {
        ...state,
        fetchingError: action.payload,
      };
    default:
      return state;
  }
};

export const sGetRightsModule = (state) => state.rights;
export const sGetRights = (state) => sGetRightsModule(state).rights;
export const sGetFetchingRights = (state) => sGetRightsModule(state).fetching;
export const sGetFetchingRightsError = (state) =>
  sGetRightsModule(state).fetchingError;
