import React from 'react';

import {
  TERMS_OF_USE_LINK,
  PRIVACY_POLICY_LINK,
} from '../../../../../constants';

import Link from '../../../../../components/atomic/Link';
import Button from '../../../../../components/atomic/Button';
import Typography from '../../../../../components/atomic/Typography';
import ArrowRightAlt from '../../../../../assets/icons/ArrowRightAlt';
import PageLayout from '../../../../../components/layouts/WithHeader';
import useDesktop from '../../../../../hooks/useDesktop';

const Terms = () => (
  <PageLayout
    minFullHeight
    headerTitle="Brukervilkår og personvern"
    lessMarginLeft={useDesktop()}
    maxWidth="lg"
  >
    <h3>Brukervilkår</h3>
    <Typography component="p" variant="body2" color="textPrimary">
      Disse brukervilkår («Vilkårene») gjelder bruk av Boligmappa
    </Typography>
    <br />
    <Link openInNewTab isExternal to={TERMS_OF_USE_LINK}>
      <Button size="small" color="primary" variant="contained">
        Les våre vilkår for bruk
        <ArrowRightAlt />
      </Button>
    </Link>
    <br />
    <br />
    <br />

    <h3>Personvern</h3>
    <Typography component="p" variant="body2" color="textPrimary">
      Personvernerklæringen forteller deg hvilken data vi samler inn, hvorfor vi
      samler den inn og hva vi gjør med den.
    </Typography>
    <br />
    <Link openInNewTab isExternal to={PRIVACY_POLICY_LINK}>
      <Button size="small" color="primary" variant="contained">
        Les personvernerklærnigen vår
        <ArrowRightAlt />
      </Button>
    </Link>
  </PageLayout>
);

export default Terms;
