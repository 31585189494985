import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Box from '../../components/atomic/Box';
import List from '../../components/atomic/List';
import { formattedDate } from '../../utils/dates';
import SeeMoreLink from '../../components/SeeMoreLink';
import Typography from '../../components/atomic/Typography';
import ListItem from '../../components/CardList/KeyValueListItem';
import ContentLoader from '../../components/loaders/ContentLoader';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';
import { getSaleType, getSaleDate, getSaleBuyer } from '../../utils/financials';
import matomo from '../../utils/matomo';

const PropertySales = ({
  sale,
  classes,
  fetching,
  saleCount,
  title = 'Omsetningshistorikk',
}) => {
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const link = `${dashboardLinkPrefix}/economy/sales`;
  const showLink = saleCount > 1;

  const dateFormat = 'DD.MM.YYYY';

  const onClickSeeMore = () => {
    matomo.clickEvent({
      category: 'Value Estimate and Finances',
      name: 'See More Turnover History',
      action: 'See Owners and Finances',
    });
  };

  if (fetching) {
    return (
      <List>
        <ContentLoader width={180} height={20} />
        <br />
        <br />
        <ContentLoader
          width={50}
          height={20}
          className={classes.listItemLoading}
        />
        <ContentLoader
          width={80}
          height={20}
          className={classes.listItemLoading}
        />
        <ContentLoader
          width={60}
          height={20}
          className={classes.listItemLoading}
        />
      </List>
    );
  }

  return (
    <>
      <List>
        <Typography variant="h2" className={classes.title}>
          {title}
          {saleCount > 1 && ` (1/${saleCount})`}
        </Typography>
        <ListItem label="Dato">
          {formattedDate(getSaleDate(sale), dateFormat)}
        </ListItem>
        <ListItem label="Omsetning">{getSaleType(sale)}</ListItem>
        <ListItem label="Solgt til">{getSaleBuyer(sale)}</ListItem>
      </List>

      {showLink && (
        <Box py={2} textAlign="right">
          <SeeMoreLink to={link} onClick={onClickSeeMore} />
        </Box>
      )}
    </>
  );
};

export default withStyles(styles)(PropertySales);
