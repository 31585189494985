import React from 'react';

import Page from '../WithHeader';

const PublicPage = ({
  hidePublicHeader,
  headerAdditionalContent,
  ...props
}) => <Page {...props} />;

export default PublicPage;
