import React from 'react';

const Home = ({ width = '16', height = '16', fill = 'none', ...props }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 15"
    fill={fill}
    {...props}
  >
    <path
      d="M8.41016 0.914062L15.6289 7.03906C15.9023 7.28516 15.9297 7.69531 15.7109 7.96875C15.4648 8.24219 15.0547 8.26953 14.7812 8.05078L14.125 7.47656V12.5625C14.125 13.793 13.1406 14.75 11.9375 14.75H4.0625C2.83203 14.75 1.875 13.793 1.875 12.5625V7.47656L1.19141 8.05078C0.917969 8.26953 0.507812 8.24219 0.261719 7.96875C0.0429688 7.69531 0.0703125 7.28516 0.34375 7.03906L7.5625 0.914062C7.80859 0.722656 8.16406 0.722656 8.41016 0.914062ZM3.1875 12.5625C3.1875 13.0547 3.57031 13.4375 4.0625 13.4375H5.375V9.28125C5.375 8.67969 5.83984 8.1875 6.46875 8.1875H9.53125C10.1328 8.1875 10.625 8.67969 10.625 9.28125V13.4375H11.9375C12.4023 13.4375 12.8125 13.0547 12.8125 12.5625V6.35547L8 2.28125L3.1875 6.35547V12.5625ZM6.6875 13.4375H9.3125V9.5H6.6875V13.4375Z"
      fill="#717171"
    />
  </svg>
);

export default Home;
