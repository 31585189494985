const styles = () => ({
  buttonWrapper: {
    textDecoration: 'none',
  },
  buttonText: {
    fontSize: '14px',
    fontWeight: '500',
    marginLeft: '9.33px',
  },
  closeIconBtn: {
    float: 'right',
    marginTop: '11px',
  },
  button: {
    color: '#FFFFFF',
    background: '#13142A',
    minWidth: '100px',
    padding: '10px 24px',
    margin: '7px auto',
    height: '40px',
    lineHeight: '14px',
    '@media only screen and (max-width: 375px)': {
      padding: '5px 12px',
    },
    '@media only screen and (max-width: 340px)': {
      padding: '3px 12px',
    },
  },
  closeIcon: {
    width: '19px',
  },
  hjemlaLoginSnackBarTitle: {
    '@media only screen and (min-width: 1024px) and (max-width:1440px)': {
      fontSize: '14px',
    },
    fontSize: '14px',
    fontFamily: 'Sofia Pro, sans-serif',
    fontWeight: '500',
    lineHeight: '24px',
    margin: '0',
    textDecoration: 'none',

    '@media only screen and (max-width: 375px)': {
      fontSize: '13px',
    },
    '@media only screen and (max-width: 340px)': {
      fontSize: '12px',
    },
  },
});

export default styles;
