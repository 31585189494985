import React from 'react';
import { StyledBreadCrumbsFullPath } from '@componentsV2/BreadCrumbsFullPath/styles';

const BreadCrumbsFullPath = ({
  breadCrumbs,
  breadCrumbsNames,
  setBreadCrumbs,
}) => {
  const onBreadCrumb = (index) => {
    setBreadCrumbs(breadCrumbs.slice(0, index + 1));
  };

  const onRootFolder = () => {
    setBreadCrumbs([]);
  };

  // new changes
  return (
    <StyledBreadCrumbsFullPath>
      <div className="breadCrumb_container">
        <>
          <div className="column">
            <button type="button" onClick={onRootFolder}>
              <p
                style={{
                  color: breadCrumbsNames.length > 0 ? '#717171' : '#111111',
                }}
              >
                Alle dokumenter
              </p>
            </button>
          </div>
          {breadCrumbsNames.length > 0 && (
            <div
              style={{
                color: breadCrumbsNames.length > 0 ? '#717171' : '#111111',
              }}
            >
              /
            </div>
          )}
        </>
        {breadCrumbsNames.map((el, index) => (
          <>
            <div className="column">
              <button type="button" onClick={() => onBreadCrumb(index)}>
                <p
                  style={{
                    color:
                      index < breadCrumbsNames.length - 1
                        ? '#717171'
                        : '#111111',
                  }}
                >
                  {el}
                </p>
              </button>
            </div>
            {index + 1 !== breadCrumbsNames.length && (
              <div
                style={{
                  color:
                    index + 2 !== breadCrumbsNames.length
                      ? '#717171'
                      : '#111111',
                }}
              >
                /
              </div>
            )}
          </>
        ))}
      </div>
    </StyledBreadCrumbsFullPath>
  );
};

export default BreadCrumbsFullPath;
