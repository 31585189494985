/* eslint-disable react/no-children-prop */
import { connect } from 'react-redux';
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { useMediaQuery } from '@material-ui/core';
import { FeatureCard, FeatureCardMobile } from '../../components/FeatureCard';
import Box from '../../components/atomic/Box';
import Hidden from '../../components/atomic/Hidden';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';

import sessionStorage from '../../utils/sessionStorage';
import { features as allFeatures } from '../../structure/features';
import { sGetActiveProperty } from '../../store/reducers/properties';
import { actionResetMDR } from '../../store/actions/mdr';

import { redirectOptions, redirectPath } from '../../utils/routes';
import Carousel from '../../components/Carousel';
import CarouselControl from './CarouselControl';
import { FileUploadContext } from '../../contexts/fileUpload';
import useDesktop from '../../hooks/useDesktop';

const Features = ({
  isPropertyOwned = false,
  singleFeature = null,
  resetMDR,
}) => {
  const history = useHistory();
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const [features, setFeatures] = useState(singleFeature || allFeatures);
  const { onFileUploadClick } = useContext(FileUploadContext);
  const isTabMode = useMediaQuery(`(max-width: 1200px)`);
  const isDesktop = useDesktop();

  const onShowMissingDocumentsClick = () => {
    resetMDR();
    history.push(`${dashboardLinkPrefix}/create-mdr`);
  };

  const onClickAddDocument = () => {
    if (isDesktop) {
      onFileUploadClick({
        query: { bulkUploadMode: true, source: 'table', newUpload: true },
      });
    } else {
      onFileUploadClick({
        query: { bulkUploadMode: true, source: 'listView', newUpload: true },
      });
    }
  };

  const getOnClickEventForFeature = (feature) => {
    switch (feature.code) {
      case 'MISSING_DOCUMENTS':
        return onShowMissingDocumentsClick;
      case 'UPLOAD_DOCUMENTS':
        return onClickAddDocument;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!isPropertyOwned && !singleFeature) {
      const filteredFeatures = allFeatures.filter(
        (feature) => !feature.privatePropertyOnly
      );
      setFeatures(filteredFeatures);
    }
    const redirectPathValue = sessionStorage.get(redirectPath);
    if (
      redirectPathValue &&
      redirectPathValue === redirectOptions.requestMissingDocuments
    ) {
      sessionStorage.remove(redirectPath);
      onShowMissingDocumentsClick();
    }
  }, []);

  return (
    <>
      {/* Desktop */}
      <Hidden smDown>
        {!isTabMode && (
          <Carousel
            arrows
            slidesToShow={singleFeature ? 1 : 3}
            nextArrow={<CarouselControl />}
            prevArrow={<CarouselControl previous />}
          >
            {features.map((feature) => (
              <FeatureCard
                feature={feature}
                onClick={getOnClickEventForFeature(feature)}
                deskTopheight="300px"
                key={`${feature.id}`}
              />
            ))}
          </Carousel>
        )}
        {isTabMode && (
          <Carousel
            arrows
            slidesToShow={singleFeature ? 1 : 3}
            nextArrow={<CarouselControl />}
            prevArrow={<CarouselControl previous />}
          >
            {features.map((feature) => (
              <FeatureCardMobile
                feature={feature}
                key={`feature-item-${feature.title}`}
                onClick={getOnClickEventForFeature(feature)}
              />
            ))}
          </Carousel>
        )}
      </Hidden>

      {/* Mobile */}
      <Hidden mdUp>
        <Box display="flex" alignItems="stretch" overflow="auto" pb={2}>
          {features.map((feature) => (
            <FeatureCardMobile
              onClick={getOnClickEventForFeature(feature)}
              feature={feature}
              key={`feature-item-${feature.title}`}
            />
          ))}
        </Box>
      </Hidden>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeProperty: sGetActiveProperty(state),
});

const mapDispatchToProps = (dispatch) => ({
  resetMDR: () => dispatch(actionResetMDR()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Features);
