import React from 'react';
import { withStyles } from '@material-ui/core';
import useDesktop from '@hooksV2/useDesktop';
import styles from './style';
import PersonalAreaLanding from '../../../../componentsV2/PersonalAreaLanding';

const PersonalArea = ({ classes }) => {
  const isDesktop = useDesktop();

  return (
    <div className={classes.page}>
      <PersonalAreaLanding isDesktop={isDesktop} />
    </div>
  );
};

export default withStyles(styles)(PersonalArea);
