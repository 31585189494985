const styles = {
  estimateDot: {
    height: 12,
    width: 12,
    backgroundColor: '#451F96',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: 10,
  },
  averageDot: {
    height: 12,
    width: 12,
    border: '1px solid black',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: 10,
  },
  statChartContent: {
    fontSize: 13,
  },
  chartArea: {
    height: 375,
  },
};

export default styles;
