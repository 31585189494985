import clsx from 'clsx';
import React from 'react';
import { withStyles } from '@material-ui/core';

import Style from './style';
import ProgressButton from '../ProgressButton';
import RoundPlus from '../../assets/icons/RoundPlus';
import UploadImageButton from '../forms/ImageForm/UploadImageButton';

const DirectImageUpload = ({
  classes,
  onClick,
  imageRef,
  className,
  setImageRef,
  onImagesUploaded,
  lastImagePosition,
  uploadImageRequest,
  ...props
}) => (
  <ProgressButton
    size="small"
    onClick={onClick}
    className={clsx(classes.root, className)}
    {...props}
  >
    <RoundPlus fill="#707070" width="15px" height="15px" />

    <span className={classes.text}>Legg til bilde</span>

    <UploadImageButton
      showButton={false}
      imageRef={imageRef}
      setImageRef={setImageRef}
      onImagesUploaded={onImagesUploaded}
      lastImagePosition={lastImagePosition}
      uploadImageRequest={uploadImageRequest}
    />
  </ProgressButton>
);

export default withStyles(Style)(DirectImageUpload);
