import React, { useEffect, useState } from 'react';

import {
  fetchConsents as apiFetchConsents,
  updateConsents as apiUpdateConsents,
} from '../../api/propertyowners';
import { MARKETINGEMAIL } from '../../constants';
import useFetchEffect from '../../hooks/useFetchEffect';
import { StyledNewsletterSignup } from './styles';
import CheckCircle from '../../assets/icons/CheckCircle';

const NewsletterSignup = ({
  children,
  backgroundColor,
  boxClassName,
  style,
}) => {
  const defaultConsents = [
    {
      isGranted: false,
      type: MARKETINGEMAIL,
    },
  ];

  const [newsletterSignedUp, setNewsletterSignedUp] = useState(false);
  const getConsentByType = (consents, type) =>
    (consents || []).find((consent) => consent.type === type);

  const {
    fetching: fetchingConsent,
    data: consents,
    setData: setConsents,
  } = useFetchEffect({
    initialFetchingState: true,
    initialDataState: defaultConsents,
    defaultDataState: defaultConsents,
    apiFetchFunction: apiFetchConsents,
  });

  const updateConcents = async () => {
    const updatedConsents = consents.map((consent) =>
      consent.type === MARKETINGEMAIL
        ? { ...consent, isGranted: true }
        : consent
    );

    const [errorConsent, consentResponse] = await apiUpdateConsents(
      updatedConsents
    );
    if (!errorConsent && consentResponse) {
      setConsents(updatedConsents);
    }
  };

  useEffect(() => {
    setNewsletterSignedUp(
      (
        getConsentByType(consents, MARKETINGEMAIL) || {
          isGranted: false,
        }
      ).isGranted
    );
  }, [consents]);

  return (
    <StyledNewsletterSignup
      className={boxClassName}
      $backgroundColor={backgroundColor}
      style={style}
    >
      <div className="feature_content">
        <h2 className="place_holder_heading">Få siste nytt først!</h2>
        <p className="place_holder_description">
          {children}I vårt nyhetsbrev mottar du siste nytt fra boligmarkedet, og
          du er først ute til å få vite om nye tjenester og funksjoner i
          Boligmappa.
        </p>
        {!newsletterSignedUp && (
          <button
            size="small"
            color="primary"
            className="button"
            type="button"
            onClick={updateConcents}
            disabled={fetchingConsent}
          >
            Meld meg på
          </button>
        )}
        {newsletterSignedUp && (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircle className="checkIcon" />

              <h4>Du er påmeldt nyhetsbrevet!</h4>
            </div>
            <p className="newsletter_description">
              Takk for at du ønsker å holde deg oppdatert. I nyhetsbrevet finner
              du lenke for å melde deg av.
            </p>
          </>
        )}
      </div>
    </StyledNewsletterSignup>
  );
};

export default NewsletterSignup;
