const styles = () => ({
  buttonPane: {
    marginTop: '5px',
    padding: '15px 20px',
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #E6E6E6',
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#0000000a' },
  },
  title: {
    marginLeft: '10px',
    fontSize: 16,
    fontWeight: 300,
  },
});

export default styles;
