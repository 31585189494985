import { makeStyles } from '@material-ui/core';

export const useFindProfessionalsStyles = makeStyles(() => ({
  filterSection: {
    zIndex: 2,
    backgroundColor: 'white',

    '&.mobile': {
      width: '100%',
    },

    '&.collapsed': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: 'auto',
    },
  },
  filterSectionMobile: {
    zIndex: 2,
    backgroundColor: 'white',

    '&.mobile': {
      width: '100%',
    },

    '&.collapsed': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: 'auto',
    },
  },
  infiniteScrollWrapper: {
    overflow: 'hidden !important',
  },
  loadingWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: '50px',
    marginTop: 20,
  },
  noResultWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResultTextContent: {
    textAlign: 'center',
    '& p': {
      margin: 0,
    },
  },
  noResultImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  companyCard: {
    height: '260px',
    boxShadow: '0px 12px 32px rgba(38, 39, 59, 0.13)',
    display: 'flex',
    flexDirection: 'row',
    '@media only screen and (max-width: 768px)': {
      height: '219px',
      boxShadow: '0px 12px 32px rgba(38, 39, 59, 0.13)',
      padding: '20px 5px',
    },
    '@media only screen and (max-width: 320px)': {
      height: '238px',
      boxShadow: '0px 12px 32px rgba(38, 39, 59, 0.13)',
    },
  },
  companyCardButton: {
    width: '90%',
    border: '1px solid',
    fontSize: '14px',
    fontWeight: '500',
    borderColor: '#DDE2F1',
    padding: '5px 0',
  },
  companyButtonArea: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '2px',
    '@media only screen and (max-width: 1080px)': {
      marginBottom: '6px',
    },
  },
  companyImageSection: {
    display: 'flex',
    justifyContent: 'center',
  },
  companyProfessionDataArea: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '11px',
    '@media only screen and (max-width: 1080px)': {
      marginBottom: '8px',
    },
  },
  professionTypeTag: {
    backgroundColor: '#D9E9E9',
    width: 'max-content',
    height: '24px',
    fontSize: '12px',
    fontWeight: '700',
    padding: '3px 8px',
    marginRight: '4px',
    borderRadius: '4px',
    '@media only screen and (max-width:1080px)': {
      fontSize: '10px',
      padding: '4px 8px',
    },
  },
  profetionTypeText: {
    fontSize: '12px',
    fontWeight: '700',
    borderRadius: '4px',
    '@media only screen and (max-width:1080px)': {
      fontSize: '10px',
    },
  },
  emptyProfessionTypeTag: {
    backgroundColor: '#DEDFDF',
    width: 'max-content',
    height: '24px',
    fontSize: '12px',
    fontWeight: '500',
    padding: '3px 8px',
    marginRight: '4px',
    borderRadius: '4px',
    '@media only screen and (max-width: 1080px)': {
      fontSize: '10px',
      padding: '4px 8px',
    },
  },
  distanceTag: {
    backgroundColor: '#F7F8FD',
    width: 'max-content',
    height: '24px',
    fontSize: '12px',
    padding: '3px 8px',
    marginRight: '5px',
    color: '#73748F',
    borderRadius: '4px',
  },
  distanceTagArea: {
    display: 'flex',
    '@media only screen and (max-width: 1080px)': {
      display: 'none',
    },
  },
  text: {
    fontSize: '12px',
    padding: '0 8px',
    fontWeight: '400',

    '@media only screen and (min-width:1080px)': {
      width: '250px',
    },
    '@media only screen and (min-width: 960px) and  (max-width: 1080px)': {
      fontSize: '12px',
    },
  },
  title: {
    fontWeight: '500',
    '@media only screen and (max-width: 1080px)': {
      fontSize: '14px',
    },
  },
  companyCardTitleArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '@media only screen and (max-width: 1080px)': {
      marginBottom: '8px',
    },
  },
  companyCardMobilleDistanceArea: {
    display: 'none',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: '8px',
    '@media only screen and (max-width: 1080px)': {
      display: 'flex',
      minHeight: '24px',
    },
  },
  companyCardAddressArea: {
    fontSize: '14px',
    fontWeight: '400',
    alignItem: 'center',
  },
  companyCardCertificateTitle: {
    fontSize: '10px',
    fontWeight: '700',
    fontFamily: 'Sofia Pro',
  },
  companyCertificateArea: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '14px',
    fontWeight: '400',
    maxHeight: '48px',
    '@media only screen and (max-width: 768px)': {
      display: 'none',
    },
  },
  certificateMargin: {
    margin: '0 3px',
  },
  BallIcon: {
    width: '8px',
    height: '8px',
    margin: '0 3px',
  },
  logoImage: {
    width: '112px',
    height: '84px',
    objectFit: 'contain',
    '@media only screen and (max-width: 768px)': {
      width: '84px',
      height: '51px',
      objectFit: 'cover',
    },
  },
  companyCardImageArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  companyCardContactMissingText: {
    fontWeight: '500',
    fontSize: '14px',
  },
  companyBadgeIcon: {
    width: '12px',
    height: '20px',
  },
  sortFilterWrapper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    flexDirection: 'row',
    paddingBottom: '4px',
  },
  filterOptionsSection: {
    flexGrow: 1,
  },
  sortingSection: {
    minWidth: '200px',
    '@media only screen and  (max-width: 767px)': {
      display: 'none',
    },
  },
  formInput: {
    '& .MuiInput-formControl': {
      marginTop: '0 !important',
    },
    '& .MuiInputBase-input': {
      paddingTop: '8px',
      paddingBottom: '9px',
    },
    '@media only screen and (max-width: 1000px)': {
      '& .MuiInputBase-formControl': {
        border: 'none',
      },
    },
  },
  dotLoader: {
    marginLeft: 90,
  },
  totalItemCount: {
    margin: '50px 0 20px 24px',
    '@media only screen and (max-width: 768px)': {
      textAlign: 'center',
      margin: 0,
    },
  },
  verticalSeperator: {
    borderRight: '1px solid #DCDCE8',
    height: '40px',
    marginRight: '5px',
    '@media only screen and (max-width: 960px)': {
      display: 'none',
    },
  },
  companyNameSearchFilter: {
    '@media only screen and (max-width: 960px)': {
      top: 10,
      right: 5,
      display: 'flex',
    },
  },
  companyNameSearchFilterIOS: {
    '@media only screen and (max-width: 960px)': {
      top: 40,
      right: 5,
      display: 'flex',
    },
  },
  companyNameSearchFilterOnExpand: {
    width: '100%',
    '& .MuiInputBase-formControl': {
      border: '1px solid black',
    },
    '& .MuiCollapse-wrapperInner': {
      width: '100%',
    },
  },
  companyNameSearchFilterOnExpandIOS: {
    width: '100%',
    '& .MuiInputBase-formControl': {
      border: '1px solid black',
    },
    '& .MuiCollapse-wrapperInner': {
      width: '100%',
    },
  },

  mobileDrawer: {
    borderRadius: '15px 15px 0 0',
  },
  mobileDrawerModel: {
    height: '90%',
  },
  sortOptionsContainer: {
    width: '100%',
    overflowX: 'scroll',
    padding: '10px 10px 10px 0px',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
  },
  sortOption: {
    display: 'inline-block',
    height: '100%',
  },
  closeButton: {
    width: '20px',
    height: '20px',
    '@media only screen and (min-width: 960px)': {
      paddingRight: '5px',
      width: '25px',
      height: '25px',
    },
  },
  textInputOnValue: {
    borderColor: 'black',
    borderWidth: '2px',
    background: '#D9E9E9',
    boxShadow: 'none',
    borderRadius: '4px',
    ':hover': {
      borderColor: 'black !important',
    },
  },
  moreFiltersDialogTitle: {
    padding: 0,
    marginLeft: 16,
    marginTop: 24,
    height: '80px',
    '@supports (padding-top: env(safe-area-inset-top))': {
      paddingTop: 'env(safe-area-inset-top)',
    },
  },
  moreFiltersDialogFilterTitle: {
    padding: 0,
    marginLeft: 13,
    marginTop: 24,
    height: '80px',
    '@supports (padding-top: env(safe-area-inset-top))': {
      paddingTop: 'env(safe-area-inset-top)',
    },
  },
  findProfessionalHeader: {
    '@media only screen and (max-width: 960px)': {
      '& header': {
        zIndex: 1,
      },
    },
  },
  drawerHeaderText: {
    fontWeight: '700',
    fontSize: '18px',
    color: 'inherit',
  },
  distanceFilterCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '12px 24px 24px 24px',
    gap: '24px',
    width: '417px',
    height: '156px',
    background: '#FFFFFF',
    boxShadow: '0px 5px 25px rgba(51, 51, 51, 0.13)',
    borderRadius: '8px',
    flex: 'none',
    order: '1',
    flexGrow: '0',
    marginTop: '5%',
  },
  distancefilterheading: {
    width: '133px',
    height: '24px',
    fontFamily: 'Sofia Pro',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '24px',
    color: '#13142A',
    flex: 'none',
    order: '0',
    flexGrow: '0',
  },
  distancefilterheadingMobile: {
    position: 'absolute',
    width: '118px',
    height: '28px',
    left: '18px',
    fontFamily: 'Sofia Pro',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '28px',
    color: '#13142A',
  },
  moreFilterBody: {
    margin: '0px 24px 20px 24px',
    minHeight: '400px',
  },
  moreFiltersSubTitles: {
    fontSize: '18px',
    fontWeight: 700,
  },
  moreFiltersSubTitlesMobile: {
    fontSize: '16px',
    fontWeight: 700,
  },
  moreFilterContent: {
    fontSize: '14px',
    fontWeight: 400,
  },
  moreSelectedSortButton: {
    fontWeight: '700',
    fontSize: '14px',
    padding: '12px',
    lineHeight: '16px',
    height: '40px',
    background: '#D9E9E9',
    border: '2px solid #13142A',
    borderRadius: '4px',
  },
  moreFilterButton: {
    textDecoration: 'none',
    alignItems: 'center',
    maxWidth: '25%',
    padding: '12px',
    height: '40px',
    fontSize: '12px',
    border: '1.5px solid #DDE2F1',
    borderRadius: '4px',
    width: '100%',
    marginLeft: 20,
  },
  moreFilterButtonMobile: {
    textDecoration: 'none',
    alignItems: 'center',
    padding: '12px',
    fontSize: '12px',
    border: 'none',
    borderRadius: '4px',

    '& .desktopIcon': {
      marginRight: '5px',
    },

    '&.hide': {
      display: 'none',
    },
  },
  generalButton: {
    textDecoration: 'none',
    '&:active': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'none',
      background: 'none',
    },
    padding: '10px 10px 10px 0px',
  },
  moreNonSelectedSortButton: {
    height: '40px',
    fontSize: '14px',
    lineHeight: '16px',
    border: '1.5px solid #DDE2F1',
    borderRadius: '4px',
    fontWeight: '400',
    padding: '12px',
  },
  actionButton: {
    width: '130px',
    height: '40px',
    borderRadius: '50px',
    color: '#FFFFFF',
  },
  cancelButton: {
    padding: '8px 16px',
    height: '40px',
    border: '1px solid #DDE2F1',
    borderRadius: '50px',
    color: '#13142A',
  },
  backIcon: {
    transform: 'translate(0, -50%) rotate(180deg)',
    marginBottom: '-30px',
    cursor: 'pointer',
  },
  filterOption: {
    padding: 15,
    flexGrow: 1,
  },
  filterOptionContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '@media only screen and (max-width: 1024px)': {
      borderBottom: '1px solid #DDE2F1',
    },
  },
  moreFilterSortingSection: {
    paddingLeft: '15px',
  },
  moreFilterIcon: {
    marginRight: '10px',
  },
  accordianArrow: {
    marginRight: '10px',
  },
  errorMessageTitle: {
    color: ' #707070',
    fontSize: '20px',
    fontWeight: 700,
  },
  errorMessageText: {
    color: ' #707070',
    fontSize: '16px',
    fontWeight: 500,
    width: '40%',
    textAlign: 'center',
  },
  mobileLagreButton: {
    height: 40,
    width: '100%',
    padding: 20,
    backgroundColor: '#254E52',
    borderRadius: '50px',
    color: '#FFFFFF',
    margin: '0 20px 0 20px',
  },
  errorMessageTextMobile: {
    color: ' #707070',
    fontSize: '16px',
    fontWeight: 500,
    width: '80%',
    textAlign: 'center',
  },
  dialogSmallText: {
    marginTop: '5px',
    fontWeight: 400,
    fontSize: '13px',
    color: '#73748F',
  },
  drawerClose: {
    marginTop: 6,
  },
  moreOptionDisable: {
    color: '#DEDFDF',
    pointerEvents: 'none',
  },
  professionTypesSearchSection: {
    margin: '-20px 0 25px 0',
  },
  professionTypesDivider: {
    color: '#ccc',
    marginTop: '16px',
    height: '1.5px',
    '@media only screen and (min-width: 1024px)': {
      display: 'none',
    },
  },
}));
