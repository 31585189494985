import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import clsx from 'clsx';

import styles from './styles';
import FileForm from './FileForm';
import FileChoice from './FileChoice';
import useSnackbar from '../../../../../../../hooks/useSnackbar';
import { FileUploadContext } from '../../../../../../../contexts/fileUpload';
import { acAddDocument } from '../../../../../../../store/actions/documents';
import { sGetDocuments } from '../../../../../../../store/reducers/documents';
import { getPropertyBoligmappaNumber } from '../../../../../../../utils/properties';
import Typography from '../../../../../../../components/atomic/Typography';
import useDesktop from '../../../../../../../hooks/useDesktop';

const UPLOAD_FILE_MODE = 'UPLOAD_FILE_MODE';
const SELECT_FILES_MODE = 'SELECT_FILES_MODE';

export const UploadDocumentsWrapper = ({
  classes,
  documents,
  addDocument,
  property,
  onClose,
  onDocumentUploaded,
}) => {
  const isDesktop = useDesktop();
  const { showSuccessMessage } = useSnackbar();

  const {
    files,
    setFiles,
    onFileUploadCancel,
    onFileUploadComplete,
  } = useContext(FileUploadContext);

  const [mode, setMode] = useState(SELECT_FILES_MODE);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [createdDocuments, setCreatedDocuments] = useState([]);

  const currentFile =
    files && files[currentFileIndex] ? files[currentFileIndex] : null;

  const setSuccessMessage = (newDocuments) => {
    if (newDocuments.length === 1) {
      showSuccessMessage('Dokument lastet opp.');
    } else if (newDocuments.length > 1) {
      showSuccessMessage(
        `${newDocuments.length} av ${files.length} dokumenter lastet opp`
      );
    }
  };

  const onCancel = () => {
    onClose();
    onFileUploadCancel();
  };

  const onUploaded = (document) => {
    // Only add document to store if there are documents loaded.
    // Otherwise created document will be fetched along with all other documents.
    if (documents) {
      addDocument(document);
    }

    setCreatedDocuments([...createdDocuments, document]);
    onDocumentUploaded({ label: document.title, value: document.id });

    if (currentFileIndex + 1 !== files.length) {
      setCurrentFileIndex(currentFileIndex + 1);
    } else {
      onFileUploadComplete();
      setFiles([]);
      onClose();
      setSuccessMessage([...createdDocuments, document]);
    }
  };

  const onFileSelected = () => {
    window.scrollTo(0, 0);
    setMode(UPLOAD_FILE_MODE);
  };

  const onFileSelect = (selectedFiles) => {
    setFiles(selectedFiles);

    if (selectedFiles.length === 0) {
      onClose();
    }
  };

  return (
    <>
      {mode === UPLOAD_FILE_MODE && currentFile && (
        <>
          <Typography
            className={clsx(
              isDesktop
                ? classes.currentIndexDesktop
                : classes.currentIndexMobile,
              classes.currentIndex
            )}
          >
            {`Dokument ${currentFileIndex + 1} av ${files.length}`}
          </Typography>

          <FileForm
            currentFileIndex={currentFileIndex}
            file={currentFile}
            files={files}
            onCancel={onCancel}
            onUploaded={onUploaded}
            fetchingProperty={!property}
            boligmappaNumber={getPropertyBoligmappaNumber(property)}
          />
        </>
      )}

      {mode === SELECT_FILES_MODE && (
        <FileChoice
          isBYBF
          files={files}
          setFiles={onFileSelect}
          onSubmit={onFileSelected}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  documents: sGetDocuments(state),
});

const mapDispatchToProps = (dispatch) => ({
  addDocument: (document) => dispatch(acAddDocument(document)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UploadDocumentsWrapper));
