import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import useMenuState from '../../../hooks/useMenuState';
import EditOptionHeader from '../EditOptionHeader';
import MultiSelectComboBox from '../../MultiSelectComboBox';
import {
  ATTACH_EVENT_EDIT_OPTION,
  DOCUMENT_DELETE_OPTION,
  DOCUMENT_DOWNLOAD_OPTION,
  DOCUMENT_TYPE_EDIT_OPTION,
  COPY_DOCUMENT_TO_PROPERTY_OPTION,
  MOVE_DOCUMENT_TO_PROPERTY_OPTION,
  DOCUMENT_DELETE_OPTION_PERSONAL,
} from '../../../utils/documents';
import DocumentTypeOptions from '../DocumentTypeOptions';
import { sGetEvents } from '../../../store/reducers/events';

const EditOptionItem = ({
  name,
  onFiltersReset,
  editOptions,
  menuOption,
  onMenuSave,
  icon,
  type,
  onSelectOption,
  events,
  selectedDocuments,
  isVisible,
  directAction,
  ...props
}) => {
  const {
    onMenuOpen,
    anchorEl,
    onMenuClose,
    open,
    onOptionMenuClose,
    setOpen,
    setAnchorEl,
  } = useMenuState();

  const onOutsideClick = () => {
    onOptionMenuClose();
    onFiltersReset();
  };

  const editOptionHeaderRef = useRef(null);

  const onMenuClick = () => {
    if (type === DOCUMENT_DOWNLOAD_OPTION) {
      return menuOption;
    }
    if (type === DOCUMENT_DELETE_OPTION_PERSONAL) {
      return onMenuSave;
    }
    return onMenuOpen;
  };

  const [eventRefresh, setEventRefresh] = useState(1);
  useEffect(() => {
    const newEvent = events?.sort((a, b) => b.id - a.id)[0];
    if (newEvent && editOptionHeaderRef) {
      if (eventRefresh > 1) {
        if (type === ATTACH_EVENT_EDIT_OPTION) {
          const headerElement = editOptionHeaderRef.current;
          setAnchorEl(headerElement);
          setOpen(true);
        }
      }
      setEventRefresh(1);
    }
    setEventRefresh(eventRefresh + 1);
  }, [events?.length]);

  const onSave = () => {
    onMenuClose();
    onMenuSave();
  };

  return (
    isVisible && (
      <>
        <EditOptionHeader
          name={name}
          icon={icon}
          onClick={onMenuClick()}
          ref={editOptionHeaderRef}
          directAction={directAction}
          {...props}
        />

        {type !== DOCUMENT_DOWNLOAD_OPTION &&
          type !== DOCUMENT_DELETE_OPTION_PERSONAL &&
          (type === DOCUMENT_TYPE_EDIT_OPTION ||
          type === MOVE_DOCUMENT_TO_PROPERTY_OPTION ||
          type === COPY_DOCUMENT_TO_PROPERTY_OPTION ? (
            <DocumentTypeOptions
              anchorEl={anchorEl}
              menuOpen={open}
              onMenuClose={onOutsideClick}
              onMenuSave={onSave}
              editOptions={editOptions}
              onSelectOption={onSelectOption}
              radioGroupName="Document Type"
              onFiltersReset={onFiltersReset}
              enableSearch={type === DOCUMENT_TYPE_EDIT_OPTION}
            />
          ) : (
            <MultiSelectComboBox
              type={type}
              isDelete={type === DOCUMENT_DELETE_OPTION}
              anchorEl={anchorEl}
              menuOpen={open}
              isFiltering={false}
              onMenuClose={onOutsideClick}
              onFiltersReset={onFiltersReset}
              filterOptions={editOptions}
              onMenuSave={onSave}
              selectedDocuments={selectedDocuments}
            />
          ))}
      </>
    )
  );
};

const mapStateToProps = (state) => ({
  events: sGetEvents(state),
});

export default connect(mapStateToProps, null)(EditOptionItem);
