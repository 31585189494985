import React, { useState, createRef, useEffect, createContext } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import Portal from '../components/Portal';
import FileUploadInput from '../components/FileUploadInput';
import useDashboardLinkPrefix from '../hooks/useDashboardLinkPrefix';
import { createFolderStructure } from '../api/documents';
import useSnackbar from '../hooks/useSnackbar';
import { createFolderErrorCodes } from '../utils/errorCodes';
import { acAddPersonalFolder } from '../store/actions/personalDocuments';

export const FileUploadContext = createContext();

export const FileUploadProvider = ({ inputContainerRef, children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const { showErrorMessage } = useSnackbar();

  const inputRef = createRef();
  const inputCameraRef = createRef();
  const [files, setFiles] = useState([]);
  const [directoryId, setDirectoryId] = useState();
  const [parentDirectoryId, setParentDirectoryId] = useState();
  const [directoryTree, setDirectoryTree] = useState();
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [query, setQuery] = useState(null);
  const [source, setSource] = useState(null);
  const [docRoom, setDocRoom] = useState(null);
  const [docEvent, setDocEvent] = useState(null);
  const [newUpload, setNewUpload] = useState(null);
  const [personalDocument, setPersonalDocument] = useState(null);

  const onInputClick = () => {
    inputRef.current.value = null;
  };
  const onCameraInputClick = () => {
    inputCameraRef.current.value = null;
  };

  const onFolderSelect = async (event) => {
    const selectedFiles = Array.from(event.target.files);

    const [error, response] = await createFolderStructure({
      paths: selectedFiles.map((pt) => pt.webkitRelativePath),
      isSystemGenerated: false,
      parentId: parentDirectoryId,
    });

    if (!error && response) {
      dispatch(
        acAddPersonalFolder(
          breadCrumbs,
          response?.directories.length > 0 && response?.directories[0]
        )
      );

      setSource(query?.source);
      setDocRoom(query?.room);
      setDocEvent(query?.event);
      setNewUpload(query?.newUpload);
      setPersonalDocument(query?.personalDocument);
      setFiles(selectedFiles);
      setDirectoryTree(response?.directories);
    }
    if (error) {
      showErrorMessage(createFolderErrorCodes[error.code] || error.code);
    }
  };

  const onFileSelect = (event) => {
    const isBYBF = query?.bybfEventsMode;
    const isRoomOrEvent = query?.isRoomOrEvent;
    setSource(query?.source);
    setDocRoom(query?.room);
    setDocEvent(query?.event);
    setNewUpload(query?.newUpload);
    setPersonalDocument(query?.personalDocument);

    const selectedFiles = Array.from(event.target.files);

    if (isBYBF) {
      const newFiles = [...files, ...selectedFiles];
      setFiles([...new Set(newFiles)]);
    } else {
      setFiles(selectedFiles);
    }
    if (!isRoomOrEvent) {
      if (!personalDocument) {
        history.push(`${dashboardLinkPrefix}/documents`);
      } else {
        history.push(`${dashboardLinkPrefix}/documents?personal=true`);
      }
    }
  };
  const onFilesAdd = (config) => {
    const { query: passedQuery } = config;
    setSource(passedQuery?.source);
    setDocRoom(passedQuery?.room);
    setDocEvent(passedQuery?.event);
    if (passedQuery) {
      setQuery(passedQuery);
    }
    const isRoomOrEvent = query?.isRoomOrEvent;
    if (!isRoomOrEvent) {
      history.push(`${dashboardLinkPrefix}/documents`);
    }
  };

  const onFileUploadClick = (config = {}, accept = undefined) => {
    if (accept) {
      inputRef.current.accept = accept;
    }
    const { query: passedQuery } = config;
    if (passedQuery.webkitdirectory) {
      inputRef.current.webkitdirectory = passedQuery.webkitdirectory;
      setBreadCrumbs(passedQuery.breadCrumbs);
      if (passedQuery.parentDirectoryId) {
        setParentDirectoryId(passedQuery.parentDirectoryId);
      }
    } else {
      inputRef.current.webkitdirectory = false;
    }

    if (passedQuery) {
      setQuery(passedQuery);
    }

    inputRef.current.click();
  };

  const onCameraUploadClick = (config = {}, accept) => {
    inputCameraRef.current.accept = accept;
    const { query: passedQuery } = config;
    if (passedQuery) {
      setQuery(passedQuery);
    }
    inputCameraRef.current.click();
  };

  const onFileUploadComplete = () => {
    setQuery(null);
  };

  const onFileUploadCancel = () => {
    setFiles([]);
    setQuery(null);
  };

  const value = {
    files,
    setFiles,
    directoryId,
    setDirectoryId,
    inputRef,
    onFileUploadClick,
    onCameraUploadClick,
    onFileUploadCancel,
    onFileUploadComplete,
    onFilesAdd,
    source,
    newUpload,
    docRoom,
    docEvent,
    setDocEvent,
    setDocRoom,
    personalDocument,
    directoryTree,
    setDirectoryTree,
  };

  useEffect(() => {
    // Use native onchange to fire event correctly on single room/event page
    if (inputRef && inputRef.current) {
      inputRef.current.onchange = query?.webkitdirectory
        ? onFolderSelect
        : onFileSelect;
    }
  }, [inputRef]);

  useEffect(() => {
    // Use native onchange to fire event correctly on single room/event page
    if (inputCameraRef && inputCameraRef.current) {
      inputCameraRef.current.onchange = onFileSelect;
    }
  }, [inputCameraRef]);

  return (
    <FileUploadContext.Provider value={value}>
      {children}

      {/*
       * Render input component outside of <Router> to persist file selection
       * while user navigates throghout the app after file selection
       */}
      <Portal container={inputContainerRef.current}>
        <FileUploadInput
          multiple
          accept="*"
          ref={inputRef}
          onClick={onInputClick}
        />
        <FileUploadInput
          multiple
          accept="*"
          capture="environment"
          ref={inputCameraRef}
          onClick={onCameraInputClick}
          onChange={onFileSelect}
        />
      </Portal>
    </FileUploadContext.Provider>
  );
};
