import React, { forwardRef } from 'react';

import Box from '../../../../atomic/Box';
import Button from '../../../../atomic/Button';
import MenuItem from '../../../../atomic/MenuItem';
import FilterListItem from '../../../../DocumentFilters/FilterList/FilterListItem';

const FilterList = forwardRef(({ filterOptions, onReset }, ref) => (
  <Box ref={ref}>
    <Box p={1} textAlign="right">
      <Button
        varinat="text"
        color="primary"
        onClick={onReset}
        style={{ textDecoration: 'none' }}
      >
        <small>Nullstill</small>
      </Button>
    </Box>

    {filterOptions.map((props) => (
      <FilterListItem
        {...props}
        component={MenuItem}
        key={`filter-item-${props.name}`}
      />
    ))}
  </Box>
));

export default FilterList;
