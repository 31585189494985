import React from 'react';
import { withStyles } from '@material-ui/core';

import DeleteIcon from '../../../../../../../assets/icons/Bin';

import Table from '../../../../../../../components/Table';
import IconButton from '../../../../../../../components/atomic/IconButton';
import TableLoading from '../../../../../../../components/desktop/loaders/TableLoading';
import Tooltip from '../../../../../../../components/Tooltip';
import { formattedDate } from '../../../../../../../utils/dates';

import style from './style';

const AccessTable = ({ classes, shareRecords, fetching, onDeleteShare }) => {
  const columns = [
    {
      title: 'Firma',
      field: 'name',
      render: (shareRecord) => (
        <div className={classes.name}>{shareRecord.requester.company}</div>
      ),
    },
    {
      title: 'Bygningssakkyndig',
      field: 'email',
      render: (shareRecord) => (
        <div className={classes.emailField}>
          {shareRecord.requester.firstName} {shareRecord.requester.lastName}
        </div>
      ),
    },
    {
      title: 'Dato delt',
      field: 'updatedDate',
      render: (shareRecord) => (
        <div className={classes.dateField}>
          {formattedDate(shareRecord.updatedDate)}
        </div>
      ),
    },
    {
      title: 'Tilgang utløper',
      field: 'expiryDate',
      render: (shareRecord) => (
        <div className={classes.dateField}>
          {formattedDate(shareRecord.expiryDate)}
        </div>
      ),
    },
    {
      cellStyle: {
        textAlign: 'center',
      },
      render: (shareRecord) => (
        <Tooltip title="Fjern tilgangen" placement="top" fontSize={15}>
          <IconButton
            className={classes.iconbutton}
            onClick={() => onDeleteShare(shareRecord)}
          >
            <DeleteIcon width="15px" height="15px" />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  if (fetching) {
    return (
      <>
        <br />
        <br />
        <TableLoading rows={5} columns={4} />
      </>
    );
  }

  return (
    <>
      <Table columns={columns} data={shareRecords} />
    </>
  );
};

export default withStyles(style)(AccessTable);
