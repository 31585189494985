import uuidv4 from 'uuid/v4';
import lodashTrim from 'lodash-es/trim';
import lodashTruncate from 'lodash-es/truncate';
import lodashIsString from 'lodash-es/isString';
import lodashStartsWith from 'lodash-es/startsWith';

export const toLowerCase = (string) =>
  string && string.toLowerCase ? string.toLowerCase() : string;

export const toUpperCase = (string) =>
  string && string.toUpperCase ? string.toUpperCase() : string;

export const capitalizeFirst = (string) =>
  string ? string.charAt(0).toUpperCase() + toLowerCase(string.slice(1)) : '';

export const splitAndCapitalize = (
  string = '',
  separator = ' ',
  replaceSeparator = null
) =>
  string
    .split(separator)
    .map(capitalizeFirst)
    .reduce(
      (result, value) => `${result}${replaceSeparator || separator}${value}`
    );

export const capitalizeFullname = (fullname = '', separators = ['-', ' ']) =>
  separators.reduce(
    (result, value) => splitAndCapitalize(result, value),
    fullname
  );

export const compareAlphabetically = (a, b, sortDirection = 'asc') => {
  if (toLowerCase(a) > toLowerCase(b)) {
    return sortDirection === 'asc' ? 1 : -1;
  }

  if (toLowerCase(a) < toLowerCase(b)) {
    return sortDirection === 'asc' ? -1 : 1;
  }

  return 0;
};

// Convert to String type because UIDs can be strings
export const areIdsEqual = (firstId, secondId) =>
  String(firstId) === String(secondId);

// TODO use lodash
export const getRandomUid = (...params) => uuidv4(...params);

export const trim = (string) => lodashTrim(string);

export const isString = (value) => lodashIsString(value);

export const startsWithInteger = (string) => {
  if (!isString(string)) return false;
  if (!string) return false;

  const integers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  return string[0] && integers.includes(string[0]);
};

export const startsWith = (...args) => lodashStartsWith(...args);

const defaultTruncateConfig = { omission: '...' };

export const truncate = (string, { length, ...config }) =>
  lodashTruncate(string, { ...defaultTruncateConfig, ...config, length });

export const formatNumber = (number, delimeter = ' ') =>
  String(number).replace(/\B(?=(\d{3})+(?!\d))/g, delimeter);

export const findSubString = (txt, value ) =>
  {
    return txt.substring(0, value.length) === value;
  };

export const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
};

export const getMaxNumber = (numArray) => {
    if(numArray?.length > 0){  
      return Math.max.apply(null,numArray);       
    }
    return 0;
};
