import { useMediaQuery } from '@material-ui/core';
import { gridOptions } from '../utils/grid';

const useGrid = () => {
  const mediaQueries = {
    xs: useMediaQuery((theme) => theme.breakpoints.only('xs')),
    sm: useMediaQuery((theme) => theme.breakpoints.only('sm')),
    md: useMediaQuery((theme) => theme.breakpoints.only('md')),
    lg: useMediaQuery((theme) => theme.breakpoints.up('md')),
  };

  const screenSize = Object.keys(mediaQueries).reduce((result, key) =>
    mediaQueries[key] === true ? key : result
  );

  return {
    itemsPerLine: screenSize && gridOptions[screenSize].itemsPerLine,
  };
};

export default useGrid;
