import * as React from 'react';

const DocumentDownloadWarning = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    style={{ marginRight: 10 }}
    height={15}
    fill="none"
    {...props}
  >
    <path
      fill="#111"
      d="M7.809 6.355c-.028-.082 0-.164.082-.246A.347.347 0 0 1 8.137 6h1.476c.082 0 .164.055.246.11a.441.441 0 0 1 .082.245l-.164 3.063a.595.595 0 0 1-.136.219.295.295 0 0 1-.192.082H8.301a.31.31 0 0 1-.219-.082c-.055-.055-.11-.137-.11-.219L7.81 6.355Zm2.214 4.895c0-.3-.136-.574-.355-.793-.219-.219-.492-.355-.793-.355-.328 0-.602.136-.82.355a1.095 1.095 0 0 0-.328.793c0 .328.109.602.328.82.218.22.492.328.82.328.3 0 .574-.109.793-.328.219-.218.355-.492.355-.82Zm0-9.844 6.563 11.375c.137.301.191.602.137.903-.055.3-.22.546-.438.765-.246.219-.52.301-.848.301H2.313c-.329 0-.63-.082-.848-.3a1.29 1.29 0 0 1-.438-.766c-.054-.301-.027-.602.137-.903L7.727 1.406c.164-.273.382-.465.683-.574.3-.11.602-.11.902 0 .301.11.547.3.711.574ZM2.45 13.191c-.054.055-.054.11 0 .164a.15.15 0 0 0 .137.082h12.578c.055 0 .082-.027.137-.082a.175.175 0 0 0 0-.164L9.01 2.31c-.054-.055-.081-.082-.136-.082a.15.15 0 0 0-.137.082L2.45 13.19Z"
    />
  </svg>
);
export default DocumentDownloadWarning;
