import React from 'react';

const AddFile = ({ width = '12', height = '16', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M1.5 14C1.5 14.2812 1.71875 14.5 2 14.5H10C10.25 14.5 10.5 14.2812 10.5 14V5H8C7.4375 5 7 4.5625 7 4V1.5H2C1.71875 1.5 1.5 1.75 1.5 2V14ZM2 0H7.15625C7.6875 0 8.1875 0.21875 8.5625 0.59375L11.4062 3.4375C11.7812 3.8125 12 4.3125 12 4.84375V14C12 15.125 11.0938 16 10 16H2C0.875 16 0 15.125 0 14V2C0 0.90625 0.875 0 2 0ZM6.75 12.75H6.71875C6.71875 13.1875 6.40625 13.5 5.96875 13.5C5.5625 13.5 5.21875 13.1875 5.21875 12.75V9.5625L4.25 10.5312C3.96875 10.8438 3.5 10.8438 3.21875 10.5312C2.90625 10.25 2.90625 9.78125 3.21875 9.46875L5.46875 7.21875C5.75 6.9375 6.21875 6.9375 6.5 7.21875L8.75 9.46875C9.0625 9.78125 9.0625 10.25 8.75 10.5312C8.46875 10.8438 8 10.8438 7.71875 10.5312L6.75 9.5625V12.75Z"
      fill="#111111"
    />
  </svg>
);

export default AddFile;
