import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './styles';

const NewTag = ({ classes }) => (
  <div className={classes.tagDiv}>
    <span>Ny</span>
  </div>
);

export default withStyles(styles)(NewTag);
