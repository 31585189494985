export const defaultState = null;

export const SET_ROOM_TYPES = 'SET_ROOM_TYPES';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_ROOM_TYPES:
      return action.payload;
    default:
      return state;
  }
};

export const sGetRoomTypes = (state) => state.roomTypes;
