import React from 'react';
import {
  OverviewGridItem,
  PriceHistory,
  PriceHistoryLoading,
} from './components';
import Card from '../../../../../components/Card';
import { useSectionStyles } from './sectionStyles';
import { useActiveProperty } from '../../../../../store/hooks';
import { useFetchPriceHistory } from '../../../../../api/hooks';
import useDesktop from '../../../../../hooks/useDesktop';

export function PriceHistorySection({ hideValues }) {
  const classes = useSectionStyles();
  const { property } = useActiveProperty();
  const priceHistoryFetch = useFetchPriceHistory(property);
  const isDesktop = useDesktop();
  const bogusChartData = {
    area: '---',
    unitType: '---',
    data: [
      {
        date: '2024-05-21',
        indexValue: 600,
      },
      {
        date: '2024-03-31',
        indexValue: 600,
      },
    ],
  };
  return (
    <>
      {(priceHistoryFetch.isLoading || priceHistoryFetch.data) && (
        <OverviewGridItem>
          <Card
            border={isDesktop ? 1 : 0}
            height="100%"
            className={classes.chartBlock}
          >
            <PriceHistoryLoading isLoading={priceHistoryFetch.isLoading} />

            {!priceHistoryFetch.isLoading && priceHistoryFetch.data && (
              <PriceHistory
                priceHistory={
                  hideValues ? bogusChartData : priceHistoryFetch.data
                }
              />
            )}
          </Card>
        </OverviewGridItem>
      )}
    </>
  );
}
