import React, { forwardRef } from 'react';
import { withStyles } from '@material-ui/core';
import BaseListItem from '../../atomic/ListItem';
import Check from '../../../assets/icons/Check';
import style from './style';

const Option = forwardRef(({ classes, data, innerProps }, ref) => {
  const { label } = data;
  return (
    <BaseListItem className={classes.root} ref={ref} {...innerProps}>
      <div className={classes.label}>{label}</div>
      <div className={classes.checked}>
        <Check />
      </div>
    </BaseListItem>
  );
});

export default withStyles(style)(Option);
