const style = {
  dialog: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 500px)': {
      width: '100%',
    },
  },
  dialogContent: {
    padding: '32px !important',
  },
  checkCircle: {
    fontSize: '1rem',
    color: '#5DC9A2',
    marginRight: '10px',
  },
  dialogDescription: {
    fontSize: '14px',
  },
  dialogTitle: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  button: {
    fontSize: '14px',
    width: 'auto',
    height: '32px',
    fontWeight: 'lighter',
  },
};

export default style;
