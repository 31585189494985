import React, { forwardRef } from 'react';
import BaseIconButton from '@material-ui/core/IconButton';

const IconButton = forwardRef(({ children, ...props }, ref) => (
  <BaseIconButton ref={ref} {...props}>
    {children}
  </BaseIconButton>
));

export default IconButton;
