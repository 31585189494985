import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import {
  sGetRights,
  sGetFetchingRights,
  sGetFetchingRightsError,
} from '../../../../../store/reducers/rights';

import {
  acSetRights,
  acSetFetchingRights,
  acSetFetchingRightsError,
} from '../../../../../store/actions/rights';

import useDesktop from '../../../../../hooks/useDesktop';
import Paper from '../../../../../components/atomic/Paper';
import Page from '../../../../../components/layouts/WithHeader';
import useFetchEffect from '../../../../../hooks/useFetchEffect';
import PropertyRight from '../../../../../modules/PropertyRight';
import ErrorContainer from '../../../../../components/ErrorContainer';
import { fetchRights as apiFetchRights } from '../../../../../api/properties';

const Rights = ({
  fetching,
  rights,
  setFetching,
  fetchingError,
  setRights,
  setFetchingError,
}) => {
  const isDesktop = useDesktop();
  const { boligmappaNumber } = useParams();

  useFetchEffect({
    conditionFunction: () => !rights,
    apiFetchFunction: () => apiFetchRights(boligmappaNumber),

    onFetchStart: () => setFetching(true),
    onFetchComplete: () => setFetching(false),

    onSuccess: (data) => setRights(data),
    onError: (error) => setFetchingError(error),
  });

  return (
    <Page
      minFullHeight
      noPadding={!isDesktop}
      hideHeader={isDesktop}
      headerTitle="Rettigheter"
      lessMarginLeft={isDesktop}
      maxWidth="lg"
    >
      {!isDesktop && <br />}
      {fetching ? (
        <Paper visible={!isDesktop}>
          <PropertyRight fetching />
        </Paper>
      ) : (
        <>
          {fetchingError ? (
            <ErrorContainer
              marginTop={isDesktop}
              errorResponse={fetchingError}
            />
          ) : (
            rights.map((right) => (
              <>
                <Paper visible={!isDesktop}>
                  <PropertyRight right={right} />
                </Paper>
                <br />
              </>
            ))
          )}
        </>
      )}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  rights: sGetRights(state),
  fetching: sGetFetchingRights(state),
  fetchingError: sGetFetchingRightsError(state),
});

const mapDispatchToProps = (dispatch) => ({
  setFetching: (fetching) => dispatch(acSetFetchingRights(fetching)),
  setRights: (rights) => dispatch(acSetRights(rights)),
  setFetchingError: (error) => dispatch(acSetFetchingRightsError(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rights);
