import React, { useState } from 'react';
import {
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styles from './styles';
import CheckCircle from '../../../../../../../assets/icons/CheckCircle';
import Typography from '../../../../../../../components/atomic/Typography';

const RoomProgress = ({ classes, room }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const isTitleSet = room?.title !== (null || '');
  const isDescSet = room?.description !== (null || '');
  const isImageSet =
    room.gallery !== undefined &&
    room?.gallery?.length !== 0 &&
    room?.gallery !== null;

  const isRoomComplete = isTitleSet && isDescSet && isImageSet;

  const setIcon = (isDetailsSet, isListIcon) =>
    isDetailsSet ? (
      <CheckCircle
        className={isListIcon ? classes.listCheckCircle : classes.checkCircle}
      />
    ) : (
      <RemoveCircleIcon
        className={isListIcon ? classes.listRemoveCircle : classes.removeCircle}
      />
    );

  return (
    <Accordion
      className={classes.roomProgress}
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.roomHeader}>
          {!expanded && setIcon(isRoomComplete, false)}
          <span className={classes.roomType}>
            <b>{room?.title || 'Ukjent'}</b>
          </span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.list}>
        <Typography className={classes.listItem}>
          {setIcon(isImageSet, true)}
          <span className={classes.step}>Last opp bilde</span>
        </Typography>
        <Typography className={classes.listItem}>
          {setIcon(isTitleSet, true)}
          <span className={classes.step}>Gi rommet et navn</span>
        </Typography>
        <Typography className={classes.listItem}>
          {setIcon(isDescSet, true)}
          <span className={classes.step}>Oppgi nyttig informasjon</span>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default withStyles(styles)(RoomProgress);
