import React, { createElement } from 'react';

import {
  fileIcons,
  STATIC_COLUMN,
  defaultFileIcon,
  getDocumentType,
  getDocumentName,
  FILE_NAME_FILTER,
  FILE_TYPE_FILTER,
  getDocumentRooms,
  getDocumentEvents,
  getDocumentFileType,
  DOCUMENT_TYPE_ASC,
  DOCUMENT_TYPE_DESC,
  DOCUMENT_TYPE_FILTER,
  DOCUMENT_ROOM_FILTER,
  DOCUMENT_EVENT_FILTER,
  DOCUMENT_OWNER_ASC,
  DOCUMENT_OWNER_DESC,
  getDocumentOwnedByName,
  getDocumentCompanyName,
  getDocumentProfessionType,
  FILE_UPLOADED_NAME_FILTER,
  DOCUMENT_UPLOADED_DATE_FILTER,
  DOCUMENT_PROFESSION_TYPE_FILTER,
  FILETYPE_ASC,
  FILETYPE_DESC,
  NAME_ASC,
  NAME_DESC,
  UPDATED_DATE_DESC,
  UPDATED_DATE_ASC,
} from '../../utils/documents';

import {
  toLowerCase,
  toUpperCase,
  compareAlphabetically,
} from '../../utils/strings';

import { getRoomName } from '../../utils/rooms';
import Company from '../../assets/icons/Company';
import { getEventName } from '../../utils/events';
import OptionListFilter from './OptionListFilter';
import UploadedDateFilter from './UploadedDateFilter';
import Individual from '../../assets/icons/Individual';
import TableHeaderCell from '../desktop/DocumentTable/Table/TableHeaderCell';
import TableHeaderOptionsFilter from '../desktop/DocumentTable/Table/TableHeaderOptionsFilter';
import TableHeaderUploadedDateFilter from '../desktop/DocumentTable/Table/TableHeaderUploadedDateFilter';
import matomo from '../../utils/matomo';

export const onListItemClick = (
  filter,
  filters,
  onFiltersChanged,
  processFilter = (incomingFilter) => incomingFilter
) =>
  filters.map(processFilter).includes(processFilter(filter))
    ? onFiltersChanged(
        filters.filter(
          (_filter) => processFilter(_filter) !== processFilter(filter)
        )
      )
    : onFiltersChanged([...filters, processFilter(filter)]);

export const getFileTypeIcon = (fileType) =>
  (
    fileIcons.find(
      ({ fileType: extension }) =>
        toLowerCase(extension) === toLowerCase(fileType)
    ) || defaultFileIcon
  ).icon;

const trackMatomoEvent = (filter) => {
  matomo.clickEvent({
    category: 'Document handling',
    name: `Filter by ${filter}`,
    action: 'Filter Document List',
  });
};

const getFilterOptions = (documents, filters = [], onFiltersChange) => {
  const events = (documents || [])
    .reduce((accumulator, document) => {
      const documentEvents = getDocumentEvents(document) || [];
      const uniqueEvents = documentEvents.filter(
        (event) => !accumulator.includes(getEventName(event))
      );
      return [...accumulator, ...uniqueEvents.map(getEventName)];
    }, [])
    .sort(compareAlphabetically);

  return events.map((eventName) => ({
    name: eventName,
    label: eventName,
    checked: filters.includes(eventName),
    onClick: () => {
      onListItemClick(eventName, filters, onFiltersChange);
      trackMatomoEvent('Event');
    },
  }));
};

export const getFilterTypes = (personaldocument) => {
  return [
    {
      name: 'Navn',
      type: FILE_NAME_FILTER,
      isSortable: true,
      isFilterable: false,
      isVisibleForPersonalDoc: true,
      filterComponent: OptionListFilter,
      description: 'Filterer etter filnavn',
      desktopComponent: TableHeaderOptionsFilter,
      getProps: () => ({ style: { minWidth: 200, paddingLeft: 0 } }),
      getFilterOptions: (documents, filters = [], onFiltersChanged) =>
        (documents || [])
          .reduce(
            (options, document) =>
              options.includes(getDocumentName(document))
                ? options
                : [...options, getDocumentName(document)],
            []
          )
          .map((documentName) => ({
            name: documentName,
            label: documentName,
            checked: filters.includes(documentName),
            icon: createElement(defaultFileIcon.icon),
            onClick: () =>
              onListItemClick(documentName, filters, onFiltersChanged),
          }))
          .sort((a, b) => compareAlphabetically(a.name, b.name)),
      defaultSortUp: false,
      sortUpType: NAME_DESC,
      sortDownType: NAME_ASC,
    },
    {
      name: 'Filtype',
      type: FILE_TYPE_FILTER,
      isSortable: true,
      isFilterable: true,
      filterComponent: OptionListFilter,
      isVisibleForPersonalDoc: true,
      description: (
        <div>
          <b>Filtrer på filtype</b>
        </div>
      ),
      desktopComponent: TableHeaderOptionsFilter,
      getFilterOptions: (documents, filters = [], onFiltersChanged) =>
        (documents || [])
          .reduce(
            (accumulator, document) =>
              accumulator.includes(getDocumentFileType(document))
                ? accumulator
                : [...accumulator, getDocumentFileType(document)],
            []
          )
          .sort(compareAlphabetically)
          .map((fileType) => ({
            name: fileType,
            label: toUpperCase(fileType),
            checked: filters.map(toLowerCase).includes(toLowerCase(fileType)),
            icon: createElement(getFileTypeIcon(fileType)),
            onClick: () => {
              onListItemClick(fileType, filters, onFiltersChanged, (filter) =>
                toUpperCase(filter)
              );
              trackMatomoEvent('Filtype');
            },
          })),
      getProps: () => ({
        style: {
          paddingRight: 0,
        },
      }),
      sortable: true,
      defaultSortUp: false,
      sortUpType: FILETYPE_DESC,
      sortDownType: FILETYPE_ASC,
    },
    {
      name: '',
      desktopOnly: true,
      type: STATIC_COLUMN,
      desktopComponent: TableHeaderCell,
    },
    {
      name: 'Dato',
      description: (
        <div>
          <b>Filtrer på dato</b>
        </div>
      ),
      type: DOCUMENT_UPLOADED_DATE_FILTER,
      isSortable: true,
      isFilterable: true,
      isVisibleForPersonalDoc: true,
      filterComponent: UploadedDateFilter,
      desktopComponent: TableHeaderUploadedDateFilter,
      getProps: (filters, onFiltersChanged) => ({
        filters: filters[DOCUMENT_UPLOADED_DATE_FILTER],
        onFiltersChanged: (updatedFilters) => {
          trackMatomoEvent('Date');
          onFiltersChanged({
            ...filters,
            [DOCUMENT_UPLOADED_DATE_FILTER]: updatedFilters,
          });
        },
      }),
      defaultSortUp: false,
      sortUpType: UPDATED_DATE_DESC,
      sortDownType: UPDATED_DATE_ASC,
    },
    {
      name: 'Type',
      type: DOCUMENT_TYPE_FILTER,
      isSortable: true,
      isFilterable: true,
      filterComponent: OptionListFilter,
      isVisibleForPersonalDoc: !personaldocument,
      description: (
        <div>
          <b>Fitrer på dokumenttype</b>
        </div>
      ),
      desktopComponent: TableHeaderOptionsFilter,
      getFilterOptions: (documents, filters = [], onFiltersChanged) =>
        (documents || [])
          .reduce(
            (accumulator, document) =>
              accumulator.includes(getDocumentType(document))
                ? accumulator
                : [...accumulator, getDocumentType(document)],
            []
          )
          .sort(compareAlphabetically)
          .map((documentType) => ({
            name: documentType,
            label: documentType,
            checked: filters.includes(documentType),
            onClick: () => {
              onListItemClick(documentType, filters, onFiltersChanged);
              trackMatomoEvent('Document Type');
            },
          })),
      defaultSortUp: false,
      sortUpType: DOCUMENT_TYPE_DESC,
      sortDownType: DOCUMENT_TYPE_ASC,
    },

    {
      name: 'Avsender',
      type: FILE_UPLOADED_NAME_FILTER,
      isSortable: true,
      isFilterable: true,
      filterComponent: OptionListFilter,
      isVisibleForPersonalDoc: !personaldocument,
      description: (
        <div>
          <b>Filtrer på avsender</b>
        </div>
      ),
      desktopComponent: TableHeaderOptionsFilter,
      getFilterOptions: (documents, filters = [], onFiltersChanged) =>
        (documents || [])
          .reduce((accumulator, document) => {
            const uploaderName =
              getDocumentOwnedByName(document) ||
              getDocumentCompanyName(document);

            const icon = getDocumentOwnedByName(document) ? (
              <Individual width={17} style={{ maxHeight: '25px' }} />
            ) : (
              <Company width={25} style={{ maxHeight: '25px' }} />
            );

            return accumulator.find(
              (item) => item.uploaderName === uploaderName
            )
              ? accumulator
              : [...accumulator, { uploaderName, icon }];
          }, [])
          .sort((a, b) => compareAlphabetically(a.uploaderName, b.uploaderName))
          .map(({ icon, uploaderName }) => ({
            icon,
            name: uploaderName,
            label: uploaderName,
            checked: filters.includes(uploaderName),
            onClick: () => {
              onListItemClick(uploaderName, filters, onFiltersChanged);
              trackMatomoEvent('Sender');
            },
          })),
      defaultSortUp: false,
      sortUpType: DOCUMENT_OWNER_DESC,
      sortDownType: DOCUMENT_OWNER_ASC,
    },
    {
      name: 'Fag',
      filterComponent: OptionListFilter,
      description: (
        <div>
          <b>Filtrer på fag</b>
        </div>
      ),
      type: DOCUMENT_PROFESSION_TYPE_FILTER,
      isSortable: false,
      isFilterable: true,
      isVisibleForPersonalDoc: !personaldocument,
      desktopComponent: TableHeaderOptionsFilter,
      getFilterOptions: (documents, filters = [], onFiltersChanged) =>
        (documents || [])
          .reduce(
            (accumulator, document) =>
              accumulator.includes(getDocumentProfessionType(document))
                ? accumulator
                : [...accumulator, getDocumentProfessionType(document)],
            []
          )
          .sort(compareAlphabetically)
          .map((professionType) => ({
            name: professionType,
            label: professionType,
            checked: filters.includes(professionType),
            onClick: () => {
              onListItemClick(professionType, filters, onFiltersChanged);
              trackMatomoEvent('Industry Association');
            },
          })),
      defaultSortUp: false,
      sortUpType: DOCUMENT_OWNER_DESC,
      sortDownType: DOCUMENT_OWNER_ASC,
    },
    {
      name: 'Hendelse',
      type: DOCUMENT_EVENT_FILTER,
      isSortable: false,
      isFilterable: true,
      filterComponent: OptionListFilter,
      isVisibleForPersonalDoc: !personaldocument,
      description: (
        <div>
          <b>Filtrer på hendelse</b>
        </div>
      ),
      desktopComponent: TableHeaderOptionsFilter,
      getFilterOptions: (documents, filters = [], onFiltersChange) =>
        getFilterOptions(documents, filters, onFiltersChange),
    },
    {
      name: 'Rom',
      type: DOCUMENT_ROOM_FILTER,
      isSortable: false,
      isFilterable: true,
      filterComponent: OptionListFilter,
      isVisibleForPersonalDoc: !personaldocument,
      description: (
        <div>
          <b>Filtrer på rom</b>
        </div>
      ),
      desktopComponent: TableHeaderOptionsFilter,
      getFilterOptions: (documents, filters = [], onFiltersChange) =>
        (documents || [])
          .reduce(
            (accumulator, document) => [
              ...accumulator,
              ...(getDocumentRooms(document) || [])
                .filter((room) => !accumulator.includes(getRoomName(room)))
                .map(getRoomName),
            ],
            []
          )
          .sort(compareAlphabetically)
          .map((roomName) => ({
            name: roomName,
            label: roomName,
            checked: filters.includes(roomName),
            onClick: () => {
              onListItemClick(roomName, filters, onFiltersChange);
              trackMatomoEvent('Room');
            },
          })),
    },
  ];
};

export const SummaryColumns = [
  {
    name: 'Navn',
    type: 'FILE_NAME',
    styles: { paddingLeft: 0 },
  },
  {
    name: 'Avsender',
    type: 'FILE_UPLOADED_NAME',
  },
  {
    name: 'Dokumenttype',
    type: 'DOCUMENT_TYPE',
  },
  {
    name: 'Hendelse',
    type: 'DOCUMENT_EVENT',
  },
  {
    name: 'Rom',
    type: 'DOCUMENT_ROOM',
  },
  {
    name: '',
    type: 'DOCUMENT_ACTION',
  },
];

export const filterColumns = ({
  filterType,
  showRoomsColumn,
  showEventsColumn,
  showProfessionColumn,
  showUploadedDateColumn,
  showUploaderNameColumn,
}) => {
  const typeMap = {
    DOCUMENT_PROFESSION_TYPE_FILTER: showProfessionColumn,
    DOCUMENT_UPLOADED_DATE_FILTER: showUploadedDateColumn,
    FILE_UPLOADED_NAME_FILTER: showUploaderNameColumn,
    DOCUMENT_EVENT_FILTER: showEventsColumn,
    DOCUMENT_ROOM_FILTER: showRoomsColumn,
  };

  return Object.keys(typeMap).includes(filterType.type)
    ? typeMap[filterType.type]
    : true;
};
