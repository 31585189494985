import { connect } from 'react-redux';
import React, { useState } from 'react';
import { useParams } from 'react-router';

import {
  acSetCompanies,
  acSetFetchingCompanies,
  acSetFetchingCompaniesError,
} from '../../../../store/actions/companies';

import {
  sGetCompanies,
  sGetFetchingCompanies,
  sGetFetchingCompaniesError,
} from '../../../../store/reducers/companies';

import Box from '../../../../components/atomic/Box';
import useDesktop from '../../../../hooks/useDesktop';
import Paper from '../../../../components/atomic/Paper';
import SearchField from '../../../../components/SearchField';
import Page from '../../../../components/layouts/WithHeader';
import CompanyList from '../../../../components/CompanyList';
import useFetchEffect from '../../../../hooks/useFetchEffect';
import ErrorContainer from '../../../../components/ErrorContainer';
import { getFilteredCompanies } from '../../../../utils/companies';
import { fetchCompanies as apiFetchCompanies } from '../../../../api/properties';

const PropertyCompanies = ({
  fetching,
  companies,
  setFetching,
  setCompanies,
  fetchingError,
  setFetchingError,
}) => {
  const isDesktop = useDesktop();
  const { boligmappaNumber } = useParams();
  const [searchQuery, setSearchQuery] = useState('');

  useFetchEffect({
    setFetchingFunction: setFetching,
    setFetchingErrorFunction: setFetchingError,

    onSuccess: (data) => setCompanies(data),
    conditionFunction: () => !companies && !fetching,
    apiFetchFunction: () => apiFetchCompanies(boligmappaNumber),
  });

  const filteredCompanies = () =>
    getFilteredCompanies({
      companies,
      searchQuery,
    });

  const onSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const renderContent = () => {
    if (fetchingError) {
      return (
        <ErrorContainer
          paddingTop={isDesktop}
          errorResponse={fetchingError}
          style={{ paddingTop: !isDesktop ? '15%' : undefined }}
        />
      );
    }

    return (
      <Paper visible={!isDesktop}>
        <Box pt={isDesktop ? 0 : 2}>
          <SearchField
            bordered
            value={searchQuery}
            onChange={onSearchChange}
            placeholder="Søk etter firmanavn"
            style={{ width: isDesktop ? '45%' : undefined }}
          />
        </Box>

        <Box mt={2}>
          <CompanyList
            fetching={fetching}
            searchQuery={searchQuery}
            companies={filteredCompanies()}
            requestFrom="Property"
          />
        </Box>
      </Paper>
    );
  };

  return (
    <Page
      minFullHeight
      noPadding={!isDesktop}
      headerTitle="Boligens fagfolk"
      lessMarginLeft={isDesktop}
      maxWidth="lg"
    >
      {renderContent()}
    </Page>
  );
};
const mapStateToProps = (state) => ({
  companies: sGetCompanies(state),
  fetching: sGetFetchingCompanies(state),
  fetchingError: sGetFetchingCompaniesError(state),
});

const mapDispatchToProps = (dispatch) => ({
  setFetching: (fetching) => acSetFetchingCompanies(fetching),
  setCompanies: (companies) => dispatch(acSetCompanies(companies)),
  setFetchingError: (fetchingError) =>
    acSetFetchingCompaniesError(fetchingError),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyCompanies);
