import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import BaseListItem from '../../../../../../../../components/atomic/ListItem';

const ListItem = ({
  classes,
  primaryText,
  secondaryText,
  status,
  actionComponent,
  ...props
}) => (
  <BaseListItem className={classes.listItem} {...props}>
    <div className={classes.leftContainer}>
      <div className={classes.textContainer}>
        <div className={classes.primaryText}>{primaryText}</div>
        <div className={classes.secondaryText}>{secondaryText}</div>
      </div>
    </div>
    <div>
      <div
        className={
          status === 'active' ? classes.activeLabel : classes.pendingLabel
        }
      >
        <strong>{status === 'active' ? 'AKTIV' : 'VENTER'}</strong>
      </div>
    </div>

    <div className={classes.rightContainer}>{actionComponent}</div>
  </BaseListItem>
);

export default withStyles(styles)(ListItem);
