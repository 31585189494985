import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Box from '../../atomic/Box';
import styles from './style';
import Add from '../../../assets/icons/Add';
import Typography from '../../atomic/Typography';
import {
  ATTACH_EVENT_EDIT_OPTION,
  ATTACH_ROOM_EDIT_OPTION,
} from '../../../utils/documents';
import {
  acSetShowAddRoom,
  acSetRoomReturnOnSuccess,
} from '../../../store/actions/rooms';
import {
  acSetShowAddEvent,
  acSetEventReturnOnSuccess,
} from '../../../store/actions/events';
import { acSetMultipleDocumentsSelected } from '../../../store/actions/documents';

const AttachRoomEventItem = ({
  classes,
  title = '',
  type,
  mulitipleDocumentsSelected,
  setShowAddRoom,
  setShowAddEvent,
  setRoomReturnOnSuccess,
  setEventReturnOnSuccess,
  onShowNewEntity,
  setMulitipleDocumentsSelected,
}) => {
  const handleForm = () => {
    if (mulitipleDocumentsSelected) setMulitipleDocumentsSelected(true);
    else setMulitipleDocumentsSelected(false);

    if (type === ATTACH_EVENT_EDIT_OPTION) {
      setShowAddEvent(true);
      setEventReturnOnSuccess(true);
    }
    if (type === ATTACH_ROOM_EDIT_OPTION) {
      setShowAddRoom(true);
      setRoomReturnOnSuccess(true);
    }

    onShowNewEntity(true);
  };

  return (
    <Box className={classes.buttonPane} onClick={handleForm}>
      <Add />
      <Typography className={classes.title}>{title}</Typography>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setShowAddRoom: (payload) => dispatch(acSetShowAddRoom(payload)),
  setShowAddEvent: (payload) => dispatch(acSetShowAddEvent(payload)),
  setRoomReturnOnSuccess: (payload) =>
    dispatch(acSetRoomReturnOnSuccess(payload)),
  setEventReturnOnSuccess: (payload) =>
    dispatch(acSetEventReturnOnSuccess(payload)),
  setMulitipleDocumentsSelected: (payload) =>
    dispatch(acSetMultipleDocumentsSelected(payload)),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(AttachRoomEventItem));
