const styles = (theme) => ({
  link: ({ disabled }) => ({
    textDecoration: 'none',
    color: disabled
      ? theme.palette.primary.greyLight
      : theme.palette.primary.main,
    cursor: disabled ? 'default' : 'pointer',
  }),
  underline: ({ disabled }) => ({
    textDecoration: disabled ? 'none' : 'underline',

    '&:hover': {
      textDecoration: disabled ? 'none' : 'underline',
    },
  }),
});

export default styles;
