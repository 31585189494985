import React from 'react';

const PrivateSharingMenuIcon = ({ ...props }) => (
  <svg
    viewBox="0 0 16.641 13.037"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <style>{`.privateSharingIconClass{fill:none;stroke:currentColor;stroke-miterlimit:10;stroke-width:0.65px;}`}</style>
    </defs>
    <g transform="translate(0.375 0.375)">
      <circle
        r="2.775"
        cx="2.775"
        cy="2.775"
        transform="translate(3.436)"
        className="privateSharingIconClass"
      />
      <path
        transform="translate(0 7.61)"
        className="privateSharingIconClass"
        d="M4.4,0H8.044a4.4,4.4,0,0,1,4.4,4.4v0a.281.281,0,0,1-.281.281H.281A.281.281,0,0,1,0,4.4v0A4.4,4.4,0,0,1,4.4,0Z"
      />
      <g transform="translate(11.091 2.962)">
        <line
          y2="5.176"
          transform="translate(2.588)"
          className="privateSharingIconClass"
        />
        <line
          x1="5.176"
          transform="translate(0 2.588)"
          className="privateSharingIconClass"
        />
      </g>
    </g>
  </svg>
);
export default PrivateSharingMenuIcon;
