import React from 'react';
import { withStyles } from '@material-ui/core';

import Box from '../../atomic/Box';
import Typography from '../../atomic/Typography';
import ArrowDown from '../../../assets/icons/ArrowDown';

const styles = (theme) => ({
  arrow: {
    width: '10px',
    marginLeft: '8px',
    fill: theme.palette.text.primary,
  },
});

const FilterItem = ({ name, classes, ...props }) => (
  <Box px={2} py={1} display="flex" alignItems="center" {...props}>
    <Typography>{name}</Typography>
    <ArrowDown className={classes.arrow} />
  </Box>
);

export default withStyles(styles)(FilterItem);
