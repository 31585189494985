import WetRoomImage from '../../assets/images/wetroom.png';
import ElectricalImage from '../../assets/images/electrical.png';
import OutdoorsImage from '../../assets/images/outdoor.png';
import OtherImage from '../../assets/images/other-work.png';
import WetRoomSuccessImage from '../../assets/images/1_nyvatrum.png';
import ElectricalSuccessImage from '../../assets/images/2_elektriske.png';
import OutdoorsSuccessImage from '../../assets/images/3_succesmelding.png';
import OtherSuccessImage from '../../assets/images/4_andet.png';

export const defaultState = {
  fetching: false,
  fetchingError: null,
  steps: [
    {
      id: 1,
      section: 'ROOMS',
      category: 'ROOMS_CREATED',
      title: 'Opprett rom',
      desc: 'Når du oppretter rom-struktur for din eiendom kan håndverkere legge dokumentasjonen automatisk inn på riktig sted. Smart!',
      action: 'Opprett rom',
      actionMore: 'Opprett flere rom',
      completed: false,
    },
    {
      id: 2,
      section: 'ROOMS',
      category: 'ROOMS_DETAILS',
      title: 'Detaljer om rom',
      desc: 'Et bilde sier mer enn tusen ord! Legg til bilder, rombeskrivelse og gi rommene personaliserte navn – så er det enkelt å ha oversikt. Før- og etterbilder av oppussing er en sikker vinner!',
      action: 'Oppgi detaljer',
      actionMore: 'Oppgi flere detaljer',
      completed: false,
    },
    {
      id: 3,
      section: 'EVENTS',
      category: 'WETROOMS',
      title: 'Arbeid på bad/våtrom',
      desc: 'Badet er boligens dyreste rom. Dersom du ikke har dokumentasjon for arbeid utført på bad/våtrom vil takstmann gi lav tilstandsgrad.',
      question: 'Har det blitt utført arbeid på bad/våtrom?',
      addMoreQuestion: 'Har du mer arbeid på bad/våtrom å registrere?',
      successCategoryText:
        'Dokumentasjon om arbeid utført på bad og våtrom er verdipapirer som hjelper deg ved eventuelle konflikter, garantisaker eller ved reparasjon.',
      successCategoryButtonText: 'Gå videre',
      successCategoryImageUrl: WetRoomSuccessImage,
      imageUrl: WetRoomImage,
      action: 'Registrer arbeid',
      actionMore: 'Registrer mer arbeid',
      completed: false,
    },
    {
      id: 4,
      section: 'EVENTS',
      category: 'ELECTRICAL',
      title: 'Arbeid på el-anlegg',
      desc: 'Du er pålagt å oppbevare dokumentasjon om arbeid utført på det elektriske anlegget. Ved manglende dokumentasjon kan el-tilsynet pålegge en kontroll av anlegget.',
      question: 'Har det blitt utført arbeid på det elektriske anlegget?',
      addMoreQuestion:
        'Har du mer arbeid på det elektriske anlegget å registrere?',
      successCategoryText:
        'Dokumentasjon for arbeid utført på det elektriske anlegget er av stor betydning for sikkerhet og skal følge boligen i hele levetiden til el-anlegget.',
      successCategoryButtonText: 'Gå videre',
      successCategoryImageUrl: ElectricalSuccessImage,
      imageUrl: ElectricalImage,
      action: 'Registrer arbeid',
      actionMore: 'Registrer mer arbeid',
      completed: false,
    },
    {
      id: 5,
      section: 'EVENTS',
      category: 'OUTDOORS',
      title: 'Arbeid utendørs/fasade',
      desc: 'Store strukturelle endringer som takarbeid, drenering, terrasse og påbygg er ofte kilde til konflikt i forbindelse med boligsalg – da er dokumentasjon ekstra viktig.',
      question: 'Har det blitt utført arbeid på terrasse/garasje/tak/fasade?',
      addMoreQuestion:
        'Har du mer arbeid på terrasse/garasje/tak/fasade å registrere?',
      successCategoryText:
        'Fasadeendringer og arbeid på tomt og grunn utendørs er ofte litt større jobber. Da er det lurt å ha dokumentasjonen på plass!',
      successCategoryButtonText: 'Gå videre',
      successCategoryImageUrl: OutdoorsSuccessImage,
      imageUrl: OutdoorsImage,
      action: 'Registrer arbeid',
      actionMore: 'Registrer mer arbeid',
      completed: false,
    },
    {
      id: 6,
      section: 'EVENTS',
      category: 'OTHER',
      title: 'Annet arbeid',
      desc: 'Over 800 000 boliger pusses opp hvert år. Nye kjøkken installeres, stuer males og varmepumper installeres. Arbeid som registreres i Boligmappa følger eiendommen – også ved eierskifte.',
      question: 'Har det blitt utført annet type arbeid på boligen?',
      addMoreQuestion: 'Har du mer annet type arbeid å registrere?',
      successCategoryText:
        'Arbeidet som registreres og dokumenteres i Boligmappa har verdi både for deg som nåværende eier, og ikke minst for fremtidige eiere. Du kan alltid legge til mer arbeid når det oppstår eller når du kommer på det. Boligens historikk bygges best over tid!',
      successCategoryButtonText: 'Ferdig',
      successCategoryImageUrl: OtherSuccessImage,
      imageUrl: OtherImage,
      action: 'Registrer arbeid',
      actionMore: 'Registrer mer arbeid',
      completed: false,
    },
    {
      id: 7,
      desc: 'Boligens historikk bygges over tid. Vi vil fortsette å videreutvikle denne funksjonen som hjelper deg å bygge din boligmappe.',
    },
  ],
};

export const SET_BYBF_FETCHING = 'SET_BYBF_FETCHING';
export const SET_BYBF_FETCHING_ERROR = 'SET_BYBF_FETCHING_ERROR';
export const SET_STEP_COMPLETED = 'SET_STEP_COMPLETED';
export const CLEAR_BYBF = 'CLEAR_BYBF';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_BYBF_FETCHING:
      return {
        ...state,
        fetching: action.payload,
      };
    case SET_BYBF_FETCHING_ERROR:
      return {
        ...state,
        fetchingError: action.payload,
      };
    case SET_STEP_COMPLETED: {
      const { category, completed } = action.payload;
      return {
        ...state,
        steps: state.steps.map((step) => {
          if (step.category === category) {
            return { ...step, completed };
          }
          return step;
        }),
      };
    }
    case CLEAR_BYBF:
      return defaultState;
    default:
      return state;
  }
};

export const sGetBYBFModule = (state) => state.bybf;
export const sGetBYBFFetching = (state) => sGetBYBFModule(state).fetching;
export const sGetBYBFFetchingError = (state) =>
  sGetBYBFModule(state).fetchingError;
export const sGetBYBFSteps = (state) => sGetBYBFModule(state).steps;
