import React, { useEffect } from 'react';
import BoligmappaPlusLanding from '@componentsV2/BoligmappaPlusLanding';
import { withStyles } from '@material-ui/core';
import useDesktop from '@hooksV2/useDesktop';
import useSnackbar from '../../../../hooks/useSnackbar';
import styles from './style';
import useDashboardLinkPrefix from '../../../../hooks/useDashboardLinkPrefix';
import matomo from '../../../../utils/matomo';
import { useBoligmappaPlusSubscriptionStatus } from '../../../../store/hooks/useBoligmappaPlusSubscriptionStatus';
import WithHeader from '../../../../components/layouts/WithHeader';
import { boligmappaPlusActionCallback } from '../../../../utils/boligmappaPlus';

const BoligmappaPlusPage = ({ classes }) => {
  const isDesktop = useDesktop();
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const { showErrorMessage } = useSnackbar();

  const isBoligmappaPlusSub = useBoligmappaPlusSubscriptionStatus();

  useEffect(() => {
    matomo.clickEvent({
      category: 'Boligmappa+',
      action: 'Rendered CTA',
      name: 'Rendered CTA on Boligmappa+ Landing Page',
    });
  }, []);

  const handleActionClick = async (type) => {
    await boligmappaPlusActionCallback(type, showErrorMessage);
  };

  return (
    <WithHeader topLevelLayout headerTitle="Boligmappa+" hideHeader={isDesktop}>
      <div className={classes.page}>
        <BoligmappaPlusLanding
          isBoligmappaPlusSub={isBoligmappaPlusSub}
          isDesktop={isDesktop}
          dashboardLinkPrefix={dashboardLinkPrefix}
          onActionClick={handleActionClick}
        />
      </div>
    </WithHeader>
  );
};

export default withStyles(styles)(BoligmappaPlusPage);
