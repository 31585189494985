import { makeStyles } from '@material-ui/core';

const sharedStyles = {
  textDecoration: 'none',
  padding: '6px 32px',
  fontSize: 13,
};

export const useStyles = makeStyles((theme) => ({
  filled: {
    backgroundColor: theme.palette.primary.darkGreen,
    color: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.darkGreen,
      textDecoration: 'none',
    },
    ...sharedStyles,
  },
  outlined: {
    color: theme.palette.primary.darkGreen,
    ...sharedStyles,
  },
  loader: {
    fill: theme.palette.primary.white,
    color: theme.palette.primary.white,
  },
}));
