import { makeStyles } from '@material-ui/core';

export const useConsentStyles = makeStyles(() => ({
  consentContainer: {
    textAlign: 'center',
    maxWidth: 320,
  },
  valueEstimateConsentText: {
    fontSize: '13px',
  },
  valueEstimateTextTop: {
    fontSize: '13px',
    fontWeight: 300,
  },
  valueEstimateTextBottom: {
    fontSize: '10px',
    fontWeight: 200,
  },
  consentButton: {
    fontSize: '13px',
    padding: '5px 50px',
    fontWeight: 300,
  },
  consentLink: {
    fontSize: '14px',
    fontWeight: 400,
    textDecoration: 'none',
  },
  moreLink: {
    fontSize: '14px',
    fontWeight: 700,
    textDecoration: 'none',
  },
}));
