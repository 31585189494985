import React from 'react';

import useDesktop from '../../../../hooks/useDesktop';

import Box from '../../../../components/atomic/Box';
import Link from '../../../../components/atomic/Link';
import Button from '../../../../components/atomic/Button';
import Typography from '../../../../components/atomic/Typography';

import Page from '../../../../components/layouts/PublicPage';

const Login = () => {
  const isDesktop = useDesktop();

  return (
    <Page
      hideHeader={isDesktop}
      showBackButton={false}
      headerTitle="Log inn"
      minFullHeight={isDesktop}
      lessMarginLeft={isDesktop}
      maxWidth="lg"
    >
      <Box mt={2} mb={4}>
        <Typography variant="h2" color="textPrimary">
          Visste du at…
        </Typography>
      </Box>

      <Typography variant="body1" color="textSecondary">
        Alle boliger har en unik Boligmappe. Ønsker du å se hva som er i
        Bolimappen for din bolig er det bare å logge seg inn å ta en titt. Du
        logger deg trygt inn med BankID.
      </Typography>

      <Box mt={6}>
        <Link to="/login">
          <Button
            size="large"
            color="primary"
            variant="contained"
            fullWidth={!isDesktop}
          >
            Logg inn i din Boligmappe
          </Button>
        </Link>
      </Box>
    </Page>
  );
};

export default Login;
