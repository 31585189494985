import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Image from '../../Image';
import Box from '../../atomic/Box';
import { isClickEvent } from '../../../utils/dom';
import useDesktop from '../../../hooks/useDesktop';
import placeholderImage from '../../../assets/images/placeholder.png';

const Slide = ({
  classes,
  onClick,
  editable,
  imageUrl,
  fullscreenMode,
  ...props
}) => {
  const isDesktop = useDesktop();

  const [touchStartEvent, setTouchStartEvent] = useState(null);

  const getContainerPaddings = () => {
    if (fullscreenMode && isDesktop) return { p: 8, pb: 9 };
    if (fullscreenMode && !isDesktop) return { px: 2, pt: 8, pb: 9 };
    return {};
  };

  const onTouchStart = (e) => {
    e.persist();
    setTouchStartEvent(e);
  };

  const onTouchEnd = (e) => {
    e.persist();

    if (isClickEvent(touchStartEvent, e)) {
      onClick(e);
    }
  };

  return (
    <Box
      height="100%"
      key={`image-carousel-${imageUrl}`}
      {...props}
      {...getContainerPaddings()}
    >
      <Image
        alt="room"
        src={imageUrl}
        onMouseUp={onTouchEnd}
        className={classes.image}
        onMouseDown={onTouchStart}
        fallbackSrc={placeholderImage}
      />
    </Box>
  );
};

export default withStyles(styles)(Slide);
