import styled from 'styled-components';

export const StyledLoyalty = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  color: #111111;
  max-width: 1160px;
  padding: 8px 0;
  margin: 0 auto;
  position: relative;

  @media (max-width: 959px) {
    padding: 48px 16px 176px;
  }

  h2 {
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    margin: 14px 0;
  }

  & > p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    margin: 0;
  }

  .back_section {
    font-size: 14px;

    a {
      color: #000;
      text-decoration: unset;
    }

    span {
      text-decoration: underline;
    }
  }

  .back_arrow_icon {
    font-size: 14px;
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }

  .loyalty_row {
    display: flex;
    align-items: center;
    column-gap: 32px;
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #e6e6e6;

    @media (max-width: 767px) {
      padding-bottom: 48px;
      margin-bottom: 48px;
    }
  }

  .loyalty_cta:not(.collapsed) {
    position: inherit;

    @media (max-width: 767px) {
      position: fixed;
    }
  }

  .loyalty_content {
    display: flex;
    flex-direction: column;
    row-gap: 52px;

    .loyalty_text {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }

    h2 {
      font-size: 24px;
      line-height: 37px;
      font-weight: 700;
      margin: 0;
    }

    p {
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      margin: 0;
    }

    .advantages_text {
      color: #356e3c;
      font-weight: 500;

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    .loyalty_img {
      max-width: 100%;
    }
  }

  .company_advantages {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 32px;
  }

  .divider {
    border: 1px solid #e6e6e6;
    width: 100%;
  }

  .company_box {
    padding: 24px;

    .feature_content {
      margin: unset;
      row-gap: 16px;
      max-width: 100%;

      h2 {
        font-size: 18px;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
      }

      p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-size: 14px;
        line-height: 21.5px;
        height: 4.5em;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .logo {
        height: 128px;
        object-fit: contain;
      }

      .link {
        font-size: 14px;
        line-height: 21.5px;
        font-weight: 400;
        color: #111111;

        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
  }
`;
