export const undefinedDocumentType = { id: 0, name: 'Udefinert' };

// TODO fetch document types from API
const documentTypes = [
  undefinedDocumentType,
  { id: 1, name: 'Sluttkontroll' },
  { id: 2, name: 'Fdv' },
  { id: 3, name: 'Samsvarserklæring' },
  { id: 4, name: 'Elkontroll' },
  { id: 5, name: 'Fasadetegning' },
  { id: 6, name: 'Plantegning' },
  { id: 7, name: 'Representative snitt' },
  { id: 8, name: 'Bebyggelsesplan' },
  { id: 9, name: 'Energiberegning' },
  { id: 10, name: 'Produktblad' },
  { id: 11, name: 'Serviceavtale' },
  { id: 13, name: 'Brukstillatelse' },
  { id: 14, name: 'Målebrev' },
  { id: 16, name: 'Ferdigattest' },
  { id: 17, name: 'Gjennomføringsplan' },
  { id: 18, name: 'Risikovurdering' },
  { id: 19, name: 'Trykkprøving' },
  { id: 20, name: 'Overtagelsesprotokoll' },
  { id: 21, name: 'Sikker vanninstallasjon' },
  { id: 22, name: 'NELFO 5 sikre' },
  { id: 23, name: 'Uavhengig kontroll' },
  { id: 24, name: 'NS3600' },
  { id: 25, name: 'Kursfortegnelse' },
  { id: 26, name: 'Egenerklæring' },
  { id: 27, name: 'Godkjente byggemeldinger' },
  { id: 28, name: 'Midlertidig brukstillatelse' },
  { id: 29, name: 'Varmepumpe' },
  { id: 30, name: 'Alarmanlegg' },
  { id: 31, name: 'Soneinndeling av lys/varme' },
  { id: 32, name: 'Styring av varme' },
  { id: 33, name: 'Tilstandsrapport for fellesdel' },
  { id: 34, name: 'Rapport fra feiervesenet' },
  { id: 35, name: 'Radonmåling' },
  { id: 36, name: 'Annet' },
  { id: 37, name: 'Brukerveiledning' },
  { id: 38, name: 'Faktura' },
  { id: 39, name: 'Fargekoder' },
  { id: 40, name: 'Kvittering' },
  { id: 41, name: 'Varmekabel' },
  { id: 42, name: 'Bilde' },
  { id: 43, name: 'KNX' },
  { id: 44, name: 'IFC' },
  { id: 45, name: 'BIM' },
  { id: 46, name: 'Fargekode NCS' },
  { id: 47, name: 'HMS' },
  { id: 48, name: 'Monteringsanvisning' },
  { id: 49, name: 'Boligmappa nettbutikk' },
  { id: 50, name: 'Garantibevis' },
  { id: 51, name: 'Tegning' },
  { id: 52, name: 'Planløsning' },
  { id: 53, name: 'Vannstopper' },
  { id: 54, name: 'Komfyrvakt' },
  { id: 55, name: 'Tilstandsrapport' },
  { id: 56, name: 'Salgsoppgave \nProspekt' },
  { id: 57, name: 'Takst' },
];

export default documentTypes;
