import React from 'react';

import Card from '../Card';
import Box from '../atomic/Box';
import Grid from '../atomic/Grid';
import SeeMoreLink from '../SeeMoreLink';
import Typography from '../atomic/Typography';
import ContentLoader from '../loaders/ContentLoader';
import matomo from '../../utils/matomo';

const NewCardList = ({
  classes,
  fetching,
  items,
  moreEvent,
  title,
  link,
  isFinance,
  ...props
}) => {
  const renderContent = () => {
    const onClickSeeMore = () => {
      if (isFinance) {
        matomo.clickEvent({
          category: 'Value Estimate and Finances',
          name: 'See More Owners and Finances',
          action: 'See Owners and Finances',
        });
      } else {
        matomo.clickEvent({
          category: 'Value Estimate and Finances',
          name: 'See More Easements',
          action: 'See Easements',
        });
      }
    };

    if (fetching) {
      return (
        <Box>
          <Box mb={4}>
            <ContentLoader width={160} height={25} />
          </Box>

          <Grid container spacing={1}>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <ContentLoader width={60} height={15} />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
              <ContentLoader width={120} height={15} />
            </Grid>
          </Grid>

          <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <ContentLoader width={60} height={15} />
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <ContentLoader width={120} height={15} />
              </Grid>
            </Grid>
          </Box>

          <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <ContentLoader width={60} height={15} />
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <ContentLoader width={120} height={15} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      );
    }

    return (
      <Box
        height="70%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h3" marginTop={false} fontWeight={800}>
            {title}
          </Typography>
          {Array.isArray(items) &&
            items.map((item) => (
              <Grid container spacing={1} key={item.value + item.label}>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <strong>{item.label}</strong>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                  {item.value}
                </Grid>
              </Grid>
            ))}
        </Box>

        {link && (
          <Box pt={2} textAlign="right">
            <SeeMoreLink to={link} onClick={onClickSeeMore} />
          </Box>
        )}
      </Box>
    );
  };

  return <Card {...props}>{renderContent()}</Card>;
};

export default NewCardList;
