import React, { useEffect, useState } from 'react';
import { useFlag } from '@unleash/proxy-client-react';
import { connect } from 'react-redux';
import { acSetInsuranceRemainingDays } from '../../../../../store/actions/documents';
import {
  getDocumentsCreatedAfterDate,
  getProfessionalDocuments,
  getRecentlylAddedUnInsuredProfessionalDocument,
} from '../../../../../utils/documents';
import PurchasingInsuranceCTA from '../../../../../components/PurchasingInsuranceCTA';
import PurchasingInsuranceExperimentCTA from '../../../../../components/PurchasingInsuranceExperimentCTA';
import PropertyImagesSection from './PropertyImagesSection';
import Grid from '../../../../../components/atomic/Grid';
import useDesktop from '../../../../../hooks/useDesktop';
import { DocumentationActionsSection } from './DocumentationActionsSection';
import BuildYourBoligmappeSection from './BuildYourBoligmappeSection';
import { PropertyInfoSection } from './PropertyInfoSection';
import { PropertyDocumentsSection } from './PropertyDocumentsSection';
import { PropertyCompaniesSection } from './PropertyCompaniesSection';
import OtovoInfoSection from './OtovoInforSection';
import { UserInterviewSection } from './UserInterviewSection';
import { PropertyReferralSection } from './PropertyReferralSection';
import { getPropertyType } from '../../../../../utils/properties';
import { useActiveProperty } from '../../../../../store/hooks';
import {
  enableHjemlaFinancialPage,
  enableOtovo,
  enableUserInterviews,
  uxSignalStudyId,
  uxSignalMode,
  uxSignalScriptURL,
} from '../../../../../config';
import Box from '../../../../../components/atomic/Box';
import Link from '../../../../../components/atomic/Link';
import useDashboardLinkPrefix from '../../../../../hooks/useDashboardLinkPrefix';
import useScript from '../../../../../hooks/useScript';
import EventExperimentCTASection from './EventExperimentCTASection';
import BoligmappePlusSection from './BoligmappaPlusSection';
import { isNativeEnv } from '../../../../../utils/nativeBridge';
import { useBoligmappaPlusSubscriptionStatus } from '../../../../../store/hooks/useBoligmappaPlusSubscriptionStatus';

const Overview = ({
  documents,
  insurancePaidDocuments,
  setInsuranceRemainingDays,
}) => {
  const isDesktop = useDesktop();
  const [landTypeProperty, setLandTypeProperty] = useState();
  const [showInsuranceCTA, setShowInsuranceCTA] = useState(false);
  const [activeStudy, setActiveStudy] = useState(true);
  const [pendingDaycount, setPendingDaycount] = useState(0);
  const { property } = useActiveProperty();
  const isNative = isNativeEnv();
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const enableInsurance = useFlag('boligeier.insurance.page');
  const enableEventExperimentCTA = useFlag('boligeier.renovation.document.cta');
  const enableBoligmappaPlus = useFlag('boligeier.boligmappaPlus');
  const enableInsuranceExperiment = useFlag(
    'boligeier.help.insurance.cta.experiment'
  );

  const boligmappaPlusSubscriptionStatus =
    useBoligmappaPlusSubscriptionStatus();

  useScript(true, uxSignalScriptURL);

  useEffect(() => {
    if (getPropertyType(property) === 'Eiendom/tomt uten bygning') {
      setLandTypeProperty(true);
    }
  }, []);

  useEffect(() => {
    if (documents && documents.length > 0) {
      const sevenDaysBack = new Date();
      sevenDaysBack.setDate(sevenDaysBack.getDate() - 7);
      const recentDocuments = getDocumentsCreatedAfterDate(
        documents,
        sevenDaysBack
      );
      const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
      const recentlyAddProfessionalDocs =
        getProfessionalDocuments(recentDocuments);
      if (
        recentlyAddProfessionalDocs &&
        recentlyAddProfessionalDocs.length > 0
      ) {
        if (insurancePaidDocuments && insurancePaidDocuments.length > 0) {
          const recentlyAddedUnInsuredDoc =
            getRecentlylAddedUnInsuredProfessionalDocument(
              recentlyAddProfessionalDocs,
              insurancePaidDocuments
            );
          if (recentlyAddedUnInsuredDoc) {
            setShowInsuranceCTA(true);
            const diffDays = Math.round(
              (new Date() -
                new Date(recentlyAddedUnInsuredDoc[0].createdDate)) /
                oneDay
            );
            setInsuranceRemainingDays(7 - diffDays);
            setPendingDaycount(7 - diffDays);
          } else {
            setShowInsuranceCTA(false);
            setInsuranceRemainingDays(0);
          }
        } else {
          setShowInsuranceCTA(true);
          const proffDocuments = recentlyAddProfessionalDocs.sort(
            (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
          );
          const diffDays = Math.round(
            (new Date() - new Date(proffDocuments[0].createdDate)) / oneDay
          );
          setInsuranceRemainingDays(7 - diffDays);
          setPendingDaycount(7 - diffDays);
        }
      } else {
        setInsuranceRemainingDays(0);
      }
    }
  }, [documents]);

  if (enableOtovo === 'true') {
    return (
      <>
        {enableHjemlaFinancialPage === 'true' && (
          <Box mb={5}>
            <Link to={`${dashboardLinkPrefix}/financials/hjemla`}>
              <strong>Hjemla Financials Test Page</strong>
            </Link>
          </Box>
        )}

        <Grid container spacing={isDesktop ? 6 : 2}>
          {enableBoligmappaPlus &&
            !boligmappaPlusSubscriptionStatus &&
            !isNative && (
              <BoligmappePlusSection maxWidth smallVersion={showInsuranceCTA} />
            )}
          {isDesktop && <PropertyImagesSection />}
          <PropertyDocumentsSection />
          {isDesktop && <PropertyInfoSection />}
          {enableUserInterviews === 'true' && activeStudy ? (
            <UserInterviewSection
              studyId={uxSignalStudyId}
              mode={uxSignalMode}
              scriptUrl={uxSignalScriptURL}
            />
          ) : (
            <PropertyReferralSection />
          )}
          <OtovoInfoSection />
          {enableUserInterviews === 'true' && activeStudy && (
            <PropertyReferralSection />
          )}
          <BuildYourBoligmappeSection
            maxWidh={enableUserInterviews === 'true' && !activeStudy}
          />
          <DocumentationActionsSection />
          <PropertyCompaniesSection />
          {!isDesktop && <PropertyImagesSection />}
          {!isDesktop && <PropertyInfoSection />}
        </Grid>
      </>
    );
  }
  return (
    <Grid container spacing={isDesktop ? 6 : 2}>
      {enableEventExperimentCTA && <EventExperimentCTASection />}
      {enableBoligmappaPlus &&
        !boligmappaPlusSubscriptionStatus &&
        !isNative && (
          <BoligmappePlusSection maxWidth smallVersion={showInsuranceCTA} />
        )}
      {enableInsuranceExperiment && showInsuranceCTA && (
        <PurchasingInsuranceExperimentCTA
          insurancePaidDocuments={insurancePaidDocuments}
          pendingDaycount={pendingDaycount}
        />
      )}
      {enableInsurance && showInsuranceCTA && !enableInsuranceExperiment && (
        <PurchasingInsuranceCTA
          insurancePaidDocuments={insurancePaidDocuments}
        />
      )}
      {isDesktop && <PropertyImagesSection />}
      <PropertyDocumentsSection />
      {isDesktop && <PropertyInfoSection />}
      {enableUserInterviews === 'true' && activeStudy ? (
        <UserInterviewSection
          studyId={uxSignalStudyId}
          mode={uxSignalMode}
          scriptUrl={uxSignalScriptURL}
          getUxSignalStatus={(status) => {
            if (status !== null) setActiveStudy(status);
          }}
        />
      ) : (
        <PropertyReferralSection />
      )}
      <DocumentationActionsSection />
      {enableUserInterviews === 'true' && activeStudy && (
        <PropertyReferralSection />
      )}
      {/* {!landTypeProperty && <PropertyReferralSection />} */}
      {!landTypeProperty && (
        <BuildYourBoligmappeSection
          maxWidh={enableUserInterviews === 'false'}
        />
      )}
      <PropertyCompaniesSection />
      {!isDesktop && <PropertyImagesSection />}
      {!isDesktop && <PropertyInfoSection />}
    </Grid>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setInsuranceRemainingDays: (days) =>
    dispatch(acSetInsuranceRemainingDays(days)),
});

export default connect(null, mapDispatchToProps)(Overview);
