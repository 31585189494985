const styles = (theme) => ({
  container: {
    display: 'flex',
    padding: '20px 0',
    flexDirection: 'row',
    borderBottom: `1px solid ${theme.colors.whiteDarker}`,

    '&:last-child': {
      borderBottom: 'none',
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  fileNameLoader: {
    marginBottom: '5px',
  },
  iconContainer: {
    display: 'flex',
    marginRight: '15px',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

export default styles;
