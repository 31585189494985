import React, { useEffect, useState } from 'react';
import {
  StyledDialog,
  StyledOverlay,
} from '@componentsV2/CancelSubDialog/styles';

const CancelSubDialog = ({ isShown, onHide, onSubCancel, children }) => {
  const [isChecked, setIsChecked] = useState(false);
  const resetDialog = () => {
    setIsChecked(false);
  };
  useEffect(() => {
    if (!isShown) {
      resetDialog();
    }
  }, [isShown]);
  return (
    <StyledOverlay className={isShown && 'show'}>
      <StyledDialog>
        {children}
        <div className="row">
          <input
            id="subCancelConfirm"
            className="sub_checkbox"
            type="checkbox"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          <label htmlFor="subCancelConfirm">
            Ja, jeg forstår at jeg mister tilgangen til innhold fra Boligmappa+
            etter at nåværende, betalte abonnementperiode utløper.
          </label>
        </div>
        <div className="button_row">
          <button
            className="filled_button"
            type="button"
            onClick={() => isChecked && onSubCancel()}
            disabled={!isChecked}
          >
            Avslutt abonnement
          </button>
          <button
            className="filled_button"
            type="button"
            onClick={() => onHide()}
          >
            Avbryt
          </button>
        </div>
      </StyledDialog>
    </StyledOverlay>
  );
};

export default CancelSubDialog;
