import React from 'react';
import { withStyles } from '@material-ui/core';

import PropertyCard from '../../../../componentsV2/PropertyCard';

export const styles = () => ({});

const PropertyList = ({ properties }) =>
  Array.isArray(properties) &&
  properties.map((property) => (
    <PropertyCard property={property} key={`property-${property.id}`} />
  ));

export default withStyles(styles)(PropertyList);
