import React from 'react';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router';

import Show from './Show';
import Edit from './Edit';
import Route from '../../../../../components/atomic/Route';
import { sGetRooms } from '../../../../../store/reducers/rooms';
import useFetchEffect from '../../../../../hooks/useFetchEffect';
import { sGetEvents } from '../../../../../store/reducers/events';
import { sGetDocuments } from '../../../../../store/reducers/documents';
import { acUpdateDocument } from '../../../../../store/actions/documents';
import { acSyncRoomsDocuments } from '../../../../../store/actions/rooms';
import { acSyncEventsDocuments } from '../../../../../store/actions/events';
import { fetchDocument as apiFetchDocument } from '../../../../../api/documents';
import { fetchPersonalDocument as apiFetchPersonalDocument } from '../../../../../api/propertyowners';
import { sGetPersonalDocuments } from '../../../../../store/reducers/personalDocuments';
import { acUpdatePersonalDocument } from '../../../../../store/actions/personalDocuments';

/**
 * Parent component for edit/show single document pages.
 * It allows to store document data and prevent double
 * fetching upon switching from "show document" view
 * to "edit document" view and vice versa
 */
const Document = ({
  rooms,
  events,
  documents,
  personalDocuments,
  updatePersonalDocument,
  updateDocument,
  syncRoomsDocuments,
  syncEventsDocuments,
}) => {
  const { documentId } = useParams();
  const location = useLocation();
  const { isPersonalDocuments, isFromPersonal } = location?.state || {};

  const {
    fetching,
    fetchingError,
    data: document,
    setData: setDocument,
  } = useFetchEffect({
    apiFetchFunction: () =>
      !isPersonalDocuments
        ? apiFetchDocument(documentId)
        : apiFetchPersonalDocument(documentId),
  });

  const onDocumentUpdated = (updatedDocument) => {
    setDocument(updatedDocument);

    if (!isPersonalDocuments) {
      // Only update document in store if there are documents loaded.
      // Otherwise updated document will be fetched along with all other documents.
      if (documents) {
        updateDocument(updatedDocument);
      }

      if (rooms) {
        const { roomIds } = updatedDocument;

        syncRoomsDocuments({
          roomIds,
          documentId,
          document: updatedDocument,
        });
      }

      if (events) {
        const { eventIds } = updatedDocument;

        syncEventsDocuments({
          eventIds,
          documentId,
          document: updatedDocument,
        });
      }
    } else if (personalDocuments) {
      updatePersonalDocument(updatedDocument);
    }
  };

  return (
    <>
      <Route
        exact
        path="/dashboard/:boligmappaNumber/documents/:documentId/edit"
      >
        <Edit
          document={document}
          fetching={fetching}
          fetchingError={fetchingError}
          onDocumentUpdated={onDocumentUpdated}
        />
      </Route>

      <Route exact path="/personal-documents/:documentId">
        <Show
          document={document}
          fetching={fetching}
          fetchingError={fetchingError}
          onDocumentUpdated={onDocumentUpdated}
          isPersonalDocument={isPersonalDocuments}
          isFromPersonal={isFromPersonal}
        />
      </Route>

      <Route exact path="/dashboard/:boligmappaNumber/documents/:documentId">
        <Show
          document={document}
          fetching={fetching}
          fetchingError={fetchingError}
          onDocumentUpdated={onDocumentUpdated}
          isPersonalDocument={isPersonalDocuments}
          isFromPersonal={isFromPersonal}
        />
      </Route>
    </>
  );
};

const mapStateToProps = (state) => ({
  rooms: sGetRooms(state),
  events: sGetEvents(state),
  documents: sGetDocuments(state),
  personalDocuments: sGetPersonalDocuments(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateDocument: (document) => dispatch(acUpdateDocument(document)),
  updatePersonalDocument: (document) =>
    dispatch(acUpdatePersonalDocument(document)),
  syncRoomsDocuments: (payload) => dispatch(acSyncRoomsDocuments(payload)),
  syncEventsDocuments: (payload) => dispatch(acSyncEventsDocuments(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Document);
