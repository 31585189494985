import React from 'react';

const FilePdfIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      d="M0 2C0 0.90625 0.875 0 2 0H7V4C7 4.5625 7.4375 5 8 5H12V9.5H5.5C4.375 9.5 3.5 10.4062 3.5 11.5V16H2C0.875 16 0 15.125 0 14V2ZM12 4H8V0L12 4ZM5.5 11H6.5C7.4375 11 8.25 11.8125 8.25 12.75C8.25 13.7188 7.4375 14.5 6.5 14.5H6V15.5C6 15.7812 5.75 16 5.5 16C5.21875 16 5 15.7812 5 15.5V14V11.5C5 11.25 5.21875 11 5.5 11ZM6.5 13.5C6.90625 13.5 7.25 13.1875 7.25 12.75C7.25 12.3438 6.90625 12 6.5 12H6V13.5H6.5ZM9.5 11H10.5C11.3125 11 12 11.6875 12 12.5V14.5C12 15.3438 11.3125 16 10.5 16H9.5C9.21875 16 9 15.7812 9 15.5V11.5C9 11.25 9.21875 11 9.5 11ZM10.5 15C10.75 15 11 14.7812 11 14.5V12.5C11 12.25 10.75 12 10.5 12H10V15H10.5ZM13 11.5C13 11.25 13.2188 11 13.5 11H15C15.25 11 15.5 11.25 15.5 11.5C15.5 11.7812 15.25 12 15 12H14V13H15C15.25 13 15.5 13.25 15.5 13.5C15.5 13.7812 15.25 14 15 14H14V15.5C14 15.7812 13.75 16 13.5 16C13.2188 16 13 15.7812 13 15.5V13.5V11.5Z"
      fill="#EF4836"
    />
  </svg>
);

export default FilePdfIcon;
