import React, { useEffect } from 'react';

import Link from '../atomic/Link';
import { isBrowserEdge } from '../../utils/dom';
import { edgeUserNotifiedKey } from '../../config';
import localStorage from '../../utils/localStorage';
import useHeaderSnackbar from '../../hooks/useHeaderSnackbar';

const EdgeImageUploadWarn = () => {
  const { showSnackbar } = useHeaderSnackbar();

  useEffect(() => {
    const isLoggedInWithEdge = isBrowserEdge();

    const isUserAlreadyNotified = !!localStorage.get(edgeUserNotifiedKey);

    if (isLoggedInWithEdge && !isUserAlreadyNotified) {
      showSnackbar({
        variant: 'info',
        onClose: () => localStorage.set(edgeUserNotifiedKey, 'true'),
        content: (
          <small>
            Nettleseren må{' '}
            <Link
              underline
              inheritColor
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.microsoft.com/en-us/edge"
            >
              oppdateres
            </Link>{' '}
            for å kunne ta i bruk last opp bilde funksjonen. Vi støtter Edge 79
            eller nyere. Alternativt kan du benytte en annen nettleser, for
            eksempel{' '}
            <Link
              underline
              inheritColor
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/chrome/"
            >
              Chrome.
            </Link>
          </small>
        ),
      });
    }
  }, []);

  return null;
};

export default EdgeImageUploadWarn;
