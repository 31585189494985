import React from 'react';

const RadonIcon = (props) => (
  <svg
    width={20}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <style>{`.radonIcon{fill:transparent;stroke:currentColor;stroke-miterlimit:10;stroke-width:1px;}`}</style>
    </defs>
    <path
      className="radonIcon"
      d="M18 20.134v-10M12 20.134v-16M6 20.134v-6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RadonIcon;
