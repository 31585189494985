import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Box from '../../../../atomic/Box';
import TableCell from '../../../../atomic/TableCell';
import ArrowDown from '../../../../../assets/icons/ArrowDown';

const TableHeaderCell = ({
  name,
  column,
  classes,
  clickable = true,
  ...props
}) => (
  <TableCell className={classes.root} {...props}>
    <Box display="flex" alignItems="top">
      <span>{name}</span>

      {clickable && (
        <Box pt="5px">
          <ArrowDown className={classes.arrow} />
        </Box>
      )}
    </Box>
  </TableCell>
);

export default withStyles(styles)(TableHeaderCell);
