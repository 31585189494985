import HomeIcon from '../assets/icons/Home';
import MoreIcon from '../assets/icons/More';
import LoginIcon from '../assets/icons/Login';
import HomeIconActive from '../assets/icons/HomeActive';
import { enableAddOn } from '../config';
import { experimentTemplateData } from '../views/PrivateRoutes/Dashboard/ExperimentTemplate/util';
import FileIcon from '../assets/icons/FileIcon';
import VerdiEconomy from '../assets/icons/VerdiEconomy';
import Finance from '../assets/icons/Finance';
import BoligmappaFolder from '../assets/icons/BoligmappaFolder';
import BoligmappaFolderActive from '../assets/icons/BoligmappaFolderActive';
import CalendarIcon from '../assets/icons/CalendarIcon';
import CalendarActiveIcon from '../assets/icons/CalendarActiveIcon';
import FileActiveIcon from '../assets/icons/FileActiveIcon';
import VerdiEconomyActive from '../assets/icons/VerdiEconomyActive';
import Wrench from '../assets/icons/Wrench';
import WrenchActive from '../assets/icons/WrenchActive';
import PersonShelter from '../assets/icons/PersonShelter';
import ContractTemplate from '../assets/icons/ContractTemplate';
import ContractTemplateActive from '../assets/icons/ContractTemplateActive';

export const privateNavbarMenu = [
  {
    title: 'Hjem',
    icon: HomeIcon,
    activeIcon: HomeIconActive,
    link: 'home',
  },
  {
    title: 'Hendelser',
    icon: CalendarIcon,
    activeIcon: CalendarActiveIcon,
    link: 'history',
  },
  {
    title: 'Dokumenter',
    icon: FileIcon,
    activeIcon: FileActiveIcon,
    link: 'documents',
  },
  {
    title: 'Håndverkere',
    icon: Wrench,
    activeIcon: WrenchActive,
    link: 'more/find-professional',
    secondary: true,
    desktopOnly: true,
    experimental: false,
  },
  {
    title: 'Rom',
    icon: PersonShelter,
    activeIcon: (props) => PersonShelter({ fill: '#164144', ...props }),
    secondary: true,
    link: 'rooms',
  },
  {
    title: 'Verdi og økonomi',
    icon: VerdiEconomy,
    activeIcon: VerdiEconomyActive,
    secondary: true,
    link: 'financials',
    isDisabledInApp: true,
    desktopOnly: true,
  },
  {
    title: 'Finansiering',
    icon: Finance,
    activeIcon: Finance,
    secondary: true,
    link: 'loyalty/company/sparebank-1-sorost-norge',
    isDisabledInApp: true,
    desktopOnly: true,
  },
  ...(enableAddOn === 'true'
    ? experimentTemplateData
        .map((value) => {
          if (value.menuItem.isEnable)
            return {
              title: value.menuItem.title,
              icon: value.menuItem.icon,
              activeIcon: value.menuItem.activeIcon,
              desktopOnly: true,
              link: `addon/${value.experimentSlug}`,
              isNew: value.menuItem.isNew,
              hideInPrivateProperty: value.menuItem.hideInPrivateProperty,
              hideOnLands: value.menuItem.hideOnLands,
              hideOnGarageBuilding: value.menuItem.hideOnGarageBuilding,
            };
          return null;
        })
        .filter((value) => value)
    : []),
  {
    title: 'Boligmappa+',
    icon: BoligmappaFolder,
    activeIcon: BoligmappaFolderActive,
    isDisabledInApp: true,
    link: 'boligmappa-plus',
  },
  {
    title: 'Mer',
    icon: MoreIcon,
    activeIcon: MoreIcon,
    moreAction: true,
    mobileOnly: true,
    notificationLabel: true,
  },
];

export const publicNavbarMenu = [
  {
    title: 'Hjem',
    icon: HomeIcon,
    activeIcon: HomeIconActive,
    link: 'home',
  },
  {
    title: 'Hendelser',
    icon: CalendarIcon,
    activeIcon: CalendarActiveIcon,
    link: 'history',
  },
  {
    title: 'Dokumenter',
    icon: FileIcon,
    activeIcon: FileActiveIcon,
    link: 'documents',
  },
  {
    title: 'Rom',
    icon: PersonShelter,
    activeIcon: (props) => PersonShelter({ fill: '#164144', ...props }),
    secondary: true,
    link: 'rooms',
  },
  {
    title: 'Logg inn',
    icon: LoginIcon,
    activeIcon: LoginIcon,
    link: 'login',
  },
];

export const personalAreaNavbarMenu = [
  {
    title: 'Hjem',
    icon: HomeIcon,
    activeIcon: HomeIconActive,
    link: 'home',
  },
  {
    title: 'Mine dokumenter',
    icon: FileIcon,
    activeIcon: FileActiveIcon,
    link: 'documents',
  },
  {
    title: 'Kontrakter og maler',
    icon: ContractTemplate,
    activeIcon: ContractTemplateActive,
    link: 'contract-templates',
  },
  {
    title: 'Boligmappa+',
    icon: BoligmappaFolder,
    activeIcon: BoligmappaFolderActive,
    isDisabledInApp: true,
    link: 'boligmappa-plus',
  },
];
