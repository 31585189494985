import BathImage from '../../assets/images/create-event-suggestion/bath.jpeg';
import BedroomImage from '../../assets/images/create-event-suggestion/bedroom.jpg';
import WarmPumpImage from '../../assets/images/create-event-suggestion/warmpump.jpeg';
import KitchenImage from '../../assets/images/create-event-suggestion/new_kitchen.png';
import SmartHomeImage from '../../assets/images/create-event-suggestion/smarthouse.jpeg';

export const cycles = 2;

export const slides = [
  {
    title: 'Nytt kjøkken',
    subtitle: 'Kjøkken',
    src: KitchenImage,
  },
  {
    title: 'Oppussing bad',
    subtitle: 'Bad',
    src: BathImage,
  },
  {
    title: 'Malte soverommet',
    subtitle: 'Hovedsoverom',
    src: BedroomImage,
  },
  {
    title: 'Installerte varmepumpe',
    subtitle: 'Hele eiendommen, Stue, Teknisk Rom',
    src: WarmPumpImage,
  },
  {
    title: 'Smarthusstyring fra Futurehome',
    subtitle: 'Hele eiendommen',
    src: SmartHomeImage,
  },
];
