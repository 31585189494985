import React, { useContext } from 'react';
import { Grid, withStyles, useMediaQuery } from '@material-ui/core';

import styles from './style';
import Box from '../../../../../components/atomic/Box';
import useDesktop from '../../../../../hooks/useDesktop';
import Typography from '../../../../../components/atomic/Typography';
import ProgressButton from '../../../../../components/ProgressButton';
import PublicShareIcon from '../../../../../assets/icons/PublicShare';
import PublicShareImage from '../../../../../assets/images/PublicSharingImage.png';
import StatusImage from '../../../../../assets/images/statusImage.png';
import Image from '../../../../../components/Image';
import { FileUploadContext } from '../../../../../contexts/fileUpload';
import { AddNewButton } from '../../../../../components/Buttons';

const Intro = ({ onContinue, classes }) => {
  const isDesktop = useDesktop();
  const { onFileUploadClick } = useContext(FileUploadContext);
  const displayLargeTable = useMediaQuery('(min-width:1300px)');

  if (isDesktop) {
    return (
      <>
        <Grid container={isDesktop}>
          <Grid item md={isDesktop && 12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div
                  className={
                    isDesktop ? classes.publicShareH2 : classes.publicShareH2
                  }
                >
                  <Typography variant="h2" className={classes.publicShareH2}>
                    {isDesktop ? 'Offentlig deling' : null}
                  </Typography>
                </div>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <p className={classes.publicShareContent}>
                    Ved å dele din eiendom med takstmann eller potensielle
                    boligkjøpere, så kan de få et innblikk i din boligmappe og
                    boligens tilstand for en valgt periode. Mottakere får kun
                    lesetilgang, og kan ikke gjøre endringer i din boligmappe.
                    Du generere en unik lenke som du kan sende til aktuelle
                    mottakere eller dele i andre kanaler.
                  </p>

                  <div>
                    <ProgressButton
                      onClick={onContinue}
                      requestInProgress={false}
                      type="submit"
                      color="primary"
                      variant="contained"
                      className={
                        displayLargeTable
                          ? classes.publicShareProcessButton
                          : classes.publicShareProcessButtonsmall
                      }
                    >
                      <PublicShareIcon className={classes.buttonIcon} />
                      Ny offentlig deling
                    </ProgressButton>
                  </div>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Image
                  alt="publicshare"
                  src={PublicShareImage}
                  className={classes.publicshareimage}
                  fallbackSrc={PublicShareImage}
                />
              </Grid>
              <Grid item md={displayLargeTable ? 4 : 6}>
                <Image
                  alt="publicsharestatus"
                  src={StatusImage}
                  className={
                    displayLargeTable
                      ? classes.publicshareimagestatus
                      : classes.publicshareimagestatussmall
                  }
                  fallbackSrc={StatusImage}
                />
              </Grid>
              <Grid
                item
                md={6}
                className={displayLargeTable ? classes.statuscontents : null}
              >
                <Box>
                  <Typography className={classes.statuscontent}>
                    Hva slags dokumentasjon trenger takstmann tilgang til i
                    forbindelse med tilstandsrapporten?
                  </Typography>
                  <p className={classes.publicShareContent}>
                    Eksempler på dokumentasjon er informasjon om arbeid utført
                    av håndverkere på din bolig, informasjon om radonmåling- og
                    nivåer, om det er foretatt el-kontroll de siste 5 årene.
                  </p>
                  <Box>
                    <AddNewButton
                      text="Last opp dokument"
                      onClick={onFileUploadClick}
                      className={
                        isDesktop
                          ? classes.proceedButtonpublicshare
                          : classes.proceedButton
                      }
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
  return (
    <>
      <Grid container={!isDesktop}>
        <Grid item md={!isDesktop && 12}>
          <Grid container rowSpacing={1} direction="column">
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  alt="publicshare"
                  src={PublicShareImage}
                  className={classes.publicshareimageMobile}
                  fallbackSrc={PublicShareImage}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <p className={classes.publicShareContentMobile}>
                  Ved å dele din eiendom med takstmann eller potensielle
                  boligkjøpere, så kan de få et innblikk i din boligmappe og
                  boligens tilstand for en valgt periode. Mottakere får kun
                  lesetilgang, og kan ikke gjøre endringer i din boligmappe. Du
                  generere en unik lenke som du kan sende til aktuelle mottakere
                  eller dele i andre kanaler.
                </p>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <ProgressButton
                    onClick={onContinue}
                    requestInProgress={false}
                    type="submit"
                    color="primary"
                    variant="contained"
                    className={classes.publicShareProcessButtonMobile}
                  >
                    <PublicShareIcon className={classes.buttonIcon} /> Ny
                    offentlig deling
                  </ProgressButton>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  alt="publicsharestatus"
                  src={StatusImage}
                  className={classes.publicshareimagestatusMobile}
                  fallbackSrc={StatusImage}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.statuscontentsMobile}>
              <Typography className={classes.statuscontentMobile}>
                Hva slags dokumentasjon trenger takstmann tilgang til i
                forbindelse med tilstandsrapporten?
              </Typography>
              <p>
                Eksempler på dokumentasjon er informasjon om arbeid utført av
                håndverkere på din bolig, informasjon om radonmåling- og nivåer,
                om det er foretatt el-kontroll de siste 5 årene.
              </p>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <AddNewButton
                  text="Last opp dokument"
                  onClick={onFileUploadClick}
                  className={classes.proceedButtonpublicshareMobile}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(Intro);
