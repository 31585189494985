import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Box from '../atomic/Box';
import Grid from '../atomic/Grid';
import { gridItemProps } from '../../utils/grid';

const GridItem = ({
  label,
  classes,
  children,
  padding = 2,
  contentHeight,
  ...props
}) => (
  <Grid item {...gridItemProps} {...props}>
    <Box className={classes.contentContainer}>
      <Box className={classes.content} padding={padding}>
        {children}
      </Box>
    </Box>

    <Box px={padding} pb={padding}>
      {label}
    </Box>
  </Grid>
);

export default withStyles(styles)(GridItem);
