import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { sGetDocuments } from '../../../../store/reducers/documents';
import styles from './style';
import Overview from './Overview';
import Box from '../../../../components/atomic/Box';
import Route from '../../../../components/atomic/Route';
import Hidden from '../../../../components/atomic/Hidden';
import PageLayout from '../../../../components/layouts/WithHeader';
import { sGetActiveProperty } from '../../../../store/reducers/properties';
import { acSetDocumentSearchText } from '../../../../store/actions/documents';
import { ErrorMessage } from './ErrorMessage';
import PropertyFinancials from '../PropertyFinancials';
import { getUserTransactions } from '../../../../api/boligmappaPlus';
import { getPaidSinglePayments } from '../../../../utils/boligmappaPlus';
import { acSetUserTransactions } from '../../../../store/actions/userTransactions';
import MarketingConsentDialog from './MarketingConsentDialog';
import localStorage from '../../../../utils/localStorage';

export const Home = ({
  activeProperty,
  fetchingProperties,
  fetchingPropertiesError,
  setSearchText,
  setUserTransactions,
  documents,
}) => {
  const match = useRouteMatch();
  const financialsUrl = `${match.url}/financials`;
  const [loading, setLoading] = useState(true);
  const [userPayments, setUserPayments] = useState([]);
  const [insurancePaidDocuments, setInsurancePaidDocuments] = useState([]);

  useEffect(() => {
    setSearchText('');
  }, []);

  const fetchUserTransactions = async () => {
    const [error, response] = await getUserTransactions();
    setLoading(false);
    if (!error && response) {
      setUserPayments(getPaidSinglePayments(response.responses.singlePayments));
      setUserTransactions(response.responses);
    }
  };
  useEffect(() => {
    fetchUserTransactions();
  }, []);
  useEffect(() => {
    if (userPayments && userPayments.length) {
      setInsurancePaidDocuments(
        userPayments
          .map((el) => {
            const documentId = el.paymentInfo
              ? el.paymentInfo.professionalDocumentId
              : null;
            return documentId;
          })
          .filter(Boolean)
      );
    }
  }, [userPayments]);

  const [
    isConsentRequestDialogSeenStorage,
    setIsConsnetRequestDialogSeenStorage,
  ] = useState(localStorage.get('consentRequestSeen'));

  const onConsentRequestDialogClose = () => {
    localStorage.set('consentRequestSeen', true);
    setIsConsnetRequestDialogSeenStorage(true);
  };

  if (fetchingPropertiesError) {
    return <ErrorMessage fetchingPropertiesError={fetchingPropertiesError} />;
  }

  return (
    <>
      <PageLayout
        minFullHeight
        homeLayout
        fetchingProperties={fetchingProperties}
        fetchingPropertiesError={fetchingPropertiesError}
      >
        {fetchingPropertiesError && (
          <ErrorMessage fetchingPropertiesError={fetchingPropertiesError} />
        )}
        {!fetchingPropertiesError && (
          <Hidden mdUp>
            <Route exact path={match.url}>
              <Box p={1}>
                <Overview
                  loading={loading}
                  documents={documents}
                  insurancePaidDocuments={insurancePaidDocuments}
                  property={activeProperty}
                  fetchingProperty={fetchingProperties}
                  fetchingPropertyError={fetchingPropertiesError}
                />
              </Box>
            </Route>

            <Route exact path={financialsUrl}>
              <PropertyFinancials />
            </Route>
          </Hidden>
        )}
        {/* Desktop only */}
        <Hidden smDown>
          {!fetchingPropertiesError && (
            <Box>
              <Overview
                documents={documents}
                insurancePaidDocuments={insurancePaidDocuments}
                property={activeProperty}
                fetchingProperty={fetchingProperties}
                fetchingPropertyError={fetchingPropertiesError}
              />
            </Box>
          )}
          {fetchingPropertiesError && (
            <ErrorMessage fetchingPropertiesError={fetchingPropertiesError} />
          )}
        </Hidden>
      </PageLayout>
      {!isConsentRequestDialogSeenStorage && (
        <MarketingConsentDialog
          isOpen={!isConsentRequestDialogSeenStorage}
          onClose={onConsentRequestDialogClose}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  activeProperty: sGetActiveProperty(state),
  documents: sGetDocuments(state),
});

const mapDispatchToProps = (dispatch) => ({
  setSearchText: (searchText) => dispatch(acSetDocumentSearchText(searchText)),
  setUserTransactions: (userTransactions) =>
    dispatch(acSetUserTransactions(userTransactions)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Home));
