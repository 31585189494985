import { unleashAPIKey, unleashBaseUrl } from '../config';

export const unleashConfig = {
  url: `${unleashBaseUrl}/api/frontend`,
  clientKey: unleashAPIKey,
  refreshInterval: 3600,
  appName: 'Boligeier-PWA',
  environment: '',
};

export const isCompatibleVersion = (currentVersion, targetVersion) => {
  if (
    typeof currentVersion !== 'string' ||
    !parseInt(currentVersion, 10) ||
    typeof targetVersion !== 'string' ||
    !parseInt(targetVersion, 10)
  ) {
    return false;
  }
  const version = currentVersion.split('.').map(Number);
  const target = targetVersion.split('.').map(Number);
  for (let i = 0; i < version.length; i += 1) {
    if (version[i] > target[i]) {
      return true;
    }
    if (version[i] < target[i]) {
      return false;
    }
  }
  return true;
};
