/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useActiveProperty } from '../../store/hooks';
import { acSetActiveProperty } from '../../store/actions/properties';
import { getValueEstimateFromApi, fetchPropertyFromApi } from '../properties';

export function useValueEstimate() {
  const dispatch = useDispatch();
  const [responseData, setResponseData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const { property } = useActiveProperty();

  const executeFetch = async () => {
    if (!property) return;
    try {
      setIsLoading(true);
      const response = await getValueEstimateFromApi(property.boligmappaNumber);

      if (response.status !== 200) {
        throw Error(response);
      }
      const valueEstimate = response.data?.response;
      setResponseData(valueEstimate);

      const updatedPropertyResponse = await fetchPropertyFromApi(
        property.boligmappaNumber
      );
      const updatedProperty = updatedPropertyResponse.data.response;

      dispatch(
        acSetActiveProperty({
          ...updatedProperty,
          valueEstimate,
        })
      );
    } catch (e) {
      setError(e.response.data);
    } finally {
      setIsLoading(false);
    }
  };

  return { data: responseData, error, isLoading, executeFetch };
}
