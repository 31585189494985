import React from 'react';

const Backspace = ({ width = '24', height = '24', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.85435 12.7572L1 8L3.85435 3.24275C3.89877 3.16871 3.96161 3.10744 4.03675 3.0649C4.11188 3.02236 4.19675 3 4.28309 3H13.5C13.6326 3 13.7598 3.05268 13.8536 3.14645C13.9473 3.24021 14 3.36739 14 3.5V12.5C14 12.6326 13.9473 12.7598 13.8536 12.8536C13.7598 12.9473 13.6326 13 13.5 13H4.28309C4.19675 13 4.11188 12.9776 4.03675 12.9351C3.96161 12.8926 3.89877 12.8313 3.85435 12.7572V12.7572Z"
      stroke="#13142A"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 6.5L7 9.5"
      stroke="#13142A"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 9.5L7 6.5"
      stroke="#13142A"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Backspace;
