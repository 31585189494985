import React, { forwardRef } from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import TableCell from '../../atomic/TableCell';
import Box from '../../atomic/Box';
import Typography from '../../atomic/Typography';
import ArrowDownFilter from '../../../assets/icons/ArrowDownFilter';

const EditOptionHeader = forwardRef(
  ({ classes, name, icon, onClick, directAction, ...props }, ref) => {
    return (
      <TableCell ref={ref} {...props}>
        <Box display="flex">
          <Box
            onClick={onClick}
            className={`${classes.hoverHeaderBox} ${
              name === 'Slett' ? classes.deleteAction : ''
            }`}
          >
            <Box sx={{ marginRight: 5 }}>{icon}</Box>
            <Typography fontSize={13} fontWeight={600}>
              {name}
            </Typography>
            {!directAction && (
              <Box>
                <ArrowDownFilter className={classes.arrow} />
              </Box>
            )}
          </Box>
        </Box>
      </TableCell>
    );
  }
);

export default withStyles(styles)(EditOptionHeader);
