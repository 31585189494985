const styles = (theme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    verticalAlign: 'top',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  arrow: {
    width: '10px',
    marginLeft: '8px',
    fill: theme.palette.text.primary,
  },
});

export default styles;
