import { getColor, getBackgroundColor } from './util';

export default (theme) => ({
  control: (styles) => ({
    ...styles,
    boxShadow: 'none',
    borderColor: theme.palette.primary.greyLighter,

    ':hover': {
      ...styles[':hover'],
      borderColor: theme.palette.primary.greyLighter,
    },
  }),
  option: (styles, state) => ({
    ...styles,
    color: getColor(state, theme),
    background: getBackgroundColor(state, theme),

    ':active': {
      ...styles[':active'],
      background: theme.palette.primary.greyLighter,
    },
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    padding: '8px',
    paddingLeft: '10px',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    color: theme.palette.primary.white,
    background: theme.palette.primary.green,
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    right: '1px',
    padding: '8px',
    paddingLeft: '10px',
    position: 'relative',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: theme.palette.primary.white,
    background: theme.palette.primary.green,

    ':active': {
      ...styles[':active'],
      color: theme.palette.primary.white,
      background: theme.palette.primary.green,
    },

    ':hover': {
      ...styles[':hover'],
      color: theme.palette.primary.white,
      background: theme.palette.primary.green,
    },
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    display: 'flex',
    paddingRight: '8px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  groupHeading: () => ({
    color: theme.palette.primary.main,
    fontSize: '13px',
    fontWeight: 600,
    paddingLeft: '13px',
    paddingBottom: '5px',
  }),
});
