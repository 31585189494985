import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Box from '../../../components/atomic/Box';
import Typography from '../../../components/atomic/Typography';

const Detail = ({ classes, label, children }) => (
  <Box display="flex" alignItems="top">
    <Box>
      <Typography fontWeight={400} className={classes.label}>
        {label}
      </Typography>

      <div
        className={`${classes.childrenItems} word-break ${classes.textDetailsWrapper}`}
      >
        {children}
      </div>
    </Box>
  </Box>
);

export default withStyles(styles)(Detail);
