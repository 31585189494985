import { css } from '@linaria/core';
import { fonts } from '@style/vars';
import { sizes } from '@style/vars/sizes';
import { borders, colors } from '../../../style/vars';

export const primaryButtonTransition =
  'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms';

export const primaryButton = css`
  position: relative;
  height: 40px;
  padding: 12px 24px;
  background: none;
  border: ${borders.default};
  border-radius: ${borders.radius.full};
  font-size: ${fonts.size.xxs};
  transition: ${primaryButtonTransition};
  color: ${colors.textColor};

  svg {
    stroke: ${colors.textColor};
    width: 16px;
    height: 16px;
  }
  /* 
   * Hides the buttons content
   * Makes sure the button dimensions stays the same
   */
  .isLoading {
    visibility: hidden;
  }

  .buttonLoaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &:hover,
  &:focus-visible
   {
    border: ${borders.defaultActive};
  }

  &:focus-visible {
    outline: ${borders.outlineLight};
  }

  &:active {
    background: ${colors.button.background_active};
    outline: none;
  }

  &:disabled {
    color: ${colors.textColor_disabled};
    background: ${colors.disabledBackground};
    border: none;
    outline: none;
    cursor: not-allowed;

    svg {
      stroke: ${colors.textColor_disabled};
    }
  }

  &.filled {
    border: none;
    color: ${colors.textLight};
    background: ${colors.darkColor};

    svg {
      stroke: ${colors.textLight};
    }

    &:hover {
      background: ${colors.button.filled.background_hover};
    }

    &:focus-visible {
      background: ${colors.button.filled.background_hover};
      outline: ${borders.outlineLight};
    }

    &:active {
      background: ${colors.button.filled.background_active};
      outline: none;
    }

    &:disabled {
      color: ${colors.textColor_disabled};
      background: ${colors.disabledBackground};

      svg {
        stroke: ${colors.textColor_disabled};
      }
    }
  }

  &.full-width {
    width: 100%;
  }

  &.big {
    height: 48px;
    font-size: ${fonts.size.xs};
  }

  &.small {
    height: 32px;
    padding: 8px 16px;

    svg {
      width: ${sizes.iconSmallSize};
    }
  }
`;

export default primaryButton;
