import moment from 'moment';
import matomo from './matomo';
import { boligmappaPlusSinglePayment } from '../api/boligmappaPlus';
import BoligmappaPlusFocusOnLoyalty from '../assets/images/bm+focus_on_loyalty.jpeg';
import BoligmappaPlusValueEstimate from '../assets/images/bm+valueEstimate.jpeg';

export const getActiveSubscriptions = (subscriptions) =>
  (subscriptions || []).filter((el) => el.status === 'ACTIVE');

export const getPaidSinglePayments = (singlePayments) =>
  (singlePayments || []).filter((el) => el.status === 'PAID');

export const getFormattedTransactionsData = (transactions) =>
  transactions.length
    ? transactions
        .map((el) => {
          const type =
            (el.userSinglePaymentId && 'userSinglePaymentId') ||
            (el.userSubscriptionId && 'userSubscriptionId');
          const orderNumber = el.userSinglePaymentId || el.userSubscriptionId;
          const nextBillingDate =
            el.nextBillingDate &&
            moment(el.nextBillingDate).format('DD.MM.YYYY');
          const expireDate =
            el.expireDate && moment(el.expireDate).format('DD.MM.YYYY');
          const { amount, createdDate } = el;
          const transaction = {
            type,
            orderNumber,
            amount: amount && amount / 100,
            createdDate:
              createdDate && moment(createdDate).format('DD.MM.YYYY'),
            nextBillingDate,
            expireDate,
            cancelled: nextBillingDate === expireDate,
          };
          return transaction;
        })
        .filter((el) => {
          if (el.expireDate) {
            if (new Date() > new Date(el.expireDate)) {
              return true;
            }
            return false;
          }
          return true;
        })
    : [];

export const boligmappaPlusActionCallback = async (type, showErrorMessage) => {
  const redirectUrl = window.location.href;
  const [error, response] = await boligmappaPlusSinglePayment(
    type,
    redirectUrl
  );
  if (!error && response) {
    matomo.goalEvent({ goalType: type });
    window.location.href = response.response.agreementFrontendUrl;
  }
  if (error && error.response?.data?.code === 'HAS_PENDING_SUBSCRIPTION') {
    matomo.clickEvent({
      category: 'Boligmappa+',
      action: 'Payment stalled due to pending subscription',
    });
    showErrorMessage(
      `Du har allerede påbegynt en betaling som ikke ble fullført. Vennligst vent i 20 minutter før du prøver på nytt.`
    );
  }
};

export const boligmappaPlusHomePageVariants = {
  early_bird: {
    desktopHeadline: 'Sommertilbud på Boligmappa+',
    mobileHeadline: 'Sommertilbud på Boligmappa+',
    desktopMainText:
      'Med Boligmappa+ får du en rekke nye og spennende funksjoner og gode tilbud. Nå kun 199,- per år.',
    mobileMainText:
      'Med Boligmappa+ får du en rekke nye og spennende funksjoner og gode tilbud. Nå kun 199,- per år.',
    desktopCta1: 'Få sommertilbud!',
    matomoRenderActionName: 'pluss-banner-early-bird-rendered',
    matomoCta1ActionName: 'pluss-banner-early-bird-click-1',
    matomoCta2ActionName: 'pluss-banner-early-bird-click-2',
  },
  focus_on_loyalty: {
    desktopImageSrc: BoligmappaPlusFocusOnLoyalty,
    mobileImageSrc: BoligmappaPlusFocusOnLoyalty,
    desktopHeadline: 'Gode tilbud med Boligmappa+',
    mobileHeadline: 'Gode tilbud med Boligmappa+',
    desktopCta1: 'Se tilbudene',
    desktopCta2: 'Les mer om Boligmappa+',
    postfixLinkCta1: '/boligmappa-plus/loyalty',
    desktopMainText:
      'Med Boligmappa+ får du skikkelig gode tilbud på varer og tjenester tilknyttet din bolig. Sjekk det ut!',
    mobileMainText:
      'Med Boligmappa+ får du skikkelig gode tilbud på varer og tjenester tilknyttet din bolig. Sjekk det ut!',
    matomoRenderActionName: 'pluss-banner-loyalty-rendered',
    matomoCta1ActionName: 'pluss-banner-loyalty-click-1',
    matomoCta2ActionName: 'pluss-banner-loyalty-click-2',
  },
  value_estimate: {
    desktopImageSrc: BoligmappaPlusValueEstimate,
    mobileImageSrc: BoligmappaPlusValueEstimate,
    desktopHeadline: 'Følg prisutviklingen til din bolig!',
    mobileHeadline: 'Følg prisutviklingen til din bolig!',
    desktopCta1: 'Se verdiestimat',
    desktopCta2: 'Les mer om Boligmappa+',
    postfixLinkCta1: '/financials',
    postfixLinkCta2: '/boligmappa-plus',
    desktopMainText:
      'Med Boligmappa+ får du markedsledende, oppdaterte verdiestimat på din bolig, og mye mer!',
    mobileMainText:
      'Med Boligmappa+ får du markedsledende, oppdaterte verdiestimat på din bolig, og mye mer!',
    matomoRenderActionName: 'pluss-banner-value-rendered',
    matomoCta1ActionName: 'pluss-banner-value-click-1',
    matomoCta2ActionName: 'pluss-banner-value-click-2',
  },
};
