import { pdfjs } from 'react-pdf';

/**
 * This is required to get the PDF worker to work
 * Apparently "Modern builds will be introduced in 6.0.0."
 * https://github.com/wojtekmaj/react-pdf/issues/1004
 *
 * It's kept here in a separate file so that we can mock it in jest.setup.js
 */
const SetupPDFWorker = () => {
  if (typeof window !== 'undefined' && 'Worker' in window) {
    pdfjs.GlobalWorkerOptions.workerPort = new Worker(
      new URL('pdfjs-dist/legacy/build/pdf.worker', import.meta.url),
    );
  }
};

export default SetupPDFWorker;
