import styled from 'styled-components';

export const StyledHeaderNav = styled.div`
  display: flex;
  align-items: center;
  max-width: 1115px;
  margin: 0 auto;

  .sidebar_nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 24px;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .sidebar_item {
    padding: 0;

    .list_item_icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .active_icon {
      display: none;
    }

    .sidebar-link {
      display: flex;
      align-items: center;
      column-gap: 6px;
      font-size: 14px;
      line-height: 28px;
      font-weight: 500;
      color: #717171;
      text-decoration: none;
      padding: 5px 0 3px;
      border-bottom: 3px solid transparent;
      transition: 0.3s;
    }

    &.active {
      .sidebar-link {
        font-weight: 700;
        color: #164144;
        border-color: #164144;
      }
    }

    &:hover {
      .sidebar-link {
        color: #164144;
        border-color: #164144;
      }
    }

    &.active,
    &:hover {
      .list_item_icon {
        display: none;
      }
      .active_icon {
        display: flex;
      }
    }

    p {
      margin: 0;
    }
  }
`;
