import React from 'react';

const EventBar = ({ width = 14, height = 10 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 10"
    width={width}
    height={height}
  >
    <path
      d="M0.5 0.859375C0.5 0.529297 0.753906 0.25 1.10938 0.25H11.2656C11.5957 0.25 11.875 0.529297 11.875 0.859375C11.875 1.21484 11.5957 1.46875 11.2656 1.46875H1.10938C0.753906 1.46875 0.5 1.21484 0.5 0.859375ZM2.125 4.92188C2.125 4.5918 2.37891 4.3125 2.73438 4.3125H12.8906C13.2207 4.3125 13.5 4.5918 13.5 4.92188C13.5 5.27734 13.2207 5.53125 12.8906 5.53125H2.73438C2.37891 5.53125 2.125 5.27734 2.125 4.92188ZM11.875 8.98438C11.875 9.33984 11.5957 9.59375 11.2656 9.59375H1.10938C0.753906 9.59375 0.5 9.33984 0.5 8.98438C0.5 8.6543 0.753906 8.375 1.10938 8.375H11.2656C11.5957 8.375 11.875 8.6543 11.875 8.98438Z"
      fill="#164144"
    />
  </svg>
);

export default EventBar;
