import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import {
  acUpdateEvent,
  acSyncEventsRooms,
} from '../../../../../store/actions/events';

import Images from './Images';
import ShowRoom from './Show';
import EditRoomDialog from './Edit';
import { getEventId } from '../../../../../utils/events';
import Route from '../../../../../components/atomic/Route';
import { areIdsEqual } from '../../../../../utils/strings';
import { sGetRooms } from '../../../../../store/reducers/rooms';
import useFetchEffect from '../../../../../hooks/useFetchEffect';
import { acUpdateRoom } from '../../../../../store/actions/rooms';
import { sGetEvents } from '../../../../../store/reducers/events';
import { fetchRoom as apiFetchRoom } from '../../../../../api/rooms';
import { getRoomEvents, getRoomId } from '../../../../../utils/rooms';
import Button from '../../../../../components/atomic/Button';
import ArrowBackward from '../../../../../assets/icons/ArrowBackward';
import useDesktop from '../../../../../hooks/useDesktop';

/**
 * Parent component for edit/show single room pages.
 * It allows to store room data and prevent double
 * fetching upon switching from "show room" view
 * to "edit room" view and vice versa
 */
const Room = ({ rooms, events, updateRoom, updateEvent, syncEventsRooms }) => {
  const { roomId } = useParams();
  const isDesktop = useDesktop();
  const history = useHistory();
  const storedRoom = (rooms || []).find((room) =>
    areIdsEqual(getRoomId(room), roomId)
  );

  const {
    fetching,
    fetchingError,
    setData: setRoom,
    data: fetchedRoom,
  } = useFetchEffect({
    initialFetchingState: !storedRoom,
    dependencies: [storedRoom, roomId],
    apiFetchFunction: () => apiFetchRoom(roomId),
    conditionFunction: ([_storedRoom, _roomId]) => _roomId && !_storedRoom,
  });

  const room = storedRoom || fetchedRoom;

  const onBackButtonClick = () => {
    history.goBack();
  };

  const onRoomUpdated = (updatedRoom) => {
    setRoom(updatedRoom);

    // Only update room in store if there are rooms loaded.
    // Otherwise updated room will be fetched along with all other rooms.
    if (rooms) {
      updateRoom(updatedRoom);
    }

    if (events) {
      const { eventIds } = updatedRoom;

      syncEventsRooms({
        roomId,
        room: updatedRoom,
        eventIds:
          eventIds || (getRoomEvents(updatedRoom) || []).map(getEventId),
      });
    }
  };
  const onEventUpdated = (updatedEvent) => {
    if (events) {
      updateEvent(updatedEvent);
    }
  };

  return (
    <>
      {isDesktop && (
        <Button
          style={{
            backgroundColor: 'transparent',
            fontSize: '14px',
            margin: '15px 0',
          }}
          startIcon={<ArrowBackward width={14} height={14} />}
          onClick={() => onBackButtonClick()}
        >
          Gå tilbake
        </Button>
      )}
      <Route exact path="/dashboard/:boligmappaNumber/rooms/:roomId">
        <ShowRoom
          room={room}
          fetching={fetching}
          onRoomUpdated={onRoomUpdated}
          fetchingError={fetchingError}
          onEventUpdated={onEventUpdated}
          enableImageUpload
        />
      </Route>
      <Route exact path="/dashboard/:boligmappaNumber/rooms/:roomId/images">
        <Images
          room={room}
          fetching={fetching}
          onRoomUpdated={onRoomUpdated}
          fetchingError={fetchingError}
        />
      </Route>

      <EditRoomDialog
        room={room}
        fetching={fetching}
        onRoomUpdated={onRoomUpdated}
        fetchingError={fetchingError}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  rooms: sGetRooms(state),
  events: sGetEvents(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateRoom: (room) => dispatch(acUpdateRoom(room)),
  updateEvent: (event) => dispatch(acUpdateEvent(event)),
  syncEventsRooms: (payload) => dispatch(acSyncEventsRooms(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Room);
