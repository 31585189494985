const style = {
  informationIcon: {
    margin: '0 0 13px 17px',
    color: '#8D8D8D',
  },
  textAreaDesktop: {
    margin: '20px 0 50px 0',
  },
  adminShareForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleArea: {
    top: '94px',
    left: '292px',
    maxWidth: '200px',
    height: '42px',
  },
  titleAreaMobile: {
    top: '16px',
    left: '2px',
    width: '200px',
    height: '50px',
  },
  textField: {
    margin: '22px 0 5px 0',
    width: '350px',
  },
  form: {
    margin: '25px 0 0 0',
    diplay: 'grid',
    justifyContent: 'left',
  },
  introContent: {
    textAlign: 'left',
    top: '166px',
    left: '292px',
    maxWidth: '510px',
    height: '92px',
    opacity: '1',
  },
  introContentMobile: {
    textAlign: 'left',
    maxWidth: '100%',
  },
  dialogContent: {
    marginTop: '-20px',
    textAlign: 'left',
    fontSize: '15px',
  },
  formContent: {
    padding: '13px',
  },
  titleLoader: {
    margin: '10px 0 55px',
  },
  listItemLoader: {
    margin: '35px 0',
    '&:last-child': {
      margin: '35px 0 30px',
    },
  },
  privateShareImage: {
    top: '94px',
    left: '848px',
    width: '344px',
    height: '250px',
    marginTop: '20px',
  },
  container: {
    display: 'flex',
  },
  detailUseCase: {
    color: '#254E52',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  useCaseTitle: {
    font: 'normal normal bold 18px/28px Sofia Pro',
    color: '#254E52',
    top: '507px',
    left: '750px',
    width: '436px',
    height: '23px',
  },
};
export default style;
