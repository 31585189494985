const styles = (theme) => ({
  root: {
    display: 'flex',
    borderRadius: '4px',
    alignItems: 'center',
    padding: '3px 2px 3px 5px',
    // marginRight: theme.spacing(0.5),
    color: theme.palette.primary.darkBlack,
    background: '#D1EDE8',
    fontWeight: '500',
    fontSize: '12px',
    height: '30px',
  },
  label: {
    marginRight: theme.spacing(0.5),
    paddingLeft: '5px',
  },
  closeIcon: {
    width: '12px',
    color: theme.palette.primary.darkBlack,
  },
});

export default styles;
