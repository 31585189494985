import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import {
  acSetCommitments,
  acSetFetchingCommitments,
  acSetFetchingCommitmentsError,
} from '../../../../store/actions/commitments';

import {
  acSetRights,
  acSetFetchingRights,
  acSetFetchingRightsError,
} from '../../../../store/actions/rights';

import {
  fetchRights as apiFetchRights,
  fetchCommitments as apiFetchCommitments,
} from '../../../../api/properties';

import {
  sGetRights,
  sGetFetchingRights,
} from '../../../../store/reducers/rights';

import {
  sGetCommitments,
  sGetFetchingCommitments,
} from '../../../../store/reducers/commitments';

import useDesktop from '../../../../hooks/useDesktop';
import Paper from '../../../../components/atomic/Paper';
import Page from '../../../../components/layouts/WithHeader';
import useFetchEffect from '../../../../hooks/useFetchEffect';
import PropertyRight from '../../../../modules/PropertyRight';
import PropertyCommitment from '../../../../modules/PropertyCommitment';
import useDashboardLinkPrefix from '../../../../hooks/useDashboardLinkPrefix';

const PropertyDetails = ({
  commitments,
  setCommitments,
  rights,
  setRights,
  fetchingCommitments,
  setFetchingCommitments,
  setFetchingCommitmentsError,
  fetchingRights,
  setFetchingRights,
  setFetchingRightsError,
}) => {
  const isDesktop = useDesktop();
  const { boligmappaNumber } = useParams();
  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const commitment =
    !fetchingCommitments && Array.isArray(commitments) && commitments.length > 0
      ? commitments[0]
      : null;

  const showCommitment =
    fetchingCommitments ||
    (!fetchingCommitments &&
      Array.isArray(commitments) &&
      commitments.length > 0);

  const commitmentsLink = `${dashboardLinkPrefix}/details/commitments`;
  const showCommitmentsLink =
    Array.isArray(commitments) && commitments.length > 1;

  const right =
    !fetchingRights && Array.isArray(rights) && rights.length > 0
      ? rights[0]
      : null;

  const showRight =
    fetchingRights ||
    (!fetchingRights && Array.isArray(rights) && rights.length > 0);

  const rightsLink = `${dashboardLinkPrefix}/details/rights`;
  const showRightsLink = Array.isArray(rights) && rights.length > 1;

  useFetchEffect({
    conditionFunction: () => !rights,
    apiFetchFunction: () => apiFetchRights(boligmappaNumber),

    onFetchStart: () => setFetchingRights(true),
    onFetchComplete: () => setFetchingRights(false),

    onSuccess: (data) => setRights(data),
    onError: (error) => setFetchingRightsError(error),
  });

  useFetchEffect({
    conditionFunction: () => !commitments,
    apiFetchFunction: () => apiFetchCommitments(boligmappaNumber),

    onFetchComplete: () => setFetchingCommitments(false),
    onFetchStart: () => setFetchingCommitmentsError(null),

    onSuccess: (data) => setCommitments(data),
    onError: (error) => setFetchingCommitmentsError(error),
  });

  return (
    <Page
      minFullHeight
      noPadding={!isDesktop}
      headerTitle="Detaljer"
      hideHeader={isDesktop}
      lessMarginLeft={isDesktop}
      maxWidth="lg"
    >
      {showRight && (
        <>
          <br />
          <Paper visible={!isDesktop}>
            <PropertyRight
              showType
              showTitle
              link={rightsLink}
              right={right}
              showLink={showRightsLink}
              fetching={fetchingRights}
            />
          </Paper>
        </>
      )}

      {showCommitment && (
        <>
          <br />
          <Paper visible={!isDesktop}>
            <PropertyCommitment
              showType
              showTitle
              link={commitmentsLink}
              commitment={commitment}
              showLink={showCommitmentsLink}
              fetching={fetchingCommitments}
            />
          </Paper>
        </>
      )}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  commitments: sGetCommitments(state),
  rights: sGetRights(state),
  fetchingCommitments: sGetFetchingCommitments(state),
  fetchingRights: sGetFetchingRights(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCommitments: (commitments) => dispatch(acSetCommitments(commitments)),
  setFetchingCommitments: (fetching) =>
    dispatch(acSetFetchingCommitments(fetching)),
  setFetchingCommitmentsError: (error) =>
    dispatch(acSetFetchingCommitmentsError(error)),
  setRights: (rights) => dispatch(acSetRights(rights)),
  setFetchingRights: (fetching) => dispatch(acSetFetchingRights(fetching)),
  setFetchingRightsError: (error) => dispatch(acSetFetchingRightsError(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails);
