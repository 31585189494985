/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { OverviewGridItem, OverviewBox } from './components';
import Grid from '../../../../../components/atomic/Grid';
import PropertyEconomy from '../../../../../modules/PropertyEconomy';
import PropertyRightsCommitments from '../../../../../modules/PropertyRightsCommitments';
import * as hooks from '../../../../../api/hooks';
import { useActiveProperty } from '../../../../../store/hooks';
import * as finance from '../../../../../utils/financials';
import useDashboardLinkPrefix from '../../../../../hooks/useDashboardLinkPrefix';
import useDesktop from '../../../../../hooks/useDesktop';

export function PropertyRightsAndEconomySection({ hideValues }) {
  const { property, propertyOwners } = useActiveProperty();

  const isDesktop = useDesktop();

  const financials = hooks.useFetchFinancials(property);

  const rights = hooks.useFetchRights(property);

  const commitments = hooks.useFetchCommitments(property);

  const dashboardLinkPrefix = useDashboardLinkPrefix();

  if (
    // eslint-disable-next-line no-use-before-define
    !shouldDisplayEconomy(financials) &&
    // eslint-disable-next-line no-use-before-define
    !shouldDisplayRightsAndCommitments(rights, commitments)
  ) {
    return null;
  }

  if (financials.error) {
    return (
      <OverviewBox>
        <PropertyRightsCommitments
          height="100%"
          rights={rights.data}
          commitments={commitments.data}
          fetchingRights={rights.isLoading}
          link={`${dashboardLinkPrefix}/details`}
          fetchingCommitments={commitments.isLoading}
        />
      </OverviewBox>
    );
  }

  return (
    <OverviewBox>
      <Grid container spacing={isDesktop ? 6 : 2}>
        <OverviewGridItem>
          <PropertyEconomy
            height="100%"
            financials={financials.data}
            error={financials.error}
            propertyOwners={propertyOwners}
            fetching={financials.isLoading || !financials.data}
            link={!financials.error && `${dashboardLinkPrefix}/economy`}
            hideValues={hideValues}
          />
        </OverviewGridItem>
        <OverviewGridItem>
          <PropertyRightsCommitments
            height="100%"
            rights={rights.data}
            commitments={commitments.data}
            fetchingRights={rights.isLoading}
            link={`${dashboardLinkPrefix}/details`}
            fetchingCommitments={commitments.isLoading}
          />
        </OverviewGridItem>
      </Grid>
    </OverviewBox>
  );
}

function shouldDisplayEconomy(financials) {
  return (
    financials.isLoading ||
    (!financials.isLoading &&
      !financials.error &&
      (finance.getLoanDetails(financials.data) ||
        finance.getOwnershipHistory(financials.data) ||
        finance.getPropertyOwnershipDetails(financials.data)))
  );
}

function shouldDisplayRightsAndCommitments(rights, commitments) {
  return !rights.isLoading && !commitments.isLoading;
}
