import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import { getEventDescription } from '../../utils/events';
import Typography from '../../components/atomic/Typography';
import ContentLoader from '../../components/loaders/ContentLoader';

const EventDescription = ({ event, classes, fetching }) => (
  <div>
    <Typography variant="h3">
      {fetching && <ContentLoader width={100} height={20} />}
      {!fetching && 'Beskrivelse'}
    </Typography>

    <div className="word-break">
      {fetching && (
        <>
          <ContentLoader className={classes.lineLoading} />
          <ContentLoader width="90%" className={classes.lineLoading} />
          <ContentLoader width="80%" className={classes.lineLoading} />
        </>
      )}

      {!fetching && (
        <Typography style={{ whiteSpace: 'pre-line' }}>
          {getEventDescription(event)}
        </Typography>
      )}
    </div>
  </div>
);

export default withStyles(styles)(EventDescription);
