import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { withStyles } from '@material-ui/core';
import WomenPhone from '../../../../../assets/images/Woman_Phone.png';
import Button from '../../../../../components/atomic/Button';
import TextField from '../../../../../components/atomic/TextField';
import style from './style';
import Typography from '../../../../../components/atomic/Typography';
import NewsLetterSubscribedDialog from '../NewsletterSubscribedDialog';
import {
  fetchPropertyOwner as apiFetchPropertyOwner,
  updatePropertyOwner as apiUpdatePropertyOwner,
  fetchConsents as apiFetchConsents,
  updateConsents as apiUpdateConsents,
} from '../../../../../api/propertyowners';
import { MARKETINGEMAIL } from '../../../../../constants';
import useFetchEffect from '../../../../../hooks/useFetchEffect';
import localStorage from '../../../../../utils/localStorage';
import matomo from '../../../../../utils/matomo';

const MarketingConsentDialog = ({ classes }) => {
  const [isOpen, setIsOpen] = useState();
  const [isExpanded, setIsExpanded] = useState(false);
  const [email, setEmail] = useState();
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [successSubscription, setSuccessSubscription] = useState(false);
  const [requestError, setRequestError] = useState(null);

  const {
    fetching: fetchingPropertyOwner,
    fetchingError: propertyOwnerError,
    data: propertyOwner,
  } = useFetchEffect({
    initialDataState: {},
    defaultDataState: {},
    initialFetchingState: true,
    apiFetchFunction: apiFetchPropertyOwner,
  });

  const {
    fetching: fetchingConsent,
    fetchingError: consentsError,
    data: consents,
  } = useFetchEffect({
    initialFetchingState: true,
    initialDataState: [],
    defaultDataState: [],
    apiFetchFunction: apiFetchConsents,
    onError: (error) => setRequestError(error),
  });

  const handleMatomoClickEvent = (actionName) => {
    matomo.clickEvent({
      category: 'User Interaction',
      action: actionName,
      name: 'Marketing Consent Pop Up',
    });
  };

  useEffect(() => {
    if (setIsOpen) {
      handleMatomoClickEvent('Pop Up Loaded');
    }
  }, []);

  useEffect(() => {
    if (propertyOwner && !propertyOwnerError) {
      if (propertyOwner.email === null) {
        setIsExpanded(true);
      }
      setEmail(propertyOwner.email || '');
    }

    if (consents && !consentsError) {
      const marketingConsent = consents.find(
        (consent) => consent.type === MARKETINGEMAIL
      );
      setIsOpen(
        marketingConsent === undefined ||
          (marketingConsent && !marketingConsent?.isGranted)
      );
    }
  }, [propertyOwner, propertyOwnerError, consents, consentsError]);

  const handleClose = () => {
    setIsOpen(false);
    setSuccessSubscription(false);
    localStorage.set('consentRequestSeen', true);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(event.target.value));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isEmailValid || propertyOwner.email) {
      const marketingEmailConsent = consents.find(
        (consent) => consent.type === MARKETINGEMAIL
      );
      let updatedConsent;

      if (marketingEmailConsent) {
        updatedConsent = consents.map((consent) =>
          consent.type === MARKETINGEMAIL
            ? { ...consent, isGranted: true }
            : consent
        );
      } else {
        updatedConsent = [
          ...consents,
          {
            type: MARKETINGEMAIL,
            isGranted: true,
          },
        ];
      }

      const [errorConsent, consentResponse] = await apiUpdateConsents(
        updatedConsent
      );

      let error = null;
      let response = null;
      if (isExpanded) {
        [error, response] = await apiUpdatePropertyOwner({ email });
      }

      if ((!error && response) || (!errorConsent && consentResponse)) {
        setSuccessSubscription(true);
        setIsOpen(false);
        handleMatomoClickEvent('Consent Given');
      }
    }
  };

  if (fetchingPropertyOwner || fetchingConsent) {
    return <div>Loading...</div>;
  }

  if (requestError) {
    return <div>Error: {requestError.message}</div>;
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') handleClose();
        }}
      >
        <DialogContent className={classes.dialogContent}>
          <div className={classes.dialog}>
            <div className={classes.imageContainer}>
              <img
                src={WomenPhone}
                alt="Women with Phone"
                className={classes.image}
              />
            </div>
            <div className={classes.textContainer}>
              <Typography
                variant="h3"
                className={classes.title}
                color="textPrimary"
              >
                Få siste nytt først!
              </Typography>
              <Typography variant="body">
                Gode tips om oppussing, boligmarkedet, økonomi og podkasten
                Boligsnakk. Gjør som over 150.000 - abonner på Boligmappas
                nyhetsbrev.
              </Typography>
              {isExpanded ? (
                <form onSubmit={handleSubmit}>
                  <p style={{ fontWeight: 'bold' }}>Epostadresse</p>
                  <TextField
                    className={classes.emailInput}
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={handleChangeEmail}
                    placeholder="Din epostadresse"
                  />
                  <div className={classes.buttonContainer}>
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!isEmailValid}
                    >
                      Meld meg på
                    </Button>
                    <Button
                      className={classes.btn}
                      type="button"
                      variant="outlined"
                      onClick={() => {
                        handleClose();
                        handleMatomoClickEvent('Consent Pop Up Closed');
                      }}
                    >
                      Nei, takk
                    </Button>
                  </div>
                </form>
              ) : (
                <div className={classes.buttonContainer}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Meld meg på
                  </Button>
                  <Button
                    className={classes.btn}
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      handleClose();
                      handleMatomoClickEvent('Consent Pop Up Closed');
                    }}
                  >
                    Nei, takk
                  </Button>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <NewsLetterSubscribedDialog
        open={successSubscription}
        onClose={handleClose}
      />
    </>
  );
};

export default withStyles(style)(MarketingConsentDialog);
