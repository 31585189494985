const styles = (theme) => ({
  cardLayout: {
    display: 'flex',
    padding: '16px',
    gap: '13px',
    alignSelf: 'stretch',
    width: '544px',
    borderRadius: '8px',
    background: 'var(--White, #FFF)',
    border: '1px solid var(--BM-Grey, #E6E6E6)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  itemText: {
    fontWeight: '700',
    lineHeight: '50px',
    color: '#111111',
  },
  linkContainer: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  new_batch: {
    display: 'flex',
    padding: '2px 8px',
    justifyDontent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    background: '#5dc9a2',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '10px',
  },
  arrowIcon: {
    position: 'relative',
    left: '5px',
    color: '#717171',
    height: '16px',
    width: '16px',
    top: '18px',
  },
});

export default styles;
