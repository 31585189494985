import { mapVariables } from './helpers';
import { hjemlaColors } from '@style/themes/hjemla/colors';

/**
 * This object should define CSS variable names that can be set by the theme in /style/themes
 * We should strive to create semantic names, like textColor, warningColor, instead of using the name of the colors.
 */
export const colorVariables = {
  transparent: '--hj-transparent',
  darkColor: '--hj-dark-color',
  lightColor: '--hj-light-color',
  iconBaseColor: '--hj-icon-base-color',
  backgroundColor: '--hj-background-color',
  backgroundLightGrayColor: '--hj-background-light-gray-color',
  backgroundDarkColor: '--hj-background-dark-color',
  backgroundLightColor: '--hj-background-light-color',
  backgroundGrayColor: '--hj-background-gray-color',
  textColor: '--hj-text-color',
  textDark: '--hj-text-dark-color',
  textLight: '--hj-light-text-color',
  textError: '--hj-error-text-color',
  textSecondary: '--hj-text-secondary',
  textGreenBright: '--hj-text-green-bright',
  textColor_disabled: '--hj-text-color_disabled',
  textGrayColor: '--hj-text-gray',
  brandBackgroundColor: '--hj-brand-background-color',
  badgeColor: '--hj-badge-color',
  tagColorSecondary: '--hj-tag-color-secondary',
  tagColorLight: '--hj-tag-color-light',
  tagColorPrimary: '--hj-tag-color-primary',
  tagColorDark: '--hj-tag-color-dark',
  dotColor: '--hj-dot-color',
  dotColorSuccess: '--hj-dot-color-success',
  dotColorWarning: '--hj-dot-color-warning',
  dotColorInfo: '--hj-dot-color-info',
  dotColorNeutral: '--hj-dot-color-neutral',
  disabledBackground: '--hj-disabled-background',
  disabledBackgroundDark: '--hj-disabled-background-dark',
  primaryBoxShadow: '--hj-primary-box-shadow',
  link: {
    hover: '--hj-link-hover',
    text: '--hj-link-text',
  },
  switch: {
    background: '--hj-switch-background',
    background_hover: '--hj-switch-background-hover',
  },
  radio: {
    square_background: '--hj-radio-square-background-color',
  },
  button: {
    background_active: '--hj-btn-primary-background_active',
    filled: {
      background_active: '--hj-btn-primary-filled-background_active',
      background_hover: '--hj-btn-primary-filled_hover',
    },
  },
  slider: {
    track: '--hj-slider-track',
    trackFilled: '--hj-slider-track--filled',
    handleLines: '--hj-slider-handle-lines',
  },
  fileUpload: {
    deleteBackground: '--hj-file-delete-background',
    background: '--hj-file-background',
    removeButton: '--hj-file-remove-button',
    control: '--hj-file-control',
    progress: '--hj-file-progress',
    done: '--hj-file-done',
    border: '--hj-file-border',
    preview: '--hj-file-preview',
    filesUploaded: '--hj-files-uploaded',
  },
  select: {
    background: '--hj-select-background',
    hoverBg: '--hj-select-hover-background',
    selectedBg: '--hj-select-selected-background',
  },
  tags: {
    hover: '--hj-tags-remove-background',
    background: '--hj-tags-background',
    temporary: '--hj-tags-temporary',
  },
  dropdownMenu: {
    background: '--hj-dropdown-menu-background',
    border: '--hj-dropdown-menu-border',
    color: '--hj-dropdown-menu-color',
    backgroundHover: '--hj-dropdown-menu-background-hover',
  },
  paginationButton: {
    background: '--hj-pagination-background',
    backgroundActive: '--hj-pagination-background-active',
  },
  documentViewer: {
    background: '--hj-docs-viewer-background',
  },
  map: {
    zoom: {
      dashColor: '--hj-map-zoom-dash-color',
    },
  },
  loader: {
    mainColor: '--hj-loader-main-color',
  },
  alert: {
    error: '--hj-alert-error',
    info: '--hj-alert-info',
    infoDark: '--hj-alert-info-dark',
  },
  input: {
    default: '--hj-input-default',
    error: '--hj-input-error',
  },
};

/**
 * This is a utility for automatically mapping each variable defined in colorVariables to a
 * valid css variable.
 * e.g. darkColor: '--hj-dark-color', -> var(--hj-dark-color)
 */
export const colors = mapVariables<typeof colorVariables>(colorVariables);

// TODO: move to theme variables
export const chartColors = {
  transparent: 'rgb(250, 250, 250, 0)',
  darkGray: '#333333',
  lightGray: '#DEDEDE',
  textSecondary: '#7a7a7a',
  invisibleGray: '#F1F1F9',
  text: '#8D8D8D',
  grid: '#DDE2F1',
  green: '#5DC9A2',
  radonGreen: '#13A085',
  darkGreen: '#31777B',
  white: '#FFFFFF',
  darkPurple: 'rgba(69, 31, 150, 1)',
  lightRed: 'rgba(200,0,0,0.2)',
  lightOrange: 'rgb(255,234,221)',
  black: '#000000',
  redOrange: '#FC6865',
};
