export const defaultState = {
  mdrInputs: {},
  selectedCompany: null,
  isCompanyRegistered: false,
  isCompanyInSystem: false,
  jobYearDone: { value: '', label: '' },
};

export const SET_MDR_INPUTS = 'SET_MDR_INPUTS';
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';
export const SET_JOB_YEAR_DONE = 'SET_JOB_YEAR_DONE';
export const SET_IS_COMPANY_REGISTERED = 'SET_IS_COMPANY_REGISTERED';
export const SET_IS_COMPANY_IN_SYSTEM = 'SET_IS_COMPANY_IN_SYSTEM';
export const RESET = 'RESET';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_MDR_INPUTS:
      return {
        ...state,
        mdrInputs: action.payload,
      };
    case SET_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload,
      };
    case SET_JOB_YEAR_DONE:
      return {
        ...state,
        jobYearDone: action.payload,
      };
    case SET_IS_COMPANY_REGISTERED:
      return {
        ...state,
        isCompanyRegistered: action.payload,
      };
    case SET_IS_COMPANY_IN_SYSTEM:
      return {
        ...state,
        isCompanyInSystem: action.payload,
      };
    case RESET:
      return {
        ...state,
        mdrInputs: {},
        selectedCompany: null,
        isCompanyRegistered: false,
        isCompanyInSystem: false,
        jobYearDone: {},
      };
    default:
      return state;
  }
};

export const sGetMDRModule = (state) => state.mdr;
export const sGetMDRInputs = (state) => sGetMDRModule(state).mdrInputs;
export const sGetSelectedCompany = (state) =>
  sGetMDRModule(state).selectedCompany;
export const sGetJobYearDone = (state) => sGetMDRModule(state).jobYearDone;
export const sGetIsCompanyRegistered = (state) =>
  sGetMDRModule(state).isCompanyRegistered;
export const sGetIsCompanyInSystem = (state) =>
  sGetMDRModule(state).isCompanyInSystem;
