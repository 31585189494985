import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import Box from '../atomic/Box';

const DialogCloseConfirm = ({ classes }) => (
  <Box>
    <Box
      className={classes.close_dialog_heading}
      display="flex"
      justifyContent="center"
      component="div"
    >
      <b>
        <p className={classes.close_dialog_text}>Er du sikker på at du</p>
        <p className={classes.close_dialog_text}>vil avslutte flyten?</p>
      </b>
    </Box>
    <Box component="div">
      <p className={classes.close_dialog_text}>
        Det du har gjort så langt er lagret,
      </p>
      <p className={classes.close_dialog_text}>og du kan komme tilbake</p>
      <p className={classes.close_dialog_text}>der du slapp senere.</p>
    </Box>
  </Box>
);

export default withStyles(styles)(DialogCloseConfirm);
