import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Box from '../atomic/Box';
import Divider from '../atomic/Divider';
import Typography from '../atomic/Typography';

const Headline = ({
  classes,
  h = 'h1',
  mt = 0,
  mb = 2,
  children,
  align = 'left',
  showDivider = true,
  ...props
}) => (
  <Box mt={mt} mb={mb} {...props} textAlign={align}>
    <Typography noMargins variant={h}>
      {children}
    </Typography>

    {showDivider && (<Divider className={classes.divider} />)}
  </Box>
);

export default withStyles(styles)(Headline);
