import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Button from '../atomic/Button';
import CirclesLoader from '../loaders/CirclesLoader';

/**
 * Progress button component.
 * Needs to have fixed size (width and height assigned) to preserve size
 * while switching children/content.
 */
const ProgressButton = ({
  classes,
  children,
  disabled,
  requestInProgress,
  onClick = () => {},
  ...props
}) => {
  const onButtonClick = (event) =>
    disabled || requestInProgress ? event.preventDefault() : onClick(event);

  return (
    <Button
      onClick={onButtonClick}
      disabled={disabled && !requestInProgress}
      {...props}
    >
      {requestInProgress ? (
        <CirclesLoader width={30} height={20} className={classes.loader} />
      ) : (
        children
      )}
    </Button>
  );
};

export default withStyles(styles)(ProgressButton);
