import styled from 'styled-components';

export const StyledCustomDialogOverlay = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  z-index: 999999;

  &.show {
    display: flex;
    justify-content: center;
  }
`;

export const StyledCustomDialog = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 10%;
  max-width: 100%;
  background-color: #fff;
  gap: 32px;
  overflow-y: auto;
  min-height: fit-content;
  max-height: 610px;
  border-radius: 4px;

  .close {
    position: absolute;
    top: 24px;
    right: 24px;
    padding: 0;
    border: none;
    background-color: unset;
    cursor: pointer;
  }
`;
