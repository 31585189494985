import clsx from 'clsx';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { withStyles } from '@material-ui/core';

import {
  isOnline,
  getXRequestId,
  getErrorMessage,
  isConnectionError,
  getHttpErrorStatus,
  getStaticErrorCode,
  getErrorDescription,
  isHttpForbiddenResponse,
} from '../../utils/requests';

import styles from './style';
import Box from '../atomic/Box';
import Button from '../atomic/Button';
import Typography from '../atomic/Typography';
import ProgressButton from '../ProgressButton';
import useDesktop from '../../hooks/useDesktop';
import useSnackbar from '../../hooks/useSnackbar';
import Logo from '../../assets/icons/BoligmappaLogo';
import { GENERIC_CLIENT_ERROR_CODE } from '../../constants';
import ConnectionError from '../../assets/icons/ConnectionError';

export const ErrorContainer = ({
  user,
  classes,
  children,
  className,
  errorCode,
  errorResponse,
  customErrorObject,
  paddings = true,
  paddingTop = true,
  onHomeRedirect = () => {},
  ...props
}) => {
  const history = useHistory();
  const isDesktop = useDesktop();
  const { showSuccessMessage } = useSnackbar();

  const [feedbackSent, setFeedbackSent] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const isRuntimeError =
    !errorResponse && errorCode === GENERIC_CLIENT_ERROR_CODE;

  const actionButtonText = isRuntimeError
    ? 'Gå til hjemskjerm'
    : 'Prøv på nytt';

  const onRefreshClick = () => {
    if (isRuntimeError) {
      onHomeRedirect();
      history.push('/');
    } else {
      window.location.reload();
    }
  };

  const getErrorCode = () =>
    getStaticErrorCode(errorResponse) ||
    errorCode ||
    getXRequestId(errorResponse);

  const errorMessage = getErrorMessage(errorResponse, children);

  const errorDescription = getErrorDescription(errorResponse);

  const onSendButtonClick = async () => {
    setRequestInProgress(true);

    setFeedbackSent(true);
    showSuccessMessage('Takk! Feilkoden ble sendt.');

    setRequestInProgress(false);
  };

  if (
    customErrorObject &&
    isHttpForbiddenResponse(getHttpErrorStatus(errorResponse))
  ) {
    return (
      <div
        className={clsx(
          classes.container,
          paddings && classes.containerPaddings,
          paddingTop && classes.customMessagePaddingTop,
          className
        )}
        {...props}
      >
        <Logo className={classes.logo} />

        <Box textAlign="center">
          <Typography
            variant="h2"
            color="textPrimary"
            className={classes.alignCenter}
          >
            {children || <>{customErrorObject.title}</>}
          </Typography>

          {customErrorObject.message && (
            <Typography
              variant="body2"
              color="textPrimary"
              className={classes.helpText}
            >
              {customErrorObject.message}
            </Typography>
          )}
        </Box>
      </div>
    );
  }

  return (
    <Box
      className={clsx(
        classes.container,
        paddings && classes.containerPaddings,
        paddingTop && classes.containerPaddingTop,
        className
      )}
      {...props}
    >
      {isConnectionError(errorResponse) ? (
        <ConnectionError className={classes.connectionErrorIcon} />
      ) : (
        <Logo className={classes.logo} />
      )}

      <Box textAlign="center">
        <Typography
          variant="h2"
          color="primary"
          className={classes.textContainer}
        >
          {errorMessage}
        </Typography>

        {!isConnectionError(errorResponse) && (
          <Box mt={4}>
            <Typography variant="body2" color="textPrimary">
              Feilkode:&nbsp;
              {getErrorCode()}
            </Typography>
          </Box>
        )}

        <Box mt={4}>
          <Typography
            variant="body2"
            color="textPrimary"
            className={classes.helpText}
          >
            {errorDescription}
          </Typography>
        </Box>
      </Box>

      <Box mt={4}>
        {isOnline() && (
          <>
            <ProgressButton
              rounded
              size="large"
              color="primary"
              variant="contained"
              fullWidth={isDesktop}
              disabled={feedbackSent}
              onClick={onSendButtonClick}
              requestInProgress={requestInProgress}
              className={classes.sendErrorCodeButton}
            >
              Send feilkode
            </ProgressButton>
            {!isDesktop && <br />}
          </>
        )}

        <Button
          rounded
          size="large"
          color="secondary"
          variant="contained"
          fullWidth={isDesktop}
          onClick={onRefreshClick}
        >
          {actionButtonText}
        </Button>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(ErrorContainer);
