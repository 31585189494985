import React from 'react';

const ChapterSix = ({
  width = '100%',
  height = '100%',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 31.869 29.88"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g data-name="Group 3036" transform="translate(0 0)">
      <path
        fill={fill}
        fillRule="evenodd"
        transform="translate(-48 -395.601)"
        d="M78.541,400.249a1.328,1.328,0,0,1,1.328,1.328v22.575a1.328,1.328,0,0,1-1.328,1.328H49.328A1.328,1.328,0,0,1,48,424.152V401.578a1.328,1.328,0,0,1,1.328-1.328H64.689a.332.332,0,0,1,.235.567l-.875.875a2.655,2.655,0,0,0-.726,1.357l-.183.918a.333.333,0,0,1-.325.266h-9.5a1.328,1.328,0,0,0-1.328,1.328.332.332,0,0,0,.1.235.327.327,0,0,0,.235.1h6.307a1,1,0,0,1,0,1.992H52.316a.332.332,0,0,0-.332.332v1.328a.332.332,0,0,0,.332.332h6.307a1,1,0,0,1,0,1.992H52.316a.332.332,0,0,0-.332.332v1.992a.332.332,0,0,0,.332.332h5.577a1.7,1.7,0,0,1,1.726,1.66v4.98a.332.332,0,0,0,.332.332h3.32a.332.332,0,0,0,.332-.332v-5.644a1,1,0,0,1,1-1h6.639a1,1,0,0,1,1,.995v5.643a.332.332,0,0,0,.332.332h1.992a1.328,1.328,0,0,0,1.328-1.328V412.2a.332.332,0,0,0-.332-.332H69.91a1,1,0,0,1,0-1.992h5.643a.332.332,0,0,0,.332-.332V405.56a1.328,1.328,0,0,0-1.328-1.328h-.228l2.885-2.888a4.481,4.481,0,0,0,.717-.93.334.334,0,0,1,.288-.166Zm-8.3,17.6v-1.328H65.594v1.328Zm-4.648,1.992h4.648v1.66H65.594Zm-7.967-2.988a.332.332,0,0,0-.332-.332h-4.98a.332.332,0,0,0-.332.332v3.32a1.328,1.328,0,0,0,1.328,1.328H57.3a.332.332,0,0,0,.332-.332Z"
      />
      <path
        fill={fill}
        transform="translate(-43.902 -396.748)"
        d="M61.358,404.253a.664.664,0,0,0-.181.34l-.664,3.32a.663.663,0,0,0,.781.781l3.32-.664a.672.672,0,0,0,.34-.182l6.754-6.76a2.542,2.542,0,0,0-3.595-3.595Z"
      />
    </g>
  </svg>
);

export default ChapterSix;
