import React from 'react';

const RadonAreaLevelHigh = (props) => (
  <svg
    width={33}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#BE1E14"
      d="M.097 12.598h8.202v8.398H.097zM12.398 6.305H20.6v14.697h-8.202zM24.701.001h8.202v20.996h-8.202z"
    />
  </svg>
);

export default RadonAreaLevelHigh;
