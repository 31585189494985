import React from 'react';

const BMPlusCircle = ({
  width = '29',
  height = '29',
  fill = '#FF3121',
  ...props
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox="0 0 29 29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5739 4.32023C8.88298 4.32023 4.26957 8.88693 4.26957 14.5202C4.26957 20.1535 8.88298 24.7202 14.5739 24.7202C20.2648 24.7202 24.8783 20.1535 24.8783 14.5202C24.8783 8.88693 20.2648 4.32023 14.5739 4.32023ZM0.147827 14.5202C0.147827 6.63361 6.60661 0.240234 14.5739 0.240234C22.5412 0.240234 29 6.63361 29 14.5202C29 22.4069 22.5412 28.8002 14.5739 28.8002C6.60661 28.8002 0.147827 22.4069 0.147827 14.5202ZM14.5739 6.36023C15.7121 6.36023 16.6348 7.27357 16.6348 8.40023V12.4802H20.7565C21.8947 12.4802 22.8174 13.3936 22.8174 14.5202C22.8174 15.6469 21.8947 16.5602 20.7565 16.5602H16.6348V20.6402C16.6348 21.7669 15.7121 22.6802 14.5739 22.6802C13.4357 22.6802 12.513 21.7669 12.513 20.6402V16.5602H8.3913C7.25312 16.5602 6.33044 15.6469 6.33044 14.5202C6.33044 13.3936 7.25312 12.4802 8.3913 12.4802H12.513V8.40023C12.513 7.27357 13.4357 6.36023 14.5739 6.36023Z"
      fill={fill}
    />
  </svg>
);

export default BMPlusCircle;
