import { toLowerCase } from './utils/strings';

export const apiBaseUrl = process.env.REACT_APP_BOLIGEIER_API_HOST;
export const publicApiBaseUrl = process.env.REACT_APP_BOLIGEIER_API_PUBLIC_HOST;
export const loginUrl = process.env.REACT_APP_BOLIGEIER_LOGIN_REDIRECT_URL;

export const privateShareTokenKey =
  process.env.REACT_APP_BOLIGEIER_PRIVATE_SHARE_TOKEN_KEY;

export const authUserKey = process.env.REACT_APP_BOLIGEIER_AUTH_USER_KEY;
export const legacyAppRedirectUrl = process.env.REACT_APP_LEGACY_REDIRECT;
export const legacyAppUrl = process.env.REACT_APP_LEGACY_LOGIN;
export const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

export const localSeenNotificationsKey =
  process.env.REACT_APP_LOCAL_SEEN_NOTIFICATIONS_KEY;

export const betaUserKey = process.env.REACT_APP_BETA_USER_KEY;
export const backendBaseUrl = process.env.REACT_APP_BOLIGEIER_BACKEND_HOST;
export const cookieConsentKey = process.env.REACT_APP_COOKIE_CONSENT_KEY;
export const lastLoginTimestampKey = process.env.REACT_APP_LAST_LOGIN_TIMESTAMP;
export const edgeUserNotifiedKey = process.env.REACT_APP_EDGE_NOTIFIED_KEY;

export const referralSuggestedKey =
  process.env.REACT_APP_REFERRAL_SUGGESTED_KEY;

export const referralSuggestedDateKey =
  process.env.REACT_APP_REFERRAL_SUGGESTED_DATE_KEY;

export const referralSuggestedDays =
  process.env.REACT_APP_REFERRAL_SUGGESTED_DAYS;

export const refreshCallDateTimePoint =
  process.env.REACT_APP_REFRESH_CALL_DATE_TIME_POINT;

export const privateSectionUrlInLegacyApp =
  process.env.REACT_APP_LEGACY_PRIVATE_SECTION_REDIRECT;

export const gaTrackingId = process.env.REACT_APP_GA_TRACKING_ID;
export const rbToken = process.env.REACT_APP_RB_TOKEN;
export const hotjarId = process.env.REACT_APP_HOTJAR_ID;
export const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;
export const showNewConsents = process.env.REACT_APP_NEW_CONSENTS_CHANGE;
export const zendeskHelpPage = process.env.REACT_APP_ZENDESK_HELP_PAGE_URL;
export const VirdiEstimateLink = process.env.REACT_APP_VIRDI_ESTIMATE_LINK;
export const isTesting = () =>
  toLowerCase(process.env.REACT_APP_ENV) === 'testing';

export const isProduction = () =>
  toLowerCase(process.env.REACT_APP_ENV) === 'production';
export const isDevelopment = () =>
  toLowerCase(process.env.REACT_APP_ENV) === 'development';

export const virdiApiKey = process.env.REACT_APP_VIRDI_API_KEY;

export const loginDeviceUrl = process.env.REACT_APP_LOGIN_DEVICE_URL;
export const loginMobileUrl = process.env.REACT_APP_LOGIN_DEVICE_URL;

export const authScope = process.env.REACT_APP_AUTH_SCOPE;
export const authPageUri = process.env.REACT_APP_AUTH_PAGE_URI;
export const authClientId = process.env.REACT_APP_AUTH_CLIENT_ID;
export const authRedirectUri = process.env.REACT_APP_AUTH_REDIRECT_URI;
export const authResponseType = process.env.REACT_APP_AUTH_RESPONSE_TYPE;
export const authTokensEndpoint = process.env.REACT_APP_AUTH_TOKENS_ENDPOINT;
export const authLogoutEndpoint = process.env.REACT_APP_AUTH_LOGOUT_ENDPOINT;
export const backendJobsAPIUrl = process.env.REACT_APP_PUBLIC_JOBS_API;
export const virdiUnitLinkBaseUrl = process.env.REACT_APP_VIRDI_UNIT_BASE_URL;
export const virdiMapUrl = process.env.REACT_APP_VIRDI_MAP_URL;

export const appIdlePeriod = process.env.REACT_APP_IDLE_PERIOD;
export const enableBYBF = process.env.REACT_APP_ENABLE_BYBF;
export const enableHealthCheck = process.env.REACT_APP_ENABLE_HEALTH_CHECK;
export const rollbarEnv = process.env.REACT_APP_ROLLBAR_ENV;
export const onesignalappid = process.env.REACT_APP_ONESIGNAL_APPID;
export const enableRadon = process.env.REACT_APP_ENABLE_RADON;
export const enableAddOn = process.env.REACT_APP_ENABLE_ADD_ON;
export const enableOtovo = process.env.REACT_APP_ENABLE_OTOVO;
export const enableOvero = process.env.REACT_APP_ENABLE_OVERO;
export const enableEnergyMark = process.env.REACT_APP_ENABLE_ENERGY_MARK;
export const enableSurveyor = process.env.REACT_APP_ENABLE_SURVEYOR;
export const enableHjemla = process.env.REACT_APP_ENABLE_HJEMLA;
export const hjemlaBaseUrl = process.env.REACT_APP_HJEMLA_BASE_URL;
export const enableHjemlaFinancialPage =
  process.env.REACT_APP_ENABLE_HJEMLA_FINANCIALS_PAGE;
export const hideSignupPolicyAcceptance =
  process.env.REACT_APP_HIDE_SIGNUP_POLICY_ACCEPTANCE;
export const enableGoogleStreetViewImage =
  process.env.REACT_APP_GOOGLE_STREET_VIEW_IMAGE;
export const enableUserInterviews =
  process.env.REACT_APP_ENABLE_USER_INTERVIEWS;
export const uxSignalStudyId = process.env.REACT_APP_UX_SIGNALS_STUDY_ID;
export const uxSignalMode = process.env.REACT_APP_UX_SIGNALS_MODE;
export const uxSignalScriptURL = process.env.REACT_APP_UX_SIGNALS_SCRIPT_URL;
export const enableNewDocumentTable =
  process.env.REACT_APP_ENABLE_NEW_DOCUMENT_TABLE;
export const enableRadonAnnualAverage =
  process.env.REACT_APP_ENABLE_RADON_ANNUAL_AVERAGE;
export const unleashAPIKey = process.env.REACT_APP_UNLEASH_API_KEY;
export const unleashBaseUrl = process.env.REACT_APP_UNLEASH_BASE_URL;
export const helpInsuraceProductId =
  process.env.REACT_APP_HELP_INSURANCE_PRODUCT_ID;
export const boligmappaPlusMonthlyProductId =
  process.env.REACT_APP_BOLIGMAPPA_PLUS_MONTHLY_PRODUCT_ID;
export const boligmappaPlusYearlyProductId =
  process.env.REACT_APP_BOLIGMAPPA_PLUS_YEARLY_PRODUCT_ID;
export const FullstendigeVilkårLink =
  process.env.REACT_APP_FULL_STENDIGE_VILKAR_LINK;
export const ProduktarkLink = process.env.REACT_APP_PRODUKTARK_LINK;
export const AngrerettskjemaLink = process.env.REACT_APP_ANGRERETTSKJEMMA_LINK;
export const SanityDatabase = process.env.REACT_APP_SANITY_DATABASE;
export const SanityToken = process.env.REACT_APP_SANITY_TOKEN;
