import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { withStyles } from '@material-ui/core';
import React, { createElement, useState } from 'react';

import {
  chapters,
  getFilteredDocuments,
  getDocumentChapterTags,
} from '../../../../../utils/documents';

import {
  acSetDocuments,
  acDeleteDocument,
} from '../../../../../store/actions/documents';

import styles from './style';
import ChapterHeadline from './ChapterHeadline';
import Box from '../../../../../components/atomic/Box';
import useDesktop from '../../../../../hooks/useDesktop';
import { areIdsEqual } from '../../../../../utils/strings';
import SearchField from '../../../../../components/SearchField';
import Page from '../../../../../components/layouts/WithHeader';
import useFetchEffect from '../../../../../hooks/useFetchEffect';
import DocumentList from '../../../../../components/DocumentList';
import EmptyMessage from '../../../../../components/EmptyMessage';
import ErrorContainer from '../../../../../components/ErrorContainer';
import { sGetDocuments } from '../../../../../store/reducers/documents';
import { acDetachDocumentFromRooms } from '../../../../../store/actions/rooms';
import { acDetachDocumentFromEvents } from '../../../../../store/actions/events';
import { fetchDocuments as apiFetchDocuments } from '../../../../../api/properties';
import { getDocumentTable } from '../../../../../utils/DocumentTable';

const Chapter = ({
  classes,
  documents,
  setDocuments,
  deleteDocument,
  detachDocumentFromRooms,
  detachDocumentFromEvents,
}) => {
  const isDesktop = useDesktop();
  const { boligmappaNumber, chapterId } = useParams();

  const [searchQuery, setSearchQuery] = useState('');

  const { fetching, fetchingError } = useFetchEffect({
    dependencies: [boligmappaNumber],
    conditionFunction: () => !documents,
    onSuccess: (data) => setDocuments(data),
    apiFetchFunction: () => apiFetchDocuments(boligmappaNumber),
  });

  const chapter = chapters.find(({ id }) => areIdsEqual(id, chapterId));

  // for chapter 10 documents without chapter tag is included
  const chapterDocuments = (documents || []).filter(
    (document) =>
      getDocumentChapterTags(document)
        .map(({ id }) => String(id))
        .includes(chapterId) ||
      (chapterId === '10' && getDocumentChapterTags(document).length === 0)
  );

  const totalDocuments = () =>
    getFilteredDocuments({ documents, chapterId: chapter && chapter.id });

  const showDocumentList =
    fetching || (!fetching && Array.isArray(documents) && documents.length > 0);

  const showEmptyListMessage =
    !fetching && Array.isArray(documents) && documents.length === 0;

  const onSearchChange = (e) => setSearchQuery(e.target.value);

  const onDocumentDeleted = (document) => {
    detachDocumentFromRooms(document);
    detachDocumentFromEvents(document);

    deleteDocument(document);
  };

  const renderTable = () =>
    createElement(isDesktop ? getDocumentTable() : DocumentList, {
      fetching,
      searchQuery,
      onDocumentDeleted,
      searchEnabled: true,
      documents: chapterDocuments,
    });

  return (
    <Page
      minFullHeight
      noPadding={!isDesktop}
      hideHeader={isDesktop}
      headerTitle="Dokumenter"
      lessMarginLeft={isDesktop}
      maxWidth="lg"
    >
      {fetchingError ? (
        <ErrorContainer
          paddingTop={isDesktop}
          errorResponse={fetchingError}
          style={{ paddingTop: isDesktop ? undefined : '15%' }}
        />
      ) : (
        <>
          {showDocumentList && (
            <>
              <Box
                display="flex"
                alignItems="end"
                pt={isDesktop ? 0 : 2}
                px={isDesktop ? 0 : 2}
              >
                <Box flex="50%" maxWidth={isDesktop ? '50%' : undefined}>
                  <SearchField
                    fullWidth
                    value={searchQuery}
                    onChange={onSearchChange}
                    placeholder="Søk i dokumenter"
                  />
                </Box>
              </Box>

              <Box mt={3}>
                <ChapterHeadline
                  chapter={chapter}
                  documents={totalDocuments()}
                />

                <Box mt={isDesktop ? 2 : 0}>{renderTable()}</Box>
              </Box>
            </>
          )}

          {showEmptyListMessage && (
            <EmptyMessage
              shiftMarginTopBy={121}
              className={classes.emptyMessage}
            >
              Du har ikke lastet opp noen dokumenter.
            </EmptyMessage>
          )}
        </>
      )}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  documents: sGetDocuments(state),
});

const mapDispatchToProps = (dispatch) => ({
  setDocuments: (documents) => dispatch(acSetDocuments(documents)),
  deleteDocument: (document) => dispatch(acDeleteDocument(document)),
  detachDocumentFromRooms: (document) =>
    dispatch(acDetachDocumentFromRooms(document)),
  detachDocumentFromEvents: (document) =>
    dispatch(acDetachDocumentFromEvents(document)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Chapter));
