import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import { useHistory } from 'react-router';
import { withStyles } from '@material-ui/core';

import style from './style';
import CardItem from '../../../../components/CardList';
import localStorage from '../../../../utils/localStorage';
import { ACTIVE_PROPERTY_UID } from '../../../../constants';
import getSettingItems from '../../../../structure/settings';
import ListItem from '../../../../components/CardList/ListItem';
import PageLayout from '../../../../components/layouts/WithHeader';
import useLegacyAppRedirect from '../../../../hooks/useLegacyAppRedirect';
import useDashboardLinkPrefix from '../../../../hooks/useDashboardLinkPrefix';
import { sGetPropertyByBoligmappaNumber } from '../../../../store/reducers/properties';
import { sGetPendingRequests } from '../../../../store/reducers/accessRequests';
import NotificationsTag from '../../../../components/atomic/NotificationsTag';
import { useActiveProperty } from '../../../../store/hooks';
import NewTag from '../../../../components/atomic/NewTag';

const More = ({
  getPropertyByBoligmappaNumber,
  classes,
  pedningAccessRequests,
}) => {
  const history = useHistory();
  const { property } = useActiveProperty();
  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const { redirectToLegacyApp, redirectToPersonalDocuments } =
    useLegacyAppRedirect();

  const activePropertyId = localStorage.get(ACTIVE_PROPERTY_UID);
  const activeProperty = getPropertyByBoligmappaNumber(activePropertyId);
  const isPublicProperty = activeProperty && activeProperty.owned;

  const sections = getSettingItems({
    history,
    dashboardLinkPrefix,
    redirectToLegacyApp,
    redirectToPersonalDocuments,
    property,
  }).filter(
    (menuItem) => !(menuItem.hideInPrivateProperty && !isPublicProperty)
  );
  return (
    <PageLayout noPadding headerTitle="Mer" showBackButton={false}>
      {sections.map(({ id, title, items }) => (
        <Fragment key={`more-section-item-${id}`}>
          <br />
          <CardItem title={title}>
            {items.map(
              ({
                text,
                isNew,
                hideInPrivateProperty,
                notificationLabel,
                ...props
              }) =>
                (!hideInPrivateProperty || isPublicProperty) && (
                  <ListItem key={`more-section-list-item-${text}`} {...props}>
                    <div className={classes.listDiv}>
                      <span>{text}</span>
                      {isNew && (
                        <span className={classes.tagDiv}>
                          <NewTag />
                        </span>
                      )}
                      {notificationLabel &&
                        pedningAccessRequests.length > 0 && (
                          <NotificationsTag
                            qty={pedningAccessRequests.length}
                          />
                        )}
                    </div>
                  </ListItem>
                )
            )}
          </CardItem>
        </Fragment>
      ))}
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  getPropertyByBoligmappaNumber: (boligmappaNumber) =>
    sGetPropertyByBoligmappaNumber(state, boligmappaNumber),
  pedningAccessRequests: sGetPendingRequests(state),
});

export default connect(mapStateToProps)(withStyles(style)(More));
