import React from 'react';
import { withStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';

import styles from './style';
import ContentLoader from '../ContentLoader';

const DocumentListItemLoading = ({ classes }) => (
  <ListItem className={classes.container}>
    <div className={classes.label}>
      <div className={classes.iconContainer}>
        <ContentLoader width={25} height={25} variant="circle" />
      </div>
      <div className={classes.textContainer}>
        <div className={classes.fileNameLoader}>
          <ContentLoader width={200} height={12} />
        </div>
        <div>
          <ContentLoader width={150} height={12} />
        </div>
      </div>
    </div>
  </ListItem>
);

export default withStyles(styles)(DocumentListItemLoading);
