const styles = {
  '@global': {
    '.word-break': {
      hyphens: 'none',
      wordBreak: 'break-word',
      overflowWrap: 'break-word',
    },

    '.my-0': {
      marginTop: 0,
      marginBottom: 0,
    },
    '.mx-0': {
      marginLeft: 0,
      marginRight: 0,
    },
    '*::-webkit-scrollbar': {
      width: '0.4em',
      height: '0.4em',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.3)',
      borderRadius: '20px',
    },
  },
};

export default styles;
