import React, { createElement } from 'react';
import { connect } from 'react-redux';

import BinIcon from '../../../../../../../assets/icons/Bin';
import List from '../../../../../../../components/atomic/List';
import Menu from '../../../../../../../components/atomic/Menu';
import useDesktop from '../../../../../../../hooks/useDesktop';
import ImageIcon from '../../../../../../../assets/icons/Image';
import PencilIcon from '../../../../../../../assets/icons/Pencil';
import useSnackbar from '../../../../../../../hooks/useSnackbar';
import useActionDialog from '../../../../../../../hooks/useActionDialog';
import { deleteEvent as apiDeleteEvent } from '../../../../../../../api/events';
import BottomContextMenu from '../../../../../../../components/BottomContextMenu';
import DesktopMenuItem from '../../../../../../../components/desktop/MenuListItem';
import useDashboardLinkPrefix from '../../../../../../../hooks/useDashboardLinkPrefix';
import MobileMenuItem from '../../../../../../../components/BottomContextMenu/ListItem';
import { acDetachEventFromDocuments } from '../../../../../../../store/actions/documents';
import { sGetDocuments } from '../../../../../../../store/reducers/documents';
import matomo from '../../../../../../../utils/matomo';

const EventContextMenu = ({
  open,
  setOpen,
  eventId,
  anchorEl,
  eventName,
  onEventDeleted,
  onEditEvent,
  onEditImage,
  documents,
  detachEventFromDocuments,
}) => {
  const isDesktop = useDesktop();
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const { showSuccessMessage, showErrorMessage } = useSnackbar();
  const { showActionDialog, showLoadingDialog } = useActionDialog();

  const onDeleteEventClick = async () =>
    showActionDialog({
      submitButtonText: 'Slett',
      title: 'Vil du slette denne hendelsen?',
      message: `
        Dokumenter du har koblet til denne hendelsen vil fortsatt
        være tilgjengelige, mens bildene du har lastet  opp blir slettet.
      `,
      onSubmit: async () => {
        const closeDialog = showLoadingDialog('Sletter...');

        const [error, response] = await apiDeleteEvent(eventId);

        matomo.clickEvent({
          category: 'Events',
          action: 'Delete Event',
        });

        if (error) {
          showErrorMessage(error);
        } else {
          showSuccessMessage('Hendelse slettet.');
          onEventDeleted(response);
          if (documents) {
            detachEventFromDocuments(eventId);
          }
        }

        closeDialog();
      },
    });

  const menuItems = [
    {
      icon: PencilIcon,
      text: 'Rediger innhold',
      onClick: () => onEditEvent(),
    },
    {
      icon: ImageIcon,
      to: `${dashboardLinkPrefix}/events/${eventId}/images`,
      text: 'Rediger bilder',
      onClick: () => onEditImage(),
    },
    {
      icon: BinIcon,
      onClick: onDeleteEventClick,
      text: 'Fjern',
    },
  ];

  const renderMenuItem = ({ text, onClick, ...props }) =>
    // eslint-disable-next-line react/no-children-prop
    createElement(isDesktop ? DesktopMenuItem : MobileMenuItem, {
      key: text,
      children: text,
      border: !isDesktop,
      onClick: (event) => {
        if (onClick) {
          onClick(event);
        }

        setOpen(false);
      },
      ...props,
    });

  if (isDesktop) {
    return (
      <Menu
        open={open}
        anchorEl={anchorEl}
        disableAutoFocusItem
        onClose={() => setOpen(false)}
      >
        {menuItems.map(renderMenuItem)}
      </Menu>
    );
  }

  return (
    <BottomContextMenu
      open={open}
      setOpen={setOpen}
      title={`Instillinger for ${eventName || 'hendelse'}`}
    >
      <List>{menuItems.map(renderMenuItem)}</List>
    </BottomContextMenu>
  );
};

const mapStateToProps = (state) => ({
  documents: sGetDocuments(state),
});
const mapDispatchToProps = (dispatch) => ({
  detachEventFromDocuments: (payload) =>
    dispatch(acDetachEventFromDocuments(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventContextMenu);
