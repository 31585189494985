import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { decodeToken } from '../../utils/auth';
import useSearchQuery from '../../hooks/useSearchQuery';
import useFetchEffect from '../../hooks/useFetchEffect';
import { acSetAuthInfo } from '../../store/actions/auth';
import LoadingScreen from '../../components/LoadingScreen';
import { getAuthTokens as apiGetAuthTokens } from '../../api/auth';
import { isPropertyOwnerNotFoundError } from '../../utils/requests';
import { fetchPropertyOwner as apiFetchPropertyOwner } from '../../api/propertyowners';

const Authcode = ({ setAuthInfo }) => {
  const history = useHistory();
  const { code } = useSearchQuery();

  const redirectToSignUp = () => history.push('/signup');

  const redirectToProperties = () =>
    history.push('/properties?refreshEnabled=true');

  const fetchPropertyOwner = async (user) => {
    const [error, propertyOwner] = await apiFetchPropertyOwner();

    // This information is required for deciding if we
    // should make property details refresh call on properties (next) page.
    // ====================
    // DO NOT use this information anywhere else in the app,
    // cause it will not be persisted in redux store if user will close and reopen the app.
    setAuthInfo({
      user: {
        ...user,
        ...propertyOwner,
      },
      loaded: true,
    });

    return isPropertyOwnerNotFoundError(error)
      ? redirectToSignUp()
      : redirectToProperties();
  };

  const onLoggedIn = ({
    id_token: idToken,
    access_token: accessToken,
    refresh_token: refreshToken,
  }) => {
    const [error, user] = decodeToken(idToken);

    if (user) {
      setAuthInfo({
        user,
        idToken,
        accessToken,
        refreshToken,
        loaded: true,
      });

      return fetchPropertyOwner(user);
    }

    // eslint-disable-next-line no-console
    return console.log(error);
  };

  useFetchEffect({
    // TODO show error message
    // eslint-disable-next-line no-console
    onError: console.log,
    onSuccess: onLoggedIn,
    dependencies: [code],
    conditionFunction: ([_code]) => !!_code,
    apiFetchFunction: () => apiGetAuthTokens({ authcode: code }),
  });

  return <LoadingScreen />;
};

const mapDispatchToProps = (dispatch) => ({
  setAuthInfo: (user) => dispatch(acSetAuthInfo(user)),
});

export default connect(null, mapDispatchToProps)(Authcode);
