const styles = (theme) => ({
  root: {
    display: 'flex',
    borderRadius: '3px',
    alignItems: 'center',
    padding: '3px 3px 3px 6px',
    margin: `12px ${theme.spacing(0.5)}px`,
    color: theme.palette.primary.black,
    background: '#D1EDE8',
    height: '30px',
  },
  label: {
    fontSize: '14px',
    marginRight: theme.spacing(0.5),
  },
  closeIcon: {
    color: theme.palette.primary.black,
    width: '20px',
  },
  closeButton: {
    marginLeft: '2px',
    borderRadius: '50%',
    height: '100%',
  },
});

export default styles;
