import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import FileListItem from '../FileListItem';
import List from '../../../../../components/atomic/List';
import { ActionButtons } from '../../../../../components/Buttons';
import Typography from '../../../../../components/atomic/Typography';

const FileChoice = ({
  files,
  classes,
  setFiles,
  onCancel,
  onSubmit,
  ...props
}) => {
  const onDocumentUnselect = (fileIndex) => {
    const updatedFiles = files.filter((file, index) => index !== fileIndex);

    setFiles(updatedFiles);
  };

  return (
    <div {...props}>
      <List className={classes.fileList}>
        {files.map((file, index) => (
          <FileListItem
            file={file}
            index={index}
            key={`file-item-${file.name}`}
            onUnselect={onDocumentUnselect}
          />
        ))}
        {Array.isArray(files) && files.length === 0 && (
          <Typography color="textSecondary">Ingen filer valgt</Typography>
        )}
      </List>

      <ActionButtons
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitButtonText="Neste"
        submitButtonDisabled={Array.isArray(files) && files.length === 0}
      />
    </div>
  );
};

export default withStyles(styles)(FileChoice);
