import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from '../../style';

const MoreDescription = ({
  moreDescription,
  moreDescriptionQuestions,
  classes,
}) => {
  return (
    <>
      {moreDescription?.map((singleDescriptionBlock, index) => (
        <span
          key={singleDescriptionBlock}
          className={classes.moreDescriptionBlock}
        >
          {moreDescriptionQuestions?.length > 0 && (
            <div className={classes.MoreDescriptionQuestion}>
              {moreDescriptionQuestions[index]}
            </div>
          )}
          <span className={classes.textDescription}>
            {singleDescriptionBlock}
          </span>
        </span>
      ))}
    </>
  );
};

export default withStyles(styles)(MoreDescription);
