import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from '../../style';

const Topic = ({ title, subtitle, classes }) => {
  return (
    <>
      <span className={`${classes.headerTitle} topic-title`}>{title}</span>
      {subtitle && <span className={classes.headerSubtitle}>{subtitle}</span>}
    </>
  );
};

export default withStyles(styles)(Topic);
