const styles = () => ({
  EmailButton: {
    '@media only screen and (max-width: 1042px)': {
      marginTop: '2.93%',
      display: 'flex',
      justifyContent: 'center',
      width: '60%',
    },
    '@media only screen and (max-width: 1060px)': {
      marginTop: '2.93%',
      display: 'flex',
      justifyContent: 'center',
      width: '65%',
    },
    marginTop: '2.93%',
    display: 'flex',
    justifyContent: 'center',
    width: '47%',
  },
  buttonIcon: {
    '@media only screen and (max-width: 1101px)': {
      marginRight: '5%',
    },
    marginRight: '5%',
  },
  buttonIconmonile: {
    marginRight: '5%',
  },
  referralImage: {
    marginTop: '16%',
  },
  ReferralImageMobile: {
    width: '228px',
    height: '186px',
    opacity: '1',
  },
  referralLink: {
    marginLeft: '2%',
  },
  referralTitleMobile: {
    fontSize: '10pt',
    margin: '0.83em 0',
    fontFamily: 'Sofia Pro, sans-serif',
    fontWeight: '700',
    lineHeight: '1.167',
  },
  referralTitle: {
    '@media only screen and (max-width: 1236px) and (min-width: 1147px)': {
      fontSize: '22pt',
      margin: '0.83em 0',
      fontFamily: 'Sofia Pro, sans-serif',
      fontWeight: '700',
      lineHeight: '1.167',
    },
    '@media only screen and (max-width: 1146px)': {
      fontSize: '21pt',
      margin: '0.83em 0',
      fontFamily: 'Sofia Pro, sans-serif',
      fontWeight: '700',
      lineHeight: '1.167',
    },
    '@media only screen and (max-width: 1076px) and (min-width:960px)': {
      fontSize: '17pt',
      margin: '0.83em 0',
      fontFamily: 'Sofia Pro, sans-serif',
      fontWeight: '700',
      lineHeight: '1.167',
    },
    margin: '0.83em 0',
    fontSize: '25pt',
    fontFamily: 'Sofia Pro, sans-serif',
    fontWeight: '700',
    lineHeight: '1.167',
  },
  containermobiel: {
    marginTop: '37px',
    left: '289pt',
  },
  referralLinkMobile: {
    position: 'relative',
    Width: '50%',
    marginLeft: '25%',
    maxwidth: '100%',
  },
  containerreferral: { marginLeft: '32px' },
  containersubtitle: {
    '@media only screen and (max-width: 1024px)': {
      fontSize: '16px',
    },
    fontFamily: 'Sofia Pro',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#000000',
    marginTop: '7%',
  },
  containermobile: {
    fontFamily: 'Sofia Pro',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '28px',
    marginTop: '10%',
  },
  referralcontainermobile: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  publicshareimageMobile: {
    width: '228px',
    height: '186px',
    opacity: '1',
  },
  publicShareContentMobile: {
    marginTop: '37px',
    left: '289pt',
    display: 'flex',
    justifyContent: 'center',
  },
  EmailButtonMobile: {
    '@media only screen and (max-width: 959px)': {
      width: '75%',
    },
    '@media only screen and (max-width: 434px) and (min-width:320px)': {
      display: 'flex',
      justifyContent: 'end',
      width: '82%',
    },
    display: 'flex',
    justifyContent: 'center',
  },
  sendepostbuttonmobile: {
    '@media only screen and (max-width: 959px) and (min-width:736px)': {
      width: '35%',
      marginLeft: '31%',
    },
    '@media only screen and (max-width: 737px) and (min-width:433px)': {
      width: '60%',
      marginLeft: '31%',
    },
    '@media only screen and (max-width: 434px) and (min-width:320px)': {
      width: '84%',
    },
  },
  emailButtonContainerMobile: { marginTop: '7%', position: 'relative' },
  copyContainerDesktop: { marginTop: '7%' },
  dividerlink: { marginTop: '7%', fontWeight: '400', color: '#333333' },
  dividerlinkMobile: { marginTop: '3.5%', fontWeight: '400', color: '#333333' },
  referralLinkContainer: {},
  referalimagecontainer: { display: 'flex', justifyContent: 'center' },
  emailButtonContainerDesktop: {
    display: 'flex',
    justifyContent: 'center',
  },
  copyContainer: {
    '@media only screen and (max-width: 959px)': {
      width: '100%',
      marginLeftt: '0px',
    },
    '@media only screen and (min-width: 279px) and (max-width:378px)': {
      width: '100%',
      marginLeft: '0%',
    },
    '@media only screen and (min-width: 379px) and (max-width: 400px)': {
      width: '100%',
      marginLeft: '0px',
    },
    '@media only screen and (min-width: 401px) and (max-width: 520px)': {
      width: '100%',
      marginLeft: '0px',
    },
  },
});

export default styles;
