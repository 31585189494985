import React, { useEffect, useState } from 'react';
import { barChartOptions, chartColors } from '../../utils/chartsConfig';
import BarChart from '../charts/BarChart';

const PricePerSquareMeterChart = ({
  error,
  fetching,
  stats,
  valueEstimatePerSqm,
  children,
  header,
  chartAreaClass,
}) => {
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [chartOptions, setChartOptions] = useState(null);
  const [averageLine, setAverageLine] = useState({});
  const [valueEstimatePerSqmLine, setValueEstimatePerSqmLine] = useState({});
  const [estimatedValuePerSqm, setEstimatedValuePerSqm] = useState(null);

  const getMaxValue = (maxCount) => {
    return Math.ceil(Math.ceil(maxCount * 1.05) / 10) * 10;
  };

  const numberConvertedTickArray = (ticks) => {
    const returnArr = [];
    (ticks || []).forEach((tick) => {
      const tickUpdate = { ...tick };
      tickUpdate.label = tick.label.replace(/[^a-zA-Z0-9]/g, '');
      returnArr.push(tickUpdate);
    });
    return returnArr;
  };

  useEffect(() => {
    if (stats?.priceDistributionSQM?.length > 0) {
      const priceDestribution = stats?.priceDistributionSQM.sort((a, b) =>
        a.from > b.from ? -1 : 1
      );
      const priceDestributionCountSorted = [...priceDestribution].sort((a, b) =>
        a.count > b.count ? -1 : 1
      );

      setLabels(
        priceDestribution.map((pd) => {
          if (pd.from === 0) return pd.to;
          return pd.from;
        })
      );

      setData(
        priceDestribution.map((pd) => {
          return pd.count;
        })
      );

      const maxX = getMaxValue(priceDestributionCountSorted[0].count);
      setChartOptions({
        ...barChartOptions,
        scales: {
          ...barChartOptions.scales,
          xAxis: {
            max: maxX,
            title: {
              display: true,
              align: 'center',
              text: 'Antall solgte eiendommer',
              color: '#333333',
            },
            grid: {
              color: chartColors.invisibleGrey,
              tickColor: chartColors.transparent,
              borderDash: [3, 1],
            },
          },
        },
      });
    }

    let yAverage = 0;
    setAverageLine({
      id: 'averageLine',
      afterDraw: (chart) => {
        const {
          ctx,
          chartArea: { left, right, width },
          scales: {
            y,
            y: { ticks },
          },
        } = chart;
        const tickData = numberConvertedTickArray(ticks);
        ctx.save();
        ctx.strokeStyle = chartColors.black;
        const averageSQM = stats?.averageSQMPrice;
        if (averageSQM > 0 && tickData) {
          // average arbitory line
          const connectedTick = (tickData || []).find(
            (tick) => tick.label <= averageSQM
          );

          const tickIndex = connectedTick?.value;
          const labelValueGap =
            tickData[tickIndex - 1].label - tickData[tickIndex].label;
          const labelGap = averageSQM - connectedTick?.label;
          const extraPesentage = labelGap / labelValueGap;
          yAverage = y.getPixelForValue(tickIndex - extraPesentage);
          ctx.strokeRect(
            left,
            y.getPixelForValue(tickIndex - extraPesentage),
            width - 4,
            0
          );
          ctx.restore();

          // average line circle
          ctx.beginPath();
          ctx.arc(
            right,
            y.getPixelForValue(tickIndex - extraPesentage),
            5,
            0,
            2 * Math.PI,
            true
          );
          ctx.strokeStyle = chartColors.black;
          ctx.fillStyle = chartColors.white;
          ctx.stroke();
          ctx.fill();
          ctx.restore();
        }
      },
    });

    setEstimatedValuePerSqm(null);
    setTimeout(() => {
      setEstimatedValuePerSqm(+valueEstimatePerSqm);
    }, 1000);
    setValueEstimatePerSqmLine({
      id: 'valueEstimatePerSqmLine',
      afterDraw: (chart) => {
        const {
          ctx,
          chartArea: { left, right, width },
          scales: {
            y,
            y: { ticks },
          },
        } = chart;
        const tickData = numberConvertedTickArray(ticks);
        ctx.save();
        ctx.strokeStyle = chartColors.darkPurple;
        if (+valueEstimatePerSqm > 0) {
          // estimate arbitory line
          const connectedTick = (tickData || []).find(
            (tick) => tick.label <= +valueEstimatePerSqm
          );
          if (!connectedTick || connectedTick.value === 0) return;
          const tickIndex = connectedTick?.value;
          const labelValueGap =
            tickData[tickIndex - 1].label - tickData[tickIndex].label;
          const labelGap = +valueEstimatePerSqm - connectedTick?.label;
          const extraPesentage = labelGap / labelValueGap;
          ctx.strokeRect(
            left,
            y.getPixelForValue(tickIndex - extraPesentage),
            width,
            0
          );
          ctx.restore();

          // estimate line circle
          ctx.beginPath();
          ctx.arc(
            right,
            y.getPixelForValue(tickIndex - extraPesentage),
            5,
            0,
            2 * Math.PI,
            true
          );
          ctx.fillStyle = chartColors.darkPurple;
          ctx.fill();
          ctx.restore();

          // gap colour
          if (yAverage > 0) {
            ctx.fillStyle = chartColors.lightRed;
            ctx.fillRect(
              left,
              y.getPixelForValue(tickIndex - extraPesentage),
              width,
              -(y.getPixelForValue(tickIndex - extraPesentage) - yAverage)
            );
            ctx.restore();
          }
        }
      },
    });
  }, [stats, valueEstimatePerSqm]);

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Antall solgte eiendommer',
        backgroundColor: chartColors.lightOrange,
        borderColor: chartColors.lightOrange,
        borderRadius: 5,
        data,
      },
    ],
  };

  return (
    <>
      {!fetching && !error && header}
      {!fetching && !error && (
        <div className={chartAreaClass}>
          <BarChart
            options={chartOptions}
            data={chartData}
            plugins={[averageLine, valueEstimatePerSqmLine]}
            redraw={estimatedValuePerSqm !== null}
          />
        </div>
      )}
      {!fetching && !error && children}
    </>
  );
};

export default PricePerSquareMeterChart;
