import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import matomo from 'utils/matomo';
import { sGetInsuranceRemainingDays } from 'store/reducers/documents';
import Link from 'components/atomic/Link';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ProgressButton from 'components/ProgressButton';
import { withStyles } from '@material-ui/core';
import Box from '../../atomic/Box';
import Typography from '../../atomic/Typography';
import styles from './style';



const InsuranceBanner = ({ classes, insuranceRemainingDays }) => {

  const { boligmappaNumber } = useParams();
  const url = `/dashboard/${boligmappaNumber}/insurance`;

  const handleClick = () => {
    matomo.clickEvent({
      category: 'Trygt utført',
      name: `Clicked Learn More from Banner`,
      action: 'Clicked Learn More from Banner',
    });
  };

  useEffect(() => {
  }, []);

  return (
    insuranceRemainingDays && insuranceRemainingDays != null && insuranceRemainingDays > 0 ?
          (<Box className={classes.messageContainer}>
            <Typography className={classes.message}>
              <b>{insuranceRemainingDays} dager igjen!</b> Du har en nylig jobb som kan forsikres med advokathjelp i 5 år til kun 500,-!
            </Typography>
            <Link
              className={classes.link}
                 to={url}
                underline={false}
                inheritColor
              >
                <ProgressButton
                  requestInProgress={false}
                  type="submit"
                  onClick={handleClick}
                  color="primary"
                  variant="contained"
                  className={classes.button}
                >
                   Lær mer
                </ProgressButton>
              </Link>
          </Box>) : ""
  );
};
const mapStateToProps = (state) => ({
  insuranceRemainingDays: sGetInsuranceRemainingDays(state),
});

export default (withStyles(styles))(connect(mapStateToProps, null)(InsuranceBanner));


