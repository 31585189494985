/* eslint-disable no-restricted-syntax */
export const findDirectoriesInTheSameLevel = (directories, targetId) => {
  if (targetId === null) return [];
  const findAndCollect = (directoryList, id) => {
    for (const directory of directoryList) {
      if (directory.id === id) {
        const rootObj = [
          { id: directory.parentId, name: 'Flytt til overordnet mappe' },
        ];
        return {
          found: true,
          levelObjects: [...rootObj, ...directoryList],
        };
      }
      if (directory.directories && directory.directories.length > 0) {
        const result = findAndCollect(directory.directories, targetId);
        if (result.found) {
          return result;
        }
      }
    }
    return { found: false, levelObjects: [] };
  };

  const result = findAndCollect(directories, targetId);
  return result.levelObjects;
};
