import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { withStyles } from '@material-ui/core';

import useDesktop from '../../../../../hooks/useDesktop';
import useSnackbar from '../../../../../hooks/useSnackbar';
import useActionDialog from '../../../../../hooks/useActionDialog';

import Paper from '../../../../../components/atomic/Paper';
import PageLayout from '../../../../../components/layouts/WithHeader';
import ErrorContainer from '../../../../../components/ErrorContainer';
import ContentLoader from '../../../../../components/loaders/ContentLoader';
import { acSetDocumentSearchText } from '../../../../../store/actions/documents';

import {
  addStatus,
  getDisplayName,
  getId,
  getStatus,
} from '../../../../../utils/privateshare';

import {
  postPrivateAccessData as apiPostPrivateAccessData,
  getPrivateAccessData as apiGetPrivateAccessData,
  deletePrivateShare as apiDeletePrivateAccessData,
  patchPublicAccessData as apiPatchPublicAccessData,
  getPublicAccessData as apiGetPublicAccessData,
  postPublicAccessData as apiPostPublicAccess,
} from '../../../../../api/properties';

import Details from './Details';
import ShareForm from './ShareForm';
import SharePublicForm from '../Share/Form';
import SingleDetailsPage from './Details/SingleRecord';
import Style from './style';
import localStorage from '../../../../../utils/localStorage';
import { PREVIEWENABLEDFOR } from '../../../../../constants';
import Box from '../../../../../components/atomic/Box';
import SingleAccessRequestRecord from './Details/SingleAccessRequestDetails';
import matomo from '../../../../../utils/matomo';

const PrivateShare = ({ classes, setSearchText }) => {
  const isDesktop = useDesktop();
  const { boligmappaNumber } = useParams();
  const { showSuccessMessage, showErrorMessage } = useSnackbar();
  const { showActionDialog, showLoadingDialog } = useActionDialog();

  const [fetching, setFetching] = useState('');
  const [administratorsData, setAdministratorsData] = useState(false);
  const [publicAccessData, setPublicAccessData] = useState(null);
  const [editable, setEditable] = useState(false);
  useState(null);
  const [sharingRequestInProgress, setSharingRequestInProgress] =
    useState(null);

  const [sharingError, setSharingError] = useState(null);
  const [fetchingError, setFetchingError] = useState(null);
  const [showDetailsPage, setShowDetailsPage] = useState(true);
  const [showSingleShare, setShowSingleShare] = useState(false);
  const [showSharingForm, setShowSharingForm] = useState(false);
  const [showPublicSharingForm, setShowPublicSharingForm] = useState(false);
  const [privateAccessData, setPrivateAccessData] = useState([]);
  const [selectedShareRecord, setSelectedShareRecord] = useState(null);
  const [selectedAccessRecord, setSelectedAccessRecord] = useState(null);
  const [sharingRequestError, setSharingRequestError] = useState(false);
  const [privateSharingInprogress, setPrivateSharingInprogress] =
    useState(false);
  const { protocol } = window.location;
  const { host } = window.location;
  const publicSharingUrl = `/public/${boligmappaNumber}/home`;

  const scrollToTop = () => window.scrollTo(0, 0);

  const trackMatomoEvent = (name, action) => {
    matomo.clickEvent({
      category: 'Sharing Options',
      name,
      action,
    });
  };

  const confirmUnsharingAlert = (submitHandler, cancelHandler) => {
    const ConfirmUnsharingMessage = () => (
      <div>
        Er du sikker på at du vil stoppe delingen? Lenken vil bli deaktivert og
        alle mottakere vil miste innsyn i din boligmappe.
      </div>
    );

    return showActionDialog({
      closeable: true,
      showCancelButton: true,
      onCancel: cancelHandler,
      submitButtonText: 'Stopp deling',
      submitButtonRed: true,
      title: 'Fjern lesetilgang',
      submitButtonVariant: 'contained',
      onSubmit: submitHandler,
      message: <ConfirmUnsharingMessage />,
    });
  };

  const clearPreview = () => {
    const previewEnabledForArr =
      JSON.parse(localStorage.get(PREVIEWENABLEDFOR)) || [];

    const previewEnabledForArrModified = previewEnabledForArr.filter(
      (i) => i !== boligmappaNumber
    );

    localStorage.set(
      PREVIEWENABLEDFOR,
      JSON.stringify(previewEnabledForArrModified)
    );
  };
  const fetchPublicAccessData = async () => {
    setFetchingError(null);

    setFetching(true);

    // Get publicAccess data
    const [errorFetching, responseFetching] = await apiGetPublicAccessData(
      boligmappaNumber
    );

    if (errorFetching && !responseFetching) {
      setFetchingError(errorFetching);
      setPublicAccessData(null);
    } else {
      const { active } = responseFetching;
      let data = null;

      if (active) {
        const { id, sharedDate, expiryDate, sharedByName, viewCount, link } =
          active;

        data = {
          id,
          status: 'AKTIV',
          startDate: sharedDate,
          endDate: expiryDate,
          sharedBy: sharedByName,
          visitors: viewCount,
          link: {
            text: 'Kopier linken',
            url: link,
          },
        };
      }

      setPublicAccessData(data);
    }

    setFetching(false);
  };
  const transformExpiaryDatetoDateTime = (expiryDate) => {
    const expDateTime = new Date(expiryDate);
    expDateTime.setUTCHours(23, 59, 59);
    return expDateTime.toISOString();
  };
  const patchPublicAccessData = async (id, expiryDate, unshare = false) => {
    let closeDialog = () => {};
    let expireDateTime;

    if (unshare) {
      const dateTimeBeforeThreeMinutesNow = new Date(expiryDate);
      dateTimeBeforeThreeMinutesNow.setUTCMinutes(
        dateTimeBeforeThreeMinutesNow.getUTCMinutes() - 3
      );
      expireDateTime = dateTimeBeforeThreeMinutesNow.toISOString();

      closeDialog = showLoadingDialog('Stopper delingen');
    } else {
      expireDateTime = transformExpiaryDatetoDateTime(expiryDate);
      setSharingRequestInProgress(true);
    }

    const [errorPatch, responsePatch] = await apiPatchPublicAccessData(
      boligmappaNumber,
      id,
      {
        expiryDate: expireDateTime,
      }
    );

    // TODO: Refactor this code
    if (errorPatch && !responsePatch) {
      if (unshare) {
        setSharingRequestError(errorPatch);
      }
    } else if (unshare) {
      setPublicAccessData(null);
      trackMatomoEvent('Stopped Public Sharing', 'Public Sharing');
      showSuccessMessage('Offentlig deling av boligmappen er stoppet.');
    } else {
      trackMatomoEvent('Edited Public Sharing', 'Public Sharing');
      showSuccessMessage('Lenke til din offenlige boligmappe opprettet.');
      setShowPublicSharingForm(false);
      setEditable(false);
      fetchPublicAccessData();
    }

    if (unshare) {
      setSharingRequestInProgress(false);
    }
    closeDialog();

    // Clear localStorage
    clearPreview();
  };

  const toggleEditPublicSharing = (statue = true) => {
    setShowPublicSharingForm(statue);
    setEditable(statue);
  };

  const stopPublicSharing = async () => {
    // setUnsharingRequestError(null);

    const { id } = publicAccessData;
    const expiryDate = new Date().toISOString();
    const submitHandler = () => patchPublicAccessData(id, expiryDate, true);
    const cancelHandler = () => {};
    confirmUnsharingAlert(submitHandler, cancelHandler);
  };
  const redirectToPreviewPage = () => {
    window.location = publicSharingUrl;
  };
  const MoreSectionUrl = `/dashboard/${boligmappaNumber}/more`;
  const backToMoreMenu = () => {
    window.location = MoreSectionUrl;
  };
  const postPublicAccessData = async (expiryDate) => {
    setSharingRequestInProgress(true);
    const [errorPost, responsePost] = await apiPostPublicAccess(
      boligmappaNumber,
      {
        expiryDate: transformExpiaryDatetoDateTime(expiryDate),
      }
    );

    if (errorPost && !responsePost) {
      setSharingRequestError(errorPost);
    } else {
      // Set previewEnabledFor array on localStorage
      const previewEnabledForArr =
        JSON.parse(localStorage.get(PREVIEWENABLEDFOR)) || [];
      previewEnabledForArr.push(boligmappaNumber);
      localStorage.set(PREVIEWENABLEDFOR, JSON.stringify(previewEnabledForArr));
      trackMatomoEvent('Started Public Sharing', 'Public Sharing');
      // showSuccessMessage('Din boligmappe er klar for å bli delt offentlig.');
      redirectToPreviewPage();
    }

    setSharingRequestInProgress(false);
  };
  const confirmSharingAlert = (submitHandler) => {
    const ConfirmSharingMessage = () => (
      <Box className={classes.dialogsize}>
        <Box
          className={classes.close_dialog_heading}
          display="flex"
          justifyContent="center"
          component="div"
        >
          <b>
            <p className={classes.close_dialog_heading}>Til informasjon</p>
          </b>
        </Box>
        <Box component="div" justifyContent="center">
          <p className={classes.close_dialog_text}>
            Vi vil nå vise deg en forhåndsvisning av din delte boligmappe, så du
            kan sjekke at alt ser bra ut.
          </p>
          <p className={classes.close_dialog_text}>
            Merk deg at hvis det er noe du mener ikke skal vises, så må du
            slette dette fra mappen.
          </p>
          <p className={classes.close_dialog_text}>
            Husk å trykke «Fullfør delingen» når du sier deg fornøyd.
          </p>
        </Box>
      </Box>
    );

    return showActionDialog({
      closeable: false,
      showCancelButton: false,
      submitButtonText: 'Neste',
      submitButtonVariant: 'contained',
      onSubmit: submitHandler,
      buttonType: 'Customized',
      actionClassName: classes.confirmCloseActions,
      buttonClassName: classes.confirmCloseButton,
      message: <ConfirmSharingMessage />,
    });
  };
  const sharePublicly = ({ isAgreedTnC, endDate }) => {
    setSharingError(null);
    setSharingRequestError(null);

    // Validation
    if (!isAgreedTnC) {
      setSharingError('Vennligst godta vilkårene for å gå videre.');
      return;
    }

    const submitHandler = () => postPublicAccessData(endDate);
    if (editable) {
      const { id } = publicAccessData;
      patchPublicAccessData(id, endDate);
    } else {
      confirmSharingAlert(submitHandler);
    }
  };

  const rearrangePrivateData = (response) => {
    const data = [];
    if (response.active.length > 0) {
      const activeValues = response.active;
      activeValues.map((activeValue) =>
        data.push(addStatus(activeValue, 'active'))
      );
    }
    if (response.sharedtoken.length > 0) {
      const sharedValues = response.sharedtoken;

      sharedValues.map((sharedValue) =>
        data.push(addStatus(sharedValue, 'shareToken'))
      );
    }
    setPrivateAccessData(data);
    if (data.length > 0) {
      setAdministratorsData(false);
    } else {
      setAdministratorsData(true);
    }
  };

  const fetchPrivateAccessData = async () => {
    setFetching(true);
    const [error, response] = await apiGetPrivateAccessData(boligmappaNumber);

    if (error && !response) {
      setFetchingError(error);
    } else {
      rearrangePrivateData(response);
    }
    setFetching(false);
  };

  const toggleDetailsPrivateSharing = (state = false) => {
    scrollToTop();
    setShowSharingForm(state);
    setShowDetailsPage(!state);
  };

  const togglePrivateSharingForm = () => {
    scrollToTop();
    setShowSharingForm(true);
    setShowDetailsPage(false);
    setSharingRequestError(false);
  };

  const onDetailsClick = (record) => {
    if (record) {
      setSelectedShareRecord(record);
      setShowDetailsPage(false);
      setShowSingleShare(true);
    }
  };
  const onAccessRequestsDetailsClick = (record) => {
    if (record) {
      setSelectedAccessRecord(record);
      setShowDetailsPage(false);
      setShowSingleShare(true);
    }
  };

  const backButtonAction = () => {
    if (showSingleShare) {
      setSelectedShareRecord(null);
      setSelectedAccessRecord(null);
      setShowSingleShare(false);
    }
    if (showSharingForm) {
      setShowSharingForm(false);
    }
    if (showPublicSharingForm) {
      setShowPublicSharingForm(false);
    }
    setShowDetailsPage(true);
  };

  const onInformationClick = () => {
    const InforMessage = () => (
      <div className={classes.dialogContent}>
        <p>
          Ved å dele boligmappen din privat vil den du deler med få tilgang og
          muligeheten til å gjøre endringer Det mest vanlige er deling mellom
          familiemedlemmer eller leietagere. Ved eierskifte, vil deling av
          boligmappa nullstilles.
        </p>
      </div>
    );

    return showActionDialog({
      closeable: true,
      showCancelButton: true,
      title: ' ',
      message: <InforMessage />,
    });
  };

  const onDeleteSuccess = () => {
    showSuccessMessage('Privat deling slettet.');
    trackMatomoEvent('Remove Administrator', 'Manage Administrators');
    fetchPrivateAccessData();
  };

  const onDeleteFail = (error) => {
    showErrorMessage(error);
  };

  // Delete for Desktop share details table
  const onDeleteShare = async (query, name) =>
    showActionDialog({
      submitButtonText: 'Stopp deling',
      submitButtonRed: true,
      title: 'Fjern administrator',
      message: `Er du sikker på at du vil fjerne ${
        name || ''
      } som administrator?  Personen vil miste alle tilganger til din boligmappe.`,
      onSubmit: async () => {
        const closeDialog = showLoadingDialog('Sletter...');

        const [error, response] = await apiDeletePrivateAccessData(
          boligmappaNumber,
          query
        );

        closeDialog();

        if (error && !response) {
          onDeleteFail(error);
        } else {
          onDeleteSuccess();
        }
      },
    });

  const onDesktopDelete = (record) => {
    const query = `${getStatus(record)}Id=${getId(record)}`;
    if (record) {
      onDeleteShare(query, record.name);
    }
  };

  const setTitle = () => {
    if (showSharingForm) {
      return 'Legg til administrator';
    }
    if (showSingleShare && selectedShareRecord) {
      return getDisplayName(selectedShareRecord);
    }
    return 'Deling og tilganger';
  };

  const postPrivateAccessData = async (data) => {
    setPrivateSharingInprogress(true);

    const [error, response] = await apiPostPrivateAccessData(
      boligmappaNumber,
      data
    );

    if (error && !response) {
      setSharingRequestError(error);
    } else {
      showSuccessMessage('Tilgang er sendt.');
      fetchPrivateAccessData();
      setShowDetailsPage(true);
      setShowSharingForm(false);
      setAdministratorsData(false);
    }
    trackMatomoEvent('Add Administrator', 'Manage Administrators');
    setPrivateSharingInprogress(false);
  };

  useEffect(() => {
    fetchPrivateAccessData();
    fetchPublicAccessData();
    setSearchText('');
  }, []);

  return (
    <PageLayout
      minFullHeight
      lessMarginLeft={isDesktop}
      maxWidth="lg"
      hideHeader={isDesktop}
      noPadding={!!(showSingleShare && !isDesktop)}
      headerTitle={setTitle()}
      backButtonAction={
        showSingleShare || showSharingForm || showPublicSharingForm
          ? backButtonAction
          : backToMoreMenu
      }
    >
      {fetching && (
        <div>
          <ContentLoader
            height={40}
            width={150}
            className={classes.titleLoader}
          />
          <ContentLoader
            width={90}
            height={20}
            className={classes.listItemLoader}
          />
          <ContentLoader
            width={200}
            height={20}
            className={classes.listItemLoader}
          />
          <ContentLoader
            width={130}
            height={30}
            className={classes.listItemLoader}
          />
        </div>
      )}
      {!fetching && (
        <>
          {fetchingError ? (
            <ErrorContainer
              paddingTop={isDesktop}
              errorResponse={fetchingError}
              style={{ paddingTop: !isDesktop ? '15%' : undefined }}
            />
          ) : (
            <>
              {!showPublicSharingForm && showDetailsPage && (
                <Details
                  publicAccessData={publicAccessData}
                  setShowPublicSharingForm={setShowPublicSharingForm}
                  togglePrivateSharingForm={togglePrivateSharingForm}
                  toggleEditPublicSharing={toggleEditPublicSharing}
                  stopPublicSharing={stopPublicSharing}
                  administratorsData={administratorsData}
                  shareData={privateAccessData}
                  fetching={fetching}
                  onDetailsClick={onDetailsClick}
                  onAccessRequestsDetailsClick={onAccessRequestsDetailsClick}
                  onDelete={onDeleteSuccess}
                  onInformationClick={onInformationClick}
                  onDeleteFail={onDeleteFail}
                  onDeleteShare={onDesktopDelete}
                  publicSharingUrl={`${protocol}//${host}${publicSharingUrl}`}
                />
              )}
              {showSharingForm && (
                <ShareForm
                  onSubmit={postPrivateAccessData}
                  onClose={() => toggleDetailsPrivateSharing()}
                  requestInProgress={privateSharingInprogress}
                  requestError={sharingRequestError}
                  isDesktop={isDesktop}
                />
              )}
              {showPublicSharingForm && (
                <>
                  <SharePublicForm
                    onSubmit={sharePublicly}
                    onCancel={() => toggleEditPublicSharing(false)}
                    requestInProgress={sharingRequestInProgress}
                    error={sharingError}
                    requestError={sharingRequestError}
                    fetchPublicAccessData={fetchPublicAccessData}
                  />
                </>
              )}
              {showSingleShare && selectedShareRecord && (
                <>
                  <Paper visible={!isDesktop}>
                    <SingleDetailsPage record={selectedShareRecord} />
                  </Paper>
                </>
              )}
              {showSingleShare && selectedAccessRecord && (
                <>
                  <Paper visible={!isDesktop}>
                    <SingleAccessRequestRecord record={selectedAccessRecord} />
                  </Paper>
                </>
              )}
            </>
          )}
        </>
      )}
    </PageLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setSearchText: (searchText) => dispatch(acSetDocumentSearchText(searchText)),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(Style)(PrivateShare));
