import moment from 'moment';

export const getPriceHistoryTooltipTitle = ([tooltip]) => {
  const date = moment(tooltip.label, 'MMM. DD, YYYY HH:mm:ss tt');
  const title = `${date.quarter()}. kvartal ${date.year()}`;

  return title;
};

export const getPriceHistoryTooltipFooter = ([tooltip]) => {
  const footer = `${tooltip.parsed.y >= 0 ? '+' : '-'} ${Math.abs(
    tooltip.parsed.y
  )}%`;

  return footer;
};

export const chartColors = {
  transparent: 'rgb(250, 250, 250, 0)',
  invisibleGrey: '#F1F1F9',
  text: '#8D8D8D',
  white: '#fff',
  darkPurple: 'rgba(69, 31, 150, 1)',
  lightRed: 'rgba(200,0,0,0.2)',
  lightOrange: 'rgb(255,234,221)',
  black: '#000000',
};

export const PRICE_HISTORY_DATA = {
  datasets: [
    {
      data: [],
      pointBorderColor: chartColors.transparent,
      pointBackgroundColor: chartColors.transparent,
      pointHoverBorderColor: chartColors.white,
    },
  ],
};

export const getSpacedNumber = (number) => {
  return number
    ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    : null;
};

export const barChartOptions = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      displayColors: false,
    },
  },
  scales: {
    y: {
      ticks: {
        autoSkip: false,
        color: chartColors.text,
        /* eslint-disable  */
        callback: function (value, index, ticks) {
          if (index === ticks.length - 1)
            return `< ${getSpacedNumber(this.getLabelForValue(value))}`;
          if (index === 0)
            return `> ${getSpacedNumber(this.getLabelForValue(value))}`;
          return getSpacedNumber(this.getLabelForValue(value));
        },
        /* eslint-enable  */
      },
      grid: {
        color: chartColors.invisibleGrey,
        tickColor: chartColors.transparent,
      },
    },
  },
};
