const styles = (theme) => ({
  insuranceContent: {
    color: '#000',
    marginBottom: '48px',

    '& h2': {
      fontSize: '36px',
      lineHeight: '55px',
      margin: 0,
    },
    '& img': {
      maxWidth: '100%',
    },

    '&.mobile': {
      padding: '0 16px',

      '& h2': {
        fontSize: '36px',
        lineHeight: '40px',
      },
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    fontWeight: 400,

    '&.mobile': {
      padding: 0,
    },
  },
  consultationBlock: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '20px',
    width: '100%',
    margin: '58px 0 48px',

    '& p': {
      fontSize: '20px',
      lineHeight: '24px',
      margin: 0,
    },

    '&.mobile': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '32px',
      margin: '39px 0 48px',
    },
  },
  consultationDesc: {
    marginBottom: '48px',

    '& p': {
      fontSize: '20px',
      lineHeight: '24px',
      margin: '16px 0',
    },
  },
  featuresBlock: {
    rowGap: '16px',
    width: '100%',

    '& p': {
      fontSize: '20px',
      lineHeight: '24px',
      margin: 0,
    },

    '&.mobile': {
      padding: 0,

      '& p': {
        fontSize: '18px',
      },
    },
  },
  features: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '100%',

    '&.mobile': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      rowGap: '16px',
    },
  },
  feature: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '35px',
    padding: '64px 0',

    '&.mobile': {
      padding: '16px 0',
      columnGap: '24px',
    },
  },
  featureText: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',

    '& h2': {
      fontWeight: 800,
      fontSize: '32px',
      lineHeight: '24px',
      margin: 0,
    },
    '& p': {
      color: '#111111',
      lineHeight: '24px',
      margin: 0,
    },

    '&.mobile': {
      rowGap: '8px',

      '& h2': {
        fontSize: '24px',
        lineHeight: '24px',
      },
      '& p': {
        fontSize: '18px',
      },
    },
  },
  checkedLabel: {
    width: '36px',
    height: '36px',
  },
  priceSectionRow: {
    display: 'flex',
    alignItems: 'flex-start',
    columnGap: '32px',
    marginTop: '32px',
    width: '100%',

    '&.mobile': {
      flexDirection: 'column',
      rowGap: '32px',
    },
  },
  priceBlock: {
    width: '100%',

    '& h3': {
      fontSize: '16px',
      margin: '0 0 24px',
    },
  },
  priceRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 0',
    color: '#000',
    borderBottom: '1px solid #E6E6E6',

    '&:first-child': {
      paddingTop: 'unset',
    },

    '& h4': {
      fontSize: '14px',
      lineHeight: '22px',
      margin: 0,
    },
    '& p': {
      fontSize: '14px',
      lineHeight: '24px',
      margin: 0,
    },
  },
  mainPriceRowLeft: {
    borderBottom: '2px solid #E6E6E6',

    '& h4': {
      fontSize: '16px',
      color: '#31777B',
    },
    '& p': {
      fontSize: '16px',
      color: '#31777B',
    },
  },
  mainPriceRowRight: {
    borderBottom: '2px solid #E6E6E6',

    '& h4': {
      fontSize: '16px',
      color: '#FD6765',
    },
    '& p': {
      fontSize: '16px',
      color: '#FD6765',
    },
  },
  remark: {
    fontSize: '12px',
    lineHeight: '18.62px',
    fontWeight: 500,
    color: '#717171',
    margin: '32px 0 0',
  },
  recommendations: {
    color: '#000',
    margin: '48px 0',

    '& p': {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 400,
      margin: '16px 0 0',
    },
  },
  formSection: {
    color: '#000',

    '& h2': {
      fontSize: '36px',
      margin: 0,
    },

    '& h3': {
      fontSize: '20px',
    },

    '&.mobile': {
      padding: '0 11.5px',

      '& > p': {
        fontSize: '20px',
        margin: '16px 0 32px',
      },
    },

    '& > p': {
      fontSize: '20px',
      margin: '16px 0 48px',
    },
  },
  insuranceForm: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '32px',

    '&.mobile': {
      flexDirection: 'column-reverse',
    },
  },
  formSide: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    width: '67%',

    '& p': {
      fontSize: '14px',
    },

    '&.mobile': {
      width: '100%',
    },
  },
  formAside: {
    display: 'flex',
    flexDirection: 'column',
    gap: '28px',
    width: '100%',
    maxWidth: '352px',

    '&.mobile': {
      maxWidth: 'unset',
    },
  },
  formBlock: {
    display: 'flex',
    flexDirection: 'column',
    gridColumn: 2,
    padding: '24px',
    backgroundColor: '#fff',
    borderRadius: '6px',

    '& h3': {
      margin: 0,
    },
  },
  asideFormBlock: {
    rowGap: '16px',
  },
  asideBlockText: {
    fontSize: '14px',
    lineHeight: '22px',
    margin: 0,
  },
  notification: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: '16px 0 0',
    padding: '16px',
    columnGap: '12px',
    backgroundColor: '#F2F8F8',
    borderRadius: '4px',

    '& p': {
      margin: 0,
    },

    '&.errors': {
      backgroundColor: '#FFF6F4',

      '& p': {
        color: '#FD6765',
        lineHeight: '24px',
      },
      '& ul': {
        paddingLeft: '20px',
        margin: 0,
      },
      '& li': {
        fontSize: '14px',
        lineHeight: '24px',
        color: '#111111',
      },
    },

    '&.formNotification': {
      marginBottom: '16px',
    },

    '&.warning': {
      width: '100%',
      padding: '24px',
      margin: '0 0 48px',
      flexDirection: 'column',
      rowGap: '4px',
      backgroundColor: '#FBF3C1',

      '& p': {
        fontSize: '20px',
        lineHeight: '30px',
      },
    },
  },
  perYear: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    rowGap: '16px',
  },
  textRow: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  list: {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#000',
    margin: 0,
    paddingLeft: '19px',

    '&.files': {
      '& a': {
        color: '#000',
      },
    },
  },
  pricePerYear: {
    fontSize: '24px',
    lineHeight: '24px',
    fontWeight: 800,
    margin: 0,
  },
  pricePerYearText: {
    lineHeight: '18px',
    fontSize: '14px',
    margin: 0,
  },
  PerYearText: {
    fontSize: '14px',
    margin: 0,
    color: '#111111',
  },
  checkbox: {
    fontSize: '14px',
    marginLeft: '-3px',

    '& p': {
      fontWeight: 400,
      lineHeight: '16px',
      margin: '0 0 0 8px',
    },
  },
  submitButton: {
    alignSelf: 'flex-start',
    backgroundColor: theme.palette.primary.blackGreen,
    marginTop: '16px',
  },
  formLeftBlock: {
    rowGap: '16px',

    '& h4': {
      fontSize: '16px',
      fontWeight: 500,
      margin: 0,
    },
  },
  formRow: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '32px',

    '&.mobile': {
      flexDirection: 'column-reverse',
      rowGap: '16px',
    },
  },
  dataBlock: {
    width: '100%',

    '& p': {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 500,
      margin: 0,
    },

    '&.mobile': {
      '& p': {
        fontSize: '16px',
      },
    },
  },
  companyInputRow: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
    cursor: 'pointer',

    '& p': {
      fontSize: '14px',
      lineHeight: '16px',
      color: '#13142A',
      fontWeight: 400,
      margin: 0,
    },
  },
  companyInput: {
    appearance: 'none',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    backgroundColor: 'unset',
    width: '16px',
    height: '16px',
    margin: 0,
    borderRadius: '50%',
    outline: '2px solid #717171',
    cursor: 'pointer',

    '&:focus': {
      outline: '2px solid #717171',
    },

    '&:checked': {
      backgroundColor: '#164144',
      border: '3px solid #fff',
      outline: '2px solid #164144',
    },
  },
  additionalInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    rowGap: '8px',
    maxWidth: '335px',

    '& textarea': {
      fontFamily: 'Sofia Pro',
      fontSize: '14px',
      padding: '12px 16px',
      borderColor: '#DDE2F1',
      borderRadius: '4px',
      width: '100%',
      height: '96px',
      resize: 'none',
    },

    '& p': {
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: '12px',
      color: '#73748F',
      width: '100%',
      margin: 0,
    },
  },
  textAreaLabel: {
    fontSize: '14px',
    lineHeight: '24px',
    margin: 0,
    color: '#292823',
  },
  checkboxRow: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    color: '#13142A',

    '& a': {
      color: '#13142A',
    },

    '&.mobile': {
      marginTop: '16px',
    },
  },
  downloadTerms: {
    maxWidth: '170px',
    fontSize: '11px',
  },
  inputField: {
    width: '100%',
    color: theme.palette.primary.greyBlack,

    '&.border': {
      borderTop: '1px solid #E6E6E6',
      paddingTop: '16px',
    },

    '& .input-wrapper': {
      marginBottom: '16px',
      borderColor: '#73748F',
    },

    '&.error .input-wrapper': {
      borderColor: '#FD6765',
    },
  },
  confirmationContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '48px',
    padding: '58px 0',
    margin: 0,

    '@media print': {
      rowGap: '20px',
      padding: 0,
    },

    '&.mobile': {
      padding: '43px 16px',
    },
  },
  textLink: {
    color: '#000000',

    '@media print': {
      textDecoration: 'unset',
    },
  },
  thankyouBlock: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '35px',
    maxWidth: '820px',
    padding: '24px 0',

    '& p': {
      fontSize: '20px',
      lineHeight: '20px',
      margin: 0,
    },

    '@media print': {
      rowGap: '15px',
      padding: '14px 0',
    },
  },
  confirmationChecked: {
    width: '32px',
    height: '32px',
    marginRight: '16px',
  },
  backButton: {
    alignSelf: 'flex-start',
    backgroundColor: theme.palette.primary.blackGreen,
    borderRadius: '50px',
    lineHeight: '24px',
    color: '#fff',
    textDecoration: 'unset',
    padding: '12px 24px',

    '@media print': {
      display: 'none',
    },
  },
  summary: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
  },
  summaryText: {
    fontSize: '20px',
    lineHeight: '24px',
    margin: 0,
  },
  summaryBlocks: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    gap: '32px',

    '@media print': {
      gap: '15px',
    },
  },
  summaryLabel: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 700,
    margin: '0 0 16px',
  },
  summaryInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '544px',
    padding: '24px 16px',
    backgroundColor: '#fff',
    borderRadius: '4px',

    '@media print': {
      pageBreakInside: 'avoid',
      maxWidth: 'unset',
    },

    '& .row': {
      display: 'flex',
      justifyContent: 'space-between',

      '& p': {
        fontSize: '16px',
        lineHeight: '24px',
        margin: '12px 0',

        '@media print': {
          margin: '6px 0',
        },
      },
    },
  },
  buttonsRow: {
    display: 'flex',
    columnGap: '10px',

    '@media print': {
      display: 'none',
    },
  },
  summaryButton: {
    fontSize: '16px',
    lineHeight: '24px',
    backgroundColor: '#fff',
    color: theme.palette.primary.greyBlack,
    border: '1.5px solid #D1EDE8',
    borderRadius: '50px',
    padding: '12px 24px',
    textDecoration: 'unset',

    '&:hover': {
      borderColor: '#111',
      backgroundColor: '#fff',
      textDecoration: 'unset',
    },

    '&:active': {
      backgroundColor: '#ccc',
    },
  },
  buttonIcon: {
    marginRight: '8px',
  },
  damageReport: {
    fontSize: '20px',
    lineHeight: '24px',
  },
});

export default styles;
