import React from 'react';

const Folder = (props) => (
  <svg
    viewBox="0 0 14.274 12.996"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <style>{`.folderIcon{fill:none;stroke:currentColor;stroke-miterlimit:10;stroke-width:0.5px;}`}</style>
    </defs>
    <g transform="translate(0.375 0.375)">
      <path
        className="folderIcon"
        transform="translate(-74.287 -213.248)"
        d="M78.907,214.927l-.341-1.029a.947.947,0,0,0-.9-.65H75.235a.947.947,0,0,0-.948.948v10.35a.948.948,0,0,0,.948.948H86.863a.948.948,0,0,0,.948-.948v-8.021a.947.947,0,0,0-.948-.948H79.807A.949.949,0,0,1,78.907,214.927Z"
      />
    </g>
  </svg>
);

export default Folder;
