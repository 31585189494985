import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Box from '../../atomic/Box';
import List from '../../atomic/List';
import Button from '../../atomic/Button';
import FilterListItem from './FilterListItem';

const FilterList = ({ classes, filters, onReset }) => (
  <Box className={classes.content}>
    <Box p={1} textAlign="right">
      <Button
        varinat="text"
        color="primary"
        onClick={onReset}
        className={classes.textButton}
      >
        <small>Nullstill</small>
      </Button>
    </Box>

    <List disablePadding>
      {filters.map((props) => (
        <FilterListItem key={`filter-item-${props.name}`} {...props} />
      ))}
    </List>
  </Box>
);

export default withStyles(styles)(FilterList);
