import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';
import { personalAreaNavbarMenu } from '../../../../structure/menu';
import MobileNavbarBase from '../../../../components/MobileNavbar';
import MobileNavItem from '../../../../components/MobileNavbarItem';
import useDashboardLinkPrefix from '../../../../hooks/useDashboardLinkPrefix';

import { isNativeEnv } from '../../../../utils/nativeBridge';

export const defaultIndex = 0;

const MobileNavbar = () => {
  const isNative = isNativeEnv();
  const menuItems = personalAreaNavbarMenu.filter(
    (menuItem) =>
      !menuItem.desktopOnly && !(isNative && menuItem.isDisabledInApp)
  );
  const { params } = useRouteMatch('/personal-area/dashboard/:location');
  const { location } = params;

  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const [activeIndex, setActiveIndex] = useState(
    menuItems.findIndex((menuItem) => menuItem.link === location) ||
      defaultIndex
  );

  const isActive = (index) => index === activeIndex;

  const onNavItemClick = (event, onClick, index) => {
    setActiveIndex(index);

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <MobileNavbarBase showLabels>
      {menuItems.map(({ link, icon, activeIcon, title, onClick }, index) => (
        <MobileNavItem
          icon={isActive(index) ? activeIcon : icon}
          title={title}
          isActive={isActive(index)}
          key={`bottom-nav-item-${link}`}
          to={`${dashboardLinkPrefix}/${link}`}
          onClick={(event) => onNavItemClick(event, onClick, index)}
        />
      ))}
    </MobileNavbarBase>
  );
};

export default MobileNavbar;
