const styles = () => ({
  overflowScroll: {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#707070',
      borderRadius: '10px',
    },
  },
  dialogDesktop: {
    minWidth: 600,
  },
  dialogPadding: {
    padding: '10px 100px 20px 100px',
  },
  dialogPaddingMobile: {
    padding: '10px 15px 0px 15px',
  },
});

export default styles;
