import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from '../../../style';
import IFrameBlock from '../../IFrame';

const OtovoComponent = ({
  description,
  title,
  url,
  bannerImageUrl,
  classes,
}) => {
  return (
    <div className={classes.descriptionComponentWrapper}>
      <div className={classes.descriptionWrapper}>
        <span className={classes.textDescription}>{description}</span>
        <IFrameBlock
          iFrame={{
            title,
            url,
          }}
        />
      </div>
      <div className={classes.imageBannerWrapper}>
        <img className={classes.imageBanner} src={bannerImageUrl} alt={title} />
      </div>
    </div>
  );
};

export default withStyles(styles)(OtovoComponent);
