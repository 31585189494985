import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import InsuranceBanner from '../Banners/InsuranceBanner/InsuranceBanner';
import {
  acSetEvents,
  acUpdateEvent,
  acSetFetchingEvents,
  acSetShowAddEvent,
} from '../../store/actions/events';

import { sGetEvents, sGetFetchingEvents } from '../../store/reducers/events';

import {
  acSetDocumentSearchText,
  acSetDocuments,
  acSetFetchingDocuments,
} from '../../store/actions/documents';

import {
  acSetRooms,
  acSetFetchingRooms,
  acSetFetchingRoomsError,
} from '../../store/actions/rooms';

import { sGetRooms, sGetFetchingRooms } from '../../store/reducers/rooms';

import Box from '../atomic/Box';
import useDesktop from '../../hooks/useDesktop';
import EventList from '../EventList';
import Page from '../layouts/WithHeader';
import useFetchEffect from '../../hooks/useFetchEffect';
import EmptyMessage from '../EmptyMessage';
import ErrorContainer from '../ErrorContainer';
import {
  fetchEvents as apiFetchEvents,
  fetchDocuments as apiFetchDocuments,
  fetchRooms,
} from '../../api/properties';
import { fetchPublicEvents as apiFetchPublicEvents } from '../../api/events';
import CreateEventSuggestion from '../CreateEventSuggestion';
import { AddNewButton } from '../Buttons';
import SearchField from '../SearchField';
import { getFilteredEvents } from '../../utils/events';
import Grid from '../atomic/Grid';
import { PUBLICSECTIONFORBIDDENERROR } from '../../constants';
import {
  sGetDocuments,
  sGetFetchingDocuments,
} from '../../store/reducers/documents';
import { sGetUxSignalStatus } from '../../store/reducers/uxSignal';
import matomo from '../../utils/matomo';

const EventsPage = ({
  fetching,
  setFetching,
  events,
  setEvents,
  updateEvent,
  setSearchText,
  setShowAddEvent,
  publicSection = false,
  enableImageUpload = false,
  truncatable = false,
  displayByYear = false,
  documents,
  setDocuments,
  setFetchingDocuments,
  fetchingDocuments,
  fetchingRooms,
  rooms,
  setFetchingRooms,
  setRooms,
  setFetchingRoomsError,
}) => {
  const isDesktop = useDesktop();
  const { boligmappaNumber } = useParams();

  const [searchQuery, setSearchQuery] = useState('');
  const filteredEvents = getFilteredEvents(events, searchQuery);

  const { fetchingError } = useFetchEffect({
    dependencies: [boligmappaNumber],
    setFetchingFunction: setFetching,
    onSuccess: (data) => setEvents(data),
    conditionFunction: () => !events && !fetching,
    apiFetchFunction: () =>
      publicSection
        ? apiFetchPublicEvents(boligmappaNumber)
        : apiFetchEvents(boligmappaNumber),
  });

  useFetchEffect({
    onFetchStart: () => setFetchingDocuments(true),
    onSuccess: (data) => setDocuments(data),
    setFetchingFunction: setFetchingDocuments,
    apiFetchFunction: () =>
      apiFetchDocuments(boligmappaNumber, { includeTaggedInfo: true }),
    conditionFunction: () => !documents && !fetchingDocuments && !publicSection,
  });

  useFetchEffect({
    defaultFetchingState: !rooms,
    setFetchingFunction: setFetchingRooms,
    onSuccess: (data) => setRooms(data),
    onError: (error) => setFetchingRoomsError(error),
    conditionFunction: () => !rooms && !fetchingRooms && !publicSection,
    apiFetchFunction: () => fetchRooms(boligmappaNumber),
  });

  const onEventUpdated = (updatedEvent) => {
    // Only update event in store if there are events loaded.
    // Otherwise updated event will be fetched along with all other events.
    if (events) {
      updateEvent(updatedEvent);
    }
  };

  useEffect(() => {
    setSearchText('');
  }, []);

  const onAddEvent = () => {
    setShowAddEvent(true);
  };

  const handleSearchQuery = (e) => {
    setSearchQuery(e.target.value);
    matomo.clickEvent({
      category: 'Events',
      action: 'Search for Event',
    });
  };

  const showEmptyListMessage =
    !fetching && Array.isArray(events) && events.length === 0;

  const renderContent = () => {
    if (fetchingError) {
      return publicSection ? (
        <ErrorContainer
          customErrorObject={{
            title: PUBLICSECTIONFORBIDDENERROR,
            message: '',
          }}
          paddingTop={isDesktop}
          errorResponse={fetchingError}
          style={{ paddingTop: isDesktop ? undefined : '15%' }}
        />
      ) : (
        <ErrorContainer
          paddingTop={isDesktop}
          errorResponse={fetchingError}
          style={{ paddingTop: isDesktop ? undefined : '15%' }}
        />
      );
    }

    if (showEmptyListMessage) {
      return publicSection ? (
        <EmptyMessage publicSection>Det finnes ingen hendelser.</EmptyMessage>
      ) : (
        <>
          <CreateEventSuggestion />

          <EmptyMessage onLinkClick={onAddEvent}>
            Du har ikke opprettet noen hendelser.
          </EmptyMessage>
        </>
      );
    }

    return (
      <>
        <InsuranceBanner />
        <Box display="flex" alignItems="end" px={isDesktop ? 0 : 2} mb={3}>
          <Box flex="50%" maxWidth={isDesktop ? '50%' : undefined}>
            <Box pt={!isDesktop ? 2 : 0}>
              <SearchField
                bordered
                value={searchQuery}
                placeholder="Søk etter hendelse"
                onChange={(e) => handleSearchQuery(e)}
              />
            </Box>
          </Box>

          {isDesktop && !publicSection && (
            <Box flex="30%" textAlign="right" ml={2}>
              <AddNewButton text="Opprett hendelse" onClick={onAddEvent} />
            </Box>
          )}
        </Box>
        <Grid container>
          <Grid item xs={12} lg={6}>
            {!publicSection && <CreateEventSuggestion />}

            <Box>
              <EventList
                truncatable={truncatable}
                displayByYear={displayByYear}
                events={filteredEvents}
                searchQuery={searchQuery}
                enableImageUpload={enableImageUpload}
                fetching={fetching}
                onEventUpdated={onEventUpdated}
              />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Page
      maxWidth="lg"
      headerTitle="Hendelser"
      topLevelLayout
      publicSection={publicSection}
    >
      {renderContent()}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  events: sGetEvents(state),
  fetching: sGetFetchingEvents(state),
  documents: sGetDocuments(state),
  fetchingDocuments: sGetFetchingDocuments(state),
  rooms: sGetRooms(state),
  fetchingRooms: sGetFetchingRooms(state),
  uxSignalStatus: sGetUxSignalStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
  setEvents: (events) => dispatch(acSetEvents(events)),
  updateEvent: (event) => dispatch(acUpdateEvent(event)),
  setFetching: (fetching) => dispatch(acSetFetchingEvents(fetching)),
  setSearchText: (searchText) => dispatch(acSetDocumentSearchText(searchText)),
  setShowAddEvent: (payload) => dispatch(acSetShowAddEvent(payload)),
  setDocuments: (documents) => dispatch(acSetDocuments(documents)),
  setFetchingDocuments: (fetching) =>
    dispatch(acSetFetchingDocuments(fetching)),
  setRooms: (rooms) => dispatch(acSetRooms(rooms)),
  setFetchingRooms: (fetchingRooms) =>
    dispatch(acSetFetchingRooms(fetchingRooms)),
  setFetchingRoomsError: (fetchingRoomsError) =>
    dispatch(acSetFetchingRoomsError(fetchingRoomsError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventsPage);
