import React from 'react';

const Minus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="2"
    viewBox="0 0 15 2"
  >
    <path
      id="Shape_1945"
      d="M467.937,6585.5h13.125a1,1,0,0,0,0-2H467.937a1,1,0,0,0,0,2Z"
      transform="translate(-467 -6583.501)"
      fill="#fff"
    />
  </svg>
);

export default Minus;
