import React from 'react';
import Box from '../../../../../../../../components/atomic/Box';
import Typography from '../../../../../../../../components/atomic/Typography';
import useDesktop from '../../../../../../../../hooks/useDesktop';
import {
  getYearIndexChange,
  transformPriceHistoryData,
} from '../../../../../../../../utils/charts';
import { usePriceHistoryStyles } from './style';
import { PriceHistoryYearChange } from '../PriceHistoryYearChange/PriceHistoryYearChange';
import { PriceHistoryPlace } from '../PriceHistoryPlace/PriceHistoryPlace';
import { PriceHistoryChart } from '../PriceHistoryChart/PriceHistoryChart';

export const PriceHistory = ({ priceHistory }) => {
  const isDesktop = useDesktop();
  const classes = usePriceHistoryStyles();
  const transformedData = transformPriceHistoryData(priceHistory, 5);
  const yearChange = getYearIndexChange(transformedData?.data);

  return (
    <>
      <Typography
        paragraph
        variant="h2"
        fontWeight={800}
        marginTop={false}
        align="left"
        fontSize={!isDesktop && 18}
      >
        Prisutvikling i området
      </Typography>

      <PriceHistoryPlace value={priceHistory.area} />

      <Box mb={1}>
        <Typography fontSize={13}>Endring siste år</Typography>
      </Box>
      <PriceHistoryYearChange value={yearChange} />

      <Box className={classes.chart} mb={3}>
        <PriceHistoryChart priceHistoryTransformed={transformedData} />
      </Box>

      <Box minHeight="75px">
        <Typography fontSize={13} fontWeight={400}>
          Her vises den historiske prisutviklingen i boligens nabolag. <br />
          Beregningen er basert på eiendomstransaksjoner i nabolaget.
        </Typography>
      </Box>
    </>
  );
};
