import { compareAlphabetically, toLowerCase, toUpperCase } from './strings';

export const getCompanyId = (company) =>
  company && company.companyId ? company.companyId : null;

export const getCompanyName = (company) =>
  company && company.companyName ? toUpperCase(company.companyName) : null;

export const getCompanyDisplayName = (company) =>
  company && company.displayName ? toUpperCase(company.displayName) : null;

export const getCompanyIndustryAssociations = (company) =>
  company && company.industryAssociations !== undefined
    ? company.industryAssociations
    : null;

export const getCompanyIndustryAssociation = (company) =>
  company &&
  Array.isArray(getCompanyIndustryAssociations(company)) &&
  getCompanyIndustryAssociations(company).length > 0
    ? getCompanyIndustryAssociations(company)[0]
    : null;

export const getCompanyFeatures = (company) =>
  company && company.features ? company.features : null;

export const getCompanyFeatureName = (feature) =>
  feature && feature.featureName ? feature.featureName : null;

export const getCompanyProfileImage = (company) =>
  company && company.companyProfileImage ? company.companyProfileImage : null;

export const getCompanyLogoPath = (company) =>
  company && company.companyLogoPath ? company.companyLogoPath : null;

export const getCompanyDescription = (company) =>
  company && company.companyDescription ? company.companyDescription : null;

export const getCompanyJobCount = (company) =>
  company && company.plantCount ? company.plantCount : null;

export const getCompanyUrl = (company) =>
  company && company.specialFeatureUrl ? company.specialFeatureUrl : null;

export const getCompanyWebSiteUrl = (company) =>
  company && company.companyWebsiteUrl ? company.companyWebsiteUrl : null;

export const getCompanyCertificates = (company) =>
  company && company.certificates ? company.certificates : null;

export const getCompanyAddress = (company) =>
  company && company.address ? company.address : null;

export const getCertificateName = (certificate) =>
  certificate && certificate.name ? certificate.name : null;

export const getCertificateLogoPath = (certificate) =>
  certificate && certificate.logoImage ? certificate.logoImage : null;

export const getCompanyEmail = (company) =>
  company && company.email ? company.email : null;

export const getCompanyPhone = (company) =>
  company && company.phone ? company.phone : null;

export const getCompanyFacebookUrl = (company) =>
  company && company.companyFacebookUrl ? company.companyFacebookUrl : null;

export const getCompanyContactPersons = (company) =>
  company && company.contactPersons !== undefined
    ? company.contactPersons
    : null;

export const getCompanyContactPerson = (company) =>
  company &&
  Array.isArray(getCompanyContactPersons(company)) &&
  getCompanyContactPersons(company).length > 0
    ? getCompanyContactPersons(company)[0]
    : null;

export const getContactPersonName = (company) =>
  company &&
  getCompanyContactPerson(company) &&
  getCompanyContactPerson(company).name
    ? getCompanyContactPerson(company).name
    : null;

export const getContactPersonDesignation = (company) =>
  company &&
  getCompanyContactPerson(company) &&
  getCompanyContactPerson(company).designation
    ? getCompanyContactPerson(company).designation
    : null;

export const getContactPersonImg = (company) =>
  company &&
  getCompanyContactPerson(company) &&
  getCompanyContactPerson(company).image
    ? getCompanyContactPerson(company).image
    : null;

export const getCompanyContactPersonEmail = (company) =>
  company &&
  getCompanyContactPerson(company) &&
  getCompanyContactPerson(company).email
    ? getCompanyContactPerson(company).email
    : getCompanyEmail(company);

export const getCompanyContactPersonPhone = (company) =>
  company &&
  getCompanyContactPerson(company) &&
  getCompanyContactPerson(company).phone
    ? getCompanyContactPerson(company).phone
    : getCompanyPhone(company);

export const getCompanyIsLicensed = (company) =>
  company && company.isLicensed !== undefined ? company.isLicensed : null;

export const getCompanyFranchise = (company) =>
  company && company.franchiseCompany !== undefined
    ? company.franchiseCompany
    : null;

export const filterByCompanyName = (company, query = '') => {
  const name =
    getCompanyDisplayName(company) ||
    getCompanyName(company) ||
    getCompanyDisplayName(getCompanyFranchise(company)) ||
    getCompanyName(getCompanyFranchise(company)) ||
    '';

  return toLowerCase(name).includes(toLowerCase(query));
};

export const getFilteredCompanies = ({ companies, searchQuery }) =>
  Array.isArray(companies)
    ? companies
        .filter((company) => filterByCompanyName(company, searchQuery))
        .sort((a, b) =>
          compareAlphabetically(getCompanyName(a), getCompanyName(b))
        )
    : companies;
