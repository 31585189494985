import { createTheme } from '@material-ui/core';

export const colors = {
  blackLight: '#666',

  greyDark: '#707070',
  grey: '#8d8d8d',
  greyLight: '#dedfdf',
  greyLighter: '#ccc',
  greyLightBlue: '#DDE2F1',
  greyLightBlack: '#E6E6E6',
  greyDarkBlue: '#73748F',
  greyGreen: '#1F4F53',
  greyBlack: '#13142A',
  darkBlack: '#111111',

  whiteDarker: '#ededed',
  whiteDark: '#f4f4f4',
  white: '#fff',

  mint: '#D9E9E9',
  biege: '#FFEDE0',
  black: '#333333',
  mintLight: '#F4F9F9',
  lightBlue: '#EEF1F9',

  red: '#FC6865',
  redLightDark: '#D7394C',
  redDark: '#be1e14',
  redLight: '#fecac9',
  redTransparent: '#F6D9D8',
  redLighter: '#fff6f6',

  green: '#13A085',
  darkGreen: '#254E52',
  blackGreen: '#164144',
  lightGreen: '#00AD43',
  lighterGreen: '#31777B',
  greenLighter: '#61AAB2',
  greenPositive: '#5DC9A2',
  grapeFruit: '#EB6767',
  orange: '#FBB03B',
  blue: '#527FCF',
};

export const palette = {
  primary: {
    ...colors,
    main: colors.darkGreen,
  },
  secondary: {
    main: colors.red,
  },
  background: {
    default: colors.white,
    paper: colors.white,
  },
  text: {
    primary: colors.black,
    secondary: colors.blackLight,
  },
};

export const typography = {
  fontSize: 16,
  useNextVariants: true,
  fontFamily: 'Sofia Pro, sans-serif',
};

export const shadows = [
  'none',
  '0 1px 1px rgba(0,0,0,0.1)',
  '0 3px 4px rgba(0,0,0,0.1)',
  '0 3px 6px rgba(0,0,0,0.1)',

  // default shadows further
  '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
  '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
  '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
  '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
  '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
  '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
  '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
  '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
  '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
  '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
  '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
  '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
  '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
  '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
  '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
  '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
  '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
  '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
  '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
  '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
];

export const shape = {
  borderRadius: 4,
};

const theme = createTheme({
  shape,
  colors,
  palette,
  shadows,
  typography,
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

theme.overrides = {
  MuiTypography: {
    h1: {
      fontWeight: 700,
      margin: '0.83em 0',
      fontSize: '2.125rem',
      color: palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        fontSize: '28px',
      },
    },
    h2: {
      fontWeight: 700,
      margin: '0.83em 0',
      fontSize: '1.5rem',
      color: palette.primary.main,
    },
    h3: {
      fontWeight: 700,
      margin: '0.83em 0',
      fontSize: '1.125rem',
      color: theme.palette.primary.main,
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    body1: {
      fontSize: '16px',
      fontWeight: 300,
    },
    body2: {
      fontSize: '16px',
      fontWeight: 300,
    },
  },
};

export default theme;
