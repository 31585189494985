import styled from 'styled-components';

export const StyledSectionCallToAction = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  column-gap: 32px;
  padding-bottom: 32px;
  margin-bottom: 32px;

  @media (max-width: 959px) {
    padding-bottom: 48px;
    margin-bottom: 48px;
  }

  .loyalty_cta:not(.collapsed) {
    position: inherit;

    @media (max-width: 959px) {
      position: fixed;
    }
  }

  .loyalty_content {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    width: fit-content;

    .loyalty_text {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }

    h2 {
      font-size: 24px;
      line-height: 37px;
      font-weight: 700;
      margin: 0;
    }

    p {
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      margin: 0;
    }

    .advantages_text {
      color: #356e3c;
      font-weight: 500;

      @media (max-width: 959px) {
        font-size: 16px;
      }
    }

    .loyalty_img {
      max-width: 100%;
    }
  }
`;
