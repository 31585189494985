import {
  ADD_EVENT,
  SET_EVENTS,
  CLEAR_EVENTS,
  DELETE_EVENT,
  UPDATE_EVENT,
  SYNC_EVENTS_ROOMS,
  SET_FETCHING_EVENTS,
  SYNC_EVENTS_DOCUMENTS,
  SET_FETCHING_EVENTS_ERROR,
  DETACH_DOCUMENT_FROM_EVENTS,
  SET_SHOW_EVENT_CREATION_SUGGESION,
  SET_SHOW_ADD_EVENT,
  SET_SHOW_EDIT_EVENT,
  SET_EVENT_MODE,
  SET_ATTACHED_IDS,
  SET_RETURN_ON_SUCCESS,
} from '../reducers/events';

export const acAddEvent = (event) => ({
  type: ADD_EVENT,
  payload: event,
});

export const acSetEvents = (events) => ({
  type: SET_EVENTS,
  payload: events,
});

export const acClearEvents = () => ({
  type: CLEAR_EVENTS,
});

export const acUpdateEvent = (event) => ({
  type: UPDATE_EVENT,
  payload: event,
});

export const acDeleteEvent = (eventId) => ({
  type: DELETE_EVENT,
  payload: { eventId },
});

export const acSyncEventsRooms = ({ room, roomId, eventIds }) => ({
  type: SYNC_EVENTS_ROOMS,
  payload: {
    room,
    roomId,
    eventIds,
  },
});

export const acSyncEventsDocuments = ({ eventIds, document, documentId }) => ({
  type: SYNC_EVENTS_DOCUMENTS,
  payload: {
    eventIds,
    document,
    documentId,
  },
});

export const acDetachDocumentFromEvents = (document) => ({
  type: DETACH_DOCUMENT_FROM_EVENTS,
  payload: document,
});

export const acSetFetchingEvents = (fetching) => ({
  payload: fetching,
  type: SET_FETCHING_EVENTS,
});

export const acSetFetchingEventsError = (error) => ({
  payload: error,
  type: SET_FETCHING_EVENTS_ERROR,
});

export const acSetShowEventCreateSuggesion = (boolean) => ({
  type: SET_SHOW_EVENT_CREATION_SUGGESION,
  payload: boolean,
});

export const acSetShowAddEvent = (boolean) => ({
  type: SET_SHOW_ADD_EVENT,
  payload: boolean,
});

export const acSetShowEditEvent = (boolean) => ({
  type: SET_SHOW_EDIT_EVENT,
  payload: boolean,
});

export const acSetEventMode = (mode) => ({
  type: SET_EVENT_MODE,
  payload: mode,
});

export const acSetEventAttachedIds = (ids) => ({
  type: SET_ATTACHED_IDS,
  payload: ids,
});

export const acSetEventReturnOnSuccess = (boolean) => ({
  type: SET_RETURN_ON_SUCCESS,
  payload: boolean,
});
