import {
  ACTIVE_PROPERTY_UID,
  LOCAL_PROPERTIES_CONFIGS,
  GOOGLE_STREET_VIEW_IMAGE_ENABLED,
} from '../constants';

import localStorage from './localStorage';
import { sortImagesByPosition } from './images';
import { getPropertyGoogleMapsImagePath } from './googleMaps';
import { compareAlphabetically, startsWithInteger, trim } from './strings';
import { RoomTypePropertyType } from './propertyRoomTypes';
import { enableGoogleStreetViewImage } from '../config';

export const getPropertyId = (property) => (property ? property.id : null);

export const getPropertyAddress = (property) =>
  property && property.address
    ? `${property.address.streetName} ${property.address.houseNumber || ''}${
        property.address.houseSubNumber || ''
      }`
    : null;

export const getCompleteAddress = (property) =>
  property
    ? `${property.address.streetName} ${property.address.houseNumber} , ${property.address.postalCode} ${property.address.postalPlace}`
    : null;

export const getPropertyGeoCode = (property) =>
  property && property.address && property.address?.geoCode
    ? property.address?.geoCode
    : null;

export const getPropertyZip = (property) =>
  property && property.address ? property.address.postalCode : null;

export const getPropertyPostalPlace = (property) =>
  property && property.address ? property.address.postalPlace : null;

export const getPropertyBoligmappaNumber = (property) =>
  property ? property.boligmappaNumber : null;

export const getPropertyTotalSpace = (property) =>
  property && property.propertyFacts ? property.propertyFacts.totalSpace : null;

export const getPropertyCustomTotalSpace = (property) =>
  property &&
  property.customPropertyFacts &&
  property.customPropertyFacts.primarySpace !== null
    ? property.customPropertyFacts.primarySpace
    : getPropertyTotalSpace(property);

export const getPropertyPrimarySpace = (property) =>
  property &&
  property.customPropertyFacts &&
  property.customPropertyFacts.primarySpace !== undefined
    ? property.customPropertyFacts.primarySpace
    : null;

export const getPropertyUnitNumber = (property) =>
  property && property.building ? property.building.unitNumber : null;

export const getPropertyImages = (property) =>
  property && property.gallery ? property.gallery : null;

export const getPropertyImagePath = (property) => {
  const images = getPropertyImages(property);

  if (!Array.isArray(images) || images.length === 0) {
    return null;
  }

  return images.slice().sort(sortImagesByPosition)[0].url;
};

export const getPropertyGoogleStreetImage = (property) =>
  getPropertyGoogleMapsImagePath(getPropertyGeoCode(property));

export const storeActivePropertyUid = (property) =>
  localStorage.set(ACTIVE_PROPERTY_UID, getPropertyBoligmappaNumber(property));

export const clearActivePropertyUid = () =>
  localStorage.remove(ACTIVE_PROPERTY_UID);

export const getImageUrl = (image) => (image ? image.url : null);

export const getPropertyMatrikkel = (property, separator = '/') =>
  property && property.cadastre
    ? [
        property.cadastre.knr,
        property.cadastre.gnr,
        property.cadastre.bnr,
        property.cadastre.fnr,
        property.cadastre.snr,
      ].join(separator)
    : null;

export const getPropertyBuildingId = (property) =>
  property && property.building ? property.building.mainBuildingNumber : null;

export const getPropertyShareNumber = (property) =>
  property && property.share ? property.share.shareNumber : null;

export const getPropertyShareOrgNumber = (property) =>
  property && property.share ? property.share.orgNumber : null;

export const getPropertyType = (property) =>
  property ? property.propertyType : null;

export const isFloorEnabledProperty = (propertyType) => {
  const pType = RoomTypePropertyType.find(
    (a) => a.propertyType === trim(propertyType).toUpperCase()
  );
  return pType ? pType.floorsEnable : true;
};

export const getValueEstimatePerSqm = (property) =>
  property && property.valueEstimate
    ? property.valueEstimate.valueestimatepersqm
    : null;

export const hasValueEstimate = (property) =>
  property && property.valueEstimate ? property.valueEstimate : null;

/** A property is defined as owned either based on being in an "owned" array from the API
 * or if the property containes "ownership: owned" */
export const isPropertyOwned = (property) =>
  property ? property.owned || property.ownership === 'owned' : null;

export const sortPropertiesByAddress = (a, b) => {
  if (
    startsWithInteger(getPropertyAddress(a)) &&
    !startsWithInteger(getPropertyAddress(b))
  ) {
    return 1;
  }

  if (
    !startsWithInteger(getPropertyAddress(a)) &&
    startsWithInteger(getPropertyAddress(b))
  ) {
    return -1;
  }

  return compareAlphabetically(getPropertyAddress(a), getPropertyAddress(b));
};

export const getPropertyOwners = (property) =>
  property && property.propertyOwners !== undefined
    ? property.propertyOwners
    : null;

export const getPropertyOwnerFirstname = (propertyOwner) =>
  propertyOwner && propertyOwner.firstName !== undefined
    ? propertyOwner.firstName
    : null;

export const getPropertyOwnerLastname = (propertyOwner) =>
  propertyOwner && propertyOwner.lastName !== undefined
    ? propertyOwner.lastName
    : null;

export const getPropertyOwnerFullname = (propertyOwner) =>
  propertyOwner && propertyOwner.fullName !== undefined
    ? propertyOwner.fullName
    : `${getPropertyOwnerFirstname(propertyOwner)} ${getPropertyOwnerLastname(
        propertyOwner
      )}`;

export const getPropertyOwnerSubscriptionStatus = (propertyOwner) =>
  propertyOwner && propertyOwner.isSubscribeToBoligmappaPlus !== null
    ? propertyOwner.isSubscribeToBoligmappaPlus
    : null;

export const getLocalPropertiesConfigs = () => {
  try {
    return JSON.parse(localStorage.get(LOCAL_PROPERTIES_CONFIGS));
  } catch (e) {
    return {};
  }
};

export const setLocalPropertiesConfigs = (configs) =>
  localStorage.set(LOCAL_PROPERTIES_CONFIGS, JSON.stringify(configs));

export const getLocalPropertyConfig = (boligmappaNumber) => {
  const configs = getLocalPropertiesConfigs();

  return configs && configs[boligmappaNumber]
    ? configs[boligmappaNumber]
    : null;
};

export const getRadonAreaLevel = (property) => {
  return property && property.radonAreaLevel ? property.radonAreaLevel : 0;
};

export const getPropertyRadonHighestAnnualAverage = (property) =>
  property && property.radonHighestAnnualAverage
    ? property.radonHighestAnnualAverage
    : null;

export const updateLocalPropertyConfig = (boligmappaNumber, config) => {
  const configs = getLocalPropertiesConfigs();

  setLocalPropertiesConfigs({
    ...configs,
    [boligmappaNumber]: {
      ...(getLocalPropertyConfig(boligmappaNumber) || {}),
      ...config,
    },
  });
};

export const getPropertyGoogleStreetViewPhotoEnabled = (boligmappaNumber) => {
  const config = getLocalPropertyConfig(boligmappaNumber);

  if (enableGoogleStreetViewImage === 'false') return false;

  if (config && config[GOOGLE_STREET_VIEW_IMAGE_ENABLED] !== undefined) {
    return config[GOOGLE_STREET_VIEW_IMAGE_ENABLED];
  }

  return true; // by default google street view photo is enabled
};

export const setPropertyGoogleStreetViewPhotoEnabled = (
  boligmappaNumber,
  photoEnabled
) =>
  updateLocalPropertyConfig(boligmappaNumber, {
    [GOOGLE_STREET_VIEW_IMAGE_ENABLED]: photoEnabled,
  });
