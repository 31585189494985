import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import {
  fetchPropertyOwner as apiFetchPropertyOwner,
  updatePropertyOwner as apiUpdatePropertyOwner,
  fetchConsents as apiFetchConsents,
  updateConsents as apiUpdateConsents,
} from '../../../../../api/propertyowners';
import styles from './style';
import Box from '../../../../../components/atomic/Box';
import useDesktop from '../../../../../hooks/useDesktop';
import useSnackbar from '../../../../../hooks/useSnackbar';
import Form from '../../../../../components/forms/BaseForm';
import CharAvatar from '../../../../../components/CharAvatar';
import useFetchEffect from '../../../../../hooks/useFetchEffect';
import TextField from '../../../../../components/atomic/TextField';
import Typography from '../../../../../components/atomic/Typography';
import ErrorContainer from '../../../../../components/ErrorContainer';
import PageLayout from '../../../../../components/layouts/WithHeader';
import ContentLoader from '../../../../../components/loaders/ContentLoader';
import { getPropertyOwnerFullName } from '../../../../../utils/propertyowners';
import {
  MARKETINGEMAIL,
  PROPERTYVALUEESTIMATEUPDATES,
} from '../../../../../constants';
import Checkbox from '../../../../../components/atomic/Checkbox';

export const defaultConsents = [
  {
    isGranted: false,
    type: MARKETINGEMAIL,
  },
];

export const getConsentByType = (consents, type) =>
  (consents || []).find((consent) => consent.type === type);

const Account = ({ classes }) => {
  const history = useHistory();
  const isDesktop = useDesktop();
  const { showSuccessMessage } = useSnackbar();

  const [requestError, setRequestError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const {
    fetching,
    fetchingError,
    data: propertyOwner,
    setData: setPropertyOwner,
  } = useFetchEffect({
    initialDataState: {},
    defaultDataState: {},
    initialFetchingState: true,
    apiFetchFunction: apiFetchPropertyOwner,
  });

  const {
    fetching: fetchingConsent,
    data: consents,
    setData: setConsents,
  } = useFetchEffect({
    initialFetchingState: true,
    initialDataState: defaultConsents,
    defaultDataState: defaultConsents,
    apiFetchFunction: apiFetchConsents,
    onError: (error) => setRequestError(error),
  });

  const { email, mobile } = propertyOwner || {};
  const propertyOwnerName = getPropertyOwnerFullName(propertyOwner);

  const setEmail = (value) =>
    setPropertyOwner({ ...propertyOwner, email: value });
  const setMobile = (value) =>
    setPropertyOwner({ ...propertyOwner, mobile: value });

  const onFormSubmit = async () => {
    setRequestError(null);
    setRequestInProgress(true);

    const [error, response] = await apiUpdatePropertyOwner({ email, mobile });

    const [errorConsent, consentResponse] = await apiUpdateConsents(consents);

    if ((error && !response) || (errorConsent && !consentResponse)) {
      setRequestError(error || errorConsent);
    } else {
      setPropertyOwner({
        ...propertyOwner,
        mobile,
        email,
      });

      showSuccessMessage();

      if (!isDesktop) {
        history.goBack();
      }
    }
    setRequestInProgress(false);
  };

  const onConsentsChange = (event, type) => {
    if (consents.find((consent) => consent.type === type)) {
      setConsents(
        consents.map((consent) =>
          type === consent.type
            ? { ...consent, isGranted: event.target.checked }
            : consent
        )
      );
    } else {
      setConsents([
        ...consents,
        {
          type,
          isGranted: event.target.checked,
        },
      ]);
    }
  };

  return (
    <PageLayout
      minFullHeight
      headerTitle={!isDesktop ? 'Dine personopplysninger' : ''}
      lessMarginLeft={isDesktop}
      maxWidth="lg"
    >
      {fetchingError ? (
        <ErrorContainer paddingTop={false} errorResponse={fetchingError} />
      ) : (
        <>
          {fetching ? (
            <>
              <ContentLoader
                style={{ margin: '27px 0' }}
                height={20}
                width={280}
              />
              <div style={{ display: 'flex' }}>
                <ContentLoader
                  width={60}
                  height={60}
                  variant="circle"
                  style={{ marginRight: '20px' }}
                />
                <div>
                  <ContentLoader
                    width={160}
                    height={20}
                    style={{ marginTop: '15px' }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {isDesktop && (
                <Typography className={classes.header} variant="h2">
                  Dine personopplysninger
                </Typography>
              )}
              <div style={{ display: 'flex' }}>
                <Box marginRight="20px">
                  <CharAvatar name={propertyOwnerName} />
                </Box>
                <div>
                  <h3
                    className={clsx(classes.mainBody, 'my-0 word-break')}
                    style={{ marginTop: '15px' }}
                  >
                    {propertyOwnerName}
                  </h3>
                </div>
              </div>
            </>
          )}

          <Form
            fetching={fetching}
            error={requestError}
            onSubmit={onFormSubmit}
            submitButtonText="Lagre"
            className={classes.form}
            requestInProgress={propertyOwner && requestInProgress}
            showCancelButton={false}
            actionButtonsClassName={classes.actionBtn}
            leftActions={isDesktop}
          >
            <TextField
              required
              className={classes.textFieldArea}
              value={email || ''}
              label="E-postadresse"
              disabled={fetching || requestInProgress}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                className: classes.textField,
              }}
            />
            <TextField
              label="Mobil"
              className={classes.textFieldArea}
              placeholder="Skriv inn ditt telefonnummer"
              value={mobile || ''}
              disabled={fetching || requestInProgress}
              onChange={(e) => setMobile(e.target.value)}
              InputProps={{
                className: classes.textField,
              }}
            />
            <Checkbox
              className={classes.checkboxTop}
              label={
                <span className={classes.checkBoxText}>
                  Få tips og triks på e-post som gjør det enklere, bedre og
                  billigere å eie bolig.
                </span>
              }
              disabled={fetchingConsent || requestInProgress}
              onChange={(e) => onConsentsChange(e, MARKETINGEMAIL)}
              checked={
                (
                  getConsentByType(consents, MARKETINGEMAIL) || {
                    isGranted: false,
                  }
                ).isGranted
              }
            />

            <Checkbox
              className={classes.checkboxBottom}
              label={
                <span className={classes.checkBoxText}>
                  Motta månedlig varsel på e-post med oppdatert prisestimat for
                  eiendommene dine.
                </span>
              }
              disabled={fetching || requestInProgress}
              onChange={(e) =>
                onConsentsChange(e, PROPERTYVALUEESTIMATEUPDATES)
              }
              checked={
                (
                  getConsentByType(consents, PROPERTYVALUEESTIMATEUPDATES) || {
                    isGranted: false,
                  }
                ).isGranted
              }
            />
          </Form>
        </>
      )}
    </PageLayout>
  );
};

export default withStyles(styles)(Account);
