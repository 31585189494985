import { useEffect, useState } from 'react';

/**
 * Hook for making a timer that lets us delay rendering
 */
export function useDelayRender(delayTime = 250) {
  const [shouldRender, setShouldRender] = useState(delayTime <= 0);

  useEffect(() => {
    if (delayTime <= 0) {
      return undefined;
    }
    const delay = setTimeout(() => {
      setShouldRender(true);
    }, delayTime);
    return () => {
      clearTimeout(delay);
    };
  }, []);

  return { shouldRender };
}
