const styles = (theme) => ({
  dialogTitle: {
    padding: 0,
    borderBottom: '1px solid #dedfdf',
    color: theme.palette.primary.black,
    background: theme.palette.primary.white,

    '@supports (padding-top: env(safe-area-inset-top))': {
      paddingTop: 'env(safe-area-inset-top)',
    },
  },
  BorderlessDialogTitle: {
    padding: 0,
    color: theme.palette.primary.black,
    background: theme.palette.primary.white,

    '@supports (padding-top: env(safe-area-inset-top))': {
      paddingTop: 'env(safe-area-inset-top)',
    },
  },
  dialogTitle_txt: {
    display: 'flex',
    justifyContent: 'start',
    width: '100%',
    marginLeft: '20px',
  },
  dialogTitle_txt_mobile: {
    width: '100%',
    textAlign: 'center',
    paddingBottom: 0,
    marginRight: '33px',
  },
});

export default styles;
