import React from 'react';

import {
  getFilterValue,
  onFilterChange,
} from '../../../../DocumentFilters/UploadedDateFilter/util';

import Box from '../../../../atomic/Box';
import TableHeaderCell from '../TableHeaderCell';
import FilterMenu from '../../Filters/FilterMenu';
import TextField from '../../../../atomic/TextField';
import useMenuState from '../../../../../hooks/useMenuState';

const TableHeaderUploadedDateFilter = ({
  name,
  description,
  filters = [],
  filterOptions,
  onFiltersReset,
  onFiltersChanged,
  ...props
}) => {
  const { onMenuOpen, onMenuClose, open, anchorEl } = useMenuState();

  return (
    <>
      <TableHeaderCell name={name} onClick={onMenuOpen} {...props} />

      <FilterMenu
        open={open}
        title={description}
        anchorEl={anchorEl}
        onClose={onMenuClose}
      >
        <Box p={2}>
          <TextField
            type="date"
            margin="normal"
            label="Startdato"
            value={getFilterValue('start', filters)}
            onChange={(e) =>
              onFilterChange(e, 'start', filters, onFiltersChanged)
            }
          />

          <TextField
            type="date"
            margin="normal"
            label="Sluttdato"
            value={getFilterValue('end', filters)}
            onChange={(e) =>
              onFilterChange(e, 'end', filters, onFiltersChanged)
            }
          />
        </Box>
      </FilterMenu>
    </>
  );
};

export default TableHeaderUploadedDateFilter;
