const styles = (theme) => ({
  notificationTag: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.red,
    borderRadius: '26px',
    color: '#ffff',
    textAlign: 'center',
    fontSize: '13px',
    padding: '0 10.5px',
    marginLeft: '3px',
  },
});

export default styles;
