import { useLocation } from 'react-router';
import { parseQuery } from '../utils/routes';

const useSearchQuery = () => {
  const { search } = useLocation();

  return parseQuery(search);
};

export default useSearchQuery;
