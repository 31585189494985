const sessionStorage = {
  set: (key, val) => {
    try {
      window.sessionStorage.setItem(key, val);
    } catch (err) {
      // ignore
    }
  },
  get: (key) => {
    try {
      return window.sessionStorage.getItem(key);
    } catch (err) {
      return undefined;
    }
  },
  remove: (key) => {
    try {
      window.sessionStorage.removeItem(key);
    } catch (err) {
      // ignore
    }
  },
  clear: () => {
    try {
      window.sessionStorage.clear();
    } catch (err) {
      // ignore
    }
  },
};

export default sessionStorage;
