import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import BoligmappaPlusLanding from '@componentsV2/BoligmappaPlusLanding';
import { withStyles } from '@material-ui/core';
import useDesktop from '@hooksV2/useDesktop';
import useSnackbar from '../../../../hooks/useSnackbar';
import styles from './style';
import WithHeader from '../../../../components/layouts/WithHeader';
import useDashboardLinkPrefix from '../../../../hooks/useDashboardLinkPrefix';
import { getPropertyOwnerSubscriptionStatus } from '../../../../utils/properties';
import matomo from '../../../../utils/matomo';
import { sGetUser } from '../../../../store/reducers/auth';
import { boligmappaPlusActionCallback } from '../../../../utils/boligmappaPlus';

const BoligmappaPlusPage = ({ classes, authUser }) => {
  const { showErrorMessage } = useSnackbar();
  const isDesktop = useDesktop();
  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const isBoligmappaPlusSub = getPropertyOwnerSubscriptionStatus(authUser);

  useEffect(() => {
    matomo.clickEvent({
      category: 'Boligmappa+',
      action: 'Rendered CTA',
      name: 'Rendered CTA on Boligmappa+ Landing Page',
    });
  }, []);

  const handleActionClick = async (type) => {
    await boligmappaPlusActionCallback(type, showErrorMessage);
  };

  return (
    <WithHeader topLevelLayout headerTitle="Boligmappa+" noPadding>
      <div className={classes.page}>
        <BoligmappaPlusLanding
          isBoligmappaPlusSub={isBoligmappaPlusSub}
          isDesktop={isDesktop}
          dashboardLinkPrefix={dashboardLinkPrefix}
          onActionClick={handleActionClick}
        />
      </div>
    </WithHeader>
  );
};

const mapStateToProps = (state) => ({
  authUser: sGetUser(state),
});

export default connect(mapStateToProps)(withStyles(styles)(BoligmappaPlusPage));
