import React from 'react';

const Info = ({
  width = '1.2rem',
  height = '1.2rem',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm0,4.792A1.042,1.042,0,1,1,8.958,5.833,1.043,1.043,0,0,1,10,4.792ZM11.667,15H8.333v-.833c.4-.149.833-.167.833-.613V9.832c0-.445-.43-.515-.833-.664V8.334h2.5v5.221c0,.446.431.465.833.613Z" />
  </svg>
);

export default Info;
