import React from 'react';

const WebIcon = ({ width = '27.842', height = '27.842', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 27.842 27.842"
    {...props}
  >
    <g id="pie-line-graph-desktop">
      <path
        id="Shape_49"
        d="M624.854,237.668a.463.463,0,0,0-.357-.168H620.85a.463.463,0,0,0-.357.168l-1.949,2.35a.464.464,0,0,0,.381.73h7.5a.464.464,0,0,0,.38-.73Z"
        transform="translate(-608.752 -212.906)"
        fill="#fff"
      />

      <path
        id="Shape_50"
        d="M612.74,217H637.1a1.788,1.788,0,0,1,1.74,1.832V238.37a1.788,1.788,0,0,1-1.74,1.832H612.74A1.788,1.788,0,0,1,611,238.37V218.832A1.788,1.788,0,0,1,612.74,217Zm23.2,18.317a.6.6,0,0,0,.58-.611V220.053a.6.6,0,0,0-.58-.611H613.9a.6.6,0,0,0-.58.611v14.654a.6.6,0,0,0,.58.611Z"
        transform="translate(-611 -217)"
        fill="#fff"
        fillRule="evenodd"
      />
    </g>
  </svg>
);

export default WebIcon;
