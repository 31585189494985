const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  icon: {
    fill: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  mobileItem: {
    padding: 0,
  },
});

export default styles;
