const styles = (theme) => ({
  selectedPropertyAddress: {
    bottom: '1px',
    marginRight: '10px',
    position: 'relative',
    fontSize: '14px',
    color: '#000',
  },
  select: {
    outline: '0',
    paddingRight: '10px',
  },
  heading: {
    padding: '25px 0 10px 20px',
  },
  menuItem: {
    padding: '10px',
    display: 'block',
    background: theme.palette.primary.whiteDark,
    borderBottom: `1px solid ${theme.palette.primary.greyLight}`,
  },
  propertyItem: {
    margin: 0,
    padding: 0,
    background: 'inherit',

    '&:last-child': {
      margin: 0,
    },
  },
  activeMenuItem: {
    padding: 0,
    display: 'block',
  },
  activePropertyItem: {
    cursor: 'default',
    padding: '15px 10px 15px 10px',
  },
  activePropertyImage: {
    width: '120px',
    height: '120px',
  },
  activePropertyAddress: {
    fontWeight: 700,
  },
  icon: {
    pointerEvents: 'all',
    position: 'relative',
  },
  iconOpen: {
    transform: 'rotate(180deg)',
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    marginLeft: '10px',
  },
  logo: {
    width: '32px',
    height: '32px',
    marginRight: '13px',
    display: 'contents',
  },
  logoImage: {
    width: '20px',
    height: '16.5px',
    position: 'relative',
  },
});

export default styles;
