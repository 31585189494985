import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './styles';

const NotificationsTag = ({ className, classes, qty }) => (
  <div className={`${classes.notificationTag} ${className}`}>
    <span>{qty}</span>
  </div>
);

export default withStyles(styles)(NotificationsTag);
