import { request } from '../utils/http';

export const fetchUxSignalData = (uxSignalStudyId) =>
  request({
    method: 'get',
    url: `study/id/${uxSignalStudyId}/active`,
    baseURL: 'https://api.uxsignals.com/v2/',
  })
    .then((response) => [null, response.data])
    .catch((error) => [error, null]);
