import clsx from 'clsx';
import React, { forwardRef, createElement } from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import BaseListItem from '../../atomic/ListItem';
import { fileNameToFileExtension } from '../../../utils/files';
import { getFileThumbNail } from '../../../utils/documents';

const ListItem = forwardRef(
  (
    {
      classes,
      className,
      primaryText,
      icon = null,
      secondaryText,
      actionComponent,
      iconContainerClass,
      ...props
    },
    ref
  ) => (
    <BaseListItem
      ref={ref}
      className={clsx(classes.listItem, className)}
      {...props}
    >
      <div className={classes.leftContainer}>
        <div className={clsx(classes.fileIconContainer, iconContainerClass)}>
          {icon ||
            createElement(
              getFileThumbNail(fileNameToFileExtension(primaryText))
            )}
        </div>
        <div className={classes.textContainer}>
          <div className={classes.primaryText}>{primaryText}</div>
          <div className={classes.secondaryText}>{secondaryText}</div>
        </div>
      </div>

      {actionComponent && (
        <div className={classes.rightContainer}>{actionComponent}</div>
      )}
    </BaseListItem>
  )
);

export default withStyles(styles)(ListItem);
