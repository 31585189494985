import React from 'react';

import useDesktop from '../../../../../../hooks/useDesktop';

import ImageForm from '../../../../../../components/forms/ImageForm';
import ErrorContainer from '../../../../../../components/ErrorContainer';
import PageLayout from '../../../../../../components/layouts/WithHeader';

const Images = ({ fetching, fetchingError }) => {
  const isDesktop = useDesktop();

  return (
    <PageLayout minFullHeight headerTitle="Bilder">
      {fetchingError ? (
        <ErrorContainer
          paddingTop={isDesktop}
          errorResponse={fetchingError}
          style={{ paddingTop: !isDesktop ? '15%' : undefined }}
        />
      ) : (
        <ImageForm fetching={fetching} />
      )}
    </PageLayout>
  );
};

export default Images;
