export const getStepByCategory = (steps, _category) =>
  steps?.find(({ category }) => category === _category);

export const getNextStep = (steps, currentStep) =>
  steps?.find(({ id }) => id === currentStep?.id + 1);

export const getPrevStep = (steps, currentStep) =>
  steps?.find(({ id }) => id === currentStep?.id - 1);

export const isFirstStep = (step, steps) => steps?.indexOf(step) === 0;

export const isLastStep = (step, steps) =>
  steps?.indexOf(step) === steps?.length - 1;

export const getFinalStep = (steps) => steps?.pop();

export const getStepsExceptFinalStep = (steps) => steps?.slice(0, -1);

export const getCompletedSteps = (steps) =>
  steps?.filter(({ completed }) => completed === true);

export const getCategoryCompleted = (categories, _category) =>
  categories.find(({ category }) => category === _category).completed;

export const getEventCategories = (steps) =>
  steps?.filter(({ section }) => section === 'EVENTS');
