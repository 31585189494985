import React from 'react';
import { withStyles } from '@material-ui/core';

import Checkbox from '../../../../atomic/Checkbox';
import TableCell from '../../../../atomic/TableCell';

import styles from './style';

const CheckboxCell = ({
  classes,
  onChange,
  isChecked,
  isVisible,
  disabled = false,
  style,
  ...props
}) => {
  return (
    <>
      {isVisible && (
        <TableCell className={classes.checkboxCell} {...props}>
          <Checkbox
            className={classes.checkbox}
            onChange={() => onChange()}
            checked={isChecked}
            disabled={disabled}
            style={style}
          />
        </TableCell>
      )}
    </>
  );
};

export default withStyles(styles)(CheckboxCell);
