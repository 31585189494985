const styles = (theme) => ({
  title: {
    margin: 0,
  },
  currentIndex: {
    display: 'block',
    marginBottom: '20px',
    color: theme.palette.primary.greyDark,
  },
  container: {
    minHeight: '100vh',
    paddingBottom: '70px',
    background: theme.palette.primary.white,
  },
});

export default styles;
