import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Card from '../../atomic/Card';
import ContentLoader from '../ContentLoader';

const iconSize = 75;

const CardItemLoading = ({ classes, props }) => (
  <Card className={classes.card} elevation={2} {...props}>
    <div>
      <ContentLoader
        width={iconSize}
        height={iconSize}
        className={classes.imageLoading}
      />
    </div>
    <div className={classes.textContainer}>
      <ContentLoader width={140} height={15} style={{ margin: '10px 0' }} />
      <ContentLoader width={80} height={15} />
    </div>
  </Card>
);

export default withStyles(styles)(CardItemLoading);
