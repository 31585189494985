import React, { createElement, useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { TableContainer } from '@mui/material';
import { useFlag } from '@unleash/proxy-client-react';
import clsx from 'clsx';

import SnackbarProvider from '../../../SnackbarProvider';
import DocumentItem from './TableRowItem';
import BaseTable from '../../../atomic/Table';
import Box from '../../../atomic/Box';
import TableRow from '../../../atomic/TableRow';
import TableHead from '../../../atomic/TableHead';
import TableBody from '../../../atomic/TableBody';
import TableCell from '../../../atomic/TableCell';
import Typography from '../../../atomic/Typography';
import Link from '../../../atomic/Link';
import SelectedFilters from '../SelectedFilters';
import CheckboxCell from './CheckboxCell';
import DocumentEditOptions from '../../../DocumentEditOptions';
import matomo from '../../../../utils/matomo';
import DocumentDownloadWarningIcon from '../../../../assets/icons/DocumentBulkEditIcons/DocumentDownloadWarning';
import DocumentDownloadSuccessIcon from '../../../../assets/icons/DocumentBulkEditIcons/DocumentDownloadSuccess';
import DocumentFielUploadItem from '../../../DocumentFileUploadItem';
import { acAddDocument } from '../../../../store/actions/documents';
import {
  acAddPersonalDocument,
  acAddPersonalFolder,
  acDeletePersonalDocumentFolder,
  acUpdatePersonalDocument,
} from '../../../../store/actions/personalDocuments';

import {
  areFiltersEmpty,
  getDocumentId,
  isDocumentReadOnly,
  getDocumentIsBuilding,
  getDocumentTypeId,
  isDocumentsFolder,
} from '../../../../utils/documents';
import { getDocTableHeaderItems } from '../../../DocumentEditOptions/utils';
import { FileUploadContext } from '../../../../contexts/fileUpload';
import useSnackbar from '../../../../hooks/useSnackbar';
import { acSyncRoomsDocuments } from '../../../../store/actions/rooms';
import {
  acSetShowEventCreateSuggesion,
  acSyncEventsDocuments,
} from '../../../../store/actions/events';

import Info from '../../../../assets/icons/Info';
import styles from './style';
import Close from '../../../../assets/icons/Close';
import IconButton from '../../../atomic/IconButton';
import EventCreateSuggesion from '../../../EventCreateSuggesion';
import { sGetShowEventCreateSuggesion } from '../../../../store/reducers/events';
import { sGetPersonalFoldersBreadCrumbs } from '../../../../store/reducers/personalDocuments';
import {
  updatePersonalDocument as apiUpdatePersonalDocument,
  updatePersonalDocumentDirectory,
} from '../../../../api/propertyowners';

const Table = ({
  filters,
  classes,
  sortType,
  documents,
  onFolderChange,
  directoryId,
  setDirectoryId,
  directoryTree,
  setDirectoryTree,
  searchQuery,
  publicSection,
  actionsEnabled,
  tableContainerRef,
  debouncedHandleScroll,
  onDocumentClick,
  onSortTypeChange,
  onFiltersChanged,
  sortingDirection,
  setSortingDirection,
  filteredDocuments,
  showBulkSelectionOptions,
  setShowEventCreateSuggesion,
  uploadedFiles,
  addDocument,
  addPersonalDocument,
  updatePersonalDocument,
  addPersonalFolder,
  deletePersonalDocumentFolder,
  setUploadedFiles,
  syncEventsDocuments,
  syncRoomsDocuments,
  showEventCreateSuggesion,
  personalFoldersBreadCrumbs,
  isPersonalDocumentView = false,
  isFromPersonal,
  isBoligmappaPlusUser,
}) => {
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [docTableHeight, setDocTableHeight] = useState(0);
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [showDocumentTypeWarning, setShowDocumentTypeWarning] = useState(true);
  const [docCountWithoutType, setDocCountWithoutType] = useState(0);
  const [mulitipleDocumentsSelected, setMulitipleDocumentsSelected] =
    useState(false);
  const [isDragged, setIsDragged] = useState(false);
  const [draggedItem, setDraggedItem] = useState({});

  const { showSuccessMessage, showErrorMessage } = useSnackbar();
  const enableSuggestNewEvent = useFlag('boligeier.suggest.new.event');
  const docTableHeaderItems = getDocTableHeaderItems(isPersonalDocumentView);

  const {
    setFiles,
    source,
    newUpload,
    docRoom,
    docEvent,
    setDocEvent,
    setDocRoom,
  } = useContext(FileUploadContext);

  const trackPersonalMatomoEvents = (action) => {
    matomo.clickEvent({
      category: 'Document handling',
      action,
    });
  };

  useEffect(() => {
    if (documents) {
      setSelectedDocuments(
        documents?.filter((doc) =>
          selectedDocuments?.some((selectedDoc) => selectedDoc.id === doc.id)
        )
      );
      setDocCountWithoutType(
        documents?.filter((x) => getDocumentTypeId(x) === 0).length
      );
    }
  }, [documents]);

  useEffect(() => {
    if (tableContainerRef.current && uploadedFiles.length > 0) {
      tableContainerRef.current.scrollTo(0, 0);
      setShowDocumentTypeWarning(true);
    }
  }, [uploadedFiles]);

  useEffect(() => {
    setMulitipleDocumentsSelected(true);
  }, []);

  useEffect(() => {
    if (selectedDocuments?.length > 0) {
      matomo.clickEvent({
        category: 'Document handling',
        name:
          documents.length === selectedDocuments.length
            ? 'Select all document'
            : 'Select single document ',
        action: 'Select Document from Document List',
        value: selectedDocuments?.length,
      });
    }
  }, [selectedDocuments?.length]);

  const handleLinkClick = () => {
    onFiltersChanged({ ...filters, DOCUMENT_TYPE_FILTER: ['Udefinert'] });
  };

  const onDocumentSelect = (value) => {
    setSelectedDocuments((currentState) => {
      if (currentState) {
        let tempSelectedDocuments = [...currentState];
        if (value?.id) {
          if (!tempSelectedDocuments.some((obj) => obj.id === value.id)) {
            tempSelectedDocuments.push(value);
          } else {
            tempSelectedDocuments = tempSelectedDocuments.filter(
              (obj) => obj.id !== value.id
            );
          }
          return tempSelectedDocuments;
        }
        return [];
      }
      return [];
    });
  };

  const onBulkDocumentSelect = () => {
    setSelectedDocuments((currentState) =>
      filteredDocuments
        .filter((item) => !isDocumentsFolder(item))
        .filter((item) => !isDocumentReadOnly(item))
        .filter((item) => !getDocumentIsBuilding(item)) > currentState
        ? filteredDocuments
            .filter((item) => !isDocumentsFolder(item))
            .filter((item) => !isDocumentReadOnly(item))
            .filter((item) => !getDocumentIsBuilding(item))
        : []
    );
  };
  const getBulkSelectionChecked = () => {
    if (selectedDocuments.length === 0) {
      return false;
    }
    return (
      selectedDocuments.length ===
      filteredDocuments
        ?.filter((item) => !isDocumentReadOnly(item))
        .filter((item) => !getDocumentIsBuilding(item)).length
    );
  };

  const handleDrag = (e) => {
    const tableTopEdge = tableContainerRef.current.getBoundingClientRect().top;
    const scrollMargin = 100;
    const scrollSpeed = 10;
    if (e.clientY < tableTopEdge + scrollMargin) {
      // Scroll up
      tableContainerRef.current.scrollBy(0, -scrollSpeed);
    } else if (e.clientY > window.innerHeight - scrollMargin) {
      // Scroll down
      tableContainerRef.current.scrollBy(0, scrollSpeed);
    }
  };

  const handleDragStart = (document) => {
    setDraggedItem(document);
    setIsDragged(true);
  };
  const handleDragEnd = () => {
    setIsDragged(false);
  };
  const handleDrop = async (destinationItem) => {
    if (
      destinationItem.id !== draggedItem.id &&
      isDocumentsFolder(destinationItem)
    ) {
      if (isDocumentsFolder(draggedItem)) {
        const patchData = {
          parentId: destinationItem.id,
        };
        const [requestError, response] = await updatePersonalDocumentDirectory(
          patchData,
          draggedItem.id
        );
        if (response && !requestError) {
          deletePersonalDocumentFolder(draggedItem);
          addPersonalFolder(
            [...personalFoldersBreadCrumbs, destinationItem],
            draggedItem
          );
          showSuccessMessage(
            `Flyttet "${draggedItem.title || draggedItem.name}" til "${
              destinationItem.name
            }"`
          );
        } else {
          showErrorMessage('Mappenavnet finnes allerede');
        }
      } else {
        const newDocData = {
          directoryId: destinationItem.id,
        };
        const [error, response] = await apiUpdatePersonalDocument(
          newDocData,
          draggedItem.id
        );
        if (!response && error) {
          showErrorMessage(error);
        } else {
          const folderId = newDocData.directoryId;
          updatePersonalDocument({ ...draggedItem, directoryId: folderId });
          showSuccessMessage(
            `Flyttet "${draggedItem.title || draggedItem.name}" til "${
              destinationItem.name
            }"`
          );
          trackPersonalMatomoEvents(
            'Move Single Personal Document to a Directory'
          );
        }
      }
    }
    setIsDragged(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const getTableHeader = () =>
    docTableHeaderItems.map(
      ({
        type,
        name,
        code,
        sortable,
        customAlign,
        getProps,
        sortUpType,
        filterable,
        sortDownType,
        desktopComponent,
        getFilterOptions,
        childrenComponent,
        isVisibleForPersonalDoc,
      }) =>
        createElement(desktopComponent, {
          name,
          type,
          sortType,
          sortable,
          customAlign,
          filterable,
          filters,
          sortUpType,
          sortDownType,
          onSortTypeChange,
          sortingDirection,
          childrenComponent,
          setSortingDirection,
          onBulkDocumentSelect,
          onChange: onBulkDocumentSelect,
          isVisible: showBulkSelectionOptions && isVisibleForPersonalDoc,
          isChecked: getBulkSelectionChecked(),
          key: `filter-option-${code ?? type}`,
          onFiltersChanged,
          onFiltersReset: () =>
            onFiltersChanged({
              ...filters,
              [type]: [],
            }),
          filterOptions:
            getFilterOptions &&
            getFilterOptions(documents, filters[type], (updatedFilters) =>
              onFiltersChanged({
                ...filters,
                [type]: updatedFilters,
              })
            ),
          ...(getProps ? getProps(filters, onFiltersChanged) : undefined),
        })
    );

  const onUploaded = (doc) => {
    setUploadedDocs((oldState) => [...oldState, doc]);
  };

  useEffect(() => {
    if (isDragged) {
      window.addEventListener('dragover', handleDrag);
    } else {
      window.removeEventListener('dragover', handleDrag);
    }

    return () => {
      window.removeEventListener('dragover', handleDrag);
    };
  }, [isDragged]);

  useEffect(() => {
    if (
      uploadedFiles.length > 0 &&
      uploadedDocs.length === uploadedFiles.length &&
      source === 'table'
    ) {
      setFiles([]);
      setUploadedFiles([]);
      setDirectoryTree([]);
      uploadedDocs.forEach((updoc) => {
        if (updoc.status) {
          const doc = updoc.data;

          if (isPersonalDocumentView) {
            matomo.clickEvent({
              category: 'Document handling',
              action: 'Personal document upload',
              name: 'Personal document upload',
            });
            addPersonalDocument(doc);
          } else addDocument(doc);

          if (docRoom) {
            syncRoomsDocuments({
              roomIds: doc?.roomIds,
              document: doc,
              documentId: doc?.id,
            });
          }

          if (docEvent) {
            syncEventsDocuments({
              eventIds: doc.eventIds,
              document: doc,
              documentId: doc?.id,
            });
          }

          setSelectedDocuments((currentState) => {
            if (currentState) {
              let tempSelectedDocuments = [...currentState];
              if (doc?.id) {
                if (!tempSelectedDocuments.some((obj) => obj.id === doc.id)) {
                  tempSelectedDocuments.push(doc);
                } else {
                  tempSelectedDocuments = tempSelectedDocuments.filter(
                    (obj) => obj.id !== doc.id
                  );
                }
                return tempSelectedDocuments;
              }
              return [];
            }
            return [];
          });
        }
      });

      if (
        uploadedDocs.filter((x) => x.status === true).length > 0 &&
        source === 'table'
      ) {
        showSuccessMessage(
          uploadedDocs.filter((x) => x.status === true).length > 1
            ? 'Dokumenter lastet opp'
            : 'Dokument lastet opp'
        );
      }

      if (enableSuggestNewEvent) {
        if (uploadedFiles?.length > 1 && !isPersonalDocumentView) {
          setShowEventCreateSuggesion(true);
        }
      }

      if (
        uploadedDocs.filter((x) => x.status === false).length > 0 &&
        source === 'table'
      ) {
        showErrorMessage(
          ` Noe gikk galt ved opplasting av ${uploadedDocs
            .filter((x) => x.status === false)
            .map(({ data }) => data)
            .join()} mangler dokumenttype. Se dokumentene.`
        );
      }
      setUploadedDocs([]);
      setUploadedFiles([]);
      setDocRoom(null);
      setDocEvent(null);
    }
  }, [uploadedDocs]);

  useEffect(() => {
    const updateComponentHeight = () => {
      const screenHeight = window.innerHeight - 200;
      setDocTableHeight(screenHeight);
    };
    window.addEventListener('resize', updateComponentHeight);
    updateComponentHeight();
    return () => {
      window.removeEventListener('resize', updateComponentHeight);
    };
  }, []);

  const onEventSuggesionClose = () => {
    setShowEventCreateSuggesion(false);
    matomo.clickEvent({
      category: 'Document handling',
      action: 'Suggest Event',
      name: 'Dismiss New Event from Suggest Event',
    });
  };

  return (
    <>
      <EventCreateSuggesion
        mulitipleDocumentsSelected={mulitipleDocumentsSelected}
        onClose={onEventSuggesionClose}
        documents={selectedDocuments}
        open={showEventCreateSuggesion}
      />
      <TableContainer
        ref={tableContainerRef}
        style={{ maxHeight: docTableHeight }}
        className={clsx([
          isPersonalDocumentView && classes.personalDocsTableContainer,
        ])}
        onScroll={debouncedHandleScroll}
      >
        <SnackbarProvider
          hideIconVariant={false}
          preventDuplicate={false}
          closeButton
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{
            root: classes.snackbar,
            success: classes.success,
          }}
          iconVariant={{
            success: <DocumentDownloadSuccessIcon />,
            warning: <DocumentDownloadWarningIcon />,
          }}
        >
          <BaseTable className={classes.root} stickyHeader>
            <TableHead>
              <TableRow>
                {selectedDocuments?.length === 0 && <>{getTableHeader()}</>}
                {selectedDocuments?.length > 0 && (
                  <TableCell
                    colspan={selectedDocuments?.length > 0 && 9}
                    padding="none"
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CheckboxCell
                        getBulkSelectionChecked={getBulkSelectionChecked}
                        isChecked={getBulkSelectionChecked}
                        isVisible={showBulkSelectionOptions}
                        onChange={onBulkDocumentSelect}
                        className={classes.bulkCheckboxCell}
                        style={{ padding: 0 }}
                      />
                      <p className={classes.selectedDocCount}>
                        {selectedDocuments?.length > 0
                          ? `${selectedDocuments?.length} valgt`
                          : ''}
                      </p>

                      <DocumentEditOptions
                        selectedDocuments={selectedDocuments}
                        isPersonalDocuments={isPersonalDocumentView}
                        clearSelectedDocuments={() => setSelectedDocuments([])}
                        isFromPersonal={isFromPersonal}
                        isBoligmappaPlusUser={isBoligmappaPlusUser}
                      />
                    </Box>
                  </TableCell>
                )}
              </TableRow>

              <TableRow>
                <TableCell
                  padding="none"
                  colSpan={docTableHeaderItems.length + 1}
                  className={clsx(
                    showBulkSelectionOptions ? classes.filtersHeaderRow : null,
                    areFiltersEmpty(filters) ? classes.borderNone : null
                  )}
                >
                  <SelectedFilters
                    filters={filters}
                    borderBottom={false}
                    onFiltersChanged={onFiltersChanged}
                  />
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {showDocumentTypeWarning &&
                !isPersonalDocumentView &&
                docCountWithoutType > 0 &&
                actionsEnabled && (
                  <TableRow className={classes.uploadMessageRow}>
                    <TableCell
                      className={classes.uploadMessageBlock}
                      colSpan={docTableHeaderItems.length + 1}
                    >
                      <Box className={classes.uploadMessageContainer}>
                        <div className={classes.infoIcon}>
                          <Info width="20" height="20" />
                        </div>
                        <Typography className={classes.uploadMessage}>
                          Du har{' '}
                          <span style={{ fontWeight: 700 }}>
                            {docCountWithoutType} dokumenter{' '}
                          </span>
                          som mangler dokumenttype.{' '}
                          <Link
                            to="#"
                            className={classes.linkButton}
                            onClick={handleLinkClick}
                          >
                            Se dokumentene.
                          </Link>
                        </Typography>
                        <IconButton
                          className={classes.closeIconButton}
                          onClick={() => {
                            setShowDocumentTypeWarning(false);
                          }}
                        >
                          <Close className={classes.closeIcon} />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}

              {Array.isArray(filteredDocuments) &&
                filteredDocuments.length === 0 &&
                uploadedFiles.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={docTableHeaderItems.length + 1}>
                      <Typography align="center" color="textSecondary">
                        Ingen dokument funnet
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              {Array.isArray(uploadedFiles) &&
                uploadedFiles.length > 0 &&
                source === 'table' &&
                uploadedFiles.map((file) => (
                  <DocumentFielUploadItem
                    file={file}
                    directoryId={directoryId}
                    setDirectoryId={setDirectoryId}
                    directoryTree={directoryTree}
                    newUpload={newUpload}
                    span={docTableHeaderItems.length + 1}
                    onUploaded={onUploaded}
                    room={docRoom}
                    event={docEvent}
                    personalDocument={isPersonalDocumentView}
                  />
                ))}
              {Array.isArray(documents) &&
                filteredDocuments.length > 0 &&
                filteredDocuments.map((document) => (
                  <DocumentItem
                    document={document}
                    onFolderChange={onFolderChange}
                    newUpload={newUpload}
                    searchQuery={searchQuery}
                    publicSection={publicSection}
                    actionsEnabled={actionsEnabled}
                    onDocumentClick={onDocumentClick}
                    key={`document-item-${getDocumentId(document)}`}
                    onSelect={(values) => onDocumentSelect(values)}
                    selectedDocuments={selectedDocuments}
                    showBulkSelectionOptions={showBulkSelectionOptions}
                    isPersonalDocuments={isPersonalDocumentView}
                    isFromPersonal={isFromPersonal}
                    draggableListItem={isPersonalDocumentView}
                    handleDragStart={handleDragStart}
                    handleDragEnd={handleDragEnd}
                    handleDragOver={handleDragOver}
                    handleDrop={handleDrop}
                    isParentDragged={isDragged}
                  />
                ))}
            </TableBody>
          </BaseTable>
        </SnackbarProvider>
      </TableContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  showEventCreateSuggesion: sGetShowEventCreateSuggesion(state),
  personalFoldersBreadCrumbs: sGetPersonalFoldersBreadCrumbs(state),
});

const mapDispatchToProps = (dispatch) => ({
  addDocument: (document) => dispatch(acAddDocument(document)),
  addPersonalDocument: (document) => dispatch(acAddPersonalDocument(document)),
  syncRoomsDocuments: (payload) => dispatch(acSyncRoomsDocuments(payload)),
  syncEventsDocuments: (payload) => dispatch(acSyncEventsDocuments(payload)),
  setShowEventCreateSuggesion: (payload) =>
    dispatch(acSetShowEventCreateSuggesion(payload)),
  updatePersonalDocument: (payload) =>
    dispatch(acUpdatePersonalDocument(payload)),
  addPersonalFolder: (breadCrumbs, response) =>
    dispatch(acAddPersonalFolder(breadCrumbs, response)),
  deletePersonalDocumentFolder: (folder) =>
    dispatch(acDeletePersonalDocumentFolder(folder)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Table));
