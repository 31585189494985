import React from 'react';

const Roof = ({ width = '25px', height = '25px', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 24.5C19.6274 24.5 25 19.1274 25 12.5C25 5.87258 19.6274 0.5 13 0.5C6.37258 0.5 1 5.87258 1 12.5C1 19.1274 6.37258 24.5 13 24.5Z"
      fill="#D9E9E9"
    />
    <path
      d="M12.9998 23.8141C19.2485 23.8141 24.3141 18.7485 24.3141 12.4998C24.3141 6.25112 19.2485 1.18555 12.9998 1.18555C6.75112 1.18555 1.68555 6.25112 1.68555 12.4998C1.68555 18.7485 6.75112 23.8141 12.9998 23.8141Z"
      stroke="white"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7344 15.7608L17.3024 10.3101C17.277 10.2697 17.2418 10.2365 17.2001 10.2134C17.1583 10.1902 17.1115 10.178 17.0638 10.1777H8.32436C8.28156 10.1777 8.23944 10.1886 8.20192 10.2092C8.16441 10.2298 8.1327 10.2595 8.10974 10.2957L4.91774 15.37C4.89365 15.4084 4.88028 15.4526 4.87901 15.4979C4.87773 15.5432 4.88861 15.5881 4.9105 15.6278C4.93239 15.6676 4.9645 15.7007 5.00351 15.7238C5.04252 15.747 5.08701 15.7593 5.13236 15.7594H13.5666C13.6096 15.7592 13.6517 15.7481 13.6892 15.7272C13.7267 15.7064 13.7584 15.6764 13.7813 15.6401L17.1996 10.2106"
      fill="#254E52"
    />
    <path
      d="M20.7344 15.7608L17.3024 10.3101C17.277 10.2697 17.2418 10.2365 17.2001 10.2134C17.1583 10.1902 17.1115 10.178 17.0638 10.1777H8.32436C8.28156 10.1777 8.23944 10.1886 8.20192 10.2092C8.16441 10.2298 8.1327 10.2595 8.10974 10.2957L4.91774 15.37C4.89365 15.4084 4.88028 15.4526 4.87901 15.4979C4.87773 15.5432 4.88861 15.5881 4.9105 15.6278C4.93239 15.6676 4.9645 15.7007 5.00351 15.7238C5.04252 15.747 5.08701 15.7593 5.13236 15.7594H13.5666C13.6096 15.7592 13.6517 15.7481 13.6892 15.7272C13.7267 15.7064 13.7584 15.6764 13.7813 15.6401L17.1996 10.2106"
      stroke="#254E52"
      strokeMiterlimit="10"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9371 8.60621H9.82171C9.79376 8.60657 9.76708 8.61793 9.74744 8.63782C9.7278 8.65771 9.71679 8.68454 9.7168 8.71249V8.91821C9.7168 8.94604 9.72785 8.97272 9.74753 8.99239C9.7672 9.01207 9.79389 9.02312 9.82171 9.02312H9.86148V9.89124H11.9042V9.02724H11.944C11.958 9.02689 11.9717 9.02376 11.9844 9.01802C11.9972 9.01229 12.0086 9.00408 12.0181 8.99386C12.0277 8.98364 12.035 8.97162 12.0399 8.95851C12.0447 8.94541 12.0468 8.93147 12.0462 8.91752V8.71181C12.0462 8.69756 12.0433 8.68345 12.0378 8.67033C12.0322 8.6572 12.0241 8.64533 12.0138 8.63541C12.0036 8.6255 11.9915 8.61774 11.9782 8.61261C11.9649 8.60747 11.9507 8.60506 11.9365 8.60552L11.9371 8.60621Z"
      fill="#254E52"
    />
  </svg>
);

export default Roof;
