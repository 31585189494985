import React from 'react';

const ConnectNewDocument = ({
  width = '1.2rem',
  height = '1.2rem',
  fill = '#FC6865',
  ...props
}) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M23.414,3,21,.586A2,2,0,0,0,19.586,0H8A2,2,0,0,0,6,2V9.273a.249.249,0,0,0,.246.25A14.34,14.34,0,0,1,7.711,9.6.247.247,0,0,0,8,9.353V2.5A.5.5,0,0,1,8.5,2H19.379a.5.5,0,0,1,.353.146l2.122,2.122A.5.5,0,0,1,22,4.621V18a.5.5,0,0,1-.5.5H14.642a.244.244,0,0,0-.241.21,7.931,7.931,0,0,1-.364,1.458.242.242,0,0,0,.225.331h7.745A1.994,1.994,0,0,0,24,18.5V4.415A2,2,0,0,0,23.414,3Z" />
    <path
      fillRule="evenodd"
      d="M0,17.5A6.5,6.5,0,1,1,6.5,24,6.5,6.5,0,0,1,0,17.5Zm7.5.75H9a.75.75,0,0,0,0-1.5H7.5a.25.25,0,0,1-.25-.25V15a.75.75,0,0,0-1.5,0v1.5a.25.25,0,0,1-.25.25H4a.75.75,0,0,0,0,1.5H5.5a.25.25,0,0,1,.25.25V20a.75.75,0,0,0,1.5,0V18.5A.25.25,0,0,1,7.5,18.25Z"
    />
  </svg>
);

export default ConnectNewDocument;
