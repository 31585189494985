import React from 'react';

const OtovoIconActive = (props) => (
  <svg
    width={20}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <style>{`.otovoIcon{fill:transparent;stroke:currentColor;stroke-miterlimit:10;stroke-width:1.5px;}`}</style>
    </defs>
    <path
      className="otovoIcon"
      d="M14.167 15.633a4.167 4.167 0 1 0-8.334 0M10 2.3v5.834M6.825 4.959l3.15-3.125 3.2 3.174M3.517 9.15 4.7 10.334M.833 15.633H2.5M17.5 15.633h1.667M15.3 10.334l1.183-1.184M19.167 18.967H.833"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default OtovoIconActive;
