const styles = (theme) => ({
  desktopContainer: {
    minHeight: '680px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '500px',
  },
  mobileContainer: {
    minHeight: '680px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    width: '146px',
    height: '107px',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  infoText: {
    width: '320px',
    textAlign: 'center',
    color: theme.palette.text.primary,
    lineHeight: 2,
    marginBottom: theme.spacing(7),
  },
  getStartedBtn: {
    width: '250px',
    height: '50px',
    backgroundColor: theme.colors.red,
    marginBottom: theme.spacing(9),
    '&:hover': {
      backgroundColor: theme.colors.red,
    },
  },
  animationContainer: {
    marginTop: '100px',
  },
});

export default styles;
