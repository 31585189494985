import React, { useState } from 'react';
import { tooltipStyles } from './styles';
import Tooltip from '../Tooltip';
import InfoIcon from '../../assets/icons/Info';
import IconButton from '../atomic/IconButton';

export default function InfoIconTooltip({
  children,
  classes = tooltipStyles(),
  placement = '',
  // if disableHoverListener = true, the tooltip only appears and dissapears on click
  // this is necessary if we want to be able to click something inside the tooltip
  disableHoverListener = false,
  isInteractive = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleTooltip = () => setIsOpen((prev) => !prev);

  // MUI sets 'pointer-events: none' to the content inside tooltips,
  // so we need this to make sure the content is interactive.
  const content = isInteractive ? (
    <div className={classes.interactiveWrapper}>{children}</div>
  ) : (
    children
  );
  return (
    // passing in children to title, cause title is the "content" of the tooltip
    <Tooltip
      title={content}
      classes={{ tooltip: classes.tooltip }}
      placement={placement}
      fontSize={13}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      disableHoverListener={disableHoverListener}
    >
      <IconButton onClick={toggleTooltip} className={classes.infoIconStyle}>
        <InfoIcon width="15px" height="15px" />
      </IconButton>
    </Tooltip>
  );
}
