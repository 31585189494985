const styles = (theme) => ({
  container: {
    display: 'block',
    padding: '15px 16px 15px 0',
    borderBottom: `1px solid ${theme.palette.primary.whiteDarker}`,
  },
  selected: {
    background: theme.palette.primary.redLighter,
  },
  iconsContainer: {
    width: '40px',
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
  },
  ballIcon: {
    width: '1rem',
    height: '1rem',
    color: theme.palette.primary.green,
  },
  attachmentIcon: {
    width: '1rem',
    height: '1rem',
    fill: theme.palette.primary.grey,
  },
  titleContainer: {
    display: 'flex',
    marginBottom: '5px',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  message: {
    marginTop: 0,
    fontSize: '0.85rem',
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  date: {
    color: theme.palette.primary.grey,
  },
  arrowIcon: {
    width: '15px',
    height: '15px',
    marginLeft: '3px',
    color: theme.palette.primary.grey,
  },
});

export default styles;
