import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

import styles from './style';
import FileListItem from '../../../../../AddDocument/FileListItem';
import List from '../../../../../../../../components/atomic/List';
import Typography from '../../../../../../../../components/atomic/Typography';
import ProgressButton from '../../../../../../../../components/ProgressButton';
import useDesktop from '../../../../../../../../hooks/useDesktop';
import Box from '../../../../../../../../components/atomic/Box';

const FileChoice = ({ files, classes, setFiles, onSubmit }) => {
  const isDesktop = useDesktop();

  const onDocumentUnselect = (fileIndex) => {
    const updatedFiles = files.filter((file, index) => index !== fileIndex);

    setFiles(updatedFiles);
  };

  return (
    <Box className={classes.container}>
      <Box
        className={clsx(
          isDesktop ? classes.paddingDesktop : classes.paddingMobile,
          classes.content
        )}
      >
        <List>
          {files.map((file, index) => (
            <FileListItem
              file={file}
              index={index}
              key={`file-item-${file.name}`}
              onUnselect={onDocumentUnselect}
            />
          ))}
          {Array.isArray(files) && files.length === 0 && (
            <Typography color="textSecondary">Ingen filer valgt</Typography>
          )}
        </List>
      </Box>

      <Box
        className={clsx(
          isDesktop ? classes.actionButtons : classes.actionButtonsMobile,
          classes.actionButtonsContainer
        )}
      >
        <ProgressButton
          className={isDesktop ? classes.nextBtn : classes.nextBtnMobile}
          type="submit"
          color="primary"
          variant="contained"
          onClick={onSubmit}
        >
          Neste
        </ProgressButton>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(FileChoice);
