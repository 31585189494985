import React, { forwardRef } from 'react';

import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import BaseButton from '@material-ui/core/Button';

import styles from './style';

const Button = forwardRef(
  ({ classes, children, underline, className, ...props }, ref) => (
    <BaseButton
      className={clsx(className, classes.button)}
      ref={ref}
      {...props}
    >
      {children}
    </BaseButton>
  )
);

export default withStyles(styles)(Button);
