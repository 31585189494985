import React from 'react';

const Badge = ({ width = '12.08', height = '20', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.252 0.507812L12.2476 2.38281L14.377 2.64156L12.8145 4.04969L13.127 6.13281L11.252 4.96094L9.37695 6.13281L9.68945 4.04969L8.12695 2.64156L10.3145 2.38281L11.252 0.507812Z"
      fill="black"
    />
    <path
      d="M11.0751 7.53861L9.86383 7.22736C9.64671 8.06376 9.18657 8.81704 8.54153 9.39204C7.89649 9.96705 7.09549 10.338 6.23974 10.4579C5.38399 10.5779 4.51188 10.4416 3.73359 10.0661C2.95529 9.69069 2.30573 9.09299 1.86697 8.34855C1.42821 7.60411 1.21994 6.74632 1.26846 5.88357C1.31699 5.02081 1.62014 4.19179 2.1396 3.50124C2.65907 2.81069 3.37156 2.2896 4.18705 2.0038C5.00253 1.71799 5.88443 1.6803 6.72133 1.89548L7.03321 0.684858C5.8132 0.367516 4.52191 0.469659 3.36697 0.974865C2.21202 1.48007 1.26052 2.35899 0.665441 3.4703C0.0703646 4.58162 -0.13371 5.86076 0.0860273 7.10207C0.305765 8.34338 0.936548 9.47473 1.87696 10.3142V18.008L5.62696 15.508L9.37696 18.008V10.3255C10.207 9.58565 10.7982 8.61551 11.0751 7.53861ZM8.12696 15.6724L5.62696 14.0055L3.12696 15.6724V11.1642C3.90261 11.5539 4.75849 11.7571 5.62651 11.7578C6.49453 11.7584 7.35073 11.5565 8.12696 11.168V15.6724Z"
      fill="black"
    />
  </svg>
);

export default Badge;
