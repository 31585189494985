import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import styles from './style';
import Box from '../../atomic/Box';
import List from '../../atomic/List';
import Button from '../../atomic/Button';
import ListItem from '../ListBodyItem';
import useOutsideAlerter from '../../../hooks/useOutsideAlerter';
import { sGetReturnOnSuccess, sGetRooms } from '../../../store/reducers/rooms';
import { sGetEvents } from '../../../store/reducers/events';
import AttachRoomEventItem from '../AttachRoomEventItem';
import {
  ATTACH_EVENT_EDIT_OPTION,
  ATTACH_ROOM_EDIT_OPTION,
} from '../../../utils/documents';

const ListBody = ({
  classes,
  filters,
  type,
  onReset,
  onMenuSave,
  onMenuClose,
  isDelete,
  setSelectedOptions,
  selectedOptions,
  isFiltering,
  getReturnOnSuccess,
  rooms,
  events,
  // selectedDocuments, To Do : enable when mataomor or delete endpoint is integrated
}) => {
  const [title, setTitle] = useState('');
  const [mulitipleDocumentsSelected, setMulitipleDocumentsSelected] =
    useState(false);
  const topContentRef = useRef(null);

  const [isShowAttachItem, setIsShowAttachItem] = useState(false);
  const [showNewEntity, setShowNewEntity] = useState(false);

  useEffect(() => {
    if (!getReturnOnSuccess) setShowNewEntity(false);
  }, [getReturnOnSuccess]);

  useEffect(() => {
    if (topContentRef.current) {
      topContentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [rooms]);

  useEffect(() => {
    if (topContentRef.current) {
      topContentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [events]);

  useEffect(() => {
    setMulitipleDocumentsSelected(true);
  }, []);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onMenuClose, showNewEntity);

  const onDelete = () => {
    // To Do add matomo tracking and delete functionality
  };

  const onOptionSelect = (id, label) => {
    if (!selectedOptions) return;

    if (isDelete) {
      onDelete();
    } else if (
      (selectedOptions || []).some(
        (selectedOption) => selectedOption.value === id
      )
    ) {
      setSelectedOptions(selectedOptions.filter((opt) => opt.value !== id));
    } else {
      setSelectedOptions([...selectedOptions, { value: id, label }]);
    }
  };

  useEffect(() => {
    if (type === ATTACH_EVENT_EDIT_OPTION || type === ATTACH_ROOM_EDIT_OPTION) {
      setIsShowAttachItem(true);
      if (type === ATTACH_EVENT_EDIT_OPTION) {
        setTitle('Opprett ny hendelse');
      } else {
        setTitle('Opprett nytt rom');
      }
    } else {
      setIsShowAttachItem(false);
    }
  }, [type]);

  return (
    <Box ref={wrapperRef}>
      <Box className={classes.content}>
        <p ref={topContentRef} />
        <List disablePadding>
          {filters.map((props) => (
            <ListItem
              key={`filter-item-${props.name}`}
              isDelete={isDelete}
              onOptionSelect={onOptionSelect}
              selectedOptions={selectedOptions}
              {...props}
            />
          ))}
        </List>
      </Box>
      {!isDelete && (
        <>
          {(!isFiltering || isShowAttachItem) && (
            <AttachRoomEventItem
              title={title}
              onShowNewEntity={setShowNewEntity}
              mulitipleDocumentsSelected={mulitipleDocumentsSelected}
              type={type}
            />
          )}
          <Box p={1} textAlign="right" className={classes.buttonPane}>
            <Button
              varinat="text"
              color="primary"
              onClick={onReset}
              className={classes.textButton}
            >
              <small className={classes.resetButtonText}>
                Nullstill
                <div className={classes.textBorder} />
              </small>
            </Button>
            <Button
              varinat="text"
              color="primary"
              onClick={onMenuSave}
              className={classes.saveButton}
            >
              Lagre
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  getReturnOnSuccess: sGetReturnOnSuccess(state),
  rooms: sGetRooms(state),
  events: sGetEvents(state),
});

export default connect(mapStateToProps)(withStyles(styles)(ListBody));
