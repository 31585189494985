import styled from 'styled-components';

export const StyledPurchases = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1160px;
  padding: 0 20px;
  margin: 0 auto;

  @media (max-width: 767px) {
    align-items: center;
  }

  h2,
  h3,
  p {
    margin: 0;
    color: #000;
  }

  & > h2 {
    font-size: 24px;
    line-height: 48px;
    font-weight: 500;
    margin: 28px 0 49px;

    @media (max-width: 767px) {
      width: 100%;
      max-width: 352px;
      margin: 18px 0 29px;
    }
  }

  h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
  }

  .content {
    display: flex;
    margin-bottom: 40px;
    gap: 32px;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .purchases_cards {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;
    width: 100%;

    @media (max-width: 767px) {
      justify-content: center;
    }

    & > .purchase_card {
      width: 100%;
      max-width: 352px;
    }

    .no_transactions {
      background-color: #f6d9d8;
      max-width: unset;
      align-self: flex-start;
      padding: 32px;
      border-radius: 4px;

      p {
        font-size: 20px;
        line-height: 31px;
        font-weight: 700;
        color: #111;
      }
    }
  }

  .help_aside {
    width: 100%;
    max-width: 352px;
  }

  .help_box {
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: #fff;
    padding: 32px;

    h3 {
      font-size: 20px;
      line-height: 31px;
      font-weight: 700;
      color: #111;
    }

    p {
      font-size: 16px;
      line-height: 25px;
      font-weight: 500;
      color: #111;
    }

    .filled_button {
      text-align: center;
    }
  }

  .link_list a {
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
    color: #000;
  }

  .dialog_title {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }

  .dialog_description {
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
  }

  .dialog_list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    margin: 0;
    padding-left: 22px;
  }

  .filled_button {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    background-color: #164144;
    padding: 12px 24px;
    border-radius: 50px;
    cursor: pointer;

    &:hover {
      background-color: #193639;
    }
    &:active {
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    }
  }

  .outlined_button {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #13142a;
    background-color: #fff;
    text-decoration: none;
    padding: 12px 24px;
    border: 1.5px solid #d1ede8;
    border-radius: 50px;
    cursor: pointer;

    &:hover {
      background-color: #f8f8f8;
    }
    &:active {
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    }
  }
`;
