import React from 'react';
import OtovoIcon from '../../../../assets/icons/OtovoIcon';
import OtovoIconActive from '../../../../assets/icons/OtovoIconActive';
import RadonIcon from '../../../../assets/icons/RadonIcon';
import RadonIconActive from '../../../../assets/icons/RadonIconActive';
import OtovoComponent from './Components/Companies/OtovoComponent';
import RadonComponent from './Components/Companies/RadonComponent';
import { enableOtovo, enableOvero } from '../../../../config';

export const defaultExperimentBlock = {
  menuItem: {
    title: '',
    icon: null,
    activeIcon: null,
    isEnable: true,
    hideInPrivateProperty: false,
    hideOnLands: false,
    hideOnGarageBuilding: false,
    isNew: false,
  },
  title: '',
  experimentSlug: '',
  description: [],
  descriptionList: [],
  commonExperimentComponent: <></>,
  moreDescriptionTopic: '',
  moreDescription: [''],
  moreDescriptionQuestions: [],
  commonExperimentComponentTitle: '',
  secondCommonExperimentComponent: '',
  learnMoreLink: {
    link: null,
    text: null,
    location: null,
  },
};

export const otovoIframeUrl =
  'https://solar.otovo.com/nb-no/search?context=iframe&utm_source=boligmappa&theme=default';
export const experimentTemplateData = [
  {
    menuItem: {
      title: 'Radon',
      icon: RadonIcon,
      activeIcon: RadonIconActive,
      isEnable: false,
      hideInPrivateProperty: false,
      hideOnLands: false,
      hideOnGarageBuilding: false,
      isNew: enableOvero !== 'true',
    },
    title: 'Estimert radonnivå',
    experimentSlug: 'radon',
    description: [
      'Radon er en radioaktiv gass uten farge og lukt. Den dannes i berggrunnen fra grunnstoffene radium og uran, og dette skjer hele tiden. Det er helseskadelig å oppholde seg i hus og bygninger med for mye radon over lengre tid. Radon er i dag hovedårsaken til lungekreft blant ikke-røykere.',
      'Nasjonalt aktsomhetskart for radon viser hvilke områder i Norge som kan være mer radonutsatt enn andre. Kartet er basert på inneluftmålinger av radon og på kunnskap om geologiske forhold.',
      'I områder markert med «høy aktsomhet», er det beregnet at minst 20 % av boligene har radonkonsentrasjoner over øvre anbefalte grenseverdi på 200 Bq/m3 i første etasje.',
    ],
    descriptionList: [],
    commonExperimentComponent: (
      <RadonComponent
        description="Radon er en luktfri gass i boligen som øker risikoen for alvorlige helseskader."
        descriptionSecondPart="Den eneste måten å finne ut nøyaktig radonnivå i din bolig er å måle - uavhengig av estimert grad."
        FeatureRedirectionUrl="https://www.radonmannen.no/boligmappa"
        bannerImageUrl="https://ik.imagekit.io/boligmappa/Experiments/radon.png?ik-sdk-version=javascript-1.4.3&updatedAt=1667386267581"
      />
    ),
    moreDescriptionTopic: 'Fakta om radon',
    moreDescription: [
      'Boligmappa ønsker å ta et tak for å fremme kunnskapen om innemiljø. Radon er i dag hovedårsaken til lungekreft blant ikke-røykere. Radon er også noe kjøpere er opptatt av ved boligsalg.',
      'Norge er blant de landene i verden med de høyeste konsentrasjonene av radon i luften innendørs. Dette skyldes både geologi og vårt kalde klima. Oppvarming av bygninger og lokaler fører gjerne til et undertrykk innendørs. Radonholdig luft fra bakken (jordluft) kan dermed trekkes inn gjennom utettheter i hus og bygninger.',
      'Selv om man bor i et område med moderat aktsomhetsgrad av radon, anbefales en måling av radonniåvet. Dette kan man gjøre selv, med en såkalt sporfilm-måling over minst to måneder i vinterhalvåret.',
      'Ved måling av radonnivåer over 100 Bq/m3 (snitt per mnd) i hjemmet ditt, kreves radontiltak for å redusere nivået. Det kreves profesjonell hjelp til å få utført varige utbedringer. Boligmappa samarbeider med Radonmannen, som er størst i Norge på måling og utbedring av radonproblematikk.',
    ],
    moreDescriptionQuestions: [],
    commonExperimentComponentTitle: 'Sjekk ditt radonnivå - bestill måling',
    secondCommonExperimentComponent: '',
    learnMoreLink: {
      location: 'AFTER_MORE_DESCRIPTION',
      link: 'https://register.geonorge.no/data/documents/produktark_radon-aktsomhet_v3_produktark-radonaktsomhet-ngu_.pdf',
      text: 'Les mer hos Geo Norge',
    },
  },
  {
    menuItem: {
      title: 'Solcellepanel',
      icon: OtovoIcon,
      activeIcon: OtovoIconActive,
      isEnable: enableOtovo === 'true',
      hideInPrivateProperty: false,
      hideOnLands: false,
      hideOnGarageBuilding: false,
      isNew: true,
    },
    title: 'Spar penger på taket ditt',
    experimentSlug: 'otovo',
    description: [
      'Boligmappa samarbeider med Otovo, som er et av landets ledende aktører på solceller. Hos Otovo kan du lease ditt solcelleanlegg med markedets beste garantier',
      'Solenergi har aldri vært lettere å utnytte. I løpet av få sekunder viser vi deg hvor mye strøm du kan produsere. Ved bruk av solstrøm over tid kan solcellene ende opp med å produsere gratis strøm i flere år.',
      'Det er mange faktorer som påvirker hva et solcelleanlegg vil koste deg. Faktorene som blant annet påvirker prisen er:',
    ],
    descriptionList: [
      'ønsker du å kjøpe eller lease solcellene?',
      'installasjonskompleksitet',
      'antall takflater',
      'type taktekking',
      'valg av inverter og optimizer',
    ],
    commonExperimentComponent: (
      <OtovoComponent
        description="Økende strømpriser gjør solstrøm til et mer lønnsomt alternativ.  Nå kan vi beregne den årlige besparelsen du gjør ved å kjøpe solceller."
        title="Se hva du kan spare på din adresse"
        url="https://solar.otovo.com/nb-no/search?context=iframe&utm_source=boligmappa&theme=default"
        bannerImageUrl="https://ik.imagekit.io/boligmappa/Experiments/enovo.png?ik-sdk-version=javascript-1.4.3&updatedAt=1667386286269"
      />
    ),
    moreDescriptionTopic: 'Spar penger på taket ditt',
    moreDescription: [],
    moreDescriptionQuestions: [],
    commonExperimentComponentTitle: '',
    secondCommonExperimentComponent: '',
    learnMoreLink: {
      link: null,
      text: null,
      location: null,
    },
  },
];
