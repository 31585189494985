const styles = () => ({
  page: {
    backgroundColor: '#f5f5f5',
    printColorAdjust: 'exact',
  },
  wrapper: {
    maxWidth: '1160px',
    padding: '0 20px',
    margin: '0 auto',

    '&.mobile': {
      padding: 0,
    },
  },
});

export default styles;
