import React from 'react';
import TableHeaderCell from '../TableHeaderCell';
// import MultiSelectComboBox from '../../../../MultiSelectComboBox';
import useMenuState from '../../../../../hooks/useMenuState';
import FilterMenu from '../../Filters/FilterMenu';
import FilterList from '../../Filters/FilterList';

const TableHeaderOptionsFilter = ({
  name,
  description,
  filterOptions,
  onFiltersReset,
  ...props
}) => {
  const { onMenuOpen, onMenuClose, open, anchorEl } = useMenuState();

  return (
    <>
      <TableHeaderCell name={name} onClick={onMenuOpen} {...props} />
      <FilterMenu
        open={open}
        title={description}
        anchorEl={anchorEl}
        onClose={onMenuClose}
      >
        <FilterList onReset={onFiltersReset} filterOptions={filterOptions} />
      </FilterMenu>
    </>
  );
};

export default TableHeaderOptionsFilter;
