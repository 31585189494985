import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import { StyledCTA, StyledBannerCTA } from './styles';
import useDashboardLinkPrefix from '../../../hooks/useDashboardLinkPrefix';
import BoligmappaPlusHome from '../../../assets/images/BM_Plus_CTA.png';
import BoligmappaPlusLogo from '../../../assets/images/Boligmappa-Plus-Logo.png';
import useIsDesktop from '../../../hooks/v2/useDesktop';
import matomo from '../../../utils/matomo';

const HomeCallToAction = ({
  className,
  smallVersion,
  desktopImageSrc = BoligmappaPlusHome,
  mobileImageSrc = BoligmappaPlusHome,
  desktopHeadline = 'Prøv Boligmappa+ nå!',
  mobileHeadline = 'Prøv Boligmappa Pluss!',
  desktopCta1 = 'Få early bird her!',
  desktopCta2 = 'Lær mer om Boligmappa+',
  postfixLinkCta1 = '/boligmappa-plus',
  postfixLinkCta2 = '/boligmappa-plus',
  desktopMainText = 'Med Boligmappa+ får du tilgang til en rekke nye og spennende funksjoner. Mye blir lansert i januar og utover, få early bird-tilgang nå!',
  mobileMainText = 'Med Boligmappa Pluss får du tilgang til en rekke nye og spennende funksjoner.',
  matomoRenderActionName = 'Rendered Boligmappa+ Banner',
  matomoCta1ActionName = 'pluss-banner-click-1',
  matomoCta2ActionName = 'pluss-banner-click-2',
}) => {
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const isDesktop = useIsDesktop();

  const onCtaClick = (actionName) => {
    matomo.clickEvent({
      category: 'banner-front-page',
      action: actionName,
      name: actionName,
    });
  };

  useEffect(() => {
    if (smallVersion) {
      matomo.clickEvent({
        category: 'banner-front-page',
        action: 'Rendered Boligmappa+ mini Banner',
        name: 'Rendered Boligmappa+ mini Banner',
      });
    } else {
      matomo.clickEvent({
        category: 'banner-front-page',
        action: matomoRenderActionName,
        name: matomoRenderActionName,
      });
    }
  }, [smallVersion]);

  if (smallVersion) {
    return (
      <StyledBannerCTA>
        <div className="banner_content">
          <img
            alt="Boligmappa+Logo"
            src={BoligmappaPlusLogo}
            className="boligmappa-plus-logo"
          />
          <p>Få mer ut av Boligmappa!</p>
        </div>
        <Link
          to={`${dashboardLinkPrefix}/boligmappa-plus`}
          className="learn_more"
          onClick={() => onCtaClick('mini-pluss-banner-click')}
        >
          <button type="submit" className="banner_content_action">
            Lær mer
          </button>
        </Link>
      </StyledBannerCTA>
    );
  }
  return (
    <StyledCTA className={className}>
      <div className="text_section">
        {isDesktop && (
          <div className="new_batch">
            <p className="new_text">Nyhet!</p>
          </div>
        )}
        <h2>{isDesktop ? desktopHeadline : mobileHeadline}</h2>
        <p>{isDesktop ? desktopMainText : mobileMainText}</p>
        <div className="button_section">
          <Link
            to={`${dashboardLinkPrefix}${postfixLinkCta1}`}
            className="learn_more"
            onClick={() => onCtaClick(matomoCta1ActionName)}
          >
            <button type="submit" className="early_bird">
              {desktopCta1}
            </button>
          </Link>
          {isDesktop && (
            <Link
              to={`${dashboardLinkPrefix}${postfixLinkCta2}`}
              className="learn_more underline"
              onClick={() => onCtaClick(matomoCta2ActionName)}
            >
              <p>{desktopCta2}</p>
            </Link>
          )}
        </div>
      </div>
      <div className="image_section">
        <img
          alt="Boligmappa+"
          src={isDesktop ? desktopImageSrc : mobileImageSrc}
          className="landscape-horizonal"
        />
      </div>
    </StyledCTA>
  );
};

export default HomeCallToAction;
