import React from 'react';

const BoligmappaFolder = ({
  width = '24',
  height = '20',
  fill = '#717171',
  ...props
}) => (
  <svg
    viewBox="0 0 29 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
  >
    <g clipPath="url(#clip0_13573_34188)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.48134 20.9998H18.0397C18.1988 20.9842 18.3518 20.9323 18.486 20.8484C18.6201 20.7644 18.7317 20.6509 18.8113 20.5171L22.7307 13.7312C23.3036 12.7478 22.9326 11.9395 21.9108 11.9395H7.36014C7.20018 11.955 7.0463 12.0068 6.91107 12.0907C6.77585 12.1746 6.66311 12.2882 6.58206 12.4221L2.66275 19.2049C2.09302 20.1908 2.46084 20.9998 3.48262 20.9998"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.19859 4.40578L1.72522 10.0837C1.48257 10.2633 1.28976 10.4981 1.16399 10.7671C1.03821 11.0361 0.983379 11.331 1.00437 11.6254V17.8964L4.86259 11.2295C5.08877 10.8418 5.41468 10.5169 5.8089 10.2861C6.20312 10.0553 6.65234 9.92638 7.11322 9.91182H18.8673L11.6318 4.41198C11.2861 4.14529 10.8566 4 10.4139 4C9.97126 4 9.54177 4.14529 9.19602 4.41198"
        fill={fill}
      />
    </g>
    <circle cx="20" cy="10" r="8" fill="white" />
    <path
      d="M14.7857 10C14.7857 7.12023 17.1202 4.78571 20 4.78571C22.8798 4.78571 25.2143 7.12023 25.2143 10C25.2143 12.8798 22.8798 15.2143 20 15.2143C17.1202 15.2143 14.7857 12.8798 14.7857 10ZM20 1.5C15.3056 1.5 11.5 5.30558 11.5 10C11.5 14.6944 15.3056 18.5 20 18.5C24.6944 18.5 28.5 14.6944 28.5 10C28.5 5.30558 24.6944 1.5 20 1.5ZM21.6429 6.57143C21.6429 5.6641 20.9073 4.92857 20 4.92857C19.0927 4.92857 18.3571 5.6641 18.3571 6.57143V8.35714H16.5714C15.6641 8.35714 14.9286 9.09267 14.9286 10C14.9286 10.9073 15.6641 11.6429 16.5714 11.6429H18.3571V13.4286C18.3571 14.3359 19.0927 15.0714 20 15.0714C20.9073 15.0714 21.6429 14.3359 21.6429 13.4286V11.6429H23.4286C24.3359 11.6429 25.0714 10.9073 25.0714 10C25.0714 9.09267 24.3359 8.35714 23.4286 8.35714H21.6429V6.57143Z"
      fill={fill}
      stroke="white"
      strokeLinecap="round"
    />
    <defs>
      <clipPath id="clip0_13573_34188">
        <rect width="22" height="17" fill="white" transform="translate(1 4)" />
      </clipPath>
    </defs>
  </svg>
);

export default BoligmappaFolder;
