const styles = (theme) => ({
  title: {
    margin: '10px 0 10px 0',
    color: theme.palette.primary.darkGreen,
    fontWeight: 600,
    fontSize: '16px',
  },
  header: {
    margin: '20px 85px 30px 74px',
  },
  headerMobile: {
    margin: '20px 30px 30px 30px',
  },
  progressSummary: {
    fontWeight: 400,
  },
  list: {
    padding: '0px 85px 29px 74px',
  },
  listMobile: {
    padding: '0 30px 30px 30px',
    borderBottom: `1px solid ${theme.palette.primary.greyLight}`,
  },
  listDivider: {
    marginTop: '30px',
  },
});

export default styles;
