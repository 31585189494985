const styles = (theme) => ({
  documentForm: {
    marginBottom: '20px',
  },
  formElement: {
    marginBottom: '30px',
  },
  actionButtons: {
    padding: '15px 0 15px 0',
    borderTop: '1px solid #dedfdf',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
  },
  actionButtonsMobile: {
    padding: '15px 0 30px 0',
    borderTop: '1px solid #dedfdf',
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
  },
  nextBtn: {
    marginRight: 45,
    width: '130px',
    height: '50px',
  },
  nextBtnMobile: {
    marginRight: 15,
    width: '130px',
    height: '50px',
  },
  backBtn: {
    marginLeft: 45,
    marginRight: 'auto',
    width: '130px',
    height: '50px',
  },
  backBtnMobile: {
    marginLeft: 15,
    marginRight: 'auto',
    width: '130px',
    height: '50px',
  },
  error: {
    color: theme.palette.primary.redDark,
  },
});

export default styles;
