import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { useFlag } from '@unleash/proxy-client-react';
import Link from 'components/atomic/Link';
import useDesktop from '../../../../hooks/useDesktop';
import Grid from '../../../../components/atomic/Grid';
import { useActiveProperty } from '../../../../store/hooks';
import {
  getPropertyType,
  hasValueEstimate,
} from '../../../../utils/properties';
import { PriceHistorySection } from '../Home/Overview/PriceHistorySection';
import PricePerSquareMeterSection from '../Home/Overview/PricePerSquareMeterSection';
import { PropertyRightsAndEconomySection } from '../Home/Overview/PropertyRightsAndEconomySection';
import PropertyEstimateSection from '../Home/Overview/PropertyEstimateSection';
import PageLayout from '../../../../components/layouts/WithHeader';
import { PropertyMarketEventsSection } from '../Home/Overview/PropertyMarketEventsSection';
import { propertyHasTotalSpaceData } from '../../../../api/helpers/property';
import { sGetActiveProperty } from '../../../../store/reducers/properties';
import styles from './style';
import CallToAction from '../../../../componentsV2/BoligmappaPlus/CallToAction';
import Box from '../../../../components/atomic/Box';
import { useBoligmappaPlusSubscriptionStatus } from '../../../../store/hooks/useBoligmappaPlusSubscriptionStatus';
import { isNativeEnv } from '../../../../utils/nativeBridge';
import { boligmappaPlusActionCallback } from '../../../../utils/boligmappaPlus';
import useSnackbar from '../../../../hooks/useSnackbar';
import matomo from '../../../../utils/matomo';

const PropertyFinancials = ({ activeProperty, classes }) => {
  const isDesktop = useDesktop();
  const [landTypeProperty, setLandTypeProperty] = useState(false);
  const { property } = useActiveProperty();
  const [showMaxWidth, setShowMaxWidth] = useState(true);
  const [valueEstimateError, setValueEstimateError] = useState(false);
  const veridiBoligmappaPlus = useFlag('boligeier.veridi.boligmappa.plus');
  const boligmappaPlusSubscriptionStatus =
    useBoligmappaPlusSubscriptionStatus();
  const [boligmappaPlusCTAShow, setBoligmappaPlusCTAShow] = useState(false);

  const owned = activeProperty?.owned;
  const { showErrorMessage } = useSnackbar();

  const extraCtaContent = (
    <div className={classes.extraCtaContent}>
      <h2>Verdi & økonomi</h2>
      <p>
        Følg verdiutviklingen på din bolig. Vi bruker verdiestimatet fra vår
        søstertjeneste{' '}
        <Link
          to="https://www.hjemla.no/?mtm_campaign=verdiestimat&mtm_source=ikke-plussbruker"
          className={classes.hjemlaLink}
          isExternal
          openInNewTab
        >
          Hjemla
        </Link>
        , som nylig ble kåret til markedets beste.
        <br />
        <br />
        For å få tilgang til verdiestimatet må du være medlem i Boligmappa+
      </p>
    </div>
  );
  useEffect(() => {
    if (boligmappaPlusSubscriptionStatus) {
      setBoligmappaPlusCTAShow(false);
    } else if (!isNativeEnv()) {
      setBoligmappaPlusCTAShow(veridiBoligmappaPlus);
    } else {
      setBoligmappaPlusCTAShow(false);
    }
  }, [veridiBoligmappaPlus, boligmappaPlusSubscriptionStatus]);

  useEffect(() => {
    if (getPropertyType(property) === 'Eiendom/tomt uten bygning') {
      setLandTypeProperty(true);
      setShowMaxWidth(true);
    } else if (
      !hasValueEstimate(property) &&
      propertyHasTotalSpaceData(property)
    ) {
      setShowMaxWidth(true);
    } else {
      setShowMaxWidth(false);
    }
  }, [property]);

  useEffect(() => {
    if (valueEstimateError) {
      setShowMaxWidth(true);
    }
  }, [valueEstimateError, showMaxWidth]);

  const handleActionClick = async (type) => {
    matomo.clickEvent({
      category: 'Boligmappa+',
      action: 'Pay with Vipps',
    });
    await boligmappaPlusActionCallback(type, showErrorMessage);
  };

  return (
    <PageLayout
      topLevelLayout
      headerTitle="Verdi og økonomi"
      minFullHeight
      maxWidth="lg"
    >
      {boligmappaPlusCTAShow && (
        <Box className={classes.veridiBoligmappaPlusSection}>
          {/* <h2>Verdi og økonomi</h2>
          <Box className={classes.veridiBoligmappaPlusTextSection}>
            <p>
              Følg verdiutviklingen på din bolig. Vi bruker verdiestimatet fra
              vår søstertjeneste{' '}
              <Link
                to="https://www.hjemla.no/?mtm_campaign=verdiestimat&mtm_source=ikke-plussbruker"
                isExternal
                openInNewTab
              >
                Hjemla
              </Link>
              , som nylig ble kåret til markedets beste. Estimatet beregnes ved
              hjelp av maskinlæringsalgoritmer og bruker blant annet fysiske
              egenskaper ved boligen, offentlige boligdata, markedsforhold og
              data oppgitt av boligeiere. På Hjemla kan du følge utviklingen i
              boligmarkedet live
            </p>
            <p>
              Verdiestimatet er tilgjengelig med Boligmappa+. Med Boligmappa+
              får du også tilgang til Boligmappas fordelsprogram, et personlig
              område som følger deg for alltid – og mye mer.
            </p>
            <p className={classes.greenText}>
              Boligmappa+ er et tillegg til alt det som allerede finnes i
              Boligmappa. Dine boligdokumenter, oversikt over håndverkere, rom
              og hendelser er fortsatt tilgjengelig – helt gratis.
            </p>
          </Box> */}
          <CallToAction
            isDesktop={isDesktop}
            extraHeaderContent={extraCtaContent}
            collapsible={false}
            ctaHeadline="Bli med i Boligmappa+"
            backgroundColor="#fff"
            annualOffer
            defaultAnnual
            className={classes.veridiCallToAction}
            isCollapsed={false}
            onActionClick={handleActionClick}
            buttonColor="#DB4551"
            learnMoreLink
            bottomDescription="Les mer om hva du får med Boligmappa+"
          />
        </Box>
      )}
      <Grid
        container
        spacing={isDesktop ? 6 : 2}
        className={boligmappaPlusCTAShow ? classes.sectionBlur : ''}
      >
        {!landTypeProperty && (
          <PropertyEstimateSection
            hasValueEstimateError={(val) => setValueEstimateError(val)}
            hideValues={boligmappaPlusCTAShow}
          />
        )}
        {isDesktop && (
          <PropertyMarketEventsSection
            showMaxwidth={showMaxWidth}
            hideValues={boligmappaPlusCTAShow}
          />
        )}
        {!landTypeProperty && (
          <PriceHistorySection hideValues={boligmappaPlusCTAShow} />
        )}
        <PricePerSquareMeterSection hideValues={boligmappaPlusCTAShow} />
        {!isDesktop && (
          <PropertyMarketEventsSection hideValues={boligmappaPlusCTAShow} />
        )}
        {owned && (
          <PropertyRightsAndEconomySection hideValues={boligmappaPlusCTAShow} />
        )}
      </Grid>
      <br />
      <br />
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  activeProperty: sGetActiveProperty(state),
});

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(PropertyFinancials));
