export const defaultState = {
  requests: [],
};

export const SET_ACCESS_REQUESTS = 'SET_ACCESS_REQUESTS';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_ACCESS_REQUESTS:
      return {
        ...state,
        requests: action.payload,
      };
    default:
      return state;
  }
};

export const sGetAccessRequests = (state) => state.accessRequests.requests;
export const sGetPendingRequests = (state) =>
  sGetAccessRequests(state).filter((el) => el.status.status === 'Pending');
