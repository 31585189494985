const styles = (theme) => ({
  filterItem: {
    color: theme.palette.primary.darkBlack,
  },
  arrow: {
    width: '10px',
    marginLeft: '8px',
    fill: theme.palette.primary.darkBlack,
    paddingTop: '7px',
  },
  sortingArrow: {
    height: '16px',
    width: '16px',
    fill: theme.palette.primary.darkBlack,
  },
  sortingArrowBox: {
    marginLeft: 0,
    padding: '5px 20px 0 11px',
  },
  sortWithFilter: {
    padding: '5px 20px 0 0',
  },
});

export default styles;
