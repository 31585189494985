import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';

import Typography from '../../../../components/atomic/Typography';
import Box from '../../../../components/atomic/Box';
import ListItemCard from '../../../../components/ListItemCard';

import BoligmappaHomeCross from '../../../../assets/images/BM_Home_Cross.png';
import BoligmappaHeaderLogo from '../../../../assets/images/BM-header-logo.svg';
import ProgressButton from '../../../../components/ProgressButton';
import { NO_OWNED_PROPERTIES } from '../../../../constants';

import styles from './style';

const NoPropertiesScreen = ({ classes }) => {
  const [showMore, setShowMore] = useState(false);
  const handleToggle = () => {
    setShowMore(!showMore);
  };
  const redirectToSupport = () => {
    window.open(NO_OWNED_PROPERTIES, '_blank');
  };

  return (
    <>
      <Box className={classes.headerBox}>
        <img
          src={BoligmappaHeaderLogo}
          className={classes.headerImage}
          alt=""
        />
      </Box>
      <Box className={classes.messageBox}>
        <div>
          <img
            alt=""
            src={BoligmappaHomeCross}
            className={classes.messageImage}
          />
        </div>
        <Typography className={classes.messageText}>
          Vi finner ingen eiendommer hvor du er oppført som hjemmelshaver
        </Typography>
        {showMore ? (
          <Typography className={classes.messageMoreText}>
            <p>Dette kan skyldes flere årsaker:</p>

            <ol className={classes.messageMoreTextItems}>
              <li>Eiendommen er tinglyst på noen andre enn deg.</li>
              <li>Eiendommen er registrert med løpenummer i grunnboken.</li>
              <li>Det mangler kobling mellom seksjons-nummer og bruksenhet.</li>
              <li>Det mangler kobling mellom andelsnummer og bruksenhet.</li>
              <li>Du har en aksje- eller obligansjonsleilighet.</li>
              <li>Det er en feil i Boligmappa.</li>
            </ol>

            <p>
              På vårt kundesenter kan du lese mer om årsakene, og hva du kan
              gjøre for å få tilgang til eiendommen.
            </p>
          </Typography>
        ) : null}
        {showMore ? (
          <ProgressButton
            size="small"
            color="primary"
            variant="contained"
            justifyContent="center"
            className={classes.button}
            onClick={redirectToSupport}
          >
            Gå til kundesenter
          </ProgressButton>
        ) : null}
        <botton className={classes.messageBtn} onClick={handleToggle}>
          {showMore ? 'Skjul' : 'Les mer'}
        </botton>
      </Box>

      <br />
      <ListItemCard />
    </>
  );
};

export default withStyles(styles)(NoPropertiesScreen);
