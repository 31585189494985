import {
  SET_MDR_INPUTS,
  SET_SELECTED_COMPANY,
  SET_JOB_YEAR_DONE,
  SET_IS_COMPANY_REGISTERED,
  SET_IS_COMPANY_IN_SYSTEM,
  RESET,
} from '../reducers/mdr';

export const actionSetMDRInputs = (mdrInputs) => ({
  type: SET_MDR_INPUTS,
  payload: mdrInputs,
});
export const actionSetSelectedCompany = (selectedCompany) => ({
  type: SET_SELECTED_COMPANY,
  payload: selectedCompany,
});
export const actionSetJobYearDone = (jobYearDone) => ({
  type: SET_JOB_YEAR_DONE,
  payload: jobYearDone,
});
export const actionSetIsCompanyRegistered = (isRegistered) => ({
  type: SET_IS_COMPANY_REGISTERED,
  payload: isRegistered,
});
export const actionSetIsCompanyInSystem = (isInSystem) => ({
  type: SET_IS_COMPANY_IN_SYSTEM,
  payload: isInSystem,
});
export const actionResetMDR = () => ({
  type: RESET,
});
