const styles = () => ({
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bannerImage: {
    marginTop: '37px',
    width: '495px',
    '@media only screen and (max-width: 960px)': {
      marginTop: '21px',
      width: '361px',
    },
    '@media only screen and (max-width: 360px)': {
      marginTop: '21px',
      width: '320px',
    },
  },
  title: {
    width: '440px',
    fontWeight: '600',
    fontSize: '28px',
    lineHeight: '37px',
    color: '#333448',
    textAlign: 'center',
    marginTop: '48px',
    '@media only screen and (max-width: 960px)': {
      width: '338px',
      marginTop: '25px',
    },
    '@media only screen and (max-width: 360px)': {
      width: '300px',
      marginTop: '25px',
    },
  },
  description: {
    width: '440px',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '28px',
    color: '#13142A',
    textAlign: 'center',
    marginTop: '16px',
    '@media only screen and (max-width: 960px)': {
      width: '350px',
    },
    '@media only screen and (max-width: 360px)': {
      width: '310px',
    },
  },
  buttonWrapper: {
    textDecoration: 'none',
  },
  button: {
    color: '#FFFFFF',
    background: '#13142A',
    minWidth: '100px',
    padding: '12px 24px',
    margin: '7px auto',
    height: '40px',
    lineHeight: '14px',
    marginTop: '24px',
    '@media only screen and (max-width: 960px)': {
      marginTop: '40px',
    },
    '@media only screen and (max-width: 360px)': {
      marginTop: '40px',
    },
  },
  buttonText: {
    fontSize: '14px',
    fontWeight: '500',
    marginLeft: '9.33px',
  },
});

export default styles;
