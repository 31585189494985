const styles = (theme) => ({
  title: ({ fullPage }) => ({
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    textAlign: fullPage ? 'center' : 'left',
    ...(fullPage ? { color: theme.palette.text.primary } : {}),
  }),
  container: ({ safePaddingEnabled = false }) => ({
    '@supports (padding-top: env(safe-area-inset-top))': {
      paddingTop: safePaddingEnabled ? 'env(safe-area-inset-top)' : 0,
    },
  }),
  minFullHeight: ({ hideHeader = false, headerPosition }) => ({
    [theme.breakpoints.up('md')]: {
      minHeight:
        hideHeader || headerPosition === 'static'
          ? '100vh'
          : 'calc(100vh - 65px)',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '100vh',
    },
  }),
  root: {
    [theme.breakpoints.up('md')]: {
      minHeight: '100vh',
    },
  },
  withHeaderContent: ({ noPadding }) => ({
    padding: '24px 0',

    [theme.breakpoints.down('sm')]: {
      padding: noPadding ? 0 : '15px',
    },
  }),
  subPageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pageHeader: {
    fontSize: '34px',
    lineHeight: '36px',
    color: '#254E52',
    margin: '28px 0',
  },
  editButtonsBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  editItemButton: {
    border: 'none',
    background: 'none',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  // for the tabs
  tabIndicator: {
    height: '4px',
  },
  tab: {
    fontWeight: 600,
    textTransform: 'none',
  },
  marginLeftZero: {
    marginLeft: 0,
  },
});

export default styles;
