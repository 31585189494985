import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Form from '../../../../../components/forms/BaseForm';
import Typography from '../../../../../components/atomic/Typography';
import PageLayout from '../../../../../components/layouts/WithHeader';
import { downloadPersonalData } from '../../../../../api/propertyowners';

const DownloadPersonalData = ({ classes }) => {
  const [downloadError, setDownloadError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const onSubmit = async () => {
    setDownloadError(null);
    setRequestInProgress(true);

    const [error, data] = await downloadPersonalData();

    if (error && !data) {
      setDownloadError(error);
    }

    setRequestInProgress(false);
  };

  return (
    <PageLayout headerTitle="Last ned personlig innhold">
      <Typography component="p" variant="body2" color="textSecondary">
        Du har full kontroll over personopplysninger som deles med Boligmappa.
        Du kan når som laste ned en kopi av personopplysningene Boligmappa har
        registrert på deg.
      </Typography>

      <h4>Hva er inkludert?</h4>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          Personopplysningene dine: Navn, fødselsnummer, e-postadresse,
          telefonnummer og folkeregistrert bostedsadresse.
        </li>
        <li className={classes.listItem}>
          Dokumenter du selv har lastet opp til ditt “Personlig område”.
        </li>
      </ul>

      <br />
      <Form
        onSubmit={onSubmit}
        error={downloadError}
        submitButtonText="Last ned"
        requestInProgress={requestInProgress}
      />
    </PageLayout>
  );
};

export default withStyles(styles)(DownloadPersonalData);
