const styles = () => ({
  content: {
    maxHeight: '40vh',
    overflowY: 'auto',
  },
  textButton: {
    fontWeight: 400,
    textDecoration: 'none',
    float: 'left',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  resetButtonText: {
    position: 'relative',
  },
  textBorder: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    borderBottom: '1.5px solid #E6E6E6',
  },
  saveButton: {
    width: '69px',
    height: '32px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    borderRadius: '50px',
    color: '#FFFFFF',
    padding: '8px 16px',
    background: '#164144',
    textDecoration: 'none',

    '&:hover': { backgroundColor: '#164144' },
  },
  buttonPane: {
    padding: '12px',
  },
});

export default styles;
