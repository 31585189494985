const styles = (theme) => ({
  error: {
    color: theme.palette.primary.redDark,
  },
  actionButtons: {
    marginTop: '40px',
  },
});

export default styles;
