import React, { createElement, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import useFetchEffect from '../../../hooks/useFetchEffect';
import Page from '../../../components/layouts/WithHeader';
import styles from './style';
import Box from '../../../components/atomic/Box';
import SearchField from '../../../components/SearchField';
import { AddNewButton } from '../../../components/Buttons';
import ErrorContainer from '../../../components/ErrorContainer';
import DesktopHeader from '../../../components/desktop/Header';
import { fetchPersonalDocuments as apiFetchPersonalDocuments } from '../../../api/propertyowners';
import {
  sGetFetchingPersonalDocuments,
  sGetFetchingPersonalDocumentsError,
  sGetPersonalDocuments,
} from '../../../store/reducers/personalDocuments';
import {
  acDeletePersonalDocument,
  acSetFetchingPersonalDocuments,
  acSetFetchingPersonalDocumentsError,
  acSetPersonalDocuments,
} from '../../../store/actions/personalDocuments';
import {
  acSetDocumentSearchText,
  acSetDocuments,
  acSetFetchingDocuments,
} from '../../../store/actions/documents';
import useDesktop from '../../../hooks/useDesktop';
import {
  getDocumentList,
  getDocumentTable,
} from '../../../utils/DocumentTable';
import { parseQuery } from '../../../utils/routes';
import ChapterTable from '../Dashboard/Documents/ChapterTable';
import ChapterList from '../Dashboard/Documents/ChapterList';
import { FileUploadContext } from '../../../contexts/fileUpload';
import { sGetDocumentSearchText } from '../../../store/reducers/documents';
import TabContext from '../../../components/atomic/TabContext';
import TabList from '../../../components/atomic/TabList';
import Tab from '../../../components/atomic/Tab';
import Typography from '../../../components/atomic/Typography';
import TabPanel from '../../../components/atomic/TabPanel';
import EmptyMessage from '../../../components/EmptyMessage';
import ManWorkingImg from '../../../assets/images/mann-kontorpult.png';
import Hidden from '../../../components/atomic/Hidden';
import AddContextMenu from '../Dashboard/AddContextMenu';
import Account from '../../../components/desktop/Header/Account';

const PersonalDocuments = ({
  setPersonalDocumentFetching,
  setPersonalDocuments,
  setPersonalDocumentFetchingError,
  personalDocuments,
  personalDocumentfetching,
  fetchingError,
  searchText,
  setSearchText,
  deletePersonalDocument,
  classes,
}) => {
  const [personalDocumentLoading, setPersonalDocumentLoading] = useState(false);
  const isDesktop = useDesktop();
  const { search } = useLocation();
  const parsedQuery = parseQuery(search);
  const { onFileUploadClick } = useContext(FileUploadContext);
  const [searchQuery, setSearchQuery] = useState(searchText);

  const [showEmptyListMessage, setShowEmptyListMessage] = useState(
    personalDocuments && personalDocuments.length === 0
  );

  const { chaptersMode } = parsedQuery || {};
  const isBoligmappaPlusUser = true;

  const getPersonalDocumentLoading = (data) => {
    setPersonalDocumentFetching(data);
    setPersonalDocumentLoading(data);
  };

  useFetchEffect({
    setFetchingFunction: (data) => getPersonalDocumentLoading(data),
    onSuccess: (data) => setPersonalDocuments(data.documents),
    onError: (error) => setPersonalDocumentFetchingError(error),
    apiFetchFunction: () => apiFetchPersonalDocuments(),
  });

  useEffect(() => {
    if (personalDocuments?.length < 1) {
      setShowEmptyListMessage(true);
    } else {
      setShowEmptyListMessage(false);
    }
  }, [personalDocuments]);

  const onDcumentUpload = () => {
    if (isDesktop) {
      onFileUploadClick({
        query: {
          bulkUploadMode: true,
          source: 'table',
          newUpload: true,
          isRoomOrEvent: true,
        },
      });
    } else {
      onFileUploadClick({
        query: {
          bulkUploadMode: true,
          source: 'listView',
          newUpload: true,
          isRoomOrEvent: true,
        },
      });
    }
  };

  const onDocumentDeleted = (document) => {
    deletePersonalDocument(document);
  };

  const onSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setSearchText(event.target.value);
  };

  const renderTable = () => {
    if (chaptersMode) {
      return createElement(isDesktop ? ChapterTable : ChapterList, {
        documents: personalDocuments,
        fetchingDocuments: personalDocumentfetching,
        fetchingDocumentsError: fetchingError,
        searchQuery,
      });
    }
    return createElement(isDesktop ? getDocumentTable() : getDocumentList(), {
      fetching: personalDocumentLoading,
      documents: personalDocuments,
      searchQuery,
      showBulkSelectionOptions: true,
      onDocumentDeleted,
      isPersonalDocuments: true,
      fetchingError,
      isFromPersonal: true,
    });
  };

  return (
    <Page
      maxWidth={false}
      hideHeader
      noPadding={!isDesktop}
      showBackButton={false}
      headerTitle="Dokumenter"
      minFullHeight={isDesktop}
    >
      {fetchingError ? (
        <ErrorContainer
          paddingTop={isDesktop}
          errorResponse={fetchingError}
          style={{ paddingTop: isDesktop ? undefined : '15%' }}
        />
      ) : (
        <Box className={isDesktop ? classes.contentBox : classes.mobilePadding}>
          {isDesktop && (
            <Hidden smDown>
              <DesktopHeader isFromPersonal />
            </Hidden>
          )}
          <Box
            className={isDesktop ? classes.headerBox : classes.headerBoxMobile}
          >
            <Typography
              className={
                isDesktop ? classes.headerTitle : classes.headerTitleMobile
              }
            >
              Dokumenter
            </Typography>
            {!isDesktop && <Account isFromPersonal />}
          </Box>
          <Box display="flex" alignItems="end" px={isDesktop ? 0 : 2}>
            <Box flex="50%" maxWidth={isDesktop ? '50%' : undefined}>
              {!chaptersMode && (
                <Box className={!isDesktop && classes.mobilePadding}>
                  <SearchField
                    fullWidth
                    value={searchQuery}
                    onChange={onSearchChange}
                    placeholder="Søk i dokumenter"
                  />
                </Box>
              )}
            </Box>

            {isDesktop && !chaptersMode && (
              <Box flex="30%" textAlign="right" ml={2}>
                <AddNewButton
                  text="Last opp dokument"
                  onClick={onDcumentUpload}
                  colored
                />
              </Box>
            )}
          </Box>

          <Box className={classes.tabContent}>
            <TabContext value="2">
              <Box ml={!isDesktop && 2}>
                <TabList
                  TabIndicatorProps={{ style: { background: 'none' } }}
                  className={classes.tabs}
                >
                  <Tab
                    className={classes.tabItem}
                    label={
                      <Typography className={classes.tabHeaderText}>
                        Boligens område
                      </Typography>
                    }
                    disabled
                    value="1"
                  />
                  <Tab
                    className={classes.tabItem}
                    label={
                      <Box className={classes.tabHeaderBox}>
                        <Typography className={classes.tabHeaderText}>
                          Personlig område
                        </Typography>
                        <Typography className={classes.tabHeaderNewText}>
                          Ny!
                        </Typography>
                      </Box>
                    }
                    value="2"
                  />
                </TabList>
              </Box>
              <TabPanel value="2">
                <Box mt={1}>{renderTable()}</Box>
              </TabPanel>
            </TabContext>
          </Box>

          {showEmptyListMessage && !personalDocumentfetching && (
            <Box className={isDesktop && classes.personalEmptyContetnt}>
              <EmptyMessage
                marginTop={false}
                onLinkClick={() => {}}
                className={classes.emptyPersonalMessage}
                isPersonal
                buttonText="Lær mer om personlig område"
                additionalContent={
                  <Box>
                    <img src={ManWorkingImg} alt="personal empty documents" />
                  </Box>
                }
              >
                <Typography className={classes.personalErrorHeader}>
                  Et hjem for verdisakene dine
                </Typography>
                <Typography className={classes.personalErrorText}>
                  Dokumenter i personlig område følger brukeren dinog er ikke
                  synlig i boligens område eller de du har delt tilgang med.
                  Slik kan du ta vare på viktig dokumentasjon, selv nor du
                  flytter.
                </Typography>
              </EmptyMessage>
            </Box>
          )}

          {/* Mobile only */}
          <Hidden mdUp>
            {!isDesktop && (
              <AddContextMenu
                isFromPersonal
                isBoligmappaPlusUser={isBoligmappaPlusUser}
              />
            )}
          </Hidden>
        </Box>
      )}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  fetchingError: sGetFetchingPersonalDocumentsError(state),
  personalDocumentfetching: sGetFetchingPersonalDocuments(state),
  personalDocuments: sGetPersonalDocuments(state),
  searchText: sGetDocumentSearchText(state),
});

const mapDispatchToProps = (dispatch) => ({
  setPersonalDocumentFetchingError: (fetchingError) =>
    dispatch(acSetFetchingPersonalDocumentsError(fetchingError)),
  setDocuments: (documents) => dispatch(acSetDocuments(documents)),
  setPersonalDocuments: (documents) =>
    dispatch(acSetPersonalDocuments(documents)),
  setFetching: (fetching) => dispatch(acSetFetchingDocuments(fetching)),
  setSearchText: (searchText) => dispatch(acSetDocumentSearchText(searchText)),
  setPersonalDocumentFetching: (fetching) =>
    dispatch(acSetFetchingPersonalDocuments(fetching)),
  deletePersonalDocument: (document) =>
    dispatch(acDeletePersonalDocument(document)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PersonalDocuments));
