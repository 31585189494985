import React, { useState } from 'react';
import PropertyRefreshContext from '../../contexts/propertyRefresh';

const defaultState = { propertyRefreshRequestInProgress: false };

const PropertyRefreshContextProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  const setPropertyRefreshRequestInProgress = (
    propertyRefreshRequestInProgress
  ) => setState({ ...state, propertyRefreshRequestInProgress });

  const exposed = { ...state, setPropertyRefreshRequestInProgress };

  return (
    <PropertyRefreshContext.Provider value={exposed}>
      {children}
    </PropertyRefreshContext.Provider>
  );
};

export default PropertyRefreshContextProvider;
