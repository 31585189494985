import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

import styles from './style';
import Form from '../BaseForm';
import Bin from '../../../assets/icons/Bin';
import Sortable from '../../atomic/Sortable';
import Drag from '../../../assets/icons/Drag';
import ArrowUp from '../../../assets/icons/ArrowUp';
import UploadImageButton from './UploadImageButton';
import ActionButton from '../../atomic/ActionButton';
import ArrowDown from '../../../assets/icons/ArrowDown';
import CirclesLoader from '../../loaders/CirclesLoader';
import matomo from '../../../utils/matomo';

const ImageForm = ({
  classes,
  onCancel,
  onSubmit,
  fetching,
  formError,
  fitImage = false,
  additionalFields,
  requestInProgress,
  uploadImageRequest,
  images: passedImages,
  onImagesUploaded: passedOnImagesUploaded,
  origin = '',
}) => {
  const [images, setImages] = useState([]);

  const lastImagePosition = Array.isArray(passedImages)
    ? passedImages.length
    : 0;

  const scrollToBottom = () => window.scrollTo(0, document.body.scrollHeight);

  const getImagesByIds = (ids) => {
    const imageIds = images.map((image) => String(image.id));

    return ids.reduce(
      (result, id, index) => [
        ...result,
        {
          ...images[imageIds.indexOf(id)],
          position: index + 1,
        },
      ],
      []
    );
  };

  const onChange = (imageIds) => {
    setImages(getImagesByIds(imageIds));
  };

  const onDelete = (indexToDelete) => {
    if (origin === 'DASHBOARD') {
      matomo.clickEvent({
        category: 'Dashboard CTAs',
        name: 'Delete Property Photo',
        action: 'Edit Property Photo',
      });
    }
    setImages(images.filter((image, index) => index !== indexToDelete));
  };

  const getNewIndex = (oldIndex, direction) => {
    if (oldIndex + direction < 0) return images.length + direction;
    if (oldIndex + direction >= images.length) return -1 + direction;

    return oldIndex + direction;
  };

  const onMoveClick = (imageId, oldIndex, direction) => {
    const newIndex = getNewIndex(oldIndex, direction);
    const leftIndex = oldIndex < newIndex ? oldIndex : newIndex;
    const rightIndex = oldIndex < newIndex ? newIndex : oldIndex;

    const reorderedImages = [
      ...images.slice(0, leftIndex),
      ...images.slice(leftIndex, rightIndex + 1).reverse(),
      ...images.slice(rightIndex + 1, images.length),
    ].map((image, index) => ({
      ...image,
      position: index + 1,
    }));

    setImages(reorderedImages);
  };

  const onFormSubmit = () => {
    if (origin === 'DASHBOARD') {
      matomo.clickEvent({
        category: 'Dashboard CTAs',
        name: 'Add Property Photo',
        action: 'Edit Property Photo',
        value: images?.length,
      });
    }

    onSubmit(images);
  };

  const onImagesUploaded = (uploadedImages) => {
    setImages([...images, ...uploadedImages]);

    passedOnImagesUploaded(uploadedImages);

    scrollToBottom();
  };

  useEffect(() => {
    if (!fetching && passedImages) {
      setImages(passedImages);
    }
  }, [fetching]);

  if (fetching) {
    return (
      <div className={classes.loaderContainer}>
        <CirclesLoader className={classes.loader} />
      </div>
    );
  }

  return (
    <Form
      error={formError}
      onCancel={onCancel}
      onSubmit={onFormSubmit}
      className={classes.form}
      requestInProgress={requestInProgress}
    >
      <UploadImageButton
        onImagesUploaded={onImagesUploaded}
        lastImagePosition={lastImagePosition}
        uploadImageRequest={uploadImageRequest}
        className={clsx(classes.imageContainer, classes.listItem)}
      />

      <Sortable onChange={onChange} options={{ handle: '.dragging-area' }}>
        {images.map(({ id, url }, index) => (
          <div
            data-id={id}
            key={`image-item-${id}`}
            className={classes.listItem}
          >
            <div
              className={clsx(classes.imageContainer, classes.greyBackground)}
            >
              <img
                src={url}
                alt="form"
                className={clsx(classes.image, fitImage && classes.fitImage)}
              />
              {index === 0 && (
                <div className={classes.primaryLabel}>HOVEDBILDE</div>
              )}
              <ActionButton
                size="small"
                className={classes.deleteIcon}
                onClick={() => onDelete(index)}
              >
                <Bin />
              </ActionButton>
            </div>

            <div className={classes.controls}>
              <ArrowUp
                className={classes.arrowButton}
                onClick={() => onMoveClick(id, index, -1)}
              />
              <Drag className={clsx('dragging-area', classes.draggingArea)} />
              <ArrowDown
                className={classes.arrowButton}
                onClick={() => onMoveClick(id, index, 1)}
              />
            </div>
          </div>
        ))}
      </Sortable>

      {additionalFields}
    </Form>
  );
};

export default withStyles(styles)(ImageForm);
