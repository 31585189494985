import { connect } from 'react-redux';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { withStyles } from '@material-ui/core';

import {
  sGetCompanies,
  sGetFetchingCompanies,
  sGetFetchingCompaniesError,
} from '../../../../store/reducers/companies';

import {
  acSetCompanies,
  acSetFetchingCompanies,
  acSetFetchingCompaniesError,
} from '../../../../store/actions/companies';

import styles from './styles';
import Box from '../../../../components/atomic/Box';
import useDesktop from '../../../../hooks/useDesktop';
import Paper from '../../../../components/atomic/Paper';
import Page from '../../../../components/layouts/PublicPage';
import useFetchEffect from '../../../../hooks/useFetchEffect';
import SearchField from '../../../../components/SearchField';
import CompanyList from '../../../../components/CompanyList';
import ErrorContainer from '../../../../components/ErrorContainer';
import { getFilteredCompanies } from '../../../../utils/companies';
import { fetchPublicPropertyCompanies as apiFetchPropertyCompanies } from '../../../../api/properties';
import Typography from '../../../../components/atomic/Typography';

const PropertyCompanies = ({
  fetching,
  companies,
  setFetching,
  setCompanies,
  fetchingError,
  setFetchingError,
}) => {
  const isDesktop = useDesktop();
  const { boligmappaNumber } = useParams();
  const [searchQuery, setSearchQuery] = useState('');

  const filteredCompanies = () =>
    getFilteredCompanies({
      companies,
      searchQuery,
    });

  const onSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useFetchEffect({
    dependencies: [boligmappaNumber],
    conditionFunction: ([_boligmappaNumber]) =>
      !companies && !!_boligmappaNumber && !fetching,
    apiFetchFunction: () => apiFetchPropertyCompanies(boligmappaNumber),

    setFetchingFunction: setFetching,
    setFetchingErrorFunction: setFetchingError,
    onSuccess: (_companies) => setCompanies(_companies),
  });

  const renderContent = () => {
    if (fetchingError) {
      return (
        <ErrorContainer
          paddingTop={isDesktop}
          errorResponse={fetchingError}
          style={{ paddingTop: !isDesktop ? '15%' : undefined }}
        />
      );
    }

    return (
      <Paper visible={!isDesktop}>
        <Box pt={isDesktop ? 0 : 2}>
          <SearchField
            bordered
            value={searchQuery}
            onChange={onSearchChange}
            placeholder="Søk etter firmanavn"
            style={{ width: isDesktop ? '45%' : undefined }}
          />
        </Box>

        <Box mt={2}>
          <CompanyList
            fetching={fetching}
            searchQuery={searchQuery}
            companies={filteredCompanies()}
            requestFrom="Public Property"
          />
        </Box>
      </Paper>
    );
  };

  return (
    <Page
      minFullHeight
      hidePublicHeader
      noPadding={!isDesktop}
      headerTitle="Boligens fagfolk"
      lessMarginLeft={isDesktop}
      maxWidth="lg"
      hideHeader={isDesktop}
    >
      <Typography
        variant={isDesktop ? 'h1' : 'h3'}
        color={isDesktop ? undefined : 'textPrimary'}
      >
        {isDesktop ? 'Boligens fagfolk' : null}
      </Typography>
      {renderContent()}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  companies: sGetCompanies(state),
  fetching: sGetFetchingCompanies(state),
  fetchingError: sGetFetchingCompaniesError(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCompanies: (companies) => dispatch(acSetCompanies(companies)),
  setFetching: (fetching) => dispatch(acSetFetchingCompanies(fetching)),
  setFetchingError: (fetchingError) =>
    dispatch(acSetFetchingCompaniesError(fetchingError)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PropertyCompanies));
