import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import CarouselControlArrowRight from '../../../assets/icons/CarouselControlArrowRight';

const CarouselControlArrow = ({
  classes,
  previous,
  className,
  slideCount,
  currentSlide,
  ...props
}) => (
  <CarouselControlArrowRight
    className={clsx(className, classes.arrow, previous && classes.previous)}
    {...props}
  />
);

export default withStyles(styles)(CarouselControlArrow);
