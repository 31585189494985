import React from 'react';
import { withStyles, useMediaQuery } from '@material-ui/core';

import styles from './style';
import Card from '../../Card';
import Box from '../../atomic/Box';
import Link from '../../atomic/Link';
import useDesktop from '../../../hooks/useDesktop';
import Typography from '../../atomic/Typography';
import useDashboardLinkPrefix from '../../../hooks/useDashboardLinkPrefix';

const FeatureCardMobile = ({ classes, feature, onClick }) => {
  const isDesktop = useDesktop();
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const isTabMode = useMediaQuery(`(min-width: 760px)`);
  const renderCard = (props) => {
    const { className, combinedOnClick } = props || {};
    return (
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        className={className}
        onClick={() => {
          if (combinedOnClick) combinedOnClick();
        }}
      >
        <Card
          className={
            isTabMode
              ? classes.propertysalescardtab
              : classes.propertysalescardmobile
          }
          border={0}
          display="flex"
          minWidth={146}
          minHeight={180}
          maxHeight={180}
          maxWidth={146}
          flexDirection="column"
          justifyContent="flex-end"
          style={{
            background: `url(${feature.image})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            padding: '0px',
          }}
        >
          <Box className={classes.gradientOverlay}>
            <Typography
              noMargins
              variant="h3"
              align="center"
              fontWeight={800}
              className={classes.mobileTitle}
            >
              {feature.renderTitle
                ? feature.renderTitle({ isDesktop })
                : feature.title}
            </Typography>
          </Box>
        </Card>

        <Box mt={1}>
          <Typography
            lineHeight={1}
            fontWeight={400}
            align="center"
            className={
              isTabMode
                ? classes.propertysalesdescriptiontab
                : classes.propertysalesdescriptionmobile
            }
          >
            <small>
              {feature.descriptionMobile
                ? feature.descriptionMobile
                : feature.description}
            </small>
          </Typography>
        </Box>
      </Box>
    );
  };

  const combinedOnClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return onClick ? (
    renderCard({ combinedOnClick, className: classes.mobileRoot })
  ) : (
    <Link
      inheritColor
      underline={false}
      onClick={combinedOnClick}
      className={classes.mobileRoot}
      to={`${dashboardLinkPrefix}/${feature.buttonLink}`}
    >
      {renderCard()}
    </Link>
  );
};

export default withStyles(styles)(FeatureCardMobile);
