const styles = (theme) => ({
  root: ({ colored }) => ({
    borderRadius: '50px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    padding: '10px 25px',
    background: colored
      ? theme.palette.primary.main
      : theme.palette.primary.white,
    boxShadow: '0 2px 5px 1px rgba(0,0,0,0.2)',
    color: 'red',
    height: '50px',
    '&:hover': {
      backgroundColor: colored ? theme.palette.primary.main : '#3333330a',
      borderColor: colored ? theme.palette.primary.main : '#13142A',
    },
  }),
  icon: ({ colored }) => ({
    marginRight: theme.spacing(1),
    color: colored ? theme.palette.primary.white : theme.palette.primary.main,
  }),
  text: ({ colored }) => ({
    color: colored ? theme.palette.primary.white : theme.palette.primary.main,
    fontWeight: '500',
  }),
});

export default styles;
