import Add from './Add.svg';
import AlertCircle from './Alert_Circle.svg';
import Apartment from './Apartment.svg';
import Approved from './Approved.svg';
import ArrowDown from './Arrow_Down.svg';
import ArrowLeft from './Arrow_Left.svg';
import ArrowRight from './Arrow_Right.svg';
import ArrowUp from './Arrow_Up.svg';
import Award from './Award.svg';
import Backspace from './Backspace.svg';
import Balcony from './Balcony.svg';
import BarChart from './Bar_Chart.svg';
import Bell from './Bell.svg';
import Calendar from './Calendar.svg';
import Camera from './Camera.svg';
import Castle from './Castle.svg';
import CaretDown from './Caret_Down.svg';
import CaretLeft from './Caret_Left.svg';
import CaretRight from './Caret_Right.svg';
import CaretUp from './Caret_Up.svg';
import Cart from './Cart.svg';
import Certificate from './Certificate.svg';
import Chat from './Chat.svg';
import Check from './Check.svg';
import CheckMark from './CheckMark.svg';
import CheckedFill from './CheckedFill.svg';
import Circle from './Circle.svg';
import Close from './Close.svg';
import Compare from './Compare.svg';
import ContactPerson from './Contact_Person.svg';
import CreditCard from './Credit_Card.svg';
import Cross from './Cross.svg';
import Dash from './Dash.svg';
import Decrease from './Decrease.svg';
import Diagram1 from './Diagram1.svg';
import Diagram2 from './Diagram2.svg';
import Diagram3 from './Diagram3.svg';
import Document from './Document.svg';
import Down from './Down.svg';
import Download from './Download.svg';
import Edit from './Edit.svg';
import Elevator from './Elevator.svg';
import Ellade from './Ellade.svg';
import Email from './Email.svg';
import Error from './Error.svg';
import Facilities from './Facilities.svg';
import Favorite from './Favorite.svg';
import Filter from './Filter.svg';
import Fireplace from './Fireplace.svg';
import Firework from './Firework.svg';
import Fullscreen from './Fullscreen.svg';
import Help from './Help.svg';
import Home from './Home.svg';
import HomeLike from './HomeLike.svg';
import House from './House.svg';
import House_v2 from './House_v2.svg';
import Housingtype from './Housingtype.svg';
import Image from './Image.svg';
import Inbox from './Inbox.svg';
import Increase from './Increase.svg';
import InfoCircle from './Info_Circle.svg';
import Left from './Left.svg';
import List from './List.svg';
import LoaderChevrons from './Loader_chevrons.svg';
import Location from './Location.svg';
import Lock from './Lock.svg';
import LockOpen from './Lock_Open.svg';
import LockedDocument from './Locked_Document.svg';
import MailArrow from './Mail_Arrow.svg';
import Map from './Map.svg';
import MapV2 from './MapV2.svg';
import MapLocation from './MapLocation.svg';
import Menu from './Menu.svg';
import MoreHorizontal from './More_Horizontal.svg';
import MoreVertical from './More_Vertical.svg';
import Number from './Number.svg';
import PDF from './PDF.svg';
import ParasolBalcony from './Parasol_Balcony.svg';
import Parking from './Parking.svg';
import Password_Eye from './Password_Eye.svg';
import Password_Eye_Closed from './Password_Eye_Closed.svg';
import Phone from './Phone.svg';
import Position from './Position.svg';
import Price_Graph from './Price_Graph.svg';
import Rental from './Rental.svg';
import Right from './Right.svg';
import Rooms from './Rooms.svg';
import Ruler from './Ruler.svg';
import Search from './Search.svg';
import SemiDetachedHouse from './SemiDetachedHouse.svg';
import Share from './Share.svg';
import Size from './Size.svg';
import Sold_Price from './Sold_Price.svg';
import Star from './Star.svg';
import Subtract from './Subtract.svg';
import Success from './Success.svg';
import SunDown from './Sun_Down.svg';
import SunUp from './Sun_Up.svg';
import TerracedHouse from './TerracedHouse.svg';
import Trash from './Trash.svg';
import Up from './Up.svg';
import Upload from './Upload.svg';
import UploadCloud from './Upload_cloud.svg';
import User from './User.svg';
import User_Edit from './User_Edit.svg';
import Users from './Users.svg';
import View from './View.svg';
import Zap from './Zap.svg';
import PlaceholderBuilder from './placeholder_builder.svg';
import PlaceholderElectrician from './placeholder_electrician.svg';
import PlaceholderHeatPump from './placeholder_heat_pump.svg';
import PlaceholderMaler from './placeholder_maler.svg';
import PlaceholderMason from './placeholder_mason.svg';
import PlaceholderMultiProfession from './placeholder_multi_profession_types.svg';
import PlaceholderOtherProfession from './placeholder_other_profession_types.svg';
import PlaceholderPlumber from './placeholder_plumber.svg';
import PlaceholderRoofingConstruction from './placeholder_roofing_construction.svg';
import FinnLogo from './FinnLogo.svg';
import Graph from './Graph.svg';
import Label from './Label.svg';
import Neighborhood from './Neighborhood.svg';

const icons = {
  Housingtype,
  Number,
  Rooms,
  Ruler,
  Add,
  AlertCircle,
  Approved,
  ArrowRight,
  ArrowLeft,
  ArrowDown,
  ArrowUp,
  Apartment,
  Award,
  Backspace,
  Balcony,
  BarChart,
  Bell,
  Calendar,
  CaretDown,
  CaretUp,
  CaretLeft,
  CaretRight,
  Camera,
  Cart,
  Chat,
  Check,
  Cross,
  CheckedFill,
  Circle,
  CheckMark,
  Close,
  Compare,
  CreditCard,
  Dash,
  Decrease,
  Diagram1,
  Diagram2,
  Diagram3,
  Document,
  Down,
  Download,
  Edit,
  Ellade,
  Elevator,
  Error,
  Facilities,
  Favorite,
  Filter,
  Fireplace,
  Fullscreen,
  Help,
  Home,
  HomeLike,
  House,
  House_v2,
  Image,
  Inbox,
  Increase,
  InfoCircle,
  Left,
  List,
  Location,
  Lock,
  LockOpen,
  LockedDocument,
  Map,
  MapV2,
  MapLocation,
  Menu,
  MailArrow,
  MoreHorizontal,
  MoreVertical,
  PDF,
  ParasolBalcony,
  Parking,
  Password_Eye_Closed,
  Password_Eye,
  PlaceholderElectrician,
  PlaceholderMaler,
  PlaceholderMason,
  PlaceholderPlumber,
  PlaceholderRoofingConstruction,
  PlaceholderHeatPump,
  PlaceholderBuilder,
  PlaceholderMultiProfession,
  PlaceholderOtherProfession,
  Position,
  Price_Graph,
  Rental,
  Right,
  Search,
  Share,
  Size,
  Sold_Price,
  Star,
  Success,
  SunDown,
  SunUp,
  Subtract,
  Trash,
  TerracedHouse,
  SemiDetachedHouse,
  Up,
  Upload,
  UploadCloud,
  User,
  Users,
  User_Edit,
  View,
  Zap,
  Certificate,
  ContactPerson,
  Email,
  Phone,
  LoaderChevrons,
  FinnLogo,
  Firework,
  Castle,
  Graph,
  Label,
  Neighborhood,
};

export type IconKey = keyof typeof icons;

export default icons;
