import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import styles from './style';
import BaseListItem from '../../../atomic/ListItem';
import Box from '../../../atomic/Box';
import Typography from '../../../atomic/Typography';
import Button from '../../../atomic/Button';
import RightArrow from '../../../../assets/icons/DocumentBulkEditIcons/RightArrow';
import MultiSelectComboBox from '../../../MultiSelectComboBox';
import DocumentTypeOptions from '../../DocumentTypeOptions';
import {
  ATTACH_EVENT_EDIT_OPTION,
  DOCUMENT_DELETE_OPTION,
  DOCUMENT_DOWNLOAD_OPTION,
  DOCUMENT_TYPE_EDIT_OPTION,
  MOVE_DOCUMENT_TO_PROPERTY_OPTION,
  COPY_DOCUMENT_TO_PROPERTY_OPTION,
  MOVE_BULK_DOCUMENT_TO_PERSONAL_OPTION,
  DOCUMENT_DELETE_OPTION_PERSONAL,
} from '../../../../utils/documents';
import MultipleDownloadLink from '../../../atomic/MultipleDownloadLink';
import { sGetEvents } from '../../../../store/reducers/events';
import { acAddDocument } from '../../../../store/actions/documents';

const EditOptionMobileItem = ({
  name,
  onFiltersReset,
  editOptions,
  icon,
  type,
  className,
  selectedDocuments,
  onSelectOption,
  border,
  classes,
  onMenuSave,
  onClick,
  events,
  addDocument,
  newEventAdded,
  isVisible,
  ...props
}) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  useEffect(() => {
    if (newEventAdded && type === ATTACH_EVENT_EDIT_OPTION) {
      setSubMenuOpen(true);
    }
  }, [newEventAdded]);
  const onItemClick = (e) => {
    setSubMenuOpen(true);
    e.stopPropagation();
    e.preventDefault();
  };

  const onMenuClose = () => {
    onFiltersReset();
    setSubMenuOpen(false);
  };

  const onSave = () => {
    setSubMenuOpen(false);
    onMenuSave();
  };

  return (
    isVisible && (
      <>
        {type === DOCUMENT_DOWNLOAD_OPTION ? (
          <MultipleDownloadLink documents={selectedDocuments}>
            <BaseListItem
              className={clsx(
                className,
                classes.listItem,
                border && classes.borderClass
              )}
              {...props}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ mr: 2 }}>{icon}</Box>
                <Typography fontSize={16} fontWeight={500}>
                  {name}
                </Typography>
              </Box>
            </BaseListItem>
          </MultipleDownloadLink>
        ) : (
          <BaseListItem
            className={clsx(
              className,
              classes.listItem,
              border && classes.borderClass
            )}
            onClick={
              type !== DOCUMENT_DELETE_OPTION &&
              type !== DOCUMENT_DELETE_OPTION_PERSONAL &&
              type !== MOVE_BULK_DOCUMENT_TO_PERSONAL_OPTION
                ? onItemClick
                : onClick && onClick
            }
            {...props}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: type === DOCUMENT_DELETE_OPTION ? '#D7394C' : '',
              }}
            >
              <Box sx={{ mr: 2 }}>{icon}</Box>
              <Typography fontSize={16} fontWeight={500}>
                {name}
              </Typography>
            </Box>
            {!border && (
              <Button>
                <RightArrow width="0.6rem" height="0.6rem" />
              </Button>
            )}
          </BaseListItem>
        )}

        {type !== DOCUMENT_DOWNLOAD_OPTION &&
          type !== DOCUMENT_DELETE_OPTION &&
          type !== DOCUMENT_DELETE_OPTION_PERSONAL &&
          type !== MOVE_BULK_DOCUMENT_TO_PERSONAL_OPTION &&
          (type === DOCUMENT_TYPE_EDIT_OPTION ||
          type === MOVE_DOCUMENT_TO_PROPERTY_OPTION ||
          type === COPY_DOCUMENT_TO_PROPERTY_OPTION ? (
            <DocumentTypeOptions
              description={name}
              menuOpen={subMenuOpen}
              onMenuClose={onMenuClose}
              onMenuSave={onSave}
              editOptions={editOptions}
              radioGroupName="Document Type"
              onFiltersReset={onFiltersReset}
              onSelectOption={onSelectOption}
              selectedDocuments={selectedDocuments}
              enableSearch={type === DOCUMENT_TYPE_EDIT_OPTION}
            />
          ) : (
            <MultiSelectComboBox
              type={type}
              description={name}
              isFiltering={false}
              menuOpen={subMenuOpen}
              setMenuOpen={setSubMenuOpen}
              selectedDocuments={selectedDocuments}
              onMenuClose={onMenuClose}
              onFiltersReset={onFiltersReset}
              filterOptions={editOptions}
              onMenuSave={onSave}
            />
          ))}
      </>
    )
  );
};

const mapStateToProps = (state) => ({
  events: sGetEvents(state),
});

const mapDispatchToProps = (dispatch) => ({
  addDocument: (document) => dispatch(acAddDocument(document)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditOptionMobileItem));
