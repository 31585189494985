import React, { useEffect, useState } from 'react';
import Purchases from '@componentsV2/Purchases';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import styles from './style';

import useDashboardLinkPrefix from '../../../../hooks/useDashboardLinkPrefix';
import {
  boligmappaPlusCancel,
  getUserTransactions,
} from '../../../../api/boligmappaPlus';

import {
  getActiveSubscriptions,
  getFormattedTransactionsData,
  getPaidSinglePayments,
} from '../../../../utils/boligmappaPlus';
import useSnackbar from '../../../../hooks/useSnackbar';
import { sGetUserTransactions } from '../../../../store/reducers/userTransactions';
import WithHeader from '../../../../components/layouts/WithHeader';

const PurchasesPage = ({ classes }) => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userTransactions, setUserTransactions] = useState({});
  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const { showSuccessMessage, showErrorMessage } = useSnackbar();

  const onSubscriptionCancel = async (subId) => {
    const [error, response] = await boligmappaPlusCancel(subId);
    if (!error && response) {
      setTransactions((prevState) =>
        prevState.filter((el) => el.orderNumber !== subId)
      );
      showSuccessMessage('Abonnementet er avsluttet!');
    } else {
      showErrorMessage('Noe gikk galt.');
    }
  };

  const fetchStoredUserTransactions = async () => {
    if (userTransactions) {
      const transactionsData = [
        ...getActiveSubscriptions(userTransactions.subscriptions),
        ...getPaidSinglePayments(userTransactions.singlePayments),
      ];

      setTransactions(getFormattedTransactionsData(transactionsData));
      setLoading(false);
    }
  };

  const fetchUserTransactions = async () => {
    const [error, response] = await getUserTransactions();
    if (!error && response) {
      setUserTransactions(response.responses);
    }
  };

  useEffect(() => {
    fetchUserTransactions();
  }, []);

  useEffect(() => {
    fetchStoredUserTransactions();
  }, [userTransactions]);

  return (
    <WithHeader headerTitle="Mine kjøp" noPadding>
      <div className={classes.page}>
        <Purchases
          dashboardLinkPrefix={dashboardLinkPrefix}
          transactions={transactions}
          setTransactions={setTransactions}
          onSubscriptionCancel={onSubscriptionCancel}
          loading={loading}
        />
      </div>
    </WithHeader>
  );
};

const mapStateToProps = (state) => ({
  userTransactions: sGetUserTransactions(state),
});

export default connect(mapStateToProps)(withStyles(styles)(PurchasesPage));
