import { checked } from '../util';

const styles = (theme) => ({
  root: {
    cursor: 'pointer',
    paddingLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    '&:hover': {
      background: theme.palette.primary.greyLight,
    },
  },
  label: {
    fontSize: '13px',
  },
  checked: (state) => ({
    display: checked(state),
    height: '20px',
    width: '20px',
  }),
});

export default styles;
