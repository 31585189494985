import clsx from 'clsx';
import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Image from '../../Image';
import Box from '../../atomic/Box';
import BrokenPropertyImage from '../../../assets/images/brokenPropertyImage.png';

const SelectionList = ({
  classes,
  currentIndex,
  onImageSelected,
  images: passedImages,
}) => {
  const images = Array.isArray(passedImages)
    ? passedImages.filter((image) => image.url)
    : [];

  const onImageClick = (index) => () => onImageSelected(index);

  return (
    <Box className={classes.root}>
      {images.map((image, index) => (
        <Image
          src={image.url}
          onClick={onImageClick(index)}
          fallbackSrc={BrokenPropertyImage}
          key={`selection-list-image-item-${image.url}`}
          className={clsx(
            classes.image,
            currentIndex === index && classes.active
          )}
        />
      ))}
    </Box>
  );
};

export default withStyles(styles)(SelectionList);
