import { connect } from 'react-redux';
import { useParams } from 'react-router';
import React, { Fragment, useContext } from 'react';

import useDesktop from '../../../../../hooks/useDesktop';
import Paper from '../../../../../components/atomic/Paper';
import Page from '../../../../../components/layouts/WithHeader';
import useFetchEffect from '../../../../../hooks/useFetchEffect';
import { getPropertyLoans } from '../../../../../utils/financials';
import ErrorContainer from '../../../../../components/ErrorContainer';
import PropertyLoansModule from '../../../../../modules/PropertyLoans';
import { sGetFinancials } from '../../../../../store/reducers/financials';
import { acSetFinancials } from '../../../../../store/actions/financials';
import PropertyRefreshContext from '../../../../../contexts/propertyRefresh';
import { fetchFinancials as apiFetchFinancials } from '../../../../../api/properties';

const PropertyLoans = ({ financials, setFinancials }) => {
  const isDesktop = useDesktop();
  const { boligmappaNumber } = useParams();

  const { propertyRefreshRequestInProgress } = useContext(
    PropertyRefreshContext
  );

  const { fetching, fetchingError } = useFetchEffect({
    initialFetchingState: !financials,
    conditionFunction: () => !financials,
    onSuccess: (data) => setFinancials(data),
    apiFetchFunction: () => apiFetchFinancials(boligmappaNumber),
  });

  const loans = getPropertyLoans(financials);

  const renderLoan = (loan, index, total) => (
    <Fragment key={`loan-item-${index}`}>
      <Paper visible={!isDesktop}>
        <PropertyLoansModule
          loan={loan}
          fetching={fetching}
          subtitle={`${index + 1} AV ${total}`}
        />
      </Paper>
      <br />
    </Fragment>
  );

  return (
    <Page
      minFullHeight
      noPadding={!isDesktop}
      hideHeader={isDesktop}
      headerTitle="Heftelser"
      showProgressBar={propertyRefreshRequestInProgress}
      lessMarginLeft={isDesktop}
      maxWidth="lg"
    >
      {!isDesktop && <br />}

      {fetching && (
        <Paper visible={!isDesktop}>
          <PropertyLoansModule fetching />
        </Paper>
      )}

      {!fetching && fetchingError && (
        <ErrorContainer paddingTop={isDesktop} errorResponse={fetchingError} />
      )}

      {!fetching &&
        !fetchingError &&
        Array.isArray(loans) &&
        loans.map((loan, index) => renderLoan(loan, index, loans.length))}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  financials: sGetFinancials(state),
});

const mapDispatchToProps = (dispatch) => ({
  setFinancials: (financials) => dispatch(acSetFinancials(financials)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyLoans);
