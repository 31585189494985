import { useEffect } from 'react';

const useScript = (ready, scriptURL) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = scriptURL;
    if (ready) {
      document.body.appendChild(script);
    }

    return () => {
      try {
        document.body.removeChild(script);
        // eslint-disable-next-line no-empty
      } catch {}
    };
  }, [ready]);
};

export default useScript;
