const styles = () => ({
  content: {
    maxHeight: '70vh',
    overflowY: 'auto',
  },
  textButton: {
    textDecoration: 'none',
  },
});

export default styles;
