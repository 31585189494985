import React from 'react';

const DownloadOnDiscAltIcon = ({ width = '14', height = '15', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M7.65625 1.40625V8.81641L10.2539 6.21875C10.5 5.94531 10.9102 5.94531 11.1836 6.21875C11.4297 6.46484 11.4297 6.875 11.1836 7.12109L7.4375 10.8398C7.19141 11.1133 6.78125 11.1133 6.53516 10.8398L2.81641 7.12109C2.54297 6.875 2.54297 6.46484 2.81641 6.21875C3.0625 5.94531 3.47266 5.94531 3.74609 6.21875L6.34375 8.81641V1.40625C6.34375 1.05078 6.61719 0.75 7 0.75C7.35547 0.75 7.65625 1.05078 7.65625 1.40625ZM3.5 9.0625L4.8125 10.375H1.75C1.50391 10.375 1.3125 10.5938 1.3125 10.8125V13C1.3125 13.2461 1.50391 13.4375 1.75 13.4375H12.25C12.4688 13.4375 12.6875 13.2461 12.6875 13V10.8125C12.6875 10.5938 12.4688 10.375 12.25 10.375H9.16016L10.4727 9.0625H12.25C13.207 9.0625 14 9.85547 14 10.8125V13C14 13.9844 13.207 14.75 12.25 14.75H1.75C0.765625 14.75 0 13.9844 0 13V10.8125C0 9.85547 0.765625 9.0625 1.75 9.0625H3.5ZM11.8125 11.9062C11.8125 12.2891 11.5117 12.5625 11.1562 12.5625C10.7734 12.5625 10.5 12.2891 10.5 11.9062C10.5 11.5508 10.7734 11.25 11.1562 11.25C11.5117 11.25 11.8125 11.5508 11.8125 11.9062Z"
      fill="#111111"
    />
  </svg>
);
export default DownloadOnDiscAltIcon;
