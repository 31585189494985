const styles = (theme) => ({
  filters: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    background: theme.palette.primary.white,
  },
});

export default styles;
