const styles = () => ({
  h1: {
    float: 'left',
    marginTop: 0,
    fontSize: '32px',
    marginRight: '10px',
  },
  textContent: {
    top: '166px',
    left: '292px',
    width: '55%',
  },
  introContent: {
    textAlign: 'left',
    top: '166px',
    left: '292px',
    maxWidth: '570px',
    height: '92px',
    opacity: '1',
  },
  introMobileContent: {
    textAlign: 'center',
    top: '166px',
    left: '292px',
    maxWidth: '570px',
    height: '92px',
    opacity: '1',
    '@media only screen and (min-width: 460px) and (max-width: 760px)': {
      textAlign: 'center',
      top: '166px',
      left: '292px',
      maxWidth: '570px',
      height: '92px',
      opacity: '1',
      marginLeft: '6%',
    },
  },
  introTabContent: {
    textAlign: 'center',
    top: '166px',
    left: '292px',
    height: '92px',
    opacity: '1',
  },
  section_seperator: {
    marginBottom: 30,
  },
  titleAreaMobile: {
    width: 'max-content',
  },
  salesFeatures: {
    width: '90%',
  },
  salesFeaturesMobile: {
    paddingTop: '63px',
    '@media only screen and (min-width: 460px) and (max-width: 760px)': {
      paddingTop: '0px',
    },
    '@media only screen and (min-width: 380px) and (max-width: 387px)': {
      paddingTop: '0px',
    },
  },
  salesInfo: {
    width: '90%',
    paddingTop: '40px',
  },
  salesInfoMobile: {
    width: '100%',
  },
  divider: {
    margin: '30px 0',
  },
});

export default styles;
