import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import Cookies from 'js-cookie';
import InputField from '@vb/components/build/components/forms/inputs/InputField/InputField';
import Checkbox from '../atomic/Checkbox';
import Link from '../atomic/Link';
import styles from './style';
import ProgressButton from '../ProgressButton';
import {
  getCompleteAddress,
  getPropertyOwnerFirstname,
  getPropertyOwnerLastname,
  getPropertyZip,
} from '../../utils/properties';
import useFetchEffect from '../../hooks/useFetchEffect';
import {
  fetchPropertyOwner as apiFetchPropertyOwner,
  updatePropertyOwner,
} from '../../api/propertyowners';
import { getMobileNumber, getUserEmail } from '../../utils/auth';
import useDesktop from '../../hooks/useDesktop';
import InfoTransparent from '../../assets/icons/InfoTransparent';
import {
  getInsurancePaymentInfo,
  insuranceSinglePayment,
} from '../../api/insurance';
import { useActiveProperty } from '../../store/hooks';
import matomo from '../../utils/matomo';
import {
  AngrerettskjemaLink,
  ProduktarkLink,
  FullstendigeVilkårLink,
} from '../../config';

const FormSection = ({ classes, loading, availableCompanies }) => {
  const [formFields, setFormFields] = useState({});
  const [additionalInfoQty, setAdditionalInfoQty] = useState(0);
  const [formValidation, setFormValidation] = useState({});
  const [formError, setFormError] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const isDesktop = useDesktop();
  const { property: activeProperty } = useActiveProperty();

  const fullstendigeVilkårLink = FullstendigeVilkårLink;
  const produktarkLink = ProduktarkLink;
  const angrerettskjemaLink = AngrerettskjemaLink;
  const policyLink = 'https://www.boligmappa.no/vilkar-for-kjop';

  const errorMessages = {
    firstName: 'Fornavn mangler',
    lastName: 'Etternavn mangler',
    phone: 'Mobilnummer mangler',
    email: 'E-post mangler',
    address: 'Adresse mangler',
    postalCode: 'Postnummer mangler',
    location: 'Sted mangler',
    checkboxes: 'Du må gi ditt samtykke for å kunne gjennomføre kjøpet.',
    companyId: 'Utførende bedrift mangler',
  };

  const { data: propertyOwner } = useFetchEffect({
    initialDataState: {},
    defaultDataState: {},
    initialFetchingState: true,
    apiFetchFunction: apiFetchPropertyOwner,
  });

  const requiredFields = [
    'firstName',
    'lastName',
    'phone',
    'email',
    'address',
    'postalCode',
    'companyId',
    'privacy',
    'terms',
  ];
  const validateFields = () => {
    const validationData = {};
    requiredFields.forEach((field) => {
      validationData[field] =
        formFields[field]?.length > 0 || !!formFields[field];
    });
    setFormValidation(validationData);
    return requiredFields.every((el) => validationData[el] === true);
  };
  const onSubmitRequest = async (orderId) => {
    const [error, response] = await insuranceSinglePayment(orderId);
    if (!error && response) {
      Cookies.set(
        'insuranceFormData',
        { ...formFields, orderId },
        { expires: 1, path: '/' }
      );
      window.location.href = response.response.agreementFrontendUrl;
    } else {
      setIsLoadingSubmit(false);
      setFormError(true);
    }
  };
  const onFormSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateFields();
    if (isValid) {
      matomo.clickEvent({
        category: 'Trygt utført',
        name: '',
        action: 'Clicked Purchase',
      });

      setIsLoadingSubmit(true);
      updatePropertyOwner({ phone: formFields.phone });
      const [error, response] = await getInsurancePaymentInfo(
        formFields.documentId,
        formFields.companyId,
        formFields.additionalInfo
      );
      if (!error && response) {
        onSubmitRequest(response.response.orderId);
      } else {
        setFormError(true);
      }
    } else {
      setShowErrors(true);
    }
  };

  const handlePhoneChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    let formattedPhoneNumber = fieldValue.replace(/[^\d+\s]/g, '');
    if (formattedPhoneNumber.charAt(0) !== '+') {
      formattedPhoneNumber = `+${formattedPhoneNumber}`;
    }
    setFormFields((prevState) => ({
      ...prevState,
      [fieldName]: formattedPhoneNumber,
    }));
  };

  const onFieldValueChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue =
      e.target.type !== 'checkbox' ? e.target.value : e.target.checked;
    setFormFields((prevState) => ({ ...prevState, [fieldName]: fieldValue }));
  };

  useEffect(() => {
    const firstName = getPropertyOwnerFirstname(propertyOwner);
    const lastName = getPropertyOwnerLastname(propertyOwner);
    const phone = getMobileNumber(propertyOwner);
    const email = getUserEmail(propertyOwner);
    const postalCode =
      propertyOwner?.postalCode || getPropertyZip(activeProperty);
    const address =
      propertyOwner?.streetAddress || getCompleteAddress(activeProperty);
    formFields.firstName = firstName;
    formFields.lastName = lastName;
    formFields.phone = phone;
    formFields.email = email;
    formFields.postalCode = postalCode;
    formFields.address = address;
  }, [propertyOwner, activeProperty]);
  useEffect(() => {
    if (showErrors) {
      setShowErrors(!validateFields());
    }
  }, [formFields]);
  return (
    <div className={clsx([classes.formSection, !isDesktop && 'mobile'])}>
      <h2>Kjøp forsikring</h2>
      <p>Vennligst fyll ut alle felter.</p>
      <form
        className={clsx([classes.insuranceForm, !isDesktop && 'mobile'])}
        onSubmit={(e) => onFormSubmit(e)}
      >
        <div className={clsx([classes.formSide, !isDesktop && 'mobile'])}>
          <div className={clsx([classes.formBlock, classes.formLeftBlock])}>
            <h3>Om forsikrede</h3>
            <h4>Forsikringen er personlig.</h4>
            <div className={clsx([classes.formRow, !isDesktop && 'mobile'])}>
              <InputField
                label="Telefonnummer"
                name="phone"
                type="tel"
                value={formFields.phone || ''}
                className={clsx([
                  classes.inputField,
                  showErrors && !formValidation.phone && 'error',
                ])}
                onChange={(e) => handlePhoneChange(e)}
              />
              <div
                className={clsx([classes.dataBlock, !isDesktop && 'mobile'])}
              >
                <p>
                  <b>
                    {formFields.firstName} {formFields.lastName}
                  </b>
                </p>
                <p>{formFields.email}</p>
                <p>{formFields.address}</p>
                <p>{formFields.postalCode}</p>
              </div>
            </div>
          </div>
          <div className={clsx([classes.formBlock, classes.formLeftBlock])}>
            <h3>Utførende bedrift</h3>
            <h4>
              Velg hvilken bedrift som har utført arbeidet du ønsker forsikret.
              Ved skademelding må du kunne fremvise dokumentasjon på at arbeidet
              har funnet sted.{' '}
            </h4>
            {!loading &&
              availableCompanies.map((company) => (
                <label
                  key={company.companyId}
                  htmlFor={`company-${company.companyId}`}
                  className={classes.companyInputRow}
                >
                  <input
                    type="radio"
                    className={classes.companyInput}
                    id={`company-${company.companyId}`}
                    name="companyId"
                    onChange={(e) => {
                      onFieldValueChange(e);
                      setFormFields((prevState) => ({
                        ...prevState,
                        documentId: company.documentId,
                      }));
                    }}
                    value={company.companyId}
                  />
                  <p>{company.companyName}</p>
                </label>
              ))}
            <div className={classes.additionalInfoBlock}>
              <label className={classes.textAreaLabel}>
                Ekstra opplysninger (valgfritt)
              </label>
              <textarea
                name="additionalInfo"
                placeholder="Kort beskrivelse"
                value={formFields.additionalInfo}
                onChange={(e) => {
                  if (e.target.value.length <= 200) {
                    onFieldValueChange(e);
                    setAdditionalInfoQty(e.target.value.length);
                  }
                }}
              />
              <p>{additionalInfoQty}/200</p>
            </div>
          </div>
          <div className={clsx([classes.formBlock, classes.formLeftBlock])}>
            <h3>Samtykke</h3>
            <div
              className={clsx([classes.checkboxRow, !isDesktop && 'mobile'])}
            >
              <Checkbox
                name="terms"
                className={classes.checkbox}
                onChange={(e) => onFieldValueChange(e)}
                style={{ padding: 0 }}
              >
                <p>
                  Jeg har lest og forstått <Link to="#">vilkårene</Link> for
                  forsikringen Trygt utført
                </p>
              </Checkbox>
              <Checkbox
                name="privacy"
                className={classes.checkbox}
                onChange={(e) => onFieldValueChange(e)}
                style={{ padding: 0 }}
              >
                <p>
                  Jeg godtar at personopplysningene mine deles med Help
                  Forsikring AS
                </p>
              </Checkbox>
              <Checkbox
                name="subscription"
                className={classes.checkbox}
                onChange={(e) => onFieldValueChange(e)}
                style={{ padding: 0 }}
              >
                <p>
                  Jeg ønsker å motta e-post om nye produkter og tjenester
                  (valgfritt)
                </p>
              </Checkbox>
            </div>
            {showErrors &&
              !!requiredFields.filter((el) => !formValidation[el]).length && (
                <div className={clsx([classes.notification, 'errors'])}>
                  <InfoTransparent fill="#FD6765" />
                  <div className={classes.submitErrors}>
                    <p>
                      {
                        requiredFields.filter((el) => !formValidation[el])
                          .length
                      }{' '}
                      felt mangler
                    </p>
                    <ul>
                      {requiredFields.map(
                        (el) =>
                          !formValidation[el] &&
                          errorMessages[el] && (
                            <li key={el}>{errorMessages[el]}</li>
                          )
                      )}
                      {(!formValidation.terms || !formValidation.privacy) && (
                        <li>{errorMessages.checkboxes}</li>
                      )}
                    </ul>
                  </div>
                </div>
              )}
            <ProgressButton
              type="submit"
              className={clsx([classes.submitButton])}
              color="primary"
              variant="contained"
              disabled={showErrors || formError}
              requestInProgress={isLoadingSubmit}
            >
              Kjøp forsikring
            </ProgressButton>
          </div>
        </div>
        <div className={clsx([classes.formAside, !isDesktop && 'mobile'])}>
          <div className={clsx([classes.formBlock, classes.perYear])}>
            <div className={classes.textRow}>
              <p className={classes.pricePerYear}>500 kr</p>
              <p className={classes.pricePerYearText}>/ utført jobb</p>
            </div>
            <p className={classes.PerYearText}>
              Forsikringen løper fra tegning og i fem år fra tegningsdato.
            </p>
          </div>
          <div className={clsx([classes.formBlock, classes.asideFormBlock])}>
            <h3>Forsikringen dekker</h3>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                Inntil 5 timer juridisk rådgivning per år i saker der det ikke
                foreligger tvist
              </li>
              <li className={classes.listItem}>
                Ved tvist dekkes inntil kr 300.000 per forsikringstilfelle
              </li>
              <li className={classes.listItem}>
                Forsikringen dekker to tvister i forsikringsperioden
              </li>
              <li className={classes.listItem}>
                Forsikringen gjelder arbeidet nevnt i dokumentasjonen håndverker
                har lastet opp på Boligmappa.
              </li>
            </ul>
          </div>
          <div className={clsx([classes.formBlock, classes.asideFormBlock])}>
            <h3>Fullstendige vilkår</h3>
            <p className={classes.asideBlockText}>
              Informasjonen på denne siden er forenklet og forkortet. Det er
              alltid de fullstendige vilkårene dine som gjelder.
            </p>
            <ul className={clsx([classes.list, 'files'])}>
              <li className={classes.listItem}>
                <Link to={fullstendigeVilkårLink} isExternal openInNewTab>
                  Last ned fullstendige vilkår
                </Link>{' '}
                (PDF)
              </li>
              <li className={classes.listItem}>
                <Link to={produktarkLink} isExternal openInNewTab>
                  Last ned produktark
                </Link>{' '}
                (PDF)
              </li>
              <li className={classes.listItem}>
                <Link to={policyLink} isExternal openInNewTab>
                  Vilkår for kjøp
                </Link>{' '}
                (Boligmappa.no)
              </li>
              <li className={classes.listItem}>
                <Link to={angrerettskjemaLink} isExternal openInNewTab>
                  Angrerettskjema
                </Link>{' '}
                (Word)
              </li>
            </ul>
            <p className={classes.asideBlockText}>
              Angrefrist for forsikringsavtalen er 14 dager fra
              forsikringsavtalens startdato.
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default withStyles(styles)(FormSection);
