import { apiBaseUrl, helpInsuraceProductId } from '../config';
import { request, get } from '../utils/http';

export const insuranceSinglePayment = (orderId) =>
  request({
    method: 'post',
    url: 'propertyowners/user-singlepayments',
    baseURL: apiBaseUrl,
    data: {
      orderNumber: orderId,
      paymentMethod: 'VIPPS',
      singlePaymentProductId: helpInsuraceProductId,
    },
  })
    .then((response) => [null, response.data])
    .catch((error) => [error, null]);

export const getInsurancePaymentInfo = (
  documentId,
  companyId,
  userInput = ''
) =>
  request({
    method: 'post',
    url: 'propertyowners/payment-info',
    baseURL: apiBaseUrl,
    data: {
      ProfessionalDocumentId: documentId,
      ProductName: 'Help Insurance',
      CompanyId: companyId,
      UserInput: userInput,
    },
  })
    .then((response) => [null, response.data])
    .catch((error) => [error, null]);

export const getUserPaymentInfoById = (id) =>
  get(`propertyowners/payment-info/${id}`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);
