import { useSnackbar as useSnackbarBase } from 'notistack';

import { getErrorMessage } from '../utils/requests';
import { getRandomUid, isString } from '../utils/strings';
import { DEFAULT_DOWNLOAD_RESTRICTION_MESSAGE } from '../utils/documents';

export const defaultSuccessMessage = 'Endringer lagret.';
export const defaultErrorMessage = 'Beklager, men noe gikk galt.';

const useSnackbar = () => {
  const snackbar = useSnackbarBase();

  const showMessage = (payload, options, variant) => {
    const key = getRandomUid();

    const message = isString(payload)
      ? payload
      : getErrorMessage(payload, defaultErrorMessage);

    snackbar.enqueueSnackbar(message, {
      key,
      variant,
      ...options,
    });

    return () => snackbar.closeSnackbar(key);
  };

  return {
    showInfoMessage: (message, options) =>
      showMessage(message, options, 'info'),
    showWarningMessage: (
      message = DEFAULT_DOWNLOAD_RESTRICTION_MESSAGE,
      options
    ) => showMessage(message, options, 'warning'),
    showErrorMessage: (message = defaultErrorMessage, options) =>
      showMessage(message, options, 'error'),
    showSuccessMessage: (message = defaultSuccessMessage, options) =>
      showMessage(message, options, 'success'),
  };
};

export default useSnackbar;
