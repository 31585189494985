import moment from 'moment';
import { virdiMapUrl } from '../config';
import { isNativeEnv, openLinkRequest } from './nativeBridge';
import { toLowerCase } from './strings';

export const eventLimit = 6;

export const selectedEventLimit = 3;

export const propertUnitTranslations = [
  {
    type: 'apartment',
    no: 'Leilighet',
  },
  {
    type: 'rowhouse',
    no: 'Rekkehus',
  },
  {
    type: 'semidetatchedhouse',
    no: 'Tomannsbolig',
  },
  {
    type: 'holidayhome',
    no: 'Fritidsbolig',
  },
  {
    type: 'other',
    no: 'Annet',
  },
  {
    type: 'house',
    no: 'Hus',
  },
];

export const truncateArray = (mEvents) =>
  mEvents && mEvents.length > 0 ? mEvents.slice(0, selectedEventLimit) : [];
export const getPropertyCount = (marketEvents, limit) => {
  if (marketEvents && marketEvents.length > 0) {
    return marketEvents.length === limit ? `${limit}+` : marketEvents.length;
  }
  return null;
};

export const getEstimatePrice = (unit) =>
  unit && unit.estimate && unit.estimate.estimatedPrice
    ? Math.round(unit.estimate.estimatedPrice)
    : null;

export const sortArraybyEstimatePrice = (firstArray, secondArray) => {
  if (firstArray.length > 0 || secondArray.length > 0) {
    const events = [...firstArray, ...secondArray];
    // eslint-disable-next-line func-names
    events.sort(function (a, b) {
      return a.estimate.estimatedPrice - b.estimate.estimatedPrice;
    });
    return events;
  }
  return [];
};

export const getMaxValueofArray = (firstArray, secondArray) => {
  const sortedArray = sortArraybyEstimatePrice(firstArray, secondArray);
  return getEstimatePrice(sortedArray[sortedArray.length - 1]);
};

export const getMinValueofArray = (firstArray, secondArray) => {
  const sortedArray = sortArraybyEstimatePrice(firstArray, secondArray);
  const obj = sortedArray.find((s) => s.estimate.estimatedPrice != null);
  return obj && sortedArray.indexOf(obj) !== sortedArray.length - 1
    ? getEstimatePrice(obj)
    : 0;
};

export const getAddress = (unit) =>
  unit ? `${unit.street} ${unit.number} , ${unit.postalCode} ${unit.city}` : '';

export const getUnitType = (unit) => {
  let unitType = null;
  if (unit && unit.unitType) {
    unitType = propertUnitTranslations.find(
      (a) => a.type === toLowerCase(unit.unitType)
    );
  }
  return unitType && unitType.no ? unitType.no : 'Annet';
};

export const getSize = (unit) => (unit && unit.size ? unit.size : null);

export const getProm = (unit) =>
  unit && unit.prom ? unit.prom : getSize(unit);

export const getRoomCount = (unit) =>
  unit && unit.bedrooms ? unit.bedrooms : null;

export const getFixedDate = (unit) =>
  unit && unit.marketData && unit.marketData.fixedDate
    ? unit.marketData.fixedDate
    : null;

export const getRegisteredDate = (unit) =>
  unit && unit.marketData && unit.marketData.registerDate
    ? unit.marketData.registerDate
    : null;

export const getAnnounceDate = (type, unit) => {
  if (type === 'sold') {
    return getFixedDate(unit);
  }
  return getRegisteredDate(unit);
};

export const getAskingPrice = (unit) =>
  unit && unit.marketData && unit.marketData.askingPrice
    ? Math.round(unit.marketData.askingPrice)
    : 0;

export const getFixedPrice = (unit) =>
  unit && unit.marketData && unit.marketData.fixedPrice
    ? Math.round(unit.marketData.fixedPrice)
    : 0;

export const getCommonDebts = (unit) =>
  unit && unit.estimate && unit.estimate.commonDebt
    ? unit.estimate.commonDebt
    : 0;

export const getPriceForSoldUnit = (unit) => {
  return unit && getFixedPrice(unit) > 0
    ? getFixedPrice(unit) + getCommonDebts(unit)
    : null;
};

export const getPriceForOtherUnit = (unit) => {
  return unit && getAskingPrice(unit) > 0
    ? getAskingPrice(unit) + getCommonDebts(unit)
    : null;
};

export const getUnitId = (unit) => (unit && unit.Id ? unit.Id : null);

export const getVirdiUnitLink = (unitId, baseUrl) =>
  unitId ? baseUrl.concat(unitId.toString()) : null;

export const getAnnouncedPrice = (unit) => {
  if (unit && unit.marketData.adState === 'for_sale') {
    return getAskingPrice(unit);
  }
  return getFixedPrice(unit);
};

export const formatDate = (date) =>
  date ? moment(date).format('DD.MMMM YYYY') : null;

export const redirectToVirdiMap = () => {
  if (isNativeEnv()) {
    openLinkRequest(virdiMapUrl);
    return;
  }
  window.open(virdiMapUrl, '_blank');
};
