const styles = (theme) => ({
  container: {
    display: 'block',
    padding: '20px 15px 20px 35px',
    borderBottom: `1px solid ${theme.palette.primary.whiteDarker}`,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export default styles;
