import React from 'react';

const Check = ({ width = '20', height = '20', fill = '#13142A', ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.14355 8.12607C4.88608 7.86288 4.46399 7.85824 4.2008 8.11571C3.9376 8.37318 3.93296 8.79527 4.19044 9.05846L5.14355 8.12607ZM6.69598 10.6663L6.21942 11.1325C6.34959 11.2656 6.52947 11.3382 6.71553 11.3327C6.9016 11.3273 7.0769 11.2443 7.19905 11.1038L6.69598 10.6663ZM11.8367 5.77046C12.0783 5.49262 12.0489 5.07154 11.7711 4.82994C11.4933 4.58834 11.0722 4.61772 10.8306 4.89556L11.8367 5.77046ZM4.19044 9.05846L6.21942 11.1325L7.17253 10.2001L5.14355 8.12607L4.19044 9.05846ZM7.19905 11.1038L11.8367 5.77046L10.8306 4.89556L6.19291 10.2289L7.19905 11.1038Z"
        fill={fill}
      />
    </svg>
  );
};

export default Check;
