const styles = (theme) => ({
  form: {
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(16),
    },
  },
  hidden: {
    display: 'none',
  },
  button: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(4),
    color: theme.palette.primary.white,
  },
});

export default styles;
