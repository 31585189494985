import React, { forwardRef } from 'react';

import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import BaseTextField from '@material-ui/core/TextField';

import styles from './style';

// TODO use Input + InputLabel combo instead to reuse InputLabel component
const TextField = forwardRef(
  (
    {
      classes,
      className,
      inputProps = {},
      InputProps = {},
      numeric = false,
      InputLabelProps = {},
      isExpandable = false,
      ...props
    },
    ref
  ) => (
    <BaseTextField
      ref={ref}
      className={clsx(className, classes.baseTextField)}
      InputLabelProps={{
        shrink: true,
        focused: false,
        variant: 'standard',
        className: clsx(classes.label, InputLabelProps.className),
        ...InputLabelProps,
      }}
      InputProps={{
        disableUnderline: true,
        ...InputProps,
        className: clsx(classes.inputField, InputProps.className),
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        className: isExpandable
          ? classes.expandableInputField
          : classes.nativeInputField,
        pattern: numeric ? '[0-9]*' : undefined,
        inputMode: numeric ? 'numeric' : undefined,
        ...inputProps,
      }}
      {...props}
    />
  )
);

export default withStyles(styles)(TextField);
