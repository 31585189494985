import React from 'react';
import Grid from '../../../../../components/atomic/Grid';
import useDesktop from '../../../../../hooks/useDesktop';
import PropertyImagesSection from './PropertyImagesSection';
import PropertyInfoSection from './PropertyInfoSection';
import PropertyCompaniesSection from './PropertyCompaniesSection';

const Overview = () => {
  const isDesktop = useDesktop();

  return (
    <Grid container spacing={isDesktop ? 6 : 2}>
      <PropertyImagesSection />
      <PropertyInfoSection />
      <PropertyCompaniesSection />
    </Grid>
  );
};

export default Overview;
