import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Button from '../../atomic/Button';
import ProgressButton from '../../ProgressButton';
import useDesktop from '../../../hooks/useDesktop';

const ActionButtons = ({
  classes,
  onCancel,
  onSubmit,
  fetching,
  rootClass,
  requestInProgress,
  submitButtonProps = {},
  cancelButtonProps = {},
  showCancelButton = true,
  showSubmitButton = true,
  showLargeSubmitButton = false,
  submitButtonRed = false,
  cancelButtonText = 'Avbryt',
  submitButtonText = 'Lagre',
  submitButtonDisabled = false,
  buttonType = 'default',
  buttonClassName = '',
  centeredActions = false,
  leftActions = false,
  centeredRowActions = false,
}) => {
  const isDesktop = useDesktop();

  const history = useHistory();

  const onCancelClick = () => (onCancel ? onCancel() : history.goBack());

  const onSubmitClick = (e) => (onSubmit ? onSubmit(e) : null);

  return (
    <div
      className={clsx(
        classes.actionButtons,
        rootClass,
        centeredActions ? classes.actionBtnCenter : '',
        leftActions ? classes.actionBtnLeft : '',
        centeredRowActions ? classes.actionBtnCnterRow : ''
      )}
    >
      {showCancelButton && (
        <Button
          onClick={onCancelClick}
          className={clsx(classes.actionButton, !isDesktop && 'mobile')}
          disabled={fetching || requestInProgress}
          {...cancelButtonProps}
        >
          {cancelButtonText}
        </Button>
      )}
      {showSubmitButton && (
        <ProgressButton
          type="submit"
          color="primary"
          variant="contained"
          onClick={onSubmitClick}
          className={clsx(
            buttonType === 'Customized' &&
              !showLargeSubmitButton &&
              classes.actionButtonBYBF,
            showLargeSubmitButton
              ? classes.actionButtonLarge
              : classes.actionButton,
            submitButtonRed && classes.submitButtonRed,
            buttonClassName
          )}
          requestInProgress={requestInProgress}
          disabled={fetching || submitButtonDisabled}
          {...submitButtonProps}
        >
          {submitButtonText}
        </ProgressButton>
      )}
    </div>
  );
};

export default withStyles(styles)(ActionButtons);
