import store from '../store';
import { backendBaseUrl } from '../config';
import { sGetUsername } from '../store/reducers/auth';
import { loggerEndpointUrl /* , postLog */ } from '../api/loggers';
// import * as rb from './rollbar';

export const logLevelInfo = 'INF';
export const logLevelError = 'ERR';
export const logLevelDebug = 'DBG';
export const logLevelWarning = 'WAR';

export const prepareLogData = (level, message, stackTrace) => [
  level,
  `${
    sGetUsername(store.getState()) || 'Unauthenticated'
  }: ${message} [location: ${window.location.pathname}]`,
  stackTrace,
];

// const loggingRequest = (...args) => postLog(...prepareLogData(...args));

export const isLoggerUrl = (url) =>
  url === `${backendBaseUrl}/${loggerEndpointUrl}`;

export const isLoggerFailedResponse = (error) =>
  error && error.config && error.config.url && isLoggerUrl(error.config.url);

// TODO Remove older logger func. later once decided
// that we are going with rollbar permenantly
export const logInfo = () => null;
// export const logInfo = (message, stackTrace = null) => {
//   //loggingRequest(logLevelInfo, message, stackTrace);
//   // rb.log(message, stackTrace);
// };

// TODO enable when necessary or permenantly switch to Rollbar
// export const logError = (message, stackTrace = null) =>
// rb.error(message, stackTrace);
export const logError = () => null;
// export const logError = (message, stackTrace = null) => loggingRequest(logLevelError, message, stackTrace);

// TODO enable when necessary or permenantly switch to Rollbar
// export const logDebug = (message, stackTrace = null) =>
// rb.debug(message, stackTrace);
export const logDebug = () => null;
// export const logDebug = (message, stackTrace = null) => loggingRequest(logLevelDebug, message, stackTrace);

// TODO enable when necessary or permenantly switch to Rollbar
// export const logWarning = (message, stackTrace = null) =>
// rb.warn(message, stackTrace);
export const logWarning = () => null;
// export const logWarning = (message, stackTrace = null) => loggingRequest(logLevelWarning, message, stackTrace);
