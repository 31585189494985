import React from 'react';

const CalendarIcon = ({ width = '1.4rem', height = '1.4rem', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    {...props}
  >
    <g id="Group_361" transform="translate(-563.5 -3173)">
      <g id="calendar" transform="translate(563.5 3173)">
        <path
          id="Shape_1009"
          d="M579.37,3175.5h2.391a1.706,1.706,0,0,1,1.739,1.667v14.167a1.706,1.706,0,0,1-1.739,1.667H565.239a1.706,1.706,0,0,1-1.739-1.667v-14.167a1.706,1.706,0,0,1,1.739-1.667h1.3a.424.424,0,0,1,.435.417v1.875a.653.653,0,0,0,1.3,0v-3.958a.87.87,0,0,1,1.739,0v1.458a.212.212,0,0,0,.217.208h5.435a.424.424,0,0,1,.435.417v1.875a.653.653,0,0,0,1.3,0v-3.958a.87.87,0,0,1,1.739,0v1.458A.212.212,0,0,0,579.37,3175.5Zm-13.7,15.833h15.652a.424.424,0,0,0,.435-.417v-10a.424.424,0,0,0-.435-.417H565.674a.424.424,0,0,0-.435.417v10A.424.424,0,0,0,565.674,3191.333Z"
          transform="translate(-563.5 -3173)"
          fill="#666"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

export default CalendarIcon;
