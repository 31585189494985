import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

import {
  getDocumentType,
  getDocumentRooms,
  getDocumentEvents,
  isDocumentReadOnly,
  getDocumentFileName,
  getDocumentCompanyId,
  getDocumentUpdatedDate,
  getDocumentCreatedDate,
  getDocumentOwnedByName,
  getDocumentCompanyName,
  getOriginalDocumentPath,
  getDocumentProfessionType,
} from '../../utils/documents';

import styles from './style';
import Box from '../../components/atomic/Box';
import List from '../../components/atomic/List';
import Link from '../../components/atomic/Link';
import { formattedDate } from '../../utils/dates';
import LockCircled from '../../assets/icons/LockCircled';
import { getRoomId, getRoomName } from '../../utils/rooms';
import Typography from '../../components/atomic/Typography';
import { getEventId, getEventName } from '../../utils/events';
import ListItem from '../../components/CardList/KeyValueListItem';
import ContentLoader from '../../components/loaders/ContentLoader';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';
import Tooltip from '../../components/Tooltip';
import useActionDialog from '../../hooks/useActionDialog';

const DocumentDetails = ({
  classes,
  fetching,
  document,
  isHide = false,
  showDownloadLink = true,
  showHelperText = false,
  personalDocument,
}) => {
  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const rooms = getDocumentRooms(document);
  const events = getDocumentEvents(document);
  const { showActionDialog } = useActionDialog();

  const onLockIconClick = () => {
    showActionDialog({
      message:
        'Dette dokumentet har en rettighetsbegrensning som gjør at det ikke kan lastes ned lokalt til din enhet.',
    });
  };

  if (fetching) {
    return (
      <div>
        <ContentLoader
          height={20}
          width={100}
          className={classes.titleLoader}
        />
        <ContentLoader
          width={70}
          height={20}
          className={classes.listItemLoader}
        />
        <ContentLoader
          width={90}
          height={20}
          className={classes.listItemLoader}
        />
        {!personalDocument && (
          <>
            <ContentLoader
              width={120}
              height={20}
              className={classes.listItemLoader}
            />
            <ContentLoader
              width={40}
              height={20}
              className={classes.listItemLoader}
            />
            <ContentLoader
              width={70}
              height={20}
              className={classes.listItemLoader}
            />
          </>
        )}
      </div>
    );
  }

  return (
    <div>
      <Box mb={2} mt={1}>
        <Box
          variant="h2"
          display="flex"
          alignItems="center"
          component={Typography}
          justifyContent="space-between"
          className={classes.title}
        >
          Detaljer
          {isDocumentReadOnly(document) && (
            <Tooltip
              title="Dette dokumentet har en rettighetsbegrensning som gjør at det ikke kan lastes ned lokalt til din enhet."
              fontSize={13}
            >
              <Box
                display="flex"
                alignItems="center"
                onClick={showHelperText ? () => onLockIconClick() : null}
              >
                <LockCircled />
                &nbsp;
                <Typography color="textSecondary">
                  <small>Historisk data</small>
                </Typography>
              </Box>
            </Tooltip>
          )}
        </Box>
      </Box>

      <List>
        <ListItem
          classes={{
            listItem: classes.listItem,
            label: classes.label,
            value: classes.value,
          }}
          label="Navn"
        >
          {showDownloadLink
            ? getDocumentFileName(document) && (
                <Link
                  isExternal
                  openInNewTab
                  className={classes.link}
                  href={getOriginalDocumentPath(document)}
                >
                  {getDocumentFileName(document)}
                </Link>
              )
            : getDocumentFileName(document)}
        </ListItem>
        <ListItem
          classes={{
            listItem: classes.listItem,
            label: classes.label,
            value: classes.value,
          }}
          label="Lagt til"
        >
          {formattedDate(getDocumentCreatedDate(document)) || 'N/A'}
        </ListItem>
        <ListItem
          classes={{
            listItem: classes.listItem,
            label: classes.label,
            value: classes.value,
          }}
          label="Sist endret"
        >
          {formattedDate(getDocumentUpdatedDate(document)) || 'N/A'}
        </ListItem>
        {!personalDocument && (
          <>
            <ListItem
              classes={{
                listItem: classes.listItem,
                label: classes.label,
                value: classes.value,
              }}
              label="Avsender"
            >
              {getDocumentCompanyId(document) ? (
                <Link
                  to={`${dashboardLinkPrefix}/company/${getDocumentCompanyId(
                    document
                  )}`}
                >
                  {getDocumentCompanyName(document)}
                </Link>
              ) : (
                getDocumentOwnedByName(document) ||
                getDocumentCompanyName(document)
              )}
            </ListItem>

            <ListItem
              classes={{
                listItem: classes.listItem,
                label: classes.label,
                value: classes.value,
              }}
              label="Type dokument"
            >
              {getDocumentType(document)}
            </ListItem>

            <ListItem
              classes={{
                listItem: classes.listItem,
                label: classes.label,
                value: classes.value,
              }}
              label="Fagområde"
            >
              {getDocumentProfessionType(document)}
            </ListItem>

            <ListItem
              classes={{
                listItem: classes.listItem,
                label: classes.label,
                value: classes.value,
              }}
              label="Rom"
              isHide={isHide}
            >
              {(!rooms || rooms.length === 0) && (
                <p className={clsx(classes.roomEventTag, classes.emptyTag)}>
                  Ingen rom tilknyttet
                </p>
              )}

              <div className={classes.tagList}>
                {rooms &&
                  rooms.length > 0 &&
                  rooms.map((room) => (
                    <Link
                      className={classes.roomEventTag}
                      key={`rooms-link-${getRoomId(room)}`}
                      to={`${dashboardLinkPrefix}/rooms/${getRoomId(room)}`}
                    >
                      {getRoomName(room)}
                    </Link>
                  ))}
              </div>
            </ListItem>

            <ListItem
              classes={{
                listItem: classes.listItem,
                label: classes.label,
                value: classes.value,
              }}
              label="Hendelse"
            >
              {(!events || events.length === 0) && (
                <p className={clsx(classes.roomEventTag, classes.emptyTag)}>
                  Ingen hendelser tilknyttet
                </p>
              )}

              <div className={classes.tagList}>
                {events &&
                  events.length > 0 &&
                  events.map((event) => (
                    <Link
                      className={classes.roomEventTag}
                      key={`events-link-${getEventId(event)}`}
                      to={`${dashboardLinkPrefix}/events/${getEventId(event)}`}
                    >
                      {getEventName(event)}
                    </Link>
                  ))}
              </div>
            </ListItem>
          </>
        )}
      </List>
    </div>
  );
};

export default withStyles(styles)(DocumentDetails);
