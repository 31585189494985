export const getRightName = (right) =>
  right && right.name !== undefined ? right.name : null;

export const getRightRegisteredDate = (right) =>
  right && right.registeredDate !== undefined ? right.registeredDate : null;

export const getRightDocumentId = (right) =>
  right && right.documentId !== undefined ? right.documentId : null;

export const getRightMattrikkel = (right) =>
  right && right.propertyRightedMatrikkel !== undefined
    ? right.propertyRightedMatrikkel
    : null;

export const getRightTransferredFromMattrikkel = (right) =>
  right && right.transferedFromMatrikkel !== undefined
    ? right.transferedFromMatrikkel
    : null;
