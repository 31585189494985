import React from 'react';

const ChapterMode = ({
  width = '100%',
  height = '100%',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24.001 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(0.001)">
      <path
        fill={fill}
        transform="translate(-322.75 -5153.25)"
        d="M330.75,5157.5h15a1,1,0,0,0,0-2h-15a1,1,0,0,0,0,2Z"
      />
      <path
        fill={fill}
        transform="translate(-322.75 -5153)"
        d="M345.75,5164h-15a1,1,0,0,0,0,2h15a1,1,0,0,0,0-2Z"
      />
      <path
        fill={fill}
        transform="translate(-322.75 -5152.75)"
        d="M345.75,5172.5h-15a1,1,0,0,0,0,2h15a1,1,0,0,0,0-2Z"
      />
      <path
        fill={fill}
        transform="translate(-323.25 -5152.75)"
        d="M325.212,5170.25a2,2,0,0,0-1.933,1.48.751.751,0,0,0,1.45.39.5.5,0,0,1,.57-.36.49.49,0,0,1,.411.53.5.5,0,0,1-.5.46.75.75,0,0,0,0,1.5.5.5,0,1,1-.482.63.75.75,0,1,0-1.445.4,2,2,0,0,0,1.635,1.45,2.026,2.026,0,0,0,1.983-.91,2,2,0,0,0-.029-2.18.255.255,0,0,1,0-.28,2,2,0,0,0-1.662-3.11Z"
      />
      <path
        fill={fill}
        transform="translate(-323.25 -5153)"
        d="M327.25,5163.75a2,2,0,0,0-4,0,.75.75,0,0,0,1.5,0,.5.5,0,0,1,1,0,1.02,1.02,0,0,1-.227.64l-2.109,2.64a.743.743,0,0,0-.091.79.755.755,0,0,0,.677.43h2.5a.75.75,0,0,0,0-1.5h-.419a.262.262,0,0,1-.226-.14.257.257,0,0,1,.031-.27l.814-1.01A2.549,2.549,0,0,0,327.25,5163.75Z"
      />
      <path
        fill={fill}
        transform="translate(-323.25 -5153.25)"
        d="M327,5158.25h-.25a.25.25,0,0,1-.25-.25v-3.38a1.371,1.371,0,0,0-1.375-1.37H324.5a.75.75,0,0,0,0,1.5h.25a.233.233,0,0,1,.177.07.253.253,0,0,1,.073.18v3a.249.249,0,0,1-.25.25h-.25a.75.75,0,0,0,0,1.5H327a.75.75,0,0,0,0-1.5Z"
      />
    </g>
  </svg>
);

export default ChapterMode;
