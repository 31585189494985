import React from 'react';

const ChapterEight = ({
  width = '100%',
  height = '100%',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32.763 30.029"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(0)">
      <path
        fill={fill}
        fillRule="evenodd"
        transform="translate(-96 -391.98)"
        d="M96,417a5.481,5.481,0,1,1,5.461,5A5.248,5.248,0,0,1,96,417Zm2.048,0a3.426,3.426,0,1,0,3.413-3.128A3.28,3.28,0,0,0,98.048,417Z"
      />
      <path
        fill={fill}
        transform="translate(-95.088 -391.353)"
        d="M100.548,418.254a1.884,1.884,0,1,0-2.048-1.877A1.969,1.969,0,0,0,100.548,418.254Z"
      />
      <path
        fill={fill}
        transform="translate(-92.805 -394.01)"
        d="M105.433,411.214H106.8a.656.656,0,0,1,.683.626v3.128a1.312,1.312,0,0,0,1.365,1.251h1.365a1.312,1.312,0,0,0,1.365-1.251v-2.5a1.37,1.37,0,0,1,2.73,0v2.5a1.312,1.312,0,0,0,1.365,1.251h1.365a1.312,1.312,0,0,0,1.365-1.251v-3.128a.656.656,0,0,1,.683-.626h1.365a.682.682,0,0,0,.636-.4.589.589,0,0,0-.177-.688l-7.048-5.872a1.444,1.444,0,0,0-1.836,0l-7.05,5.872a.589.589,0,0,0-.177.688A.682.682,0,0,0,105.433,411.214Z"
      />
      <path
        fill={fill}
        transform="translate(-92.896 -395.372)"
        d="M122.93,398.5h-15.7a1.256,1.256,0,1,0,0,2.5h15.017a.656.656,0,0,1,.683.626v20.645a.656.656,0,0,1-.683.626H105.865a1.256,1.256,0,1,0,0,2.5H122.93a2.623,2.623,0,0,0,2.73-2.5V401A2.623,2.623,0,0,0,122.93,398.5Z"
      />
      <path
        fill={fill}
        transform="translate(-96 -396)"
        d="M101.461,396A5.248,5.248,0,0,0,96,401v14.737a.625.625,0,0,0,.394.567.732.732,0,0,0,.727-.088,7.209,7.209,0,0,1,8.68,0,.731.731,0,0,0,.726.088.625.625,0,0,0,.395-.567V401A5.248,5.248,0,0,0,101.461,396Z"
      />
    </g>
  </svg>
);

export default ChapterEight;
