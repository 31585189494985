import clsx from 'clsx';
import React from 'react';
import { withStyles } from '@material-ui/core';

import {
  getRoomType,
  getRoomFloor,
  getRoomDescription,
} from '../../utils/rooms';

import styles from './style';
import List from '../../components/atomic/List';
import Typography from '../../components/atomic/Typography';
import ListItem from '../../components/CardList/KeyValueListItem';
import ContentLoader from '../../components/loaders/ContentLoader';

const RoomDescription = ({ room, classes, fetching }) => (
  <div>
    <Typography variant="h3">
      {fetching ? (
        <>
          <ContentLoader width={100} height={20} />
          <br />
        </>
      ) : (
        'Nyttig informasjon'
      )}
    </Typography>

    <div>
      {fetching ? (
        <>
          <ContentLoader width="90%" className={classes.lineLoading} />
          <ContentLoader width="80%" className={classes.lineLoading} />
        </>
      ) : (
        <Typography className={clsx(classes.description, 'word-break')}>
          {!fetching && getRoomDescription(room || '-')}
        </Typography>
      )}
    </div>
    {!fetching && (
      <List className={classes.miscList}>
        {getRoomFloor(room) && (
          <ListItem label="Etasje">{getRoomFloor(room)}</ListItem>
        )}
        <ListItem label="Romtype">{getRoomType(room) || 'N/A'}</ListItem>
      </List>
    )}
  </div>
);

export default withStyles(styles)(RoomDescription);
