import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import useDesktop from '../../../../hooks/useDesktop';
import PageLayout from '../../../../components/layouts/WithHeader';
import VerdiHjemlaBanner from '../../../../assets/images/verdiHjemlaBanner.png';
import HjemlaLogo from '../../../../assets/icons/HjemlaLogo';
import styles from './style';
import { hjemlaBaseUrl } from '../../../../config';
import { useActiveProperty } from '../../../../store/hooks';
import useHeaderSnackbar from '../../../../hooks/useHeaderSnackbar';
import { sGetUser } from '../../../../store/reducers/auth';
import Link from '../../../../components/atomic/Link';
import { PrimaryButton } from '../../../../components/Buttons';
import { closeSnackBarWithUpdate } from '../../../../utils/hjemlaLoginSnackBarUtil';

const PropertyFinancialsHjemla = ({ user, classes }) => {
  const isDesktop = useDesktop();
  const { property } = useActiveProperty();
  const { closeSnackbar } = useHeaderSnackbar();
  const getHjemlaUrl = () =>
    `${hjemlaBaseUrl}min-bolig/${property?.boligmappaNumber}?shouldLogin=true`;

  return (
    <PageLayout hideHeader lessMarginLeft={isDesktop} maxWidth="lg">
      <div className={classes.pageWrapper}>
        <img
          className={classes.bannerImage}
          alt="Hjemla Login Banner"
          src={VerdiHjemlaBanner}
        />
        <h2 className={classes.title}>
          Gå til Hjemla for å se verdiestimatet for boligen din{' '}
        </h2>
        <p className={classes.description}>
          Hjemla er en helt ny tjeneste som samler informasjon om boligens verdi
          og tilstand, og er bygget på det beste fra våre to eksisterende
          boligplattformer Virdi og Boligmappa.
        </p>
        <Link
          className={`${classes.buttonWrapper}`}
          to={getHjemlaUrl()}
          isExternal
          openInNewTab
        >
          <PrimaryButton
            onClick={() =>
              closeSnackBarWithUpdate(
                false,
                'Boligmappa-to-Hjemla-Login-using-page',
                'Logging from boligmappa page',
                user?.sid,
                closeSnackbar
              )
            }
            variant="contained"
            noPadding={isDesktop}
            className={classes.button}
          >
            <HjemlaLogo height="16px" width="16px" />
            <span className={classes.buttonText}>hjemla.no</span>
          </PrimaryButton>
        </Link>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: sGetUser(state),
});

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(PropertyFinancialsHjemla));
