import clsx from 'clsx';
import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Box from '../../atomic/Box';

const isCircle = (variant) => variant === 'circle';

const ContentLoader = ({
  width,
  height,
  classes,
  className,
  style = {},
  variant = '',
  ...props
}) => (
  <Box
    style={{
      width: isCircle(variant) ? height : width,
      height,
      borderRadius: isCircle(variant) ? '100%' : undefined,
      ...style,
    }}
    className={clsx(variant, className, classes.line)}
    {...props}
  />
);

export default withStyles(styles)(ContentLoader);
