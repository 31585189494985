import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { useRouteMatch } from 'react-router';

import useDesktop from '../../../../hooks/useDesktop';

import Route from '../../../../components/atomic/Route';
import Paper from '../../../../components/atomic/Paper';
import Hidden from '../../../../components/atomic/Hidden';

import { acSetDocumentSearchText } from '../../../../store/actions/documents';

import Overview from './Overview';
import styles from './styles';
import Box from '../../../../components/atomic/Box';
import WithHeader from "../../../../components/layouts/WithHeader";

const Home = ({ setSearchText }) => {
  const isDesktop = useDesktop();

  const match = useRouteMatch();

  useEffect(() => {
    setSearchText('');
  }, []);

  return (
    <WithHeader
      minFullHeight
      homeLayout
      publicSection
      maxWidth="lg"
    >
      <Hidden mdUp>
        <Route exact path={match.url}>
          <Box p={1}>
            <Overview />
          </Box>
        </Route>
        {/* ToDo Rooms route   */}
      </Hidden>

      {/* For desktop only */}
      <Hidden smDown>
        <Paper visible={!isDesktop}>
          <Box p={1}>
            <Overview />
          </Box>
        </Paper>
      </Hidden>
    </WithHeader>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setSearchText: (searchText) => dispatch(acSetDocumentSearchText(searchText)),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(Home));
