import {
  SET_WETROOM_TEMPLATES,
  SET_ELECTRICAL_TEMPLATES,
  SET_OUTDOOR_TEMPLATES,
  SET_OTHER_TEMPLATES,
  SET_FETCHING_ERROR,
} from '../reducers/bybfEventTemplates';

export const acSetWetroomTemplates = (wetroomTemplates) => ({
  type: SET_WETROOM_TEMPLATES,
  payload: wetroomTemplates,
});

export const acSetElectricalTemplates = (electricalTempltes) => ({
  type: SET_ELECTRICAL_TEMPLATES,
  payload: electricalTempltes,
});

export const acSetOutdoorTemplates = (outdoorTemplates) => ({
  type: SET_OUTDOOR_TEMPLATES,
  payload: outdoorTemplates,
});

export const acSetOtherTemplates = (otherTemplates) => ({
  type: SET_OTHER_TEMPLATES,
  payload: otherTemplates,
});

export const acSetFetchingError = (fetchingError) => ({
  type: SET_FETCHING_ERROR,
  payload: fetchingError,
});
