import React from 'react';

const Company = (props) => (
  <svg
    viewBox="0 0 20.256 19.834"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <style>{`.a,.b{fill:#8d8d8d;}.b{fill - rule:evenodd;}`}</style>
    </defs>
    <path
      className="a"
      transform="translate(-48 -125.75)"
      d="M56.018,138.41a2.518,2.518,0,0,1,.97-1.984.422.422,0,0,0,.148-.436A4.642,4.642,0,0,0,48,137.144V140.1a.422.422,0,0,0,.422.422H49.95a.211.211,0,0,1,.21.193l.372,4.484a.422.422,0,0,0,.422.387H54.33a.422.422,0,0,0,.422-.387l.374-4.484a.211.211,0,0,1,.208-.193H55.6a.422.422,0,0,0,.422-.422Z"
    />
    <path
      className="a"
      transform="translate(-48.312 -124.5)"
      d="M52.954,130.408A2.954,2.954,0,1,0,50,127.454,2.954,2.954,0,0,0,52.954,130.408Z"
    />
    <path
      className="b"
      transform="translate(-49.716 -126.191)"
      d="M59,140.75v-1.9a1.266,1.266,0,0,1,1.266-1.266h1.182a.212.212,0,0,0,.195-.133L62.1,136.3a1.652,1.652,0,0,1,1.494-.96h1.786a1.635,1.635,0,0,1,1.471.91l.479,1.2a.211.211,0,0,0,.2.133h1.182a1.266,1.266,0,0,1,1.266,1.266v1.9a.211.211,0,0,1-.211.211H59.211A.211.211,0,0,1,59,140.75Zm4.591-4.147a.381.381,0,0,0-.338.209l-.188.484a.211.211,0,0,0,.2.288H65.71a.21.21,0,0,0,.2-.289l-.21-.534a.379.379,0,0,0-.316-.159Z"
    />
    <path
      className="a"
      transform="translate(-49.716 -127.386)"
      d="M69.972,143.211a.211.211,0,0,0-.211-.211h-4.22a.211.211,0,0,0-.211.211v.633a.844.844,0,1,1-1.688,0v-.633a.211.211,0,0,0-.211-.211h-4.22a.211.211,0,0,0-.211.211v2.743a1.266,1.266,0,0,0,1.266,1.266h8.44a1.266,1.266,0,0,0,1.266-1.266Z"
    />
    <path
      className="a"
      transform="translate(-48 -125.75)"
      d="M56.018,138.41a2.518,2.518,0,0,1,.97-1.984.422.422,0,0,0,.148-.436A4.642,4.642,0,0,0,48,137.144V140.1a.422.422,0,0,0,.422.422H49.95a.211.211,0,0,1,.21.193l.372,4.484a.422.422,0,0,0,.422.387H54.33a.422.422,0,0,0,.422-.387l.374-4.484a.211.211,0,0,1,.208-.193H55.6a.422.422,0,0,0,.422-.422Z"
    />
    <path
      className="a"
      transform="translate(-48.312 -124.5)"
      d="M52.954,130.408A2.954,2.954,0,1,0,50,127.454,2.954,2.954,0,0,0,52.954,130.408Z"
    />
    <path
      className="b"
      d="M59,140.75v-1.9a1.266,1.266,0,0,1,1.266-1.266h1.182a.212.212,0,0,0,.195-.133L62.1,136.3a1.652,1.652,0,0,1,1.494-.96h1.786a1.635,1.635,0,0,1,1.471.91l.479,1.2a.211.211,0,0,0,.2.133h1.182a1.266,1.266,0,0,1,1.266,1.266v1.9a.211.211,0,0,1-.211.211H59.211A.211.211,0,0,1,59,140.75Zm4.591-4.147a.381.381,0,0,0-.338.209l-.188.484a.211.211,0,0,0,.2.288H65.71a.21.21,0,0,0,.2-.289l-.21-.534a.379.379,0,0,0-.316-.159Z"
      transform="translate(-49.716 -126.191)"
    />
    <path
      className="a"
      d="M69.972,143.211a.211.211,0,0,0-.211-.211h-4.22a.211.211,0,0,0-.211.211v.633a.844.844,0,1,1-1.688,0v-.633a.211.211,0,0,0-.211-.211h-4.22a.211.211,0,0,0-.211.211v2.743a1.266,1.266,0,0,0,1.266,1.266h8.44a1.266,1.266,0,0,0,1.266-1.266Z"
      transform="translate(-49.716 -127.386)"
    />
  </svg>
);

export default Company;
