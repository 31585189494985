export const logoSize = 32;

const styles = (theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    textAlign: 'left',
    boxShadow: 'none',
    padding: '12px 16px 12px',
    minHeight: '64px',
    borderBottom: '1px solid #dedede',
    position: 'sticky',
    top: '0',
    background: '#fff',
    zIndex: '9',

    '&.hide': {
      display: 'none',
    },
  },
  iosNativeNotch: {
    height: 'env(safe-area-inset-top)',
  },
  mainHeaderContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  propertyAlign: {
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  headerLayout: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    width: '100%',
  },
  hideAccount: {
    display: 'none',
  },
  pageTitle: {
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 500,
    color: '#000',
    margin: 0,
    padding: '6px 0',
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    border: 0,
    padding: '9px',
    background: 'none',
    cursor: 'pointer',
  },
  propertiesLink: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    columnGap: '8px',
    textDecoration: 'none',
  },
  contentText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  logoLoader: {
    flexShrink: 0,
    width: '20px',
    height: '16.25px',
    marginRight: '13px',
  },
  logo: {
    minWidth: '20px',
    height: '16.25px',
  },
  badge: {
    fontSize: '12px',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.red,
  },
  notificationIcon: {
    width: '30px',
    height: '25px',
    fill: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  grow: {
    flexGrow: 1,
  },
  editItemButton: {
    border: 'none',
    background: 'none',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
});

export default styles;
