const styles = (theme) => ({
  container: {
    display: 'flex',
    border: '1px solid #ccc',
    width: '100%',
  },
  buttonContainer: {
    padding: '15px',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    borderRight: `1px solid ${theme.palette.primary.greyLighter}`,
    '&.mobile': {
      padding: '10px',
    },
  },
  textInput: ({ color = 'primary' }) => {
    const colors = {
      primary: theme.palette.primary.main,
      secondary: theme.palette.secondary.main,
    };

    return {
      flex: 9,
      border: 'none',
      padding: '8px',
      fontSize: '16px',
      color: colors[color],
      textDecoration: 'underline',
      fontWeight: '800',
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&.mobile': {
        fontSize: '13px',
        fontStyle: 'Italic',
        color: '#F36966',
      },
    };
  },
});

export default styles;
