import { makeStyles } from '@material-ui/core';

export const usePriceHistoryYearChange = makeStyles((theme) => ({
  signPositive: {
    color: theme.colors.greenPositive,
  },
  signNegative: {
    color: theme.colors.red,
  },
}));
