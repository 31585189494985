import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  cardHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  cardHeaderMobile: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  editButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 8,
    marginTop: -8,
  },
}));
