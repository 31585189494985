const styles = (theme) => ({
  containerRoot: {
    '@supports (top: env(safe-area-inset-top))': {
      top: 'env(safe-area-inset-top)',
    },
  },
  root: {
    '& #client-snackbar': {
      display: 'block',
    },
  },
  info: { backgroundColor: theme.palette.primary.blue },
  error: { backgroundColor: theme.palette.primary.redDark },
  success: { backgroundColor: theme.palette.primary.green },
  warning: { backgroundColor: theme.palette.primary.orange },
  dismissButton: {
    fontWeignt: 300,
    color: theme.palette.primary.white,
  },
  dismissDarkButton: {
    fontWeignt: 300,
    color: theme.palette.primary.black,
  },
  closeIcon: {
    width: '1rem',
    height: '1rem',
  },
});

export default styles;
