const styles = () => ({
  content: {
    maxHeight: '40vh',
    overflowY: 'auto',
  },
  textButton: {
    textDecoration: 'none',
    float: 'left',
  },
  textField: {
    border: '0.1rem solid #d4d4d4',
    display: 'flex',
    padding: '8px',
  },
  saveButton: {
    width: '69px',
    height: '32px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    borderRadius: '50px',
    color: '#FFFFFF',
    padding: '8px 16px',
    background: '#164144',
    textDecoration: 'none',

    '&:hover': { backgroundColor: '#164144' },
  },
  buttonPane: {
    padding: '12px',
  },
});

export default styles;
