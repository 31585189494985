import React from 'react';
import ErrorContainer from '../../../../components/ErrorContainer';

export function ErrorMessage(props) {
  return (
    <ErrorContainer
      paddingTop={false}
      // eslint-disable-next-line react/destructuring-assignment
      errorResponse={props.fetchingPropertiesError}
    />
  );
}
