import {
  getNotificationDate,
  isNotificationUnseen,
  getNotificationIdHash,
} from '../../utils/notifications';

import { areIdsEqual, compareAlphabetically } from '../../utils/strings';

export const defaultState = {
  fetching: true,
  notifications: null,
  fetchingError: null,
};

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const SET_FETCHING_NOTIFICATIONS = 'SET_FETCHING_NOTIFICATIONS';
export const SET_FETCHING_NOTIFICATIONS_ERROR =
  'SET_FETCHING_NOTIFICATIONS_ERROR';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case CLEAR_NOTIFICATIONS:
      return defaultState;
    case UPDATE_NOTIFICATION:
      const updatedNotification = action.payload;

      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          areIdsEqual(
            getNotificationIdHash(notification),
            getNotificationIdHash(updatedNotification)
          )
            ? updatedNotification
            : notification
        ),
      };
    case SET_FETCHING_NOTIFICATIONS:
      return {
        ...state,
        fetching: action.payload,
      };
    case SET_FETCHING_NOTIFICATIONS_ERROR:
      return {
        ...state,
        fetchingError: action.payload,
      };
    default:
      return state;
  }
};

export const sGetNotifications = (state) =>
  Array.isArray(state.notifications.notifications)
    ? state.notifications.notifications.sort((a, b) =>
        compareAlphabetically(
          getNotificationDate(a),
          getNotificationDate(b),
          'desc'
        )
      )
    : null;

export const sGetNotificationsLoaded = (state) => !state.notifications.fetching;
export const sGetNotificationsFetching = (state) =>
  state.notifications.fetching;
export const sGetFetchingNotificationsError = (state) =>
  state.notifications.fetchingError;

export const sGetUnseenNotifications = (state) =>
  (sGetNotifications(state) || []).filter(isNotificationUnseen);
