const styles = (theme) => ({
  root: {
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  image: {
    height: 150,
    maxWidth: '100%',
    cursor: 'pointer',
    objectFit: 'contain',
    display: 'inline-block',
    padding: theme.spacing(1),
  },
  active: {
    border: `1px solid ${theme.palette.primary.white}`,
  },
});

export default styles;
