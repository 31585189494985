import { truncate } from './strings';

export const getCompanyLogo = (company) =>
  company && company.companyLogo ? company.companyLogo : null;

export const getProfessionTypes = (company) =>
  company && company.professionTypes.length > 0 ? company.professionTypes : [];

export const getProfessionTypeName = (professionType) =>
  professionType && professionType.name ? professionType.name : null;

export const getCompanyAddress = (company) =>
  company && company.address
    ? `${company.address.streetName} ${
        company.address.houseNumber ? company.address.houseNumber : ''
      } ${
        company.address.houseSubNumber ? company.address.houseSubNumber : ''
      }, ${company.address.postalCode ? company.address.postalCode : ''} ${
        company.address.postalPlace ? company.address.postalPlace : ''
      }`
    : null;

export const getCertificates = (company) =>
  company &&
  company.companyCertificates &&
  company.companyCertificates.length > 0
    ? company.companyCertificates
    : [];

export const getPlantCount = (company) =>
  company && company.plantCount ? company.plantCount : 0;

export const getCompanyLegalName = (company) =>
  company && company.legalName ? company.legalName : null;

export const getCompanyName = (company) =>
  company && company.displayName
    ? company.displayName
    : getCompanyLegalName(company);

export const getCompanyEmail = (company) =>
  company && company.email ? company.email : null;

export const getCompanyOrgNumber = (company) =>
  company && company.organizationNumber ? company.organizationNumber : null;

export const getProfessionCount = (company) =>
  company && company.professionTypes ? company.professionTypes.length : 0;

export const getTruncatedTitle = (title) =>
  title && title.length > 20 ? truncate(title, { length: 20 }) : title;

export const getDistance = (company) =>
  company && company.geoLocation && company.geoLocation.distanceFromLocation
    ? (company.geoLocation.distanceFromLocation / 1000).toFixed(1)
    : null;

export const getCompanyId = (company) =>
  company && company.id ? company.id : null;
export const getSortOptionsForFindProfessionals = (option) => {
  const options = [
    {
      label: 'Nærmest deg',
      sort: 'distance',
      sortOrder: 'asc',
      value: 1,
    },
    {
      label: 'Flest jobber i boligmappa',
      sort: 'plantcount',
      sortOrder: 'desc',
      value: 2,
    },
    {
      label: 'Flest sertifiseringer',
      sort: 'certificatecount',
      sortOrder: 'desc',
      value: 3,
    },
    {
      label: 'A-Å',
      sort: 'name',
      sortOrder: 'asc',
      value: 4,
    },
  ];

  if (option) return options[option];
  return options;
};

export const getSortOptionsForFindProfessionalsWithoutDistance = (option) => {
  const options = [
    {
      label: 'Flest jobber i boligmappa',
      sort: 'plantcount',
      sortOrder: 'desc',
      value: 2,
    },
    {
      label: 'Flest sertifiseringer',
      sort: 'certificatecount',
      sortOrder: 'desc',
      value: 3,
    },
    {
      label: 'A-Å',
      sort: 'name',
      sortOrder: 'asc',
      value: 4,
    },
  ];

  if (option) return options[option];
  return options;
};

export const calculateTotalVisibleCount = (metaData, responseLength) => {
  if (metaData) {
    const page = metaData.page !== 1 ? metaData.page - 1 : metaData.page;
    return metaData.page === 1 && responseLength < 12
      ? responseLength
      : page * metaData.pageSize + (metaData.page === 1 ? 0 : responseLength);
  }
  return 0;
};

export const getPropertyOptions = (locations, properties) => {
  const locationSearchOptions = [
    {
      label: '',
      options: locations.length > 0 ? locations : [],
    },
    {
      label: 'Dine adresser',
      options: properties,
    },
  ];
  return locationSearchOptions;
};

export const FranchiserData = [
  {
    name: 'VVS NORGE AS',
    displayName: 'Bademiljø',
  },
  {
    name: 'COMFORT-KJEDEN AS',
    displayName: 'Comfort',
  },
  {
    name: 'ONNINEN AS',
    displayName: 'Elfag',
  },
  {
    name: 'ELKONOR AS',
    displayName: 'Elkonor',
  },
  {
    name: 'FLISEKOMPANIET AS',
    displayName: 'Flisekompaniet',
  },
  {
    name: 'VARME & BAD AS',
    displayName: 'Varme & Bad',
  },
  {
    name: 'VVS EKSPERTEN AS',
    displayName: 'VVS Eksperten',
  },
  {
    name: 'EL PROFFEN AS',
    displayName: 'EL-PROFFEN',
  },
];
