import styled from 'styled-components';

export const StyledLanding = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 48px;
  max-width: 1160px;
  padding: 24px 0;
  margin: 0 auto;
  position: relative;

  @media (max-width: 959px) {
    padding: 32px 16px 134px;
  }

  h2,
  h3,
  p {
    margin: 0;
    color: #000;
  }

  h2 {
    font-size: 32px;
    line-height: 50px;
    font-weight: 700;
  }

  h3 {
    font-size: 20px;
    line-height: 40px;
    font-weight: 700;
  }

  .section {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    p {
      font-size: 18px;
      line-height: 24px;
      max-width: calc(100% - 352px);

      @media (max-width: 959px) {
        max-width: 100%;
      }
    }
  }

  .landing_img {
    width: 100%;
    max-width: 928px;
  }

  .cta_advantages {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    font-size: 18px;
    max-width: 732px;

    ul {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      padding-left: 28px;
      margin: 0;
    }
  }

  .features_section {
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    @media (max-width: 767px) {
      row-gap: 16px;
    }
  }

  .features_block {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;

    @media (max-width: 767px) {
      gap: 8px;
    }
  }

  .unsubscribe {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    @media (max-width: 767px) {
      padding-bottom: 48px;
      border-bottom: 1px solid #e6e6e6;
    }

    p {
      font-size: 16px;
      line-height: 24px;

      a {
        color: #000;
      }
    }
  }
`;
