import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Select from '@vb/components/build/components/forms/inputs/Select';
import InputField from '@vb/components/build/components/forms/inputs/InputField';
import TextArea from '@vb/components/build/components/forms/inputs/TextArea';
import { Divider } from '@material-ui/core';
import Typography from '../../atomic/Typography';
import useDebounce from '../../../hooks/useDebounce';
import useFetchEffect from '../../../hooks/useFetchEffect';
import { useValidation } from '../../../hooks/useValidation';
import { useActiveProperty } from '../../../store/hooks';
import {
  lookupCompanies as apiLookupCompanies,
  fetchCompany,
} from '../../../api/companies';
import { requestMissingDocumentsToJobs } from '../../../api/jobs';
import { MDRFormStyles, autocompleteStyles } from './styles';
import { compareAlphabetically } from '../../../utils/strings';
import ListItemText from '../../atomic/ListItemText';
import Box from '../../atomic/Box';
import Autocomplete from '../../Autocomplete';
import { PrimaryButton } from '../../Buttons';
import {
  getJobYearDoneOptions,
  transformJobDoneDate,
} from '../../../utils/jobsUtils';
import { scrollToFormField } from '../../../utils/scrollToFormField';
import matomo from '../../../utils/matomo';
import ArrowDown from '../../../assets/icons/ArrowDown';
import Check from '../../../assets/icons/Check';
import Close from '../../../assets/icons/Close';
import Backspace from '../../../assets/icons/Backspace';
import {
  actionSetMDRInputs,
  actionSetSelectedCompany,
  actionSetJobYearDone,
  actionSetIsCompanyRegistered,
  actionSetIsCompanyInSystem,
} from '../../../store/actions/mdr';
import {
  sGetMDRInputs,
  sGetSelectedCompany,
  sGetJobYearDone,
  sGetIsCompanyRegistered,
  sGetIsCompanyInSystem,
} from '../../../store/reducers/mdr';

function MDRForm({
  classes = MDRFormStyles(),
  fetchPendingJobsData,
  labels,
  mdrInputs,
  setMDRInputs,
  setStoreSelectedCompany,
  setStoreJobYearDone,
  setStoreIsCompanyRegistered,
  setStoreIsCompanyInSystem,
  storeIsCompanyInSystem,
  storeSelectedCompany,
  storeJobYearDone,
  storeIsCompanyRegistered,
  company: passedCompany,
  companies: passedCompanies,
  ...props
}) {
  const [companyName, setCompanyName] = useState('');
  const [errors, setErrors] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasSubmissionFailed, setHasSubmissionFailed] = useState(false);
  const debouncedCompanyName = useDebounce(companyName, 500);

  const { property } = useActiveProperty();
  const validatedFields = ['company', 'selectedJobYearDone'];
  const { isValid, validationData } = useValidation(
    validatedFields,
    mdrInputs || {}
  );
  const inputRefs = useRef({});
  const titleRef = useRef(null);
  validatedFields.forEach((field) => {
    inputRefs.current[field] = useRef(null);
  });

  // ------------------------------------------------------------------------
  // -------------------SETUP & API HANDLERS---------------------------------
  // ------------------------------------------------------------------------

  const {
    data: companies,
    setData: setCompanies,
    fetching: fetchingCompanies,
  } = useFetchEffect({
    dependencies: [debouncedCompanyName],
    apiFetchFunction: () => apiLookupCompanies({ name: debouncedCompanyName }),
    conditionFunction: ([_debouncedCompanyName]) =>
      typeof _debouncedCompanyName === 'string' &&
      _debouncedCompanyName.length > 0,
  });

  useEffect(() => {
    if (companyName === '') {
      setCompanies([]);
    }
  }, [companyName]);

  const companyOptions = (Array.isArray(companies) ? companies : [])
    .map(
      ({
        name: label,
        organizationnumber: value,
        address: companyAddress,
      }) => ({ value, label, companyAddress })
    )
    .slice(0, 10)
    .sort((a, b) => compareAlphabetically(a.label, b.label));

  const showOptionsMenu = fetchingCompanies || companyOptions.length > 0;

  // ------------------------------------------------------------------------
  // --------------------------- HANDLERS------------------------------------
  // ------------------------------------------------------------------------
  const validateSelectedCompany = async (selectedCompany) => {
    await fetchCompany(selectedCompany.value).then((res) => {
      let isRegistered = false;
      let isInSystem = true;

      if (res[1]?.companyId) {
        isRegistered = true;
      }

      if (res[0]) {
        isInSystem = false;
      }
      setStoreSelectedCompany(selectedCompany);
      setStoreIsCompanyRegistered(isRegistered);
      setStoreIsCompanyInSystem(isInSystem);
    });
  };

  const onSelect = async (newSelectedCompany) => {
    if (newSelectedCompany) {
      setMDRInputs({ ...mdrInputs, company: newSelectedCompany.value });
      validateSelectedCompany(newSelectedCompany);
    }

    return null;
  };

  const onSelectYearDone = (selectedYearDone) => {
    if (selectedYearDone) {
      setStoreJobYearDone(selectedYearDone);
      setMDRInputs({
        ...mdrInputs,
        selectedJobYearDone: selectedYearDone.value,
      });
    }
  };

  const onCompanyNameChange = (text, { action }) => {
    const approvedActions = ['set-value', 'input-change'];
    if (approvedActions.includes(action)) {
      setCompanyName(text);
    }
  };

  const onClearCompanyName = () => {
    setCompanies([]);
    setCompanyName('');
    setStoreSelectedCompany(null);
    setStoreIsCompanyRegistered(false);
    setStoreIsCompanyInSystem(false);
    setMDRInputs({ ...mdrInputs, company: null, companyEmail: null });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors(true);
    const jobDate = transformJobDoneDate(storeJobYearDone.value);
    if (isValid) {
      const { jobDescription, companyEmail } = mdrInputs;
      const data = {
        boligmappaNumber: property.boligmappaNumber,
        organizationNumber: Number(storeSelectedCompany.value),
        organizationEmailAddress: companyEmail,
        description: jobDescription,
        jobDate,
        title: `${property.boligmappaNumber}_${storeSelectedCompany.value}`,
        status: 'Pending',
        origin: 'PO',
        jobSummary: null,
        professionTypes: [], // []
        locations: [], // []
        files: [],
        tags: [],
      };

      await requestMissingDocumentsToJobs(data)
        .then((res) => {
          if (!res[1]?.success) {
            setHasSubmissionFailed(true);
          } else {
            fetchPendingJobsData();
          }
        })
        .finally(() => {
          setHasSubmitted(true);
          scrollToFormField(titleRef);

          matomo.clickEvent({
            category: 'MDR Form Submission',
            name: 'MDR Form Submission',
            action: 'Form has been submitted successfully',
          });
        });
    } else {
      const name = validatedFields.find((el) => !validationData[el]);
      if (name) {
        scrollToFormField(inputRefs.current[name]);
      }
    }
  };

  const handleSendNewRequest = () => {
    setHasSubmissionFailed(false);
    setHasSubmitted(false);
    onClearCompanyName();
    setStoreJobYearDone({});
    setStoreSelectedCompany(null);
    setStoreIsCompanyRegistered(false);
    setStoreIsCompanyInSystem(false);
    setMDRInputs({});
    setErrors(false);
    setMDRInputs({});
  };

  const isErrorInput = (name) => {
    if (errors && !validationData[name]) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (storeSelectedCompany) {
      setMDRInputs({ ...mdrInputs, company: storeSelectedCompany.value });
      validateSelectedCompany(storeSelectedCompany);
    }
  }, [storeSelectedCompany]);

  // ------------------------------------------------------------------------
  // -----------------------CUSTOM RENDERERS --------------------------------
  // ------------------------------------------------------------------------

  const companySearchItem = ({ label, companyAddress }) => {
    const lowerCompanyAddress = companyAddress;

    return (
      <div className={classes.companySearchItem} key={label}>
        <ListItemText>
          <Typography className={classes.companyName}>{label}</Typography>
        </ListItemText>
        {showOptionsMenu && (
          <ListItemText>
            <Typography className={classes.companyAddress}>
              {companyAddress === null || companyAddress === ''
                ? 'Adresse Ikke Tilgjengelig'
                : lowerCompanyAddress}
            </Typography>
          </ListItemText>
        )}
      </div>
    );
  };

  const DropdownIndicator = () => {
    return (
      <div className={classes.arrowDown}>
        <ArrowDown />
      </div>
    );
  };

  const companySelectionSection = (
    <Box ref={inputRefs.current.company} width="100%" position="relative">
      <Autocomplete
        value={storeSelectedCompany}
        onChange={onSelect}
        inputValue={companyName}
        options={companyOptions}
        escapeClearsValue={false}
        isClearable={false}
        toShrink={false}
        styles={{
          ...autocompleteStyles,
          control: (provided) => ({
            ...provided,
            border: '1.5px solid #DDE2F1',
            borderColor: isErrorInput('company')
              ? '#D7394C !important'
              : '#DDE2F1',
            boxShadow: 'none',
            '&:hover': {
              border: '1.5px solid  #DDE2F1',
              borderSize: '1.5px',
              borderColor: isErrorInput('company') ? '#D7394C' : '#DDE2F1',
            },
            '&:focus': {
              border: '1.5px solid',
              borderColor: isErrorInput('company') ? '#D7394C' : '#DDE2F1',
            },
          }),
        }}
        className={clsx(
          classes.autocompleteInput,
          isErrorInput('company') && classes.errorInput
        )}
        menuIsOpen={showOptionsMenu}
        isLoading={fetchingCompanies}
        placeholder="Bedrift AS"
        label={labels.lblCompanyName?.toString()}
        loadingMessage={() => 'Laster...'}
        onInputChange={onCompanyNameChange}
        noOptionsMessage={() => 'Ingen selskaper'}
        formatOptionLabel={companySearchItem}
        components={{ DropdownIndicator }}
        {...props}
      />
      {(companyName || storeSelectedCompany) && (
        <>
          <Backspace
            onClick={onClearCompanyName}
            className={classes.clearTextButton}
          />
          <Typography className={classes.clearTextButtonDivider}>|</Typography>
        </>
      )}
      {isErrorInput('company') && (
        <div className="errorMessage errorCompany">{labels.errorCompany}</div>
      )}
      {storeSelectedCompany &&
        (storeIsCompanyRegistered ? (
          <Typography
            className={classes.companyRegisteredMessage}
            component="small"
            variant="inherit"
            color="textPrimary"
          >
            <Check className={classes.companyRegisteredIcon} />
            {labels.emailIsRegistered}
          </Typography>
        ) : (
          <div className={classes.notRegisteredSection}>
            <Typography
              className={classes.companyRegisteredMessage}
              component="small"
              variant="inherit"
              color="textPrimary"
            >
              <Close className={classes.companyRegisteredIcon} />
              {storeIsCompanyInSystem
                ? labels.emailNotRegistered
                : labels.companyNotInSystem}
            </Typography>
            {storeIsCompanyInSystem && (
              <InputField
                id="companyEmail"
                label={labels.lblcompanyEmail?.toString()}
                placeholder={labels.phCompanyEmail?.toString()}
                name="companyEmail"
                withCancelButton={false}
                value={mdrInputs.companyEmail}
                className="companyEmail"
                onChange={(e) =>
                  setMDRInputs({ ...mdrInputs, companyEmail: e.target.value })
                }
              />
            )}
            {storeIsCompanyInSystem && <Divider />}
          </div>
        ))}
    </Box>
  );

  const jobYearSection = (
    <div ref={inputRefs.current.selectedJobYearDone}>
      <Select
        className={clsx(
          'selectField',
          isErrorInput('selectedJobYearDone') && 'errorInput'
        )}
        defaultValue={storeJobYearDone}
        label={labels.whatYearWasDone?.toString()}
        options={getJobYearDoneOptions(labels.moreThanFiveyears)}
        placeholder={labels.phWhatYearWasDone?.toString()}
        optionsLabel=""
        onChange={(option) => onSelectYearDone(option)}
      />
      {isErrorInput('selectedJobYearDone') && (
        <span className="errorMessage">{labels.errorJobYearDone}</span>
      )}

      {storeJobYearDone.value === '5+' && (
        <Typography className={classes.mdrFormSubTitle}>
          {labels.moreThatFiveYearsNote}
        </Typography>
      )}
    </div>
  );

  const jobDescriptionSection = (
    <TextArea
      label={labels.jobDescription}
      placeholder={labels.giveShortDescription}
      value={mdrInputs.jobDescription}
      id="otherReason"
      className="otherTextArea"
      onChange={(e) =>
        setMDRInputs({ ...mdrInputs, jobDescription: e.target.value })
      }
    />
  );

  const submittionButton = (onClick, label) => (
    <div className={classes.buttonRow}>
      <PrimaryButton
        className={classes.submitButton}
        type="submit"
        onClick={onClick}
      >
        {label}
      </PrimaryButton>
    </div>
  );

  const hasSubmittedSection = (
    <>
      <Typography
        ref={inputRefs.current.submittedSection}
        className={classes.mdrFormSubTitle}
      >
        {hasSubmissionFailed ? labels.anErrorOccured : labels.requestSent}
      </Typography>
      <Typography className={classes.mdrFormSubTitle}>
        {hasSubmissionFailed ? labels.pleaseTryAgain : labels.weCannotGuarantee}
      </Typography>
      {submittionButton(handleSendNewRequest, labels.btnSentNewRequest)}
    </>
  );

  return (
    <div className={classes.mdrForm}>
      <Typography ref={titleRef} className={classes.mdrFormTitle}>
        {labels.mdrFormTitle}
      </Typography>
      {!hasSubmitted ? (
        <>
          <Typography className={classes.mdrFormSubTitle}>
            {labels.mdrFormSubtitle}
          </Typography>

          {companySelectionSection}

          {jobYearSection}

          {jobDescriptionSection}

          {submittionButton(handleSubmit, labels.btnSendRequest)}
        </>
      ) : (
        hasSubmittedSection
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  mdrInputs: sGetMDRInputs(state),
  storeSelectedCompany: sGetSelectedCompany(state),
  storeJobYearDone: sGetJobYearDone(state),
  storeIsCompanyRegistered: sGetIsCompanyRegistered(state),
  storeIsCompanyInSystem: sGetIsCompanyInSystem(state),
});

const mapDispatchToProps = (dispatch) => ({
  setMDRInputs: (inputs) => dispatch(actionSetMDRInputs(inputs)),
  setStoreSelectedCompany: (selectedCompany) =>
    dispatch(actionSetSelectedCompany(selectedCompany)),
  setStoreJobYearDone: (jobDone) => dispatch(actionSetJobYearDone(jobDone)),
  setStoreIsCompanyRegistered: (isRegistered) =>
    dispatch(actionSetIsCompanyRegistered(isRegistered)),
  setStoreIsCompanyInSystem: (isInSystem) =>
    dispatch(actionSetIsCompanyInSystem(isInSystem)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MDRForm);
