/* eslint-disable react/no-children-prop */
import { connect } from 'react-redux';
import React, { useState, useEffect, useContext } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router';
import { FeatureCard, FeatureCardMobile } from '../../components/FeatureCard';
import Grid from '../../components/atomic/Grid';
import useDesktop from '../../hooks/useDesktop';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';

import sessionStorage from '../../utils/sessionStorage';
import { features as allFeatures } from '../../structure/salesFeatures';
import { sGetActiveProperty } from '../../store/reducers/properties';

import { redirectOptions, redirectPath } from '../../utils/routes';
import { FileUploadContext } from '../../contexts/fileUpload';
import BYBFRooms from '../../views/PrivateRoutes/Dashboard/BuildBoligmappa/BYBFRooms';
import BYBFEvents from '../../views/PrivateRoutes/Dashboard/BuildBoligmappa/BYBFEvents';
import { sGetBYBFRoomsFlowCompleted } from '../../store/reducers/bybfRooms';

import { sGetEventsCategories } from '../../store/reducers/bybfEvents';

import { acBYBFEventsSetActiveCategory } from '../../store/actions/bybfEvents';
import { actionResetMDR } from '../../store/actions/mdr';

const PropertySalesFeatures = ({
  isPropertyOwned = false,
  singleFeature = null,
  floorCompletedStatus,
  setEventsActiveCategory,
  eventCategories,
  resetMDR,
}) => {
  const [features, setFeatures] = useState(singleFeature || allFeatures);
  const { onFileUploadClick } = useContext(FileUploadContext);
  const isTabMode = useMediaQuery(`(max-width: 1023px)`);
  const isDesktop = useDesktop();
  const history = useHistory();
  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const [showRoomsDialog, setShowRoomsDialog] = useState(false);

  const [showEventsDialog, setShowEventsDialog] = useState(false);

  const onShowMissingDocumentsClick = () => {
    resetMDR();
    history.push(`${dashboardLinkPrefix}/create-mdr`);
  };

  const findIncompleteCategories = (categories) => {
    return categories
      .filter((e) => e.completed === false)
      .map((e) => e.id)
      .sort((a, b) => {
        return a.id - b.id;
      });
  };

  const incompleteCategoryIds = findIncompleteCategories(eventCategories);

  const onShowBuildYourBoligmappaClick = () => {
    if (floorCompletedStatus) {
      if (incompleteCategoryIds) {
        setEventsActiveCategory(incompleteCategoryIds[0]);
      } else {
        setEventsActiveCategory(3);
      }
      setShowEventsDialog(true);
    } else {
      setShowRoomsDialog(true);
    }
  };

  const onClickAddDocument = () => {
    if (isDesktop) {
      onFileUploadClick({
        query: { bulkUploadMode: true, source: 'table', newUpload: true },
      });
    } else {
      onFileUploadClick({
        query: { bulkUploadMode: true, source: 'listView', newUpload: true },
      });
    }
  };

  const getOnClickEventForFeature = (feature) => {
    switch (feature.code) {
      case 'MISSING_DOCUMENTS':
        return onShowMissingDocumentsClick;
      case 'BUILD_YOUR_BOLIGMAPPA':
        return onShowBuildYourBoligmappaClick;
      case 'UPLOAD_DOCUMENTS':
        return onClickAddDocument;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!isPropertyOwned && !singleFeature) {
      const filteredFeatures = allFeatures.filter(
        (feature) => !feature.privatePropertyOnly
      );
      setFeatures(filteredFeatures);
    }
    const redirectPathValue = sessionStorage.get(redirectPath);
    if (
      redirectPathValue &&
      redirectPathValue === redirectOptions.requestMissingDocuments
    ) {
      sessionStorage.remove(redirectPath);
      onShowMissingDocumentsClick();
    }
  }, []);

  return (
    <>
      {/* Desktop */}

      {isDesktop && !isTabMode && (
        <Grid container spacing={3}>
          {features.map((feature) => (
            <Grid item xs={6}>
              <FeatureCard
                feature={feature}
                onClick={getOnClickEventForFeature(feature)}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {/* Mobile */}

      {!isDesktop && !isTabMode && (
        <Grid container spacing={2}>
          {features.map((feature) => (
            <Grid item xs={6}>
              <FeatureCardMobile
                onClick={getOnClickEventForFeature(feature)}
                feature={feature}
                key={`feature-item-${feature.title}`}
              />
            </Grid>
          ))}
        </Grid>
      )}

      {!isDesktop && isTabMode && (
        <Grid container spacing={2}>
          {features.map((feature) => (
            <Grid item xs={6}>
              <FeatureCardMobile
                onClick={getOnClickEventForFeature(feature)}
                feature={feature}
                style={{
                  background: `url(${feature.image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  padding: '0px',
                  marginRight: '8%',
                  marginLeft: '6%',
                }}
                key={`feature-item-${feature.title}`}
              />
            </Grid>
          ))}
        </Grid>
      )}

      {isDesktop && isTabMode && (
        <Grid container spacing={2}>
          {features.map((feature) => (
            <Grid item xs={6}>
              <FeatureCardMobile
                onClick={getOnClickEventForFeature(feature)}
                feature={feature}
                style={{
                  background: `url(${feature.image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  padding: '0px',
                  marginRight: '8%',
                  marginLeft: '6%',
                }}
                key={`feature-item-${feature.title}`}
              />
            </Grid>
          ))}
        </Grid>
      )}

      {showRoomsDialog && (
        <BYBFRooms
          open={showRoomsDialog}
          onClose={() => {
            setShowRoomsDialog(false);
          }}
        />
      )}

      {showEventsDialog && (
        <BYBFEvents
          open={showEventsDialog}
          activeBYBFCategory={4}
          onClose={() => {
            setShowEventsDialog(false);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  activeProperty: sGetActiveProperty(state),
  floorCompletedStatus: sGetBYBFRoomsFlowCompleted(state),
  eventCategories: sGetEventsCategories(state),
});

const mapDispatchToProps = (dispatch) => ({
  setEventsActiveCategory: (status) =>
    dispatch(acBYBFEventsSetActiveCategory(status)),
  resetMDR: () => dispatch(actionResetMDR()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertySalesFeatures);
