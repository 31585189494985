import React from 'react';

const MoveToFolderIcon = ({
  width = '1.75rem',
  height = '1.75rem',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 200 200"
    fill="none"
    stroke="black"
    strokeWidth="5px"
    {...props}
  >
    <g>
      <path d="M155.94,123.44h-22.59l7.56-6.76c0.87-0.78,0.95-2.13,0.17-3c-0.78-0.88-2.12-0.95-3-0.17l-11.71,10.47   c-0.45,0.4-0.71,0.98-0.71,1.58s0.26,1.18,0.71,1.58l11.71,10.47c0.41,0.36,0.91,0.54,1.42,0.54c0.58,0,1.16-0.24,1.59-0.71   c0.78-0.88,0.71-2.22-0.17-3l-7.56-6.76h22.59c1.17,0,2.13-0.95,2.13-2.13C158.07,124.39,157.12,123.44,155.94,123.44z" />
      <path d="M162.86,106.41l4.64-9.42c1.41-2.87,1.25-6.21-0.45-8.92c-1.69-2.72-4.61-4.34-7.81-4.34H147.8V72.48   c0-3.96-3.22-7.18-7.19-7.18H87.17c-1.28,0-2.39-0.87-2.69-2.12l-2.84-11.83c-0.76-3.17-3.57-5.38-6.82-5.38H39.06   c-5.18,0-9.4,4.22-9.4,9.4v82.94c0,0.11,0.05,0.2,0.06,0.3c-0.2,1.98,0.19,4,1.28,5.76c1.69,2.72,4.61,4.34,7.81,4.34h86.41   c0.03,0,0.05-0.02,0.08-0.02c4.67,3.36,10.38,5.36,16.56,5.36c15.7,0,28.47-12.77,28.47-28.47   C170.34,118.19,167.49,111.47,162.86,106.41z M39.06,50.21h35.76c1.28,0,2.39,0.87,2.69,2.12l2.84,11.83   c0.76,3.17,3.57,5.38,6.82,5.38h53.44c1.62,0,2.94,1.32,2.94,2.93v11.25H61.71c-3.53,0-6.7,1.97-8.25,5.14l-19.54,39.75V55.36   C33.91,52.52,36.23,50.21,39.06,50.21z M34.61,142.11c-0.91-1.46-1-3.26-0.24-4.8l22.9-46.57c0.84-1.71,2.54-2.77,4.44-2.77h97.53   c1.72,0,3.29,0.87,4.2,2.33c0.91,1.46,1,3.26,0.24,4.8l-4.06,8.25c-4.87-3.91-11.04-6.26-17.76-6.26   c-15.7,0-28.47,12.77-28.47,28.47c0,7.25,2.74,13.85,7.22,18.88h-81.8C37.09,144.45,35.53,143.57,34.61,142.11z M141.87,149.79   c-13.35,0-24.22-10.87-24.22-24.22s10.87-24.22,24.22-24.22c13.35,0,24.22,10.87,24.22,24.22S155.22,149.79,141.87,149.79z" />
    </g>
  </svg>
);
export default MoveToFolderIcon;
