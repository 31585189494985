import React from "react";

const FindProfesional = (props) => (
  <svg
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <style>{`.findProfessionalIconClass{fill:none;stroke:currentColor;stroke-miterlimit:10;stroke-width:0.5px;}`}</style>
    </defs>
    <g clipPath="url(#clip0_2157_29618)">
      <path
        d="M8.28971 12.8685C8.28998 12.5019 8.37635 12.1402 8.54226 11.8108C8.70817 11.4815 8.94925 11.1931 9.24721 10.9677C9.31065 10.9192 9.35821 10.8539 9.3841 10.7797C9.40999 10.7055 9.4131 10.6256 9.39305 10.5497C9.11343 9.50317 8.45043 8.59133 7.52807 7.98472C6.60571 7.37811 5.48718 7.1183 4.38167 7.25386C3.27617 7.38941 2.25943 7.91107 1.52163 8.72124C0.783835 9.53142 0.375513 10.5746 0.373047 11.6557V14.4855C0.373047 14.5927 0.416945 14.6955 0.495085 14.7714C0.573226 14.8472 0.679207 14.8898 0.789714 14.8898H2.29805C2.35026 14.8898 2.40055 14.9088 2.43898 14.9431C2.4774 14.9774 2.50116 15.0244 2.50555 15.0749L2.87305 19.3705C2.88184 19.4718 2.92959 19.5661 3.0068 19.6347C3.08401 19.7033 3.18501 19.7412 3.28971 19.7408H6.62305C6.72775 19.7412 6.82875 19.7033 6.90596 19.6347C6.98317 19.5661 7.03092 19.4718 7.03971 19.3705L7.40888 15.0749C7.41324 15.0247 7.43676 14.9779 7.47484 14.9437C7.51291 14.9094 7.56279 14.8902 7.61471 14.8898H7.87305C7.98355 14.8898 8.08953 14.8472 8.16768 14.7714C8.24581 14.6955 8.28971 14.5927 8.28971 14.4855V12.8685Z"
        fill="#8D8D8D"
        className="findProfessionalIconClass"
      />
      <path
        d="M4.95573 6.40176C5.53259 6.40176 6.0965 6.2358 6.57614 5.92486C7.05579 5.61391 7.42962 5.17196 7.65038 4.65488C7.87113 4.13781 7.92889 3.56884 7.81635 3.01991C7.70381 2.47099 7.42603 1.96676 7.01812 1.57101C6.61022 1.17526 6.09052 0.905751 5.52474 0.796563C4.95896 0.687375 4.37252 0.743411 3.83957 0.957591C3.30662 1.17177 2.8511 1.53447 2.53061 1.99983C2.21012 2.46519 2.03906 3.0123 2.03906 3.57198C2.03906 4.32248 2.34635 5.04225 2.89334 5.57293C3.44032 6.10362 4.18218 6.40176 4.95573 6.40176Z"
        fill="#8D8D8D"
        className="findProfessionalIconClass"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53906 14.6928V12.8736C9.53906 12.552 9.67076 12.2435 9.90518 12.0161C10.1396 11.7887 10.4575 11.6609 10.7891 11.6609H11.9557C11.9973 11.6605 12.0378 11.6482 12.072 11.6255C12.1063 11.6027 12.1328 11.5706 12.1482 11.5331L12.5982 10.4279C12.7283 10.1542 12.9362 9.92226 13.1975 9.75927C13.4588 9.59629 13.7626 9.50906 14.0732 9.50782H15.8366C16.1385 9.50678 16.4346 9.5879 16.6914 9.74196C16.9481 9.89603 17.1552 10.1169 17.2891 10.3794L17.7624 11.5331C17.7777 11.5708 17.8044 11.603 17.8388 11.6258C17.8733 11.6486 17.914 11.6608 17.9557 11.6609H19.1224C19.4539 11.6609 19.7719 11.7887 20.0063 12.0161C20.2407 12.2435 20.3724 12.552 20.3724 12.8736V14.6928C20.3724 14.7464 20.3504 14.7978 20.3114 14.8357C20.2723 14.8736 20.2193 14.8949 20.1641 14.8949H9.7474C9.69214 14.8949 9.63915 14.8736 9.60008 14.8357C9.56101 14.7978 9.53906 14.7464 9.53906 14.6928ZM14.0724 10.7206C14.0032 10.7211 13.9355 10.7401 13.8767 10.7754C13.8179 10.8108 13.7703 10.8612 13.7391 10.9211L13.5532 11.3852C13.5409 11.4158 13.5364 11.4489 13.5403 11.4816C13.5441 11.5143 13.5561 11.5456 13.5753 11.5728C13.5944 11.6 13.6201 11.6222 13.6501 11.6375C13.6801 11.6529 13.7135 11.6609 13.7474 11.6609H16.1641C16.1981 11.661 16.2316 11.653 16.2617 11.6376C16.2918 11.6222 16.3175 11.5999 16.3366 11.5726C16.3557 11.5453 16.3676 11.5138 16.3712 11.481C16.3749 11.4482 16.3701 11.415 16.3574 11.3844L16.1499 10.8726C16.1145 10.8245 16.0675 10.7855 16.0131 10.759C15.9586 10.7325 15.8983 10.7193 15.8374 10.7206H14.0724Z"
        fill="#8D8D8D"
        className="findProfessionalIconClass"
      />
      <path
        d="M20.3724 15.8974C20.3724 15.8438 20.3504 15.7924 20.3114 15.7545C20.2723 15.7166 20.2193 15.6953 20.1641 15.6953H15.9974C15.9421 15.6953 15.8892 15.7166 15.8501 15.7545C15.811 15.7924 15.7891 15.8438 15.7891 15.8974V16.5038C15.7891 16.7183 15.7013 16.9239 15.545 17.0755C15.3887 17.2272 15.1767 17.3123 14.9557 17.3123C14.7347 17.3123 14.5228 17.2272 14.3665 17.0755C14.2102 16.9239 14.1224 16.7183 14.1224 16.5038V15.8974C14.1224 15.8438 14.1004 15.7924 14.0614 15.7545C14.0223 15.7166 13.9693 15.6953 13.9141 15.6953H9.7474C9.69214 15.6953 9.63915 15.7166 9.60008 15.7545C9.56101 15.7924 9.53906 15.8438 9.53906 15.8974V18.5251C9.53906 18.8467 9.67076 19.1552 9.90518 19.3826C10.1396 19.6101 10.4575 19.7379 10.7891 19.7379H19.1224C19.4539 19.7379 19.7719 19.6101 20.0063 19.3826C20.2407 19.1552 20.3724 18.8467 20.3724 18.5251V15.8974Z"
        fill="#8D8D8D"
        className="findProfessionalIconClass"
      />
      <path
        d="M8.28971 12.8685C8.28998 12.5019 8.37635 12.1402 8.54226 11.8108C8.70817 11.4815 8.94925 11.1931 9.24721 10.9677C9.31065 10.9192 9.35821 10.8539 9.3841 10.7797C9.40999 10.7055 9.4131 10.6256 9.39305 10.5497C9.11343 9.50317 8.45043 8.59133 7.52807 7.98472C6.60571 7.37811 5.48718 7.1183 4.38167 7.25386C3.27617 7.38941 2.25943 7.91107 1.52163 8.72124C0.783835 9.53142 0.375513 10.5746 0.373047 11.6557V14.4855C0.373047 14.5927 0.416945 14.6955 0.495085 14.7714C0.573226 14.8472 0.679207 14.8898 0.789714 14.8898H2.29805C2.35026 14.8898 2.40055 14.9088 2.43898 14.9431C2.4774 14.9774 2.50116 15.0244 2.50555 15.0749L2.87305 19.3705C2.88184 19.4718 2.92959 19.5661 3.0068 19.6347C3.08401 19.7033 3.18501 19.7412 3.28971 19.7408H6.62305C6.72775 19.7412 6.82875 19.7033 6.90596 19.6347C6.98317 19.5661 7.03092 19.4718 7.03971 19.3705L7.40888 15.0749C7.41324 15.0247 7.43676 14.9779 7.47484 14.9437C7.51291 14.9094 7.56279 14.8902 7.61471 14.8898H7.87305C7.98355 14.8898 8.08953 14.8472 8.16768 14.7714C8.24581 14.6955 8.28971 14.5927 8.28971 14.4855V12.8685Z"
        fill="#8D8D8D"
        className="findProfessionalIconClass"
      />
      <path
        d="M4.95573 6.40176C5.53259 6.40176 6.0965 6.2358 6.57614 5.92486C7.05579 5.61391 7.42962 5.17196 7.65038 4.65488C7.87113 4.13781 7.92889 3.56884 7.81635 3.01991C7.70381 2.47099 7.42603 1.96676 7.01812 1.57101C6.61022 1.17526 6.09052 0.905751 5.52474 0.796563C4.95896 0.687375 4.37252 0.743411 3.83957 0.957591C3.30662 1.17177 2.8511 1.53447 2.53061 1.99983C2.21012 2.46519 2.03906 3.0123 2.03906 3.57198C2.03906 4.32248 2.34635 5.04225 2.89334 5.57293C3.44032 6.10362 4.18218 6.40176 4.95573 6.40176Z"
        fill="#8D8D8D"
        className="findProfessionalIconClass"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53906 14.6928V12.8736C9.53906 12.552 9.67076 12.2435 9.90518 12.0161C10.1396 11.7887 10.4575 11.6609 10.7891 11.6609H11.9557C11.9973 11.6605 12.0378 11.6482 12.072 11.6255C12.1063 11.6027 12.1328 11.5706 12.1482 11.5331L12.5982 10.4279C12.7283 10.1542 12.9362 9.92226 13.1975 9.75927C13.4588 9.59629 13.7626 9.50906 14.0732 9.50782H15.8366C16.1385 9.50678 16.4346 9.5879 16.6914 9.74196C16.9481 9.89603 17.1552 10.1169 17.2891 10.3794L17.7624 11.5331C17.7777 11.5708 17.8044 11.603 17.8388 11.6258C17.8733 11.6486 17.914 11.6608 17.9557 11.6609H19.1224C19.4539 11.6609 19.7719 11.7887 20.0063 12.0161C20.2407 12.2435 20.3724 12.552 20.3724 12.8736V14.6928C20.3724 14.7464 20.3504 14.7978 20.3114 14.8357C20.2723 14.8736 20.2193 14.8949 20.1641 14.8949H9.7474C9.69214 14.8949 9.63915 14.8736 9.60008 14.8357C9.56101 14.7978 9.53906 14.7464 9.53906 14.6928ZM14.0724 10.7206C14.0032 10.7211 13.9355 10.7401 13.8767 10.7754C13.8179 10.8108 13.7703 10.8612 13.7391 10.9211L13.5532 11.3852C13.5409 11.4158 13.5364 11.4489 13.5403 11.4816C13.5441 11.5143 13.5561 11.5456 13.5753 11.5728C13.5944 11.6 13.6201 11.6222 13.6501 11.6375C13.6801 11.6529 13.7135 11.6609 13.7474 11.6609H16.1641C16.1981 11.661 16.2316 11.653 16.2617 11.6376C16.2918 11.6222 16.3175 11.5999 16.3366 11.5726C16.3557 11.5453 16.3676 11.5138 16.3712 11.481C16.3749 11.4482 16.3701 11.415 16.3574 11.3844L16.1499 10.8726C16.1145 10.8245 16.0675 10.7855 16.0131 10.759C15.9586 10.7325 15.8983 10.7193 15.8374 10.7206H14.0724Z"
        fill="#8D8D8D"
        className="findProfessionalIconClass"
      />
      <path
        d="M20.3724 15.8974C20.3724 15.8438 20.3504 15.7924 20.3114 15.7545C20.2723 15.7166 20.2193 15.6953 20.1641 15.6953H15.9974C15.9421 15.6953 15.8892 15.7166 15.8501 15.7545C15.811 15.7924 15.7891 15.8438 15.7891 15.8974V16.5038C15.7891 16.7183 15.7013 16.9239 15.545 17.0755C15.3887 17.2272 15.1767 17.3123 14.9557 17.3123C14.7347 17.3123 14.5228 17.2272 14.3665 17.0755C14.2102 16.9239 14.1224 16.7183 14.1224 16.5038V15.8974C14.1224 15.8438 14.1004 15.7924 14.0614 15.7545C14.0223 15.7166 13.9693 15.6953 13.9141 15.6953H9.7474C9.69214 15.6953 9.63915 15.7166 9.60008 15.7545C9.56101 15.7924 9.53906 15.8438 9.53906 15.8974V18.5251C9.53906 18.8467 9.67076 19.1552 9.90518 19.3826C10.1396 19.6101 10.4575 19.7379 10.7891 19.7379H19.1224C19.4539 19.7379 19.7719 19.6101 20.0063 19.3826C20.2407 19.1552 20.3724 18.8467 20.3724 18.5251V15.8974Z"
        fill="#8D8D8D"
        className="findProfessionalIconClass"
      />
    </g>
  </svg>
);

export default FindProfesional;
