import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams, useRouteMatch } from 'react-router';
import HeaderNav from '@componentsV2/HeaderNav';
import DesktopTopHeader from '../../../components/desktop/Header';
import Home from './Home';
import Login from './Login';
import Rooms from './Rooms';
import Events from './Events';
import Room from './Rooms/Room';
import Event from './Events/Event';
import Documents from './Documents';
import MobileNavbar from './MobileNavbar';
import Document from './Documents/Document';
import PropertyCompanies from './PropertyCompanies';
import Route from '../../../components/atomic/Route';
import TopBanner from '../../../components/TopBanner';
import Hidden from '../../../components/atomic/Hidden';
import DashboardLayout from '../../../components/layouts/Dashboard';
import {
  acSetActiveProperty,
  acSetFetchingProperties,
  acSetFetchingPropertiesError,
} from '../../../store/actions/properties';
import { sGetActiveProperty } from '../../../store/reducers/properties';
import CompanyProfile from './CompanyProfile';
import useDesktop from '../../../hooks/useDesktop';
import { isFullScreenPreviewMode } from '../../PrivateRoutes/Dashboard/util';
import useFetchEffect from '../../../hooks/useFetchEffect';
import { fetchPublicProperty as apiFetchProperty } from '../../../api/properties';
import {publicNavbarMenu} from "../../../structure/menu";

const Dashboard = ({
  property,
  setProperty,
  setFetchingProperties,
  setFetchingPropertiesError,
}) => {
  const isDesktop = useDesktop();
  const match = useRouteMatch();
  const { pathname } = useLocation();
  const { boligmappaNumber } = useParams();

  const fullscreenPreviewMode = isFullScreenPreviewMode(pathname, match.url);

  useFetchEffect({
    dependencies: [boligmappaNumber],
    onSuccess: (_property) => setProperty(_property),
    apiFetchFunction: () => apiFetchProperty(boligmappaNumber),
    conditionFunction: ([_boligmappaNumber]) => !!_boligmappaNumber,

    onFetchStart: () => setFetchingProperties(true),
    onFetchComplete: () => setFetchingProperties(false),
    onError: (error) => setFetchingPropertiesError(error),
  });
  const desktopTopHeader = <DesktopTopHeader activeProperty={property} publicMode />;
  const desktopHeaderNav = <HeaderNav activeProperty={property} navbarMenuItems={publicNavbarMenu} />;

  return (
    <DashboardLayout
      desktopTopHeader={desktopTopHeader}
      desktopHeaderNav={desktopHeaderNav}
      addButtonPaddingEnabled={false}
      addPublicButtomPaddingEnabled={!isDesktop}
      isFullscreenPreviewMode={fullscreenPreviewMode}
    >
      <Route
        exact
        component={Documents}
        path="/public/:boligmappaNumber/documents"
      />

      <Route
        component={Document}
        path="/public/:boligmappaNumber/documents/:documentId"
      />

      <Route component={Login} path="/public/:boligmappaNumber/login" />

      <Route
        exact
        component={Events}
        path="/public/:boligmappaNumber/history"
      />

      <Route
        exact
        component={Event}
        path="/public/:boligmappaNumber/events/:eventId"
      />

      <Route exact component={Rooms} path="/public/:boligmappaNumber/rooms" />

      <Route
        exact
        component={Room}
        path="/public/:boligmappaNumber/rooms/:roomId"
      />

      <Route component={Home} path="/public/:boligmappaNumber/home" />

      <Route
        exact
        component={PropertyCompanies}
        path="/public/:boligmappaNumber/companies"
      />

      <Route
        exact
        component={CompanyProfile}
        path="/public/:boligmappaNumber/company/:companyId"
      />

      {!fullscreenPreviewMode && (
        <Hidden mdUp>
          <MobileNavbar />
        </Hidden>
      )}

      <TopBanner />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => ({
  property: sGetActiveProperty(state),
});

const mapDispatchToProps = (dispatch) => ({
  setProperty: (property) => dispatch(acSetActiveProperty(property)),
  setFetchingProperties: (fetching) =>
    dispatch(acSetFetchingProperties(fetching)),
  setFetchingPropertiesError: (error) =>
    dispatch(acSetFetchingPropertiesError(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
