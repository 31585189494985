import React from 'react';

import {
  chapters,
  groupDocumentsByChapters,
} from '../../../../../utils/documents';

import ChapterRow from './ChapterRow';
import Table from '../../../../../components/atomic/Table';
import TableRow from '../../../../../components/atomic/TableRow';
import TableHead from '../../../../../components/atomic/TableHead';
import TableBody from '../../../../../components/atomic/TableBody';
import TableCell from '../../../../../components/atomic/TableCell';
import Typography from '../../../../../components/atomic/Typography';
import TableLoading from '../../../../../components/desktop/loaders/TableLoading';

const ChapterTable = ({
  documents,
  fetchingDocuments,
  fetchingDocumentsError,
}) => {
  if (fetchingDocumentsError) {
    return null;
  }

  if (fetchingDocuments) {
    return <TableLoading rows={5} columns={4} />;
  }

  const documentChapterMap =
    Array.isArray(documents) && groupDocumentsByChapters(documents);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography color="textSecondary">Navn</Typography>
          </TableCell>
          <TableCell style={{ minWidth: '175px' }}>
            <Typography color="textSecondary">Antall dokumenter</Typography>
          </TableCell>
          <TableCell style={{ minWidth: '130px' }}>
            <Typography color="textSecondary">Sist endret</Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary">Sist oppdatert av</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {chapters.map((chapter) => (
          <ChapterRow
            chapter={chapter}
            key={`chapter-item-${chapter.id}`}
            documents={documentChapterMap[chapter.id] || []}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default ChapterTable;
