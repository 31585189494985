import clsx from 'clsx';
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';

import {
  getDocumentId,
  getDocumentName,
  areFiltersEmpty,
  groupingSortTypes,
  getDocumentsByType,
  getFilteredDocuments,
  getDefaultDocumentSortType,
} from '../../utils/documents';

import styles from './style';
import Box from '../atomic/Box';
import List from '../atomic/List';
import Paper from '../atomic/Paper';
import Typography from '../atomic/Typography';
import DocumentFilters from '../DocumentFilters';
import TextHighlighted from '../TextHighlighted';
import DocumentListItem from './DocumentListItem';
import DocumentContextMenu from '../DocumentContextMenu';
import useDocumentFilters from '../../hooks/useDocumentFilters';
import SelectedFilters from '../DocumentEditFilters/SelectedFilters';
import DocumentListItemLoading from '../loaders/DocumentListItemLoading';
import { areIdsEqual, compareAlphabetically } from '../../utils/strings';

const DocumentList = ({
  classes,
  fetching,
  documents,
  documentIds,
  searchQuery,
  fetchingError,
  onDocumentDeleted,
  showOnPaper = true,
  showFilters = true,
  actionsEnabled = true,
  showRoomsColumn = false,
  showEventsColumn = false,
  enableWidgetContextMenu = false,
}) => {
  const { filters, onFiltersChanged } = useDocumentFilters();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openContextMenu, setOpenContextMenu] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [sortType, setSortType] = useState(getDefaultDocumentSortType());

  const groupByDocumentType =
    sortType && sortType.id && groupingSortTypes.includes(sortType.id);

  const selectedDocument = (documents || []).find((document) =>
    areIdsEqual(getDocumentId(document), selectedDocumentId)
  );

  const filteredDocuments = getFilteredDocuments({
    filters,
    documents,
    searchQuery,
    documentIds,
    sortFunction: sortType.sortFunction,
    shouldSort: !groupingSortTypes.includes(sortType),
  });

  const onDocumentMenuClick = (document, event) => {
    event.persist();
    setSelectedDocumentId(getDocumentId(document));
    setOpenContextMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const toggleContextMenu = (open) => {
    if (!open) {
      setAnchorEl(null);
      setSelectedDocumentId(null);
    }
  };

  const onSortTypeChange = (newSortType) => setSortType(newSortType);

  if (fetching) {
    return (
      <Paper visible={showOnPaper}>
        <List>
          <DocumentListItemLoading />
          <DocumentListItemLoading />
        </List>
      </Paper>
    );
  }

  const filtersComponents = (
    <Box
      className={clsx(
        classes.filters,
        areFiltersEmpty(filters) && classes.filtersEmpty
      )}
    >
      {/* replace with new document edit filter */}

      <DocumentFilters
        filters={filters}
        fetching={fetching}
        sortType={sortType}
        documents={documents}
        fetchingError={fetchingError}
        onSortTypeChange={onSortTypeChange}
        onFiltersChanged={onFiltersChanged}
      />

      <SelectedFilters filters={filters} onFiltersChanged={onFiltersChanged} />
    </Box>
  );

  if (groupByDocumentType) {
    const documentsByType = getDocumentsByType(filteredDocuments);

    const documentList = Object.keys(documentsByType)
      .sort(sortType.sortFunction)
      .map((documentType) => (
        <Box mb={2} key={`document-type-${documentType}`}>
          <Box mb={1} textAlign="center">
            <Typography component="small" color="textSecondary">
              <TextHighlighted searchQuery={searchQuery}>
                {documentType}
              </TextHighlighted>
            </Typography>
          </Box>
          <List>
            {documentsByType[documentType]
              .sort((a, b) =>
                compareAlphabetically(
                  getDocumentName(a),
                  getDocumentName(b),
                  sortType.direction
                )
              )
              .map((document) => (
                <DocumentListItem
                  document={document}
                  searchQuery={searchQuery}
                  actionsEnabled={actionsEnabled}
                  onMenuClick={onDocumentMenuClick}
                  key={`document-item-${getDocumentId(document)}`}
                />
              ))}
          </List>
        </Box>
      ));

    return (
      <>
        <DocumentContextMenu
          open={
            enableWidgetContextMenu
              ? !!anchorEl && !!selectedDocument
              : openContextMenu
          }
          document={selectedDocument}
          setOpen={
            enableWidgetContextMenu ? toggleContextMenu : setOpenContextMenu
          }
          onDocumentDeleted={onDocumentDeleted}
          anchorEl={anchorEl}
        />

        <Paper className={classes.paper} visible={showOnPaper}>
          {showFilters && (
            <Box mx={-2} mb={2}>
              {filtersComponents}
            </Box>
          )}

          {documentList}
        </Paper>
      </>
    );
  }

  return (
    <Paper className={classes.paper} visible={showOnPaper}>
      {showFilters && filtersComponents}

      {Array.isArray(filteredDocuments) && filteredDocuments.length > 0 && (
        <List disablePadding>
          {filteredDocuments.map((document) => (
            <DocumentListItem
              document={document}
              searchQuery={searchQuery}
              actionsEnabled={actionsEnabled}
              onMenuClick={onDocumentMenuClick}
              showRoomsColumn={showRoomsColumn}
              showEventsColumn={showEventsColumn}
              key={`document-item-${getDocumentId(document)}`}
            />
          ))}
        </List>
      )}

      {Array.isArray(filteredDocuments) && filteredDocuments.length === 0 && (
        <div className={classes.emptyList}>Ingen dokument funnet.</div>
      )}

      <DocumentContextMenu
        open={
          enableWidgetContextMenu
            ? !!anchorEl && !!selectedDocument
            : openContextMenu
        }
        document={selectedDocument}
        setOpen={
          enableWidgetContextMenu ? toggleContextMenu : setOpenContextMenu
        }
        onDocumentDeleted={onDocumentDeleted}
        anchorEl={anchorEl}
      />
    </Paper>
  );
};

export default withStyles(styles)(DocumentList);
