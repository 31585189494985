import React, { createElement } from 'react';
import Box from '../../components/atomic/Box';
// import DocumentList from '../../components/DocumentList';
import Typography from '../../components/atomic/Typography';
import { padding } from '../../components/atomic/Paper/style';
import ContentLoader from '../../components/loaders/ContentLoader';
import { getDocumentList, getDocumentTable } from '../../utils/DocumentTable';

const CompanyDocuments = ({
  fetching,
  isDesktop,
  companyDocuments,
  actionsEnabled = true,
  showRoomsColumn = false,
  showEventsColumn = false,
  showBulkSelectionOptions = true,
}) => {
  const renderTable = () =>
    createElement(isDesktop ? getDocumentTable() : getDocumentList(), {
      fetching,
      actionsEnabled,
      showRoomsColumn,
      showEventsColumn,
      isFiltersSticky: false,
      showOnPaper: false,
      documents: companyDocuments,
      showCompanyNameLinks: false,
      showFilters: true,
      showBulkSelectionOptions,
    });

  return (
    <>
      <Typography variant="h3">
        {fetching ? <ContentLoader height={20} width={110} /> : 'Dokumenter'}
      </Typography>

      <Box mx={`-${padding}px`}>{renderTable()}</Box>
    </>
  );
};

export default CompanyDocuments;
