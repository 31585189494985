import {
  eventEmitter,
  IOS_TOKENS_REFRESH_INPROGRESS,
  UNAUTHENTICATED_RESPONSE_RECEIVED,
} from './eventEmitter';

import { INTERNET_CONNECTION_ERROR } from '../constants';
import { isNativeEnv, requestTokenRefresh } from './nativeBridge';
import { logError, logWarning, isLoggerFailedResponse } from './loggers';

export const getErrorResponseData = (errorResponse) =>
  errorResponse && errorResponse.response && errorResponse.response.data
    ? errorResponse.response.data
    : null;

export const getResponseErrorCode = (errorResponse) =>
  errorResponse && errorResponse.response && errorResponse.response.data
    ? errorResponse.response.data.code
    : null;

export const getResponseErrorMessage = (errorResponse) =>
  errorResponse &&
  errorResponse.response &&
  errorResponse.response.data &&
  errorResponse.response.data.message &&
  (errorResponse.response.data.message.no ||
    errorResponse.response.data.message.en)
    ? errorResponse.response.data.message.no ||
      errorResponse.response.data.message.en
    : getResponseErrorCode(errorResponse);

export const getFailedResponseMessage = (errorResponse) =>
  errorResponse && errorResponse.message
    ? errorResponse.message
    : 'Request failed';

export const isServerError = (status) => status >= 500 && status < 600;

export const isHttpBadRequestError = (status) => Number(status) === 400;

export const isHttpUnauthenticatedError = (status) => Number(status) === 401;

export const isHttpSuccessfulResponse = (status) =>
  status >= 200 && status < 300;

export const isHttpForbiddenResponse = (status) => status === 403;

export const isPropertyOwnerNotFoundError = (error) =>
  error &&
  error.response &&
  error.response.data &&
  error.response.data.code === 'PROPERTYOWNER_NOT_FOUND';

export const getHttpResponseStatus = (response) =>
  response && response.status ? response.status : null;

export const getHttpErrorStatus = (error) =>
  error && error.response ? getHttpResponseStatus(error.response) : null;

export const successResponseInterceptor = (response) => response;

export const isOnline = () => window.navigator.onLine;

export const errorResponseInterceptor = (error) => {
  if (!isLoggerFailedResponse(error)) {
    const logInfo = [
      getFailedResponseMessage(error),
      {
        ...error.toJSON(),
        serverResponse: getErrorResponseData(error),
      },
    ];

    if (isServerError(error)) {
      logError(...logInfo);
    } else {
      logWarning(...logInfo);
    }
  }

  // check if token update failed, if yes then return corresponding warning
  if (!isOnline()) {
    return Promise.reject(new Error(INTERNET_CONNECTION_ERROR));
  }

  if (!error.response || !error.response.status) {
    return Promise.reject(error);
  }

  const {
    response: { status },
  } = error;
  if (isHttpUnauthenticatedError(status) && isNativeEnv()) {
    eventEmitter.emit(IOS_TOKENS_REFRESH_INPROGRESS);
    requestTokenRefresh();
    // clearTokenUpdatedTimestamp();
    // logoutRequest();
    return Promise.reject(error);
  }

  if (isHttpUnauthenticatedError(status)) {
    eventEmitter.emit(UNAUTHENTICATED_RESPONSE_RECEIVED);
  }

  return Promise.reject(error);
};

export const getXRequestId = (error) =>
  error &&
  error.response &&
  error.response.headers &&
  error.response.headers['x-request-id']
    ? error.response.headers['x-request-id']
    : 'UNDEFINED_X_REQUEST_ID';

export const errorTypes = {
  INTERNET_CONNECTION_ERROR: {
    message: 'Ingen Internett-tilkobling.',
    description:
      'Det ser ut som om du ikke har noen internettforbindelse. Aktiver WiFi eller mobilt internett, og prøv på nytt.',
  },
};

export const defaultErrorMessage = 'Beklager, men noe gikk galt.';

export const defaultErrorDescription =
  'Vennligst hjelp oss med å løse problemet ved å dele feilkoden med oss.';

export const getStaticErrorCode = (error) =>
  error && Object.keys(errorTypes).includes(error.message) && error.message;

export const getErrorMessage = (error, fallback = defaultErrorMessage) =>
  error && error.message && Object.keys(errorTypes).includes(error.message)
    ? errorTypes[error.message].message
    : fallback;

export const getErrorDescription = (
  error,
  fallback = defaultErrorDescription
) =>
  error && error.message && Object.keys(errorTypes).includes(error.message)
    ? errorTypes[error.message].description
    : fallback;

export const isConnectionError = (error) =>
  error && error.message && error.message === INTERNET_CONNECTION_ERROR;
