const styles = (theme) => ({
  menuList: {
    listStyle: 'none',
    padding: 'unset',
    margin: 'unset',
    maxWidth: '100%',
    minWidth: '100%',
    borderRadius: '8px',
  },
  listItem: {
    marginBottom: '8px',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  separator: {
    margin: '0 16px',
  },
  itemButton: {
    display: 'flex',
    alignItems: 'center',
    background: 'unset',
    border: 'unset',
    padding: '14px 16px',
    columnGap: '8px',
    color: theme.palette.primary.darkBlack,
  },
  itemIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    minWidth: 'unset',
  },
  itemIcon: {
    maxWidth: '20px',
    maxHeight: '20px',
    fill: theme.palette.primary.darkBlack,
  },
  itemText: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Sofia Pro',
    fontSize: '14px',
    lineHeight: '24px',
    margin: 'unset',
  },
  boldText: {
    fontWeight: 700,
  },
  newLabel: {
    fontSize: '10px',
    lineHeight: '16px',
    fontWeight: '800',
    color: '#000',
    background: theme.palette.primary.greenPositive,
    borderRadius: '8px',
    margin: '0 4px',
    padding: '2px 8px',
  },
  itemTextBold: {
    fontWeight: 700,
  },
});

export default styles;
