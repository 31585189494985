import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchUxSignalData } from '../../api/uxsignal';
import Close from '../../assets/icons/Close';
import { uxSignalStudyId } from '../../config';
import useFetchEffect from '../../hooks/useFetchEffect';
import useScript from '../../hooks/useScript';
import { acSetUxSignalStatus } from '../../store/actions/uxSignal';
import { sGetUxSignalStatus } from '../../store/reducers/uxSignal';
import Card from '../atomic/Card';
import IconButton from '../atomic/IconButton';
import ContentLoader from '../loaders/ContentLoader';
import './style.css';
import Box from '../atomic/Box';

const UXSignals = ({
  studyId,
  mode,
  scriptUrl,
  cardView = false,
  onClose,
  getUxSignalStatus = () => {},
  uxSignalStatus,
  setUxSignalStatus,
}) => {
  useScript(true, scriptUrl);

  const [statusLoading, setStatusLoading] = useState(false);

  useFetchEffect({
    onFetchStart: () => setStatusLoading(true),
    onSuccess: (data) => {
      setStatusLoading(false);
      setUxSignalStatus(data?.active);
    },
    conditionFunction: () => uxSignalStatus === null,
    apiFetchFunction: () => fetchUxSignalData(uxSignalStudyId),
  });

  useEffect(() => {
    if (uxSignalStatus !== null) getUxSignalStatus(uxSignalStatus);
  }, [uxSignalStatus]);

  const UxSignalLoading = () => (
    <Box m={3}>
      <ContentLoader height={20} mb={2} mt={5} />
      <ContentLoader height={20} mb={2} />
      <ContentLoader height={20} mb={2} />
      <ContentLoader height={20} mb={2} />
    </Box>
  );

  if (cardView) {
    return (
      <Card className="ux-signal-card">
        <IconButton
          size="small"
          onClick={() => onClose()}
          className="ux-signal-card-close"
        >
          <Close />
        </IconButton>
        {statusLoading && <UxSignalLoading />}
        <div
          className="ux-signal-embed-div"
          data-uxsignals-embed={studyId}
          data-uxsignals-mode={mode}
        />
      </Card>
    );
  }

  return (
    <>
      {statusLoading && <UxSignalLoading />}
      <div
        className="ux-signal-embed-div"
        data-uxsignals-embed={studyId}
        data-uxsignals-mode={mode}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  uxSignalStatus: sGetUxSignalStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
  setUxSignalStatus: (status) => dispatch(acSetUxSignalStatus(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UXSignals);
