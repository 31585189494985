const defaultPadding = (padding) => (padding ? '10px 45px' : undefined);

const styles = (theme) => ({
  button: ({ noPadding, variant, underline, rounded = true, padding }) => ({
    fontWeight: 700,
    textTransform: 'none',
    padding: noPadding ? 0 : defaultPadding(padding),
    textDecoration:
      (variant === 'text' || variant === undefined) && underline !== false
        ? 'underline'
        : undefined,
    '&:hover': {
      textDecoration:
        (variant === 'text' || variant === undefined) && underline !== false
          ? 'underline'
          : undefined,
    },

    '&.MuiButton-outlined': {
      borderWidth: 2,
    },

    borderRadius: rounded ? theme.spacing(3) : undefined,

    '&.MuiButton-containedSecondary': {
      color: theme.palette.primary.white,
    },
  }),
});

export default styles;
