import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import useFetchEffect from '../../../../../../hooks/useFetchEffect';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import RoomForm from '../../../../../../components/forms/RoomForm';
import { updateRoom as apiUpdateRoom } from '../../../../../../api/rooms';
import { fetchProperty } from '../../../../../../api/properties';
import { getPropertyType } from '../../../../../../utils/properties';
import {
  acSetRoomMode,
  acSetShowEditRoom,
} from '../../../../../../store/actions/rooms';
import {
  sGetRoomMode,
  sGetShowEditRoom,
} from '../../../../../../store/reducers/rooms';
import { acSyncDocumentsRooms } from '../../../../../../store/actions/documents';
import { sGetDocuments } from '../../../../../../store/reducers/documents';
import { roomModes } from '../../../../../../utils/rooms';
import useActionDialog from '../../../../../../hooks/useActionDialog';
import useDesktop from '../../../../../../hooks/useDesktop';

const EditRoom = ({
  room,
  fetching,
  fetchingError,
  onRoomUpdated,
  showEditRoom,
  roomMode,
  setShowEditRoom,
  setRoomMode,
  documents,
  syncDocumentRooms,
}) => {
  const { showSuccessMessage } = useSnackbar();
  const { roomId, boligmappaNumber } = useParams();
  const isDesktop = useDesktop();
  const { data: _property, fetching: fetchingProperty } = useFetchEffect({
    dependencies: [boligmappaNumber],
    apiFetchFunction: () => fetchProperty(boligmappaNumber),
    conditionFunction: ([_boligmappaNumber]) => _boligmappaNumber,
  });

  const propertyType =
    !fetchingProperty && _property && getPropertyType(_property);

  const [error, setError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const { showActionDialog } = useActionDialog();

  const onClose = (showActionStatus = false) => {
    if (showActionStatus) {
      setError(null);
      setShowEditRoom(false);
      setRoomMode(roomModes.room);
      return;
    }
    showActionDialog({
      title: 'Rommet er ikke lagret',
      showCancelButton: false,
      submitButtonText: 'Avbryt',
      submitButtonVariant: 'contained',
      message: 'Er du sikker på at du vil avbryte?',
      onSubmit: () => {
        setError(null);
        setShowEditRoom(false);
        setRoomMode(roomModes.room);
      },
    });
  };

  const setTitle = () => {
    switch (roomMode) {
      case roomModes.document:
        return 'Koble til dokumenter';
      case roomModes.event:
        return 'Koble til hendelser';
      default:
        return 'Redigere rom';
    }
  };

  const onFormSubmit = async (data) => {
    setError(null);
    setRequestInProgress(true);

    const [requestError, response] = await apiUpdateRoom(roomId, data);

    if (!response && requestError) {
      setError(requestError);
    } else {
      const { documentIds } = data;
      // eslint-disable-next-line no-shadow
      const { id: roomId } = data;
      onRoomUpdated({
        ...room,
        ...data,
      });

      if (documents) {
        syncDocumentRooms({
          roomId,
          documentIds,
          room: {
            ...room,
            ...data,
          },
        });
      }
      onClose(true);
      showSuccessMessage();
    }

    setRequestInProgress(false);
  };

  return (
    <RoomForm
      isEdit
      room={room}
      open={showEditRoom}
      header={setTitle}
      onClose={onClose}
      fetching={fetching}
      fetchingError={fetchingError}
      onSubmit={onFormSubmit}
      error={error}
      boligmappaNumber={boligmappaNumber}
      propertyType={propertyType}
      requestInProgress={requestInProgress}
      isDesktop={isDesktop}
    />
  );
};

const mapStateToProps = (state) => ({
  showEditRoom: sGetShowEditRoom(state),
  roomMode: sGetRoomMode(state),
  documents: sGetDocuments(state),
});

const mapDispatchToProps = (dispatch) => ({
  setShowEditRoom: (payload) => dispatch(acSetShowEditRoom(payload)),
  setRoomMode: (payload) => dispatch(acSetRoomMode(payload)),
  syncDocumentRooms: (payload) => dispatch(acSyncDocumentsRooms(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditRoom);
