const styles = (theme) => ({
  container: {
    minHeight: '680px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageDesktop: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    maxHeight: '245px',
  },
  infoText: {
    width: '100%',
    textAlign: 'center',
    color: '#254E52',
    lineHeight: 1.5,
    marginBottom: theme.spacing(3),
  },
  imageMobile: {
    maxHeight: '245px',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  question: {
    fontSize: '28px',
    lineHeight: 1.4,
  },
  questionMobile: {
    width: '330px',
    fontSize: '24px',
    lineHeight: 1.4,
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '300px',
    marginTop: theme.spacing(4),
  },
  button: {
    width: '130px',
    height: '50px',
  },
});

export default styles;
