const styles = {
  '@keyframes animateLine': {
    '0%': {
      backgroundPosition: '-400px 0',
    },
    '100%': {
      backgroundPosition: '400px 0',
    },
  },
  line: {
    float: 'none',
    position: 'relative',

    animationDuration: '1.2s',
    animationName: '$animateLine',
    backgroundSize: '800px 100px',
    animationFillMode: 'forwards',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
    background:
      'linear-gradient(to right, #ececec 10%, #dfdfdf 30%, #ececec 50%)',
  },
};

export default styles;
