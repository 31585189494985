import { get, post, patch, del, delPayLoad, getblob } from '../utils/http';
import { documentRequestDataTransformer } from './transformers/documents';

export const fetchPropertyOwner = () =>
  get('propertyowners')
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const createPropertyOwner = (data) =>
  post('propertyowners', data)
    .then((response) => [null, response.data])
    .catch((error) => [error, null]);

export const updatePropertyOwner = (data) =>
  patch('propertyowners', data)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const downloadPersonalData = () =>
  get('propertyowners/personaldocuments')
    .then((response) => [null, response])
    .catch((error) => [error, null]);

export const fetchConsents = () =>
  get('propertyowners/consents')
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const updateConsents = (data) =>
  patch('propertyowners/consents', data)
    .then((response) => [null, response.data])
    .catch((error) => [error, null]);

export const deletePropertyOwner = () =>
  del('propertyowners')
    .then((response) => [null, response])
    .catch((error) => [error, null]);

export const updateLastActivityTime = () =>
  patch('propertyowners/activities')
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const refreshProperties = () =>
  post('propertyowners/propertyrefresh')
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const createInquiry = (data) =>
  post('propertyowners/inquiry', data)
    .then((response) => [null, response.data])
    .catch((error) => [error, null]);

export const fetchPersonalDocuments = (data, pageSize = 1000) =>
  get(`propertyowners/documents?PageSize=${pageSize}`, data)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const fetchPersonalFolders = () =>
  get('propertyowners/directories')
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const fetchPersonalDocument = (documentId) =>
  get(`propertyowners/documents/${documentId}`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const createPersonalDocument = (data) =>
  post('propertyowners/documents', data, {
    transformRequest: documentRequestDataTransformer,
  })
    .then((response) => {
      return [null, response.data.response];
    })
    .catch((error) => [error, null]);

export const deletePersonalDocument = (documentId) =>
  del(`propertyowners/documents/${documentId}`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const deletePersonalDocumentFolder = (folderId) =>
  del(`propertyowners/directories/${folderId}`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const bulkDeletePersonalDocumentFolder = (data) =>
  delPayLoad(`propertyowners/directories`, data)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const deletePersonalAreaDocuments = (boligmappaNumbr, data) => {
  return delPayLoad(`propertyowners/documents`, {
    documentIds: data,
  })
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);
};

export const copyPersonalDocumentstoProperty = (data) =>
  post(`propertyowners/documents/copy`, data)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const copySinglePersonalDocumentToProperty = (data, id) =>
  post(`propertyowners/documents/copy/${id}`, data)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const moveDocumentsToProperty = (data) =>
  patch(`propertyowners/documents/move`, data)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const moveSingleDocumentToProperty = (data, id) =>
  patch(`propertyowners/documents/move/${id}`, data)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const updatePersonalDocument = (data, id) =>
  patch(`propertyowners/documents/${id}`, data)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const updatePersonalDocumentDirectory = (data, id) =>
  patch(`propertyowners/directories/${id}`, data)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const bulkDownloadDocuments = (documentIds, documentType) =>
  getblob(
    `documents/bulkdownload?documentIds=${documentIds.toString()}&type=${documentType}`
  )
    .then((response) => [null, response.data])
    .catch((error) => [error, null]);
