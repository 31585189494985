export const getCommitmentName = (commitment) =>
  commitment && commitment.name !== undefined ? commitment.name : null;

export const getCommitmentRegisteredDate = (commitment) =>
  commitment && commitment.registeredDate !== undefined
    ? commitment.registeredDate
    : null;

export const getCommitmentDocumentId = (commitment) =>
  commitment && commitment.documentId !== undefined
    ? commitment.documentId
    : null;

export const getCommitmentLicenseMattrikkel = (commitment) =>
  commitment && commitment.licenseeMattrikkel !== undefined
    ? commitment.licenseeMattrikkel
    : null;

export const getCommitmentTransferredFromMattrikkel = (commitment) =>
  commitment && commitment.transferredFromMattrikkel !== undefined
    ? commitment.transferredFromMattrikkel
    : null;
