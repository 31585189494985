import React from 'react';
import { Link } from 'react-router-dom';
import useDesktop from '@hooksV2/useDesktop';

import ArrowDown from '../../assets/icons/ArrowDown';
import { StyledFeatureBox } from './styles';

const FeatureBox = ({
  headline,
  linkText,
  hideLinkText,
  to,
  children,
  backgroundColor,
  logo,
  boxClassName,
  style,
  navigationArrowIcon,
  placeHolderCategory,
}) => {
  const isDesktop = useDesktop();
  const Wrapper = ({ ...props }) =>
    to ? (
      <>
        <Link className="link_wrapper" to={to}>
          {props.children}
        </Link>
        {!isDesktop && navigationArrowIcon && (
          <ArrowDown className="arrowIcon" />
        )}
      </>
    ) : (
      <>{props.children}</>
    );
  return (
    <StyledFeatureBox
      className={boxClassName}
      $backgroundColor={backgroundColor}
      style={style}
    >
      <Wrapper>
        <div className="feature_content">
          {logo && <img className="logo" src={logo} alt="company logo" />}
          <h2 className={placeHolderCategory && 'place_holder_heading'}>
            {headline}
          </h2>
          <p className={placeHolderCategory && 'place_holder_description'}>
            {children}
          </p>
          {!hideLinkText &&
            !placeHolderCategory &&
            (linkText ? (
              <Link className="link" to={to}>
                {linkText}
              </Link>
            ) : (
              <p className="soon">Kommer snart!</p>
            ))}
        </div>
      </Wrapper>
    </StyledFeatureBox>
  );
};

export default FeatureBox;
