import React from 'react';

const ConnectExistingRoom = ({
  width = '1.2rem',
  height = '1.2rem',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 23 23.127"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g data-name="Group 1920" transform="translate(7117 3085.877)">
      <g transform="translate(-10177.279 395)">
        <path
          transform="translate(3059.279 -3480.877)"
          d="M9.315,0,1,4.535v9.17l8.315,4.436,8.315-4.436V4.585L9.315,0Zm5.979,5.024L9.319,8.212,3.368,4.966,9.311,1.724ZM2.512,6.221l6.047,3.3v6.506L2.512,12.8V6.221Zm7.559,9.8v-6.5L16.118,6.3v6.5Zm5.048-4.018a.453.453,0,1,1-.462-.444.453.453,0,0,1,.462.444ZM13.07,10.74a.453.453,0,1,0,.462.444A.452.452,0,0,0,13.07,10.74Zm-1.612-.828a.454.454,0,1,0,.462.444A.453.453,0,0,0,11.459,9.912Zm-7.485,1.65a.454.454,0,1,1-.462.444.454.454,0,0,1,.462-.444ZM5.1,11.184a.453.453,0,1,0,.462-.444A.454.454,0,0,0,5.1,11.184Zm1.612-.827a.453.453,0,1,0,.462-.444.454.454,0,0,0-.462.444ZM9.315,6.012a.454.454,0,1,0,.454.454A.454.454,0,0,0,9.315,6.012Zm0-1.682a.454.454,0,1,0,.454.454A.454.454,0,0,0,9.315,4.33Zm0-1.535a.454.454,0,1,0,.454.454A.454.454,0,0,0,9.315,2.795Z"
        />
      </g>
      <path
        data-name="iconmonstr-link-1 (2)"
        transform="translate(-7104.001 -3072.751)"
        d="M2.579,3.633A2.674,2.674,0,0,1,6.7,4.042l-.936.935a1.41,1.41,0,0,0-1.6-.812,1.388,1.388,0,0,0-.68.374L1.692,6.334A1.4,1.4,0,1,0,3.667,8.309l.553-.553A3.556,3.556,0,0,0,5.795,8l-1.22,1.22A2.679,2.679,0,0,1,.785,5.427L2.579,3.633ZM5.427.785l-1.22,1.22a3.556,3.556,0,0,1,1.575.239l.553-.552A1.4,1.4,0,1,1,8.309,3.667L6.515,5.461a1.4,1.4,0,0,1-1.975,0,1.486,1.486,0,0,1-.3-.438L3.3,5.958a2.516,2.516,0,0,0,.332.409,2.677,2.677,0,0,0,3.789,0L9.216,4.573A2.679,2.679,0,1,0,5.427.785Z"
      />
    </g>
  </svg>
);

export default ConnectExistingRoom;
