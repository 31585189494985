import { put, delPayLoad } from '../utils/http';

export const updatePropertyDocuments = (boligmappaNumbr, data) => {
  return put(`properties/${boligmappaNumbr}/documents`, data)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);
};

export const deletePropertyDocuments = (boligmappaNumbr, data) => {
  return delPayLoad(`properties/${boligmappaNumbr}/documents`, data)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);
};
