const styles = {
  fileList: {
    marginBottom: '20px',
  },
  inputField: {
    width: 0,
    height: 0,
    overflow: 'hidden',
  },
};

export default styles;
