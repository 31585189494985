import React from 'react';

const VideoIcon = ({ width = '20', height = '24' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 24"
  >
    <g
      id="Group_2782"
      data-name="Group 2782"
      transform="translate(-205.109 -3123.902)"
    >
      <g id="Group_2781" data-name="Group 2781" transform="translate(74 1)">
        <g
          id="Group_2777"
          data-name="Group 2777"
          transform="translate(-188 1862)"
        >
          <g id="Group_2771" data-name="Group 2771">
            <path
              id="Shape_42"
              d="M406,54.328V70a2,2,0,0,1-2,2H388a2,2,0,0,1-2-2V50a2,2,0,0,1,2-2h11.672a2,2,0,0,1,1.414.586l4.328,4.328A2,2,0,0,1,406,54.328Z"
              transform="translate(-66.891 1212.902)"
              fill="#9a54d9"
            />
          </g>
        </g>
      </g>
      <g id="ondemand_video-24px" transform="translate(207 3127.902)">
        <path
          id="Path_2838"
          data-name="Path 2838"
          d="M0,0H15.628V19H0Z"
          fill="none"
        />
        <path
          id="Path_2839"
          data-name="Path 2839"
          d="M13.389,3H2.239A1.349,1.349,0,0,0,1,4.444v8.667a1.353,1.353,0,0,0,1.239,1.444h3.1V16h4.956V14.556h3.1a1.353,1.353,0,0,0,1.233-1.444l.006-8.667A1.353,1.353,0,0,0,13.389,3Zm0,10.111H2.239V4.444h11.15Zm-3.1-4.333L5.956,11.667V5.889Z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default VideoIcon;
