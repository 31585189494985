import React from 'react';

const ChapterTen = ({
  width = '100%',
  height = '100%',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24.137 29.706"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(0 0)">
      <path
        fill={fill}
        transform="translate(-433.357 -393.878)"
        d="M447.9,417.086h1.238a1.238,1.238,0,0,0,1.238-1.238v-3.094a.619.619,0,0,1,.619-.619h1.238a.619.619,0,0,0,.416-1.077l-6.391-5.81a1.23,1.23,0,0,0-1.666,0l-6.391,5.81a.619.619,0,0,0,.416,1.077h1.238a.619.619,0,0,1,.619.619v3.094a1.238,1.238,0,0,0,1.238,1.238h1.238a1.238,1.238,0,0,0,1.238-1.238v-2.476a1.238,1.238,0,1,1,2.476,0v2.476A1.238,1.238,0,0,0,447.9,417.086Z"
      />
      <path
        fill={fill}
        transform="translate(-434.25 -395.584)"
        d="M455.911,397.75h-1.238a1.238,1.238,0,0,0,0,2.476h.619a.619.619,0,0,1,.619.619V422.2a.619.619,0,0,1-.619.619H437.344a.619.619,0,0,1-.619-.619V400.844a.619.619,0,0,1,.619-.619h.619a1.238,1.238,0,1,0,0-2.476h-1.238a2.475,2.475,0,0,0-2.476,2.476v22.589a2.475,2.475,0,0,0,2.476,2.476h19.185a2.475,2.475,0,0,0,2.476-2.476V400.226A2.475,2.475,0,0,0,455.911,397.75Z"
      />
      <path
        fill={fill}
        transform="translate(-433.121 -396)"
        d="M440.238,403.427h9.9a1.238,1.238,0,0,0,1.238-1.238v-2.476a1.238,1.238,0,0,0-1.238-1.238h-1.451a.618.618,0,0,1-.583-.412,3.094,3.094,0,0,0-5.835,0,.618.618,0,0,1-.583.412h-1.451A1.238,1.238,0,0,0,439,399.713v2.476A1.238,1.238,0,0,0,440.238,403.427Z"
      />
    </g>
  </svg>
);

export default ChapterTen;
