import React from 'react';

import { enableAddOn, isProduction } from '../config';
import BinIcon from '../assets/icons/Bin';
import Box from '../components/atomic/Box';
import ExitIcon from '../assets/icons/Exit';
import InfoIcon from '../assets/icons/Info';
import Shield from '../assets/icons/Shield';
import ExternalLinkIcon from '../assets/icons/ExternalLink';
import PrivateSharingMenuIcon from '../assets/icons/PrivateSharingMenuIcon';
import FolderIcon from '../assets/icons/Folder';
import { experimentTemplateData } from '../views/PrivateRoutes/Dashboard/ExperimentTemplate/util';
import FindProfesionalIcon from '../assets/icons/FindProfessional';
import { getPropertyType } from '../utils/properties';
import CreditCard from '../assets/icons/CreditCard';
import ProfileIcon from '../assets/icons/ProfileIcon';
import CircleQuestion from '../assets/icons/CircleQuestion';
import PrivateSharingIcon from '../assets/icons/PrivateSharingIcon';
import Bell from '../assets/icons/Bell';

const getSettingItems = ({ dashboardLinkPrefix, property, isFromPersonal }) => {
  const settings = [
    {
      // required for key list prop in react
      id: 0,
      title: 'Tjenester',
      mobileOnly: true,
      experimental: false,
      items: [
        {
          icon: FindProfesionalIcon,
          text: 'Finn håndverker',
          to: `${dashboardLinkPrefix}/more/find-professional`,
        },
      ]
        .concat(
          ...(enableAddOn === 'true'
            ? experimentTemplateData
                .map((value) => {
                  if (
                    value.menuItem.isEnable &&
                    property &&
                    !(
                      value.menuItem.hideOnLands &&
                      getPropertyType(property) === 'Eiendom/tomt uten bygning'
                    ) &&
                    !(
                      value.menuItem.hideOnGarageBuilding &&
                      getPropertyType(property) ===
                        'GARASJEUTHUS ANNEKS TIL BOLIG'
                    )
                  )
                    return {
                      icon: value.menuItem.icon,
                      text: value.menuItem.title,
                      to: `${dashboardLinkPrefix}/addon/${value.experimentSlug}`,
                      isNew: value.menuItem.isNew,
                      hideOnLands: value.menuItem.hideOnLands,
                      hideOnGarageBuilding: value.menuItem.hideOnGarageBuilding,
                    };
                  return null;
                })
                .filter((value) => value)
            : [])
        )
        .concat([
          {
            icon: PrivateSharingMenuIcon,
            text: 'Delinger og tilganger',
            hideInPrivateProperty: true,
            notificationLabel: true,
            to: `${dashboardLinkPrefix}/more/private-share`,
          },
        ])
        .concat([
          {
            icon: FolderIcon,
            text: 'Mangler du dokumentasjon',
            to: `${dashboardLinkPrefix}/create-mdr`,
          },
        ]),
    },
    {
      id: 1,
      title: 'Konto',
      items: [
        {
          icon: () => ProfileIcon({ fill: '#111' }),
          text: 'Min profil',
          to: `${dashboardLinkPrefix}/more/account`,
        },
        {
          icon: Shield,
          text: 'Administrer samtykker',
          to: `${dashboardLinkPrefix}/more/consents`,
        },
        {
          icon: CreditCard,
          text: 'Mine kjøp',
          to: `${dashboardLinkPrefix}/my-purchases`,
        },
        {
          icon: CircleQuestion,
          text: 'Hjelp og kundestøtte',
          to: `support.boligmappa.no`,
          isExternalLink: true,
          openInNewTab: true,
        },
        {
          icon: PrivateSharingIcon,
          text: 'Del din Boligmappe',
          hideInSharedProperty: true,
          to: `${dashboardLinkPrefix}/more/private-share`,
        },
        {
          icon: Bell,
          text: 'Meldinger',
          notificationsBadge: true,
          to: `${dashboardLinkPrefix}/notifications`,
          hideInPersonalArea: true,
        },
        {
          icon: () => BinIcon({ fill: '#111' }),
          text: 'Slett kontoen din',
          to: `${dashboardLinkPrefix}/more/profile-delete`,
        },
        // TODO: enable when ready
        // {
        //   icon: Download,
        //   text: 'Last ned personlig innhold',
        //   to: `${dashboardLinkPrefix}/more/download-personal-data`,
        // },
      ],
    },
    {
      id: 2,
      title: 'Hjelp',
      supportSection: true,
      mobileOnly: true,
      items: [
        {
          icon: InfoIcon,
          openInNewTab: true,
          isExternalLink: true,
          text: (
            <>
              Kundestøtte
              <Box display="inline" ml={2} position="relative" top={2}>
                <ExternalLinkIcon />
              </Box>
            </>
          ),
          to: 'https://support.boligmappa.no/hc/no',
        },
      ],
    },
    {
      id: 3,
      items: [
        {
          to: '/logout',
          icon: ExitIcon,
          className: 'logout',
          text: 'Logg ut',
        },
      ],
    },
  ];

  const personalSettings = [
    {
      id: 0,
      items: [
        {
          to: '/logout',
          icon: ExitIcon,
          text: 'Logg ut',
        },
      ],
    },
  ];

  const defaultSettings = settings.filter(({ experimental }) => {
    const isNotInProduction = !isProduction();
    if (isNotInProduction) return true;
    return !experimental;
  });

  const defaultPersonalSettings = personalSettings.filter(
    ({ experimental }) => {
      const isNotInProduction = !isProduction();
      if (isNotInProduction) return true;
      return !experimental;
    }
  );

  if (!isFromPersonal) {
    return defaultSettings;
  }
  return defaultPersonalSettings;
};

export default getSettingItems;
