const styles = (theme) => ({
  container: {
    height: '100%',
    position: 'relative',
  },
  upload_btn_container: {
    width: '100%',
    height: '125px',
    '&:hover': {
      backgroundColor: theme.palette.primary.mint,
    },
  },
  content: {
    display: 'block',
  },
  imageIcon: {
    width: '42px',
    height: '20px',
    fill: theme.palette.primary.darkGreen,
  },
  image_galary_view: {
    width: '100%',
    height: '250px',
    overflow: 'hidden',
    borderRadius: '21px',
  },
  actions: {
    zIndex: 1,
    right: '10px',
    bottom: '15px',
    display: 'flex',
    position: 'absolute',
  },
  editButton: {
    marginRight: '15px',
    position: 'relative',
    fontWeight: 'normal',
    textDecoration: 'none',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    background: theme.palette.primary.white,
    height: '30px',
    '&:hover': {
      textDecoration: 'none',
      background: theme.palette.primary.white,
    },
  },
  cameraIcon: {
    width: '100%',
    height: '100%',
    maxWidth: '1rem',
    maxHeight: '1rem',
    marginRight: theme.spacing(1),
  },
  binIcon: {
    width: '100%',
    height: '100%',
    maxWidth: '1rem',
    maxHeight: '1rem',
  },
  deleteIcon: {
    marginRight: '5px',
    height: '30px',
    width: '30px',
    minWidth: '30px',
    background: theme.palette.primary.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '21px',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      background: theme.palette.primary.white,
    },
  },
  image_heading: {
    width: '100%',
    height: 60,
    background: 'rgba(0,0,0,0.4)',
    position: 'absolute',
    bottom: 0,
    borderRadius: '0 0 21px 21px',
  },
});

export default styles;
