/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Card from '../../../../../components/Card';
import { OverviewGridItem } from './components';
import PropertyImages from '../../../../../modules/PropertyImages';
import {
  getPropertyBoligmappaNumber,
  getPropertyGoogleStreetViewPhotoEnabled,
} from '../../../../../utils/properties';
import { useActiveProperty } from '../../../../../store/hooks';

function PropertyImagesSection() {
  const { property, isFetchingProperties } = useActiveProperty();

  const boligmappaNumber = getPropertyBoligmappaNumber(property);

  const googleStreetImageEnabled =
    getPropertyGoogleStreetViewPhotoEnabled(boligmappaNumber);

  return (
    <OverviewGridItem>
      <Card height="100%" border={0} padding={0}>
        <PropertyImages
          editAccess={false}
          property={property}
          fetching={isFetchingProperties}
          googleStreetImageEnabled={googleStreetImageEnabled}
        />
      </Card>
    </OverviewGridItem>
  );
}
export default PropertyImagesSection;
