// Add your styles here, and reference them in your component className
import { css } from '@linaria/core';

export const Label = css`
  display: flex;
  margin-bottom: 8px;
  font-size: .875rem;
  line-height: 24px;
  font-weight: 500;

  .visually-hidden {
    display: none;
  }
`;
