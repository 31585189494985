import React from 'react';

import Box from '../../../atomic/Box';
import Typography from '../../../atomic/Typography';
import BaseSelectedFilters from '../../../DocumentFilters/SelectedFiltersForDocumentView';

import { areFiltersEmpty } from '../../../../utils/documents';
import Filters from '../../../../assets/icons/Filters';

const SelectedFilters = ({ filters, ...props }) => (
  <Box display="flex" alignItems="center">
    {!areFiltersEmpty(filters) && (
      <Box
        px={1}
        display="flex"
        alignItems="center"
        style={{ width: '17px', margin: '0 20px', padding: 0 }}
      >
        <Typography>
          <Filters />
        </Typography>
      </Box>
    )}
    <BaseSelectedFilters filters={filters} {...props} />
  </Box>
);

export default SelectedFilters;
