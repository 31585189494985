const styles = (theme) => ({
  listItem: ({ disabled }) => ({
    padding: '16px',
    columnGap: '10px',
    paddingLeft: '6px',
    minWidth: '335px',

    color: disabled
      ? theme.palette.primary.greyLight
      : theme.palette.text.primary,
  }),
  borderClass: {
    borderBottom: `1px solid ${theme.palette.primary.whiteDarker}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  listItemText: {
    fontWeight: 500,
    width: '90%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  boldText: {
    fontWeight: '600',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
    width: '20px',
  },
  icon: ({ disabled }) => ({
    fill: disabled
      ? theme.palette.primary.greyLight
      : theme.palette.text.secondary,
    color: disabled
      ? theme.palette.primary.greyLight
      : theme.palette.text.secondary,
  }),
  link: {
    display: 'flex',
    color: 'inherit',
    textDecoration: 'none',
    margin: 0,
    padding: 0,
  },
  listItemWithCheck: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  divider: {
    margin: '0 12px 0 12px',
  },
});

export default styles;
