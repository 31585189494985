const styles = (theme) => ({
  menu: {
    paddingTop: '0',
    paddingBottom: '0',

    '& a:hover': {
      textDecoration: 'unset',
    },
  },
  menuPaper: {
    borderRadius: '8px',
    boxShadow: '0px 12px 32px 0px rgba(38, 39, 59, 0.13)',
  },
  iconWrapperClassName: {
    '&:after': {
      content: '"2"',
      position: 'absolute',
    },
  },
  profileBlock: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '9px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#000',
    margin: 'unset',
  },
  profileButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  notificationIcon: {
    position: 'absolute',
    top: '-8px',
    right: '-7px',
    width: '16px',
    height: '16px',
    background: '#FD6765',
    borderRadius: '50%',
    padding: '3px',
  },
  username: {
    cursor: 'pointer',
    padding: '9px',
    margin: '-6px 0',

    '&.mobile': {
      padding: '0 6px 1px 6px',
    },

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  boligmappaPlusSign: {
    position: 'absolute',
    top: '-10px',
    right: '-8px',
    width: '19px',
    height: '19px',
    background: '#fff',
    borderRadius: '50%',
    padding: '1.5px',
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  notificationsBadge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 900,
    width: '24px',
    height: '24px',
    backgroundColor: '#FD6765',
    borderRadius: '50%',
    margin: 0,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#111',
    padding: '14px 16px',
    cursor: 'pointer',

    [theme.breakpoints.up('md')]: {
      '&:hover': {
        background: '#FAFAFA',
        textDecoration: 'unset',
      },
    },
    '&.logout': {
      color: 'red',
    },
  },
  divider: {
    margin: '10px 0',
  },
});

export default styles;
