import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, withStyles } from '@material-ui/core';

import clsx from 'clsx';
import moment from 'moment';
import { useParams } from 'react-router';

import ArrowRight from '../../../../../../assets/icons/ArrowRight';
import AddIcon from '../../../../../../assets/icons/Add';
import style from './style';
import DetailsList from './DetailsList';
import DetailsTable from './DetailsTable';
import useDesktop from '../../../../../../hooks/useDesktop';
import Typography from '../../../../../../components/atomic/Typography';
import ProgressButton from '../../../../../../components/ProgressButton';
import Copybox from '../../../../../../components/Copybox';
import { openLink } from '../../../../../../utils/routes';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import { getUserFullname } from '../../../../../../utils/auth';
import { sGetUser } from '../../../../../../store/reducers/auth';
import MenuIcon from '../../../../../../assets/icons/MenuHorizontal';
import ContextMenu from '../../Share/Details/ContextMenu';
import IconButton from '../../../../../../components/atomic/IconButton';
import Button from '../../../../../../components/atomic/Button';
import ListItem from '../../../../../../components/CardList/KeyValueListItem';
import List from '../../../../../../components/atomic/List';
import { sGetAccessRequests } from '../../../../../../store/reducers/accessRequests';
import Alert from '../../../../../../assets/icons/Alert';
import {
  getAccessRequestsData,
  patchAccessRequest,
} from '../../../../../../api/properties';
import { actionSetAccessRequests } from '../../../../../../store/actions/accessRequests';
import AccessTable from './AccessTable';
import AccessList from './AccessList';
import AccessRequestDialog from './AccessRequestDialog';
import useActionDialog from '../../../../../../hooks/useActionDialog';
import { enableSurveyor } from '../../../../../../config';
import matomo from '../../../../../../utils/matomo';

const Details = ({
  classes,
  publicAccessData,
  setShowPublicSharingForm,
  user,
  togglePrivateSharingForm,
  toggleEditPublicSharing,
  stopPublicSharing,
  administratorsData,
  shareData,
  fetching,
  onDetailsClick,
  onAccessRequestsDetailsClick,
  onDelete,
  onDeleteFail,
  onDeleteShare,
  publicSharingUrl,
  accessRequests,
  setAccessRequests,
}) => {
  const { boligmappaNumber } = useParams();
  const [visibleAccessRequests, setVisibleAccessRequests] =
    useState(accessRequests);
  const [openContextMenu, setOpenContextMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [morePublicShareInfo, setMorePublicShareInfo] = useState(false);
  const [accessRequestDialog, setAccessRequestDialog] = useState(false);
  const [selectedAccessRequest, setSelectedAccessRequest] = useState();
  const getFormattedDate = (dateStr) => moment(dateStr).format('DD.MMMM YYYY');
  const onAccessRequest = (requestIndex) => {
    setSelectedAccessRequest(visibleAccessRequests[requestIndex]);
    setAccessRequestDialog(true);
  };
  const updateRequests = async () => {
    const [error, response] = await getAccessRequestsData(boligmappaNumber);
    if (!error && response) {
      setAccessRequests(response);
    }
  };
  const { showActionDialog, showLoadingDialog } = useActionDialog();

  const trackMatomoEvent = (name, action) => {
    matomo.clickEvent({
      category: 'Sharing Options',
      name,
      action,
    });
  };

  const onAccessRequestDenied = async (request) =>
    showActionDialog({
      submitButtonText: 'Stopp deling',
      submitButtonRed: true,
      title: 'Fjern innsyn i dine dokumenter?',
      message: 'Er du sikker på at du vil stoppe delingen?',
      onSubmit: async () => {
        const closeDialog = showLoadingDialog('Sletter...');

        const [error, response] = await patchAccessRequest(request, 'Denied');

        closeDialog();

        if (error && !response) {
          onDeleteFail(error);
        } else {
          trackMatomoEvent(
            'Removes Surveyor Request',
            'Sharing With Surveyors'
          );
          await updateRequests();
        }
      },
    });
  const onMenuButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenContextMenu(true);
  };
  const { showSuccessMessage } = useSnackbar();
  const onCopySuccess = () => {
    trackMatomoEvent('Copied Public Sharing Link', 'Public Sharing');
    showSuccessMessage('Lenken er kopiert.');
  };
  const propertyOwnerName = getUserFullname(user);
  const emailPublicSharingUrl = () => {
    const subject = 'Jeg inviterer deg til å se min Boligmappe';
    const messageBody = `Jeg har gjort min boligmappe offentlig tilgjengelig og inviterer deg til å se innholdet.%0DSe boligmappen her: ${publicSharingUrl}%0D%0DVennlig hilsen%0D${propertyOwnerName}`;

    openLink(`mailto:?subject=${subject}&body=${messageBody}`, '_blank');
    trackMatomoEvent('Shared Public Sharing Link by Email', 'Public Sharing');
  };
  const buttonLabel = 'Legg til';
  const isDesktop = useDesktop();

  // TODO: Use case info will be updated once received
  // const privateShareDetailsImage = (
  //   <Image
  //     width="100%"
  //     className={isDesktop && classes.privateShareImage}
  //     fallbackSrc={PrivateShareDetails}
  //   />
  // );

  const newShareButton = (onClick) => (
    <div className={classes.introButtonArea}>
      <ProgressButton
        className={isDesktop ? classes.addShare : classes.addShareMobile}
        onClick={onClick}
        requestInProgress={false}
        type="submit"
        color="primary"
        variant="contained"
      >
        <AddIcon width="15px" height="15px" />
        {isDesktop && (
          <span className={classes.progressButtonTxt}>{buttonLabel}</span>
        )}
      </ProgressButton>
    </div>
  );
  const deskTop = isDesktop && administratorsData && true;
  useEffect(() => {
    setVisibleAccessRequests(
      accessRequests.filter((el) => el.status.status !== 'Denied')
    );
  }, [accessRequests]);
  return (
    <>
      <ContextMenu
        open={openContextMenu}
        setOpen={setOpenContextMenu}
        onEditClick={toggleEditPublicSharing}
        onDeleteClick={stopPublicSharing}
        anchorEl={anchorEl}
      />
      <Grid container={deskTop}>
        {isDesktop && (
          <Typography className={classes.headline} variant="h1">
            Deling og tilganger
          </Typography>
        )}
        <Grid
          className={clsx(classes.tableWrapper, !isDesktop && 'mobile')}
          item
          xs={12}
        >
          <Grid container spacing={2} className={classes.blockRow}>
            <Grid item xs={12}>
              <div className={classes.titleArea}>
                <div className={clsx(classes.shareRow, 'mobile')}>
                  <Typography variant="h2" marginTop={!isDesktop}>
                    Legg til administrator
                  </Typography>
                  {!fetching && newShareButton(togglePrivateSharingForm)}
                </div>
                <Grid item md={9}>
                  <Typography
                    variant="h6"
                    className={clsx(
                      classes.blockDescription,
                      !isDesktop && 'mobile'
                    )}
                  >
                    Du kan legge til en medeier av din boligmappe, som gir
                    personen full innsikt og redigeringstilgang.
                  </Typography>
                </Grid>
              </div>
            </Grid>
          </Grid>

          {!administratorsData && (
            <div>
              {isDesktop && (
                <DetailsTable
                  shareRecords={shareData}
                  fetching={fetching}
                  onDelete={onDelete}
                  onDeleteFail={onDeleteFail}
                  onDeleteShare={onDeleteShare}
                />
              )}
              {!isDesktop && (
                <DetailsList
                  shareRecords={shareData}
                  fetching={fetching}
                  isDesktop={isDesktop}
                  onDelete={onDelete}
                  onDetailsClick={onDetailsClick}
                  onDeleteFail={onDeleteFail}
                />
              )}
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container={deskTop}>
        <Grid
          className={clsx(classes.tableWrapper, !isDesktop && 'mobile')}
          item
          xs={12}
        >
          <Grid container spacing={2} className={classes.blockRow}>
            <Grid item xs={12}>
              <div className={clsx(classes.shareRow, 'mobile')}>
                <Typography variant="h2" marginTop={!isDesktop}>
                  Gi lesetilgang
                </Typography>
                {publicAccessData ? (
                  <IconButton
                    onClick={onMenuButtonClick}
                    className={classes.moreButton}
                  >
                    <MenuIcon className={classes.moreButtonIcon} />
                  </IconButton>
                ) : (
                  <ProgressButton
                    onClick={() => setShowPublicSharingForm(true)}
                    requestInProgress={false}
                    type="submit"
                    color="primary"
                    variant="contained"
                    className={
                      isDesktop ? classes.addShare : classes.addShareMobile
                    }
                  >
                    <AddIcon />
                    {isDesktop && (
                      <span className={classes.progressButtonTxt}>
                        Ny deling
                      </span>
                    )}
                  </ProgressButton>
                )}
              </div>
              <Grid item md={9}>
                <Typography
                  variant="h6"
                  className={clsx(
                    classes.blockDescription,
                    !isDesktop && 'mobile'
                  )}
                >
                  Personer med lenken vil få lesetilgang til boligens
                  dokumenter, informasjon og fagfolk. De vil ikke få mulighet
                  til å gjøre endringer, og du bestemmer utløpsdato for lenken.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {publicAccessData && (
            <>
              <div className={classes.copyBoxContainer}>
                <Copybox
                  str={publicSharingUrl}
                  onCopySuccess={onCopySuccess}
                  onClickUrl={() => openLink(publicSharingUrl, '_blank')}
                  emailButton
                  onEmailSend={emailPublicSharingUrl}
                  isDesktop={isDesktop}
                />
              </div>
              <Button
                className={clsx(classes.morePublicInfo, 'mobile')}
                type="button"
                onClick={() => setMorePublicShareInfo(!morePublicShareInfo)}
              >
                <div
                  className={clsx(
                    classes.morePublicInfoIcon,
                    morePublicShareInfo && 'active'
                  )}
                >
                  <ArrowRight />
                </div>
                <span
                  className={clsx(classes.linkText, !isDesktop && 'mobile')}
                >
                  {morePublicShareInfo ? 'Skjul' : 'Vis'} detaljer om linken
                </span>
              </Button>
              <List
                className={clsx(
                  morePublicShareInfo && 'active',
                  classes.publicShareList
                )}
              >
                <ListItem label="Status">
                  <span className={classes.activeStatus}>
                    {publicAccessData.status}
                  </span>
                </ListItem>
                <ListItem label="Delt fra">
                  {getFormattedDate(publicAccessData.startDate)}
                </ListItem>
                <ListItem label="Delt til">
                  {getFormattedDate(publicAccessData.endDate)}
                </ListItem>
                <ListItem label="Delt av">{publicAccessData.sharedBy}</ListItem>
                <ListItem label="Antall visninger">
                  {publicAccessData.visitors}
                </ListItem>
              </List>
            </>
          )}
        </Grid>
      </Grid>

      {enableSurveyor === 'true' && (
        <Grid container={deskTop}>
          <Grid
            className={clsx(classes.tableWrapper, !isDesktop && 'mobile')}
            item
            xs={12}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div
                  className={
                    isDesktop ? classes.titleArea : classes.titleAreaMobile
                  }
                >
                  <Typography variant="h2" marginTop={!isDesktop}>
                    Del med bygningssakkyndig
                  </Typography>
                  <Typography
                    variant="h6"
                    className={clsx(
                      classes.blockDescription,
                      !isDesktop && 'mobile'
                    )}
                  >
                    Når du deler med bygningssakkyndig får de opp dine
                    dokumenter i sitt fagsystem. De vil kunne lese og laste ned
                    dokumentene. Tilgangen gjelder i tre måneder, eller til du
                    sletter den.
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <h3 className={clsx(classes.subHeadline, 'mobile')}>
              Dine forespørsler
            </h3>
            {enableSurveyor === 'true' && (
              <AccessRequestDialog
                accessRequestDialog={accessRequestDialog}
                setAccessRequestDialog={setAccessRequestDialog}
                selectedAccessRequest={selectedAccessRequest}
              />
            )}
            {visibleAccessRequests.length ? (
              <>
                {visibleAccessRequests.map(
                  (el, index) =>
                    el.status.status === 'Pending' && (
                      <div
                        className={clsx(
                          classes.requestRow,
                          !isDesktop && 'mobile'
                        )}
                      >
                        <div
                          className={clsx(
                            classes.requestDescription,
                            !isDesktop && 'mobile'
                          )}
                        >
                          <div className={classes.requestIcon}>
                            <Alert />
                          </div>
                          <Typography
                            noMargins
                            className={classes.pendingRequestTxt}
                          >
                            {el.requester.company || '...'} ønsker innsyn i dine
                            dokumenter
                          </Typography>
                        </div>
                        <Button
                          className={clsx(
                            classes.requestButton,
                            !isDesktop && 'mobile'
                          )}
                          onClick={() => onAccessRequest(index)}
                        >
                          Gå til forespørsel
                        </Button>
                      </div>
                    )
                )}
                {visibleAccessRequests.find(
                  (req) => req.status.status === 'Granted'
                ) &&
                  (isDesktop ? (
                    <AccessTable
                      shareRecords={visibleAccessRequests.filter(
                        (el) => el.status.status === 'Granted'
                      )}
                      onDeleteShare={onAccessRequestDenied}
                    />
                  ) : (
                    <AccessList
                      shareRecords={visibleAccessRequests.filter(
                        (el) => el.status.status === 'Granted'
                      )}
                      onDetailsClick={onAccessRequestsDetailsClick}
                      onDelete={onAccessRequestDenied}
                    />
                  ))}
              </>
            ) : (
              <Typography variant="h6" className={classes.placeholderText}>
                Du har ingen forespørsler
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  user: sGetUser(state),
  accessRequests: sGetAccessRequests(state),
});
const mapDispatchToProps = (dispatch) => ({
  setAccessRequests: (accessRequests) =>
    dispatch(actionSetAccessRequests(accessRequests)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Details));
