import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import { sGetIsAuthenticated } from '../../store/reducers/auth';
import styles from './styles';
import Box from '../../components/atomic/Box';
import Image from '../../components/Image';
import BoligmappaLogoWithName from '../../assets/images/BoligmappaLogoWithName.svg';
import StatusImage from '../../assets/images/statusImage.png';
import Typography from '../../components/atomic/Typography';
import TextField from '../../components/atomic/TextField';
import Button from '../../components/atomic/Button';
import useDesktop from '../../hooks/useDesktop';
import { emailPattern, getErrorMessage } from '../../validation';
import { sendMail } from '../../api/mail';
import useSnackbar from '../../hooks/useSnackbar';

const RETRY_INTERVAL = 300000;

const HealthCheck = ({ isAuthenticated, classes }) => {
  const history = useHistory();
  const isDesktop = useDesktop();
  const { showSuccessMessage } = useSnackbar();
  const { control, handleSubmit, errors, reset } = useForm({
    mode: 'onTouched',
  });

  const onRetry = () => {
    if (isAuthenticated) {
      history.push('properties');
    } else {
      history.push('login');
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      onRetry();
    }, RETRY_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const onSend = async (formData, e) => {
    e.preventDefault();
    const [error, response] = await sendMail(formData.email, 'NOTIFY_LATER');
    if (!error && response) {
      showSuccessMessage('E-postadressen din er registert.');
      reset();
    }
  };

  return (
    <>
      {isDesktop ? (
        <>
          <Box className={classes.status_header}>
            <Image
              className={classes.logoImage}
              fallbackSrc={BoligmappaLogoWithName}
            />
          </Box>
          <Box className={classes.status_body}>
            <Box className={classes.status_body_container}>
              <Box>
                <Typography className={classes.main_header}>Oops!</Typography>
                <Typography className={classes.sub_header}>
                  Vi opplever svært stor pågang akkurat nå.
                </Typography>
                <Typography className={classes.sub_sub_header}>
                  Prøv på nytt om litt
                </Typography>
                <Box className={classes.email_form}>
                  <Typography className={classes.email_form_header}>
                    Hold meg oppdatert.
                  </Typography>
                  <Typography className={classes.email_form_sub_header}>
                    Vi sender deg en e-post når du kan logge inn.
                  </Typography>
                  <form
                    className={classes.email_section}
                    onSubmit={handleSubmit(onSend)}
                  >
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{ required: true, pattern: emailPattern }}
                      render={({ onChange, value, ...props }) => (
                        <TextField
                          required
                          value={value || ''}
                          label="E-postadresse"
                          error={!!errors.email}
                          InputProps={{
                            className: classes.email_address_name_input,
                          }}
                          InputLabelProps={{
                            className: classes.email_address_label,
                          }}
                          placeholder="Skriv inn e-postadressen din"
                          onChange={(e) => onChange(e.target.value)}
                          helperText={getErrorMessage(
                            errors,
                            'email',
                            'E-post'
                          )}
                          {...props}
                        />
                      )}
                    />
                    <Button
                      type="submit"
                      className={classes.send_button}
                      size="large"
                      variant="contained"
                      color="primary"
                    >
                      Send
                    </Button>
                  </form>
                </Box>
              </Box>
              <Box className={classes.status_body_image}>
                <Image
                  className={classes.logoImage}
                  fallbackSrc={StatusImage}
                />
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box className={classes.status_body_mobile}>
          <Box className={classes.status_body_image_mobile}>
            <Image
              width="100%"
              className={classes.logoImage}
              fallbackSrc={StatusImage}
            />
          </Box>
          <Box className={classes.status_body_container_mobile}>
            <Typography className={classes.main_header_mobile}>
              Oops!
            </Typography>
            <Typography className={classes.sub_header_mobile}>
              Vi opplever svært stor pågang akkurat nå.
            </Typography>
            <Typography className={classes.sub_sub_header_mobile}>
              Prøv på nytt om litt
            </Typography>
          </Box>
          <Box className={classes.email_form}>
            <Typography className={classes.email_form_header_mobile}>
              Hold meg oppdatert.
            </Typography>
            <Typography className={classes.email_form_sub_header_mobile}>
              Vi sender deg en e-post når du kan logge inn.
            </Typography>
            <form
              className={classes.email_section_mobile}
              onSubmit={handleSubmit(onSend)}
            >
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{ required: true, pattern: emailPattern }}
                render={({ onChange, value, ...props }) => (
                  <TextField
                    required
                    value={value || ''}
                    label="E-postadresse"
                    error={!!errors.email}
                    className={classes.email_address_name_mobile}
                    InputLabelProps={{
                      className: classes.email_address_label,
                    }}
                    placeholder="Skriv inn din e-postadresse"
                    onChange={(e) => onChange(e.target.value)}
                    helperText={getErrorMessage(errors, 'email', 'E-post')}
                    {...props}
                  />
                )}
              />
              <Button
                type="submit"
                className={classes.send_button_mobile}
                size="large"
                variant="contained"
                color="primary"
              >
                Send
              </Button>
            </form>
          </Box>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: sGetIsAuthenticated(state),
});

export default connect(mapStateToProps)(withStyles(styles)(HealthCheck));
