import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';

import styles from './style';
import ListItemBase from '../../BottomContextMenu/ListItem';
import Tooltip from '../../Tooltip';

const MenuListItem = forwardRef(
  (
    {
      classes,
      disabled,
      isDropDown = false,
      isDelete = false,
      showError = false,
      className,
      label,
      textClass,
      ...props
    },
    ref
  ) =>
    disabled && label ? (
      <Tooltip title={label} fontSize={13}>
        <ListItemBase
          ref={ref}
          className={clsx(
            className,
            classes.listItem,
            disabled && classes.disabled
          )}
          disabled={disabled}
          textClass={isDelete ? classes.disabledDelete : classes.text}
          {...props}
        />
      </Tooltip>
    ) : (
      <ListItemBase
        ref={ref}
        className={clsx(
          className,
          classes.listItem,
          disabled && classes.disabled
        )}
        disabled={disabled}
        textClass={isDelete || showError ? classes.delete : classes.text}
        isDropDown={isDropDown}
        {...props}
      />
    )
);

export default withStyles(styles)(MenuListItem);
