const styles = (theme) => ({
  dialogDesktop: {
    minWidth: '600px !important',
  },
  dialogPadding: {
    minWidth: '600px',
    minHeight: '536px',
    padding: '10px 73px 50px 73px',
  },
  dialogPaddingMobile: {
    padding: '10px 15px 0px 15px',
  },
  error: {
    marginBottom: '20px',
    color: theme.palette.primary.redDark,
  },
  actionButtons: {
    padding: '10px 0 10px 0',
    borderTop: '1px solid #dedfdf',
  },
  actionButtonsMobile: {
    padding: '15px 5px 30px 0',
    borderTop: '1px solid #dedfdf',
  },
  actionButtonsCenter: {
    justifyContent: 'center',
  },
});

export default styles;
