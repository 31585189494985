import React from 'react';
import { withStyles } from '@material-ui/core';

import Box from '../../../../atomic/Box';
import ArrowDownFilter from '../../../../../assets/icons/ArrowDownFilter';
import MultiSelectComboBox from '../../../../MultiSelectComboBox';
import useMenuState from '../../../../../hooks/useMenuState';

import styles from './style';

const HeaderCellItem = ({
  name,
  classes,
  filterable,
  getFilterOptions,
  onFiltersReset,
}) => {
  const { onMenuOpen, onMenuClose, onOptionMenuClose, open, anchorEl } =
    useMenuState();
  const getTitleBox = (titleName, handleOnMenuOpen, isFilterable) => {
    return (
      <Box
        onClick={isFilterable && handleOnMenuOpen}
        display="flex"
        alignItems="top"
        className={classes.headerBox}
      >
        <span>{titleName}</span>
        {isFilterable && (
          <Box>
            <ArrowDownFilter className={classes.arrow} />
          </Box>
        )}
      </Box>
    );
  };
  return (
    <>
      <Box display="flex" alignItems="top">
        {getTitleBox(name, onMenuOpen, filterable)}
      </Box>
      <MultiSelectComboBox
        anchorEl={anchorEl}
        isFiltering={filterable}
        menuOpen={open}
        onMenuClose={onOptionMenuClose}
        onFiltersReset={onFiltersReset}
        filterOptions={getFilterOptions}
        onMenuSave={onMenuClose}
      />
    </>
  );
};

export default withStyles(styles)(HeaderCellItem);
