import React from 'react';

const BuildingDocument = ({
  width = '1.2rem',
  height = '1.2rem',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.4375 6.83594V10.1875C11.4375 10.3516 11.3672 10.4922 11.2734 10.5859C11.1562 10.7031 11.0156 10.75 10.875 10.75H8.15625C8.0625 10.75 7.99219 10.7266 7.94531 10.6797C7.89844 10.6328 7.875 10.5625 7.875 10.4688V7.84375C7.875 7.77344 7.82812 7.70312 7.78125 7.65625C7.73438 7.60938 7.66406 7.5625 7.59375 7.5625H5.90625C5.8125 7.5625 5.74219 7.60938 5.69531 7.65625C5.64844 7.70312 5.625 7.77344 5.625 7.84375V10.4688C5.625 10.5625 5.57812 10.6328 5.53125 10.6797C5.48438 10.7266 5.41406 10.75 5.34375 10.75H2.625C2.46094 10.75 2.32031 10.7031 2.22656 10.5859C2.10938 10.4922 2.0625 10.3516 2.0625 10.1875V6.83594C2.0625 6.74219 2.08594 6.67188 2.15625 6.60156L6.5625 2.99219C6.60938 2.94531 6.67969 2.92188 6.75 2.92188C6.79688 2.92188 6.86719 2.94531 6.9375 2.99219L11.3438 6.60156C11.3906 6.67188 11.4375 6.74219 11.4375 6.83594ZM13.4062 5.40625L11.4375 3.78906V0.53125C11.4375 0.460938 11.3906 0.390625 11.3438 0.34375C11.2969 0.296875 11.2266 0.25 11.1562 0.25H9.84375C9.75 0.25 9.67969 0.296875 9.63281 0.34375C9.58594 0.390625 9.5625 0.460938 9.5625 0.53125V2.24219L7.47656 0.507812C7.24219 0.34375 7.00781 0.25 6.75 0.25C6.46875 0.25 6.23438 0.34375 6.04688 0.507812L0.09375 5.40625C0.046875 5.45312 0 5.52344 0 5.59375C0 5.6875 0.0234375 5.75781 0.0703125 5.80469L0.65625 6.53125C0.703125 6.60156 0.75 6.625 0.84375 6.625C0.914062 6.625 0.984375 6.60156 1.05469 6.55469L6.5625 2.03125C6.60938 1.98438 6.67969 1.96094 6.75 1.96094C6.79688 1.96094 6.86719 1.98438 6.9375 2.03125L12.4453 6.55469C12.4922 6.60156 12.5625 6.625 12.6562 6.625C12.7266 6.625 12.7969 6.60156 12.8438 6.53125L13.4297 5.80469C13.4766 5.75781 13.4766 5.6875 13.4766 5.59375C13.4766 5.52344 13.4531 5.45312 13.4062 5.40625Z"
      fill="#13142A"
    />
  </svg>
);

export default BuildingDocument;
