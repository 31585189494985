const styles = () => ({
  searchBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    position: 'relative',
  },
  search: {
    padding: '0 20px',
  },
  searchButton: {
    border: 'none',
    background: 'none',
  },
  hide: {
    opacity: 0,
    width: 0,
    zIndex: -1,
  },
  closeSearchButton: {
    position: 'absolute',
    top: 0,
    border: 'none',
    background: 'none',
    height: '100%',
    padding: 0,
    marginRight: '20px',
  },
});

export default styles;
