import React, { createElement } from 'react';
import { TableCell, TableRow } from '@material-ui/core';

import {
  getDocumentId,
  getDocumentName,
  getDocumentType,
  isDocumentImage,
  getFileThumbNail,
  getDocumentRooms,
  getDocumentEvents,
  isDocumentReadOnly,
  getDocumentFileType,
  getDocumentCompanyId,
  getDocumentCreatedDate,
  getDocumentOwnedByName,
  getDocumentCompanyName,
  getOriginalDocumentPath,
  getDocumentProfessionType,
  getTruncatedDocName,
} from '../../../../../utils/documents';
import Tooltip from '../../../../Tooltip';

import Image from '../../../../Image';
import Link from '../../../../atomic/Link';
import IconButton from '../../../../atomic/IconButton';
import AttachedEntity from '../../../../AttachedEntity';
import TextHighlighted from '../../../../TextHighlighted';
import { formattedDate } from '../../../../../utils/dates';
import LockCircled from '../../../../../assets/icons/LockCircled';
import ImgIcon from '../../../../../assets/icons/DocumentIcons/ImgIcon';
import MenuHorizontalIcon from '../../../../../assets/icons/MenuHorizontal';
import useDashboardLinkPrefix from '../../../../../hooks/useDashboardLinkPrefix';

const DocumentItem = ({
  document,
  searchQuery,
  actionsEnabled,
  onDocumentClick,
  showCompanyNameLink,
  showRoomsColumn = false,
  showEventsColumn = false,
  showProfessionColumn = true,
  showUploadedDateColumn = true,
  showUploaderNameColumn = true,
}) => {
  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const documentPath = `${dashboardLinkPrefix}/documents/${getDocumentId(
    document
  )}`;

  const renderIcon = () => {
    if (isDocumentImage(document)) {
      return (
        <Image
          fallbackComponent={<ImgIcon />}
          alt={getDocumentName(document)}
          src={getOriginalDocumentPath(document)}
          style={{
            width: '38px',
            height: '38px',
            objectFit: 'cover',
            borderRadius: '3px',
          }}
        />
      );
    }

    return createElement(
      getFileThumbNail(getDocumentFileType(document)) ||
        getFileThumbNail('default')
    );
  };

  return (
    <TableRow>
      <TableCell align="center" style={{ paddingRight: 0 }}>
        {renderIcon()}
      </TableCell>

      <TableCell align="center" style={{ paddingLeft: 0, paddingRight: 0 }}>
        {isDocumentReadOnly(document) && (
          <LockCircled
            width={18}
            height={18}
            style={{ position: 'relative', top: 2 }}
          />
        )}
      </TableCell>

      <TableCell
        style={{ paddingLeft: 0, maxWidth: '300px', wordBreak: 'break-all' }}
      >
        <Tooltip
          title={getDocumentName(document)}
          placement="top"
          fontSize={13}
        >
          <Link underline={false} inheritColor to={documentPath}>
            <TextHighlighted searchQuery={searchQuery}>
              {getTruncatedDocName(getDocumentName(document))}
            </TextHighlighted>
          </Link>
        </Tooltip>
      </TableCell>

      {showUploadedDateColumn && (
        <TableCell>
          <TextHighlighted searchQuery={searchQuery}>
            {formattedDate(getDocumentCreatedDate(document))}
          </TextHighlighted>
        </TableCell>
      )}

      {showUploaderNameColumn && (
        <TableCell>
          {getDocumentCompanyId(document) && showCompanyNameLink ? (
            <Link
              to={`${dashboardLinkPrefix}/company/${getDocumentCompanyId(
                document
              )}`}
            >
              <TextHighlighted searchQuery={searchQuery}>
                {getDocumentCompanyName(document)}
              </TextHighlighted>
            </Link>
          ) : (
            <TextHighlighted searchQuery={searchQuery}>
              {getDocumentOwnedByName(document) ||
                getDocumentCompanyName(document)}
            </TextHighlighted>
          )}
        </TableCell>
      )}

      <TableCell>
        <TextHighlighted searchQuery={searchQuery}>
          {getDocumentType(document)}
        </TextHighlighted>
      </TableCell>

      {showProfessionColumn && (
        <TableCell>
          <TextHighlighted searchQuery={searchQuery}>
            {getDocumentProfessionType(document)}
          </TextHighlighted>
        </TableCell>
      )}

      {showEventsColumn && (
        <TableCell>
          <AttachedEntity
            type="event"
            document={document}
            editable={actionsEnabled}
            entities={getDocumentEvents(document)}
          />
        </TableCell>
      )}

      {showRoomsColumn && (
        <TableCell>
          <AttachedEntity
            type="room"
            document={document}
            editable={actionsEnabled}
            entities={getDocumentRooms(document)}
          />
        </TableCell>
      )}

      <TableCell>
        {actionsEnabled && (
          <IconButton
            size="small"
            onClick={(event) => onDocumentClick(event, document)}
          >
            <MenuHorizontalIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default DocumentItem;
