const style = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  button: {
    backgroundColor: theme.palette.primary.white,
    border: '1.5px solid',
    borderColor: theme.palette.primary.mint,
    boxShadow: 'none',
    margin: '0 0 0 4px',
    minHeight: '28px',
    '@media only screen and (max-width: 400px)': {
      height: '40px',
      fontSize: '13px',
    },
  },

  eventButton: {
    margin: '0 4px 0 0',
    minHeight: '28px',
    '@media only screen and (max-width: 400px)': {
      height: '40px',
      fontSize: '13px',
    },
  },

  text: {
    fontSize: '20px',
    fontWeight: '600',
    margin: '0',
    '@media only screen and (max-width: 1170px)': {
      fontSize: '18px',
    },
  },

  subText: {
    fontSize: '18px',
    marginTop: '5px',
    marginBottom: '0',
    '@media only screen and (max-width: 1170px)': {
      fontSize: '14px',
      marginTop: '5px',
      marginBottom: '24px',
    },
  },

  titleRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    padding: '19px 25.5px 17px 16px',
  },

  closeButton: {
    padding: 0,
    border: 0,
    background: 'unset',
  },
});

export default style;
