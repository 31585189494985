import axios from 'axios';
import pick from 'lodash-es/pick';

/**
 * Taken from API contract described in confluence:
 * Create: https://boligmappa.atlassian.net/wiki/spaces/BMP/pages/757301291/POST+events
 * Update: https://boligmappa.atlassian.net/wiki/spaces/BMP/pages/757104697/PATCH+events+id
 * @type {string[]}
 */
export const eventFields = [
  'boligmappaNumber',
  'title',
  'description',
  'type',
  'subType',
  'dateStart',
  'dateEnd',
  'createdBy',
  'totalValue',
  'associations',
  // array values
  'roomIds',
  'imageIds',
  'companyIds',
  'documentIds',
];

export const eventRequestDataTransformer = [
  (data) => pick(data, eventFields),
  ...axios.defaults.transformRequest,
];
