const styles = (theme) => ({
  tabIndicator: {
    display: 'none',
  },
  tab: {
    fontWeight: 600,
    textTransform: 'none',
    backgroundColor: '#D9E9E9',
    borderRadius: 50,
    height: '25px',
    '&.Mui-selected': {
      backgroundColor: 'white',
      color: 'black',
      WebkitTransition: 'background-color 1000ms linear',
      MSTransition: 'background-color 1000ms linear',
      transition: 'background-color 1000ms linear',
    },
  },
  tabWrapper: {
    backgroundColor: '#D9E9E9',
    padding: '6px 10px 6px 10px',
    borderRadius: 50,
  },
  tabContainer: {
    zIndex: '1',
    position: 'sticky',
    backgroundColor: '#fff',
    marginBottom: theme.spacing(-1),
    margin: '0 40px 10px 40px',
  },
});

export default styles;
