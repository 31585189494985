import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import GridItem from '../GridItem';
import ContentLoader from '../loaders/ContentLoader';

const GridItemLoading = ({ classes, showLabelLoader = true, ...props }) => {
  const label = <ContentLoader width="110%" height={25} margin="9px" />;

  return (
    <GridItem label={showLabelLoader && label} {...props}>
      <ContentLoader className={classes.contentLoader} />
    </GridItem>
  );
};

export default withStyles(styles)(GridItemLoading);
