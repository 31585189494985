import React from 'react';

const HouseIcon = ({ width = 16, height = 14 }) => (
  <svg
    viewBox="0 0 16 14"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <path
      d="M8.38086 0.777344L15.084 6.46484C15.3379 6.69336 15.3633 7.07422 15.1602 7.32812C14.9316 7.58203 14.5508 7.60742 14.2969 7.4043L13.6875 6.87109V11.5938C13.6875 12.7363 12.7734 13.625 11.6562 13.625H4.34375C3.20117 13.625 2.3125 12.7363 2.3125 11.5938V6.87109L1.67773 7.4043C1.42383 7.60742 1.04297 7.58203 0.814453 7.32812C0.611328 7.07422 0.636719 6.69336 0.890625 6.46484L7.59375 0.777344C7.82227 0.599609 8.15234 0.599609 8.38086 0.777344ZM3.53125 11.5938C3.53125 12.0508 3.88672 12.4062 4.34375 12.4062H5.5625V8.54688C5.5625 7.98828 5.99414 7.53125 6.57812 7.53125H9.42188C9.98047 7.53125 10.4375 7.98828 10.4375 8.54688V12.4062H11.6562C12.0879 12.4062 12.4688 12.0508 12.4688 11.5938V5.83008L8 2.04688L3.53125 5.83008V11.5938ZM6.78125 12.4062H9.21875V8.75H6.78125V12.4062Z"
      fill="#164144"
    />
  </svg>
);

export default HouseIcon;
