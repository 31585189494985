import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import {
  getRoomType,
  getRoomName,
  getRoomFloor,
  getFloorName,
  getFloorId,
  getRoomTypeId,
  getRoomEvents,
  getRoomDocuments,
  getRoomTypeOptions,
  getFloorOptions,
  getRoomDescription,
  getFloor,
  getDefaultFloor,
  roomModes,
} from '../../../utils/rooms';

import {
  getDocumentId,
  getDocumentName,
  sortDocumentOptions,
} from '../../../utils/documents';

import {
  fetchEvents as apiFetchEvents,
  fetchDocuments as apiFetchDocuments,
} from '../../../api/properties';

import {
  acSetDocuments,
  acSetFetchingDocuments,
} from '../../../store/actions/documents';

import {
  acSetEvents,
  acSetFetchingEvents,
} from '../../../store/actions/events';

import styles from './style';
import TextArea from '../../TextArea';
import Box from '../../atomic/Box';
import Autocomplete from '../../Autocomplete';
import TextField from '../../atomic/TextField';
import DocumentOptionList from '../../DocumentOptionList';
import useFetchEffect from '../../../hooks/useFetchEffect';
import { sGetEvents, sGetFetchingEvents } from '../../../store/reducers/events';
import {
  getEventId,
  getEventName,
  sortEventOptions,
} from '../../../utils/events';
import {
  sGetRoomMode,
  sGetRoomAttachedIds,
} from '../../../store/reducers/rooms';
import { acSetRoomAttachedIds } from '../../../store/actions/rooms';
import { sGetRoomTypes } from '../../../store/reducers/roomTypes';
import { acSetRoomTypes } from '../../../store/actions/roomTypes';
import {
  sGetDocuments,
  sGetFetchingDocuments,
} from '../../../store/reducers/documents';
import { trim } from '../../../utils/strings';
import { fetchRoomTypes as apiFetchRoomTypes } from '../../../api/rooms';

import floors from '../../../utils/floors';
import { isFloorEnabledProperty } from '../../../utils/properties';
import DialogForm from '../BaseDialogForm';
import Form from '../BaseForm';
import Typography from '../../atomic/Typography';
import RoomImages from '../../../modules/RoomImages';
import matomo from '../../../utils/matomo';

const descriptionMaxLength = 1500; // in characters

const prepareRoomData = ({
  type,
  title,
  typeId,
  events,
  floorName,
  eventIds,
  documents,
  description,
  documentIds,
  floor,
}) => ({
  roomType: type,
  roomTypeId: typeId,
  floorNo: floor ? trim(floor.label) : null,
  floorName: trim(floorName),
  title: trim(title || type),
  description: trim(description),
  events: (events || []).filter((event) =>
    eventIds
      .map(({ value }) => String(value))
      .includes(String(getEventId(event)))
  ),
  documents: (documents || []).filter((document) =>
    documentIds
      .map(({ value }) => String(value))
      .includes(String(getDocumentId(document)))
  ),
  eventIds: eventIds.map(({ value }) => value),
  documentIds: documentIds.map(({ value }) => value),
});

export const RoomForm = ({
  room,
  isEdit = false,
  open,
  header,
  onSubmit,
  onClose,
  error,
  fetching,
  requestInProgress,
  boligmappaNumber,
  propertyType,
  classes,
  events,
  documents,
  roomTypes,
  roomMode,
  setEvents,
  setDocuments,
  setFetchingEvents,
  setFetchingDocuments,
  setRoomTypes,
  isDesktop = false,
  isDialog = true,
  onRoomImageUpload,
  onRoomImageDelete,
  disableEventSelection = false,
  disableDocumentSelection = false,
  submitButtonText,
  isImageUpdate = false,
  showLargeSubmitButton,
  centeredActions,
  actionButtonsClassName,
  setRoomAttachedIds,
  attachedIds,
  fetchingEvents,
  onRoomFormOpen = () => {},
}) => {
  const [floorValue, setFloorValue] = useState(false); // trigger edit floors in floors disabled properties
  const [title, setTitle] = useState('');
  const [type, setType] = useState(null);
  const [typeId, setTypeId] = useState(null);
  const [floorNo, setFloorNo] = useState(getDefaultFloor(floorValue));
  const [floorName, setFloorName] = useState(null);
  const [floorId, setFloorId] = useState(null);
  const [description, setDescription] = useState('');
  const [eventIds, setEventIds] = useState([]);
  const [documentIds, setDocumentIds] = useState([]);
  const [floorVisibility, setFloorVisibility] = useState(false);

  const { documentIds: queryDocumentIds } = attachedIds;

  const { fetching: fetchingRoomTypes, fetchingError: fetchingRoomTypesError } =
    useFetchEffect({
      initialFetchingState: !roomTypes,
      apiFetchFunction: apiFetchRoomTypes,
      conditionFunction: () => !roomTypes,
      onSuccess: (data) => setRoomTypes(data),
    });

  const { fetching: fetchingDocuments } = useFetchEffect({
    dependencies: [open],
    initialFetchingState: !documents,
    conditionFunction: () => !documents && !fetchingDocuments && open,
    onSuccess: (data) => setDocuments(data),
    setFetchingFunction: setFetchingDocuments,
    apiFetchFunction: () =>
      apiFetchDocuments(boligmappaNumber, { includeTaggedInfo: true }),
  });

  useFetchEffect({
    dependencies: [open],
    initialFetchingState: !events,
    conditionFunction: () => !events && !fetchingEvents && open,
    onSuccess: (data) => setEvents(data),
    setFetchingFunction: setFetchingEvents,
    apiFetchFunction: () => apiFetchEvents(boligmappaNumber),
  });

  const roomType =
    type != null && typeId != null ? { label: type, value: typeId } : null;

  const floor =
    floorId != null && floorNo != null
      ? { label: floorNo, value: floorId }
      : getFloor(floorValue);

  const roomTypeOptions = fetchingRoomTypesError
    ? []
    : getRoomTypeOptions(roomTypes);

  const floorOptions = getFloorOptions(floors);

  const onRoomTypeChange = (option) => {
    if (option) {
      setType(option.label);
      setTypeId(option.value);
    } else {
      setType(null);
      setTypeId(null);
    }
  };

  const onFloorChange = (option) => {
    if (option) {
      setFloorName(option.label);
      setFloorNo(option.label);
      setFloorId(option.value);
    } else if (floorValue) {
      setFloorNo('1. etasje');
      setFloorId('1');
    } else {
      setFloorNo(null);
      setFloorId(null);
    }
  };

  const onChangeDocuments = (options) => setDocumentIds(options || []);

  const onChangeEvents = (options) => {
    setEventIds(options || []);
  };

  const resetFields = () => {
    setTitle(getRoomName(room) || '');
    setType(getRoomType(room) || null);
    setTypeId(getRoomTypeId(room) || null);
    setFloorNo(getRoomFloor(room) || getDefaultFloor(floorValue));
    setFloorName(getFloorName(room) || '');
    setFloorId(getFloorId(room) || '1');
    setDescription(getRoomDescription(room) || '');

    setEventIds(
      (getRoomEvents(room) || []).map((event) => ({
        value: getEventId(event),
        label: getEventName(event),
      }))
    );

    setDocumentIds(
      (getRoomDocuments(room) || []).map((document) => ({
        value: getDocumentId(document),
        label: getDocumentName(document),
      }))
    );
  };

  useEffect(() => {
    if (room && !isImageUpdate) {
      resetFields();
    }
  }, [room]);

  const handleClose = () => {
    onClose();
    resetFields();
  };

  const onFormSubmit = () => {
    onSubmit(
      prepareRoomData({
        type,
        title,
        typeId,
        events,
        floorName,
        eventIds,
        documents,
        description,
        documentIds,
        propertyType,
        floor,
      }),
      resetFields
    );
    setRoomAttachedIds({
      roomId: [],
      documentIds: [],
    });

    matomo.clickEvent({
      category: 'Rooms',
      action: 'Create New Room',
    });
  };

  useEffect(() => {
    onRoomFormOpen(
      prepareRoomData({
        type,
        title,
        typeId,
        events,
        floorName,
        eventIds,
        documents,
        description,
        documentIds,
        propertyType,
        floor,
      })
    );
  }, []);

  useEffect(() => {
    if (propertyType)
      setFloorVisibility(isFloorEnabledProperty(propertyType) || isEdit);
    setFloorValue(isFloorEnabledProperty(propertyType));
  }, [propertyType]);

  useEffect(() => {
    if (
      Array.isArray(documents) &&
      Array.isArray(queryDocumentIds) &&
      queryDocumentIds.length > 0
    ) {
      setDocumentIds([
        ...documents
          .filter((document) =>
            queryDocumentIds.includes(String(getDocumentId(document)))
          )
          .map((document) => ({
            value: getDocumentId(document),
            label: getDocumentName(document),
          })),
        ...documentIds,
      ]);
    } else {
      setDocumentIds(
        (getRoomDocuments(room) || []).map((document) => ({
          value: getDocumentId(document),
          label: getDocumentName(document),
        }))
      );
    }
  }, [documents, open]);

  const renderContent = () => (
    <>
      {!isEdit && (
        <Box className={classes.eventImagesBox}>
          <RoomImages
            editAccess={false}
            fetching={false}
            room={room}
            isManualUpload
            onRoomImageUpload={onRoomImageUpload}
            onRoomImageDelete={onRoomImageDelete}
            isGalleryModeActive={false}
          />
        </Box>
      )}
      {roomMode === roomModes.event && (
        <Box pt={4} pb={4}>
          <Autocomplete
            isMulti
            autoFocus
            value={eventIds}
            options={sortEventOptions(events)}
            closeMenuOnSelect={false}
            isLoading={fetchingEvents}
            label="Koble til hendelse"
            disabled={requestInProgress}
            className={classes.formElement}
            loadingMessage={() => 'Laster...'}
            noOptionsMessage={() => 'Ingen hendelser'}
            placeholder="Velg en eller flere hendelser"
            onChange={(options) => onChangeEvents(options)}
            maxMenuHeight={isDesktop ? 80 : null}
          />
        </Box>
      )}

      {roomMode === roomModes.document && (
        <Box pt={4} pb={4}>
          <DocumentOptionList
            autoFocus
            value={documentIds}
            options={sortDocumentOptions(documents)}
            disabled={requestInProgress}
            isLoading={fetchingDocuments}
            className={classes.formElement}
            onChange={(options) => onChangeDocuments(options)}
            maxMenuHeight={isDesktop ? 110 : null}
          />
        </Box>
      )}

      {roomMode === roomModes.room && (
        <>
          <Autocomplete
            required
            label="Romtype*"
            value={roomType}
            options={roomTypeOptions}
            placeholder="Velg romtype"
            onChange={onRoomTypeChange}
            disabled={requestInProgress}
            isLoading={fetchingRoomTypes}
            className={classes.formElement}
            loadingMessage={() => 'Laster...'}
            noOptionsMessage={() => 'Ingen resultater'}
          />
          <TextField
            value={title}
            label="Gi rommet et navn"
            className={classes.formElement}
            disabled={fetching || requestInProgress}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="F eks. Gjestebad, Hovedsoverom, etc."
          />

          {floorVisibility && (
            <>
              <Autocomplete
                label="Etasje"
                value={floor}
                options={floorOptions}
                placeholder="Velg etasje"
                onChange={onFloorChange}
                disabled={requestInProgress}
                loadingMessage={() => 'Laster...'}
                noOptionsMessage={() => 'Ingen resultater'}
                isSearchable={false}
                isClearable={false}
              />
              <Typography className={classes.dropdown_support_text}>
                Hvilken etasje i boligen dette rommet hører til.
              </Typography>
            </>
          )}

          <TextArea
            label="Nyttig informasjon"
            value={description}
            className={classes.formElement}
            maxLength={descriptionMaxLength}
            disabled={fetching || requestInProgress}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Skriv inn nyttig informasjon om rommet som f eks. fargekoder, bruttoareal, med mer..."
          />

          {!disableDocumentSelection &&
            Array.isArray(documents) &&
            documents.length > 0 && (
              <DocumentOptionList
                value={documentIds}
                options={sortDocumentOptions(documents)}
                disabled={requestInProgress}
                isLoading={fetchingDocuments}
                className={classes.formElement}
                onChange={(options) => onChangeDocuments(options)}
                maxMenuHeight={isDesktop ? 140 : null}
              />
            )}

          {!disableEventSelection &&
            Array.isArray(events) &&
            events.length > 0 && (
              <Autocomplete
                isMulti
                value={eventIds}
                options={sortEventOptions(events)}
                closeMenuOnSelect={false}
                isLoading={fetchingEvents}
                label="Koble til hendelse"
                disabled={requestInProgress}
                className={classes.formElement}
                loadingMessage={() => 'Laster...'}
                noOptionsMessage={() => 'Ingen hendelser'}
                placeholder="Velg en eller flere hendelser"
                onChange={(options) => onChangeEvents(options)}
                maxMenuHeight={isDesktop ? 60 : null}
              />
            )}
        </>
      )}
    </>
  );

  return isDialog ? (
    <DialogForm
      open={open}
      onClose={handleClose}
      onSubmit={onFormSubmit}
      requestInProgress={requestInProgress}
      error={error}
      header={header}
      showCancelButton={false}
      showLargeSubmitButton
      submitButtonDisabled={!roomType && !roomType}
    >
      {renderContent()}
    </DialogForm>
  ) : (
    <Form
      fetching={fetching}
      onSubmit={onFormSubmit}
      error={error}
      showCancelButton={false}
      requestInProgress={requestInProgress}
      formBodyClassName={isDesktop ? classes.roomForm : classes.roomFormMobile}
      submitButtonText={submitButtonText}
      showLargeSubmitButton={showLargeSubmitButton}
      centeredActions={centeredActions}
      actionButtonsClassName={actionButtonsClassName}
      isBodyOverflow
    >
      {renderContent()}
    </Form>
  );
};

const mapStateToProps = (state) => ({
  events: sGetEvents(state),
  documents: sGetDocuments(state),
  roomTypes: sGetRoomTypes(state),
  roomMode: sGetRoomMode(state),
  fetchingDocuments: sGetFetchingDocuments(state),
  attachedIds: sGetRoomAttachedIds(state),
  fetchingEvents: sGetFetchingEvents(state),
});

const mapDispatchToProps = (dispatch) => ({
  setFetchingDocuments: (fetching) =>
    dispatch(acSetFetchingDocuments(fetching)),
  setEvents: (events) => dispatch(acSetEvents(events)),
  setDocuments: (documents) => dispatch(acSetDocuments(documents)),
  setRoomTypes: (roomTypes) => dispatch(acSetRoomTypes(roomTypes)),
  setFetchingEvents: (fetching) => dispatch(acSetFetchingEvents(fetching)),
  setRoomAttachedIds: (payload) => dispatch(acSetRoomAttachedIds(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RoomForm));
