import { trim } from '../../../utils/strings';
import { getRoomId } from '../../../utils/rooms';
import { getEventId } from '../../../utils/events';
import { undefinedDocumentType } from './documentTypes';

export const prepareDocumentData = ({
  title,
  rooms,
  events,
  roomIds,
  eventIds,
  documentType,
  ...data
}) => ({
  ...data,
  title: trim(title),
  documentType:
    documentType && documentType.label
      ? trim(documentType.label)
      : undefinedDocumentType.name,
  documentTypeId:
    documentType && documentType.value
      ? documentType.value
      : undefinedDocumentType.id,
  rooms: (rooms || []).filter((room) =>
    roomIds.map(({ value }) => String(value)).includes(String(getRoomId(room)))
  ),
  events: (events || []).filter((event) =>
    eventIds
      .map(({ value }) => String(value))
      .includes(String(getEventId(event)))
  ),
  roomIds: (roomIds || []).map(({ value }) => value),
  eventIds: (eventIds || []).map(({ value }) => value),
  // required for legacy app
  applicationName: 'BOLIG_PWA',
});
