import React, { useState, useEffect, useContext, useRef } from 'react';
import { withStyles } from '@material-ui/core';
import { debounce } from 'lodash';

import Table from './Table';
import Box from '../../atomic/Box';
import TableLoading from '../../desktop/loaders/TableLoading';
import DocumentActionMenu from './DocumentActionMenu';
import { FileUploadContext } from '../../../contexts/fileUpload';

import {
  getDefaultDocumentSortType,
  getDocumentId,
  getDocumentSortType,
  getFilteredDocuments,
} from '../../../utils/documents';
import { areIdsEqual } from '../../../utils/strings';
import useDocumentFilters from '../../../hooks/useDocumentFilters';

import styles from './style';
import useDebounce from '../../../hooks/useDebounce';
import matomo from '../../../utils/matomo';

const DocumentViewTable = ({
  classes,
  fetching,
  documents = [],
  onFolderChange,
  documentFolders,
  directoryId,
  setDirectoryId,
  directoryTree,
  setDirectoryTree,
  searchQuery,
  onDocumentDeleted,
  publicSection = false,
  actionsEnabled = true,
  showBulkSelectionOptions,
  isPersonalDocuments = false,
  isFromPersonal,
  isBoligmappaPlusUser = false,
  onFolderDelete,
}) => {
  const { filters, onFiltersChanged } = useDocumentFilters(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentDocumentId, setCurrentDocumentId] = useState(null);
  const [sortType, setSortType] = useState(getDefaultDocumentSortType());
  const [sortingDirection, setSortingDirection] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const debouncedDocuments = useDebounce(searchQuery, 1000);

  const [scrollPosition, setScrollPosition] = useState(0);

  const tableContainerRef = useRef(null);

  const { files } = useContext(FileUploadContext);

  const currentDocument = (documents || [])
    .concat(documentFolders)
    .find((document) =>
      areIdsEqual(getDocumentId(document), currentDocumentId)
    );

  useEffect(() => {
    if (files.length > 0) {
      setUploadedFiles(files);
      window.scrollTo(0, 0);
    }
  }, [files]);

  const filteredDocuments = getFilteredDocuments({
    filters,
    documents,
    documentFolders,
    searchQuery,
    shouldSort: true,
    sortFunction: sortType.sortFunction,
    isPersonalDocuments,
  });

  useEffect(() => {
    if (searchQuery) {
      matomo.clickEvent({
        category: 'Document handling',
        action: 'Search for Document',
        name: 'Search for Document',
        value: filteredDocuments?.length,
      });
    }
  }, [debouncedDocuments]);

  const onDocumentClick = (event, document) => {
    event.persist();
    setCurrentDocumentId(getDocumentId(document));
    setAnchorEl(event.currentTarget);
  };

  const onSortTypeChange = (newSortType) => {
    setSortType(getDocumentSortType(newSortType));
  };

  const toggleContextMenu = (open) => {
    if (!open) {
      setAnchorEl(null);
      setCurrentDocumentId(null);
    }
  };

  const handleScroll = (e) => {
    setScrollPosition(e.target.scrollTop);
  };

  const debouncedHandleScroll = debounce(handleScroll, 1000);

  useEffect(() => {
    const savedScrollPosition = localStorage.getItem('scrollPosition');
    const savedSortType = JSON.parse(localStorage.getItem('sortType'));

    if (savedSortType !== null) {
      setSortType(getDocumentSortType(savedSortType.id));
      setSortingDirection(savedSortType.id);
    }
    if (savedScrollPosition !== null) {
      setScrollPosition(parseInt(savedScrollPosition, 10));
    }
    if (tableContainerRef.current !== null) {
      tableContainerRef.current.scrollTop = parseInt(savedScrollPosition, 10);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('sortType', JSON.stringify(sortType));
  }, [sortType]);

  useEffect(() => {
    return () => {
      localStorage.setItem('scrollPosition', scrollPosition);
    };
  }, [scrollPosition]);

  if (fetching) {
    return (
      <Box pt={2}>
        <TableLoading rows={5} columns={4} />
      </Box>
    );
  }
  return (
    <Box className={classes.root}>
      <Table
        filters={filters}
        sortType={sortType}
        documents={documents}
        onFolderChange={onFolderChange}
        directoryId={directoryId}
        setDirectoryId={setDirectoryId}
        directoryTree={directoryTree}
        setDirectoryTree={setDirectoryTree}
        searchQuery={searchQuery}
        publicSection={publicSection}
        actionsEnabled={actionsEnabled}
        debouncedHandleScroll={debouncedHandleScroll}
        tableContainerRef={tableContainerRef}
        onDocumentClick={onDocumentClick}
        onFiltersChanged={onFiltersChanged}
        onSortTypeChange={onSortTypeChange}
        filteredDocuments={filteredDocuments}
        sortingDirection={sortingDirection}
        setSortingDirection={setSortingDirection}
        showBulkSelectionOptions={showBulkSelectionOptions}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        isPersonalDocumentView={isPersonalDocuments}
        isFromPersonal={isFromPersonal}
        isBoligmappaPlusUser={isBoligmappaPlusUser}
      />

      <DocumentActionMenu
        full={false}
        parentAnchorEl={anchorEl}
        document={currentDocument}
        setOpenParentPop={toggleContextMenu}
        onDocumentDeleted={onDocumentDeleted}
        openParentPop={!!anchorEl && !!currentDocument}
        documentId={getDocumentId(currentDocument)}
        isPersonalDocuments={isPersonalDocuments}
        isFromPersonal={isFromPersonal}
        isBoligmappaPlusUser={isBoligmappaPlusUser}
        onFolderDelete={onFolderDelete}
        onFolderChange={onFolderChange}
      />
    </Box>
  );
};

export default withStyles(styles)(DocumentViewTable);
