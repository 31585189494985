import React from 'react';

const ArrowForward = ({
  fill = 'currentColor',
  strokeWidth = 2,
  width = '100%',
  height = '100%',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 6.92 11.011"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="none"
      stroke={fill}
      strokeLinecap="round"
      strokeWidth={strokeWidth}
      d="M125,312.932l4.091,4.091L125,321.115"
      transform="translate(-123.586 -311.518)"
    />
  </svg>
);

export default ArrowForward;
