import { useEffect } from 'react';
import { fetchCommitmentsFromApi } from '../properties';
import { useApiFetch } from './useApiFetch';

export function useFetchCommitments(property) {
  const commitmentsApi = useApiFetch(fetchCommitmentsFromApi);

  useEffect(() => {
    if (!property?.boligmappaNumber) return;

    const { boligmappaNumber } = property;
    commitmentsApi.executeFetch(boligmappaNumber);
  }, [property?.boligmappaNumber]);

  return {
    data: commitmentsApi.data,
    error: commitmentsApi.error,
    executeFetch: commitmentsApi.executeFetch,
    isLoading: commitmentsApi.isLoading,
  };
}
