const styles = (theme) => ({
  root: {
    display: 'flex',
    borderRadius: '3px',
    alignItems: 'center',
    padding: '3px 3px 3px 6px',
    marginRight: theme.spacing(0.5),
    color: theme.palette.primary.white,
    background: theme.palette.primary.green,
  },
  label: {
    marginRight: theme.spacing(0.5),
  },
  closeIcon: {
    color: theme.palette.primary.white,
  },
});

export default styles;
