const styles = (theme) => ({
  container: {
    maxWidth: '400px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  containerPaddingTop: {
    paddingTop: '25%',
  },
  customMessagePaddingTop: {
    paddingTop: '20%',
  },
  containerPaddings: {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '16px',
  },
  alignLeft: {
    textAlign: 'left',
  },
  alignCenter: {
    textAlign: 'center',
  },
  logo: {
    width: '70px',
    height: '70px',
    marginBottom: '10px',
  },
  connectionErrorIcon: {
    marginBottom: theme.spacing(2),
  },
  textContainer: {
    lineHeight: '1.3',
    margin: '15px auto',
  },
  helpText: {
    lineHeight: 2,
    margin: '15px 0',
  },
  sendErrorCodeButton: {
    height: '50px',
    margin: '10px 0 20px',

    [theme.breakpoints.down('sm')]: {
      width: '200px',
    },
  },
});

export default styles;
