import React, { useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Button from '../../../atomic/Button';
import Typography from '../../../atomic/Typography';
import FileUploadInput from '../../../FileUploadInput';
import useSnackbar from '../../../../hooks/useSnackbar';
import { manualUploadImages, uploadImages } from '../../../../utils/images';
import { inputImagesAccept } from '../../../../utils/files';
import useActionDialog from '../../../../hooks/useActionDialog';
import PhotoCamera from '../../../../assets/icons/PhotoCamera';

const UploadImageButton = ({
  classes,
  className,
  onImagesUploaded,
  lastImagePosition,
  showButton = true,
  uploadImageRequest,
  isManualUpload = false,
  imageRef: passedImageRef,
  setImageRef: passedSetImageRef,
}) => {
  const { showErrorMessage, showSuccessMessage } = useSnackbar();
  const { showProgressDialog, showLoadingDialog } = useActionDialog();

  const [imageRef, setImageRef] = useState(null);

  const openImageUploadDialog = () =>
    passedImageRef ? passedImageRef.click() : imageRef.click();

  const onImagesSelect = async (event) => {
    const imageFiles = event.target.files;

    event.persist();

    if (imageFiles.length > 0) {
      const closeDialog = showLoadingDialog();
      if (!isManualUpload) {
        uploadImages({
          imageFiles,
          positionStart: lastImagePosition,
          uploadRequest: uploadImageRequest,
          onComplete: (uploadedImages) => {
            onImagesUploaded(uploadedImages);

            closeDialog();

            if (Array.isArray(uploadedImages) && uploadedImages.length > 0) {
              showSuccessMessage();
            }
          },
          onError: (error) => showErrorMessage(error),
          onImageUploaded: (imagesUploaded) =>
            showProgressDialog((imagesUploaded / imageFiles.length) * 100),
        });
      } else {
        manualUploadImages({
          imageFiles,
          positionStart: lastImagePosition,
          onComplete: (uploadedImages) => {
            onImagesUploaded(uploadedImages, imageFiles);
            closeDialog();
            if (Array.isArray(uploadedImages) && uploadedImages.length > 0) {
              showSuccessMessage();
            }
          },
          onError: (error) => showErrorMessage(error),
          onImageUploaded: (imagesUploaded) =>
            showProgressDialog((imagesUploaded / imageFiles.length) * 100),
        });
      }
    }
  };

  return (
    <>
      {showButton && (
        <Button
          onClick={openImageUploadDialog}
          className={clsx(className, classes.container)}
        >
          <div className={classes.content}>
            <PhotoCamera className={classes.imageIcon} />
            <br />
            <Typography color="primary">Legg til bilde</Typography>
          </div>
        </Button>
      )}

      <FileUploadInput
        multiple
        onChange={onImagesSelect}
        accept={inputImagesAccept}
        ref={(ref) =>
          passedSetImageRef ? passedSetImageRef(ref) : setImageRef(ref)
        }
      />
    </>
  );
};

export default withStyles(styles)(UploadImageButton);
