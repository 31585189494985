// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./assets/fonts/sofia_pro/light.otf";
import ___CSS_LOADER_URL_IMPORT_1___ from "./assets/fonts/sofia_pro/regular.otf";
import ___CSS_LOADER_URL_IMPORT_2___ from "./assets/fonts/sofia_pro/semi_bold.otf";
import ___CSS_LOADER_URL_IMPORT_3___ from "./assets/fonts/sofia_pro/bold.otf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-weight: 300;\n  font-style: normal;\n  font-family: 'Sofia Pro';\n  src: local('Sofia Pro'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('opentype');\n}\n\n@font-face {\n  font-weight: 500;\n  font-style: normal;\n  font-family: 'Sofia Pro';\n  src: local('Sofia Pro'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('opentype');\n}\n\n@font-face {\n  font-weight: 700;\n  font-style: normal;\n  font-family: 'Sofia Pro';\n  src: local('Sofia Pro'), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('opentype');\n}\n\n@font-face {\n  font-weight: 800;\n  font-style: normal;\n  font-family: 'Sofia Pro';\n  src: local('Sofia Pro'), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('opentype');\n}\n", "",{"version":3,"sources":["webpack://src/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,wBAAwB;EACxB,mFAAmF;AACrF;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,wBAAwB;EACxB,mFAAqF;AACvF;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,wBAAwB;EACxB,mFAAuF;AACzF;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,wBAAwB;EACxB,mFAAkF;AACpF","sourcesContent":["@font-face {\n  font-weight: 300;\n  font-style: normal;\n  font-family: 'Sofia Pro';\n  src: local('Sofia Pro'), url(./assets/fonts/sofia_pro/light.otf) format('opentype');\n}\n\n@font-face {\n  font-weight: 500;\n  font-style: normal;\n  font-family: 'Sofia Pro';\n  src: local('Sofia Pro'), url(./assets/fonts/sofia_pro/regular.otf) format('opentype');\n}\n\n@font-face {\n  font-weight: 700;\n  font-style: normal;\n  font-family: 'Sofia Pro';\n  src: local('Sofia Pro'), url(./assets/fonts/sofia_pro/semi_bold.otf) format('opentype');\n}\n\n@font-face {\n  font-weight: 800;\n  font-style: normal;\n  font-family: 'Sofia Pro';\n  src: local('Sofia Pro'), url(./assets/fonts/sofia_pro/bold.otf) format('opentype');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
