import React from 'react';
import { withStyles } from '@material-ui/core';
import style from './style';
import useDesktop from '../../hooks/useDesktop';
import Typography from '../atomic/Typography';
import Image from '../Image/index';

const PropertySalesLawInfoCard = ({
  classes,
  cardTitle,
  cardMobileTutle,
  infoText,
  cardImage,
}) => {
  const isDesktop = useDesktop();

  const information = (
    <Typography
      className={isDesktop ? classes.introContent : classes.introContentMobile}
    >
      {infoText}
    </Typography>
  );

  const SalesLawImageForCard = (
    <div
      className={
        isDesktop ? classes.imageContainer : classes.mobileImageContainer
      }
    >
      <Image width="100%" className={classes.image} fallbackSrc={cardImage} />
    </div>
  );

  return (
    <div>
      {isDesktop && (
        <div className={classes.title}>
          <Typography mt={0} variant="h2" className={classes.titleText}>
            {cardTitle}
          </Typography>
        </div>
      )}
      {!isDesktop && (
        <Typography noMargins variant="h2">
          {cardMobileTutle}
        </Typography>
      )}

      {!isDesktop && SalesLawImageForCard}

      <div
        className={
          isDesktop ? classes.contentContainer : classes.contentContainerMobile
        }
      >
        <div
          className={
            isDesktop ? classes.textWrapper : classes.textWrapperMobile
          }
        >
          {information}
        </div>

        {isDesktop && SalesLawImageForCard}
      </div>
    </div>
  );
};

export default withStyles(style)(PropertySalesLawInfoCard);
