import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  divider: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0 15px',
  },
  hr: {
    height: '1px',
    width: '100%',
    backgroundColor: theme.palette.primary.greyLighter,
  },
  dividerText: {
    margin: '0 5px',
    width: '100px',
    textAlign: 'center',
  },
});

const Divider = ({ classes, children }) => (
  <div className={classes.divider}>
    <div className={classes.hr} />
    <div className={classes.dividerText}>{children}</div>
    <div className={classes.hr} />
  </div>
);

export default withStyles(styles)(Divider);
