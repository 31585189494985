import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { handleRouteChange, matomoClient } from '../../utils/matomo';
import { useEventListner } from '../../api/hooks';
import {
  CookieConsentCategories,
  cookieConsentClient,
} from '../../utils/cookieConsent';
import hotjar from '../../utils/hotjar';
import { hotjarId, hotjarVersion } from '../../config';
/**
 * This is the Matomo component which we use to manage Matomo as the app is running, tracking page loads, events etc.
 */
function Matomo() {
  const location = useLocation();
  // set up listening for the user cookie consent
  useEventListner('CookieInformationConsentGiven', () => {
    const client = matomoClient();
    if (!client) {
      return;
    }
    const cookieConsent = cookieConsentClient();

    if (cookieConsent.getConsentGivenFor(CookieConsentCategories.statistic)) {
      client.push(['rememberCookieConsentGiven']);
      hotjar.initialize(hotjarId, hotjarVersion);
      hotjar.trigger('hotjar_opt_in');
    } else {
      client.push(['forgetCookieConsentGiven']);
      hotjar.trigger('hotjar_opt_out');
    }
  });

  useEffect(() => {
    handleRouteChange();
  }, [location?.pathname]);
  return <></>;
}

export default Matomo;
