import { enableNewDocumentTable } from '../config';
import DocumentViewTable from '../components/DocumentView/DocumentViewTable';
import DocumentTable from '../components/desktop/DocumentTable';
import DocumentNewList from '../components/DocumentView/DocumentList';
import DocumentList from '../components/DocumentList';

export const getDocumentTable = () => {
  return enableNewDocumentTable === 'true' ? DocumentViewTable : DocumentTable;
};

export const getDocumentList = () => {
  return enableNewDocumentTable === 'true' ? DocumentNewList : DocumentList;
};
