const styles = {
  list: {
    padding: 0,
  },
  delete: {
    color: '#D7394C',
    fontSize: '14px',
    fontWeight: 400,
    paddingLeft: '5px',
  },
  deleteActionDialog: {
    maxWidth: '500px',
  },
  deleteFolderActionButton: {
    backgroundColor: '#F50B08',
    height: '48px',
    color: 'white',
    textDecoration: 'none',
    marginBottom: '12px',
    '&:hover': {
      backgroundColor: '#c20a08',
    },
  },
  deleteFolderActionButtonCluster: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  dialogTitle: {
    flex: 1,
    textAlign: 'center',
    '&:first-child': {
      fontSize: '20px',
    },
  },
};

export default styles;
