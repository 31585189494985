const styles = (theme) => ({
  container: {
    maxWidth: '85%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  marginTop: ({ shiftMarginTopBy }) => ({
    [theme.breakpoints.up('md')]: {
      marginTop: shiftMarginTopBy
        ? `calc(35vh - ${shiftMarginTopBy}px)`
        : '35vh',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: shiftMarginTopBy
        ? `calc(25vh - ${shiftMarginTopBy}px)`
        : '25vh',
    },
  }),
  text: {
    lineHeight: '1.3',
    textAlign: 'center',
    marginBottom: '30px',
  },
  buttonContainer: {
    marginTop: '2rem',
    textAlign: 'center',
  },
  personalBtn: {
    backgroundColor: 'none',
    border: '1px solid #5dc9a2',
    textDecoration: 'none',
    padding: '5px 20px',
    marginBottom: '20px',
  },
});

export default styles;
