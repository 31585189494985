import moment from 'moment';

export const formattedDate = (date, format = 'D MMM YYYY') =>
  date && moment(date).isValid() ? moment(date).format(format) : null;

export const fromNow = (date) =>
  date && moment(date).isValid()
    ? moment.utc(date).local().startOf('seconds').fromNow()
    : null;

export const getYear = (date) => formattedDate(date, 'YYYY');

export const getMonth = (date) => formattedDate(date, 'MM');

export const createDate = (year, month, day = 1) =>
  new Date(year, month - 1, day);
