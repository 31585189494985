const style = {
  progressButton: {
    fontStyle: 'normal',
    fontWeight: '500',
  },
  title: {
    fontSize: '1.5rem',
  },
  detailsButtonArea: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  detailsButtonAreaMobile: {
    display: 'flex',
    justifyContent: 'center',
    margin: '27px 0 27px 0 ',
  },
  button: {
    width: '198px',
  },
  introContent: {
    textAlign: 'left',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.43',
    marginBottom: '20px',
  },
  introContentMobile: {
    textAlign: 'left',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.43',
    marginTop: '10px',
  },
  contentContainer: {
    height: '100%',
    display: 'grid',
    alignItems: 'center',
    gap: '4px',
    gridTemplateRows: '2',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  textWrapper: {
    alignSelf: 'center',
  },
  buttonWrapper: {
    alignSelf: 'flex-end',
  },
  imageContainer: {
    height: '100%',
    maxHeight: '200px',
    gridColumn: '2 / 3',
    gridRow: '1 / 3',
    alignSelf: 'flex-start',
    justifySelf: 'flex-end',
  },
  mobileImageContainer: {
    height: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '200px',
    marginTop: '20px',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  contentContainerMobile: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'space-between',
    gridTemplateRows: 'none',
    gridTemplateColumns: 'none',
    gap: '4px',
    textAlign: 'left',
  },
  textWrapperMobile: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  buttonWrapperMobile: {
    width: '100%',
  },
  titleText: {
    marginTop: '0',
  },
};
export default style;
