import { stringifyQuery } from './routes';

export const getNotificationId = (notification) =>
  notification && notification.id ? notification.id : null;

export const getNotificationIds = (notification) =>
  notification && notification.ids && Array.isArray(notification.ids)
    ? notification.ids
    : null;

export const getNotificationCompanyId = (notification) =>
  notification && notification.companyId ? notification.companyId : null;

export const getNotificationIdHash = (notification, separator = '-') =>
  Array.isArray(getNotificationIds(notification))
    ? getNotificationIds(notification).join(separator)
    : null;

export const getNotificationDate = (notification) =>
  notification && notification.createdDate ? notification.createdDate : null;

export const getNotificationIssuer = (notification) =>
  notification && notification.issuer ? notification.issuer : null;

export const getNotificationCompanyName = (notification) =>
  notification && notification.companyName ? notification.companyName : null;

export const getNotificationCompanyEmail = (notification) =>
  notification && notification.companyEmail ? notification.companyEmail : null;

export const getNotificationCompanyPhone = (notification) =>
  notification && notification.companyPhone ? notification.companyPhone : null;

export const getNotificationCompanyWebsite = (notification) =>
  notification && notification.companyWebsite
    ? notification.companyWebsite
    : null;

export const getNotificationMessage = (notification) =>
  notification && notification.message ? notification.message : null;

export const getNotificationAcceptLabel = (notification) =>
  notification && notification.action && notification.action.acceptLabel
    ? notification.action.acceptLabel
    : null;

export const getNotificationType = (notification) =>
  notification && notification.notificationType
    ? notification.notificationType
    : null;

export const isNotificationSeen = (notification) =>
  notification && notification.seenByUser && notification.seenByUser === true;

export const isNotificationUnseen = (notification) =>
  !isNotificationSeen(notification);

export const isInformationPushNotification = (notification) =>
  notification && notification.notificationType === 11;

// TODO enable when backend is ready
export const hasAttachment = () => false;

export const getNotificationTitle = (notification) =>
  notification && (notification.title || notification.issuer)
    ? notification.title || notification.issuer
    : null;

export const getNotificationActionButtonConfig = (
  notification,
  dashboardLinkPrefix
) => {
  let isPrivateShareNotification = false;
  if (!notification) {
    return null;
  }
  isPrivateShareNotification =
    notification.notificationType === 8 ||
    notification.notificationType === 9 ||
    notification.notificationType === 10;


  if (isPrivateShareNotification) {
    return {
      label: 'Administrer delte tilganger',
      link: `${dashboardLinkPrefix}/more/private-share`,
    };
  }

  const { documentIds, label } = notification.action;

  if (Array.isArray(documentIds)) {
    return {
      label,
      link:
        documentIds.length > 1
          ? `${dashboardLinkPrefix}/documents?${stringifyQuery({
              ids: documentIds,
            })}`
          : `${dashboardLinkPrefix}/documents/${documentIds[0]}`,
    };
  }

  if (isInformationPushNotification(notification) && notification.action) {
    return {
      label: `${notification.action.label}`,
      link: `${notification.action.link}`,
    };
  }

  return null;
};
