const floors = [
  {
    id: 7,
    name: 'Kjeller',
  },
  {
    id: 8,
    name: 'Underetasje',
  },
  {
    id: 1,
    name: '1. etasje',
  },
  {
    id: 2,
    name: '2. etasje',
  },
  {
    id: 3,
    name: '3. etasje',
  },
  {
    id: 4,
    name: '4. etasje',
  },
  {
    id: 5,
    name: '5. etasje',
  },
  {
    id: 6,
    name: '6. etasje',
  },
  {
    id: 9,
    name: 'Over 6. etasje',
  },
  {
    id: 10,
    name: 'Loft',
  },
];

export default floors;
