import React from 'react';
import _ from 'lodash';

const Separator = (turns, style) => {
  return (
    <div
      key={`sep_${turns}`}
      style={{
        position: 'absolute',
        height: '100%',
        transform: `rotate(${turns}turn)`,
      }}
    >
      <div style={style} />
    </div>
  );
};

const RadialSeparators = (count, style) => {
  const turns = 1 / count;
  return _.range(count).map((index) => (
    <Separator turns={index * turns} style={style} />
  ));
};

export default RadialSeparators;
