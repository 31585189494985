import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import ImageGallery from '../../components/ImageGallery';
import { sortImagesByPosition } from '../../utils/images';
import { getRoomId, getRoomImages } from '../../utils/rooms';
import {
  updateRoom as apiUpdateRoom,
  uploadImage as apiUploadImage,
} from '../../api/rooms';
import UploadImageButton from '../../components/forms/ImageForm/UploadImageButton';
import Button from '../../components/atomic/Button';
import Camera from '../../assets/icons/Camera';
import Bin from '../../assets/icons/Bin';
import useActionDialog from '../../hooks/useActionDialog';

const RoomImages = ({
  classes,
  fetching,
  room,
  editAccess = true,
  isManualUpload = false,
  onRoomImageUpload = () => {},
  onRoomImageDelete = () => {},
  isGalleryModeActive = true,
}) => {
  const { showActionDialog } = useActionDialog();

  const [imageRef, setImageRef] = useState(null);
  const [imageRefUpload, setImageRefUpload] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  let roomImages = (getRoomImages(room) || []).sort(sortImagesByPosition);

  const lastImagePosition = Array.isArray(roomImages) ? roomImages.length : 0;

  const uploadImageRequest = (image, position) =>
    apiUploadImage(getRoomId(room), image, position);

  const showIndex = Array.isArray(roomImages) && roomImages.length > 0;

  const onImagesUploaded = (uploadedImages, imageFiles) => {
    roomImages = uploadedImages;
    onRoomImageUpload(uploadedImages, imageFiles);
  };

  const openImageUploadDialog = () => imageRefUpload.click();

  const onImagesUpdated = async (imagesToUpdate, onSuccess, onError) => {
    if (!isManualUpload) {
      const [error, response] = await apiUpdateRoom(getRoomId(room), {
        imageIds: imagesToUpdate.map((image) => image.id),
      });

      if (!error && response) {
        const imageRest = (imagesToUpdate || []).map((image, index) => {
          const i = image;
          i.position = index + 1;
          return i;
        });
        onRoomImageDelete(imageRest, selectedImageIndex);
        roomImages = imageRest;
        if (onSuccess) onSuccess();
      }

      if (onError && error && !response) {
        onError();
      }
    } else {
      const imageRest = (imagesToUpdate || []).map((image, index) => {
        const i = image;
        i.position = index + 1;
        return i;
      });
      onRoomImageDelete(imageRest, selectedImageIndex);
      roomImages = imageRest;
    }
  };

  const onRoomImageChange = (index) => {
    setSelectedImageIndex(index);
  };

  const onImageDelete = () =>
    showActionDialog({
      submitButtonText: 'Slett',
      title: 'Vil du slette dette bilde?',
      onSubmit: () => {
        const roomImagesToSave = [...roomImages];
        roomImagesToSave.splice(selectedImageIndex, 1);
        onImagesUpdated(roomImagesToSave);
      },
    });

  return (
    <div className={classes.container}>
      {roomImages?.length === 0 ? (
        <UploadImageButton
          showButton
          imageRef={imageRef}
          className={classes.upload_btn_container}
          isManualUpload={isManualUpload}
          setImageRef={setImageRef}
          onImagesUploaded={onImagesUploaded}
          lastImagePosition={lastImagePosition}
          uploadImageRequest={uploadImageRequest}
        />
      ) : (
        <>
          <ImageGallery
            dots={false}
            showPlaceholder
            images={roomImages}
            editable={editAccess}
            showIndex={showIndex}
            fetching={!room || fetching}
            className={classes.image_galary_view}
            onImagesUpdated={onImagesUpdated}
            getCurrentIndex={onRoomImageChange}
            isGalleryModeActive={isGalleryModeActive}
          />
          <div className={classes.image_heading}>
            <div className={classes.actions}>
              <Button className={classes.deleteIcon} onClick={onImageDelete}>
                <Bin className={classes.binIcon} />
              </Button>
              <Button
                size="small"
                className={classes.editButton}
                onClick={() => openImageUploadDialog()}
              >
                <Camera className={classes.cameraIcon} />
                <small>Rediger</small>
                <UploadImageButton
                  showButton={false}
                  imageRef={imageRefUpload}
                  className={classes.upload_btn_container}
                  setImageRef={setImageRefUpload}
                  isManualUpload={isManualUpload}
                  onImagesUploaded={onImagesUploaded}
                  lastImagePosition={lastImagePosition}
                  uploadImageRequest={uploadImageRequest}
                />
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(RoomImages);
