import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import Box from '../../../../../../components/atomic/Box';
import DialogScrollBox from '../../../../../../components/DialogScrollBox';
import useDesktop from '../../../../../../hooks/useDesktop';
import Typography from '../../../../../../components/atomic/Typography';
import {
  mostCreatedRoomTypes,
  RoomTypePropertyType,
} from '../../../../../../utils/propertyRoomTypes';
import { getPropertyType } from '../../../../../../utils/properties';
import ExistingRooms from '../ExistingRooms';
import AddCircleSharp from '../../../../../../assets/icons/AddCircleSharp';
import RemoveCircleSharp from '../../../../../../assets/icons/RemoveCircleSharp';

const RoomTypes = ({
  classes,
  property,
  onRoomAdd,
  onRoomRemove,
  rooms,
  nonCreatedRooms,
  requestInProgress,
  roomsCreatedStatus,
  bybfRooms,
}) => {
  const isDesktop = useDesktop();
  const [roomTypes, setRoomTypes] = useState(
    RoomTypePropertyType.find(
      (a) => a.propertyType === getPropertyType(property)
    )?.roomTypes ||
      RoomTypePropertyType.find((a) => a.propertyType === 'Default').roomTypes
  );
  const [initialSetUpPassed, setInitialSetupPassed] = useState(false);

  useEffect(() => {
    setRoomTypes((preRT) => {
      const tempArr = Array.from(preRT);
      tempArr.forEach((element) => {
        const rte = element;
        rte.currentCount =
          !roomsCreatedStatus &&
          bybfRooms.length === 0 &&
          rooms.length === 0 &&
          mostCreatedRoomTypes.some((a) => a === rte.code)
            ? 1
            : 0;
        if (rte.currentCount === 1) {
          onRoomAdd(rte);
        }
      });
      return tempArr;
    });
  }, [rooms]);

  useEffect(() => {
    if (nonCreatedRooms && nonCreatedRooms.length > 0 && !initialSetUpPassed) {
      nonCreatedRooms.forEach((ncr) => {
        setRoomTypes((preRT) => {
          const tempArr = Array.from(preRT);
          tempArr.find((a) => a.code === ncr.code).currentCount =
            ncr.currentCount;
          setInitialSetupPassed(true);
          return tempArr;
        });
      });
    }
  }, [nonCreatedRooms]);

  const onMinusClick = (index) => {
    if (roomTypes[index].currentCount !== 0) {
      setRoomTypes((preRT) => {
        const tempArr = Array.from(preRT);
        tempArr[index].currentCount -= 1;
        onRoomRemove(tempArr[index]);
        return tempArr;
      });
    }
  };

  const onPlusClick = (index) => {
    setRoomTypes((preRT) => {
      const tempArr = Array.from(preRT);
      tempArr[index].currentCount += 1;
      onRoomAdd(tempArr[index]);
      return tempArr;
    });
  };

  const renderSummeryText = () => {
    if (rooms.length === 0) {
      return (
        <>
          <Typography align="center" className={classes.room_select_typo_top}>
            Når du har opprettet alle rommene for boligen, kan du enkelt knytte
            dine hendelser og dokumenter til de. Slik får du god kontroll over
            boligen.
          </Typography>
          <Typography align="center" className={classes.room_select_Typo}>
            Velg blant noen av de mest brukte rommene nedenfor. Du kan legge til
            flere rom senere.
          </Typography>
        </>
      );
    }

    if (rooms.length > 0) {
      return (
        <>
          <Typography className={classes.title}>Opprett nye rom</Typography>
          <Typography className={classes.progressSummary}>
            Vil du opprette flere rom velger du antall nedenfor og trykker
            «Neste»
          </Typography>
          <Typography className={classes.progressSummaryLast}>
            Hvis dette er alle rommene i boligen går du bare videre.
          </Typography>
        </>
      );
    }

    return null;
  };

  return (
    <DialogScrollBox>
      {!requestInProgress && <ExistingRooms />}
      <Box
        className={
          isDesktop
            ? classes.room_type_heading
            : classes.room_type_heading_mobile
        }
      >
        {renderSummeryText()}
      </Box>
      <ul
        className={
          isDesktop ? classes.room_type_list : classes.room_type_list_mobile
        }
      >
        {(roomTypes || []).map((rt, index) => (
          <li
            key={`room-type-item-${rt.code}`}
            className={classes.room_type_list_item}
          >
            <Box className={classes.room_type_list_item_name}>
              <b>{rt.displayName}</b>
            </Box>
            <Box className={classes.room_type_list_item_pad}>
              <RemoveCircleSharp
                onClick={() => {
                  onMinusClick(index);
                }}
                className={
                  rt.currentCount === 0
                    ? `${classes.room_type_list_remove_circle} ${classes.room_type_list_item_pad_pm_disabled}`
                    : classes.room_type_list_remove_circle
                }
              />
              <Box width={15} textAlign="center" component="div">
                {rt.currentCount}
              </Box>
              <AddCircleSharp
                onClick={() => {
                  onPlusClick(index);
                }}
                className={classes.room_type_list_add_circle}
              />
            </Box>
          </li>
        ))}
      </ul>
    </DialogScrollBox>
  );
};

export default withStyles(styles)(RoomTypes);
