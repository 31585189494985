import { makeStyles } from '@material-ui/core';

export const BottomSectionStyles = makeStyles((theme) => ({
  mdrBottomSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
    '@media only screen and (max-width: 768px)': {
      flexDirection: 'column',
      gap: '32px',
    },
  },
  mdrBottomSectionCard: {
    maxWidth: '470px',
    display: 'flex',
    flexDirection: 'column',
    gap: '22px',
    padding: '40px 20px',
    borderRadius: '32px',
    '@media only screen and (max-width: 768px)': {
      border: `1px solid ${theme.palette.primary.greenLighter}`,
    },
  },
  mdrBottomSectionImage: {
    maxWidth: '470px',
    maxHeight: '325px',
    borderRadius: '32px',
  },
  cardTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '20px',
    lineHeight: '31px',
    '@media only screen and (max-width: 768px)': {
      fontSize: '16px',
    },
  },
  cardContent: {
    fontSize: '16px',
    lineHeight: '25px',
    fontWeight: '400',
    '@media only screen and (max-width: 768px)': {
      fontSize: '12px',
    },
  },
}));
