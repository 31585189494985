import { makeStyles } from '@material-ui/core';

export const tooltipStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.black,
    color: theme.palette.primary.white,
    padding: 10,
    zIndex: 10000,
    fontWeight: 300,
  },
  infoIconStyle: {
    padding: 4,
    margin: 4,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.white,
    fontSize: 13,
  },
  interactiveWrapper: { pointerEvents: 'all' },
}));

