const styles = {
  lineLoading: {
    width: '100%',
    height: '15px',
    marginBottom: '15px',
    '&:first-child': {
      marginTop: '20px',
    },
  },
};

export default styles;
