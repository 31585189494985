import React from 'react';

const ChapterTwo = ({
  width = '100%',
  height = '100%',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32.718 29.991"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g data-name="Group 3033" transform="translate(0)">
      <path
        fill={fill}
        transform="translate(-334.91 -396.273)"
        d="M339.241,406.659a.682.682,0,0,0,.44,1.2h1.363a.682.682,0,0,1,.682.682v6.135a.682.682,0,0,0,.682.682h3.408a.682.682,0,0,0,.682-.682V411.61a2.045,2.045,0,0,1,4.09,0v3.067a.682.682,0,0,0,.682.682h3.408a.682.682,0,0,0,.682-.682v-6.135a.682.682,0,0,1,.682-.682H357.4a.682.682,0,0,0,.442-1.2l-8.861-7.5a.683.683,0,0,0-.882,0Z"
      />
      <path
        fill={fill}
        transform="translate(-330.914 -390.462)"
        d="M352.726,420.453A2.727,2.727,0,1,0,350,417.726,2.726,2.726,0,0,0,352.726,420.453Z"
      />
      <path
        fill={fill}
        transform="translate(-334.91 -390.462)"
        d="M341.727,420.453A2.727,2.727,0,1,0,339,417.726,2.726,2.726,0,0,0,341.727,420.453Z"
      />
      <path
        fill={fill}
        transform="translate(-336 -397)"
        d="M368.718,398.363A1.364,1.364,0,0,0,367.355,397H365.31a1.363,1.363,0,0,0-1.351,1.183l-2.491,18.676a.682.682,0,0,1-.682.592H337.363a1.363,1.363,0,1,0,0,2.726h25.22a1.363,1.363,0,0,0,1.351-1.183l2.491-18.676a.682.682,0,0,1,.682-.592h.248a1.364,1.364,0,0,0,1.363-1.363Z"
      />
    </g>
  </svg>
);

export default ChapterTwo;
