import React from 'react';

import { StyledContactMeBox } from './styles';

const ContactMeBox = ({ title, onButtonClick, children }) => {
  return (
    <StyledContactMeBox>
      <div className="box_wrapper">
        <h3 className="title">{title}</h3>
        {children}
        {onButtonClick && (
          <button
            className="contact_button"
            type="button"
            onClick={onButtonClick && onButtonClick}
          >
            Kontakt meg
          </button>
        )}
      </div>
    </StyledContactMeBox>
  );
};

export default ContactMeBox;
