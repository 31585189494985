import React, { createElement } from 'react';

import useDesktop from '../../../../../../hooks/useDesktop';

import BottomContextMenu from '../../../../../../components/BottomContextMenu';
import List from '../../../../../../components/atomic/List';
import Menu from '../../../../../../components/atomic/Menu';
import DesktopListItem from '../../../../../../components/desktop/MenuListItem';
import MobileListItem from '../../../../../../components/BottomContextMenu/ListItem';

import EditIcon from '../../../../../../assets/icons/Edit';
import DeleteIcon from '../../../../../../assets/icons/Bin';

const ContextMenu = ({
  open,
  setOpen,
  onEditClick,
  onDeleteClick,
  anchorEl,
}) => {
  const isDesktop = useDesktop();

  const menuItems = [
    {
      icon: EditIcon,
      onClick: onEditClick,
      text: 'Rediger sluttdato for deling',
    },
    {
      icon: DeleteIcon,
      onClick: onDeleteClick,
      text: 'Stopp deling',
    },
  ];

  const renderMenuItem = ({ text, onClick, disabled, ...props }) =>
    // eslint-disable-next-line react/no-children-prop
    createElement(isDesktop ? DesktopListItem : MobileListItem, {
      disabled,
      key: text,
      children: text,
      border: !isDesktop,
      onClick: (event) => {
        if (disabled) {
          return;
        }

        if (onClick) {
          onClick(event);
        }

        setOpen(false);
      },
      ...props,
    });

  const renderMenuItems = () =>
    menuItems.filter(({ hidden }) => !hidden).map(renderMenuItem);

  if (isDesktop) {
    return (
      <Menu
        open={open}
        anchorEl={anchorEl}
        disableAutoFocusItem
        onClose={() => setOpen(false)}
      >
        {renderMenuItems()}
      </Menu>
    );
  }

  return (
    <BottomContextMenu
      open={open}
      setOpen={setOpen}
      title="Innstillinger for offentlig deling"
      ModalProps={{
        style: {
          zIndex: 1202,
        },
      }}
    >
      <List>{renderMenuItems()}</List>
    </BottomContextMenu>
  );
};

export default ContextMenu;
