const style = (theme) => ({
  cardContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  ballIcon: {
    width: '12px',
    height: '12px',
    color: theme.palette.primary.red,
    marginRight: '5px',
    marginTop: '8px',
  },
  greenBallIcon: {
    width: '12px',
    height: '12px',
    color: theme.palette.primary.green,
    marginRight: '5px',
    marginTop: '8px',
  },
  titleArea: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  titleAreaMobile: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  sectionBox: {
    padding: '5px 0 45px 0',
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: '700',
    fontSize: '18px',
  },
  divider: ({ align = 'center' }) => ({
    height: '3px',
    width: '45px',
    marginTop: theme.spacing(1),
    background: theme.palette.primary.red,
    marginLeft: align === 'center' ? 'auto' : undefined,
    marginRight: align === 'center' ? 'auto' : undefined,
  }),
  emptyStateText: {
    marginTop: '35px',
  },
  emptyStateTextMobile: {
    marginTop: '35px',
    textAlign: 'center',
  },
});

export default style;
