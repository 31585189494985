import React from 'react';

const ChapterSeven = ({
  width = '100%',
  height = '100%',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 31.074 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill={fill}
      transform="translate(-275 -9321.24)"
      d="M306.074,9325.269a2.462,2.462,0,0,0-.743-1.756,2.555,2.555,0,0,0-1.794-.72H289.87A1.9,1.9,0,0,0,288,9321.24h-5.073a1.9,1.9,0,0,0-1.871,1.553,1.72,1.72,0,0,0-.031.3v11.754a.606.606,0,0,0,.432.583.635.635,0,0,0,.708-.216l3.041-3.952a.319.319,0,0,1,.508,0l3.047,3.952a.636.636,0,0,0,.708.216.609.609,0,0,0,.434-.583v-9.268a.318.318,0,0,1,.324-.314h12.663a.636.636,0,0,1,.647.631v22.235a.637.637,0,0,1-.647.632h-24.7a.637.637,0,0,1-.647-.632v-22.563a.309.309,0,0,1,.312-.3,1.238,1.238,0,1,0,0-2.476h-.312a2.508,2.508,0,0,0-2.536,2.476v23.5a2.463,2.463,0,0,0,.742,1.754,2.607,2.607,0,0,0,1.795.72h26a2.6,2.6,0,0,0,1.794-.72,2.459,2.459,0,0,0,.743-1.754Z"
    />
    <path
      fill={fill}
      transform="translate(-269.736 -9319.697)"
      d="M289.449,9328.59h3.918a.98.98,0,0,0,.875-1.479,1,1,0,0,0-.875-.48h-3.918a1,1,0,0,0-.875.48.98.98,0,0,0,.875,1.479Z"
    />
    <path
      fill={fill}
      transform="translate(-269.736 -9318.576)"
      d="M289.449,9332.51h3.918a.98.98,0,1,0,0-1.96h-3.918a.98.98,0,1,0,0,1.96Z"
    />
    <path
      fill={fill}
      transform="translate(-275 -9317.453)"
      d="M299.956,9334.47H281.61c-.81,0-1.468.44-1.468.98s.658.979,1.468.979h18.346c.81,0,1.468-.439,1.468-.979S300.766,9334.47,299.956,9334.47Z"
    />
    <path
      fill={fill}
      transform="translate(-271.895 -9316.331)"
      d="M292.387,9339.37a.98.98,0,0,0-.979-.98H281.122a.98.98,0,0,0,0,1.96h10.286a1,1,0,0,0,.693-.29A.984.984,0,0,0,292.387,9339.37Z"
    />
  </svg>
);

export default ChapterSeven;
