import styled from 'styled-components';

export const StyledNewsletterSignup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  width: 100%;
  max-width: 550px;
  margin: 0;

  @media (max-width: 767px) {
    padding: 24px;
    max-width: 100%;
  }

  .link_wrapper {
    text-decoration: unset;
  }

  .checkIcon {
    width: 22px;
    color: #5dc9a2;
    padding-right: 5px;
  }

  .feature_content {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    @media (max-width: 767px) {
      row-gap: 8px;
    }

    @media (max-width: 767px) {
      margin-right: 8px;
    }

    h2 {
      font-size: 20px;
      line-height: 31px;
      font-weight: 700;
      color: #111;
      margin: 0;

      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 28px;
      }
    }

    h4 {
      font-size: 18px;
      line-height: 5px;
      font-weight: 700;
      color: #111;
      margin: 0;

      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 28px;
      }
    }

    p {
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      color: #111;
      margin: 0;

      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 22px;
      }
    }
    .link {
      color: #164144;
      font-weight: 500;
    }
    .soon {
      font-size: 16px;
      line-height: 25px;
      font-weight: 700;
      color: #31777b;
    }
    .logo {
      max-width: 100%;
    }
    h2.place_holder_heading {
      color: #111;
      font-size: 24px;
      line-height: 37px;
      font-weight: 700;
    }
    p.place_holder_description {
      font-size: 16px;
      line-height: 25px;
    }

    p.newsletter_description {
      font-size: 14px;
      line-height: normal;
    }
  }

  button {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: unset;
    border-radius: 50px;
    background: #164144;
    color: #fff;
    margin-right: 16px;
    font-size: 14px;
    cursor: pointer;
    width: 118px;
  }
`;
