const styles = (theme) => ({
  lineLoading: {
    width: '100%',
    height: '20px',
    marginBottom: '20px',
  },
  description: {
    padding: '5px 0 10px',
    whiteSpace: 'pre-line',
  },
  miscList: {
    padding: 0,
    borderTop: `1px solid ${theme.palette.primary.whiteDarker}`,
  },
});

export default styles;
