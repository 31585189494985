import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import DocumentSummaryItem from './DocumentSummaryItem';
import BaseTable from '../../../atomic/Table';
import TableRow from '../../../atomic/TableRow';
import TableHead from '../../../atomic/TableHead';
import TableBody from '../../../atomic/TableBody';
import TableCell from '../../../atomic/TableCell';
import Typography from '../../../atomic/Typography';
import { getDocumentId } from '../../../../utils/documents';
import { SummaryColumns } from '../../../DocumentFilters/util';

const SummaryTable = ({
  classes,
  documents,
  searchQuery,
  actionsEnabled,
  onDocumentClick,
  filteredDocuments,
  showCompanyNameLinks,
  showRoomsColumn = false,
  showEventsColumn = false,
  showUploadedDateColumn = true,
  showUploaderNameColumn = true,
}) => (
  <BaseTable className={classes.root}>
    <TableHead>
      <TableRow>
        {SummaryColumns.map((column) => (
          <TableCell key={column.type} style={column.styles}>
            {column.name}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {Array.isArray(filteredDocuments) && filteredDocuments.length === 0 && (
        <TableRow>
          <TableCell colSpan={SummaryColumns.length + 1}>
            <Typography align="center" color="textSecondary">
              Ingen dokument funnet
            </Typography>
          </TableCell>
        </TableRow>
      )}

      {Array.isArray(documents) &&
        filteredDocuments.length > 0 &&
        filteredDocuments.map((document) => (
          <DocumentSummaryItem
            document={document}
            searchQuery={searchQuery}
            actionsEnabled={actionsEnabled}
            onDocumentClick={onDocumentClick}
            showRoomsColumn={showRoomsColumn}
            showEventsColumn={showEventsColumn}
            showCompanyNameLink={showCompanyNameLinks}
            showUploadedDateColumn={showUploadedDateColumn}
            showUploaderNameColumn={showUploaderNameColumn}
            key={`document-item-${getDocumentId(document)}`}
          />
        ))}
    </TableBody>
  </BaseTable>
);

export default withStyles(styles)(SummaryTable);
