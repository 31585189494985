// Add your styles here, and reference them in your component className
import { css } from '@linaria/core';
import { hjemlaColors } from '@style/themes/hjemla/colors';
import { borders, colors, fonts, spacing } from '@style/vars';

export const inputContainer = css``;

export const inputWrapper = css`
  display: flex;
  align-items: center;
  border: 1.5px solid ${colors.backgroundGrayColor};
  background-color: ${colors.backgroundLightColor};
  border-radius: 4px;
  margin-bottom: 8px;
  position: relative;

  .loadableContainer {
    display: flex;
    align-items: center;
  }

  &.error {
    border-color: ${hjemlaColors.redWarning};
  }

  &:focus-within {
    border-color: ${colors.textDark};
  }

  &.text-area-wrapper {
    &:focus-within {
      border: ${borders.inputActive};
    }
  }

  &.rounded,
  &.rounded input {
    border-radius: 50px;
  }

  &.disabled {
    cursor: not-allowed;
    background: ${colors.backgroundLightGrayColor};
  }

  input {
    all: unset;
    width: 100%;
    font-size: ${fonts.size.xs};
    line-height: ${fonts.lineHeight.xs};
    background: ${colors.transparent};
    border-radius: ${borders.radius.small};

    &.default {
      padding: 10.5px 16px;
    }

    &.slim {
      padding: 6.5px 16px;
    }
  }

  input:focus {
    outline: none;
  }

  textarea {
    all: unset;
    width: 100%;
    font-size: ${fonts.size.xs};
    line-height: ${fonts.lineHeight.xs};
    background: ${colors.transparent};
    padding: ${spacing.small} ${spacing.medium};
    border-radius: ${borders.radius.small};
  }

  textarea:focus {
    outline: none;
  }

  textarea:disabled,
  input:disabled {
    background: ${colors.backgroundLightGrayColor};
    color: ${colors.textGrayColor};
  }

  input::placeholder,
  textarea::placeholder {
    color: ${colors.textColor_disabled};
  }

  button {
    all: unset;
    display: flex;
    align-items: center;
    border-radius: 50px;
  }

  button:hover {
    cursor: pointer;
  }

  button:focus {
    outline: auto;
    outline-offset: 4px;
  }

  svg {
    align-self: center;
  }

  .input-r-content {
    position: absolute;
    top: 0;
    right: 5px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
