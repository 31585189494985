import clsx from 'clsx';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { parseISO } from 'date-fns';

import {
  getEventName,
  getEventRooms,
  getEventSubType,
  getEventDocuments,
  getEventDescription,
  getEventStartDate,
  getEventEndDate,
  eventModes,
  getEventImages,
} from '../../../utils/events';

import {
  fetchRooms as apiFetchRooms,
  fetchDocuments as apiFetchDocuments,
} from '../../../api/properties';

import { getDocumentId, getDocumentName } from '../../../utils/documents';

import {
  acSetDocuments,
  acSetFetchingDocuments,
} from '../../../store/actions/documents';

import styles from './style';
import Grid from '../../atomic/Grid';
import TextArea from '../../TextArea';
import Autocomplete from '../../Autocomplete';
import TextField from '../../atomic/TextField';
import InputLabel from '../../atomic/InputLabel';
import { eventTypes, categoryOther } from './eventTypes';
import { sGetFetchingRooms, sGetRooms } from '../../../store/reducers/rooms';
import DocumentOptionList from '../../DocumentOptionList';
import useFetchEffect from '../../../hooks/useFetchEffect';
import { getRoomId, getRoomName } from '../../../utils/rooms';
import {
  sGetDocuments,
  sGetFetchingDocuments,
} from '../../../store/reducers/documents';
import { acSetFetchingRooms, acSetRooms } from '../../../store/actions/rooms';

import {
  prepareNormalEventData,
  documentOptions,
  roomOptions,
  categoryOptions,
} from './util';
import DialogForm from '../BaseDialogForm';
import DatePicker from '../../DateTimePicker';
import {
  sGetEventAttachedIds,
  sGetEventMode,
} from '../../../store/reducers/events';
import {
  acSetEventAttachedIds,
  acSetEventReturnOnSuccess,
} from '../../../store/actions/events';
import EventImages from '../../../modules/EventImages';
import Box from '../../atomic/Box';

export const descriptionMaxLength = 1500; // in characters

export const EventForm = ({
  isCreate = false,
  event,
  header,
  open,
  onClose,
  onSubmit,
  boligmappaNumber,
  error,
  requestInProgress,
  fetchingError,
  fetching,
  classes,
  rooms,
  setRooms,
  documents,
  setDocuments,
  setFetchingRooms,
  setFetchingDocuments,
  eventMode,
  attachedIds,
  setEventAttachedIds,
  setReturnOnSuccess,
  fetchingRooms,
  disableDocumentControl = false,
}) => {
  const {
    control,
    handleSubmit,
    errors,
    getValues,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  const [dateEnd, setDateEnd] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState(null);
  const [roomIds, setRoomIds] = useState([]);
  const [documentIds, setDocumentIds] = useState([]);
  const [customCategory, setCustomCategory] = useState('');

  const attachDocumentMode = eventMode === eventModes.document;
  const attachRoomMode = eventMode === eventModes.room;

  const { roomIds: queryRoomIds, documentIds: queryDocumentIds } = attachedIds;

  const [uploadedImages, setUploadedImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);

  useFetchEffect({
    dependencies: [open],
    conditionFunction: () => !rooms && !fetchingRooms && open,
    onSuccess: (data) => setRooms(data),
    setFetchingFunction: setFetchingRooms,
    apiFetchFunction: () => apiFetchRooms(boligmappaNumber),
  });

  const { fetching: fetchingDocuments } = useFetchEffect({
    dependencies: [open],
    conditionFunction: () => !documents && !fetchingDocuments && open,
    onSuccess: (data) => setDocuments(data),
    setFetchingFunction: setFetchingDocuments,
    apiFetchFunction: () =>
      apiFetchDocuments(boligmappaNumber, { includeTaggedInfo: true }),
  });

  const onChangeRooms = (options) => setRoomIds(options || []);

  const onChangeDocuments = (options) => setDocumentIds(options || []);

  const onCategoryChange = (option) => {
    setCategory(option);
    setCustomCategory('');
  };

  const resetFields = () => {
    setDateEnd(getEventEndDate(event) ? new Date(getEventEndDate(event)) : '');
    setDescription(getEventDescription(event) || '');

    const staticEventType = eventTypes.find(
      (eventType) => eventType === getEventSubType(event)
    );

    if (staticEventType) {
      setCategory({
        value: staticEventType,
        label: staticEventType,
      });
    } else {
      setCategory({
        value: categoryOther,
        label: categoryOther,
      });
      setCustomCategory(getEventSubType(event));
    }

    setUploadedImages({
      gallery: getEventImages(event) || [],
    });
    setImageFiles([]);
  };

  useEffect(() => {
    if (event) {
      resetFields();
    }
  }, [event, open]);

  const resetForm = () => {
    setEventAttachedIds({
      roomIds: [],
      documentIds: [],
    });
    setReturnOnSuccess(false);
    resetFields();
  };

  const handleClose = () => onClose(resetForm);

  useEffect(() => {
    if (rooms && Array.isArray(queryRoomIds) && queryRoomIds.length > 0) {
      setRoomIds([
        ...rooms
          .filter((room) => queryRoomIds.includes(String(getRoomId(room))))
          .map((room) => ({
            value: getRoomId(room),
            label: getRoomName(room),
          })),
      ]);
    } else {
      setRoomIds(
        (getEventRooms(event) || []).map((room) => ({
          value: getRoomId(room),
          label: getRoomName(room),
        }))
      );
    }
  }, [rooms, open]);

  useEffect(() => {
    if (documents && queryDocumentIds.length > 0) {
      setDocumentIds([
        ...documents
          .filter((document) =>
            queryDocumentIds.includes(String(getDocumentId(document)))
          )
          .map((document) => ({
            value: getDocumentId(document),
            label: getDocumentName(document),
          })),
      ]);
    } else {
      setDocumentIds(
        (getEventDocuments(event) || []).map((document) => ({
          value: getDocumentId(document),
          label: getDocumentName(document),
        }))
      );
    }
  }, [documents, open]);

  const onFormSubmit = () => {
    if (attachDocumentMode || attachRoomMode) {
      onSubmit(
        prepareNormalEventData({
          ...event,
          rooms,
          roomIds,
          documents,
          documentIds,
        }),
        resetFields
      );
    } else {
      onSubmit(
        prepareNormalEventData({
          title: getValues('title'),
          dateStart: getValues('dateStart'),
          dateEnd,
          rooms,
          roomIds,
          category,
          documents,
          description,
          documentIds,
          customCategory,
          gallery: uploadedImages.gallery,
          associations: event?.associations,
        }),
        resetFields,
        imageFiles
      );
    }
    setEventAttachedIds({
      roomId: [],
      documentIds: [],
    });
  };

  const onEventImageUpload = (_uploadedImages, recentFiles) => {
    setImageFiles([...imageFiles, ...recentFiles]);

    setUploadedImages((prevState) => ({
      ...prevState,
      gallery: [...(getEventImages(prevState) || []), ..._uploadedImages],
    }));
  };

  const onEventImageDelete = (imagesToBeUpload, removedIndex) => {
    setImageFiles((preval) => {
      const files = preval;
      files.splice(removedIndex, 1);
      return files;
    });

    setUploadedImages((prevState) => ({
      ...prevState,
      gallery: [...imagesToBeUpload],
    }));
  };

  const renderFormContent = () => {
    const documentSelectField = disableDocumentControl ? (
      <></>
    ) : (
      <DocumentOptionList
        value={documentIds}
        options={documentOptions(documents)}
        autoFocus={attachDocumentMode}
        disabled={requestInProgress}
        isLoading={fetchingDocuments}
        className={classes.formElement}
        onChange={(options) => onChangeDocuments(options)}
      />
    );

    const roomSelectField = (
      <Autocomplete
        isMulti
        value={roomIds}
        options={roomOptions(rooms)}
        label="Koble til rom"
        autoFocus={attachRoomMode}
        isLoading={fetchingRooms}
        closeMenuOnSelect={false}
        disabled={requestInProgress}
        className={classes.formElement}
        loadingMessage={() => 'Laster...'}
        noOptionsMessage={() => 'Ingen rom'}
        placeholder="Velg ett eller flere rom"
        onChange={(options) => onChangeRooms(options)}
      />
    );

    if (attachDocumentMode && disableDocumentControl) {
      return documentSelectField;
    }

    if (attachRoomMode) {
      return roomSelectField;
    }

    return (
      <>
        {isCreate && (
          <Box className={classes.formElement}>
            <EventImages
              editAccess={false}
              fetching={false}
              event={uploadedImages}
              isManualUpload
              onEventImageUpload={onEventImageUpload}
              onEventImageDelete={onEventImageDelete}
              isGalleryModeActive={false}
            />
          </Box>
        )}

        <Controller
          name="title"
          control={control}
          defaultValue={getEventName(event) || ''}
          rules={{ required: true }}
          render={({ onChange, value, ...props }) => (
            <TextField
              required
              value={value}
              inputProps={{
                maxLength: 60,
              }}
              label="Navn på hendelsen"
              className={classes.formElement}
              disabled={fetching || requestInProgress}
              onChange={(e) => onChange(e.target.value)}
              placeholder="Feks. Oppussing kjøkken, flislegging bad ..."
              error={errors.title}
              {...props}
            />
          )}
        />

        <Grid
          container
          spacing={1}
          className={clsx(classes.formElement, classes.datesContainer)}
        >
          <Grid item xs={5}>
            <Controller
              name="dateStart"
              defaultValue={
                getEventStartDate(event)
                  ? parseISO(getEventStartDate(event))
                  : null
              }
              control={control}
              rules={{ required: true }}
              render={({ onChange, value, ...props }) => (
                <DatePicker
                  isSimple
                  required
                  label="Start"
                  value={value}
                  dateSelected={value}
                  placeholder="mm/åååå"
                  disabled={fetching || requestInProgress}
                  onChange={(_value) => onChange(_value)}
                  error={errors.dateStart}
                  popperModifiers={[
                    {
                      name: 'offset',
                      options: {
                        offset: [20, 0],
                      },
                    },
                  ]}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  {...props}
                />
              )}
            />
          </Grid>
          <Grid item xs={1} container className={classes.toLabelContainer}>
            <InputLabel
              shrink
              className={classes.dateLabel}
              disabled={fetching || requestInProgress}
            >
              til
            </InputLabel>
          </Grid>
          <Grid item xs={5} container className={classes.datePickerContainer}>
            <DatePicker
              isSimple
              label="Slutt"
              value={dateEnd ? new Date(dateEnd) : null}
              dateSelected={dateEnd ? new Date(dateEnd) : null}
              placeholder="mm/åååå"
              disabled={fetching || requestInProgress}
              onChange={(value) => setDateEnd(value)}
              popperModifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [0, 0],
                  },
                },
              ]}
              selectsEnd
              dateFormat="MM/yyyy"
              showMonthYearPicker
            />
          </Grid>
        </Grid>

        <TextArea
          value={description}
          label="Beskrivelse"
          className={classes.formElement}
          maxLength={descriptionMaxLength}
          disabled={fetching || requestInProgress}
          onChange={(e) => setDescription(e.target.value)}
        />

        <Autocomplete
          label="Kategori"
          value={category}
          options={categoryOptions(eventTypes)}
          placeholder="Velg kategori"
          onChange={onCategoryChange}
          className={classes.formElement}
          noOptionsMessage={() => 'Ingen kategorier'}
          disabled={fetching || requestInProgress}
        />

        {category && category.label && category.label === categoryOther && (
          <TextField
            label="Din kategori"
            value={customCategory}
            className={classes.formElement}
            disabled={fetching || requestInProgress}
            onChange={(e) => setCustomCategory(e.target.value)}
          />
        )}

        {Array.isArray(rooms) && rooms.length > 0 && roomSelectField}

        {Array.isArray(documents) &&
          documents.length > 0 &&
          documentSelectField}
      </>
    );
  };

  return (
    <DialogForm
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit(onFormSubmit)}
      requestInProgress={requestInProgress}
      fetchingError={fetchingError}
      error={error}
      header={header}
      fetching={fetching}
      showCancelButton={false}
      showLargeSubmitButton
      submitButtonDisabled={!isValid}
    >
      {renderFormContent()}
    </DialogForm>
  );
};

const mapStateToProps = (state) => ({
  rooms: sGetRooms(state),
  documents: sGetDocuments(state),
  eventMode: sGetEventMode(state),
  attachedIds: sGetEventAttachedIds(state),
  fetchingDocuments: sGetFetchingDocuments(state),
  fetchingRooms: sGetFetchingRooms(state),
});

const mapDispatchToProps = (dispatch) => ({
  setRooms: (rooms) => dispatch(acSetRooms(rooms)),
  setDocuments: (documents) => dispatch(acSetDocuments(documents)),
  setFetchingDocuments: (fetching) =>
    dispatch(acSetFetchingDocuments(fetching)),
  setFetchingRooms: (fetching) => dispatch(acSetFetchingRooms(fetching)),
  setEventAttachedIds: (payload) => dispatch(acSetEventAttachedIds(payload)),
  setReturnOnSuccess: (payload) => dispatch(acSetEventReturnOnSuccess(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EventForm));
