import React from 'react';
import Card from '../../../../../components/Card';
import { OverviewGridItem } from './components';
import OtovoCardInfo from '../../../../../modules/OtovoCardInfo';

const OtovoInfoSection = () => {
  return (
    <OverviewGridItem>
      <Card height="100%">
        <OtovoCardInfo />
      </Card>
    </OverviewGridItem>
  );
};

export default OtovoInfoSection;
