import React from 'react';

const RoundPlus = ({
  width = '1.2rem',
  height = '1.2rem',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 17.208 16.849"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      transform="translate(-10.75 -2579.001)"
      d="M10.75,2587.425a8.606,8.606,0,1,1,8.6,8.425A8.515,8.515,0,0,1,10.75,2587.425Zm10.589,1.3h2.647a1.3,1.3,0,1,0,0-2.593H21.339a.651.651,0,0,1-.662-.647v-2.593a1.324,1.324,0,0,0-2.647,0v2.593a.651.651,0,0,1-.662.647H14.721a1.3,1.3,0,1,0,0,2.593h2.647a.651.651,0,0,1,.662.647v2.592a1.324,1.324,0,0,0,2.647,0v-2.592A.651.651,0,0,1,21.339,2588.722Z"
    />
  </svg>
);

export default RoundPlus;
