import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { withStyles } from '@material-ui/core';
import BaseTypography from '@material-ui/core/Typography';

import styles from './style';

const Typography = forwardRef(
  (
    {
      classes,
      fontSize,
      marginTop,
      className,
      noMargins,
      component,
      lineHeight,
      paletteColor,
      ...props
    },
    ref
  ) => (
    <BaseTypography
      ref={ref}
      component={component}
      className={clsx(
        className,
        classes.root,
        noMargins && classes.noMargins,
        component === 'small' && classes.small,
        component === 'strong' && classes.strong
      )}
      {...props}
    />
  )
);

export default withStyles(styles)(Typography);
