import React from 'react';

const ConnectionError = (props) => (
  <svg
    width="278.87"
    height="189.915"
    viewBox="0 0 278.87 189.915"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <style>{`.a{fill:rgba(252,234,223,0.55);}.b{fill:#fc6865;}`}</style>
    </defs>
    <g transform="translate(-43 -110.313)">
      <path
        className="a"
        d="M309.994,230.813s-115.7,37.059-273.9.846c0,0-11.072-106.6,63.575-124.847,73.183-17.9,93.349-51.257,130.68-49.089C342.561,64.243,309.994,230.813,309.994,230.813Z"
        transform="translate(7.483 52.691)"
      />
      <g transform="translate(-94.542 -63.971)">
        <g transform="translate(241.543 244.922)">
          <path
            className="b"
            d="M242.676,262.468a3.491,3.491,0,0,0-1.012,3.481,3.637,3.637,0,0,0,2.651,2.555,3.807,3.807,0,0,0,3.613-.977,49.423,49.423,0,0,1,42.083-12.944,3.814,3.814,0,0,0,3.438-1.155l.019-.027a3.485,3.485,0,0,0,.705-3.531,3.686,3.686,0,0,0-2.841-2.337A57.054,57.054,0,0,0,242.676,262.468Z"
            transform="translate(-241.543 -239.584)"
          />
          <path
            className="b"
            d="M262.2,257.2a3.815,3.815,0,0,0,5.206-.043,3.485,3.485,0,0,0,.047-5.016c-.279-.267-.562-.527-.845-.791a3.815,3.815,0,0,0-5.253.124,3.491,3.491,0,0,0,.128,5.063C261.724,256.757,261.965,256.978,262.2,257.2Z"
            transform="translate(-187.507 -229.261)"
          />
          <path
            className="b"
            d="M269.624,257.494a3.467,3.467,0,0,0,.407-4.132,3.767,3.767,0,0,0-3.923-1.721,41.286,41.286,0,0,0-17.712,8.094q-1.558,1.244-3.008,2.632a3.487,3.487,0,0,0-1.012,3.477,3.648,3.648,0,0,0,2.651,2.558,3.813,3.813,0,0,0,3.613-.977,33.563,33.563,0,0,1,16.874-8.742,3.7,3.7,0,0,0,2.07-1.144Z"
            transform="translate(-233.742 -225.784)"
          />
          <path
            className="b"
            d="M258.249,254.915a3.5,3.5,0,0,0-.872,2.628,3.545,3.545,0,0,0,1.314,2.45c.523.442,1.031.9,1.527,1.372a3.814,3.814,0,0,0,5.206-.043,3.485,3.485,0,0,0,.046-5.016c-.655-.632-1.333-1.229-2.027-1.791a3.816,3.816,0,0,0-5.2.411Z"
            transform="translate(-196.034 -219.724)"
          />
          <path
            className="b"
            d="M255.258,258.264a3.5,3.5,0,0,0-.864,2.783,3.574,3.574,0,0,0,1.562,2.481,15.884,15.884,0,0,1,2.384,1.931,3.814,3.814,0,0,0,5.253,0,3.49,3.49,0,0,0,0-5.063,26.423,26.423,0,0,0-3.314-2.7,3.859,3.859,0,0,0-5.016.57Z"
            transform="translate(-204.66 -210.118)"
          />
          <path
            className="b"
            d="M257.514,275.854a7.163,7.163,0,1,0-7.431-7.156A7.3,7.3,0,0,0,257.514,275.854Z"
            transform="translate(-216.976 -197.125)"
          />
          <path
            className="b"
            d="M318.711,245.794a3.814,3.814,0,0,0-5.237.361l-70.587,78.72a3.481,3.481,0,0,0-.768,3.555,3.667,3.667,0,0,0,2.849,2.372,3.786,3.786,0,0,0,3.539-1.248L319.1,250.83A3.492,3.492,0,0,0,318.711,245.794Z"
            transform="translate(-240.451 -244.922)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ConnectionError;
