import React from 'react';

const DefaultIcon = ({ width = '20', height = '24' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 24"
  >
    <g
      id="Group_2789"
      data-name="Group 2789"
      transform="translate(-171.109 -3122.902)"
    >
      <g
        id="Group_2783"
        data-name="Group 2783"
        transform="translate(-148 1862)"
      >
        <path
          id="Shape_42"
          d="M406,54.328V70a2,2,0,0,1-2,2H388a2,2,0,0,1-2-2V50a2,2,0,0,1,2-2h11.672a2,2,0,0,1,1.414.586l4.328,4.328A2,2,0,0,1,406,54.328Z"
          transform="translate(-66.891 1212.902)"
          fill="#dedfdf"
        />
      </g>
    </g>
  </svg>
);

export default DefaultIcon;
