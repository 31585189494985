const styles = (theme) => ({
  link: {
    color: 'inherit',
    display: 'block',
    textDecoration: 'none',
  },
  borderedItem: {
    borderBottom: `1px solid ${theme.colors.whiteDarker}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  menuIcon: {
    color: theme.palette.primary.blackLight,
  },
});

export default styles;
