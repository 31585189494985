import { useHistory } from 'react-router';
import React, { createElement } from 'react';
import { withStyles } from '@material-ui/core';

import {
  getDocumentCompanyName,
  getDocumentCreatedDate,
  getDocumentOwnedByName,
  getDocumentUpdatedDate,
} from '../../../../../../utils/documents';

import styles from './style';
import Box from '../../../../../../components/atomic/Box';
import { formattedDate } from '../../../../../../utils/dates';
import TableRow from '../../../../../../components/atomic/TableRow';
import TableCell from '../../../../../../components/atomic/TableCell';
import Typography from '../../../../../../components/atomic/Typography';
import useDashboardLinkPrefix from '../../../../../../hooks/useDashboardLinkPrefix';

const ChapterRow = ({ classes, chapter, documents }) => {
  const history = useHistory();
  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const lastUpdatedDocument =
    Array.isArray(documents) &&
    documents.length > 0 &&
    documents.reduce((result, document) =>
      getDocumentCreatedDate(document) > getDocumentCreatedDate(result)
        ? document
        : result
    );

  const onClick = () =>
    history.push(`${dashboardLinkPrefix}/chapters/${chapter.id}`);

  return (
    <TableRow className={classes.root} onClick={onClick}>
      <TableCell>
        <Box display="flex">
          <Box mr={2}>
            <Typography color="primary">
              {createElement(chapter.icon, { width: '2rem' })}
            </Typography>
          </Box>
          <Box>
            <Typography component="strong">
              {chapter.id}. {chapter.name}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell>{chapter.documentCount || documents.length}</TableCell>
      <TableCell>
        {lastUpdatedDocument
          ? formattedDate(getDocumentUpdatedDate(lastUpdatedDocument))
          : '-'}
      </TableCell>
      <TableCell>
        {getDocumentOwnedByName(lastUpdatedDocument) ||
          getDocumentCompanyName(lastUpdatedDocument) ||
          '-'}
      </TableCell>
    </TableRow>
  );
};

export default withStyles(styles)(ChapterRow);
