import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler,
  CategoryScale,
  BarElement,
  BarController,
} from 'chart.js';
import 'chartjs-adapter-moment';
import { last } from 'lodash';
import moment from 'moment';
import { PRICE_HISTORY_DATA } from './chartsConfig';

export const chartJsInit = () => {
  ChartJS.register(
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    BarController,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    Filler,
    CategoryScale
  );
  ChartJS.defaults.font.size = 13;
};

export const getPercentage = (value, valueFrom) => {
  return Math.round((value / valueFrom - 1) * 100);
};

export const transformPriceHistoryData = (priceHistoryData, years) => {
  let trimmedData = priceHistoryData.data.filter(
    (item) => moment().year() - moment(item.date).year() <= years
  );
  const lastItem = last(trimmedData);

  trimmedData = trimmedData.map((item) => {
    return {
      y: getPercentage(item.indexValue, lastItem.indexValue),
      x: moment(item.date).format(),
    };
  });

  return { ...priceHistoryData, data: trimmedData };
};

export const generatePriceHistoryChartData = (priceHistoryArray) => {
  const data = { ...PRICE_HISTORY_DATA };

  data.datasets = data.datasets.map((dataset, i) => {
    return { ...dataset, data: priceHistoryArray[i].data };
  });

  return data;
};

export const getYearIndexChange = (transformedChartData) => {
  const sorted = transformedChartData.sort(
    (a, b) => new Date(b.x) - new Date(a.x)
  );

  return sorted[0] && sorted[5] ? (sorted[0].y - sorted[5].y).toFixed(0) : null;
};

export const createGradient = (ctx, area, gradientData) => {
  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradientData.forEach((item) => gradient.addColorStop(item.stop, item.color));

  return gradient;
};

export default chartJsInit;
