import React from 'react';

const ArrowUp = ({ width = '1.2rem', height = '1.2rem', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 11"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      transform="translate(-566.25 -8637.002)"
      d="M566.668,8647.6a1.463,1.463,0,0,0,2.021,0l7.36-7.078a.287.287,0,0,1,.4,0l7.36,7.078a1.433,1.433,0,0,0,1.377.353,1.389,1.389,0,0,0,1.01-.971,1.329,1.329,0,0,0-.367-1.323l-8.572-8.247a1.461,1.461,0,0,0-2.021,0l-8.572,8.247a1.335,1.335,0,0,0,0,1.941Z"
    />
  </svg>
);

export default ArrowUp;
