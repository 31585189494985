import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';

import styles from './style';
import NavbarBase from '../atomic/Navbar';

const MobileNavbar = ({
  hidden,
  classes,
  className,
  showLabels = true,
  ...props
}) => (
  <NavbarBase
    className={clsx(className, classes.navbar, hidden && classes.hidden)}
    showLabels={showLabels}
    {...props}
  />
);

export default withStyles(styles)(MobileNavbar);
