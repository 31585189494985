import { css } from '@linaria/core';
import { borders, spacing } from '@style/vars';
import { colors } from '@style/vars/colors';
import { linkSizeMap } from '@style/vars/typography';

export const linkStyle = css`
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  width: auto;
  font-size: ${linkSizeMap.default.fontSize};
  line-height: ${linkSizeMap.default.lineHeight};
  font-weight: ${linkSizeMap.default.fontWeight};
  color: ${colors.textColor};
  border-radius: 1px;
  .link-text {
    position: relative;
    text-decoration: underline;
    text-decoration-color: ${colors.link.text};
    text-underline-offset: 4px;
    text-decoration-thickness: 1.5px;
  }

  &.size-big {
    font-size: ${linkSizeMap.big.fontSize};
    line-height: ${linkSizeMap.big.lineHeight};
    font-weight: ${linkSizeMap.big.fontWeight};
  }

  .icon {
    flex-shrink: 0;
    transition: transform 0.2s;
  }

  &:hover,
  &:focus {
    outline: none;
    .link-text {
      text-decoration-color: ${colors.textColor};
    }
  }

  &:focus-visible {
    outline-offset: 6px;
    outline: ${borders.link.outline};
  }

  &.variant-light {
    color: ${colors.textLight};

    .link-text {
      text-decoration-color: ${colors.link.hover};
    }

    &:hover,
    &:focus {
      .link-text {
        text-decoration-color: ${colors.lightColor};
      }
    }
  }
`;

/**
 * We split the styles up into base and with icon, so that we don't
 * accidentally add things to the one without icon when it's not necessary
 */
export const linkWithIcon = css`
  .link-text {
    margin-right: ${spacing.smaller};
  }
  &.icon-left {
    flex-direction: row-reverse;

    .link-text {
      margin: 0;
    }

    svg {
      margin-right: ${spacing.smaller};
    }
  }
  &:hover,
  &:focus {
    .icon {
      transform: translateX(4px);
    }
  }
  &.variant-light {
    .icon * {
      stroke: ${colors.lightColor};
    }
  }
`;
