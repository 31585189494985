import { request } from '../utils/http';
import { backendBaseUrl } from '../config';

export const postFeedback = (data) =>
  request({
    method: 'post',
    url: 'zendesk',
    baseURL: backendBaseUrl,
    data,
  })
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);
