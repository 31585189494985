// Add your styles here, and reference them in your component className
import { css } from '@linaria/core';
import { mediaQueries } from '@style/mediaQueries';

export const organizationDetailsWrapper = css`
  .textLine {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-size: 14px;
  }

  .text-break-wrapper {
    word-break: break-all;
  }

  .company-web-url {
    display: flex;
    svg {
      display: none;
      &.iconWrapper {
        display: block;
        margin-top: 12px;
      }
    }
  }

  .certificate-wrapper {
    margin-top: 22px;
  }

  .certificate-title {
    font-size: 16px;
  }

  .no-certificates-text {
    font-size: 14px;
  }

  .certificate-image-wrapper {
    margin-top: 3px;
    margin-left: -20px;
  }

  .certificate-image {
    width: 60px;
    height: 60px;
    margin-left: 20px;
    margin-top: 3px;
    object-fit: contain;
  }

  .franchiser-image {
    height: 65px;
    margin-left: 20px;
    object-fit: contain;
  }

  .ignore-underline {
    text-decoration: none !important;
  }

  .learn-more-certificates-text {
    margin-top: 27px;
    font-size: 14px;
    outline: none;
    .learn-more-certificates-text-items {
      display: flex;
      align-items: center;
      .arrow-icon{
        margin-left: 10px;
      }
    }
    .link-wrapper {
      svg{
        width:15px;
      }
    }
  }

  .iconWrapper {
    margin-right: 14px;
  }

  .labelsWrapper {
    margin-top: 36px;
    display: flex;
    flex-wrap: wrap;
  }

  .labelTextWrapper {
    margin-top: 10px;
    margin-right: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    background: #d1ede8;
    border-radius: 8px;
    padding: 5px 11px;
    height: 32px;
    text-align:center;
  }

  .organizationName {
    font-size: 20px;
    line-height: 36px;
  }

  .organizationId {
    font-size: 14px;
    line-height: 24px;
    color: #73748f;
  }

  .divider {
    margin-top: 31px;
    width: 100%;
    height: 1px;
    background-color: #cccccc;
    flex: none;
    order: 3;
    flex-grow: 0;
  }
  .companyAboutWrapper {
    display : flex;
    align-items: flex-end
  }
  .companyAboutData {
    flex-grow: 1;
        ${mediaQueries.xs} {
            flex-grow: 0;
        }
  }
`;
