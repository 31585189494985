const styles = (theme) => ({
  paper: {
    padding: 0,
  },
  filters: {
    borderBottom: `1px solid ${theme.palette.primary.greyLighter}`,
  },
  filtersEmpty: {
    borderBottom: 'none',
  },
  emptyList: {
    padding: '40px 0',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

export default styles;
