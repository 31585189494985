const styles = (theme) => ({
  listItem: {
    display: 'flex',
    padding: '20px 0',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    borderBottom: `1px solid ${theme.colors.whiteDarker}`,

    '&:last-child': {
      borderBottom: 'none',
    },
  },
  leftContainer: {
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'row',
    width: '60%',
    color: theme.palette.text.secondary,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  primaryText: {
    fontWeight: 700,
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    overflowWrap: 'break-word',
    color: theme.palette.text.primary,
  },
  secondaryText: {
    fontSize: '16px',
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  rightContainer: {
    display: 'flex',
    alignSelf: 'flex-start',
    cursor: 'pointer',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.text.primary,
  },
  activeLabel: {
    color: '#13A085',
    backgroundColor: '#b6e0db',
    borderRadius: '3px',
    height: '20px',
    width: '68px',
    textAlign: 'center',
    fontSize: '13px',
  },
  pendingLabel: {
    color: '#FBB03B',
    backgroundColor: '#ffe9c7',
    borderRadius: '3px',
    height: '20px',
    width: '68px',
    textAlign: 'center',
    fontSize: '13px',
  },
});
export default styles;
