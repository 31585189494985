// Add your styles here, and reference them in your component className
import { css } from '@linaria/core';

export const style = css``;

export const featureWrapper = css`
  .feature-title-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-size: 20px;
  }

  .textLine {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .empty-error-message-wrapper {
    font-size: 14px;
    line-height: 22px;
    margin-top: 17px;
  }

  .link-wrapper {
    line-height: 24px;
    font-size: 14px;
    margin-top: 13px;
    margin-right: 35px;

    svg {
      width: 8px;
      height: 8px;
    }
    .arrow-icon {
      margin-left:8px;
    }
  }
`;
