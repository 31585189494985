import Home from '../views/PrivateRoutes/Dashboard/Home';
import More from '../views/PrivateRoutes/Dashboard/More';
import History from '../views/PrivateRoutes/Dashboard/Events';
import Documents from '../views/PrivateRoutes/Dashboard/Documents';
import Notifications from '../views/PrivateRoutes/Dashboard/Notifications';
import Rooms from '../views/PrivateRoutes/Dashboard/Rooms';

const routes = [
  {
    title: 'Home',
    path: 'home',
    component: Home,
    hasSubRoutes: true,
  },
  {
    title: 'Rom',
    path: 'rooms',
    component: Rooms,
    hasSubRoutes: false,
  },
  {
    title: 'Boligens historikk',
    path: 'history',
    component: History,
    hasSubRoutes: false,
  },
  {
    title: 'Dokumenter',
    path: 'documents',
    component: Documents,
    hasSubRoutes: false,
  },
  {
    title: 'Varsler',
    path: 'notifications',
    component: Notifications,
    hasSubRoutes: false,
  },
  {
    title: 'Mer',
    path: 'more',
    component: More,
    mobileOnly: true,
    hasSubRoutes: false,
  },
];

export default routes;
