import React from 'react';

const ConnectExistingDocument = ({
  width = '1.2rem',
  height = '1.2rem',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    data-name="Component 1"
    viewBox="0 0 24.5 25.75"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(0.5)">
      <g>
        <g>
          <path d="M23.414,3,21,.586A2,2,0,0,0,19.586,0H8A2,2,0,0,0,6,2V9.273a.249.249,0,0,0,.246.25A14.34,14.34,0,0,1,7.711,9.6.247.247,0,0,0,8,9.353V2.5A.5.5,0,0,1,8.5,2H19.379a.5.5,0,0,1,.353.146l2.122,2.122A.5.5,0,0,1,22,4.621V18a.5.5,0,0,1-.5.5H14.642a.244.244,0,0,0-.241.21,7.931,7.931,0,0,1-.364,1.458.242.242,0,0,0,.225.331h7.745A1.994,1.994,0,0,0,24,18.5V4.415A2,2,0,0,0,23.414,3Z" />
        </g>
      </g>
    </g>
    <path
      data-name="iconmonstr-link-1 (2)"
      transform="translate(-0.001 10.249)"
      d="M4,5.631a4.145,4.145,0,0,1,6.387.634L8.933,7.715A2.186,2.186,0,0,0,6.457,6.457a2.151,2.151,0,0,0-1.055.58L2.622,9.818a2.165,2.165,0,0,0,3.061,3.061l.857-.857a5.511,5.511,0,0,0,2.441.371L7.09,14.285A4.153,4.153,0,0,1,1.217,8.412L4,5.631ZM8.412,1.216,6.52,3.108a5.512,5.512,0,0,1,2.441.371l.856-.856a2.165,2.165,0,0,1,3.061,3.061L10.1,8.464a2.169,2.169,0,0,1-3.061,0,2.3,2.3,0,0,1-.47-.679L5.117,9.235a3.9,3.9,0,0,0,.514.634,4.148,4.148,0,0,0,5.873,0l2.78-2.78A4.152,4.152,0,1,0,8.412,1.216Z"
    />
  </svg>
);

export default ConnectExistingDocument;
