import React from 'react';
import { withStyles } from '@material-ui/core';

import {
  getCommitmentName,
  getCommitmentDocumentId,
  getCommitmentRegisteredDate,
  getCommitmentLicenseMattrikkel,
  getCommitmentTransferredFromMattrikkel,
} from '../../utils/commitments';

import styles from './style';
import Box from '../../components/atomic/Box';
import List from '../../components/atomic/List';
import { formattedDate } from '../../utils/dates';
import { capitalizeFirst } from '../../utils/strings';
import SeeMoreLink from '../../components/SeeMoreLink';
import Typography from '../../components/atomic/Typography';
import ListItem from '../../components/CardList/KeyValueListItem';
import ContentLoader from '../../components/loaders/ContentLoader';

const PropertyCommitment = ({
  link,
  classes,
  fetching,
  showLink,
  showType,
  showTitle,
  commitment,
}) => (
  <List>
    {fetching ? (
      <>
        <ContentLoader width={120} height={20} />
        <br />
        <br />
        <ContentLoader
          width={110}
          height={20}
          className={classes.listItemLoading}
        />
        <ContentLoader
          width={160}
          height={20}
          className={classes.listItemLoading}
        />
        <ContentLoader
          width={140}
          height={20}
          className={classes.listItemLoading}
        />
        <ContentLoader
          width={100}
          height={20}
          className={classes.listItemLoading}
        />
      </>
    ) : (
      <>
        <Typography variant="h2" className={classes.title}>
          {showTitle
            ? 'Servitutter'
            : capitalizeFirst(getCommitmentName(commitment))}
        </Typography>
        {showType && (
          <ListItem label="Type">
            {capitalizeFirst(getCommitmentName(commitment))}
          </ListItem>
        )}
        <ListItem label="Registrert dato">
          {getCommitmentRegisteredDate(commitment) &&
            formattedDate(getCommitmentRegisteredDate(commitment))}
        </ListItem>
        <ListItem label="Dokumentnummer">
          {getCommitmentDocumentId(commitment)}
        </ListItem>
        <ListItem label="Rettighetshaver">
          {getCommitmentLicenseMattrikkel(commitment)}
        </ListItem>
        <ListItem label="Overført fra">
          {getCommitmentTransferredFromMattrikkel(commitment) &&
            Array.isArray(getCommitmentTransferredFromMattrikkel(commitment)) &&
            getCommitmentTransferredFromMattrikkel(commitment).map(
              (matrikkel) => (
                <>
                  <span>{matrikkel}</span>
                  <br />
                </>
              )
            )}
        </ListItem>

        {showLink && (
          <ListItem>
            <Box py={2} textAlign="right">
              <SeeMoreLink to={link}>Se alle servitutter</SeeMoreLink>
            </Box>
          </ListItem>
        )}
      </>
    )}
  </List>
);

export default withStyles(styles)(PropertyCommitment);
