import {
  ADD_DOCUMENT,
  SET_DOCUMENTS,
  CLEAR_DOCUMENTS,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT,
  SET_FETCHING_DOCUMENTS,
  SET_FETCHING_DOCUMENTS_ERROR,
  SET_SEARCH_TEXT,
  SYNC_DOCUMENTS_EVENTS,
  SYNC_DOCUMENTS_ROOMS,
  DETACH_EVENT_FROM_DOCUMENTS,
  DETACH_ROOM_FROM_DOCUMENTS,
  SET_MULTIPLE_DOCUMENTS_SELECTED,
  SET_INSURANCE_REMAINING_DAYS,
  SET_IS_CREATING_DOCUMENT_FOLDER,
} from '../reducers/documents';

export const acAddDocument = (document) => ({
  type: ADD_DOCUMENT,
  payload: document,
});

export const acSetDocuments = (documents) => ({
  type: SET_DOCUMENTS,
  payload: documents,
});

export const acClearDocuments = () => ({
  type: CLEAR_DOCUMENTS,
});

export const acUpdateDocument = (document) => ({
  type: UPDATE_DOCUMENT,
  payload: document,
});

export const acDeleteDocument = (document) => ({
  type: DELETE_DOCUMENT,
  payload: document,
});

export const acSetFetchingDocuments = (fetching) => ({
  type: SET_FETCHING_DOCUMENTS,
  payload: fetching,
});

export const acSetFetchingDocumentsError = (error) => ({
  type: SET_FETCHING_DOCUMENTS_ERROR,
  payload: error,
});

export const acSetDocumentSearchText = (searchText) => ({
  type: SET_SEARCH_TEXT,
  payload: searchText,
});

export const acSyncDocumentsEvents = ({ documentIds, event, eventId }) => ({
  type: SYNC_DOCUMENTS_EVENTS,
  payload: {
    documentIds,
    event,
    eventId,
  },
});

export const acSyncDocumentsRooms = ({ documentIds, room, roomId }) => ({
  type: SYNC_DOCUMENTS_ROOMS,
  payload: {
    documentIds,
    room,
    roomId,
  },
});

export const acDetachEventFromDocuments = (eventId) => ({
  type: DETACH_EVENT_FROM_DOCUMENTS,
  payload: eventId,
});

export const acDetachRoomFromDocuments = (roomId) => ({
  type: DETACH_ROOM_FROM_DOCUMENTS,
  payload: roomId,
});

export const acSetMultipleDocumentsSelected = (multipleDocumentsSelected) => ({
  type: SET_MULTIPLE_DOCUMENTS_SELECTED,
  payload: multipleDocumentsSelected,
});

export const acSetInsuranceRemainingDays = (remainingDays) => ({
  type: SET_INSURANCE_REMAINING_DAYS,
  payload: remainingDays,
});

export const acSetIsCreatingDocumentFolder = (isCreatingDocumentFolder) => ({
  type: SET_IS_CREATING_DOCUMENT_FOLDER,
  payload: isCreatingDocumentFolder,
});
