import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import styles from '../../../style';
import { sGetUser } from '../../../../../../../store/reducers/auth';
import { PrimaryButton } from '../../../../../../../components/Buttons';
import RadonAreaLevel from '../../../../../../../components/RadonAreaLevel';
import { useActiveProperty } from '../../../../../../../store/hooks';
import {
  getCompleteAddress,
  getPropertyBoligmappaNumber,
  getRadonAreaLevel,
} from '../../../../../../../utils/properties';
import {
  isNativeEnv,
  openLinkRequest,
} from '../../../../../../../utils/nativeBridge';

const RadonComponent = ({
  description,
  descriptionSecondPart,
  bannerImageUrl,
  FeatureRedirectionUrl,
  classes,
  user,
}) => {
  const { property } = useActiveProperty();
  const [, setPropertyOwnerId] = useState('');

  useEffect(() => {
    setPropertyOwnerId(user?.sub?.slice(user?.sub?.length - 14));
  }, [user]);

  const openRadonWebShop = () => {
    if (isNativeEnv()) {
      openLinkRequest(
        `${FeatureRedirectionUrl}?boligmappaNumber=${getPropertyBoligmappaNumber(
          property
        )}&address=${getCompleteAddress(property)}`
      );
      return;
    }
    window.open(
      `${FeatureRedirectionUrl}?boligmappaNumber=${getPropertyBoligmappaNumber(
        property
      )}&address=${getCompleteAddress(property)}`,
      '_blank'
    );
  };

  return (
    <div className={classes.descriptionComponentWrapper}>
      <div className={classes.descriptionWrapper}>
        <span className={classes.textDescription}>{description}</span>
        <div className={classes.radonWrapper}>
          <RadonAreaLevel
            boligmappaNumber={getPropertyBoligmappaNumber(property)}
            radonAreaLevel={getRadonAreaLevel(property)}
          />
        </div>
        <span className={classes.textDescription}>{descriptionSecondPart}</span>

        <PrimaryButton
          className={classes.routeToAnotherPageButton}
          target="_blank"
          onClick={openRadonWebShop}
        >
          Bestill radonmåling
        </PrimaryButton>
      </div>
      <div className={classes.imageBannerWrapper}>
        <img
          className={classes.imageBanner}
          src={bannerImageUrl}
          alt="Radon Banner"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: sGetUser(state),
});

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(RadonComponent));
