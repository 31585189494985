const styles = {
  titleLoader: {
    margin: '10px 0 45px',
  },
  containerMobile: {
    padding: '0',
  },
  listItemLoader: {
    margin: '43px 0',
    '&:last-child': {
      margin: '43px 0 30px',
    },
  },
  contactCard: {
    width: '419px',
    boxShadow: '2px 5px 15px rgba(0, 0, 0, 0.15)',
  },
  contactCardMobile: {
    width: '100%',
    boxShadow: 'none',
  },
  personDetails: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '15px',
  },
  avatarArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: '30px',
  },
  detailsArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100px',
    textTransform: 'capitalize',
  },
  avatarCircle: {
    width: '100px',
    height: '100px',
  },
  nameLine: {
    padding: '0px',
    margin: '0px',
  },
  designationLine: {
    padding: '0px',
    margin: '0px',
    fontSize: 'smaller',
    color: '#827e7e',
  },
  buttonArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  lineMobileView: {
    borderColor: '#f4f4f4',
  },
  otherDetails: {
    padding: '0px 24px 0px 24px',
  },
  disable: {
    display: 'none',
  },
};

export default styles;
