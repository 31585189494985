export const cookieConsentClient = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  return window.CookieInformation || null;
};

export const CookieConsentCategories = {
  marketing: 'cookie_cat_marketing',
  functional: 'cookie_cat_functional',
  statistic: 'cookie_cat_statistic',
};
