import React from 'react';

const EditDocumentIcon = ({
  width = '0.9rem',
  height = '0.9rem',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.6719 5.79688C11.8828 6.03125 12 6.28906 12 6.59375C12 6.92188 11.8828 7.17969 11.6719 7.39062L6.89062 12.1719C6.65625 12.4062 6.39844 12.5 6.09375 12.5C5.76562 12.5 5.50781 12.4062 5.29688 12.1719L0.328125 7.20312C0.09375 6.99219 0 6.73438 0 6.40625V1.625C0 1.32031 0.09375 1.0625 0.328125 0.828125C0.539062 0.617188 0.796875 0.5 1.125 0.5H5.90625C6.21094 0.5 6.46875 0.617188 6.70312 0.828125L11.6719 5.79688ZM6.09375 11.375L10.875 6.59375L5.90625 1.625H1.125V6.40625L6.09375 11.375ZM4.5 3.875C4.5 3.57031 4.38281 3.3125 4.17188 3.07812C3.9375 2.86719 3.67969 2.75 3.375 2.75C3.04688 2.75 2.78906 2.86719 2.57812 3.07812C2.34375 3.3125 2.25 3.57031 2.25 3.875C2.25 4.20312 2.34375 4.46094 2.57812 4.67188C2.78906 4.90625 3.04688 5 3.375 5C3.67969 5 3.9375 4.90625 4.17188 4.67188C4.38281 4.46094 4.5 4.20312 4.5 3.875Z"
      fill="#111111"
    />
  </svg>
);
export default EditDocumentIcon;
