import React from 'react';
import Box from '../../../../../../../../components/atomic/Box/index';
import Typography from '../../../../../../../../components/atomic/Typography';
import { usePriceHistoryYearChange } from './styles';

export const PriceHistoryYearChange = ({ value }) => {
  const sign = value >= 0;
  const classes = usePriceHistoryYearChange();

  return (
    <Box mb={3} className={sign ? classes.signPositive : classes.signNegative}>
      <Typography fontWeight={700}>
        {sign ? '+' : '-'} {Math.abs(value)} %
      </Typography>
    </Box>
  );
};
