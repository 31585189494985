import { withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import ArrowForward from '../../assets/icons/ArrowForward';
import Close from '../../assets/icons/Close';
import DashPencil from '../../assets/icons/DashPencil';
import General from '../../assets/icons/General';
import MissingDocs from '../../assets/icons/MissingDocs';
import Roof from '../../assets/icons/Roof';
import useDesktop from '../../hooks/useDesktop';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';
import {
  sGetActiveProperty,
  sGetOwnedProperties,
  sGetSharedPrivatelyProperties,
} from '../../store/reducers/properties';
import { actionSetSelectedCompany } from '../../store/actions/mdr';
import { getCompanyContactPersonEmail } from '../../utils/companies';
import {
  getCompanyOrgNumber,
  getCompanyName,
  getCompanyAddress,
} from '../../utils/findProfessional';
import Box from '../atomic/Box';
import Dialog from '../atomic/Dialog';
import DialogTitle from '../atomic/DialogTitle';
import Drawer from '../atomic/Drawer';
import Grid from '../atomic/Grid';
import IconButton from '../atomic/IconButton';
import Toolbar from '../atomic/Toolbar';
import Typography from '../atomic/Typography';
import Autocomplete from '../Autocomplete';
import GeneralInquiryForm from '../GeneralInquiryForm';
import ProgressButton from '../ProgressButton';
import styles from './styles';
import matomo from '../../utils/matomo';

const contactTypes = {
  missingDocs: {
    code: 'MISSING_DOC',
    title: 'Mangler dokumentasjon',
  },
  general: {
    code: 'GENERAL',
    title: 'Generell henvendelse',
  },
};

const ContactProfessional = ({
  open,
  onClose,
  company,
  ownedProperties,
  activeProperty,
  sharedProperties,
  classes,
  setStoreSelectedCompany,
}) => {
  const isDesktop = useDesktop();
  const history = useHistory();
  const [activeContactType, setActiveContactType] = useState('');
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [propertyOptions, setPropertyOptions] = useState(null);
  const [mobilePropertySelection, setMobilePropertySelection] = useState(false);
  const [showGeneralInquiryDialog, setShowGeneralInquiryDialog] =
    useState(false);
  const [mobilePropertySelectOpen, setMobilePropertySelectOpen] =
    useState(false);
  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const onContactTypeSelect = (code) => {
    setActiveContactType(code);
    if (propertyOptions)
      setPropertyOptions((options) => {
        if (!selectedProperty?.value) {
          if (activeProperty) {
            setSelectedProperty({
              label: activeProperty.name,
              value: activeProperty.boligmappaNumber,
            });
          }
        }
        const currentOptions = (options || []).filter((a) => a.value);
        if (code === 'GENERAL') {
          return [
            ...currentOptions,
            ...[
              {
                label: 'Ingen adresse',
                value: null,
              },
            ],
          ];
        }
        if (!selectedProperty?.value) {
          if (activeProperty) {
            setSelectedProperty({
              label: activeProperty.name,
              value: activeProperty.boligmappaNumber,
            });
          }
        }
        return currentOptions;
      });
  };

  const onClosingContactProff = () => {
    setActiveContactType('');
    setMobilePropertySelection(false);
    onClose();
  };

  useEffect(() => {
    const propData = (
      Array.isArray(ownedProperties) && Array.isArray(sharedProperties)
        ? ownedProperties.concat(sharedProperties)
        : []
    ).map((locationData) => ({
      label: locationData.name,
      value: locationData.boligmappaNumber,
    }));
    /* eslint-disable no-nested-ternary */
    setPropertyOptions(
      propData.sort((a, b) =>
        a.label > b.label ? 1 : b.label > a.label ? -1 : 0
      )
    );
    /* eslint-enable no-nested-ternary */
  }, [ownedProperties]);

  useEffect(() => {
    if (activeProperty) {
      setSelectedProperty({
        label: activeProperty.name,
        value: activeProperty.boligmappaNumber,
      });
    }
  }, [activeProperty]);

  useEffect(() => {
    setActiveContactType('');
    setMobilePropertySelection(false);
    if (activeProperty) {
      setSelectedProperty({
        label: activeProperty.name,
        value: activeProperty.boligmappaNumber,
      });
    }
  }, [company]);

  const openContactType = () => {
    if (!isDesktop && !mobilePropertySelection) {
      setMobilePropertySelection(true);
      return;
    }
    if (activeContactType === 'MISSING_DOC') {
      setStoreSelectedCompany({
        label: company.companyName ?? getCompanyName(company),
        value: getCompanyOrgNumber(company),
        companyAddress:
          typeof company.address === 'string'
            ? company.address
            : getCompanyAddress(company),
      });

      history.push(`${dashboardLinkPrefix}/create-mdr`);
    }
    if (activeContactType === 'GENERAL') {
      matomo.clickEvent({
        category: 'Find Professionals',
        name: 'General inquiry',
        action: 'Contact Company',
      });
      setShowGeneralInquiryDialog(true);
    }
    onClosingContactProff();
  };

  const renderMobilePropertySelection = () => (
    <>
      <Box>
        <Grid container spacing={isDesktop ? 6 : 3}>
          <Grid item xs={isDesktop ? 6 : 12}>
            {!mobilePropertySelectOpen && (
              <Box
                className={
                  selectedProperty
                    ? `${classes.propertyOption} active`
                    : classes.propertyOption
                }
                onClick={() => setMobilePropertySelectOpen(true)}
              >
                <IconButton
                  size="small"
                  color="inherit"
                  className={classes.propertyIcon}
                >
                  <Roof />
                </IconButton>
                <Box className={classes.propertySelectText}>
                  {selectedProperty?.label}
                </Box>
                <IconButton
                  size="small"
                  color="inherit"
                  className={classes.editIcon}
                >
                  <DashPencil />
                </IconButton>
              </Box>
            )}
            {mobilePropertySelectOpen && (
              <Box className={classes.propertySelectMobileContainer}>
                {propertyOptions.map((option) => (
                  <Box
                    className={classes.propertyOption}
                    onClick={() => {
                      setMobilePropertySelectOpen(false);
                      setSelectedProperty(option);
                    }}
                  >
                    <IconButton
                      size="small"
                      color="inherit"
                      className={classes.propertyIcon}
                    >
                      <Roof />
                    </IconButton>
                    <Box className={classes.propertySelectText}>
                      {option?.label}
                    </Box>
                    <IconButton
                      size="small"
                      color="inherit"
                      className={classes.propertyIcon}
                    >
                      <ArrowForward strokeWidth={1} height={13} width={10} />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            )}
          </Grid>
        </Grid>
        {activeContactType !== '' && (
          <Box className={classes.moveBtn}>
            <ProgressButton
              color="primary"
              align="right"
              className={classes.backBtn}
              onClick={() => setMobilePropertySelection(false)}
            >
              Tilbake
            </ProgressButton>
            <ProgressButton
              type="submit"
              color="primary"
              variant="contained"
              align="right"
              onClick={openContactType}
            >
              Gå videre
            </ProgressButton>
          </Box>
        )}
      </Box>
    </>
  );

  const renderDialogBody = () => {
    return (
      <>
        {!isDesktop && (
          <Box>
            <Toolbar>
              <Box p={isDesktop ? 0 : 2} display="flex" flexGrow={1}>
                <Typography variant="h3" component="h3" color="inherit">
                  <b>
                    {!mobilePropertySelection
                      ? `Hva gjelder det?`
                      : `Adresse for henvendelsen`}
                  </b>
                </Typography>
              </Box>
              <IconButton
                size="small"
                color="inherit"
                onClick={onClosingContactProff}
              >
                <Close />
              </IconButton>
            </Toolbar>
          </Box>
        )}
        <Box className={classes.contactOptions}>
          {!mobilePropertySelection ? (
            <Grid container spacing={isDesktop ? 6 : 3}>
              <Grid
                item
                xs={isDesktop ? 6 : 12}
                onClick={() =>
                  onContactTypeSelect(contactTypes.missingDocs.code)
                }
              >
                <Box
                  className={
                    activeContactType === 'MISSING_DOC'
                      ? `${classes.contactOption} active`
                      : classes.contactOption
                  }
                  marginLeft={isDesktop ? '20px' : 0}
                >
                  <IconButton
                    size="small"
                    color="inherit"
                    className={classes.typeIcon}
                  >
                    <MissingDocs
                      height={isDesktop ? 60 : 35}
                      width={isDesktop ? 60 : 35}
                    />
                  </IconButton>
                  <Box className={classes.contactTxt}>
                    <b>{contactTypes.missingDocs.title}</b>
                    <p className={classes.subContactTxt}>
                      Etterspør dokumentasjon på utført arbeid
                    </p>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={isDesktop ? 6 : 12}
                onClick={() => onContactTypeSelect(contactTypes.general.code)}
              >
                <Box
                  className={
                    activeContactType === 'GENERAL'
                      ? `${classes.contactOption} active`
                      : classes.contactOption
                  }
                  marginRight={isDesktop ? '20px' : 0}
                >
                  <IconButton
                    size="small"
                    color="inherit"
                    className={classes.typeIcon}
                  >
                    <General
                      height={isDesktop ? 60 : 35}
                      width={isDesktop ? 60 : 35}
                    />
                  </IconButton>
                  <Box className={classes.contactTxt}>
                    <b>{contactTypes.general.title}</b>
                    <p className={classes.subContactTxt}>
                      Vi sender en mail til bedriften på vegne av deg
                    </p>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ) : (
            renderMobilePropertySelection()
          )}
          {isDesktop && (
            <Box marginTop="40px">
              <Autocomplete
                label="Relevant adresse for henvendelsen"
                value={selectedProperty}
                options={propertyOptions}
                isOptionDisabled={(option) => option.disabled}
                loadingMessage={() => 'Laster...'}
                className={classes.formElement}
                onChange={(options) => setSelectedProperty(options)}
                isSearchable={false}
                isClearable={false}
              />
            </Box>
          )}
          {activeContactType !== '' && !mobilePropertySelection && (
            <Box className={classes.moveBtn}>
              <ProgressButton
                type="submit"
                color="primary"
                variant="contained"
                align="right"
                onClick={openContactType}
              >
                Gå videre
              </ProgressButton>
            </Box>
          )}
        </Box>
      </>
    );
  };

  return (
    <>
      {isDesktop ? (
        <Dialog
          open={open}
          scroll="paper"
          onClose={(_, reason) => {
            if (reason !== 'backdropClick') {
              onClosingContactProff();
            }
          }}
          fullWidth
          disableEscapeKeyDown
          fullScreen={!isDesktop}
        >
          <DialogTitle className={classes.dialogTitle}>
            <Toolbar>
              <Box p={isDesktop ? 0 : 2} display="flex" flexGrow={1}>
                <Typography variant="h3" component="h3" color="inherit">
                  <b>Hva gjelder det?</b>
                </Typography>
              </Box>
              <IconButton
                size="small"
                color="inherit"
                onClick={onClosingContactProff}
              >
                <Close />
              </IconButton>
            </Toolbar>
          </DialogTitle>
          {renderDialogBody()}
        </Dialog>
      ) : (
        <Drawer
          anchor="bottom"
          open={open}
          classes={{
            paper: classes.mobileDrawer,
            root: classes.mobileDrawerModel,
          }}
        >
          {renderDialogBody()}
        </Drawer>
      )}

      {showGeneralInquiryDialog && (
        <GeneralInquiryForm
          open={showGeneralInquiryDialog}
          boligmappaNumber={selectedProperty?.value}
          companyemail={getCompanyContactPersonEmail(company)}
          organizationnnmber={getCompanyOrgNumber(company)}
          companyname={getCompanyName(company)}
          onClose={() => setShowGeneralInquiryDialog(false)}
        />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  ownedProperties: sGetOwnedProperties(state),
  activeProperty: sGetActiveProperty(state),
  sharedProperties: sGetSharedPrivatelyProperties(state),
});

const mapDispatchToProps = (dispatch) => ({
  setStoreSelectedCompany: (selectedCompany) =>
    dispatch(actionSetSelectedCompany(selectedCompany)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ContactProfessional));
