import React from 'react';

const LockIcon = ({ width = '1rem', height = '1rem', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    {...props}
  >
    <path
      d="M12.5 6C12.9062 6 13.25 6.15625 13.5625 6.4375C13.8438 6.75 14 7.09375 14 7.5V14.5C14 14.9375 13.8438 15.2812 13.5625 15.5625C13.25 15.875 12.9062 16 12.5 16H1.5C1.0625 16 0.71875 15.875 0.4375 15.5625C0.125 15.2812 0 14.9375 0 14.5V7.5C0 7.09375 0.125 6.75 0.4375 6.4375C0.71875 6.15625 1.0625 6 1.5 6H2.5V4.5C2.5 3.6875 2.6875 2.9375 3.09375 2.25C3.5 1.5625 4.0625 1.03125 4.75 0.625C5.4375 0.21875 6.1875 0 7 0C7.8125 0 8.5625 0.21875 9.25 0.625C9.9375 1.03125 10.4688 1.59375 10.875 2.28125C11.2812 2.96875 11.5 3.71875 11.5 4.53125V6H12.5ZM4 4.5V6H10V4.5C10 3.6875 9.6875 2.96875 9.125 2.375C8.53125 1.8125 7.8125 1.5 7 1.5C6.15625 1.5 5.4375 1.8125 4.875 2.375C4.28125 2.96875 4 3.6875 4 4.5ZM12.5 14.5V7.5H1.5V14.5H12.5Z"
      fill="#111111"
    />
  </svg>
);
export default LockIcon;
