import React from 'react';

const Alert = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M12.1327 21.2321C17.1581 21.2321 21.2321 17.1581 21.2321 12.1327C21.2321 7.10716 17.1581 3.0332 12.1327 3.0332C7.10716 3.0332 3.0332 7.10716 3.0332 12.1327C3.0332 17.1581 7.10716 21.2321 12.1327 21.2321Z"
      stroke="#13142A"
      strokeWidth="2.0221"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1328 8.08838V12.1326"
      stroke="#13142A"
      strokeWidth="2.0221"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1328 16.1768H12.1428"
      stroke="#13142A"
      strokeWidth="2.0221"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Alert;
