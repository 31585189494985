import React from 'react';

const InfoTransparent = ({
  width = '22',
  height = '21',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 21"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.1327 2.2134C8.1706 2.2134 5.485 3.79316 3.98422 6.32078C2.52294 8.8879 2.52294 12.0079 3.98422 14.5356C5.485 17.1027 8.1706 18.643 11.1327 18.643C14.0552 18.643 16.7408 17.1027 18.2416 14.5356C19.7029 12.0079 19.7029 8.8879 18.2416 6.32078C16.7408 3.79316 14.0552 2.2134 11.1327 2.2134ZM11.1327 20.5387C7.4992 20.5387 4.18169 18.643 2.36496 15.4834C0.548229 12.3634 0.548229 8.53246 2.36496 5.37293C4.18169 2.25289 7.4992 0.317677 11.1327 0.317677C14.7266 0.317677 18.0441 2.25289 19.8609 5.37293C21.6776 8.53246 21.6776 12.3634 19.8609 15.4834C18.0441 18.643 14.7266 20.5387 11.1327 20.5387ZM9.55289 13.5877H10.5008V11.0601H9.55289C8.99997 11.0601 8.60503 10.6651 8.60503 10.1122C8.60503 9.5988 8.99997 9.16436 9.55289 9.16436H11.4486C11.962 9.16436 12.3965 9.5988 12.3965 10.1122V13.5877H12.7124C13.2258 13.5877 13.6603 14.0221 13.6603 14.5356C13.6603 15.0885 13.2258 15.4834 12.7124 15.4834H9.55289C8.99997 15.4834 8.60503 15.0885 8.60503 14.5356C8.60503 14.0221 8.99997 13.5877 9.55289 13.5877ZM11.1327 7.90055C10.4218 7.90055 9.86884 7.34763 9.86884 6.63674C9.86884 5.96534 10.4218 5.37293 11.1327 5.37293C11.8041 5.37293 12.3965 5.96534 12.3965 6.63674C12.3965 7.34763 11.8041 7.90055 11.1327 7.90055Z"
      fill={fill}
    />
  </svg>
);

export default InfoTransparent;
