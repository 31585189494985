import React from 'react';

const ConnectNewEvent = ({
  width = '1.2rem',
  height = '1.2rem',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 24 28.959"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g data-name="Group 2179" transform="translate(-513.737 7758.959)">
      <path
        fillRule="evenodd"
        transform="translate(524 -7754)"
        d="M0,17.5A6.5,6.5,0,1,1,6.5,24,6.5,6.5,0,0,1,0,17.5Zm7.5.75H9a.75.75,0,0,0,0-1.5H7.5a.25.25,0,0,1-.25-.25V15a.75.75,0,0,0-1.5,0v1.5a.25.25,0,0,1-.25.25H4a.75.75,0,0,0,0,1.5H5.5a.25.25,0,0,1,.25.25V20a.75.75,0,0,0,1.5,0V18.5A.25.25,0,0,1,7.5,18.25Z"
      />
      <g transform="translate(513.737 -7758.959)">
        <g>
          <path
            transform="translate(-419.035 -1698.146)"
            d="M428.767,1707.3a.511.511,0,0,0-.709,0l-8.287,8.284a2.494,2.494,0,0,0-.735,1.775,2.581,2.581,0,0,0,2.507,2.507,2.5,2.5,0,0,0,1.77-.732l8.287-8.284a.506.506,0,0,0,0-.712Z"
          />
          <path
            transform="translate(-419.016 -1698.172)"
            d="M442.576,1706.264l-1.063-1.063a1,1,0,0,0-1.418,0,1.042,1.042,0,0,1-1.418,0,1.012,1.012,0,0,1-.225-1.083,1.506,1.506,0,0,0-.336-1.6c-1.436-1.444-2.013-2.026-2.3-2.257h.007a7.77,7.77,0,0,0-10.06-.451,1,1,0,0,0,.641,1.805,5.057,5.057,0,0,1,3.763,1.454c.518.521.781.923.781,1.194a.422.422,0,0,1-.151.311l-.985.983a.505.505,0,0,0,0,.712l2.836,2.828a.493.493,0,0,0,.709,0l1.064-1.063a1.042,1.042,0,0,1,1.417,0,1.011,1.011,0,0,1,0,1.424,1,1,0,0,0,0,1.414l1.064,1.073a1.5,1.5,0,0,0,2.127,0l3.546-3.551a1.5,1.5,0,0,0,0-2.126Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ConnectNewEvent;
