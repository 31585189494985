import React from 'react';

const ContractTemplateActive = ({
  width = '1.2rem',
  height = '1.2rem',
  fill = '#164144',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.75 5C0.75 3.63281 1.84375 2.5 3.25 2.5H9.5V7.5C9.5 8.20312 10.0469 8.75 10.75 8.75H15.75V13.6719L12.3516 17.0703C11.9219 17.4609 11.6484 17.9688 11.5312 18.5156L10.7891 21.4453C10.7109 21.7969 10.7109 22.1875 10.8281 22.5391H3.25C1.84375 22.5391 0.75 21.4062 0.75 20.0391V5ZM15.75 7.5H10.75V2.5L15.75 7.5ZM22.1953 11.7188L22.7812 12.3047C23.3672 12.8906 23.3672 13.8672 22.7812 14.4922L21.6094 15.6641L18.8359 12.8906L20.0078 11.7188C20.5938 11.1328 21.6094 11.1328 22.1953 11.7188ZM12.8984 18.7891L17.9766 13.75L20.75 16.5234L15.6719 21.5625C15.5156 21.7188 15.3203 21.8359 15.125 21.9141L12.7422 22.5C12.5469 22.5391 12.3125 22.5 12.1562 22.3438C12 22.1875 11.9609 21.9531 12 21.7188L12.5859 19.375C12.625 19.1797 12.7422 18.9453 12.8984 18.7891Z"
      fill={fill}
    />
  </svg>
);

export default ContractTemplateActive;
