import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import Lottie from 'react-lottie';

import styles from './styles';
import Box from '../../../../../../components/atomic/Box';
import Typography from '../../../../../../components/atomic/Typography';
import useDesktop from '../../../../../../hooks/useDesktop';
import successAnimation from '../../../../../../assets/animations/successAnimation.json';

const BYBFEventSuccess = ({ classes, nextStep }) => {
  const isDesktop = useDesktop();
  const [showAnimation, setShowAnimation] = useState(false);
  const animationOptions = {
    loop: false,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    setTimeout(() => {
      setShowAnimation(true);
    }, 500);

    setTimeout(() => {
      setShowAnimation(false);
      nextStep();
    }, 2000);
  }, []);

  return (
    <Box
      className={isDesktop ? classes.desktopContainer : classes.mobileContainer}
    >
      {showAnimation && (
        <div className={classes.animationContainer}>
          <Lottie
            options={animationOptions}
            height={200}
            width={200}
            isStopped={!showAnimation}
          />
          <Typography color="primary" variant="h2">
            Hendelse opprettet
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default withStyles(styles)(BYBFEventSuccess);
