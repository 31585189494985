import React from 'react';
import { withStyles } from '@material-ui/core';

import {
  getOwnershipName,
  getOwnershipShare,
  getOwnershipSalesDate,
  getOwnershipSalesType,
  getOwnershipSalesPrice,
  getOwnershipUpdatedDate,
} from '../../utils/financials';

import styles from './style';
import Box from '../../components/atomic/Box';
import List from '../../components/atomic/List';
import { formattedDate } from '../../utils/dates';
import SeeMoreLink from '../../components/SeeMoreLink';
import { splitAndCapitalize } from '../../utils/strings';
import Typography from '../../components/atomic/Typography';
import ListItem from '../../components/CardList/KeyValueListItem';
import ContentLoader from '../../components/loaders/ContentLoader';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';
import matomo from '../../utils/matomo';

const PropertyOwnership = ({
  classes,
  fetching,
  ownership,
  ownershipCount,
  title = 'Hjemmelsinformasjon',
}) => {
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const link = `${dashboardLinkPrefix}/economy/ownerships`;
  const showLink = ownershipCount > 1;

  const dateFormat = 'DD.MM.YYYY';

  const onClickSeeMore = () => {
    matomo.clickEvent({
      category: 'Value Estimate and Finances',
      name: 'See More Authorisation Information',
      action: 'See Owners and Finances',
    });
  };

  if (fetching) {
    return (
      <List>
        <ContentLoader width={240} height={20} />
        <br />
        <br />
        <ContentLoader
          width={50}
          height={20}
          className={classes.listItemLoading}
        />
        <ContentLoader
          width={100}
          height={20}
          className={classes.listItemLoading}
        />
        <ContentLoader
          width={80}
          height={20}
          className={classes.listItemLoading}
        />
        <ContentLoader
          width={120}
          height={20}
          className={classes.listItemLoading}
        />
        <ContentLoader
          width={80}
          height={20}
          className={classes.listItemLoading}
        />
        <ContentLoader className={classes.listItemLoading} width={150} />
      </List>
    );
  }

  return (
    <>
      <List>
        <Typography variant="h2" className={classes.title}>
          {title}
          {ownershipCount > 1 && ` (1/${ownershipCount})`}
        </Typography>

        <ListItem label="Navn">
          {splitAndCapitalize(getOwnershipName(ownership))}
        </ListItem>
        <ListItem label="Ideell andel">{getOwnershipShare(ownership)}</ListItem>
        <ListItem label="Beløp">{`${getOwnershipSalesPrice(
          ownership
        )} NOK`}</ListItem>
        <ListItem label="Omsetning">
          {getOwnershipSalesType(ownership)}
        </ListItem>
        <ListItem label="Dato">
          {formattedDate(getOwnershipSalesDate(ownership), dateFormat)}
        </ListItem>
        <ListItem label="Sist oppdatert">
          {formattedDate(getOwnershipUpdatedDate(ownership), dateFormat)}
        </ListItem>
      </List>

      {showLink && (
        <Box py={2} textAlign="right">
          <SeeMoreLink to={link} onClick={onClickSeeMore} />
        </Box>
      )}
    </>
  );
};

export default withStyles(styles)(PropertyOwnership);
