import React from 'react';
import Box from '../../../../../../components/atomic/Box';
import useDesktop from '../../../../../../hooks/useDesktop';
import { useSectionStyles } from '../sectionStyles';

export function OverviewBox({ children }) {
  const isDesktop = useDesktop();
  const classes = useSectionStyles();
  return (
    <Box
      px={isDesktop ? 3 : 1}
      mt={isDesktop ? 2 : 4}
      width="100%"
      // If no class is passed to props, return empty string
      className={classes ? classes.section_seperator : ''}
    >
      {children}
    </Box>
  );
}
