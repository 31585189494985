import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import ListItem from '../../../../../components/atomic/ListItem';
import ContentLoader from '../../../../../components/loaders/ContentLoader';

const NotificationItemLoading = ({ classes }) => (
  <ListItem className={classes.container}>
    <div className={classes.titleContainer}>
      <ContentLoader height={15} width={100} />
      <ContentLoader height={15} width={90} />
    </div>
    <br />
    <ContentLoader height={15} width={180} />
    <br />
    <ContentLoader height={15} width={240} />
  </ListItem>
);

export default withStyles(styles)(NotificationItemLoading);
