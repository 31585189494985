const styles = (theme) => ({
  listItemLoader: {
    margin: '35px 0',
    '&:last-child': {
      margin: '35px 0 30px',
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  listLink: {
    marginBottom: '8px',
    '&:last-child': {
      marginBottom: '0',
    },
  },
});

export default styles;
