import { withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { createRoom, uploadImage } from '../../../../../../../api/rooms';
import RoomForm from '../../../../../../../components/forms/RoomForm';
import useDesktop from '../../../../../../../hooks/useDesktop';
import useSnackbar from '../../../../../../../hooks/useSnackbar';
import { acAddRoom } from '../../../../../../../store/actions/rooms';
import { uploadImages } from '../../../../../../../utils/images';
import {
  getPropertyBoligmappaNumber,
  getPropertyType,
} from '../../../../../../../utils/properties';
import { getRoomImages } from '../../../../../../../utils/rooms';
import styles from './styles';

const CreateRoomWrapper = ({
  classes,
  property,
  addRoom,
  onClose,
  onRoomCreated,
}) => {
  const { showSuccessMessage } = useSnackbar();
  const isDesktop = useDesktop();
  const [error, setError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [isImageUpdate, setIsImageUpdate] = useState(false);

  const onSubmitRoomComplete = (resetFields, roomObj) => {
    addRoom(roomObj);
    setRequestInProgress(false);
    onClose(true);
    showSuccessMessage('Rom opprettet.');
    onRoomCreated({ label: roomObj.title, value: roomObj.id });
    resetFields();
    setCurrentRoom(null);
  };

  const uploadRoomImages = (images, roomId, resetFields, roomObj) => {
    const uploadImageRequest = (image, position) =>
      uploadImage(roomId, image, position);
    uploadImages({
      imageFiles: images,
      positionStart: 0,
      uploadRequest: uploadImageRequest,
      onComplete: (uploadedImages) => {
        onSubmitRoomComplete(resetFields, {
          ...roomObj,
          gallery: uploadedImages || [],
        });
      },
      onError: () => {},
      onImageUploaded: () => {},
    });
  };

  const onRoomFormSubmit = async (room, resetFields) => {
    setError(null);
    setRequestInProgress(true);

    const [errorResponse, data] = await createRoom({
      ...room,
      boligmappaNumber: getPropertyBoligmappaNumber(property),
      propertyType: getPropertyType(property),
    });

    if (errorResponse) {
      setError(errorResponse);
      setRequestInProgress(false);
    } else {
      const roomObj = {
        ...room,
        ...data,
        gallery: currentRoom?.gallery || [],
      };
      if (imageFiles?.length > 0) {
        uploadRoomImages(imageFiles, data.id, resetFields, roomObj);
      } else {
        onSubmitRoomComplete(resetFields, roomObj);
      }
    }
  };

  const onRoomFormOpen = (room) => {
    const roomData = {
      ...room,
      id: -9999,
    };
    setCurrentRoom(roomData);
  };

  const onRoomImageUpload = (uploadedImages, recentFiles) => {
    setImageFiles([...imageFiles, ...recentFiles]);
    setIsImageUpdate(true);
    setCurrentRoom((prevState) => ({
      ...prevState,
      gallery: [...(getRoomImages(prevState) || []), ...uploadedImages],
    }));
  };

  const onRoomImageDelete = (imagesToBeUpload, removedIndex) => {
    setImageFiles((preval) => {
      const files = preval;
      files.splice(removedIndex, 1);
      return files;
    });
    setIsImageUpdate(true);
    setCurrentRoom((prevState) => ({
      ...prevState,
      gallery: [...imagesToBeUpload],
    }));
  };

  return (
    <RoomForm
      boligmappaNumber={getPropertyBoligmappaNumber(property)}
      propertyType={getPropertyType(property)}
      disableEventSelection
      disableDocumentSelection
      onSubmit={onRoomFormSubmit}
      isDialog={false}
      submitButtonText="Opprett"
      error={error}
      requestInProgress={requestInProgress}
      onRoomFormOpen={onRoomFormOpen}
      onRoomImageUpload={onRoomImageUpload}
      onRoomImageDelete={onRoomImageDelete}
      room={currentRoom}
      isDesktop={isDesktop}
      isImageUpdate={isImageUpdate}
      actionButtonsClassName={classes.actionBtnRoomOverlay}
      showLargeSubmitButton
      centeredActions
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  addRoom: (room) => dispatch(acAddRoom(room)),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(CreateRoomWrapper));
