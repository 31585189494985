export const categoryOther = 'Annet';

// TODO fetch event types from API
export const eventTypes = [
  'Oppussing',
  'Baderomsrenovering',
  'Containerleie',
  'Elektrikertjenester',
  'Elektrisk anlegg',
  'Grunnmur/såle',
  'Gulvlegging',
  'Gulvpigging',
  'Gulvsliping',
  'Isolering',
  'Kjøkkenmontering',
  'Kjøkkenrenovering',
  'Malertjenester',
  'Membran',
  'Montering av el.artikler',
  'Reparasjon av mur',
  'Riving',
  'Rørleggertjenester',
  'Sette opp vegg',
  'Stiger og stillaser',
  'Snekkertjenester',
  'Taklegging og -reparasjon',
  'Takrenner og snøfangere',
  'Trapp',
  'Vannbåren varme',
  'Vinduer og dører',
  'Yttervegg',
  categoryOther,
];
