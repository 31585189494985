import React from 'react';

const EventIcon = ({ width = '0.9rem', height = '0.9rem', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.375 1.5C0.257812 1.5 0.164062 1.47656 0.09375 1.40625C0.0234375 1.33594 0 1.24219 0 1.125V0.375C0 0.28125 0.0234375 0.1875 0.09375 0.117188C0.164062 0.046875 0.257812 0 0.375 0H10.125C10.2188 0 10.3125 0.046875 10.3828 0.117188C10.4531 0.1875 10.5 0.28125 10.5 0.375V1.125C10.5 1.24219 10.4531 1.33594 10.3828 1.40625C10.3125 1.47656 10.2188 1.5 10.125 1.5H0.375ZM11.625 3.75C11.7188 3.75 11.8125 3.79688 11.8828 3.86719C11.9531 3.9375 12 4.03125 12 4.125V4.875C12 4.99219 11.9531 5.08594 11.8828 5.15625C11.8125 5.22656 11.7188 5.25 11.625 5.25H1.875C1.75781 5.25 1.66406 5.22656 1.59375 5.15625C1.52344 5.08594 1.5 4.99219 1.5 4.875V4.125C1.5 4.03125 1.52344 3.9375 1.59375 3.86719C1.66406 3.79688 1.75781 3.75 1.875 3.75H11.625ZM10.125 7.5C10.2188 7.5 10.3125 7.54688 10.3828 7.61719C10.4531 7.6875 10.5 7.78125 10.5 7.875V8.625C10.5 8.74219 10.4531 8.83594 10.3828 8.90625C10.3125 8.97656 10.2188 9 10.125 9H0.375C0.257812 9 0.164062 8.97656 0.09375 8.90625C0.0234375 8.83594 0 8.74219 0 8.625V7.875C0 7.78125 0.0234375 7.6875 0.09375 7.61719C0.164062 7.54688 0.257812 7.5 0.375 7.5H10.125Z"
      fill="#111111"
    />
  </svg>
);
export default EventIcon;
