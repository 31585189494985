const styles = (theme) => ({
  hoverHeaderBox: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 25,
    cursor: 'pointer',
    height: 32,
    justifyContent: 'center',
    padding: '0 20px 0 20px',
    border: 'solid 0.1rem #D1EDE8',
    '&:hover': {
      backgroundColor: '#F2F8F8',
      transition: 'background-color 0.4s ease-in',
      border: 'solid 0.1rem white',
    },
  },
  deleteAction: {
    border: 'solid 0.1rem red',
  },
  arrow: {
    width: '10px',
    marginLeft: '8px',
    fill: theme.palette.text.primary,
    paddingTop: '12px',
  },
});

export default styles;
