import React, { createElement } from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Box from '../atomic/Box';
import { filterTypes } from './util';
import SortingMenuItem from './SortingMenuItem';

const DocumentFilters = ({
  classes,
  filters,
  fetching,
  sortType,
  documents,
  fetchingError,
  onFiltersChanged,
  onSortTypeChange,
}) => {
  if (fetching || fetchingError || !Array.isArray(documents)) {
    return null;
  }

  return (
    <Box className={classes.root}>
      {filterTypes
        .filter(({ desktopOnly }) => !desktopOnly)
        .map(
          ({
            type,
            name,
            getProps,
            description,
            filterComponent,
            getFilterOptions,
          }) =>
            createElement(filterComponent, {
              name,
              description,
              key: `filter-option-${type}`,
              onFiltersReset: () =>
                onFiltersChanged({
                  ...filters,
                  [type]: [],
                }),
              filterOptions:
                getFilterOptions &&
                getFilterOptions(documents, filters[type], (updatedFilters) =>
                  onFiltersChanged({
                    ...filters,
                    [type]: updatedFilters,
                  })
                ),
              ...(getProps ? getProps(filters, onFiltersChanged) : undefined),
            })
        )}

      <SortingMenuItem
        sortType={sortType}
        onSortTypeChange={onSortTypeChange}
      />
    </Box>
  );
};

export default withStyles(styles)(DocumentFilters);
