import React from 'react';

const Drag = ({ width = '1.2rem', height = '1.2rem', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14.058 11.359"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g data-name="Group 1986" transform="translate(0 0)">
      <g data-name="Group 1985">
        <path
          transform="translate(0 3.807) rotate(-90)"
          d="M1.9,3.807A1.9,1.9,0,1,0,0,1.9,1.9,1.9,0,0,0,1.9,3.807Z"
        />
        <path
          transform="translate(5.125 3.807) rotate(-90)"
          d="M1.9,3.807A1.9,1.9,0,1,0,0,1.9,1.9,1.9,0,0,0,1.9,3.807Z"
        />
        <path
          transform="translate(10.251 3.807) rotate(-90)"
          d="M1.9,3.807A1.9,1.9,0,1,0,0,1.9,1.9,1.9,0,0,0,1.9,3.807Z"
        />
      </g>
      <path
        data-name="Oval_19"
        transform="translate(0 11.359) rotate(-90)"
        d="M1.9,3.807A1.9,1.9,0,1,0,0,1.9,1.9,1.9,0,0,0,1.9,3.807Z"
      />
      <path
        data-name="Oval_20"
        transform="translate(5.125 11.359) rotate(-90)"
        d="M1.9,3.807A1.9,1.9,0,1,0,0,1.9,1.9,1.9,0,0,0,1.9,3.807Z"
      />
      <path
        data-name="Oval_21"
        transform="translate(10.251 11.359) rotate(-90)"
        d="M1.9,3.807A1.9,1.9,0,1,0,0,1.9,1.9,1.9,0,0,0,1.9,3.807Z"
      />
    </g>
  </svg>
);

export default Drag;
