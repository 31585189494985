import React from 'react';
import CirclesLoader from '../../loaders/CirclesLoader';
import { useStyles } from './styles';

import Button from '../../atomic/Button';

function PrimaryButton({
  children,
  variant = 'filled',
  isLoading = false,
  ...props
}) {
  const classes = useStyles();

  return (
    <Button
      className={variant === 'filled' ? classes.filled : classes.outlined}
      color="primary"
      variant={variant === 'filled' ? 'contained' : 'outlined'}
      {...props}
    >
      {isLoading ? (
        <CirclesLoader width={30} height={20} className={classes.loader} />
      ) : (
        children
      )}
    </Button>
  );
}

export default PrimaryButton;
