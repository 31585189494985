import React from 'react';

const CarouselControlArrowRight = ({
  width = '1.2rem',
  height = '1.2rem',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 6.231 11.047"
    {...props}
  >
    <path
      id="left-arrow-icon-pink"
      d="M125,312.932l4.816,4.816L125,322.564"
      transform="translate(-124.293 -312.225)"
      fill="none"
      stroke="#254e52"
      strokeLinecap="round"
      strokeWidth="1"
    />
  </svg>
);

export default CarouselControlArrowRight;
