import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Dialog, DialogContent } from '@mui/material';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import Typography from '../../../../../../../components/atomic/Typography';
import IconButton from '../../../../../../../components/atomic/IconButton';
import Close from '../../../../../../../assets/icons/Close';
import Checkbox from '../../../../../../../components/atomic/Checkbox';
import ProgressButton from '../../../../../../../components/ProgressButton';
import Button from '../../../../../../../components/atomic/Button';
import useDesktop from '../../../../../../../hooks/useDesktop';
import {
  getAccessRequestsData,
  patchAccessRequest,
} from '../../../../../../../api/properties';
import { sGetAccessRequests } from '../../../../../../../store/reducers/accessRequests';
import { actionSetAccessRequests } from '../../../../../../../store/actions/accessRequests';
import style from './style';
import localStorage from '../../../../../../../utils/localStorage';
import useSnackbar from '../../../../../../../hooks/useSnackbar';
import useActionDialog from '../../../../../../../hooks/useActionDialog';
import matomo from '../../../../../../../utils/matomo';

const AccessRequestDialog = ({
  classes,
  accessRequestDialog,
  setAccessRequestDialog,
  selectedAccessRequest,
  accessRequests,
  setAccessRequests,
  dashboardLinkPrefix,
}) => {
  const { showLoadingDialog } = useActionDialog();
  const history = useHistory();
  const { showSuccessMessage, showErrorMessage } = useSnackbar();
  const [sharePermit, setSharePermit] = useState(false);
  const firstExpiringRequest = () => {
    const getTimestamp = (time) => new Date(time).valueOf();
    const pendingRequests = accessRequests.filter(
      (el) => el.status.status === 'Pending'
    );
    const sortedByExpiryDate = pendingRequests.sort(
      (a, b) => getTimestamp(a.expiryDate) - getTimestamp(b.expiryDate)
    );
    return sortedByExpiryDate[0];
  };
  const activeRequest = selectedAccessRequest || firstExpiringRequest();
  const { boligmappaNumber } = useParams();
  const isDesktop = useDesktop();
  const getCompany = () => activeRequest.requester?.company || '...';
  const updateRequests = async () => {
    const [error, response] = await getAccessRequestsData(boligmappaNumber);
    if (!error && response) {
      setAccessRequests(response);
    }
  };

  const trackMatomoEvent = (name) => {
    matomo.clickEvent({
      category: 'Sharing Options',
      name: `${name} Surveyor Request`,
      action: 'Sharing With Surveyors',
    });
  };

  const onAccessRequestChange = async (status) => {
    const closeDialog = showLoadingDialog('Sletter...');
    const [error, response] = await patchAccessRequest(activeRequest, status);
    closeDialog();
    await updateRequests();
    if (!error && response) {
      showSuccessMessage(
        status === 'Granted'
          ? `${getCompany()} har fått tilgang til din boligmappe.`
          : `Forespørselen er avslått`
      );
      trackMatomoEvent(status);
      setAccessRequestDialog(false);
      setSharePermit(false);
      if (status === 'Granted' && dashboardLinkPrefix) {
        history.push(`${dashboardLinkPrefix}/more/private-share`);
      }
    } else {
      showErrorMessage(error);
    }
  };
  const onDialogClose = () => {
    if (setAccessRequestDialog) {
      setAccessRequestDialog(false);
      localStorage.set('accessRequestsSeen', true);
    }
    setSharePermit(false);
  };
  return (
    <Dialog open={accessRequestDialog} onClose={onDialogClose}>
      <DialogContent
        className={clsx(classes.dialogContent, !isDesktop && 'mobile')}
      >
        <div className={classes.rowBetween}>
          <Typography
            variant="h2"
            className={clsx(classes.permitHeadline, !isDesktop && 'mobile')}
          >
            Del med bygningssakkyndig
          </Typography>
          <IconButton className={classes.closeButton} onClick={onDialogClose}>
            <Close />
          </IconButton>
        </div>
        <Typography
          variant="p"
          className={clsx(classes.dialogDescription, !isDesktop && 'mobile')}
        >
          {activeRequest?.requester.firstName.concat(
            ' ',
            activeRequest?.requester.lastName
          )}{' '}
          i {activeRequest && getCompany()} ønsker tilgang til din boligmappe i
          forbindelse med et oppdrag ved din bolig. Når du deler med
          bygningssakkyndig får de opp dine dokumenter i sitt fagsystem. De vil
          kunne lese og laste ned dokumentene. Tilgangen gjelder i tre måneder,
          eller til du sletter den.
        </Typography>
        <div className={clsx(classes.baseRow, classes.checkboxContainer)}>
          <Checkbox
            style={{ paddingLeft: 'unset' }}
            onChange={() => setSharePermit(!sharePermit)}
            label={
              <p
                className={clsx(classes.checkboxLabel, !isDesktop && 'mobile')}
              >
                Jeg samtykker til å dele min boligmappe
              </p>
            }
            value={sharePermit}
            className={clsx(classes.permitCheckbox, !isDesktop && 'mobile')}
            required
          />
        </div>
        <div className={classes.buttonRow}>
          <ProgressButton
            className={clsx(
              classes.addShare,
              classes.dialogButton,
              !isDesktop && 'mobile'
            )}
            requestInProgress={false}
            variant="contained"
            onClick={() => sharePermit && onAccessRequestChange('Granted')}
            color="primary"
            disabled={!sharePermit}
          >
            Gi tilgang
          </ProgressButton>
          <Button
            onClick={() => onAccessRequestChange('Denied')}
            className={clsx(classes.rejectButton, !isDesktop && 'mobile')}
          >
            Avslå forespørsel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  accessRequests: sGetAccessRequests(state),
});
const mapDispatchToProps = (dispatch) => ({
  setAccessRequests: (accessRequests) =>
    dispatch(actionSetAccessRequests(accessRequests)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(AccessRequestDialog));
