const styles = () => ({
  close_dialog_heading: {
    fontSize: '21px',
    marginBottom: '30px',
  },
  close_dialog_text: {
    margin: 0,
    textAlign: 'center',
  },
});

export default styles;
