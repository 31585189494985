import React from 'react';
import FilterItem from '../FilterItem';
import MultiSelectComboBox from '../../MultiSelectComboBox';
import useMenuState from '../../../hooks/useMenuState';

const OptionListFilter = ({
  name,
  description,
  filterOptions,
  onFiltersReset,
  isSortable = false,
  isFilterable = false,
  currentSortingType,
  onSortTypeChange,
  sortUpType,
  sortDownType,
  isVisible,
  // onMenuSave,   This is need when in edit mode
}) => {
  const {
    onMenuOpen,
    onMenuClose,
    onOptionMenuClose,
    open: menuOpen,
    setOpen: setMenuOpen,
  } = useMenuState();

  return (
    isVisible && (
      <>
        <FilterItem
          name={name}
          onClick={onMenuOpen}
          onSortTypeChange={onSortTypeChange}
          isSortable={isSortable}
          isFilterable={isFilterable}
          sortUpType={sortUpType}
          sortDownType={sortDownType}
          currentSortingType={currentSortingType}
        />

        <MultiSelectComboBox
          isBulk={false}
          isFiltering
          description={description}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          onMenuClose={onOptionMenuClose}
          onFiltersReset={onFiltersReset}
          filterOptions={filterOptions}
          onMenuSave={onMenuClose}
        />
      </>
    )
  );
};

export default OptionListFilter;
