import React from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import styles from './style';
import BaseDialog from '../atomic/Dialog';
import DialogContent from '../atomic/DialogContent';
import useDesktop from '../../hooks/useDesktop';
import Typography from '../atomic/Typography';
import Button from '../atomic/Button';
import {
  acSetEventReturnOnSuccess,
  acSetShowAddEvent,
  acSetShowEventCreateSuggesion,
} from '../../store/actions/events';
import { acSetMultipleDocumentsSelected } from '../../store/actions/documents';
import matomo from '../../utils/matomo';

const EventCreateSuggesion = ({
  open,
  classes,
  documents,
  onClose,
  setShowAddEvent,
  setReturnonSuccess,
  mulitipleDocumentsSelected,
  setMulitipleDocumentsSelected,
  setShowEventCreateSuggesion,
}) => {
  const isDesktop = useDesktop();

  const onClickCreateEvent = () => {
    if (mulitipleDocumentsSelected) setMulitipleDocumentsSelected(true);
    else setMulitipleDocumentsSelected(false);

    matomo.clickEvent({
      category: 'Document handling',
      action: 'Suggest Event',
      name: 'Create New Event from Suggest Event',
      value: documents?.length,
    });

    setShowAddEvent(true);
    setShowEventCreateSuggesion(false);
    setReturnonSuccess(true);
  };

  return (
    <BaseDialog
      onClose={onClose}
      className={classes.dialogBox}
      open={open}
      scroll="paper"
      PaperProps={{ style: { borderRadius: 16 } }}
    >
      <DialogContent
        className={isDesktop ? classes.dialogDesktop : classes.dialogMobile}
        classes={{ paperScrollPaper: classes.customPaperStyle }}
      >
        <Typography className={classes.header}>Opprett ny hendelse</Typography>
        <Typography className={classes.text}>
          Hendelser gjør at både du og fremtidige eiere enkelt kan holde
          oversikt over arbeid utført på boligen.
        </Typography>

        <Button
          onClick={onClickCreateEvent}
          varinat="text"
          color="primary"
          className={classes.saveButton}
        >
          Opprett en ny hendelse
        </Button>

        <Typography className={classes.hintText}>
          {documents?.length} dokumenter blir lagt til.
        </Typography>

        <Button
          onClick={onClose}
          varinat="text"
          color="primary"
          className={classes.dismissButton}
        >
          Ikke nå
        </Button>
      </DialogContent>
    </BaseDialog>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setShowAddEvent: (payload) => dispatch(acSetShowAddEvent(payload)),
  setEventReturnOnSuccess: (payload) =>
    dispatch(acSetEventReturnOnSuccess(payload)),
  setMulitipleDocumentsSelected: (payload) =>
    dispatch(acSetMultipleDocumentsSelected(payload)),
  setShowEventCreateSuggesion: (payload) =>
    dispatch(acSetShowEventCreateSuggesion(payload)),
  setReturnonSuccess: (payload) => dispatch(acSetEventReturnOnSuccess(payload)),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(EventCreateSuggesion));
