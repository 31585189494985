import React from 'react';

const PublicShare = (props) => (
  <svg
    viewBox="0 0 14.908 13.295"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <style>{`.publicSharingIconClass{fill:none;stroke:currentColor;stroke-miterlimit:10;stroke-width:0.5px;}`}</style>
    </defs>
    <g transform="translate(0.375 0.375)">
      <circle
        r="2.091"
        cx="2.091"
        cy="2.091"
        className="publicSharingIconClass"
        transform="translate(9.977)"
      />
      <circle
        r="2.091"
        cx="2.091"
        cy="2.091"
        className="publicSharingIconClass"
        transform="translate(0 4.182)"
      />
      <circle
        r="2.091"
        cx="2.091"
        cy="2.091"
        className="publicSharingIconClass"
        transform="translate(9.977 8.363)"
      />
      <line
        y2="2.66"
        x2="5.934"
        className="publicSharingIconClass"
        transform="translate(4.043 7.008)"
      />
      <line
        y2="2.66"
        x1="5.935"
        className="publicSharingIconClass"
        transform="translate(4.182 2.825)"
      />
    </g>
  </svg>
);

export default PublicShare;
