import React from 'react';

const ChapterFour = ({
  width = '100%',
  height = '100%',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 33.175 29.993"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-2 0)">
      <path
        fill={fill}
        fillRule="evenodd"
        transform="translate(-556.795 -4462.776)"
        d="M574,4484.644c0-4.491,4.022-8.123,8.985-8.123s8.979,3.645,8.985,8.123-4.022,8.125-8.985,8.125S574,4489.135,574,4484.644Zm9.123,2.435,4.009-4.842h.008a.851.851,0,0,0,.124-.924,1.019,1.019,0,0,0-.829-.561,1.076,1.076,0,0,0-.954.365l-3.777,4.556a.35.35,0,0,1-.252.118.337.337,0,0,1-.268-.091l-1.8-1.613a1.067,1.067,0,0,0-1.015-.274.969.969,0,0,0-.748.678.873.873,0,0,0,.3.911l2.074,1.875a2.173,2.173,0,0,0,1.613.547A2.109,2.109,0,0,0,583.123,4487.078Z"
      />
      <path
        fill={fill}
        transform="translate(-559.375 -4463.428)"
        d="M568.287,4476.234h8.985a.942.942,0,1,0,0-1.874h-8.985a.942.942,0,1,0,0,1.874Z"
      />
      <path
        fill={fill}
        transform="translate(-559.375 -4462.415)"
        d="M568.287,4479.6h4.147a.942.942,0,1,0,0-1.874h-4.147a.942.942,0,1,0,0,1.874Z"
      />
      <path
        fill={fill}
        transform="translate(-559.375 -4461.4)"
        d="M568.287,4482.955h3.8a.942.942,0,1,0,0-1.874h-3.8a.942.942,0,1,0,0,1.874Z"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        transform="translate(-559.088 -4465.962)"
        d="M580.441,4473.458a.333.333,0,0,1-.346.312h-11.75a.332.332,0,0,1-.346-.312v-3.75a.333.333,0,0,1,.346-.312h2.419v-.208a3.231,3.231,0,0,1,2.517-3.111,3.736,3.736,0,0,1,3.022.521,3.034,3.034,0,0,1,1.373,2.486v.313H580.1a.334.334,0,0,1,.346.312Zm-5.184-4.687a1.042,1.042,0,1,0-1.037.937A.989.989,0,0,0,575.257,4468.772Z"
      />
      <path
        fill={fill}
        transform="translate(-561 -4465.166)"
        d="M578.15,4492.789a.361.361,0,0,0-.276-.13H566.456a.665.665,0,0,1-.691-.625v-20.308a.656.656,0,0,1,.691-.625h1.037a.334.334,0,0,0,.346-.313v-1.874a.334.334,0,0,0-.346-.313h-1.728a2.642,2.642,0,0,0-2.765,2.5v21.558a2.641,2.641,0,0,0,2.765,2.5h14a.336.336,0,0,0,.323-.209.284.284,0,0,0-.1-.338A10.229,10.229,0,0,1,578.15,4492.789Z"
      />
      <path
        fill={fill}
        transform="translate(-555.074 -4465.166)"
        d="M582.939,4477.167a.362.362,0,0,0,.281-.078.3.3,0,0,0,.119-.248V4471.1a2.642,2.642,0,0,0-2.765-2.5h-1.728a.337.337,0,0,0-.245.091.3.3,0,0,0-.1.222v1.874a.333.333,0,0,0,.346.313h1.037a.656.656,0,0,1,.691.625v5.011a.288.288,0,0,0,.108.222.365.365,0,0,0,.257.091c.109,0,.217-.013.326-.013A10.268,10.268,0,0,1,582.939,4477.167Z"
      />
    </g>
  </svg>
);

export default ChapterFour;
