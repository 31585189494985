import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './style';
import { uploadFile } from '../../../../../../../../api/files';
import { createDocument } from '../../../../../../../../api/documents';
import useDesktop from '../../../../../../../../hooks/useDesktop';
import Box from '../../../../../../../../components/atomic/Box';
import Button from '../../../../../../../../components/atomic/Button';
import ProgressButton from '../../../../../../../../components/ProgressButton';
import documentTypes from '../../../../../../../../components/forms/DocumentForm/documentTypes';
import { prepareDocumentData } from '../../../../../../../../components/forms/DocumentForm/util';
import Autocomplete from '../../../../../../../../components/Autocomplete';
import TextField from '../../../../../../../../components/atomic/TextField';
import { getUserFullname } from '../../../../../../../../utils/auth';
import { sGetUser } from '../../../../../../../../store/reducers/auth';
import { compareAlphabetically } from '../../../../../../../../utils/strings';
import {
  getErrorMessage,
  getResponseErrorMessage,
} from '../../../../../../../../utils/requests';

const FileForm = ({
  classes,
  currentFileIndex,
  file,
  files,
  onCancel,
  onUploaded,
  fetchingProperty,
  boligmappaNumber,
  authUser,
}) => {
  const isDesktop = useDesktop();
  const [error, setError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [title, setTitle] = useState('');
  const [documentType, setDocumentType] = useState(null);

  const documentTypeOptions = documentTypes
    .sort((a, b) => compareAlphabetically(a.name, b.name))
    .map((staticDocumentType) => ({
      value: staticDocumentType.id,
      label: staticDocumentType.name,
    }));

  useEffect(() => {
    if (file) {
      setTitle(file.name || '');
      setDocumentType(null);
    }
  }, [file]);

  const generateSubmitData = (data) => ({
    ...data,
    boligmappaNumber,
    fileName: file.name,
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    setError(null);
    setRequestInProgress(true);

    const formData = generateSubmitData(
      prepareDocumentData({
        title,
        documentType,
        updatedDate: moment().format(),
        createdDate: moment().format(),
        ownedByName: getUserFullname(authUser),
      })
    );

    const [createDocumentError, createDocumentResponse] = await createDocument(
      formData
    );

    if (createDocumentError) {
      setError(createDocumentError);
      setRequestInProgress(false);
      return;
    }

    const { id, uploadLink } = createDocumentResponse;
    const [uploadDocumentError] = await uploadFile(uploadLink, file);

    setRequestInProgress(false);

    if (uploadDocumentError) {
      setError(uploadDocumentError);
    } else {
      onUploaded({
        id,
        ...formData,
      });
    }
  };

  return (
    <form onSubmit={onSubmit} className={classes.documentForm}>
      <Box px={isDesktop ? 5 : 2}>
        {error && (
          <div className={clsx(classes.error, 'word-break')}>
            {getResponseErrorMessage(error) || getErrorMessage(error)}
          </div>
        )}

        <TextField
          required
          value={title}
          label="Navn"
          className={classes.formElement}
          onChange={(e) => setTitle(e.target.value)}
          disabled={fetchingProperty || requestInProgress}
        />

        <Autocomplete
          value={documentType}
          label="Dokumenttype"
          options={documentTypeOptions}
          className={classes.formElement}
          placeholder="Velg dokumenttype"
          disabled={fetchingProperty || requestInProgress}
          onChange={(option) => setDocumentType(option)}
        />
      </Box>

      <div
        className={clsx(
          isDesktop ? classes.actionButtons : classes.actionButtonsMobile
        )}
      >
        <Button
          className={isDesktop ? classes.backBtn : classes.backBtnMobile}
          color="primary"
          variant="outlined"
          onClick={onCancel}
        >
          Avbryt
        </Button>
        <ProgressButton
          className={isDesktop ? classes.nextBtn : classes.nextBtnMobile}
          type="submit"
          color="primary"
          variant="contained"
          requestInProgress={requestInProgress}
        >
          {currentFileIndex === files?.length - 1 ? 'Lagre' : 'Neste'}
        </ProgressButton>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  authUser: sGetUser(state),
});

export default connect(mapStateToProps, null)(withStyles(styles)(FileForm));
