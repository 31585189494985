import {
  getDocumentId,
  getDocumentIsBuilding,
  getDocumentName,
  getDocumentUpdatedDate,
} from '../../../utils/documents';

import { getRoomId, getRoomName } from '../../../utils/rooms';
import { createDate, formattedDate } from '../../../utils/dates';
import { compareAlphabetically, trim } from '../../../utils/strings';

export const categoryOptions = (eventTypes) =>
  eventTypes
    .map((eventType) => ({
      value: eventType,
      label: eventType,
    }))
    .sort((a, b) => compareAlphabetically(a.label, b.label));

export const roomOptions = (rooms) =>
  Array.isArray(rooms)
    ? rooms
        .sort((a, b) => compareAlphabetically(getRoomName(a), getRoomName(b)))
        .map((room) => ({
          value: getRoomId(room),
          label: getRoomName(room),
        }))
    : [];

export const documentOptions = (documents) =>
  Array.isArray(documents)
    ? documents
        .sort((a, b) =>
          compareAlphabetically(
            getDocumentUpdatedDate(b),
            getDocumentUpdatedDate(a)
          )
        )
        .filter((document) => !getDocumentIsBuilding(document))
        .map((document) => ({
          document,
          value: getDocumentId(document),
          label: getDocumentName(document),
        }))
    : [];

export const prepareEventData = ({
  title,
  rooms,
  roomIds,
  endYear = null,
  endMonth = null,
  category,
  documents,
  associations,
  startYear = null,
  startMonth = null,
  documentIds,
  description,
  customCategory,
  dateEnd = null,
  dateStart = null,
  gallery = null,
  subType,
}) => ({
  title: trim(title),
  description: trim(description),
  subType:
    customCategory || (category && category.value) || subType
      ? trim(customCategory || (category && category.value)) || subType
      : 'undefined',
  rooms: (rooms || []).filter((room) =>
    (roomIds || [])
      .map(({ value }) => String(value))
      .includes(String(getRoomId(room)))
  ),
  documents: (documents || []).filter((document) =>
    (documentIds || [])
      .map(({ value }) => String(value))
      .includes(String(getDocumentId(document)))
  ),
  roomIds: (roomIds || []).map(({ value }) => value),
  documentIds: (documentIds || []).map(({ value }) => value),
  associations: (associations || []).map(({ value }) => value),
  dateEnd: dateEnd
    ? formattedDate(dateEnd, 'YYYY-MM-DD')
    : endYear &&
      endYear.length === 4 &&
      endMonth &&
      endMonth > 0 &&
      endMonth < 13 &&
      formattedDate(createDate(endYear, endMonth), 'YYYY-MM-DD'),
  dateStart: dateStart
    ? formattedDate(dateStart, 'YYYY-MM-DD')
    : startYear &&
      startYear.length === 4 &&
      startMonth &&
      startMonth > 0 &&
      startMonth < 13 &&
      formattedDate(createDate(startYear, startMonth), 'YYYY-MM-DD'),
  gallery: gallery || [],
});

export const prepareNormalEventData = ({
  title,
  rooms,
  roomIds,
  endYear = null,
  endMonth = null,
  category,
  documents,
  startYear = null,
  startMonth = null,
  documentIds,
  description,
  customCategory,
  dateEnd = null,
  dateStart = null,
  gallery = null,
  subType,
  associations = null,
}) => ({
  title: trim(title),
  description: trim(description),
  subType:
    customCategory || (category && category.value) || subType
      ? trim(customCategory || (category && category.value)) || subType
      : 'undefined',
  rooms: (rooms || []).filter((room) =>
    (roomIds || [])
      .map(({ value }) => String(value))
      .includes(String(getRoomId(room)))
  ),
  documents: (documents || []).filter((document) =>
    (documentIds || [])
      .map(({ value }) => String(value))
      .includes(String(getDocumentId(document)))
  ),
  roomIds: (roomIds || []).map(({ value }) => value),
  documentIds: (documentIds || []).map(({ value }) => value),
  dateEnd: dateEnd
    ? formattedDate(dateEnd, 'YYYY-MM-DD')
    : endYear &&
      endYear.length === 4 &&
      endMonth &&
      endMonth > 0 &&
      endMonth < 13 &&
      formattedDate(createDate(endYear, endMonth), 'YYYY-MM-DD'),
  dateStart: dateStart
    ? formattedDate(dateStart, 'YYYY-MM-DD')
    : startYear &&
      startYear.length === 4 &&
      startMonth &&
      startMonth > 0 &&
      startMonth < 13 &&
      formattedDate(createDate(startYear, startMonth), 'YYYY-MM-DD'),
  gallery: gallery || [],
  associations,
});
