import { getDocumentId } from './documents';
import { getEventId } from './events';
import { getRoomImages } from './rooms';
import { trim } from './strings';

export const getBYBFRoomId = (room) => (room ? room.id : null);

export const prepareRoomData = ({
  type,
  title,
  typeId,
  events,
  floorNo,
  eventIds,
  documents,
  description,
  documentIds,
}) => ({
  roomType: type,
  roomTypeId: typeId,
  floorNo: floorNo ? trim(floorNo) : null,
  title: trim(title || type),
  description: trim(description),
  events: (events || []).filter((event) =>
    eventIds
      .map(({ value }) => String(value))
      .includes(String(getEventId(event)))
  ),
  documents: (documents || []).filter((document) =>
    documentIds
      .map(({ value }) => String(value))
      .includes(String(getDocumentId(document)))
  ),
  eventIds: (eventIds || []).map(({ value }) => value),
  documentIds: (documentIds || []).map(({ value }) => value),
});

export const prepareBYBFRoomJsonData = ({
  rooms,
  isEdit,
  flowCompleted,
  stage,
  roomsCreated,
  roomsDetailsUpdated,
}) => {
  const temparray = [];
  rooms.forEach((a) => {
    if (a.id === null) {
      const item = a;
      item.gallery = null;
      temparray.push(item);
    }
  });
  return {
    stage,
    isEdit,
    flowCompleted,
    rooms: temparray,
    roomsCreated,
    roomsDetailsUpdated,
  };
};

export const allTheRoomInforUpdatedCheck = (rooms) => {
  return !(rooms || []).some(
    (a) => a.description?.trim() === '' || (getRoomImages(a) || []).length === 0
  );
};

export const roomInforUpdatedCheck = (room) => {
  return !(
    room.title?.trim() === '' ||
    room.description?.trim() === '' ||
    room.description?.trim() === '' ||
    (getRoomImages(room) || []).length === 0
  );
};

export const allRoomsCteatedStatus = (rooms) => {
  return !(rooms || []).some((a) => a.id === null);
};
