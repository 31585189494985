import React, { createRef } from 'react';
import { withStyles } from '@material-ui/core';
import { SnackbarProvider as SnackbarProviderBase } from 'notistack';

import styles from './style';
import IconButton from '../atomic/IconButton';
import CloseIcon from '../../assets/icons/Close';

export const maxSnackMessages = 5;
export const autoHideDurationMs = 3000; // ms

const SnackbarProvider = ({
  classes,
  hideIconVariant = true,
  preventDuplicate = false,
  maxSnack = maxSnackMessages,
  autoHideDuration = autoHideDurationMs,
  closeButton,
  ...props
}) => {
  const ref = createRef();

  const onDismissClick = (key) => {
    ref.current.closeSnackbar(key);
  };

  return (
    <SnackbarProviderBase
      ref={ref}
      maxSnack={maxSnack}
      hideIconVariant={hideIconVariant}
      preventDuplicate={preventDuplicate}
      autoHideDuration={autoHideDuration}
      classes={{
        root: classes.root,
        variantInfo: classes.info,
        variantError: classes.error,
        success: classes.success,
        variantWarning: classes.warning,
        containerRoot: classes.containerRoot,
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      action={(key) => (
        <IconButton
          className={
            closeButton ? classes.dismissDarkButton : classes.dismissButton
          }
          onClick={() => onDismissClick(key)}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      )}
      {...props}
    />
  );
};

export default withStyles(styles)(SnackbarProvider);
