import { css } from '@linaria/core';
import { baseBorders, baseColors, baseSizes } from '../base/baseTheme';

import { createVariablesFromTheme } from '../themeHelpers';
import { Theme } from '../themeTypes';
import { bmBorders } from './borders';
import { boligmappaColors } from './colors';
import { bodySize, generalFonts, linkSize, titleSize } from './typography';

const boligmappaMain: Theme = {
  typography: {
    body: {
      ...bodySize,
    },
    link: {
      ...linkSize,
    },
    title: {
      ...titleSize,
    },
    general: {
      ...generalFonts,
    },
  },
  borders: {
    ...baseBorders,
    ...bmBorders,
  },
  sizes: {
    ...baseSizes,
  },
  colors: {
    ...baseColors,
    ...boligmappaColors,
  },
};

export const mainTheme = css`
  ${createVariablesFromTheme(boligmappaMain)}
`;

export default { mainTheme };
