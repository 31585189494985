const styles = (theme) => ({
  button: {
    borderRadius: 24,
    fontSize: '13px',
    fontWeight: 'normal',
    position: 'relative',
    textDecoration: 'none',
    padding: `0 ${theme.spacing(2)}px`,
    color: theme.palette.primary.lightGreen,
    border: `1px solid ${theme.palette.primary.lightGreen}`,

    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    },
    minWidth: '120px',
    minHeight: '35px',
  },
  add: {
    color: theme.palette.primary.greyDark,
    border: `1px solid ${theme.palette.primary.greyDark}`,
  },
  checkIcon: {
    top: '-5px',
    right: '-5px',
    width: '20px',
    height: '20px',
    background: 'white',
    position: 'absolute',
  },
  badge: {
    zIndex: '1',
    width: '15px',
    color: '#fff',
    height: '15px',
    display: 'flex',
    fontSize: '14px',
    marginLeft: '3px',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.primary.lightGreen,
  },
  listItem: {
    justifyContent: 'center',
  },
});

export default styles;
