import React from 'react';

const PencilOutlined = ({
  width = '16',
  height = '15',
  fill = '#fff',
  ...props
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox="0 0 16 15"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.98438 10.6211C2.09375 10.2383 2.3125 9.88281 2.58594 9.60938L10.8984 1.29688C11.582 0.613281 12.7031 0.613281 13.3867 1.29688L14.4531 2.36328C14.5352 2.44531 14.6172 2.55469 14.6719 2.63672C15.1367 3.32031 15.0547 4.25 14.4531 4.85156L6.14062 13.1641C6.11328 13.1914 6.05859 13.2188 6.03125 13.2734C5.75781 13.4922 5.45703 13.6562 5.12891 13.7656L2.99609 14.3945L1.82031 14.75C1.60156 14.8047 1.35547 14.75 1.19141 14.5586C1 14.3945 0.945312 14.1484 1.02734 13.9297L1.35547 12.7539L1.98438 10.6211ZM3.24219 11.0039L3.05078 11.6328L2.61328 13.1367L4.11719 12.6992L4.74609 12.5078C4.9375 12.4531 5.07422 12.3711 5.21094 12.2344L11.4727 5.97266L9.77734 4.27734L3.51562 10.5391C3.48828 10.5391 3.48828 10.5664 3.46094 10.5938C3.35156 10.7031 3.29688 10.8398 3.24219 11.0039Z"
      fill={fill}
    />
  </svg>
);

export default PencilOutlined;
