import React from 'react';

const Photos = ({ width = '18', height = '14', fill = 'none', ...props }) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 18 14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 12H4.5C3.8125 12 3.21875 11.7812 2.71875 11.2812C2.21875 10.7812 2 10.1875 2 9.5V3H1.5C1.0625 3 0.71875 3.15625 0.4375 3.4375C0.125 3.75 0 4.09375 0 4.5V12.5C0 12.9375 0.125 13.2812 0.4375 13.5625C0.71875 13.875 1.0625 14 1.5 14H13.5C13.9062 14 14.25 13.875 14.5625 13.5625C14.8438 13.2812 15 12.9375 15 12.5V12ZM18 9.5C18 9.9375 17.8438 10.2812 17.5625 10.5625C17.25 10.875 16.9062 11 16.5 11H4.5C4.0625 11 3.71875 10.875 3.4375 10.5625C3.125 10.2812 3 9.9375 3 9.5V1.5C3 1.09375 3.125 0.75 3.4375 0.4375C3.71875 0.15625 4.0625 0 4.5 0H16.5C16.9062 0 17.25 0.15625 17.5625 0.4375C17.8438 0.75 18 1.09375 18 1.5V9.5ZM8 3C8 2.59375 7.84375 2.25 7.5625 1.9375C7.25 1.65625 6.90625 1.5 6.5 1.5C6.0625 1.5 5.71875 1.65625 5.4375 1.9375C5.125 2.25 5 2.59375 5 3C5 3.4375 5.125 3.78125 5.4375 4.0625C5.71875 4.375 6.0625 4.5 6.5 4.5C6.90625 4.5 7.25 4.375 7.5625 4.0625C7.84375 3.78125 8 3.4375 8 3ZM5 7.5V9H16V5.5L13.25 2.75C13.1875 2.6875 13.0938 2.65625 13 2.65625C12.875 2.65625 12.8125 2.6875 12.75 2.75L8.5 7L7.25 5.75C7.1875 5.6875 7.09375 5.65625 7 5.65625C6.875 5.65625 6.8125 5.6875 6.75 5.75L5 7.5Z"
      fill="#111111"
    />
  </svg>
);

export default Photos;
