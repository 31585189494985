import React from 'react';

const RadonAreaLevelModerate = (props) => (
  <svg
    width={33}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#BE1E14"
      d="M.097 12.597h8.202v8.398H.097zM12.398 6.304H20.6v14.697h-8.202z"
    />
    <path fill="#363750" fillOpacity={0.3} d="M24.701 0h8.202v20.996h-8.202z" />
  </svg>
);

export default RadonAreaLevelModerate;
