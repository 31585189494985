import {
  SET_BYBF_FETCHING,
  SET_BYBF_FETCHING_ERROR,
  SET_STEP_COMPLETED,
  CLEAR_BYBF,
} from '../reducers/bybf';

export const acBYBFSetFetching = (fetching) => ({
  type: SET_BYBF_FETCHING,
  payload: fetching,
});

export const acBYBFSetFetchingError = (error) => ({
  type: SET_BYBF_FETCHING_ERROR,
  payload: error,
});

export const acBYBFSetStepCompleted = (category, completed) => ({
  type: SET_STEP_COMPLETED,
  payload: { category, completed },
});

export const acBYBFClear = () => ({
  type: CLEAR_BYBF,
});
