import React, { createElement, forwardRef } from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

import styles from './style';
import Link from '../../atomic/Link';
import Divider from '../../atomic/Divider';
import BaseListItem from '../../atomic/ListItem';
import ArrowRight from '../../../assets/icons/DocumentBulkEditIcons/ArrowRight';
import LockIcon from '../../../assets/icons/DocumentBulkEditIcons/LockIcon';

const ListItem = forwardRef(
  (
    {
      to,
      icon,
      classes,
      children,
      disabled,
      className,
      iconClass,
      textClass,
      isDropDown,
      startSeperation,
      endSeperation,
      border = true,
      openInNewTab = false,
      isExternalLink = false,
      ...props
    },
    ref
  ) => {
    const listItemContent = (
      <>
        {icon && (
          <div className={classes.iconContainer}>
            {createElement(icon, {
              className: clsx(classes.icon, iconClass),
            })}
          </div>
        )}

        <div
          className={clsx(
            classes.listItemText,
            textClass,
            children === 'Slett' && classes.boldText
          )}
        >
          {children}
        </div>
        {isDropDown && !disabled && <ArrowRight />}
        {disabled && <LockIcon />}
      </>
    );

    if (to) {
      return (
        <Link
          to={to}
          ref={ref}
          disabled={disabled}
          isExternal={isExternalLink}
          openInNewTab={openInNewTab}
          target={openInNewTab ? '_blank' : undefined}
          rel={openInNewTab ? 'noopener noreferrer' : undefined}
          className={clsx(classes.link, border && classes.borderClass)}
        >
          <BaseListItem
            className={clsx(className, classes.listItem)}
            disabled={disabled}
            {...props}
          >
            {listItemContent}
          </BaseListItem>
        </Link>
      );
    }

    return (
      <>
        {startSeperation && <Divider className={classes.divider} />}
        <BaseListItem
          ref={ref}
          className={clsx(
            className,
            classes.listItem,
            border && classes.borderClass
          )}
          disabled={disabled}
          {...props}
        >
          {listItemContent}
        </BaseListItem>
        {endSeperation && <Divider className={classes.divider} />}
      </>
    );
  }
);

export default withStyles(styles)(ListItem);
