import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import { uploadFile } from '../../../../../api/files';
import { createDocument } from '../../../../../api/documents';
import DocumentForm from '../../../../../components/forms/DocumentForm';

const FileForm = ({
  file,
  onSkip,
  classes,
  onUploaded,
  boligmappaNumber,
  fetchingProperty,
}) => {
  const [error, setError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const updatedFileData = {
    rooms: [],
    events: [],
    name: file.name,
  };

  const [fileDocument, setFileDocument] = useState(updatedFileData);

  const prepareDocumentData = (data) => ({
    ...data,
    boligmappaNumber,
    fileName: file.name,
  });

  const onNextClick = async (data) => {
    setError(null);
    setRequestInProgress(true);

    const formData = prepareDocumentData(data);

    const [createDocumentError, createDocumentResponse] = await createDocument(
      formData
    );

    if (createDocumentError) {
      setError(createDocumentError);
      setRequestInProgress(false);
      return;
    }

    // success
    const { id, uploadLink } = createDocumentResponse;
    const [uploadDocumentError] = await uploadFile(uploadLink, file);

    setRequestInProgress(false);

    if (uploadDocumentError) {
      setError(uploadDocumentError);
    } else {
      onUploaded({
        id,
        ...formData,
      });
    }
  };

  useEffect(() => {
    if (file) {
      setFileDocument(updatedFileData);
    }
  }, [file]);

  return (
    <div>
      <DocumentForm
        onCancel={onSkip}
        onSubmit={onNextClick}
        document={fileDocument}
        formRequestError={error}
        submitButtonText="Lagre"
        fetching={fetchingProperty}
        className={classes.documentForm}
        boligmappaNumber={boligmappaNumber}
        requestInProgress={requestInProgress}
      />
    </div>
  );
};

export default withStyles(styles)(FileForm);
