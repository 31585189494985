import React from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core';
import HeaderCellItem from './HeaderCellItem';
import {
  DOCUMENT_EVENT_FILTER,
  DOCUMENT_PROFESSION_TYPE_FILTER,
  DOCUMENT_ROOM_FILTER,
  getDocumentEvents,
  getDocumentProfessionType,
  getDocumentRooms,
} from '../../../../../utils/documents';
import { compareAlphabetically } from '../../../../../utils/strings';
import { onListItemClick } from '../../../../DocumentFilters/util';
import matomo from '../../../../../utils/matomo';
import { getRoomName } from '../../../../../utils/rooms';
import { getEventName } from '../../../../../utils/events';
import { sGetDocuments } from '../../../../../store/reducers/documents';
import styles from './style';
import TableCell from '../../../../atomic/TableCell';

const CombinedHeaderCell = ({
  name,
  type,
  documents,
  filters,
  classes,
  filterable,
  onFiltersChanged,
  onFiltersReset,
  isVisible = true,
  ...props
}) => {
  const trackMatomoEvent = (currentFilters) => {
    matomo.clickEvent({
      category: 'Document handling',
      name: `Filter by ${currentFilters}`,
      action: 'Filter Document List',
    });
  };
  const getProfessionOptions = (docs, currentFilters = [], onFiltersChange) =>
    (docs || [])
      .reduce(
        (accumulator, document) =>
          accumulator.includes(getDocumentProfessionType(document))
            ? accumulator
            : [...accumulator, getDocumentProfessionType(document)],
        []
      )
      .sort(compareAlphabetically)
      .map((professionType) => ({
        key: professionType,
        name: professionType,
        label: professionType,
        checked: currentFilters.includes(professionType),
        onClick: () => {
          trackMatomoEvent('Industry Association');
          onListItemClick(professionType, currentFilters, onFiltersChange);
        },
      }));
  const getRoomOptions = (docs, currentFilters = [], onFiltersChange) =>
    (docs || [])
      .reduce(
        (accumulator, document) => [
          ...accumulator,
          ...(getDocumentRooms(document) || [])
            .filter((room) => !accumulator.includes(getRoomName(room)))
            .map(getRoomName),
        ],
        []
      )
      .sort(compareAlphabetically)
      .map((roomName) => ({
        key: roomName,
        name: roomName,
        label: roomName,
        checked: currentFilters.includes(roomName),
        onClick: () => {
          trackMatomoEvent('Room');
          onListItemClick(roomName, currentFilters, onFiltersChange);
        },
      }));
  const getEventOptions = (docs, currentFilters = [], onFiltersChange) =>
    (docs || [])
      .reduce(
        (accumulator, document) => [
          ...accumulator,
          ...(getDocumentEvents(document) || [])
            .filter((event) => !accumulator.includes(getEventName(event)))
            .map(getEventName),
        ],
        []
      )
      .sort(compareAlphabetically)
      .map((eventName) => ({
        key: eventName,
        name: eventName,
        label: eventName,
        checked: currentFilters.includes(eventName),
        onClick: () => {
          trackMatomoEvent('Event');
          onListItemClick(eventName, currentFilters, onFiltersChange);
        },
      }));
  const currectOptions = {
    [DOCUMENT_PROFESSION_TYPE_FILTER]: getProfessionOptions(
      documents,
      filters[DOCUMENT_PROFESSION_TYPE_FILTER],
      (updatedFilters) =>
        onFiltersChanged({
          ...filters,
          [DOCUMENT_PROFESSION_TYPE_FILTER]: updatedFilters,
        })
    ),
    [DOCUMENT_ROOM_FILTER]: getRoomOptions(
      documents,
      filters[DOCUMENT_ROOM_FILTER],
      (updatedFilters) =>
        onFiltersChanged({
          ...filters,
          [DOCUMENT_ROOM_FILTER]: updatedFilters,
        })
    ),
    [DOCUMENT_EVENT_FILTER]: getEventOptions(
      documents,
      filters[DOCUMENT_EVENT_FILTER],
      (updatedFilters) =>
        onFiltersChanged({
          ...filters,
          [DOCUMENT_EVENT_FILTER]: updatedFilters,
        })
    ),
  };
  return isVisible ? (
    <TableCell className={classes.root}>
      <div className={classes.combinedFilters}>
        {name.map((el, index) => (
          <HeaderCellItem
            key={name[index]}
            name={name[index]}
            classes={classes}
            filterable={filterable}
            getFilterOptions={currectOptions[type[index]]}
            onFiltersReset={() =>
              onFiltersChanged({
                ...filters,
                [type[index]]: [],
              })
            }
            {...props}
          />
        ))}
      </div>
    </TableCell>
  ) : null;
};

const mapStateToProps = (state) => ({
  documents: sGetDocuments(state),
});

export default connect(mapStateToProps)(withStyles(styles)(CombinedHeaderCell));
