/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useVariant } from '@unleash/proxy-client-react';
import { OverviewGridItem } from './components';
import HomeCallToAction from '../../../../../componentsV2/BoligmappaPlus/HomeCallToAction';
import { boligmappaPlusHomePageVariants } from '../../../../../utils/boligmappaPlus';

function BoligmappePlusSection({ maxWidth, smallVersion }) {
  const { payload: featureVariant } = useVariant('boligeier.boligmappaPlus');
  const variantsContent = boligmappaPlusHomePageVariants[featureVariant.value];
  const minActionButton = true;
  return (
    <OverviewGridItem showMaxwidth={maxWidth}>
      <HomeCallToAction
        minActionButton={minActionButton}
        smallVersion={smallVersion}
        {...variantsContent}
      />
    </OverviewGridItem>
  );
}

export default BoligmappePlusSection;
