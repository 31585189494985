import React from 'react';
import { useMediaQuery, Typography } from '@material-ui/core';
import Card from '../../Card';
import Link from '../../atomic/Link';
import useDashboardLinkPrefix from '../../../hooks/useDashboardLinkPrefix';
import styles from './featureCard.module.css';
import { FeatureCardImage } from './FeatureImage';
import FeatureCardButton from './FeatureCardButton';

export function FeatureCard({ feature, onClick }) {
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const isTabMode = useMediaQuery(`(max-width: 1230px)`);
  const displayAsLink = onClick === null;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Card
      border={0}
      height="100%"
      display="flex"
      flexDirection="column"
      bgcolor="primary.mintLight"
      marginRight="8px"
      minHeight="300px"
      maxHeight="300px"
    >
      <Typography align="left" className={styles.featureHeader} variant="h3">
        {feature.renderTitle
          ? feature.renderTitle({
              isDesktop: true,
            })
          : feature.title}
      </Typography>

      <div className={styles.featureContentContainer}>
        <div className={styles.textWrapper}>
          <p className={styles.featureDescription}>
            {isTabMode ? feature.descriptionMobile : feature.description}
          </p>
        </div>

        <FeatureCardImage feature={feature} />

        <div className={styles.buttonWrapper}>
          {!displayAsLink && (
            <FeatureCardButton
              text={feature.buttonText}
              onClick={handleClick}
            />
          )}

          {displayAsLink && (
            <Link
              to={`${dashboardLinkPrefix}/${feature.buttonLink}`}
              onClick={handleClick}
              underline={false}
              inheritColor
            >
              <FeatureCardButton text={feature.buttonText} />
            </Link>
          )}
        </div>
      </div>
    </Card>
  );
}
