import React from 'react';

const Filters = ({ width = 17, height = 13, fill = '#31777B', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 17 13"
    fill="none"
    {...props}
  >
    <path
      d="M2.25 0H10.75C11.2188 0 11.6875 0.3125 11.875 0.75C12.0625 1.21875 12 1.75 11.6562 2.09375L8.5 5.625V11C8.5 11.4062 8.28125 11.75 7.9375 11.9062C7.59375 12.0625 7.1875 12.0312 6.875 11.8125L4.875 10.3125C4.625 10.125 4.5 9.84375 4.5 9.5V5.625L1.3125 2.09375C0.96875 1.71875 0.90625 1.21875 1.09375 0.75C1.28125 0.3125 1.75 0 2.25 0ZM12 10H16C16.5312 10 17 10.4688 17 11C17 11.5625 16.5312 12 16 12H12C11.4375 12 11 11.5625 11 11C11 10.4688 11.4375 10 12 10ZM11 6C11 5.46875 11.4375 5 12 5H16C16.5312 5 17 5.46875 17 6C17 6.5625 16.5312 7 16 7H12C11.4375 7 11 6.5625 11 6ZM14 0H16C16.5312 0 17 0.46875 17 1C17 1.5625 16.5312 2 16 2H14C13.4375 2 13 1.5625 13 1C13 0.46875 13.4375 0 14 0Z"
      fill={fill}
    />
  </svg>
);

export default Filters;
