import { makeStyles } from '@material-ui/core';

export const MDRFormStyles = makeStyles((theme) => ({
  mdrForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    flex: '1',
    maxWidth: '450px',
    '& label': {
      fontWeight: 600,
      fontSize: '16px',
    },
    '& .companyEmail': {
      '& .input-wrapper:not(:hover), .input-wrapper:hover': {
        borderColor: theme.palette.primary.greyLightBlue,
      },
    },
    '& .selectField': {
      '& .input-wrapper:not(:hover), .input-wrapper:hover': {
        borderColor: theme.palette.primary.greyLightBlue,
      },

      '& .selectList': {
        zIndex: 1000,
      },

      '& .input-r-content': {
        '& svg': {
          width: '34px',
          height: '20px',
        },
      },
    },
    '& .errorMessage': {
      color: theme.palette.primary.redLightDark,
    },
    '& .errorCompany': {
      paddingTop: '8px',
    },
    '& .errorInput': {
      borderColor: `${theme.palette.primary.redLightDark} !important`,

      '& .input-wrapper:not(:hover), .input-wrapper:hover': {
        borderColor: `${theme.palette.primary.redLightDark} !important`,
      },
    },

    '& .otherTextArea': {
      '& .input-wrapper:not(:hover), .input-wrapper:hover': {
        borderColor: theme.palette.primary.greyLightBlue,
      },

      '& .selectList': {
        zIndex: 1000,
      },
    },
  },
  autocompleteInput: {
    '& label': {
      fontWeight: 600,
      fontSize: '16px !important',
      color: `${theme.palette.primary.greyBlack} !important`,
      marginBottom: '8px !important',
    },
  },
  mdrFormTitle: {
    fontSize: '24px',
    fontWeight: '700',
    color: theme.palette.primary.darkGreen,
    '@media only screen and (max-width: 768px)': {
      fontSize: '20px',
      fontWeight: '600',
    },
  },
  mdrFormSubTitle: {
    fontWeight: '500',
  },
  clearTextButton: {
    height: '15px',
    width: '15px',
    top: '41px',
    right: '52px',
    cursor: 'pointer',
    position: 'absolute',
    color: theme.palette.primary.grey,
  },
  clearTextButtonDivider: {
    top: '31px',
    right: '40px',
    height: '20px',
    cursor: 'pointer',
    fontSize: '22px',
    position: 'absolute',
    color: theme.palette.primary.greyLightBlue,
  },
  notRegisteredSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  companySearchItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  companyName: {
    fontWeight: 500,
    fontSize: '14px',
  },
  companyAddress: {
    fontSize: '12px',
    color: theme.palette.primary.greyDarkBlue,
    marginTop: '-7px',
    fontWeight: 400,
  },
  divider: {
    margin: '-8px -12px',
  },
  arrowDown: {
    width: '28px',
    height: '22px',
    '& svg': {
      width: '12px',
      height: '12px',
    },
  },
  otherTextArea: {
    borderColor: theme.palette.primary.greyLightBlue,
  },
  submitButton: {
    borderRadius: '50px',
    fontWeight: 500,
    backgroundColor: theme.palette.primary.darkGreen,
    color: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.darkGreen,
      color: theme.palette.primary.white,
    },
    '@media only screen and (max-width: 768px)': {
      width: '100%',
    },
  },
  companyRegisteredMessage: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '16px',
    gap: '8px',
    paddingTop: '8px',
  },
  companyRegisteredIcon: {
    width: '20px',
  },
}));

export const autocompleteStyles = {
  loadingIndicator: (style) => ({
    ...style,
    marginRight: '50px',
  }),
  menuList: (style) => ({
    ...style,
    padding: '0px',
  }),
  placeholder: (style) => ({
    ...style,
    color: '#73748F',
  }),
  option: (style) => ({
    ...style,
    background: 'none',
    padding: '8px 16px',
    color: 'inherit',
    '&:hover': {
      background: '#EEF1F9',
    },
    '&:focus': {
      background: '#EEF1F9',
    },
  }),
  valueContainer: (style) => ({
    ...style,
    padding: '8px 16px',
    fontSize: '16px',
  }),
};
