import { get, post, put, patch, del, request } from '../utils/http';
import { uploadFile } from './files';
import { publicRequest } from '../utils/api';
import { storeReferralSuggested } from '../utils/referral';
import { backendBaseUrl } from '../config';

/**
 * These functions are used in the useApi hook.
 * This is kinda a quick fix, avoiding too much breaking changes.
 * The useApi hook takes in a promise as an argument, and handles the errors and loading state etc.
 * The only difference from the functions below and the others found in this
 * file is that the ones below do not await for the promise to resolve or reject.
 *
 * We will most likely change this in the future, as the refactoring has only just begun.
 */
export const getValueEstimateFromApi = (boligmappaNumber, provider = 'virdi') =>
  get(`properties/${boligmappaNumber}/valueestimate?provider=${provider}`);

export const fetchPropertyFromApi = (boligmappaNumber) =>
  get(`properties/${boligmappaNumber}`);

export const fetchFinancialsFromApi = (boligmappaNumber) =>
  get(`properties/${boligmappaNumber}/financials`);

export const fetchDocumentsFromApi = (boligmappaNumber, params) =>
  get(`properties/${boligmappaNumber}/documents`, { params });

export const fetchCompaniesFromApi = (boligmappaNumber) =>
  get(`properties/${boligmappaNumber}/companies`);

export const fetchCommitmentsFromApi = (boligmappaNumber) =>
  get(`properties/${boligmappaNumber}/commitments`);

export const fetchRightsFromApi = (boligmappaNumber) =>
  get(`properties/${boligmappaNumber}/rights`);

export const updatePropertyInDb = (boligmappaNumber, data) =>
  patch(`properties/${boligmappaNumber}`, data);

export const getPriceHistoryFromApi = (boligmappaNumber) =>
  get(`properties/${boligmappaNumber}/pricehistory`);

export const fetchPublicCompaniesFromApi = (boligmappaNumber) =>
  publicRequest.get(`properties/${boligmappaNumber}/companies`);

export const fetchPriceStatsFromApi = (boligmappaNumber) =>
  get(`properties/${boligmappaNumber}/pricestatistics`);

/**
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 */

export const fetchProperty = (boligmappaNumber) =>
  get(`properties/${boligmappaNumber}`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const fetchProperties = () =>
  get('propertyowners/properties')
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const fetchFinancials = (boligmappaNumber) =>
  get(`properties/${boligmappaNumber}/financials`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

/**
 * These functions returns an array where the first
 * value returned is an error object, and the second
 * value returned is the response
 */
export const fetchDocuments = (boligmappaNumber, params) =>
  get(`properties/${boligmappaNumber}/documents`, { params })
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const fetchCompanyDocuments = (boligmappaNumber, companyId, params) =>
  get(`properties/${boligmappaNumber}/documents?companyid=${companyId}`, {
    params,
  })
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const fetchEvents = (boligmappaNumber) =>
  get(`properties/${boligmappaNumber}/events`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const fetchRooms = (boligmappaNumber) =>
  get(`properties/${boligmappaNumber}/rooms`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const fetchCompanies = (boligmappaNumber) =>
  get(`properties/${boligmappaNumber}/companies`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const requestUploadLink = (boligmappaNumber, data) =>
  put(`properties/${boligmappaNumber}/images`, data)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const uploadImage = async (boligmappaNumber, file, position) => {
  const data = {
    position,
    fileName: file.name,
  };

  const [error, response] = await requestUploadLink(boligmappaNumber, data);

  if (error) return [error, null];

  const [uploadError] = await uploadFile(response.uploadLink, file);

  return uploadError ? [uploadError, null] : [null, response];
};

export const fetchNotifications = (boligmappaNumber) =>
  get(`properties/${boligmappaNumber}/notifications`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

// removing the single notifications patch APi
// using multiple patch instead
export const updateNotificationGroup = (data) =>
  patch('notifications', data)
    .then((response) => [null, response.data])
    .catch((error) => [error, null]);

export const updateProperty = (boligmappaNumber, data) =>
  patch(`properties/${boligmappaNumber}`, data)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const fetchCommitments = (boligmappaNumber) =>
  get(`properties/${boligmappaNumber}/commitments`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const fetchRights = (boligmappaNumber) =>
  get(`properties/${boligmappaNumber}/rights`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const getPublicAccessData = (boligmappaNumber) =>
  get(`properties/${boligmappaNumber}/publicaccess`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const getAccessRequestsData = (boligmappaNumber) =>
  get(`propertyowners/accessRequests?boligmappaNumber=${boligmappaNumber}`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const postPublicAccessData = (boligmappaNumber, data) =>
  post(`properties/${boligmappaNumber}/publicaccess`, data)
    .then((response) => {
      storeReferralSuggested(false);
      return [null, response.data.response];
    })
    .catch((error) => [error, null]);

export const patchAccessRequest = (selectedAccessRequest, status) => {
  const statusCodes = {
    Pending: 1,
    Granted: 2,
    Denied: 3,
    Expired: 4,
  };
  const data = {
    accessType: selectedAccessRequest.accessType,
    resource: selectedAccessRequest.resource,
    // Todo: endpoint is not ready for date
    // expiryDate:
    //   status === 'Granted'
    //     ? moment(new Date()).add(3, 'M').format('DD/MM/YYYY 00:00:00')
    //     : moment(new Date()).format('DD/MM/YYYY 00:00:00'),
    status: {
      id: statusCodes[status],
      expiryDate: 'string',
      status: 'status',
    },
  };
  return patch(
    `propertyowners/accessRequests/${selectedAccessRequest.id}`,
    data
  )
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);
};

export const patchPublicAccessData = (boligmappaNumber, id, data) =>
  patch(`properties/${boligmappaNumber}/publicaccess/${id}`, data)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

// public property endpoint
export const fetchPublicProperty = (boligmappaNumber) =>
  publicRequest
    .get(`/properties/${boligmappaNumber}`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const fetchPublicPropertyCompanies = (boligmappaNumber) =>
  publicRequest
    .get(`properties/${boligmappaNumber}/companies`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const fetchPublicCompanyDocuments = (boligmappaNumber, companyId) =>
  publicRequest
    .get(`properties/${boligmappaNumber}/documents?companyid=${companyId}`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);
// Private Sharing end point

export const postPrivateAccessData = (boligmappaNumber, data) =>
  post(`properties/${boligmappaNumber}/privateaccess`, data)
    .then((response) => {
      storeReferralSuggested(false);
      return [null, response.data.response];
    })
    .catch((error) => [error, null]);

export const getPrivateAccessData = (boligmappaNumber) =>
  get(`properties/${boligmappaNumber}/privateaccess`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const deletePrivateShare = (boligmappaNumber, query) =>
  del(`properties/${boligmappaNumber}/privateaccess?${query}`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const getValueEstimate = (boligmappaNumber) =>
  get(`properties/${boligmappaNumber}/valueestimate`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const getPropertyFeatures = (boligmappaNumber) =>
  get(`properties/${boligmappaNumber}/features`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const buildBoligmappa = (boligmappaNumber, data, type) =>
  put(`properties/${boligmappaNumber}/buildboligmappa?type=${type}`, data)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const getBuildBoligmappaState = (boligmappaNumber, type) =>
  get(
    `properties/${boligmappaNumber}/buildboligmappa${
      type ? `?type=${type}` : ''
    }`
  )
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const getMarketEvents = (boligmappaNumber, limit, adState) =>
  get(
    `properties/${boligmappaNumber}/marketevents?limit=${limit}&adState=${adState}`
  )
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const getEnergyMark = (boligmappaNumber) =>
  get(`properties/${boligmappaNumber}/energymark`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const createOveroConsent = (boligmappaNumber, data) =>
  post(`properties/${boligmappaNumber}/overoConsent`, data)
    .then((response) => {
      return [null, response.data.response];
    })
    .catch((error) => [error, null]);

export const getOveroIframe = (overoParams) =>
  request({
    method: 'post',
    url: 'overo-iframe',
    baseURL: backendBaseUrl,
    data: overoParams,
  });

export const copySinglePropertyDocumentToPrivate = (boligmappaNumber, id) =>
  post(`properties/${boligmappaNumber}/documents/copy/${id}`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const copyBulkPropertyDocumentToPrivate = (boligmappaNumber, data) =>
  post(`properties/${boligmappaNumber}/documents/copy/`, data)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);
