import React from 'react';
import { withStyles } from '@material-ui/core';

import {
  getRightName,
  getRightDocumentId,
  getRightMattrikkel,
  getRightRegisteredDate,
} from '../../utils/rights';

import styles from './style';
import Box from '../../components/atomic/Box';
import List from '../../components/atomic/List';
import { formattedDate } from '../../utils/dates';
import { capitalizeFirst } from '../../utils/strings';
import SeeMoreLink from '../../components/SeeMoreLink';
import Typography from '../../components/atomic/Typography';
import ListItem from '../../components/CardList/KeyValueListItem';
import ContentLoader from '../../components/loaders/ContentLoader';
import matomo from '../../utils/matomo';

const PropertyRight = ({
  link,
  classes,
  fetching,
  showLink,
  showType,
  showTitle,
  right,
}) => {
  const onClickSeeAll = () => {
    matomo.clickEvent({
      category: 'Value Estimate and Finances',
      name: 'See All Easements',
      action: 'See Easements',
    });
  };

  return (
    <List>
      {fetching ? (
        <>
          <ContentLoader width={120} height={20} />
          <br />
          <br />
          <ContentLoader
            width={110}
            height={20}
            className={classes.listItemLoading}
          />
          <ContentLoader
            width={160}
            height={20}
            className={classes.listItemLoading}
          />
          <ContentLoader
            width={140}
            height={20}
            className={classes.listItemLoading}
          />
          <ContentLoader
            width={100}
            height={20}
            className={classes.listItemLoading}
          />
        </>
      ) : (
        <>
          <Typography variant="h2" className={classes.title}>
            {showTitle ? 'Rettigheter' : capitalizeFirst(getRightName(right))}
          </Typography>
          {showType && (
            <ListItem label="Type">
              {capitalizeFirst(getRightName(right))}
            </ListItem>
          )}
          <ListItem label="Registrert dato">
            {getRightRegisteredDate(right) &&
              formattedDate(getRightRegisteredDate(right))}
          </ListItem>
          <ListItem label="Dokumentnummer">
            {getRightDocumentId(right)}
          </ListItem>
          <ListItem label="Rettighet hefter i">
            {getRightMattrikkel(right) &&
              Array.isArray(getRightMattrikkel(right)) &&
              getRightMattrikkel(right).map((matrikkel) => (
                <>
                  <span>{matrikkel}</span>
                  <br />
                </>
              ))}
          </ListItem>

          {showLink && (
            <ListItem>
              <Box py={2} textAlign="right">
                <SeeMoreLink to={link} onClick={onClickSeeAll}>
                  Se alle rettigheter
                </SeeMoreLink>
              </Box>
            </ListItem>
          )}
        </>
      )}
    </List>
  );
};

export default withStyles(styles)(PropertyRight);
