import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PageLayout from '../../../../../components/layouts/WithHeader';
import useDesktop from '../../../../../hooks/useDesktop';
import Box from '../../../../../components/atomic/Box';
import { isNativeEnv } from '../../../../../utils/nativeBridge';
import ProfessionalFilters from '../../../../../components/FindProfessionals/ProfessionalFilters';
import ProfessionalListLoading from '../../../../../components/FindProfessionals/ProfessionalListLoading';
import ProfessionalList from '../../../../../components/FindProfessionals/ProfessionalList';
import ContactProfessional from '../../../../../components/ContactProfessional';
import Image from '../../../../../components/Image';
import NoResoltImage from '../../../../../assets/images/noResult.png';
import NewsLetterImage from '../../../../../assets/images/boglimappa-newsletter.png';
import Typography from '../../../../../components/atomic/Typography';
import Button from '../../../../../components/atomic/Button';
import { useFindProfessionalsStyles } from '../../../../../components/FindProfessionals/findProfessionalStyles';

const FindProfessional = () => {
  const [professionals, setProfessionals] = useState([]);
  const [nameFilterChecked, setNameFilterChecked] = useState(false);
  const [fetchStatus, setFetchStatus] = useState(false);
  const [isNoResult, setIsNoResult] = useState(false);
  const [loading, setLoading] = useState(true);
  const [metaData, setMetaData] = useState(null);
  const [showContactProfDialog, setShowContactProfDialog] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [error, setError] = useState(false);
  const isDesktop = useDesktop();

  const classes = useFindProfessionalsStyles();

  useEffect(() => {
    setIsNoResult(!professionals.length > 0);
  }, [professionals]);

  const renderSearchFilterSection = () => (
    <Box
      className={clsx([
        isNativeEnv() ? classes.filterSectionMobile : classes.filterSection,
        !nameFilterChecked && !isDesktop && 'collapsed',
        !isDesktop && 'mobile',
      ])}
    >
      <ProfessionalFilters
        filteredProfessionals={setProfessionals}
        nameFilterChecked={nameFilterChecked}
        setNameFilterChecked={setNameFilterChecked}
        fetchStatus={fetchStatus}
        setFetchStatus={setFetchStatus}
        setLoading={setLoading}
        setMetaData={setMetaData}
        setError={setError}
      />
    </Box>
  );

  const NoResult = () => (
    <Box className={classes.noResultWrapper}>
      <Box>
        <Box className={classes.noResultImageContainer}>
          <Image
            alt="ReferralImage"
            src={NoResoltImage}
            fallbackSrc={NoResoltImage}
          />
        </Box>
        <Box className={classes.noResultTextContent}>
          <h3>Ingen treff</h3>
          <p>Vi finner ingen håndverkere basert på søket ditt,</p>
          <p>endre filtreringen og prøv på nytt.</p>
        </Box>
      </Box>
    </Box>
  );

  const ErrorMessage = () => (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      mt={3}
    >
      <img alt="boligmappa newsletter" src={NewsLetterImage} />
      <br />
      <Typography className={classes.errorMessageTitle}>
        En feil har oppstått
      </Typography>
      <Typography className={classes.errorMessageText}>
        En feil har oppstått slik at vi ikke får vist deg resultater basert på
        filtreringen din. Prøv på nytt, eller kom tilbake senere.
      </Typography>
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          window.location.reload();
        }}
      >
        Prøv på nytt
      </Button>
    </Box>
  );

  const contactCompany = (company) => {
    setSelectedCompany(company);
    setShowContactProfDialog(true);
  };

  return (
    <>
      <PageLayout
        minFullHeight
        lessMarginLeft={isDesktop}
        maxWidth="lg"
        hideHeader={isDesktop}
        headerTitle="Finn håndverker"
        topLevelLayout
        headerSearch={renderSearchFilterSection()}
        isSearching={nameFilterChecked}
        className={classes.findProfessionalHeader}
        noPaddingTop
      >
        {isDesktop && renderSearchFilterSection()}
        {loading && !error && <ProfessionalListLoading />}
        {error && <ErrorMessage />}
        {!loading && !isNoResult ? (
          <ProfessionalList
            setFetchStatus={setFetchStatus}
            professionals={professionals}
            metaData={metaData}
            setSelectedCompany={contactCompany}
          />
        ) : (
          !loading && <NoResult />
        )}
        <ContactProfessional
          open={showContactProfDialog}
          onClose={() => setShowContactProfDialog(false)}
          company={selectedCompany}
        />
      </PageLayout>
    </>
  );
};

export default FindProfessional;
