import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import styles from './style';
import Box from '../atomic/Box';
import Dialog from '../atomic/Dialog';
import { ActionButtons } from '../Buttons';
import Close from '../../assets/icons/Close';
import IconButton from '../atomic/IconButton';
import Typography from '../atomic/Typography';
import LoadingMessage from '../LoadingMessage';
import DialogTitle from '../atomic/DialogTitle';
import DialogContent from '../atomic/DialogContent';
import DialogActions from '../atomic/DialogActions';
import ActionDialogContext from '../../contexts/actionDialog';

export const defaultState = {
  open: false,
  title: null,
  message: null,
  onCancel: null,
  onSubmit: null,
  closeable: true,
  showCloseButton: true,
  showSubmitButton: true,
  showCancelButton: true,
  submitButtonRed: false,
  cancelButtonText: 'Avbryt',
  submitButtonText: 'Forsette',
  submitButtonVariant: undefined,
  buttonType: 'default',
};

const ActionDialogProvider = ({ classes, children }) => {
  const [state, setState] = useState(defaultState);

  const {
    open,
    title,
    message,
    onCancel,
    onSubmit,
    closeable,
    actionClassName,
    buttonClassName,
    showCloseButton,
    showSubmitButton,
    showCancelButton,
    showLargeSubmitButton,
    submitButtonRed,
    submitButtonText,
    cancelButtonText,
    submitButtonVariant,
    buttonType,
    centeredRowActions,
    customTitleClassName,
    centeredContent,
    titleFontSize,
  } = state;

  const showActionDialog = (options) => {
    setState({
      ...state,
      ...options,
      open: true,
    });

    return () => {
      setState({
        ...state,
        open: false,
      });
    };
  };

  const showLoadingDialog = (loadingMessage, options) =>
    showActionDialog({
      closeable: false,
      showSubmitButton: false,
      showCancelButton: false,
      message: <LoadingMessage>{loadingMessage}</LoadingMessage>,
      ...options,
    });

  const showProgressDialog = (progress) =>
    showLoadingDialog(null, {
      closeable: false,
      showSubmitButton: false,
      showCancelButton: false,
      message: <LoadingMessage variant="determinate" value={progress} />,
    });

  const onClose = () => {
    if (!closeable) {
      return;
    }

    if (onCancel) {
      onCancel();
    }

    setState(defaultState);
  };

  const onSubmitClick = () => {
    setState(defaultState);

    if (onSubmit) {
      onSubmit();
    }
  };

  const exposedFunctions = {
    showActionDialog,
    showLoadingDialog,
    showProgressDialog,
  };

  return (
    <ActionDialogContext.Provider value={exposedFunctions}>
      <Dialog open={open} onClose={onClose} className={classes.dialog}>
        {title && (
          <DialogTitle disableTypography className={classes.dialogTitle}>
            <Box py={2} className={customTitleClassName}>
              <Typography variant="h4" fontSize={titleFontSize}>
                {title}
              </Typography>
            </Box>
            {showCloseButton && (
              <div>
                <IconButton
                  size="small"
                  onClick={onClose}
                  className={classes.closeButton}
                >
                  <Close />
                </IconButton>
              </div>
            )}
          </DialogTitle>
        )}
        {message && (
          <DialogContent
            className={clsx(
              classes.dialogContent,
              centeredContent ? classes.centeredDialogContent : ''
            )}
          >
            {message}
          </DialogContent>
        )}
        {(onCancel || onSubmit) && (
          <DialogActions className={`${classes.actions} ${actionClassName}`}>
            <ActionButtons
              alignRight={false}
              buttonSize="small"
              buttonType={buttonType}
              submitButtonProps={{
                color: 'primary',
                variant: submitButtonVariant,
              }}
              onCancel={onClose}
              onSubmit={onSubmitClick}
              showCancelButton={showCancelButton}
              showSubmitButton={showSubmitButton}
              submitButtonRed={submitButtonRed}
              submitButtonText={submitButtonText}
              cancelButtonText={cancelButtonText}
              showLargeSubmitButton={showLargeSubmitButton}
              buttonClassName={buttonClassName}
              centeredRowActions={centeredRowActions}
            />
          </DialogActions>
        )}
      </Dialog>
      {children}
    </ActionDialogContext.Provider>
  );
};

export default withStyles(styles)(ActionDialogProvider);
