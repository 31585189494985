import React from 'react';
import { OverviewGridItem } from './components';
import Box from '../../../../../components/atomic/Box';
import EventExperimentCTA from '../../../../../modules/EventExperimentCTA';

const EventExperimentCTASection = () => {
  return (
    <OverviewGridItem showMaxwidth>
      <Box
        style={{ background: '#FAFAFA', borderRadius: '8px', padding: '24px' }}
      >
        <EventExperimentCTA />
      </Box>
    </OverviewGridItem>
  );
};

export default EventExperimentCTASection;
