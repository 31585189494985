import React, { createElement } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import Route from '../atomic/Route';
import { sGetIsAuthenticated } from '../../store/reducers/auth';

const ProtectedRoute = ({
  component,
  isAuthenticated,
  componentProps = {},
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated ? (
        <Redirect
          to={{ pathname: '/login', state: { from: 'ATOMATED_LOGOUT' } }}
        />
      ) : (
        createElement(component, { ...props, ...componentProps })
      )
    }
  />
);

const mapStateToProps = (state) => ({
  isAuthenticated: sGetIsAuthenticated(state),
});

export default connect(mapStateToProps)(ProtectedRoute);
