const style = (theme) => ({
  progressButton: {
    fontStyle: 'normal',
    fontWeight: '500',
  },
  title: {
    fontSize: '1.5rem',
    marginBottom: '20px',
  },
  detailsButtonArea: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  detailsButtonAreaMobile: {
    display: 'flex',
    justifyContent: 'center',
    margin: '27px 0 27px 0 ',
  },
  button: {
    width: '198px',
  },
  introContent: {
    textAlign: 'left',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.43',
    marginBottom: '20px',
  },
  introContentMobile: {
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.43',
    marginTop: '10px',
  },
  contentContainer: {
    height: '100%',
    display: 'grid',
    alignItems: 'center',
    gap: '4px',
    gridTemplateRows: '2',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  textWrapper: {
    alignSelf: 'center',
  },
  buttonWrapper: {
    alignSelf: 'flex-end',
  },
  imageContainer: {
    height: '100%',
    // maxHeight: '200px',
    gridColumn: '2 / 3',
    gridRow: '1 / 3',
    alignSelf: 'flex-start',
    justifySelf: 'flex-end',
    marginBottom: '20px',
  },
  mobileImageContainer: {
    height: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '200px',
    marginTop: '20px',
  },
  image: {
    width: '100%',
    height: '100%',
    marginBottom: '20px',
    objectFit: 'contain',
  },
  contentContainerMobile: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gridTemplateRows: 'none',
    gridTemplateColumns: 'none',
    gap: '4px',
    textAlign: 'center',
  },
  textWrapperMobile: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  buttonWrapperMobile: {
    width: '100%',
  },
  buttonWrapperMarketEventMobile: {
    width: '100%',
    marginBottom: '2px',
    marginTop: '2px',
  },
  titleText: {
    marginTop: '0',
    marginBottom: '0',
  },
  linkIcon: {
    top: '7px',
    width: '25px',
    height: '25px',
    position: 'relative',
    fill: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  emptyStateButton: {
    '@media only screen and (min-width: 768px) and (max-width: 1023px)': {
      width: '65%',
    },
    width: '75%',
    border: '1px solid',
    fontSize: '13px',
    fontWeight: '200',
    height: '42px',
    marginTop: '29px',
  },
  buttonIcon: {
    marginLeft: '16px',
  },
  virdiMapButton: {
    width: 'fit-content',
    padding: '0',
    '&:hover': {
      backgroundColor: 'rgb(255 255 255)',
    },
  },
});
export default style;
