const styles = (theme) => ({
  dialogOverlay: {
    background: 'transparent',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 99,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
  },
  dialogOverlayContentMobile: {
    background: theme.palette.primary.white,
    marginTop: '25px',
    width: '100%',
    height: '-webkit-fill-available',
    borderRadius: '15px 15px 0 0',
    boxShadow: '0 3px 20px rgb(0 0 0)',
    position: 'absolute',
  },
  dialogOverlayContent: {
    background: theme.palette.primary.white,
    width: '100%',
    height: '-webkit-fill-available',
    borderRadius: '4px',
    position: 'absolute',
  },
  dialogOverlayHeaderMobile: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0 20px 0',
  },
  dialogOverlayHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 24px 15px 40px',
    alignItems: 'center',
    borderBottom: '1px #dedfdf solid',
  },
  dialogOverlayHeaderTxt: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  dialogOverlayCloseButtonMobile: {
    position: 'absolute',
    left: '15px',
  },
});

export default styles;
