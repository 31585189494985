import React from 'react';

const More = ({ width = '16', height = '16', fill = '#717171', ...props }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
    {...props}
    viewBox="0 0 128 512"
    {...props}
  >
    <path d="M64 368a48 48 0 1 0 0 96 48 48 0 1 0 0-96zm0-160a48 48 0 1 0 0 96 48 48 0 1 0 0-96zM112 96A48 48 0 1 0 16 96a48 48 0 1 0 96 0z" />
  </svg>
);

export default More;
