// Add your styles here, and reference them in your component className
import { css } from '@linaria/core';
import { borders, colors, spacing } from '@style/vars';

export const style = css`
  display: flex;
  align-items: flex-start;
  border-radius: ${borders.radius.small};
  padding: ${spacing.medium};

  svg {
    margin-right: ${spacing.small};
  }

  .hj-alert__content,
  .hj-alert__children {
    width: 100%;
  }

  .hj-alert__title {
    font-weight: 500;
    margin-bottom: ${spacing.small};
    margin-top: 0;
  }
  &.title-only {
    .hj-alert__title {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  &.hj-alert--inline {
    .hj-alert__children {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &.hj-alert--block {
    .hj-alert__children {
      div:nth-child(2) {
        margin-top: ${spacing.small};
      }
    }
  }

  &.hj-alert--error {
    background: ${colors.alert.error};

    .hj-alert__title {
      color: ${colors.textError};
    }

    svg {
      stroke: ${colors.textError};
    }
  }

  &.hj-alert--info {
    background: ${colors.alert.info};
  }

  &.hj-alert--infoDark {
    background: ${colors.alert.infoDark};
    color: ${colors.textLight};
    svg {
      stroke: ${colors.textLight};
    }

    ul,
    p {
      color: ${colors.textLight};
    }
  }
`;
