import React, { createElement } from 'react';
import { connect } from 'react-redux';

import List from '../../../../../../../components/atomic/List';
import useDesktop from '../../../../../../../hooks/useDesktop';
import Menu from '../../../../../../../components/atomic/Menu';
import ConnectNewEvent from '../../../../../../../assets/icons/ConnectNewEvent';
import BottomContextMenu from '../../../../../../../components/BottomContextMenu';
import DesktopMenuItem from '../../../../../../../components/desktop/MenuListItem';
import MobileMenuItem from '../../../../../../../components/BottomContextMenu/ListItem';
import ConnectExistingEvent from '../../../../../../../assets/icons/ConnectExistingEvent';
import { roomModes } from '../../../../../../../utils/rooms';
import {
  acSetEventAttachedIds,
  acSetShowAddEvent,
} from '../../../../../../../store/actions/events';
import matomo from '../../../../../../../utils/matomo';

const AttachEventMenu = ({
  open,
  roomId,
  setOpen,
  anchorEl,
  setShowEditRoom,
  setRoomMode,
  setShowAddEvent,
  setEventAttachedIds,
}) => {
  const isDesktop = useDesktop();

  const onConnectNewEvent = () => {
    setEventAttachedIds({
      roomIds: [roomId],
      documentIds: [],
    });
    setShowAddEvent(true);
  };

  const onConnectExistingEvent = () => {
    setRoomMode(roomModes.event);
    setShowEditRoom(true);
  };

  const menuItems = [
    {
      icon: ConnectNewEvent,
      text: 'Opprett ny hendelse',
      onClick: () => {
        onConnectNewEvent();
        matomo.clickEvent({
          category: 'Rooms',
          action: 'Connect Event to Room',
          name: 'Connect New Event to Room',
        });
      },
    },
    {
      icon: ConnectExistingEvent,
      text: 'Koble til eksisterende hendelse',
      onClick: () => {
        onConnectExistingEvent();
        matomo.clickEvent({
          category: 'Rooms',
          action: 'Connect Event to Room',
          name: 'Connect Existing Event to Room',
        });
      },
    },
  ];

  const renderMenuItem = ({ text, onClick, ...props }) =>
    // eslint-disable-next-line react/no-children-prop
    createElement(isDesktop ? DesktopMenuItem : MobileMenuItem, {
      key: text,
      children: text,
      border: !isDesktop,
      onClick: (event) => {
        if (onClick) {
          onClick(event);
        }

        setOpen(false);
      },
      ...props,
    });

  if (isDesktop) {
    return (
      <Menu
        open={open}
        anchorEl={anchorEl}
        disableAutoFocusItem
        onClose={() => setOpen(false)}
      >
        {menuItems.map(renderMenuItem)}
      </Menu>
    );
  }

  return (
    <BottomContextMenu open={open} setOpen={setOpen} title="Legg til hendelse">
      <List>{menuItems.map(renderMenuItem)}</List>
    </BottomContextMenu>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setShowAddEvent: (payload) => dispatch(acSetShowAddEvent(payload)),
  setEventAttachedIds: (payload) => dispatch(acSetEventAttachedIds(payload)),
});

export default connect(null, mapDispatchToProps)(AttachEventMenu);
