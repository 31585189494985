/* eslint-disable react/destructuring-assignment */
import { withStyles } from '@material-ui/core';
import React from 'react';
import Grid from '../../components/atomic/Grid';
import Typography from '../../components/atomic/Typography';
import PricePerSquareMeterChart from '../../components/PricePerSquareMeterChart';
import useDesktop from '../../hooks/useDesktop';
import { getSpacedNumber } from '../../utils/chartsConfig';
import styles from './styles';

function PricePerSquareMeterView({
  priceStatData,
  valueEstimatePerSqm,
  classes,
}) {
  const isDesktop = useDesktop();

  return (
    <PricePerSquareMeterChart
      stats={priceStatData.data}
      error={priceStatData.error}
      fetching={priceStatData.isLoading || !priceStatData.data}
      valueEstimatePerSqm={valueEstimatePerSqm}
      chartAreaClass={classes.chartArea}
      header={
        <Typography
          paragraph
          variant="h2"
          fontWeight={800}
          marginTop={false}
          align="left"
          fontSize={!isDesktop && 18}
        >
          Kvadratmeterpris (p-rom)
        </Typography>
      }
    >
      <Grid container spacing={2} className={classes.statChartContent}>
        <Grid item xs={6} md={8}>
          <span className={classes.estimateDot} />
          Estimat for valgt bolig
        </Grid>
        <Grid item xs={6} md={4} align="right">
          {valueEstimatePerSqm ? (
            <b>
              {getSpacedNumber(valueEstimatePerSqm)} Kr/m<sup>2</sup>
            </b>
          ) : (
            <b>ikke funnet</b>
          )}
        </Grid>
        <Grid item xs={6} md={8}>
          <span className={classes.averageDot} />
          Gjennomsnitt, nabolaget
        </Grid>
        <Grid item xs={6} md={4} align="right">
          <b>
            {priceStatData?.data?.averageSQMPrice
              ? getSpacedNumber(priceStatData?.data?.averageSQMPrice)
              : '...'}{' '}
            Kr/m<sup>2</sup>
          </b>
        </Grid>
        <Grid>
          <p>
            Diagrammet og kvadratmeterpris er basert på salg av alle eiendommer
            i nabolaget over de siste seks månedene.
          </p>
        </Grid>
      </Grid>
    </PricePerSquareMeterChart>
  );
}

export default withStyles(styles)(PricePerSquareMeterView);
