import { get, post, patch, del } from '../utils/http';
import { publicRequest } from '../utils/api';
import { documentRequestDataTransformer } from './transformers/documents';
import { storeReferralSuggested } from '../utils/referral';

export const fetchDocument = (documentId) =>
  get(`documents/${documentId}`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const updateDocument = (documentId, data) =>
  patch(`documents/${documentId}`, data, {
    transformRequest: documentRequestDataTransformer,
  })
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const createDocument = (data) =>
  post('documents', data, { transformRequest: documentRequestDataTransformer })
    .then((response) => {
      storeReferralSuggested(false);
      return [null, response.data.response];
    })
    .catch((error) => [error, null]);

export const createFolder = (data) =>
  post('propertyowners/directories', data)
    .then((response) => {
      return [null, response.data.response];
    })
    .catch((error) => [error.response.data, null]);

export const createFolderStructure = (data) =>
  post('propertyowners/directory-structures', data)
    .then((response) => {
      return [null, response.data.response];
    })
    .catch((error) => [error.response.data, null]);

export const deleteDocument = (documentId) =>
  del(`documents/${documentId}`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

// Public APIs
export const getPublicDocuments = (boligmappaNumber, params) =>
  publicRequest
    .get(`/properties/${boligmappaNumber}/documents`, { params })
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

// eslint-disable-next-line arrow-body-style
export const fetchPublicDocument = (documentId) => {
  return publicRequest
    .get(`/documents/${documentId}`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);
};
