export const defaultState = {
  fetching: true,
  commitments: null,
  fetchingError: null,
};

export const SET_COMMITMENTS = 'SET_COMMITMENTS';
export const CLEAR_COMMITMENTS = 'CLEAR_COMMITMENTS';
export const SET_FETCHING_COMMITMENTS = 'SET_FETCHING_COMMITMENTS';
export const SET_FETCHING_COMMITMENTS_ERROR = 'SET_FETCHING_COMMITMENTS_ERROR';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_COMMITMENTS:
      return {
        ...state,
        commitments: action.payload,
      };
    case CLEAR_COMMITMENTS:
      return {
        ...state,
        commitments: null,
      };
    case SET_FETCHING_COMMITMENTS:
      return {
        ...state,
        fetching: action.payload,
      };
    case SET_FETCHING_COMMITMENTS_ERROR:
      return {
        ...state,
        fetchingError: action.payload,
      };
    default:
      return state;
  }
};

export const sGetCommitmentsModule = (state) => state.commitments;
export const sGetCommitments = (state) =>
  sGetCommitmentsModule(state).commitments;
export const sGetFetchingCommitments = (state) =>
  sGetCommitmentsModule(state).fetching;
export const sGetFetchingCommitmentsError = (state) =>
  sGetCommitmentsModule(state).fetchingError;
