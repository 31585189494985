const styles = (theme) => ({
  errorBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '216px',
    position: 'absolute',
    color: '#fff',
    width: '100%',
    '&.mobile': {
      marginTop: '5%',
    },
  },
  errorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '303px',
    textAlign: 'center',
  },
  errorWrapperWider: {
    maxWidth: '399px',
  },
  errorHeadline: {
    fontSize: '24px',
    lineHeight: '24px',
    fontWeight: '500',
    margin: '0 0 8px',
  },
  errorText: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '400',
    textWrap: 'balance',
    margin: 0,
  },
  errorButtonsBlock: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '16px',
    '&.mobile': {
      columnGap: '3px',
    },
  },
  errorBlockButton: {
    border: '1.5px solid #D1EDE8',
    backgroundColor: '#fff',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: '500',
    color: theme.colors.greyBlack,
    marginTop: '32px',
    padding: '12px 24px',
    '&:hover': {
      borderColor: theme.colors.greyBlack,
    },
    '&.mobile': {
      padding: '12px 8px',
    },
  },
  errorContactButton: {
    border: 'unset',
    padding: '13px 24px',
    backgroundColor: '#164144',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#31777B',
    },
  },
  errorButtonText: {
    margin: '0 0 0 8px',
  },
});

export default styles;
