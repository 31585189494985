import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Link from '../atomic/Link';
import ArrowRightIcon from '../../assets/icons/ArrowRightAlt';

const SeeMoreLink = ({ classes, children = 'Se mer', ...props }) => (
  <Link className={classes.link} {...props}>
    <strong>{children}</strong>
    <ArrowRightIcon className={classes.linkIcon} />
  </Link>
);

export default withStyles(styles)(SeeMoreLink);
