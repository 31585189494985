const styles = (theme) => ({
  displayNone: {
    display: 'none',
  },
  hideBlock: {
    width: 0,
    opacity: 0,
  },
  showBlock: {
    width: 'unset',
    maxWidth: '100%',
    visibility: 'unset',
    opacity: 'unset',
    display: 'flex',
    alignItems: 'center',
  },
  previewPage: {
    background: '#717171',
    paddingTop: '24px',
    marginTop: '98px',
  },
  contentClass: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    position: 'relative',
    width: '100%',
  },
  previewHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1199,
    background: '#000',
    width: '100%',
  },
  previewHeaderNameBlock: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    margin: '46px 0 20px 0',
    maxHeight: '32px',
  },
  previewCloseButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '32px',
    maxHeight: '32px',
    padding: '11px',
    margin: '8px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '50%',
    '&:hover': {
      background: '#ccc',
    },
  },
  editInputBlock: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
  },
  previewHeaderMobileEditing: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  editNameButtonsPadding: {
    padding: '16px',
  },
  editNameButton: {
    display: 'flex',
    marginLeft: '8px',
    padding: '9px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '50%',
    '&:hover': {
      background: '#ccc',
    },
  },
  editNameInput: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 700,
    fontFamily: 'Sofia Pro, sans-serif',
    padding: 0,
    height: '24px',
    maxWidth: '132px',
    color: '#fff',
    background: 'none',
    border: 'unset',
    '&.desktop': {
      maxWidth: '100%',
    },
  },
  hiddenInput: {
    position: 'absolute',
    visibility: 'hidden',
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: 'Sofia Pro, sans-serif',
    padding: 0,
    maxWidth: '132px',
    '&.desktop': {
      maxWidth: 'calc(50% - 170px)',
    },
  },
  fileTypeLabel: {
    color: '#fff',
    opacity: 0.7,
    marginLeft: '2px',
    marginRight: '24px',
  },
  editButtons: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    border: 'unset',
    borderRadius: '50%',
    cursor: 'pointer',
    width: '32px',
    height: '32px',
    padding: '9px',
  },
  closeEditButton: {
    backgroundColor: theme.colors.redTransparent,
    marginRight: '8px',
  },
  saveEditButton: {
    backgroundColor: '#D1EDE8',
  },
  saveNameIcon: {
    display: 'flex',
    alignSelf: 'center',
    transform: 'scale(1.5)',
  },
  closeEditIcon: {
    display: 'flex',
    alignSelf: 'center',
  },
  previewEditButton: {
    display: 'flex',
    alignSelf: 'center',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',
    color: theme.colors.greyBlack,
    backgroundColor: '#fff',
    border: '1.5px solid #D1EDE8',
    margin: '40px 24px 16px 0',
    padding: '8px 16px',

    '&:hover': {
      backgroundColor: '#fff',
      borderColor: theme.colors.greyBlack,
    },
  },
  documentName: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 700,
    color: '#fff',
    textWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '157px',
    '&.desktop': {
      maxWidth: '100%',
    },
  },
  previewDetails: {
    width: '300px',
    maxWidth: '100%',
    margin: '0 16px',
    padding: '19px 16px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  mobileContext: {
    pointerEvents: 'none',
    visibility: 'unset!important',
    zIndex: '1200!important',
  },
  mobileContextShown: {
    pointerEvents: 'unset',
  },
  visibilityDrawerHidden: {
    visibility: 'unset!important',
    transform: 'translateY(calc(100% - 80px))!important',
    pointerEvents: 'unset!important',
    maxHeight: '95vh',
  },
  contentWrapper: {
    position: 'relative',
    padding: '19px 16px',
    overflow: 'scroll',
    maxHeight: '95vh',
    pointerEvents: 'auto',
  },
  contentHidden: {
    overflow: 'hidden',
  },
  mobileContextButton: {
    border: 'unset',
    background: 'unset',
    position: 'absolute',
    top: '20px',
    right: '20px',
  },
  mobileContextIcon: {
    transition: '0.2s',
  },
  mobileContextIconOpened: {
    transform: 'rotate(-180deg)',
  },
});

export default styles;
