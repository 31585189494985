import React from 'react';
import { Link } from 'react-router-dom';
import // getLoyaltyCompanyContactInfo,
// getLoyaltyCompanyLogo,
'../../../utils/LoyaltyCompanies';
import AlternateEmail from '../../../assets/icons/AlternateEmail';
import PhoneOutlined from '../../../assets/icons/PhoneOutlined';
import BrowserIcon from '../../../assets/icons/BrowserIcon';
import { getImageKitImageUrlForSanity } from '../../../content/contentHelpers';

const CompanyInfo = ({ loyaltyCompany, showEmail }) => {
  // const [contactInfo, setContactInfo] = useState(null);

  // useEffect(() => {
  //   // setContactInfo(getLoyaltyCompanyContactInfo(loyaltyCompany));
  // }, [loyaltyCompany]);
  return (
    <div className="offer_wrapper">
      <div className="logo_wrapper">
        <div className="logo_img_container">
          <img
            alt="Company Logo"
            src={getImageKitImageUrlForSanity(
              loyaltyCompany?.contactbox?.companyLogo?.asset
            )}
            className="landscape-horizonal"
          />
        </div>
      </div>
      <p className="info_paragraph">
        <PhoneOutlined className="info_icons" />
        {loyaltyCompany?.contactbox?.companyMobile}
      </p>
      <p className="info_paragraph">
        <AlternateEmail className="info_icons" />
        {showEmail ? loyaltyCompany?.contactbox?.companyEmail : '-'}
      </p>
      <p className="info_paragraph">
        <BrowserIcon className="info_icons" />
        <Link
          className="info_link"
          to={{ pathname: loyaltyCompany?.contactbox?.companyWeb?.href }}
          target="_blank"
        >
          {loyaltyCompany?.contactbox?.companyWeb?.title}
        </Link>
      </p>
    </div>
  );
};

export default CompanyInfo;
