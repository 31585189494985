import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core';

import {
  logError,
  logWarning,
  logLevelError,
  logLevelWarning,
} from '../../utils/loggers';

import styles from './style';
import Page from '../layouts/WithHeader';
import ErrorContainer from '../ErrorContainer';
import { GENERIC_CLIENT_ERROR_CODE } from '../../constants';

export const defaultState = {
  error: null,
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = defaultState;
  }

  componentDidCatch(error, errorInfo) {
    const { logLevel = logLevelError } = this.props;

    const stackTrace = this.getComponentStack(errorInfo);
    const errorMsg = this.getErrorMessage(error) || 'Unknown error';

    // TODO Remove older logger func. later once decided
    // that we are going with rollbar permenantly

    if (logLevel === logLevelWarning) {
      logWarning(`CLIENT WARNING: ${errorMsg}`, stackTrace);
    } else {
      logError(`CLIENT ERROR: ${errorMsg}`, stackTrace);
    }

    this.setState({
      error,
    });
  }

  getComponentStack = (errorInfo) =>
    errorInfo && errorInfo.componentStack
      ? errorInfo.componentStack
      : errorInfo;

  getErrorMessage = (error) =>
    error && error.message !== undefined ? error.message : null;

  resetError = () => {
    this.setState(defaultState);
  };

  render() {
    const { error } = this.state;
    const { classes, children, onError } = this.props;

    if (!error) {
      return children;
    }

    if (error && onError) {
      return onError();
    }

    return (
      <Page headerTitle="Oops">
        <ErrorContainer
          paddingTop={false}
          onHomeRedirect={this.resetError}
          className={classes.errorContainer}
          errorCode={GENERIC_CLIENT_ERROR_CODE}
        >
          {this.getErrorMessage(error)}
        </ErrorContainer>
      </Page>
    );
  }
}

export default withRouter(withStyles(styles)(ErrorBoundary));
