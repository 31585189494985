const styles = () => ({
  priceestimatecard: {
    marginBottom: '2px',
  },
  sectionBlur: {
    filter: 'blur(10px)',
    pointerEvents: 'none',
  },
  veridiCallToAction: {
    top: '0 !important',
    '@media (max-width: 959px)': {
      top: 'unset !important',
    },
  },
  veridiBoligmappaPlusSection: {
    position: 'relative',
    background: '#F3F6F6',
  },
  veridiBoligmappaPlusTextSection: {
    width: '65%',
    minHeight: '340px',
    fontSize: '18px',
    '@media (max-width: 959px)': {
      width: '100%',
    },
  },
  pageLayout: {
    background: '#F3F6F6',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  boligmappa_plus_logo_btn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fbf3c1',
    gap: '4px',
    padding: '4px 16px',
    borderRadius: '8px',
    border: 'none',
    fontWeight: '500',
    marginTop: '15px',
    fontSize: '18px',
    height: '36px',
    '& img': {
      width: '110px',
      height: '24px',
    },
  },
  contractAdvantages: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',

    '@media (max-width: 959px)': {
      gridTemplateColumns: 'auto',
    },
  },
});

export default styles;
