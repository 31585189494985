const styles = (theme) => ({
  tooltip: ({ fontSize, width }) => ({
    width,
    fontSize,
    boxShadow: theme.shadows[3],
    color: '#ffffff',
    backgroundColor: '#3B3B3B',
  }),
});

export default styles;
