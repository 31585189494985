import React from 'react';

const FileWordIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 16" {...props}>
    <path
      d="M2 0H7V4C7 4.5625 7.4375 5 8 5H12V14C12 15.125 11.0938 16 10 16H2C0.875 16 0 15.125 0 14V2C0 0.90625 0.875 0 2 0ZM8 0L12 4H8V0ZM3.46875 8.0625C3.34375 7.65625 2.90625 7.4375 2.53125 7.53125C2.125 7.65625 1.90625 8.09375 2.03125 8.46875L3.53125 13.4688C3.625 13.7812 3.90625 14 4.21875 14C4.53125 14.0312 4.84375 13.8125 4.9375 13.5L6 10.5312L7.03125 13.5C7.125 13.8125 7.4375 14.0312 7.75 14C8.09375 14 8.375 13.7812 8.46875 13.4688L9.96875 8.46875C10.0625 8.09375 9.84375 7.65625 9.4375 7.53125C9.0625 7.4375 8.625 7.65625 8.53125 8.0625L7.6875 10.8438L6.6875 8C6.59375 7.71875 6.3125 7.5 6 7.5C5.65625 7.5 5.375 7.71875 5.28125 8L4.28125 10.8438L3.46875 8.0625Z"
      fill="#2A5699"
    />
  </svg>
);

export default FileWordIcon;
