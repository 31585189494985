const styles = (theme) => ({
  linkIcon: {
    top: '7px',
    width: '25px',
    height: '25px',
    position: 'relative',
    fill: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
});

export default styles;
