import React, { createElement, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

import styles from './style';
import TableRow from '../atomic/TableRow';
import TableCell from '../atomic/TableCell';
import Typography from '../atomic/Typography';
import { getReadableSize, fileNameToFileExtension } from '../../utils/files';
import LinearProgress from '../atomic/LinearProgress';
import CheckboxCell from '../DocumentView/DocumentViewTable/Table/CheckboxCell';
import {
  getFileThumbNailForDocumentView,
  getDocumentName,
  truncateUploadedFileName,
  getFolderIdfromStructure,
} from '../../utils/documents';
import { createDocument } from '../../api/documents';
import { createPersonalDocument } from '../../api/propertyowners';
import { uploadFile } from '../../api/files';
import { prepareDocumentData } from '../forms/DocumentForm/util';
import { sGetUser } from '../../store/reducers/auth';
import { getUserFullname } from '../../utils/auth';
import { getRoomId, getRoomName } from '../../utils/rooms';
import { getEventId, getEventName } from '../../utils/events';
import useDesktop from '../../hooks/useDesktop';
import ListItem from '../atomic/ListItem';
import Checkbox from '../atomic/Checkbox';
import TextHighlighted from '../TextHighlighted';
import ListItemText from '../atomic/ListItemText';
import ListItemSecondaryAction from '../atomic/ListItemSecondaryAction';
import Box from '../atomic/Box';

const DocumentFielUploadItem = ({
  file,
  directoryId,
  setDirectoryId,
  directoryTree,
  span,
  classes,
  authUser,
  room,
  event,
  onUploaded,
  personalDocument = false,
}) => {
  const [progress, setProgress] = useState(0);
  const [checked, setChecked] = useState(true);
  const [progressColor, setProgressColor] = useState('primary');
  const { boligmappaNumber } = useParams();
  const isDesktop = useDesktop();

  const addDocument = async () => {
    let finalDirectoryId = null;
    if (directoryId && (!directoryTree || directoryTree?.length === 0)) {
      finalDirectoryId = directoryId;
    } else if (directoryTree && directoryTree?.length > 0) {
      finalDirectoryId = getFolderIdfromStructure(
        file?.webkitRelativePath,
        directoryTree
      );
    }

    const documentData = {
      ...prepareDocumentData({
        title: getDocumentName(file),
        updatedDate: moment().format(),
        createdDate: moment().format(),
        ownedByName: getUserFullname(authUser),
        eventIds: event
          ? [{ value: getEventId(event), label: getEventName(event) }]
          : [],
        roomIds: room
          ? [{ value: getRoomId(room), label: getRoomName(room) }]
          : [],
        rooms: room ? [room] : [],
        events: event ? [event] : [],
      }),
      boligmappaNumber: !personalDocument ? boligmappaNumber : null,
      fileName: file.name,
      directoryId: finalDirectoryId,
    };

    const [createDocumentError, createDocumentResponse] = personalDocument
      ? await createPersonalDocument(documentData)
      : await createDocument(documentData);

    if (createDocumentError) {
      setChecked(false);
      setProgressColor('secondary');
      setProgress(100);
      onUploaded({
        status: false,
        data: file.name,
      });
      return;
    }

    const { id, documentId, uploadLink } = createDocumentResponse;
    setProgress(Math.floor(Math.random() * 51 + 40));

    const [uploadDocumentError] = await uploadFile(uploadLink, file);

    if (uploadDocumentError) {
      setChecked(false);
      setProgressColor('secondary');
      setProgress(100);
      onUploaded({
        status: false,
        data: file.name,
      });
    } else {
      setProgress(100);
      onUploaded({
        status: true,
        data: {
          id: id || documentId, // backend call should return id nor documentId for consistancy
          ...documentData,
        },
      });
    }
  };

  useEffect(() => {
    if (file) {
      if (file.size > 500000000) {
        setProgressColor('secondary');
        setProgress(100);

        onUploaded({
          status: false,
          data: file.name,
        });
      } else {
        addDocument();
      }
    }
    return () => setDirectoryId();
  }, [file]);

  if (isDesktop) {
    return (
      <TableRow className={classes.uploadRow}>
        <CheckboxCell
          isVisible
          isChecked={checked}
          disabled={file}
          className={classes.checkboxCell}
          style={{ padding: 0 }}
        />
        <TableCell className={classes.tableRow} colSpan={span - 1}>
          <div className={classes.ItemContainer}>
            <span className={classes.imageIconBoxTable}>
              {createElement(
                getFileThumbNailForDocumentView(
                  fileNameToFileExtension(file.name)
                ),
                { className: classes.imageIcon }
              )}
            </span>
            <div className={classes.ProgressContainer}>
              <Typography align="left" fontWeight="bold" fontSize="14px">
                {file.name}
              </Typography>
              <Typography className={classes.fileDetailsText} fontSize="12px">
                {getReadableSize(file.size)} • {getUserFullname(authUser)}
              </Typography>
            </div>
          </div>
        </TableCell>
        <LinearProgress
          className={classes.linearProgress}
          variant="determinate"
          value={progress}
          color={progressColor}
        />
      </TableRow>
    );
  }

  return (
    <ListItem className={clsx(classes.listItem, 'word-break')}>
      <div className={classes.divmobile}>
        <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
          <Checkbox checked className={classes.checkbox} disabled />
        </ListItemSecondaryAction>
        <span className={classes.imageIconBox}>
          {createElement(
            getFileThumbNailForDocumentView(fileNameToFileExtension(file.name)),
            { className: classes.imageIcon }
          )}
        </span>

        <ListItemText
          primary={
            <TextHighlighted>
              <span className={classes.primaryText}>
                {truncateUploadedFileName(file, 25)}
              </span>
            </TextHighlighted>
          }
          secondaryTypographyProps={{ component: 'div' }}
          secondary={
            <Box>
              <small className={classes.secondaryText}>
                <TextHighlighted>
                  {' '}
                  {[getReadableSize(file.size), getUserFullname(authUser)].join(
                    ' • '
                  )}
                </TextHighlighted>
              </small>
            </Box>
          }
        />
      </div>
      <div className={classes.ProgressContainerMobile}>
        <LinearProgress
          variant="determinate"
          value={progress}
          color={progressColor}
        />
      </div>
    </ListItem>
  );
};

const mapStateToProps = (state) => ({
  authUser: sGetUser(state),
});

export default connect(mapStateToProps)(
  withStyles(styles)(DocumentFielUploadItem)
);
