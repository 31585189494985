const style = {
  listDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tagDiv: {
    marginLeft: '15px',
  },
};

export default style;
