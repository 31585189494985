import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { withStyles, useMediaQuery, useTheme } from '@material-ui/core';
// eslint-disable-next-line import/no-unresolved, import/extensions
import { OverviewGridItem } from 'views/PublicRoutes/Dashboard/Home/Overview/components/OverviewGridItem';
import ProgressButton from '../ProgressButton';
import styles from './style';
import Box from '../atomic/Box';
import Grid from '../atomic/Grid';
import Image from '../Image/index';
import Typography from '../atomic/Typography';
import CardImage from '../../assets/images/Application_In_Phone.png';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';
import matomo from '../../utils/matomo';

const PurchasingInsuranceExperimentCTA = ({
  classes,
  insurancePaidDocuments,
  pendingDaycount,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const dashboardLinkPrefix = useDashboardLinkPrefix();

  useEffect(() => {
    matomo.clickEvent({
      category: 'Trygt utført',
      name: '',
      action: 'B:Rendered Trygt Utført',
    });
  }, []);

  return (
    <OverviewGridItem showMaxwidth>
      <Box
        className={classes.content}
        style={{ margin: isSmallScreen && '10px' }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className={classes.textArea}
            style={{ order: isSmallScreen ? 2 : 1 }}
          >
            <Typography
              style={{
                marginBottom: '14px',
                color: '#CD4646',
                fontWeight: '700',
              }}
            >
              Kjøp innen {pendingDaycount} dager!
            </Typography>
            <Typography mt={0} variant="h2" className={classes.title}>
              Tusenvis av håndverkertjenester ender opp i langvarig konflikt
              hvert år
            </Typography>

            <Typography className={classes.text}>
              Selv med gode hensikter, kan man bli uenig. Med Trygt utført fra
              Boligmappa får du advokathjelp i 5 år til kun 500,-
            </Typography>

            <Box className={classes.btnContent}>
              <ProgressButton
                color="primary"
                variant="contained"
                onClick={() => {
                  matomo.clickEvent({
                    category: 'Trygt utført ',
                    name: '',
                    action: 'Clicked Learn More for Trygt Utført -B',
                  });
                  history.push(
                    `${dashboardLinkPrefix}/insurance?insurancePaidDocuments=${insurancePaidDocuments.join(
                      ','
                    )}`
                  );
                }}
                className={classes.button}
              >
                Lær mer og kjøp forsikring
              </ProgressButton>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            style={{ display: 'flex', order: isSmallScreen ? 1 : 2 }}
            className={classes.imageBox}
          >
            <Image
              className={classes.image}
              style={{ margin: isSmallScreen ? 'auto' : '0' }}
              fallbackSrc={CardImage}
            />
          </Grid>
        </Grid>
      </Box>
    </OverviewGridItem>
  );
};

export default withStyles(styles)(PurchasingInsuranceExperimentCTA);
