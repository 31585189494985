/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useDispatch } from 'react-redux';
import Card from '../../../../../components/Card';
import useDesktop from '../../../../../hooks/useDesktop';
import { useFetchDocuments } from '../../../../../api/hooks';
import { useActiveProperty } from '../../../../../store/hooks';
import { acDetachDocumentFromRooms } from '../../../../../store/actions/rooms';
import { acDetachDocumentFromEvents } from '../../../../../store/actions/events';
import { acDeleteDocument } from '../../../../../store/actions/documents';
import { OverviewGridItem } from './components';
import DocumentList from '../../../../../components/DocumentList';
import Typography from '../../../../../components/atomic/Typography';
import useDashboardLinkPrefix from '../../../../../hooks/useDashboardLinkPrefix';
import SeeMoreLink from '../../../../../components/SeeMoreLink';
import Box from '../../../../../components/atomic/Box';
import Headline from '../../../../../components/Headline';
import matomo from '../../../../../utils/matomo';

export function PropertyDocumentsSection() {
  const dispatch = useDispatch();

  const { property } = useActiveProperty();

  const documents = useFetchDocuments(property);

  const isDesktop = useDesktop();

  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const onDocumentDeleted = (document) => {
    dispatch(acDetachDocumentFromRooms(document));
    dispatch(acDetachDocumentFromEvents(document));
    dispatch(acDeleteDocument(document));
  };

  return (
    <>
      <OverviewGridItem>
        <Card
          border={isDesktop ? 1 : 0}
          padding={isDesktop ? 3 : 0}
          height="100%"
        >
          {isDesktop ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '-14px',
              }}
            >
              <Typography
                paragraph
                variant="h2"
                fontWeight={800}
                noMargins={!isDesktop}
              >
                Siste dokumenter
              </Typography>
              <SeeMoreLink
                to={`${dashboardLinkPrefix}/documents`}
                onClick={() =>
                  matomo.clickEvent({
                    category: 'Dashboard CTAs',
                    action: 'See More Documents',
                  })
                }
              >
                Se mer
              </SeeMoreLink>
            </Box>
          ) : (
            <Headline mt={3} mb={3} h="h2" align="center">
              Siste dokumenter
            </Headline>
          )}

          <DocumentList
            showRoomsColumn
            showEventsColumn
            showOnPaper={false}
            showFilters={false}
            fetching={documents?.isLoading}
            documents={documents?.firstFiveDocuments?.slice(
              0,
              isDesktop ? 2 : 1
            )}
            onDocumentDeleted={onDocumentDeleted}
            enableWidgetContextMenu={isDesktop}
          />
          {!isDesktop && (
            <Box py={2} textAlign="right">
              <SeeMoreLink to={`${dashboardLinkPrefix}/documents`}>
                Se mer
              </SeeMoreLink>
            </Box>
          )}
        </Card>
      </OverviewGridItem>
    </>
  );
}
