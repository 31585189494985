import { useEffect, useState, useRef } from 'react';

/**
 * This hook is used as an abstraction for api calls.
 * It takes a async function as an argument, and this
 * function is triggered when executeFetch is called.
 *
 * The function passed in needs to be an axios fetch function:
 *
 * ```js
 *  const fetchFunc = (args) => axiosFetch(url)
 * ```
 * The hook then handles the promise and returns loading state, error and data.
 *
 * We might change / remove the data fetching library in the future
 *
 * @param {(args) => Promise} functionForFetching
 */
export function useApiFetch(functionForFetching) {
  const [responseData, setResponseData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * isMounted is a flag for preventing state updates on an unmounted component.
   * It is stored in a useRef to prevent it from being reassigned on each render.
   * We need this because we are running an async function that might resolve 
   * after the components in unmounted.  
   */
  const isMounted = useRef(true);

  const executeFetch = async (...args) => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      /**
       * The api returns a response with the following format:
       * {
       *  response: data[],
       *  success: boolean
       * }
       * We are using axios, which are returing the response data
       * from a prop named "data" as default. That's why we need to
       * set the response data as data.response.
       */
      const { data } = await functionForFetching(...args);

      // Only update state if component is mounted
      if (isMounted.current) {
        setResponseData(data.response);
      }
    } catch (e) {
      if (isMounted.current) {
        setError(e);
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    return () => {
      // Prevents state updates after unmount
      isMounted.current = false;
    };
  }, []);

  return {
    executeFetch,
    isLoading,
    error,
    data: responseData,
  };
}
