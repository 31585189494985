import React from 'react';
import { Switch } from 'react-router';

import Dashboard from './Dashboard';
import Route from '../../components/atomic/Route';

const PublicRoutes = () => (
  <Switch>
    <Route path="/public/:boligmappaNumber">
      <Dashboard />
    </Route>

    {/* TODO 404 page */}
  </Switch>
);

export default PublicRoutes;
