import Cookies from 'js-cookie';
import { cookieConsentKey } from '../config';
import localStorage from './localStorage';

export const setCookie = (cookieName, cookieValue, expires = 30) =>
  Cookies.set(cookieName, cookieValue, {
    expires,
    path: '/',
    sameSite: 'strict',
  });

export const getCookie = (cookieName) => Cookies.get(cookieName);

export const removeCookie = (cookieName) => Cookies.remove(cookieName);

// TODO use cookie for storing cookie consent once issue is identified
export const retrieveCookieConsent = () =>
  localStorage.get(cookieConsentKey)
    ? JSON.parse(localStorage.get(cookieConsentKey))
    : undefined;

// TODO use cookie for storing cookie consent once issue is identified
export const storeCookieConsent = (consent) =>
  localStorage.set(cookieConsentKey, JSON.stringify(consent));
