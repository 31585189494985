import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import ImageGallery from '../../components/ImageGallery';
import { sortImagesByPosition } from '../../utils/images';
import { getEventId, getEventImages } from '../../utils/events';
import {
  updateEvent as apiUpdateEvent,
  uploadImage as apiUploadImage,
} from '../../api/events';
import UploadImageButton from '../../components/forms/ImageForm/UploadImageButton';
import Button from '../../components/atomic/Button';
import Camera from '../../assets/icons/Camera';
import Bin from '../../assets/icons/Bin';
import useActionDialog from '../../hooks/useActionDialog';

const EventImages = ({
  classes,
  fetching,
  event,
  editAccess = true,
  isManualUpload = false,
  onEventImageUpload = () => {},
  onEventImageDelete = () => {},
  isGalleryModeActive = true,
}) => {
  const { showActionDialog } = useActionDialog();

  const [imageRef, setImageRef] = useState(null);
  const [imageRefUpload, setImageRefUpload] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  let eventImages = (getEventImages(event) || []).sort(sortImagesByPosition);

  const lastImagePosition = Array.isArray(eventImages) ? eventImages.length : 0;

  const uploadImageRequest = (image, position) =>
    apiUploadImage(getEventId(event), image, position);

  const showIndex = Array.isArray(eventImages) && eventImages.length > 0;

  const onImagesUploaded = (uploadedImages, imageFiles) => {
    eventImages = uploadedImages;
    onEventImageUpload(uploadedImages, imageFiles);
  };

  const openImageUploadDialog = () => imageRefUpload.click();

  const onImagesUpdated = async (imagesToUpdate, onSuccess, onError) => {
    if (!isManualUpload) {
      const [error, response] = await apiUpdateEvent(getEventId(event), {
        imageIds: imagesToUpdate.map((image) => image.id),
      });

      if (!error && response) {
        const imageRest = (imagesToUpdate || []).map((image, index) => {
          const i = image;
          i.position = index + 1;
          return i;
        });
        onEventImageDelete(imageRest, selectedImageIndex);
        eventImages = imageRest;
        if (onSuccess) onSuccess();
      }

      if (onError && error && !response) {
        onError();
      }
    } else {
      const imageRest = (imagesToUpdate || []).map((image, index) => {
        const i = image;
        i.position = index + 1;
        return i;
      });
      onEventImageDelete(imageRest, selectedImageIndex);
      eventImages = imageRest;
    }
  };

  const onEventImageChange = (index) => {
    setSelectedImageIndex(index);
  };

  const onImageDelete = () =>
    showActionDialog({
      submitButtonText: 'Slett',
      title: 'Vil du slette dette bilde?',
      onSubmit: () => {
        const eventImagesToSave = [...eventImages];
        eventImagesToSave.splice(selectedImageIndex, 1);
        onImagesUpdated(eventImagesToSave);
      },
    });

  return (
    <div className={classes.container}>
      {eventImages?.length === 0 ? (
        <UploadImageButton
          showButton
          imageRef={imageRef}
          className={classes.upload_btn_container}
          isManualUpload={isManualUpload}
          setImageRef={setImageRef}
          onImagesUploaded={onImagesUploaded}
          lastImagePosition={lastImagePosition}
          uploadImageRequest={uploadImageRequest}
        />
      ) : (
        <>
          <ImageGallery
            dots={false}
            showPlaceholder
            images={eventImages}
            editable={editAccess}
            showIndex={showIndex}
            fetching={!event || fetching}
            className={classes.image_galary_view}
            onImagesUpdated={onImagesUpdated}
            getCurrentIndex={onEventImageChange}
            isGalleryModeActive={isGalleryModeActive}
          />
          <div className={classes.image_heading}>
            <div className={classes.actions}>
              <Button className={classes.deleteIcon} onClick={onImageDelete}>
                <Bin className={classes.binIcon} />
              </Button>
              <Button
                size="small"
                className={classes.editButton}
                onClick={() => openImageUploadDialog()}
              >
                <Camera className={classes.cameraIcon} />
                <small>Rediger</small>
                <UploadImageButton
                  showButton={false}
                  imageRef={imageRefUpload}
                  className={classes.upload_btn_container}
                  setImageRef={setImageRefUpload}
                  isManualUpload={isManualUpload}
                  onImagesUploaded={onImagesUploaded}
                  lastImagePosition={lastImagePosition}
                  uploadImageRequest={uploadImageRequest}
                />
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(EventImages);
