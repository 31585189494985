import React from 'react';
import CheckboxControl from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Label = ({ children }) => (
  <span style={{ fontSize: '1rem' }}>{children}</span>
);

const Checkbox = ({
  label,
  style,
  disabled,
  required,
  children,
  checkboxColor = 'primary',
  ...props
}) => (
  <FormControlLabel
    label={(label || children) && <Label>{label || children}</Label>}
    control={
      <CheckboxControl
        style={style}
        disabled={disabled}
        required={required}
        color={checkboxColor}
      />
    }
    {...props}
  />
);

export default Checkbox;
