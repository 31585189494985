const styles = (theme) => ({
  emptyMessage: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '20%',
    },
  },
});

export default styles;
