import React, { useEffect, useState, useContext } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { withStyles } from '@material-ui/core';

import {
  fetchRooms as apiFetchRooms,
  fetchDocuments as apiFetchDocuments,
} from '../../../../../../api/properties';

import styles from './styles';
import Grid from '../../../../../../components/atomic/Grid';
import TextArea from '../../../../../../components/TextArea';
import Autocomplete from '../../../../../../components/Autocomplete';
import TextField from '../../../../../../components/atomic/TextField';
import InputLabel from '../../../../../../components/atomic/InputLabel';
import useDesktop from '../../../../../../hooks/useDesktop';
import {
  categoryOther,
  eventTypes,
} from '../../../../../../components/forms/EventForm/eventTypes';
import DocumentOptionList from '../../../../../../components/DocumentOptionList';
import {
  categoryOptions,
  documentOptions,
  prepareEventData,
  roomOptions,
} from '../../../../../../components/forms/EventForm/util';
import {
  getEventDescription,
  getEventName,
  getEventCategory,
  getEventStartDate,
  getEventEndDate,
  getEventSubType,
  getEventImages,
} from '../../../../../../utils/events';
import { getRoomId, getRoomName } from '../../../../../../utils/rooms';
import {
  getDocumentId,
  getDocumentName,
} from '../../../../../../utils/documents';
import {
  acSetFetchingRooms,
  acSetRooms,
} from '../../../../../../store/actions/rooms';
import {
  acSetDocuments,
  acSetFetchingDocuments,
} from '../../../../../../store/actions/documents';
import useFetchEffect from '../../../../../../hooks/useFetchEffect';
import { sGetDocuments } from '../../../../../../store/reducers/documents';
import { sGetRooms } from '../../../../../../store/reducers/rooms';
import {
  getErrorMessage,
  getResponseErrorMessage,
} from '../../../../../../utils/requests';
import ProgressButton from '../../../../../../components/ProgressButton';
import Button from '../../../../../../components/atomic/Button';
import DatePicker from '../../../../../../components/DateTimePicker';
import EventImages from '../../../../../../modules/EventImages';
import DialogContent from '../../../../../../components/atomic/DialogContent';
import DialogActions from '../../../../../../components/atomic/DialogActions';
import Box from '../../../../../../components/atomic/Box';
import Add from '../../../../../../assets/icons/Add';
import DialogOverlay from '../../../../../../components/DialogOverlay';
import CreateRoomWrapper from './CreateRoomWrapper';
import UploadDocumentsWrapper from './UploadDocumentsWrapper';
import { FileUploadContext } from '../../../../../../contexts/fileUpload';

const descriptionMaxLength = 1500;

const CreateEvent = ({
  classes,
  boligmappaNumber,
  event,
  setEvent,
  rooms,
  property,
  documents,
  setRooms,
  setDocuments,
  setFetchingRooms,
  setFetchingDocuments,
  previousStep,
  error,
  fetching,
  requestInProgress,
  onSumbitEvent,
  evetTemplate,
}) => {
  const isDesktop = useDesktop();
  const {
    control,
    handleSubmit,
    errors,
    setValue,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  const [currentEvent, setCurrentEvent] = useState();

  const [dateEnd, setDateEnd] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionPlaceHolder, setDescriptionPlaceHolder] = useState('');
  const [category, setCategory] = useState(null);
  const [roomIds, setRoomIds] = useState([]);
  const [documentIds, setDocumentIds] = useState([]);
  const [customCategory, setCustomCategory] = useState('');
  const [imageFiles, setImageFiles] = useState([]);

  const [createRoomOverlay, setCreateRoomOverlay] = useState(false);
  const [uploadDocumentOverlay, setUploadDocumentOverlay] = useState(false);

  const { onFileUploadClick, onFileUploadCancel } =
    useContext(FileUploadContext);

  const { fetching: fetchingRooms } = useFetchEffect({
    conditionFunction: () => !rooms,
    onSuccess: (data) => setRooms(data),
    setFetchingFunction: setFetchingRooms,
    apiFetchFunction: () => apiFetchRooms(boligmappaNumber),
  });

  const { fetching: fetchingDocuments } = useFetchEffect({
    conditionFunction: () => !documents,
    onSuccess: (data) => setDocuments(data),
    setFetchingFunction: setFetchingDocuments,
    apiFetchFunction: () =>
      apiFetchDocuments(boligmappaNumber, { includeTaggedInfo: true }),
  });

  const generateEventData = () =>
    prepareEventData({ ...currentEvent, rooms, documents });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (currentEvent) {
        setEvent(generateEventData());
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [currentEvent]);

  const updateEvent = (field, newValue) =>
    setCurrentEvent((prevState) => ({
      ...prevState,
      [field]: newValue,
    }));

  const onChangeRooms = (options) => {
    setRoomIds(options || []);
    updateEvent('roomIds', options || []);
  };

  const onChangeDocuments = (options) => {
    setDocumentIds(options || []);
    updateEvent('documentIds', options || []);
  };

  const onCategoryChange = (option) => {
    setCategory(option);
    setCustomCategory('');
    updateEvent('category', option);
    updateEvent('customCategory', '');
  };

  const setInitialEvent = (initialCategory, initialCustomCategory = '') =>
    setCurrentEvent({
      ...event,
      category: initialCategory,
      customCategory: initialCustomCategory,
    });

  useEffect(() => {
    if (event) {
      setValue('title', getEventName(event));
      setValue(
        'dateStart',
        getEventStartDate(event) ? new Date(getEventStartDate(event)) : ''
      );
      setDateEnd(
        getEventEndDate(event) ? new Date(getEventEndDate(event)) : ''
      );
      if (evetTemplate?.description !== getEventDescription(event)) {
        setDescription(getEventDescription(event));
      } else {
        setDescription('');
      }
      setDescriptionPlaceHolder(getEventDescription(event));

      const initialCategory = getEventCategory(event) || getEventSubType(event);

      const staticEventType = eventTypes.find(
        (eventType) => eventType === initialCategory
      );

      const eventCategory = {
        value: staticEventType || categoryOther,
        label: staticEventType || categoryOther,
      };

      setCategory(eventCategory);

      if (staticEventType) {
        setInitialEvent(eventCategory);
      } else {
        setInitialEvent(eventCategory, initialCategory);
        setCustomCategory(initialCategory);
      }
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(rooms)) {
      const selectedRooms = [
        ...rooms
          .filter((room) => event?.roomIds.includes(getRoomId(room)))
          .map((room) => ({
            value: getRoomId(room),
            label: getRoomName(room),
          })),
        ...roomIds,
      ];
      onChangeRooms(selectedRooms);
    }
  }, [rooms]);

  useEffect(() => {
    if (Array.isArray(documents)) {
      const selectedDocuments = [
        ...documents
          .filter((document) =>
            event?.documentIds.includes(getDocumentId(document))
          )
          .map((document) => ({
            value: getDocumentId(document),
            label: getDocumentName(document),
          })),
        ...documentIds,
      ];
      onChangeDocuments(selectedDocuments);
    }
  }, [documents]);

  const onSubmit = (_, e) => {
    if (createRoomOverlay) return;
    e.preventDefault();
    onSumbitEvent(generateEventData(), imageFiles);
  };

  const roomSelectField = (
    <Autocomplete
      isMulti
      value={roomIds}
      options={roomOptions(rooms)}
      label="Koble til rom"
      isLoading={fetchingRooms}
      closeMenuOnSelect={false}
      disabled={requestInProgress}
      className={classes.formElement}
      loadingMessage={() => 'Laster...'}
      noOptionsMessage={() => 'Ingen rom'}
      placeholder="Velg ett eller flere rom"
      onChange={(options) => onChangeRooms(options)}
    />
  );

  const onEventImageUpload = (uploadedImages, recentFiles) => {
    setImageFiles([...imageFiles, ...recentFiles]);

    setCurrentEvent((prevState) => ({
      ...prevState,
      gallery: [...(getEventImages(prevState) || []), ...uploadedImages],
    }));
  };

  const onEventImageDelete = (imagesToBeUpload, removedIndex) => {
    setImageFiles((preval) => {
      const files = preval;
      files.splice(removedIndex, 1);
      return files;
    });
    setCurrentEvent((prevState) => ({
      ...prevState,
      gallery: [...imagesToBeUpload],
    }));
  };

  const onAddRoom = () => {
    setCreateRoomOverlay(true);
  };

  const onCloseCreateRoom = () => setCreateRoomOverlay(false);

  const onRoomCreated = (roomOption) => {
    setRoomIds((prevState) => {
      const currentValue = (prevState || []).filter(
        (v, i, a) => a.findIndex((t) => t.value === v.value) === i
      );
      currentValue.push(roomOption);
      onChangeRooms(currentValue);
      return currentValue;
    });
  };

  const onUploadDocument = () => {
    setUploadDocumentOverlay(true);
    onFileUploadClick({ query: { bybfEventsMode: true } });
  };

  const onCloseUploadDocument = () => {
    setUploadDocumentOverlay(false);
    onFileUploadCancel();
  };

  const onDocumentUploaded = (documentUploaded) =>
    onChangeDocuments([...documentIds, documentUploaded]);

  const renderForm = () => (
    <form id="createEvent" onSubmit={handleSubmit(onSubmit)}>
      {error && (
        <div className={clsx(classes.error, 'word-break')}>
          {getResponseErrorMessage(error) || getErrorMessage(error)}
        </div>
      )}

      <Box className={classes.eventImagesBox}>
        <EventImages
          editAccess={false}
          fetching={false}
          event={event}
          isManualUpload
          onEventImageUpload={onEventImageUpload}
          onEventImageDelete={onEventImageDelete}
          isGalleryModeActive={false}
        />
      </Box>

      <Controller
        name="title"
        control={control}
        defaultValue={event?.title}
        rules={{ required: true }}
        render={({ onChange, value, ...props }) => (
          <TextField
            required
            value={value}
            inputProps={{
              maxLength: 60,
            }}
            label="Navn på hendelsen"
            className={classes.formElement}
            disabled={fetching || requestInProgress}
            onChange={(e) => {
              onChange(e.target.value);
              updateEvent('title', e.target.value);
            }}
            placeholder="Feks. Oppussing kjøkken, flislegging bad..."
            error={errors.title}
            {...props}
          />
        )}
      />

      <Grid
        container
        className={clsx(classes.formElement, classes.datesContainer)}
      >
        <Grid item xs={5} spacing={1}>
          <Controller
            name="dateStart"
            defaultValue={event?.dateStart}
            control={control}
            rules={{ required: true }}
            render={({ onChange, value, ...props }) => (
              <DatePicker
                isSimple
                required
                label="Start"
                value={value}
                dateSelected={value}
                placeholder="mm/åååå"
                disabled={fetching || requestInProgress}
                onChange={(_value) => {
                  onChange(_value);
                  updateEvent('dateStart', _value);
                }}
                error={errors.dateStart}
                popperModifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [20, 0],
                    },
                  },
                ]}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                {...props}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={1}
          container
          spacing={1}
          className={classes.toLabelContainer}
        >
          <InputLabel
            shrink
            className={classes.dateLabel}
            disabled={fetching || requestInProgress}
          >
            til
          </InputLabel>
        </Grid>
        <Grid
          item
          xs={5}
          container
          spacing={1}
          className={classes.datePickerContainer}
        >
          <DatePicker
            isSimple
            label="Slutt"
            value={dateEnd}
            dateSelected={dateEnd}
            placeholder="mm/åååå"
            disabled={fetching || requestInProgress}
            onChange={(value) => {
              setDateEnd(value);
              updateEvent('dateEnd', value);
            }}
            popperModifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ]}
            selectsEnd
            dateFormat="MM/yyyy"
            showMonthYearPicker
          />
        </Grid>
      </Grid>

      <TextArea
        value={description}
        placeholder={descriptionPlaceHolder}
        label="Beskrivelse"
        className={classes.formElement}
        maxLength={descriptionMaxLength}
        disabled={fetching || requestInProgress}
        onChange={(e) => {
          setDescription(e.target.value);
          updateEvent('description', e.target.value);
        }}
      />

      <Autocomplete
        label="Kategori"
        value={category}
        options={categoryOptions(eventTypes)}
        placeholder="Velg kategori"
        onChange={onCategoryChange}
        className={classes.formElement}
        noOptionsMessage={() => 'Ingen kategorier'}
        disabled={fetching || requestInProgress}
      />

      {category && category.label && category.label === categoryOther && (
        <TextField
          label="Din kategori"
          value={customCategory}
          className={classes.formElement}
          disabled={fetching || requestInProgress}
          onChange={(e) => {
            setCustomCategory(e.target.value);
            updateEvent('customCategory', e.target.value);
          }}
        />
      )}

      {Array.isArray(rooms) && rooms.length > 0 && roomSelectField}
      {Array.isArray(rooms) && rooms.length > 0 && (
        <>
          <Button
            className={classes.addRoomBtn}
            color="primary"
            variant="outlined"
            onClick={onAddRoom}
          >
            <Add className={classes.addIcon} /> Opprett rom
          </Button>
        </>
      )}
      {(!Array.isArray(rooms) || rooms.length <= 0) && (
        <>
          <InputLabel shrink className={classes.labelElement}>
            Koble til rom
          </InputLabel>
          <Button
            className={classes.addRoomBtn}
            color="primary"
            variant="outlined"
            onClick={onAddRoom}
          >
            <Add className={classes.addIcon} /> Opprett rom
          </Button>
        </>
      )}
      {Array.isArray(documents) && documents.length > 0 && (
        <>
          <DocumentOptionList
            value={documentIds}
            options={documentOptions(documents)}
            disabled={requestInProgress}
            isLoading={fetchingDocuments}
            className={classes.formElement}
            onChange={(options) => onChangeDocuments(options)}
          />

          <Button
            className={classes.addRoomBtn}
            color="primary"
            variant="outlined"
            onClick={onUploadDocument}
          >
            <Add className={classes.addIcon} /> Last opp dokument
          </Button>
        </>
      )}

      {(!Array.isArray(documents) || documents.length <= 0) && (
        <>
          <InputLabel shrink className={classes.labelElement}>
            Koble til dokument
          </InputLabel>
          <Button
            className={classes.addRoomBtn}
            color="primary"
            variant="outlined"
            onClick={onUploadDocument}
          >
            <Add className={classes.addIcon} /> Last opp dokument
          </Button>
        </>
      )}
    </form>
  );

  return (
    <>
      {createRoomOverlay && (
        <DialogOverlay headerTxt="Opprett rom" onClose={onCloseCreateRoom}>
          <CreateRoomWrapper
            property={property}
            onClose={onCloseCreateRoom}
            onRoomCreated={onRoomCreated}
          />
        </DialogOverlay>
      )}

      {uploadDocumentOverlay && (
        <DialogOverlay
          headerTxt="Last opp dokument"
          onClose={onCloseUploadDocument}
        >
          <UploadDocumentsWrapper
            property={property}
            onClose={onCloseUploadDocument}
            onDocumentUploaded={onDocumentUploaded}
          />
        </DialogOverlay>
      )}

      <DialogContent
        className={clsx(
          isDesktop && classes.dialogDesktopPadding,
          !isDesktop && classes.dialogPaddingMobile
        )}
      >
        {renderForm()}
      </DialogContent>

      <DialogActions
        className={clsx(
          isDesktop ? classes.actionButtons : classes.actionButtonsMobile
        )}
      >
        <Button
          className={isDesktop ? classes.backBtn : classes.backBtnMobile}
          color="primary"
          variant="outlined"
          onClick={previousStep}
        >
          Forrige
        </Button>
        <ProgressButton
          className={isDesktop ? classes.nextBtn : classes.nextBtnMobile}
          type="submit"
          color="primary"
          variant="contained"
          align="right"
          onClick={handleSubmit(onSubmit)}
          form="createEvent"
          requestInProgress={requestInProgress}
          disabled={!isValid}
        >
          Lagre
        </ProgressButton>
      </DialogActions>
    </>
  );
};

const mapStateToProps = (state) => ({
  rooms: sGetRooms(state),
  documents: sGetDocuments(state),
});

const mapDispatchToProps = (dispatch) => ({
  setRooms: (rooms) => dispatch(acSetRooms(rooms)),
  setDocuments: (documents) => dispatch(acSetDocuments(documents)),
  setFetchingDocuments: (fetching) =>
    dispatch(acSetFetchingDocuments(fetching)),
  setFetchingRooms: (fetching) => dispatch(acSetFetchingRooms(fetching)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CreateEvent));
