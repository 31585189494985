import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import {
  acSetRooms,
  acSetFetchingRooms,
  acSetFetchingRoomsError,
} from '../../../../store/actions/rooms';

import {
  sGetRooms,
  sGetFetchingRooms,
  sGetFetchingRoomsError,
} from '../../../../store/reducers/rooms';

import {
  fetchRooms,
  fetchDocuments as apiFetchDocuments,
  fetchEvents as apiFetchEvents,
} from '../../../../api/properties';
import RoomsPage from '../../../../components/RoomsPage';
import useFetchEffect from '../../../../hooks/useFetchEffect';
import {
  sGetDocuments,
  sGetFetchingDocuments,
} from '../../../../store/reducers/documents';
import {
  acSetDocuments,
  acSetFetchingDocuments,
} from '../../../../store/actions/documents';

import {
  acSetEvents,
  acSetFetchingEvents,
} from '../../../../store/actions/events';

import {
  sGetEvents,
  sGetFetchingEvents,
} from '../../../../store/reducers/events';

const Rooms = ({
  rooms,
  setRooms,
  fetching,
  setFetching,
  fetchingError,
  setFetchingError,
  documents,
  setDocuments,
  setFetchingDocuments,
  fetchingDocuments,
  fetchingEvents,
  setEvents,
  events,
  setFetchingEvents,
}) => {
  const { boligmappaNumber } = useParams();

  useFetchEffect({
    defaultFetchingState: !rooms,
    setFetchingFunction: setFetching,
    onSuccess: (data) => setRooms(data),
    onError: (error) => setFetchingError(error),
    conditionFunction: () => !rooms && !fetching,
    apiFetchFunction: () => fetchRooms(boligmappaNumber),
  });

  useFetchEffect({
    onFetchStart: () => setDocuments(null),
    onSuccess: (data) => setDocuments(data),
    setFetchingFunction: setFetchingDocuments,
    apiFetchFunction: () =>
      apiFetchDocuments(boligmappaNumber, { includeTaggedInfo: true }),
    conditionFunction: () => !documents && !fetchingDocuments,
  });

  useFetchEffect({
    dependencies: [boligmappaNumber],
    setFetchingFunction: setFetchingEvents,
    onSuccess: (data) => setEvents(data),
    conditionFunction: () => !events && !fetchingEvents,
    apiFetchFunction: () => apiFetchEvents(boligmappaNumber),
  });

  return (
    <RoomsPage
      rooms={rooms}
      fetching={fetching}
      fetchingError={fetchingError}
    />
  );
};

const mapStateToProps = (state) => ({
  rooms: sGetRooms(state),
  fetching: sGetFetchingRooms(state),
  fetchingError: sGetFetchingRoomsError(state),
  fetchingDocuments: sGetFetchingDocuments(state),
  documents: sGetDocuments(state),
  events: sGetEvents(state),
  fetchingEvents: sGetFetchingEvents(state),
});

const mapDispatchToProps = (dispatch) => ({
  setRooms: (rooms) => dispatch(acSetRooms(rooms)),
  setFetching: (fetching) => dispatch(acSetFetchingRooms(fetching)),
  setFetchingError: (error) => dispatch(acSetFetchingRoomsError(error)),
  setFetchingDocuments: (fetching) =>
    dispatch(acSetFetchingDocuments(fetching)),
  setDocuments: (documents) => dispatch(acSetDocuments(documents)),
  setEvents: (events) => dispatch(acSetEvents(events)),
  setFetchingEvents: (fetchingEvents) =>
    dispatch(acSetFetchingEvents(fetchingEvents)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rooms);
