import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';

import {
  getPropertyAddress,
  getPropertyGoogleStreetImage,
} from '../../../../../utils/properties';

import styles from './style';
import InfoIcon from '../../../../../assets/icons/Info';
import Visibility from '../../../../../assets/icons/Visibility';
import useActionDialog from '../../../../../hooks/useActionDialog';
import IconButton from '../../../../../components/atomic/IconButton';
import Typography from '../../../../../components/atomic/Typography';
import VisibilityOff from '../../../../../assets/icons/VisibilityOff';
import ActionButton from '../../../../../components/atomic/ActionButton';

const GoogleStreetViewField = ({
  classes,
  onChange,
  property,
  photoEnabled,
}) => {
  const { showActionDialog } = useActionDialog();

  const onInfoClick = () =>
    showActionDialog({
      showSubmitButton: false,
      showCancelButton: false,
      title: 'Google Street View',
      message: (
        <Typography>
          Vi henter bilder fra Google Street View for å gjøre mappen din mer
          personlig. Noen ganger vil feil eiendom dukke opp i Street View. Du
          velger derfor selv om du vil vise eller skjule bildet nedenfor, eller
          du kan laste opp et eget bilde.
        </Typography>
      ),
    });

  return (
    <>
      <div className={classes.heading}>
        <Typography variant="h3" component="div" color="textPrimary">
          Google Street View
        </Typography>
        <IconButton onClick={onInfoClick}>
          <InfoIcon />
        </IconButton>
      </div>

      <div style={{ position: 'relative' }}>
        <img
          className={clsx(
            classes.image,
            !photoEnabled && classes.photoDisabled
          )}
          alt={getPropertyAddress(property)}
          src={getPropertyGoogleStreetImage(property)}
        />
        <ActionButton size="small" onClick={onChange} className={classes.icon}>
          {photoEnabled ? <Visibility /> : <VisibilityOff />}
        </ActionButton>
      </div>
    </>
  );
};

export default withStyles(styles)(GoogleStreetViewField);
