import { useState } from 'react';

const useMenuState = () => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const onMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
    e.stopPropagation();
    e.preventDefault();
  };

  const onMenuClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const onOptionMenuClose = () => {
    setOpen(false);
  };

  return {
    open,
    setOpen,
    anchorEl,
    onMenuOpen,
    onOptionMenuClose,
    onMenuClose,
    setAnchorEl,
  };
};

export default useMenuState;
