import { withStyles } from '@material-ui/core';
import React from 'react';
import Button from '../../atomic/Button';
import styles from './muiStyles';

function FeatureCardButton({ classes, text, onClick }) {
  return (
    <Button
      fullWidth
      color="secondary"
      variant="outlined"
      className={classes.actionBtn}
      onClick={onClick ? () => onClick() : null}
      style={{
        margin: '15px 0 0 0',
      }}
    >
      {text}
    </Button>
  );
}

export default withStyles(styles)(FeatureCardButton);
