// Add your styles here, and reference them in your component className
import { css } from '@linaria/core';
import { mediaQueries } from '@style/mediaQueries';

export const coverImage = css`
    display: flex;
    img {
        width: 100%;
        height: 240px;
        object-fit: cover;
    }
    ${mediaQueries.md} {
        img {
            width: 100vw;
            height: 250px;
        }
    }
`;

export const headerWrapper = css`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    &.cover-image-margin {
        margin-top: -55px;
        ${mediaQueries.md} {
            margin-top: 20px;
        }
    }
    img{
        object-fit: cover;
    }
    ${mediaQueries.md} {
        flex-direction: row;
        align-items: flex-start;
    }
`;


export const imageWrapper = css`
    width: 110px;
    height: 110px;
    img {
        width: inherit;
        height: inherit;
        border-radius: 20px;
    }
    ${mediaQueries.md} {
        margin-right: 40px;
    }
`;

export const nameWrapper = css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
        text-align: center;
        ${mediaQueries.md} {
            padding: 5px;
        }
    }
    .company-name {
        font-size: 20px;
        font-weight: 600;
        ${mediaQueries.md} {
            font-size: 48px;
            font-weight: 700;
            text-align: start;
            line-height: 40px;
        }
    }
    ${mediaQueries.md} {
        align-items: flex-start;
    }
`;

export const contactButtonWrapper = css`
    display: none;
    .contact-button {
        background: #254E52 !important;
        :hover {
            background: #193639 !important; 
        }

        &:focus {
            outline: none !important;
        }
    }
    ${mediaQueries.md} {
        display: block;
    }
`;