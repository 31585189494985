import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import styles from './style';
import matomo from '../../../../utils/matomo';
import WithHeader from '../../../../components/layouts/WithHeader';
import PersonalAreaLanding from '../../../../componentsV2/PersonalAreaLanding';
import { boligmappaPlusActionCallback } from '../../../../utils/boligmappaPlus';
import useIsDesktop from '../../../../hooks/v2/useDesktop';
import useSnackbar from '../../../../hooks/useSnackbar';
import Link from '../../../../components/atomic/Link';
import Logo from '../../../../assets/icons/BoligmappaLogo';
import ArrowDownFilter from '../../../../assets/icons/ArrowDownFilter';

const PersonalAreaLandingPage = ({ classes }) => {
  const { showErrorMessage } = useSnackbar();
  const isDesktop = useIsDesktop();
  useEffect(() => {
    matomo.clickEvent({
      category: 'Boligmappa+',
      action: 'Rendered CTA',
      name: 'Rendered Personal Area Landing',
    });
  }, []);

  const handleActionClick = async (type) => {
    await boligmappaPlusActionCallback(type, showErrorMessage);
  };

  return (
    <WithHeader
      topLevelLayout
      headerTitle={
        <Link to="/properties" className={classes.propertiesLink}>
          <Logo className={classes.logo} />
          <ArrowDownFilter
            className={classes.arrow}
            width={13}
            height={6}
            fill="#717171"
          />
          Personlig område
        </Link>
      }
      maxWidth="xl"
      hideHeader={isDesktop}
    >
      <div className={classes.page}>
        <PersonalAreaLanding onActionClick={handleActionClick} />
      </div>
    </WithHeader>
  );
};

export default withStyles(styles)(PersonalAreaLandingPage);
