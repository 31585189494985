const styles = (theme) => ({
  form: {
    marginTop: '30px',
    maxWidth: '605px',
  },
  error: {
    color: theme.palette.primary.redDark,
  },
  textField: {
    height: '48px',
  },
  textFieldArea: {
    paddingTop: '30px',
  },
  actionBtn: {
    left: 0,
  },
  checkbox: {
    marginTop: '30px',
    fontWeight: 'bold',
    '&:last-child': {
      marginBottom: 0,
    },
    '&:first-child': {
      margin: 0,
    },
  },
  header: {
    fontSize: '33px',
  },
  mainBody: {
    color: theme.palette.primary.darkGreen,
  },
  checkBoxText: {
    fontWeight: 500,
  },
  checkboxTop: {
    marginTop: '30px',
    fontWeight: 'bold',
  },
  checkboxBottom: {
    marginTop: '10px',
    fontWeight: 'bold',
  },
});

export default styles;
