import React from 'react';

const General = ({ width = '60px', height = '60px', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30 60.5996C46.5685 60.5996 60 47.1682 60 30.5996C60 14.0311 46.5685 0.599609 30 0.599609C13.4315 0.599609 0 14.0311 0 30.5996C0 47.1682 13.4315 60.5996 30 60.5996Z"
      fill="#D9E9E9"
    />
    <path
      d="M29.9997 59.2659C45.8318 59.2659 58.6663 46.4314 58.6663 30.5993C58.6663 14.7671 45.8318 1.93262 29.9997 1.93262C14.1675 1.93262 1.33301 14.7671 1.33301 30.5993C1.33301 46.4314 14.1675 59.2659 29.9997 59.2659Z"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M30.6667 33.9329C32.1394 33.9329 33.3333 32.739 33.3333 31.2663C33.3333 29.7935 32.1394 28.5996 30.6667 28.5996C29.1939 28.5996 28 29.7935 28 31.2663C28 32.739 29.1939 33.9329 30.6667 33.9329Z"
      fill="#254E52"
    />
    <path
      d="M38.6672 33.9329C40.1399 33.9329 41.3338 32.739 41.3338 31.2663C41.3338 29.7935 40.1399 28.5996 38.6672 28.5996C37.1944 28.5996 36.0005 29.7935 36.0005 31.2663C36.0005 32.739 37.1944 33.9329 38.6672 33.9329Z"
      fill="#254E52"
    />
    <path
      d="M22.6667 33.9329C24.1394 33.9329 25.3333 32.739 25.3333 31.2663C25.3333 29.7935 24.1394 28.5996 22.6667 28.5996C21.1939 28.5996 20 29.7935 20 31.2663C20 32.739 21.1939 33.9329 22.6667 33.9329Z"
      fill="#254E52"
    />
  </svg>
);

export default General;
