import { mapVariables } from './helpers';

export type Size = 'xxs' | 'xs' | 'small' | 'medium' | 'large' | 'xl' | 'xxl';
export type LinkSize = 'big' | 'default';
export type BodySize = 'large' | 'medium' | 'body' | 'small' | 'tiny' | 'kicker' | 'special_1' | 'special_2';
export const headingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span'] as const;

export type FontSize = {
  fontSize: string;
  lineHeight: string;
  fontSizeMobile?: string;
  lineHeightMobile?: string;
  fontWeight?: string;
};

export type BodyElement = keyof Pick<
  JSX.IntrinsicElements,
  'p' | 'span'
>;

export type TitleElement = keyof Pick<
  JSX.IntrinsicElements,
  typeof headingLevels[number]
>;

export const titleSizeVariables: Record<Size, FontSize> = {
  xxl: {
    fontSize: '--hj-typography-title-xxl-size',
    lineHeight: '--hj-typography-title-xxl-line-height',
    fontSizeMobile: '--hj-typography-title-xxl-size--mobile',
    lineHeightMobile: '--hj-typography-title-xxl-line-height--mobile',
  },
  xl: {
    fontSize: '--hj-typography-title-xl-size',
    lineHeight: '--hj-typography-title-xl-line-height',
    fontSizeMobile: '--hj-typography-title-xl-size--mobile',
    lineHeightMobile: '--hj-typography-title-xl-line-height--mobile',
  },
  large: {
    fontSize: '--hj-typography-title-large-size',
    lineHeight: '--hj-typography-title-large-line-height',
    fontSizeMobile: '--hj-typography-title-large-size--mobile',
    lineHeightMobile: '--hj-typography-title-large-line-height--mobile',
  },
  medium: {
    fontSize: '--hj-typography-title-medium-size',
    lineHeight: '--hj-typography-title-medium-line-height',
    fontSizeMobile: '--hj-typography-title-medium-size--mobile',
    lineHeightMobile: '--hj-typography-title-medium-line-height--mobile',
  },
  small: {
    fontSize: '--hj-typography-title-small-size',
    lineHeight: '--hj-typography-title-small-line-height',
    fontSizeMobile: '--hj-typography-title-small-size--mobile',
    lineHeightMobile: '--hj-typography-title-small-line-height--mobile',
  },
  xs: {
    fontSize: '--hj-typography-title-xs-size',
    lineHeight: '--hj-typography-title-xs-line-height',
  },
  xxs: {
    fontSize: '--hj-typography-title-xxs-size',
    lineHeight: '--hj-typography-title-xxs-line-height',
  },
};

export const titleSizeMap = mapVariables<typeof titleSizeVariables>(titleSizeVariables);

export const linkSizeVariables: Record<LinkSize, FontSize> = {
  big: {
    fontSize: '--hj-typography-link-big-size',
    lineHeight: '--hj-typography-link-big-line-height',
    fontWeight: '--hj-typography-link-big-weight',
  },
  default: {
    fontSize: '--hj-typography-link-default-size',
    lineHeight: '--hj-typography-link-default-line-height',
    fontWeight: '--hj-typography-link-default-weight',
  },
};

export const linkSizeMap = mapVariables<typeof linkSizeVariables>(linkSizeVariables);

/**
 * These are the sizes for plain text of VB Design system.
 */
export const bodySizeVariables: Record<BodySize, FontSize> = {
  large: {
    fontSize: '--hj-typography-body-large-size',
    lineHeight: '--hj-typography-body-large-line-height',
    fontWeight: '--hj-typography-body-large-weight',
    fontSizeMobile: '--hj-typography-body-large-size--mobile',
    lineHeightMobile: '--hj-typography-body-large-line-height',
  },
  medium: {
    fontSize: '--hj-typography-body-medium-size',
    lineHeight: '--hj-typography-body-medium-line-height',
    fontWeight: '--hj-typography-body-medium-weight',
  },
  body: {
    fontSize: '--hj-typography-body-body-size',
    lineHeight: '--hj-typography-body-body-line-height',
    fontWeight: '--hj-typography-body-body-weight',
  },
  small: {
    fontSize: '--hj-typography-body-small-size',
    lineHeight: '--hj-typography-body-small-line-height',
    fontWeight: '--hj-typography-body-small-weight',
  },
  tiny: {
    fontSize: '--hj-typography-body-tiny-size',
    lineHeight: '--hj-typography-body-tiny-line-height',
    fontWeight: '--hj-typography-body-tiny-weight',
  },
  kicker: {
    fontSize: '--hj-typography-body-kicker-size',
    lineHeight: '--hj-typography-body-kicker-line-height',
    fontWeight: '--hj-typography-body-kicker-weight',
  },
  special_1: {
    fontSize: '--hj-typography-body-special_1-size',
    lineHeight: '--hj-typography-body-special_1-line-height',
    fontWeight: '--hj-typography-body-special_1-weight',
    fontSizeMobile: '--hj-typography-body-special_1-size--mobile',
    lineHeightMobile: '--hj-typography-body-special_1-line-height--mobile',
  },
  special_2: {
    fontSize: '--hj-typography-body-special_2-size',
    lineHeight: '--hj-typography-body-special_2-line-height',
    fontWeight: '--hj-typography-body-special_2-weight',
    fontSizeMobile: '--hj-typography-body-special_2-size--mobile',
    lineHeightMobile: '--hj-typography-body-special_2-line-height--mobile',
  },
};

export const bodySizeMap = mapVariables<typeof bodySizeVariables>(bodySizeVariables);