import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import {
  postPublicAccessData as apiPostPublicAccess,
  getPublicAccessData as apiGetPublicAccessData,
  patchPublicAccessData as apiPatchPublicAccessData,
} from '../../../../../api/properties';

import Intro from './Intro';
import styles from './style';
import Details from './Details';
import ShareForm from './Form';
import { openLink } from '../../../../../utils/routes';
import useDesktop from '../../../../../hooks/useDesktop';
import useSnackbar from '../../../../../hooks/useSnackbar';
import { getUserFullname } from '../../../../../utils/auth';
import { PREVIEWENABLEDFOR } from '../../../../../constants';
import localStorage from '../../../../../utils/localStorage';
import { sGetUser } from '../../../../../store/reducers/auth';
import useActionDialog from '../../../../../hooks/useActionDialog';
import PageLayout from '../../../../../components/layouts/WithHeader';
import ErrorContainer from '../../../../../components/ErrorContainer';
import ContentLoader from '../../../../../components/loaders/ContentLoader';
import { acSetDocumentSearchText } from '../../../../../store/actions/documents';
import Box from '../../../../../components/atomic/Box';

const Share = ({ user, classes, setSearchText }) => {
  const [fetching, setFetching] = useState(true);
  const [fetchingError, setFetchingError] = useState(null);
  const [publicAccessData, setPublicAccessData] = useState(null);
  const [showSharingForm, setShowSharingForm] = useState(false);
  const [editable, setEditable] = useState(false);

  const [sharingError, setSharingError] = useState(null);
  const [sharingRequestError, setSharingRequestError] = useState(null);
  const [sharingRequestInProgress, setSharingRequestInProgress] =
    useState(null);

  const [unsharingRequestError, setUnsharingRequestError] = useState(null);
  const [unshareRequestInProgress, setUnshareRequestInProgress] =
    useState(null);

  const isDesktop = useDesktop();
  const { boligmappaNumber } = useParams();
  const { showSuccessMessage } = useSnackbar();
  const { showActionDialog, showLoadingDialog } = useActionDialog();

  const { host } = window.location;
  const { protocol } = window.location;

  const propertyOwnerName = getUserFullname(user);
  const publicSharingUrl = `/public/${boligmappaNumber}/home`;
  const MoreSectionUrl = `/dashboard/${boligmappaNumber}/more`;

  // TODO: Disable until ready.
  // const onInfoIconClick = async () => {
  //   showActionDialog({
  //     closeable: true,
  //     showSubmitButton: false,
  //     showCancelButton: true,
  //     message: "{Info Message}"
  //   });
  // };

  const clearPreview = () => {
    const previewEnabledForArr =
      JSON.parse(localStorage.get(PREVIEWENABLEDFOR)) || [];

    const previewEnabledForArrModified = previewEnabledForArr.filter(
      (i) => i !== boligmappaNumber
    );

    localStorage.set(
      PREVIEWENABLEDFOR,
      JSON.stringify(previewEnabledForArrModified)
    );
  };

  const emailPublicSharingUrl = () => {
    const subject = 'Jeg inviterer deg til å se min Boligmappe';
    const messageBody = `Jeg har gjort min boligmappe offentlig tilgjengelig og inviterer deg til å se innholdet.%0DSe boligmappen her: ${protocol}//${host}${publicSharingUrl}%0D%0DVennlig hilsen%0D${propertyOwnerName}`;

    openLink(`mailto:?subject=${subject}&body=${messageBody}`, '_blank');
  };

  const confirmSharingAlert = (submitHandler) => {
    const ConfirmSharingMessage = () => (
      /*       <div style={{"text-align":"center"}} >
        <p>
          Til informasjon
        </p>
        <p>
          Vi vil nå vise deg en foråhndsvisning av din delte boligmappe, så du kan gå gjennom å sjekke at alt ser bra ut.
        </p>
        <p>
          Merk deg at hvis det er noe du mener ikke skal vises, må du slette detten fra mappen.
        </p>

        <p>
          Husk å trykke Fullfør delingen når du sier deg fornøyd.
        </p>
      </div> */
      <Box className={classes.dialogsize}>
        <Box
          className={classes.close_dialog_heading}
          display="flex"
          justifyContent="center"
          component="div"
        >
          <b>
            <p className={classes.close_dialog_heading}>Til informasjon</p>
          </b>
        </Box>
        <Box component="div" justifyContent="center">
          <p className={classes.close_dialog_text}>
            Vi vil nå vise deg en forhåndsvisning av din delte boligmappe, så du
            kan sjekke at alt ser bra ut.
          </p>
          <p className={classes.close_dialog_text}>
            Merk deg at hvis det er noe du mener ikke skal vises, så må du
            slette dette fra mappen.
          </p>
          <p className={classes.close_dialog_text}>
            Husk å trykke «Fullfør delingen» når du sier deg fornøyd.
          </p>
        </Box>
      </Box>
    );

    return showActionDialog({
      closeable: false,
      showCancelButton: false,
      submitButtonText: 'Neste',
      submitButtonVariant: 'contained',
      onSubmit: submitHandler,
      buttonType: 'Customized',
      actionClassName: classes.confirmCloseActions,
      buttonClassName: classes.confirmCloseButton,
      message: <ConfirmSharingMessage />,
    });
  };

  const confirmUnsharingAlert = (submitHandler, cancelHandler) => {
    const ConfirmUnsharingMessage = () => (
      <div>
        <p>Er du sikker på at du vil stoppe delingen?</p>
      </div>
    );

    return showActionDialog({
      closeable: true,
      showCancelButton: true,
      onCancel: cancelHandler,
      submitButtonText: 'Stopp',
      submitButtonVariant: 'contained',
      onSubmit: submitHandler,
      message: <ConfirmUnsharingMessage />,
    });
  };

  const redirectToPreviewPage = () => {
    window.location = publicSharingUrl;
  };

  const fetchPublicAccessData = async () => {
    setFetchingError(null);

    setFetching(true);

    // Get publicAccess data
    const [errorFetching, responseFetching] = await apiGetPublicAccessData(
      boligmappaNumber
    );
    if (errorFetching && !responseFetching) {
      setFetchingError(errorFetching);
      setPublicAccessData(null);
    } else {
      const { active } = responseFetching;
      let data = null;

      if (active) {
        const { id, sharedDate, expiryDate, sharedByName, viewCount, link } =
          active;

        data = {
          id,
          status: 'AKTIV',
          startDate: sharedDate,
          endDate: expiryDate,
          sharedBy: sharedByName,
          visitors: viewCount,
          link: {
            text: 'Kopier linken',
            url: link,
          },
        };
      }

      setPublicAccessData(data);
    }

    setFetching(false);
  };

  const transformExpiaryDatetoDateTime = (expiryDate) => {
    const expDateTime = new Date(expiryDate);
    expDateTime.setUTCHours(23, 59, 59);
    return expDateTime.toISOString();
  };

  const postPublicAccessData = async (expiryDate) => {
    setSharingRequestInProgress(true);

    const [errorPost, responsePost] = await apiPostPublicAccess(
      boligmappaNumber,
      {
        expiryDate: transformExpiaryDatetoDateTime(expiryDate),
      }
    );

    if (errorPost && !responsePost) {
      setSharingRequestError(errorPost);
    } else {
      // Set previewEnabledFor array on localStorage
      const previewEnabledForArr =
        JSON.parse(localStorage.get(PREVIEWENABLEDFOR)) || [];
      previewEnabledForArr.push(boligmappaNumber);
      localStorage.set(PREVIEWENABLEDFOR, JSON.stringify(previewEnabledForArr));

      // showSuccessMessage('Din boligmappe er klar for å bli delt offentlig.');
      redirectToPreviewPage();
    }

    setSharingRequestInProgress(false);
  };

  const patchPublicAccessData = async (id, expiryDate, unshare = false) => {
    let closeDialog = () => {};
    let expireDateTime;

    if (unshare) {
      const dateTimeBeforeThreeMinutesNow = new Date(expiryDate);
      dateTimeBeforeThreeMinutesNow.setUTCMinutes(
        dateTimeBeforeThreeMinutesNow.getUTCMinutes() - 3
      );
      expireDateTime = dateTimeBeforeThreeMinutesNow.toISOString();

      setUnshareRequestInProgress(true);
      closeDialog = showLoadingDialog('Stopper delingen');
    } else {
      expireDateTime = transformExpiaryDatetoDateTime(expiryDate);
      setSharingRequestInProgress(true);
    }

    const [errorPatch, responsePatch] = await apiPatchPublicAccessData(
      boligmappaNumber,
      id,
      {
        expiryDate: expireDateTime,
      }
    );

    // TODO: Refactor this code
    if (errorPatch && !responsePatch) {
      if (unshare) {
        setUnsharingRequestError(errorPatch);
      } else {
        setSharingRequestError(errorPatch);
      }
    } else if (unshare) {
      setPublicAccessData(null);
      showSuccessMessage('Offentlig deling av boligmappen er stoppet.');
    } else {
      showSuccessMessage('Lenke til din offenlige boligmappe opprettet.');
      setShowSharingForm(false);
      setEditable(false);
      fetchPublicAccessData();
    }

    if (unshare) {
      setUnshareRequestInProgress(false);
    } else {
      setSharingRequestInProgress(false);
    }
    closeDialog();

    // Clear localStorage
    clearPreview();
  };

  const toggleEditPublicSharing = (statue = true) => {
    setShowSharingForm(statue);
    setEditable(statue);
  };

  const sharePublicly = ({ isAgreedTnC, endDate }) => {
    setSharingError(null);
    setSharingRequestError(null);

    // Validation
    if (!isAgreedTnC) {
      setSharingError('Vennligst godta vilkårene for å gå videre.');
      return;
    }

    const submitHandler = () => postPublicAccessData(endDate);
    if (editable) {
      const { id } = publicAccessData;
      patchPublicAccessData(id, endDate);
    } else {
      confirmSharingAlert(submitHandler);
    }
  };

  const stopPublicSharing = async () => {
    setUnsharingRequestError(null);

    const { id } = publicAccessData;
    const expiryDate = new Date().toISOString();
    const submitHandler = () => patchPublicAccessData(id, expiryDate, true);
    const cancelHandler = () => {};
    confirmUnsharingAlert(submitHandler, cancelHandler);
  };

  const backButtonAction = () => {
    window.location = MoreSectionUrl;
  };

  useEffect(() => {
    fetchPublicAccessData();
    setSearchText('');
  }, []);

  return (
    <PageLayout
      minFullHeight
      hideHeader={isDesktop}
      lessMarginLeft={isDesktop}
      maxWidth="lg"
      headerTitle={
        !isDesktop && showSharingForm
          ? 'Ny offentlig deling'
          : 'Offentlig deling'
      }
      backButtonAction={backButtonAction}
    >
      <>
        {fetching && (
          <div>
            <ContentLoader
              height={40}
              width={150}
              className={classes.titleLoader}
            />
            <ContentLoader
              width={90}
              height={20}
              className={classes.listItemLoader}
            />
            <ContentLoader
              width={200}
              height={20}
              className={classes.listItemLoader}
            />
            <ContentLoader
              width={130}
              height={30}
              className={classes.listItemLoader}
            />
          </div>
        )}

        {!fetching && (
          <>
            {fetchingError ? (
              <ErrorContainer
                paddingTop={isDesktop}
                errorResponse={fetchingError}
                style={{ paddingTop: !isDesktop ? '15%' : undefined }}
              />
            ) : (
              <>
                {publicAccessData && !showSharingForm && (
                  <Details
                    {...publicAccessData}
                    publicSharingUrl={`${protocol}//${host}${publicSharingUrl}`}
                    stopPublicSharing={stopPublicSharing}
                    toggleEditPublicSharing={toggleEditPublicSharing}
                    emailPublicSharingUrl={emailPublicSharingUrl}
                    requestInProgress={unshareRequestInProgress}
                    requestError={unsharingRequestError}
                  />
                )}

                {!publicAccessData && !showSharingForm && (
                  <Intro
                    onContinue={() => {
                      setShowSharingForm(true);
                    }}
                  />
                )}

                {showSharingForm && (
                  <ShareForm
                    onSubmit={sharePublicly}
                    onCancel={() => toggleEditPublicSharing(false)}
                    requestInProgress={sharingRequestInProgress}
                    error={sharingError}
                    requestError={sharingRequestError}
                    fetchPublicAccessData={fetchPublicAccessData}
                  />
                )}
              </>
            )}
          </>
        )}
      </>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: sGetUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  setSearchText: (searchText) => dispatch(acSetDocumentSearchText(searchText)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Share));
