import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import CompanyProfile from '@vb/components/build/components/features/CompanyProfile';
import useDesktop from '../../../../hooks/useDesktop';
import useFetchEffect from '../../../../hooks/useFetchEffect';
import {
  fetchPublicCompanyDocuments,
  fetchPublicPropertyCompanies,
} from '../../../../api/properties';
import { areIdsEqual } from '../../../../utils/strings';
import { getCompanyId } from '../../../../utils/companies';
import Page from '../../../../components/layouts/WithHeader';
import CompanyDocuments from '../../../../modules/CompanyDocuments';
import Box from '../../../../components/atomic/Box';
import ContactProfessional from '../../../../components/ContactProfessional';
import { sGetCompanies } from '../../../../store/reducers/companies';
import { CompanyProfileLoading } from '../../../../components/CompanyProfileLoading';
import Link from '../../../../components/atomic/Link';
import {
  acSetCompanies,
  acSetFetchingCompanies,
} from '../../../../store/actions/companies';
import ArrowBackward from '../../../../assets/icons/ArrowBackward';
import Button from '../../../../components/atomic/Button';

const learnMoreCertificateUrl =
  'https://support.boligmappa.no/hc/no/articles/10689348171154-Om-h%C3%A5ndverkernes-sertifiseringsordninger';

const CompanyProfileCmp = ({ companies, setFetching, setCompanies }) => {
  const [showContactProfDialog, setShowContactProfDialog] = useState(false);
  const { companyId, boligmappaNumber } = useParams();
  const isDesktop = useDesktop();
  const history = useHistory();

  const onBackButtonClick = () => {
    history.goBack();
  };

  const company =
    Array.isArray(companies) &&
    companies.find((storedCompany) =>
      areIdsEqual(getCompanyId(storedCompany), companyId)
    );

  const { data: companyDocuments, fetching: fetchingDocuments } =
    useFetchEffect({
      apiFetchFunction: () =>
        fetchPublicCompanyDocuments(boligmappaNumber, companyId),
    });

  useFetchEffect({
    dependencies: [boligmappaNumber],
    setFetchingFunction: setFetching,
    onSuccess: (_companies) => setCompanies(_companies),
    apiFetchFunction: () => fetchPublicPropertyCompanies(boligmappaNumber),
    conditionFunction: ([_boligmappaNumber]) =>
      !companies && !!_boligmappaNumber,
  });

  return (
    <Page minFullHeight lessMarginLeft={isDesktop} maxWidth="lg">
      {isDesktop && (
        <Button
          style={{
            backgroundColor: 'transparent',
            fontSize: '14px',
            marginBottom: '24px',
          }}
          startIcon={
            <ArrowBackward
              style={{ minWidth: '14px', maxHeight: '14px', maxWidth: '14px' }}
            />
          }
          onClick={() => onBackButtonClick()}
        >
          Gå tilbake
        </Button>
      )}

      {!company && <CompanyProfileLoading isLoading />}
      {company && (
        <CompanyProfile
          company={company}
          linkComponent={<Link />}
          contactCompany={() => setShowContactProfDialog(true)}
          learnMoreAboutCertificatesURLPath={learnMoreCertificateUrl}
          isPubliclyShared
        />
      )}
      <Box mt={8}>
        <CompanyDocuments
          showRoomsColumn={false}
          showEventsColumn={false}
          isDesktop={isDesktop}
          fetching={fetchingDocuments}
          companyDocuments={companyDocuments}
          actionsEnabled={false}
          showBulkSelectionOptions={false}
        />
      </Box>
      <ContactProfessional
        open={showContactProfDialog}
        onClose={() => setShowContactProfDialog(false)}
        company={company}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  companies: sGetCompanies(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCompanies: (companies) => dispatch(acSetCompanies(companies)),
  setFetching: (fetching) => dispatch(acSetFetchingCompanies(fetching)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfileCmp);
