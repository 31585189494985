import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { formattedDate } from '../../../../../../../utils/dates';
import style from './style';
import ContextMenu from '../ContextMenu';
import { areIdsEqual } from '../../../../../../../utils/strings';
import { getId } from '../../../../../../../utils/privateshare';
import MenuIcon from '../../../../../../../assets/icons/MenuHorizontal';

const AccessList = ({ classes, shareRecords, onDetailsClick, onDelete }) => {
  const [openContextMenu, setOpenContextMenu] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(null);

  const selectedRecord = (shareRecords || []).find((shareRecord) =>
    areIdsEqual(getId(shareRecord), selectedRecordId)
  );
  const onMenuClick = (shareRecord) => {
    setSelectedRecordId(getId(shareRecord));
    setOpenContextMenu(true);
  };
  return (
    <div>
      <ContextMenu
        open={openContextMenu}
        setOpen={setOpenContextMenu}
        record={selectedRecord}
        onDelete={onDelete}
        onDetailsClick={onDetailsClick}
      />
      {shareRecords.map((el) => (
        <>
          <div className={classes.row}>
            <div className={classes.column}>
              <p className={classes.label}>{el.requester.company}</p>
              <p className={classes.date}>{formattedDate(el.updatedDate)}</p>
            </div>
            <MenuIcon
              className={classes.menuIcon}
              onClick={() => onMenuClick(selectedRecord)}
            />
          </div>
        </>
      ))}
    </div>
  );
};

export default withStyles(style)(AccessList);
