import {
  apiBaseUrl,
  helpInsuraceProductId,
  boligmappaPlusMonthlyProductId,
  boligmappaPlusYearlyProductId,
} from '../config';
import { request } from '../utils/http';
import matomo from '../utils/matomo';

const productTypes = {
  earlyBirdMonth: 'boligmappa-pluss-monthly',
  earlyBirdYearly: 'boligmappa-pluss-yearly',
  month: boligmappaPlusMonthlyProductId,
  year: boligmappaPlusYearlyProductId,
  helpInsurance: helpInsuraceProductId,
  previousHelpInsurance: 'boligmappa-trygt-utfort'
};

export const boligmappaPlusSinglePayment = (type, redirectUrl) => {
  return request({
    method: 'post',
    url: 'propertyowners/user-subscriptions',
    baseURL: apiBaseUrl,
    data: {
      paymentMethod: 'VIPPS',
      subscriptionProductId: productTypes[type],
      signupRedirectUrl: `${redirectUrl}?subscribed=true`,
    },
  })
    .then((response) => [null, response.data])
    .catch((error) => [error, null]);
};

export const getUserTransactions = () => {
  const productFilters = Object.values(productTypes).join(',');

  return request({
    method: 'get',
    url: `propertyowners/user-transactions?productFilter=${productFilters}`,
    baseURL: apiBaseUrl,
  })
    .then((response) => [null, response.data])
    .catch((error) => [error, null]);
};

export const boligmappaPlusCancel = (id) =>
  request({
    method: 'delete',
    url: `propertyowners/user-subscriptions/${id}`,
    baseURL: apiBaseUrl,
  })
    .then((response) => [null, response.data])
    .catch((error) => [error, null]);

export const onBoligmappaPlusActionClick = async (type) => {
  const redirectUrl = window.location.href;
  const [error, response] = await boligmappaPlusSinglePayment(
    type,
    redirectUrl
  );
  if (!error && response) {
    matomo.goalEvent({ goalType: type });
    window.location.href = response.response.agreementFrontendUrl;
  }
};
