import React from 'react';
import Box from '../../atomic/Box';
import Typography from '../../atomic/Typography';
import BottomContextMenu from '../../BottomContextMenu';

const FilterMenu = ({ title, onMenuClose, ...props }) => {
  const titleContent = (
    <Box display="flex" alignItems="right">
      <Box flexGrow={1} marginRight="-64px">
        <Typography>
          <strong>{title}</strong>
        </Typography>
      </Box>
    </Box>
  );

  return <BottomContextMenu title={titleContent} {...props} />;
};

export default FilterMenu;
