import React from 'react';
import { Route as BaseRoute } from 'react-router';
import useDesktop from '../../../hooks/useDesktop';

const Route = ({ mobileOnly = false, desktopOnly = false, ...props }) => {
  const isDesktop = useDesktop();

  if (mobileOnly && isDesktop) return null;
  if (desktopOnly && !isDesktop) return null;

  return <BaseRoute {...props} />;
};

export default Route;
