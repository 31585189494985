import { parse, stringify } from 'query-string';
import { isNativeEnv, openLinkRequest } from './nativeBridge';

export const options = {
  parseBooleans: true,
  arrayFormat: 'bracket',
};

export const getDashboardLinkPrefix = (
  boligmappaNumber,
  prefix = 'dashboard'
) => `/${prefix}/${boligmappaNumber}`;

export const parseQuery = (query) => parse(query, options);

export const stringifyQuery = (query) => stringify(query, options);

export const openLink = (link, ...args) =>
  isNativeEnv() ? openLinkRequest(link) : window.open(link, ...args);

export const openLinkNoEncode = (link, ...args) =>
  isNativeEnv() ? openLinkRequest(link, true) : window.open(link, ...args);

export const redirectPath = 'REDIRECT_PATH';

export const redirectOptions = {
  requestMissingDocuments: 'requestMissingDocuments',
  privateSharing: 'privateSharing',
  publicSharing: 'publicSharing',
  home: 'home',
  companies: 'companies',
  moreAccount: 'more/account',
  financials: 'financials',
  findProfessional: 'findProfessional',
  radon: 'radon',
  otovo: 'otovo',
  boligmappaPlus: 'boligmappaPlus',
  myPurchases: 'my-purchases',
  insurance: 'insurance',
  sparebank1sorostnorge: 'sparebank1sorostnorge',
  personaldocuments: 'personaldocuments',
  krogsveen: 'krogsveen',
  flisekompaniet: 'flisekompaniet',
  ifforsikring: 'ifforsikring',
  verisure: 'verisure',
  profileDelete: 'profileDelete',
  documents: 'documents',
};
