import React, { createElement, useContext } from 'react';

import Menu from '../atomic/Menu';
import List from '../atomic/List';
import useDesktop from '../../hooks/useDesktop';
import BottomContextMenu from '../BottomContextMenu';
import DesktopMenuItem from '../desktop/MenuListItem';
import MobileMenuItem from '../BottomContextMenu/ListItem';
import { FileUploadContext } from '../../contexts/fileUpload';
import ConnectNewDocument from '../../assets/icons/ConnectNewDocument';
import ConnectExistingDocument from '../../assets/icons/ConnectExistingDocument';
import { roomModes } from '../../utils/rooms';
import { eventModes } from '../../utils/events';
import matomo from '../../utils/matomo';

const AttachDocumentMenu = ({
  open,
  room,
  event,
  setOpen,
  anchorEl,
  setShowEditEvent,
  setEventMode,
  setShowEditRoom,
  setRoomMode,
}) => {
  const isDesktop = useDesktop();
  const { onFileUploadClick } = useContext(FileUploadContext);

  const onConnectExistingDocument = () => {
    if (event) {
      setEventMode(eventModes.document);
      setShowEditEvent(true);
    } else if (room) {
      setRoomMode(roomModes.document);
      setShowEditRoom(true);
    }
  };

  const menuItems = [
    {
      icon: ConnectNewDocument,
      text: 'Legg til nytt dokument',
      onClick: () => {
        onFileUploadClick({
          query: {
            bulkUploadMode: true,
            isRoomOrEvent: true,
            source: isDesktop ? 'table' : 'listView',
            room,
            event,
          },
        });
        matomo.clickEvent({
          category: 'Rooms',
          action: 'Add Document to Room',
          name: 'Add New Document from Room',
        });
      },
    },
    {
      icon: ConnectExistingDocument,
      text: 'Koble til eksisterende dokument',
      onClick: () => {
        onConnectExistingDocument();
        matomo.clickEvent({
          category: 'Rooms',
          action: 'Add Document to Room',
          name: 'Add Existing Document from Room',
        });
      },
    },
  ];

  const renderMenuItem = ({ text, onClick, ...props }) =>
    // eslint-disable-next-line react/no-children-prop
    createElement(isDesktop ? DesktopMenuItem : MobileMenuItem, {
      key: text,
      children: text,
      border: !isDesktop,
      onClick: (e) => {
        if (onClick) {
          onClick(e);
        }

        setOpen(false);
      },
      ...props,
    });

  if (isDesktop) {
    return (
      <Menu
        open={open}
        anchorEl={anchorEl}
        disableAutoFocusItem
        onClose={() => setOpen(false)}
      >
        {menuItems.map(renderMenuItem)}
      </Menu>
    );
  }

  return (
    <BottomContextMenu open={open} setOpen={setOpen} title="Legg til dokument">
      <List>{menuItems.map(renderMenuItem)}</List>
    </BottomContextMenu>
  );
};

export default AttachDocumentMenu;
