import { makeStyles } from '@material-ui/core';

export const useSectionStyles = makeStyles(() => ({
  section_seperator: {
    marginTop: 30,
  },
  section_seperator_mobile: {
    marginBottom: 0,
  },
  removePaddingBottom: {
    paddingBottom: '0 !important',
  },
  chartBlock: {
    paddingTop: 32,
    paddingRight: 16,
  },
  estimateCard: {
    height: '100%',
    width: '100%',
    display: 'flex',
    gap: 10,
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    paddingTop: 16,
    paddingBottom: 16,
  },
}));
