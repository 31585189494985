import flisekompanietLogo from '../assets/images/loyaltyCompanies/flisekompaniet/flisekompaniet-logo.png';
import flisekompanietCover from '../assets/images/loyaltyCompanies/flisekompaniet/flisekompaniet-cover.png';
import flisekompanietFooter from '../assets/images/loyaltyCompanies/flisekompaniet/flisekompaniet-footer.JPG';

export const LoyaltyCompanies = [
  {
    code: 'FLISEKOMPANIET',
    name: 'Flisekompaniet',
    mainDescriptions: [
      'Norges største forhandler av fliser, med butikker fra nord til sør. Få gode rabatter på fliser, baderomsmøbler samt gratis tegnetime i butikk.',
    ],
    logo: flisekompanietLogo,
    coverImage: flisekompanietCover,
    contactInfo: {
      phone: '+ 22 88 47 10',
      email: 'post@flisekompaniet.no',
      webSite: 'Flisekompaniet.no',
    },
    options: [
      {
        heading: '20 % på fliser',
        descriptions: [
          'Marrokanske, historiske eller klassiske fliser? Flis er pent på kjøkken, bad, i gangen, på vegger og i uterommene. I tillegg er det enkelt å holde rent og slitesterkt over tid. Flisekompaniet har et enormt utvalg varianter.',
        ],
        image: null,
      },
      {
        heading: '15 % på VVS',
        descriptions: [
          'Flisekompaniet tilbyr et stort utvalg av baderomsinnredning med høy kvalitet. Her finner du alt fra prisgunstige baderomsmøbler hvor du får mye for pengene, til eksklusive baderomsinnredninger spesielt tilpasset dine behov.',
        ],
        image: null,
      },
      {
        heading: 'Gratis tegnetime',
        descriptions: [
          'Få hjelp til tips og råd til oppussing, tegning av ditt prosjekt, beregning av materialforbruk og tilvalg.',
        ],
        image: null,
      },
    ],
    longDescriptions: [
      'Flisekompaniet er Norges største forhandler av keramiske fliser, med butikker fra nord til sør. Kjeden tilbyr et bredt vareutvalg av fliser, verktøy, baderomsinnredninger og øvrige produkter som kompletterer baderommet.',
      'Som landsdekkende kjede med over 30 butikker er det aldri langt til nærmeste Flisekompaniet.',
      'Som medlem i Boligmappa+ får du ekstra gode fordeler:',
    ],
    footerImage: flisekompanietFooter,
    offers: {
      title: 'Flisekompaniet',
      description:
        'Norges største leverandør av fliser og baderomsutstyr, med over 30 butikker overhele landet. Tilbyr flisleggere med 12 års tetthetsgaranti',
    },
    benefit: {
      title: 'Rabatt og fordeler for medlemmer av Boligmappa+',
      description: null,
      list: ['20 % på fliser', '15 % på VVS', 'Gratis tegnetime'],
      discountBtnText: 'BM+',
    },
  },
];

export const placeHolderCategories = [
  // {
  //   id: 2,
  //   heading: '🏦 Boliglån',
  //   descriptions:
  //     'Det er dumt å betale for mye til banken. Få tilgang til en av landets beste boliglånsrenter.',
  // },
  {
    id: 4,
    heading: '🎨 Maling, tapet og gulv',
    descriptions:
      'Flatene i hjemmet synes godt. Unn deg en mester - og en god avtale.',
  },
  {
    id: 6,
    heading: '🌳 Hage og uterom',
    descriptions:
      'Grønne fingre? Norges ledende hagesenter hjelper deg + tilbyr rabatterte priser.',
  },
  {
    id: 7,
    heading: '🛁 Rørlegger og bad',
    descriptions:
      'Badet er boligens viktigste rom. Norges ledende rørleggerkjede hjelper deg.',
  },
  {
    id: 8,
    heading: '♨️ Varmepumpe',
    descriptions:
      'En varmepumpe gir bedre inneklima og sparer strøm. Få god rabatt på kjøp og installasjon.',
  },
  {
    id: 9,
    heading: '🛠️ Oppussing',
    descriptions:
      'Oppussingsprosjekter kan være kompliserte. Få hjelp av kvalifiserte fagfolk - hele veien.',
  },
  {
    id: 10,
    heading: '🔥 Peis og ildsted',
    descriptions:
      'En rentbrennende ovn gir mer varme for pengene, og sparer miljøet.',
  },
  {
    id: 11,
    heading: '👷‍♀️ Byggevare',
    descriptions:
      'Få gode rabatter og tilbud hos en av landets ledende byggevarekjeder.',
  },
  {
    id: 12,
    heading: '💡️ Smarthus',
    descriptions:
      'Fordelene med et smarthus er blant annet økt komfort, effektivitet og sikkerhet.',
  },
];

export const getLoyaltyCompanyByCode = (code, sanityCompanies) => {
  if (sanityCompanies) {
    return sanityCompanies.find((a) => a.companyCode?.current === code);
  }
  return LoyaltyCompanies.find((a) => a.code === code);
};

export const getLoyaltyCompanyCode = (company) => company?.companyCode.current;

export const getLoyaltyCompanyName = (company) =>
  company?.name || company?.title;

export const getLoyaltyCompanyShortDescription = (company) =>
  company?.shortDescription;

export const getLoyaltyCompanyEmail = (company) =>
  company?.contactbox?.companyEmail;

export const getLoyaltyCompanyCoverAsset = (company) =>
  company?.coverPhoto?.asset;

export const getLoyaltyCompanyLogo = (company) =>
  company?.contactbox?.companyLogo;

export const getLoyaltyCompanyOptionData = (company) => company?.options;

export const getLoyaltyCompanyLongDescriptions = (company) =>
  company?.longDescriptions;

export const getLoyaltyCompanyFooterImage = (company) => company?.footerImage;

export const getLoyaltyCompanyContactInfo = (company) => company?.contactInfo;

export const getLoyaltyCompanyBenefit = (company) => company?.benefit;

export const getLoyaltyCompanyOffers = (company) => company?.offers;
