import Plus from '../../../../../../assets/images/plus.png';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  containerDesktop: {
    width: '500px',
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    height: '150px',
    borderRadius: '12px',
    boxShadow: '0 3px 4px rgba(0,0,0,0.1)',
    marginBottom: theme.spacing(4),
  },
  cardLoading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '1rem',
  },
  cardDesktop: {
    width: '125px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cardMobile: {
    width: '105px',
  },
  cardImage: {
    height: '85px',
  },
  cardText: {
    textAlign: 'center',
    fontSize: '12px',
    color: theme.palette.primary.main,
  },
  cardTextMobile: {
    padding: '8px 5px 0 5px',
  },
  button: {
    width: '250px',
    height: '30px',
    fontSize: '13px',
    fontWeight: 400,
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(16),
  },
  emptyCardImage: {
    height: '85px',
    background: `${theme.colors.mint} url(${Plus}) no-repeat center`,
    backgroundSize: '30px',
  },
});

export default styles;
