const styles = {
  arrow: {
    '&.slick-next, &.slick-prev': {
      width: '29px',
      height: '29px',
    },
  },
  previous: {
    '&.slick-next, &.slick-prev': {
      transform: 'translate(0, -50%) rotate(180deg)',
    },
  },
};

export default styles;
