import styled from 'styled-components';

export const StyledPersonalAreaLanding = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 48px;
  max-width: 1160px;
  padding: 28px 20px;
  margin: 0 auto;
  position: relative;

  @media (max-width: 767px) {
    padding: 32px 16px 134px;
  }

  h2,
  h3,
  p {
    margin: 0;
    color: #000;
  }

  h1 {
    font-size: 36px;
    line-height: 50px;
    font-weight: 700;
  }

  h2 {
    font-size: 32px;
    line-height: 50px;
    font-weight: 700;
  }

  h3 {
    font-size: 20px;
    line-height: 40px;
    font-weight: 700;
  }
  .boligmappa_plus_subscription_section {
    min-height: 377px;
    @media (max-width: 959px) {
      min-height: unset;
    }

    .personal_area_call_to_action {
      top: 0 !important;
      @media (max-width: 959px) {
        top: unset !important;
      }
    }

    .boligmappa_plus_logo_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: #fbf3c1;
      gap: 4px;
      padding: 4px 16px;
      border-radius: 8px;
      border: none;
      font-weight: 500;
      margin-top: 15px;
      font-size: 18px;
      height: 36px;
      img {
        width: 110px;
        height: 24px;
      }
    }

    .boligmappa_plus_personal_text {
      width: 70%;
      line-height: 30px;
      @media (max-width: 959px) {
        width: 100%;
      }
    }
  }

  .personal_area_section {
    p {
      margin-top: 10px;
    }
    .card_container {
      display: grid;
      grid-template-columns: auto auto;
      gap: 30px 20px;
      margin: 40px 0;
      @media (max-width: 959px) {
        grid-template-columns: auto;
      }
    }
  }

  .latest_news_section {
    margin-top: 40px;
    @media (max-width: 959px) {
      min-height: 150px;
    }
    p {
      font-size: 14px;
    }
    button {
      height: 32px;
      width: 118px;
      background-color: #164144;
      color: #fff;
      font-size: 16px;
      border-radius: 25px;
      border: none;
      margin-top: 10px;
    }
  }
`;
