const styles = {
  list: {
    paddingLeft: '20px',
  },
  listItem: {
    marginBottom: '15px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
};

export default styles;
