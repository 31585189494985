const styles = () => ({
  otovoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  otowoInforWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  otovoHeader: {
    fontWeight: 800,
    fontSize: '24px',
    lineHeight: '28px',
    textAlign: 'center',
    color: '#254E52',
    marginTop: '24px',
  },
  otovoDescription: {
    margin: '24px 140px 0 140px',
    textAlign: 'center',
    fontSize: '13px',
    '@media only screen and (max-width: 638px)': {
      margin: '24px',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: 'auto',
    '@media only screen and (max-width: 1024px)': {
      marginTop: '24px',
    },
    '@media only screen and (max-width: 638px)': {
      justifyContent: 'center',
    },
  },
  button: {
    fontWeight: '100',
    '@media only screen and (max-width: 638px)': {
      flexGrow: '1',
    },
  },
});

export default styles;
