import React from 'react';

import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import BasePaper from '@material-ui/core/Paper';

import styles from './style';

const Paper = ({ classes, children, className, visible = true, ...props }) => {
  if (!visible) {
    return (
      <div className={clsx(classes.paper, className)} {...props}>
        {children}
      </div>
    );
  }

  return (
    <BasePaper square className={clsx(classes.paper, className)} {...props}>
      {children}
    </BasePaper>
  );
};

export default withStyles(styles)(Paper);
