/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropertyInfo from '../../../../../modules/PropertyInfo';
import { OverviewGridItem } from './components';
import Card from '../../../../../components/Card';
import { useActiveProperty } from '../../../../../store/hooks';
import { useSectionStyles } from './sectionStyles';

export function PropertyInfoSection({ showMaxwidth }) {
  const { property, isFetchingProperties, unitFeatures } = useActiveProperty();
  const classes = useSectionStyles();
  return (
    <OverviewGridItem showMaxwidth={showMaxwidth}>
      <Card height="100%">
        <PropertyInfo
          className={classes.section_seperator}
          property={property}
          fetching={isFetchingProperties && unitFeatures.fetching}
          unitFeatures={unitFeatures}
        />
      </Card>
    </OverviewGridItem>
  );
}
