import React, { createElement } from 'react';
import TableHeaderCell from '../TableHeaderCell';
import FilterMenu from '../../Filters/FilterMenu';
import MenuItem from '../../../../atomic/MenuItem';
import ListItemIcon from '../../../../atomic/ListItemIcon';
import ListItemText from '../../../../atomic/ListItemText';
import { areIdsEqual } from '../../../../../utils/strings';
import useMenuState from '../../../../../hooks/useMenuState';
import { arrayFromObject } from '../../../../../utils/arrays';
import { documentSortTypes } from '../../../../../utils/documents';

const TableHeaderSortFilter = ({
  description,
  filterOptions,
  sortType: activeSortType,
  onSortTypeChange: passedOnSortTypeChange,
  ...props
}) => {
  const { onMenuOpen, onMenuClose, open, anchorEl } = useMenuState();

  const onSortTypeChange = (newSortType) => {
    passedOnSortTypeChange(newSortType);
    onMenuClose();
  };

  return (
    <>
      <TableHeaderCell name="Sort etter" onClick={onMenuOpen} {...props} />

      <FilterMenu
        open={open}
        title="Sort etter"
        anchorEl={anchorEl}
        onClose={onMenuClose}
        showCloseButton={false}
      >
        {arrayFromObject(documentSortTypes).map((sortType) => (
          <MenuItem
            key={`sort-type-item-${sortType.id}`}
            onClick={() => onSortTypeChange(sortType)}
            selected={areIdsEqual(sortType.id, activeSortType.id)}
          >
            <ListItemIcon>{createElement(sortType.icon)}</ListItemIcon>
            <ListItemText>{sortType.label}</ListItemText>
          </MenuItem>
        ))}
      </FilterMenu>
    </>
  );
};

export default TableHeaderSortFilter;
