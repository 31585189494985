import { imageIconStyles } from '../../DocumentList/DocumentListItem/styles';

const styles = (theme) => ({
  root: {
    cursor: 'pointer',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    '&:hover': {
      background: theme.palette.primary.greyLight,
    },
  },
  imageIcon: {
    ...imageIconStyles,
  },
});

export default styles;
