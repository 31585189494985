import { useState } from 'react';
import { isObject, reduce } from 'lodash-es';
import { useHistory, useLocation } from 'react-router';

import { parseQuery, stringifyQuery } from '../utils/routes';
import { filterTypes } from '../components/DocumentFilters/util';
import { getDocTableHeaderItems } from '../components/DocumentEditOptions/utils';

const docTableHeaderItems = getDocTableHeaderItems();
const filterTypeKeys = (isDocumentView) =>
  (isDocumentView ? docTableHeaderItems : filterTypes).map(({ type }) => type);

const parseFilter = (filter) => {
  try {
    return JSON.parse(filter);
  } catch (err) {
    return filter;
  }
};

const useDocumentFilters = (isDocumentView = false) => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const [filters, setFilters] = useState(
    // skip irrelevant GET params
    reduce(
      parseQuery(search),
      (result, filtersArray, filterKey) =>
        filterTypeKeys(isDocumentView).includes(filterKey)
          ? { ...result, [filterKey]: filtersArray.map(parseFilter) }
          : result,
      {}
    )
  );

  const onFiltersChanged = (updatedFilters) => {
    setFilters(updatedFilters);
    history.replace(
      `${pathname}?${stringifyQuery(
        reduce(
          updatedFilters,
          (result, filtersArray, key) => ({
            ...result,
            [key]: filtersArray.map((filter) =>
              isObject(filter) ? JSON.stringify(filter) : filter
            ),
          }),
          {}
        )
      )}`
    );
  };

  return {
    filters,
    onFiltersChanged,
  };
};

export default useDocumentFilters;
