import React, { useEffect, useMemo, useState } from 'react';
import Box from '../../../../atomic/Box';
import Grid from '../../../../atomic/Grid';
import Checkbox from '../../../../atomic/Checkbox';
import Typography from '../../../../atomic/Typography';
import useDesktop from '../../../../../hooks/useDesktop';
import Divider from '../../../../atomic/Divider';
import { useFindProfessionalsStyles } from '../../../findProfessionalStyles';
import TextField from '../../../../atomic/TextField';
import InputAdornment from '../../../../atomic/InputAdornment';
import SearchIcon from '../../../../../assets/icons/Search';

const ProfessionTypesFilter = ({
  selectedOptions,
  professionTypes,
  reset = false,
  showHeading = true,
  onSelect = () => {},
}) => {
  const isDesktop = useDesktop();
  const classes = useFindProfessionalsStyles();
  const [options, setOptions] = useState(
    selectedOptions !== '' ? selectedOptions.split(',') : []
  );
  const [searchTerm, setSearchTerm] = useState('');

  const professionTypeFiltered = useMemo(() => {
    return searchTerm !== ''
      ? professionTypes.filter((a) =>
          a.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : professionTypes;
  }, [professionTypes, searchTerm]);

  const handleOptionChange = (o) => {
    const index = options.indexOf(o);
    if (index === -1) {
      setOptions([...options, o]);
    } else {
      setOptions(options.filter((s) => s !== o));
    }
  };

  useEffect(() => {
    onSelect(options);
  }, [options.length]);

  useEffect(() => {
    if (reset) {
      setOptions([]);
    }
  }, [reset]);

  return (
    <Box sx={{ flexGrow: 1 }} p={!isDesktop && 2}>
      {showHeading && (
        <Typography
          className={
            isDesktop
              ? classes.moreFiltersSubTitles
              : classes.moreFiltersSubTitlesMobile
          }
        >
          Fagområde
        </Typography>
      )}
      {!isDesktop && (
        <Box className={classes.professionTypesSearchSection}>
          <TextField
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Søk på fagområde"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" disablePointerEvents>
                  &nbsp;
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Divider className={classes.professionTypesDivider} />
        </Box>
      )}

      <Grid container spacing={0} columns={{ xs: 1, sm: 8 }}>
        {professionTypeFiltered.map((prof) =>
          isDesktop ? (
            <Grid item xs={6} sm={6} md={6} key={`professionType-${prof.name}`}>
              <Box pl={2} pr={2}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleOptionChange(prof.name)}
                >
                  <Typography className={classes.moreFilterContent}>
                    {prof.name}
                  </Typography>
                  <Checkbox
                    value={prof.name}
                    checked={options.includes(prof.name)}
                    onChange={(e) => handleOptionChange(e.target.value)}
                    classes={{ label: classes.moreFilterContent }}
                  />
                </Box>
                <Divider />
              </Box>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              key={`professionType-${prof.name}`}
            >
              <Checkbox
                label={prof.name}
                value={prof.name}
                checked={options.includes(prof.name)}
                onChange={(e) => handleOptionChange(e.target.value)}
                classes={{ label: classes.moreFilterContent }}
              />
            </Grid>
          )
        )}
      </Grid>
    </Box>
  );
};

export default ProfessionTypesFilter;
