import React, { useEffect } from 'react';
import FeatureBox from '@componentsV2/FeatureBox';
import SectionCallToAction from '@componentsV2/BoligmappaPlus/SectionCallToAction';
import { Link } from 'react-router-dom';
import { useSanityContent } from '@hooksV2/useSanityContent';

import { StyledLoyalty } from './styles';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';
import matomo from '../../utils/matomo';
import {
  getLoyaltyCompanyCode,
  getLoyaltyCompanyLogo,
  getLoyaltyCompanyName,
  getLoyaltyCompanyShortDescription,
  placeHolderCategories,
} from '../../utils/LoyaltyCompanies';
import { getImageKitImageUrlForSanity } from '../../content/contentHelpers';

const BoligmappaPlusLoyalty = ({
  isDesktop,
  isBoligmappaPlusSub = false,
  onActionClick,
  isBackButtonVisible = true,
}) => {
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const sanityData = useSanityContent();
  useEffect(() => {
    matomo.clickEvent({
      category: 'Boligmappa+',
      action: 'Rendered CTA',
      name: 'Rendered CTA on Loyalties',
    });
  }, []);
  return (
    <StyledLoyalty>
      {isDesktop && isBackButtonVisible && (
        <div className="back_section">
          <Link to={`${dashboardLinkPrefix}/boligmappa-plus`}>
            ← <span>Tilbake</span>
          </Link>
        </div>
      )}
      {!isBoligmappaPlusSub ? (
        <SectionCallToAction
          title="Mine fordeler"
          linkText="Mine fordeler er tilgjengelig med Boligmappa+, som er et tillegg til alt det som allerede finnes i Boligmappa. Dine boligdokumenter, oversikt over håndverkere, rom og hendelser er fortsatt tilgjengelig, helt gratis."
          isDesktop={isDesktop}
          onActionClick={onActionClick}
          className="cta_bordered"
        >
          Rabatter, fordeler og gode avtaler for oss som er opptatt av bolig.
          <br />
          <br />
          Over 900 000 boligeiere bruker Boligmappa i dag. Når vi er så mange
          får vi skikkelig gode avtaler på mye av det man trenger som boligeier.
          Fordelene kommer i januar!
        </SectionCallToAction>
      ) : (
        <>
          <h2>Mine fordeler</h2>
          <p>
            Rabatter, fordeler og gode avtaler for oss som er opptatt av bolig.
            <br />
            <br />
            Over 900 000 boligeiere bruker Boligmappa i dag. Når vi er så mange
            får vi skikkelig gode avtaler på mye av det man trenger som
            boligeier.
          </p>
        </>
      )}
      {!isBoligmappaPlusSub && <hr className="divider" />}
      <div className="company_advantages">
        {sanityData.LoyaltyCompanies?.map((company) => (
          <FeatureBox
            key={getLoyaltyCompanyCode(company)}
            headline={getLoyaltyCompanyName(company)}
            logo={getImageKitImageUrlForSanity(getLoyaltyCompanyLogo(company))}
            boxClassName="company_box"
            linkText="Se tilbudet →"
            to={
              getLoyaltyCompanyCode(company) === 'sparebank-1-sorost-norge' &&
              !`${dashboardLinkPrefix}`.includes('personal-area')
                ? `${dashboardLinkPrefix}/loyalty/company/sparebank-1-sorost-norge`
                : `loyalty/company/${getLoyaltyCompanyCode(company)}`
            }
          >
            {getLoyaltyCompanyShortDescription(company)}
          </FeatureBox>
        ))}
      </div>
      <h2>Kommer snart!</h2>
      <p>
        I løpet av året kommer det en masse spennende fordeler innen en rekke
        ulike produkter og tjenester.
      </p>
      <div className="company_advantages">
        {placeHolderCategories.map((category) => (
          <FeatureBox
            key={category.id}
            headline={category.heading}
            boxClassName="company_box"
            placeHolderCategory
          >
            {category.descriptions}
          </FeatureBox>
        ))}
      </div>
    </StyledLoyalty>
  );
};

export default BoligmappaPlusLoyalty;
