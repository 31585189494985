import React, { createElement, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import {
  getDocumentId,
  getDocumentName,
  isDocumentImage,
  isDocumentReadOnly,
  getDocumentFileType,
  getDocumentCreatedDate,
  getDocumentOwnedByName,
  getDocumentCompanyName,
  getOriginalDocumentPath,
  getDocumentProfessionType,
  isProfessionTypeSpecified,
  getFileThumbNailForDocumentView,
  getDocumentIsBuilding,
  isDocumentsFolder,
} from '../../../../utils/documents';
import styles from './styles';
import Image from '../../../Image';
import Box from '../../../atomic/Box';
import ListItem from '../../../atomic/ListItem';
import Checkbox from '../../../atomic/Checkbox';
import TextHighlighted from '../../../TextHighlighted';
import ListItemText from '../../../atomic/ListItemText';
import { formattedDate } from '../../../../utils/dates';
import useDashboardLinkPrefix from '../../../../hooks/useDashboardLinkPrefix';
import ListItemSecondaryAction from '../../../atomic/ListItemSecondaryAction';
import FileImageIcon from '../../../../assets/icons/DocumentViewIcons/FileImageIcon';
import Tooltip from '../../../Tooltip';
import LockForDocView from '../../../../assets/icons/LockForDocView';
import BuildingDocument from '../../../../assets/icons/BuildingDocument';
import FolderClosed from '../../../../assets/icons/FolderClosed';

const DocumentListItem = ({
  classes,
  document,
  onFolderChange,
  newUpload,
  onMenuClick,
  searchQuery,
  actionsEnabled,
  selectedDocuments = [],
  onSelect = () => {},
  reset = false,
  isPersonalDocuments = false,
  isFromPersonal,
}) => {
  const [options, setOptions] = useState(selectedDocuments);
  const [checkDocumentItem, setCheckDocumentItem] = useState(null);
  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const isFolder = isDocumentsFolder(document);

  const onMenuButtonClick = (focusedDocument, event) => {
    event.preventDefault();
    onMenuClick(focusedDocument, event);
  };

  const docTitle = getDocumentName(document);
  const ownedByName = getDocumentOwnedByName(document);
  const companyName = getDocumentCompanyName(document);
  const uploadedByName = ownedByName || companyName;
  const professionType = getDocumentProfessionType(document);
  const createdDate = formattedDate(getDocumentCreatedDate(document));

  let secondaryText;

  if (isPersonalDocuments === true) {
    secondaryText = createdDate;
  } else if (isProfessionTypeSpecified(professionType)) {
    secondaryText = [createdDate, uploadedByName].join(' • ');
  } else {
    secondaryText = [createdDate, uploadedByName].join(' • ');
  }

  const documentLink = `${dashboardLinkPrefix}/documents/${getDocumentId(
    document
  )}`;

  const personalDocumentPath = `/personal-documents/${getDocumentId(document)}`;

  useEffect(() => {
    onSelect(options);
  }, [options.length]);

  useEffect(() => {
    if (reset) {
      setOptions([]);
    }
  }, [reset]);

  const handleChange = (o) => {
    if (checkDocumentItem) {
      setCheckDocumentItem(null);
      onSelect(o);
    } else {
      setCheckDocumentItem(o);
      onSelect(o);
    }
  };

  const renderIcon = () => {
    if (isDocumentImage(document)) {
      return (
        <>
          <Image
            fallbackComponent={<FileImageIcon className={classes.imageIcon} />}
            alt={docTitle}
            src={getOriginalDocumentPath(document)}
            className={classes.thumbnailImageIcon}
          />
        </>
      );
    }
    if (isFolder) {
      return <FolderClosed />;
    }
    return createElement(
      getFileThumbNailForDocumentView(getDocumentFileType(document)) ||
        getFileThumbNailForDocumentView('default'),
      { className: classes.imageIcon }
    );
  };

  const ListItemButton = ({ children }) => {
    if (isFolder) {
      return (
        <button
          type="button"
          className={classes.linkText}
          onClick={() => onFolderChange(document.id)}
        >
          {children}
        </button>
      );
    }
    return (
      <Link
        className={classes.linkText}
        to={{
          pathname: isFromPersonal ? personalDocumentPath : documentLink,
          state: { newUpload, isPersonalDocuments, isFromPersonal },
        }}
      >
        {children}
      </Link>
    );
  };

  return (
    <ListItem className={clsx(classes.listItem, 'word-break')}>
      {actionsEnabled && (
        <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
          <Checkbox
            onChange={() => handleChange(document)}
            checked={
              !!selectedDocuments.find((value) => value.id === document?.id)
            }
            className={clsx([
              classes.checkbox,
              isFolder && classes.folderCheckbox,
            ])}
            disabled={
              isDocumentReadOnly(document) || getDocumentIsBuilding(document)
            }
          />
        </ListItemSecondaryAction>
      )}
      <span className={classes.imageIconBox}>{renderIcon()}</span>
      <ListItemButton>
        <ListItemText
          primary={
            <TextHighlighted searchQuery={searchQuery}>
              <div className={classes.primaryText}>
                {isDocumentReadOnly(document) && (
                  <Tooltip
                    title="Filen inneholder historisk data, og kan ikke endres"
                    interactive
                    placement="bottom"
                    arrow
                    popperArrow
                  >
                    <div className={classes.lockForDocViewContainer}>
                      <LockForDocView width={14} height={14} />
                    </div>
                  </Tooltip>
                )}

                {getDocumentIsBuilding(document) && (
                  <Tooltip
                    title="Filen er knyttet til bygningen og ikke boenheten og kan derfor ikke redigeresFilen er knyttet til bygningen og ikke boenheten, og den kan derfor kun endres av avsender/bedriften"
                    interactive
                    placement="bottom"
                    arrow
                    popperArrow
                  >
                    <div className={classes.builidingDocViewContainer}>
                      <BuildingDocument width={12} height={12} />
                    </div>
                  </Tooltip>
                )}
                {docTitle}
              </div>
            </TextHighlighted>
          }
          secondaryTypographyProps={{ component: 'div' }}
          secondary={
            <Box>
              <small className={classes.secondaryText}>
                <TextHighlighted searchQuery={searchQuery}>
                  {secondaryText}
                </TextHighlighted>
              </small>
            </Box>
          }
        />
      </ListItemButton>
      <ListItemSecondaryAction className={classes.secondaryAction}>
        {actionsEnabled && (
          <MoreVert
            className={classes.menuIcon}
            onClick={(event) => onMenuButtonClick(document, event)}
          />
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default withStyles(styles)(DocumentListItem);
