import { borderVariables } from '@style/vars/borders';
import { baseBorders, baseSizes } from '../base/baseTheme';
import { makeBorder, makeOutline } from '../themeHelpers';
import { colors } from './colors';

export const bmBorders: typeof borderVariables = {
  ...baseBorders,
  default: makeBorder(colors.gray[300]),
  defaultFocus: makeBorder(colors.gray[500]),
  defaultActive: makeBorder(colors.black),
  inputActive: makeBorder(colors.purple[400]),
  outlineDefault: makeOutline(colors.purple[400]),
  outlineLight: makeOutline(colors.purple[200]),
  radio: {
    border: makeBorder(colors.gray[400], baseSizes.borderLarge),
    borderActive: makeBorder(colors.black, baseSizes.borderLarge),
  },
  link: {
    outline: makeOutline(colors.purple[400], baseSizes.borderLarge),
  },
};
