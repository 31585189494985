const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12,
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  infoText: {
    textAlign: 'center',
    maxWidth: 320,
  },
  iconButton: {
    position: 'absolute',
    top: -8,
    right: -8,
    padding: 8,
  },
});

export default styles;
