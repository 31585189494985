const styles = (theme) => ({
  pageTitle: {
    padding: '0 36px',
  },
  pageContent: {
    padding: '0 32px',
    '@media only screen and (max-width: 768px)': {
      borderTop: `1px solid ${theme.palette.primary.greyLightBlue}`,
      padding: '0 24px',
    },
  },
  topSection: {
    display: 'flex',
    flexDirection: 'row',
    gap: '72px',
    width: '100%',
    marginBottom: '72px',
    '@media only screen and (max-width: 768px)': {
      marginTop: '24px',
      flexDirection: 'column',
      gap: '32px',
    },
  },
});

export default styles;
