import React from 'react';

const DeleteIcon = ({ width = '1rem', height = '1rem', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9375 2.5H14.75C14.9375 2.5 15.125 2.59375 15.2812 2.71875C15.4062 2.875 15.5 3.0625 15.5 3.25V3.625C15.5 3.75 15.4375 3.84375 15.375 3.90625C15.3125 3.96875 15.2188 4 15.125 4H14.625L13.625 14.625C13.5625 15.0312 13.4062 15.375 13.125 15.625C12.8438 15.875 12.5 16 12.125 16H3.875C3.46875 16 3.125 15.875 2.84375 15.625C2.5625 15.375 2.40625 15.0312 2.375 14.625L1.375 4H0.875C0.75 4 0.65625 3.96875 0.59375 3.90625C0.53125 3.84375 0.5 3.75 0.5 3.625V3.25C0.5 3.0625 0.5625 2.875 0.71875 2.71875C0.84375 2.59375 1.03125 2.5 1.25 2.5H4.0625L5.125 0.71875C5.25 0.5 5.4375 0.3125 5.6875 0.1875C5.90625 0.0625 6.15625 0 6.4375 0H9.5625C9.8125 0 10.0625 0.0625 10.3125 0.1875C10.5312 0.3125 10.7188 0.5 10.875 0.71875L11.9375 2.5ZM6.4375 1.5L5.8125 2.5H10.1875L9.5625 1.5H6.4375ZM12.125 14.5L13.0938 4H2.90625L3.875 14.5H12.125Z"
      fill="#D7394C"
    />
  </svg>
);
export default DeleteIcon;
