import React, { useState, useEffect } from 'react';
import Button from '../../../../../../../../components/atomic/Button';
import Link from '../../../../../../../../components/atomic/Link';
import {
  fetchConsents as apiFetchConsents,
  updateConsents as apiUpdateConsents,
} from '../../../../../../../../api/propertyowners';
import { PROPERTYVALUEESTIMATEUPDATES } from '../../../../../../../../constants';
import useSnackbar from '../../../../../../../../hooks/useSnackbar';
import useFetchEffect from '../../../../../../../../hooks/useFetchEffect';
import { useConsentStyles } from './style';
import matomo from '../../../../../../../../utils/matomo';

export function ValueEstimateConsent() {
  const classes = useConsentStyles();
  const { showSuccessMessage, showErrorMessage } = useSnackbar();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [consents, setConsents] = useState(null);
  const [showConsentButton, setShowConsentButton] = useState(false);

  useFetchEffect({
    initialFetchingState: true,
    apiFetchFunction: apiFetchConsents,

    onSuccess: (consentsData) => {
      setConsents(consentsData);
    },
  });

  useEffect(() => {
    if (consents) {
      const valueEstimateEmailConsent = consents.find(
        (consent) => consent.type === PROPERTYVALUEESTIMATEUPDATES
      );
      const isEmailConsentGranted = valueEstimateEmailConsent?.isGranted;

      setShowConsentButton(!isEmailConsentGranted);
    }
  }, [consents]);

  const onConsentButtonSubmit = async () => {
    setRequestInProgress(true);

    const updatedConsent = [
      { type: PROPERTYVALUEESTIMATEUPDATES, isGranted: true },
    ];

    const [error, response] = await apiUpdateConsents(updatedConsent);
    if (error && !response) {
      showErrorMessage();
    } else {
      showSuccessMessage();
      setShowConsentButton(false);
    }

    setRequestInProgress(false);
  };

  const onClickIncorrect = () => {
    matomo.clickEvent({
      category: 'Value Estimate and Finances',
      action: 'Clicked Incorrect valuation',
    });
  };

  return (
    <div className={classes.consentContainer}>
      {showConsentButton && (
        <>
          <p className={classes.valueEstimateConsentText}>
            Motta månedlig varsel på e-post med oppdatert prisestimat for
            eiendommen.
          </p>
          <Button
            color="primary"
            variant="contained"
            disabled={requestInProgress}
            onClick={onConsentButtonSubmit}
            className={classes.consentButton}
          >
            Hold meg oppdatert
          </Button>
          <br />
          <br />

          <p className={classes.consentLink}>
            <b>Oppleves estimatet uriktig?</b>
            <br />
            Dette kan skyldes at vi har feil størrelse på boligen. Dette kan du
            endre på Hjemla.no.
            <br />
            <Link
              className={classes.moreLink}
              onClick={onClickIncorrect}
              to="https://support.hjemla.no/hc/no/articles/13886426641554-Estimatet-jeg-ser-i-Boligmappa-er-feil"
              isExternal
              openInNewTab
            >
              Les mer her.
            </Link>
          </p>
        </>
      )}

      {!showConsentButton && (
        <>
          <p className={classes.valueEstimateTextTop}>
            Du mottar månedlig varsel på epost med oppdatert prisestimat.
          </p>
          <p className={classes.valueEstimateTextBottom}>
            *Dette kan endres under «Administrer samtykker»
          </p>
          <p className={classes.consentLink}>
            <b>Oppleves estimatet uriktig?</b>
            <br />
            Dette kan skyldes at vi har feil størrelse på boligen. Dette kan du
            endre på Hjemla.no.
            <br />
            <Link
              className={classes.moreLink}
              onClick={onClickIncorrect}
              to="https://support.hjemla.no/hc/no/articles/13886426641554-Estimatet-jeg-ser-i-Boligmappa-er-feil"
              isExternal
              openInNewTab
            >
              Les mer her.
            </Link>
          </p>
        </>
      )}
    </div>
  );
}
