import {
  SET_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS,
  UPDATE_NOTIFICATION,
  SET_FETCHING_NOTIFICATIONS,
  SET_FETCHING_NOTIFICATIONS_ERROR,
} from '../reducers/notifications';

export const acSetNotifications = (notifications) => ({
  type: SET_NOTIFICATIONS,
  payload: notifications,
});

export const acClearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS,
});

export const acUpdateNotification = (notification) => ({
  type: UPDATE_NOTIFICATION,
  payload: notification,
});

export const acSetFetchingNotifications = (fetching) => ({
  type: SET_FETCHING_NOTIFICATIONS,
  payload: fetching,
});

export const acSetFetchingNotificationsError = (error) => ({
  type: SET_FETCHING_NOTIFICATIONS_ERROR,
  payload: error,
});
