import React from 'react';

const CloseBold = ({
  width = '13',
  height = '14',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 13 14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.625 7L12.8047 11.2188C12.9219 11.3359 13 11.4922 13 11.6484C13 11.8438 12.9219 12 12.8047 12.0781L11.8281 13.0547C11.7109 13.2109 11.5547 13.25 11.3984 13.25C11.2031 13.25 11.0469 13.2109 10.9688 13.0547L6.75 8.875L2.53125 13.0547C2.41406 13.2109 2.25781 13.25 2.10156 13.25C1.90625 13.25 1.75 13.2109 1.67188 13.0547L0.695312 12.0781C0.539062 12 0.5 11.8438 0.5 11.6484C0.5 11.4922 0.539062 11.3359 0.695312 11.2188L4.875 7L0.695312 2.78125C0.539062 2.70312 0.5 2.54688 0.5 2.35156C0.5 2.19531 0.539062 2.03906 0.695312 1.92188L1.67188 0.945312C1.75 0.828125 1.90625 0.75 2.10156 0.75C2.25781 0.75 2.41406 0.828125 2.53125 0.945312L6.75 5.125L10.9688 0.945312C11.0469 0.828125 11.2031 0.75 11.3984 0.75C11.5547 0.75 11.7109 0.828125 11.8281 0.945312L12.8047 1.92188C12.9219 2.03906 13 2.19531 13 2.35156C13 2.54688 12.9219 2.70312 12.8047 2.78125L8.625 7Z"
      fill="#111111"
    />
  </svg>
);

export default CloseBold;
