import clsx from 'clsx';
import React, { createElement } from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Box from '../atomic/Box';
import ListItem from '../atomic/ListItem';
import Typography from '../atomic/Typography';

const ChapterMobileItem = ({
  classes,
  chapter,
  className,
  rightText,
  secondaryText,
  boldPrimaryText = false,
  ...props
}) => (
  <ListItem
    alignItems="flex-start"
    className={clsx(classes.root, className)}
    {...props}
  >
    <Box mr={2}>
      <Typography color="primary">
        {createElement(chapter.icon, { className: classes.icon })}
      </Typography>
    </Box>

    <Box flexGrow={1}>
      <Typography>
        {boldPrimaryText ? (
          <strong>
            {chapter.id}. {chapter.name}
          </strong>
        ) : (
          <>
            {chapter.id}. {chapter.name}
          </>
        )}
      </Typography>

      {secondaryText && (
        <Typography component="small" color="textSecondary">
          {secondaryText}
        </Typography>
      )}
    </Box>

    {rightText && (
      <Box minWidth={75} textAlign="right">
        <Typography component="small" color="textSecondary">
          {rightText}
        </Typography>
      </Box>
    )}
  </ListItem>
);

export default withStyles(styles)(ChapterMobileItem);
