import clsx from 'clsx';
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import styles from './style';
import Tooltip from '../Tooltip';
import List from '../atomic/List';
import Button from '../atomic/Button';
import ListItem from '../atomic/ListItem';
import EntitiesDialog from './EntitiesDialog';
import { truncate } from '../../utils/strings';
import useDesktop from '../../hooks/useDesktop';
import { getDocumentId } from '../../utils/documents';
import CheckCircle from '../../assets/icons/CheckCircle';
import { getRoomId, getRoomName } from '../../utils/rooms';
import { getEventId, getEventName } from '../../utils/events';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';

export const maxNameLength = 10;

const entityTypes = [
  {
    type: 'event',
    getId: getEventId,
    getName: getEventName,
    attachText: 'Koble til hendelse',
    getEntityUrl: (dashboardLinkPrefix, eventId) =>
      `${dashboardLinkPrefix}/events/${eventId}`,
    getAttachEntityUrl: (dashboardLinkPrefix, documentId) =>
      `${dashboardLinkPrefix}/documents/${documentId}/edit?focusOnEvent=true`,
  },
  {
    type: 'room',
    getId: getRoomId,
    getName: getRoomName,
    attachText: 'Koble til rom',
    getEntityUrl: (dashboardLinkPrefix, roomId) =>
      `${dashboardLinkPrefix}/rooms/${roomId}`,
    getAttachEntityUrl: (dashboardLinkPrefix, documentId) =>
      `${dashboardLinkPrefix}/documents/${documentId}/edit?focusOnRoom=true`,
  },
];

const AttachedEntity = ({
  type,
  classes,
  entities,
  document,
  editable = true,
}) => {
  const isDesktop = useDesktop();
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const history = useHistory();

  const [dialogOpen, setDialogOpen] = useState(false);

  const entityType = entityTypes.find(
    (_entityType) => _entityType.type === type
  );

  const onDialogOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDialogOpen(true);
  };

  const isNameLong = (entity) =>
    String(entityType.getName(entity)).length > maxNameLength;

  if (!Array.isArray(entities) || entities.length === 0) {
    if (!editable) {
      return null;
    }

    return (
      <Button
        className={clsx(classes.button, classes.add)}
        onClick={(event) => {
          event.preventDefault();
          const url = entityType.getAttachEntityUrl(
            dashboardLinkPrefix,
            getDocumentId(document)
          );
          history.push(url);
        }}
      >
        {entityType.attachText}
      </Button>
    );
  }

  if (entities.length === 1 && !isNameLong(entities[0])) {
    return (
      <Button
        className={classes.button}
        onClick={(event) => {
          event.preventDefault();
          const url = entityType.getEntityUrl(
            dashboardLinkPrefix,
            entityType.getId(entities[0])
          );
          history.push(url);
        }}
      >
        <strong>{entityType.getName(entities[0])}</strong>
        <CheckCircle className={classes.checkIcon} />
      </Button>
    );
  }

  const list = (
    <List>
      {entities.map((entity) => (
        <ListItem
          className={classes.listItem}
          key={`document-${getDocumentId(document)}-entity-${entityType.getId(
            entity
          )}`}
        >
          <Button
            className={classes.button}
            onClick={(event) => {
              event.preventDefault();
              const url = entityType.getEntityUrl(
                dashboardLinkPrefix,
                entityType.getId(entity)
              );
              history.push(url);
            }}
          >
            <strong>{entityType.getName(entity)}</strong>
          </Button>
        </ListItem>
      ))}
    </List>
  );

  if (isDesktop) {
    return (
      <Tooltip interactive placement="left" title={list}>
        <Button className={classes.button}>
          <strong>
            {truncate(entityType.getName(entities[0]), {
              length: maxNameLength,
            })}
          </strong>

          <span className={classes.badge}>{entities.length}</span>

          <CheckCircle className={classes.checkIcon} />
        </Button>
      </Tooltip>
    );
  }

  return (
    <>
      <Button onClick={onDialogOpen} className={classes.button}>
        <strong>
          {truncate(entityType.getName(entities[0]), { length: maxNameLength })}
        </strong>

        <span className={classes.badge}>{entities.length}</span>

        <CheckCircle className={classes.checkIcon} />
      </Button>

      <EntitiesDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        {list}
      </EntitiesDialog>
    </>
  );
};

export default withStyles(styles)(AttachedEntity);
