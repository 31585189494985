const styles = () => ({
  textTop: {
    fontWeight: 500,
    fontSize: '14px',
  },
  companyName: {
    fontWeight: 700,
  },
  dialogContainer: {
    width: '500px',
    padding: '10px 40px',
  },
  dialogTitle: {
    paddingRight: '10px',
  },
  dialogActins: {
    padding: '8px 20px 26px',
  },
  dialogPaper: {
    borderRadius: '8px',
  },
  mobileDrawer: {
    borderRadius: '15px 15px 0 0',
  },
});

export default styles;
