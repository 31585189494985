import * as React from 'react';
import PropTypes from 'prop-types';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import useDesktop from '../../../hooks/useDesktop';
import TextField from '../TextField';

function ValueLabelComponent(props) {
  const { children, value } = props;
  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};
const DistanceSlider = styled(Slider)(({ theme }) => ({
  color: '#254E52',
  padding: '0px',

  marginTop: '8%',
  '& .MuiSlider-thumb': {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 3,
  },
}));

function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}

export default function CustomizedSlider({ onSelect, value, disabled }) {
  const isDesktop = useDesktop();

  const handleChange = (event, newValue) => {
    if (typeof newValue === 'number') {
      onSelect(newValue);
    }
  };

  const handleInputChange = (val) => {
    onSelect(val);
  };

  return (
    <>
      {isDesktop ? (
        <Box sx={{ width: 320 }}>
          <Box sx={{ width: '110px', display: 'flex', alignItems: 'center' }}>
            <TextField
              disabled={disabled}
              type="number"
              value={value > 250 ? 250 : value}
              onChange={(e) => handleInputChange(e.target.value)}
            />
            <Box sx={disabled ? { color: '#00000061' } : {}}>
              {value > 250 ? <>&nbsp;&nbsp;+Km </> : <>&nbsp;&nbsp;Km </>}
            </Box>
          </Box>
          <DistanceSlider
            components={{ Thumb: AirbnbThumbComponent }}
            getAriaLabel={(index) =>
              index === 0 ? 'Minimum price' : 'Maximum price'
            }
            value={value}
            onChange={handleChange}
            min={1}
            max={251}
            defaultValue={251}
            disabled={disabled}
          />
        </Box>
      ) : (
        <Box sx={{ width: 320, marginLeft: '5px' }}>
          <DistanceSlider
            components={{ Thumb: AirbnbThumbComponent }}
            getAriaLabel={(index) =>
              index === 0 ? 'Minimum price' : 'Maximum price'
            }
            value={value}
            onChange={handleChange}
            min={1}
            max={251}
            defaultValue={251}
            disabled={disabled}
          />
          <Box sx={{ width: '110px', display: 'flex', alignItems: 'center' }}>
            <TextField
              disabled={disabled}
              type="number"
              value={value > 250 ? 250 : value}
              onChange={(e) => handleInputChange(e.target.value)}
            />
            <Box sx={disabled ? { color: '#00000061' } : {}}>
              {value > 250 ? <>&nbsp;&nbsp;+Km </> : <>&nbsp;&nbsp;Km </>}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
