import {
  ADD_PERSONAL_DOCUMENT,
  ADD_PERSONAL_FOLDER,
  SET_PERSONAL_DOCUMENTS,
  CLEAR_PERSONAL_DOCUMENTS,
  UPDATE_PERSONAL_DOCUMENT,
  DELETE_PERSONAL_DOCUMENT,
  SET_FETCHING_PERSONAL_DOCUMENTS,
  SET_FETCHING_PERSONAL_DOCUMENTS_ERROR,
  SET_SEARCH_TEXT,
  SET_MULTIPLE_PERSONAL_DOCUMENTS_SELECTED,
  UPDATE_PERSONAL_DOCUMENT_FOLDER_TO_RENDER,
  SET_PERSONAL_DOCUMENT_FOLDERS,
  SET_PERSONAL_DOCUMENT_FOLDERS_TO_RENDER,
  SET_PERSONAL_FOLDERS_BREADCRUMBS,
  DELETE_PERSONAL_DOCUMENT_FOLDER,
  SELECTED_PERSONAL_DOCUMENT_FOLDER_ID,
} from '../reducers/personalDocuments';

export const acAddPersonalDocument = (document) => ({
  type: ADD_PERSONAL_DOCUMENT,
  payload: document,
});

export const acAddPersonalFolder = (breadcrumbs, newFolder) => ({
  type: ADD_PERSONAL_FOLDER,
  payload: { breadcrumbs, newFolder },
});

export const acSetPersonalDocuments = (documents) => ({
  type: SET_PERSONAL_DOCUMENTS,
  payload: documents,
});

export const acSetPersonalDocumentFolders = (folders) => ({
  type: SET_PERSONAL_DOCUMENT_FOLDERS,
  payload: folders,
});

export const acSetPersonalDocumentFoldersToRender = (folders) => ({
  type: SET_PERSONAL_DOCUMENT_FOLDERS_TO_RENDER,
  payload: folders,
});

export const acSetPersonalFoldersBreadCrumbs = (breadCrumbs) => ({
  type: SET_PERSONAL_FOLDERS_BREADCRUMBS,
  payload: breadCrumbs,
});

export const acClearPersonalDocuments = () => ({
  type: CLEAR_PERSONAL_DOCUMENTS,
});

export const acUpdatePersonalDocument = (document) => ({
  type: UPDATE_PERSONAL_DOCUMENT,
  payload: document,
});

export const acUpdatePersonalDocumentFolderToRender = (folder) => ({
  type: UPDATE_PERSONAL_DOCUMENT_FOLDER_TO_RENDER,
  payload: folder,
});

export const acDeletePersonalDocument = (document) => ({
  type: DELETE_PERSONAL_DOCUMENT,
  payload: document,
});

export const acDeletePersonalDocumentFolder = (folder) => ({
  type: DELETE_PERSONAL_DOCUMENT_FOLDER,
  payload: folder,
});

export const acSetFetchingPersonalDocuments = (fetching) => ({
  type: SET_FETCHING_PERSONAL_DOCUMENTS,
  payload: fetching,
});

export const acSetFetchingPersonalDocumentsError = (error) => ({
  type: SET_FETCHING_PERSONAL_DOCUMENTS_ERROR,
  payload: error,
});

export const acSetPersonalDocumentSearchText = (searchText) => ({
  type: SET_SEARCH_TEXT,
  payload: searchText,
});

export const acSetMultiplePersonalDocumentsSelected = (
  multipleDocumentsSelected
) => ({
  type: SET_MULTIPLE_PERSONAL_DOCUMENTS_SELECTED,
  payload: multipleDocumentsSelected,
});

export const acSetSelectedPersonalFolderId = (id) => ({
  type: SELECTED_PERSONAL_DOCUMENT_FOLDER_ID,
  payload: id,
});
