/**
 * Items per line for each of device sizes
 * @type {{md: number, sm: number, xs: number, lg: number}}
 */
export const gridOptions = {
  xs: {
    itemsPerLine: 4,
  },
  sm: {
    itemsPerLine: 4,
  },
  md: {
    itemsPerLine: 6,
  },
  lg: {
    itemsPerLine: 5,
  },
  xl: {
    itemsPerLine: 5,
  },
};

/**
 * Grid item props for each breakpoint
 * @type {string}
 */
export const gridItemProps = Object.keys(gridOptions).reduce(
  (result, key) => ({
    ...result,
    [key]: Math.floor(12 / gridOptions[key].itemsPerLine), // in Mui Grid is divided onto 12 sections
  }),
  {}
);
