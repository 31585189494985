import { useEffect, useState } from 'react';
import { Chart as ChartJS } from 'chart.js';
import { chartColors } from '@style/vars/colors';
import { createGradient } from '../../chartHelpers';

export function useGradientData(chartRef, gradientData, data) {
  const [chartData, setChartData] = useState({ datasets: [] });

  useEffect(() => {
    const chart: ChartJS = chartRef.current;
    const chartDataInner = { ...data };

    if (!chart) {
      return;
    }

    chartDataInner.datasets = data.datasets.map((dataset) => ({
      ...dataset,
      fill: {
        target: 'start',
        above: gradientData?.length
          ? createGradient(chart.ctx, chart.chartArea, gradientData)
          : chartColors.transparent,
      },
    }));

    setChartData(chartDataInner);
  }, [gradientData]);

  return chartData;
}