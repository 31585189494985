import React from 'react';

const FolderOpened = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    {...props}
  >
    <path
      d="M3.75 6L1 10.75V2C1 0.90625 1.875 0 3 0H6.65625C7.1875 0 7.6875 0.21875 8.0625 0.59375L8.90625 1.4375C9.28125 1.8125 9.78125 2 10.3125 2H14C15.0938 2 16 2.90625 16 4V5H5.5C4.78125 5 4.125 5.375 3.75 6ZM4.625 6.5C4.8125 6.1875 5.125 6 5.5 6H18C18.3438 6 18.6875 6.21875 18.8438 6.53125C19.0312 6.84375 19.0312 7.21875 18.8438 7.53125L15.3438 13.5312C15.1562 13.8125 14.8438 14 14.5 14H2C1.625 14 1.3125 13.8125 1.125 13.5C0.9375 13.1875 0.9375 12.8125 1.125 12.5L4.625 6.5Z"
      fill="#1B88C2"
    />
  </svg>
);

export default FolderOpened;
