import { hotjar } from 'react-hotjar';

const initialize = (hjid, hjsv) => hotjar.initialize(hjid, hjsv);

const trigger = (triggerName) => {
  window.hj('trigger', triggerName);
};

export default {
  initialize,
  trigger,
};
