import React from 'react';
import Box from '../../../../../../components/atomic/Box';
import List from '../../../../../../components/atomic/List';
import ListItem from '../../../../../../components/atomic/ListItem';
import ContentLoader from '../../../../../../components/loaders/ContentLoader';

const ChapterListLoading = () => (
  <List>
    {[...Array(6).keys()].map((key) => (
      <ListItem
        style={{ padding: '16px 0' }}
        key={`chapter-loader-list-item-${key}`}
      >
        <Box mr={1}>
          <ContentLoader variant="circle" height={50} width={50} />
        </Box>
        <Box>
          <ContentLoader width={200} height={15} />
          <ContentLoader style={{ marginTop: '5px' }} width={150} height={15} />
        </Box>
      </ListItem>
    ))}
  </List>
);

export default ChapterListLoading;
