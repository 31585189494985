import React from 'react';

const AddFolder = ({ width = '16', height = '14', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M7.84375 3L6.5 1.65625L7.5625 0.59375L6.5 1.65625C6.40625 1.5625 6.28125 1.5 6.15625 1.5H2C1.71875 1.5 1.5 1.75 1.5 2V12C1.5 12.2812 1.71875 12.5 2 12.5H14C14.25 12.5 14.5 12.2812 14.5 12V4C14.5 3.75 14.25 3.5 14 3.5H9.09375C8.625 3.5 8.1875 3.34375 7.84375 3ZM8.90625 1.9375C8.96875 2 9.03125 2 9.09375 2H14C15.0938 2 16 2.90625 16 4V12C16 13.125 15.0938 14 14 14H2C0.875 14 0 13.125 0 12V2C0 0.90625 0.875 0 2 0H6.15625C6.6875 0 7.1875 0.21875 7.5625 0.59375L8.90625 1.9375ZM7.25 10.75V8.75H5.25C4.8125 8.75 4.5 8.4375 4.5 8C4.5 7.59375 4.8125 7.25 5.25 7.25H7.25V5.25C7.25 4.84375 7.5625 4.5 8 4.5C8.40625 4.5 8.75 4.84375 8.75 5.25V7.25H10.75C11.1562 7.25 11.5 7.59375 11.5 8C11.5 8.4375 11.1562 8.75 10.75 8.75H8.75V10.75C8.75 11.1875 8.40625 11.5 8 11.5C7.5625 11.5 7.25 11.1875 7.25 10.75Z"
      fill="#111111"
    />
  </svg>
);

export default AddFolder;
