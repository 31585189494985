const styles = (theme) => ({
  header: {
    padding: '30px 25px 25px 35px',
    borderBottom: `1px solid ${theme.palette.primary.whiteDarker}`,
  },
  list: {
    overflowY: 'auto',
    height: 'calc(100vh - 65px)',
    borderRight: `1px solid ${theme.palette.primary.whiteDarker}`,
  },
});

export default styles;
