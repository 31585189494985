import { useEffect } from 'react';
import { fetchRightsFromApi } from '../properties';
import { useApiFetch } from './useApiFetch';

export function useFetchRights(property) {
  const fanancialsApi = useApiFetch(fetchRightsFromApi);

  useEffect(() => {
    if (!property?.boligmappaNumber) return;

    const { boligmappaNumber } = property;
    fanancialsApi.executeFetch(boligmappaNumber);
  }, [property?.boligmappaNumber]);

  return {
    data: fanancialsApi.data,
    error: fanancialsApi.error,
    executeFetch: fanancialsApi.executeFetch,
    isLoading: fanancialsApi.isLoading,
  };
}
