import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Box from '../../atomic/Box';
import { filterTypes } from '../util';
import Button from '../../atomic/Button';
import SelectedFilter from './SelectedFilter';
import { areFiltersEmpty } from '../../../utils/documents';

const SelectedFilters = ({ classes, filters, onFiltersChanged }) =>
  areFiltersEmpty(filters) ? null : (
    <Box className={classes.filters}>
      {filterTypes.map(({ type }) =>
        (filters[type] || []).map((filter) => (
          <Box
            p={0.5}
            key={`chosen-filter-${
              filter && filter.value ? filter.value : filter
            }`}
          >
            <SelectedFilter
              filter={filter}
              onCloseClick={() =>
                onFiltersChanged({
                  ...filters,
                  [type]: filters[type].filter((_filter) => _filter !== filter),
                })
              }
            />
          </Box>
        ))
      )}

      <Button
        size="small"
        variant="text"
        color="primary"
        onClick={() => onFiltersChanged({})}
      >
        Nullstill
      </Button>
    </Box>
  );

export default withStyles(styles)(SelectedFilters);
