import React from 'react';
import Box from '../atomic/Box';

export const defaultBorderRadius = 21;

const Card = ({
  children,
  border = 1,
  padding = 3,
  overflow = 'hidden',
  borderRadius = defaultBorderRadius,
  borderColor = 'primary.greenLighter',
  ...props
}) => (
  <Box
    border={border}
    padding={padding}
    overflow={overflow}
    borderColor={borderColor}
    borderRadius={borderRadius}
    {...props}
  >
    {children}
  </Box>
);

export default Card;
