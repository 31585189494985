import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import List from '../atomic/List';
import Paper from '../atomic/Paper';
import Typography from '../atomic/Typography';

const CardList = ({ title, classes, children, onPaper = true }) => {
  const content = (
    <>
      {title && (
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
      )}
      <List className={classes.list}>{children}</List>
    </>
  );

  return onPaper ? <Paper>{content}</Paper> : content;
};

export default withStyles(styles)(CardList);
