import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { useLocation } from 'react-router';
import RadonAreaLevelUnknown from '../../assets/icons/RadonAreaLevelUnknown';
import RadonAreaLevelModerate from '../../assets/icons/RadonAreaLevelModerate';
import RadonAreaLevelHigh from '../../assets/icons/RadonAreaLevelHigh';
import RadonAreaLevelVeryHigh from '../../assets/icons/RadonAreaLevelVeryHigh';

import styles from './style';
import { getPropertyFeatures } from '../../api/properties';
import ContentLoader from '../loaders/ContentLoader';
import { RadonLevel } from '../../utils/radon';
import useFetchEffect from '../../hooks/useFetchEffect';

const RadonAreaLevel = ({
  classes,
  isWidget,
  boligmappaNumber,
  radonAreaLevel,
}) => {
  const [radonLevelBlock, setRadonLevelBlock] = useState({});
  const location = useLocation();
  const [fetching, setFetching] = useState(true);
  const radonFirstLine = 'Estimert aktsomhetsgrad i ditt område:';
  const radonSecondLine = 'Basert på data fra Geo Norge';
  const classSwitch = isWidget
    ? [
        classes.widgetContainer,
        classes.widgetTitle,
        classes.widgetLevelText,
        classes.widgetFooter,
      ]
    : [
        classes.pageContainer,
        classes.pageTitle,
        classes.pageLevelText,
        classes.pageFooter,
      ];

  const getLevel = (currentLevel) => {
    switch (currentLevel) {
      case RadonLevel.UNKNOWN:
        setRadonLevelBlock({
          status: 'ukjent',
          icon: <RadonAreaLevelUnknown />,
        });
        break;
      case RadonLevel.MODERATE:
        setRadonLevelBlock({
          status: 'moderat',
          icon: <RadonAreaLevelModerate />,
        });
        break;
      case RadonLevel.HIGH:
        setRadonLevelBlock({ status: 'høy', icon: <RadonAreaLevelHigh /> });
        break;

      case RadonLevel.VERYHIGH:
        setRadonLevelBlock({
          status: 'svært høy',
          icon: <RadonAreaLevelVeryHigh />,
        });
        break;
      default:
        setRadonLevelBlock({ status: null, icon: null });
        break;
    }
  };

  const fetchRadonAreaData = (response) => {
    const currentLevel = response?.radonLevel
      ? response.radonLevel
      : RadonLevel.UNKNOWN;
    getLevel(currentLevel);
  };

  useFetchEffect({
    dependencies: [boligmappaNumber],
    setFetchingFunction: setFetching,
    onSuccess: (data) => fetchRadonAreaData(data),
    onError: (error) => fetchRadonAreaData(error),
    conditionFunction: () =>
      !radonAreaLevel &&
      boligmappaNumber &&
      (location?.pathname.includes('radon') ||
        location?.pathname.includes('home')),
    apiFetchFunction: () => getPropertyFeatures(boligmappaNumber),
  });

  useEffect(() => {
    if (radonAreaLevel) {
      setFetching(false);
      getLevel(radonAreaLevel);
    }
  }, [radonAreaLevel]);

  return (
    <div className={`${classes.container} ${classSwitch[0]}`}>
      <div className={`${classes.title} ${classSwitch[1]}`}>
        {radonFirstLine}
      </div>
      {!fetching && (
        <div className={classes.levelContainer}>
          <span className={`${classes.levelText} ${classSwitch[2]}`}>
            {radonLevelBlock.status}
            {''}
          </span>
          <span className={classes.levelIcon}>{radonLevelBlock.icon}</span>
        </div>
      )}
      {fetching && (
        <div style={{ width: '100px' }}>
          <ContentLoader width="100%" height={25} />
        </div>
      )}
      <div className={`${classes.footer} ${classSwitch[3]}`}>
        {radonSecondLine}
      </div>
    </div>
  );
};

export default withStyles(styles)(RadonAreaLevel);
