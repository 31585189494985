import React, { createElement } from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Box from '../atomic/Box';
import { getFilterTypes } from './util';
// import SortingMenuItem from './SortingMenuItem';

const DocumentEditFilters = ({
  classes,
  filters,
  fetching,
  // sortType,
  documents,
  fetchingError,
  onFiltersChanged,
  onSortTypeChange,
  currentSortingType,
  isPersonalDocuments,
}) => {
  const filterTypes = getFilterTypes(isPersonalDocuments);

  if (fetching || fetchingError || !Array.isArray(documents)) {
    return null;
  }

  return (
    <Box className={classes.root}>
      {filterTypes
        .filter(({ desktopOnly }) => !desktopOnly)
        .map(
          ({
            type,
            name,
            getProps,
            description,
            filterComponent,
            getFilterOptions,
            isSortable,
            isFilterable,
            sortUpType,
            sortDownType,
            defaultSortUp,
            isVisibleForPersonalDoc,
          }) => {
            return createElement(filterComponent, {
              name,
              description,
              key: `filter-option-${type}`,
              onFiltersReset: () =>
                onFiltersChanged({
                  ...filters,
                  [type]: [],
                }),
              filterOptions:
                getFilterOptions &&
                getFilterOptions(documents, filters[type], (updatedFilters) =>
                  onFiltersChanged({
                    ...filters,
                    [type]: updatedFilters,
                  })
                ),
              isSortable,
              isFilterable,
              isVisible: isVisibleForPersonalDoc,
              sortUpType,
              sortDownType,
              defaultSortUp,
              onSortTypeChange,
              currentSortingType,
              ...(getProps ? getProps(filters, onFiltersChanged) : undefined),
            });
          }
        )}

      {/* <SortingMenuItem
        sortType={sortType}
        onSortTypeChange={onSortTypeChange}
      /> */}
    </Box>
  );
};

export default withStyles(styles)(DocumentEditFilters);
