import React from 'react';

import {
  chapters,
  groupDocumentsByChapters,
} from '../../../../../utils/documents';

import ChapterItem from './ChapterItem';
import ChapterListLoading from './ChapterListLoading';
import List from '../../../../../components/atomic/List';
import Paper from '../../../../../components/atomic/Paper';

const ChapterList = ({
  documents,
  fetchingDocuments,
  fetchingDocumentsError,
}) => {
  if (fetchingDocumentsError) {
    return null;
  }

  if (fetchingDocuments) {
    return (
      <Paper style={{ paddingTop: 0, paddingBottom: 0 }}>
        <ChapterListLoading />
      </Paper>
    );
  }

  const documentChapterMap =
    Array.isArray(documents) && groupDocumentsByChapters(documents);

  return (
    <Paper style={{ paddingTop: 0, paddingBottom: 0 }}>
      <List>
        {chapters.map((chapter, index) => (
          <ChapterItem
            chapter={chapter}
            key={`chapter-item-${chapter.id}`}
            divider={index + 1 !== chapters.length}
            documents={documentChapterMap[chapter.id] || []}
          />
        ))}
      </List>
    </Paper>
  );
};

export default ChapterList;
