const styles = (theme) => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionButton: {
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0,
    },
    minHeight: '40px',
    width: '140px',
    height: 'auto',
    '&.mobile': {
      width: '100px',
    },
  },
  actionButtonBYBF: {
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0,
    },
    minHeight: '40px',
    width: '280px',
    height: 'auto',
  },
  actionButtonLarge: {
    minHeight: '45px',
    minWidth: '250px',
    height: 'auto',
  },
  submitButtonRed: {
    backgroundColor: '#D7394C',
    color: '#fff',
    textDecoration: 'unset',
    '&:hover': {
      backgroundColor: '#D7394C',
    },
  },
  actionBtnCenter: {
    justifyContent: 'center',
  },
  actionBtnLeft: {
    justifyContent: 'flex-start',
  },
  actionBtnCnterRow: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
  },
});

export default styles;
