import styled from 'styled-components';

export const StyledContactMeBox = styled.div`
  background-color: #fff;
  padding: 32px;

  .box_wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    .title {
      font-size: 18px;
      line-height: 31px;
      font-weight: 700;
      margin: 0;
    }

    p {
      font-size: 16px;
      line-height: 25px;
      font-weight: 500;
      margin: 0;
    }

    .contact_button {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: #fff;
      padding: 12px 24px;
      width: 100%;
      border: none;
      border-radius: 50px;
      background-color: #164144;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 5px 1px #848484;
      }
    }
  }
`;
