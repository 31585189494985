const styles = (theme) => ({
  root: {
    top: 0,
    zIndex: 999,
    position: 'sticky',
  },
  content: {
    '@supports (padding-top: env(safe-area-inset-bottom))': {
      paddingTop: 'env(safe-area-inset-top)',
    },
  },
  closeIcon: {
    width: '20px',
    height: '20px',
    cursor: 'pointer',
  },
  regular: {
    backgroundColor: theme.palette.primary.white,
    borderBottom: `1px solid ${theme.palette.primary.whiteDarker}`,
  },
  info: {
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.blue,
  },
  error: {
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.redDark,
  },
  success: {
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.green,
  },
  warning: {
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.orange,
  },
  hjemlaLoginSnackBarColor: {
    color: '#000000',
    backgroundColor: '#D4D5FC',
  },
});

export default styles;
