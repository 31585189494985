import React from 'react';
import { withStyles } from '@material-ui/core';

import DeleteIcon from '../../../../../../../assets/icons/Bin';

import Table from '../../../../../../../components/Table';
import IconButton from '../../../../../../../components/atomic/IconButton';
import TableLoading from '../../../../../../../components/desktop/loaders/TableLoading';
import Tooltip from '../../../../../../../components/Tooltip';

import {
  getName,
  getEmail,
  getSharedDate,
  getSharedBy,
  getStatus,
} from '../../../../../../../utils/privateshare';
import { formattedDate } from '../../../../../../../utils/dates';

import style from './style';

const DetailsTable = ({ classes, shareRecords, fetching, onDeleteShare }) => {
  const statusDiv = (status) => (
    <div
      className={
        status === 'active' ? classes.activeLabel : classes.pendingLabel
      }
    >
      <strong>{status === 'active' ? 'AKTIV' : 'VENTER'}</strong>
    </div>
  );

  const columns = [
    {
      title: 'Navn',
      field: 'name',
      render: (shareRecord) => (
        <div className={classes.name}>
          {getName(shareRecord) ? getName(shareRecord) : '-'}
        </div>
      ),
    },
    {
      title: 'E-post',
      field: 'email',
      render: (shareRecord) => (
        <div className={classes.emailField}>{getEmail(shareRecord)}</div>
      ),
    },
    {
      title: 'Dato delt',
      field: 'sharedDate',
      render: (shareRecord) => (
        <div className={classes.dateField}>
          {formattedDate(getSharedDate(shareRecord))}
        </div>
      ),
    },
    {
      title: 'Delt av',
      field: 'shareEndDate',
      render: (shareRecord) => (
        <div className={classes.dateField}>{getSharedBy(shareRecord)}</div>
      ),
    },
    {
      title: 'Status',
      field: 'status',
      render: (shareRecord) => (
        <div className={classes.statusField}>
          {statusDiv(getStatus(shareRecord))}
        </div>
      ),
    },
    {
      cellStyle: {
        textAlign: 'center',
      },
      render: (shareRecord) => (
        <Tooltip title="Fjern tilgangen" placement="top" fontSize={15}>
          <IconButton
            className={classes.iconbutton}
            onClick={() => onDeleteShare(shareRecord)}
          >
            <DeleteIcon width="15px" height="15px" />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  if (fetching) {
    return (
      <>
        <br />
        <br />
        <TableLoading rows={5} columns={4} />
      </>
    );
  }

  return (
    <>
      <Table columns={columns} data={shareRecords} />
    </>
  );
};

export default withStyles(style)(DetailsTable);
