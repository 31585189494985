const styles = (theme) => ({
  dialogDesktop: {
    width: '500px',
  },
  dialogDesktopPadding: {
    width: '500px',
    padding: '10px 73px 20px 73px',
  },
  dialogPaddingMobile: {
    padding: '10px 15px 0px 15px',
  },
  error: {
    color: theme.palette.primary.redDark,
  },
  actionButtons: {
    padding: '10px 0 10px 0',
    borderTop: '1px solid #dedfdf',
  },
  actionButtonsMobile: {
    padding: '15px 0 30px 0',
    borderTop: '1px solid #dedfdf',
  },
  actionButtonsCenter: {
    justifyContent: 'center',
  },
  confirmCloseActions: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 60px !important',
  },
  confirmCloseButton: {
    width: '200px',
  },
  nextBtn: {
    marginRight: 45,
    width: '130px',
    height: '50px',
  },
  nextBtnMobile: {
    marginRight: 15,
    width: '130px',
    height: '50px',
  },
  backBtn: {
    marginLeft: 45,
    marginRight: 'auto',
    width: '130px',
    height: '50px',
  },
  backBtnMobile: {
    marginLeft: 15,
    marginRight: 'auto',
    width: '130px',
    height: '50px',
  },
});

export default styles;
