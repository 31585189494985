import React from 'react';

const DownloadOnDiscIcon = ({ width = '18px', height = '16px', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.53125 4.83594L7.21094 7.15625C7.14062 7.22656 7.07031 7.25 7 7.25C6.90625 7.25 6.85938 7.22656 6.8125 7.15625L4.49219 4.83594C4.39844 4.76562 4.375 4.67188 4.42188 4.55469C4.46875 4.4375 4.53906 4.36719 4.67969 4.36719H6.25V0.78125C6.25 0.710938 6.27344 0.640625 6.32031 0.59375C6.36719 0.546875 6.4375 0.5 6.53125 0.5H7.46875C7.53906 0.5 7.60938 0.546875 7.65625 0.59375C7.70312 0.640625 7.75 0.710938 7.75 0.78125V4.36719H9.32031C9.4375 4.36719 9.53125 4.4375 9.57812 4.55469C9.625 4.67188 9.60156 4.76562 9.53125 4.83594ZM12.625 8.75H9.8125L9.0625 10.25H4.9375L4.1875 8.75H1.375V11.375H12.625V8.75ZM13.75 8.79688V11.375C13.75 11.7031 13.6328 11.9609 13.4219 12.1719C13.1875 12.4062 12.9297 12.5 12.625 12.5H1.375C1.04688 12.5 0.789062 12.4062 0.578125 12.1719C0.34375 11.9609 0.25 11.7031 0.25 11.375V8.79688C0.25 8.51562 0.34375 8.28125 0.53125 8.04688L2.92188 5.375C2.96875 5.32812 3.01562 5.25781 3.08594 5.21094C3.13281 5.16406 3.20312 5.1875 3.27344 5.23438L3.85938 5.82031C3.88281 5.84375 3.90625 5.86719 3.90625 5.91406C3.90625 5.96094 3.88281 5.98438 3.85938 6.00781L2.42969 7.625H4.89062L5.64062 9.125H8.35938L9.10938 7.625H11.5703L10.1406 6.00781C10.0703 5.96094 10.0703 5.89062 10.1406 5.82031L10.7266 5.23438C10.7734 5.1875 10.8203 5.16406 10.8906 5.21094C10.9609 5.25781 11.0312 5.32812 11.0781 5.375L13.4688 8.04688C13.6562 8.28125 13.75 8.51562 13.75 8.79688Z"
      fill="#111111"
    />
  </svg>
);
export default DownloadOnDiscIcon;
