import React from 'react';
import { withWidth } from '@material-ui/core';
import Box from '../atomic/Box';
import Grid from '../atomic/Grid';
import CompanyItem from './CompanyItem';
import Typography from '../atomic/Typography';
import Link from '../atomic/Link';
import { gridOptions } from '../../utils/grid';
import GridItemLoading from '../GridItemLoading';
import { getCompanyId } from '../../utils/companies';
import useDesktop from '../../hooks/useDesktop';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';
import FindCompanies from '../../assets/images/find-professionals.png';
import Button from '../atomic/Button';
import { useCompanyListStyles } from './styles';

const CompanyList = ({
  width,
  fetching,
  companies,
  searchQuery,
  itemsPerLine: passedItemsPerLine,
  showEmptyStatePlaceholder = false,
  requestFrom,
  ...props
}) => {
  const { itemsPerLine } =
    passedItemsPerLine || gridOptions[width] || gridOptions.md;
  const isDesktop = useDesktop();
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const findProfessionalsUrl = `/more/find-professional`;

  const styles = useCompanyListStyles();

  const imageSection = (className) => {
    return (
      <div className={className}>
        <img
          alt="Sjekk din håndverker"
          src={FindCompanies}
          className={styles.image}
        />
      </div>
    );
  };

  if (fetching || !Array.isArray(companies)) {
    return (
      <Box py={2} mx={-2}>
        <Grid container {...props}>
          {[...Array(itemsPerLine).keys()].map((itemKey) => (
            <GridItemLoading key={`company-loading-${itemKey}`} />
          ))}
        </Grid>
      </Box>
    );
  }

  if (Array.isArray(companies) && companies.length === 0) {
    if (showEmptyStatePlaceholder) {
      return (
        <div>
          {!isDesktop && imageSection(styles.mobileImageContainer)}
          <div
            className={
              isDesktop
                ? styles.contentContainer
                : styles.contentContainerMobile
            }
          >
            <div
              className={
                isDesktop ? styles.textWrapper : styles.textWrapperMobile
              }
            >
              <p>
                Alle bedrifter som bruker Boligmappa er kontrollert for
                fagkompetanse, økonomi og selskapsform. Det betyr at du kan være
                trygg på at dokumentasjonen du mottar i Boligmappa kommer fra
                seriøse virksomheter.
              </p>
            </div>

            {isDesktop && imageSection(styles.imageContainer)}

            <div
              className={
                isDesktop ? styles.buttonWrapper : styles.buttonWrapperMobile
              }
            >
              <Link
                to={`${dashboardLinkPrefix}${findProfessionalsUrl}`}
                underline={false}
                inheritColor
              >
                <Button
                  color="primary"
                  variant="contained"
                  className={styles.findProfessionalButton}
                >
                  Finn håndverker
                </Button>
              </Link>
            </div>
          </div>
        </div>
      );
    }
    return (
      <Box textAlign="center" py={2}>
        <Typography variant="h3" color="textSecondary">
          Ingen boligens fagfolk funnet.
        </Typography>
      </Box>
    );
  }

  return (
    <Box py={2} paddingBottom="0px">
      <Grid container {...props}>
        {companies.map((company) => (
          <CompanyItem
            company={company}
            searchQuery={searchQuery}
            key={`company-item-${getCompanyId(company)}`}
            requestFrom={requestFrom}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default withWidth()(CompanyList);
