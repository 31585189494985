import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import RoomItem from './RoomItem';
import Box from '../../atomic/Box';
import Grid from '../../atomic/Grid';
import Typography from '../../atomic/Typography';
import { compareAlphabetically } from '../../../utils/strings';
import { getRoomId, getRoomName, getFloorLabel } from '../../../utils/rooms';
import useDesktop from '../../../hooks/useDesktop';

const FloorGroup = ({
  floor,
  rooms,
  searchQuery,
  onRoomUpdated,
  enableImageUpload,
}) => {
  const isDesktop = useDesktop();

  const sortedRooms = (rooms || []).sort((a, b) =>
    compareAlphabetically(getRoomName(a), getRoomName(b))
  );

  return (
    <Box>
      <Box mb={2} ml={!isDesktop ? 2 : 0}>
        <Typography variant="h2">{getFloorLabel(floor)}</Typography>
      </Box>

      <Box ml={isDesktop ? -2 : 0}>
        <Grid container spacing={isDesktop ? 6 : 0}>
          {sortedRooms.map((room) => (
            <RoomItem
              room={room}
              searchQuery={searchQuery}
              onRoomUpdated={onRoomUpdated}
              key={`room-item-${getRoomId(room)}`}
              enableImageUpload={enableImageUpload}
            />
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(FloorGroup);
