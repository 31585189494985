const style = (theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '20px 0',
    borderBottom: `1px solid ${theme.colors.whiteDarker}`,
  },
  label: {
    fontWeight: 700,
    margin: '0 0 3px',
  },
  date: {
    fontWeight: 400,
    margin: 'unset',
    color: theme.palette.text.secondary,
  },
});

export default style;
