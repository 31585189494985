const styles = () => ({
  wrapperActionBtn: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionBtnRoomOverlay: {
    position: 'absolute',
    bottom: 0,
    transform: 'translate(-50%)',
    left: '50%',
    width: '100%',
    borderTop: '1px #dedfdf solid',
    background: 'white',
    '& div': {
      marginTop: '15px',
      marginBottom: '15px',
    },
  },
});

export default styles;
