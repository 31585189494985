import styled from 'styled-components';

export const StyledCTA = styled.div`
  display: flex;
  background: #fdf3da;
  border-radius: 8px;
  min-height: 270px;

  h2,
  h3,
  p {
    margin: 0;
    color: #000;
  }

  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }

  .text_section {
    display: flex;
    padding: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    max-width: 50%;
    @media (max-width: 767px) {
      max-width: 100%;
    }

    .new_batch {
      display: flex;
      padding: 2px 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: #5dc9a2;

      .new_text {
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
      }
    }

    .button_section {
      display: flex;
      .early_bird {
        display: flex;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: unset;
        border-radius: 50px;
        background: #164144;
        color: #fff;
        margin-right: 16px;
        font-size: 14px;
        cursor: pointer;
      }
      .learn_more {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        cursor: pointer;
        line-height: 16px;
        text-decoration: unset;

        &.underline {
          text-decoration: underline;
        }

        p {
          padding-top: 10px;
        }
      }
    }
  }
  .image_section {
    max-width: 50%;
    width: 100%;
    overflow: hidden;
    border-radius: 0 8px 8px 0;
    position: relative;
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 100%;
    }
    @media (max-width: 767px) {
      width: 100%;
      height: 128px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: unset;
      max-width: unset;
      border-radius: 8px 8px 0 0;
      img {
        width: 100%;
        height: auto;
        position: unset;
        display: unset;
        transform: unset;
      }
    }
  }
`;

export const StyledBannerCTA = styled.div`
  display: flex;
  background: #fdf3da;
  border-radius: 8px;
  min-height: 90px;
  padding: 16px 24px;
  align-items: center;

  h2,
  h3,
  p {
    margin: 0;
    color: #000;
    font-size: 16px;
  }
  .banner_content {
    flex-grow: 1;
    p {
      color: #717171;
    }
  }
  .banner_content_action {
    border-radius: 50px;
    background: #164144;
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #fff;
    font-size: 14px;
    height: 40px;
    cursor: pointer;
  }
  .learn_more {
    text-decoration: unset;
  }
  .boligmappa-plus-logo {
    width: 110px;
  }
`;
