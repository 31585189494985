import React from 'react';

const Bin = ({ width = '1.2rem', height = '1.2rem', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16.5 18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      transform="translate(-228.761 -3422.875)"
      d="M242.6,3428.5H231.421a.385.385,0,0,0-.277.12.375.375,0,0,0-.1.286l.965,10.6a1.5,1.5,0,0,0,1.494,1.365h7.01a1.5,1.5,0,0,0,1.494-1.365l.964-10.6a.371.371,0,0,0-.374-.405Zm-6.9,9.75a.563.563,0,0,1-1.125,0v-6.75a.562.562,0,0,1,1.125,0Zm3.188.563a.565.565,0,0,0,.563-.562v-6.75a.563.563,0,0,0-1.125,0v6.75A.565.565,0,0,0,238.888,3438.813Z"
    />
    <path
      fillRule="evenodd"
      transform="translate(-228 -3421)"
      d="M240.188,3424h3.563a.75.75,0,0,1,0,1.5h-15a.75.75,0,0,1,0-1.5h3.562a.212.212,0,0,0,.133-.052.192.192,0,0,0,.055-.135v-.938a1.875,1.875,0,0,1,1.875-1.875h3.75a1.875,1.875,0,0,1,1.875,1.875v.938A.187.187,0,0,0,240.188,3424ZM234,3422.875v.938a.187.187,0,0,0,.188.187h4.125a.186.186,0,0,0,.187-.187v-.938a.373.373,0,0,0-.375-.375h-3.75A.373.373,0,0,0,234,3422.875Z"
    />
  </svg>
);

export default Bin;
