import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { withStyles } from '@material-ui/core';
import Typography from '../../../../../components/atomic/Typography';
import Button from '../../../../../components/atomic/Button';
import style from './style';
import CheckCircle from '../../../../../assets/icons/CheckCircle';

const NewsLetterSubscribedDialog = ({ open, onClose, classes }) => (
  <Dialog
    open={open}
    onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    }}
  >
    <DialogContent className={classes.dialogContent}>
      <div className={classes.dialog}>
        <CheckCircle className={classes.checkCircle} />
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.dialogTitle}
        >
          Du er påmeldt nyhetsbrevet!
        </Typography>
      </div>
      <Typography variant="body" className={classes.dialogDescription}>
        Takk for at du ønsker å holde deg oppdatert. <br />
        Du kan når som helst melde deg av.
      </Typography>
      <div style={{ marginTop: '20px' }}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={onClose}
        >
          Gå videre til Boligmappa
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);

export default withStyles(style)(NewsLetterSubscribedDialog);
