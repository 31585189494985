export const addToNestedObject = (items, breadcrumbs, newValue) => {
  const current = items.find((item) => item.id === breadcrumbs[0].id);

  if (!current) return items;

  if (breadcrumbs.length === 1) {
    current.directories = [newValue, ...current.directories];
  } else {
    current.directories = addToNestedObject(
      current.directories,
      breadcrumbs.slice(1),
      newValue
    );
  }

  return items.map((item) => (item.id === current.id ? current : item));
};

export const removeFromNestedObject = (items, breadcrumbs, valueToRemove) => {
  const current = items.find((item) => item.id === breadcrumbs[0].id);

  if (!current) return items;

  if (breadcrumbs.length === 1) {
    current.directories = current.directories.filter(
      (el) => el.id !== valueToRemove.id
    );
  } else {
    current.directories = removeFromNestedObject(
      current.directories,
      breadcrumbs.slice(1),
      valueToRemove
    );
  }

  return items.map((item) => (item.id === current.id ? current : item));
};
