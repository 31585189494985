import { useEffect } from 'react';

const useOutsideAlerter = (ref, onMenuClose, showNewEntity) => {
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target) && !showNewEntity) {
      onMenuClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, showNewEntity, onMenuClose]);
};

export default useOutsideAlerter;
