export const borderWidth = 5;

export const iconSize = (border = 0) => ({
  width: 36 + border,
  height: 36 + border,
});

const styles = (theme) => ({
  root: {
    color: theme.palette.primary.darkGreen,
    background: theme.palette.primary.biege,
    border: `2px dashed ${theme.palette.primary.darkGreen}`,
    borderRadius: '20px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  imageContainer: {
    width: 140,
    background: '#fff',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  iconContainer: {
    left: -18,
    bottom: 0,
    borderRadius: '100%',
    position: 'absolute',
    background: theme.palette.primary.redLighter,
    border: `${borderWidth}px solid ${theme.palette.primary.biege}`,
    ...iconSize(borderWidth),
  },
  addIcon: {
    right: borderWidth,
    bottom: borderWidth,
    position: 'relative',
    ...iconSize(borderWidth),
  },
});

export default styles;
