import React from 'react';
import MaterialTable from 'material-table';
import ArrowSortUp from '../../assets/icons/ArrowSortUp';

export const defaultOptions = {
  search: false,
  paging: false,
  toolbar: false,
  showTitle: false,
  showSelectAllCheckbox: false,
  showFirstLastPageButtons: false,
  showEmptyDataSourceMessage: false,
};

export const defaultComponents = {
  Container: (props) => <div {...props} />,
};

export const defaultIcons = {
  SortArrow: ArrowSortUp,
};

const Table = ({ icons, options, components, ...props }) => (
  <MaterialTable
    icons={{
      ...defaultIcons,
      ...icons,
    }}
    components={{
      ...defaultComponents,
      ...components,
    }}
    options={{
      ...defaultOptions,
      ...options,
      headerStyle: {
        paddingLeft: '0',
        fontWeight: '800',
        fontSize: '13px',
      },
      cellStyle: {
        padding: '8px 8px 8px 0',
      },
    }}
    {...props}
  />
);

export default Table;
