import {
  ADD_BYBF_ROOM,
  SET_BYBF_ROOMS,
  CLEAR_BYBF_ROOMS,
  DELETE_BYBF_ROOM,
  UPDATE_BYBF_ROOM,
  DELETE_BYBF_ROOM_ORDER_AND_TYPE,
  SET_BYBF_ROOMS_ACTIVE_STEP,
  SET_BYBF_ROOMS_FLOW_COMPLETED,
  SET_BYBF_ROOMS_CREATED,
} from '../reducers/bybfRooms';

export const acBYBFSetRooms = (rooms) => ({
  type: SET_BYBF_ROOMS,
  payload: rooms,
});

export const acBYBFClearRooms = () => ({
  type: CLEAR_BYBF_ROOMS,
});

export const acBYBFAddRoom = (room) => ({
  type: ADD_BYBF_ROOM,
  payload: room,
});

export const acBYBFDeleteRoom = (roomId) => ({
  type: DELETE_BYBF_ROOM,
  payload: { roomId },
});

export const acBYBFDeleteRoomByCodeAndOrder = (code, order) => ({
  type: DELETE_BYBF_ROOM_ORDER_AND_TYPE,
  payload: { code, order },
});

export const acBYBFUpdateRoom = (room) => ({
  type: UPDATE_BYBF_ROOM,
  payload: room,
});

export const acBYBFRoomsSetActiveStep = (step) => ({
  type: SET_BYBF_ROOMS_ACTIVE_STEP,
  payload: step,
});

export const acBYBFRoomsFlowCompleted = (status) => ({
  type: SET_BYBF_ROOMS_FLOW_COMPLETED,
  payload: status,
});

export const acBYBFRoomsRoomsCreated = (status) => ({
  type: SET_BYBF_ROOMS_CREATED,
  payload: status,
});
