const styles = () => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    margin: '10px auto',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    width: '544px',
    marginLeft: '25px',
    '@media (max-width: 959px)': {
      width: '100%',
      marginLeft: '0px',
    },
  },
  description: {
    marginBottom: '10px',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '24px',
  },
  title: {
    alignSelf: 'flex-start',
  },
  buttonRow: {
    alignSelf: 'flex-start',
  },
  logo: {
    width: '48px',
    height: '48px',
    alignSelf: 'flex-end',
  },
  button: {
    width: '127px',
    height: '40px',
    fontSize: '14px',
  },
  buttonIcon: {
    color: 'white',
  },
});

export default styles;
