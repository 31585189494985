import { useEffect, useState } from 'react';

const useFetchEffect = ({
  apiFetchFunction,
  dependencies = [],
  onInit = () => true,
  conditionFunction = () => true,
  transformResponse = (response) => response,

  setFetchingFunction = () => {},
  setFetchingErrorFunction = () => {},

  defaultDataState = null,
  defaultFetchingState = false,
  defaultFetchingErrorState = null,

  onFetchStart = () => {},
  onFetchComplete = () => {},
  onSuccess = (data) => data,
  onError = (error) => error,
}) => {
  const [data, setData] = useState(defaultDataState);
  const [fetching, setFetching] = useState(defaultFetchingState);
  const [fetchingError, setFetchingError] = useState(defaultFetchingErrorState);

  const fetchData = async () => {
    onFetchStart();

    setData(null);
    setFetching(true);
    setFetchingError(null);

    setFetchingError(null);
    setFetchingFunction(true);

    const [error, response] = await apiFetchFunction();

    if (!error && response) {
      onSuccess(response);
      setData(transformResponse(response));
    } else {
      onError(error);
      setFetchingError(error);
      setFetchingErrorFunction(error);
    }

    onFetchComplete();
    setFetching(false);
    setFetchingFunction(false);
  };

  useEffect(() => {
    if (onInit() !== false && Boolean(conditionFunction(dependencies)) === true)
      fetchData();
  }, dependencies);

  return {
    data,
    setData,
    fetching,
    fetchData,
    fetchingError,
  };
};

export default useFetchEffect;
