import { map } from 'lodash-es';
import { compareAlphabetically } from './strings';
import { imageToBase64, rotateImage } from './files';

export const uploadImage = async ({
  onError,
  onSuccess,
  imageFile,
  imagePosition,
  uploadRequest,
}) => {
  try {
    const rotatedImage = await rotateImage(imageFile);
    const [error, response] = await uploadRequest(rotatedImage, imagePosition);

    if (!error && response) {
      const [, base64] = await imageToBase64(rotatedImage);

      onSuccess({ response, base64, rotatedImage, position: imagePosition });
    } else {
      onError(error);
    }
  } catch (error) {
    onError(error);
  }
};

export const uploadImages = ({
  imageFiles,
  onComplete,
  positionStart,
  uploadRequest,
  onError = () => {},
  onImageUploaded = () => {},
}) => {
  const uploadedImages = [];

  const promises = map(imageFiles, (imageFile, index) =>
    uploadImage({
      onError,
      imageFile,
      uploadRequest,
      imagePosition: index + positionStart + 1,
      onSuccess: ({ response, base64, rotatedImage, position }) => {
        const uploadedImage = {
          position,
          response,
          url: base64,
          id: response.id,
          image: rotatedImage,
        };

        uploadedImages.push(uploadedImage);

        onImageUploaded(uploadedImages.length);
      },
    })
  );

  Promise.all(promises).then(() => onComplete(uploadedImages));
};

export const manualUploadImage = async ({
  onError,
  onSuccess,
  imageFile,
  imagePosition,
}) => {
  try {
    const rotatedImage = await rotateImage(imageFile);
    const [, base64] = await imageToBase64(rotatedImage);
    onSuccess({
      response: null,
      base64,
      rotatedImage,
      position: imagePosition,
    });
  } catch (error) {
    onError(error);
  }
};

export const manualUploadImages = ({
  imageFiles,
  onComplete,
  positionStart,
  onError = () => {},
  onImageUploaded = () => {},
}) => {
  const pendingUploadedImages = [];

  const promises = map(imageFiles, (imageFile, index) =>
    manualUploadImage({
      onError,
      imageFile,
      imagePosition: index + positionStart + 1,
      onSuccess: ({ response, base64, rotatedImage, position }) => {
        const image = {
          position,
          response,
          url: base64,
          id: -1,
          image: rotatedImage,
        };

        pendingUploadedImages.push(image);

        onImageUploaded(pendingUploadedImages.length);
      },
    })
  );

  Promise.all(promises).then(() => onComplete(pendingUploadedImages));
};

export const sortImagesByPosition = (image1, image2) =>
  compareAlphabetically(image1.position, image2.position);
