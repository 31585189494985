import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import {
  defaultExperimentBlock,
  experimentTemplateData,
  otovoIframeUrl,
} from './util';
import Topic from './Components/Topic';
import styles from './style';
import MoreDescription from './Components/MoreDescription';
import Description from './Components/Description';
import useDesktop from '../../../../hooks/useDesktop';
import Page from '../../../../components/layouts/WithHeader';
import Link from '../../../../components/atomic/Link';
import { sGetUser } from '../../../../store/reducers/auth';
import { acSetAuthInfo } from '../../../../store/actions/auth';
import { acSetUnitFeatures } from '../../../../store/actions/unitFeatures';

const ExperimentTemplate = ({ classes }) => {
  const { experimentSlug } = useParams();
  const [data, setData] = useState(defaultExperimentBlock);

  useEffect(() => {
    setData(
      experimentTemplateData.filter(
        (value) => value.experimentSlug === experimentSlug
      )?.[0] || defaultExperimentBlock
    );
  }, [experimentSlug]);

  useEffect(() => {
    if (experimentSlug === 'otovo') {
      if (document.getElementById('otovoScript')) {
        setTimeout(() => {
          const searchBars = [
            ...document.getElementsByClassName('otovo-address-search'),
          ];
          searchBars.forEach((value) => {
            const elementComponent = value;
            elementComponent.src = otovoIframeUrl;
          });
        }, 0);
      } else {
        const script = document.createElement('script');
        script.setAttribute('id', 'otovoScript');
        script.src = 'https://solar.otovo.com/js/search.js';
        script.async = true;
        document.body.appendChild(script);
      }
    }
  }, [experimentSlug]);
  const isDesktop = useDesktop();

  return (
    <Page
      hideHeader={isDesktop}
      minFullHeight
      showBackButton={!isDesktop}
      maxWidth="lg"
      lessMarginLeft={isDesktop}
      headerTitle={data.menuItem.title}
    >
      <div className={classes.componentWrapper}>
        {data?.title && <Topic title={data.title} />}
        {data?.commonExperimentComponent}
        {data?.description && (
          <Description
            description={data.description}
            descriptionList={data.descriptionList}
          />
        )}
        {data?.learnMoreLink?.link &&
          data?.learnMoreLink?.location === 'AFTER_DESCRIPTION' && (
            <div className={classes.learnMoreLinkWrapper}>
              <Link to={data?.learnMoreLink?.link} isExternal openInNewTab>
                {data?.learnMoreLink?.text}
              </Link>
            </div>
          )}
        {data?.moreDescriptionTopic && (
          <div className={classes.moreDescriptionTopicWrapper}>
            <Topic title={data.moreDescriptionTopic} />
          </div>
        )}
        {data?.moreDescription && (
          <MoreDescription
            moreDescriptionQuestions={data.moreDescriptionQuestions}
            moreDescription={data.moreDescription}
          />
        )}
        {data?.learnMoreLink?.link &&
          data?.learnMoreLink?.location === 'AFTER_MORE_DESCRIPTION' && (
            <div className={classes.learnMoreLinkWrapper}>
              <Link to={data?.learnMoreLink?.link} isExternal openInNewTab>
                {data?.learnMoreLink?.text}
              </Link>
            </div>
          )}
        {data?.commonExperimentComponentTitle && (
          <div className={classes.secondHeaderTitle}>
            <Topic title={data.commonExperimentComponentTitle} />
          </div>
        )}
        <div className={data.secondCommonExperimentComponent}>
          {data?.commonExperimentComponent}
        </div>
      </div>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  user: sGetUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  setUnitFeatures: (unitFeatures) => dispatch(acSetUnitFeatures(unitFeatures)),
  setAuthInfo: (user) => dispatch(acSetAuthInfo(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ExperimentTemplate));
