import React from 'react';

function EnergyMarkIcon({
  width = 'auto',
  height = '23px',
  color = '#FF5733',
  letter = 'a',
  ...props
}) {
  const getEnergyMarkLetter = () => {
    switch (letter.toUpperCase()) {
      case 'A':
        return (
          <path
            d="M4710 5464c-46-131-206-588-356-1014l-272-775 360-3c198-1 518-1 712 0l353 3-353 1005c-194 553-355 1009-357 1014s-41-98-87-230z M2412 8337L0 5925V0h9640v5935L7232 8343l-2407 2407-2413-2413zm3001-1724c96-242 1900-4840 1905-4856 4-16-25-17-549-15l-554 3-192 510c-105 281-201 536-213 568l-21 57H3794l-178-467c-98-258-196-514-218-570l-39-103H2328l4 22c3 13 427 1105 943 2428 515 1323 946 2429 958 2458l20 52h1125l35-87z"
            transform="matrix(.1 0 0 -.1 0 1075)"
          />
        );
      case 'B':
        return (
          <path d="M483 1s.5 0 .871.305A2719721.824 2719721.824 0 01960.687 478.1c1.524 1.524 2.96 3.136 4.402 5.182-.055 2.64-.087 4.806-.087 6.971C965 685.503 965 880.751 965 1076H1c0-197.361 0-394.723.267-592.73C159.697 324.463 317.86 166.3 476.02 8.133 478.37 5.78 480.674 3.38 483 1m73.379 895.892c12.189-3.289 24.663-5.783 36.512-10.022 30.976-11.081 56.034-29.77 71.62-59.5 11.563-22.055 15.467-45.883 15.298-70.51-.142-20.6-5.294-39.856-16.525-57.243-18.52-28.671-45.745-44.275-78.364-51.545-5.825-1.298-11.759-2.113-17.642-3.15 2.76-1.483 5.483-2.141 8.196-2.838 2.739-.703 5.502-1.335 8.196-2.184 33.217-10.475 58.904-29.837 72.866-62.617 8.503-19.966 9.856-40.932 7.437-62.27-4.533-39.977-23.77-70.044-59.994-88.497-29.493-15.025-61.344-19.707-93.898-19.927-57.323-.386-114.65-.107-171.976-.092-1.76 0-3.521.167-5.17.25v494.42h6.398c55.16 0 110.321.319 165.476-.192 16.914-.157 33.806-2.588 51.57-4.083z M965.089 483.283c.037-.475.493-.466.702-.375C966 680.667 966 878.333 965.5 1076c-.5-195.249-.5-390.497-.498-585.746 0-2.165.032-4.33.087-6.971z M471 823h-38.697V680.65c.845-.15 1.783-.472 2.718-.46 21.98.249 43.972.17 65.934.946 13.669.484 27.046 3.313 39.81 8.605 24.311 10.081 36.704 28.76 39.14 54.438 1.693 17.863-.376 34.988-12.253 49.522-10.937 13.384-25.875 20.025-42.12 24.166-17.724 4.52-35.832 5.41-54.532 5.133zM470 606.167h-37.697V485.318c.52-.137 1.122-.444 1.72-.434 22.639.354 45.295.336 67.91 1.273 12.666.524 25.03 3.564 36.401 9.6 19.351 10.271 26.671 27.394 27.163 48.268.62 26.362-11.973 44.486-37.51 53.966-18.554 6.888-37.917 8.316-57.987 8.176z" />
        );
      case 'C':
        return (
          <path d="M965 1076H1c0-197.361 0-394.723.267-592.728C160.874 323.286 320.215 163.944 479.551 4.598c1.175-1.174 2.3-2.397 3.449-3.598 0 0 .5 0 .867.306A2884253.074 2884253.074 0 01959.63 477.041c1.877 1.877 3.665 3.843 5.459 6.242a436.894 436.894 0 00-.086 7.971C965 686.169 965 881.084 965 1076M373.787 448.281c-39.133 28.848-65.27 66.962-79.79 113.081-16.201 51.453-19.108 103.987-9.974 156.978 13.976 81.082 58.834 137.87 135.532 168.759 41.542 16.73 85.141 22.292 129.53 22.644 26.706.211 53.145-3.139 79.345-8.229 11.561-2.246 23.066-4.781 34.978-7.264l-4.272-83.175c-36.709 17.295-105.98 25.305-146.217 16.956-49.364-10.244-87.21-36.455-109.348-81.954-28.105-57.761-28.174-117.205-3.068-175.919 18.734-43.81 51.613-74.029 97.885-86.752 52.263-14.37 103.194-8.698 151.708 16.074 1.707.872 3.407 1.759 5.518 2.85 2.332-27.553 4.66-54.389 6.699-81.246.096-1.26-2.13-3.39-3.7-3.98-23.472-8.825-47.817-14.105-72.671-16.975-36.853-4.255-73.49-2.273-109.832 4.915-36.825 7.284-71.144 20.662-102.323 43.237z M965.089 483.283c.036-.474.498-.49.704-.386C966 680.667 966 878.333 965.5 1076c-.5-194.915-.5-389.83-.497-584.746 0-2.499.032-4.997.086-7.97z" />
        );
      case 'D':
        return (
          <path d="M965 1076H1c0-197.361 0-394.723.267-592.73C159.697 324.463 317.86 166.3 476.02 8.134 478.371 5.78 480.674 3.379 483 1c0 0 .5 0 .87.305A2725942.797 2725942.797 0 01960.687 478.1c1.525 1.524 2.962 3.136 4.402 5.182a289.75 289.75 0 00-.086 6.472C965 685.17 965 880.585 965 1076M477.741 899.147c31.305-2.503 62.045-7.935 91.772-18.269 71.854-24.98 118.17-73.627 135.94-148.127 11.323-47.47 11.556-95.402 3.157-143.31-5.785-32.999-17.58-63.757-37.596-90.99-25.014-34.035-58.66-56.143-97.874-70.36-44.06-15.972-89.94-21.088-136.426-21.52-45.819-.424-91.644-.092-137.467-.092h-5.669v494.48c1.564.083 2.721.197 3.878.197 45.157.01 90.314.17 135.468-.08 14.634-.081 29.262-1.235 44.817-1.93z M965.088 483.283c.038-.475.494-.466.703-.375C966 680.667 966 878.333 965.5 1076c-.5-195.415-.5-390.83-.498-586.245 0-2 .032-3.998.086-6.472z M392.487 749V485.35c1.081-.21 1.842-.486 2.603-.486 20.162-.015 40.346-.609 60.482.117 71.574 2.583 132.027 51.407 149.032 121.106 11.579 47.455 7.958 93.84-16.68 136.842-25.633 44.74-65.047 71.017-115.895 77.753-25.931 3.436-52.47 2.278-79.542 3.224V749z" />
        );
      case 'E':
        return (
          <path d="M965 1076H1c0-197.695 0-395.39.332-593.621 4.192-4.18 8.143-7.731 11.897-11.48 30.675-30.623 61.313-61.283 91.961-91.932l62.254-62.254 64.978-64.978 63.065-63.065c22.637-22.636 45.31-45.235 67.898-67.921 18.372-18.453 36.56-37.09 54.974-55.498 19.829-19.824 39.84-39.464 59.726-59.23C479.578 4.535 480.704 2.681 482 1c.444 0 .889 0 1.776.31 158.681 158.547 316.92 316.784 475.154 475.027 2.11 2.112 4.132 4.313 6.153 6.947-.055 2.64-.08 4.805-.08 6.97L965 1076M343.837 821.5v79.305h293v-77.952H443.321V684.22h175.734v-78.14h-175.74V484.632H635.56V406.83H343.837V821.5z M965.083 483.284c.043-.474.505-.494.71-.389C966 680.667 966 878.333 965.5 1076c-.5-195.248-.5-390.497-.497-585.745 0-2.166.025-4.332.08-6.971z" />
        );
      case 'F':
        return (
          <path d="M965 1076H1c0-197.361 0-394.723.268-592.751 9.252-9.64 18.266-18.58 27.211-27.59 20.23-20.377 40.35-40.865 60.657-61.164 22.723-22.714 45.68-45.194 68.395-67.916 25.335-25.343 50.481-50.875 75.766-76.268a20960.482 20960.482 0 0163.96-63.959c22.444-22.345 45.036-44.541 67.434-66.932 20.307-20.3 40.379-40.835 60.667-61.153C444.496 39.1 463.78 20.083 483 1c0 0 .5 0 .867.306A2887323.168 2887323.168 0 01959.63 477.041c1.877 1.878 3.665 3.843 5.459 6.242a435.884 435.884 0 00-.087 7.971C965 686.169 965 881.084 965 1076M367.835 597.5V900.87h99.258V684.346h166.751v-78.257H467.23V484.676h175.182v-77.853H367.835V597.5z M965.089 483.283c.036-.474.498-.49.704-.386C966 680.667 966 878.333 965.5 1076c-.5-194.915-.5-389.83-.498-584.746 0-2.499.033-4.997.087-7.97z" />
        );
      case 'G':
        return (
          <path d="M965 1076H1c0-197.361 0-394.723.27-592.724C118.482 365.69 235.43 248.75 352.363 131.791 395.93 88.215 439.456 44.598 483 1c0 0 .5 0 .865.307a3039910.01 3039910.01 0 01477.18 477.15c1.406 1.405 2.722 2.9 4.042 4.828a484.25 484.25 0 00-.085 8.47C965 686.503 965 881.252 965 1076M583.119 479.158c28.725 4.205 56.746 11.003 83.409 23.07.245-.847.461-1.297.497-1.76 2-25.562 3.907-51.132 6.05-76.682.339-4.032-.684-5.688-4.766-6.863-42.507-12.241-85.912-18.153-130.067-18.909-40.705-.696-80.841 3.21-119.672 16.171C347.6 437.875 298 484.124 273.464 555.216c-19.899 57.654-22.405 116.884-9.41 176.325 16.043 73.391 59.306 124.98 128.736 153.815 56.85 23.611 116.431 27.747 177.03 22.118 38.377-3.564 75.84-12.11 113.085-21.742 4.22-1.09 5.363-2.79 5.356-7.023-.129-85.165-.096-170.33-.096-255.496v-5.278H488.587v78.113h104.14v128.64c-32.192 6.89-64.432 9.446-96.844 4.2-46.872-7.588-83.98-30.735-108.956-71.66-21.546-35.305-28.393-74.175-26.241-114.979 1.61-30.543 8.857-59.598 24.65-85.977 30.293-50.596 76.872-74.054 134.108-79.276 21.014-1.917 41.918-.445 63.675 2.162z M965.087 483.285c.038-.475.498-.483.705-.384C966 680.667 966 878.333 965.5 1076c-.5-194.748-.5-389.497-.498-584.245 0-2.665.03-5.33.085-8.47z" />
        );
      default:
        return (
          <path
            d="M4710 5464c-46-131-206-588-356-1014l-272-775 360-3c198-1 518-1 712 0l353 3-353 1005c-194 553-355 1009-357 1014s-41-98-87-230z M2412 8337L0 5925V0h9640v5935L7232 8343l-2407 2407-2413-2413zm3001-1724c96-242 1900-4840 1905-4856 4-16-25-17-549-15l-554 3-192 510c-105 281-201 536-213 568l-21 57H3794l-178-467c-98-258-196-514-218-570l-39-103H2328l4 22c3 13 427 1105 943 2428 515 1323 946 2429 958 2458l20 52h1125l35-87z"
            transform="matrix(.1 0 0 -.1 0 1075)"
          />
        );
    }
  };

  const getColorCode = () => {
    switch (color?.toLowerCase()) {
      case 'dark green':
        return '#018134';
      case 'light green':
        return '#89ba22';
      case 'yellow':
        return '#eecd28';
      case 'orange':
        return '#ec7519';
      case 'red':
        return '#c5201e';
      default:
        return color;
    }
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1"
      viewBox="0 0 965 1075"
      {...props}
    >
      <g fill={getColorCode()}>{getEnergyMarkLetter()}</g>
    </svg>
  );
}

export default EnergyMarkIcon;
