import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import React, { createElement, useState, useEffect } from 'react';

import {
  eventModes,
  getEventDescription,
  getEventDocuments,
  getEventImages,
  getEventName,
} from '../../../../../../utils/events';

import EventActionsMenu from './EventActions';
import EventContextMenu from './EventContextMenu';
import Box from '../../../../../../components/atomic/Box';
import useDesktop from '../../../../../../hooks/useDesktop';
import Paper from '../../../../../../components/atomic/Paper';
import EventDetails from '../../../../../../modules/EventDetails';
import { getDocumentId } from '../../../../../../utils/documents';
import Page from '../../../../../../components/layouts/WithHeader';
import ImageGallery from '../../../../../../components/ImageGallery';
import {
  sGetEvents,
  sGetShowEditEvent,
} from '../../../../../../store/reducers/events';
import { sortImagesByPosition } from '../../../../../../utils/images';
import {
  acDeleteEvent,
  acSetEventMode,
  acSetShowEditEvent,
} from '../../../../../../store/actions/events';
import Typography from '../../../../../../components/atomic/Typography';
import ErrorContainer from '../../../../../../components/ErrorContainer';
import { padding } from '../../../../../../components/atomic/Paper/style';
import EventDescription from '../../../../../../modules/EventDescription';
import { acSyncRoomsEvents } from '../../../../../../store/actions/rooms';
import { sGetDocuments } from '../../../../../../store/reducers/documents';
import {
  acDeleteDocument,
  acSetDocuments,
} from '../../../../../../store/actions/documents';
import { updateEvent as apiUpdateEvent } from '../../../../../../api/events';
import ContentLoader from '../../../../../../components/loaders/ContentLoader';
import AttachDocumentMenu from '../../../../../../components/AttachDocumentMenu';
import {
  getDocumentList,
  getDocumentTable,
} from '../../../../../../utils/DocumentTable';
import matomo from '../../../../../../utils/matomo';
import { fetchDocuments as apiFetchDocuments } from '../../../../../../api/properties';
import useFetchEffect from '../../../../../../hooks/useFetchEffect';

const Show = ({
  event,
  events,
  fetching,
  deleteEvent,
  fetchingError,
  deleteDocument,
  onEventUpdated,
  storedDocuments,
  syncRoomsEvents,
  setShowEditEvent,
  setEventMode,
  setStoredDocuments,
}) => {
  const history = useHistory();
  const isDesktop = useDesktop();
  const { eventId } = useParams();

  const { boligmappaNumber } = useParams();

  const [eventMenuAnchorEl, setEventMenuAnchorEl] = useState(null);
  const [documentMenuAnchorEl, setDocumentMenuAnchorEl] = useState(null);

  const [uploadedImageIndex, setUploadedImageIndex] = useState(null);
  const [mainContextMenuOpen, setMainContextMenuOpen] = useState(false);
  const [attachDocumentMenuOpen, setAttachDocumentMenuOpen] = useState(false);
  const currentEvent = events?.filter((e) => e.id === event.id)[0];
  const images = (getEventImages(event) || []).sort(sortImagesByPosition);

  const showImageGallery =
    fetching || (!fetching && Array.isArray(images) && images.length > 0);

  const [documents, setDocuments] = useState(getEventDocuments(event));

  // const showDocumentList = fetching || (!fetching && documents && documents.length > 0);

  const headerTitle = fetching ? 'Hendelse' : getEventName(event);

  useFetchEffect({
    onSuccess: (data) => setStoredDocuments(data),
    conditionFunction: () => !storedDocuments && !fetching,
    apiFetchFunction: () =>
      apiFetchDocuments(boligmappaNumber, { includeTaggedInfo: true }),
  });

  const onDocumentDeleted = (document) => {
    onEventUpdated({
      ...event,
      documents: getEventDocuments(event || []).filter(
        (eventDocument) =>
          getDocumentId(eventDocument) !== getDocumentId(document)
      ),
    });

    if (Array.isArray(storedDocuments)) {
      deleteDocument(document);
    }
  };

  useEffect(() => {
    if (!documents) {
      setDocuments(getEventDocuments(event));
    }
  }, [event]);

  useEffect(() => {
    if (storedDocuments && currentEvent) {
      setDocuments(
        storedDocuments?.filter((doc) =>
          getEventDocuments(currentEvent)?.some(
            (selectedDoc) => selectedDoc.id === doc.id
          )
        )
      );
    }
  }, [storedDocuments, currentEvent]);

  const onEventDeleted = () => {
    // Only delete event from store if there are events loaded.
    // Otherwise updated event list will be fetched when needed.
    if (events) {
      deleteEvent(eventId);
    }

    syncRoomsEvents({
      eventId,
      roomIds: [],
    });

    history.goBack();
  };

  const onImagesUploaded = (uploadedImages) => {
    const newIndex = (getEventImages(event) || []).length;

    matomo.clickEvent({
      category: 'Events',
      action: 'Add Image to Event',
      name: 'Add Image from Event',
      value: Math.abs(uploadedImages?.length - newIndex),
    });
    onEventUpdated({
      ...event,
      gallery: [...(getEventImages(event) || []), ...uploadedImages],
    });

    setUploadedImageIndex(newIndex);
  };

  const renderDocumentList = () =>
    createElement(isDesktop ? getDocumentTable() : getDocumentList(), {
      fetching,
      documents,
      onDocumentDeleted,
      isFiltersSticky: false,
      showOnPaper: false,
      error: fetchingError,
      showBulkSelectionOptions: true,
    });

  const onEventMenuClick = (domEvent) => {
    setMainContextMenuOpen(true);
    setEventMenuAnchorEl(domEvent.currentTarget);
  };

  const onDocumentMenuClick = (domEvent) => {
    setAttachDocumentMenuOpen(true);
    setDocumentMenuAnchorEl(domEvent.currentTarget);
  };

  const onConnectRoomClick = () => {
    setEventMode(eventModes.room);
    setShowEditEvent(true);
  };

  const onEditEvent = () => {
    matomo.clickEvent({
      category: 'Events',
      action: 'Edit Event',
      name: 'Edit Event Details',
    });
    setEventMode(eventModes.event);
    setShowEditEvent(true);
  };

  const onEditImage = () => {
    matomo.clickEvent({
      category: 'Events',
      action: 'Edit Event',
      name: 'Edit Event Images',
    });
  };

  const onImagesUpdated = async (imagesToUpdate, onSuccess, onError) => {
    const currentImages = images.slice();

    onEventUpdated({
      ...event,
      gallery: imagesToUpdate.map((image, index) => ({
        ...image,
        position: index + 1,
      })),
    });

    const [error, response] = await apiUpdateEvent(eventId, {
      imageIds: imagesToUpdate.map((image) => image.id),
    });

    if (onSuccess && !error && response) {
      onSuccess();
    }

    if (onError && error && !response) {
      onError(error);
    }

    if (error && !response) {
      onEventUpdated({
        ...event,
        gallery: currentImages,
      });
    }
  };

  if (fetchingError) {
    return <ErrorContainer errorResponse={fetchingError} />;
  }

  return (
    <Page
      noPaddingTop
      minFullHeight
      noPadding={!isDesktop}
      headerTitle={headerTitle}
      subPageHeader
      onMenuIconClick={onEventMenuClick}
      lessMarginLeft={isDesktop}
      maxWidth="lg"
    >
      {showImageGallery && (
        <ImageGallery
          editable
          images={images}
          fetching={fetching}
          activeIndex={uploadedImageIndex}
          onImagesUpdated={onImagesUpdated}
        />
      )}

      <EventActionsMenu
        event={event}
        onImagesUploaded={onImagesUploaded}
        onDocumentIconClick={onDocumentMenuClick}
      />

      {(fetching || getEventDescription(event)) && (
        <>
          <br />
          <Paper visible={!isDesktop}>
            <EventDescription event={event} fetching={fetching} />
          </Paper>
        </>
      )}

      <>
        <br />
        <Paper visible={!isDesktop}>
          <Typography variant="h3">
            {fetching ? (
              <ContentLoader height={20} width={110} />
            ) : (
              'Dokumenter'
            )}
          </Typography>

          <Box mx={`-${padding}px`}>{renderDocumentList()}</Box>
        </Paper>
      </>

      <br />
      <Paper visible={!isDesktop}>
        <EventDetails
          event={event}
          fetching={fetching}
          onConnectRoomClick={onConnectRoomClick}
        />
      </Paper>

      <EventContextMenu
        eventId={eventId}
        onEditImage={onEditImage}
        open={mainContextMenuOpen}
        anchorEl={eventMenuAnchorEl}
        eventName={getEventName(event)}
        onEventDeleted={onEventDeleted}
        setOpen={setMainContextMenuOpen}
        onEditEvent={onEditEvent}
      />
      <AttachDocumentMenu
        event={event}
        open={attachDocumentMenuOpen}
        anchorEl={documentMenuAnchorEl}
        setOpen={setAttachDocumentMenuOpen}
        setShowEditEvent={setShowEditEvent}
        setEventMode={setEventMode}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  events: sGetEvents(state),
  storedDocuments: sGetDocuments(state),
  showEditEvent: sGetShowEditEvent(state),
});

const mapDispatchToProps = (dispatch) => ({
  deleteEvent: (eventId) => dispatch(acDeleteEvent(eventId)),
  deleteDocument: (document) => dispatch(acDeleteDocument(document)),
  syncRoomsEvents: (payload) => dispatch(acSyncRoomsEvents(payload)),
  setShowEditEvent: (payload) => dispatch(acSetShowEditEvent(payload)),
  setEventMode: (payload) => dispatch(acSetEventMode(payload)),
  setStoredDocuments: (documents) => dispatch(acSetDocuments(documents)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Show);
