import clsx from 'clsx';
import React, { createElement } from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Button from '../atomic/Button';
import Typography from '../atomic/Typography';
import ConnectNewRoom from '../../assets/icons/ConnectNewRoom';
import ConnectExistingRoom from '../../assets/icons/ConnectExistingRoom';

const ConnectRoomButton = ({
  classes,
  className,
  newRoomIcon = false,
  children = 'Koble til rom',
  textColor = 'textSecondary',
  ...props
}) => (
  <Button
    variant="outlined"
    className={clsx(className, classes.button)}
    {...props}
  >
    <Typography component="span" color={textColor}>
      {createElement(newRoomIcon ? ConnectNewRoom : ConnectExistingRoom, {
        className: classes.icon,
      })}
      {children}
    </Typography>
  </Button>
);

export default withStyles(styles)(ConnectRoomButton);
