const startsWithExp = (path) => `^${path}`;
const cleanPaths = startsWithExp('/(authcode|properties)');

const getGenericUrl = (url) => {
  if (url) {
    const subPathList = url.split('/');
    if (
      subPathList.length > 0 &&
      // eslint-disable-next-line no-restricted-globals
      !isNaN(subPathList[subPathList.length - 1])
    ) {
      return `${subPathList.slice(0, subPathList.length - 1).join('/')}/{id}`;
    }
    return url;
  }
  return '';
};

export const getAnalyticsUrl = () => {
  if (!window || !window.location) {
    return undefined;
  }

  const { href, pathname } = window.location;
  /** If the path should be cleaned (remove query parameters),
   * we split the string and only return the part before ?
   */
  if (new RegExp(cleanPaths).test(pathname)) {
    return getGenericUrl(href.split('?')[0]);
  }
  /**
   * For any path that starts with /dashboard we need to remove the Boligmappanumber from the URL
   */
  if (pathname.startsWith('/dashboard')) {
    // Matches the word "/dashboard/" + anything after that until the next /
    const dashboardRegex = /\/dashboard\/[^/]+\//i;
    const newUrl = href.replace(
      dashboardRegex,
      '/dashboard/{boligmappanumber}/'
    );
    if (newUrl.includes('?')) return getGenericUrl(newUrl.split('?')[0]);
    return getGenericUrl(newUrl);
  }
  return getGenericUrl(href);
};

export const getAnalyticsTitle = (title) => {
  const { pathname } = window.location;
  const subPathList = pathname.split('/');
  if (
    subPathList.length > 0 &&
    // eslint-disable-next-line no-restricted-globals
    !isNaN(subPathList[subPathList.length - 1])
  ) {
    return `${title}-${subPathList[subPathList.length - 2]}-{id}`;
  }
  return `${title}-${subPathList[subPathList.length - 1]}`;
};
