import React from 'react';
import Sliders from '../../../../atomic/Slider';
import Box from '../../../../atomic/Box';
import Grid from '../../../../atomic/Grid';
import { useFindProfessionalsStyles } from '../../../findProfessionalStyles';
import Typography from '../../../../atomic/Typography';
import useDesktop from '../../../../../hooks/useDesktop';
import Alert from '../../../../atomic/Alert';
import LocationFilter from '../LocationFilter';

function DistanceFilter({
  onSelect,
  value,
  isLocationAvailable,
  selectedLocation,
  propertyLocationData,
  onLocationSelect,
}) {
  const classes = useFindProfessionalsStyles();
  const isDesktop = useDesktop();
  return (
    <>
      {isDesktop && (
        <LocationFilter
          onSelect={(locationValue) => onLocationSelect(locationValue)}
          selectedOption={selectedLocation}
          propertyLocations={propertyLocationData}
        />
      )}
      <Box sx={{ flexGrow: 1 }} p={2}>
        <Typography
          className={
            isDesktop
              ? classes.distancefilterheading
              : classes.distancefilterheadingMobile
          }
        >
          Avstand
        </Typography>
        <Grid
          container
          spacing={1}
          columns={{ xs: 1, sm: 8 }}
          className={
            isDesktop
              ? classes.distanceFilterCard
              : classes.distanceFilterCardMobile
          }
        >
          <Sliders
            onSelect={onSelect}
            value={value}
            disabled={!isLocationAvailable}
          />
        </Grid>
        {!isLocationAvailable && (
          <>
            <br />
            <Alert
              severity="info"
              sx={{
                backgroundColor: '#D9E9E9',
                color: 'black',
                width: '70%',
                '& .MuiAlert-icon': {
                  color: 'black',
                },
              }}
            >
              Du må velge posisjon for å kunne sette avstand fra deg.
            </Alert>
          </>
        )}
      </Box>
    </>
  );
}

export default DistanceFilter;
