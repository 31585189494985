import styled from 'styled-components';

export const StyledCTA = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 352px;
  background-color: #fbf3c1;
  border-radius: 4px;
  z-index: 1;

  position: absolute;
  right: 0;
  top: 141px;

  @media (max-width: 959px) {
    position: fixed;
    top: unset;
    bottom: 98px;
    max-width: calc(100% - 16px);
    right: 8px;
  }

  .content {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 32px;

    @media (max-width: 959px) {
      width: 352px;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  &.collapsed {
    padding: 16px 24px;
  }

  .hide {
    display: none !important;
  }

  .cta_headline {
    font-size: 20px;
    line-height: 31px;
    font-weight: 700;
    color: #111111;
    margin: 0;
  }

  .head_desc {
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
    margin: 0;
  }

  .learn_more {
    color: #111111;
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
    text-align: center;
  }

  .collapsed_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cta_headline {
      font-size: 18px;
      line-height: 28px;
      font-weight: 700;
      color: #111111;
    }

    p {
      font-size: 16px;
      line-height: 25px;
      font-weight: 500;
      margin: 0;
      color: #717171;
    }

    button {
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      color: #fff;
      background-color: #164144;
      border: unset;
      border-radius: 50px;
      padding: 12px 24px;
      cursor: pointer;
    }
  }

  .terms_text {
    font-size: 12px;
    line-height: 18.5px;
    font-weight: 400;
    color: #111111;
    text-align: center;
    margin: 0;
  }

  .terms_link {
    color: #111111;
  }

  .offerContent {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  .inputsWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
`;

export const StyledLabelCTA = styled.label`
  overflow: hidden;
  position: relative;

  .input_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    border: 2px solid #e6e6e6;
    border-radius: 16px;
    background-color: #fff;
    cursor: pointer;
  }

  &.active,
  &:focus {
    .input_content {
      background-color: #d7e4fd;
      border-color: #111111;
    }
  }

  p,
  input {
    margin: 0;
  }

  p,
  span {
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
  }

  span {
    color: #717171;
  }

  .inputBlock {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  input[type='radio'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: unset;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    outline: 2px solid #717171;
    cursor: pointer;
  }

  input[type='radio']:checked {
    background-color: #164144;
    border: 3px solid #d7e4fd;
    outline: 2px solid #164144;
  }

  input[type='radio']:focus-visible {
    outline-offset: 0px;
  }

  .price {
    display: flex;
    align-items: center;
  }
`;

export const StyledOffer = styled.div`
  position: absolute;
  top: 4px;
  right: -32px;
  background-color: #f7d160;
  font-size: 10px;
  line-height: 15.5px;
  font-weight: 700;
  color: #111111;
  transform: rotate(37deg);
  padding: 4px;
  width: 100px;
  text-align: center;
`;

export const StyledCTAButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
  font-size: 18.5px;
  letter-spacing: -0.2px;
  font-weight: 600;
  color: #fff;
  background-color: ${(props) =>
    props.$buttonColor ? props.$buttonColor : '#000'};
  border: unset;
  border-radius: 24px;
  padding: 12px 16px;
  transition: 0.3s;
  cursor: pointer;

  &:focus,
  &:active,
  &:hover {
    box-shadow: 0 0 5px 5px #fff;
  }
`;
