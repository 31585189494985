const styles = () => ({
  componentWrapper: {
    padding: '27px',
    '@media only screen and (max-width: 638px)': {
      marginRight: '0',
      padding: '0 16px 0 16px',
    },
    '& .otovoSecondExperimentCmp': {
      '& .primary': {
        display: 'none',
      },
    },
  },
  headerTitle: {
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '50px',
    color: '#254E52',
    '@media only screen and (max-width: 638px)': {
      fontSize: '20px',
    },
  },
  headerSubtitle: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '25px',
    color: '#8D8D8D',
  },
  descriptionComponentWrapper: {
    display: 'flex',
    marginTop: '40px',
    '@media only screen and (max-width: 638px)': {
      display: 'block',
      marginTop: '10px',
    },
  },
  descriptionTextWrapper: {
    marginTop: '40px',
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '31px',
    color: 'rgba(0, 0, 0, 0.8)',
  },
  iframeTitleWrapper: {
    display: 'block',
    marginTop: '32px',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '31px',
    color: 'rgba(0, 0, 0, 0.8)',
    '@media only screen and (max-width: 638px)': {
      marginTop: '24px',
      fontSize: '14px',
    },
  },
  iframeWrapper: {
    width: '100%',
    maxWidth: '500px',
    marginLeft: '-7px',
  },
  iframe: {
    border: '0px',
    overflow: 'hidden',
    width: '100%',
  },
  descriptionWrapper: {
    width: '50%',
    paddingRight: '33px',
    fontSize: '20px',
    '@media only screen and (max-width: 638px)': {
      width: '100%',
      paddingRight: '0px',
      fontSize: '14px',
    },
  },
  imageBannerWrapper: {
    position: 'relative',
    width: '50%',
    paddingLeft: '33px',
    '@media only screen and (max-width: 638px)': {
      marginTop: '40px',
      width: '100%',
      paddingLeft: '0px',
    },
    '&.overo': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  imageBanner: {
    width: '100%',
    maxWidth: '416px',
    '@media only screen and (max-width: 638px)': {
      width: '100%',
      height: '100%',
    },
  },
  moreDescriptionTopicWrapper: {
    marginTop: '80px',
    '& .topic-title': {
      fontSize: '32px',
    },
    '@media only screen and (max-width: 638px)': {
      marginTop: '0',
    },
  },
  moreDescriptionBlock: {
    display: 'block',
    marginTop: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '31px',
    color: '#000000',
    '@media only screen and (max-width: 638px)': {
      lineHeight: '22px',
      fontSize: '14px',
    },
  },
  MoreDescriptionQuestion: {
    fontWeight: 700,
  },
  secondDescription: {
    marginTop: '160px',
    '@media only screen and (max-width: 638px)': {
      marginTop: '80px',
    },
  },
  radonWrapper: {
    margin: '32px 0 32px 0',
    '@media only screen and (max-width: 638px)': {
      margin: '25px 0 25px 0',
    },
  },
  routeToAnotherPageButton: {
    width: '100%',
    padding: '12px 24px',
    display: 'block',
    marginTop: '56px',
    borderRadius: '50px',
    textAlign: 'center',
    backgroundColor: '#254E52',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#193639',
      textDecoration: 'none',
    },
    '&:focus': {
      outline: 'none',
      textDecoration: 'none',
    },
    '@media only screen and (max-width: 638px)': {
      marginTop: '25px',
    },
  },
  routeToAnotherPageButtonText: {
    fontWeight: '500',
    fontSize: '16px',
    '@media only screen and (max-width: 638px)': {
      fontSize: '14px',
    },
  },
  textDescription: {
    fontSize: '16px',
    '@media only screen and (max-width: 638px)': {
      fontSize: '14px',
    },
    '&.secondary': {
      marginTop: '32px',
    },
  },
  secondHeaderTitle: {
    marginTop: '80px',
    '@media only screen and (max-width: 638px)': {
      marginTop: '60px',
    },
  },
  learnMoreLinkWrapper: {
    fontSize: '16px',
    marginTop: '20px',
    '@media only screen and (max-width: 426px)': {
      fontSize: '14px',
    },
  },
  listItem: {
    fontSize: '20px',
    '@media only screen and (max-width: 638px)': {
      fontSize: '14px',
    },
  },
  overoInfoLinkWrapper: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: '400',
  },
  consentWrapper: {
    marginTop: '32px',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
    },
  },
  overoInfoLinkSection: {
    color: '#254E52',
    textDecoration: 'underline',
  },
  overoConsentError: {
    display: 'flex',
    justifyContent: 'center',
    color: '#D7394C',
    fontSize: '14px',
    margin: '56px 0 -48px 0',
    '@media only screen and (max-width: 638px)': {
      margin: '16px 0 -20px 0',
    },
  },
  iFreameDialogPaper: {
    backgroundColor: '#284447',
    color: 'white',
    '& .MuiDialogContent-root': {
      padding: 0,
      overflowX: 'hidden',
    },
  },
  iframeDialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 24px',
    '& .dialog-title-text': {
      fontSize: '20px',
    },
  },
});

export default styles;
