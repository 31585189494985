import { isBrowserEdge, isIosPlatform } from './dom';
import localStorage from './localStorage';
import {
  referralSuggestedKey,
  referralSuggestedDateKey,
  referralSuggestedDays,
} from '../config';

const dateDiff = (saved, current) => {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  return Math.round((current - saved) / (1000 * 60 * 60 * 24));
};

const setLocalStorage = (value) => {
  localStorage.set(referralSuggestedKey, JSON.stringify(value));
  localStorage.set(
    referralSuggestedDateKey,
    JSON.stringify(new Date().getTime())
  );
};

export const retrieveReferralSuggested = () =>
  localStorage.get(referralSuggestedKey)
    ? JSON.parse(localStorage.get(referralSuggestedKey))
    : null;

export const retrieveReferralSuggestedDate = () =>
  localStorage.get(referralSuggestedDateKey)
    ? JSON.parse(localStorage.get(referralSuggestedDateKey))
    : null;

export const storeReferralSuggested = (value) => {
  if (value) {
    setLocalStorage(value);
  } else {
    const obj = retrieveReferralSuggestedDate();
    if (!obj) {
      setLocalStorage(value);
    } else {
      const now = new Date().getTime().toString();
      if (dateDiff(obj, now) > referralSuggestedDays) {
        setLocalStorage(value);
      }
    }
  }
};

export const referralLink = 'https://www.boligmappa.no/anbefaling';

export const referralTitle = 'Tips en venn om Boligmappa';

export const isNativeSharingSupported = () =>
  !!navigator && !!navigator.share && !isBrowserEdge(false) && isIosPlatform();

export const nativeShare = async (url, text) => {
  try {
    await navigator.share({ url, text });
  } catch (err) {
    //
  }
};
