const styles = (theme) => ({
  header: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.colors.whiteDarker}`,
  },
  circular_progress_bar: {
    margin: '15px 30px 15px auto',
    width: 50,
  },
  circular_progress_bar_mobile: {
    margin: '15px 15px 15px auto',
    width: 50,
  },
  room_name: {
    marginLeft: 50,
    alignItems: 'center',
  },
  room_title: {
    color: theme.palette.primary.darkGreen,
    margin: '0.5rem 0 0 0',
    fontSize: '1.4rem',
  },
  room_sub_title: {
    fontSize: 13,
    fontWeight: 400,
  },
  image_galary_view: {
    margin: '20px 70px 0 70px',
  },
  room_edit_form: {
    margin: '0 70px 0 70px',
  },
  image_galary_view_mobile: {
    margin: '20px 10px 0 10px',
  },
  room_edit_form_mobile: {
    margin: '0 10px 0 10px',
  },
  formElement: {
    marginTop: 30,
    '&:last-child': {
      marginBottom: 20,
    },
  },
  formElementTitle: {
    marginTop: 10,
  },
  room_detail_footer: {
    display: 'flex',
    justifyContent: 'center',
  },
  delete_room_button: {
    backgroundColor: 'white',
    width: 170,
    height: 30,
    border: '1px solid',
    fontWeight: 'unset',
    color: '#707070',
    fill: '#707070',
    marginBottom: '30px',
  },
  error: {
    color: theme.palette.primary.redDark,
    marginTop: '20px',
  },
  dorpdown_support_text: {
    fontSize: '13px',
    fontWeight: 400,
    marginLeft: '13px',
  },
});

export default styles;
