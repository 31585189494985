import React from 'react';
import DotLoader from '../../../atomic/DotLoader';
import { useFindProfessionalsStyles } from '../../findProfessionalStyles';

const ProfessionalListLoading = ({ metaData }) => {
  const classes = useFindProfessionalsStyles();

  return (
    <div className={classes.loadingWrapper}>
      <div>
        {metaData.totalVisibleCount !== metaData.totalCount && (
          <div className={classes.dotLoader}>
            <DotLoader />
          </div>
        )}
        Viser {metaData.totalVisibleCount} av {metaData.totalCount}{' '}
        håndverksbedrifter
      </div>
    </div>
  );
};

export default ProfessionalListLoading;
