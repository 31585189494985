import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import {
  getCompanyPhone,
  getCompanyAddress,
  getCompanyFranchise,
  getContactPersonImg,
  getContactPersonName,
  getCompanyWebSiteUrl,
  getCompanyFacebookUrl,
  getContactPersonDesignation,
  getCompanyContactPersonEmail,
  getCompanyContactPersonPhone,
} from '../../utils/companies';

import styles from './style';
import WebIcon from '../../assets/icons/Web';
import List from '../../components/atomic/List';
import Link from '../../components/atomic/Link';
import Card from '../../components/atomic/Card';
import useDesktop from '../../hooks/useDesktop';
import PhoneIcon from '../../assets/icons/Phone';
import EmailIcon from '../../assets/icons/Email';
import Avatar from '../../components/atomic/Avatar';
import ActionList from '../../components/ActionList';
import { sGetUser } from '../../store/reducers/auth';
import FacebookIcon from '../../assets/icons/Facebook';
import Container from '../../components/atomic/Container';
import ListItem from '../../components/CardList/KeyValueListItem';
import ContentLoader from '../../components/loaders/ContentLoader';

const CompanyContacts = ({ company, classes, fetching, showTitle = true }) => {
  const isDesktop = useDesktop();

  const franchiseProfile = getCompanyFranchise(company);

  const phone =
    getCompanyContactPersonPhone(company) ||
    getCompanyPhone(company) ||
    getCompanyPhone(franchiseProfile);

  const email =
    getCompanyContactPersonEmail(company) ||
    getCompanyContactPersonEmail(franchiseProfile);

  const address =
    getCompanyAddress(company) || getCompanyAddress(franchiseProfile);

  const companyWeb =
    getCompanyWebSiteUrl(company) || getCompanyWebSiteUrl(franchiseProfile);

  const companyFacebookUrl =
    getCompanyFacebookUrl(company) || getCompanyFacebookUrl(franchiseProfile);

  const contactPersonDesignation =
    getContactPersonDesignation(company) ||
    getContactPersonDesignation(franchiseProfile);

  const contactPersonName =
    getContactPersonName(company) || getContactPersonName(franchiseProfile);

  const contactPersonImgUrl =
    getContactPersonImg(company) || getContactPersonImg(franchiseProfile);

  /* eslint-disable no-nested-ternary */
  const actions = [
    {
      text: `Ring`,
      icon: PhoneIcon,
      to: `tel:${phone}`,
      tag: isDesktop ? 'hidden' : phone ? null : 'hidden',
    },
    {
      icon: EmailIcon,
      text: 'Send e-post',
      to: `mailto:${email}`,
    },
    {
      icon: WebIcon,
      to: companyWeb,
      text: `Nettside`,
      tag: companyWeb ? null : 'hidden',
    },
    {
      icon: FacebookIcon,
      text: 'Facebookside',
      to: companyFacebookUrl,
      tag: companyFacebookUrl ? null : 'hidden',
    },
  ];
  /* eslint-enable no-nested-ternary */

  if (fetching) {
    return (
      <div>
        {showTitle && (
          <ContentLoader
            height={20}
            width={100}
            className={classes.titleLoader}
          />
        )}
        <ContentLoader
          width={90}
          height={20}
          className={classes.listItemLoader}
        />
        <ContentLoader
          width={80}
          height={20}
          className={classes.listItemLoader}
        />
        <ContentLoader
          width={90}
          height={20}
          className={classes.listItemLoader}
        />
        <ContentLoader
          width={140}
          height={20}
          className={classes.listItemLoader}
        />
      </div>
    );
  }

  return (
    <Card
      className={isDesktop ? classes.contactCard : classes.contactCardMobile}
    >
      <Container className={!isDesktop ? classes.containerMobile : null}>
        <div>{showTitle && <h3 className={classes.title}>Kontakt</h3>}</div>

        <div
          className={
            contactPersonName ? classes.personDetails : classes.disable
          }
        >
          <div
            className={
              contactPersonImgUrl ? classes.avatarArea : classes.disable
            }
          >
            <Avatar
              className={classes.avatarCircle}
              src={contactPersonImgUrl}
            />
          </div>
          <div className={classes.detailsArea}>
            <h4 className={classes.nameLine}>{contactPersonName}</h4>
            <p className={classes.designationLine}>
              {contactPersonDesignation}
            </p>
          </div>
        </div>

        <ActionList
          actions={actions.filter((action) => action.tag !== 'hidden')}
          viewState={!isDesktop}
        />
      </Container>
      {!isDesktop && address && <hr className={classes.lineMobileView} />}
      <div className={classes.otherDetails}>
        <List>
          {phone && isDesktop && (
            <ListItem label="Telefon">
              <Link
                component="a"
                href={`tel:${phone}`}
                className={classes.link}
                isExternal
              >
                {phone}
              </Link>
            </ListItem>
          )}

          {address && <ListItem label="Adresse">{address}</ListItem>}
        </List>
      </div>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  user: sGetUser(state),
});

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(CompanyContacts));
