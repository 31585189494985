import { areIdsEqual } from '../../utils/strings';
import { getDocumentId } from '../../utils/documents';
import {
  addToNestedObject,
  removeFromNestedObject,
} from '../../utils/reducersData';

export const defaultState = {
  fetching: false,
  personalDocuments: [],
  personalDocumentFolders: [],
  personalDocumentFoldersToRender: [],
  personalFoldersBreadCrumbs: [],
  fetchingError: null,
  searchText: null,
  multiplePersonalDocumentsSelected: false,
  selectedPersonalFolderId: null,
};

export const ADD_PERSONAL_DOCUMENT = 'ADD_PERSONAL_DOCUMENT';
export const ADD_PERSONAL_FOLDER = 'ADD_PERSONAL_FOLDER';
export const SET_PERSONAL_DOCUMENTS = 'SET_PERSONAL_DOCUMENTS';
export const SET_PERSONAL_DOCUMENT_FOLDERS = 'SET_PERSONAL_DOCUMENT_FOLDERS';
export const SET_PERSONAL_DOCUMENT_FOLDERS_TO_RENDER =
  'SET_PERSONAL_DOCUMENT_FOLDERS_TO_RENDER';
export const CLEAR_PERSONAL_DOCUMENTS = 'CLEAR_PERSONAL_DOCUMENTS';
export const UPDATE_PERSONAL_DOCUMENT = 'UPDATE_PERSONAL_DOCUMENT';
export const UPDATE_PERSONAL_DOCUMENT_FOLDER_TO_RENDER =
  'UPDATE_PERSONAL_DOCUMENT_FOLDER_TO_RENDER';
export const SET_PERSONAL_FOLDERS_BREADCRUMBS =
  'SET_PERSONAL_FOLDERS_BREADCRUMBS';
export const DELETE_PERSONAL_DOCUMENT = 'DELETE_PERSONAL_DOCUMENT';
export const SET_FETCHING_PERSONAL_DOCUMENTS =
  'SET_FETCHING_PERSONAL_DOCUMENTS';
export const SET_FETCHING_PERSONAL_DOCUMENTS_ERROR =
  'SET_FETCHING_PERSONAL_DOCUMENTS_ERROR';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_MULTIPLE_PERSONAL_DOCUMENTS_SELECTED =
  'SET_MULTIPLE_PERSONAL_DOCUMENTS_SELECTED';
export const DELETE_PERSONAL_DOCUMENT_FOLDER =
  'DELETE_PERSONAL_DOCUMENT_FOLDER';
export const SELECTED_PERSONAL_DOCUMENT_FOLDER_ID =
  'SELECTED_PERSONAL_DOCUMENT_FOLDER_ID';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_PERSONAL_DOCUMENTS:
      return {
        ...state,
        personalDocuments: action.payload,
      };
    case SET_PERSONAL_DOCUMENT_FOLDERS:
      return {
        ...state,
        personalDocumentFolders: action.payload,
      };
    case SET_PERSONAL_DOCUMENT_FOLDERS_TO_RENDER:
      return {
        ...state,
        personalDocumentFoldersToRender: action.payload,
      };
    case ADD_PERSONAL_DOCUMENT:
      return {
        ...state,
        personalDocuments: [...(state.personalDocuments || []), action.payload],
      };
    case ADD_PERSONAL_FOLDER:
      const { breadcrumbs, newFolder } = action.payload;
      const newState = JSON.parse(JSON.stringify(state));

      if (breadcrumbs.length > 0) {
        newState.personalDocumentFolders = addToNestedObject(
          newState.personalDocumentFolders,
          breadcrumbs,
          newFolder
        );
      } else {
        newState.personalDocumentFolders = [
          newFolder,
          ...newState.personalDocumentFolders,
        ];
      }

      return newState;

    case CLEAR_PERSONAL_DOCUMENTS:
      return defaultState;
    case DELETE_PERSONAL_DOCUMENT:
      return {
        ...state,
        personalDocuments: state.personalDocuments.filter(
          (document) =>
            !areIdsEqual(getDocumentId(document), getDocumentId(action.payload))
        ),
      };
    case UPDATE_PERSONAL_DOCUMENT:
      const updatedDocument = action.payload;

      return {
        ...state,
        personalDocuments: state.personalDocuments.map((document) =>
          areIdsEqual(getDocumentId(document), getDocumentId(updatedDocument))
            ? updatedDocument
            : document
        ),
      };
    case UPDATE_PERSONAL_DOCUMENT_FOLDER_TO_RENDER:
      const updatedDocumentFolderToRender = action.payload;

      return {
        ...state,
        personalDocumentFoldersToRender:
          state.personalDocumentFoldersToRender.map((folder) =>
            areIdsEqual(
              getDocumentId(folder),
              getDocumentId(updatedDocumentFolderToRender)
            )
              ? updatedDocumentFolderToRender
              : folder
          ),
      };
    case SET_PERSONAL_FOLDERS_BREADCRUMBS:
      return {
        ...state,
        personalFoldersBreadCrumbs: action.payload,
      };
    case SET_FETCHING_PERSONAL_DOCUMENTS:
      return {
        ...state,
        fetching: action.payload,
      };
    case SET_FETCHING_PERSONAL_DOCUMENTS_ERROR:
      return {
        ...state,
        fetchingError: action.payload,
      };
    case DELETE_PERSONAL_DOCUMENT_FOLDER:
      let deletionStateCopy = JSON.parse(
        JSON.stringify(state.personalDocumentFolders)
      );

      if (state.personalFoldersBreadCrumbs.length > 0) {
        deletionStateCopy = removeFromNestedObject(
          deletionStateCopy,
          state.personalFoldersBreadCrumbs,
          action.payload
        );
      } else {
        deletionStateCopy = deletionStateCopy.filter(
          (el) => el.id !== action.payload.id
        );
      }

      return { ...state, personalDocumentFolders: deletionStateCopy };

    case SELECTED_PERSONAL_DOCUMENT_FOLDER_ID:
      return {
        ...state,
        selectedPersonalFolderId: action.payload,
      };
    default:
      return state;
  }
};

export const sGetPersonalDocumentsModule = (state) => state.personalDocuments;
export const sGetPersonalDocuments = (state) =>
  sGetPersonalDocumentsModule(state).personalDocuments;

export const sGetPersonalDocumentFolders = (state) =>
  sGetPersonalDocumentsModule(state).personalDocumentFolders;

export const sGetPersonalDocumentFoldersToRender = (state) =>
  sGetPersonalDocumentsModule(state).personalDocumentFoldersToRender;

export const sGetPersonalFoldersBreadCrumbs = (state) =>
  sGetPersonalDocumentsModule(state).personalFoldersBreadCrumbs;

export const sGetFetchingPersonalDocuments = (state) =>
  sGetPersonalDocumentsModule(state).fetching;

export const sGetFetchingPersonalDocumentsError = (state) =>
  sGetPersonalDocumentsModule(state).fetchingError;

export const sGetPersonalDocumentSearchText = (state) =>
  sGetPersonalDocumentsModule(state).searchText;

export const sGetMultipePersonalDocumentsSelected = (state) =>
  sGetPersonalDocumentsModule(state).multiplePersonalDocumentsSelected;

export const sGetSelectedFolderId = (state) =>
  sGetPersonalDocumentsModule(state).selectedPersonalFolderId;
