import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import styles from './style';
import Box from '../../../atomic/Box';
import Menu from '../../../atomic/Menu';
import Divider from '../../../atomic/Divider';
import ListItem from '../../../CardList/ListItem';
import Typography from '../../../atomic/Typography';
import getSettingItems from '../../../../structure/settings';
import useLegacyAppRedirect from '../../../../hooks/useLegacyAppRedirect';
import useDashboardLinkPrefix from '../../../../hooks/useDashboardLinkPrefix';
import {
  getPropertyOwnerFullname,
  getPropertyOwnerSubscriptionStatus,
} from '../../../../utils/properties';
import ContentLoader from '../../../loaders/ContentLoader';
import ProfileIcon from '../../../../assets/icons/ProfileIcon';
import { sGetUnseenNotifications } from '../../../../store/reducers/notifications';
import BellSolid from '../../../../assets/icons/BellSolid';
import useDesktop from '../../../../hooks/useDesktop';
import BMPlusCircle from '../../../../assets/icons/BMPlusCircle';
import { sGetAuthLoaded, sGetUser } from '../../../../store/reducers/auth';

const Account = ({
  classes,
  propertyOwner,
  loaded,
  isFromPersonal,
  isNameHidden,
  ownedProperty,
  unseenNotifications,
}) => {
  const history = useHistory();
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const isDesktop = useDesktop();
  const { pathname } = useLocation();

  const { redirectToLegacyApp, redirectToPersonalDocuments } =
    useLegacyAppRedirect();

  const name = getPropertyOwnerFullname(propertyOwner);

  const isSubBoligmappaPlus = getPropertyOwnerSubscriptionStatus(propertyOwner);

  const [anchorEl, setAnchroEl] = useState(null);

  const handleClose = () => setAnchroEl(null);

  const onUsernameClick = (event) => setAnchroEl(event.currentTarget);

  const onItemClick = (passedOnClick, event) => {
    if (passedOnClick) {
      passedOnClick(event);
    }

    handleClose();
  };

  const sections = getSettingItems({
    isFromPersonal,
    history,
    dashboardLinkPrefix,
    redirectToLegacyApp,
    redirectToPersonalDocuments,
  }).filter(({ mobileOnly }) => !mobileOnly);
  return (
    <>
      <Typography
        component="div"
        fontWeight={300}
        color="textPrimary"
        onClick={onUsernameClick}
        className={clsx([classes.username, !isDesktop && 'mobile'])}
      >
        <Box className={classes.profileBlock}>
          {!loaded && !isNameHidden && (
            <ContentLoader height={20} width={150} />
          )}
          {loaded && !isNameHidden && <span>{name}</span>}
          <Box position="relative" className={classes.profileButton}>
            {!loaded ? (
              <ContentLoader height={30} width={30} variant="circle" />
            ) : (
              <>
                <ProfileIcon width={20} height={20} />
                {unseenNotifications.length > 0 ? (
                  <BellSolid className={classes.notificationIcon} />
                ) : (
                  isSubBoligmappaPlus && (
                    <BMPlusCircle className={classes.boligmappaPlusSign} />
                  )
                )}
              </>
            )}
          </Box>
        </Box>
      </Typography>

      <Menu
        keepMounted
        MenuListProps={{
          className: classes.menu,
        }}
        PopoverClasses={{ paper: classes.menuPaper }}
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        {sections.map(({ id, items }, index) => (
          <div key={`account-section-item-${id}`}>
            {index !== 0 && <Divider className={classes.divider} />}
            {items.map(
              ({
                text,
                onClick,
                className,
                notificationsBadge,
                hideInSharedProperty,
                hideInPersonalArea,
                ...props
              }) =>
                (!ownedProperty && hideInSharedProperty) ||
                pathname.includes('personal-area') === hideInPersonalArea || (
                  <ListItem
                    border={false}
                    className={clsx([classes.listItem, className])}
                    textClass={classes.listItemText}
                    key={`account-list-item-${text}`}
                    onClick={(event) => onItemClick(onClick, event)}
                    {...props}
                  >
                    {text}
                    {notificationsBadge && unseenNotifications.length > 0 && (
                      <p className={classes.notificationsBadge}>
                        {unseenNotifications.length}
                      </p>
                    )}
                  </ListItem>
                )
            )}
          </div>
        ))}
      </Menu>
    </>
  );
};

const mapStateToProps = (state) => ({
  propertyOwner: sGetUser(state),
  loaded: sGetAuthLoaded(state),
  unseenNotifications: sGetUnseenNotifications(state),
});

export default connect(mapStateToProps)(withStyles(styles)(Account));
