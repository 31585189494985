const styles = () => ({
  title: {
    margin: '10px 0 15px',
  },
  titleLoader: {
    margin: '10px 0 45px',
  },
  listItemLoading: {
    marginBottom: '45px',
    '&:last-child': {
      marginBottom: '30px',
    },
  },
});

export default styles;
