import React from 'react';

import Typography from '../../../../../../../../components/atomic/Typography';
import { useStyles } from './style';
import HjemlaIcon from '../../../../../../../../assets/images/hjemla.svg';

export function EstimateTitleWithPopup() {
  const classes = useStyles();

  return (
    <Typography variant="h2" className={classes.hjemlaEstimateTitle}>
      Verdiestimat fra{' '}
      <img alt="" className={classes.hjemlaEstimateLogo} src={HjemlaIcon} />
    </Typography>
  );
}
