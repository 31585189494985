import React, { useEffect, useRef, useState } from 'react';
import {
  StyledBreadCrumbs,
  StyledMenu,
} from '@componentsV2/BreadCrumbs/styles';
import MenuItem from '../../components/atomic/MenuItem';
import BackArrow from '../../assets/icons/DocumentBulkEditIcons/BackArrow';
import MenuHorizontal from '../../assets/icons/MenuHorizontal';

const BreadCrumbs = ({
  breadCrumbs,
  breadCrumbsNames,
  setBreadCrumbs,
  onlyBack = false,
  pathClassName,
}) => {
  const [isDialog, setIsDialog] = useState(false);
  const menuHorizontalRef = useRef(null);

  const onRootFolder = () => {
    setBreadCrumbs([]);
  };
  const onPreviousFolder = () => {
    setBreadCrumbs(breadCrumbs.slice(0, -1));
  };

  useEffect(() => {
    setIsDialog(false);
  }, [breadCrumbs]);
  return (
    <StyledBreadCrumbs ref={menuHorizontalRef} className={pathClassName}>
      {breadCrumbs.length === 1 || onlyBack ? (
        <div className="column">
          <button
            className="backButton"
            type="button"
            aria-label="back"
            onClick={onPreviousFolder}
          >
            <BackArrow />
          </button>
        </div>
      ) : (
        <div className="column">
          <button
            className="menu_button"
            type="button"
            aria-label="back"
            onClick={() => setIsDialog(true)}
          >
            <MenuHorizontal />
          </button>
          <StyledMenu
            open={isDialog}
            PopoverClasses={{ paper: 'paper' }}
            MenuListProps={{ className: 'menuList' }}
            anchorEl={menuHorizontalRef.current}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            onClose={() => setIsDialog(false)}
          >
            <MenuItem className="menuItem">
              <button type="button" onClick={onRootFolder}>
                Alle dokumenter
              </button>
            </MenuItem>
            <MenuItem className="menuItem">
              <button type="button" onClick={onPreviousFolder}>
                {breadCrumbsNames[breadCrumbsNames.length - 2]}
              </button>
            </MenuItem>
          </StyledMenu>
        </div>
      )}
      <div className="column divider">/</div>
      <div className="column lastName">{breadCrumbsNames.slice(-1)}</div>
    </StyledBreadCrumbs>
  );
};

export default BreadCrumbs;
