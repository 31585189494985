import React from 'react';
import styles from './featureCard.module.css';

export function FeatureCardImage({ feature }) {
  return (
    <div className={styles.imageContainer}>
      <img alt={feature.title} src={feature.image} className={styles.image} />
    </div>
  );
}
