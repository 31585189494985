import React, { useRef, useState } from 'react';
import clsx from 'clsx';

import useOutsideAlerter from '@hooksV2/useOutsideAlerter';

import { StyledEditableTitle } from '@componentsV2/EditableTitle/styles';
import EditPencil from '../../assets/icons/EditPencil';
import Check from '../../assets/icons/Check';
import CloseBold from '../../assets/icons/DocumentIcons/CloseBold';

const EditableTitle = ({
  title = '',
  onChangeTitle,
  overlay = false,
  disabled = false,
  overlayBackground = '#fff',
  children,
}) => {
  const [tempTitle, setTempTitle] = useState(title);
  const [isEditing, setIsEditing] = useState(false);

  const editBlock = useRef(null);
  const inputRef = useRef(null);

  const onEdit = () => {
    setIsEditing(true);
    inputRef.current.focus();
  };

  const onSave = () => {
    if (onChangeTitle && tempTitle.length > 0) {
      onChangeTitle(tempTitle);
    }
    setIsEditing(false);
  };

  const onCancel = () => {
    setTempTitle(title);
    setIsEditing(false);
  };

  const handleKeydown = (e) => {
    if (e.key === 'Escape') {
      onCancel();
    }
    if (e.key === 'Enter') {
      onSave();
    }
  };

  useOutsideAlerter(editBlock, onCancel);

  return (
    <StyledEditableTitle
      ref={editBlock}
      $isEditing={isEditing}
      $overlay={overlay}
      $overlayBackground={overlayBackground}
    >
      <div className="edit_name_wrapper">
        <div className={clsx(['edit_block', isEditing && 'show'])}>
          <input
            ref={inputRef}
            className="edit_name_input"
            value={tempTitle}
            onChange={(e) => setTempTitle(e.target.value)}
            onKeyPress={handleKeydown}
          />
          <div className="edit_hint_block">
            <div className="hint_icon_block close">
              <CloseBold width={5} height={5} fill="#fff" />
            </div>
            <p>esc</p>
            <div className="hint_icon_block save">
              <Check fill="#fff" />
            </div>
            <p>enter</p>
          </div>
        </div>
        <p className={clsx(['doc_title', !isEditing && 'show'])}>{children}</p>
        {!disabled && (
          <button
            aria-label="Edit pencil"
            type="button"
            className={clsx(['edit_button', !isEditing && 'show'])}
            onClick={onEdit}
          >
            <EditPencil width={15} height={15} />
          </button>
        )}
        <div className={clsx(['edit_control_buttons', isEditing && 'show'])}>
          <button
            className="edit_action close"
            type="button"
            onClick={onCancel}
            aria-label="close"
          >
            <CloseBold
              className="close_edit_icon"
              width={10}
              height={10}
              fill="#000"
            />
          </button>
          <button
            className="edit_action save"
            type="button"
            onClick={onSave}
            aria-label="Save edit"
          >
            <Check className="save_edit_icon" />
          </button>
        </div>
      </div>
    </StyledEditableTitle>
  );
};

export default EditableTitle;
