import React from 'react';
import Box from '../../../atomic/Box';
import Typography from '../../../atomic/Typography';
import { areFiltersEmpty } from '../../../../utils/documents';
import BaseSelectedFilters from '../../../DocumentFilters/SelectedFilters';

const SelectedFilters = ({ filters, ...props }) => (
  <Box display="flex" alignItems="center">
    {!areFiltersEmpty(filters) && (
      <Box px={1}>
        <Typography>
          <small>Valgte filter:</small>
        </Typography>
      </Box>
    )}
    <BaseSelectedFilters filters={filters} {...props} />
  </Box>
);

export default SelectedFilters;
