import React, { useState } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import clsx from 'clsx';

import Form from '../../../../../components/forms/BaseForm';
import TextField from '../../../../../components/atomic/TextField';
import Typography from '../../../../../components/atomic/Typography';
import style from './style';

const ShareForm = ({
  classes,
  onSubmit,
  onClose,
  requestInProgress,
  requestError,
  isDesktop,
}) => {
  const [email, setEmail] = useState('');
  return (
    <Grid container>
      <Grid item xs={12}>
        <div>
          {isDesktop && (
            <div>
              <Typography variant="h1">Legg til administrator</Typography>
            </div>
          )}

          <div className={isDesktop && classes.textAreaDesktop}>
            <p>
              Når du gir personer tilgang til din boligmappa vil den du gir
              tilgang kunne gjøre endringer, legge til og slette dokumenter,
              hendelser, rom m.m. i boligmappen. Det mest vanlige er deling
              mellom familiemedlemmer eller leietagere. Ved eierskifte, vil
              deling av boligmappa nullstilles.
            </p>
          </div>
          <div>
            <p>
              <strong className={classes.useCaseTitle}>Ny administrator</strong>
              <br />
              <sub>Oppgi administrator sin e-postadresse.</sub>
            </p>
          </div>
          <Form
            onSubmit={() => {
              onSubmit({ email });
            }}
            onCancel={onClose}
            submitButtonText="Gi tilgang"
            className={clsx(classes.form, classes.adminShareForm)}
            requestInProgress={requestInProgress}
            error={requestError}
            leftActions
          >
            <TextField
              label="E-postadresse"
              placeholder="Eksempel: ola@normann.no"
              value={email}
              className={classes.textField}
              onChange={(e) => setEmail(e.target.value)}
            />
            <p>
              <sub>
                Mottaker vil motta en unik lenke på epost. Lenken er aktiv i 30
                dager, dersom tiden løper ut må du gi tilgangen på nytt.
              </sub>
            </p>
          </Form>
        </div>
      </Grid>
    </Grid>
  );
};
export default withStyles(style)(ShareForm);
