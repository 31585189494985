const styles = (theme) => ({
  mobileRoot: {
    marginRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '&:last-child': {
      marginRight: 0,
    },
  },
  mobileTitle: {
    color: theme.palette.primary.white,
  },
  propertysalescardtab: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    padding: '0px',
    marginLeft: '25%',
    marginRight: '15%',
  },
  propertysalescardmobile: {
    '@media only screen and (min-width: 425px) and (max-width: 455px)': {
      marginLeft: '12%',
    },
    '@media only screen and (min-width: 456px) and (max-width: 555px)': {
      marginLeft: '16%',
      paddingtop: '0px',
    },
    '@media only screen and (min-width: 560px) and (max-width: 780px)': {
      marginLeft: '26%',
      paddingtop: '0px',
    },
    '@media only screen and (min-width: 780px) and (max-width: 790px)': {
      marginLeft: '26%',
      paddingtop: '0px',
    },
    '@media only screen and (min-width: 380px) and (max-width: 425px)': {
      marginLeft: '11%',
      paddingtop: '0px',
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '0px',
    marginTop: '16%',
  },

  propertysalesdescriptiontab: {
    marginLeft: '6%',
    textAlign: 'center',
    marginRight: '10%',
  },
  featurecardimagetab: {
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    padding: '0',
    marginRight: '8%',
    marginLeft: '6%',
  },
  featurecardimagemobile: {
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    padding: '0',
  },
  imageDesktop: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  actionBtn: {
    fontSize: '15px',
    fontWeight: 400,
    height: '30px',
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.white,
    },
  },
  gradientOverlay: {
    padding: '1.5rem 1rem',
    background:
      'linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.7) 100%)',
    height: '50%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  descDesktop: {
    fontSize: '14px',
    lineHeight: '1.5',
    fontWeight: 300,
    textAlign: 'left',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});

export default styles;
