import React from 'react';
import { useHistory } from 'react-router';

import Box from '../../../../../components/atomic/Box';
import useDesktop from '../../../../../hooks/useDesktop';
import FileViewer from '../../../../../components/FileViewer';
import Page from '../../../../../components/layouts/WithHeader';
import Typography from '../../../../../components/atomic/Typography';
import NavigationBackButton from '../../../../../components/NavigationBackButton';
import chapterDocumentation from '../../../../../assets/pdf/chapterDocumentation.pdf';

const ChapterOne = () => {
  const history = useHistory();
  const isDesktop = useDesktop();

  const onBackClick = () => history.goBack();

  return (
    <Page
      minFullHeight
      hideHeader={isDesktop}
      headerTitle="Generell brukerveiledning bolig"
      lessMarginLeft={isDesktop}
      maxWidth="lg"
    >
      {isDesktop && (
        <>
          <Box mb={4}>
            <NavigationBackButton
              mb={2}
              onClick={onBackClick}
              title="Tilbake til kapitteloversikten"
            />

            <Typography style={{ fontSize: '38px', fontWeight: 700 }}>
              Generell brukerveiledning bolig.pdf
            </Typography>
          </Box>
        </>
      )}

      <FileViewer fileType="pdf" filePath={chapterDocumentation} />
    </Page>
  );
};

export default ChapterOne;
