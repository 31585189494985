import ShareImage from '../assets/images/share.png';
import RequestImage from '../assets/images/request.png';
import RegisterImage from '../assets/images/register.png';
import PublicShareImage from '../assets/images/public-share.png';
import FindProfesionalsImage from '../assets/images/find-professionals-cta.png';

export const features = [
  {
    code: 'MISSING_DOCUMENTS',
    privatePropertyOnly: false,
    renderTitle: ({ isDesktop }) =>
      isDesktop ? 'Mangler du dokumentasjon?' : 'Mangler du noe?',
    image: RequestImage,
    buttonLink: 'create-mdr',
    buttonText: 'Etterspør',
    description:
      'Dokumentasjon fra håndverkeren er et verdipapir. Vi hjelper deg å få tak i det.',
    descriptionMobile:
      'Dokumentasjon fra håndverkeren er et verdipapir. Vi hjelper deg å få tak i det.',
    gaEventTxt: 'Missing / Request Documentation',
  },
  {
    code: 'FIND_PROFESSIONALS',
    privatePropertyOnly: false,
    title: 'Finn håndverker',
    image: FindProfesionalsImage,
    buttonLink: 'more/find-professional',
    buttonText: 'Sjekk',
    description:
      'Søk i vårt register over kontrollerte håndverkere. Etterspør dokumentasjon, eller be om tilbud.',
    descriptionMobile:
      'Søk i vårt register over kontrollerte håndverkere. Etterspør dokumentasjon, eller be om tilbud.',
    gaEventTxt: 'Register Work / New Event',
  },
  {
    code: 'UPLOAD_DOCUMENTS',
    privatePropertyOnly: false,
    title: 'Last opp boligens verdipapirer',
    image: RegisterImage,
    buttonLink: '',
    buttonText: 'Last opp',
    description:
      'Våtromssertifikat og kontroll av elektrisk anlegg er eksempler på dokumentasjon som bør lastes opp.',
    descriptionMobile:
      'Våtromssertifikat og kontroll av elektrisk anlegg er eksempler på dokumentasjon som bør lastes opp.',
    gaEventTxt: 'Upload Documents',
  },
  {
    code: 'SURVEYOR_SHARE',
    privatePropertyOnly: true,
    title: 'Del mappa med takstmann',
    image: PublicShareImage,
    buttonLink: 'more/share',
    buttonText: 'Del',
    description:
      'Har takstmann bedt om innsyn? Her kan du enkelt dele boligens tilstand med takstmann.',
    descriptionMobile:
      'Har takstmann bedt om innsyn? Her kan du enkelt dele boligens tilstand med takstmann.',
    gaEventTxt: 'Share with surveyor',
  },
  {
    code: 'PRIVATE_SHARE',
    privatePropertyOnly: true,
    title: 'Del din boligmappe',
    image: ShareImage,
    buttonLink: 'more/private-share',
    buttonText: 'Del',
    description:
      'La interessenter få innsyn i din boligmappe, for eksempel boligkjøpere som ønsker å sjekke ut boligens tilstand.',
    descriptionMobile:
      'La interessenter få innsyn i din boligmappe, for eksempel boligkjøpere som ønsker å sjekke ut boligens tilstand.',
    gaEventTxt: 'Private Sharing',
  },
];
