import React from 'react';

const CopyDocumentIcon = ({ width = '1rem', height = '1rem', ...props }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.75 8.375C9.9375 8.375 10.125 8.21094 10.125 8V3.21875L8.53125 1.625H5.25C5.03906 1.625 4.875 1.8125 4.875 2V8C4.875 8.21094 5.03906 8.375 5.25 8.375H9.75ZM5.25 9.5C4.40625 9.5 3.75 8.84375 3.75 8V2C3.75 1.17969 4.40625 0.5 5.25 0.5H8.53125C8.8125 0.5 9.11719 0.640625 9.32812 0.851562L10.8984 2.42188C11.1094 2.63281 11.25 2.9375 11.25 3.21875V8C11.25 8.84375 10.5703 9.5 9.75 9.5H5.25ZM2.25 3.5H3V4.625H2.25C2.03906 4.625 1.875 4.8125 1.875 5V11C1.875 11.2109 2.03906 11.375 2.25 11.375H6.75C6.9375 11.375 7.125 11.2109 7.125 11V10.25H8.25V11C8.25 11.8438 7.57031 12.5 6.75 12.5H2.25C1.40625 12.5 0.75 11.8438 0.75 11V5C0.75 4.17969 1.40625 3.5 2.25 3.5Z"
      fill="#111111"
    />
  </svg>
);
export default CopyDocumentIcon;
