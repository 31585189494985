import React from 'react';
import Autocomplete from '../Autocomplete';
import DocumentOption from './DocumentOption';

const DocumentOptionList = (props) => (
  <Autocomplete
    isMulti
    closeMenuOnSelect={false}
    label="Koble til dokument"
    loadingMessage={() => 'Laster...'}
    components={{ Option: DocumentOption }}
    noOptionsMessage={() => 'Ingen dokumenter'}
    placeholder="Velg ett eller flere dokumenter"
    {...props}
  />
);

export default DocumentOptionList;
