export const cardHeightMobile = 100;
export const cardHeightDesktop = 100;

const styles = (theme) => ({
  linkContainer: {
    textDecoration: 'none',
  },
  link: {
    display: 'inherit',
    marginBottom: theme.spacing(3),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  card: {
    maxHeight: cardHeightDesktop,

    [theme.breakpoints.down('sm')]: {
      maxHeight: cardHeightMobile,
    },

    display: 'flex',
    cursor: 'pointer',
    alignItems: 'stretch',
    borderRadius: 8,
    border: `1px solid ${theme.palette.primary.mint}`,
    minHeight: 100,
    boxShadow: 'none',
    width: '544px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  details: {
    lineHeight: 1.5,
    margin: '5px 40px',
  },
  imageContainer: {
    /*     flexShrink: 0,
    flexBasis: '40%',
    marginRight: '26px', */
    maxWidth: '56px',
    borderRadius: 4,
  },
  image: {
    width: '56px',
    height: '56px',
    objectFit: 'cover',
    margin: '20px 16px 20px 16px',
    borderRadius: 4,
    /*     [theme.breakpoints.down('sm')]: {
      minHeight: cardHeightMobile,
    }, */
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  actionsContainer: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'flex-end',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    justifyContent: 'center',
  },
  arrowIcon: {
    position: 'relative',
    left: '5px',
    color: theme.palette.text.secondary,
    height: '16px',
    width: '16px',
  },
  ballIcon: {
    top: '3px',
    left: '4px',
    width: '6px',
    height: '6px',
  },
});

export default styles;
