const styles = (theme) => ({
  contentLoader: {
    height: '100%',
    width: '110%',
    borderRadius: theme.spacing(1),
    margin: '10px',
  },
});

export default styles;
