import React from 'react';
import { withStyles } from '@material-ui/core';
import LoyaltyCompany from '@componentsV2/LoyaltyCompany';
import styles from './style';
import useSnackbar from '../../../../hooks/useSnackbar';
import WithHeader from '../../../../components/layouts/WithHeader';
import { boligmappaPlusActionCallback } from '../../../../utils/boligmappaPlus';

const BoligmappaPlusLoyaltyCompanyPage = ({ classes }) => {
  const { showErrorMessage } = useSnackbar();

  const handleActionClick = async (type) => {
    await boligmappaPlusActionCallback(type, showErrorMessage);
  };
  return (
    <WithHeader headerTitle="Selskap" noPadding>
      <div className={classes.page}>
        <LoyaltyCompany onActionClick={handleActionClick} />
      </div>
    </WithHeader>
  );
};

export default withStyles(styles)(BoligmappaPlusLoyaltyCompanyPage);
