import { connect } from 'react-redux';
import { useParams } from 'react-router';
import React, { Fragment, useContext } from 'react';

import useDesktop from '../../../../../hooks/useDesktop';
import Paper from '../../../../../components/atomic/Paper';
import { formattedDate } from '../../../../../utils/dates';
import Page from '../../../../../components/layouts/WithHeader';
import useFetchEffect from '../../../../../hooks/useFetchEffect';
import ErrorContainer from '../../../../../components/ErrorContainer';
import PropertySalesModule from '../../../../../modules/PropertySales';
import { acSetFinancials } from '../../../../../store/actions/financials';
import { sGetFinancials } from '../../../../../store/reducers/financials';
import PropertyRefreshContext from '../../../../../contexts/propertyRefresh';
import { getPropertySales, getSaleDate } from '../../../../../utils/financials';
import { fetchFinancials as apiFetchFinancials } from '../../../../../api/properties';

const PropertySales = ({ financials, setFinancials }) => {
  const dateFormat = 'DD.MM.YYYY';

  const isDesktop = useDesktop();
  const { boligmappaNumber } = useParams();

  const { propertyRefreshRequestInProgress } = useContext(
    PropertyRefreshContext
  );

  const { fetching, fetchingError } = useFetchEffect({
    initialFetchingState: !financials,
    conditionFunction: () => !financials,
    onSuccess: (data) => setFinancials(data),
    apiFetchFunction: () => apiFetchFinancials(boligmappaNumber),
  });

  const sales = getPropertySales(financials);

  const renderSale = (sale, index) => (
    <Fragment key={`sale-item-${index}`}>
      <Paper visible={!isDesktop}>
        <PropertySalesModule
          sale={sale}
          fetching={fetching}
          title={formattedDate(getSaleDate(sale), dateFormat)}
        />
      </Paper>
      <br />
    </Fragment>
  );

  return (
    <Page
      minFullHeight
      noPadding={!isDesktop}
      hideHeader={isDesktop}
      lessMarginLeft={isDesktop}
      maxWidth="lg"
      headerTitle="Omsetningshistorikk"
      showProgressBar={propertyRefreshRequestInProgress}
    >
      {!isDesktop && <br />}

      {fetching && (
        <Paper visible={!isDesktop}>
          <PropertySalesModule fetching />
        </Paper>
      )}

      {!fetching && fetchingError && (
        <ErrorContainer paddingTop={isDesktop} errorResponse={fetchingError} />
      )}

      {!fetching &&
        !fetchingError &&
        Array.isArray(sales) &&
        sales.map(renderSale)}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  financials: sGetFinancials(state),
});

const mapDispatchToProps = (dispatch) => ({
  setFinancials: (financials) => dispatch(acSetFinancials(financials)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertySales);
