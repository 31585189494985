import jwt from 'jsonwebtoken';

import {
  appIdlePeriod,
  privateShareTokenKey,
  lastLoginTimestampKey,
} from '../config';

import { parseQuery } from './routes';
import { setAuthHeaders } from './http';
import localStorage from './localStorage';
import { getRefreshToken } from '../api/auth';
import { setCookie, getCookie, removeCookie } from './cookie';
import {
  eventEmitter,
  IOS_TOKENS_REFRESH_FINISHED,
  TOKENS_UPDATED,
} from './eventEmitter';

export const defaultJwtConfig = {
  ignoreNotBefore: true,
  algorithms: ['RS256', 'HS256'],
};

// private sharing token operations
export const storePrivateShareToken = (privateShareToken) =>
  setCookie(privateShareTokenKey, privateShareToken);

export const retrievePrivateShareToken = () => getCookie(privateShareTokenKey);

export const removePrivateShareToken = () => removeCookie(privateShareTokenKey);

export const retrieveIdToken = () => getCookie('id_token');

export const retrieveIdTokenFromUrl = () => {
  const query = parseQuery(window.location.search);

  return query && query.id_token;
};

export const retrieveAccessToken = () => getCookie('access_token');

export const retrieveAccessTokenFromUrl = () => {
  const query = parseQuery(window.location.search);

  return query && query.access_token;
};

export const retrieveRefreshToken = () => getCookie('refresh_token');

export const decodeToken = (token, config = defaultJwtConfig) => {
  try {
    return [null, jwt.decode(token, config)];
  } catch (error) {
    return [error, null];
  }
};

export const unSyncRefreshTokenWithInterval = () =>
  clearInterval(window.tokenRefreshInterval);

export const getUserFullname = (user) =>
  user && (user.fullname || user.fullName || user.name)
    ? user.fullname || user.fullName || user.name
    : null;

export const getUserPhone = (user) =>
  user && user.phone !== undefined ? user.phone : null;

export const getMobileNumber = (user) => {
  const phone = getUserPhone(user);
  return phone || (user && user.mobile !== undefined) ? user.mobile : null;
};

export const getUserEmail = (user) =>
  user && user.email !== undefined ? user.email : null;

export const getUserName = (user) =>
  user && (user.username ? user.username : null);

export const getUserLastActivityTime = (user) =>
  user && user.lastActivityTime ? user.lastActivityTime : null;

export const getUserFirstname = (user) =>
  (user && user.givenname) || (user && user.given_name);

export const getUserLastname = (user) =>
  (user && user.familyname) || (user && user.family_name);

export const retrieveLastLoginTimestamp = () =>
  JSON.parse(getCookie(lastLoginTimestampKey));

export const setLastLoginTimestamp = (timestamp) =>
  setCookie(lastLoginTimestampKey, JSON.stringify(timestamp));

// if last login was not more than 2 hours
export const shouldShowSessionExpiredWarning = () =>
  (Date.now() - retrieveLastLoginTimestamp()) / 1000 / 60 / 60 <= 2;

export const setAuthTokens = ({ idToken, accessToken, refreshToken }) => {
  if (idToken) setCookie('id_token', idToken);
  if (accessToken) setCookie('access_token', accessToken);
  if (refreshToken) setCookie('refresh_token', refreshToken);
};

export const resetAuthTokens = () => {
  setCookie('id_token', '', 0);
  setCookie('access_token', '', 0);
  setCookie('refresh_token', '', 0);
};

export const callRefreshTokenEndpoint = async () => {
  const [error, response] = await getRefreshToken();

  if (error) {
    return [error, response];
  }

  const {
    id_token: idToken,
    access_token: accessToken,
    refresh_token: refreshToken,
  } = response;

  if (!error && idToken && accessToken && refreshToken) {
    setAuthTokens({ idToken, accessToken, refreshToken });
    setAuthHeaders({ idToken, accessToken, refreshToken });
  }

  return [error, response];
};

// TODO Should move for a better implimentation later.
export const syncRefreshTokenWithInterval = () => {
  const promise = callRefreshTokenEndpoint();

  if (window.tokenRefreshInterval) {
    clearInterval(window.tokenRefreshInterval);
  }

  window.tokenRefreshInterval = setInterval(callRefreshTokenEndpoint, 270000); // 4.5m

  return promise;
};

export const onTokensUpdated = (passedAccessToken, passedIdToken) => {
  // eslint-disable-next-line no-console
  console.log('===== Tokens Recieved From IOS App =====');
  const idToken = passedIdToken || retrieveIdToken();
  const accessToken = passedAccessToken || retrieveAccessToken();

  eventEmitter.emit(TOKENS_UPDATED, { idToken, accessToken });

  setAuthTokens({
    idToken,
    accessToken,
  });

  setAuthHeaders({
    idToken,
    accessToken,
  });
  eventEmitter.emit(IOS_TOKENS_REFRESH_FINISHED);
};

export const TOKENS_UPDATED_TIMESTAMP = 'TOKENS_UPDATED_TIMESTAMP';

export const storeTokenUpdatedTimestamp = () =>
  localStorage.set(TOKENS_UPDATED_TIMESTAMP, Date.now());

export const retrieveTokensUpdatedTimestamp = () =>
  localStorage.get(TOKENS_UPDATED_TIMESTAMP);

export const hasPassedIdleTimeThreshold = (lastTimestamp) =>
  lastTimestamp &&
  (Date.now() - lastTimestamp) / 1000 / 60 > appIdlePeriod * 60;

export const clearTokenUpdatedTimestamp = () =>
  localStorage.remove(TOKENS_UPDATED_TIMESTAMP);
