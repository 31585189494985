import React from 'react';

const ChapterOne = ({
  height = '100%',
  width = '100%',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26.487 29.915"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      transform="translate(-241.375 -396)"
      d="M247.3,402.232h19.32a1.247,1.247,0,0,1,1.246,1.246v21.19a1.247,1.247,0,0,1-1.246,1.246H246.672a3.739,3.739,0,0,1-3.739-3.739.312.312,0,0,1,.312-.312h2.493a.623.623,0,1,0,0-1.246H242.31a.935.935,0,0,1,0-1.87.623.623,0,0,0,.623-.623v-2.493a.623.623,0,0,1,.623-.623h2.181a.623.623,0,0,0,0-1.246H242.31a.935.935,0,0,1,0-1.87.623.623,0,0,0,.623-.623v-2.493a.623.623,0,0,1,.623-.623h2.181a.623.623,0,0,0,0-1.246H242.31a.935.935,0,0,1,0-1.87.623.623,0,0,0,.623-.623v-4.051A4.363,4.363,0,0,1,247.3,396h19.32a1.246,1.246,0,1,1,0,2.493H247.3a1.87,1.87,0,0,0,0,3.739Zm15.269,11.53h.623a.623.623,0,0,0,.425-1.079l-5.8-5.438a1.247,1.247,0,0,0-1.706,0l-5.8,5.44a.623.623,0,0,0,.426,1.078h.623a.623.623,0,0,1,.623.623v4.363a1.247,1.247,0,0,0,1.246,1.246h7.479a1.247,1.247,0,0,0,1.246-1.246v-4.363A.623.623,0,0,1,262.565,413.762Z"
    />
  </svg>
);

export default ChapterOne;
