import { makeStyles } from '@material-ui/core';

export const SentMDRStyles = makeStyles((theme) => ({
  sentMDRSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    flex: '1',
    marginTop: '72px',
    marginBottom: '32px',
    '@media only screen and (max-width: 768px)': {
      marginTop: '32px',
    },
  },
  sentMDRTitle: {
    fontSize: '24px',
    fontWeight: '700',
    color: theme.palette.primary.darkGreen,
    '@media only screen and (max-width: 768px)': {
      fontSize: '20px',
      fontWeight: '600',
    },
  },
  sentMDRSubTitle: {
    fontWeight: '500',
    color: theme.palette.primary.darkGreen,
  },
  sentMDRItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '16px',
    gap: '12px',
    borderRadius: '3px',
    background: theme.palette.primary.mintLight,
  },
  sentMDRItemLabel: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
  },
  emailIcon: {},
}));
