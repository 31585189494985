import { withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  storeEventSuggestionPreference,
  retrieveEventSuggestionPreference,
} from '../../utils/events';

import styles from './style';
import Image from '../Image';
import Box from '../atomic/Box';
import Card from '../atomic/Card';
import Switch from '../atomic/Switch';
import { slides, cycles } from './util';
import Typography from '../atomic/Typography';
import AddCircle from '../../assets/icons/AddCircle';
import { acSetShowAddEvent } from '../../store/actions/events';
import matomo from '../../utils/matomo';

const CreateEventSuggestion = ({ classes, setShowAddEvent }) => {
  const [suggestionsEnabled, setSuggestionsEnabled] = useState(
    retrieveEventSuggestionPreference
  );

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const currentSlide = slides[currentSlideIndex % slides.length];

  const onSuggestionsEnabledChange = (e) => {
    setSuggestionsEnabled(e.target.checked);
    storeEventSuggestionPreference(e.target.checked);

    matomo.clickEvent({
      category: 'Events',
      action: 'Toggle Suggestions',
      value: e.target.checked,
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlideIndex((currentIndex) =>
        currentIndex + 1 <= slides.length * cycles - 1
          ? currentIndex + 1
          : currentIndex
      );
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Box>
      <Box pb={suggestionsEnabled ? 2 : 0} textAlign="center">
        <Typography component="div" color="primary">
          <strong>Forslag til hendelser</strong>

          <Box display="inline" ml={1}>
            <Switch
              size="small"
              color="primary"
              checked={suggestionsEnabled}
              onChange={onSuggestionsEnabledChange}
            />
          </Box>
        </Typography>
      </Box>

      {suggestionsEnabled && (
        <Card
          elevation={2}
          className={classes.root}
          onClick={() => setShowAddEvent(true)}
        >
          <Box display="flex">
            <Box className={classes.imageContainer}>
              <Image src={currentSlide.src} className={classes.image} />
            </Box>
            <Box flexGrow={1} position="relative">
              <Box textAlign="right" pr={2} pt={1}>
                <small>Juli, 2019</small>
              </Box>
              <Box pl={4} mb={4}>
                <Typography>
                  <strong>{currentSlide.title}</strong>
                </Typography>
                <Typography>{currentSlide.subtitle}</Typography>
              </Box>
              <Box className={classes.iconContainer}>
                <AddCircle className={classes.addIcon} />
              </Box>
            </Box>
          </Box>
        </Card>
      )}
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setShowAddEvent: (payload) => dispatch(acSetShowAddEvent(payload)),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(CreateEventSuggestion));
