import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { withStyles } from '@material-ui/core';
import useFetchEffect from '../../../../hooks/useFetchEffect';

import {
  sGetNotifications,
  sGetNotificationsFetching,
  sGetFetchingNotificationsError,
} from '../../../../store/reducers/notifications';

import {
  acSetNotifications,
  acSetFetchingNotifications,
  acSetFetchingNotificationsError,
} from '../../../../store/actions/notifications';

import styles from './style';
import Notification from './Notification';
import NotificationList from './NotificationList';
import useDesktop from '../../../../hooks/useDesktop';
import Grid from '../../../../components/atomic/Grid';
import Page from '../../../../components/layouts/WithHeader';
import Typography from '../../../../components/atomic/Typography';
import ErrorContainer from '../../../../components/ErrorContainer';
import { fetchNotifications as apiFetchNotifications } from '../../../../api/properties';

const Notifications = ({
  classes,
  fetching,
  notifications,
  fetchingError,
  setNotifications,
  setFetchingNotifications,
  setFetchingNotificationsError,
}) => {
  const isDesktop = useDesktop();
  const { notificationIdHash, boligmappaNumber } = useParams();

  useFetchEffect({
    conditionFunction: () => !fetching && !notifications,
    apiFetchFunction: () => apiFetchNotifications(boligmappaNumber),

    onFetchStart: () => setFetchingNotifications(true),
    onFetchComplete: () => setFetchingNotifications(false),

    onSuccess: (data) => setNotifications(data),
    onError: (error) => setFetchingNotificationsError(error),
  });

  return (
    <Page
      noPadding
      maxWidth={false}
      headerTitle="Varsler"
      hideHeader={isDesktop}
      showBackButton={false}
      minFullHeight={isDesktop}
    >
      {!fetching && !notifications ? (
        <ErrorContainer
          paddingTop={isDesktop}
          errorResponse={fetchingError}
          style={{ paddingTop: !isDesktop ? '15%' : undefined }}
        />
      ) : (
        <Grid container>
          <Grid
            item
            xs={isDesktop ? 6 : 12}
            className={clsx(isDesktop && classes.list)}
          >
            {isDesktop && (
              <div className={classes.header}>
                <Typography variant="h2" color="textPrimary">
                  Varsler
                </Typography>
              </div>
            )}
            <NotificationList
              fetching={fetching}
              notifications={notifications}
              selectedNotificationHash={notificationIdHash}
            />
          </Grid>
          {isDesktop && (
            <Grid item xs={6}>
              <Notification fullScreenMode={false} />
            </Grid>
          )}
        </Grid>
      )}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  notifications: sGetNotifications(state),
  fetching: sGetNotificationsFetching(state),
  fetchingError: sGetFetchingNotificationsError(state),
});

const mapDispatchToProps = (dispatch) => ({
  setNotifications: (notifications) =>
    dispatch(acSetNotifications(notifications)),
  setFetchingNotifications: (fetching) =>
    dispatch(acSetFetchingNotifications(fetching)),
  setFetchingNotificationsError: (error) =>
    dispatch(acSetFetchingNotificationsError(error)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Notifications));
