import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { useVariant } from '@unleash/proxy-client-react';
import useDesktop from '@hooksV2/useDesktop';
import { withStyles, useMediaQuery, useTheme } from '@material-ui/core';
import CustomDialog from '@componentsV2/CustomDialog';
// eslint-disable-next-line import/no-unresolved, import/extensions
import { OverviewGridItem } from 'views/PublicRoutes/Dashboard/Home/Overview/components/OverviewGridItem';
import ProgressButton from '../ProgressButton';
import styles from './style';
import Box from '../atomic/Box';
import Grid from '../atomic/Grid';
import Image from '../Image/index';
import Typography from '../atomic/Typography';
import WomenPhone from '../../assets/images/Woman_Phone.png';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';
import matomo from '../../utils/matomo';
import { sGetUser } from '../../store/reducers/auth';
import {
  getUserEmail,
  getUserFullname,
  getMobileNumber,
} from '../../utils/auth';
import { createInquiry } from '../../api/propertyowners';
import { sGetActiveProperty } from '../../store/reducers/properties';
import CheckCircleRounded from '../../assets/icons/CheckCircleRounded';

const PurchasingInsuranceCTA = ({
  classes,
  insurancePaidDocuments,
  authUser,
  activeProperty,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const variant = useVariant('boligeier.insurance.page');
  const isDesktop = useDesktop();
  const [isDialog, setIsDialog] = useState(false);
  const [formData, setFormData] = useState(false);
  const [commentQty, setCommentQty] = useState(0);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);

  useEffect(() => {
    matomo.clickEvent({
      category: 'Trygt utført',
      name: '',
      action: 'A:Rendered Trygt Utført',
    });
  }, []);

  const getEmail = () => {
    return variant.enabled && variant.name === 'requestEmail'
      ? variant.payload.value
      : null;
  };

  useEffect(() => {
    if (getUserEmail(authUser)) {
      setFormData((prevState) => ({
        ...prevState,
        name: getUserFullname(authUser),
        email: getUserEmail(authUser),
        phone: getMobileNumber(authUser),
      }));
    }
  }, [authUser]);

  const onFieldValueChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    setFormData((prevState) => ({ ...prevState, [fieldName]: fieldValue }));
  };

  const onDialogClose = () => {
    setIsDialog(false);
    setFormData((prevState) => ({
      ...prevState,
      name: getUserFullname(authUser),
      email: getUserEmail(authUser),
      comment: '',
    }));
  };

  const onContactMeSubmit = async () => {
    const requestData = {
      boligmappaNumber: activeProperty.boligmappaNumber,
      requestEmail: getEmail(),
      description: formData.comment,
    };
    const [error, response] = await createInquiry(requestData);
    if (!error && response) {
      setIsDialog(false);
      setIsSuccessMessage(true);
      setFormData((prevState) => ({
        ...prevState,
        name: getUserFullname(authUser),
        email: getUserEmail(authUser),
        comment: '',
      }));
    }
  };
  return (
    <OverviewGridItem showMaxwidth>
      <Box
        className={classes.content}
        style={{ margin: isSmallScreen && '10px' }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className={classes.textArea}
            style={{ order: isSmallScreen ? 2 : 1 }}
          >
            {isDesktop && <Box className={classes.tag}>Nyhet!</Box>}
            <Typography mt={0} variant="h2" className={classes.title}>
              Vil du vite mer om Boligmappas advokatforsikring?
            </Typography>
            <Typography
              style={{ marginBottom: '18px' }}
              className={classes.text}
            >
              Visste du at 1 av 6 opplever uenighet med håndverkeren? Trygt
              utført er Boligmappas advokatforsikring for håndverkertjenester i
              5 år til kun 500,-
            </Typography>

            <Typography className={classes.text}>
              Vi tar gjerne en uforpliktende prat for å fortelle mer.
            </Typography>

            <Box className={classes.btnContent}>
              <ProgressButton
                color="primary"
                variant="contained"
                onClick={() => {
                  matomo.clickEvent({
                    category: 'Trygt utført ',
                    name: '',
                    action: 'Clicked Kontakt meg for Trygt Utført',
                  });
                  setIsDialog(true);
                }}
                className={classes.button}
              >
                Kontakt meg
              </ProgressButton>

              <ProgressButton
                className={classes.buttonRg}
                variant="contained"
                onClick={() => {
                  matomo.clickEvent({
                    category: 'Trygt utført',
                    name: '',
                    action: 'Clicked Learn More Trygt Utført -A',
                  });
                  history.push(
                    `${dashboardLinkPrefix}/insurance?insurancePaidDocuments=${insurancePaidDocuments.join(
                      ','
                    )}`
                  );
                }}
              >
                Lær mer
              </ProgressButton>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            style={{ display: 'flex', order: isSmallScreen ? 1 : 2 }}
            className={classes.imageBox}
          >
            <Image
              className={classes.image}
              style={{ margin: isSmallScreen ? 'auto' : '0' }}
              fallbackSrc={WomenPhone}
            />
          </Grid>
        </Grid>
      </Box>
      <CustomDialog isShown={isDialog} onClose={onDialogClose}>
        <div className={classes.dialogContent}>
          <h2 className={classes.contactHeader}>Kontakt meg</h2>
          <p className={classes.contactText}>Opplysninger som deles:</p>
          <div className="form_wrapper">
            <div className={classes.wrapperRow}>
              <p className={classes.label}>Navn</p>
              <p className={classes.label}>{formData.name}</p>
            </div>
            <div className={classes.wrapperRow}>
              <p className={classes.label}>E-post</p>
              <p className={classes.label}>{formData.email}</p>
            </div>
            <div className={classes.wrapperRow}>
              <p className={classes.label}>Telefon</p>
              <p className={classes.label}>{formData.phone}</p>
            </div>
          </div>
          <div className={classes.commentWrapper}>
            <label className={classes.commentLabel} htmlFor="commentInput">
              Kommentar (valgfritt):
            </label>
            <textarea
              className={classes.textarea}
              id="commentInput"
              name="comment"
              placeholder="Relevante opplysninger om din forespørsel."
              value={formData.comment}
              onChange={(e) => {
                if (e.target.value.length <= 1500) {
                  onFieldValueChange(e);
                  setCommentQty(e.target.value.length);
                }
              }}
            />
            <p className={classes.wordLimit}>{commentQty}/1500</p>
            <button
              className={classes.submit_comment}
              type="button"
              onClick={onContactMeSubmit}
            >
              Send inn
            </button>
          </div>
        </div>
      </CustomDialog>
      <CustomDialog isShown={isSuccessMessage} hideClose>
        <div className={classes.successMessage}>
          <div className={classes.messeageContent}>
            <CheckCircleRounded width={30} height={30} />
            <h2 className={classes.messeageTitle}>Takk for din henvendelse!</h2>
            <p className={classes.messageText}>
              Vi tar kontakt for å fortelle mer så snart vi har mottatt en
              forespørsel
            </p>
          </div>
          <button
            className={classes.closeButton}
            type="button"
            onClick={() => setIsSuccessMessage(false)}
          >
            Lukk
          </button>
        </div>
      </CustomDialog>
    </OverviewGridItem>
  );
};

const mapStateToProps = (state) => ({
  authUser: sGetUser(state),
  activeProperty: sGetActiveProperty(state),
});
export default connect(mapStateToProps)(
  withStyles(styles)(PurchasingInsuranceCTA)
);
