const styles = (theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  stepTitle: {
    fontSize: '13px',
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
  checkIcon: {
    height: '20px',
    width: '20px',
    color: theme.palette.primary.green,
    marginLeft: '5px',
  },
  stepContainer: {
    '@media only screen and (max-width: 1500px) and (min-width: 1280px)': {
      marginLeft: '20px',
    },
  },
  stepDesc: {
    fontSize: '13px',
    color: theme.colors.grey,
  },
  navButtons: {
    height: '30px',
  },
  btnContainer: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  btnText: {
    fontSize: '13px',
    fontWeight: 400,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  prevIcon: {
    marginRight: '3px',
  },
  nextIcon: {
    marginLeft: '3px',
  },
  actionBtn: {
    width: '100%',
    height: '27px',
    borderRadius: 14,
    fontSize: '13px',
    fontWeight: 600,
    position: 'relative',
    textDecoration: 'none',
    padding: `1px ${theme.spacing(2)}px`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
  minActionBtn: {
    width: '50%',
    height: '27px',
    borderRadius: 14,
    fontSize: '13px',
    fontWeight: 600,
    position: 'relative',
    textDecoration: 'none',
    left: '50%',
    padding: `1px ${theme.spacing(2)}px`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
  imageDesktop: {
    height: '95px',
    width: '133px',
  },
});

export default styles;
