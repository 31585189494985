import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from '../../style';

const Description = ({ description, descriptionList, classes }) => {
  return (
    <div className={classes.secondDescription}>
      {description?.map((singleDescriptionBlock) => (
        <span
          key={singleDescriptionBlock}
          className={classes.moreDescriptionBlock}
        >
          {singleDescriptionBlock}
        </span>
      ))}
      {descriptionList?.length > 0 && (
        <ul>
          {descriptionList?.map((desListItem) => (
            <li className={classes.listItem} key={desListItem}>
              {desListItem}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default withStyles(styles)(Description);
