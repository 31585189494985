const styles = (theme) => ({
  container: {
    height: '100%',
    position: 'relative',
  },
  actions: {
    zIndex: 1,
    right: '10px',
    bottom: '15px',
    display: 'flex',
    position: 'absolute',
  },
  editButton: {
    marginRight: '15px',
    position: 'relative',
    fontWeight: 'normal',
    textDecoration: 'none',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    background: theme.palette.primary.white,

    '&:hover': {
      textDecoration: 'none',
      background: theme.palette.primary.white,
    },
  },
  cameraIcon: {
    width: '100%',
    height: '100%',
    maxWidth: '1rem',
    maxHeight: '1rem',
    marginRight: theme.spacing(1),
  },
  addButton: {
    width: '150px',
  },
  addIcon: {
    marginRight: '7px',
  },
  gradientOverlay: {
    padding: '10px 20px 20px 20px',
    background:
      'linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.7) 100%)',
    height: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
});

export default styles;
