import {
  ADD_PROPERTY,
  SET_PROPERTIES,
  UPDATE_PROPERTY,
  SET_ACTIVE_PROPERTY,
  SET_PUBLIC_PROPERTY, // new
  // SET_PRIVATE_SHARE,
  SET_FETCHING_PROPERTIES,
  SET_FETCHING_PROPERTIES_ERROR,
} from '../reducers/properties';

import { storeActivePropertyUid } from '../../utils/properties';

export const acSetProperties = (properties) => ({
  type: SET_PROPERTIES,
  payload: properties,
});

export const acAddProperty = ({ isOwned, property }) => ({
  type: ADD_PROPERTY,
  payload: { property, isOwned },
});

export const acSetActiveProperty = (property) => {
  storeActivePropertyUid(property);

  return {
    type: SET_ACTIVE_PROPERTY,
    payload: property,
  };
};

export const propertyUpdateAction = (property) => {
  return {
    type: UPDATE_PROPERTY,
    payload: property,
  };
};

export const acSetFetchingProperties = (fetching) => ({
  type: SET_FETCHING_PROPERTIES,
  payload: fetching,
});

export const acSetPublicProperty = (property) => ({
  // new
  type: SET_PUBLIC_PROPERTY,
  payload: property,
});

// export const acSetPrivateShare = (state) => ({ // new
//   type: SET_PRIVATE_SHARE,
//   payload: state,
// });

export const acSetFetchingPropertiesError = (error) => ({
  type: SET_FETCHING_PROPERTIES_ERROR,
  payload: error,
});
