const styles = (theme) => ({
  actionBtn: {
    fontSize: '15px',
    fontWeight: 400,
    height: '25px',
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.white,
    },
    '@media only screen and (min-width: 1023px) and (max-width: 1201px)': {
      fontSize: '11px',
      fontWeight: 400,
      height: '25px',
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.primary.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.white,
      },
      marignTop: '17%',
    },
  },
});

export default styles;
