import React from 'react';

import Box from '../atomic/Box';
import { isString, toUpperCase } from '../../utils/strings';

const CharAvatar = ({ name, size = 60, ...props }) => {
  if (!name || !isString(name)) {
    return null;
  }

  return (
    <Box
      width={size}
      height={size}
      display="flex"
      alignItems="center"
      borderRadius="100%"
      fontSize={size * 0.7}
      color="primary.white"
      bgcolor="primary.main"
      justifyContent="center"
      {...props}
    >
      {toUpperCase(name.charAt(0))}
    </Box>
  );
};

export default CharAvatar;
