import { connect } from 'react-redux';
import React, { createElement, useState, useEffect } from 'react';
import { useHistory, useRouteMatch, useParams } from 'react-router';

import {
  getRoomDocuments,
  getRoomEvents,
  getRoomId,
  getRoomImages,
  getRoomName,
  roomModes,
} from '../../../../../../utils/rooms';

import RoomActionsMenu from './RoomActions';
import RoomContextMenu from './RoomContextMenu';
import AttachEventMenu from './AttachEventMenu';
import Box from '../../../../../../components/atomic/Box';
import { truncate } from '../../../../../../utils/strings';
import useDesktop from '../../../../../../hooks/useDesktop';
import Paper from '../../../../../../components/atomic/Paper';
import EventList from '../../../../../../components/EventList';
import { getDocumentId } from '../../../../../../utils/documents';
import Page from '../../../../../../components/layouts/WithHeader';
import {
  sGetRooms,
  sGetShowEditRoom,
} from '../../../../../../store/reducers/rooms';
import {
  acDeleteRoom,
  acSetRoomMode,
  acSetShowEditRoom,
} from '../../../../../../store/actions/rooms';
import ImageGallery from '../../../../../../components/ImageGallery';
import { sortImagesByPosition } from '../../../../../../utils/images';
import Typography from '../../../../../../components/atomic/Typography';
import RoomDescription from '../../../../../../modules/RoomDescription';
import ErrorContainer from '../../../../../../components/ErrorContainer';
import { padding } from '../../../../../../components/atomic/Paper/style';
import { updateRoom as apiUpdateRoom } from '../../../../../../api/rooms';
import { fetchDocuments as apiFetchDocuments } from '../../../../../../api/properties';
import { acSyncEventsRooms } from '../../../../../../store/actions/events';
import {
  sGetDocuments,
  sGetFetchingDocuments,
  sGetFetchingDocumentsError,
} from '../../../../../../store/reducers/documents';
import {
  acDeleteDocument,
  acSetDocuments,
  acSetFetchingDocuments,
  acSetFetchingDocumentsError,
} from '../../../../../../store/actions/documents';
import ContentLoader from '../../../../../../components/loaders/ContentLoader';
import AttachDocumentMenu from '../../../../../../components/AttachDocumentMenu';
import {
  getDocumentList,
  getDocumentTable,
} from '../../../../../../utils/DocumentTable';
import matomo from '../../../../../../utils/matomo';
import useFetchEffect from '../../../../../../hooks/useFetchEffect';
import useDashboardLinkPrefix from '../../../../../../hooks/useDashboardLinkPrefix';

const Room = ({
  room,
  rooms,
  fetching,
  deleteRoom,
  fetchingError,
  onRoomUpdated,
  onEventUpdated,
  deleteDocument,
  syncEventsRooms,
  storedDocuments,
  enableImageUpload,
  setShowEditRoom,
  setRoomMode,
  setFetchingError,
  setFetching,
  setStoredDocuments,
}) => {
  const history = useHistory();
  const isDesktop = useDesktop();

  const { boligmappaNumber } = useParams();

  const {
    params: { roomId },
  } = useRouteMatch();

  const [roomMenuAnchorEl, setRoomMenuAnchorEl] = useState(null);
  const [eventMenuAnchorEl, setEventMenuAnchorEl] = useState(null);
  const [documentMenuAnchorEl, setDocumentMenuAnchorEl] = useState(null);

  const [uploadedImageIndex, setUploadedImageIndex] = useState(null);
  const [mainContextMenuOpen, setMainContextMenuOpen] = useState(false);
  const [attachEventMenuOpen, setAttachEventMenuOpen] = useState(false);
  const [attachDocumentMenuOpen, setAttachDocumentMenuOpen] = useState(false);
  const currentRoom = rooms?.filter((e) => e.id === room?.id)[0];
  const images = (getRoomImages(room) || []).sort(sortImagesByPosition);

  const shouldShowImageGallery =
    fetching || (Array.isArray(images) && images.length > 0);

  const events = getRoomEvents(room);
  const [documents, setDocuments] = useState(getRoomDocuments(room));
  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const showEventList = fetching || (!fetching && events && events.length > 0);
  // const [showDocumentList,setShowDocumentList] = useState(fetching || (!fetching && documents && documents.length > 0));

  const headerTitle = isDesktop
    ? getRoomName(room)
    : truncate(getRoomName(room), { length: 30 });

  const onImagesUploaded = (uploadedImages) => {
    const newIndex = (getRoomImages(room) || []).length;

    onRoomUpdated({
      ...room,
      gallery: [...(getRoomImages(room) || []), ...uploadedImages],
    });

    setUploadedImageIndex(newIndex);
  };

  const onRoomMenuClick = (event) => {
    setMainContextMenuOpen(true);
    setRoomMenuAnchorEl(event.currentTarget);
  };

  const onDocumentMenuClick = (event) => {
    setAttachDocumentMenuOpen(true);
    setDocumentMenuAnchorEl(event.currentTarget);
  };

  const onEditRoom = () => {
    setRoomMode(roomModes.room);
    setShowEditRoom(true);

    matomo.clickEvent({
      category: 'Rooms',
      action: 'Edit Room',
      name: 'Edit Room Details',
    });
  };

  const onEventMenuClick = (event) => {
    setAttachEventMenuOpen(true);
    setEventMenuAnchorEl(event.currentTarget);
  };

  useFetchEffect({
    dependencies: [boligmappaNumber],
    setFetchingFunction: setFetching,
    onSuccess: (data) => setStoredDocuments(data),
    onError: (error) => setFetchingError(error),
    conditionFunction: () => !storedDocuments && !fetching,
    apiFetchFunction: () =>
      apiFetchDocuments(boligmappaNumber, { includeTaggedInfo: true }),
  });

  useEffect(() => {
    if (!documents) {
      setDocuments(getRoomDocuments(room));
    }
  }, [room]);

  useEffect(() => {
    if (storedDocuments && currentRoom) {
      setDocuments(
        storedDocuments?.filter((doc) =>
          getRoomDocuments(currentRoom)?.some(
            (selectedDoc) => selectedDoc.id === doc.id
          )
        )
      );
    }
  }, [storedDocuments, currentRoom]);

  const onRoomDeleted = () => {
    // Only delete room from store if there are rooms loaded.
    // Otherwise updated room list will be fetched when needed.
    if (rooms) {
      deleteRoom(getRoomId(room));
    }

    syncEventsRooms({
      roomId,
      eventIds: [],
    });

    matomo.clickEvent({
      category: 'Rooms',
      action: 'Delete Room',
    });

    // history.goBack();
    history.push(`${dashboardLinkPrefix}/rooms`);
  };

  const onDocumentDeleted = (document) => {
    onRoomUpdated({
      ...room,
      documents: getRoomDocuments(room || []).filter(
        (roomDocument) =>
          getDocumentId(roomDocument) !== getDocumentId(document)
      ),
    });

    if (Array.isArray(storedDocuments)) {
      deleteDocument(document);
    }
  };

  const renderDocumentList = () =>
    createElement(isDesktop ? getDocumentTable() : getDocumentList(), {
      fetching,
      documents,
      onDocumentDeleted,
      isFiltersSticky: false,
      showOnPaper: false,
      error: fetchingError,
      showBulkSelectionOptions: true,
    });

  const onImagesUpdated = async (imagesToUpdate, onSuccess, onError) => {
    const currentImages = images.slice();

    onRoomUpdated({
      ...room,
      gallery: imagesToUpdate.map((image, index) => ({
        ...image,
        position: index + 1,
      })),
    });

    const [error, response] = await apiUpdateRoom(roomId, {
      imageIds: imagesToUpdate.map((image) => image.id),
    });

    if (onSuccess && !error && response) {
      onSuccess();
    }

    if (onError && error && !response) {
      onError();
    }

    if (error && !response) {
      onRoomUpdated({
        ...room,
        gallery: currentImages,
      });
    }
  };

  if (fetchingError) {
    return <ErrorContainer errorResponse={fetchingError} />;
  }

  return (
    <Page
      noPaddingTop
      minFullHeight
      noPadding={!isDesktop}
      onMenuIconClick={onRoomMenuClick}
      subPageHeader
      headerTitle={headerTitle || 'Rom'}
      lessMarginLeft={isDesktop}
      maxWidth="lg"
    >
      {shouldShowImageGallery && (
        <ImageGallery
          editable
          images={images}
          fetching={fetching}
          activeIndex={uploadedImageIndex}
          onImagesUpdated={onImagesUpdated}
        />
      )}

      <RoomActionsMenu
        room={room}
        fetching={fetching}
        onImagesUploaded={onImagesUploaded}
        onEventIconClick={onEventMenuClick}
        onDocumentIconClick={onDocumentMenuClick}
      />

      <br />
      <Paper visible={!isDesktop}>
        <RoomDescription room={room} fetching={fetching} />
      </Paper>

      {showEventList && (
        <>
          <br />
          <Paper visible={!isDesktop}>
            <Typography variant="h3">
              {fetching ? (
                <Box py={1}>
                  <ContentLoader height={20} width={140} />
                </Box>
              ) : (
                'Historikk'
              )}
            </Typography>
            <EventList
              truncatable
              fetching={fetching}
              events={getRoomEvents(room)}
              onEventUpdated={onEventUpdated}
              enableImageUpload={enableImageUpload}
            />
          </Paper>
        </>
      )}

      <>
        <br />
        <Paper visible={!isDesktop}>
          <Typography variant="h3">
            {fetching ? (
              <ContentLoader height={20} width={110} />
            ) : (
              'Dokumenter'
            )}
          </Typography>

          <Box mx={`-${padding}px`}>{renderDocumentList()}</Box>
        </Paper>
      </>

      <AttachEventMenu
        roomId={roomId}
        open={attachEventMenuOpen}
        anchorEl={eventMenuAnchorEl}
        setOpen={setAttachEventMenuOpen}
        setShowEditRoom={setShowEditRoom}
        setRoomMode={setRoomMode}
      />

      <RoomContextMenu
        roomId={roomId}
        open={mainContextMenuOpen}
        anchorEl={roomMenuAnchorEl}
        roomName={getRoomName(room)}
        onRoomDeleted={onRoomDeleted}
        setOpen={setMainContextMenuOpen}
        onEditRoom={onEditRoom}
      />

      <AttachDocumentMenu
        room={room}
        open={attachDocumentMenuOpen}
        anchorEl={documentMenuAnchorEl}
        setOpen={setAttachDocumentMenuOpen}
        setShowEditRoom={setShowEditRoom}
        setRoomMode={setRoomMode}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  rooms: sGetRooms(state),
  storedDocuments: sGetDocuments(state),
  fetching: sGetFetchingDocuments(state),
  fetchingError: sGetFetchingDocumentsError(state),
  showEditRoom: sGetShowEditRoom(state),
});

const mapDispatchToProps = (dispatch) => ({
  deleteRoom: (roomId) => dispatch(acDeleteRoom(roomId)),
  deleteDocument: (document) => dispatch(acDeleteDocument(document)),
  syncEventsRooms: (payload) => dispatch(acSyncEventsRooms(payload)),
  setShowEditRoom: (payload) => dispatch(acSetShowEditRoom(payload)),
  setRoomMode: (payload) => dispatch(acSetRoomMode(payload)),
  setFetchingError: (fetchingError) =>
    dispatch(acSetFetchingDocumentsError(fetchingError)),
  setStoredDocuments: (documents) => dispatch(acSetDocuments(documents)),
  setFetching: (fetching) => dispatch(acSetFetchingDocuments(fetching)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Room);
