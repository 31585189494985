import React, { createElement, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router';
import styles from './style';
import Box from '../../atomic/Box';
import { getMobileEditOptionTypes, transformDocumentData } from '../utils';
import { sGetRooms } from '../../../store/reducers/rooms';
import { sGetEvents } from '../../../store/reducers/events';
import {
  acDeleteDocument,
  acUpdateDocument,
  acAddDocument,
} from '../../../store/actions/documents';
import {
  acDetachDocumentFromEvents,
  acSyncEventsDocuments,
} from '../../../store/actions/events';
import {
  acDetachDocumentFromRooms,
  acSyncRoomsDocuments,
} from '../../../store/actions/rooms';
import {
  ATTACH_EVENT_EDIT_OPTION,
  ATTACH_ROOM_EDIT_OPTION,
  DOCUMENT_DELETE_OPTION,
  DOCUMENT_TYPE_EDIT_OPTION,
  MOVE_DOCUMENT_TO_PROPERTY_OPTION,
  COPY_DOCUMENT_TO_PROPERTY_OPTION,
  getDocumentEvents,
  getDocumentFileName,
  getDocumentId,
  getDocumentRooms,
  getDocumentTypeId,
  isCompanyDocument,
  MOVE_BULK_DOCUMENT_TO_PERSONAL_OPTION,
  DOCUMENT_DELETE_OPTION_PERSONAL,
} from '../../../utils/documents';
import documentTypes from '../../forms/DocumentForm/documentTypes';
import BottomContextMenu from '../../BottomContextMenu';
import List from '../../atomic/List';
import matomo from '../../../utils/matomo';
import {
  updatePropertyDocuments as apiUpdatePropertyDocuments,
  deletePropertyDocuments as apiDeletePropertyDocuments,
} from '../../../api/propertydocuments';
import useSnackbar from '../../../hooks/useSnackbar';
import useActionDialog from '../../../hooks/useActionDialog';
import { prepareDocumentData } from '../../forms/DocumentForm/util';
import {
  sGetAllProperties,
  sGetActiveProperty,
} from '../../../store/reducers/properties';
import {
  acAddPersonalDocument,
  acDeletePersonalDocument,
} from '../../../store/actions/personalDocuments';
import {
  moveDocumentsToProperty as apiMoveDocumentToProperty,
  copyPersonalDocumentstoProperty as apiCopyPersonalDocumentstoProperty,
  deletePersonalAreaDocuments,
} from '../../../api/propertyowners';
import { copyBulkPropertyDocumentToPrivate as apiCopyBulkPropertyDocumentToPrivate } from '../../../api/properties';

const DocumentBulkEditMobile = ({
  classes,
  rooms,
  events,
  open,
  setOpen,
  selectedDocuments,
  updateDocument,
  deleteDocument,
  clearSelectedDocuments,
  closeActionMenu,
  syncRoomsDocuments,
  syncEventsDocuments,
  detachDocumentFromRooms,
  detachDocumentFromEvents,
  isPersonalDocuments = false,
  properties,
  activeProperty,
  addDocument,
  deletePersonalDocument,
  isFromPersonal,
  addPersonalDocument,
  isBoligmappaPlusUser,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const deleteOption = [{ title: 'Slett' }];
  const [menuSave, setMenuSave] = useState(false);
  const [currentEditType, setCurretEditType] = useState('');
  const { showSuccessMessage, showErrorMessage, showWarningMessage } =
    useSnackbar();
  const { boligmappaNumber } = useParams();
  const { showActionDialog, showLoadingDialog } = useActionDialog();

  const [newEventAdded, setNewEventAdded] = useState(false);

  const trackMatomoEvent = (type) => {
    matomo.clickEvent({
      category: 'Document handling',
      name: type === 'Delete' ? '' : `Edit ${type}`,
      action:
        type === 'Delete'
          ? 'Delete Document from Document List'
          : 'Edit Document from Document List',
      value: selectedDocuments?.length,
    });
  };

  const trackPersonalMatomoEvents = (type) => {
    matomo.clickEvent({
      category: 'Document handling',
      action: type,
      value: selectedDocuments?.length,
    });
  };

  const updateDocumentState = ({
    selectedRooms = [],
    selectedEvents = [],
    selectedDocumentType,
  }) => {
    selectedDocuments.forEach((doc) => {
      // update document state
      updateDocument({
        ...doc,
        events: Array.from(
          new Set(
            [...(getDocumentEvents(doc) || []), ...selectedEvents].map(
              (obj) => obj?.id
            )
          )
        ).map((id) =>
          [...(getDocumentEvents(doc) || []), ...selectedEvents].find(
            (obj) => obj?.id === id
          )
        ),
        rooms: Array.from(
          new Set(
            [...(getDocumentRooms(doc) || []), ...selectedRooms].map(
              (obj) => obj?.id
            )
          )
        ).map((id) =>
          [...(getDocumentRooms(doc) || []), ...selectedRooms].find(
            (obj) => obj?.id === id
          )
        ),
        documentTypeId:
          selectedDocumentType == null
            ? getDocumentTypeId(doc) ?? 0
            : documentTypes.find((obj) => obj?.id === selectedDocumentType ?? 0)
                ?.id,
        documentType:
          selectedDocumentType == null
            ? documentTypes.find(
                (obj) => obj.id === getDocumentTypeId(doc) ?? 0
              )?.name
            : documentTypes.find((obj) => obj.id === selectedDocumentType ?? 0)
                ?.name,
      });

      // sync room documents
      syncRoomsDocuments({
        documentId: getDocumentId(doc),
        document: doc,
        roomIds: Array.from(
          new Set(
            [...(getDocumentRooms(doc) || []), ...selectedRooms].map(
              (obj) => obj?.id
            )
          )
        )
          .map((id) =>
            [...(getDocumentRooms(doc) || []), ...selectedRooms].find(
              (obj) => obj?.id === id
            )
          )
          .map((room) => room.id),
      });

      // sync event documents
      syncEventsDocuments({
        documentId: getDocumentId(doc),
        document: doc,
        eventIds: Array.from(
          new Set(
            [...(getDocumentEvents(doc) || []), ...selectedEvents].map(
              (obj) => obj?.id
            )
          )
        )
          .map((id) =>
            [...(getDocumentEvents(doc) || []), ...selectedEvents].find(
              (obj) => obj?.id === id
            )
          )
          .map((event) => event?.id),
      });
    });
  };

  const updateDocuments = async ({
    selectedEvents,
    selectedRooms,
    selectedDocumentType,
  }) => {
    const [requestError, response] = await apiUpdatePropertyDocuments(
      boligmappaNumber,
      transformDocumentData({
        documents: selectedDocuments,
        rooms: selectedRooms,
        events: selectedEvents,
        documentType: selectedDocumentType,
      })
    );

    if (!response && requestError) {
      showErrorMessage();
    } else {
      updateDocumentState({
        selectedRooms,
        selectedEvents,
        selectedDocumentType,
      });
      showSuccessMessage('Endringene er lagret');
    }
  };

  const deleteDocuments = async () => {
    const deleteRestrictedDocs = (selectedDocuments || []).filter((doc) =>
      isCompanyDocument(doc)
    );
    if (deleteRestrictedDocs?.length > 0) {
      const docNameList = deleteRestrictedDocs.map((a) =>
        getDocumentFileName(a)
      );
      showWarningMessage(
        `${docNameList} ble ikke lastet ned. Filene har rettighetssbegrensninger som ikke tillater sletting.`
      );
    }
    if (deleteRestrictedDocs.length === selectedDocuments.length) return;
    showActionDialog({
      submitButtonText: 'Slett',
      message:
        selectedDocuments.length > 1
          ? 'Vil du slette dokumentene?'
          : 'Vil du slette dokumentet?',
      onSubmit: async () => {
        const closeDialog = showLoadingDialog('Sletter...');
        const documentIds = (selectedDocuments || [])
          .filter((doc) => !isCompanyDocument(doc))
          .map((a) => getDocumentId(a));
        const deleteRequest =
          (currentEditType === DOCUMENT_DELETE_OPTION &&
            apiDeletePropertyDocuments) ||
          (currentEditType === DOCUMENT_DELETE_OPTION_PERSONAL &&
            deletePersonalAreaDocuments);
        const [requestError, response] = await deleteRequest(
          boligmappaNumber,
          documentIds
        );
        closeDialog();

        if (requestError && !response) {
          showErrorMessage(
            'Noe gikk galt ved sletting av filen. Vennligst prøv igjen.'
          );
        } else {
          showSuccessMessage('Sletting vellykket');
          if (currentEditType === DOCUMENT_DELETE_OPTION) {
            (selectedDocuments || []).forEach((doc) => {
              deleteDocument(doc);
              detachDocumentFromRooms(doc);
              detachDocumentFromEvents(doc);
            });
            trackMatomoEvent('Delete');
          }
          if (currentEditType === DOCUMENT_DELETE_OPTION_PERSONAL) {
            (selectedDocuments || []).forEach((doc) => {
              deletePersonalDocument(doc);
            });
            trackPersonalMatomoEvents('Delete files from Personal Documents');
          }
          clearSelectedDocuments();
          closeActionMenu();
        }
      },
    });
  };

  const moveDocument = async (selectedOption) => {
    const documentIds = selectedDocuments?.map((doc) => doc?.id);
    const data = {
      boligmappaNumber: selectedOption?.label,
      documentIds,
    };
    const [error, respose] = await apiMoveDocumentToProperty(data);
    if (!respose && error) {
      showErrorMessage(error);
    } else {
      if (data?.boligmappaNumber === activeProperty?.boligmappaNumber) {
        selectedDocuments?.forEach((doc) => {
          addDocument(prepareDocumentData(doc));
        });
      }
      selectedDocuments?.forEach((doc) => {
        deletePersonalDocument(doc);
      });
      trackPersonalMatomoEvents(
        'Move Bulk Personal Documents to Property Documents'
      );
      showSuccessMessage('Dokument flyttet.');
    }
  };

  const copyDocument = async (selectedOption) => {
    const documentIds = selectedDocuments?.map((doc) => doc?.id);
    const data = {
      boligmappaNumber: selectedOption?.label,
      documentIds,
    };
    const [error, response] = await apiCopyPersonalDocumentstoProperty(data);
    if (!response && error) {
      showErrorMessage(error);
    } else {
      if (data.boligmappaNumber === activeProperty?.boligmappaNumber) {
        response.forEach((res) => {
          if (res.isSuccess) {
            const doc = selectedDocuments.find((d) => d.id === res.copiedId);
            const url = {
              rel: doc?.url?.rel,
              link: res.downloadUrl,
              fileType: doc?.url?.fileType,
            };

            const d = { ...doc, id: res.id, url };
            addDocument(prepareDocumentData(d));
          }
        });
      }
      trackPersonalMatomoEvents(
        'Copy Bulk Personal Documents to Property Documents'
      );
      showSuccessMessage('Dokumentet er kopiert');
    }
  };

  const copyBulkDocumentsToPersonal = async () => {
    const documentIds = selectedDocuments?.map((doc) => doc?.id);
    const data = {
      boligmappaNumber: activeProperty?.boligmappaNumber,
      documentIds,
    };
    const [error, response] = await apiCopyBulkPropertyDocumentToPrivate(
      activeProperty?.boligmappaNumber,
      data
    );
    if (!response && error) {
      showErrorMessage(error);
    } else {
      response.forEach((res) => {
        if (res.isSuccess) {
          const doc = selectedDocuments.find((d) => d.id === res?.copiedId);
          const url = {
            rel: doc?.url?.rel,
            link: res.downloadUrl,
            fileType: doc?.url?.fileType,
          };
          const d = { ...doc, id: res.id, url };
          addPersonalDocument(d);
        }
      });
      showSuccessMessage('Dokumentet er kopiert');
      trackPersonalMatomoEvents(
        'Copy Bulk Property Documents to Personal Documents'
      );
      closeActionMenu();
    }
  };

  useEffect(() => {
    if (menuSave && currentEditType) {
      setMenuSave(false);
      if (currentEditType === MOVE_BULK_DOCUMENT_TO_PERSONAL_OPTION) {
        copyBulkDocumentsToPersonal();
      }
      if (
        currentEditType === DOCUMENT_DELETE_OPTION ||
        currentEditType === DOCUMENT_DELETE_OPTION_PERSONAL
      ) {
        deleteDocuments();
      }
      if (selectedOptions.length > 0) {
        if (currentEditType === ATTACH_ROOM_EDIT_OPTION) {
          trackMatomoEvent('Room');
          updateDocuments({ selectedRooms: selectedOptions });
        } else if (currentEditType === ATTACH_EVENT_EDIT_OPTION) {
          trackMatomoEvent('Event');
          updateDocuments({ selectedEvents: selectedOptions });
        } else if (currentEditType === DOCUMENT_TYPE_EDIT_OPTION) {
          trackMatomoEvent('Document Type');
          updateDocuments({ selectedDocumentType: selectedOptions[0]?.id });
        } else if (currentEditType === MOVE_DOCUMENT_TO_PROPERTY_OPTION) {
          moveDocument(selectedOptions[0]);
        } else if (currentEditType === COPY_DOCUMENT_TO_PROPERTY_OPTION) {
          copyDocument(selectedOptions[0]);
        }
      }
      setSelectedOptions([]);
    }
  }, [menuSave]);

  const [count, setCount] = useState(1);
  useEffect(() => {
    const newRoom = rooms?.sort((a, b) => b.id - a.id)[0];
    if (newRoom) {
      if (count > 1) setSelectedOptions([...selectedOptions, newRoom]);
      setCount(1);
    }
    setCount(count + 1);
  }, [rooms?.length]);

  const [eventRefresh, setEventRefresh] = useState(1);
  useEffect(() => {
    const newEvent = events?.sort((a, b) => b.id - a.id)[0];
    if (newEvent) {
      if (eventRefresh > 1) {
        setSelectedOptions([...selectedOptions, newEvent]);
        setNewEventAdded(true);
      }
      setEventRefresh(1);
    }

    setEventRefresh(eventRefresh + 1);
  }, [events?.length]);

  const getEditOptions = (type) => {
    let options = [];
    if (type === ATTACH_ROOM_EDIT_OPTION) {
      options = rooms?.sort((a, b) => b.id - a.id);
    } else if (type === ATTACH_EVENT_EDIT_OPTION) {
      options = events?.sort((a, b) => b.id - a.id);
    } else if (type === DOCUMENT_TYPE_EDIT_OPTION) {
      options = documentTypes;
    } else if (type === DOCUMENT_DELETE_OPTION) {
      options = deleteOption;
    } else if (
      type === MOVE_DOCUMENT_TO_PROPERTY_OPTION ||
      type === COPY_DOCUMENT_TO_PROPERTY_OPTION
    )
      options = properties?.sort((a, b) => b.id - a.id);
    return options;
  };

  const onEditOptionClick = (type) => {
    if (type === MOVE_BULK_DOCUMENT_TO_PERSONAL_OPTION) {
      setMenuSave(true);
      setCurretEditType(type);
    }
    if (
      type === DOCUMENT_DELETE_OPTION ||
      type === DOCUMENT_DELETE_OPTION_PERSONAL
    ) {
      setMenuSave(true);
      setCurretEditType(type);
    }
  };

  return (
    <Box className={classes.root}>
      <BottomContextMenu
        open={open}
        isBulk
        setOpen={setOpen}
        title={selectedDocuments?.length}
        ModalProps={{
          style: {
            zIndex: 1202,
          },
        }}
      >
        <List>
          {getMobileEditOptionTypes(
            isPersonalDocuments,
            isFromPersonal,
            isBoligmappaPlusUser
          ).map(
            ({
              type,
              name,
              mobileComponent,
              editOptions,
              icon,
              border,
              isVisible,
            }) =>
              createElement(mobileComponent, {
                name,
                icon,
                type,
                border,
                newEventAdded,
                key: `edit-option-${type}`,
                open: true,
                selectedDocuments,
                onSelectOption: setSelectedOptions,
                editOptions:
                  editOptions &&
                  editOptions(
                    getEditOptions(type),
                    selectedOptions,
                    setSelectedOptions
                  ),
                onFiltersReset: () => {
                  setSelectedOptions([]);
                },
                onMenuSave: () => {
                  setMenuSave(true);
                  setCurretEditType(type);
                },
                isVisible,
                onClick: () => {
                  onEditOptionClick(type);
                },
              })
          )}
        </List>
      </BottomContextMenu>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  rooms: sGetRooms(state),
  events: sGetEvents(state),
  properties: sGetAllProperties(state),
  activeProperty: sGetActiveProperty(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateDocument: (document) => dispatch(acUpdateDocument(document)),
  deleteDocument: (document) => dispatch(acDeleteDocument(document)),
  addDocument: (document) => dispatch(acAddDocument(document)),
  addPersonalDocument: (document) => dispatch(acAddPersonalDocument(document)),
  syncRoomsDocuments: (payload) => dispatch(acSyncRoomsDocuments(payload)),
  syncEventsDocuments: (payload) => dispatch(acSyncEventsDocuments(payload)),
  detachDocumentFromRooms: (payload) =>
    dispatch(acDetachDocumentFromRooms(payload)),
  detachDocumentFromEvents: (payload) =>
    dispatch(acDetachDocumentFromEvents(payload)),
  deletePersonalDocument: (document) =>
    dispatch(acDeletePersonalDocument(document)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(DocumentBulkEditMobile);
