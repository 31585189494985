import { Size, TitleElement, titleSizeMap } from '@style/vars/typography';
import { colors, fonts } from '@style/vars';
import { css } from '@linaria/core';
import { mediaQueries } from '@style/mediaQueries';

export interface StyledTitleProps {
  /**
   * Sets the title's font-size from a set of defined sizes.
   */
  size?: Size;
  /**
   * The `level` of the title. You can read more about `levels` [here](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Heading_Elements)
   */
  level?: TitleElement;
}

export const getStyledTitle = () => {
  return Object.keys(titleSizeMap).map((size) => {
    return `
    &.${size} {
      color: ${colors.textColor};
      font-weight: ${fonts.weight.bold};
      font-size: ${titleSizeMap[size].fontSizeMobile || titleSizeMap[size].fontSize};
      line-height: ${titleSizeMap[size].lineHeightMobile || titleSizeMap[size].lineHeight};

      ${mediaQueries.sm} {
        font-size: ${titleSizeMap[size].fontSize};
        line-height: ${titleSizeMap[size].lineHeight};
      }
    }
    `;
  }).join('');
};

export const titleTextStyle = css`
  ${getStyledTitle()}
`;