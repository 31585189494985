import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import CloseIcon from '../../../../../assets/icons/Close';
import { getReadableSize } from '../../../../../utils/files';
import ListItem from '../../../../../components/DocumentList/ListItem';

const FileListItem = ({ file, index, classes, onUnselect }) => (
  <ListItem
    primaryText={file.name}
    secondaryText={getReadableSize(file.size)}
    actionComponent={
      <CloseIcon
        className={classes.closeIcon}
        onClick={() => onUnselect(index)}
      />
    }
  />
);

export default withStyles(styles)(FileListItem);
