import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import styles from './style';
import useDesktop from '../../hooks/useDesktop';

const Prices = ({ classes }) => {
  const isDesktop = useDesktop();

  return (
    <div className={clsx([classes.insuranceContent, !isDesktop && 'mobile'])}>
      <h2>Priseksempel</h2>
      <div className={clsx([classes.priceSectionRow, !isDesktop && 'mobile'])}>
        <div className={classes.priceBlock}>
          <h3>Trygt utført </h3>
          <div className={classes.priceList}>
            <div className={classes.priceRow}>
              <p>Engangskjøp</p>
              <p className={classes.price}>500 kr</p>
            </div>
            <div className={classes.priceRow}>
              <p>Egenandel ved tvist</p>
              <p>3000 kr</p>
            </div>
            <div className={classes.priceRow}>
              <p>Snittpris bistand hovedforhandling</p>
              <p>100-150.000 kr</p>
            </div>
            <div className={classes.priceRow}>
              <p>Sakskostander motpart ved tapt sak</p>
              <p>100-150.000 kr</p>
            </div>
            <div className={clsx([classes.priceRow, classes.mainPriceRowLeft])}>
              <h4>Totalpris</h4>
              <p className={classes.price}>
                <b>3500 kr</b>
              </p>
            </div>
          </div>
        </div>
        <div className={classes.priceBlock}>
          <h3>Uten Trygt utført</h3>
          <div className={classes.priceList}>
            <div className={classes.priceRow}>
              <p>Årlig pris</p>
              <p className={classes.price}>0 kr</p>
            </div>
            <div className={classes.priceRow}>
              <p>Egenandel ved tvist</p>
              <p>0 kr</p>
            </div>
            <div className={classes.priceRow}>
              <p>Snittpris bistand hovedforhandling</p>
              <p>100-150.000 kr</p>
            </div>
            <div className={classes.priceRow}>
              <p>Sakskostander motpart ved tapt sak</p>
              <p>100-150.000 kr</p>
            </div>
            <div
              className={clsx([classes.priceRow, classes.mainPriceRowRight])}
            >
              <h4>Totalpris</h4>
              <p className={classes.price}>
                <b>200-300.000 kr</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <p className={classes.remark}>
        Konflikt i rettsak, med og uten forsikring. Snittprisen på én
        advokattime lå i 2018 på 1673 kr*
      </p>
      <div className={classes.recommendations}>
        <h2>96 % som har brukt advokatforsikringen anbefaler den</h2>
        <p>
          Basert på HELP sin kundeundersøkelse i 2022 svarer kundene at de er
          svært fornøyd med rask bistand og at advokatene er dyktige og
          imøtekommende.
        </p>
      </div>
    </div>
  );
};

export default withStyles(styles)(Prices);
