import React from 'react';
import CallToAction from '../BoligmappaPlus/CallToAction';
import useIsDesktop from '../../hooks/v2/useDesktop';
import { StyledPersonalAreaLanding } from './styles';
import BoligmappaPlusLogo from '../../assets/images/Boligmappa-Plus-Logo.png';
import ContractTemplate from '../../assets/images/ContractTemplate.png';
import MyDocuments from '../../assets/images/MyDocuments.png';
import FormerHouses from '../../assets/images/FormerHouses.png';
import PersonalAreaCard from './PersonalAreaCard';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';
import { useBoligmappaPlusSubscriptionStatus } from '../../store/hooks/useBoligmappaPlusSubscriptionStatus';
import NewsletterSignup from '../NewsletterSignup';

const PersonalAreaLanding = ({ onActionClick }) => {
  const isDesktop = useIsDesktop();
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const isSubscribedToBoligmappaPlus = useBoligmappaPlusSubscriptionStatus();

  return (
    <StyledPersonalAreaLanding>
      {!isSubscribedToBoligmappaPlus && (
        <div className="boligmappa_plus_subscription_section">
          <h1>Personlig område</h1>
          <p className="boligmappa_plus_personal_text">
            Dokumenter og innhold i personlig område følger deg for alltid.
            Trygg og sikker lagring av viktige dokumenter med BankID.{' '}
          </p>
          <button type="button" className="boligmappa_plus_logo_btn">
            Tilgjengelig med
            <img alt="Boligmappa+Logo" src={BoligmappaPlusLogo} />
          </button>
          <CallToAction
            onActionClick={onActionClick}
            isDesktop={isDesktop}
            className="personal_area_call_to_action"
          />
        </div>
      )}
      <div className="personal_area_section">
        <h2>Hva inneholder personlig område?</h2>
        <p>
          Vi kommer til å slippe en rekke spennende funksjoner til personlig
          område i løpet av 2024!
        </p>
        <div className="card_container">
          <PersonalAreaCard
            title="Mine dokumenter"
            description="Dokumenter i personlig område følger deg for alltid. Trygg og sikker lagring av viktige dokumenter med BankID."
            image={MyDocuments}
            linkText="Gå til Mine dokumenter →"
            link={`${dashboardLinkPrefix}/documents?personal=true`}
          />
          {/* TODO
          Change the link once the correct route implemented */}
          <PersonalAreaCard
            title="Kontraktmaler"
            description="Kvalitetssikret malverk for alt du trenger innen renovasjon, kjøp og salg av bolig."
            image={ContractTemplate}
            linkText="Gå til kontraktmaler →"
            link={`${dashboardLinkPrefix}/contract-templates`}
          />
          <PersonalAreaCard
            title="Tidligere boliger"
            description="Få tilgang til dokumentasjon, produkter og håndverkere fra tidligere boliger."
            image={FormerHouses}
            linkText="Kommer snart!"
          />
        </div>
        <NewsletterSignup />
      </div>
    </StyledPersonalAreaLanding>
  );
};

export default PersonalAreaLanding;
