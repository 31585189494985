import React from 'react';

const BackArrow = ({ width = '1.2rem', height = '1.2rem', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.98438 17.5156C8.86719 17.5938 8.75 17.6328 8.63281 17.6328C8.47656 17.6328 8.39844 17.5938 8.32031 17.5156L0.15625 9.3125C0.0390625 9.23438 0 9.15625 0 9C0 8.88281 0.0390625 8.76562 0.15625 8.6875L8.32031 0.484375C8.39844 0.40625 8.47656 0.367188 8.63281 0.367188C8.75 0.367188 8.86719 0.40625 8.98438 0.484375L9.76562 1.26562C9.84375 1.34375 9.88281 1.46094 9.88281 1.57812C9.88281 1.73438 9.84375 1.85156 9.76562 1.92969L3.71094 7.98438H17.0312C17.1484 7.98438 17.2656 8.0625 17.3438 8.14062C17.4219 8.21875 17.5 8.33594 17.5 8.45312V9.54688C17.5 9.70312 17.4219 9.82031 17.3438 9.89844C17.2656 9.97656 17.1484 10.0156 17.0312 10.0156H3.71094L9.76562 16.0703C9.84375 16.1875 9.88281 16.3047 9.88281 16.4219C9.88281 16.5781 9.84375 16.6562 9.76562 16.7344L8.98438 17.5156Z"
      fill="#111111"
    />
  </svg>
);
export default BackArrow;
