const styles = (theme) => ({
  tagDiv: {
    backgroundColor: theme.palette.primary.red,
    width: '29px',
    borderRadius: '26px',
    color: '#ffff',
    textAlign: 'center',
    fontSize: '13px',
  },
});

export default styles;
