import React from 'react';

const ViewIcon = ({ width = '0.9rem', height = '0.9rem', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.7812 5.25C17.9062 5.5 18 5.75 18 6C18 6.28125 17.9062 6.53125 17.7812 6.75C16.875 8.28125 15.6562 9.5 14.125 10.375C12.5625 11.3125 10.8438 11.75 9 11.75C7.125 11.75 5.4375 11.3125 3.875 10.375C2.3125 9.5 1.09375 8.28125 0.21875 6.75C0.0625 6.53125 0 6.28125 0 6C0 5.75 0.0625 5.5 0.21875 5.25C1.09375 3.75 2.3125 2.53125 3.875 1.625C5.4375 0.71875 7.125 0.25 9 0.25C10.8438 0.25 12.5625 0.71875 14.125 1.625C15.6562 2.53125 16.875 3.75 17.7812 5.25ZM9 10.25C10.5625 10.25 12.0312 9.875 13.3438 9.09375C14.6562 8.34375 15.7188 7.3125 16.5 6C16 5.21875 15.4062 4.53125 14.7188 3.90625C14.0312 3.28125 13.25 2.78125 12.375 2.40625C12.9375 3.1875 13.25 4.0625 13.25 5C13.25 5.78125 13.0312 6.5 12.6562 7.125C12.2812 7.78125 11.75 8.3125 11.125 8.6875C10.4688 9.0625 9.75 9.25 9 9.25C8.21875 9.25 7.5 9.0625 6.875 8.6875C6.21875 8.3125 5.6875 7.78125 5.3125 7.125C4.9375 6.5 4.75 5.78125 4.75 5C4.75 4.3125 4.90625 3.625 5.25 3C5.25 3.5 5.40625 3.90625 5.75 4.25C6.09375 4.59375 6.5 4.75 7 4.75C7.46875 4.75 7.875 4.59375 8.21875 4.25C8.5625 3.90625 8.75 3.5 8.75 3C8.75 2.53125 8.5625 2.125 8.25 1.78125C6.8125 1.90625 5.53125 2.34375 4.34375 3.09375C3.15625 3.84375 2.1875 4.8125 1.5 6C2.25 7.3125 3.3125 8.34375 4.625 9.09375C5.9375 9.875 7.40625 10.25 9 10.25Z"
      fill="#111111"
    />
  </svg>
);
export default ViewIcon;
