const styles = (theme) => ({
  root: {
    padding: '0 15px',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  content: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '1115px',
      margin: '0 auto',
    },
  },
});

export default styles;
