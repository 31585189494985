import sortBy from 'lodash-es/sortBy';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';

import {
  uploadImage as apiUploadImage,
  updateProperty as apiUpdateProperty,
} from '../../../../api/properties';

import {
  getPropertyImages,
  getPropertyGoogleStreetViewPhotoEnabled,
  setPropertyGoogleStreetViewPhotoEnabled,
} from '../../../../utils/properties';

import {
  sGetActiveProperty,
  sGetPropertiesLoaded,
  sGetFetchingPropertiesError,
} from '../../../../store/reducers/properties';

import useSnackbar from '../../../../hooks/useSnackbar';
import Divider from '../../../../components/atomic/Divider';
import GoogleStreetViewField from './GoogleStreetViewField';
import ImageForm from '../../../../components/forms/ImageForm';
import ErrorContainer from '../../../../components/ErrorContainer';
import PageLayout from '../../../../components/layouts/WithHeader';
import { propertyUpdateAction } from '../../../../store/actions/properties';

const Images = ({ property, fetching, fetchingError, updateProperty }) => {
  const history = useHistory();
  const { boligmappaNumber } = useParams();
  const { showSuccessMessage } = useSnackbar();

  const [requestError, setRequestError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const [enableGoogleStreetViewPhoto, setEnableGoogleStreetViewPhoto] =
    useState(getPropertyGoogleStreetViewPhotoEnabled(boligmappaNumber));

  const byPosition = ({ position }) => position;
  const sortedImages = sortBy(getPropertyImages(property) || [], byPosition);

  const toggleGoogleStreetViewPhoto = () =>
    setEnableGoogleStreetViewPhoto(!enableGoogleStreetViewPhoto);

  const onSubmit = async (images) => {
    setRequestInProgress(true);

    const imageIds = images.map(({ id }) => id);

    const [error, response] = await apiUpdateProperty(boligmappaNumber, {
      imageIds,
    });

    if (error && !response) {
      setRequestError(error);
    } else {
      updateProperty({
        ...property,
        gallery: images,
      });

      setPropertyGoogleStreetViewPhotoEnabled(
        boligmappaNumber,
        enableGoogleStreetViewPhoto
      );

      showSuccessMessage();
      history.goBack();
    }

    setRequestInProgress(false);
  };

  const onImageUploadRequest = (image, position) =>
    apiUploadImage(boligmappaNumber, image, position);

  const onImagesUploaded = (images) =>
    updateProperty({
      ...property,
      gallery: [...(getPropertyImages(property) || []), ...images],
    });

  const googleStreetViewField = (
    <>
      <Divider />
      <GoogleStreetViewField
        property={property}
        onChange={toggleGoogleStreetViewPhoto}
        photoEnabled={enableGoogleStreetViewPhoto}
      />
    </>
  );

  return (
    <PageLayout minFullHeight headerTitle="Bilder">
      {fetching && fetchingError && (
        <ErrorContainer errorResponse={fetchingError} />
      )}

      {!fetchingError && (
        <ImageForm
          onSubmit={onSubmit}
          images={sortedImages}
          formError={requestError}
          fetching={fetching || !property}
          onImagesUploaded={onImagesUploaded}
          requestInProgress={requestInProgress}
          additionalFields={
            enableGoogleStreetViewPhoto ? googleStreetViewField : <></>
          }
          uploadImageRequest={onImageUploadRequest}
          origin="DASHBOARD"
        />
      )}
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  property: sGetActiveProperty(state),
  fetching: !sGetPropertiesLoaded(state),
  fetchingError: sGetFetchingPropertiesError(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateProperty: (property) => dispatch(propertyUpdateAction(property)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Images);
