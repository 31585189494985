import React, { useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as BaseLink } from '@material-ui/core';
import Box from '../Box';
import { isNativeEnv, openLinkRequest } from '../../../utils/nativeBridge';
import { getOriginalDocumentPath } from '../../../utils/documents';
import matomo from '../../../utils/matomo';

export const systemPrefixes = ['tel', 'http', 'mailto'];

const MultipleDownloadLink = ({
  documents,
  isExternal = true,
  disabled = false,
  openInNewTab = true,
  children,
  preventEncode = false,
}) => {
  const baseLinksRef = useRef([]);
  const nativeEnv = isNativeEnv();

  const trackMatomoEvent = () => {
    matomo.clickEvent({
      category: 'Document handling',
      name: '',
      action: 'Download Document from Document List',
      value: documents?.length,
    });
  };

  const getPath = (doc) => {
    const path = getOriginalDocumentPath(doc);

    if (
      isExternal &&
      systemPrefixes.some((prefix) => String(path).startsWith(prefix))
    ) {
      return path;
    }

    if (isExternal && !String(path).startsWith('http')) {
      return `https://${path}`;
    }

    return path;
  };

  const onParentClick = () => {
    trackMatomoEvent();
    baseLinksRef.current.forEach((baseLink) => {
      if (nativeEnv) {
        baseLink.click();
      } else {
        setTimeout(() => {
          window.open(baseLink.href, '_blank');
        }, 1000);
      }
    });
  };

  const onNativeParentClick = () => {
    trackMatomoEvent();
    documents.forEach((doc) => {
      openLinkRequest(getPath(doc), preventEncode);
    });
  };

  const linkConfig = {
    to: getPath(),
    href: getPath(),
    target: openInNewTab ? '_blank' : undefined,
    rel: openInNewTab ? 'noopener noreferrer' : undefined,
  };

  if (isExternal && nativeEnv) {
    return <Box onClick={onNativeParentClick}>{children}</Box>;
  }

  if (isExternal) {
    return (
      <>
        <Box onClick={onParentClick}>
          {documents.length > 0 &&
            documents.map((doc, idx) => (
              <BaseLink
                ref={(el) => {
                  baseLinksRef.current[idx] = el;
                }}
                href={getPath(doc)}
                target="_blank"
                rel="noopener noreferrer"
              />
            ))}
          {children}
        </Box>
      </>
    );
  }

  return (
    <RouterLink disabled={disabled} {...linkConfig}>
      {children}
    </RouterLink>
  );
};

export default MultipleDownloadLink;
