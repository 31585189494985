const styles = {
  container: {
    display: 'flex',
  },
  loader: {
    display: 'flex',
    padding: '0 24px 0 0',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  children: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
};

export default styles;
