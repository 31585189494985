import { reduce, size } from 'lodash-es';

import {
  areIdsEqual,
  compareAlphabetically,
  toLowerCase,
  toUpperCase,
  truncate,
} from './strings';
import { getRoomName } from './rooms';
import { getEventName } from './events';
import { formattedDate } from './dates';

import ArrowSortUp from '../assets/icons/ArrowSortUp';
import ArrowSortDown from '../assets/icons/ArrowSortDown';
import CsvIcon from '../assets/icons/DocumentIcons/CsvIcon';
import DocIcon from '../assets/icons/DocumentIcons/DocIcon';
import IfcIcon from '../assets/icons/DocumentIcons/IfcIcon';
import ImgIcon from '../assets/icons/DocumentIcons/ImgIcon';
import PdfIcon from '../assets/icons/DocumentIcons/PdfIcon';
import PptIcon from '../assets/icons/DocumentIcons/PptIcon';
import RtfIcon from '../assets/icons/DocumentIcons/RtfIcon';
import TxtIcon from '../assets/icons/DocumentIcons/TxtIcon';
import XlsIcon from '../assets/icons/DocumentIcons/XlsIcon';
import DocxIcon from '../assets/icons/DocumentIcons/DocxIcon';
import PptxIcon from '../assets/icons/DocumentIcons/PptxIcon';
import XlsxIcon from '../assets/icons/DocumentIcons/XlsxIcon';
import AudioIcon from '../assets/icons/DocumentIcons/AudioIcon';
import CobieIcon from '../assets/icons/DocumentIcons/CobieIcon';
import VideoIcon from '../assets/icons/DocumentIcons/VideoIcon';
import DefaultIcon from '../assets/icons/DocumentIcons/DefaultIcon';
import ChapterOne from '../assets/icons/DocumentChapters/ChapterOne';
import ChapterTwo from '../assets/icons/DocumentChapters/ChapterTwo';
import ChapterSix from '../assets/icons/DocumentChapters/ChapterSix';
import ChapterTen from '../assets/icons/DocumentChapters/ChapterTen';
import ChapterFour from '../assets/icons/DocumentChapters/ChapterFour';
import ChapterFive from '../assets/icons/DocumentChapters/ChapterFive';
import ChapterNine from '../assets/icons/DocumentChapters/ChapterNine';
import ChapterThree from '../assets/icons/DocumentChapters/ChapterThree';
import ChapterSeven from '../assets/icons/DocumentChapters/ChapterSeven';
import ChapterEight from '../assets/icons/DocumentChapters/ChapterEight';
import FileImageIcon from '../assets/icons/DocumentViewIcons/FileImageIcon';
import FileExcelIcon from '../assets/icons/DocumentViewIcons/FileExcelIcon';
import FileLinesIcon from '../assets/icons/DocumentViewIcons/FileLinesIcon';
import FileWordIcon from '../assets/icons/DocumentViewIcons/FileWordIcon';
import FileVideoIcon from '../assets/icons/DocumentViewIcons/FileVideoIcon';
import FileAudioIcon from '../assets/icons/DocumentViewIcons/FileAudioIcon';
import FileCodeIcon from '../assets/icons/DocumentViewIcons/FileCodeIcon';
import FileCsvIcon from '../assets/icons/DocumentViewIcons/FileCsvIcon';
import FilePowerpointIcon from '../assets/icons/DocumentViewIcons/FilePowerpointIcon';
import FilePdfIcon from '../assets/icons/DocumentViewIcons/FilePdfIcon';
import FileDefaultIcon from '../assets/icons/DocumentViewIcons/FileDefaultIcon';

export const getDocumentId = (document) => (document ? document.id : null);

export const getFolderId = (folder) => (folder ? folder.id : null);

export const getDocumentName = (document) =>
  document ? document.title || document.name : null;

export const getTruncatedDocName = (title) =>
  title && title.length > 50 ? truncate(title, { length: 50 }) : title;

export const getDocumentFileName = (document) =>
  document ? document.fileName : null;

export const getDocumentCompanyId = (document) =>
  document && document.companyId ? document.companyId : null;

export const getDocumentCompanyName = (document) =>
  document && document.companyName ? toUpperCase(document.companyName) : null;

export const getDocumentOwnedByName = (document) =>
  document && document.ownedByName ? document.ownedByName : null;

export const getDocumentUpdatedDate = (document) =>
  document ? document.updatedDate : null;

export const getDocumentCreatedDate = (document) =>
  document ? document.createdDate : null;

export const getDocumentType = (document) =>
  document ? document.documentType : null;

export const getDocumentTypeId = (document) =>
  document ? document.documentTypeId : null;

export const getCurrentDocumentType = (document) =>
  document
    ? {
        name: getDocumentType(document),
        label: getDocumentType(document),
        value: getDocumentTypeId(document),
      }
    : null;

export const getDocumentEvents = (document) =>
  document ? document.events : null;

export const getDocumentRooms = (document) =>
  document ? document.rooms : null;

export const extractFileType = (document) =>
  document && document.fileName ? document.fileName.split('.').pop() : null;

export const getDocumentFileType = (document) =>
  document && document.url && document.url.fileType
    ? document.url.fileType
    : extractFileType(document);

export const getUploadedFileType = (file) =>
  file && file.name ? file.name.split('.').pop() : null;

export const getOriginalDocumentPath = (document) =>
  document && document.url && document.url.link ? document.url.link : null;

export const getDocumentPath = (document) => {
  if (document && (document?.pdfconversion || document?.pdfFilePath))
    return document.pdfconversion || document?.pdfFilePath;
  return document?.url?.link || document?.url?.href || null;
};

export const getDocumentProfessionType = (document) =>
  document && document.professionType !== undefined
    ? document.professionType
    : null;

export const getDocumentProfessionTypeForDocumentView = (document) =>
  document &&
  document.professionType !== undefined &&
  toLowerCase(document.professionType) !== 'ikke spesifisert'
    ? document.professionType
    : null;

export const getDocumentChapterTags = (document) =>
  document && Array.isArray(document.chapterTags) ? document.chapterTags : [];

export const getDocumentDownloadRestricted = (document) =>
  document && document.downloadRestricted !== undefined
    ? document.downloadRestricted
    : null;

export const getDocumentIsBuilding = (document) =>
  document && document.isBuilding !== undefined ? document.isBuilding : null;

export const isCompanyDocument = (document) => document && document.companyId;

export const getProfessionalDocuments = (documents) => {
  const professionalDocuments = documents.filter(
    (document) =>
      document.companyId !== null &&
      document.companyId !== 0 &&
      document.companyId !== undefined
  );
  return professionalDocuments;
};

export const getByDocumentsCreatedDate = (documents, dateString) => {
  const documentList = documents.filter(
    (document) =>
      new Date(getDocumentCreatedDate(document)).getTime() <=
      new Date(dateString).getTime()
  );

  return documentList;
};

export const getDocumentsCreatedAfterDate = (documents, dateString) => {
  const documentList = documents.filter(
    (document) =>
      new Date(getDocumentCreatedDate(document)).getTime() >=
      new Date(dateString).getTime()
  );

  return documentList;
};

export const sortDocuments = (a, b, sortDirection = 'asc') =>
  compareAlphabetically(getDocumentName(a), getDocumentName(b), sortDirection);

export const filterByDocumentCategory = (document, query = '') => {
  const category = getDocumentType(document);

  return toLowerCase(category).includes(toLowerCase(query));
};

export const filterByDocumentFileType = (document, query) => {
  const fileType = getDocumentFileType(document);

  return toLowerCase(fileType)?.includes(toLowerCase(query));
};

export const filterByDocumentCreatedDate = (document, query) => {
  const createdDateString = formattedDate(getDocumentCreatedDate(document));

  return toLowerCase(String(createdDateString)).includes(toLowerCase(query));
};

export const filterByDocumentProfessionType = (document, query = '') =>
  toLowerCase(getDocumentProfessionType(document) || '').includes(
    toLowerCase(query)
  );

export const filterByDocumentName = (document, query = '') => {
  const name = getDocumentName(document) || '';

  return toLowerCase(name).includes(toLowerCase(query));
};

export const filterByDocumentOwnerName = (document, query = '') => {
  const ownerName =
    getDocumentOwnedByName(document) || getDocumentCompanyName(document) || '';

  return toLowerCase(ownerName).includes(toLowerCase(query));
};
export const truncateUploadedFileName = (file, maxLength) => {
  const docName = getDocumentName(file);
  const docType = getUploadedFileType(file);
  const tempDocName =
    docName.substring(docName.lastIndexOf('.')) === `.${docType}`
      ? docName
      : `${docName}.${docType}`;

  return docName.length <= maxLength
    ? tempDocName
    : `${docName.substring(0, maxLength - 6)}[...].${docType}`;
};

export const getRecentlylAddedProfessionalDocument = (documents) => {
  const nullOwnedDocuments = documents?.filter(
    (document) => document.companyId !== 0
  );
  nullOwnedDocuments?.sort(
    (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
  );
  return nullOwnedDocuments[0];
};

export const getRecentlylAddedUnInsuredProfessionalDocument = (
  documents,
  insuredDocuments
) => {
  const unInsuredDocuments = documents
    ?.filter((el) => !insuredDocuments.includes(el.id))
    .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));

  return unInsuredDocuments.length > 0 ? unInsuredDocuments : null;
};

export const defaultFileIcon = {
  fileType: 'default',
  category: 'ÅÅ',
  icon: DefaultIcon,
};

export const defaultFileIconForDocumentView = {
  fileType: 'default',
  category: 'ÅÅ',
  icon: FileDefaultIcon,
};

// Deciding on document icon
export const fileIcons = [
  {
    fileType: 'pdf',
    category: 'pdf',
    icon: PdfIcon,
  },
  {
    fileType: 'doc',
    category: 'doc',
    icon: DocIcon,
  },
  {
    fileType: 'docx',
    category: 'docx',
    icon: DocxIcon,
  },
  {
    fileType: 'txt',
    category: 'txt',
    icon: TxtIcon,
  },
  {
    fileType: 'rtf',
    category: 'rtf',
    icon: RtfIcon,
  },
  {
    fileType: 'xls',
    category: 'xls',
    icon: XlsIcon,
  },
  {
    fileType: 'xlsx',
    category: 'xlsx',
    icon: XlsxIcon,
  },
  {
    fileType: 'csv',
    category: 'csv',
    icon: CsvIcon,
  },
  {
    fileType: 'ppt',
    category: 'ppt',
    icon: PptIcon,
  },
  {
    fileType: 'pptx',
    category: 'pptx',
    icon: PptxIcon,
  },
  {
    fileType: 'jpeg',
    category: 'image',
    icon: ImgIcon,
  },
  {
    fileType: 'png',
    category: 'image',
    icon: ImgIcon,
  },
  {
    fileType: 'jpg',
    category: 'image',
    icon: ImgIcon,
  },
  {
    fileType: 'tiff',
    category: 'image',
    icon: ImgIcon,
  },
  {
    fileType: 'bmp',
    category: 'image',
    icon: ImgIcon,
  },
  {
    fileType: 'mp4',
    category: 'video',
    icon: VideoIcon,
  },
  {
    fileType: 'mpg',
    category: 'video',
    icon: VideoIcon,
  },
  {
    fileType: 'avi',
    category: 'video',
    icon: VideoIcon,
  },
  {
    fileType: 'mov',
    category: 'video',
    icon: VideoIcon,
  },
  {
    fileType: 'flv',
    category: 'video',
    icon: VideoIcon,
  },
  {
    fileType: 'mp3',
    category: 'audio',
    icon: AudioIcon,
  },
  {
    fileType: 'm4a',
    category: 'audio',
    icon: AudioIcon,
  },
  {
    fileType: 'flac',
    category: 'audio',
    icon: AudioIcon,
  },
  {
    fileType: 'wav',
    category: 'audio',
    icon: AudioIcon,
  },
  {
    fileType: 'cobie',
    category: 'cobie',
    icon: CobieIcon,
  },
  {
    fileType: 'ifc',
    category: 'ifc',
    icon: IfcIcon,
  },
  defaultFileIcon,
];

export const fileIconsForDocumentView = [
  {
    fileType: 'pdf',
    category: 'pdf',
    icon: FilePdfIcon,
  },
  {
    fileType: 'doc',
    category: 'doc',
    icon: FileWordIcon,
  },
  {
    fileType: 'docx',
    category: 'docx',
    icon: FileWordIcon,
  },
  {
    fileType: 'txt',
    category: 'txt',
    icon: FileLinesIcon,
  },
  {
    fileType: 'rtf',
    category: 'rtf',
    icon: FileLinesIcon,
  },
  {
    fileType: 'xls',
    category: 'xls',
    icon: FileExcelIcon,
  },
  {
    fileType: 'xlsx',
    category: 'xlsx',
    icon: FileExcelIcon,
  },
  {
    fileType: 'csv',
    category: 'csv',
    icon: FileCsvIcon,
  },
  {
    fileType: 'ppt',
    category: 'ppt',
    icon: FilePowerpointIcon,
  },
  {
    fileType: 'pptx',
    category: 'pptx',
    icon: FilePowerpointIcon,
  },
  {
    fileType: 'jpeg',
    category: 'image',
    icon: FileImageIcon,
  },
  {
    fileType: 'png',
    category: 'image',
    icon: FileImageIcon,
  },
  {
    fileType: 'jpg',
    category: 'image',
    icon: FileImageIcon,
  },
  {
    fileType: 'tiff',
    category: 'image',
    icon: FileImageIcon,
  },
  {
    fileType: 'bmp',
    category: 'image',
    icon: FileImageIcon,
  },
  {
    fileType: 'mp4',
    category: 'video',
    icon: FileVideoIcon,
  },
  {
    fileType: 'mpg',
    category: 'video',
    icon: FileVideoIcon,
  },
  {
    fileType: 'avi',
    category: 'video',
    icon: FileVideoIcon,
  },
  {
    fileType: 'mov',
    category: 'video',
    icon: FileVideoIcon,
  },
  {
    fileType: 'flv',
    category: 'video',
    icon: FileVideoIcon,
  },
  {
    fileType: 'mp3',
    category: 'audio',
    icon: FileAudioIcon,
  },
  {
    fileType: 'm4a',
    category: 'audio',
    icon: FileAudioIcon,
  },
  {
    fileType: 'flac',
    category: 'audio',
    icon: FileAudioIcon,
  },
  {
    fileType: 'wav',
    category: 'audio',
    icon: FileAudioIcon,
  },
  {
    fileType: 'cobie',
    category: 'cobie',
    icon: FileCodeIcon,
  },
  {
    fileType: 'ifc',
    category: 'ifc',
    icon: FileCodeIcon,
  },
  defaultFileIconForDocumentView,
];

export const imageFileTypes = ['jpeg', 'png', 'jpg', 'tiff', 'bmp'];

export const isDocumentImage = (document) =>
  imageFileTypes.includes(toLowerCase(getDocumentFileType(document)));

export const isDocumentsFolder = (document) => !document?.fileName;

export const getFileThumbNail = (documentType) => {
  const fileType = fileIcons.filter(
    (icon) => icon.fileType === documentType
  )[0];
  return fileType ? fileType.icon : DefaultIcon;
};

export const getFileThumbNailForDocumentView = (documentType) => {
  const fileType = fileIconsForDocumentView.filter(
    (icon) => icon.fileType === documentType
  )[0];
  return fileType ? fileType.icon : FileDefaultIcon;
};

export const NAME_ASC = 'NAME_ASC';
export const NAME_DESC = 'NAME_DESC';
export const UPDATED_DATE_ASC = 'UPDATED_DATE_ASC';
export const UPDATED_DATE_DESC = 'UPDATED_DATE_DESC';
export const FILETYPE_ASC = 'FILETYPE_ASC';
export const FILETYPE_DESC = 'FILETYPE_DESC';
export const DOCUMENT_TYPE_ASC = 'DOCUMENT_TYPE_ASC';
export const DOCUMENT_TYPE_DESC = 'DOCUMENT_TYPE_DESC';
export const DOCUMENT_OWNER_ASC = 'DOCUMENT_OWNER_ASC';
export const DOCUMENT_OWNER_DESC = 'DOCUMENT_OWNER_DESC';

export const DOCUMENT_NAME_COLUMN = 'DOCUMENT_NAME_COLUMN';
export const DOCUMENT_OWNER_COLUMN = 'DOCUMENT_OWNER_COLUMN';
export const DOCUMENT_CATEGORY_COLUMN = 'DOCUMENT_CATEGORY_COLUMN';
export const DOCUMENT_FILE_TYPE_COLUMN = 'DOCUMENT_FILE_TYPE_COLUMN';
export const DOCUMENT_CREATED_DATE_COLUMN = 'DOCUMENT_CREATED_DATE_COLUMN';

export const DEFAULT_DOWNLOAD_RESTRICTION_MESSAGE =
  'Filene har rettighetsbegrensninger som ikke tillater nedlasting.';
export const DEFAULT_DOWNLOAD_SUCCESS_MESSAGE = ' Nedlastingen var vellykket';

export const groupingSortTypes = [DOCUMENT_TYPE_ASC, DOCUMENT_TYPE_DESC];

export const PROPERTY_DOCUMENT = 0;
export const PERSONAL_DOCUMENT = 1;

export const documentSortTypes = {
  [UPDATED_DATE_DESC]: {
    id: UPDATED_DATE_DESC,
    icon: ArrowSortUp,
    column: DOCUMENT_CREATED_DATE_COLUMN,
    label: 'Dato lastet opp (synkende rekkefølge)',
    sortFunction: (a, b) =>
      compareAlphabetically(
        getDocumentCreatedDate(a),
        getDocumentCreatedDate(b),
        'asc'
      ),
  },
  [UPDATED_DATE_ASC]: {
    id: UPDATED_DATE_ASC,
    icon: ArrowSortDown,
    column: DOCUMENT_CREATED_DATE_COLUMN,
    label: 'Dato lastet opp (stigende rekkefølge)',
    sortFunction: (a, b) =>
      compareAlphabetically(
        getDocumentCreatedDate(a),
        getDocumentCreatedDate(b),
        'desc'
      ),
  },
  [NAME_ASC]: {
    id: NAME_ASC,
    icon: ArrowSortDown,
    column: DOCUMENT_NAME_COLUMN,
    label: 'Filnavn ( fra A til Å)',
    sortFunction: (a, b) =>
      compareAlphabetically(getDocumentName(a), getDocumentName(b), 'asc'),
  },
  [NAME_DESC]: {
    id: NAME_DESC,
    icon: ArrowSortUp,
    column: DOCUMENT_NAME_COLUMN,
    label: 'Filnavn (fra Å til A)',
    sortFunction: (a, b) =>
      compareAlphabetically(getDocumentName(a), getDocumentName(b), 'desc'),
  },
  [FILETYPE_ASC]: {
    id: FILETYPE_ASC,
    icon: ArrowSortDown,
    label: 'Filtype (fra A til Å)',
    column: DOCUMENT_FILE_TYPE_COLUMN,
    sortFunction: (a, b) =>
      compareAlphabetically(
        getDocumentFileType(a),
        getDocumentFileType(b),
        'asc'
      ),
  },
  [FILETYPE_DESC]: {
    id: FILETYPE_DESC,
    icon: ArrowSortUp,
    label: 'Filtype (fra Å til A)',
    column: DOCUMENT_FILE_TYPE_COLUMN,
    sortFunction: (a, b) =>
      compareAlphabetically(
        getDocumentFileType(a),
        getDocumentFileType(b),
        'desc'
      ),
  },
  [DOCUMENT_TYPE_ASC]: {
    id: DOCUMENT_TYPE_ASC,
    icon: ArrowSortDown,
    direction: 'asc',
    column: DOCUMENT_CATEGORY_COLUMN,
    label: 'Dokumenttype (fra A til Å)',
    sortFunction: (a, b) =>
      compareAlphabetically(getDocumentType(a), getDocumentType(b), 'asc'),
  },
  [DOCUMENT_TYPE_DESC]: {
    id: DOCUMENT_TYPE_DESC,
    icon: ArrowSortUp,
    direction: 'desc',
    column: DOCUMENT_CATEGORY_COLUMN,
    label: 'Dokumenttype (fra Å til A)',
    sortFunction: (a, b) =>
      compareAlphabetically(getDocumentType(a), getDocumentType(b), 'desc'),
  },
  [DOCUMENT_OWNER_ASC]: {
    id: DOCUMENT_OWNER_ASC,
    icon: ArrowSortDown,
    direction: 'asc',
    desktopOnly: true,
    column: DOCUMENT_OWNER_COLUMN,
    label: 'Avsender (fra A til Å)',
    sortFunction: (a, b) =>
      compareAlphabetically(
        getDocumentOwnedByName(a) || getDocumentCompanyName(a),
        getDocumentOwnedByName(b) || getDocumentCompanyName(b),
        'asc'
      ),
  },
  [DOCUMENT_OWNER_DESC]: {
    id: DOCUMENT_OWNER_DESC,
    icon: ArrowSortUp,
    direction: 'asc',
    desktopOnly: true,
    column: DOCUMENT_OWNER_COLUMN,
    label: 'Avsender (fra Å til A)',
    sortFunction: (a, b) =>
      compareAlphabetically(
        getDocumentOwnedByName(a) || getDocumentCompanyName(a),
        getDocumentOwnedByName(b) || getDocumentCompanyName(b),
        'desc'
      ),
  },
};

export const getDefaultDocumentSortType = () =>
  documentSortTypes[UPDATED_DATE_ASC];

export const getDocumentSortType = (type) => documentSortTypes[type];

export const getDocumentsByType = (documents) => {
  if (!Array.isArray(documents)) {
    return documents;
  }

  return documents.reduce((documentsByType, document) => {
    if (documentsByType[getDocumentType(document)]) {
      return {
        ...documentsByType,
        [getDocumentType(document)]: [
          ...documentsByType[getDocumentType(document)],
          document,
        ],
      };
    }

    return {
      ...documentsByType,
      [getDocumentType(document)]: [document],
    };
  }, {});
};

export const sortByDocumentType = (a, b) =>
  compareAlphabetically(getDocumentType(a), getDocumentType(b)) ||
  compareAlphabetically(getDocumentName(a), getDocumentName(b));

export const chapterOne = {
  id: 1,
  name: 'Generell brukerveiledning bolig',
  documentCount: 1,
  icon: ChapterOne,
};

export const chapterOthers = {
  id: 10,
  name: 'Annet nyttig',
  icon: ChapterTen,
};

export const chapters = [
  chapterOne,
  {
    id: 2,
    name: 'Informasjon om produkter i boligen',
    icon: ChapterTwo,
  },
  {
    id: 3,
    name: 'Informasjon om tekniske anlegg i boligen',
    icon: ChapterThree,
  },
  {
    id: 4,
    name: 'Samsvarserklæringer og garantibevis',
    icon: ChapterFour,
  },
  {
    id: 5,
    name: 'Sjekklister, målinger og kontrollskjema',
    icon: ChapterFive,
  },
  {
    id: 6,
    name: 'Tegninger og bilder',
    icon: ChapterSix,
  },
  {
    id: 7,
    name: 'Ferdigattest og andre offentlige dokumenter',
    icon: ChapterSeven,
  },
  {
    id: 8,
    name: 'Takster og prospekter',
    icon: ChapterEight,
  },
  {
    id: 9,
    name: 'Fargekoder, avtaler og kvitteringer',
    icon: ChapterNine,
  },
  chapterOthers,
];

export const groupDocumentsByChapters = (documents) =>
  documents.reduce((map, document) => {
    const { chapterTags } = document;

    if (!Array.isArray(chapterTags) || chapterTags.length === 0) {
      return {
        ...map,
        [chapterOthers.id]: [...(map[chapterOthers.id] || []), document],
      };
    }

    const relatedChapters = chapterTags.reduce((result, chapterTag) => {
      return {
        ...result,
        [chapterTag.id]: [...(map[chapterTag.id] || []), document],
      };
    }, {});

    return {
      ...map,
      ...relatedChapters,
    };
  }, {});

export const isProfessionTypeSpecified = (professionType) =>
  professionType !== null && toLowerCase(professionType) !== 'ikke spesifisert';

export const STATIC_COLUMN = 'STATIC_COLUMN';
export const FILE_TYPE_FILTER = 'FILE_TYPE_FILTER';
export const FILE_NAME_FILTER = 'FILE_NAME_FILTER';
export const DOCUMENT_TYPE_FILTER = 'DOCUMENT_TYPE_FILTER';
export const DOCUMENT_ROOM_FILTER = 'DOCUMENT_ROOM_FILTER';
export const DOCUMENT_EVENT_FILTER = 'DOCUMENT_EVENT_FILTER';
export const FILE_UPLOADED_NAME_FILTER = 'FILE_UPLOADED_NAME_FILTER';
export const DOCUMENT_UPLOADED_DATE_FILTER = 'DOCUMENT_UPLOADED_DATE_FILTER';
export const DOCUMENT_PROFESSION_TYPE_FILTER =
  'DOCUMENT_PROFESSION_TYPE_FILTER';

export const ATTACH_ROOM_EDIT_OPTION = 'ATTACH_ROOM_EDIT_OPTION';
export const ATTACH_EVENT_EDIT_OPTION = 'ATTACH_EVENT_EDIT_OPTION';
export const DOCUMENT_TYPE_EDIT_OPTION = 'DOCUMENT_TYPE_EDIT_OPTION';
export const DOCUMENT_DOWNLOAD_OPTION = 'DOCUMENT_DOWNLOAD_OPTION';
export const DOCUMENT_DELETE_OPTION = 'DOCUMENT_DELETE_OPTION';
export const DOCUMENT_DELETE_OPTION_PERSONAL =
  'DOCUMENT_DELETE_OPTION_PERSONAL';
export const COPY_DOCUMENT_TO_PROPERTY_OPTION =
  'COPY_DOCUMENT_TO_PROPERTY_OPTION';
export const MOVE_DOCUMENT_TO_PROPERTY_OPTION =
  'MOVE_DOCUMENT_TO_PROPERTY_OPTION';
export const MOVE_BULK_DOCUMENT_TO_PERSONAL_OPTION =
  'MOVE_BULK_DOCUMENT_TO_PERSONAL_OPTION';

export const getFilteredDocuments = ({
  filters,
  documents,
  documentFolders = [],
  chapterId,
  shouldSort,
  searchQuery,
  documentIds,
  sortFunction,
  isPersonalDocuments,
}) => {
  if (!Array.isArray(documents)) {
    return documents;
  }

  // stringify ids so that .includes filter works properly
  const stringifiedDocumentIds =
    Array.isArray(documentIds) && documentIds.map(String);

  const filterByDocumentIds = (document) => {
    if (!Array.isArray(documentIds) || documentIds.length === 0) {
      return true;
    }

    return stringifiedDocumentIds.includes(String(getDocumentId(document)));
  };

  const filterByChapterId = (document) => {
    if (!chapterId) return true;

    const chapterTags = getDocumentChapterTags(document);
    const chapterIds = chapterTags.map(({ id }) => String(id));

    if (chapterIds.length === 0 && areIdsEqual(chapterOthers.id, chapterId)) {
      return true;
    }

    return chapterIds.includes(String(chapterId));
  };

  const filterByFilename = (document) => {
    if (
      !Array.isArray(filters[FILE_NAME_FILTER]) ||
      filters[FILE_NAME_FILTER].length === 0
    ) {
      return true;
    }

    return filters[FILE_NAME_FILTER].includes(getDocumentName(document));
  };

  const filterByFiletype = (document) => {
    if (
      !Array.isArray(filters[FILE_TYPE_FILTER]) ||
      filters[FILE_TYPE_FILTER].length === 0
    ) {
      return true;
    }

    return filters[FILE_TYPE_FILTER].map(toLowerCase).includes(
      toLowerCase(getDocumentFileType(document))
    );
  };

  const filterByUploadedName = (document) => {
    if (
      !Array.isArray(filters[FILE_UPLOADED_NAME_FILTER]) ||
      filters[FILE_UPLOADED_NAME_FILTER].length === 0
    ) {
      return true;
    }

    return (
      filters[FILE_UPLOADED_NAME_FILTER].includes(
        getDocumentOwnedByName(document)
      ) ||
      filters[FILE_UPLOADED_NAME_FILTER].includes(
        getDocumentCompanyName(document)
      )
    );
  };

  const filterByDocumentType = (document) => {
    if (
      !Array.isArray(filters[DOCUMENT_TYPE_FILTER]) ||
      filters[DOCUMENT_TYPE_FILTER].length === 0
    ) {
      return true;
    }

    return filters[DOCUMENT_TYPE_FILTER].includes(getDocumentType(document));
  };

  const filterByUploadedDate = (document) => {
    if (
      !Array.isArray(filters[DOCUMENT_UPLOADED_DATE_FILTER]) ||
      filters[DOCUMENT_UPLOADED_DATE_FILTER].length === 0
    ) {
      return true;
    }

    const startDate = filters[DOCUMENT_UPLOADED_DATE_FILTER].find(
      (filter) => filter.key === 'start'
    );

    const endDate = filters[DOCUMENT_UPLOADED_DATE_FILTER].find(
      (filter) => filter.key === 'end'
    );

    if (startDate && getDocumentCreatedDate(document) < startDate.value) {
      return false;
    }

    if (endDate && getDocumentCreatedDate(document) > endDate.value) {
      return false;
    }

    return true;
  };

  const filterByProfessionType = (document) => {
    if (
      !Array.isArray(filters[DOCUMENT_PROFESSION_TYPE_FILTER]) ||
      filters[DOCUMENT_PROFESSION_TYPE_FILTER].length === 0
    ) {
      return true;
    }

    return filters[DOCUMENT_PROFESSION_TYPE_FILTER].includes(
      getDocumentProfessionType(document)
    );
  };

  const filterByEvents = (document) => {
    if (
      !Array.isArray(filters[DOCUMENT_EVENT_FILTER]) ||
      filters[DOCUMENT_EVENT_FILTER].length === 0
    ) {
      return true;
    }

    return (getDocumentEvents(document) || []).some((event) =>
      filters[DOCUMENT_EVENT_FILTER].includes(getEventName(event))
    );
  };

  const filterByRooms = (document) => {
    if (
      !Array.isArray(filters[DOCUMENT_ROOM_FILTER]) ||
      filters[DOCUMENT_ROOM_FILTER].length === 0
    ) {
      return true;
    }

    return (getDocumentRooms(document) || []).some((room) =>
      filters[DOCUMENT_ROOM_FILTER].includes(getRoomName(room))
    );
  };

  const filterByFilters = (document) => {
    if (size(filters) === 0) {
      return true;
    }

    return (
      filterByRooms(document) &&
      filterByEvents(document) &&
      filterByFiletype(document) &&
      filterByFilename(document) &&
      filterByDocumentType(document) &&
      filterByUploadedDate(document) &&
      filterByUploadedName(document) &&
      filterByProfessionType(document)
    );
  };

  if (!searchQuery) {
    const filteredDocuments = documents.filter(
      (document) =>
        filterByDocumentIds(document) &&
        filterByChapterId(document) &&
        filterByFilters(document)
    );

    return [
      ...documentFolders,
      ...(shouldSort
        ? filteredDocuments.sort(sortFunction)
        : filteredDocuments),
    ];
  }

  const filteredBySearchQuery = [...documents, ...documentFolders].filter(
    (document) =>
      (filterByFilters(document) &&
        filterByChapterId(document) &&
        filterByDocumentIds(document) &&
        (filterByDocumentName(document, searchQuery) ||
          (!isPersonalDocuments &&
            filterByDocumentCategory(document, searchQuery)) ||
          filterByDocumentFileType(document, searchQuery) ||
          filterByDocumentOwnerName(document, searchQuery) ||
          filterByDocumentCreatedDate(document, searchQuery))) ||
      filterByDocumentProfessionType(document, searchQuery)
  );
  return [
    ...(shouldSort
      ? filteredBySearchQuery.sort(sortFunction)
      : filteredBySearchQuery),
  ];
};

export const areFiltersEmpty = (filters) =>
  size(filters) === 0 ||
  reduce(filters, (result, value) => result + value.length, 0) === 0;

export const isDocumentReadOnly = (document) =>
  getDocumentDownloadRestricted(document) === true;

export const sortDocumentOptions = (documents) =>
  Array.isArray(documents)
    ? documents
        .sort((a, b) =>
          compareAlphabetically(
            getDocumentUpdatedDate(b),
            getDocumentUpdatedDate(a)
          )
        )
        .filter((document) => !getDocumentIsBuilding(document))
        .map((document) => ({
          document,
          value: getDocumentId(document),
          label: getDocumentName(document),
        }))
    : [];

export const mapDocumentIds = (documents) =>
  Array.isArray(documents) ? documents.map(({ id }) => id) : [];

export const mapDocumentOptions = (documents) =>
  Array.isArray(documents) ? documents.map(({ document }) => document) : [];

export const getSortType = ({ SortType }) => {
  let data = '';
  if (SortType === NAME_ASC || SortType === NAME_DESC) {
    data = 'Name';
  } else if (SortType === FILETYPE_ASC || SortType === FILETYPE_DESC) {
    data = 'Filetype';
  } else if (SortType === UPDATED_DATE_ASC || SortType === UPDATED_DATE_DESC) {
    data = 'Date';
  } else if (
    SortType === DOCUMENT_TYPE_ASC ||
    SortType === DOCUMENT_TYPE_DESC
  ) {
    data = 'Document Type';
  } else if (
    SortType === DOCUMENT_OWNER_ASC ||
    SortType === DOCUMENT_OWNER_DESC
  ) {
    data = 'Sender';
  }
  return data;
};

export const createDirectoryMap = (directories, parentDirectoryPath = '') => {
  const directoryMap = new Map();

  const mapDirectories = (dirList, currentPath = '') => {
    dirList.forEach((dir) => {
      const fullPath = `${currentPath}${dir.name}`;
      directoryMap.set(fullPath, dir.id);

      if (dir.directories.length > 0) {
        mapDirectories(dir.directories, `${fullPath}/`);
      }
    });
  };

  mapDirectories(directories, parentDirectoryPath);

  return directoryMap;
};

export const findFolderForFile = (filePath, directoryMap) => {
  const dirPath = filePath.substring(0, filePath.lastIndexOf('/'));
  return directoryMap.get(dirPath) || null;
};

export const getFolderIdfromStructure = (
  filePath,
  directoryStructureRespose
) => {
  const dirMap = createDirectoryMap(directoryStructureRespose);
  const folderId = findFolderForFile(filePath, dirMap);
  return folderId;
};
