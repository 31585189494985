export const useValidation = (fieldsToValidate, data) => {
  const supportedfields = ['company', 'selectedJobYearDone'];
  const fieldsWithValues = {};
  fieldsToValidate.forEach((el) => {
    fieldsWithValues[el] = data[el]?.toString() || '';
  });
  const validationData = {};
  fieldsToValidate.forEach((field) => {
    if (supportedfields.includes(field)) {
      if (field === 'selectedJobYearDone' || field === 'company') {
        validationData[field] = fieldsWithValues[field].length > 0;
      }
    }
  });
  const isValid = fieldsToValidate.every((el) => validationData[el] === true);
  return { isValid, validationData };
};
