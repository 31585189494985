import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router';

import { parseQuery } from '../../utils/routes';
import { storePrivateShareToken } from '../../utils/auth';
import { sGetIsAuthenticated } from '../../store/reducers/auth';

export const Home = ({ isAuthenticated }) => {
  const { search } = useLocation();

  const { logintoken } = parseQuery(search);

  useEffect(() => {
    if (logintoken) {
      storePrivateShareToken(logintoken);
    }
  }, [logintoken]);

  return isAuthenticated ? (
    <Redirect to="/properties" />
  ) : (
    <Redirect to="/login" />
  );
};

Home.defaultProps = {
  isAuthenticated: false,
};

Home.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: sGetIsAuthenticated(state),
});

export default connect(mapStateToProps, null)(Home);
