const styles = (theme) => ({
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  closeIcon: {
    width: '18px',
    height: '18px',
    borderRadius: '100%',
    color: theme.palette.primary.white,
    background: theme.palette.primary.greyLighter,
  },
});

export default styles;
