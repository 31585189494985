import React from 'react';
import Grid from '../../../atomic/Grid';
import ContentLoader from '../../../loaders/ContentLoader';

const TableLoading = ({ rows = 3, columns = 4, spacing = 5 }) => {
  const items = Array.from(Array(rows * columns).keys());

  return (
    <Grid container spacing={spacing}>
      {items.map((item) => (
        <Grid item xs={12 / columns} key={`grid-item-${item}`}>
          <ContentLoader width="100%" height={20} />
        </Grid>
      ))}
    </Grid>
  );
};

export default TableLoading;
