const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#FFEADD',
    borderRadius: '7px',
    flex: 'none',
    flexGrow: '0',
  },
  pageContainer: {
    padding: '24px 16px',
    gap: '16px',
    order: '1',
    alignSelf: 'stretch',
  },
  widgetContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    gap: '8px',
    order: '0',
  },
  title: {
    fontWeight: '400',
    left: '0px',
    top: '0px',
    textAlign: 'center',
  },
  pageTitle: {
    fontSize: '20px',
    lineHeight: '21px',
  },
  widgetTitle: {
    fontSize: '12px',
    lineHeight: '12px',
  },
  footer: {
    padding: 0,
    marginTop: '-10px',
    fontWeight: 400,
    textAlign: 'center',
  },
  pageFooter: {
    fontSize: '12px',
    lineHeight: '19px',
  },
  widgetFooter: {
    fontSize: '8px',
    lineHeight: '19px',
  },
  levelText: {
    fontWeight: 700,
    textAlign: 'center',
    textDecorationLine: 'underline',
    color: '#13142A',
  },
  widgetLevelText: {
    left: '0px',
    top: '2px',
    lineHeight: '12px',
    paddingRight: '12px',
    fontSize: '14px',
  },
  pageLevelText: {
    left: '0px',
    top: '0px',
    lineHeight: '21px',
    paddingRight: '16px',
    fontSize: '20px',
  },
  levelIcon: {
    verticalAlign: 'middle',
  },
  levelContainer: {
    display: 'inline-block',
  },
});

export default styles;
