import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import Box from '../../../../../../components/atomic/Box';
import Typography from '../../../../../../components/atomic/Typography';
import Button from '../../../../../../components/atomic/Button';
import Headline from '../../../../../../components/Headline';
import useDesktop from '../../../../../../hooks/useDesktop';

const BYBFEventCategorySuccess = ({ classes, nextStep, activeCategory }) => {
  const isDesktop = useDesktop();

  return (
    <Box className={classes.container}>
      <img
        alt="Success"
        src={activeCategory.successCategoryImageUrl}
        className={isDesktop ? classes.imageDesktop : classes.imageMobile}
      />

      <Headline mb={1} h="h2" align="center" showDivider={false}>
        Kjempebra jobba!
      </Headline>

      <Typography variant="body" className={classes.infoText}>
        {activeCategory.successCategoryText}
      </Typography>

      <Button
        color="primary"
        variant="contained"
        className={classes.getStartedBtn}
        onClick={() => nextStep()}
      >
        {activeCategory.successCategoryButtonText}
      </Button>
    </Box>
  );
};

export default withStyles(styles)(BYBFEventCategorySuccess);
