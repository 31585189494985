import { useEffect } from 'react';

// helper to easily add event listeners with un-subscription
export const useEventListner = (event, listener, eventTarget) => {
  useEffect(() => {
    const target = eventTarget || window;

    if (target && typeof target !== 'undefined') {
      target.addEventListener(event, listener);

      return () => {
        target.removeEventListener(event, listener);
      };
    }

    return () => {};
  }, []);
};
