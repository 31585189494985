const styles = (theme) => ({
  title: {
    margin: 0,
  },
  currentIndexDesktop: {
    padding: '0.3rem 40px',
  },
  currentIndexMobile: {
    padding: '0.3rem 1rem',
  },
  currentIndex: {
    display: 'block',
    marginBottom: '20px',
    color: theme.palette.primary.main,
    background: theme.colors.mint,
    fontSize: '13px',
    fontWeight: '500',
  },
  container: {
    minHeight: '100vh',
    paddingBottom: '70px',
    background: theme.palette.primary.white,
  },
});

export default styles;
