import LeavesImage from '../../../assets/images/leaves.png';
import PlantsImage from '../../../assets/images/plants.png';

const styles = (theme) => ({
  root: {
    position: 'relative',
    backgroundSize: '100px',
    background: [`url(${LeavesImage}) right top no-repeat`, '#fff'],

    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(3),
    },

    [theme.breakpoints.up('md')]: {
      backgroundSize: '200px',
    },
  },
  plantBackground: {
    background: [
      `url(${LeavesImage}) right top no-repeat`,
      `url(${PlantsImage}) left 10px bottom no-repeat`,
      '#fff',
    ],
    backgroundSize: '100px',

    [theme.breakpoints.up('md')]: {
      background: [
        `url(${LeavesImage}) right top no-repeat`,
        `url(${PlantsImage}) left 10px bottom 100px no-repeat`,
        '#fff',
      ],
      backgroundSize: ['200px', '140px'],
    },
  },
  floor: {
    [theme.breakpoints.up('md')]: {
      left: 0,
      bottom: 0,
      width: '100%',
      height: '100px',
      position: 'absolute',
      background: theme.palette.primary.main,
    },
  },
  logo: {
    width: '160px',
  },
});

export default styles;
