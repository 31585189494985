import { KeyboardEvent, useEffect, useState } from 'react';

export type NavigationType = 'keyboard' | 'mouse';
/**
 * In order to support setting scroll when navigating with keyboard
 * we need to define what type of navigation is used when we update the index
 */
export type NavigationIndex = {
  index: number;
  navigationType?: NavigationType;
};

export function useListNavigation(
  onCancel: () => void,
  onSelect: (index: number, closeSelect?: boolean) => void,
  numberOfItems: number,
  selectOnArrowMovement?: boolean,
) {
  const [isActive, setIsActive] = useState(false);

  const [selectedItem, setSelectedItem] = useState<NavigationIndex | undefined>(
    undefined,
  );

  const setSelectedItemIndex = (
    index?: number,
    navigationType?: NavigationType,
  ) => {
    if (typeof index === 'undefined') {
      setSelectedItem(undefined);
    }
    setSelectedItem({ index, navigationType });
  };

  useEffect(() => {
    if (selectOnArrowMovement && selectedItem !== undefined) {
      onSelect(selectedItem.index, false);
    }
  }, [selectedItem]);

  const onItemClick = (index: number) => {
    if (onSelect) {
      onSelect(index);
    }
    setIsActive(false);
    setSelectedItem(undefined);
  };

  const handleCancel = () => {
    setIsActive(false);
    setSelectedItem(undefined);
    if (onCancel) {
      onCancel();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Escape' || e.key === 'Tab') {
      handleCancel();
    }

    if (!numberOfItems) return;

    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }

    if (e.key === 'ArrowDown' && !isActive && numberOfItems > 0) {
      setIsActive(true);
    }

    if (e.key === 'Enter' && onSelect) {
      e.preventDefault();
      onItemClick(selectedItem.index);
    }

    const highestIndex = numberOfItems - 1;
    const itemIndexIsUndefined = typeof selectedItem === 'undefined';

    if (e.key === 'ArrowUp') {
      if (itemIndexIsUndefined || selectedItem.index === 0) {
        setSelectedItem({
          index: highestIndex,
          navigationType: 'keyboard',
        });
        return;
      }
      setSelectedItem((prev) => ({
        index: prev.index - 1,
        navigationType: 'keyboard',
      }));
    }

    if (e.key === 'ArrowDown') {
      if (itemIndexIsUndefined || selectedItem.index === highestIndex) {
        setSelectedItem({
          index: 0,
          navigationType: 'keyboard',
        });
        return;
      }

      setSelectedItem((prev) => ({
        index: prev.index + 1,
        navigationType: 'keyboard',
      }));
    }
  };

  return {
    selectedItemIndex: selectedItem?.index || undefined,
    setSelectedItemIndex,
    selectedItem,
    setSelectedItem,
    onItemClick,
    handleKeyDown,
    isActive,
    setIsActive,
    handleCancel,
  };
}
