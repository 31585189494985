import { useState, useEffect } from 'react';

export function useDebouncedState<T>(
  initialValue: T,
  time: number,
): [T, T, React.Dispatch<T>] {
  const [value, setValue] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

  useEffect(() => {
    let debounce;
    if (time && time > 0) {
      debounce = setTimeout(() => {
        setDebouncedValue(value);
      }, time);
    }
    return () => {
      if (debounce) {
        clearTimeout(debounce);
      }
    };
  }, [value, time]);

  return [debouncedValue, value, setValue];
}
