import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import useDesktop from '../../../../hooks/useDesktop';

import {
  acSetRooms,
  acSetFetchingRooms,
  acSetFetchingRoomsError,
} from '../../../../store/actions/rooms';

import {
  sGetRooms,
  sGetFetchingRooms,
  sGetFetchingRoomsError,
} from '../../../../store/reducers/rooms';

import RoomsPage from '../../../../components/RoomsPage';
import useFetchEffect from '../../../../hooks/useFetchEffect';
import { fetchPublicRooms as apiFetchRooms } from '../../../../api/rooms';

import ErrorContainer from '../../../../components/ErrorContainer';

import { PUBLICSECTIONFORBIDDENERROR } from '../../../../constants';

const Rooms = ({
  rooms,
  setRooms,
  fetching,
  setFetching,
  fetchingError,
  setFetchingError,
}) => {
  const { boligmappaNumber } = useParams();
  const isDesktop = useDesktop();

  useFetchEffect({
    defaultFetchingState: !rooms,
    conditionFunction: () => !rooms,
    onSuccess: (data) => setRooms(data),
    onFetchStart: () => setFetching(true),
    onFetchComplete: () => setFetching(false),
    onError: (error) => setFetchingError(error),
    apiFetchFunction: () => apiFetchRooms(boligmappaNumber),
  });

  return fetchingError ? (
    <ErrorContainer
      customErrorObject={{
        title: PUBLICSECTIONFORBIDDENERROR,
        message: '',
      }}
      paddingTop={isDesktop}
      errorResponse={fetchingError}
      style={{ paddingTop: isDesktop ? undefined : '15%' }}
    />
  ) : (
    <>
      <RoomsPage
        publicSection
        rooms={rooms}
        fetching={fetching}
        fetchingError={fetchingError}
        emptyMessage="Det finnes ingen rom"
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  rooms: sGetRooms(state),
  fetching: sGetFetchingRooms(state),
  fetchingError: sGetFetchingRoomsError(state),
});

const mapDispatchToProps = (dispatch) => ({
  setRooms: (rooms) => dispatch(acSetRooms(rooms)),
  setFetching: (fetching) => dispatch(acSetFetchingRooms(fetching)),
  setFetchingError: (error) => dispatch(acSetFetchingRoomsError(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rooms);
