import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import ProgressAnimation from '../ProgressAnimation';

const LoadingMessage = ({ classes, children = 'Laster opp...', ...props }) => (
  <div className={classes.container}>
    <div className={classes.loader}>
      <ProgressAnimation loading {...props} />
    </div>
    {children && <div className={classes.children}>{children}</div>}
  </div>
);

export default withStyles(styles)(LoadingMessage);
