import React from 'react';

import { withStyles } from '@material-ui/core';
import moment from 'moment';
import {
  getPropertyMatrikkel,
  getPropertyUnitNumber,
  getPropertyBoligmappaNumber,
  getPropertyType,
  getPropertyBuildingId,
  getPropertyShareNumber,
  getPropertyShareOrgNumber,
  getPropertyRadonHighestAnnualAverage,
} from '../../utils/properties';
import { formattedDate } from '../../utils/dates';
import Detail from './Detail';
import Box from '../../components/atomic/Box';
import Grid from '../../components/atomic/Grid';
import useDesktop from '../../hooks/useDesktop';
import Typography from '../../components/atomic/Typography';
import ContentLoader from '../../components/loaders/ContentLoader';
import styles from './style';
import Link from '../../components/atomic/Link';
import EnergyMarkIcon from '../../assets/icons/EnergyMarkOtovo';
import { enableEnergyMark, enableRadonAnnualAverage } from '../../config';

const PropertyInfo = ({ property, fetching, classes, unitFeatures }) => {
  const isDesktop = useDesktop();
  const unitNumber = getPropertyUnitNumber(property);
  const shareNumber = getPropertyShareNumber(property);
  const shareOrgNumber = getPropertyShareOrgNumber(property);
  const buildingNumber = getPropertyBuildingId(property);
  const radonHighestAnnualAverage =
    getPropertyRadonHighestAnnualAverage(property);
  const boligmappaNumber = getPropertyBoligmappaNumber(property);
  const radonUrl = `/dashboard/${boligmappaNumber}/addon/radon`;

  const issueDate = moment(unitFeatures?.certificateIssueDate);
  const expirationDate = issueDate.clone().add(10, 'years');
  const currentDate = moment();
  const energimerkingSupportURL =
    'https://support.boligmappa.no/hc/no/articles/13289842372882-Energimerking-fra-Enova';

  if (fetching) {
    return (
      <Grid container spacing={1}>
        <Grid item md={6} sm={6} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={12}>
              <Box display="flex" alignItems="center" my={2}>
                <ContentLoader variant="circle" height={30} mr={2} />
                <ContentLoader width="50%" height={20} />
              </Box>
            </Grid>
            <Grid item xs={6} md={12}>
              <Box display="flex" alignItems="center" my={2}>
                <ContentLoader variant="circle" height={30} mr={2} />
                <ContentLoader width="50%" height={20} />
              </Box>
            </Grid>
            <Grid item xs={6} md={12}>
              <Box display="flex" alignItems="center" my={2}>
                <ContentLoader variant="circle" height={30} mr={2} />
                <ContentLoader width="50%" height={20} />
              </Box>
            </Grid>
            <Grid item xs={6} md={12}>
              <Box display="flex" alignItems="center" my={2}>
                <ContentLoader variant="circle" height={30} mr={2} />
                <ContentLoader width="50%" height={20} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={12}>
              <Box display="flex" alignItems="center" my={2}>
                <ContentLoader variant="circle" height={30} mr={2} />
                <ContentLoader width="50%" height={20} />
              </Box>
            </Grid>
            <Grid item xs={6} md={12}>
              <Box display="flex" alignItems="center" my={2}>
                <ContentLoader variant="circle" height={30} mr={2} />
                <ContentLoader width="50%" height={20} />
              </Box>
            </Grid>
            <Grid item xs={6} md={12}>
              <Box display="flex" alignItems="center" my={2}>
                <ContentLoader variant="circle" height={30} mr={2} />
                <ContentLoader width="50%" height={20} />
              </Box>
            </Grid>
            <Grid item xs={6} md={12}>
              <Box display="flex" alignItems="center" my={2}>
                <ContentLoader variant="circle" height={30} mr={2} />
                <ContentLoader width="50%" height={20} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return (
    <Box>
      <Typography
        paragraph
        variant="h2"
        fontWeight={800}
        marginTop={false}
        align={isDesktop ? 'left' : 'center'}
      >
        Om boligen
      </Typography>

      <Grid container spacing={1}>
        {unitNumber && (
          <Grid item xs={6} sm={4} lg={6}>
            <Detail label="Bruksenhet">{unitNumber}</Detail>
          </Grid>
        )}

        <Grid item xs={6} sm={4} lg={6}>
          <Detail label="Matrikkel">{getPropertyMatrikkel(property)}</Detail>
        </Grid>

        {shareOrgNumber && shareOrgNumber !== 0 ? (
          <Grid item xs={6} sm={4} lg={6}>
            <Detail label="Borettslagets org. nr." icon={Number}>
              {shareOrgNumber}
            </Detail>
          </Grid>
        ) : null}

        {shareNumber && shareNumber !== 0 ? (
          <Grid item xs={6} sm={4} lg={6}>
            <Detail label="Andelsnummer" icon={Number}>
              {shareNumber}
            </Detail>
          </Grid>
        ) : null}

        {buildingNumber && (
          <Grid item xs={6} sm={4} lg={6}>
            <Detail label="Bygningsnummer">{buildingNumber}</Detail>
          </Grid>
        )}

        <Grid item xs={6} sm={4} lg={6}>
          <Detail label="Boligmappenummer">{boligmappaNumber}</Detail>
        </Grid>

        <Grid item xs={6} sm={4} lg={6}>
          <Detail label="Bygningstype">{getPropertyType(property)}</Detail>
        </Grid>

        {enableRadonAnnualAverage && (
          <Grid item xs={6} sm={4} lg={6}>
            <Detail label="Radon">
              <>
                {radonHighestAnnualAverage ? (
                  <>
                    <Grid container spacing={1}>
                      <Grid className={classes.text} item>
                        {radonHighestAnnualAverage} Bq/m <sup>3</sup>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Link
                          className={classes.seeMoreLink}
                          to="https://www.radonmannen.no/l%c3%a6r-om-radon"
                          isExternal
                          openInNewTab
                          isNoPadding
                        >
                          Les mer hos Radonmannen
                        </Link>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid container spacing={1}>
                    <Grid item>
                      <Link className={classes.seeMoreLink} to={radonUrl}>
                        Bestill radonmånling
                      </Link>
                    </Grid>
                  </Grid>
                )}
              </>
            </Detail>
          </Grid>
        )}
        {unitFeatures && enableEnergyMark === 'true' && (
          <Grid item xs={6} sm={4} lg={6}>
            <Detail label="Energimerking">
              <>
                {unitFeatures?.energyScore && unitFeatures?.heatingScore?.no ? (
                  <>
                    {expirationDate.isSameOrAfter(currentDate) ? (
                      <>
                        <Grid container spacing={1}>
                          <Grid item>
                            <EnergyMarkIcon
                              color={unitFeatures?.heatingScore?.en}
                              letter={unitFeatures?.energyScore}
                            />
                          </Grid>
                          <Grid item>
                            {unitFeatures?.energyScore} -{' '}
                            {unitFeatures?.heatingScore?.no}
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item>
                              <p
                                className={classes.energyMarkText}
                              >{`Gyldig til ${formattedDate(
                                expirationDate
                              )}`}</p>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid container spacing={1}>
                          <Grid item>
                            <EnergyMarkIcon
                              color={unitFeatures?.heatingScore?.en}
                              letter={unitFeatures?.energyScore}
                            />
                          </Grid>
                          <Grid item>
                            {unitFeatures?.energyScore} -{' '}
                            {unitFeatures?.heatingScore?.no}
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item>
                              <Link
                                className={classes.seeMoreLink}
                                to={energimerkingSupportURL}
                                isExternal
                                openInNewTab
                                isNoPadding
                              >
                                {`Utløpt ${formattedDate(expirationDate)}`}
                              </Link>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                ) : (
                  <Grid container spacing={1}>
                    <Grid item>
                      <Link
                        className={classes.seeMoreLink}
                        to={energimerkingSupportURL}
                        isExternal
                        openInNewTab
                        isNoPadding
                      >
                        Fant ingen gyldige energimerkinger
                      </Link>
                    </Grid>
                  </Grid>
                )}
              </>
            </Detail>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default withStyles(styles)(PropertyInfo);
