const styles = {
  content: {
    background: '#FAFAFA',
    borderRadius: '8px',
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'end',
  },
  image: {
    borderRadius: '8px',
    '@media only screen and (max-width: 1300px)': {
      width: '100%',
    },
    height: '100%',
    objectFit: 'cover',
  },
  textArea: {
    padding: '28px',
    color: '#000',
  },
  text: {
    fontWeight: '500',
    fontSize: '14px',
    '@media only screen and (max-width: 598px)': {
      fontSize: '14px',
    },
  },
  title: {
    color: '#000',
    // marginTop: '5px',
    fontWeight: '700',
    fontSize: '17px',
    '@media only screen and (max-width: 598px)': {
      fontSize: '18px',
    },
  },
  button: {
    marginRight: '10px',
    padding: '4px 20px',
    fontSize: '14px',
  },
  buttonRg: {
    fontSize: '14px',
    padding: '4px 20px',
    background: '#fff',
    border: '1px solid #D1EDE8',
  },
  btnContent: {
    display: 'flex',
    marginTop: '14px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '24px',
    padding: '24px',
    width: '374px',
    maxWidth: '100%',
  },
  submit_comment: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '500',
    color: ' #fff',
    padding: '12px 24px',
    width: '100%',
    border: 'none',
    borderRadius: '50px',
    backgroundColor: '#164144',
    transition: '0.3s',
    cursor: 'pointer',

    '&:hover': {
      boxShadow: '0 0 5px 1px #848484',
    },
  },
  wrapperRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  label: {
    color: '#000',
    fontWeight: '500',
  },
  commentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: ' 8px',
  },
  commentLabel: {
    fontSize: '14px',
    color: '#292823',
    margin: '0 0 7px 1px',
  },
  textarea: {
    fontSize: '14px',
    lineHeight: '24px',
    color: '#73748f',
    border: '1.5px solid #DDE2F1',
    borderRadius: '4px',
    padding: '12px 16px',
    width: '100%',
    margin: '0 4px',
  },
  wordLimit: {
    fontSize: '12px',
    lineHeight: '20px',
    color: '#73748f',
    alignSelf: 'flex-end',
  },
  contactHeader: {
    fontSize: '20px',
    lineHeight: '31px',
    color: '#111',
    fontWeight: ' 700',
    margin: '0',
  },
  contactText: {
    fontSize: '16px',
    color: '#111',
    fontWeight: '400',
    margin: '0',
  },
  successMessage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '24px',
    padding: ' 48px 24px',
    width: ' 374px',
    maxWidth: ' 100%',
  },
  messeageContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '16px',
    textAlign: 'center',
  },
  messeageTitle: {
    fontSize: '18px',
    lineHeight: '19px',
    fontWeight: '700',
    margin: '0',
  },
  messageText: {
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: '400',
    margin: '0',
  },
  closeButton: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '500',
    color: '#fff',
    padding: '12px 24px',
    width: 'calc(100% - 38px)',
    border: 'none',
    borderRadius: '50px',
    backgroundColor: '#164144',
    transition: '0.3s',
    cursor: 'pointer',

    '&:hover': {
      boxShadow: '0 0 5px 1px #848484',
    },
  },
  tag: {
    backgroundColor: '#5DC9A2',
    width: 'max-content',
    padding: '6px 8px',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '800',
  },
};
export default styles;
