import clsx from 'clsx';
import React, { createElement } from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Checkbox from '../../atomic/Checkbox';
import ListItem from '../../atomic/ListItem';
import Typography from '../../atomic/Typography';
import ListItemText from '../../atomic/ListItemText';
import ListItemSecondaryAction from '../../atomic/ListItemSecondaryAction';
import Box from '../../atomic/Box';

const ListBodyItem = ({
  icon,
  name,
  label,
  id,
  classes,
  checked,
  onClick,
  isDelete,
  onOptionSelect,
  selectedOptions,
  component = ListItem,
  ...props
}) =>
  // eslint-disable-next-line react/no-children-prop
  createElement(component, {
    button: true,
    divider: false,
    classes: {
      root: clsx(classes.listItem, 'word-break'),
    },
    onClick:
      onClick != null ? () => onClick() : () => onOptionSelect(id, label),
    children: !isDelete ? (
      <>
        <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
          <Checkbox
            checked={
              onClick ? checked : selectedOptions.some((so) => so.value === id)
            }
            className={classes.checkbox}
          />
        </ListItemSecondaryAction>

        <ListItemText className={classes.listItemText}>
          <Typography color="textPrimary">{label}</Typography>
        </ListItemText>
      </>
    ) : (
      <>
        <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
          <Box className={classes.iconBox}>{icon}</Box>
        </ListItemSecondaryAction>

        <ListItemText className={classes.listItemText}>
          <Typography className={label === 'slett' && classes.deleteText}>
            {label}
          </Typography>
        </ListItemText>
      </>
    ),
    ...props,
  });

export default withStyles(styles)(ListBodyItem);
