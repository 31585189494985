import React from 'react';

const Individual = (props) => (
  <svg
    viewBox="0 0 13.055 16.101"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <style>{`.a{fill:#8d8d8d;}`}</style>
    </defs>
    <path
      className="a"
      d="M443.4,2770.953a4.1,4.1,0,1,0-4.153-4.1A4.13,4.13,0,0,0,443.4,2770.953Z"
      transform="translate(-436.875 -2762.75)"
    />
    <path
      className="a"
      d="M443.027,2773.25a6.506,6.506,0,0,0-6.527,6.476.431.431,0,0,0,.435.432H449.12a.431.431,0,0,0,.435-.432,6.506,6.506,0,0,0-6.527-6.476Z"
      transform="translate(-436.5 -2764.057)"
    />
  </svg>
);

export default Individual;
