import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import {
  authScope,
  authPageUri,
  authClientId,
  authRedirectUri,
  authResponseType,
  enableHealthCheck,
} from '../../config';

import styles from './style';
import { stringifyQuery } from '../../utils/routes';
import LoadingScreen from '../../components/LoadingScreen';
import { LOCAL_API_HEALTH_STATUS } from '../../constants';
import { getApiHealthStatus } from '../../api/apiHealth';
import sessionStorage from '../../utils/sessionStorage';
import {
  acApiHealthSetFetching,
  acApiHealthSetFetchingError,
  acSetApiHealth,
} from '../../store/actions/apiHealth';

const LoginPage = ({
  setApiHealthFetching,
  setApiHealthError,
  setApiHealthStatus,
}) => {
  const history = useHistory();

  const setApiHealthStatusData = (data) => {
    setApiHealthStatus(data);
    sessionStorage.set(LOCAL_API_HEALTH_STATUS, data);
    if (data) {
      const query = stringifyQuery({
        scope: authScope,
        client_id: authClientId,
        redirect_uri: authRedirectUri,
        response_type: authResponseType,
      });

      const param = new URLSearchParams(window.location.search);

      const loginType =
        param.get('loginType') || sessionStorage.get('loginType');

      if (loginType) {
        window.location = `${authPageUri}?${query}&kc_idp_hint=${loginType}`;
      } else {
        window.location = `${authPageUri}?${query}`;
      }
    } else {
      history.push('/status');
    }
  };

  const getApiStatus = async () => {
    setApiHealthFetching(true);
    setApiHealthError(null);
    if (enableHealthCheck === 'true') {
      if (!(sessionStorage.get(LOCAL_API_HEALTH_STATUS) === 'true')) {
        const [error, data] = await getApiHealthStatus();
        if (error) {
          setApiHealthError(error);
          setApiHealthStatusData(false);
        } else {
          setApiHealthStatusData(data);
        }
        setApiHealthFetching(false);
      } else {
        setApiHealthStatusData(true);
        setApiHealthFetching(false);
      }
    } else {
      setApiHealthStatusData(true);
    }
  };

  useEffect(() => {
    getApiStatus();
  }, []);

  return <LoadingScreen />;
};

const mapDispatchToProps = (dispatch) => ({
  setApiHealthStatus: (status) => dispatch(acSetApiHealth(status)),
  setApiHealthFetching: (fetching) =>
    dispatch(acApiHealthSetFetching(fetching)),
  setApiHealthError: (error) => dispatch(acApiHealthSetFetchingError(error)),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(LoginPage));
