import React, { createElement } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import clsx from 'clsx';
import NewTag from '../../components/atomic/NewTag';
import { enableSurveyor, isProduction } from '../../config';
import NotificationsTag from '../../components/atomic/NotificationsTag';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';
import { StyledHeaderNav } from './styles';
import { sGetPendingRequests } from '../../store/reducers/accessRequests';
import { getPropertyType } from '../../utils/properties';
import { sGetPropertyByBoligmappaNumber } from '../../store/reducers/properties';
import localStorage from '../../utils/localStorage';
import { ACTIVE_PROPERTY_UID } from '../../constants';
import { useActiveProperty } from '../../store/hooks';
import {
  sGetNotificationsFetching,
  sGetUnseenNotifications,
} from '../../store/reducers/notifications';

const DesktopHeaderNav = ({
  unseenNotifications,
  pedningAccessRequests,
  getPropertyByBoligmappaNumber,
  fetchingNotifications,
  navbarMenuItems = [],
}) => {
  const { pathname } = useLocation();
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const { property } = useActiveProperty();
  const unseenNotificationsCount = unseenNotifications.length;
  const activePropertyId = localStorage.get(ACTIVE_PROPERTY_UID);
  const activeProperty = getPropertyByBoligmappaNumber(activePropertyId);
  const isPublicProperty = activeProperty && activeProperty.owned;

  const items = navbarMenuItems
    .filter((menuItem) => !menuItem.mobileOnly && !menuItem.isHide)
    .filter(({ experimental }) => {
      const isNotInProduction = !isProduction();
      if (isNotInProduction) return true;
      return !experimental;
    })
    .filter(
      (menuItem) =>
        !(menuItem.hideInPrivateProperty && !isPublicProperty) &&
        !(
          menuItem.hideOnLands &&
          getPropertyType(property) === 'Eiendom/tomt uten bygning'
        ) &&
        !(
          menuItem.hideOnGarageBuilding &&
          getPropertyType(property) === 'GARASJEUTHUS ANNEKS TIL BOLIG'
        )
    )
    .map(({ link, icon, title, isNotification, isNew, ...properties }) => ({
      link,
      icon,
      title,
      isNotification,
      badgeContent:
        isNotification && !fetchingNotifications
          ? unseenNotificationsCount
          : null,
      isNew,
      ...properties,
    }));

  return (
    <StyledHeaderNav>
      <ul className="sidebar_nav">
        {items.map(
          ({ link, icon, activeIcon, title, isNew, notificationLabel }) => (
            <li
              key={`sidebar-item-${link}`}
              className={clsx([
                'sidebar_item',
                pathname.includes(link) && 'active',
              ])}
            >
              <Link
                to={`${dashboardLinkPrefix}/${link}`}
                className="sidebar-link"
              >
                <div className="list_item_icon">{createElement(icon)}</div>
                {activeIcon && (
                  <div className="list_item_icon active_icon">
                    {createElement(activeIcon)}
                  </div>
                )}

                <p>{title}</p>
                {isNew && <NewTag />}
                {notificationLabel &&
                  pedningAccessRequests.length > 0 &&
                  enableSurveyor === 'true' && (
                    <NotificationsTag qty={pedningAccessRequests.length} />
                  )}
              </Link>
            </li>
          )
        )}
      </ul>
    </StyledHeaderNav>
  );
};
const mapStateToProps = (state) => ({
  unseenNotifications: sGetUnseenNotifications(state),
  pedningAccessRequests: sGetPendingRequests(state),
  fetchingNotifications: sGetNotificationsFetching(state),
  getPropertyByBoligmappaNumber: (boligmappaNumber) =>
    sGetPropertyByBoligmappaNumber(state, boligmappaNumber),
});
export default connect(mapStateToProps)(DesktopHeaderNav);
