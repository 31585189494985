import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import useDesktop from '../../../../hooks/useDesktop';
import PageLayout from '../../../../components/layouts/WithHeader';
import FAQ from '../../../../components/Jobs/FAQ';
import MDRForm from '../../../../components/Jobs/MDRForm';
import MDRBottomSection from '../../../../components/Jobs/MDRBottomSection';
import SentMDR from '../../../../components/Jobs/SentMDR';
import { JobStates } from '../../../../utils/jobsUtils';
import { useActiveProperty } from '../../../../store/hooks';
import { fetchMDRList } from '../../../../api/jobs';
import styles from './styles';
import translations from './translations.json';

const CreateMDR = ({ classes, language = 'nb' }) => {
  // ------------------------------------------------------------------------
  // --------------------------STATE & HOOKS---------------------------------
  // ------------------------------------------------------------------------
  const [sentRequests, setSentRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const pageTranslations = translations[language];
  const { property } = useActiveProperty();
  const isDesktop = useDesktop();

  // ------------------------------------------------------------------------
  // ----------------------------HANDLERS------------------------------------
  // ------------------------------------------------------------------------
  const filterSentMDR = (item) =>
    item.boligmappaNumber === property.boligmappaNumber &&
    item.status === JobStates.Pending;

  const fetchPendingJobsData = async () => {
    setIsLoading(true);
    await fetchMDRList(property.boligmappaNumber)
      .then((res) => {
        if (res[1]) {
          const newSentRequests = res[1].jobs.filter((item) =>
            filterSentMDR(item)
          );
          setSentRequests(newSentRequests ?? []);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (property) fetchPendingJobsData();
  }, [property]);

  return (
    <PageLayout
      noPaddingTop
      minFullHeight
      classes={{ title: classes.pageTitle }}
      noPadding={!isDesktop}
      lessMarginLeft={isDesktop}
      contentClass={classes.pageContent}
      headerTitle={pageTranslations.pageTitle}
      maxWidth="lg"
    >
      <div className={classes.topSection}>
        <MDRForm
          labels={pageTranslations}
          fetchPendingJobsData={fetchPendingJobsData}
        />

        <FAQ
          title={pageTranslations.faqTitle}
          FAQList={[
            {
              title: pageTranslations.faqQuestion1,
              content: pageTranslations.faqQuestion1Content,
            },
            {
              title: pageTranslations.faqQuestion2,
              content: pageTranslations.faqQuestion2Content,
            },
            {
              title: pageTranslations.faqQuestion3,
              content: pageTranslations.faqQuestion3Content,
            },
            {
              title: pageTranslations.faqQuestion4,
              content: pageTranslations.faqQuestion4Content,
            },
            {
              title: pageTranslations.faqQuestion5,
              content: pageTranslations.faqQuestion5Content,
            },
          ]}
        />
      </div>

      <MDRBottomSection
        title={pageTranslations.mdrBottomSectionTitle}
        content={pageTranslations.mdrBottomSectionContent}
        isDesktop={isDesktop}
      />

      <SentMDR
        labels={{
          title: pageTranslations.sentMDRTitle,
          subTitle: pageTranslations.sentMDRSubTitle,
          moreThanFiveyears: pageTranslations.moreThanFiveyears,
        }}
        isLoading={isLoading}
        sentRequests={sentRequests}
      />
    </PageLayout>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, null)(withStyles(styles)(CreateMDR));
