const styles = (theme) => ({
  listItem: {
    display: 'flex',
    padding: '20px 0',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.colors.whiteDarker}`,

    '&:last-child': {
      borderBottom: 'none',
    },
  },
  label: {
    fontWeight: 600,
    marginRight: '20px',
    color: theme.palette.text.secondary,
  },
  value: {
    display: 'flex',
    textAlign: 'right',
    alignItems: 'flex-end',
    flexDirection: 'column',
    color: theme.palette.text.primary,
  },
  listItemDisable: {
    display: 'none',
  },
});

export default styles;
