import React from 'react';

const UploadFolder = ({ width = '16', height = '14', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.84375 3L6.5 1.65625L7.5625 0.59375L6.5 1.65625C6.40625 1.5625 6.28125 1.5 6.15625 1.5H2C1.71875 1.5 1.5 1.75 1.5 2V12C1.5 12.2812 1.71875 12.5 2 12.5H14C14.25 12.5 14.5 12.2812 14.5 12V4C14.5 3.75 14.25 3.5 14 3.5H9.09375C8.625 3.5 8.1875 3.34375 7.84375 3ZM8.90625 1.9375C8.96875 2 9.03125 2 9.09375 2H14C15.0938 2 16 2.90625 16 4V12C16 13.125 15.0938 14 14 14H2C0.875 14 0 13.125 0 12V2C0 0.90625 0.875 0 2 0H6.15625C6.6875 0 7.1875 0.21875 7.5625 0.59375L8.90625 1.9375ZM8.75 10.75H8.71875C8.71875 11.1875 8.40625 11.5 7.96875 11.5C7.5625 11.5 7.21875 11.1875 7.21875 10.75V7.5625L6.25 8.53125C5.96875 8.84375 5.5 8.84375 5.21875 8.53125C4.90625 8.25 4.90625 7.78125 5.21875 7.46875L7.46875 5.21875C7.75 4.9375 8.21875 4.9375 8.5 5.21875L10.75 7.46875C11.0625 7.78125 11.0625 8.25 10.75 8.53125C10.4688 8.84375 10 8.84375 9.71875 8.53125L8.75 7.5625V10.75Z"
      fill="#111111"
    />
  </svg>
);

export default UploadFolder;
