const styles = (theme) => ({
  error: {
    color: theme.palette.primary.redDark,
  },
  labelsContainer: {
    marginBottom: '10px',
  },
  labelContainer: {
    paddingLeft: '4px',
  },
  datesContainer: {
    justifyContent: 'space-between',
  },
  dateLabel: {
    marginBottom: '-10px',
  },
  toLabelContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(1),
  },
  datePickerContainer: {
    alignItems: 'center',
  },
  formElement: {
    marginBottom: '30px',
  },
  labelElement: {
    marginBottom: '17px',
  },
  dialogDesktopPadding: {
    width: '500px',
    maxHeight: 'calc(100vh - 30vh)',
    padding: '20px 43px 20px 43px',
  },
  dialogPaddingMobile: {
    padding: '10px 15px 0px 15px',
  },
  actionButtons: {
    padding: '10px 0 10px 0',
    borderTop: '1px solid #dedfdf',
  },
  actionButtonsMobile: {
    padding: '15px 0 30px 0',
    borderTop: '1px solid #dedfdf',
  },
  actionButtonsCenter: {
    justifyContent: 'center',
  },
  confirmCloseActions: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 60px !important',
  },
  confirmCloseButton: {
    width: '200px',
  },
  nextBtn: {
    marginRight: 45,
    width: '130px',
    height: '50px',
  },
  nextBtnMobile: {
    marginRight: 15,
    width: '130px',
    height: '50px',
  },
  backBtn: {
    marginLeft: 45,
    marginRight: 'auto',
    width: '130px',
    height: '50px',
  },
  backBtnMobile: {
    marginLeft: 15,
    marginRight: 'auto',
    width: '130px',
    height: '50px',
  },
  eventImagesBox: {
    marginBottom: '30px',
  },
  addRoomBtn: {
    margin: '-10px 0 30px 0',
    fontWeight: 500,
    height: '30px',
    fontSize: '13px',
  },
  addIcon: {
    width: '20px',
  },
  dialogOverlayWrapper: {
    width: '100%',
    display: 'flex',
    zIndex: 2,
  },
  slideUp: {
    zIndex: 2,
    background: 'white',
    height: '100%',
    width: '100%',
  },
});

export default styles;
