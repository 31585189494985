import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router';
import React, {
  createElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useFlag } from '@unleash/proxy-client-react';
import clsx from 'clsx';
import SectionCallToAction from '@componentsV2/BoligmappaPlus/SectionCallToAction';
import InputField from '@vb/components/build/components/forms/inputs/InputField/InputField';
import BreadCrumbs from '@componentsV2/BreadCrumbs';
import BreadCrumbsFullPath from '@componentsV2/BreadCrumbsFullPath';
import InsuranceBanner from '../../../../components/Banners/InsuranceBanner/InsuranceBanner';
import useHeaderSnackbar from '../../../../hooks/useHeaderSnackbar';
import useDashboardLinkPrefix from '../../../../hooks/useDashboardLinkPrefix';
import Link from '../../../../components/atomic/Link';
import {
  acDeleteDocument,
  acSetDocuments,
  acSetDocumentSearchText,
  acSetFetchingDocuments,
  acSetFetchingDocumentsError,
  acSetIsCreatingDocumentFolder,
} from '../../../../store/actions/documents';

import {
  acSetPersonalDocuments,
  acSetFetchingPersonalDocuments,
  acSetFetchingPersonalDocumentsError,
  acDeletePersonalDocument,
  acSetPersonalDocumentFolders,
  acSetPersonalDocumentFoldersToRender,
  acDeletePersonalDocumentFolder,
  acSetSelectedPersonalFolderId,
  acSetPersonalFoldersBreadCrumbs,
} from '../../../../store/actions/personalDocuments';

import {
  sGetDocuments,
  sGetDocumentSearchText,
  sGetFetchingDocuments,
  sGetFetchingDocumentsError,
  sGetIsCreatingDocumentFolder,
} from '../../../../store/reducers/documents';
import {
  sGetFetchingPersonalDocuments,
  sGetPersonalDocumentFolders,
  sGetPersonalDocumentFoldersToRender,
  sGetPersonalDocuments,
  sGetPersonalFoldersBreadCrumbs,
} from '../../../../store/reducers/personalDocuments';
import { sGetUser } from '../../../../store/reducers/auth';
import styles from './style';
import ChapterList from './ChapterList';
import ChapterTable from './ChapterTable';
import Box from '../../../../components/atomic/Box';
import Divider from '../../../../components/Divider';
import useDesktop from '../../../../hooks/useDesktop';
import Page from '../../../../components/layouts/WithHeader';
import SearchField from '../../../../components/SearchField';
import useFetchEffect from '../../../../hooks/useFetchEffect';
import EmptyMessage from '../../../../components/EmptyMessage';
import IconButton from '../../../../components/atomic/IconButton';
import ErrorContainer from '../../../../components/ErrorContainer';
import { FileUploadContext } from '../../../../contexts/fileUpload';
import { parseQuery, stringifyQuery } from '../../../../utils/routes';
import { acDetachDocumentFromRooms } from '../../../../store/actions/rooms';
import { acDetachDocumentFromEvents } from '../../../../store/actions/events';
import { AddNewButton } from '../../../../components/Buttons';
import ChapterMode from '../../../../assets/icons/DocumentChapters/ChapterMode';
import { fetchDocuments as apiFetchDocuments } from '../../../../api/properties';
import {
  fetchPersonalDocuments as apiFetchPersonalDocuments,
  fetchPersonalFolders as apiFetchPersonalFolders,
} from '../../../../api/propertyowners';
import DocumentMode from '../../../../assets/icons/DocumentChapters/DocumentMode';
import Features from '../../../../modules/Features';
import { features } from '../../../../structure/features';
import Grid from '../../../../components/atomic/Grid';
import {
  getDocumentList,
  getDocumentTable,
} from '../../../../utils/DocumentTable';
import TabContext from '../../../../components/atomic/TabContext';
import TabPanel from '../../../../components/atomic/TabPanel';
import personalDocumentsCTA from '../../../../assets/images/personalDocumentsCTA.jpg';
import personalDocumentsCTAmob from '../../../../assets/images/personalDocumentsCTAmob.jpg';
import { isNativeEnv } from '../../../../utils/nativeBridge';
import { getPropertyOwnerSubscriptionStatus } from '../../../../utils/properties';
import { boligmappaPlusActionCallback } from '../../../../utils/boligmappaPlus';
import matomo from '../../../../utils/matomo';
import DocumentTabs from './DocumentTabs';
import MobileDocumentSearch from './MobileDocumentSearch';
import useSnackbar from '../../../../hooks/useSnackbar';
import useAddNewDocumentMenu from '../../../../hooks/useAddNewDocumentMenu';
import AddNewDocumentMenu from '../../../../components/AddNewDocumentMenu';
import BaseDialogForm from '../../../../components/forms/BaseDialogForm';
import Typography from '../../../../components/atomic/Typography';

const Documents = ({
  classes,
  fetching,
  personalDocumentfetching,
  documents,
  personalDocuments,
  personalDocumentFolders,
  personalDocumentFoldersToRender,
  setFetching,
  setPersonalDocumentFetching,
  setDocuments,
  setPersonalDocuments,
  setPersonalFoldersBreadCrumbs,
  setPersonalDocumentFolders,
  setPersonalDocumentFoldersToRender,
  personalFoldersBreadCrumbs,
  fetchingError,
  deleteDocument,
  deletePersonalDocument,
  setFetchingError,
  setPersonalDocumentFetchingError,
  detachDocumentFromRooms,
  detachDocumentFromEvents,
  searchText,
  setSearchText,
  isCreatingDocumentFolder,
  setIsCreatingDocumentFolder,
  activeUser,
  deletePersonalDocumentFolder,
  setSelectedPersonalFolderId,
}) => {
  const history = useHistory();
  const isDesktop = useDesktop();
  const { search, pathname } = useLocation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabValue = queryParams.get('personal');
  const parsedQuery = parseQuery(search);
  const [currentDocFolderId, setCurrentDocFolderId] = useState(null);
  const [documentsToRender, setDocumentsToRender] = useState([]);
  const [loading, setLoading] = useState(false);
  const [personalDocumentLoading, setPersonalDocumentLoading] = useState(false);
  const [tableVariant, setTableVariant] = useState(
    tabValue === 'true' ? '2' : '1'
  );
  const [headerHeight, setHeaderHeight] = useState(0);
  const [stickyFiltersHeight, setStickyFiltersHeight] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const enableDocumentsTab = useFlag('boligeier.document.tab');
  const enableBoligmappaPlus = useFlag('boligeier.boligmappaPlus');
  const folderFeatureFlag = useFlag('boligeier.folder.feature');
  const isApp = isNativeEnv();
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const { showSnackbar, closeSnackbar } = useHeaderSnackbar();
  const [isBoligmappaPlusUser, setIsBoligmappaPlusUser] = useState(true);

  const addNewDocRef = useRef(null);
  const {
    isAddingNewDoc,
    setIsAddingNewDoc,
    isCreatingName,
    setIsCreatingName,
    newFolderName,
    setNewFolderName,
    folderNameErrorMessage,
    isFolderNameError,
    onAddNewDoc,
    onFolderCreate,
    onCancelCreatingFolder,
  } = useAddNewDocumentMenu({
    currentDocFolderId,
    breadCrumbs: personalFoldersBreadCrumbs,
  });

  const onTabChange = (event, newValue) => {
    setTableVariant(newValue);
  };

  useEffect(() => {
    setIsCreatingName(isCreatingDocumentFolder);
  }, [isCreatingDocumentFolder]);

  useEffect(() => {
    setIsCreatingDocumentFolder(isCreatingName);
  }, [isCreatingName]);

  const missingDocumentFeature = features.filter(
    (feature) => feature.code === 'MISSING_DOCUMENTS'
  );

  const { ids: documentIds, chaptersMode } = parsedQuery || {};

  const { boligmappaNumber } = useParams();
  const {
    onFileUploadClick,
    directoryId,
    setDirectoryId,
    directoryTree,
    setDirectoryTree,
  } = useContext(FileUploadContext);
  const { showErrorMessage } = useSnackbar();

  const getLoading = (data) => {
    setFetching(data);
    setLoading(data);
  };

  const getPersonalDocumentLoading = (data) => {
    setPersonalDocumentFetching(data);
    setPersonalDocumentLoading(data);
  };

  useEffect(() => {
    if (enableBoligmappaPlus) {
      setIsBoligmappaPlusUser(activeUser?.isSubscribeToBoligmappaPlus);
    }
  }, [activeUser]);

  const updateDocsAndFoldersToRender = (folderId) => {
    setCurrentDocFolderId(folderId);
    let foldersToRender = personalDocumentFolders;
    personalFoldersBreadCrumbs.forEach((breadcrumb) => {
      foldersToRender = foldersToRender.find(
        (el) => el.id === breadcrumb.id
      ).directories;
    });
    setPersonalDocumentFoldersToRender(foldersToRender);
    setSelectedPersonalFolderId(folderId);
    setDocumentsToRender(
      personalDocuments.filter((doc) => doc.directoryId === folderId)
    );
  };
  const onFolderChange = (id) => {
    updateDocsAndFoldersToRender(id);
    const currentFolder = personalDocumentFoldersToRender.find(
      (folder) => folder.id === id
    );
    setPersonalFoldersBreadCrumbs([
      ...personalFoldersBreadCrumbs,
      currentFolder,
    ]);
  };

  const launchDocsAndFoldersState = ({ folders, docs }) => {
    if (folders) {
      setPersonalDocumentFolders(folders);
      setPersonalDocumentFoldersToRender(folders);
      setSelectedPersonalFolderId(null);
    }
    if (docs) {
      const docsToRender = docs.filter((el) => el.directoryId === null);
      setPersonalDocuments(docs);
      setDocumentsToRender(docsToRender);
    }
  };

  useFetchEffect({
    dependencies: [boligmappaNumber],
    setFetchingFunction: (data) => getLoading(data),
    onSuccess: (data) => setDocuments(data),
    onError: (error) => setFetchingError(error),
    conditionFunction: () => !documents && !fetching,
    apiFetchFunction: () =>
      apiFetchDocuments(boligmappaNumber, { includeTaggedInfo: true }),
  });

  useFetchEffect({
    conditionFunction: () => personalDocuments?.length === 0,
    setFetchingFunction: (data) => getPersonalDocumentLoading(data),
    onSuccess: (data) => launchDocsAndFoldersState({ docs: data.documents }),
    onError: (error) => setPersonalDocumentFetchingError(error),
    apiFetchFunction: () => apiFetchPersonalDocuments(),
  });

  useFetchEffect({
    conditionFunction: () => personalDocumentFolders?.length === 0,
    setFetchingFunction: (data) => getPersonalDocumentLoading(data),
    onSuccess: (data) =>
      launchDocsAndFoldersState({ folders: data.directories.reverse() }),
    onError: (error) => setPersonalDocumentFetchingError(error),
    apiFetchFunction: () => apiFetchPersonalFolders(),
  });

  useEffect(() => {
    setDocumentsToRender(
      personalDocuments.filter((doc) => doc.directoryId === currentDocFolderId)
    );
  }, [personalDocuments]);

  useEffect(() => {
    updateDocsAndFoldersToRender(
      personalFoldersBreadCrumbs[personalFoldersBreadCrumbs.length - 1]?.id ||
        null
    );
  }, [personalDocumentFolders]);

  useEffect(() => {
    updateDocsAndFoldersToRender(
      personalFoldersBreadCrumbs[personalFoldersBreadCrumbs.length - 1]?.id ||
        null
    );
  }, [personalFoldersBreadCrumbs]);

  useEffect(() => {
    if (history.action !== 'POP') {
      setPersonalFoldersBreadCrumbs([]);
    }
  }, [history]);

  const isBoligmappaPlusSub = getPropertyOwnerSubscriptionStatus(activeUser);

  const handleActionClick = async (type) => {
    matomo.clickEvent({
      category: 'Boligmappa+',
      action: 'Pay with Vipps',
    });
    await boligmappaPlusActionCallback(type, showErrorMessage);
  };

  const [searchQuery, setSearchQuery] = useState(searchText);

  const [showDocumentList, setShowDocumentList] = useState(
    !fetching || (!fetching && documents && documents.length > 0)
  );

  const [showEmptyListMessage, setShowEmptyListMessage] = useState(
    !fetching && documents && documents.length === 0
  );

  useEffect(() => {
    if (enableBoligmappaPlus && isApp && tableVariant === '2') {
      matomo.clickEvent({
        category: 'Boligmappa+',
        action: 'Rendered CTA',
        name: 'onRender CTA on Personal Folder Page',
      });
    }
  }, [tableVariant]);

  useEffect(() => {
    if (tableVariant === '1') {
      setShowEmptyListMessage(!fetching && documents && documents.length === 0);
    } else if (tableVariant === '2') {
      setShowEmptyListMessage(!fetching && documentsToRender.length === 0);
    }
  }, [tableVariant, documentsToRender, documents]);

  const toggleChaptersMode = () => {
    history.push({
      pathname,
      search: stringifyQuery({ ...parsedQuery, chaptersMode: !chaptersMode }),
    });
  };

  const onSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setSearchText(event.target.value);
  };

  const onDocumentDeleted = (document) => {
    if (tableVariant === '1') {
      detachDocumentFromRooms(document);
      detachDocumentFromEvents(document);
      deleteDocument(document);
    } else {
      deletePersonalDocument(document);
    }
  };

  const onFolderDelete = (folders) => {
    const folderIds = folders.map((el) => el.id);
    const deletedDocs = personalDocuments.filter((doc) =>
      folderIds.includes(doc.directoryId)
    );
    if ((deletedDocs || []).length > 0) {
      deletedDocs?.forEach((doc) => {
        deletePersonalDocument(doc);
      });
    }

    if (folders.length > 0) {
      folders.forEach((folder) => {
        deletePersonalDocumentFolder(folder);
      });
    }
  };

  const handleDocumentUpload = () => {
    if (!isBoligmappaPlusUser && tableVariant === '2') {
      showSnackbar({
        onClose: () => {},
        variant: 'info',
        content: (
          <>
            Den personlige mappen krever{' '}
            <Link to={`${dashboardLinkPrefix}/boligmappa-plus`}>
              Boligmappa+
            </Link>
          </>
        ),
      });

      setTimeout(() => {
        closeSnackbar();
      }, 13000);
    } else {
      onFileUploadClick({
        query: {
          bulkUploadMode: true,
          source: isDesktop ? 'table' : 'listView',
          newUpload: true,
          personalDocument: tableVariant === '2',
        },
      });
      setIsAddingNewDoc(false);
    }
  };

  const onDocumentUpload = () => {
    if (showEmptyListMessage) {
      setShowDocumentList(true);
    }
    if (folderFeatureFlag && isBoligmappaPlusUser && tableVariant === '2') {
      onAddNewDoc();
    } else {
      handleDocumentUpload();
    }
  };

  const onFolderUpload = () => {
    onFileUploadClick({
      query: {
        bulkUploadMode: true,
        source: 'table',
        newUpload: true,
        isRoomOrEvent: true,
        webkitdirectory: true,
        parentDirectoryId: currentDocFolderId,
        breadCrumbs: personalFoldersBreadCrumbs,
      },
    });
    setIsAddingNewDoc(false);
  };

  const handleTabChange = (tab) => {
    history.push(`?personal=${tab}`);
  };

  const mobileHeaderChild = () => (
    <DocumentTabs
      classes={classes}
      handleTabChange={handleTabChange}
      handleChange={onTabChange}
      value={tableVariant}
    />
  );

  const renderMobileBreadCrumbs = () =>
    personalFoldersBreadCrumbs.length > 0 ? (
      <BreadCrumbs
        breadCrumbs={personalFoldersBreadCrumbs}
        breadCrumbsNames={personalFoldersBreadCrumbs.map((el) => el.name)}
        setBreadCrumbs={setPersonalFoldersBreadCrumbs}
        onlyBack
        pathClassName={classes.breadcrumbs}
      />
    ) : (
      <Typography
        className={isDesktop ? classes.headerTitle : classes.headerTitleMobile}
      >
        Alle dokumenter
      </Typography>
    );

  const changeBreadCrumb = (index) => {
    setPersonalFoldersBreadCrumbs(
      personalFoldersBreadCrumbs.slice(0, index + 1)
    );
  };

  const rootBreadCrumb = () => {
    setPersonalFoldersBreadCrumbs([]);
  };

  const renderHeaderBreadCrumbs = () => {
    const breadCrumbsNames = personalFoldersBreadCrumbs
      .map((el) => el.name)
      .slice(0, -1);
    return (
      <Box display="flex" alignItems="center">
        <>
          <div>
            <button
              type="button"
              className={classes.breadCrumbButton}
              onClick={rootBreadCrumb}
            >
              <p style={{ color: '#717171' }}>Alle dokumenter</p>
            </button>
          </div>
          {breadCrumbsNames.length > 0 && (
            <div style={{ color: '#717171' }}>/</div>
          )}
        </>
        {breadCrumbsNames.map((el, index) => (
          <>
            {index + 1 !== breadCrumbsNames.lenght && (
              <div>
                <button
                  type="button"
                  className={classes.breadCrumbButton}
                  onClick={() => changeBreadCrumb(index)}
                >
                  <p style={{ color: '#717171' }}>{el}</p>
                </button>
              </div>
            )}
            {index + 1 !== breadCrumbsNames.length && (
              <div style={{ color: '#717171' }}>/</div>
            )}
          </>
        ))}
      </Box>
    );
  };

  const mobileHeaderSearch = () => (
    <MobileDocumentSearch
      fullWidth
      value={searchQuery}
      onChange={onSearchChange}
      placeholder="Søk i dokumenter"
      isSearching={isSearching}
      setIsSearching={setIsSearching}
    />
  );

  const renderTable = () => {
    if (chaptersMode) {
      return createElement(isDesktop ? ChapterTable : ChapterList, {
        documents: tableVariant === '1' ? documents : personalDocuments,
        searchQuery,
        fetchingDocuments: fetching,
        fetchingDocumentsError: fetchingError,
      });
    }
    return createElement(isDesktop ? getDocumentTable() : getDocumentList(), {
      fetching: loading || personalDocumentLoading,
      documents: tableVariant === '1' ? documents : documentsToRender,
      onFolderChange,
      documentFolders:
        tableVariant === '1' ? [] : personalDocumentFoldersToRender,
      directoryId: directoryId || currentDocFolderId,
      setDirectoryId,
      directoryTree,
      setDirectoryTree,
      documentIds,
      searchQuery,
      fetchingError,
      onDocumentDeleted,
      headerHeight,
      setStickyFiltersHeight,
      showRoomsColumn: true,
      showEventsColumn: true,
      showBulkSelectionOptions: true,
      isPersonalDocuments: tableVariant === '2',
      isBoligmappaPlusUser: isBoligmappaPlusUser === true,
      onFolderDelete,
    });
  };

  const chaptersModeToggleButton = (
    <IconButton size="medium" onClick={toggleChaptersMode}>
      {createElement(chaptersMode ? DocumentMode : ChapterMode, {
        className: classes.chaptersToggleButtonMobile,
      })}
    </IconButton>
  );

  const renderHeaderContextButtons = () =>
    !isDesktop ? chaptersModeToggleButton : null;

  const missingDocumentSection = (
    <Grid container justifyContent="center">
      <Grid item xs={8} lg={4}>
        <Box mx={3} mt={8}>
          <Features singleFeature={missingDocumentFeature} />
        </Box>
      </Grid>
    </Grid>
  );
  return (
    <Page
      topLevelLayout
      document
      noPadding
      maxWidth={false}
      headerTitle={
        tableVariant === '1' ? 'Dokumenter' : renderMobileBreadCrumbs()
      }
      minFullHeight={isDesktop}
      headerContextButtons={renderHeaderContextButtons()}
      headerChild={mobileHeaderChild()}
      headerSearch={mobileHeaderSearch()}
      isSearching={isSearching}
      setHeaderHeight={setHeaderHeight}
    >
      {fetchingError ? (
        <ErrorContainer
          paddingTop={isDesktop}
          errorResponse={fetchingError}
          style={{ paddingTop: isDesktop ? undefined : '15%' }}
        />
      ) : (
        <div
          className={classes.mobileDocListContent}
          style={{ marginTop: stickyFiltersHeight }}
        >
          {showDocumentList && (
            <>
              <InsuranceBanner />
              <Box display="flex" alignItems="end" px={isDesktop ? 0 : 2}>
                {isDesktop && (
                  <Box flex="50%" maxWidth="50%">
                    {!chaptersMode && (
                      <Box className={!isDesktop ? classes.mobilePadding : ''}>
                        <SearchField
                          fullWidth
                          value={searchQuery}
                          onChange={onSearchChange}
                          placeholder="Søk i dokumenter"
                        />
                      </Box>
                    )}
                  </Box>
                )}

                {isDesktop && !chaptersMode && (
                  <Box flex="30%" textAlign="right" ml={2}>
                    <AddNewButton
                      text="Legg til nytt"
                      onClick={onDocumentUpload}
                      buttonRef={addNewDocRef}
                    />
                  </Box>
                )}
              </Box>
              {enableDocumentsTab ? (
                <Box
                  className={clsx([classes.tabContent, isDesktop && 'desktop'])}
                >
                  <TabContext value={tableVariant}>
                    {isDesktop && (
                      <DocumentTabs
                        classes={classes}
                        handleTabChange={handleTabChange}
                        handleChange={onTabChange}
                        value={tableVariant}
                      />
                    )}
                    <TabPanel value="1">
                      <Box mt={1}>{renderTable()}</Box>
                    </TabPanel>
                    <TabPanel value="2">
                      {isDesktop && (
                        <div className={classes.breadCrumbsWrapper}>
                          {personalFoldersBreadCrumbs.length > 0 ? (
                            <Box flex="none">
                              {renderHeaderBreadCrumbs()}
                              <Typography
                                className={
                                  isDesktop
                                    ? classes.headerTitle
                                    : classes.headerTitleMobile
                                }
                              >
                                {personalFoldersBreadCrumbs?.slice(-1)[0].name}
                              </Typography>
                            </Box>
                          ) : (
                            <Typography
                              className={
                                isDesktop
                                  ? classes.headerTitle
                                  : classes.headerTitleMobile
                              }
                            >
                              Alle dokumenter
                            </Typography>
                          )}
                        </div>
                      )}
                      <Box mt={1}>{renderTable()}</Box>
                    </TabPanel>
                  </TabContext>
                </Box>
              ) : (
                <Box mt={isDesktop ? 5 : 1}>{renderTable()}</Box>
              )}
            </>
          )}
          {isDesktop &&
            tableVariant === '2' &&
            personalFoldersBreadCrumbs &&
            !personalDocumentfetching && (
              <BreadCrumbsFullPath
                breadCrumbs={personalFoldersBreadCrumbs}
                breadCrumbsNames={personalFoldersBreadCrumbs.map(
                  (el) => el.name
                )}
                setBreadCrumbs={setPersonalFoldersBreadCrumbs}
              />
            )}
          {enableBoligmappaPlus &&
            !isApp &&
            !isBoligmappaPlusSub &&
            tableVariant === '2' && (
              <>
                <SectionCallToAction
                  title="Personlig område"
                  linkText="Personlig område er tilgjengelig med Boligmappa+, som er et tillegg til alt det som allerede finnes i Boligmappa. Dine boligdokumenter, oversikt over håndverkere, rom og hendelser er fortsatt tilgjengelig, helt gratis."
                  desktopImage={personalDocumentsCTA}
                  mobileImage={personalDocumentsCTAmob}
                  isDesktop={isDesktop}
                  className={clsx([classes.cta, !isDesktop && 'mobile'])}
                  onActionClick={handleActionClick}
                  bottomText={
                    <p>
                      Vi har fått mange tilbakemeldinger om kunne å lagre
                      informasjon om mer enn bare håndverksarbeid. Dette kan
                      være kvitteringer på vaskemaskiner, møbler, TV,
                      stereoanlegg og andre verdigjenstander. Alt trygt og
                      sikkert lagret bak BankID - tilgjengelig den dagen man
                      trenger det for garanti, reklamasjon eller
                      forsikringsselskapet.
                      <br />
                      <br />
                      Dokumenter og filer lastet opp til boligen din følger
                      boligen over tid. Det betyr at om du flytter vil ny eier
                      automatisk få tilgang og ta over innholdet i din
                      boligmappe. Det betyr også at du mister tilgangen. Snart
                      lanserer vi muligheten for at du kan beholde en kopi av
                      alt innholdet på boligen, selv om du flytter. Det kan være
                      nyttig i reklamasjonssaker, eller når du trenger navnet på
                      den flinke elektrikeren du brukte tidligere. Denne
                      funksjonen blir tilgjengelig med personlig område, som en
                      del av Boligmappa+
                    </p>
                  }
                >
                  Dokumenter i personlig område følger deg for alltid. Trygg og
                  sikker lagring av viktige dokumenter med BankID.
                </SectionCallToAction>
              </>
            )}
          {showEmptyListMessage && !fetching && !personalDocumentfetching && (
            <EmptyMessage
              shiftMarginTopBy={121}
              onLinkClick={handleDocumentUpload}
              className={classes.emptyMessage}
              buttonText="Legg til nytt"
              additionalContent={
                tableVariant === '1' && (
                  <Box className={classes.dividerContent}>
                    <Grid container justifyContent="center">
                      <Grid item xs={12} lg={4}>
                        <Box mt={6}>
                          <Divider>ELLER</Divider>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )
              }
            >
              Du har ikke lastet opp noen dokumenter.
            </EmptyMessage>
          )}
          {tableVariant === '1' && missingDocumentSection}
          {isDesktop && (
            <AddNewDocumentMenu
              isAddingNewDoc={isAddingNewDoc}
              setIsAddingNewDoc={setIsAddingNewDoc}
              addNewDocRef={addNewDocRef}
              isCreatingName={isCreatingName}
              setIsCreatingName={setIsCreatingName}
              newFolderName={newFolderName}
              setNewFolderName={setNewFolderName}
              isFolderNameError={isFolderNameError}
              folderNameErrorMessage={folderNameErrorMessage}
              onDocumentUpload={handleDocumentUpload}
              onFolderUpload={onFolderUpload}
              onFolderCreate={onFolderCreate}
              onCancelCreatingFolder={onCancelCreatingFolder}
            />
          )}
          {!isDesktop && (
            <BaseDialogForm
              open={isCreatingName}
              header="Ny mappe"
              onSubmit={onFolderCreate}
              onClose={onCancelCreatingFolder}
            >
              <div className={classes.mobileCreateFolderContent}>
                <InputField
                  type="text"
                  label="Opprett mappe:"
                  value={newFolderName}
                  placeholder="Skriv inn mappenavn"
                  onChange={(event) => setNewFolderName(event.target.value)}
                />
                {isFolderNameError && (
                  <p className={classes.errorMessage}>
                    {folderNameErrorMessage}
                  </p>
                )}
              </div>
            </BaseDialogForm>
          )}
        </div>
      )}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  documents: sGetDocuments(state),
  fetching: sGetFetchingDocuments(state),
  fetchingError: sGetFetchingDocumentsError(state),
  searchText: sGetDocumentSearchText(state),
  personalDocuments: sGetPersonalDocuments(state),
  personalDocumentFolders: sGetPersonalDocumentFolders(state),
  personalDocumentFoldersToRender: sGetPersonalDocumentFoldersToRender(state),
  personalFoldersBreadCrumbs: sGetPersonalFoldersBreadCrumbs(state),
  personalDocumentfetching: sGetFetchingPersonalDocuments(state),
  activeUser: sGetUser(state),
  isCreatingDocumentFolder: sGetIsCreatingDocumentFolder(state),
});

const mapDispatchToProps = (dispatch) => ({
  detachDocumentFromRooms: (document) =>
    dispatch(acDetachDocumentFromRooms(document)),
  detachDocumentFromEvents: (document) =>
    dispatch(acDetachDocumentFromEvents(document)),
  setFetchingError: (fetchingError) =>
    dispatch(acSetFetchingDocumentsError(fetchingError)),
  setPersonalDocumentFetchingError: (fetchingError) =>
    dispatch(acSetFetchingPersonalDocumentsError(fetchingError)),
  setDocuments: (documents) => dispatch(acSetDocuments(documents)),
  setPersonalDocuments: (documents) =>
    dispatch(acSetPersonalDocuments(documents)),
  setPersonalDocumentFolders: (folders) =>
    dispatch(acSetPersonalDocumentFolders(folders)),
  setPersonalDocumentFoldersToRender: (folders) =>
    dispatch(acSetPersonalDocumentFoldersToRender(folders)),
  setPersonalFoldersBreadCrumbs: (breadCrumbs) =>
    dispatch(acSetPersonalFoldersBreadCrumbs(breadCrumbs)),
  deleteDocument: (document) => dispatch(acDeleteDocument(document)),
  deletePersonalDocument: (document) =>
    dispatch(acDeletePersonalDocument(document)),
  setFetching: (fetching) => dispatch(acSetFetchingDocuments(fetching)),
  setPersonalDocumentFetching: (fetching) =>
    dispatch(acSetFetchingPersonalDocuments(fetching)),
  setSearchText: (searchText) => dispatch(acSetDocumentSearchText(searchText)),
  deletePersonalDocumentFolder: (folder) =>
    dispatch(acDeletePersonalDocumentFolder(folder)),
  setIsCreatingDocumentFolder: (isCreatingDocumentFolder) =>
    dispatch(acSetIsCreatingDocumentFolder(isCreatingDocumentFolder)),
  setSelectedPersonalFolderId: (id) =>
    dispatch(acSetSelectedPersonalFolderId(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Documents));
