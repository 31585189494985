export const navbarHeight = 69;

const styles = (theme) => ({
  hidden: {
    visibility: 'hidden',
  },
  navbar: {
    display: 'flex',
    justifyContent: 'space-evenly',
    left: '0',
    zIndex: 2,
    bottom: '0',
    width: '100%',
    height: '69px',
    position: 'fixed',
    borderTop: `1px solid ${theme.palette.primary.whiteDark}`,
    '@supports (height: env(safe-area-inset-bottom))': {
      height: `calc(env(safe-area-inset-bottom) * 0.6 + ${navbarHeight}px)`,
    },
  },
});

export default styles;
