export const defaultState = {
  activeCategory: 0,
  categories: [
    {
      id: 3,
      category: 'WETROOMS',
      activeStep: 1,
      event: null,
      completed: false,
    },
    {
      id: 4,
      category: 'ELECTRICAL',
      activeStep: 1,
      event: null,
      completed: false,
    },
    {
      id: 5,
      category: 'OUTDOORS',
      activeStep: 1,
      event: null,
      completed: false,
    },
    {
      id: 6,
      category: 'OTHER',
      activeStep: 1,
      event: null,
      completed: false,
    },
  ],
};

export const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_CATEGORY_ACTIVE_STEP = 'SET_CATEGORY_ACTIVE_STEP';
export const SET_CATEGORY_EVENT = 'SET_CATEGORY_EVENT';
export const SET_CATEGORY_COMPLETED = 'SET_CATEGORY_COMPLETED';
export const CLEAR_BYBF_EVENTS = 'CLEAR_BYBF_EVENTS';

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_ACTIVE_CATEGORY:
      return {
        ...state,
        activeCategory: action.payload,
      };
    case SET_CATEGORIES:
      return { ...state, categories: action.payload };
    case SET_CATEGORY_ACTIVE_STEP: {
      const { activeCategory, activeStep } = action.payload;
      return {
        ...state,
        categories: state.categories.map((category) => {
          if (category.id === activeCategory) {
            return { ...category, activeStep };
          }
          return category;
        }),
      };
    }
    case SET_CATEGORY_EVENT: {
      const { activeCategory, event } = action.payload;
      return {
        ...state,
        categories: state.categories.map((category) => {
          if (category.id === activeCategory) {
            return { ...category, event };
          }
          return category;
        }),
      };
    }
    case SET_CATEGORY_COMPLETED: {
      const { activeCategory, completed } = action.payload;
      return {
        ...state,
        categories: state.categories.map((category) => {
          if (category.id === activeCategory) {
            return { ...category, completed };
          }
          return category;
        }),
      };
    }
    case CLEAR_BYBF_EVENTS:
      return defaultState;
    default:
      return state;
  }
};

export const sGetBYBFEventsModule = (state) => state.bybfEvents;
export const sGetEventsActiveCategory = (state) =>
  sGetBYBFEventsModule(state).activeCategory;
export const sGetEventsCategories = (state) =>
  sGetBYBFEventsModule(state).categories;
