import React from 'react';

const NewPage = ({ width = '15.001', height = '14.752', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 15.001 14.752"
    {...props}
  >
    <g
      id="Interface-Essential_Expand_Retract_expand-6-alternate"
      data-name="Interface-Essential / Expand/Retract / expand-6-alternate"
      transform="translate(72 -17.5)"
    >
      <g id="Group_926" transform="translate(0 0)">
        <g id="expand-6-alternate" transform="translate(-71.5 18)">
          <path
            id="Shape_2470"
            d="M430.25,8248.223a.992.992,0,0,0-1-.982h-3.5a2.977,2.977,0,0,0-3,2.946v7.858a2.973,2.973,0,0,0,3,2.947h8a2.973,2.973,0,0,0,3-2.947v-2.455a1,1,0,0,0-2,0v2.455a.994.994,0,0,1-1,.982h-8a.994.994,0,0,1-1-.982v-7.858a.992.992,0,0,1,1-.981h3.5a1,1,0,0,0,.707-.285A.976.976,0,0,0,430.25,8248.223Z"
            transform="translate(-422.75 -8247.24)"
            fill="none"
            stroke="#333"
            strokeWidth="1"
          />
          <path
            id="Shape_2471"
            d="M428.254,8252.78a1,1,0,0,0,.71-.295l4.331-4.319a.159.159,0,0,1,.236,0l1.184,1.181a.355.355,0,0,0,.237.1.407.407,0,0,0,.128-.026.339.339,0,0,0,.207-.31v-4.016a.334.334,0,0,0-.335-.335h-4.017a.333.333,0,0,0-.237.568l1.179,1.178a.181.181,0,0,1,.049.122.162.162,0,0,1-.05.114l-4.332,4.324a1.011,1.011,0,0,0-.218,1.1A1,1,0,0,0,428.254,8252.78Z"
            transform="translate(-421.287 -8244.759)"
            fill="none"
            stroke="#333"
            strokeWidth="1"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default NewPage;
