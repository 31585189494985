import clsx from 'clsx';
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';

import {
  getRoomId,
  getImageUrl,
  getRoomName,
  getRoomType,
  getRoomImage,
  getRoomImages,
} from '../../../../utils/rooms';

import styles from './style';
import Image from '../../../Image';
import Box from '../../../atomic/Box';
import Link from '../../../atomic/Link';
import GridItem from '../../../GridItem';
import Typography from '../../../atomic/Typography';
import TextHighlighted from '../../../TextHighlighted';
import { toUpperCase } from '../../../../utils/strings';
import DirectImageUpload from '../../../DirectImageUpload';
import { uploadImage as apiUploadImage } from '../../../../api/rooms';
import PlaceholderImage from '../../../../assets/images/placeholder.png';
import useDashboardLinkPrefix from '../../../../hooks/useDashboardLinkPrefix';
import useDesktop from '../../../../hooks/useDesktop';
import matomo from '../../../../utils/matomo';

const RoomItem = ({
  room,
  classes,
  searchQuery,
  onRoomUpdated,
  enableImageUpload = null,
}) => {
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const isDesktop = useDesktop();

  const [imageRef, setImageRef] = useState(null);

  const roomLetter =
    (getRoomName(room) && toUpperCase(getRoomName(room).substr(0, 1))) ||
    (getRoomType(room) && toUpperCase(getRoomType(room).substr(0, 1)));

  const roomImage = getRoomImage(room);
  const link = `${dashboardLinkPrefix}/rooms/${getRoomId(room)}`;

  const existingImages = getRoomImages(room);
  const lastImagePosition = Array.isArray(existingImages)
    ? existingImages.length
    : 0;

  const openImageUploadDialog = () => imageRef.click();

  const onImagesUploaded = (uploadedImages) => {
    onRoomUpdated({
      ...room,
      gallery: [...(existingImages || []), ...uploadedImages],
    });

    matomo.clickEvent({
      category: 'Rooms',
      action: 'Add Image to Room',
      name: 'Add Image from Room',
      value: uploadedImages?.length,
    });
  };

  const uploadImageRequest = (image, position) =>
    apiUploadImage(getRoomId(room), image, position);

  const trackRoomViewEvent = () => {
    matomo.clickEvent({
      category: 'Rooms',
      action: 'See Room',
    });
  };

  const label = (
    <Link to={link} underline={false}>
      <Typography
        color="textSecondary"
        className={clsx(classes.roomName, 'word-break')}
      >
        <strong>
          <TextHighlighted searchQuery={searchQuery}>
            {getRoomName(room)}
          </TextHighlighted>
        </strong>
      </Typography>
    </Link>
  );

  return (
    <GridItem
      className={
        !isDesktop ? classes.room_item_mobile : classes.room_item_desktop
      }
      contentHeight="100%"
      label={label}
    >
      {!roomImage && enableImageUpload && (
        <DirectImageUpload
          imageRef={imageRef}
          setImageRef={setImageRef}
          onClick={openImageUploadDialog}
          onImagesUploaded={onImagesUploaded}
          lastImagePosition={lastImagePosition}
          className={classes.imageUploadButton}
          uploadImageRequest={uploadImageRequest}
        />
      )}

      <Link to={link} underline={false} onClick={trackRoomViewEvent}>
        {roomImage ? (
          <Image
            className={classes.image}
            src={getImageUrl(roomImage)}
            fallbackSrc={PlaceholderImage}
          />
        ) : (
          <Box className={classes.roomLetterContainer}>
            <Typography color="textSecondary" className={classes.roomLetter}>
              {roomLetter}
            </Typography>
          </Box>
        )}
      </Link>
    </GridItem>
  );
};

export default withStyles(styles)(RoomItem);
