import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { withStyles } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { showNewConsents } from '../../../../../config';

import {
  fetchConsents as apiFetchConsents,
  updateConsents as apiUpdateConsents,
} from '../../../../../api/propertyowners';

import styles from './style';
import useDesktop from '../../../../../hooks/useDesktop';
import useSnackbar from '../../../../../hooks/useSnackbar';
import Form from '../../../../../components/forms/BaseForm';
import Checkbox from '../../../../../components/atomic/Checkbox';
import useFetchEffect from '../../../../../hooks/useFetchEffect';
import Typography from '../../../../../components/atomic/Typography';
import PageLayout from '../../../../../components/layouts/WithHeader';
import Box from '../../../../../components/atomic/Box';
import {
  BEDRIFTNOTIFICATION,
  MARKETINGEMAIL,
  PROPERTYVALUEESTIMATEUPDATES,
} from '../../../../../constants';

export const defaultConsents = [
  {
    isGranted: false,
    type: MARKETINGEMAIL,
  },
  {
    isGranted: false,
    type: BEDRIFTNOTIFICATION,
  },
];

export const getConsentByType = (consents, type) =>
  (consents || []).find((consent) => consent.type === type);

const Consents = ({ classes }) => {
  const history = useHistory();
  const isDesktop = useDesktop();
  const { showSuccessMessage } = useSnackbar();

  const [requestError, setRequestError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const enableNewConsents = showNewConsents === 'true';

  const {
    fetching,
    data: consents,
    setData: setConsents,
  } = useFetchEffect({
    initialFetchingState: true,
    initialDataState: defaultConsents,
    defaultDataState: defaultConsents,
    apiFetchFunction: apiFetchConsents,
    onError: (error) => setRequestError(error),
  });

  const onConsentsChange = (event, type) => {
    if (consents.find((consent) => consent.type === type)) {
      setConsents(
        consents.map((consent) =>
          type === consent.type
            ? { ...consent, isGranted: event.target.checked }
            : consent
        )
      );
    } else {
      setConsents([
        ...consents,
        {
          type,
          isGranted: event.target.checked,
        },
      ]);
    }
  };

  const onFormSubmit = async () => {
    setRequestInProgress(true);

    const [error, response] = await apiUpdateConsents(consents);

    if (error && !response) {
      setRequestError(error);
    } else {
      showSuccessMessage();

      if (!isDesktop) {
        history.goBack();
      }
    }

    setRequestInProgress(false);
  };

  return (
    <PageLayout
      minFullHeight
      headerTitle={!isDesktop ? 'Administer samtykker' : ''}
      lessMarginLeft={isDesktop}
      maxWidth="lg"
    >
      {isDesktop && (
        <Typography className={classes.header} variant="h2">
          Administer samtykker
        </Typography>
      )}
      <Box className={classes.consentBody}>
        <Typography className={classes.mainBody} component="p" variant="body2">
          <strong>
            Ved å bruke Boligmappa, godtar du å dele navn, fødselsnummer,
            e-postadresse og telefonnummer. Dette bruker vi personopplysningene
            til:
          </strong>
        </Typography>
        <ul className={classes.list}>
          <li className={classes.listItem}>
            <FiberManualRecordIcon className={classes.bulletPoint} />
            <Typography
              className={classes.bodyTextPara}
              variant="body2"
              color="textPrimary"
            >
              Når du logger inn med BankID, mottar vi fødselsnummeret ditt. Det
              brukes kun til å identifisere deg slik at vi kan vise deg dine
              eiendommer, slik de er oppført i Eiendomsregisteret.
            </Typography>
          </li>
          <li className={classes.listItem}>
            <FiberManualRecordIcon className={classes.bulletPoint} />
            <Typography
              className={classes.bodyTextPara}
              variant="body2"
              color="textPrimary"
            >
              Hvis du eier en eiendom sammen med andre, vil ditt navnet være
              synlig for dem.
            </Typography>
          </li>
          {!enableNewConsents && (
            <li className={classes.listItem}>
              <FiberManualRecordIcon className={classes.bulletPoint} />
              <Typography
                className={classes.bodyTextPara}
                variant="body2"
                color="textPrimary"
              >
                Vi bruker e-postadressen til å sende deg en velkomstmail,
                informasjon om endringer i våre vilkår og eventuelt vedlikehold
                på nettsiden.
              </Typography>
            </li>
          )}
          {enableNewConsents && (
            <li className={classes.listItem}>
              <FiberManualRecordIcon className={classes.bulletPoint} />
              <Typography
                className={classes.bodyTextPara}
                variant="body2"
                color="textPrimary"
              >
                Vi bruker e-postadressen til å sende deg en velkomstmail,
                viktige nyheter om tjenesten, varsler om dokumentopplastinger
                fra dine håndverkere, informasjon om vesentlige endringer i våre
                vilkår og eventuelt vedlikehold på nettsiden.
              </Typography>
            </li>
          )}
        </ul>

        <hr className={classes.divider} />

        <Form
          fetching={fetching}
          error={requestError}
          onSubmit={onFormSubmit}
          submitButtonText="Lagre"
          showCancelButton={false}
          requestInProgress={requestInProgress}
          leftActions={isDesktop}
          formClassName={classes.formSection}
        >
          {!enableNewConsents && (
            <Checkbox
              className={classes.checkbox}
              disabled={fetching || requestInProgress}
              onChange={(e) => onConsentsChange(e, BEDRIFTNOTIFICATION)}
              checked={
                (
                  getConsentByType(consents, BEDRIFTNOTIFICATION) || {
                    isGranted: false,
                  }
                ).isGranted
              }
              label="Jeg ønsker varsling på e-post når fagfolk laster opp dokumentasjon til min(e) eiendom(mer)."
            />
          )}
          <Checkbox
            className={classes.checkbox}
            label={
              <span className={classes.checkBoxText}>
                Få tips og triks på e-post som gjør det enklere, bedre og
                billigere å eie bolig.
              </span>
            }
            disabled={fetching || requestInProgress}
            onChange={(e) => onConsentsChange(e, MARKETINGEMAIL)}
            checked={
              (
                getConsentByType(consents, MARKETINGEMAIL) || {
                  isGranted: false,
                }
              ).isGranted
            }
          />

          <Checkbox
            className={classes.checkbox}
            label={
              <span className={classes.checkBoxText}>
                Motta månedlig varsel på e-post med oppdatert prisestimat for
                eiendommene dine.
              </span>
            }
            disabled={fetching || requestInProgress}
            onChange={(e) => onConsentsChange(e, PROPERTYVALUEESTIMATEUPDATES)}
            checked={
              (
                getConsentByType(consents, PROPERTYVALUEESTIMATEUPDATES) || {
                  isGranted: false,
                }
              ).isGranted
            }
          />
        </Form>
      </Box>
    </PageLayout>
  );
};

export default withStyles(styles)(Consents);
