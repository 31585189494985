import styled from 'styled-components';

export const StyledPurchaseCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  padding: 24px 16px;
  gap: 32px;

  h3 {
    font-size: 18px;
    line-height: 19px;
    font-weight: 700;
  }

  p.description {
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
    color: #717171;

    a {
      color: #717171;
    }
  }

  .data_block {
    width: 100%;

    .row {
      display: flex;
      justify-content: space-between;
      padding: 16px 0;
      border-bottom: 1px solid #e6e6e6;

      p.row_title {
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
      }

      p {
        font-size: 14px;
        line-height: 19px;
        font-weight: 400;
      }
    }

    .insurance_hint {
      font-size: 14px;
      line-height: 19px;
      font-weight: 500;
      color: #111;
      margin-top: 16px;

      a {
        color: #111;
      }
    }
  }
`;
