const styles = (theme) => ({
  baseTextField: {
    width: '100%',
  },
  label: {
    fontWeight: 700,
    position: 'static',
    marginBottom: '-10px',
    color: theme.palette.text.secondary,
  },
  inputField: {
    fontWeight: 500,
    fontSize: '16px',
    marginTop: '5px',
    borderRadius: '3px',
    background: theme.palette.primary.white,
    border: `1px solid ${theme.palette.primary.greyLighter}`,
  },
  nativeInputField: {
    padding: '10px',
  },
  expandableInputField: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  multiline: {
    paddingTop: '20px',
  },
});

export default styles;
