import React from 'react';

const ChapterFive = ({
  width = '100%',
  height = '100%',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 31.861 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g data-name="Group 3035" transform="translate(0)">
      <path
        fill={fill}
        fillRule="evenodd"
        transform="translate(-179 -4465)"
        d="M182.983,4465h23.9a3.869,3.869,0,0,1,3.983,3.75v22.5a3.869,3.869,0,0,1-3.983,3.75h-23.9a3.869,3.869,0,0,1-3.983-3.75v-22.5A3.869,3.869,0,0,1,182.983,4465Zm23.9,27.5a1.293,1.293,0,0,0,1.328-1.25v-22.5a1.293,1.293,0,0,0-1.328-1.25h-23.9a1.293,1.293,0,0,0-1.328,1.25v22.5a1.293,1.293,0,0,0,1.328,1.25Z"
      />
      <path
        fill={fill}
        transform="translate(-177.43 -4463.937)"
        d="M192.363,4469.562a1.737,1.737,0,0,0-2.323.312l-2.833,3.563-.64-.6a1.731,1.731,0,0,0-2.286.05,1.5,1.5,0,0,0-.06,2.15l1.991,1.875a1.682,1.682,0,0,0,1.29.45,1.712,1.712,0,0,0,1.211-.612l3.983-5A1.5,1.5,0,0,0,192.363,4469.562Z"
      />
      <path
        fill={fill}
        transform="translate(-177.449 -4461.686)"
        d="M192.383,4478.562a1.737,1.737,0,0,0-2.323.312l-2.833,3.563-.64-.6a1.7,1.7,0,0,0-1.614-.425,1.594,1.594,0,0,0-1.184,1.112,1.516,1.516,0,0,0,.453,1.525l1.991,1.875a1.729,1.729,0,0,0,1.292.438,1.71,1.71,0,0,0,1.209-.613l3.983-5A1.5,1.5,0,0,0,192.383,4478.562Z"
      />
      <path
        fill={fill}
        transform="translate(-174.824 -4463.063)"
        d="M199.383,4472.75h-5.974a1.565,1.565,0,1,0,0,3.125h5.974a1.565,1.565,0,1,0,0-3.125Z"
      />
      <path
        fill={fill}
        transform="translate(-174.824 -4461.063)"
        d="M199.383,4480.75h-5.974a1.565,1.565,0,1,0,0,3.125h5.974a1.565,1.565,0,1,0,0-3.125Z"
      />
    </g>
  </svg>
);

export default ChapterFive;
