export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LINK_OPEN_REQUEST = 'LINK_OPEN_REQUEST';
export const TOKEN_REFRESH_REQUEST = 'TOKEN_REFRESH_REQUEST';
export const DOCUMENT_SCAN_REQUEST = 'DOCUMENT_SCAN_REQUEST';

export const isIOSNativeEnvironment = () =>
  !!window &&
  !!window.webkit &&
  !!window.webkit.messageHandlers &&
  !!window.webkit.messageHandlers.nativeBridge &&
  !!window.webkit.messageHandlers.nativeBridge.postMessage;

export const isAndroidNativeEnvironment = () =>
  !!window && !!window.nativeBridge && !!window.nativeBridge.postMessage;

export const isNativeEnv = () =>
  isIOSNativeEnvironment() || isAndroidNativeEnvironment();

export const postMessage = (action, payload = null) => {
  if (isIOSNativeEnvironment()) {
    window.webkit.messageHandlers.nativeBridge.postMessage(
      JSON.stringify({
        action,
        payload,
      })
    );
  } else if (isAndroidNativeEnvironment()) {
    window.nativeBridge.postMessage(
      JSON.stringify({
        action,
        payload,
      })
    );
  }
};

export const openLinkRequest = (link, preventEncode) => {
  // preventEncode is added to fix some issues when downloading images from image gallery component. if the url is encoded it will result in a 403
  if (preventEncode === true) postMessage(LINK_OPEN_REQUEST, { link });
  else postMessage(LINK_OPEN_REQUEST, { link: encodeURI(link) });
};

export const logoutRequest = () => postMessage(LOGOUT_REQUEST);

export const requestTokenRefresh = () => {
  // eslint-disable-next-line no-console
  console.log('====== Requesting IOS App to Refresh Tokens ======');
  postMessage(TOKEN_REFRESH_REQUEST);
};

export const requestDocumentScan = () => {
  postMessage(DOCUMENT_SCAN_REQUEST);
};
