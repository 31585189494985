import {
  getRoomId,
  getRoomEvents,
  getRoomDocuments,
  roomModes,
} from '../../utils/rooms';

import { getEventId } from '../../utils/events';
import { areIdsEqual } from '../../utils/strings';
import { getDocumentId } from '../../utils/documents';

export const defaultState = {
  rooms: null,
  fetching: false,
  fetchingError: null,
  showAddRoom: false,
  showEditRoom: false,
  roomMode: roomModes.room,
  roomAttachedIds: {
    documentIds: [],
    roomIds: [],
  },
  returnOnSuccess: false,
};

export const ADD_ROOM = 'ADD_ROOM';
export const SET_ROOMS = 'SET_ROOMS';
export const DELETE_ROOM = 'DELETE_ROOM';
export const CLEAR_ROOMS = 'CLEAR_ROOMS';
export const UPDATE_ROOM = 'UPDATE_ROOM';
export const SYNC_ROOMS_EVENTS = 'SYNC_ROOMS_EVENTS';
export const SET_FETCHING_ROOMS = 'SET_FETCHING_ROOMS';
export const SYNC_ROOMS_DOCUMENTS = 'SYNC_ROOMS_DOCUMENTS';
export const SET_FETCHING_ROOMS_ERROR = 'SET_FETCHING_ROOMS_ERROR';
export const DETACH_DOCUMENT_FROM_ROOMS = 'DETACH_DOCUMENT_FROM_ROOMS';
export const SET_SHOW_ADD_ROOM = 'SET_SHOW_ADD_ROOM';
export const SET_SHOW_EDIT_ROOM = 'SET_SHOW_EDIT_ROOM';
export const SET_ROOM_MODE = 'SET_ROOM_MODE';
export const SET_ATTACHED_IDS = 'SET_ATTACHED_IDS';
export const SET_RETURN_ON_SUCCESS = 'SET_RETURN_ON_SUCCESS';

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_ROOM:
      return {
        ...state,
        rooms: [...(state.rooms || []), action.payload],
      };
    case DELETE_ROOM:
      return {
        ...state,
        rooms: state.rooms.filter(
          (room) => !areIdsEqual(getRoomId(room), action.payload.roomId)
        ),
      };
    case SET_ROOMS:
      return {
        ...state,
        rooms: action.payload,
      };
    case CLEAR_ROOMS:
      return defaultState;
    case UPDATE_ROOM:
      const updatedRoom = action.payload;

      return {
        ...state,
        rooms: state.rooms.map((room) =>
          areIdsEqual(getRoomId(room), getRoomId(updatedRoom))
            ? updatedRoom
            : room
        ),
      };
    case SYNC_ROOMS_DOCUMENTS:
      return {
        ...state,
        rooms: (state.rooms || []).map((room) => {
          const {
            documentId,
            document,
            roomIds: numericRoomIds,
          } = action.payload;
          const roomIds = numericRoomIds?.map((id) => String(id));
          const documentIds = (getRoomDocuments(room) || []).map((_document) =>
            String(getDocumentId(_document))
          );
          const roomId = getRoomId(room);
          const roomHadDocument = documentIds.includes(String(documentId));
          const documentAttached = roomIds?.includes(String(roomId));

          if (roomHadDocument && !documentAttached) {
            return {
              ...room,
              documents: (getRoomDocuments(room) || []).filter(
                (_document) =>
                  !areIdsEqual(getDocumentId(_document), documentId)
              ),
            };
          }

          if (!roomHadDocument && documentAttached) {
            return {
              ...room,
              documents: [...(getRoomDocuments(room) || []), document],
            };
          }

          return {
            ...room,
            documents: (getRoomDocuments(room) || []).map((roomDocument) =>
              areIdsEqual(getDocumentId(roomDocument), documentId)
                ? document
                : roomDocument
            ),
          };
        }),
      };
    case SYNC_ROOMS_EVENTS:
      return {
        ...state,
        rooms: (state.rooms || []).map((room) => {
          const { eventId, event, roomIds: numericRoomIds } = action.payload;
          const roomIds = numericRoomIds.map((id) => String(id));
          const eventIds = (getRoomEvents(room) || []).map((_event) =>
            String(getEventId(_event))
          );
          const roomId = getRoomId(room);
          const roomHadEvent = eventIds.includes(String(eventId));
          const eventAttached = roomIds.includes(String(roomId));

          if (roomHadEvent && !eventAttached) {
            return {
              ...room,
              events: (getRoomEvents(room) || []).filter(
                (_event) => !areIdsEqual(getEventId(_event), eventId)
              ),
            };
          }

          if (!roomHadEvent && eventAttached) {
            return {
              ...room,
              events: [...(getRoomEvents(room) || []), event],
            };
          }

          return {
            ...room,
            events: (getRoomEvents(room) || []).map((roomEvent) =>
              areIdsEqual(getEventId(roomEvent), eventId) ? event : roomEvent
            ),
          };
        }),
      };
    case SET_FETCHING_ROOMS:
      return {
        ...state,
        fetching: action.payload,
      };
    case SET_FETCHING_ROOMS_ERROR:
      return {
        ...state,
        fetchingError: action.payload,
      };
    case DETACH_DOCUMENT_FROM_ROOMS:
      return {
        ...state,
        rooms: Array.isArray(state.rooms)
          ? state.rooms.map((room) => ({
              ...room,
              documents: (getRoomDocuments(room) || []).filter(
                (document) =>
                  !areIdsEqual(
                    getDocumentId(document),
                    getDocumentId(action.payload)
                  )
              ),
            }))
          : state.rooms,
      };
    case SET_SHOW_ADD_ROOM:
      return {
        ...state,
        showAddRoom: action.payload,
      };
    case SET_SHOW_EDIT_ROOM:
      return {
        ...state,
        showEditRoom: action.payload,
      };
    case SET_ROOM_MODE:
      return {
        ...state,
        roomMode: action.payload,
      };
    case SET_ATTACHED_IDS:
      return {
        ...state,
        roomAttachedIds: action.payload,
      };
    case SET_RETURN_ON_SUCCESS:
      return {
        ...state,
        returnOnSuccess: action.payload,
      };
    default:
      return state;
  }
};

export const sGetRoomsModule = (state) => state.rooms;
export const sGetRooms = (state) => sGetRoomsModule(state).rooms;
export const sGetFetchingRooms = (state) => sGetRoomsModule(state).fetching;
export const sGetFetchingRoomsError = (state) =>
  sGetRoomsModule(state).fetchingError;
export const sGetShowAddRoom = (state) => sGetRoomsModule(state).showAddRoom;
export const sGetShowEditRoom = (state) => sGetRoomsModule(state).showEditRoom;
export const sGetRoomMode = (state) => sGetRoomsModule(state).roomMode;
export const sGetRoomAttachedIds = (state) =>
  sGetRoomsModule(state).roomAttachedIds;
export const sGetReturnOnSuccess = (state) =>
  sGetRoomsModule(state).returnOnSuccess;
