import React from 'react';
import { useStyles } from './style';
import { formatNumber } from '../../../../../../../../utils/strings';
import Typography from '../../../../../../../../components/atomic/Typography';

export function EstimateNumber({ valueEstimate }) {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h1" className={classes.estimateNumber}>
        {formatNumber(valueEstimate)}
      </Typography>
    </div>
  );
}
