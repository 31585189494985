import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';

import {
  getDocumentId,
  getFilteredDocuments,
  getDefaultDocumentSortType,
} from '../../../utils/documents';

import Table from './Table';
import SummaryTable from './SummaryTable';
import styles from './style';
import Box from '../../atomic/Box';
import TableLoading from '../loaders/TableLoading';
import { areIdsEqual } from '../../../utils/strings';
import DocumentContextMenu from '../../DocumentContextMenu';
import useDocumentFilters from '../../../hooks/useDocumentFilters';

const DocumentTable = ({
  classes,
  fetching,
  documents,
  searchQuery,
  showRoomsColumn,
  showEventsColumn,
  onDocumentDeleted,
  showProfessionColumn,
  actionsEnabled = true,
  showUploadedDateColumn,
  showUploaderNameColumn,
  showCompanyNameLinks = true,
  displayDocumentSummary = false,
}) => {
  const { filters, onFiltersChanged } = useDocumentFilters();

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentDocumentId, setCurrentDocumentId] = useState(null);
  const [sortType, setSortType] = useState(getDefaultDocumentSortType());

  const currentDocument = (documents || []).find((document) =>
    areIdsEqual(getDocumentId(document), currentDocumentId)
  );

  const filteredDocumens = getFilteredDocuments({
    filters,
    documents,
    searchQuery,
    shouldSort: true,
    sortFunction: sortType.sortFunction,
  });

  const onDocumentClick = (event, document) => {
    event.persist();

    setCurrentDocumentId(getDocumentId(document));
    setAnchorEl(event.currentTarget);
  };

  const onSortTypeChange = (newSortType) => setSortType(newSortType);

  const toggleContextMenu = (open) => {
    if (!open) {
      setAnchorEl(null);
      setCurrentDocumentId(null);
    }
  };

  if (fetching) {
    return (
      <Box pt={2}>
        <TableLoading rows={5} columns={4} />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      {displayDocumentSummary ? (
        <SummaryTable
          documents={documents}
          searchQuery={searchQuery}
          actionsEnabled={actionsEnabled}
          showRoomsColumn={showRoomsColumn}
          onDocumentClick={onDocumentClick}
          onFiltersChanged={onFiltersChanged}
          onSortTypeChange={onSortTypeChange}
          showEventsColumn={showEventsColumn}
          filteredDocuments={filteredDocumens}
          showCompanyNameLinks={showCompanyNameLinks}
          showProfessionColumn={showProfessionColumn}
          showUploadedDateColumn={showUploadedDateColumn}
          showUploaderNameColumn={showUploaderNameColumn}
        />
      ) : (
        <Table
          filters={filters}
          sortType={sortType}
          documents={documents}
          searchQuery={searchQuery}
          actionsEnabled={actionsEnabled}
          showRoomsColumn={showRoomsColumn}
          onDocumentClick={onDocumentClick}
          onFiltersChanged={onFiltersChanged}
          onSortTypeChange={onSortTypeChange}
          showEventsColumn={showEventsColumn}
          filteredDocuments={filteredDocumens}
          showCompanyNameLinks={showCompanyNameLinks}
          showProfessionColumn={showProfessionColumn}
          showUploadedDateColumn={showUploadedDateColumn}
          showUploaderNameColumn={showUploaderNameColumn}
        />
      )}

      <DocumentContextMenu
        full={false}
        anchorEl={anchorEl}
        document={currentDocument}
        setOpen={toggleContextMenu}
        onDocumentDeleted={onDocumentDeleted}
        open={!!anchorEl && !!currentDocument}
        documentId={getDocumentId(currentDocument)}
      />
    </Box>
  );
};

export default withStyles(styles)(DocumentTable);
