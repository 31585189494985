import React from 'react';

const Copy = ({ width = '1.2rem', height = '1.2rem', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 23.85 23.845"
    {...props}
  >
    <g
      id="Interface-Essential_Link_Unlink_hyperlink-3"
      data-name="Interface-Essential / Link/Unlink / hyperlink-3"
      transform="translate(-372.074 -3917.075)"
    >
      <g id="Group_426" transform="translate(1)">
        <g id="hyperlink-3">
          <path
            id="Shape_1165"
            d="M381.458,3935.37l-2.737,2.74a2.861,2.861,0,0,1-3.942,0l-.892-.89a2.786,2.786,0,0,1,0-3.94l5.8-5.8a2.785,2.785,0,0,1,3.942,0l.893.89a1,1,0,0,0,1.418-1.42l-.893-.89a4.787,4.787,0,0,0-6.771,0l-5.8,5.8a4.787,4.787,0,0,0,0,6.77l.892.89a4.794,4.794,0,0,0,6.771,0l2.736-2.73a1,1,0,1,0-1.414-1.42Z"
            fill="#707070"
          />
          <path
            id="Shape_1166"
            d="M393.526,3919.36l-.892-.89a4.8,4.8,0,0,0-6.77,0l-2.9,2.9a1,1,0,0,0,1.414,1.42l2.9-2.9a2.784,2.784,0,0,1,3.941,0l.893.89a2.783,2.783,0,0,1,0,3.94l-5.8,5.8a2.8,2.8,0,0,1-1.971.82,2.754,2.754,0,0,1-1.969-.82,1,1,0,1,0-1.415,1.41,4.791,4.791,0,0,0,6.769,0l5.8-5.8a4.785,4.785,0,0,0,0-6.77Z"
            fill="#707070"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Copy;
