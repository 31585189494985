import { isProduction } from '../config';
import { getAnalyticsTitle, getAnalyticsUrl } from './analyticsHelpers';

// update the goalTypes dictionary once the goal has been registered in matomo
export const goalTypes = {
  month: 11,
  year: 12,
};

export const matomoClient = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  // eslint-disable-next-line no-underscore-dangle
  return window._paq ?? null;
};

/**
 * This function allows us to pass custom tracking to Matomo
 * For most pages it will simply pass the current path with parameters
 * For any shallow path changes it won't send an update to Matomo, this is mostly for changes
 * in the map.
 * For everything else it calls the "getAnalyticsUrl" which handles defining custom URLs for certain
 * pages
 * @param url The url that was changed to
 * @param options Options object containing whether change was shallow or not
 */
export const handleRouteChange = () => {
  const client = matomoClient();
  if (!client) {
    return;
  }
  const analyticsUrl = getAnalyticsUrl();
  const analyticsTitle = getAnalyticsTitle(document.title);

  if (analyticsUrl !== null) {
    client.push(['setCustomUrl', analyticsUrl]);
    client.push(['setDocumentTitle', `${analyticsTitle}`]);
    client.push(['trackPageView']);
  }
};

export const trackEvent = (category, action, name, value) => {
  const client = matomoClient();
  if (!client) {
    return;
  }
  client.push(['trackEvent', category, action, name, value]);
};

export const trackGoal = (goalId) => {
  const client = matomoClient();
  if (!client) {
    return;
  }
  client.push(['trackGoal', goalId]);
};

const clickEvent = ({ category, action, name, value }) =>
  trackEvent(category, action, name, value);

const goalEvent = ({ goalType }) => {
  trackGoal(goalTypes[goalType]);
};

/**
 * Gets user identifier from matomo
 * Based on https://matomo.org/faq/how-to/faq_23654/
 * @returns matomo user id
 */
export const getMatomoUserId = () => {
  let crossDomainQueryParam;
  const client = matomoClient();

  if (client) {
    client.push([
      // eslint-disable-next-line func-names
      function () {
        const param = this.getCrossDomainLinkingUrlParameter();
        if (param) {
          [, crossDomainQueryParam] = param.split('=');
        }
      },
    ]);
  }

  return crossDomainQueryParam;
};

/**
 * This is the actual Matomo tag which we need to load in the _document section in order to have
 * Matomo ready when we load the page
 * It will only be loaded in production, if you need to test locally you will need to remove the isProduction check
 * If you need to test with tracking cookie you need to also remove "requireCookieConsent" below
 */
export const MatomoTag = () =>
  isProduction()
    ? `
          var _paq = window._paq = window._paq || [];

          // cross domain 
          _paq.push(["setDomains", ["*.auth.boligmappa.no", "*.testauth.boligmappa.no","*.boligmappa.no", "boligeier.boligmappa.no"]]);
          _paq.push(["enableCrossDomainLinking"]);

          // require user tracking consent before processing data
          _paq.push(['requireCookieConsent']);
          _paq.push(['enableLinkTracking']);
          (function() {
            var u="https://matomo.hjemla.no/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '2']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
          })();
       
      `
    : '';

export default { clickEvent, goalEvent };
