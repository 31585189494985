import React from 'react';
import { withStyles, Slide } from '@material-ui/core';

import styles from './styles';
import Box from '../atomic/Box';
import IconButton from '../atomic/IconButton';
import Close from '../../assets/icons/Close';
import useDesktop from '../../hooks/useDesktop';

const DialogOverlay = ({
  classes,
  children,
  props,
  headerTxt = '',
  displayClose = true,
  onClose = () => {},
}) => {
  const isDesktop = useDesktop();
  return (
    <Box className={classes.dialogOverlay} {...props}>
      <Slide direction="up" in={displayClose} mountOnEnter unmountOnExit>
        <Box
          className={
            isDesktop
              ? classes.dialogOverlayContent
              : classes.dialogOverlayContentMobile
          }
        >
          <Box
            className={
              isDesktop
                ? classes.dialogOverlayHeader
                : classes.dialogOverlayHeaderMobile
            }
          >
            <Box className={classes.dialogOverlayHeaderTxt}>{headerTxt}</Box>
            {displayClose && (
              <IconButton
                size="small"
                onClick={onClose}
                className={
                  isDesktop ? null : classes.dialogOverlayCloseButtonMobile
                }
              >
                <Close />
              </IconButton>
            )}
          </Box>
          {children}
        </Box>
      </Slide>
    </Box>
  );
};

export default withStyles(styles)(DialogOverlay);
