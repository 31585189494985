import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import styles from './style';
import Button from '../atomic/Button';
import Refresh from '../../assets/icons/Refresh';
import EmailAlternate from '../../assets/icons/EmailAlternate';
import DownloadOnDiscAltIcon from '../../assets/icons/DocumentBulkEditIcons/DownloadOnDiscAltIcon';
import useDesktop from '../../hooks/useDesktop';
import Link from '../atomic/Link';
import matomo from '../../utils/matomo';

const ShowErrorMessage = ({ classes, noFile, documentOriginalPath }) => {
  const isDesktop = useDesktop();
  const trackMatomoEvent = (message) => {
    matomo.clickEvent({
      category: 'Document preview error handling',
      name: noFile
        ? `Error preview: File doesn't exist`
        : `Error preview: File can't be previewed`,
      action: message,
    });
  };
  const onRefreshPage = () => {
    trackMatomoEvent('Refresh file preview page.');
    window.location.reload();
  };
  const onContactSupport = () => {
    trackMatomoEvent('Contact customer support.');
  };
  const onFileDownload = () => {
    trackMatomoEvent('Download file.');
  };
  return (
    <div className={clsx([classes.errorBlock, !isDesktop && 'mobile'])}>
      {noFile ? (
        <div
          className={clsx([classes.errorWrapper, classes.errorWrapperWider])}
        >
          <h2 className={classes.errorHeadline}>Noe er galt med filen</h2>
          <p className={classes.errorText}>
            Det virker som om noe kan ha gått galt da filen ble lastet opp.
            Forsøk å laste siden på nytt, eller ta kontakt med kundestøtte.
          </p>
          <div
            className={clsx([
              classes.errorButtonsBlock,
              !isDesktop && 'mobile',
            ])}
          >
            <Button
              className={clsx([
                classes.errorBlockButton,
                !isDesktop && 'mobile',
              ])}
              variant="contained"
              onClick={() => onRefreshPage()}
            >
              <Refresh />
              <p className={classes.errorButtonText}>Last siden på nytt</p>
            </Button>
            <Link
              openInNewTab
              isExternal
              to="https://boligmappa.zendesk.com/hc/no/requests/new"
              onClick={onContactSupport}
            >
              <Button
                className={clsx([
                  classes.errorBlockButton,
                  classes.errorContactButton,
                  !isDesktop && 'mobile',
                ])}
                variant="contained"
              >
                <EmailAlternate width={15} height={15} strokeColor="#fff" />
                <p className={classes.errorButtonText}>Kontakt kundestøtte</p>
              </Button>
            </Link>
          </div>
        </div>
      ) : (
        <div className={classes.errorWrapper}>
          <h2 className={classes.errorHeadline}>
            Filen kan ikke forhåndsvises
          </h2>
          <p className={classes.errorText}>
            Filtypen støtter ikke forhåndsvisning. Vennligst last ned filen for
            å se dokumentet.
          </p>
          <Link
            openInNewTab
            isExternal
            to={documentOriginalPath}
            onClick={onFileDownload}
          >
            <Button
              className={clsx([
                classes.errorBlockButton,
                !isDesktop && 'mobile',
              ])}
              variant="contained"
            >
              <DownloadOnDiscAltIcon />
              <p className={classes.errorButtonText}>Last ned filen</p>
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ShowErrorMessage);
