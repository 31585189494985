const styles = () => ({
  contentContainer: ({ contentHeight = '100%' }) => ({
    position: 'relative',
    '&::before': {
      width: '100%',
      content: '" "',
      display: 'block',
      paddingTop: contentHeight,
    },
  }),
  content: {
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    position: 'absolute',
  },
});

export default styles;
