const styles = (theme) => ({
  form: {
    [theme.breakpoints.up('md')]: {
      width: '400px',
      margin: '0 auto',
    },
  },
  loaderContainer: {
    marginTop: '45%',
    textAlign: 'center',
  },
  loader: {
    width: '80px',
  },
  listItem: {
    display: 'flex',
    marginBottom: '20px',
  },
  imageContainer: {
    height: '150px',
    width: '100%',
    marginRight: '15px',
    borderRadius: '12px',
    position: 'relative',
  },
  greyBackground: {
    backgroundColor: theme.palette.primary.greyLight,
  },
  image: {
    width: '100%',
    height: '150px',
    objectFit: 'contain',
    borderRadius: '12px',
  },
  fitImage: {
    objectFit: 'cover',
  },
  deleteIcon: {
    right: '10px',
    bottom: '10px',
    boxShadow: 'none',
    position: 'absolute',
    fill: theme.palette.primary.white,
    color: theme.palette.primary.white,
    backgroundColor: 'rgba(0,0,0,0.6)',

    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.6)',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  primaryLabel: {
    top: '15px',
    left: '15px',
    padding: '5px 7px',
    borderRadius: '5px',
    position: 'absolute',
    fontSize: '0.8125rem',
    color: theme.palette.primary.white,
    backgroundColor: 'rgba(0,0,0,0.6)',
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fill: theme.palette.primary.grey,
    color: theme.palette.primary.grey,
  },
  arrowButton: {
    cursor: 'pointer',
  },
  draggingArea: {
    cursor: 'grab',
  },
});

export default styles;
