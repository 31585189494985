import clsx from 'clsx';
import React, { createElement } from 'react';
import { withStyles } from '@material-ui/core';

import {
  getDocumentId,
  getDocumentName,
  isDocumentImage,
  getFileThumbNail,
  getDocumentRooms,
  getDocumentEvents,
  isDocumentReadOnly,
  getDocumentFileType,
  getDocumentCreatedDate,
  getDocumentOwnedByName,
  getDocumentCompanyName,
  getOriginalDocumentPath,
  getDocumentProfessionType,
  getDocumentType,
  isProfessionTypeSpecified,
} from '../../../utils/documents';

import styles from './styles';
import Image from '../../Image';
import Box from '../../atomic/Box';
import Link from '../../atomic/Link';
import ListItem from '../../atomic/ListItem';
import AttachedEntity from '../../AttachedEntity';
import TextHighlighted from '../../TextHighlighted';
import ListItemText from '../../atomic/ListItemText';
import { formattedDate } from '../../../utils/dates';
import ListItemAvatar from '../../atomic/ListItemAvatar';
import LockCircled from '../../../assets/icons/LockCircled';
import MenuIcon from '../../../assets/icons/MenuHorizontal';
import ImgIcon from '../../../assets/icons/DocumentIcons/ImgIcon';
import useDashboardLinkPrefix from '../../../hooks/useDashboardLinkPrefix';
import ListItemSecondaryAction from '../../atomic/ListItemSecondaryAction';

const DocumentListItem = ({
  classes,
  document,
  onMenuClick,
  searchQuery,
  actionsEnabled,
  showRoomsColumn = false,
  showEventsColumn = false,
  isPersonalDocuments = false,
}) => {
  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const onMenuButtonClick = (focusedDocument, event) => {
    event.preventDefault();
    onMenuClick(focusedDocument, event);
  };

  const ownedByName = getDocumentOwnedByName(document);
  const companyName = getDocumentCompanyName(document);
  const uploadedByName = ownedByName || companyName;
  const professionType = getDocumentProfessionType(document);
  const createdDate = formattedDate(getDocumentCreatedDate(document));
  const documentType = getDocumentType(document);

  const primaryText = getDocumentName(document);

  let secondaryText;

  if (isPersonalDocuments === true) {
    secondaryText = createdDate;
  } else if (isProfessionTypeSpecified(professionType)) {
    secondaryText = [
      uploadedByName,
      createdDate,
      professionType,
      documentType,
    ].join(' • ');
  } else {
    secondaryText = [uploadedByName, createdDate, documentType].join(' • ');
  }

  const documentLink = `${dashboardLinkPrefix}/documents/${getDocumentId(
    document
  )}`;

  const getIcon = () => {
    if (isDocumentImage(document)) {
      return (
        <Image
          className={classes.imageIcon}
          fallbackComponent={<ImgIcon />}
          alt={getDocumentName(document)}
          src={getOriginalDocumentPath(document)}
        />
      );
    }

    return createElement(getFileThumbNail(getDocumentFileType(document)));
  };

  const actionComponent = actionsEnabled ? (
    <MenuIcon
      className={classes.menuIcon}
      onClick={(event) => onMenuButtonClick(document, event)}
    />
  ) : null;

  return (
    <Link
      to={documentLink}
      className={clsx(classes.link, classes.borderedItem)}
    >
      <ListItem className={clsx(classes.listItem, 'word-break')}>
        <ListItemAvatar className={classes.iconContainer}>
          {getIcon()}
        </ListItemAvatar>

        <ListItemText
          primary={
            <TextHighlighted searchQuery={searchQuery}>
              {primaryText}
            </TextHighlighted>
          }
          secondaryTypographyProps={{ component: 'div' }}
          secondary={
            <Box>
              <Box>
                <small className={classes.secondaryText}>
                  <TextHighlighted searchQuery={searchQuery}>
                    {secondaryText}
                  </TextHighlighted>
                  {isDocumentReadOnly(document) && (
                    <LockCircled className={classes.lockIcon} />
                  )}
                </small>
              </Box>

              {(showEventsColumn || showRoomsColumn) && (
                <Box mt={1}>
                  {showEventsColumn && (
                    <Box mr={1} mb={1} display="inline-block">
                      <AttachedEntity
                        type="event"
                        document={document}
                        editable={actionsEnabled}
                        entities={getDocumentEvents(document)}
                      />
                    </Box>
                  )}

                  {showRoomsColumn && (
                    <AttachedEntity
                      type="room"
                      document={document}
                      editable={actionsEnabled}
                      entities={getDocumentRooms(document)}
                    />
                  )}
                </Box>
              )}
            </Box>
          }
        />
        <ListItemSecondaryAction className={classes.secondaryAction}>
          {actionComponent}
        </ListItemSecondaryAction>
      </ListItem>
    </Link>
  );
};

export default withStyles(styles)(DocumentListItem);
