import { chartColors } from '@style/vars/colors';
import { format } from 'date-fns';
import { nb } from 'date-fns/locale';

export const defaultProps = {
  data: {
    datasets: [
      {
        data: [
          { y: 21.9, x: '2022-02-22T00:00:00+01:00' },
          { y: 20.9, x: '2021-12-31T00:00:00+01:00' },
          { y: 19.4, x: '2021-09-30T00:00:00+02:00' },
          { y: 19.2, x: '2021-06-30T00:00:00+02:00' },
          { y: 18.9, x: '2021-03-31T00:00:00+02:00' },
          { y: 12.2, x: '2020-12-31T00:00:00+01:00' },
          { y: 7.9, x: '2020-09-30T00:00:00+02:00' },
          { y: 4.9, x: '2020-06-30T00:00:00+02:00' },
          { y: 5.0, x: '2020-03-31T00:00:00+02:00' },
          { y: 0.9, x: '2019-12-31T00:00:00+01:00' },
          { y: 1.6, x: '2019-09-30T00:00:00+02:00' },
          { y: 1.1, x: '2019-06-30T00:00:00+02:00' },
          { y: -0.8, x: '2019-03-31T00:00:00+01:00' },
          { y: -4.3, x: '2018-12-31T00:00:00+01:00' },
          { y: -3.1, x: '2018-09-30T00:00:00+02:00' },
          { y: -3.3, x: '2018-06-30T00:00:00+02:00' },
          { y: -5.6, x: '2018-03-31T00:00:00+02:00' },
          { y: -8.3, x: '2017-12-31T00:00:00+01:00' },
          { y: -8.4, x: '2017-09-30T00:00:00+02:00' },
          { y: -3.5, x: '2017-06-30T00:00:00+02:00' },
          { y: 0, x: '2017-03-31T00:00:00+02:00' },
        ],
      },
    ],
  },
  gradient: {
    data: [
      { stop: 1, color: 'rgba(97,170,178,0.2)' },
      { stop: 0.5, color: 'rgba(97,170,178,0.15)' },
      { stop: 0.2, color: 'rgba(97,170,178,0.10)' },
      { stop: 0.1, color: 'rgba(97,170,178,0.05)' },
      { stop: 0, color: 'rgba(97,170,178,0.02)' },
    ],
  },
  height: 300,
  yAxisSuffix: '%',
  xAxisSuffix: '',
  lineColor: chartColors.darkGreen,
  dotColor: chartColors.green,
  tickColor: chartColors.text,
};

export const defaultAreaProps = {
  ...defaultProps,
  gradient: {
    data: [
      {
        stop: 1,
        color: chartColors.darkGreen,
      },
    ],
  },
};

export const lineChartDefaultOptions = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    point: {
      borderWidth: 4,
      hoverBorderWidth: 4,
      radius: 8,
      hoverRadius: 8,
      backgroundColor: chartColors.transparent,
      hoverBackgroundColor: chartColors.green,
      borderColor: 'rgb(250, 250, 250, 0)',
      hoverBorderColor: chartColors.white,
    },
    line: {
      borderColor: chartColors.darkGreen,
      borderWidth: 4,
      fill: 'start',
      tension: '.6',
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
    tooltip: {
      backgroundColor: chartColors.white,
      caretSize: 0,
      titleColor: chartColors.darkGray,
      footerColor: chartColors.darkGray,
      caretPadding: -100,
      position: 'nearest',
      displayColors: false,
      borderWidth: 1,
      borderColor: chartColors.lightGray,
      titleMarginBottom: 2,
      padding: 14,
      bodySpacing: 0,
      footerSpacing: 0,
      titleSpacing: 0,
      yAlign: 'top',
      xAlign: 'center',
      titleFont: {
        size: 12,
        weight: 400,
      },
      bodyFont: {
        size: 0,
        lineHeight: 0,
      },
      footerFont: {
        size: 12,
        weight: 600,
      },
      callbacks: {},
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'year',
      },
      ticks: {
        color: chartColors.text,
        font: {
          size: 10,
        },
      },
      grid: {
        color: chartColors.invisibleGray,
        borderDash: [3, 1],
        tickColor: chartColors.transparent,
        borderColor: 'transparent',
      },
    },
    y: {
      grace: 10,
      ticks: {
        color: chartColors.text,
        font: {
          size: 10,
        },
      },
      grid: {
        color: chartColors.invisibleGray,
        tickColor: chartColors.transparent,
        borderColor: chartColors.invisibleGray,
      },
    },
  },
  interaction: {
    intersect: false,
    mode: 'index',
  },
  spanGaps: true,
  showLine: true,
} as any;

export const lineChartWithDotsOptions = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    point: {
      borderWidth: 3,
      hoverBorderWidth: 3,
      radius: 7,
      hoverRadius: 7,
      backgroundColor: chartColors.transparent,
      hoverBackgroundColor: chartColors.green,
      borderColor: 'rgb(250, 250, 250, 0)',
      hoverBorderColor: chartColors.white,
    },
    line: {
      borderColor: chartColors.darkGreen,
      borderWidth: 3,
      fill: 'start',
      tension: '.6',
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: chartColors.white,
      caretSize: 0,
      titleColor: chartColors.darkGray,
      footerColor: chartColors.darkGray,
      caretPadding: -100,
      position: 'nearest',
      displayColors: false,
      borderWidth: 1,
      borderColor: chartColors.lightGray,
      titleMarginBottom: 2,
      padding: 14,
      bodySpacing: 0,
      footerSpacing: 0,
      titleSpacing: 0,
      yAlign: 'top',
      xAlign: 'center',
      titleFont: {
        size: 12,
        weight: 400,
      },
      bodyFont: {
        size: 0,
        lineHeight: 0,
      },
      footerFont: {
        size: 12,
        weight: 600,
      },
      callbacks: {},
    },
    datalabels: {
      color: 'black',
      font: {
        size: 14,
        weight: 'normal',
      },
      formatter: (item, context) => {
        if (context.dataIndex === 0) {
          return 'Nå';
        }
        const date = new Date(item.x);

        return `${format(date, 'LLL', { locale: nb })}\n${format(date, 'yyyy', {
          locale: nb,
        })}`;
      },
      textAlign: 'center',
      offset: 16,
      align: (context) => {
        if (context.dataIndex === 0) {
          return 'end';
        }
        return 'start';
      },
      display: (context) => {
        if (
          context.dataIndex === 0 ||
          context.dataIndex === context.dataset.data.length - 1
        ) {
          return true;
        }
        return false;
      },
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'year',
      },
      ticks: {
        display: false,
      },
      offset: true,
      grid: {
        color: 'transparent',
        tickColor: chartColors.transparent,
        borderColor: 'transparent',
      },
      title: {
        color: chartColors.darkGray,
        display: true,
        align: 'end',
        font: {
          size: 14,
        },
      },
    },
    y: {
      grace: 0,
      ticks: {
        color: 'red',
        font: {
          size: 14,
        },
        maxTicksLimit: 3,
      },
      offset: false,
      grid: {
        color: chartColors.invisibleGray,
        tickColor: chartColors.transparent,
        borderColor: 'transparent',
      },
    },
  },
  interaction: {
    intersect: false,
    mode: 'index',
  },
  spanGaps: true,
  showLine: true,
} as any;

export const lineDotsProps = {
  data: {
    datasets: [
      {
        data: [
          { y: 20.9, x: '2024-02-22T00:00:00+01:00' },
          { y: 20.9, x: '2023-12-22T00:00:00+01:00' },
          { y: 13.9, x: '2023-09-22T00:00:00+01:00' },
          { y: 12.9, x: '2023-06-22T00:00:00+01:00' },
          { y: 25.9, x: '2023-03-22T00:00:00+01:00' },
          { y: 20.9, x: '2022-12-22T00:00:00+01:00' },
          { y: 13.9, x: '2022-09-22T00:00:00+01:00' },
          { y: 12.9, x: '2022-06-22T00:00:00+01:00' },
          { y: 25.9, x: '2022-03-22T00:00:00+01:00' },
          { y: 20.9, x: '2021-12-31T00:00:00+01:00' },
          { y: 19.4, x: '2021-09-30T00:00:00+02:00' },
          { y: 19.2, x: '2021-06-30T00:00:00+02:00' },
          { y: 4.9, x: '2021-03-31T00:00:00+02:00' },
        ],
        pointBackgroundColor: [
          '#FFC600',
          'transparent',
          'transparent',
          'transparent',
          'transparent',
          'transparent',
          'transparent',
          'transparent',
          'transparent',
          'transparent',
          'transparent',
          'transparent',
          'white',
        ],
        pointBorderColor: [
          'black',
          'transparent',
          'transparent',
          'transparent',
          'transparent',
          'transparent',
          'transparent',
          'transparent',
          'transparent',
          'transparent',
          'transparent',
          'transparent',
          'black',
        ],
      },
    ],
  },
  chartOptions: lineChartWithDotsOptions,
  gradient: {
    data: null,
  },
  height: 240,
  yAxisSuffix: '%',
  xAxisSuffix: '',
  xAxisTitle: '+ 14%',
  lineColor: chartColors.darkGray,
  dotColor: chartColors.darkGray,
  tickColor: chartColors.textSecondary,
};
