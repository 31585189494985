import ImageLeft from './assets/left.png';
import ImageRight from './assets/right.png';
import ImageBg394 from './assets/bg-394.png';
import ImageBg788 from './assets/bg-788.png';
import ImageBg1182 from './assets/bg-1182.png';

const styles = (theme) => ({
  root: {
    background: `url(${ImageLeft}) left bottom no-repeat, url(${ImageRight}) right bottom no-repeat`,

    [theme.breakpoints.only('md')]: {
      background: `url(${ImageBg1182}) center bottom no-repeat`,
    },

    [theme.breakpoints.down('sm')]: {
      background: `url(${ImageBg788}) center bottom no-repeat`,
    },

    [theme.breakpoints.down('xs')]: {
      background: `url(${ImageBg394}) center bottom no-repeat`,
    },
  },
  button: {
    outline: '0',
    width: '100%',
    height: '72px',
    margin: '15px 0',
    cursor: 'pointer',
    maxWidth: '350px',
    borderRadius: '36px !important',
    color: theme.palette.primary.main,
    border: '1px solid #254e52 !important',
    boxShadow: '0px 1px 1px rgb(0 0 0 / 10%)',
    background: '#ffffff 0% 0% no-repeat padding-box',
  },
});

export default styles;
