import styled from 'styled-components';
import Menu from '../../components/atomic/Menu';

export const StyledBreadCrumbs = styled.div`
  display: flex;
  align-items: center;

  .backButton {
    display: flex;
    align-items: center;
    border: unset;
    outline: unset;
    background-color: unset;
    padding: 9px;
    cursor: pointer;

    svg {
      width: 17px;
      height: 17px;
    }
  }

  .menu_button {
    display: flex;
    align-items: center;
    padding: 8px;
    border: unset;
    outline: unset;
    background-color: unset;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .column {
    font-size: 24px;
    font-weight: 500;
    line-height: 37.25px;
    color: #000;

    &.lastName {
      white-space: nowrap;
      max-width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .divider {
    color: #717171;
    margin-right: 8px;
  }
`;

export const StyledMenu = styled(Menu)`
  .paper {
    width: 100%;
    max-width: 390px;
    borderradius: 8px;
    box-shadow: 0 12px 32px rgba(38, 39, 59, 0.13);
  }
  .menuList {
    padding: 0;
  }
  .menuItem {
    padding: 0;

    button {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 24px;
      color: #111;
      margin: 0;
      column-gap: 8px;
      padding: 14px 16px;
      width: 100%;
      background-color: unset;
      border: unset;
      cursor: pointer;
    }
  }
`;
