import { css } from '@linaria/core';
import { colors } from '@style/vars';

export const infoArea = css`
  display: flex;
  align-items: flex-start;

  span:not(.error-message) {
    margin-left: auto;
    color: ${colors.textSecondary};
  }
`;
