import React from 'react';

const PhoneIcon = ({ width = '20', height = '30', ...props }) => (
  <svg
    id="Group_69"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 30"
    {...props}
  >
    <g id="mobile-phone-1">
      <path
        id="Shape_168"
        d="M7.75,740h12.5A3.75,3.75,0,0,1,24,743.75v22.5A3.75,3.75,0,0,1,20.25,770H7.75A3.75,3.75,0,0,1,4,766.25v-22.5A3.75,3.75,0,0,1,7.75,740ZM6.5,744.375V763.75a.625.625,0,0,0,.625.625h13.75a.625.625,0,0,0,.625-.625V744.375a.625.625,0,0,0-.625-.625H7.125A.625.625,0,0,0,6.5,744.375Z"
        transform="translate(-4 -740)"
        fill="#fff"
        fillRule="evenodd"
      />
    </g>
  </svg>
);

export default PhoneIcon;
