import React from 'react';

const AudioIcon = ({ width = '24', height = '26' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 24"
  >
    <g
      id="Group_2784"
      data-name="Group 2784"
      transform="translate(-171.109 -3122.902)"
    >
      <g
        id="Group_2783"
        data-name="Group 2783"
        transform="translate(-148 1862)"
      >
        <path
          id="Shape_42"
          d="M406,54.328V70a2,2,0,0,1-2,2H388a2,2,0,0,1-2-2V50a2,2,0,0,1,2-2h11.672a2,2,0,0,1,1.414.586l4.328,4.328A2,2,0,0,1,406,54.328Z"
          transform="translate(-66.891 1212.902)"
          fill="#9a54c7"
        />
      </g>
      <g id="music_note-24px" transform="translate(177 3127.486)">
        <path
          id="Path_2836"
          data-name="Path 2836"
          d="M0,0H8.14V16.514H0Z"
          fill="none"
        />
        <path
          id="Path_2837"
          data-name="Path 2837"
          d="M10.07,3V9.162a3.05,3.05,0,0,0-1.357-.321A2.545,2.545,0,0,0,6,11.177a2.545,2.545,0,0,0,2.713,2.336,2.545,2.545,0,0,0,2.713-2.336V5.336H14.14V3Z"
          transform="translate(-6)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default AudioIcon;
