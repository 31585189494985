import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

import {
  getEventRoom,
  getEventName,
  getEventImage,
  getEventStartDate,
} from '../../../utils/events';

import styles from './style';
import Image from '../../Image';
import Card from '../../atomic/Card';
import Typography from '../../atomic/Typography';
import { formattedDate } from '../../../utils/dates';
import { getImageUrl, getRoomName } from '../../../utils/rooms';
import DefaultEventImage from '../../../assets/images/event.png';
import { capitalizeFirst } from '../../../utils/strings';
import TextHighlighted from '../../TextHighlighted';
import Grid from '../../atomic/Grid';
import Box from '../../atomic/Box';
import CheckCircle from '../../../assets/icons/CheckCircle';

const EventCard = ({
  classes,
  event,
  searchQuery,
  selectedEvents,
  addSelectedEvent,
  removeSelectedEvent,
  ...props
}) => {
  const eventImage = getEventImage(event);
  const imageUrl = getImageUrl(eventImage) || event.image;

  const dateFormat = 'MMMM, YYYY';
  const room = getEventRoom(event);

  const isEventSelected = () =>
    selectedEvents.find((_event) => _event === event);

  return (
    <Card elevation={2} className={classes.card} {...props}>
      <Grid container>
        <Grid item xs={10} className={classes.cardContent}>
          {imageUrl && <Box className={classes.eventIcon} />}

          <div className={classes.imageContainer}>
            <Image
              alt="event"
              src={imageUrl}
              className={classes.image}
              fallbackSrc={DefaultEventImage}
            />
          </div>

          <div className={classes.textContainer}>
            <Typography
              variant="h4"
              component="h4"
              color="textPrimary"
              className="word-break"
              style={{ margin: '5px 0' }}
            >
              <TextHighlighted searchQuery={searchQuery}>
                {getEventName(event)}
              </TextHighlighted>
            </Typography>
            <Typography
              variant="inherit"
              component="small"
              color="textSecondary"
            >
              {room && getRoomName(room)}
            </Typography>
            <small className={classes.date}>
              {capitalizeFirst(
                formattedDate(getEventStartDate(event), dateFormat)
              )}
            </small>
          </div>
        </Grid>
        <Grid item xs={2} className={classes.actionsContainer}>
          {isEventSelected() ? (
            <CheckCircle
              className={clsx(classes.checkIcon, classes.icon)}
              onClick={() => removeSelectedEvent(event)}
            />
          ) : (
            <Box
              className={clsx(classes.uncheckedIcon, classes.icon)}
              onClick={() => addSelectedEvent(event)}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default withStyles(styles)(EventCard);
