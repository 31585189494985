import React, { createElement } from 'react';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import Link from '../atomic/Link';

const ActionList = ({ actions, viewState, classes }) => {
  const items = actions.map((action, index) => {
    const key = `action-menu-item-${index}`;

    const menuItem = (
      <>
        <div className={classes.iconContainer}>
          {createElement(action.icon, { className: classes.icon })}
        </div>
        <div
          className={
            action.text.length > 10 && viewState
              ? classes.menuItemTextMobile
              : classes.menuItemText
          }
        >
          {action.text}
        </div>
      </>
    );

    if (action.to) {
      return (
        <Link
          key={key}
          isExternal
          openInNewTab
          to={action.to}
          className={classes.menuItem}
        >
          {menuItem}
        </Link>
      );
    }

    return (
      <div
        key={key}
        role="presentation"
        onClick={action.onClick}
        className={
          actions.length > 2 ? classes.menuItemforLargeText : classes.menuItem
        }
      >
        {menuItem}
      </div>
    );
  });

  const setClass =
    actions.length < 3 ? classes.multiIconMenuList : classes.menuList;

  const setMobileClass =
    actions.length < 3 ? classes.menuListMobile : classes.multimenuListMobile;

  return (
    <div className={viewState ? setMobileClass : setClass}>
      {items.map((item) => item)}
    </div>
  );
};

export default withStyles(styles)(ActionList);
