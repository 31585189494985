const styles = (theme) => ({
  container: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  content: {
    flex: '1 1 auto',
    overflow: 'auto',
  },
  paddingDesktop: {
    padding: '10px 40px 0px 40px',
  },
  paddingMobile: {
    padding: '0px 20px 0px 20px',
  },
  inputField: {
    width: 0,
    height: 0,
    overflow: 'hidden',
  },
  actionButtonsContainer: {
    flex: '0 1 auto',
    width: '100%',
    marginBottom: '65px',
    backgroundColor: theme.palette.primary.white,
  },
  actionButtons: {
    padding: '10px 0 10px 0',
    borderTop: '1px solid #dedfdf',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionButtonsMobile: {
    padding: '15px 0 30px 0',
    borderTop: '1px solid #dedfdf',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  nextBtn: {
    marginRight: 45,
    width: '130px',
    height: '50px',
  },
  nextBtnMobile: {
    marginRight: 15,
    width: '130px',
    height: '50px',
  },
  backBtn: {
    marginLeft: 45,
    marginRight: 'auto',
    width: '130px',
    height: '50px',
  },
  backBtnMobile: {
    marginLeft: 15,
    marginRight: 'auto',
    width: '130px',
    height: '50px',
  },
});

export default styles;
