// Add your styles here, and reference them in your component className
import { css } from '@linaria/core';
import { colors } from '@style/vars';
import { sizes } from '@style/vars/sizes';

export const style = css`
  flex-shrink: 0;

  &.xs {
    width: ${sizes.iconXsSize};
    height: ${sizes.iconXsSize};
  }

  &.large {
    width: ${sizes.iconDefaultSize};
    height: ${sizes.iconDefaultSize};
  }

  &.small {
    width: ${sizes.iconSmallSize};
    height: ${sizes.iconSmallSize};
  }

  &.stretch {
    width: 100%;
    height: 100%;
  }
`;

export const storyWrapper = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  color: ${colors.textColor};
  overflow: hidden;
`;
