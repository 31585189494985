// Add your styles here, and reference them in your component className
import { css } from '@linaria/core';
import { sizes } from '@style/vars/sizes';

export const spinnerStyle = css`
  border: 2px dashed black;
  border-radius: 50%;
  width: ${sizes.iconDefaultSize};
  height: ${sizes.iconDefaultSize};
  animation: rotate 1s linear infinite;
  flex-shrink: 0;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
