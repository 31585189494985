import React from 'react';
import { connect } from 'react-redux';
import { withWidth } from '@material-ui/core';

import Box from '../atomic/Box';
import Grid from '../atomic/Grid';
import FloorGroup from './FloorGroup';
import Divider from '../atomic/Divider';
import { gridOptions } from '../../utils/grid';
import useDesktop from '../../hooks/useDesktop';
import GridItemLoading from '../GridItemLoading';
import { getRoomsByFloor } from '../../utils/rooms';
import { acUpdateRoom } from '../../store/actions/rooms';

const RoomList = ({
  rooms,
  width,
  fetching,
  updateRoom,
  searchQuery,
  enableImageUpload,
}) => {
  const isDesktop = useDesktop();

  const roomsByFloor = getRoomsByFloor(rooms);

  const { itemsPerLine } = gridOptions[width] || gridOptions.md;

  const onRoomUpdated = (updatedRoom) => {
    if (rooms) {
      updateRoom(updatedRoom);
    }
  };

  if (fetching || !rooms) {
    return (
      <Box py={2}>
        <Grid container>
          {[...Array(itemsPerLine).keys()].map((itemKey) => (
            <GridItemLoading key={`room-loading-${itemKey}`} />
          ))}
        </Grid>
      </Box>
    );
  }

  return (
    <Box>
      {roomsByFloor.map(({ floor, rooms: floorRooms }, index) => (
        <Box key={floor}>
          <FloorGroup
            floor={floor}
            rooms={floorRooms}
            searchQuery={searchQuery}
            onRoomUpdated={onRoomUpdated}
            enableImageUpload={enableImageUpload}
          />
          {isDesktop && roomsByFloor.length !== index + 1 && (
            <Box mt={7} mb={6}>
              <Divider />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateRoom: (room) => dispatch(acUpdateRoom(room)),
});

export default withWidth()(connect(null, mapDispatchToProps)(RoomList));
