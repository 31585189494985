const styles = () => ({
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F6D9D8 !important',
    borderRadius: '8px',
    padding: '1px 18px',
    marginBottom: '16px',
    '@media only screen and (max-width: 768px)': {
      padding : '16px',
      marginRight: '15px',
      marginLeft: '15px',
      marginTop: '20px',
      marginBottom: '0',
    }
  },
  button: {
    height: '34px',
    padding: '8px 16px',
    fontWeight : "500",
    justifyContent: 'center',
    fontSize: '14px ',
    minWidth: '100px',
    '@media only screen and (max-width: 768px)': {
      fontSize: '13px ',
    },
    '@media only screen and (max-width: 355px)': {
      fontSize: '12px',
      minWidth: '70px',
    }
  },
  closeIconButton: {
    padding: '8px',
    color: '#111111',
  },
  closeIcon: {
    width: '18px',
    height: '18px',
  },
  message: {
    // marginLeft: '12px',
    '@media only screen and (max-width: 768px)': {
      fontSize: '14px '
    },
    '@media only screen and (max-width: 355px)': {
      fontSize: '13px',
      minWidth: '140px',
    }
  },
  messageRow: {
    display: 'block',
  },
  uploadMessageRowError: {
    backgroundColor: '#F6D9D8 !important',
  },
  linkButton: {
    color: 'black',
    fontSize: '14px',
    borderRadius: '50px'
  },
  link: {
     padding: '8px 16px',
    '@media only screen and (max-width: 768px)': {
      padding: '15px 0px 15px 30px'
    },
  }
});

export default styles;
