export const errorMessages = {
  pattern: ':attribute er ugyldig',
  required: ':attribute er påkrevd',
};

export const getErrorMessage = (errorsBag, key, label) =>
  errorsBag[key] &&
  errorsBag[key].type &&
  errorMessages[errorsBag[key].type] &&
  errorMessages[errorsBag[key].type].replace(':attribute', label || key);

export const emailPattern = /^([a-zA-Z0-9_\-+.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,}|[0-9]+)(]?)$/;

export const phonePattern = /^(\+?\d{1,3})?\s?(\d\s?){8,40}$/;
