import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { CameraAlt } from '@material-ui/icons';
import { useVariant } from '@unleash/proxy-client-react';
import styles from './style';
import AddIcon from '../../../../assets/icons/Add';
import RoomIcon from '../../../../assets/icons/DocumentBulkEditIcons/RoomIcon';
import EventIcon from '../../../../assets/icons/DocumentBulkEditIcons/EventIcon';
import { FileUploadContext } from '../../../../contexts/fileUpload';
import ActionButton from '../../../../components/atomic/ActionButton';
import BottomContextMenu from '../../../../components/BottomContextMenu';
import { acSetShowAddRoom } from '../../../../store/actions/rooms';
import { acSetShowAddEvent } from '../../../../store/actions/events';
import matomo from '../../../../utils/matomo';
import MobileActionList from '../../../../components/MobileActionList';
import CloseBold from '../../../../assets/icons/CloseBold';
import DownloadOnDiscIcon from '../../../../assets/icons/DocumentBulkEditIcons/DownloadOnDiscIcon';
import ScanCode from '../../../../assets/icons/ScanCode';
import { requestDocumentScan } from '../../../../utils/nativeBridge';
import { base64ToFile } from '../../../../utils/files';
import { actionResetMDR } from '../../../../store/actions/mdr';
import useDashboardLinkPrefix from '../../../../hooks/useDashboardLinkPrefix';
import VideoCam from '../../../../assets/icons/VideoCam';
import Photos from '../../../../assets/icons/Photos';
import { acSetOpenGlobleAddMenu } from '../../../../store/actions/action';
import { sGetGlobleAddMenuState } from '../../../../store/reducers/action';
import { isCompatibleVersion } from '../../../../utils/unleash';
import { sGetAppVersionData } from '../../../../store/reducers/appVersionData';
import AddFolder from '../../../../assets/icons/AddFolder';

const transitionDuration = 500; // ms

const AddContextMenu = ({
  open,
  classes,
  setOpen,
  setShowAddRoom,
  setShowAddEvent,
  resetMDR,
  appVersion,
  isFromPersonal,
  isBoligmappaPlusUser = false,
  onMobileFolderCreate,
  folderFeatureFlag,
}) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabValue = queryParams.get('personal');
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const { onFileUploadClick, onCameraUploadClick, setFiles, onFilesAdd } =
    useContext(FileUploadContext);
  const { enabled: isFeatureEnabled, payload: featureVariant } = useVariant(
    'boligeier.document.scan'
  );
  const enableDocumentScan =
    isFeatureEnabled &&
    isCompatibleVersion(appVersion?.nativeVersion, featureVariant.value);

  const onShowMissingDocumentsClick = () => {
    setOpen(false);
    resetMDR();
    history.push(`${dashboardLinkPrefix}/create-mdr`);
  };
  const onReceivePDFDocument = (pdfBase64) => {
    if (pdfBase64) {
      onFilesAdd({ query: { bulkUploadMode: true, source: 'listView' } });
      const pdf = base64ToFile(pdfBase64);
      setFiles([pdf]);
    }
  };
  useEffect(() => {
    window.receivePDFDocument = onReceivePDFDocument;
  }, []);
  const actions = [
    {
      icon: ScanCode,
      text: 'Scan dokument',
      exclusive: 'iosNative',
      new: true,
      bold: true,
      enable: !isFromPersonal && enableDocumentScan,
      onClick: () => {
        setOpen(false);
        requestDocumentScan();
        matomo.clickEvent({
          category: 'Global',
          action: 'Upload Document from Add Button',
        });
      },
    },
    {
      icon: CameraAlt,
      text: 'Ta bilde eller video',
      exclusive: 'ios',
      bold: true,
      enable: tabValue !== 'true' || isBoligmappaPlusUser,
      onClick: () => {
        setOpen(false);
        onCameraUploadClick(
          {
            query: {
              bulkUploadMode: true,
              source: 'listView',
              isRoomOrEvent: isFromPersonal,
              personalDocument: tabValue === 'true',
            },
          },
          'image/*, video/*'
        );
        matomo.clickEvent({
          category: 'Global',
          action: 'Upload Document from Add Button',
        });
      },
    },
    {
      icon: CameraAlt,
      text: 'Ta bilde',
      exclusive: 'android',
      bold: true,
      enable: tabValue !== 'true' || isBoligmappaPlusUser,
      onClick: () => {
        setOpen(false);
        onCameraUploadClick(
          {
            query: {
              bulkUploadMode: true,
              source: 'listView',
              isRoomOrEvent: isFromPersonal,
              personalDocument: tabValue === 'true',
            },
          },
          'image/*'
        );
        matomo.clickEvent({
          category: 'Global',
          action: 'Upload Document from Add Button',
        });
      },
    },
    {
      icon: VideoCam,
      text: 'Ta video',
      exclusive: 'android',
      bold: true,
      enable: tabValue !== 'true' || isBoligmappaPlusUser,
      onClick: () => {
        setOpen(false);
        onCameraUploadClick(
          {
            query: {
              bulkUploadMode: true,
              source: 'listView',
              isRoomOrEvent: isFromPersonal,
              personalDocument: tabValue === 'true',
            },
          },
          'video/*'
        );
        matomo.clickEvent({
          category: 'Global',
          action: 'Upload Document from Add Button',
        });
      },
    },
    {
      icon: Photos,
      text: 'Bildebibliotek eller filer',
      separator: true,
      bold: true,
      enable: tabValue !== 'true' || isBoligmappaPlusUser,
      onClick: () => {
        setOpen(false);
        onFileUploadClick(
          {
            query: {
              bulkUploadMode: true,
              source: 'listView',
              newUpload: true,
              isRoomOrEvent: isFromPersonal,
              personalDocument: tabValue === 'true',
            },
          },
          'video/*,image/*,.xml,application/xml,.xls,application/vnd.ms-excel,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.doc,application/msword,.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,application/pdf,.ppt,application/vnd.ms-powerpoint,.pptx,application/vnd.openxmlformats-officedocument.presentationml.presentation,.csv,text/csv'
        );
        matomo.clickEvent({
          category: 'Global',
          action: 'Upload Document from Add Button',
        });
      },
    },
    {
      icon: AddFolder,
      text: 'Ny mappe',
      separator: true,
      bold: true,
      enable:
        folderFeatureFlag &&
        onMobileFolderCreate &&
        (isFromPersonal || tabValue === 'true') &&
        isBoligmappaPlusUser,
      onClick: () => {
        setOpen(false);
        onMobileFolderCreate();
        matomo.clickEvent({
          category: 'Global',
          action: 'Create folder from Add Button',
        });
      },
    },
    {
      icon: () => RoomIcon({ width: 20, height: 20 }),
      text: 'Nytt rom',
      enable: !isFromPersonal,
      onClick: () => {
        setShowAddRoom(true);
        matomo.clickEvent({
          category: 'Global',
          action: 'Create New Room from Add Button',
        });
      },
    },
    {
      icon: EventIcon,
      text: 'Ny hendelse',
      separator: true,
      enable: !isFromPersonal,
      onClick: () => {
        setShowAddEvent(true);
        matomo.clickEvent({
          category: 'Global',
          action: 'Create New Event from Add Button',
        });
      },
    },
    {
      icon: DownloadOnDiscIcon,
      text: 'Etterspør dokumentasjon fra håndverker',
      separator: true,
      enable: !isFromPersonal,
      onClick: () => {
        onShowMissingDocumentsClick(true);
      },
    },
  ];

  return (
    <div>
      <ActionButton
        color="inherit"
        className={clsx(classes.actionButton, open && classes.actionButtonOpen)}
        onClick={() => {
          setOpen(!open);
        }}
        style={{
          zIndex: 1201,
          transition: `all ${transitionDuration}ms`,
        }}
      >
        <AddIcon color="inherit" />
      </ActionButton>

      <BottomContextMenu
        open={open}
        setOpen={setOpen}
        disableDiscovery
        showAnchor={false}
        transitionDuration={{
          enter: transitionDuration,
          exit: transitionDuration * 0.5,
        }}
        paperClass={classes.SwipeableDrawer}
        ModalProps={{
          style: {
            zIndex: 1200,
          },
        }}
      >
        <div className={classes.titleRow}>
          <p className={classes.title}>Legg til</p>
          <button
            className={classes.closeButton}
            type="button"
            onClick={() => setOpen(false)}
            aria-label="Close"
          >
            <CloseBold fontSize="small" />
          </button>
        </div>
        <MobileActionList actions={actions} separatorPositions={[1, 2]} />
      </BottomContextMenu>
    </div>
  );
};

const mapStateToProps = (state) => ({
  open: sGetGlobleAddMenuState(state),
  appVersion: sGetAppVersionData(state),
});
const mapDispatchToProps = (dispatch) => ({
  setShowAddRoom: (payload) => dispatch(acSetShowAddRoom(payload)),
  setShowAddEvent: (payload) => dispatch(acSetShowAddEvent(payload)),
  resetMDR: () => dispatch(actionResetMDR()),
  setOpen: (value) => dispatch(acSetOpenGlobleAddMenu(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddContextMenu));
