import React from 'react';
import { CardMedia } from '@material-ui/core';
import Card from '../../Card';
import Typography from '../../atomic/Typography';

import ConstructionWorker from '../../../assets/images/construction-worker.png';
import { BottomSectionStyles } from './styles';

export default function MDRBottomSection({
  isDesktop,
  classes = BottomSectionStyles(),
  title,
  content,
}) {
  return (
    <div className={classes.mdrBottomSection}>
      <Card
        border={isDesktop ? 1 : 0}
        padding={isDesktop ? 3 : 0}
        height="100%"
        className={classes.mdrBottomSectionCard}
      >
        <Typography className={classes.cardTitle}>{title}</Typography>
        <Typography className={classes.cardContent}>{content}</Typography>
      </Card>
      <Card
        className={classes.mdrBottomSectionImage}
        border={0}
        padding={0}
        height="100%"
      >
        <CardMedia
          component="img"
          image={ConstructionWorker}
          alt="construction worker on site holding a hammer"
        />
      </Card>
    </div>
  );
}
