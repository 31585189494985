import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Box from '../../../atomic/Box';
import IconButton from '../../../atomic/IconButton';
import Close from '../../../../assets/icons/Close';

const SelectedFilter = ({ classes, filter, onCloseClick }) => (
  <Box className={classes.root}>
    <span className={classes.label}>
      {filter && filter.label ? filter.label : filter}
    </span>

    <IconButton size="small" onClick={onCloseClick}>
      <Close className={classes.closeIcon} />
    </IconButton>
  </Box>
);

export default withStyles(styles)(SelectedFilter);
