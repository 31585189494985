import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';

import styles from './style';
import Typography from '../atomic/Typography';
import ProgressButton from '../ProgressButton';
import localStorage from '../../utils/localStorage';
import { PREVIEWENABLEDFOR } from '../../constants';
import useHeaderSnackbar from '../../hooks/useHeaderSnackbar';
import useSnackbar from '../../hooks/useSnackbar';
import useDesktop from '../../hooks/useDesktop';

const TopBanner = ({ classes }) => {
  const history = useHistory();
  const { boligmappaNumber } = useParams();
  const { showSnackbar, closeSnackbar } = useHeaderSnackbar();
  const isDesktop = useDesktop();

  const featureUrl = `/dashboard/${boligmappaNumber}/more/private-share`;

  const previewEnabledForArr =
    JSON.parse(localStorage.get(PREVIEWENABLEDFOR)) || [];

  const previewModeEnabled = previewEnabledForArr.includes(boligmappaNumber);

  const clearPreview = () => {
    const previewEnabledForArrModified = previewEnabledForArr.filter(
      (i) => i !== boligmappaNumber
    );

    localStorage.set(
      PREVIEWENABLEDFOR,
      JSON.stringify(previewEnabledForArrModified)
    );
  };
  const { showSuccessMessage } = useSnackbar();

  const onPreviewComplete = (actionhandled) => {
    clearPreview();
    if (!actionhandled) {
      closeSnackbar();
    }
    showSuccessMessage('Lenke til din offenlige boligmappa apprettet');
    history.push(featureUrl);
  };

  useEffect(() => {
    if (previewModeEnabled) {
      showSnackbar({
        className: classes.root,
        onClose: () => {
          clearPreview();
          onPreviewComplete(true);
        },
        content: (
          <Typography
            fontSize={18}
            className={!isDesktop ? classes.typography : null}
          >
            <strong>
              {isDesktop
                ? 'Forhåndsvisning av din offentlige delte boligmappa'
                : 'Forhåndsvisning'}
            </strong>
            <ProgressButton
              color="primary"
              variant="contained"
              className={isDesktop ? classes.button : null}
              onClick={() => onPreviewComplete()}
            >
              {isDesktop ? 'Fullfør delingen' : 'Fullfør'}
            </ProgressButton>
          </Typography>
        ),
        closeButton: !isDesktop && null,
      });
    }

    return closeSnackbar;
  }, []);

  return null;
};

export default withStyles(styles)(TopBanner);
