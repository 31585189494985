import {
  ADD_ROOM,
  SET_ROOMS,
  CLEAR_ROOMS,
  DELETE_ROOM,
  UPDATE_ROOM,
  SYNC_ROOMS_EVENTS,
  SET_FETCHING_ROOMS,
  SYNC_ROOMS_DOCUMENTS,
  SET_FETCHING_ROOMS_ERROR,
  DETACH_DOCUMENT_FROM_ROOMS,
  SET_SHOW_ADD_ROOM,
  SET_SHOW_EDIT_ROOM,
  SET_ROOM_MODE,
  SET_ATTACHED_IDS,
  SET_RETURN_ON_SUCCESS,
} from '../reducers/rooms';

export const acSetRooms = (rooms) => ({
  type: SET_ROOMS,
  payload: rooms,
});

export const acClearRooms = () => ({
  type: CLEAR_ROOMS,
});

export const acAddRoom = (room) => ({
  type: ADD_ROOM,
  payload: room,
});

export const acDeleteRoom = (roomId) => ({
  type: DELETE_ROOM,
  payload: { roomId },
});

export const acUpdateRoom = (room) => ({
  type: UPDATE_ROOM,
  payload: room,
});

export const acSyncRoomsEvents = ({ event, eventId, roomIds }) => ({
  type: SYNC_ROOMS_EVENTS,
  payload: {
    event,
    eventId,
    roomIds,
  },
});

export const acSyncRoomsDocuments = ({ roomIds, document, documentId }) => ({
  type: SYNC_ROOMS_DOCUMENTS,
  payload: {
    roomIds,
    document,
    documentId,
  },
});

export const acSetFetchingRooms = (fetching) => ({
  type: SET_FETCHING_ROOMS,
  payload: fetching,
});

export const acSetFetchingRoomsError = (error) => ({
  type: SET_FETCHING_ROOMS_ERROR,
  payload: error,
});

export const acDetachDocumentFromRooms = (document) => ({
  type: DETACH_DOCUMENT_FROM_ROOMS,
  payload: document,
});

export const acSetShowAddRoom = (boolean) => ({
  type: SET_SHOW_ADD_ROOM,
  payload: boolean,
});

export const acSetShowEditRoom = (boolean) => ({
  type: SET_SHOW_EDIT_ROOM,
  payload: boolean,
});

export const acSetRoomMode = (mode) => ({
  type: SET_ROOM_MODE,
  payload: mode,
});

export const acSetRoomAttachedIds = (ids) => ({
  type: SET_ATTACHED_IDS,
  payload: ids,
});

export const acSetRoomReturnOnSuccess = (boolean) => ({
  type: SET_RETURN_ON_SUCCESS,
  payload: boolean,
});
