import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import Box from '../atomic/Box';
import Home from '../../assets/icons/Home';

const PropertyImagePlaceholder = ({ classes, iconProps = {}, ...props }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    bgcolor="primary.greyLight"
    {...props}
  >
    <Home className={classes.placeholderIcon} {...iconProps} />
  </Box>
);

export default withStyles(styles)(PropertyImagePlaceholder);
