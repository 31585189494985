import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './style';
import { isString, toLowerCase } from '../../utils/strings';

const TextHighlighted = ({ classes, children, searchQuery }) => {
  if (
    !isString(children) ||
    !isString(searchQuery) ||
    children === '' ||
    searchQuery === '' ||
    !toLowerCase(children).includes(toLowerCase(searchQuery))
  ) {
    return children ?? '';
  }

  const indexStart = toLowerCase(children).indexOf(toLowerCase(searchQuery));

  const indexEnd =
    toLowerCase(children).indexOf(toLowerCase(searchQuery)) +
    searchQuery.length;

  return (
    <>
      {children.slice(0, indexStart)}

      <span className={classes.highlighted}>
        {children.slice(indexStart, indexEnd)}
      </span>

      {children.slice(indexEnd)}
    </>
  );
};

export default withStyles(styles)(TextHighlighted);
