/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { OverviewBox } from './components';
import Card from '../../../../../components/Card';
import PropertyCompanies from '../../../../../modules/PropertyCompanies';
import useDesktop from '../../../../../hooks/useDesktop';
import { useFetchCompanies } from '../../../../../api/hooks';
import { useActiveProperty } from '../../../../../store/hooks';

export function PropertyCompaniesSection() {
  const isDesktop = useDesktop();
  const { property } = useActiveProperty();
  const companies = useFetchCompanies(property, true);

  if ((companies.data || []).length > 0) {
    return (
      <OverviewBox>
        <Card border={isDesktop ? 1 : 0} padding={isDesktop ? 3 : 0}>
          <PropertyCompanies
            companies={companies.data}
            error={companies.error}
            fetching={companies.isLoading || !companies.data}
          />
        </Card>
      </OverviewBox>
    );
  }
  return <></>;
}
export default PropertyCompaniesSection;
