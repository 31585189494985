import { request, post } from '../utils/http';
import {
  authTokensEndpoint,
  backendBaseUrl,
  authLogoutEndpoint,
} from '../config';

export const getRefreshToken = () =>
  request({
    method: 'get',
    url: 'refresh',
    baseURL: backendBaseUrl,
  })
    .then((response) => [null, response.data])
    .catch((error) => [error, null]);

export const getAuthTokens = (data) =>
  post(authTokensEndpoint, data)
    .then((response) => [null, response.data])
    .catch((error) => [error, null]);

export const kcLogout = () =>
  post(authLogoutEndpoint)
    .then((response) => [null, response.data])
    .catch((error) => [error, null]);
