import { chartColors } from '@style/vars/colors';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  TimeScale,
  Filler,
  BarElement,
  BarController,
  Legend,
  Scale,
  Tick,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import getQuarter from 'date-fns/getQuarter';
import getYear from 'date-fns/getYear';
import {
  BarChartGap,
  BarChartLine,
  BarChartOrientation,
} from './BarChart/interfaces';
import { CalculationType } from './LineChart/interfaces';

export const lineChartJsInit = () => {
  ChartJS.register(
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    TimeScale,
    CategoryScale,
    Filler,
    ChartDataLabels,
  );
};

export const barChartJsInit = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    BarController,
    Title,
    Tooltip,
    Legend,
  );
};

// get number in spaced format: 1000000 to be 1 000 000
export const getSpacedNumber = (value: number): string => {
  return typeof value === 'number'
    ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    : null;
};

export const getLineTooltipTitle = (suffix: string = '') => {
  return ([tooltip]) => {
    const date = new Date(tooltip.label.replace('a.m.', ''));
    const title = `${getQuarter(date)}. kvartal ${getYear(date)}`;

    return `${title} ${suffix}`;
  };
};

export const getLineTooltipFooter = (
  suffix: string,
  calculationType: CalculationType = 'basic',
) => {
  return ([tooltip]) => {
    let value;
    switch (calculationType) {
      // difference between hovered point and previous point value:
      case 'incremental':
        const previousIndex =
          tooltip.dataIndex + 1 === tooltip.dataset.data.length
            ? tooltip.dataIndex
            : tooltip.dataIndex + 1;
        value =
          tooltip.dataset.data[tooltip.dataIndex].y -
          tooltip.dataset.data[previousIndex].y;
        break;
      // difference between first point and hovered:
      case 'basic':
      default:
        value = tooltip.parsed.y;
        break;
    }
    const footer = `${value >= 0 ? '+' : '-'} ${
      Math.round(Math.abs(value) * 10) / 10
    }${suffix}`;

    return footer;
  };
};

export const getBarTooltipTitle = (
  suffix: string = '',
  asNumber: boolean = true,
) => {
  return ([tooltip]) =>
    asNumber
      ? `${getSpacedNumber(parseInt(tooltip.label, 10))} ${suffix}`
      : `${tooltip.label} ${suffix}`;
};

export const getBarTooltipFooter = (
  suffix: string = '',
  orientation: BarChartOrientation = 'horizontal',
) => {
  return ([tooltip]) =>
    `${
      orientation === 'horizontal' ? tooltip.parsed.x : tooltip.parsed.y
    } ${suffix}`;
};

export const tickCallback = (suffix: string = '') => {
  return (value) => {
    return `${value}${suffix}`;
  };
};

export const barTickCallback = (
  suffix: string = '',
  asNumber: boolean = true,
) => {
  return function callbackFunc(value, index, ticks) {
    let tick = this.getLabelForValue(value);

    if (asNumber) {
      const maxTick = Math.max(
        ...ticks.map((o) => this.getLabelForValue(o.value)),
      );

      if (tick === maxTick) {
        tick = `> ${getSpacedNumber(tick)}`;
      } else {
        tick = getSpacedNumber(tick);
      }
    }

    return `${tick} ${suffix}`;
  };
};

export const createGradient = (ctx, area, gradientData) => {
  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradientData.forEach((item) => {
    if (item?.stop && item?.color) {
      gradient.addColorStop(item.stop, item.color);
    }
  });

  return gradient;
};

// returns position on chart base on value, ticks and scale
export const calculatePositionOnChart = (
  scale: Scale,
  tickData: Tick[],
  value: number,
  orientation: BarChartOrientation,
): number => {
  const connectedTick = tickData?.find((tick) => {
    if (orientation === 'horizontal') {
      return +tick.label <= value;
    }
    return +tick.label >= value;
  });

  if (!connectedTick || connectedTick.value === 0) {
    return null;
  }
  const tickIndex = connectedTick?.value;
  const tickValue = connectedTick?.label;
  const labelValueGap =
    +tickData[tickIndex - 1].label - +tickData[tickIndex].label;
  const labelGap = value - +tickValue;
  const extraPercentage = labelGap / labelValueGap;
  const position = scale.getPixelForValue(tickIndex - extraPercentage);

  return position;
};

// get max value, increased by 10% to have empty space between bar end and chart end
export const getMaxBarChart = (values: number[]): number => {
  const max = Math.max(...values);
  return Math.ceil(Math.ceil(max * 1.1) / 10) * 10;
};

// converts values to numbers
export const numberConvertedTickArray = (
  ticks: Tick[],
  yAxisSuffix: string,
): Tick[] => {
  return ticks
    ? ticks.map((tick) => {
        return {
          ...tick,
          label: (tick.label as string)
            .substring(0, tick.label.length - yAxisSuffix.length)
            .replace(/[^a-zA-Z0-9]/g, ''),
        };
      })
    : [];
};

// Displays custom tooltip on gap between lines.
// Detects that mouse is over gap, takes lines data and attaches to tooltip title/footer
const showTooltipOnGaps = (
  chartJs,
  gaps: BarChartGap[],
  lines: BarChartLine[],
  orientation: BarChartOrientation,
  event: MouseEvent,
  activeTickScale: Scale,
  tickData: Tick[],
) => {
  const chart = chartJs;
  const rightTooltipShift = 32;
  const topTooltipShift = 48;

  gaps.forEach((gap) => {
    if (gap.showTooltip) {
      const topLine = lines.find((line) => line.value === gap.topValue);
      const bottomLine = lines.find((line) => line.value === gap.bottomValue);

      if (topLine && bottomLine) {
        const positionFrom = calculatePositionOnChart(
          activeTickScale,
          tickData,
          gap.topValue,
          orientation,
        );
        const positionTo = calculatePositionOnChart(
          activeTickScale,
          tickData,
          gap.bottomValue,
          orientation,
        );
        let intersection;
        let customPosition;

        if (orientation === 'horizontal') {
          intersection =
            event.offsetY > positionFrom &&
            event.offsetY < positionTo &&
            event.offsetX < chart.chartArea.right &&
            event.offsetX > chart.chartArea.left;
          customPosition = {
            right: rightTooltipShift,
            left: 'unset',
            top: (positionTo + positionFrom) / 2,
            transform: 'none',
          };
        } else {
          intersection =
            event.offsetX < positionFrom &&
            event.offsetX > positionTo &&
            event.offsetY < chart.chartArea.bottom &&
            event.offsetY > chart.chartArea.top;
          customPosition = {
            left: (positionTo + positionFrom) / 2,
            right: 'unset',
            top: chart.chartArea.top + topTooltipShift,
            transform: 'translate(-50%, 0)',
          };
        }

        if (intersection) {
          chart.tooltip.customPosition = customPosition;
          chart.tooltip.title = [
            `${topLine.title}: ${getSpacedNumber(topLine.value)} ${
              topLine.suffix || ''
            }`,
          ];
          chart.tooltip.footer = [
            `${bottomLine.title}: ${getSpacedNumber(bottomLine.value)} ${
              bottomLine.suffix || ''
            }`,
          ];
        } else {
          chart.tooltip.customPosition = null;
        }

        chart.update();
      }
    }
  });
};

export const drawLines = (
  lines: BarChartLine[],
  orientation: BarChartOrientation,
  yAxisSuffix: string = '',
) => {
  return (chart) => {
    if (!lines || !lines.length) {
      return;
    }
    const {
      ctx,
      chartArea: { left, right, width, height, top },
      scales: { y, x },
    } = chart;
    const horizontal = orientation === 'horizontal';
    const activeTickScale = horizontal ? y : x;
    const tickData = numberConvertedTickArray(
      activeTickScale.ticks,
      yAxisSuffix,
    );

    lines.forEach((line) => {
      const linePosition = calculatePositionOnChart(
        activeTickScale,
        tickData,
        line.value,
        orientation,
      );

      if (linePosition) {
        // draw line
        ctx.strokeStyle = line.color;
        ctx.lineWidth = 1.5;

        if (line.lineDashed) {
          ctx.setLineDash([2.5, 5]);
        }

        if (horizontal) {
          ctx.strokeRect(
            left,
            linePosition,
            width - ((line.hasDot && 4) || 0),
            0,
          );
        } else {
          ctx.strokeRect(
            linePosition,
            top + ((line.hasDot && 4) || 0),
            0,
            height - ((line.hasDot && 4) || 0),
          );
        }

        ctx.restore();

        // draw dot
        if (line.hasDot) {
          ctx.beginPath();
          ctx.setLineDash([]);
          if (horizontal) {
            ctx.arc(right, linePosition, 5, 0, 2 * Math.PI, true);
          } else {
            ctx.arc(linePosition, top, 5, 0, 2 * Math.PI, true);
          }
          ctx.strokeStyle = line.dotColor || line.color;
          ctx.fillStyle = line.fillDot
            ? line.dotColor || line.color
            : chartColors.white;
          ctx.lineWidth = 3;
          ctx.stroke();
          ctx.fill();
          ctx.restore();
        }
      }
    });
  };
};

export const drawGaps = (
  gaps: BarChartGap[],
  lines: BarChartLine[],
  orientation: BarChartOrientation,
  yAxisSuffix: string = '',
) => {
  return (chartJs) => {
    if (!gaps || !gaps.length) {
      return;
    }
    const {
      ctx,
      chartArea: { left, width, height, top },
      scales: { y, x },
    } = chartJs;
    const chart = chartJs;
    const activeTickScale = orientation === 'horizontal' ? y : x;
    const tickData = numberConvertedTickArray(
      activeTickScale.ticks,
      yAxisSuffix,
    );

    gaps.forEach((gap) => {
      const positionFrom = calculatePositionOnChart(
        activeTickScale,
        tickData,
        gap.topValue,
        orientation,
      );
      const positionTo = calculatePositionOnChart(
        activeTickScale,
        tickData,
        gap.bottomValue,
        orientation,
      );

      if (positionTo && positionFrom) {
        ctx.fillStyle = gap.color;
        if (orientation === 'horizontal') {
          ctx.fillRect(left, positionFrom, width, -(positionFrom - positionTo));
        } else {
          ctx.fillRect(positionFrom, top, -(positionFrom - positionTo), height);
        }

        ctx.restore();
      }
    });

    chart.canvas.onmousemove = (event) => {
      showTooltipOnGaps(
        chart,
        gaps,
        lines,
        orientation,
        event,
        activeTickScale,
        tickData,
      );
    };
  };
};

// creates custom html tooltip to replace in-built and control both cases:
// tooltip for bars and tooltip for custom elements
const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = '#fff';
    tooltipEl.style.borderRadius = '5px';
    tooltipEl.style.border = `1px solid ${chartColors.lightGray}`;
    tooltipEl.style.color = chartColors.darkGray;
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';
    tooltipEl.style.whiteSpace = 'nowrap';
    tooltipEl.style.padding = '6px 12px';

    const table = document.createElement('table');
    table.style.margin = '0px';
    table.style.lineHeight = '20px';
    table.style.fontSize = '12px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

// html tooltip logic handler
export const externalTooltipHandler = (context) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0 && !tooltip.customPosition) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.title || tooltip.footer) {
    const titleLines = tooltip.title || [];
    const footerLines = tooltip.footer || [];

    const tableHead = document.createElement('thead');

    titleLines.forEach((title) => {
      const tr = document.createElement('tr');
      tr.style.borderWidth = '0';

      const th = document.createElement('th');
      th.style.borderWidth = '0';
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    tableBody.style.fontWeight = 'bold';
    footerLines.forEach((footer) => {
      const tr = document.createElement('tr');
      tr.style.borderWidth = '0';

      const td = document.createElement('td');
      td.style.borderWidth = '0';
      td.style.whiteSpace = 'nowrap';

      const text = document.createTextNode(footer);

      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  // Display, position
  tooltipEl.style.opacity = 1;

  // for custom elements
  if (tooltip.customPosition && tooltip.opacity === 0) {
    tooltipEl.style.top = `${
      tooltip.customPosition.top - tooltipEl.offsetHeight / 2
    }px`;
    tooltipEl.style.left =
      tooltip.customPosition.left === 'unset'
        ? 'unset'
        : `${tooltip.customPosition.left}px`;
    tooltipEl.style.right =
      tooltip.customPosition.right === 'unset'
        ? 'unset'
        : `${tooltip.customPosition.right}px`;
    tooltipEl.style.transform = tooltip.customPosition.transform;
  } else {
    // for bar elements
    tooltipEl.style.left = `${tooltip.caretX}px`;
    tooltipEl.style.right = 'unset';
    tooltipEl.style.top = `${tooltip.caretY - tooltipEl.offsetHeight / 2}px`;
    tooltipEl.style.transform = 'translate(-50%, 0)';
  }
};
