const styles = (theme) => ({
  root: {
    boxShadow: 'none',
    '@supports (padding-top: env(safe-area-inset-top))': {
      paddingTop: `env(safe-area-inset-top)`,
    },
  },
  headerWrapper: {
    minHeight: '56px',
  },
  notificationIcon: {
    width: '100%',
    height: '100%',
    maxWidth: '1.5rem',
    maxHeight: '1.5rem',
    fill: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  badge: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.green,
  },
  titleNavItem: {
    paddingRight: '8px',
    borderRight: '1px solid #E6E6E6',
    textDecoration: 'unset',
    '& p': {
      fontSize: '14px',
      color: '#000',
      margin: 'unset',
    },
  },
  logo: {
    marginRight: '15px',
  },
  grow: {
    flexGrow: 1,
  },
});

export default styles;
