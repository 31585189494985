import { withStyles } from '@material-ui/core';
import React, { createElement, forwardRef } from 'react';

import {
  getDocumentName,
  isDocumentImage,
  getDocumentType,
  getFileThumbNail,
  getDocumentFileType,
  getDocumentCreatedDate,
  getDocumentOwnedByName,
  getDocumentCompanyName,
  getOriginalDocumentPath,
} from '../../../utils/documents';

import styles from './style';
import Image from '../../Image';
import ListItem from '../../DocumentList/ListItem';
import { formattedDate } from '../../../utils/dates';
import ImgIcon from '../../../assets/icons/DocumentIcons/ImgIcon';

export const DocumentOption = forwardRef(
  ({ classes, data, innerProps }, ref) => {
    const { document } = data;

    const ownedByName = getDocumentOwnedByName(document);
    const companyName = getDocumentCompanyName(document);

    const uploadedByName = ownedByName || companyName;
    const documentType = getDocumentType(document);
    const createdDate = formattedDate(getDocumentCreatedDate(document));

    const primaryText = getDocumentName(document);
    const secondaryText = [uploadedByName, createdDate, documentType].join(
      ' • '
    );

    const getIcon = () => {
      if (isDocumentImage(document)) {
        return (
          <Image
            className={classes.imageIcon}
            fallbackComponent={<ImgIcon />}
            alt={getDocumentName(document)}
            src={getOriginalDocumentPath(document)}
          />
        );
      }

      return createElement(getFileThumbNail(getDocumentFileType(document)));
    };

    return (
      <ListItem
        ref={ref}
        icon={getIcon()}
        className={classes.root}
        primaryText={primaryText}
        secondaryText={secondaryText}
        {...innerProps}
      />
    );
  }
);

export default withStyles(styles)(DocumentOption);
