import React from 'react';
import { withStyles } from '@material-ui/core';

import {
  getCompanyId,
  getCompanyName,
  getCompanyLogoPath,
  getCompanyFranchise,
  getCompanyIndustryAssociation,
  getCompanyDisplayName,
} from '../../../utils/companies';

import styles from './style';
import Image from '../../Image';
import Box from '../../atomic/Box';
import Link from '../../atomic/Link';
import GridItem from '../../GridItem';
import Typography from '../../atomic/Typography';
import TextHighlighted from '../../TextHighlighted';
import useDashboardLinkPrefix from '../../../hooks/useDashboardLinkPrefix';
import CompanyProfileImage from '../../../assets/images/companyProfile.png';
import matomo from '../../../utils/matomo';

const CompanyItem = ({
  classes,
  searchQuery,
  company,
  requestFrom,
  ...props
}) => {
  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const franchiseProfile = getCompanyFranchise(company);

  const handleClick = () => {
    matomo.clickEvent({
      category: 'Find Professionals',
      name: `Visit Profile from ${requestFrom} page`,
      action: 'Visit Company Profile',
    });
  };

  const label = (
    <>
      <Typography color="textSecondary" className={classes.label}>
        <strong>
          <TextHighlighted searchQuery={searchQuery}>
            {getCompanyDisplayName(company) ||
              getCompanyName(company) ||
              getCompanyDisplayName(franchiseProfile) ||
              getCompanyName(franchiseProfile)}
          </TextHighlighted>
        </strong>
      </Typography>

      <Typography className={classes.companyIndustry}>
        <small>
          {getCompanyIndustryAssociation(company) ||
            getCompanyIndustryAssociation(franchiseProfile)}
        </small>
      </Typography>
    </>
  );

  return (
    <GridItem
      label={label}
      component={Link}
      underline={false}
      className={classes.companyItemGrid}
      to={`${dashboardLinkPrefix}/company/${getCompanyId(company)}`}
      onClick={handleClick}
      {...props}
    >
      <Box position="relative" height="100%">
        <Image
          className={classes.logoImage}
          fallbackSrc={CompanyProfileImage}
          src={
            getCompanyLogoPath(company) || getCompanyLogoPath(franchiseProfile)
          }
        />
      </Box>
    </GridItem>
  );
};

export default withStyles(styles)(CompanyItem);
