const styles = (theme) => ({
  selectIcon: {
    width: '3rem',
    height: '3rem',
    marginBottom: '10px',
    fill: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  actionBtnNotificationMoble:{
    position: 'sticky',
    bottom: '90px',
    background: 'white',
    borderTop: '1px #dedfdf solid',
    marginTop: '20px',
    padding: '15px'
  },
});

export default styles;
