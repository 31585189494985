import React from 'react';
import Box from '../../../atomic/Box';
import Grid from '../../../atomic/Grid';
import Card from '../../../Card';
import Image from '../../../Image';
import { useFindProfessionalsStyles } from '../../findProfessionalStyles';
import CompanyLogoPlaceholder from '../../../../assets/images/findProfesionalCompanyLogo.png';
import ContentLoader from '../../../loaders/ContentLoader';

const ProfessionalItemLoading = () => {
  const classes = useFindProfessionalsStyles();

  return (
    <Card
      borderRadius={8}
      borderColor="#e5e5e5"
      className={classes.companyCard}
    >
      <Grid container>
        <Grid item xs={5} className={classes.companyCardImageArea}>
          <Box className={classes.companyImageSection}>
            <Image
              className={classes.logoImage}
              fallbackSrc={CompanyLogoPlaceholder}
            />
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Box mb={2.4} className={classes.companyCardTitleArea}>
            <ContentLoader width="100%" height={20} />
          </Box>
          <Box className={classes.companyProfessionDataArea}>
            <ContentLoader width="100%" height={20} />
          </Box>
          <Box className={classes.companyProfessionDataArea}>
            <ContentLoader width="100%" height={20} />
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.companyButtonArea}>
          <ContentLoader width="100%" height={20} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProfessionalItemLoading;
