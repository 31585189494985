import styled from 'styled-components';

export const StyledFeatureBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.$backgroundColor || '#fff'};
  border-radius: 8px;
  width: 100%;
  max-width: 350px;
  padding: 32px;
  margin: 0;

  @media (max-width: 767px) {
    padding: 24px;
    max-width: 100%;
  }

  .link_wrapper {
    max-width: 100%;
    text-decoration: unset;
  }

  .feature_content {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    @media (max-width: 767px) {
      row-gap: 8px;
    }

    @media (max-width: 767px) {
      margin-right: 8px;
    }

    h2 {
      font-size: 20px;
      line-height: 31px;
      font-weight: 700;
      color: #111;
      margin: 0;

      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 28px;
      }
    }
    p {
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      color: #111;
      margin: 0;

      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 22px;
      }
    }
    .link {
      color: #164144;
      font-weight: 500;
    }
    .soon {
      font-size: 16px;
      line-height: 25px;
      font-weight: 700;
      color: #31777b;
    }
    .logo {
      max-width: 100%;
    }
    h2.place_holder_heading {
      color: #356e3c;
      font-size: 24px;
      line-height: 37px;
      font-weight: 700;
    }
    p.place_holder_description {
      font-size: 16px;
      line-height: 25px;
    }
  }

  .arrowIcon {
    transform: rotate(-90deg);
    flex-shrink: 0;
    width: 14px;
    height: 14px;
  }
`;
