import React from 'react';
import { Tooltip as TooltipBase, withStyles } from '@material-ui/core';

import styles from './style';

const Tooltip = ({ children, disableTouchListener = true, ...props }) => (
  <TooltipBase disableTouchListener={disableTouchListener} {...props}>
    {children}
  </TooltipBase>
);

export default withStyles(styles)(Tooltip);
