import React, { createElement, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';

import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

import {
  sGetUnseenNotifications,
  sGetNotificationsFetching,
} from '../../../../store/reducers/notifications';

import { privateNavbarMenu } from '../../../../structure/menu';
import MobileNavbarBase from '../../../../components/MobileNavbar';
import MobileNavbarItem from '../../../../components/MobileNavbarItem';
import useDashboardLinkPrefix from '../../../../hooks/useDashboardLinkPrefix';
import { sGetPendingRequests } from '../../../../store/reducers/accessRequests';
import BottomContextMenu from '../../../../components/BottomContextMenu';
import Close from '../../../../assets/icons/Close';
import styles from './style';
import { isNativeEnv } from '../../../../utils/nativeBridge';

const MobileNavbar = ({
  hidden,
  unseenNotifications,
  fetchingNotifications,
  pedningAccessRequests,
  classes,
}) => {
  const routeMatch = useRouteMatch('/dashboard/:boligmappaNumber/:location*');
  const { params } = routeMatch || {};
  const [isMoreShown, setIsMoreShown] = useState(false);
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const unseenNotificationsCount = unseenNotifications.length;
  const isNative = isNativeEnv();
  const isActive = (link) => params?.location.includes(link);

  const getBadgeContent = (isNotification) =>
    isNotification && !fetchingNotifications ? unseenNotificationsCount : null;

  const onMoreAction = () => {
    setIsMoreShown(true);
  };

  const onItemClick = (link, moreAction) => {
    if (moreAction) {
      onMoreAction(moreAction);
    }
  };

  const navBarItems = privateNavbarMenu.filter(
    (item) =>
      !item.desktopOnly &&
      !item.secondary &&
      !(isNative && item.isDisabledInApp)
  );

  const navBarSecondaryItems = privateNavbarMenu.filter(
    (item) => item.secondary && !(isNative && item.isDisabledInApp)
  );

  return (
    <MobileNavbarBase showLabels hidden={hidden}>
      {navBarItems.map(
        ({
          link,
          title,
          icon,
          activeIcon,
          moreAction,
          isNotification,
          iconProps,
          notificationLabel,
        }) => (
          <MobileNavbarItem
            icon={isActive(link) ? activeIcon : icon}
            title={title}
            iconProps={iconProps}
            isActive={isActive(link)}
            key={`bottom-nav-item-${link}`}
            to={`${dashboardLinkPrefix}/${link}`}
            moreAction={moreAction}
            onClick={() => onItemClick(link, moreAction)}
            badgeContent={getBadgeContent(isNotification)}
            notificationCount={
              notificationLabel ? pedningAccessRequests.length : 0
            }
          />
        )
      )}
      <BottomContextMenu
        open={isMoreShown}
        onClose={() => setIsMoreShown(false)}
        closeOnContentClick
        paperClass={classes.contextPaper}
        showAnchor={false}
      >
        <div className={classes.contextContent}>
          <div className={classes.contextMenuHeader}>
            <p className={classes.contextMenuTitle}>Mer</p>
            <Close />
          </div>
          {navBarSecondaryItems.map((item) => (
            <Link
              className={clsx([
                classes.contextItem,
                isActive(item.link) && 'active',
              ])}
              key={`bottom-nav-item-${item.link}`}
              to={`${dashboardLinkPrefix}/${item.link}`}
            >
              {createElement(
                isActive(item.link) ? item.activeIcon : item.icon,
                {
                  className: classes.icon,
                }
              )}
              <p>{item.title}</p>
            </Link>
          ))}
        </div>
      </BottomContextMenu>
    </MobileNavbarBase>
  );
};

const mapStateToProps = (state) => ({
  unseenNotifications: sGetUnseenNotifications(state),
  fetchingNotifications: sGetNotificationsFetching(state),
  pedningAccessRequests: sGetPendingRequests(state),
});

export default connect(mapStateToProps, null)(withStyles(styles)(MobileNavbar));
