import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { withStyles } from '@material-ui/core';

import styles from './style';
import useSnackbar from '../../../../../hooks/useSnackbar';
import Form from '../../../../../components/forms/BaseForm';
import Checkbox from '../../../../../components/atomic/Checkbox';
import useActionDialog from '../../../../../hooks/useActionDialog';
import Typography from '../../../../../components/atomic/Typography';
import PageLayout from '../../../../../components/layouts/WithHeader';
import { deletePropertyOwner as apiDeletePropertyOwner } from '../../../../../api/propertyowners';
import useDesktop from '../../../../../hooks/useDesktop';

const delayBeforeRedirect = 3000; // ms

const ProfileDelete = ({ classes }) => {
  const history = useHistory();
  const isDesktop = useDesktop();
  const { showSuccessMessage } = useSnackbar();
  const { showActionDialog } = useActionDialog();

  const [requestError, setRequestError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const onFormSubmit = async () => {
    showActionDialog({
      submitButtonText: 'Slett',
      message: 'Er du sikker på at du vil slette kontoen din?',
      onSubmit: async () => {
        setRequestInProgress(true);

        const [error, response] = await apiDeletePropertyOwner();

        if (error && !response) {
          setRequestError(error);
        } else {
          // TODO increase auto hide duration
          showSuccessMessage(`
            Takk for at du valgte Boligmappa!
            Hvis du vil bruke Boligmappas tjenester igjen, finner du oss på www.boligmappa.no
          `);

          setTimeout(() => {
            history.push('/logout');
          }, delayBeforeRedirect);
        }

        setRequestInProgress(false);
      },
    });
  };

  return (
    <PageLayout
      minFullHeight
      headerTitle="Slett kontoen din"
      lessMarginLeft={isDesktop}
      maxWidth="lg"
    >
      <Typography variant="body2" color="textSecondary">
        Du eier dataen tilknyttet din Boligmappe-bruker, selv om du velger å
        slette den.
      </Typography>
      <br />
      <Typography variant="body2" color="textPrimary">
        <strong>Hva skjer når du sletter Boligmappa - kontoen din?</strong>
      </Typography>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          Profilen slettes fra Boligmappa sine systemer.
        </li>
        <li className={classes.listItem}>
          E-postadresse og telefonnummer slettes umiddelbart.
        </li>
        <li className={classes.listItem}>
          Fødselsnummeret og personlige dokumenter som ligger på ditt
          “Personlige område” slettes for alltid hvis du ikke logger på igjen
          innen 3 måneder.
          <br />
          {/* TODO enable when necessary */}
          {/* <small> */}
          {/*  Du kan ta en back-up av dataene lagret på deg ved å gå til&nbsp; */}
          {/*  <Link to={`${dashboardLinkPrefix}/more/download-personal-data`}> */}
          {/*    last ned personlig innhold. */}
          {/*  </Link> */}
          {/* </small> */}
        </li>
        <li className={classes.listItem}>
          Sletting av kontoen vil ikke slette eiendommen fra de system
          håndverkerne benytter for å loggføre sitt arbeid mot eiendommer i
          Norge. Dette for å ta vare på håndverkernes krav til dokumentasjon.
          Eiendommen vil fortsatt være registrert i matrikkelen. Matrikkelen
          administreres av Statens Kartverk.
        </li>
        <li className={classes.listItem}>
          Du kan miste tilgangen til andre tjenester du har koblet Boligmappa
          til.
        </li>
        <li className={classes.listItem}>
          Personopplysningene er lagret i sikkerhetskopier og loggfiler hos oss
          slettes innen 1 år. Se retningslinjene for sikkerhetskopiering.
        </li>
      </ul>
      <hr />
      <Form
        error={requestError}
        onSubmit={onFormSubmit}
        submitButtonText="Slett"
        requestInProgress={requestInProgress}
        formClassName={classes.formSection}
      >
        <Checkbox
          required
          disabled={requestInProgress}
          className={classes.checkbox}
          label="Ja, jeg forstår at jeg er ansvarlig for eventuelle konsekvenser av at jeg sletter kontoen min."
        />
      </Form>
    </PageLayout>
  );
};

export default withStyles(styles)(ProfileDelete);
