import React, { useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import Box from '../../../../../../../components/atomic/Box';
import useDesktop from '../../../../../../../hooks/useDesktop';
import SearchField from '../../../../../../../components/SearchField';
import {
  getFilteredEvents,
  getUnattachedEvents,
} from '../../../../../../../utils/events';
import ProgressButton from '../../../../../../../components/ProgressButton';
import { updateEvent as apiUpdateEvent } from '../../../../../../../api/events';
import ChooseEventList from '../../../../../../../components/ChooseEventList';
import { acUpdateEvent } from '../../../../../../../store/actions/events';
import useSnackbar from '../../../../../../../hooks/useSnackbar';

const ChooseEvent = ({
  classes,
  events,
  activeCategory,
  onClose,
  updateEvent,
  onExistingEventChosen,
}) => {
  const isDesktop = useDesktop();

  const { showSuccessMessage } = useSnackbar();

  const [selectedEvents, setSelectedEvents] = useState([]);
  const [inProgress, setInProgress] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const filteredEvents = getFilteredEvents(
    getUnattachedEvents(events, activeCategory.question),
    searchQuery
  );

  const addSelectedEvent = (event) =>
    setSelectedEvents((prevState) => [...prevState, event]);

  const removeSelectedEvent = (event) =>
    setSelectedEvents((prevState) => {
      const newEvents = [...prevState];
      const index = newEvents.indexOf(event);

      newEvents.splice(index, 1);
      return newEvents;
    });

  const onChooseEvents = async () => {
    setInProgress(true);

    const promises = selectedEvents.map(async (selectedEvent) => {
      const existingAssociations = selectedEvent.associations || [];
      await apiUpdateEvent(selectedEvent.id, {
        associations: [...existingAssociations, activeCategory.question],
      });

      if (events) {
        updateEvent({
          ...selectedEvent,
          associations: [...existingAssociations, activeCategory.question],
        });
      }
    });

    Promise.all(promises).then(() => {
      setInProgress(false);
      setSelectedEvents([]);
      onClose();
      onExistingEventChosen();
      showSuccessMessage(`Hendelse(r) ble koblet til arbeid.`);
    });
  };

  return (
    <Box className={classes.container}>
      <Box
        className={clsx(
          isDesktop ? classes.paddingDesktop : classes.paddingMobile
        )}
      >
        <Box pt={2} width="100%" px="15px">
          <SearchField
            bordered
            value={searchQuery}
            placeholder="Søk etter hendelse"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Box>

        <Box width="100%" px="15px" pb="150px">
          <ChooseEventList
            searchQuery={searchQuery}
            events={filteredEvents}
            selectedEvents={selectedEvents}
            addSelectedEvent={addSelectedEvent}
            removeSelectedEvent={removeSelectedEvent}
          />
        </Box>
      </Box>

      <Box
        className={clsx(
          isDesktop ? classes.actionButtons : classes.actionButtonsMobile
        )}
      >
        <ProgressButton
          className={isDesktop ? classes.nextBtn : classes.nextBtnMobile}
          type="submit"
          color="primary"
          variant="contained"
          align="right"
          onClick={onChooseEvents}
          form="createEvent"
          disabled={!selectedEvents || selectedEvents?.length === 0}
          requestInProgress={inProgress}
        >
          Velg
        </ProgressButton>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateEvent: (event) => dispatch(acUpdateEvent(event)),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ChooseEvent));
