const styles = (theme) => ({
  listItem: {
    [theme.breakpoints.up('md')]: {
      width: '400px !important',
    },
  },
  listItemIcon: {
    minWidth: theme.spacing(5),
  },
  listItemText: {
    overflow: 'hidden',
  },
  checkbox: {
    marginRight: 0,
  },
  listItemSecondaryAction: {
    right: 0,
    transform: 'none',
    position: 'inherit',
    marginLeft: theme.spacing(1),
  },
});

export default styles;
