import moment from 'moment';
import React, { useState, useContext } from 'react';
import { Grid, withStyles } from '@material-ui/core';

import styles from '../style';
import ContextMenu from './ContextMenu';
import { openLink } from '../../../../../../utils/routes';
import Box from '../../../../../../components/atomic/Box';
import Copybox from '../../../../../../components/Copybox';
import List from '../../../../../../components/atomic/List';
import useDesktop from '../../../../../../hooks/useDesktop';
import EmailIcon from '../../../../../../assets/icons/Email';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import MenuIcon from '../../../../../../assets/icons/MenuHorizontal';
import IconButton from '../../../../../../components/atomic/IconButton';
import Typography from '../../../../../../components/atomic/Typography';
import ProgressButton from '../../../../../../components/ProgressButton';
import ListItem from '../../../../../../components/CardList/KeyValueListItem';
import PublicShareImage from '../../../../../../assets/images/PublicSharingImage.png';
import Image from '../../../../../../components/Image';
import { AddNewButton } from '../../../../../../components/Buttons';
import { FileUploadContext } from '../../../../../../contexts/fileUpload';
import StatusImage from '../../../../../../assets/images/statusImage.png';

const getFormattedDate = (dateStr) => moment(dateStr).format('DD.MMMM YYYY');

const Details = ({
  status,
  startDate,
  endDate,
  sharedBy,
  visitors,
  classes,
  publicSharingUrl,
  stopPublicSharing,
  emailPublicSharingUrl,
  toggleEditPublicSharing,
}) => {
  const [openContextMenu, setOpenContextMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { onFileUploadClick } = useContext(FileUploadContext);

  const { showSuccessMessage } = useSnackbar();
  const isDesktop = useDesktop();

  const onCopySuccess = () => {
    showSuccessMessage('Lenken er kopiert.');
  };

  const onMenuButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenContextMenu(true);
  };

  if (isDesktop) {
    return (
      <div>
        <ContextMenu
          open={openContextMenu}
          setOpen={setOpenContextMenu}
          onEditClick={toggleEditPublicSharing}
          onDeleteClick={stopPublicSharing}
          anchorEl={anchorEl}
        />
        <Box p={isDesktop ? 0 : 1}>
          <Grid container={isDesktop}>
            <Grid item md={isDesktop && 12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h2" className={classes.publicShareH2}>
                    {isDesktop ? 'Offentlig deling' : null}
                  </Typography>
                  <Typography variant="h2" className={classes.h2}>
                    Del lenken
                  </Typography>
                  <Box>
                    <p
                      className={
                        isDesktop
                          ? classes.publicShareContent
                          : classes.publicShareContentMobiletext
                      }
                    >
                      For at andre skal få tilgang til å se din offentlig delte
                      boligmappe må du sende dem den unike lenken til din delte
                      boligmappe. Du kan enten gjøre det via e-post eller ved å
                      kopiere lenken nedenfor og sende den via andre tjenester.
                      Lenken utløper når tiden du har satt er over, eller du
                      stopper delingen.
                    </p>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Image
                    alt="publicshare"
                    src={PublicShareImage}
                    className={classes.publicshareimage}
                    fallbackSrc={PublicShareImage}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div
            className={
              isDesktop ? classes.copyContainerDesktop : classes.copyContainer
            }
          >
            <div
              className={
                isDesktop
                  ? classes.copyboxContainerDesktop
                  : classes.copyboxContainer
              }
            >
              <Copybox
                str={publicSharingUrl}
                onCopySuccess={onCopySuccess}
                onClickUrl={() => openLink(publicSharingUrl, '_blank')}
              />
            </div>

            <div
              className={
                isDesktop
                  ? classes.emailButtonContainerDesktop
                  : classes.emailButtonContainer
              }
            >
              <ProgressButton
                onClick={emailPublicSharingUrl}
                requestInProgress={false}
                type="submit"
                color="primary"
                variant="contained"
                style={{ margin: '15px 0 0 0' }}
              >
                <EmailIcon className={classes.buttonIcon} /> Del lenken via
                e-post
                {emailPublicSharingUrl}
              </ProgressButton>
            </div>
          </div>
        </Box>

        <Box mt={2}>
          <div className={classes.headerContainer}>
            <Typography variant="h2" className={classes.detailsH2}>
              Detaljer om delingen
            </Typography>

            <div className={classes.infoIconContainer}>
              <IconButton onClick={onMenuButtonClick}>
                <MenuIcon className={classes.infoIconContainerpublicshare} />
              </IconButton>
            </div>
          </div>

          <List>
            <ListItem label="Status">
              <span className={classes.activeStatus}>{status}</span>
            </ListItem>
            <ListItem label="Delt fra">{getFormattedDate(startDate)}</ListItem>
            <ListItem label="Delt til">{getFormattedDate(endDate)}</ListItem>
            <ListItem label="Delt av">{sharedBy}</ListItem>
            <ListItem label="Antall visninger">{visitors}</ListItem>
          </List>
        </Box>

        <Grid container={!isDesktop}>
          <Grid item md={!isDesktop && 12}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Image
                  alt="publicsharestatus"
                  src={StatusImage}
                  className={classes.publicshareimagestatussmall}
                  fallbackSrc={StatusImage}
                />
              </Grid>
              <Grid item md={6} className={classes.statuscontents}>
                <Box>
                  <Typography className={classes.statuscontent}>
                    Hva slags dokumentasjon trenger takstmann tilgang til i
                    forbindelse med tilstandsrapporten?
                  </Typography>
                  <p className={classes.publicShareContent}>
                    Eksempler på dokumentasjon er informasjon om arbeid utført
                    av håndverkere på din bolig, informasjon om radonmåling- og
                    nivåer, om det er foretatt el-kontroll de siste 5 årene.
                  </p>
                  <Box>
                    <AddNewButton
                      text="Last opp dokument"
                      onClick={onFileUploadClick}
                      className={
                        isDesktop
                          ? classes.proceedButtonpublicshare
                          : classes.proceedButton
                      }
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <div>
      <ContextMenu
        open={openContextMenu}
        setOpen={setOpenContextMenu}
        onEditClick={toggleEditPublicSharing}
        onDeleteClick={stopPublicSharing}
        anchorEl={anchorEl}
      />

      <Grid container={!isDesktop}>
        <Grid item md={!isDesktop && 12}>
          <Grid container rowSpacing={1} direction="column">
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  alt="publicshare"
                  src={PublicShareImage}
                  className={classes.publicshareimageMobile}
                  fallbackSrc={PublicShareImage}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box>
                <p className={classes.publicShareContentMobile}>
                  For at andre skal få tilgang til å se din offentlig delte
                  boligmappe må du sende dem den unike lenken til din delte
                  boligmappe. Du kan enten gjøre det via e-post eller ved å
                  kopiere lenken nedenfor og sende den via andre tjenester.
                  Lenken utløper når tiden du har satt er over, eller du stopper
                  delingen.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div
        className={
          isDesktop ? classes.copyContainerDesktop : classes.copyContainer
        }
      >
        <div
          className={
            isDesktop
              ? classes.copyboxContainerDesktop
              : classes.copyboxContainer
          }
        >
          <Copybox
            str={publicSharingUrl}
            onCopySuccess={onCopySuccess}
            onClickUrl={() => openLink(publicSharingUrl, '_blank')}
          />
        </div>

        <div
          className={
            isDesktop
              ? classes.emailButtonContainerDesktop
              : classes.emailButtonContainer
          }
        >
          <ProgressButton
            onClick={emailPublicSharingUrl}
            requestInProgress={false}
            type="submit"
            color="primary"
            variant="contained"
            className={classes.EmailButtonMobile}
          >
            <EmailIcon className={classes.buttonIcon} /> Del lenken via e-post
            {emailPublicSharingUrl}
          </ProgressButton>
        </div>
      </div>

      <Box mt={2}>
        <div className={classes.headerContainer}>
          <Typography variant="h2" className={classes.detailsH2}>
            Detaljer om delingen
          </Typography>

          <div className={classes.infoIconContainerpublicshareMobile}>
            <IconButton onClick={onMenuButtonClick}>
              <MenuIcon
                className={classes.infoIconContainerpublicshareMobile}
              />
            </IconButton>
          </div>
        </div>

        <List>
          <ListItem label="Status">
            <span className={classes.activeStatus}>{status}</span>
          </ListItem>
          <ListItem label="Delt fra">{getFormattedDate(startDate)}</ListItem>
          <ListItem label="Delt til">{getFormattedDate(endDate)}</ListItem>
          <ListItem label="Delt av">{sharedBy}</ListItem>
          <ListItem label="Antall visninger">{visitors}</ListItem>
        </List>
      </Box>

      <Grid container={!isDesktop}>
        <Grid item md={!isDesktop && 12}>
          <Grid container rowSpacing={1} direction="column">
            <Grid item md={12}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  alt="publicsharestatus"
                  src={StatusImage}
                  className={classes.publicshareimagestatusMobile}
                  fallbackSrc={StatusImage}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.statuscontentsMobile}>
              <Typography className={classes.statuscontentMobile}>
                Hva slags dokumentasjon trenger takstmann tilgang til i
                forbindelse med tilstandsrapporten?
              </Typography>
              <p>
                Eksempler på dokumentasjon er informasjon om arbeid utført av
                håndverkere på din bolig, informasjon om radonmåling- og nivåer,
                om det er foretatt el-kontroll de siste 5 årene.
              </p>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <AddNewButton
                  text="Last opp dokument"
                  onClick={onFileUploadClick}
                  className={classes.proceedButtonpublicshareMobile}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Details);
