import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import SelectionListToggleButton, {
  SINGLE_IMAGE_MODE,
} from './SelectionListToggleButton';

import Slide from './Slide';
import styles from './styles';
import Box from '../atomic/Box';
import Carousel from '../Carousel';
import Bin from '../../assets/icons/Bin';
import Checkbox from '../atomic/Checkbox';
import SelectionList from './SelectionList';
import Close from '../../assets/icons/Close';
import IconButton from '../atomic/IconButton';
import useDesktop from '../../hooks/useDesktop';
import useSnackbar from '../../hooks/useSnackbar';
import Download from '../../assets/icons/Download';
import ContentLoader from '../loaders/ContentLoader';
import useActionDialog from '../../hooks/useActionDialog';
import PropertyImagePlaceholder from '../PropertyImagePlaceholder';
import { disableBodyScroll, enableBodyScroll } from '../../utils/dom';
import Link from '../atomic/Link';

const ImageGallery = ({
  images,
  classes,
  fetching,
  className,
  dots = true,
  arrows = true,
  editable = false,
  showIndex = true,
  activeIndex = null,
  showPlaceholder = false,
  getCurrentIndex = () => {},
  onImagesUpdated = () => {},
  isGalleryModeActive = true,
}) => {
  const isDesktop = useDesktop();
  const { showActionDialog } = useActionDialog();
  const { showSuccessMessage, showErrorMessage } = useSnackbar();

  const slider = useRef();
  const [viewMode, setViewMode] = useState(SINGLE_IMAGE_MODE);
  const [fullscreenMode, setFullscreenMode] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(activeIndex || 0);

  const isSingleImageMode = viewMode === SINGLE_IMAGE_MODE;

  const slideImages = Array.isArray(images)
    ? images.filter((image) => image && image.url)
    : [];

  const enableFullscreenMode = () => {
    disableBodyScroll();
    setFullscreenMode(true);
  };

  const disableFullscreenMode = () => {
    enableBodyScroll();
    setFullscreenMode(false);
    setViewMode(SINGLE_IMAGE_MODE);
  };

  const onKeyDown = useCallback(
    (event) => {
      if (!fullscreenMode) return;

      // Escape
      if (event.keyCode === 27) {
        disableFullscreenMode();
      }

      if (viewMode !== SINGLE_IMAGE_MODE) return;

      // Arrow left
      if (event.keyCode === 37) {
        const newIndex =
          currentIndex - 1 >= 0 ? currentIndex - 1 : images.length - 1;

        setCurrentIndex(newIndex);
      }

      // Arrow right
      if (event.keyCode === 39) {
        const newIndex =
          currentIndex + 1 === images.length ? 0 : currentIndex + 1;

        setCurrentIndex(newIndex);
      }
    },
    [fullscreenMode, currentIndex, viewMode]
  );

  const onImageClick = () =>
    isGalleryModeActive && !fullscreenMode && enableFullscreenMode();

  const afterSwipe = (index) => setCurrentIndex(index);

  const onDeleteImageClick = () =>
    showActionDialog({
      submitButtonText: 'Slett',
      title: 'Vil du slette dette bilde?',
      onSubmit: () =>
        onImagesUpdated(
          images.filter((image, index) => index !== currentIndex),
          () => showSuccessMessage('Bilde slettet'),
          showErrorMessage
        ),
    });

  const onMakeMainImageClick = () => {
    // it is already main image, so no need to change anything
    if (currentIndex === 0) return;

    const onError = (error) => {
      showErrorMessage(error);
      setCurrentIndex(currentIndex);
    };

    onImagesUpdated(
      [
        images[currentIndex],
        ...images.filter((image, index) => index !== currentIndex),
      ],
      showSuccessMessage,
      onError
    );

    setCurrentIndex(0);
  };

  const renderCurrentIndex = () => (
    <>
      {currentIndex + 1}
      &nbsp;AV&nbsp;
      {images.length}
    </>
  );

  const onImageSelected = (newIndex) => {
    setCurrentIndex(newIndex);
    setViewMode(SINGLE_IMAGE_MODE);
  };

  useEffect(() => {
    if (activeIndex !== null && activeIndex !== currentIndex) {
      setCurrentIndex(activeIndex);
    }
  }, [activeIndex]);

  useEffect(() => {
    getCurrentIndex(currentIndex);
    if (slider && slider.current) {
      slider.current.slickGoTo(currentIndex);
    }
  }, [currentIndex, slider]);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false);

    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [fullscreenMode, currentIndex, viewMode]);

  useEffect(() => enableBodyScroll, []);

  if (fetching) {
    return (
      <div className={clsx(classes.container, className)}>
        <Box position="absolute" width="100%" height="100%">
          <ContentLoader width="100%" height="100%" />
        </Box>
      </div>
    );
  }

  return (
    <Box
      height="100%"
      className={fullscreenMode ? classes.fullscreenMode : className}
    >
      <Box
        className={clsx(
          classes.container,
          fullscreenMode && classes.containerFullscreenMode
        )}
      >
        {fullscreenMode && (
          <Box className={classes.fullscreenHeader}>
            {showIndex && isSingleImageMode && (
              <Box
                width="60%"
                pl={isDesktop ? '40%' : 0}
                textAlign={isDesktop ? 'center' : 'left'}
              >
                {renderCurrentIndex()}
              </Box>
            )}
            <Box
              display="flex"
              justifyContent="flex-end"
              width={showIndex && isSingleImageMode ? '40%' : '100%'}
            >
              <Box mr={2}>
                <SelectionListToggleButton
                  mode={viewMode}
                  onModeChange={setViewMode}
                />
              </Box>

              <IconButton size="small" onClick={disableFullscreenMode}>
                <Close className={classes.closeFullscreenModeIcon} />
              </IconButton>
            </Box>
          </Box>
        )}

        {!fullscreenMode && showIndex && (
          <div
            className={clsx(
              classes.currentIndex,
              fullscreenMode && classes.currentIndexFullscreenMode
            )}
          >
            {renderCurrentIndex()}
          </div>
        )}

        {isSingleImageMode ? (
          <Carousel
            arrows={arrows}
            afterChange={afterSwipe}
            initialSlide={currentIndex}
            ref={slider}
            dots={isDesktop && !fullscreenMode && dots}
            className={clsx(
              classes.carousel,
              isDesktop && classes.desktop,
              fullscreenMode && classes.carouselFullscreenMode
            )}
          >
            {slideImages.map((image) => (
              <Slide
                editable={editable}
                imageUrl={image.url}
                onClick={onImageClick}
                fullscreenMode={fullscreenMode}
                key={`image-carousel-${image.url}`}
              />
            ))}

            {slideImages.length === 0 && showPlaceholder && (
              <PropertyImagePlaceholder height="100%" />
            )}
          </Carousel>
        ) : (
          <Box pt="20%" pb="5%">
            <SelectionList
              images={slideImages}
              currentIndex={currentIndex}
              onImageSelected={onImageSelected}
            />
          </Box>
        )}

        {editable &&
          isSingleImageMode &&
          fullscreenMode &&
          images[currentIndex] && (
            <div className={classes.actionContainer}>
              {!images[currentIndex].isGoogleImage && (
                <Checkbox
                  label="Hovedbilde"
                  checked={currentIndex === 0}
                  onChange={onMakeMainImageClick}
                  className={classes.mainPictureLabel}
                />
              )}

              {!images[currentIndex].isGoogleImage && (
                <Link
                  to={images[currentIndex].url}
                  isExternal
                  openInNewTab
                  preventEncode
                >
                  <IconButton>
                    <Download className={classes.actionIcon} />
                  </IconButton>
                </Link>
              )}

              {!images[currentIndex].isGoogleImage && (
                <IconButton onClick={onDeleteImageClick}>
                  <Bin className={classes.actionIcon} />
                </IconButton>
              )}
            </div>
          )}
      </Box>
    </Box>
  );
};

export default withStyles(styles)(ImageGallery);
