import { useCssVar } from '@style/utils';

// recursively maps an object with css variable names to valid css variables
export const mapVariables = <Type>(variables: Type): Type => {
  const varsMapped = {} as Type;
  
  Object.keys(variables).forEach((prop) => {
    if (typeof variables[prop] === 'string') {
      varsMapped[prop] = useCssVar(variables[prop]);
    } else if (typeof variables[prop] === 'object') {
      varsMapped[prop] = mapVariables(variables[prop]);
    }
  });
  return varsMapped;
};
