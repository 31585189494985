const styles = (theme) => ({
  line: {
    display: 'flex',
    maxWidth: '100%',
    height: '1px',
    margin: '3px 13.74px 4px',
    backgroundColor: theme.palette.primary.greyLightBlack,
  },
});

export default styles;
