import React from 'react';
import ListHeader from './ListHeader';
import ListBody from './ListBody';

const MultiSelectComboBox = ({
  description,
  menuOpen,
  setMenuOpen,
  onMenuClose,
  onMenuSave,
  filterOptions,
  onFiltersReset,
  selectedDocuments,
  selectedOptions,
  setSelectedOptions,
  anchorEl,
  isDelete,
  isFiltering,
  isBulk,
  type,
  ...props
}) => {
  return (
    <>
      <ListHeader
        open={menuOpen}
        isFiltering={isFiltering}
        title={description}
        setOpen={setMenuOpen}
        onMenuClose={onMenuClose}
        anchorEl={anchorEl}
        isBulk={isBulk}
        selectedDocuments={selectedDocuments}
        onFiltersReset={onFiltersReset}
        {...props}
      >
        <ListBody
          onReset={onFiltersReset}
          filters={filterOptions}
          onMenuClose={onMenuClose}
          onMenuSave={onMenuSave}
          isDelete={isDelete}
          isFiltering={isFiltering}
          type={type}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          selectedDocuments={selectedDocuments}
        />
      </ListHeader>
    </>
  );
};

export default MultiSelectComboBox;
