import React from 'react';
import { Link } from 'react-router-dom';
import { StyledPersonalAreaCard } from './styles';
import useIsDesktop from '../../../hooks/v2/useDesktop';

const PersonalAreaCard = ({ title, description, image, linkText, link }) => {
  // eslint-disable-next-line no-console
  console.log(title, description, image, linkText, link);
  const isDesktop = useIsDesktop();
  return (
    <StyledPersonalAreaCard>
      <div className="card_text_section">
        <h3>{title}</h3>
        <p>{description}</p>
        {isDesktop && (
          <>
            {link ? (
              <Link to={link} className="learn_more">
                <p>{linkText}</p>
              </Link>
            ) : (
              <p>
                <b>{linkText}</b>
              </p>
            )}
          </>
        )}
      </div>
      <div className="card_img_section">
        <img alt="Boligmappa+Logo" src={image} />
      </div>
      {!isDesktop && (
        <>
          {link ? (
            <Link to={link} className="learn_more">
              <p>{linkText}</p>
            </Link>
          ) : (
            <p>
              <b>{linkText}</b>
            </p>
          )}
        </>
      )}
    </StyledPersonalAreaCard>
  );
};

export default PersonalAreaCard;
