import React from 'react';

const VideoCam = ({ width = '18', height = '12', fill = 'none', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 18 12"
    {...props}
  >
    <path
      d="M10.5 0C10.9062 0 11.25 0.15625 11.5625 0.4375C11.8438 0.75 12 1.09375 12 1.5V10.5C12 10.9375 11.8438 11.2812 11.5625 11.5625C11.25 11.875 10.9062 12 10.5 12H1.5C1.0625 12 0.71875 11.875 0.4375 11.5625C0.125 11.2812 0 10.9375 0 10.5V1.5C0 1.09375 0.125 0.75 0.4375 0.4375C0.71875 0.15625 1.0625 0 1.5 0H10.5ZM16.4375 1.1875C16.625 1.0625 16.875 1 17.125 1.03125C17.375 1.0625 17.5625 1.15625 17.75 1.34375C17.9062 1.53125 18 1.71875 18 1.96875V10C18 10.2812 17.9062 10.5 17.75 10.6875C17.5625 10.875 17.375 10.9688 17.125 11C16.875 11.0312 16.625 10.9688 16.4375 10.8125L13 8.46875V3.53125L16.4375 1.1875Z"
      fill="#111111"
    />
  </svg>
);

export default VideoCam;
