import React from 'react';
import { useHistory } from 'react-router';
import { withStyles } from '@material-ui/core';
import style from './style';
import useDesktop from '../../hooks/useDesktop';
import Typography from '../atomic/Typography';
import Box from '../atomic/Box';
import Grid from '../atomic/Grid';
import useDashboardLinkPrefix from '../../hooks/useDashboardLinkPrefix';
import {
  nativeShare,
  referralLink,
  referralTitle,
  isNativeSharingSupported,
} from '../../utils/referral';
import ProgressButton from '../ProgressButton';

const PropertyReferralCardInfo = ({ classes }) => {
  const isDesktop = useDesktop();
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const history = useHistory();
  const buttonLabel = 'Anbefal til en venn';
  const cardTitle = 'Anbefal boligmappa til en venn';
  const stepTitle = ' Spre ordet om Boligmappa ';

  const referralRedirect = () => {
    if (isNativeSharingSupported()) {
      nativeShare(referralLink, referralTitle);
    } else {
      history.push(`${dashboardLinkPrefix}/referral`);
    }
  };

  const button = (
    <ProgressButton
      color="primary"
      variant="contained"
      onClick={referralRedirect}
      className={classes.actionBtn}
    >
      {buttonLabel}
    </ProgressButton>
  );

  const information = (
    <Typography className={isDesktop ? classes.stepDesc : classes.stepDesc}>
      Kjenner du for eksempel noen som er i ferd med å pusse opp boligen sin? Da
      kan det være smart å samle all dokumentasjonen på ett sted.
    </Typography>
  );

  return (
    <Box height="100%">
      <Box className={classes.container}>
        <Box>
          <Typography
            paragraph
            variant="h2"
            fontWeight={800}
            marginTop={false}
            align="left"
            fontSize={!isDesktop && 18}
          >
            {cardTitle}
          </Typography>
          <Box
            mt={isDesktop ? 3 : 1}
            mb={1}
            display="flex"
            alignItems="center"
            height="20px"
          >
            <Typography className={classes.stepTitle}>{stepTitle}</Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.stepDesc}>{information}</div>
            </Grid>
          </Grid>
        </Box>

        <Box
          className={
            isDesktop ? classes.containerclass : classes.containerclassMobile
          }
        >
          <Grid item md={6}>
            {button}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default withStyles(style)(PropertyReferralCardInfo);
