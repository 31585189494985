import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { createRoom, uploadImage } from '../../../../../../api/rooms';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import {
  acAddRoom,
  acSetShowAddRoom,
  acSetRoomReturnOnSuccess,
} from '../../../../../../store/actions/rooms';
import {
  sGetRooms,
  sGetShowAddRoom,
  sGetReturnOnSuccess,
} from '../../../../../../store/reducers/rooms';
import {
  acSyncDocumentsRooms,
  acSetMultipleDocumentsSelected,
} from '../../../../../../store/actions/documents';
import {
  sGetDocuments,
  sGetMultipeDocumentsSelected,
} from '../../../../../../store/reducers/documents';
import RoomForm from '../../../../../../components/forms/RoomForm';
import { sGetEvents } from '../../../../../../store/reducers/events';
import useSearchQuery from '../../../../../../hooks/useSearchQuery';
import { acSyncEventsRooms } from '../../../../../../store/actions/events';
import { sGetActiveProperty } from '../../../../../../store/reducers/properties';
import {
  getPropertyBoligmappaNumber,
  getPropertyType,
} from '../../../../../../utils/properties';
import useDashboardLinkPrefix from '../../../../../../hooks/useDashboardLinkPrefix';
import useActionDialog from '../../../../../../hooks/useActionDialog';
import useDesktop from '../../../../../../hooks/useDesktop';
import { uploadImages } from '../../../../../../utils/images';
import { getRoomImages } from '../../../../../../utils/rooms';

export const AddRoom = ({
  rooms,
  events,
  documents,
  activeProperty,
  showAddRoom,
  addRoom,
  syncEventsRooms,
  setShowAddRoom,
  returnOnSuccess,
  syncDocumentRooms,
  setReturnOnSuccess,
  mulitipleDocumentsSelected,
  setMulitipleDocumentsSelected,
}) => {
  const history = useHistory();
  const propertyType = getPropertyType(activeProperty);
  const isDesktop = useDesktop();
  const { boligmappaNumber } = useParams();
  const { showSuccessMessage } = useSnackbar();
  const { focusOnRoom } = useSearchQuery();

  const [error, setError] = useState(null);
  const dashboardLinkPrefix = useDashboardLinkPrefix();
  const [requestInProgress, setRequestInProgress] = useState(false);

  const { showActionDialog } = useActionDialog();

  const [imageFiles, setImageFiles] = useState([]);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [isImageUpdate, setIsImageUpdate] = useState(false);
  const [disableDocumentControl, setDisableDocumentControl] = useState(false);

  const onClose = (closeCallback, showActionStatus = false) => {
    if (showActionStatus) {
      setError(null);
      setShowAddRoom(false);
      setCurrentRoom(null);
      setImageFiles([]);
      if (closeCallback) closeCallback();
      setReturnOnSuccess(false);
      setMulitipleDocumentsSelected(false);
      return;
    }
    showActionDialog({
      title: 'Rommet er ikke lagret',
      showCancelButton: false,
      submitButtonText: 'Avbryt',
      submitButtonVariant: 'contained',
      message: 'Er du sikker på at du vil avbryte?',
      onSubmit: () => {
        setError(null);
        setShowAddRoom(false);
        setCurrentRoom(null);
        setImageFiles([]);
        setReturnOnSuccess(false);
        setMulitipleDocumentsSelected(false);
        if (closeCallback) closeCallback();
      },
    });
  };

  const onSubmitRoomComplete = (roomId, submitCallback, roomObj) => {
    // Only add room to store if rooms have already been loaded
    // otherwise new room will be fetched on rooms page
    if (rooms) {
      addRoom(roomObj);
    }
    setRequestInProgress(false);
    onClose(submitCallback, true);
    showSuccessMessage('Rom opprettet.');
    setCurrentRoom(null);
    setImageFiles([]);
    if (!focusOnRoom && !returnOnSuccess) {
      history.push(`${dashboardLinkPrefix}/rooms/${roomId}`);
    }
    setReturnOnSuccess(false);
    setMulitipleDocumentsSelected(false);
  };

  const uploadRoomImages = (images, roomId, submitCalllback, roomObj) => {
    const uploadImageRequest = (image, position) =>
      uploadImage(roomId, image, position);
    uploadImages({
      imageFiles: images,
      positionStart: 0,
      uploadRequest: uploadImageRequest,
      onComplete: (uploadedImages) => {
        onSubmitRoomComplete(roomId, submitCalllback, {
          ...roomObj,
          gallery: uploadedImages || [],
        });
      },
      onError: () => {},
      onImageUploaded: () => {},
    });
  };

  const onFormSubmit = async (room, submitCallback) => {
    setError(null);
    setRequestInProgress(true);

    const [errorResponse, data] = await createRoom({
      ...room,
      boligmappaNumber: getPropertyBoligmappaNumber(activeProperty),
      propertyType: getPropertyType(activeProperty),
      applicationName: 'BOLIGEIERPWA',
    });

    if (errorResponse) {
      setError(errorResponse);
      setRequestInProgress(false);
    } else {
      const { eventIds, documentIds } = room;
      const { id: roomId } = data;
      const roomObj = {
        ...room,
        ...data,
      };

      if (events) {
        syncEventsRooms({
          roomId,
          eventIds,
          room: {
            ...room,
            ...data,
          },
        });
      }

      if (documents) {
        syncDocumentRooms({
          roomId,
          documentIds,
          room: {
            ...room,
            ...data,
          },
        });
      }

      if (imageFiles?.length > 0) {
        uploadRoomImages(imageFiles, roomId, submitCallback, roomObj);
      } else {
        onSubmitRoomComplete(roomId, submitCallback, roomObj);
      }
    }
  };

  const onRoomFormOpen = (room) => {
    const roomData = {
      ...room,
      id: -9999,
    };
    setCurrentRoom(roomData);
  };

  const onRoomImageUpload = (uploadedImages, recentFiles) => {
    setImageFiles([...imageFiles, ...recentFiles]);
    setIsImageUpdate(true);
    setCurrentRoom((prevState) => ({
      ...prevState,
      gallery: [...(getRoomImages(prevState) || []), ...uploadedImages],
    }));
  };

  const onRoomImageDelete = (imagesToBeUpload, removedIndex) => {
    setImageFiles((preval) => {
      const files = preval;
      files.splice(removedIndex, 1);
      return files;
    });
    setIsImageUpdate(true);
    setCurrentRoom((prevState) => ({
      ...prevState,
      gallery: [...imagesToBeUpload],
    }));
  };

  useEffect(() => {
    if (mulitipleDocumentsSelected) {
      setDisableDocumentControl(true);
    } else setDisableDocumentControl(false);
  }, [mulitipleDocumentsSelected]);

  return (
    <RoomForm
      open={showAddRoom}
      onClose={onClose}
      onSubmit={onFormSubmit}
      requestInProgress={requestInProgress}
      error={error}
      header="Legg til rom"
      boligmappaNumber={boligmappaNumber}
      propertyType={propertyType}
      isDesktop={isDesktop}
      onRoomFormOpen={onRoomFormOpen}
      onRoomImageUpload={onRoomImageUpload}
      onRoomImageDelete={onRoomImageDelete}
      room={currentRoom}
      isImageUpdate={isImageUpdate}
      disableDocumentSelection={disableDocumentControl}
    />
  );
};

const mapStateToProps = (state) => ({
  rooms: sGetRooms(state),
  events: sGetEvents(state),
  documents: sGetDocuments(state),
  activeProperty: sGetActiveProperty(state),
  showAddRoom: sGetShowAddRoom(state),
  returnOnSuccess: sGetReturnOnSuccess(state),
  mulitipleDocumentsSelected: sGetMultipeDocumentsSelected(state),
});

const mapDispatchToProps = (dispatch) => ({
  addRoom: (room) => dispatch(acAddRoom(room)),
  syncEventsRooms: (payload) => dispatch(acSyncEventsRooms(payload)),
  setShowAddRoom: (payload) => dispatch(acSetShowAddRoom(payload)),
  syncDocumentRooms: (payload) => dispatch(acSyncDocumentsRooms(payload)),
  setReturnOnSuccess: (payload) => dispatch(acSetRoomReturnOnSuccess(payload)),
  setMulitipleDocumentsSelected: (payload) =>
    dispatch(acSetMultipleDocumentsSelected(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddRoom);
