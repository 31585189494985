import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createFolder } from '../api/documents';
import { createFolderErrorCodes } from '../utils/errorCodes';
import useSnackbar from './useSnackbar';
import { acAddPersonalFolder } from '../store/actions/personalDocuments';

const useAddNewDocumentMenu = ({ currentDocFolderId, breadCrumbs }) => {
  const dispatch = useDispatch();
  const [isAddingNewDoc, setIsAddingNewDoc] = useState(false);
  const [isCreatingName, setIsCreatingName] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [folderNameErrorMessage, setFolderNameErrorMessage] = useState(
    'Skriv inn mappenavn'
  );
  const [isFolderNameError, setIsFolderNameError] = useState(false);

  const { showSuccessMessage } = useSnackbar();

  const onAddNewDoc = () => {
    setIsAddingNewDoc(true);
  };

  const onCancelCreatingFolder = () => {
    setIsCreatingName(false);
    setNewFolderName('');
  };

  const onFolderCreate = async () => {
    if (newFolderName.length > 0) {
      const [error, response] = await createFolder({
        name: newFolderName,
        parentId: currentDocFolderId && currentDocFolderId,
      });
      if (!error && response) {
        dispatch(acAddPersonalFolder(breadCrumbs, response));
        setNewFolderName('');
        setIsCreatingName(false);
        setIsAddingNewDoc(false);
        showSuccessMessage('Mappe opprettet');
      }
      if (error) {
        setFolderNameErrorMessage(
          createFolderErrorCodes[error.code] || error.code
        );
        setIsFolderNameError(true);
      }
    } else {
      setFolderNameErrorMessage('Skriv inn mappenavn');
      setIsFolderNameError(true);
    }
  };

  useEffect(() => setIsFolderNameError(false), [newFolderName, isCreatingName]);

  return {
    isAddingNewDoc,
    setIsAddingNewDoc,
    isCreatingName,
    setIsCreatingName,
    newFolderName,
    setNewFolderName,
    folderNameErrorMessage,
    setFolderNameErrorMessage,
    isFolderNameError,
    setIsFolderNameError,
    onAddNewDoc,
    onFolderCreate,
    onCancelCreatingFolder,
  };
};

export default useAddNewDocumentMenu;
