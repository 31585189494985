import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import styles from './styles';
import Box from '../../../../../../components/atomic/Box';
import useDesktop from '../../../../../../hooks/useDesktop';
import Typography from '../../../../../../components/atomic/Typography';
import { sGetRooms } from '../../../../../../store/reducers/rooms';
import RoomProgress from './RoomProgress';

const ExistingRooms = ({ classes, rooms }) => {
  const isDesktop = useDesktop();
  const [isRoomsComplete, setIsRoomsComplete] = useState();

  useEffect(() => {
    if (rooms) {
      let completed = true;

      rooms.forEach((room) => {
        const { title, description, gallery } = room;
        if (
          (title || description || gallery) === (undefined || null || '') ||
          gallery?.length === 0
        ) {
          completed = false;
        }
      });

      setIsRoomsComplete(completed);
    }
  }, [rooms]);

  return (
    rooms?.length > 0 && (
      <>
        <Box className={isDesktop ? classes.header : classes.headerMobile}>
          <Typography className={classes.title}>
            Allerede opprettede rom
          </Typography>
          <Typography className={classes.progressSummary}>
            {isRoomsComplete
              ? 'Du har allerede opprettet disse rommene og oppgitt alle detaljer.'
              : 'Du har allerede opprettet disse rommene, men ikke oppgitt alle relevante detaljer.'}
          </Typography>
        </Box>
        <Box className={isDesktop ? classes.list : classes.listMobile}>
          {rooms.map((room) => (
            <RoomProgress key={room.id} room={room} />
          ))}
          {isDesktop ? <Divider className={classes.listDivider} /> : null}
        </Box>
      </>
    )
  );
};

const mapStateToProps = (state) => ({
  rooms: sGetRooms(state),
});

export default connect(mapStateToProps)(withStyles(styles)(ExistingRooms));
