import React from 'react';

const ImgIcon = ({ width = '24', height = '26' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 24"
  >
    <g
      id="Group_2778"
      data-name="Group 2778"
      transform="translate(-131.109 -3122.902)"
    >
      <g
        id="Group_2777"
        data-name="Group 2777"
        transform="translate(-188 1862)"
      >
        <g id="Group_2771" data-name="Group 2771">
          <path
            id="Shape_42"
            d="M406,54.328V70a2,2,0,0,1-2,2H388a2,2,0,0,1-2-2V50a2,2,0,0,1,2-2h11.672a2,2,0,0,1,1.414.586l4.328,4.328A2,2,0,0,1,406,54.328Z"
            transform="translate(-66.891 1212.902)"
            fill="#9a54a4"
          />
        </g>
      </g>
      <g
        id="atom_icon_picture-frame_red_sm"
        data-name="atom / icon / picture-frame / red / sm"
        transform="translate(134 3130.379)"
      >
        <g
          id="Group_1660"
          data-name="Group 1660"
          transform="translate(-3.634 -5.508)"
        >
          <path
            id="Oval_155"
            d="M283.995,8099.992a1.5,1.5,0,1,0-1.495-1.5A1.5,1.5,0,0,0,283.995,8099.992Z"
            transform="translate(-275.861 -8088.454)"
            fill="#fff"
          />
          <path
            id="Shape_2434"
            d="M289.445,8101.477a.809.809,0,0,0-.676-.317.772.772,0,0,0-.658.335l-1.149,1.988a.181.181,0,0,1-.145.081.207.207,0,0,1-.167-.052l-.6-.59a.823.823,0,0,0-.6-.219.807.807,0,0,0-.581.248l-2.3,2.573a.237.237,0,0,0-.027.3.388.388,0,0,0,.329.156h8.851a.4.4,0,0,0,.313-.139.231.231,0,0,0,.016-.277Z"
            transform="translate(-276.863 -8090.617)"
            fill="#fff"
          />
        </g>
        <path
          id="Shape_2435"
          d="M279.829,8093h10.964a1.33,1.33,0,0,1,1.329,1.329v10.965a1.33,1.33,0,0,1-1.329,1.329H279.829a1.33,1.33,0,0,1-1.329-1.329v-10.965A1.33,1.33,0,0,1,279.829,8093Zm10.633,12.293a.316.316,0,0,0,.235-.1.326.326,0,0,0,.1-.232v-10.3a.331.331,0,0,0-.332-.333h-10.3a.331.331,0,0,0-.332.333v10.3a.331.331,0,0,0,.332.333Z"
          transform="translate(-278.5 -8093)"
          fill="#fff"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

export default ImgIcon;
