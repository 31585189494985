const styles = (theme) => ({
  filters: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    background: theme.palette.primary.white,
  },
  title: {
    fontWeight: '400',
    fontSize: '14px',
    padding: '0 16px',
    color: theme.palette.primary.black,
  },
});

export default styles;
