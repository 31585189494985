import React, { createRef, useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';

import './style.sass';
import themes from '@vb/components/build/style/themes/boligmappa/boligmappa.linaria';
import { FlagProvider } from '@unleash/proxy-client-react';
import jssStyles from './style';
import Route from '../atomic/Route';
import Home from '../../views/Home';
import theme from '../../assets/theme';
import ScrollToTop from '../ScrollToTop';
import Authcode from '../../views/Authcode';
import ErrorBoundary from '../ErrorBoundary';
import LoginPage from '../../views/LoginPage';
import CssBaseline from '../atomic/CssBaseline';
import SnackbarProvider from '../SnackbarProvider';
import PublicRoutes from '../../views/PublicRoutes';
import PrivateRoutes from '../../views/PrivateRoutes';
import SessionExpiredPrompt from '../SessionExpiredPrompt';
import ActionDialogProvider from '../ActionDialogProvider';
import { FileUploadProvider } from '../../contexts/fileUpload';
import HeaderSnackbarProvider from '../HeaderSnackbarProvider';
import HealthCheck from '../../views/HealthCheck';
import sessionStorage from '../../utils/sessionStorage';
import { redirectPath } from '../../utils/routes';
import { MatomoTag } from '../../utils/matomo';
import Matomo from '../Util/Matomo';
import { unleashConfig } from '../../utils/unleash';
import PersonalAreaRoutes from '../../views/PersonalAreaRoutes';

export const App = () => {
  const globalFileInputContainerRef = createRef();
  const query = new URLSearchParams(window.location.search);
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'matomo-analytics';
    script.innerHTML = `${MatomoTag()}`;

    document.head.appendChild(script);

    const redirectRoute = query.get('redirect');
    if (redirectRoute) sessionStorage.set(redirectPath, redirectRoute);

    const loginType = query.get('loginType');
    if (redirectRoute) sessionStorage.set('loginType', loginType);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  /**
   *  Adds theme className and lets Linaria extract CSS for theme
   *  to body tag, there might be better ways of doing this
   */
  const styleClassName = themes.mainTheme;
  useEffect(() => {
    document.body.classList.add(styleClassName);
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline>
        <FlagProvider config={unleashConfig}>
          <Router>
            <ErrorBoundary>
              <SnackbarProvider>
                <HeaderSnackbarProvider>
                  <ActionDialogProvider>
                    <FileUploadProvider
                      inputContainerRef={globalFileInputContainerRef}
                    >
                      <ScrollToTop>
                        <Switch>
                          <Route exact path="/" component={Home} />
                          <Route exact path="/login" component={LoginPage} />
                          <Route exact path="/authcode" component={Authcode} />
                          <Route path="/public" component={PublicRoutes} />
                          <Route path="/status" component={HealthCheck} />
                          <Route
                            path="/personal-area"
                            component={PersonalAreaRoutes}
                          />
                          <Route path="*" component={PrivateRoutes} />
                        </Switch>
                        <SessionExpiredPrompt />
                      </ScrollToTop>
                    </FileUploadProvider>
                  </ActionDialogProvider>
                </HeaderSnackbarProvider>
              </SnackbarProvider>
              <Matomo />
            </ErrorBoundary>
          </Router>

          {/*
           * Render input here, so that it persists
           * file selection across route changes/re-renders
           */}
          <div ref={globalFileInputContainerRef} />
        </FlagProvider>
      </CssBaseline>
    </MuiThemeProvider>
  );
};

export default withStyles(jssStyles)(App);
