import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import clsx from 'clsx';

import {
  createEvent as apiCreateEvent,
  uploadImage as apiUploadImage,
} from '../../../../../api/events';

import styles from './styles';
import useDesktop from '../../../../../hooks/useDesktop';
import useActionDialog from '../../../../../hooks/useActionDialog';
import Dialog from '../../../../../components/atomic/Dialog';
import DialogTitle from '../../../../../components/DialogTitle';
import DialogContent from '../../../../../components/atomic/DialogContent';
import DialogCloseConfirm from '../../../../../components/DialogCloseConfirm';
import RegisterWork from './RegisterWork';
import Templates from './Templates';
import CreateEvent from './CreateEvent';
import BYBFEventSuccess from './BYBFEventSuccess';
import BYBFEventCategorySuccess from './BYBFEventCategorySuccess';
import { sGetRooms } from '../../../../../store/reducers/rooms';
import {
  sGetEvents,
  sGetFetchingEvents,
} from '../../../../../store/reducers/events';
import {
  acAddEvent,
  acSetEvents,
  acSetFetchingEvents,
} from '../../../../../store/actions/events';
import { acSyncRoomsEvents } from '../../../../../store/actions/rooms';
import { sGetActiveProperty } from '../../../../../store/reducers/properties';
import { getPropertyBoligmappaNumber } from '../../../../../utils/properties';
import {
  sGetEventsActiveCategory,
  sGetEventsCategories,
} from '../../../../../store/reducers/bybfEvents';
import {
  acBYBFEventsSetActiveCategory,
  acBYBFEventsSetCategoryActiveStep,
  acBYBFEventsSetCategoryCompleted,
  acBYBFEventsSetCategoryEvent,
} from '../../../../../store/actions/bybfEvents';
import { acSyncDocumentsEvents } from '../../../../../store/actions/documents';
import { sGetDocuments } from '../../../../../store/reducers/documents';
import {
  buildBoligmappa,
  fetchEvents as apiFetchEvents,
} from '../../../../../api/properties';
import { uploadImages } from '../../../../../utils/images';
import { sGetBYBFSteps } from '../../../../../store/reducers/bybf';
import { getEventCategories } from '../../../../../modules/BYBFSummary/utils';
import useFetchEffect from '../../../../../hooks/useFetchEffect';

const BYBFEvents = ({
  open,
  onClose,
  classes,
  rooms,
  events,
  fetching,
  setFetching,
  setEvents,
  documents,
  addEvent,
  activeProperty,
  syncRoomsEvents,
  syncDocumentEvents,
  steps,
  activeBYBFCategory,
  eventCategories,
  setCategoryActiveStep,
  setCategoryEvent,
  setCategoryCompleted,
  setEventsActiveCategory,
}) => {
  const isDesktop = useDesktop();
  const { showActionDialog } = useActionDialog();
  const boligmappaNumber = getPropertyBoligmappaNumber(activeProperty);

  const [categories, setCategories] = useState(null);

  const [title, setTitle] = useState('');
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeStep, setActiveStep] = useState(null);
  const [event, setEvent] = useState(null);
  const [evetTemplate, setEventTemplate] = useState(null);

  const [error, setError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [isCategorySuccessful, setCategorySuccessful] = useState(false);

  const getCategoryState = () =>
    eventCategories.find(({ id }) => id === activeBYBFCategory);

  useFetchEffect({
    dependencies: [boligmappaNumber],
    setFetchingFunction: setFetching,
    onSuccess: (data) => setEvents(data),
    conditionFunction: () => boligmappaNumber && !events && !fetching,
    apiFetchFunction: () => apiFetchEvents(boligmappaNumber),
  });

  useEffect(() => {
    if (steps) {
      setCategories(getEventCategories(steps));
    }
  }, []);

  useEffect(() => {
    if (categories) {
      setActiveCategory(categories.find(({ id }) => id === activeBYBFCategory));
    }
  }, [categories, activeBYBFCategory]);

  useEffect(() => {
    if (activeCategory) {
      setTitle(activeCategory.title);
      setActiveStep(getCategoryState().activeStep);
      setEvent(null);
      setTimeout(() => {
        setEvent(getCategoryState().event);
      }, 100);
    }
  }, [activeCategory]);

  useEffect(() => {
    if (activeStep) {
      setCategoryActiveStep(activeCategory.id, activeStep);
    }
  }, [activeStep]);

  useEffect(() => {
    if (event) {
      setCategoryEvent(activeCategory.id, event);
    }
  }, [event]);

  const saveBYBFEventsProgress = async (flowCompleted, state) => {
    setRequestInProgress(true);
    setCategorySuccessful(false);
    const [errorResponse, data] = await buildBoligmappa(
      getPropertyBoligmappaNumber(activeProperty),
      { flowCompleted, state },
      'events'
    );

    if (errorResponse) {
      setError(errorResponse);
    }

    setRequestInProgress(false);
    return data;
  };

  const onDialogClose = async (dialogEvent, reason, isLastCategory = false) => {
    if (dialogEvent && reason === 'backdropClick') return;
    showActionDialog({
      title: ' ',
      showCancelButton: false,
      submitButtonText: 'Avslutt flyten',
      submitButtonVariant: 'contained',
      buttonType: 'Customized',
      actionClassName: classes.confirmCloseActions,
      buttonClassName: classes.confirmCloseButton,
      message: <DialogCloseConfirm />,
      onSubmit: async () => {
        const state = JSON.stringify({
          activeCategory: activeCategory.id,
          categories: eventCategories.map((_category) => {
            const { category, completed } = _category;

            if (category === 'OTHER') {
              return {
                ..._category,
                completed: completed || isLastCategory,
              };
            }
            if (_category.event !== null) {
              const tempCategory = { ..._category };
              tempCategory.event.rooms = null;
              tempCategory.event.documents = null;
              tempCategory.event.gallery = [];
              return tempCategory;
            }
            return {
              ..._category,
            };
          }),
        });
        await saveBYBFEventsProgress(false, state);
        onClose();
      },
    });
  };

  const onExistingEventChosen = () => setActiveStep(4);

  const onAnswerNo = async () => {
    setCategoryCompleted(activeCategory.id, true);

    const nextCategory = categories.find(
      ({ id }) => id === activeCategory.id + 1
    );

    if (!nextCategory) {
      await onDialogClose(null, null, true);
    } else {
      setEventsActiveCategory(nextCategory.id);
    }
  };

  const onAddMore = () => {
    setActiveStep(2);
  };

  const onAddMoreAnswerNo = async () => {
    setCategoryCompleted(activeCategory.id, true);
    setCategorySuccessful(true);
    setActiveStep(1);
  };

  const setNextEventsActiveCategory = () => {
    setCategorySuccessful(false);
    const nextCategory = categories.find(
      ({ id }) => id === activeCategory.id + 1
    );

    if (nextCategory) {
      setEventsActiveCategory(nextCategory.id);
    } else {
      onClose();
    }
  };

  const previousStep = () => setActiveStep(activeStep - 1);

  const nextStep = () => setActiveStep(activeStep + 1);

  const onBYBFEventSuccess = () => setActiveStep(5);

  const generateEventObject = (template) => {
    setEventTemplate(template);
    return {
      title: template?.title || '',
      description: template?.description || '',
      category: template?.subType || '',
      roomIds: template?.suggestedRooms || [],
      documentIds: template?.suggestedDocuments || [],
      dateStart: '',
      dateEnd: '',
    };
  };

  const setEmptyEvent = () => setEvent(generateEventObject());

  const setEventFromTemplate = (template) =>
    setEvent(generateEventObject(template));

  const uploadEventImages = (imageFiles, eventId) => {
    const uploadImageRequest = (image, position) =>
      apiUploadImage(eventId, image, position);
    uploadImages({
      imageFiles,
      positionStart: Array.isArray(imageFiles) ? imageFiles.length : 0,
      uploadRequest: uploadImageRequest,
      onComplete: () => {
        setRequestInProgress(false);
        // nextStep();
        onBYBFEventSuccess();
      },
      onError: () => {},
      onImageUploaded: () => {},
    });
  };

  const onSumbitEvent = async (eventFormData, imageFiles) => {
    setError(null);
    setRequestInProgress(true);

    const [errorResponse, data] = await apiCreateEvent({
      ...eventFormData,
      associations: [activeCategory.question],
      boligmappaNumber,
      type: 1,
    });

    if (errorResponse) {
      setError(errorResponse);
      setRequestInProgress(false);
    } else {
      const { roomIds, documentIds } = eventFormData;
      const { id: eventId } = data;

      if (events) {
        addEvent({
          ...eventFormData,
          ...data,
        });
      }

      if (rooms) {
        syncRoomsEvents({
          eventId,
          roomIds,
          event: {
            ...eventFormData,
            ...data,
          },
        });
      }

      if (documents) {
        syncDocumentEvents({
          eventId,
          documentIds,
          event: {
            ...event,
            ...data,
          },
        });
      }

      if (imageFiles?.length > 0) {
        uploadEventImages(imageFiles, eventId);
      } else {
        setRequestInProgress(false);
        // nextStep();
        onBYBFEventSuccess();
      }
    }
  };

  const renderIntermediateStep = () => {
    if (isCategorySuccessful) {
      return (
        <BYBFEventCategorySuccess
          nextStep={setNextEventsActiveCategory}
          activeCategory={activeCategory}
        />
      );
    }
    return (
      <RegisterWork
        category={activeCategory}
        onAnswerNo={onAnswerNo}
        onAnswerYes={nextStep}
      />
    );
  };

  const renderSteps = () => {
    switch (activeStep) {
      case 1:
        return renderIntermediateStep();
      case 4:
        return (
          <RegisterWork
            isAddMore
            category={activeCategory}
            onAnswerNo={onAddMoreAnswerNo}
            onAnswerYes={onAddMore}
          />
        );
      default:
        return null;
    }
  };

  const renderDialogSteps = () => {
    switch (activeStep) {
      case 2:
        return (
          <Templates
            nextStep={nextStep}
            setEmptyEvent={setEmptyEvent}
            setEventFromTemplate={setEventFromTemplate}
            events={events}
            boligmappaNumber={boligmappaNumber}
            activeCategory={activeCategory}
            onExistingEventChosen={onExistingEventChosen}
          />
        );
      case 3:
        return event ? (
          <CreateEvent
            boligmappaNumber={boligmappaNumber}
            error={error}
            property={activeProperty}
            requestInProgress={requestInProgress}
            previousStep={previousStep}
            onSumbitEvent={onSumbitEvent}
            event={event}
            setEvent={setEvent}
            evetTemplate={evetTemplate}
          />
        ) : (
          <DialogContent
            className={clsx(
              isDesktop &&
                (activeStep !== 2
                  ? classes.dialogDesktopPadding
                  : classes.dialogDesktop),
              !isDesktop && classes.dialogPaddingMobile
            )}
          />
        );
      case 5:
        return <BYBFEventSuccess nextStep={previousStep} />;
      default:
        return (
          <DialogContent
            className={clsx(
              isDesktop &&
                (activeStep !== 2
                  ? classes.dialogDesktopPadding
                  : classes.dialogDesktop),
              !isDesktop && classes.dialogPaddingMobile
            )}
          >
            {renderSteps()}
          </DialogContent>
        );
    }
  };

  return (
    <Dialog
      open={open}
      scroll="paper"
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          onDialogClose();
        }
      }}
      disableEscapeKeyDown
      fullScreen={!isDesktop}
    >
      {title && <DialogTitle title={title} onClose={onDialogClose} />}

      {renderDialogSteps()}
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  rooms: sGetRooms(state),
  events: sGetEvents(state),
  fetching: sGetFetchingEvents(state),
  documents: sGetDocuments(state),
  activeProperty: sGetActiveProperty(state),
  steps: sGetBYBFSteps(state),
  activeBYBFCategory: sGetEventsActiveCategory(state),
  eventCategories: sGetEventsCategories(state),
});

const mapDispatchToProps = (dispatch) => ({
  addEvent: (event) => dispatch(acAddEvent(event)),
  setEvents: (events) => dispatch(acSetEvents(events)),
  setFetching: (fetching) => dispatch(acSetFetchingEvents(fetching)),
  syncRoomsEvents: (payload) => dispatch(acSyncRoomsEvents(payload)),
  syncDocumentEvents: (payload) => dispatch(acSyncDocumentsEvents(payload)),
  setEventsActiveCategory: (status) =>
    dispatch(acBYBFEventsSetActiveCategory(status)),
  setCategoryActiveStep: (category, step) =>
    dispatch(acBYBFEventsSetCategoryActiveStep(category, step)),
  setCategoryEvent: (category, event) =>
    dispatch(acBYBFEventsSetCategoryEvent(category, event)),
  setCategoryCompleted: (category, completed) =>
    dispatch(acBYBFEventsSetCategoryCompleted(category, completed)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BYBFEvents));
