import { useState } from 'react';

const useFormRequest = ({
  apiRequest,
  onError = () => {},
  onSuccess = () => {},
  preventDefault = true,
  initialFormDataState = {},
}) => {
  const [requestError, setRequestError] = useState(null);
  const [formData, setFormData] = useState(initialFormDataState);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const mutateFormData = (partialState) =>
    setFormData({
      ...formData,
      ...partialState,
    });

  const onFormSubmit = async (e, ...data) => {
    if (preventDefault && e && e.preventDefault) {
      e.preventDefault();
    }

    setRequestError(null);
    setRequestInProgress(true);

    const [error, response] = await apiRequest(...data);

    if (!error && response) {
      onSuccess(response);
    } else {
      onError(error);
      setRequestError(error);
    }

    setRequestInProgress(false);
  };

  return {
    formData,
    setFormData,
    requestError,
    onFormSubmit,
    mutateFormData,
    requestInProgress,
  };
};

export default useFormRequest;
