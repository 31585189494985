import clsx from 'clsx';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { withStyles } from '@material-ui/core';
import { useFlag } from '@unleash/proxy-client-react';
import { connect } from 'react-redux';

import {
  getDocumentId,
  areFiltersEmpty,
  groupingSortTypes,
  getFilteredDocuments,
  getDefaultDocumentSortType,
  UPDATED_DATE_ASC,
  documentSortTypes,
  isDocumentReadOnly,
  getDocumentIsBuilding,
  getDocumentTypeId,
  getDocumentSortType,
  isDocumentsFolder,
} from '../../../utils/documents';
import styles from './style';
import Box from '../../atomic/Box';
import Button from '../../atomic/Button';
import Link from '../../atomic/Link';
import List from '../../atomic/List';
import Paper from '../../atomic/Paper';
import Checkbox from '../../atomic/Checkbox';
import Typography from '../../atomic/Typography';
import DocumentEditFilters from '../../DocumentEditFilters';
import DocumentListItem from './DocumentListItem';
import useDocumentFilters from '../../../hooks/useDocumentFilters';
import SelectedFilters from '../../DocumentEditFilters/SelectedFilters';
import DocumentListItemLoading from '../../loaders/DocumentListItemLoading';
import { areIdsEqual } from '../../../utils/strings';
import DocumentBulkEditMobile from '../../DocumentEditOptions/DocumentBulkEditMobile';
import matomo from '../../../utils/matomo';
import SnackbarProvider from '../../SnackbarProvider';
import DocumentDownloadSuccessIcon from '../../../assets/icons/DocumentBulkEditIcons/DocumentDownloadSuccess';
import DocumentDownloadWarningIcon from '../../../assets/icons/DocumentBulkEditIcons/DocumentDownloadWarning';
import DocumentContextMenu from '../DocumentViewTable/DocumentActionMenu';
import { FileUploadContext } from '../../../contexts/fileUpload';
import DocumentFielUploadItem from '../../DocumentFileUploadItem';
import { acAddDocument } from '../../../store/actions/documents';
import { acAddPersonalDocument } from '../../../store/actions/personalDocuments';
import useSnackbar from '../../../hooks/useSnackbar';
import { acSyncRoomsDocuments } from '../../../store/actions/rooms';
import {
  acSetShowEventCreateSuggesion,
  acSyncEventsDocuments,
} from '../../../store/actions/events';
import Info from '../../../assets/icons/Info';
import IconButton from '../../atomic/IconButton';
import Close from '../../../assets/icons/Close';
import useDebounce from '../../../hooks/useDebounce';
import EventCreateSuggesion from '../../EventCreateSuggesion';
import { sGetShowEventCreateSuggesion } from '../../../store/reducers/events';

const DocumentList = ({
  classes,
  fetching,
  documents,
  documentFolders,
  onFolderChange,
  directoryId,
  setDirectoryId,
  directoryTree,
  setDirectoryTree,
  documentIds,
  searchQuery,
  fetchingError,
  onDocumentDeleted,
  isFiltersSticky = true,
  headerHeight,
  setStickyFiltersHeight,
  showOnPaper = true,
  showFilters = true,
  actionsEnabled = true,
  showRoomsColumn = false,
  showEventsColumn = false,
  enableWidgetContextMenu = false,
  addDocument,
  addPersonalDocument,
  syncEventsDocuments,
  syncRoomsDocuments,
  showEventCreateSuggesion,
  setShowEventCreateSuggesion,
  isPersonalDocuments = false,
  isFromPersonal,
  isBoligmappaPlusUser = false,
  onFolderDelete,
}) => {
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const { filters, onFiltersChanged } = useDocumentFilters();
  const {
    files,
    setFiles,
    onFileUploadComplete,
    source,
    newUpload,
    docRoom,
    docEvent,
    setDocEvent,
    setDocRoom,
  } = useContext(FileUploadContext);
  const { showSuccessMessage, showErrorMessage } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openContextMenu, setOpenContextMenu] = useState(false);
  const [openBulkEditMenu, setOpenBulkEditMenu] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [sortType, setSortType] = useState(getDefaultDocumentSortType());
  const [currentSortingType, setCurrentSortingType] =
    useState(UPDATED_DATE_ASC);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const selectedDocument = (documents || [])
    .concat(documentFolders)
    .find((document) =>
      areIdsEqual(getDocumentId(document), selectedDocumentId)
    );
  const [showDocumentTypeWarning, setShowDocumentTypeWarning] = useState(true);
  const [docCountWithoutType, setDocCountWithoutType] = useState(0);
  const debouncedDocuments = useDebounce(searchQuery, 1000);
  const enableSuggestNewEvent = useFlag('boligeier.suggest.new.event');

  const topRef = useRef(null);
  const filtersRef = useRef(null);
  const onBulkMenuLoad = () => {
    setOpenBulkEditMenu(true);
  };

  const handleLinkClick = () => {
    onFiltersChanged({ ...filters, DOCUMENT_TYPE_FILTER: ['Udefinert'] });
  };

  useEffect(() => {
    const savedSortType = JSON.parse(localStorage.getItem('sortType'));

    if (savedSortType !== null) {
      setSortType(getDocumentSortType(savedSortType.id));
      setCurrentSortingType(savedSortType.id);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('sortType', JSON.stringify(sortType));
  }, [sortType]);

  useEffect(() => {
    if (setStickyFiltersHeight && filtersRef.current) {
      setStickyFiltersHeight(filtersRef.current.offsetHeight);
    }
  }, [filtersRef.current]);

  useEffect(() => {
    if (files.length > 0) {
      setUploadedFiles(files);
      window.scrollTo(0, 0);
    }
    setShowDocumentTypeWarning(true);
  }, [files]);

  useEffect(() => {
    setSelectedDocuments(
      documents?.filter((doc) =>
        selectedDocuments?.some((selectedDoc) => selectedDoc.id === doc.id)
      )
    );
    setDocCountWithoutType(
      documents?.filter((x) => getDocumentTypeId(x) === 0).length
    );
  }, [documents]);

  useEffect(() => {
    if (selectedDocuments?.length > 0) {
      matomo.clickEvent({
        category: 'Document handling',
        name:
          documents.length === selectedDocuments.length
            ? 'Select all document'
            : 'Select single document',
        action: 'Select Document from Document List',
        value: selectedDocuments?.length,
      });
    }
  }, [selectedDocuments?.length]);

  const onUploaded = (doc) => {
    setUploadedDocs((oldState) => [...oldState, doc]);
  };

  useEffect(() => {
    if (
      uploadedFiles.length > 0 &&
      uploadedDocs.length === uploadedFiles.length &&
      source === 'listView'
    ) {
      setFiles([]);
      setUploadedFiles([]);
      setDirectoryTree([]);
      onFileUploadComplete();
      uploadedDocs.forEach((updoc) => {
        if (updoc.status) {
          const doc = updoc.data;
          if (isPersonalDocuments) {
            matomo.clickEvent({
              category: 'Document handling',
              action: 'Personal document upload',
              name: 'Personal document upload',
            });
            addPersonalDocument(doc);
          } else addDocument(doc);

          if (docRoom) {
            syncRoomsDocuments({
              roomIds: doc?.roomIds,
              document: doc,
              documentId: doc?.id,
            });
          }

          if (docEvent) {
            syncEventsDocuments({
              eventIds: doc.eventIds,
              document: doc,
              documentId: doc?.id,
            });
          }

          setSelectedDocuments((currentState) => {
            if (currentState) {
              let tempSelectedDocuments = [...currentState];
              if (doc?.id) {
                if (!tempSelectedDocuments.some((obj) => obj.id === doc.id)) {
                  tempSelectedDocuments.push(doc);
                } else {
                  tempSelectedDocuments = tempSelectedDocuments.filter(
                    (obj) => obj.id !== doc.id
                  );
                }
                return tempSelectedDocuments;
              }
              return [];
            }
            return [];
          });
        }
      });
      if (
        uploadedDocs.filter((x) => x.status === true).length > 0 &&
        source === 'listView'
      ) {
        showSuccessMessage(
          uploadedDocs.filter((x) => x.status === true).length > 1
            ? 'Dokumenter lastet opp'
            : 'Dokument lastet opp'
        );
      }

      if (enableSuggestNewEvent) {
        if (uploadedFiles?.length > 1 && !isPersonalDocuments) {
          setShowEventCreateSuggesion(true);
        }
      }

      if (
        uploadedDocs.filter((x) => x.status === false).length > 0 &&
        source === 'listView'
      ) {
        showErrorMessage(
          ` Noe gikk galt ved opplasting av ${uploadedDocs
            .filter((x) => x.status === false)
            .map(({ data }) => data)
            .join()} mangler dokumenttype. Se dokumentene.`
        );
      }
      setUploadedDocs([]);
      setUploadedFiles([]);
      setDocRoom(null);
      setDocEvent(null);
    }
  }, [uploadedDocs]);

  const onDocumentSelect = (value) => {
    setSelectedDocuments((currentState) => {
      if (currentState) {
        let tempSelectedDocuments = [...currentState];
        if (value?.id) {
          if (!tempSelectedDocuments.some((obj) => obj.id === value.id)) {
            tempSelectedDocuments.push(value);
          } else {
            tempSelectedDocuments = tempSelectedDocuments.filter(
              (obj) => obj.id !== value.id
            );
          }
          return tempSelectedDocuments;
        }
        return [];
      }
      return [];
    });
  };

  const filteredDocuments = getFilteredDocuments({
    filters,
    documents,
    documentFolders,
    searchQuery,
    documentIds,
    sortFunction: sortType.sortFunction,
    shouldSort: !groupingSortTypes.includes(sortType),
    isPersonalDocuments,
  });

  useEffect(() => {
    if (searchQuery) {
      matomo.clickEvent({
        category: 'Document handling',
        action: 'Search for Document',
        name: 'Search for Document',
        value: filteredDocuments?.length,
      });
    }
  }, [debouncedDocuments]);

  const onDocumentMenuClick = (document, event) => {
    event.persist();
    setSelectedDocumentId(getDocumentId(document));
    setOpenContextMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const onBulkDocumentSelect = () => {
    setSelectedDocuments((currentState) =>
      filteredDocuments
        .filter((item) => !isDocumentsFolder(item))
        .filter((item) => !isDocumentReadOnly(item))
        .filter((item) => !getDocumentIsBuilding(item)) > currentState
        ? filteredDocuments
            .filter((item) => !isDocumentsFolder(item))
            .filter((item) => !isDocumentReadOnly(item))
            .filter((item) => !getDocumentIsBuilding(item))
        : []
    );
  };

  const getBulkSelectionChecked = () => {
    if (selectedDocuments?.length === 0) {
      return false;
    }
    return (
      selectedDocuments?.length ===
      filteredDocuments
        ?.filter((item) => !isDocumentReadOnly(item))
        .filter((item) => !getDocumentIsBuilding(item)).length
    );
  };

  const toggleContextMenu = (open) => {
    if (!open) {
      setAnchorEl(null);
      setSelectedDocumentId(null);
    }
  };

  const onSortTypeChange = (newSortType) => {
    setSortType(documentSortTypes[newSortType]);
    setCurrentSortingType(newSortType);
  };

  const onEventSuggesionClose = () => {
    setShowEventCreateSuggesion(false);
    matomo.clickEvent({
      category: 'Document handling',
      action: 'Suggest Event',
      name: 'Dismiss New Event from Suggest Event',
    });
  };

  if (fetching) {
    return (
      <Paper visible={showOnPaper}>
        <List>
          <DocumentListItemLoading />
          <DocumentListItemLoading />
        </List>
      </Paper>
    );
  }

  const filtersComponents = (
    <Box>
      <div
        ref={filtersRef}
        className={clsx([
          classes.filtersBlock,
          isFiltersSticky && classes.fixedFilters,
        ])}
        style={{ top: headerHeight }}
      >
        {selectedDocuments?.length > 0 ? (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingRight: '10px',
                width: '100%',
              }}
            >
              {actionsEnabled && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    onChange={() => onBulkDocumentSelect()}
                    checked={getBulkSelectionChecked}
                    className={classes.checkboxChecked}
                    isChecked={getBulkSelectionChecked()}
                  />
                  <Typography className={classes.docCountText}>
                    {selectedDocuments?.length} valgt
                  </Typography>
                </Box>
              )}

              <Box>
                <Button
                  varinat="text"
                  color="primary"
                  onClick={onBulkMenuLoad}
                  className={classes.editButton}
                >
                  Rediger
                </Button>
              </Box>
            </div>
            <SelectedFilters
              filters={filters}
              onFiltersChanged={onFiltersChanged}
            />
          </>
        ) : (
          <Box
            className={clsx(
              classes.filters,
              areFiltersEmpty(filters) && classes.filtersEmpty
            )}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {actionsEnabled && (
                <Checkbox
                  onChange={() => onBulkDocumentSelect()}
                  checked={
                    selectedDocument?.length > 0 &&
                    selectedDocuments?.length === filteredDocuments?.length
                  }
                  className={classes.checkbox}
                  style={{ padding: 0 }}
                />
              )}
              <DocumentEditFilters
                filters={filters}
                fetching={fetching}
                sortType={sortType}
                documents={documents}
                fetchingError={fetchingError}
                onSortTypeChange={onSortTypeChange}
                onFiltersChanged={onFiltersChanged}
                currentSortingType={currentSortingType}
                isPersonalDocuments={isPersonalDocuments}
              />
            </div>
            <SelectedFilters
              filters={filters}
              onFiltersChanged={onFiltersChanged}
            />
          </Box>
        )}
      </div>
    </Box>
  );

  return (
    <>
      {showFilters &&
        Array.isArray(filteredDocuments) &&
        filteredDocuments.length > 0 &&
        filtersComponents}
      <EventCreateSuggesion
        mulitipleDocumentsSelected
        onClose={onEventSuggesionClose}
        documents={selectedDocuments}
        open={showEventCreateSuggesion}
      />
      <Paper className={classes.paper}>
        <div ref={topRef} />
        {showDocumentTypeWarning &&
          !isPersonalDocuments &&
          docCountWithoutType > 0 &&
          actionsEnabled && (
            <Box className={clsx([classes.uploadMessageContainer])}>
              <Info width="20px" height="20px" className={classes.infoIcon} />
              <Typography className={classes.uploadMessage}>
                <span style={{ fontWeight: 700 }}>
                  {docCountWithoutType} dokumenter {` `}
                </span>
                mangler dokumenttype.{' '}
                <Link
                  to="#"
                  className={classes.linkButton}
                  onClick={handleLinkClick}
                >
                  Se dokumentene.
                </Link>
              </Typography>
              <IconButton
                onClick={() => {
                  setShowDocumentTypeWarning(false);
                }}
                className={classes.closeButton}
              >
                <Close className={classes.infoIcon} />
              </IconButton>
            </Box>
          )}

        {Array.isArray(uploadedFiles) && uploadedFiles.length > 0 && (
          <List disablePadding>
            {uploadedFiles.map((file) => (
              <DocumentFielUploadItem
                file={file}
                span={files.length}
                directoryId={directoryId}
                setDirectoryId={setDirectoryId}
                directoryTree={directoryTree}
                onUploaded={onUploaded}
                room={docRoom}
                event={docEvent}
                personalDocument={isPersonalDocuments}
              />
            ))}
          </List>
        )}
        {Array.isArray(filteredDocuments) && filteredDocuments.length > 0 && (
          <List disablePadding>
            {filteredDocuments.map((document) => (
              <DocumentListItem
                document={document}
                onFolderChange={onFolderChange}
                newUpload={newUpload}
                searchQuery={searchQuery}
                actionsEnabled={actionsEnabled}
                onMenuClick={onDocumentMenuClick}
                showRoomsColumn={showRoomsColumn}
                showEventsColumn={showEventsColumn}
                key={`document-item-${getDocumentId(document)}`}
                onSelect={(values) => onDocumentSelect(values)}
                selectedDocuments={selectedDocuments}
                isPersonalDocuments={isPersonalDocuments}
                isFromPersonal={isFromPersonal}
              />
            ))}
          </List>
        )}

        <SnackbarProvider
          hideIconVariant={false}
          preventDuplicate={false}
          closeButton
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{
            root: classes.snackbar,
            success: classes.success,
          }}
          iconVariant={{
            success: <DocumentDownloadSuccessIcon />,
            warning: <DocumentDownloadWarningIcon />,
          }}
        >
          <DocumentBulkEditMobile
            selectedDocuments={selectedDocuments}
            anchorEl={anchorEl}
            open={openBulkEditMenu}
            setOpen={
              enableWidgetContextMenu ? toggleContextMenu : setOpenBulkEditMenu
            }
            clearSelectedDocuments={() => setSelectedDocuments([])}
            closeActionMenu={() => setOpenBulkEditMenu(false)}
            isPersonalDocuments={isPersonalDocuments}
            isFromPersonal={isFromPersonal}
            isBoligmappaPlusUser={isBoligmappaPlusUser}
          />
        </SnackbarProvider>

        <DocumentContextMenu
          openParentPop={
            enableWidgetContextMenu
              ? !!anchorEl && !!selectedDocument
              : openContextMenu
          }
          document={selectedDocument}
          setOpenParentPop={
            enableWidgetContextMenu ? toggleContextMenu : setOpenContextMenu
          }
          onDocumentDeleted={onDocumentDeleted}
          parentAnchorEl={anchorEl}
          isPersonalDocuments={isPersonalDocuments}
          isFromPersonal={isFromPersonal}
          isBoligmappaPlusUser={isBoligmappaPlusUser}
          onFolderDelete={onFolderDelete}
          onFolderChange={onFolderChange}
        />
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({
  showEventCreateSuggesion: sGetShowEventCreateSuggesion(state),
});

const mapDispatchToProps = (dispatch) => ({
  addDocument: (document) => dispatch(acAddDocument(document)),
  addPersonalDocument: (document) => dispatch(acAddPersonalDocument(document)),
  syncRoomsDocuments: (payload) => dispatch(acSyncRoomsDocuments(payload)),
  syncEventsDocuments: (payload) => dispatch(acSyncEventsDocuments(payload)),
  setShowEventCreateSuggesion: (payload) =>
    dispatch(acSetShowEventCreateSuggesion(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DocumentList));
