import React, { useEffect, useState } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import DashboardLayout from '../../../components/layouts/Dashboard';
import DesktopTopHeader from '../../../components/desktop/Header';
import HeaderNav from '../../../componentsV2/HeaderNav';
import { personalAreaNavbarMenu } from '../../../structure/menu';
import BoligmappaPlusPage from './BoligmappaPlusPage';
import Hidden from '../../../components/atomic/Hidden';
import MobileNavbar from './MobileNavbar';
import PersonalAreaLandingPage from './PersonalAreaLandingPage';
import PersonalDocumentsPage from './PersonalDocumentsPage';
import Document from '../../PrivateRoutes/Dashboard/Documents/Document';
import ContractTemplatePage from './ContractTemplatePage';
import styles from './style';
import ProfileDelete from '../../PrivateRoutes/Dashboard/More/ProfileDelete';
import Terms from '../../PrivateRoutes/Dashboard/More/Terms';
import Consents from '../../PrivateRoutes/Dashboard/More/Consents';
import DownloadPersonalData from '../../PrivateRoutes/Dashboard/More/DownloadPersonalData';
import Account from '../../PrivateRoutes/Dashboard/More/Account';
import ContactUs from '../../PrivateRoutes/Dashboard/More/ContactUs';
import PrivateShare from '../../PrivateRoutes/Dashboard/More/PrivateShare';
import BoligmappaPlusLoyaltyPage from '../../PrivateRoutes/Dashboard/BoligmappaPlusLoyaltyPage';
import BoligmappaPlusLoyaltyCompanyPage from '../../PrivateRoutes/Dashboard/BoligmappaPlusLoyaltyCompanyPage';
import { locationsBackgrounds } from '../../PrivateRoutes/Dashboard/util';
import PurchasesPage from '../../PrivateRoutes/Dashboard/PurchasesPage';

const Dashboard = () => {
  const { pathname } = useLocation();

  const [backgroundContentContainer, setBackgroundContentContainer] =
    useState('#F3F6F6');

  const desktopTopHeader = (
    <DesktopTopHeader publicMode={false} isPersonalArea />
  );
  const desktopHeaderNav = (
    <HeaderNav navbarMenuItems={personalAreaNavbarMenu} />
  );

  useEffect(() => {
    const backgroundKeys = Object.keys(locationsBackgrounds);
    const selectedBackgroundKey = backgroundKeys.find((keyword) =>
      pathname.includes(keyword)
    );
    setBackgroundContentContainer(
      locationsBackgrounds[selectedBackgroundKey] || '#F3F6F6'
    );
  }, [pathname]);

  return (
    <DashboardLayout
      desktopTopHeader={desktopTopHeader}
      desktopHeaderNav={desktopHeaderNav}
      addButtonPaddingEnabled={false}
      backgroundContentContainer={backgroundContentContainer}
    >
      <Route path="/personal-area" exact>
        <Redirect to="/personal-area/dashboard/home" />
      </Route>
      <Route
        component={PersonalAreaLandingPage}
        path="/personal-area/dashboard/home"
      />
      <Route
        exact
        component={PersonalDocumentsPage}
        path="/personal-area/dashboard/documents"
      />
      <Route
        exact
        path="/personal-area/dashboard/documents/:documentId"
        component={Document}
      />
      <Route
        exact
        component={BoligmappaPlusPage}
        path="/personal-area/dashboard/boligmappa-plus"
      />
      <Route
        component={ContractTemplatePage}
        path="/personal-area/dashboard/contract-templates"
      />
      <Route
        exact
        component={BoligmappaPlusLoyaltyPage}
        path="/personal-area/dashboard/boligmappa-plus/loyalty"
      />
      <Route
        exact
        component={BoligmappaPlusLoyaltyCompanyPage}
        path="/personal-area/dashboard/boligmappa-plus/loyalty/company/:companyCode"
      />
      <Route
        component={ProfileDelete}
        path="/personal-area/dashboard/more/profile-delete"
      />
      <Route component={Terms} path="/personal-area/dashboard/more/terms" />
      <Route
        component={Consents}
        path="/personal-area/dashboard/more/consents"
      />
      <Route
        component={DownloadPersonalData}
        path="/personal-area/dashboard/more/download-personal-data"
      />
      <Route component={Account} path="/personal-area/dashboard/more/account" />
      <Route
        component={ContactUs}
        path="/personal-area/dashboard/more/contact"
      />
      <Route
        exact
        component={PrivateShare}
        path="/personal-area/dashboard/more/private-share"
      />
      <Route
        exact
        component={PurchasesPage}
        path="/personal-area/dashboard/my-purchases"
      />
      <Hidden mdUp>
        <MobileNavbar />
      </Hidden>
    </DashboardLayout>
  );
};

export default withStyles(styles)(Dashboard);
