import React from 'react';

const DownloadIcon = ({ width = '0.9rem', height = '0.9rem', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.9062 6.25C10.9688 6.1875 11 6.125 11 6C11 5.90625 10.9375 5.8125 10.875 5.71875L10.2812 5.09375C10.1875 5.03125 10.0938 5 10 5C9.875 5 9.8125 5.0625 9.75 5.125L6.8125 8.15625V0.375C6.8125 0.28125 6.75 0.1875 6.6875 0.125C6.625 0.0625 6.53125 0 6.4375 0H5.5625C5.4375 0 5.34375 0.0625 5.28125 0.125C5.21875 0.1875 5.1875 0.28125 5.1875 0.375V8.15625L2.25 5.125C2.1875 5.0625 2.09375 5 2 5C1.875 5 1.78125 5.03125 1.71875 5.09375L1.09375 5.71875C1.03125 5.8125 1 5.90625 1 6C1 6.125 1.03125 6.1875 1.125 6.25L5.71875 10.875C5.78125 10.9688 5.875 11 6 11C6.09375 11 6.1875 10.9688 6.28125 10.875L10.9062 6.25ZM11.625 12.375C11.7188 12.375 11.8125 12.4375 11.875 12.5C11.9375 12.5625 12 12.6562 12 12.75V13.625C12 13.75 11.9375 13.8438 11.875 13.9062C11.8125 13.9688 11.7188 14 11.625 14H0.375C0.25 14 0.15625 13.9688 0.09375 13.9062C0.03125 13.8438 0 13.75 0 13.625V12.75C0 12.6562 0.03125 12.5625 0.09375 12.5C0.15625 12.4375 0.25 12.375 0.375 12.375H11.625Z"
      fill="#111111"
    />
  </svg>
);
export default DownloadIcon;
