import {
  SET_API_HEALTH_FETCHING,
  SET_API_HEALTH_FETCHING_ERROR,
  SET_API_HEALTH_STATUS,
} from '../reducers/apiHealth';

export const acSetApiHealth = (status) => ({
  type: SET_API_HEALTH_STATUS,
  payload: status,
});

export const acApiHealthSetFetching = (fetching) => ({
  type: SET_API_HEALTH_FETCHING,
  payload: fetching,
});

export const acApiHealthSetFetchingError = (error) => ({
  type: SET_API_HEALTH_FETCHING_ERROR,
  payload: error,
});
