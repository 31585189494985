/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { OverviewGridItem } from './components';
import Card from '../../../../../components/Card';
import useDesktop from '../../../../../hooks/useDesktop';
import PropertyMarketEventsCard from '../../../../../components/PropertyMarketEventsCard';

export function PropertyMarketEventsSection({ showMaxwidth, hideValues }) {
  const isDesktop = useDesktop();
  return (
    <OverviewGridItem showMaxwidth={showMaxwidth}>
      <Card border={isDesktop ? 1 : 0} padding={isDesktop ? 3 : 0}>
        <PropertyMarketEventsCard hideValues={hideValues} />
      </Card>
    </OverviewGridItem>
  );
}
