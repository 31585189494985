import React from 'react';
import Typography from '../../atomic/Typography';
import EmailAlternate from '../../../assets/icons/EmailAlternate';
import { SentMDRStyles } from './styles';
import { transformToMoreThanFiveYears } from '../../../utils/jobsUtils';
import ContentLoader from '../../loaders/ContentLoader';

export default function FAQ({
  classes = SentMDRStyles(),
  labels,
  isLoading,
  sentRequests,
}) {
  return isLoading ? (
    <ContentLoader height={20} width={100} className={classes.titleLoader} />
  ) : (
    sentRequests.length > 0 && (
      <div className={classes.sentMDRSection}>
        <Typography className={classes.sentMDRTitle}>{labels.title}</Typography>
        <Typography className={classes.sentMDRSubTitle}>
          {labels.subTitle}
        </Typography>
        {sentRequests.map((item) => (
          <div key={`item_${item.title}`} className={classes.sentMDRItem}>
            <EmailAlternate className={classes.emailIcon} />
            <Typography className={classes.sentMDRItemLabel}>
              {`${item.organizationName} - ${transformToMoreThanFiveYears(
                item.jobDate,
                labels.moreThanFiveyears
              )}`}
            </Typography>
          </div>
        ))}
      </div>
    )
  );
}
