import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import styles from './style';
import {
  getDocumentPath,
  getDocumentName,
  getDocumentFileType,
} from '../../../../../utils/documents';
import { truncate } from '../../../../../utils/strings';
import useDesktop from '../../../../../hooks/useDesktop';
import Paper from '../../../../../components/atomic/Paper';
import FileViewer from '../../../../../components/FileViewer';
import Page from '../../../../../components/layouts/WithHeader';
import useFetchEffect from '../../../../../hooks/useFetchEffect';
import DocumentDetails from '../../../../../modules/DocumentDetails';
import ErrorContainer from '../../../../../components/ErrorContainer';
import { fetchPublicDocument as apiFetchDocument } from '../../../../../api/documents';
import CloseBold from '../../../../../assets/icons/DocumentIcons/CloseBold';
import BottomContextMenu from '../../../../../components/BottomContextMenu';
import ArrowUp from '../../../../../assets/icons/ArrowUp';
import useHeaderSnackbar from '../../../../../hooks/useHeaderSnackbar';
import ShowErrorMessage from '../../../../../components/ShowErrorMessage';

const Document = ({ classes }) => {
  const [documentPath, setDocumentPath] = useState(null);
  const [isConvertedFile, setIsConvertedFile] = useState(false);
  const [isMobileContext, setIsMobileContext] = useState(false);

  const isDesktop = useDesktop();
  const { documentId, boligmappaNumber } = useParams();
  const history = useHistory();

  const swipeableRef = useRef(null);

  const { snackbarHeight } = useHeaderSnackbar();
  const {
    fetching,
    fetchingError,
    data: document,
  } = useFetchEffect({
    dependencies: [documentId],
    apiFetchFunction: () => apiFetchDocument(documentId),
  });

  const documentTitle = isDesktop
    ? getDocumentName(document)
    : truncate(getDocumentName(document), { length: 30 });

  const onDocumentClose = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(`/public/${boligmappaNumber}/documents`);
    }
  };

  const onCloseContext = () => {
    swipeableRef.current.scrollTop = 0;
    setIsMobileContext(false);
  };

  if (fetchingError) {
    return <ErrorContainer errorResponse={fetchingError} />;
  }
  useEffect(() => {
    if (document) {
      setDocumentPath(getDocumentPath(document));
      if (document.pdfconversion) {
        setIsConvertedFile(true);
      }
    }
  }, [document, documentPath]);
  return (
    <div>
      <div
        style={{ marginTop: snackbarHeight }}
        className={classes.previewHeader}
      >
        <div className={clsx([classes.editInputBlock, isDesktop && 'desktop'])}>
          <button
            className={classes.previewCloseButton}
            type="button"
            onClick={() => onDocumentClose()}
            aria-label="Close"
          >
            <CloseBold />
          </button>
          <p className={classes.documentName}>{documentTitle}</p>
        </div>
      </div>
      <Page
        noPaddingTop
        minFullHeight
        hideHeader
        noPadding
        maxWidth={false}
        className={classes.previewPage}
        contentClass={classes.contentClass}
      >
        {document && (!documentPath || !document.documentFileAvailable) && (
          <ShowErrorMessage noFile />
        )}
        {documentPath && document?.documentFileAvailable && (
          <FileViewer
            documentData={document}
            document={documentId}
            fetching={fetching}
            filePath={documentPath}
            fileType={getDocumentFileType(document)}
            className={classes.filePreview}
            isConvertedFile={isConvertedFile}
          />
        )}

        {isDesktop ? (
          <div className={classes.previewDetails}>
            <Paper visible={!isDesktop}>
              <DocumentDetails
                fetching={fetching}
                document={document}
                showDownloadLink={false}
                showHelperText={!isDesktop}
              />
            </Paper>
          </div>
        ) : (
          <BottomContextMenu
            open={isMobileContext}
            showAnchor={false}
            className={clsx([
              classes.mobileContext,
              isMobileContext && classes.mobileContextShown,
            ])}
            paperClass={!isMobileContext && classes.visibilityDrawerHidden}
            closeOnContentClick
            onClose={onCloseContext}
            onClick={() => !isMobileContext && setIsMobileContext(true)}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              style: {
                overflow: !isMobileContext && 'hidden',
              },
            }}
          >
            <div
              className={clsx(
                classes.contentWrapper,
                !isMobileContext && classes.contentHidden
              )}
              ref={swipeableRef}
            >
              <button
                type="button"
                className={classes.mobileContextButton}
                aria-label="Arrow Up"
              >
                <ArrowUp
                  className={clsx(
                    classes.mobileContextIcon,
                    isMobileContext && classes.mobileContextIconOpened
                  )}
                />
              </button>
              <DocumentDetails
                fetching={fetching}
                document={document}
                showDownloadLink={false}
                showHelperText={!isDesktop}
              />
            </div>
          </BottomContextMenu>
        )}
      </Page>
    </div>
  );
};

export default withStyles(styles)(Document);
