// Add your styles here, and reference them in your component className
import { css } from '@linaria/core';
import { mediaQueries } from '@style/mediaQueries';

export const descriptionWrapper = css`
  .company-description {
    margin: 15px 0;
    font-size: 14px;
    line-height: 22px;
  }

  .clamp {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    ${mediaQueries.md} {
      -webkit-line-clamp: 50;
    }
  }
`;
