import React, { createElement } from 'react';
import { TableCell, TableRow, withStyles } from '@material-ui/core';

import {
  getDocumentId,
  getDocumentName,
  getDocumentType,
  isDocumentImage,
  getFileThumbNail,
  getDocumentRooms,
  getDocumentEvents,
  isDocumentReadOnly,
  getDocumentFileType,
  getDocumentCompanyId,
  getDocumentCreatedDate,
  getDocumentOwnedByName,
  getDocumentCompanyName,
  getOriginalDocumentPath,
  getDocumentProfessionType,
  getTruncatedDocName,
} from '../../../../../utils/documents';
import Tooltip from '../../../../Tooltip';
import styles from './style';

import Image from '../../../../Image';
import Link from '../../../../atomic/Link';
import IconButton from '../../../../atomic/IconButton';
import AttachedEntity from '../../../../AttachedEntity';
import TextHighlighted from '../../../../TextHighlighted';
import { formattedDate } from '../../../../../utils/dates';
import LockCircled from '../../../../../assets/icons/LockCircled';
import ImgIcon from '../../../../../assets/icons/DocumentIcons/ImgIcon';
import MenuHorizontalIcon from '../../../../../assets/icons/MenuHorizontal';
import useDashboardLinkPrefix from '../../../../../hooks/useDashboardLinkPrefix';

const DocumentSummaryItem = ({
  document,
  searchQuery,
  actionsEnabled,
  onDocumentClick,
  showCompanyNameLink,
  showRoomsColumn = false,
  showEventsColumn = false,
  showUploadedDateColumn = true,
  showUploaderNameColumn = true,
  classes,
}) => {
  const dashboardLinkPrefix = useDashboardLinkPrefix();

  const documentPath = `${dashboardLinkPrefix}/documents/${getDocumentId(
    document
  )}`;

  const renderIcon = () => {
    if (isDocumentImage(document)) {
      return (
        <Image
          fallbackComponent={<ImgIcon />}
          alt={getDocumentName(document)}
          src={getOriginalDocumentPath(document)}
          style={{
            width: '38px',
            height: '38px',
            objectFit: 'cover',
            borderRadius: '3px',
          }}
        />
      );
    }

    return createElement(
      getFileThumbNail(getDocumentFileType(document)) ||
        getFileThumbNail('default')
    );
  };

  return (
    <TableRow>
      <TableCell
        style={{ paddingLeft: 0, maxWidth: '300px', wordBreak: 'break-all' }}
      >
        <div className={classes.doc_icon_name_wrapper}>
          <div style={{ display: 'flex' }}>
            {renderIcon()}
            <div className={classes.doc_list_read_only_icon}>
              {isDocumentReadOnly(document) && (
                <LockCircled
                  width={18}
                  height={18}
                  style={{ position: 'relative', top: 2 }}
                />
              )}
            </div>
          </div>
          <div>
            <Tooltip
              title={getDocumentName(document)}
              placement="top"
              fontSize={13}
            >
              <Link underline={false} inheritColor to={documentPath}>
                <TextHighlighted searchQuery={searchQuery}>
                  {getTruncatedDocName(getDocumentName(document))}
                </TextHighlighted>
                {!showUploadedDateColumn ? (
                  <div className={classes.table_col_sub_text}>
                    <small>
                      {formattedDate(getDocumentCreatedDate(document))}
                    </small>
                  </div>
                ) : (
                  ''
                )}
              </Link>
            </Tooltip>
          </div>
        </div>
      </TableCell>

      {showUploadedDateColumn && (
        <TableCell>
          <TextHighlighted searchQuery={searchQuery}>
            {formattedDate(getDocumentCreatedDate(document))}
          </TextHighlighted>
        </TableCell>
      )}

      {showUploaderNameColumn && (
        <TableCell>
          {getDocumentCompanyId(document) && showCompanyNameLink ? (
            <>
              <Link
                to={`${dashboardLinkPrefix}/company/${getDocumentCompanyId(
                  document
                )}`}
              >
                <TextHighlighted searchQuery={searchQuery}>
                  {getDocumentCompanyName(document)}
                </TextHighlighted>
              </Link>
              <div className={classes.table_col_sub_text}>
                <small>{getDocumentProfessionType(document)}</small>
              </div>
            </>
          ) : (
            <TextHighlighted searchQuery={searchQuery}>
              {getDocumentOwnedByName(document) ||
                getDocumentCompanyName(document)}
            </TextHighlighted>
          )}
        </TableCell>
      )}

      <TableCell>
        <TextHighlighted searchQuery={searchQuery}>
          {getDocumentType(document)}
        </TextHighlighted>
      </TableCell>

      {showEventsColumn && (
        <TableCell>
          <AttachedEntity
            type="event"
            document={document}
            editable={actionsEnabled}
            entities={getDocumentEvents(document)}
          />
        </TableCell>
      )}

      {showRoomsColumn && (
        <TableCell>
          <AttachedEntity
            type="room"
            document={document}
            editable={actionsEnabled}
            entities={getDocumentRooms(document)}
          />
        </TableCell>
      )}

      <TableCell>
        {actionsEnabled && (
          <IconButton
            size="small"
            onClick={(event) => onDocumentClick(event, document)}
          >
            <MenuHorizontalIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default withStyles(styles)(DocumentSummaryItem);
