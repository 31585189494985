export const getBackgroundColor = (state, theme) => {
  switch (true) {
    case state.isSelected:
      return theme.palette.primary.greyLight;
    case state.isFocused:
      return theme.palette.primary.greyLight;
    default:
      return theme.palette.primary.white;
  }
};

export const getColor = (state, theme) => {
  switch (true) {
    case state.isSelected:
      return theme.palette.primary.primary;
    case state.isFocused:
      return theme.palette.text.primary;
    default:
      return theme.palette.text.primary;
  }
};

export const checked = (state) => {
  switch (true) {
    case state.isSelected:
      return 'flex';
    default:
      return 'none';
  }
};
