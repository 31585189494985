import React from 'react';
import { withStyles } from '@material-ui/core';

import Styles from '../SingleRecord/style';
import List from '../../../../../../../components/atomic/List';
import { formattedDate } from '../../../../../../../utils/dates';
import Typography from '../../../../../../../components/atomic/Typography';
import ListItem from '../../../../../../../components/CardList/KeyValueListItem';

const SingleAccessRequestRecord = ({ record }) => {
  return (
    <>
      <div>
        <Typography variant="h2">Detaljer</Typography>
      </div>
      <List>
        <ListItem label="Firma">{record.requester.company}</ListItem>
        <ListItem label="Bygningssakkyndig">
          {record.requester.firstName} {record.requester.lastName}
        </ListItem>
        <ListItem label="Dato delt">
          {formattedDate(record.updatedDate)}
        </ListItem>
        <ListItem label="Tilgang utløper">
          {formattedDate(record.expiryDate)}
        </ListItem>
      </List>
    </>
  );
};

export default withStyles(Styles)(SingleAccessRequestRecord);
