import { get, post, put, patch, del } from '../utils/http';
import { uploadFile } from './files';
import { roomRequestDataTransformer } from './transformers/rooms';
import { publicRequest } from '../utils/api';
import { storeReferralSuggested } from '../utils/referral';

export const fetchRoomTypes = () =>
  get('rooms/types')
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const createRoom = (data) =>
  post('rooms', data, { transformRequest: roomRequestDataTransformer })
    .then((response) =>{
      storeReferralSuggested(false);
      return [null, response.data.response]} )
    .catch((error) => [error, null]);

export const fetchRoom = (roomId) =>
  get(`rooms/${roomId}`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const updateRoom = (roomId, data) =>
  patch(`rooms/${roomId}`, data, {
    transformRequest: roomRequestDataTransformer,
  })
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const deleteRoom = (roomId) =>
  del(`rooms/${roomId}`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const requestUploadLink = (roomId, data) =>
  put(`rooms/${roomId}/images`, data)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const uploadImage = async (roomId, file, position) => {
  const data = {
    position,
    fileName: file.name,
  };

  const [error, response] = await requestUploadLink(roomId, data);

  if (error) return [error, null];

  const [uploadError] = await uploadFile(response.uploadLink, file);

  return uploadError ? [uploadError, null] : [null, response];
};

// Public APIs

export const fetchPublicRooms = (boligmappaNumber) =>
  publicRequest
    .get(`/properties/${boligmappaNumber}/rooms`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);

export const fetchPublicRoom = (roomId) =>
  publicRequest
    .get(`/rooms/${roomId}`)
    .then((response) => [null, response.data.response])
    .catch((error) => [error, null]);
