import React, { createElement } from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import styles from './style';
import SeparatorLine from '../atomic/SeparatorLine';
import { isIOSNativeEnvironment } from '../../utils/nativeBridge';
import { isIos } from '../../utils/dom';

const MobileActionList = ({ classes, actions }) => {
  const isIOS = isIos();
  const isIosNative = isIOSNativeEnvironment();
  const activeApplication = {
    iosNative: isIosNative,
    ios: isIOS,
    android: !isIOS,
  };
  return (
    <ul className={classes.menuList}>
      {actions.map(
        (action) =>
          action.enable &&
          (!action.exclusive || activeApplication[action.exclusive]) && (
            <>
              <li className={classes.listItem}>
                <button
                  type="button"
                  onClick={action.onClick}
                  className={classes.itemButton}
                >
                  <div className={classes.itemIconContainer}>
                    {createElement(action.icon, {
                      className: classes.itemIcon,
                    })}
                  </div>
                  <p
                    className={clsx(
                      classes.itemText,
                      action.bold && classes.boldText
                    )}
                  >
                    {action.text}
                    {action.new && (
                      <span className={classes.newLabel}>Ny!</span>
                    )}
                  </p>
                </button>
              </li>
              {action.separator && (
                <SeparatorLine className={classes.separator} />
              )}
            </>
          )
      )}
    </ul>
  );
};

export default withStyles(styles)(MobileActionList);
