import { combineReducers } from 'redux';

import auth from './auth';
import rooms from './rooms';
import events from './events';
import rights from './rights';
import roomTypes from './roomTypes';
import documents from './documents';
import personalDocuments from './personalDocuments';
import companies from './companies';
import financials from './financials';
import properties from './properties';
import commitments from './commitments';
import notifications from './notifications';
import accessRequests from './accessRequests';
import apiHealth from './apiHealth';
import mdr from './mdr';
import bybf from './bybf';
import bybfRooms from './bybfRooms';
import bybfEvents from './bybfEvents';
import bybfEventTemplates from './bybfEventTemplates';
import marketEvents from './marketEvents';
import unitFeatures from './unitFeatures';
import userTransactions from './userTransactions';
import uxSignal from './uxSignal';
import appVersionData from './appVersionData';
import action from './action';

const rootReducer = combineReducers({
  auth,
  rooms,
  events,
  rights,
  roomTypes,
  companies,
  documents,
  personalDocuments,
  financials,
  properties,
  commitments,
  notifications,
  accessRequests,
  apiHealth,
  mdr,
  bybf,
  bybfRooms,
  bybfEvents,
  bybfEventTemplates,
  marketEvents,
  unitFeatures,
  uxSignal,
  appVersionData,
  userTransactions,
  action,
});

export default rootReducer;
