const styles = {
  popover: {
    maxHeight: 600,
  },
  title: {
    textAlign: 'left',
  },
};

export default styles;
